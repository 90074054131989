import React, { useState } from 'react';
import { CardContent, Typography, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';
import CardModal from './CardModal';

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
      marginTop: -3,
    },
  },
  cardContent: {
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  headerText: {
    color: '#363347',
    lineHeight: '20px',
  },
  subHeaderText: {
    padding: '0.5rem 0',
    borderBottom: '1px solid lightgrey',
    fontSize: '14px',
    color: '#5C6178',
    fontWeight: 500,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '.5rem',
  },
  countText: {
    fontSize: '38px',
    fontWeight: 600,
  },
  percentageText: {
    fontSize: '14px',
    color: '#171F26',
  },
  positivePercentage: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#84EAB3',
  },
  negativePercentage: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#FF206E',
  },
}));

const _sliceToLastNonZero = (arr, type) => {
  let lastIndex = arr.length - 1;
  while (lastIndex >= 0 && (!arr[lastIndex][type.key] || arr[lastIndex][type.key].count === 0)) {
    lastIndex--;
  }
  return arr.slice(0, lastIndex + 1);
};

export default function MarketCard({
  cardListViewData,
  eventType,
  startDate,
  endDate,
  chartType,
  chartView,
  isWeekly,
  title,
  subTitle,
  fetchInProgress,
  type,
  statKey,
  formatter,
  returnData,
  cardIndex,
  idx,
  snapshotTitle,
  fq,
  activityType
}) {
  const classes = useStyles();
  cardListViewData = _sliceToLastNonZero(cardListViewData, type);

  const getPreviousMonthsData = () => {
    if (!cardListViewData || cardListViewData.length <= 0 || !cardListViewData[cardListViewData.length - 1]) return [];
    return [cardListViewData[cardListViewData.length - 2], cardListViewData[cardListViewData.length - 1]];
  };

  const getPreviousYearData = (currentDate) => {
    if (!cardListViewData) return [];
    let oneYearBackDate = new Date(currentDate);

    if (isWeekly === '+7DAY') {
      oneYearBackDate.setDate(oneYearBackDate.getDate() - 52 * 7); // Subtract 52 weeks
      return cardListViewData.find((item) => {
        let itemDate = new Date(item.val);
        return itemDate.getTime() === oneYearBackDate.getTime();
      });
    } else {
      oneYearBackDate.setFullYear(oneYearBackDate.getFullYear() - 1); // one year back
      return cardListViewData.find((item) => {
        let itemDate = new Date(item.val);
        return itemDate.getTime() === oneYearBackDate.getTime();
      });
    }
  };

  const formatTime = (data, simple) => {
    let month = data && data.val;
    if (month) {
      const date = new Date(month);
      const monthName = simple
        ? date.toLocaleString('default', { month: 'short', timeZone: 'UTC' })
        : date.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
      const year = date.getUTCFullYear();
      if (isWeekly === '+7DAY') {
        const day = date.getDate();
        if (simple) {
          return `${monthName} ${day}, ${year}`;
        } else {
          return `Week of ${monthName} ${day}, ${year}`;
        }
      } else if (isWeekly === '+1MONTH') {
        return `${monthName} ${year}`;
      } else if (isWeekly === '+3MONTH') {
        const month = date.getUTCMonth(); // Get the month (0-11)
        const year = date.getUTCFullYear(); // Get the full year
        // Determine the quarter based on the month
        let quarter;
        if (month >= 0 && month <= 2) {
          quarter = 'Q1';
        } else if (month >= 3 && month <= 5) {
          quarter = 'Q2';
        } else if (month >= 6 && month <= 8) {
          quarter = 'Q3';
        } else {
          quarter = 'Q4';
        }
        return `${quarter} ${year}`;
      } else if (isWeekly === '+1YEAR'){
        return `${year}`;
      } else if (data && endDate) {
        if(simple){
          const day = date.getDate();
          return `${monthName} ${day}, ${year}`;
        }
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startMonthName = simple
          ? start.toLocaleString('default', { month: 'short', timeZone: 'UTC' })
          : start.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
        const endMonthName = simple
          ? end.toLocaleString('default', { month: 'short', timeZone: 'UTC' })
          : end.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
        const startYear = start.getUTCFullYear();
        const endYear = end.getUTCFullYear();
        const startDay = start.getDate();
        const endDay = end.getDate();
        return `${startMonthName} ${startDay}, ${startYear} - ${endMonthName} ${endDay}, ${endYear}`;
      }
    }  else {
      return '';
    }
  };

  const getValueFromDotSeparatedKey = (jsonObject, keyString) => {
    if (!jsonObject || !keyString) return undefined;
    const keys = keyString.split('.');

    const getValue = (obj, keys) => {
      if (keys.length === 0) return obj;
      const [firstKey, ...restKeys] = keys;
      if (obj.hasOwnProperty(firstKey)) {
        return getValue(obj[firstKey], restKeys);
      } else {
        return undefined;
      }
    };

    return getValue(jsonObject, keys);
  };

  const getPercentage = (val1, val2) => {
    if (val1 && val2) {
      const newCount = val1;
      const oldCount = val2;
      const percentageChange = ((newCount - oldCount) / oldCount) * 100;
      return `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%`;
    } else {
      return '';
    }
  };

  const monthlyData = getPreviousMonthsData();
  //console.log(monthlyData, "monhlty")
  const yearlyData = getPreviousYearData(monthlyData && monthlyData[1] && monthlyData[1].val && monthlyData[1].val);
  console.log(yearlyData)
  const keyStat =
    monthlyData &&
    monthlyData[1] &&
    monthlyData[1][type.key] &&
    getValueFromDotSeparatedKey(monthlyData[1][type.key], statKey)
      ? formatter(getValueFromDotSeparatedKey(monthlyData[1][type.key], statKey))
      : '--';
  const newVal = monthlyData && monthlyData[1] && monthlyData[1][type.key]
    ? getValueFromDotSeparatedKey(monthlyData[1][type.key], statKey)
    : false;
  const oldVal = monthlyData && monthlyData[0] && monthlyData[0][type.key]
    ? getValueFromDotSeparatedKey(monthlyData[0][type.key], statKey)
    : false;
  const yearVal = yearlyData && yearlyData[type.key]
    ? getValueFromDotSeparatedKey(yearlyData[type.key], statKey)
    : false;
  const data = cardListViewData.slice(isWeekly !== '+1MONTH' ? -53 : 0).map((item) => {
    return {
      name: item.val,
      val: item.val,
      value: item[type.key] && getValueFromDotSeparatedKey(item[type.key], statKey)
        ? getValueFromDotSeparatedKey(item[type.key], statKey)
        : 0,
    };
  });

  if (returnData) {
    return { stat: keyStat };
  }

  const [isOpen, setIsOpen] = useState(null);
  const [eventName, setEventName] = useState('');
  const toggleModal = () => setIsOpen(null);

  const getComparisonText = () => {
    if (isWeekly === '+7DAY') return 'vs. prior week';
    if (isWeekly === '+1MONTH') return 'vs. prior month';
    if (isWeekly === '+3MONTH') return 'vs. prior quarter';
    return 'vs. prior period';
  };

  return (
    <>
      <Card
        className={classes.card}
        style={{ borderTop: `1px solid ${type.checkedColor}`, borderBottom: `5px solid ${type.checkedColor}` }}
        onClick={() => {
          if (`${type.name}-${idx}` === cardIndex) {
            setIsOpen(cardIndex);
          }
        }}
      >
        <CardContent className={classes.cardContent}>
          <Typography component="div">
            <Typography variant="h5" component="div" className={classes.headerText}>
              {title}
            </Typography>
            <Typography variant="h6" component="div" className={classes.subHeaderText}>
              {!fetchInProgress
                ? `${subTitle} ${formatTime(monthlyData && monthlyData[1] ? monthlyData[1] : false)}`
                : `${subTitle} ....`}
            </Typography>
            {!fetchInProgress ? (
              <Typography component="div" className={classes.contentContainer}>
                <Typography component="div">
                  <Typography variant="h6" component="div" className={`${classes.countText} key-stat`}>
                    {keyStat}
                  </Typography>
                </Typography>
                <Typography component="div">
                  <Typography variant="h5" component="span" className={classes.percentageText}>
                    {getComparisonText()}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    className={
                      getPercentage(newVal, oldVal).includes('-') ? classes.negativePercentage : classes.positivePercentage
                    }
                  >
                    {getPercentage(newVal, oldVal)}
                  </Typography>
                  {["+7DAY", "+1MONTH", "+3MONTH"].indexOf(isWeekly)>-1 ?
                    <>
                    <Typography variant="h5" component="span" className={classes.percentageText}>
                      vs. prior year
                    </Typography>
                    <Typography variant="h6" component="div" className={getPercentage(newVal, yearVal).includes("-") ? classes.negativePercentage : classes.positivePercentage}>
                      {getPercentage(newVal, yearVal)}
                    </Typography>
                  </> : null}
                </Typography>
                
                
              </Typography>
              
            ) : null}
            <div style={{ height: 60, width: '100%' }}>
              <ResponsiveContainer>
                <AreaChart
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: -15,
                    bottom: 0,
                  }}
                >
                  <Area type="monotone" dataKey="value" stroke={`${type.checkedColor}`} fill={`${type.checkedColor}`} fillOpacity={0.2} />
                </AreaChart>
              </ResponsiveContainer>
              {data && data.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 10 }}>
                  <div>{formatTime(data && data[0] ? data[0] : false, true)}</div>
                  <div>{formatTime(data && data[data.length - 1] ? data[data.length - 1] : false, true)}</div>
                </div>
              )}
            </div>
          </Typography>
        </CardContent>
      </Card>
      {isOpen && (
        <CardModal
          isOpen={isOpen}
          chartType={chartType}
          chartView={chartView}
          toggleModal={toggleModal}
          eventType={eventType}
          eventName={type.key}
          startDate={startDate}
          endDate={endDate}
          monthlyData={monthlyData}
          isWeekly={isWeekly}
          snapshotTitle={snapshotTitle}
          fq={fq}
          type={type}
          activityType={activityType}
        />
      )}
    </>
  );
}
