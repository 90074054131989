import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import MatTableToolbar from './MatTableToolbar';
import UnitsSearchAppBar from './UnitsSearchAppBar';
import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {isMobile} from 'react-device-detect';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import QuarterlyIcon from 'mdi-react/CalendarBlankIcon';
import MonthlyIcon from 'mdi-react/CalendarMonthOutlineIcon';
import WeeklyIcon from 'mdi-react/CalendarClockIcon';

import ClosedDateIcon from 'mdi-react/ClockIcon';
import ReportedDateIcon from 'mdi-react/ClockOutlineIcon';

import DataExport from './DataExport';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Panel from '../../../shared/components/Panel';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';

import queryString from "query-string";

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage
} from "../../../shared/helpers/utils";

import { 
	_buildingFilters	
} from "../../../shared/helpers/filters";

let counter = 0;
am4core.useTheme(am4themes_animated);
function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';

const lsPre = "MA2_";
const subFilterKey = 'subFilters';


class UnitsSearchTable extends PureComponent {
  
  state = {
    order: !this.props.buildingView ? "desc" : "",
    orderBy: !this.props.buildingView ? "solr_updated_date" : "",
    selected: new Map([]),
    page: 0,
    rowsPerPage: 999,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _buildingFilters, subFilterKey, lsPre),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	startDate:moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(2, 'years').startOf('week').add(1, 'day').toDate().toISOString(),
	endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
	gap:  getLocalStorage(lsPre, 'gap') ? getLocalStorage(lsPre, 'gap') : "+7DAY",
	chartData:[],
	dataStart:isMobile 
				? moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf('week').add(1, 'day').toDate().toISOString() 
				: moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf('week').add(1, 'day').toDate().toISOString(),
	dataEnd:moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
	buildingEventCount:0,
	selectedDate:false,
	eventTypes:getLocalStorage(lsPre, 'eventTypes') ? getLocalStorage(lsPre, 'eventTypes') :[ "Listings", "Contracts Reported Signed", "Sales"],
	soldTimeField: getLocalStorage(lsPre, 'soldTimeField') ? getLocalStorage(lsPre, 'soldTimeField') : 'event_time'
  };
  
  defaultQ = "*:*";
  q='';
  fq = [		
		"offering_verified_status:(active \"coming soon\" \"offering plan required\" \"condo declaration vetted\")",
		//"offering_verified_status:(active)",
		"deleted:false",
		//"-key:b-4-2315-44th-dr-11101"
	];
	
  fl = buildingFL;
  chart;
  _unitSubFilter = _buildingFilters.filter(filter => !(this.props.buildingView && filter.building_hide));
  timeout = null;
   _matchedBuildingIds = [];
   _matchedBuildingSlugs = [];
   formattedData;
   tempEventTypes = this.state.eventTypes.slice(0);
   noDataIndicator;
  componentDidMount() {
  	 this._getBuildings();
  	 
  	
  }
 
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);

	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					this.state.subFilters[item.search_key] !==
					prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.gap !== prevState.gap ||
			this.state.soldTimeField !== prevState.soldTimeField || 
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)		
			  this._getBuildings(resetPage);
		}else if(this.state.dataStart !== prevState.dataStart ||
			this.state.dataEnd !== prevState.dataEnd ||
			this.state.selectedDate !== prevState.selectedDate ||
			!_isEqual(this.state.eventTypes, prevState.eventTypes)){
				this._getEvents(this._matchedBuildingIds.join(" "), function(res){					
					if(res && res.facets){
						let facetData = {};
						if(res.facets && res.facets.by_building){
							const formattedDataBuilding = res.facets.by_building.buildings.buckets.map(bucket => {
								
								bucket['building'] = this.formattedData.filter(building => building.key == bucket.val)[0];
								return bucket;
							})
							this.setState({
								groupedData:  formattedDataBuilding,
								groupedCount: formattedDataBuilding.length,
								buildingEventCount: res.facets.by_building.count,
								buildingsFetchInProgress: false

							})
						}
					}
			}.bind(this))
		}
		
		

	  
  }
  
  buildChart = (data) => {
	  let chart = am4core.create("chartdiv", am4charts.XYChart);
  	  const { user } = this.props;
  	  const { eventTypes, selectedDate } = this.state;
  	    chart.scrollbarX = new am4core.Scrollbar();
  	 // Add data
		
		chart.data = data;

		function showIndicator(noDataIndicator) {
		  if (noDataIndicator) {
		    noDataIndicator.show();
		  }
		  else {
		    noDataIndicator = chart.tooltipContainer.createChild(am4core.Container);
		    noDataIndicator.background.fill = am4core.color("#fff");
		    noDataIndicator.background.fillOpacity = 0.8;
		    noDataIndicator.width = am4core.percent(100);
		    noDataIndicator.height = am4core.percent(100);
		
		    var indicatorLabel = noDataIndicator.createChild(am4core.Label);
		    indicatorLabel.text = "No data found for your search.";
		    indicatorLabel.align = "center";
		    indicatorLabel.valign = "middle";
		    indicatorLabel.fontSize = 14;
		  }
		}
		
		function hideIndicator(noDataIndicator) {
		   noDataIndicator.hide();
		}
		
		function highLightColumn(categoryX){
			chart.series.each(function(series) {				    
			    series.columns && series.columns.values.forEach(c => {
				    if (c.dataItem.categoryX == categoryX) {
				          c.isActive = true;
				    }
				    
				 });
			}.bind(this));
		}
		
		chart.events.on("beforedatavalidated", function(ev) {
		  // check if there's data
		  if (ev.target.data.length == 0) {
		    showIndicator(this.noDataIndicator);
		  }
		  else if (this.noDataIndicator) {
		    hideIndicator(this.noDataIndicator);
		  }
		}.bind(this));
		
		chart.events.on("ready", function(ev) {
			    console.log("here")
			    if(selectedDate){
					const columnX  = this.getIntervalName(selectedDate);
					highLightColumn(columnX);
				}
			  
		}.bind(this));
		

		
		// Create axes
		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "time";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = "right";
		categoryAxis.renderer.labels.template.verticalCenter = "middle";
		categoryAxis.renderer.labels.template.rotation = 290;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.minHeight = 110;
		
		
		const slideChange = (ev) => {
			if(this.timeout) clearTimeout(this.timeout);
			
			const { dataStart, dataEnd } = this.state;
			let axis = ev.target;
			let start = axis.getPositionLabel(axis.start);
			let end = axis.getPositionLabel(axis.end);
//console.log(start, end);			
			const formattedStart = this.getIntervalTime(start);
			const formattedEnd = this.getIntervalTime(end);
//console.log(formattedStart, formattedEnd);			
			if(start && end && (dataStart !== start || dataEnd !== end)){
				this.timeout = setTimeout(function (start, end, series) {
				//  
				   chart.series.each(function(series) {
				      series.columns.values.forEach(c => c.isActive = false);
				    });
			       this.setState({
				       dataStart: formattedStart.toDate().toISOString(), 
				       dataEnd: formattedEnd.toDate().toISOString(),
				       selectedDate: false,
				       eventTypes: this.tempEventTypes.slice(0)
				   });

				}.bind(this, start, end, chart), 100)				
			}			
		}
		
		categoryAxis.events.on("endchanged", slideChange);
		categoryAxis.events.on("startchanged", slideChange);
		
		
		categoryAxis.start = isMobile ? 0.875 : 0.5;
		categoryAxis.end = 1;
		categoryAxis.keepSelection = true;
		
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.minWidth = 50;
		
		
		// Create series
		const createSeries = (field, name) => {
  
		  // Set up series
		  var series = chart.series.push(new am4charts.ColumnSeries());
		  series.name = name;
		  series.dataFields.valueY = field;
		  series.dataFields.categoryX = "time";
		  series.sequencedInterpolation = true;
		  
		  // Make it stacked
		  series.stacked = true;
		  
		 		  
		  
		  // Configure columns
		  series.columns.template.width = am4core.percent(80);
		  series.columns.template.tooltipText = "{categoryX}\n[font-size:14px]{name}: [bold]{valueY}[/]";		  
		
		  series.columns.template.column.cornerRadiusTopLeft = 0;
		  series.columns.template.column.cornerRadiusTopRight = 0;
		  series.columns.template.column.fillOpacity = 0.8;
		  series.columns.template.strokeWidth = 0;
		
		  series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
		  
	  
		  var hoverState = series.columns.template.column.states.create("hover");
			hoverState.properties.cornerRadiusTopLeft = 0;
			hoverState.properties.cornerRadiusTopRight = 0;
			hoverState.properties.fillOpacity = 1;

			//series.columns.template.column.togglable=true;
			let activeColor = "#B070CC";
			if(field === "contract_signed") activeColor = "#5C1F7A";
			if(field === "recorded_sold") activeColor = "#290059";
			
			var activeState = series.columns.template.states.create("active");
			activeState.properties.fill = am4core.color(activeColor);
			activeState.dx = 6;
			activeState.dy = 6;
			activeState.opacity = 0.3;
			

			series.columns.template.events.on("hit", function(ev) {
			  const { dataStart, dataEnd, gap } = this.state;
			  console.log(ev)
			  chart.series.each(function(series) {
				    series.columns.values
					  .filter(c => c !== ev.target)
					  .forEach(c => c.isActive = false);
			  });
			  
			  
			  
			  ev.target.isActive = !ev.target.isActive;
			  
			  if(ev.target.isActive){
				  
				  const columnX = ev.target.dataItem.categoryX;
				  highLightColumn(columnX);
				  
				  let start = ev.target.dataItem.categories.categoryX;
				  let end = gap === '+1MONTH' ? this.getIntervalTime(start).add(1, 'month') : this.getIntervalTime(start).add(1, 'week');
				  
				  const formattedStart = this.getIntervalTime(start);
				  
				  let type = ev.target.dataItem.component.dataFields.valueY;
				  const translateType = {
					  "recorded_sold" : "Sales",
					  "contract_signed" : "Contracts Reported Signed",
					  "new_listings" : "Listings"
				  }
				  
				  if(dataStart !== start || dataEnd !== end){
					 setTimeout(function (start, end, series, translateType) {
					       this.setState({
						       selectedDate: formattedStart.toDate().toISOString(), 
						       //eventTypes:[translateType[type]]
						   });
		
					}.bind(this, start, end, series, translateType), 100)
					   
				  }			  
			  }else{			  
				  this.setState({
					  selectedDate:false,
					  //eventTypes: this.tempEventTypes.slice(0)
				  })			  
			  }
			  
			  
			  
			}.bind(this));

			let color = "#4ce1b6";
			if(field === "contract_signed") color = "#FFD23F";
			if(field === "recorded_sold") color = "#F53246";
			
			series.columns.template.adapter.add("fill", function(fill, target) {
			  //return chart.colors.getIndex(target.dataItem.index);
			  
			  return am4core.color(color);
			}.bind(this));
			
			if(eventTypes.indexOf(name) < 0){
			  series.hidden = true;
			  series.hide();
		    }
		    		  
		  return series;
		};
		
		createSeries("new_listings", "Listings");
		createSeries("contract_signed", "Contracts Reported Signed");
		createSeries("recorded_sold", "Sales");
		
/*
		var series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = "new_listings";
		series.dataFields.categoryX = "time";
		series.tooltipText = "{this.state.gap === '+1MONTH' ? "Month" : "Week"} of {categoryX} - [{categoryX}: bold]{valueY}[/] Listings";
		series.columns.template.strokeWidth = 0;
		
		series.tooltip.pointerOrientation = "vertical";
		
		series.columns.template.column.cornerRadiusTopLeft = 0;
		series.columns.template.column.cornerRadiusTopRight = 0;
		series.columns.template.column.fillOpacity = 0.8;
		
		series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
*/

		
		// on hover, make corner radiuses bigger
		
		
		
		// Cursor
		chart.cursor = new am4charts.XYCursor();		
		chart.exporting.menu = new am4core.ExportMenu();		
		chart.zoomOutButton.background.fill = am4core.color("#780F9E");
		chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#9600BF");
		
		
		// Legend
		chart.legend = new am4charts.Legend();
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = 15;
		markerTemplate.height = 15;

		chart.legend.itemContainers.template.events.on("hit", function(ev) {		  
		  const { eventTypes } = this.state;
		  const type = ev.target.dataItem.name;
/*
		   chart.series.each(function(series) {
		      series.columns.values.forEach(c => c.isActive = false);
		    });
*/
		   setTimeout(function (eventTypes, type) {
			 console.log(eventTypes, type)
			  if(this.tempEventTypes.indexOf(type) >= 0){
				  this.tempEventTypes = this.tempEventTypes.filter(item => item !== type).slice(0);
				  this.setState( { eventTypes: this.tempEventTypes.slice(0) })
				  setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }else{
				 this.tempEventTypes = [...this.tempEventTypes, ...[type]];
				 this.setState( { eventTypes: this.tempEventTypes.slice(0) } )
				 setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }
		    }.bind(this, eventTypes, type), 100);
		  //let seriesColumn = ev.target.dataItem.dataContext.columns.template;
		  
		}.bind(this));
		
		
		
		
	
	

		chart.exporting.menu.items = [{
		  "label": "Export",
		  "menu": [
		    { "type": "png", "label": "Image (PNG)" },
		    { "type": "pdf", "label": "PDF" },
		  ]
		}];
		if(user.role && Array.isArray(user.role) && user.role.indexOf('data-export')>=0){
			chart.exporting.menu.items[0].menu.push({ "type": "csv", "label": "Data (CSV)" });
			
		}
		chart.exporting.filePrefix="Marketproof New Development - Market Activity";
		this.chart = chart;
  }
  
  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'key');
	 
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  getIntervalName(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date).add(1, 'hour').format('MMM, YYYY');
	  }
	  
	  return moment(date).add(1, 'hour').format("M/D/YY")
	  
	  
  }
  
  getIntervalTime(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date, 'MMM, YYYY');
	  }
	 
	  return moment(date, "M/D/YY");  
	  
  }
  
  
  _getBuildings = async (resetPage) => {
		this.q = '';
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, groupedData:false, tableData:false, reactTableFiltered:false }));
		}
		
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		

		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qVal = this._unitSubFilter.filter(filter => filter.search_key === key).length>0 
								? this._unitSubFilter.filter(filter => filter.search_key === key)[0].values.filter(value => value.filter === subFilterVal)[0].search_value
								: key;
					
					this.q += this.q.length > 0 ? " AND " : "";
					return this.q += `(${key}:${qVal})`;
				}
			});
		}
		
		setURLFromState(this.props, this.state);
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `marketing_company:(*${text}* OR *${textCaps}*) OR (name:(*${text}* OR *${textCaps}*) OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		let sort = null;
		if(this.state.orderBy && this.state.orderBy.length > 0){			
				sort = `${this.state.orderBy} ${this.state.order}`;			
		}

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: this.q,
				fq: this.fq,
				sort: sort,
				fl: this.fl,
				rows:this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				wt: "json",	
								
			}
		};
		
		if(this.state.groupedView){
			axiosData.query.rows = 999;
			axiosData.query.start = 0;
			axiosData.query["json.facet"] = {
					"by_building": {
						"type": "terms",
						"field": "key",
						"offset": resetPage ? 0 : this.state.start,
						"limit": this.state.rowsPerPage,
						"facet" : {
							"inventory_total": "sum(inventory_total)",
							"inventory_unsold": "sum(inventory_unsold)",
							"inventory_stats_listings_average_ppsf" : "avg(inventory_stats_listings_average_ppsf)",
						}
					}
					
				}
			
		}

	
		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});
	
			let formattedDataBuilding = [];
			this._matchedBuildingIds = [];
			this._matchedBuildingSlugs = [];
			
			this.formattedData = response && response.docs
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;

			let chartData = [];
			
			if(this.formattedData){
				this.formattedData.map(item => {			
					this._matchedBuildingIds.push(item.key);
					this._matchedBuildingSlugs = this._matchedBuildingSlugs.concat(item.building_slugs);
				})
			}
			
			if(this._matchedBuildingIds.length>0){
				this._getEvents(this._matchedBuildingIds.join(" "), function(res){					
	
					if(res && res.facets){
						let facetData = {};
						if(res.facets && res.facets.by_building){
							formattedDataBuilding = res.facets.by_building.buildings.buckets.map(bucket => {
								
								bucket['building'] = this.formattedData.filter(building => building.key == bucket.val)[0];
								return bucket;
							})
						}
						if(res.facets.new_listings && res.facets.new_listings.by_time){
							
							chartData = res.facets.new_listings.by_time.buckets.map(bucket => {
								const key = this.getIntervalName(bucket.val);
								return { time: key, new_listings: bucket.count}
							})
							
						}
						
						if(res.facets.contract_signed && res.facets.contract_signed.by_time){
							
							res.facets.contract_signed.by_time.buckets.forEach(bucket => {
								const key = this.getIntervalName(bucket.val);								
								let existingItem = chartData.filter(item => item.time === key);
								if(existingItem && existingItem[0]){
									existingItem[0].contract_signed = bucket.count
								}
							})
							
						}
						
						if(res.facets.recorded_sold && res.facets.recorded_sold.by_time){
							
							res.facets.recorded_sold.by_time.buckets.forEach(bucket => {
								const key = this.getIntervalName(bucket.val);								
								let existingItem = chartData.filter(item => item.time === key);
								if(existingItem && existingItem[0]){
									existingItem[0].recorded_sold = bucket.count
								}
							})
							
						}
						
					}
					
					this.buildChart(chartData);
					
					this.setState(prevState => ({
						fetchInProgress: false,
						buildingsFetchInProgress: false,
						groupedData:  formattedDataBuilding,
						groupedCount: formattedDataBuilding.length, //response.building.ngroups,	
						totalCount: res.facets.new_listings.count,
						buildingEventCount: res.facets.by_building.count,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page,
						chartData: chartData,
					}));
				}.bind(this))
			}else{
				
				chartData = [];
				this.buildChart(chartData);
				this.setState(prevState => ({
						fetchInProgress: false,
						buildingsFetchInProgress: false,
						groupedData:  [],
						groupedCount: 0, //response.building.ngroups,	
						totalCount: 0,
						buildingEventCount: 0,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page
				}));
			}
		
	
	};
	
	_getEvents = async (_matchedBuildingIds, callback) => {
		
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"building:("+_matchedBuildingIds+")",
			//"building_slugs:(" + this._matchedBuildingSlugs.join(" ") + ")",
			"hierarchy:[0 TO 2]",	
			//"borough_code:[1 TO 5]",
 			"-time:[2020-03-18T20:42:19Z TO 2020-03-19T00:47:46Z]"
		
			
		];
		
		const buildingStart = selectedDate ? selectedDate : dataStart;
		const buildingEnd = selectedDate ? gap === '+1MONTH' ? moment(selectedDate).add(1, 'month').add(-1, 'hour').toDate().toISOString()  : moment(selectedDate).add(1, 'week').add(-1, 'hour').toDate().toISOString() : dataEnd;
				
		const buildingQuery = eventTypes.filter(type => type !== "").map(type => {
			switch (type) {
				case "Listings" : 
					return `(topic:listings AND event_tags:just-listed AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}])`;
					break;
				case "Contracts Reported Signed" : 
					return `(topic:listings AND event_tags:contract-signed AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}])`;
					break;
				case "Sales" : 
					return `(topic:transactions AND event_tags:recorded-sale AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}])`
					break;
			}
				
		}).join(" OR ");

		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				wt: "json",
				rows: 0,
				"json.facet": {
					"new_listings": {
						"type": "query",
						"q": `topic:listings AND event_tags:just-listed AND -event_tags:rent`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
									}
								}
							},
							"by_building": {
								"type" : "query",
								"q" : `time:[${buildingStart} TO ${buildingEnd}]`,
								"facet" : {
									"buildings" : {
										"type": "terms",
										"field": "building",
										"offset": 0,
										"limit": 999
									}
									
								}
								
							}
						}
					},
					"contract_signed": {
						"type": "query",
						"q": "topic:listings AND event_tags:contract-signed AND -event_tags:rent",
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
									}
								}
							}
						}
					},
					"recorded_sold": {
						"type": "query",
						"q": "topic:transactions AND event_tags:recorded-sale",
						"facet": {
							"by_time": {
								"range": {
									"field": soldTimeField,
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
									}
								}
							}
						}
					},
					"by_building": {
						"type" : "query",
						"q" : `${buildingQuery}`,
						"facet" : {
							"buildings" : {
								"type": "terms",
								"field": "building",
								"offset": 0,
								"limit": 999
							}
							
						}
						
					}
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof callback === "function") {
					let formattedRes = callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	
	_formatStats = (response, redoHiddenFields = false) => {

		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, {});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		
		if(this.state.gap === '+7DAY'){
			this.setState( { gap : '+1MONTH', selectedDate: false } )
			setLocalStorage(lsPre, 'gap', '+1MONTH');
		}else{
			this.setState( { gap : '+7DAY', selectedDate: false } )
			setLocalStorage(lsPre, 'gap', '+7DAY');

		}
		
	}
	
	_toggleTime = () => {
		
		if(this.state.soldTimeField === 'event_time'){
			this.setState( { soldTimeField : 'time', selectedDate: false } )
			setLocalStorage(lsPre, 'soldTimeField', 'time');
		}else{
			this.setState( { soldTimeField : 'event_time', selectedDate: false } )
			setLocalStorage(lsPre, 'soldTimeField', 'event_time');
		}
		
	}
	
	

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, gap
    } = this.state;
    
    const { searchTags } = this.props;
    
    let companyShow = { name:"Sales Agents", type:"marketing_company"};
    
    const attributeTag = searchTags.filter(item => ['building', 'neighborhood', 'borough'].indexOf(item.type) < 0);
    
    if(attributeTag && attributeTag.length>0){
	    if(attributeTag[0].type === 'developers'){
		    companyShow = { name:"Developer", type:"developers"};
	    }else if(attributeTag[0].type === 'architect'){
		    companyShow = { name:"Architect", type:"architect"};
	    }
    }
    
    
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = false; //this.state.groupedView ? groupedCount : totalCount;
    
    const buildingStart = selectedDate ? selectedDate : dataStart;
	const buildingEnd = selectedDate ? gap === '+1MONTH' ? moment(selectedDate).add(1, 'month').add(-1, 'hour').toDate().toISOString()  : moment(selectedDate).add(1, 'week').add(-1, 'hour').toDate().toISOString() : dataEnd;
	
	const buildingQuery = eventTypes.map(type => {
			switch (type) {
				case "Listings" : 
					return `(topic:listings AND event_tags:just-listed AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}])`;
					break;
				case "Contracts Reported Signed" : 
					return `(topic:listings AND event_tags:contract-signed AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}])`;
					break;
				case "Sales" : 
					return `(topic:transactions AND event_tags:recorded-sale AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}])`
					break;
			}
		});

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >

            <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={this._unitSubFilter}      
            	{...this.props}  
            	groupedView   	
             />
             <div style={{height:10,padding:"0 10",margin:10}} >
              {fetchInProgress && this.state.groupedView && <LinearLoading /> }
             </div>
            
            <div className="material-table__header-info">
            	<Row>
            		<Col md={12} lg={12}>
		            	<h5 className="bold-text mb-3">
		            	<div className={"mobile__show"}>
		            		{this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"} {` `}
		            		{eventTypes.length===1 
			            	? eventTypes[0] : eventTypes.length===2 
			            	?  eventTypes.join(" and ") : 'Market'
			            	} Activity <br/>
							{ selectedDate ? 
							 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							 {` `} (24 Months Max)
							 
		            	</div>
		            	<span className={"desktop__show"}>
		            		{this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"} {` `}
		            		{eventTypes.length===1 
			            	? eventTypes[0] : eventTypes.length===2 
			            	?  eventTypes.join(" and ") : 'Market'
			            	} Activity - {` `}
		            		{ selectedDate ? 
							 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							 {` `} (24 Months Max)
						</span>	 
		            	</h5>
		            	
					</Col>
					
					{/*!this.props.buildingView &&
					<Col md={6} lg={6} style={{ textAlign:"right" }}>
		            	<span>
						  
						  <BuildingIcon color="#DBE0E8" />
				          <Tooltip title={`${this.state.groupedView ? "Expand to Unit View" : "Condense to Building View"}`}>
					        <PurpleSwitch 
					            checked={!this.state.groupedView}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>					      	
					      <ListIcon color="#DBE0E8" />				       
					    </span>
			    	</Col>
			    	*/}
            	</Row>
            </div>
            <div className="material-table__header-info">
            	<Row>	
					<Col md={6} lg={6} xl={6} sm={6} style={{ textAlign:"left" }}>
		            	<span>						  
						  
						  {/*<WeeklyIcon color="#DBE0E8" />	*/}
						  <span>Weekly</span>
				          <Tooltip title={`${this.state.gap === '+7DAY' ? "Change to Monthly Chart" : "Change to Weekly Chart"}`}>
					        <PurpleSwitch 
					            checked={this.state.gap !== '+7DAY'}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>
					       <span>Monthly</span>	
					      {/*<MonthlyIcon color="#DBE0E8" />*/}				      						      				       
					    </span>
					    <span style={{margin:"0 10px", color:"#D1D6E0", textAlign: "center"}}> | </span>
					    <span>						  
						  {/*/<ClosedDateIcon color="#DBE0E8" />*/}
						  <span>Closed Date</span>
				          <Tooltip title={`${this.state.soldTimeField === 'event_time' ? "Change Sold Activity to Recorded Date" : "Change Sold Activity to Closed Date"}`}>
					        <PurpleSwitch 
					            checked={this.state.soldTimeField !== 'event_time'}
					        	onChange={this._toggleTime}
					        />
					      </Tooltip>
					      <span>Recorded Date</span>					      	
					     {/* <ReportedDateIcon color="#DBE0E8" />	*/}			       
					    </span>
			    	</Col>
			    	
            	</Row>
            </div>


			<div id="chartdiv" style={{ width: "100%", height: "500px", marginTop:"30px", opacity: fetchInProgress ? .5 : 1 }}></div>
            
            {buildingsFetchInProgress && <LinearLoading />}
            
            
            <div className="material-table__wrap">
              
              
              {!buildingsFetchInProgress && !fetchInProgress && this.state.groupedView && groupedData && 
	              
	           
	          
	           <div className="material-table expansion__table">
	           		 <hr/>
	           		<Row>
	           			<Col md={12} lg={6} xl={6}>
			           		<div className="material-table__header-info pb-3 desktop__show">
								<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
								{eventTypes.length===1 ? eventTypes[0] : 'Events'} 
								
								{groupedCount ? <Fragment> Across <span className="bold-text">{groupedCount}</span> Buildings </Fragment> : ''}
								| Date Range: { selectedDate ? 
									 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
									 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
									 </Fragment>
									:
									 <Fragment> 
									 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
									 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
									</Fragment>
								}	
							</div>
							<div className="material-table__header-info pb-3 mobile__show">
								<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
								{eventTypes.length===1 ? eventTypes[0] : 'Events'}  
								
								{groupedCount ? <Fragment> Across <span className="bold-text">{groupedCount}</span> Buildings </Fragment> : ''}
								<br/> Date Range: { selectedDate ? 
									 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
									 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
									 </Fragment>
									:
									 <Fragment> 
									 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
									 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
									</Fragment>
								}	
							</div>
						</Col>
						<Col md={12} lg={6} xl={6} className={"text-right"}>
			           		<DataExport 
			              		q={'*:*'}
			              		fq={[...[`${buildingQuery}`], ...[`building:(${this._matchedBuildingIds.join(" ")})`]]}
			              		fl={this.fl}	
				              />
			            </Col>
			          </Row>	

	           		
	           	   <Container>
				        <Row className="expansion__summary__header">
							<Col lg={2} xs={12}>	                          
							  	<TableSortLabel
					                active={orderBy === "count"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "count")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Market Activity
					              </TableSortLabel>
							</Col>
							 <Col lg={3} className="">	 
	                        	<TableSortLabel
					                active={orderBy === "index"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "index")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Building
					              </TableSortLabel>                         
							 	
							</Col>	
							<Col lg={3} xs={12} className="no-padding">	                          
							  	<TableSortLabel
					                active={orderBy === companyShow.type}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, companyShow.type)}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                {companyShow.name}
					              </TableSortLabel>
							</Col>
							<Col lg={2} xs={12} className="no-padding">	                          
							  	<TableSortLabel
					                active={orderBy === "inventory_total"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "inventory_total")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Total Units
					              </TableSortLabel>
							</Col>
							<Col lg={2} xs={12} className="no-padding">	                          
							  	<TableSortLabel
					                active={orderBy === "inventory_unsold"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "inventory_unsold")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Unsold Units
					              </TableSortLabel>
							</Col>	
							
							                     
	                       
							
	                    </Row>
	                </Container>
	              {groupedData.length ===0 &&		              
		            <Container>
				        <Row className="no__data">
							<Col lg={12} xs={12} className="no__data-text">			                          
							  	No data found for your search.
							</Col>	
						</Row>
					</Container>
	              }  	           
	              {groupedData.filter(item => item.building).map((d) => {
		                const item = d
		                const numFound = item.count;
		                const isSelected = this.isSelected(item.val);
		                const building = item.building;
			            return(  			            
			              <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
					        <ExpansionPanelSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-label="Expand"
					          aria-controls=""
					          id={d.groupValue}
					        >
					        <Container>
						        <Row className="expansion__summary">
									<Col lg={2} xs={12} >			                          
									  	<Badge color="matching" style={{ width:80 }}><span className="bold-text">{_formatNumber(numFound)}</span></Badge>
									</Col>	
									<Col lg={4} xs={12} >			                          
									  <div className="expansion__header">									  									  	
									  		<span className="building_name"  onClick={event => this.handleGroupedBuildingClick(event, building)}>{building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}
									  		{building.offering_construction==='conversion' &&
							                    <span className="unit-type_badge">Conv</span>
						                	 }
						                	 <OfferingStatus building={building} />
						                	 <BuildingIcons building={building} />
									  		 
									  		</span>
				                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{building.neighborhood && building.neighborhood[0] ? `${_capitalizeText(building.neighborhood[0])}, ` : ''} {_capitalizeText(building.borough)}
				                            
				                            
				                            </span>
									  
									  </div>				          									  
									</Col>
									<Col lg={2} xs={12}  className="no-padding">			                          
									  <div className="expansion__header">									  									  	
									  		<span className="light-text small-text">{building[companyShow.type] ? _capitalizeText(building[companyShow.type]) : 'Not Available'}</span>
									  </div>								  			          									  
									</Col>
									<Col lg={2} xs={12} >			                          
									  	<span className="light-text small-text">{_formatNumber(building.inventory_total)} Units</span>
									</Col>	
									<Col lg={2} xs={12}  className="no-padding">			                          
									  	{building.offering_construction==='conversion' 
			                          	 ?
			                          	 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total}  color={"grey"} />
			                          	 :
						                 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total}  />
					                    } 
									</Col>	
									
								             
			                       		                        			                        	
		                        </Row>
		                    </Container>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>				        
					        	<UnitsListTable 
				              		isSelected={this.isSelected}
				              		handleBuildingClick={this.handleBuildingClick}
				              		handleClick={this.handleClick}
				              		handleRequestSort={this.handleRequestSort}
				              		handleSelectAllClick={this.handleSelectAllClick}
				              		{...this.state} 
				              		{...this.props} 
				              		reactTableFiltered={false}	
				              		isGrouped
				              		q={'*:*'}
				              		fq={[`${buildingQuery}`]}
				              		fl={this.fl}	
				              		building={building.key}		              		
				              />
					        </ExpansionPanelDetails>
					     </ExpansionPanel>
					    )
					 })
	             }  
	            </div>
	          }
            
            
              {!this.state.groupedView && 	              
	              <UnitsListTable 
	              		isSelected={this.isSelected}
	              		handleBuildingClick={this.handleBuildingClick}
	              		handleClick={this.handleClick}
	              		handleRequestSort={this.handleRequestSort}
	              		handleSelectAllClick={this.handleSelectAllClick}
	              		{...this.state} 
	              		{...this.props} 
	              		
	              />      
              }
            </div>
            {paginationCount ? <TablePagination
              component="div"
              className="material-table__pagination"
              count={paginationCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            /> : null}
          
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(UnitsSearchTable));