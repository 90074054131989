/* eslint-disable react/no-children-prop */
import React, { Fragment } from 'react';
import {
  Card, CardBody, Row, Col, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import{
	matTheme,
} from '../../../shared/helpers/utils';

import renderDropZoneField from './DropZone';

const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined

const renderTextField = ({
  input, label, meta: { touched, error, warning }, children, select,
}) => (
  <Fragment>
    <ThemeProvider theme={matTheme}>

	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    type="password"
	    variant="outlined"

	  />
	  {touched && ((error && <div className="field-error">{error}</div>) || (warning && <div className="field-error warn">{warning}</div>))}
	</ThemeProvider>

  </Fragment>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const PasswordForm = ( props ) => {
	const { handleSubmit, reset, user } = props;
	//console.log(user);
	return(
	  <Row>
	  <Col md={12} lg={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'Change Password'}</h5>
	          <div className="page-subhead subhead"></div>
	          <hr/>
	        </div>
	        <form className="material-form" onSubmit={handleSubmit}>
		        <Row>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Current Password<sup className="required">*</sup></span>
		            <Field
		              name="currentPassword"
		              type="password"
		              component={renderTextField}
		              placeholder="New Password"
		              validate={[ required ]}
		            />
		          </div>
		        </Col>
		        <Col md={6} lg={6}>
		        </Col>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">New Password<sup className="required">*</sup></span>
		            <Field
		              name="newPassword"
		              type="password"
		              component={renderTextField}
		              placeholder="New Password"
		              validate={[ required ]}
		            />
		          </div>
		        </Col>
		        <Col md={6} lg={6}>
		          <div>
		            <span className="material-form__label">Confirm New Password<sup className="required">*</sup></span>
		            <Field
		              name="confirmPassword"
		              type="password"
		              component={renderTextField}
		              placeholder="Confirm Password"
		              validate={[ required ]}
		            />
		          </div>
		        </Col>        		        
		        <Col md={12} lg={12}>  
		          <ButtonToolbar className="form__button-toolbar">		           
		             <Tooltip title="Change Password">
				        <Button
					         variant="contained"
					         color="primary"
					         className="primary mr-3"		         
					         startIcon={null}
					         type="submit"
					     >	Change Password
					     </Button>				 
				  	</Tooltip>
		         </ButtonToolbar>
		        </Col>
	          </Row>
	        </form>
	      </CardBody>
	    </Card>
	  </Col>
	</Row>
	)
};

const form = 'password_form';

export default connect(
  (state, props) => {
  const initalValues = Object.assign({}, props.user);
  console.log(initalValues);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
})(PasswordForm));
