import React, { PureComponent, Fragment } from 'react';
import { Progress, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';
import { getDefaultChartColors } from '../../../../../../shared/helpers';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from '../../../../../../shared/helpers/utils';

const types = [0, 1, 2, 3, 4, 99];
	
class UnitMatrix extends PureComponent {
  

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }
 
  _generateUnitMixBreakdown = (mixData) => {
	 if(!mixData || !mixData.unit_mix || !mixData.unit_mix.buckets){
		  return [];
	  }
	 const total = mixData.unit_mix.buckets.map(bucket => bucket.count).reduce((accumulator, current) => { return accumulator + current}, 0);
	 let other = 0;
	 const data = {
		 0 : false,
		 1 : false,
		 2 : false,
		 3 : false, 
		 4 : false,
		 99: false
	 }
	 
	 const _setData = (bucket, key) => {
		 
		  const stat = bucket.non_zero_price ? bucket.non_zero_price : false;
		  const soldStat = bucket.sold_price ? bucket.sold_price : {};
		  const contractStat = bucket.contract_price ? bucket.contract_price : {};
		  
		  //blend contract and sold prices;
		  soldStat['sum_price'] = soldStat['sum_price'] ? soldStat['sum_price'] : 0;
		  soldStat['sum_sq_ft'] = soldStat['sum_sq_ft'] ? soldStat['sum_sq_ft'] : 0;
		  soldStat['sum_price'] += (contractStat['sum_price'] ? contractStat['sum_price'] : 0);
		  soldStat['sum_sq_ft'] += (contractStat['sum_sq_ft'] ? contractStat['sum_sq_ft'] : 0);
		  
		  const sold = bucket.by_status && bucket.by_status.buckets ? bucket.by_status.buckets.filter(bucket => ['resale', 'in contract', 'sold', 'not_yet_recorded'].indexOf(bucket.val)>=0).map(bucket => bucket.count).reduce((accumulator, current) => { return accumulator + current}, 0) : 0;
		  if(!stat.sum_price) stat.sum_price = 0;
		  if(!stat.sum_sq_ft) stat.sum_sq_ft = 0;
		  data[key] = {name: key > 0 ? `${key} Bed` : `Studio`,
						   bed: bucket.val,
						   count:  data[key] && data[key].count ? data[key].count + bucket.count : bucket.count,
						 sold:  data[key] && data[key].sold ? data[key].sold + sold : sold,
						   size: stat.avg_unit_size,
						 sold_size: soldStat.avg_unit_size,
						 ask_price: stat.avg_price,
						 ask_ppsf: stat.avg_ppsf,
						 ask_sum_price: data[key] && data[key].ask_sum_price ? data[key].ask_sum_price + stat.sum_price : stat.sum_price,
						 ask_sum_sq_ft: data[key] && data[key].ask_sum_sq_ft ? data[key].ask_sum_sq_ft + stat.sum_sq_ft : stat.sum_sq_ft,
						   sold_price: soldStat.avg_price,
						 sold_ppsf: soldStat.avg_ppsf,
						 sold_sum_price: data[key] && data[key].sold_sum_price ? data[key].sold_sum_price + soldStat.sum_price : soldStat.sum_price,
						 sold_sum_sq_ft: data[key] && data[key].sold_sum_sq_ft ? data[key].sold_sum_sq_ft + soldStat.sum_sq_ft : soldStat.sum_sq_ft,
						   carrying_cost: stat.avg_monthly_carrying_charges,
						   avg_fees: stat.avg_fees,
						   min_price: stat.min_price,
						   max_price: stat.max_price,
						   search : bucket.val == 0 ? 'studio' :  bucket.val > 3 ? '4-or-more' :  bucket.val+'-bedroom'
				   }
	 }
	 
	mixData.unit_mix.buckets.map((bucket, i) => {
		const key = bucket.val > 4 ? 4 : bucket.val;
		 _setData(bucket, key);
	 });
	 if(mixData.non_zero_price){
		 _setData(mixData, 99);
	 }
	
	 	 
	 return data;
	  
  }
  
  handleClick(e,unit) {
  	
  	const { history, location } = this.props; 
  	const base_url = location.pathname.replace('analysis', 'units');	
  	history.push(`${base_url}?offering_bedrooms=${unit.search}`);

  }

  
  render() {
	const { t, mixData, title, stage, overview } = this.props;
    if(!mixData){
	    return null;
    }
    const allUnits = this._generateUnitMixBreakdown(mixData.has_bed);
	const contractUnits = this._generateUnitMixBreakdown(mixData.last_12_signed);
	const marketUnits = this._generateUnitMixBreakdown(mixData.on_the_market);
	const shadowUnits = this._generateUnitMixBreakdown(mixData.shadow);
	console.log(shadowUnits, stage, mixData)
	return (
			<Fragment>
			<h4 class="bold-text mt-1">{title ? title : "Unit Matrix By Status"}</h4>
		    <Table responsive className={`table--bordered dashboard__audience-table table--unit__matrix ${stage}`}>
		      <thead>
			  	<tr>
					<th></th>
					{/* <th></th> */}
					<th className="th-sec sponsor" colSpan={overview ? 4 : 5}>{stage == "sponsor" ? "Sponsor Sales (To Date)" : "Resales"}</th>
					<th className="th-sec contract" colSpan="4">Contract Signed (Last 24 Months)</th>
					<th className="th-sec market" colSpan="4">On the Market</th>
					{stage == "sponsor" &&
						<th className="th-sec unsold" colSpan="4">Unclassified</th>
					}
				</tr>
		        <tr>
				  <th>Type</th>
				  {/* <th className="th" >Total Units</th> */}
				  <th className="th sponsor"># Units</th>
		          {!overview && <th className="th sponsor">% Sold</th>}
		          <th className="th sponsor">Avg SF</th>
		          <th className="th sponsor">Avg Price</th>
		          <th className="th sponsor">Avg PSF</th>
		          <th className="th contract"># Units</th>
				  <th className="th contract">Avg SF</th>
				  <th className="th contract">Avg Price</th>
				  <th className="th contract">Avg PSF</th>
				  <th className="th market"># Units</th>
				  <th className="th market">Avg SF</th>
				  <th className="th market">Avg Price</th>
				  <th className="th market">Avg PSF</th>
				  {stage == "sponsor" &&
				  <Fragment>
				  	<th className="th unsold"># Units</th>
				  	<th className="th unsold">Avg SF</th>
				  	<th className="th unsold">Avg Price</th>
				  	<th className="th unsold">Avg PSF</th>
				  </Fragment>
			  	  }
		        </tr>
				
		      </thead>
		      <tbody>
		        {types.map(unit => 
		        <tr className={unit==99 ? "total" : "line"}>
		         <td className="td all">{unit == 0 ? `Studio` : unit >=4 && unit < 99 ? '4+ Bed' : unit==99 ? 'Total' : `${unit} Bed`}</td>
				 {/* <td className="td all" >{allUnits[unit] && allUnits[unit].count ? _formatNumber(allUnits[unit].count) : "--"}</td> */}
				 <td className="th sponsor">{allUnits[unit] && allUnits[unit].sold ? _formatNumber(allUnits[unit].sold) : "--"}</td>
				 {!overview && <td className="td sponsor">{allUnits[unit] && allUnits[unit].sold && unit==99 ? `${Math.round((allUnits[unit].sold / allUnits[unit].count) * 100)}%` : "--"}</td>}
				 <td className="td sponsor">{allUnits[unit] && allUnits[unit].sold_sum_sq_ft && allUnits[unit].sold ? `${_formatNumber(allUnits[unit].sold_sum_sq_ft/allUnits[unit].sold)} SF` : "--"}</td>
				 <td className="td sponsor">{allUnits[unit] && allUnits[unit].sold_sum_price && allUnits[unit].sold ? `$${_formatNumber(allUnits[unit].sold_sum_price/allUnits[unit].sold)}` : "--"}</td>
				 <td className="td sponsor">{allUnits[unit] && allUnits[unit].sold_sum_price && allUnits[unit].sold_sum_sq_ft ? `$${_formatNumber(allUnits[unit].sold_sum_price/allUnits[unit].sold_sum_sq_ft)}` : "--"}</td>
				 <td className="td contract">{contractUnits[unit] && contractUnits[unit].count ? _formatNumber(contractUnits[unit].count) : "--"}</td>
				 <td className="td contract">{contractUnits[unit] && contractUnits[unit].size ? `${_formatNumber(contractUnits[unit].size)} SF` : "--"}</td>
				 <td className="td contract">{contractUnits[unit] && contractUnits[unit].ask_sum_price && contractUnits[unit].count ? `$${_formatNumber(contractUnits[unit].ask_sum_price / contractUnits[unit].count)}` : "--"}</td>
				 <td className="td contract">{contractUnits[unit] && contractUnits[unit].ask_sum_price  && contractUnits[unit].ask_sum_sq_ft ? `$${_formatNumber(contractUnits[unit].ask_sum_price/contractUnits[unit].ask_sum_sq_ft)}` : "--"}</td>
				 <td className="td market">{marketUnits[unit] && marketUnits[unit].count ? _formatNumber(marketUnits[unit].count) : "--"}</td>
				  <td className="td market">{marketUnits[unit] && marketUnits[unit].size ? `${_formatNumber(marketUnits[unit].size)} SF` : "--"}</td>
				  <td className="td market">{marketUnits[unit] && marketUnits[unit].ask_sum_price && marketUnits[unit].count ? `$${_formatNumber(marketUnits[unit].ask_sum_price / marketUnits[unit].count)}` : "--"}</td>
				  <td className="td market">{marketUnits[unit] && marketUnits[unit].ask_sum_price  && marketUnits[unit].ask_sum_sq_ft ? `$${_formatNumber(marketUnits[unit].ask_sum_price/marketUnits[unit].ask_sum_sq_ft)}` : "--"}</td>
				 {stage == "sponsor" &&
				   <Fragment>
				 
				 <td className="td unsold">{shadowUnits[unit] && shadowUnits[unit].count ? _formatNumber(shadowUnits[unit].count) : "--"}</td>
				 <td className="td unsold">{shadowUnits[unit] && shadowUnits[unit].ask_sum_sq_ft && shadowUnits[unit].count ? `${_formatNumber(shadowUnits[unit].ask_sum_sq_ft / shadowUnits[unit].count)} SF` : "--"}</td>
				 <td className="td unsold">{shadowUnits[unit] && shadowUnits[unit].ask_sum_price && shadowUnits[unit].count ? `$${_formatNumber(shadowUnits[unit].ask_sum_price / shadowUnits[unit].count)}` : "--"}</td>
				 <td className="td unsold">{shadowUnits[unit] && shadowUnits[unit].ask_sum_price  && shadowUnits[unit].ask_sum_sq_ft ? `$${_formatNumber(shadowUnits[unit].ask_sum_price/shadowUnits[unit].ask_sum_sq_ft)}` : "--"}</td>
				 </Fragment>
				 }
		        </tr>
		        )}
		      </tbody>
		    </Table>
			</Fragment>
	  )
	  
  }
}

export default UnitMatrix;
