import React, { PureComponent, Fragment } from 'react';
import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Modal
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import AddToBuyersListButton from '../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../shared/components/buttons/ShareButton';

import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import moment from "moment";
import divr from 'react-slick';

import ShareTopBar from './../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../shared/components/ShareContact'
import ShareFooter from './../../../../shared/components/ShareFooter'
import UnitIcons from './../../../../shared/components/UnitIcons';

import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';

import ExportIcon from 'mdi-react/FilePdfBoxIcon';

import { FreezeTableBuildings, FreezeTableUnits } from './components/FreezeTables';
import { BuildingGridView, UnitsGridView } from './components/GridViews';

import { BuildingAnalysisView, UnitsAnalysisView } from './components/AnalysisViews';
import Fab from '@material-ui/core/Fab';

import { withOktaAuth } from '@okta/okta-react';

import SingleUnitShare from '../../../SingleUnit/components/Share/index';
import SingleBuildingShare from '../../../SingleBuilding/components/Share/index';
import BuildingProfile from './components/BuildingProfile';
import BuildingStats from './components/BuildingStats';
import BuildingMatrix from './components/BuildingMatrix';
import CompMatrix from './components/CompMatrix';

import { Publisher } from './../../../../shared/pubsub/publisher.js';

import UnitMix from './components/analysis/UnitMix';
import UnitBreakdown from './components/analysis/UnitBreakdown';
import Map from './components/Map';
import StatBox from './components/analysis/StatBox';
import Borough from './components/analysis/Borough';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_validUnitSources,
	propertyExportFL,
	pipelineCohortQuery,
	mpndCohortQuery,
	resaleCohortQuery
} from '../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../shared/components/auth/sso/ssoutils';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


	  
import './custom.scss';
const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;


const settings = {
	  dots: false,
	  infinite: false,
	  speed: 500,
	  autoplay: false,
	  swipeTodiv: true,
	  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
	  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
	  responsive: [
		{ breakpoint: 768, settings: { divsToShow: 1, divsToScroll: 1 } },
		{ breakpoint: 992, settings: { divsToShow: 2, divsToScroll: 2 } },
		{ breakpoint: 1200, settings: { divsToShow: 3, divsToScroll: 3 } },
		{ breakpoint: 1536, settings: { divsToShow: 4, divsToScroll: 4 } },
		{ breakpoint: 100000, settings: { divsToShow: 4, divsToScroll: 4} },
	  ],
  };	

const checkElement = async selector => {
	while ( document.querySelector(selector) === null) {
	  await new Promise( resolve =>  requestAnimationFrame(resolve) )
	}
	return document.querySelector(selector); 
};

const ellimanStatus = (buildingRes) => {
	let status = "In Sales";
	if(buildingRes.building_status == "resale" && buildingRes.inventory_resale > 0){
		status = "Resales";
	}else if(buildingRes.building_status == "resale" && buildingRes.inventory_resale == 0){
		status = "Sold Out";
	}else if(buildingRes.first_closing_date && parseInt(moment(buildingRes.first_closing_date).format('YYYY')) > 1990 && buildingRes.inventory_resale > 0){
		status = "Closings/Resales";
	}else if(buildingRes.first_closing_date && parseInt(moment(buildingRes.first_closing_date).format('YYYY')) > 1990){
		status = "Closings";
	}
	
	return status;
}

const statsQ = '(offering_verified_status:active AND source_organizations:(CDEC NYAG ADEC) AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"")'
const priceField =  "last_listed_sale_price_all";

const matrixFacet =  { "has_bed" : {
	"type": "query",
	"q": `bedrooms:[0 TO *] AND baths:[1 TO *]`,
	"facet": {
		"unit_mix": {
			"type" : "terms",
			"field" : "bedrooms",
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,0,50,100)",
				
				"non_zero_price": {
					"type": "query",
					"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
					"facet": {
						"avg_unit_size" : "avg(sq_ft)",
						"med_unit_size": "percentile(sq_ft,25,50,75)",
						"avg_fees" : "avg(offering_monthly_fees)",
						"med_fees": "percentile(offering_monthly_fees,25,50,75)",
						"med_price": `percentile(${priceField},25,50,75)`,
						"avg_taxes" : "avg(offering_monthly_taxes)",
						"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
						"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
						"min_price": `min(${priceField})`,
						"max_price": `max(${priceField})`,
						"sum_price" : `sum(${priceField})`,
						"sum_sq_ft" : `sum(sq_ft)`,
						"sum_price" : `sum(${priceField})`,
						"sum_sq_ft" : `sum(sq_ft)`,
						"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
					},
				},
				"sold_price": {
						 "type": "query",
						 "q": `last_sold_price:[10000 TO *] AND sq_ft:[100 TO *]`,
						 "facet": {
							 "avg_unit_size" : "avg(sq_ft)",
							 "med_unit_size": "percentile(sq_ft,25,50,75)",
							 "avg_fees" : "avg(offering_monthly_fees)",
							 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
							 "avg_taxes" : "avg(offering_monthly_taxes)",
							 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
							 "med_price": `percentile(last_sold_price,25,50,75)`,
							 "avg_ppsf": `avg(div(last_sold_price, sq_ft))`,
							 "avg_price": `min(last_sold_price)`,
							 "min_price": `min(last_sold_price)`,
							 "max_price": `max(last_sold_price)`,
							 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
							 "sum_price" : "sum(last_sold_price)",
							 "sum_sq_ft" : "sum(sq_ft)"
				   },
				 },
				 "contract_price": {
						 "type": "query",
						 "q": `offering_status:"in contract" AND last_listed_sale_price_all:[10000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
						 "facet": {
							 "avg_unit_size" : "avg(sq_ft)",
							 "med_unit_size": "percentile(sq_ft,25,50,75)",
							 "avg_fees" : "avg(offering_monthly_fees)",
							 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
							 "avg_taxes" : "avg(offering_monthly_taxes)",
							 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
							 "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
							 "avg_price": `min(last_listed_sale_price_all)`,
							 "min_price": `min(last_listed_sale_price_all)`,
							 "max_price": `max(last_listed_sale_price_all)`,
							 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
							 "sum_price" : "sum(last_listed_sale_price_all)",
							 "sum_sq_ft" : "sum(sq_ft)"
				   },
				 },
				"by_status" : {
					"type" : "terms",
					"field" : "offering_status"
				},
				
			}
		},
		"non_zero_price": {
			"type": "query",
			"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,25,50,75)",
				"avg_fees" : "avg(offering_monthly_fees)",
				"med_fees": "percentile(offering_monthly_fees,25,50,75)",
				"avg_taxes" : "avg(offering_monthly_taxes)",
				"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
				"med_price": `percentile(${priceField},25,50,75)`,
				"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
				"min_price": `min(${priceField})`,
				"max_price": `max(${priceField})`,
				"sum_price" : `sum(${priceField})`,
				"sum_sq_ft" : `sum(sq_ft)`,
				"sum_price" : `sum(${priceField})`,
				"sum_sq_ft" : `sum(sq_ft)`,
				"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
			},
			
		},
		"sold_price": {
				 "type": "query",
				 "q": `last_sold_price:[10000 TO *] AND sq_ft:[100 TO *]`,
				 "facet": {
					 "avg_unit_size" : "avg(sq_ft)",
					 "med_unit_size": "percentile(sq_ft,25,50,75)",
					 "avg_fees" : "avg(offering_monthly_fees)",
					 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
					 "avg_taxes" : "avg(offering_monthly_taxes)",
					 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
					 "med_price": `percentile(last_sold_price,25,50,75)`,
					 "avg_ppsf": `avg(div(last_sold_price, sq_ft))`,
					 "avg_price": `min(last_sold_price)`,
					 "min_price": `min(last_sold_price)`,
					 "max_price": `max(last_sold_price)`,
					 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
					 "sum_price" : "sum(last_sold_price)",
					 "sum_sq_ft" : "sum(sq_ft)"
		   },
		 },
		 "by_status" : {
			 "type" : "terms",
			 "field" : "offering_status"
		 },
	}
},
"last_12_signed" : {
	"type": "query",
	"q": `bedrooms:[0 TO *] AND baths:[1 TO *] AND last_contract_date_formatted:[NOW-24MONTHS TO NOW]`,
	"facet": {
		"unit_mix": {
			"type" : "terms",
			"field" : "bedrooms",
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,0,50,100)",
				
				"non_zero_price": {
					"type": "query",
					"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
					"facet": {
						"avg_unit_size" : "avg(sq_ft)",
						"med_unit_size": "percentile(sq_ft,25,50,75)",
						"avg_fees" : "avg(offering_monthly_fees)",
						"med_fees": "percentile(offering_monthly_fees,25,50,75)",
						"avg_taxes" : "avg(offering_monthly_taxes)",
						"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
						"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
						"min_price": `min(${priceField})`,
						"max_price": `max(${priceField})`,
						"sum_price" : `sum(${priceField})`,
						"sum_sq_ft" : `sum(sq_ft)`,
						"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
					},
				},
				"by_status" : {
					"type" : "terms",
					"field" : "offering_status"
				},
				
			}
		},
		"non_zero_price": {
			"type": "query",
			"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,25,50,75)",
				"avg_fees" : "avg(offering_monthly_fees)",
				"med_fees": "percentile(offering_monthly_fees,25,50,75)",
				"avg_taxes" : "avg(offering_monthly_taxes)",
				"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
				"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
				"min_price": `min(${priceField})`,
				"max_price": `max(${priceField})`,
				"sum_price" : `sum(${priceField})`,
				"sum_sq_ft" : `sum(sq_ft)`,
				"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
			},
		},
	}
},
"on_the_market" : {
	"type": "query",
	"q": `bedrooms:[0 TO *] AND baths:[1 TO *] AND current_status:active`,
	"facet": {
		"unit_mix": {
			"type" : "terms",
			"field" : "bedrooms",
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,0,50,100)",
				
				"non_zero_price": {
					"type": "query",
					"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
					"facet": {
						"avg_unit_size" : "avg(sq_ft)",
						"med_unit_size": "percentile(sq_ft,25,50,75)",
						"avg_fees" : "avg(offering_monthly_fees)",
						"med_fees": "percentile(offering_monthly_fees,25,50,75)",
						"avg_taxes" : "avg(offering_monthly_taxes)",
						"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
						"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
						"min_price": `min(${priceField})`,
						"max_price": `max(${priceField})`,
						"sum_price" : `sum(${priceField})`,
						"sum_sq_ft" : `sum(sq_ft)`,
						"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
					},
				},
				"by_status" : {
					"type" : "terms",
					"field" : "current_status"
				},
				
			}
		},
		"non_zero_price": {
			"type": "query",
			"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,25,50,75)",
				"avg_fees" : "avg(offering_monthly_fees)",
				"med_fees": "percentile(offering_monthly_fees,25,50,75)",
				"avg_taxes" : "avg(offering_monthly_taxes)",
				"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
				"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
				"min_price": `min(${priceField})`,
				"max_price": `max(${priceField})`,
				"sum_price" : `sum(${priceField})`,
				"sum_sq_ft" : `sum(sq_ft)`,
				"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
			},
		},
	}
},
"shadow" : {
	"type": "query",
	"q": `bedrooms:[0 TO *] AND baths:[1 TO *] AND current_status:(transfer shadow) AND -offering_construction:conversion AND property_status:sponsor`,
	"facet": {
		"unit_mix": {
			"type" : "terms",
			"field" : "bedrooms",
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,0,50,100)",
				
				"non_zero_price": {
					"type": "query",
					"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
					"facet": {
						"avg_unit_size" : "avg(sq_ft)",
						"med_unit_size": "percentile(sq_ft,25,50,75)",
						"avg_fees" : "avg(offering_monthly_fees)",
						"med_fees": "percentile(offering_monthly_fees,25,50,75)",
						"avg_taxes" : "avg(offering_monthly_taxes)",
						"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
						"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
						"min_price": `min(${priceField})`,
						"max_price": `max(${priceField})`,
						"sum_price" : `sum(${priceField})`,
						"sum_sq_ft" : `sum(sq_ft)`,
						"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
					},
				},
				"by_status" : {
					"type" : "terms",
					"field" : "offering_status"
				},
				
			}
		},
		"non_zero_price": {
			"type": "query",
			"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
			"facet": {
				"avg_unit_size" : "avg(sq_ft)",
				"med_unit_size": "percentile(sq_ft,25,50,75)",
				"avg_fees" : "avg(offering_monthly_fees)",
				"med_fees": "percentile(offering_monthly_fees,25,50,75)",
				"avg_taxes" : "avg(offering_monthly_taxes)",
				"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
				"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
				"min_price": `min(${priceField})`,
				"max_price": `max(${priceField})`,
				"sum_price" : `sum(${priceField})`,
				"sum_sq_ft" : `sum(sq_ft)`,
				"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
			},
		},
	}
}};
  
class CompReportShare extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  activeTab:  'buildings',      
	  fetchInProgress: false,
	  buildingRes: null,
	  unitRes: null,
	  userRes:null,
	  listingRes: null,
	  buildingsFetchInProgress: false,
	  modal: false,
	  modalShareData:false,
	  shareType:false,
	  unitStats: false,
	  buildingStats: false,
	  statsRes: null,
	  isPrint: false,
	  divNumber: 0,
	  marketStats: null,
	  exportProgress: false,
	  contentLoaded: false
	};
	this.buildingSlug = false;
	this.urlState = {};
	this._mapLoaded = this._mapLoaded.bind(this);
	this.printDocument = this.printDocument.bind(this);
  }
  
  componentDidMount() {
	  
	const { sharePageData } = this.props;
	
	if(sharePageData){
		this.buildingSlug = sharePageData.building_key;
		
		this.urlState = sharePageData.custom_data ? sharePageData.custom_data : {};
		if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.buildings && sharePageData.custom_data.group.group_data.buildings.length>0)){
			this.setState({activeTab:"units"})
		}
		
	}
	
	this._getBuildings();
	this._getUnits();
	this._getUserData();
	this._getMarketAnalytics();
	this._getUnitAnalytics();
	const isPrint = this.props.location && this.props.location.search && this.props.location.search.indexOf("?exportMode=true")>=0;
	
	 this.setState({isPrint:true})
		
	 
	Publisher.publish(`track-mp.shareView.collection`, {collection: sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true });   
	
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {	
	 const { sharePageData } = this.props;
	 const { statsRes, userRes, buildingRes, unitRes, isPrinted} = this.state;
	 if(!prevState.buildingRes && this.state.buildingRes){
		Publisher.publish(`track-mp.shareView.collection`, {collection: sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true }); 
	 }	
	 
	
	 
  }
  
  _mapLoaded(loaded){
	  this.setState({contentLoaded:loaded});
  }


  
  _getUserData = (key) => {
	 // console.log('_getBuildingData called', this.state)
	  const { sharePageData, updateShareData } = this.props;
	  if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
	  }
			
	  let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  
	  let userKey = sharePageData && sharePageData.user_key ? sharePageData.user_key : key;
	  
	  if(!userKey && sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.user_key){
		  userKey = sharePageData.custom_data.group.user_key;
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${userKey}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  
		  if(res && res.errorCode && res.errorCode == "E0000007"){
			  _getUser({id:sharePageData && sharePageData.user_key ? sharePageData.user_key : key}).then(ssoUser  => {
				  console.log(ssoUser)
				  if(ssoUser){
					  this.setState({
						  userRes:ssoUser,
						  fetchInProgress: false
					  })
					  
					  updateShareData(Object.assign({}, sharePageData, {user:ssoUser} ))
				  }
				  
			  })
		  }else{
				this.setState({
					userRes:res.profile,
					fetchInProgress: false
				})
				
				updateShareData(Object.assign({}, sharePageData, {user:res.profile} ))
		  }
		  

	  })
	  .catch(error => {
		console.log("_getUserData error ", error);
		this.setState({
		  fetchInProgress: false
		})
	  });
	}

  
  _getBuildings = () => {
		 const { sharePageData } = this.props;
		 const { user } = sharePageData;
		  if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.buildings && sharePageData.custom_data.group.group_data.buildings.length>0)){
				this.setState({ buildingRes : [] });
				return false
			}
		  const isPublic = sharePageData.custom_data.group && sharePageData.custom_data.group.group_access && sharePageData.custom_data.group.group_access == "public"; 
		  if (!this.state.buildingsFetchInProgress) {
			  this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		  }
		  
		  
		  var q = "key:("+sharePageData.custom_data.group.group_data.buildings.join(" ")+")";
		  
		  var fq = [
			  "deleted:false"		
		  ];
  
  //console.log(user && user.role )
		  let axiosData = {
			  url: apiURL + "/we3-buildings?cache=60",
			  method: "post",
			  query: {
				  q: q,
				  fq: fq,
				  fl: buildingFL,
				  wt: "json",
				  sort: "building_status desc, inventory_stats_listings_average_ppsf desc, inventory_pct_unsold asc",
				  rows: user && user.role && user.role.indexOf("admin")>=0 || isPublic ? 999 : 25
			  }
		  };
		  
		  if(true || sharePageData.custom_data && sharePageData.custom_data.show_stats){
			  axiosData.query["json.facet"] = {
					  "inventory_total" : "sum(inventory_total)",
					"inventory_unsold" : "sum(inventory_unsold)",
					"inventory_active" : "sum(inventory_active)",
					"avg_ppsf" : "sum(product(inventory_stats_listings_average_ppsf, inventory_total))",
					"min_price" : "min(inventory_stats_listings_low_price)",
					"max_price" : "max(inventory_stats_listings_high_price)",
					"listing_price" : {
						"type" : "query",
						"q" : "inventory_stats_listings_low_price:[250000 TO *]",
						"facet" : {
							"min_price" : "min(inventory_stats_listings_low_price)",
						}
					},
					"status" : {
						"type" : "terms",
						"field" : "building_status"
					}
			 }
		  }
		  
  
		  let formattedData = _axiosCall(axiosData)
			  .then(res => {
				  //this._setBuyersList(res);
				  if(res && res.docs){
					  
					  let propCalls = [];
					  
					  res.docs.map(bldg => {propCalls.push(this._getBuildingOPData(bldg))});
					  
					  Promise.all(propCalls).then(allRes => {
						  
						  let goatCalls = [];
						  allRes.map(bldg => goatCalls.push(this._getGoatData(bldg)));
						  
						 
						  Promise.all(goatCalls).then(allResWGoat => {
							/// console.log(allResWGoat);
						  	this.setState({ buildingRes : allResWGoat, buildingStats: res.facets, fetchInProgress : false, buildingsFetchInProgress: false });
						  });
					  })
					 
				  }else{
					  this.setState({ buildingRes : [], buildingStats:{}, fetchInProgress : false, buildingsFetchInProgress: false });
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
			  
  
		  // console.log(formattedData)
		  return formattedData;
	};
	_getGoatData = async (buildingRes) => {
			
		 
		  
	
		  let axiosData = {
			  url: apiURL + `goat?input=${buildingRes.display_full_street_address}, ${buildingRes.borough}`,
			  method: "get"
		  };
	
		  let formattedData = await _axiosCall(axiosData)
			buildingRes["goatData"] = formattedData && formattedData.results && formattedData.results[0] ? formattedData.results[0].response : {};
			return buildingRes;
	};
	_getBuildingOPData = async (buildingRes) => {
		  const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		  const addressQ = buildingRes.alternate_addresses.join('" OR "');
		  
		  let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}"))`
		  
		  let statsQ = '';
		  
		  if(['active'].indexOf(buildingRes.offering_verified_status)>=0){
			  statsQ = `((offering_verified_status:active AND source_organizations:(CDEC NYAG ADEC) AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"") OR verification_source:(user-verified-valid))`
			   q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}))`;		
		  }else if(buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970){
			  let unitSource = "source_organizations:(CDEC ADEC)";
				if(buildingRes.cdec_units_properties < (buildingRes.inventory_total * .8)){
				   unitSource = `(source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}))`
				}
			   statsQ = `(${unitSource} AND property_type:(*condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial* AND -property_type:*multi*)`;
			   q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;
		  }else if(['coming soon'].indexOf(buildingRes.offering_verified_status)>=0){
			  statsQ = `(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"")`;
			   q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
		  }else{
			  q += ` AND (source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
		  }
		  const isResale = ['resale'].indexOf(buildingRes.building_status)>=0;
		  
		  if(statsQ.length>0){
			  statsQ = 'AND ' + statsQ;
		  }
		  
		  this.q = q;
		  const priceField = isResale ? "last_sold_price" : "last_listed_sale_price_all";
		  let axiosData = {
			  url: apiURL + "we3-properties?cache=15",
			  method: "post",
			  query: {
				  q: q,
				 fq: ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""', "bedrooms:[0 TO *]", "property_type:(*residential*condo* condo \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*)", "last_listed_sale_price_all:[-1 TO -1] OR last_listed_sale_price_all:[100000 TO *] OR last_sold_price:[100000 TO *]"],
				  fl: [...propertyExportFL, ...["floor", "line", "offering_submitted_date", "div(last_sold_price, sq_ft)", "div(last_listed_sale_price_all, sq_ft)", "div(last_contract_price, sq_ft)", "offering_exterior_sq_ft", "times_exchanged", "last_exchanged_date", "offering_monthly_carrying_charges"]],
				  sort: 'times_sold asc, bedrooms asc, last_listed_sale_price_all asc',
				  wt: "json",
				  rows: 999,
				  "json.facet": {
					  "avg_price": `avg(${priceField})`,
					  "avg_sq_ft": "avg(sq_ft)",
					  "low_bed": "min(bedrooms)",
					  "sponsor_matrix" : {
						  "type": "query",
						  "q": `times_sold:[* TO 1] AND -(times_sold:[1 TO *] AND current_status:(active *contact* *pending*))`,
						  "facet": matrixFacet
					  },
					  "resale_matrix" : {
						  "type": "query",
						  "q": `(times_sold:[1 TO *] AND current_status:(active *contact* *pending*)) OR times_sold:[2 TO *]`,
						  "facet": matrixFacet
					  },
					  "has_bed" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *]`,
						  "facet": {
							  "non_zero_price": {
								  "type": "query",
								  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,25,50,75)",
									  "avg_fees" : "avg(offering_monthly_fees)",
									  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
									  "avg_taxes" : "avg(offering_monthly_taxes)",
									  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
									  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
									  "med_price": "percentile(last_listed_sale_price_all,25,50,75)",
									  "min_price": `min(last_listed_sale_price_all)`,
									  "max_price": `max(last_listed_sale_price_all)`,
									  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
									  "sum_price" : "sum(last_listed_sale_price_all)",
									  "sum_sq_ft" : "sum(sq_ft)"
								  },
								  
							  },
							  "contract_price": {
										 "type": "query",
										 "q": `offering_status:("in contract" "not_yet_recorded") AND last_listed_sale_price_all:[10000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										 "facet": {
											 "avg_unit_size" : "avg(sq_ft)",
											 "med_unit_size": "percentile(sq_ft,25,50,75)",
											 "avg_fees" : "avg(offering_monthly_fees)",
											 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											 "avg_taxes" : "avg(offering_monthly_taxes)",
											 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											 "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											 "avg_price": `min(last_listed_sale_price_all)`,
											 "min_price": `min(last_listed_sale_price_all)`,
											 "max_price": `max(last_listed_sale_price_all)`,
											 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											 "sum_price" : "sum(last_listed_sale_price_all)",
											 "sum_sq_ft" : "sum(sq_ft)"
								   },
								 },
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "med_price": "percentile(last_listed_sale_price_all,25,50,75)",
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)",
											  "med_price": "percentile(last_listed_sale_price_all,25,50,75)",
										  },
									  },
									  "sold_price": {
											 "type": "query",
											 "q": `last_sold_price:[10000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
											 "facet": {
												 "avg_unit_size" : "avg(sq_ft)",
												 "med_unit_size": "percentile(sq_ft,25,50,75)",
												 "avg_fees" : "avg(offering_monthly_fees)",
												 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
												 "avg_taxes" : "avg(offering_monthly_taxes)",
												 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
												 "avg_ppsf": `avg(div(last_sold_price, sq_ft))`,
												 "med_sold_price": "percentile(last_sold_price,25,50,75)",
												 "avg_price": `min(last_sold_price)`,
												 "min_price": `min(last_sold_price)`,
												 "max_price": `max(last_sold_price)`,
												 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
												 "sum_price" : "sum(last_sold_price)",
												 "sum_sq_ft" : "sum(sq_ft)"
									   },
									 },
									"contract_price": {
											 "type": "query",
											 "q": `offering_status:("in contract" "not_yet_recorded") AND last_listed_sale_price_all:[10000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
											 "facet": {
												 "avg_unit_size" : "avg(sq_ft)",
												 "med_unit_size": "percentile(sq_ft,25,50,75)",
												 "avg_fees" : "avg(offering_monthly_fees)",
												 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
												 "avg_taxes" : "avg(offering_monthly_taxes)",
												 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
												 "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
												 "avg_price": `min(last_listed_sale_price_all)`,
												 "min_price": `min(last_listed_sale_price_all)`,
												 "max_price": `max(last_listed_sale_price_all)`,
												 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
												 "sum_price" : "sum(last_listed_sale_price_all)",
												 "sum_sq_ft" : "sum(sq_ft)"
									   },
									 },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  },
							  "four_more" :{
								  "type" : "query",
								  "q": "bedrooms:[4 TO *]",
								  "facet" : {
									  "med_price": "percentile(last_listed_sale_price_all,25,50,75)",
									  "med_sold_price": `percentile(last_sold_price,25,50,75)`,
								  }
								  
							  },
							  "by_status" : {
									"type" : "terms",
									"field" : "offering_status"
								},
								"sold_price": {
										 "type": "query",
										 "q": `last_sold_price:[10000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										 "facet": {
											 "avg_unit_size" : "avg(sq_ft)",
											 "med_unit_size": "percentile(sq_ft,25,50,75)",
											 "avg_fees" : "avg(offering_monthly_fees)",
											 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											 "avg_taxes" : "avg(offering_monthly_taxes)",
											 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											 "avg_ppsf": `avg(div(last_sold_price, sq_ft))`,
											 "avg_price": `min(last_sold_price)`,
											 "min_price": `min(last_sold_price)`,
											 "max_price": `max(last_sold_price)`,
											 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											 "sum_price" : "sum(last_sold_price)",
											 "sum_sq_ft" : "sum(sq_ft)"
								   },
								 },
						  }
					  },
					
					  "total_sold": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*) ${statsQ}`,
						  "facet": {
							  "low_price": "min(last_listed_sale_price_all)",
							  "high_price": "max(last_listed_sale_price_all)",
							  "average_price": "avg(last_listed_sale_price_all)",
							  "average_sq_ft": "avg(last_listed_sale_price_all)",
							  "sum_price": "sum(last_listed_sale_price_all)",							
							  "low_sq_ft": "min(sq_ft)",
							  "high_sq_ft": "max(sq_ft)",
							  "low_bed": "min(bedrooms)",
							  "high_bed": "max(bedrooms)",
							  "sum_sq_ft": "sum(sq_ft)"
							  
						  }
					  },
					"total_volume": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[1 TO *] ${statsQ}`,
						  "facet": {
							  "low_price": "min(last_listed_sale_price_all)",
							  "high_price": "max(last_listed_sale_price_all)",
							  "average_price": "avg(last_listed_sale_price_all)",
							  "average_sq_ft": "avg(last_listed_sale_price_all)",
							  "sum_price": "sum(last_listed_sale_price_all)",							
							  "low_sq_ft": "min(sq_ft)",
							  "high_sq_ft": "max(sq_ft)",
							  "low_bed": "min(bedrooms)",
							  "high_bed": "max(bedrooms)",
							  "sum_sq_ft": "sum(sq_ft)",
							  "total_closed" : {
								  "type": "query",
									"q": "last_sold_price:[100000 TO *]",
									"facet" : {
										"sum_price" : "sum(last_sold_price)"
									}
							  },
							  "total_not_closed" : {
								  "type": "query",
								  "q": "last_sold_price:[* TO 0]",
								  "facet" : {
									  "sum_price" : "sum(last_listed_sale_price_all)"
								  }
							  }
							  
						  }
					  },
					  "total_sq_ft": {
						  "type": "query",
						  "q": `sq_ft:[1 TO *]  ${statsQ}`,
						  "facet" : {
									  "avg_sq_ft": "avg(sq_ft)",
									  "sum_sq_ft": "sum(sq_ft)"
								  }
						  
					  },
					  "last_12_closed" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND last_sold_date_formatted:[NOW-12MONTHS TO NOW] AND last_contract_date_formatted:[NOW-12MONTHS TO NOW]`,
						  "facet": {
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(${priceField}, sq_ft))`,
											  "min_price": `min(${priceField})`,
											  "max_price": `max(${priceField})`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "last_12_signed" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND last_contract_date_formatted:[NOW-24MONTHS TO NOW]`,
						  "facet": {
							  "non_zero_price": {
								  "type": "query",
								  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,25,50,75)",
									  "avg_fees" : "avg(offering_monthly_fees)",
									  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
									  "avg_taxes" : "avg(offering_monthly_taxes)",
									  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
									  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
									  "min_price": `min(last_listed_sale_price_all)`,
									  "max_price": `max(last_listed_sale_price_all)`,
									  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
									  "sum_price" : "sum(last_listed_sale_price_all)",
									  "sum_sq_ft" : "sum(sq_ft)"
								  },
							  },
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)"
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "on_the_market" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND current_status:active`,
						  "facet": {
							  "non_zero_price": {
								  "type": "query",
								  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,25,50,75)",
									  "avg_fees" : "avg(offering_monthly_fees)",
									  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
									  "avg_taxes" : "avg(offering_monthly_taxes)",
									  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
									  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
									  "min_price": `min(last_listed_sale_price_all)`,
									  "max_price": `max(last_listed_sale_price_all)`,
									  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
									  "sum_price" : "sum(last_listed_sale_price_all)",
									  "sum_sq_ft" : "sum(sq_ft)"
								  },
							  },
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)"
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "shadow" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND -current_status:active AND -current_status:*contract* AND -offering_construction:conversion AND property_status:sponsor`,
						  "facet": {
							  "non_zero_price": {
									"type": "query",
									"q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
									"facet": {
										"avg_unit_size" : "avg(sq_ft)",
										"med_unit_size": "percentile(sq_ft,25,50,75)",
										"avg_fees" : "avg(offering_monthly_fees)",
										"med_fees": "percentile(offering_monthly_fees,25,50,75)",
										"avg_taxes" : "avg(offering_monthly_taxes)",
										"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
										"avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
										"min_price": `min(last_listed_sale_price_all)`,
										"max_price": `max(last_listed_sale_price_all)`,
										"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
										"sum_price" : "sum(last_listed_sale_price_all)",
										"sum_sq_ft" : "sum(sq_ft)"
									},
								},
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)"
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "tax_ppsf":{
						  "type": "query",
						  "q": `monthly_taxes:[0 TO *] AND sq_ft:[100 TO *]`,
						  "facet": {
							  "avg_ppsf": "avg(div(monthly_taxes, sq_ft))",
						  }
					  },
					  "cc_ppsf":{
						  "type": "query",
						  "q": `monthly_fees:[0 TO *] AND sq_ft:[100 TO *]`,
						  "facet": {
							  "avg_ppsf": "avg(div(monthly_fees, sq_ft))",
						  }
					  }
	
				  }
			  }
		  };
	
		  let formattedData = await _axiosCall(axiosData)
		  
		  
		  
		  let transData = await this._getACRISData(buildingRes);
		  const combinedUnits = [];
		  formattedData.docs.forEach(prop => {
			if(prop.last_sold_date !== undefined && prop.last_sold_date <= 10){
				combinedUnits.push(prop);
			}	  
		  })
		  
		  
		  if(transData){
			  transData.forEach(trns => {
				  
				 let unit = formattedData.docs.filter(prop => prop['address_2_normalized'] == trns['address_2_normalized'])[0];
				 
				 if(unit){
					 let unitFields ={
						  current_status : unit && unit.times_sold >= 1 && unit.current_status ? unit.current_status :  trns.sponsored_sale ? "sold" : "resale",
						  times_sold : trns.sponsored_sale ? 1 : 2,
						  last_sold_date: trns.close_date,
						  last_sold_price: trns.price,
						  last_contract_date: trns.contract_date,
						  last_listed_sale_price_all: unit.last_listed_sale_price_all && unit.last_listed_sale_price_all > 100 ? unit.last_listed_sale_price_all : trns.listed_price,
						  last_listed_sale_date_all: unit.last_listed_sale_date_all && unit.last_listed_sale_date_all > 100 ? unit.last_listed_sale_date_all : trns.listed_date
					  }
					  Object.keys(trns).map(key => {
						  if(trns[key] == -1 ||trns[key] == ""){
							  delete trns[key];
						  }
					  })
					 combinedUnits.push(Object.assign({}, unit, trns, unitFields));		
				 }
						  
			  })
		  }
		  formattedData['docs'] = combinedUnits;
		  buildingRes["property_stats"] = formattedData;
		  buildingRes["display_full_street_address"] = buildingRes["display_full_street_address"].toLowerCase().replace("street", "st").replace("avenue", "ave");
		 // buildingRes["trans_data"] = transData;
	      return buildingRes;
		  // console.log(formattedData)
		  
	  };
	_getMarketAnalytics = () => {
			const { sharePageData } = this.props;
			 const { user } = sharePageData;
			  if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.buildings && sharePageData.custom_data.group.group_data.buildings.length>0)){
					this.setState({ buildingRes : [] });
					return false
				}
			  const isPublic = sharePageData.custom_data.group && sharePageData.custom_data.group.group_access && sharePageData.custom_data.group.group_access == "public"; 
			  if (!this.state.buildingsFetchInProgress) {
				  this.setState(prevState => ({ buildingsFetchInProgress: true, }));
			  }
			
			var q = "((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF) AND -address_2_normalized:\"\") OR building_class:R3)";
			this.q = q;
			const statsQ = '(offering_verified_status:active AND source_organizations:(CDEC NYAG ADEC) AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"")'
			const priceField =  "last_listed_sale_price_all";
		
			let axiosData = {
				url: apiURL + "we3-properties?cache=120",
				method: "post",
				query: {
					q: q,
					fq: ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""',`{!join from=key fromIndex=we3-buildings to=building}(${pipelineCohortQuery}) OR (${mpndCohortQuery}) OR (${resaleCohortQuery})`],
					
					sort: 'floor asc, address_2_normalized asc',
					wt: "json",
					rows: 0,
					"json.facet": {
						"avg_price": `avg(${priceField})`,
						"avg_sq_ft": "avg(sq_ft)",
						"low_bed": "min(bedrooms)",
						"has_bed" : {
							"type": "query",
							"q": `bedrooms:[0 TO *]`,
							"facet": {
								"unit_mix": {
									"type" : "terms",
									"field" : "bedrooms",
									"facet": {
										"avg_unit_size" : "avg(sq_ft)",
										"med_unit_size": "percentile(sq_ft,0,50,100)",
										
										"non_zero_price": {
											"type": "query",
											"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
											"facet": {
												"avg_unit_size" : "avg(sq_ft)",
												"med_unit_size": "percentile(sq_ft,25,50,75)",
												"avg_fees" : "avg(offering_monthly_fees)",
												"med_fees": "percentile(offering_monthly_fees,25,50,75)",
												"avg_taxes" : "avg(offering_monthly_taxes)",
												"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
												"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
												"min_price": `min(${priceField})`,
												"max_price": `max(${priceField})`,
												"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											},
										},
										"by_status" : {
											"type" : "terms",
											"field" : "offering_status"
										},
										
									}
								}
							}
						},
						"non_zero_price": {
							"type": "query",
							"q": `${priceField}:[100000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
							"facet": {
								"low_price": `min(${priceField})`,
								"high_price": `max(${priceField})`,
								"average_price": `avg(${priceField})`,
								"average_sq_ft": `avg(${priceField})`,
								"sum_price": `sum(${priceField})`,
								"low_sq_ft": `min(sq_ft)`,
								"high_sq_ft": `max(sq_ft)`,
								"low_bed": `min(bedrooms)`,
								"high_bed": `max(bedrooms)`,
								
							}
						},
						"total_sold": {
							"type": "query",
							"q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*) ${statsQ}`,
							"facet": {
								"low_price": "min(last_listed_sale_price_all)",
								"high_price": "max(last_listed_sale_price_all)",
								"average_price": "avg(last_listed_sale_price_all)",
								"average_sq_ft": "avg(last_listed_sale_price_all)",
								"sum_price": "sum(last_listed_sale_price_all)",							
								"low_sq_ft": "min(sq_ft)",
								"high_sq_ft": "max(sq_ft)",
								"low_bed": "min(bedrooms)",
								"high_bed": "max(bedrooms)",
								"by_neighborhood" : {
									"type" : "terms",
									"field" : "neighborhood",
									"sort" : "avg_ppsf desc",
									"limit" : 20,
									"facet" : {
										"avg_price": "avg(offering_price)",
										"avg_ppsf": "avg(div(offering_price, sq_ft))",
									}
								},
								
							}
						},
						"total_sellout": {
							"type": "query",
							"q": `last_listed_sale_price_all:[1 TO *]  ${statsQ}`,
							"facet": {
								"low_price": "min(last_listed_sale_price_all)",
								"high_price": "max(last_listed_sale_price_all)",
								"average_price": "avg(last_listed_sale_price_all)",
								"average_sq_ft": "avg(last_listed_sale_price_all)",
								"sum_price": "sum(last_listed_sale_price_all)",
								"sum_sq_ft": "sum(sq_ft)",
								"low_sq_ft": "min(last_listed_sale_price_all)",
								"high_sq_ft": "max(sq_ft)",
								"low_bed": "min(offering_bedrooms)",
								"high_bed": "max(offering_bedrooms)",
								"sold_volume" : {
									"type" : "query",
									"q" : "offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)",
									"facet" : {
										"sum_price": "sum(last_listed_sale_price_all)",
										"sum_sq_ft": "sum(sq_ft)"
									}
								}														
							}
						},
						"total_sq_ft": {
							"type": "query",
							"q": `sq_ft:[1 TO *]  ${statsQ}`,
							"facet" : {
										"avg_sq_ft": "avg(sq_ft)",
										"sum_sq_ft": "sum(sq_ft)"
									}
							
						},
						"first_sold": {
							"type": "query",
							"q": `last_sold_date:[1000 TO *] ${statsQ}`,
							"facet": {
								"first_sold_date" : "min(last_sold_date)"
							},
						},
						"sold_1_yr": {
							"type": "query",
							"q": `last_contract_date_formatted:[NOW-1YEAR TO NOW]`
							
						},
						"sold_3_mo": {
							"type": "query",
							"q": `last_contract_date_formatted:[NOW-3MONTH TO NOW]`
							
						},
						"sold_6_mo": {
							"type": "query",
							"q": `last_contract_date_formatted:[NOW-6MONTH TO NOW]`
							
						},
						"listed_1_yr": {
							"type": "query",
							"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-1, "year").unix()} TO ${moment().endOf('day').unix()}]`
							
						},
						"listed_3_mo": {
							"type": "query",
							"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-3, "month").unix()} TO ${moment().endOf('day').unix()}]`
							
						},
						"listed_6_mo": {
							"type": "query",
							"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-6, "month").unix()} TO ${moment().endOf('day').unix()}]`
							
						},
	
						"by_status" : {
							"type": "query",
							"q": `property_status:sponsor ${statsQ}`,
							"facet": {
								"sponsor": {
									"type" : "terms",
									"field" : "current_status"
								},													
							}	
							
						},
						"resale":{
							"type": "query",
							"q": `-property_status:sponsor ${statsQ}`,
						},
						"active": {
							"type": "query",
							"q": "((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF) AND -address_2_normalized:\"\") OR building_class:R3) AND (current_status:((active) OR (current_status:shadow AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])) OR (current_status:transfer AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])))) AND (property_type:((*residential*condo* condo \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*))) AND property_status:(\"resale\") AND (source_organizations:(NYAG CDEC ADEC) OR verification_source:(user-verified-valid CDEC ADEC NYCDOF) OR current_status:active)",
							"facet": {
								"buildings": "unique(building)",
								"by_borough" : {
									"type" : "terms",
									"field" : "borough",
									"facet" : {
										"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))"
									}
								}
							}
						},
						"unsold": {
							"type": "query",
							"q": "((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF) AND -address_2_normalized:\"\") OR building_class:R3) AND (current_status:((active) OR (current_status:shadow AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])) OR (current_status:transfer AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])))) AND (property_type:((*residential*condo* condo \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*))) AND property_status:(\"sponsor\") AND (source_organizations:(NYAG CDEC ADEC) OR verification_source:(user-verified-valid CDEC ADEC NYCDOF) OR current_status:active)",
							"facet": {
								"buildings": "unique(building)",
								"by_borough" : {
									"type" : "terms",
									"field" : "borough",
									"facet" : {
										"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))"
									}
								}
							}
						},
						"asking_ppsf":{
							"type": "query",
							"q": `last_listed_sale_price_all:[500000 TO 300000000] AND sq_ft:[100 TO *] AND current_status:active`,
							"facet": {
								"sponsor" : {
									"type": "query",
									"q": `property_status:sponsor ${statsQ}`,
									"facet" : {
										"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
										"by_borough" : {
											"type" : "terms",
											"field" : "borough",
											"facet" : {
												"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))"
											}
										}
									}
								},
								"resale" : {
									"type": "query",
									"q": `property_status:resale AND last_listed_sale_listed_date_all:[${moment().endOf('day').add(-24, "month").unix()} TO ${moment().endOf('day').unix()}]`,
									"facet" : {
										"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
										"by_borough" : {
											"type" : "terms",
											"field" : "borough",
											"facet" : {
												"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))"
											}
										}
									}
								}
								
							}
						},	
					}
				}
			};
	
			let formattedData = _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					//console.log(res.docs);
					
					this.setState({marketStats: res.facets});
				})
				.catch(error => {
					console.log("error: " + error);
				});
	
			// console.log(formattedData)
			return formattedData;
	};
	
	_getUnitAnalytics = () => {
			const { sharePageData } = this.props;
			 const { user } = sharePageData;
			  if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.buildings && sharePageData.custom_data.group.group_data.buildings.length>0)){
					this.setState({ buildingRes : [] });
					return false
				}
			  const isPublic = sharePageData.custom_data.group && sharePageData.custom_data.group.group_access && sharePageData.custom_data.group.group_access == "public"; 
			  if (!this.state.buildingsFetchInProgress) {
				  this.setState(prevState => ({ buildingsFetchInProgress: true, }));
			  }
			
			var q = "building:("+sharePageData.custom_data.group.group_data.buildings.join(" ")+")";
			this.q = q;
			
		
			let axiosData = {
				url: apiURL + "we3-properties?cache=refresh",
				method: "post",
				query: {
					q: q,
					fq: ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""', "bedrooms:[0 TO *]", "property_type:(*residential*condo* condo \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*)", "last_listed_sale_price_all:[-1 TO -1] OR last_listed_sale_price_all:[100000 TO *] OR last_sold_price:[100000 TO *]"],
					fl: [...propertyExportFL, ...["floor", "line", "offering_submitted_date", "div(last_sold_price, sq_ft)", "div(last_listed_sale_price_all, sq_ft)", "div(last_contract_price, sq_ft)", "offering_exterior_sq_ft", "times_exchanged", "last_exchanged_date"]],
					sort: 'floor asc, address_2_normalized asc',
					wt: "json",
					rows: 0,
					"json.facet": {
						"avg_price": `avg(${priceField})`,
						"avg_sq_ft": "avg(sq_ft)",
						"low_bed": "min(bedrooms)",
						"sponsor_matrix" : {
							"type": "query",
							"q": `times_sold:[* TO 1] AND -(times_sold:[1 TO *] AND current_status:(active *contact* *pending*))`,
							"facet": matrixFacet
						},
						"resale_matrix" : {
							"type": "query",
							"q": `(times_sold:[1 TO *] AND current_status:(active *contact* *pending*)) OR times_sold:[2 TO *]`,
							"facet": matrixFacet
						},
						"last_12_closed" : {
							"type": "query",
							"q": `bedrooms:[0 TO *] AND last_sold_date_formatted:[NOW-12MONTHS TO NOW] AND last_contract_date_formatted:[NOW-12MONTHS TO NOW]`,
							"facet": {
								"unit_mix": {
									"type" : "terms",
									"field" : "bedrooms",
									"facet": {
										"avg_unit_size" : "avg(sq_ft)",
										"med_unit_size": "percentile(sq_ft,0,50,100)",
										
										"non_zero_price": {
											"type": "query",
											"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
											"facet": {
												"avg_unit_size" : "avg(sq_ft)",
												"med_unit_size": "percentile(sq_ft,25,50,75)",
												"avg_fees" : "avg(offering_monthly_fees)",
												"med_fees": "percentile(offering_monthly_fees,25,50,75)",
												"avg_taxes" : "avg(offering_monthly_taxes)",
												"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
												"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
												"min_price": `min(${priceField})`,
												"max_price": `max(${priceField})`,
												"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											},
										},
										"by_status" : {
											"type" : "terms",
											"field" : "offering_status"
										},
										
									}
								}
							}
						},
						
						"non_zero_price": {
							"type": "query",
							"q": `${priceField}:[100000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
							"facet": {
								"low_price": `min(${priceField})`,
								"high_price": `max(${priceField})`,
								"average_price": `avg(${priceField})`,
								"average_sq_ft": `avg(${priceField})`,
								"sum_price": `sum(${priceField})`,
								"low_sq_ft": `min(sq_ft)`,
								"high_sq_ft": `max(sq_ft)`,
								"low_bed": `min(bedrooms)`,
								"high_bed": `max(bedrooms)`,
								
							}
						},
						"total_sold": {
							"type": "query",
							"q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*) ${statsQ}`,
							"facet": {
								"low_price": "min(last_listed_sale_price_all)",
								"high_price": "max(last_listed_sale_price_all)",
								"average_price": "avg(last_listed_sale_price_all)",
								"average_sq_ft": "avg(last_listed_sale_price_all)",
								"sum_price": "sum(last_listed_sale_price_all)",							
								"low_sq_ft": "min(sq_ft)",
								"high_sq_ft": "max(sq_ft)",
								"low_bed": "min(bedrooms)",
								"high_bed": "max(bedrooms)",
								"by_neighborhood" : {
									"type" : "terms",
									"field" : "neighborhood",
									"sort" : "avg_ppsf desc",
									"limit" : 20,
									"facet" : {
										"avg_price": "avg(offering_price)",
										"avg_ppsf": "avg(div(offering_price, sq_ft))",
									}
								},
								
							}
						},
						"total_sellout": {
							"type": "query",
							"q": `last_listed_sale_price_all:[1 TO *]  ${statsQ}`,
							"facet": {
								"low_price": "min(last_listed_sale_price_all)",
								"high_price": "max(last_listed_sale_price_all)",
								"average_price": "avg(last_listed_sale_price_all)",
								"average_sq_ft": "avg(last_listed_sale_price_all)",
								"sum_price": "sum(last_listed_sale_price_all)",
								"sum_sq_ft": "sum(sq_ft)",
								"low_sq_ft": "min(last_listed_sale_price_all)",
								"high_sq_ft": "max(sq_ft)",
								"low_bed": "min(offering_bedrooms)",
								"high_bed": "max(offering_bedrooms)",
								"sold_volume" : {
									"type" : "query",
									"q" : "offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)",
									"facet" : {
										"sum_price": "sum(last_listed_sale_price_all)",
										"sum_sq_ft": "sum(sq_ft)"
									}
								}														
							}
						},
						"total_sq_ft": {
							"type": "query",
							"q": `sq_ft:[1 TO *]  ${statsQ}`,
							"facet" : {
										"avg_sq_ft": "avg(sq_ft)",
										"sum_sq_ft": "sum(sq_ft)"
									}
							
						},
						"first_sold": {
							"type": "query",
							"q": `last_sold_date:[1000 TO *] ${statsQ}`,
							"facet": {
								"first_sold_date" : "min(last_sold_date)"
							},
						},
						"sold_1_yr": {
							"type": "query",
							"q": `last_contract_date_formatted:[NOW-1YEAR TO NOW]`
							
						},
						"sold_3_mo": {
							"type": "query",
							"q": `last_contract_date_formatted:[NOW-3MONTH TO NOW]`
							
						},
						"sold_6_mo": {
							"type": "query",
							"q": `last_contract_date_formatted:[NOW-6MONTH TO NOW]`
							
						},
						"listed_1_yr": {
							"type": "query",
							"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-1, "year").unix()} TO ${moment().endOf('day').unix()}]`
							
						},
						"listed_3_mo": {
							"type": "query",
							"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-3, "month").unix()} TO ${moment().endOf('day').unix()}]`
							
						},
						"listed_6_mo": {
							"type": "query",
							"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-6, "month").unix()} TO ${moment().endOf('day').unix()}]`
							
						},
	
						"by_status" : {
							"type": "query",
							"q": `property_status:sponsor ${statsQ}`,
							"facet": {
								"sponsor": {
									"type" : "terms",
									"field" : "current_status"
								},													
							}	
							
						},
						"resale":{
							"type": "query",
							"q": `-property_status:sponsor ${statsQ}`,
						},
						"ppsf":{
							"type": "query",
							"q": `last_listed_sale_price_all:[500000 TO 300000000] AND sq_ft:[100 TO *]`,
							"facet": {
								"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
							}
						},
						
						"original_owner" : {
							"type": "query",
							"q": `times_exchanged:[0 TO *] AND owner:* ${statsQ}`,
							"facet" : {
								"times_sold" : {
									"type" : "terms",
									"field" : "times_sold"
								},
								"times_exchanged" : {
									"type" : "terms",
									"field" : "times_exchanged"
								}
								
							}
													
						},
						"corp_owner" : {
							"type": "query",
							"q": `(owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)) ${statsQ}`,																	
						}
	
					}
				}
			};
			
			axiosData.query["json.facet"] = Object.assign(axiosData.query["json.facet"], matrixFacet);
	
			let formattedData = _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					//console.log(res.docs);
					
					this.setState({statsRes: res.facets, unitsRes: res.docs.slice()});
				})
				.catch(error => {
					console.log("error: " + error);
				});
	
			// console.log(formattedData)
			return formattedData;
	};

   _getUnits = () => {
		const { sharePageData } = this.props;
		const { user } = sharePageData;
		 if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.units && sharePageData.custom_data.group.group_data.units.length>0)){
			   this.setState({ unitRes : [] });
			   return false
		 }
		 
		 const isPublic = sharePageData.custom_data.group && sharePageData.custom_data.group.group_access && sharePageData.custom_data.group.group_access == "public";
		  
		 if (!this.state.fetchInProgress) {
			 this.setState(prevState => ({ fetchInProgress: true, }));
		 }
		
		 let q = "key:("+sharePageData.custom_data.group.group_data.units.join(" ")+")";
		 
		 let fq = [
			 "deleted:false"			
		 ];
   
		 let axiosData = {
			 url: apiURL + "/we3-properties?cache=60",
			 method: "post",
			 query: {
				 q: q,
				 fq: fq,
				 fl: [...propertyFL],
				 wt: "json",
				 sort: "building_name asc, display_full_street_address asc, address_2_normalized asc",
				 rows: user && user.role && user.role.indexOf("admin")>=0 || isPublic ? 999 : 10,
				 
			 }
		 };
		 
		 if(sharePageData.custom_data && sharePageData.custom_data.show_stats){
			axiosData.query["json.facet"] = {
					  "asking_price": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *]`,
						  "facet": {
							  "low": `min(last_listed_sale_price_all)`,
							  "high": `max(last_listed_sale_price_all)`,
							  "average": `avg(last_listed_sale_price_all)`,
							  "sum": `sum(last_listed_sale_price_all)`
							  
						  }
					  },
					  "asking_ppsf": {
						   "type": "query",
						   "q": `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *]`,
						   "facet": {
							   "low": `min(div(last_listed_sale_price_all, sq_ft))`,
							   "high": `max(div(last_listed_sale_price_all, sq_ft))`,
							   "average": `avg(div(last_listed_sale_price_all, sq_ft))`,
						   }
					   },
					   "sold_price": {
							"type": "query",
							"q": `last_sold_price:[100000 TO *] AND sq_ft:[100 TO *]`,
							"facet": {
								"low": `min(last_sold_price)`,
								"high": `max(last_sold_price)`,
								"average": `avg(last_sold_price)`,
								"sum": `sum(last_sold_price)`
								
							}
						},
						"sold_ppsf": {
							 "type": "query",
							 "q": `last_sold_price:[100000 TO *] AND sq_ft:[100 TO *]`,
							 "facet": {
								 "low": `min(div(last_sold_price, sq_ft))`,
								 "high": `max(div(last_sold_price, sq_ft))`,
								 "average": `avg(div(last_sold_price, sq_ft))`,
							 }
						 },
					  "total_sold": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)`,
						  "facet": {
							  "low_price": "min(last_listed_sale_price_all)",
							  "high_price": "max(last_listed_sale_price_all)",
							  "average_price": "avg(last_listed_sale_price_all)",
							  "average_sq_ft": "avg(last_listed_sale_price_all)",
							  "sum_price": "sum(last_listed_sale_price_all)",							
							  "low_sq_ft": "min(sq_ft)",
							  "high_sq_ft": "max(sq_ft)",
							  "low_bed": "min(bedrooms)",
							  "high_bed": "max(bedrooms)",
							 
							  
						  }
					  },
					  "sq_ft": {
						  "type": "query",
						  "q": `sq_ft:[1 TO *]`,
						  "facet" : {
									  "avg_sq_ft": "avg(sq_ft)",
									  "sum_sq_ft": "sum(sq_ft)"
								  }
						  
					  },						
					  "original_owner" : {
						  "type": "query",
						  "q": `times_exchanged:[0 TO *] AND owner:*`,
						  "facet" : {
							  "times_sold" : {
								  "type" : "terms",
								  "field" : "times_sold"
							  },
							  "times_exchanged" : {
								  "type" : "terms",
								  "field" : "times_exchanged"
							  }
							  
						  }
												  
					  },
					  "corp_owner" : {
						  "type": "query",
						  "q": `(owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c))`,																	
					  }
			  }
		 }
		 
   
		 let formattedData = _axiosCall(axiosData)
			 .then(res => {
				 //this._setBuyersList(res);
				 if(res && res.docs){
					 let keys = res.docs.map(unit => unit.last_listed_sale_key_all);
					 if(!keys || keys.length<=0){
						this.setState({ unitRes : res.docs, unitStats: res.facets ? res.facets : {}, fetchInProgress : false, fetchInProgress: false });
					 }else{
						this._getListingData(res.docs, keys, res.facets);
					 }
					 
					 
				 }else{
					 this.setState({ unitRes : [], fetchInProgress : false, fetchInProgress: false });
				 }
				 
			 })
			 .catch(error => {
				 console.log("error: " + error);
			 });
			 
   
		 // console.log(formattedData)
		 return formattedData;
   };
   
   _getListingData = async (unitRes, keys, unitStats) => {
			
		  if(!keys || keys.length<=0){
			  return false;
		  }
		  
		  let q = "key:("+keys.join(" ")+")";
   
		  let axiosData = {
			  url: apiURL + "search-listings?cache=15",
			  method: "post",
			  query: {
				  q: q,
				  fq: ["deleted:false","sale_status:active"],
				  sort: "solr_updated_date desc",
				  wt: "json",
				  rows: 10
			  }
		  };
   
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  if(res.docs && res.docs.length>0){
					  unitRes.map(unit => {
						  unit['listing'] = res.docs.filter(l => l.key == unit['last_listed_sale_key_all']).length > 0 ? res.docs.filter(l => l.key == unit['last_listed_sale_key_all'])[0] : false;
					  })					
					  this.setState({unitRes: unitRes, unitStats: unitStats ? unitStats : {}});
				  }else{
					  this.setState({unitRes: unitRes, unitStats: unitStats ? unitStats : {}});
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
		  return formattedData;
	};
   
   _getACRISData = async (buildingRes) => {
			if (!this.state.fetchInProgress) {
			   this.setState(prevState => ({ fetchInProgress: true, }));
			}
   
		   const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		 
		   const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip})`;
   
		   let axiosData = {
			   url: apiURL + "we3-transactions?cache=15",
			   method: "post",
			   query: {
				   q: q,
				   fq: ["source_providers:*ACRIS*", "price:[10000 TO *]", "parcels_condocoop_count:1", "parcels_count:[* TO 2]", "status:sold"],
				   sort: "close_date_formatted desc",
				   wt: "json",
				   rows: 9999
			   }
		   };
   
		   let formattedData = await _axiosCall(axiosData)
			   .then(res => {
				   // console.log('Chart Data', res);
				   if(res.docs && res.docs.length>0){						
					   return res.docs;
				   }else{
					   return false;
				   }			
			   })
			   .catch(error => {
				   console.log("error: " + error);
				   return false;
			   });
		   return formattedData;
	 };
   
	 
	 
   
   toggle = (tab) => {
	   const { activeTab } = this.state;
	   this.setState({activeTab: tab})
   };
   
   toggleModal(type, data) {
	   //this.snackOpen('Your Message Has Been Sent!', 'success');
	   const { sharePageData } = this.props;
	   
	   let modalShareData = JSON.parse(JSON.stringify(sharePageData));
	   
	   delete modalShareData['group'];
	   modalShareData['report_type'] = "single";
	   if(type == "building"){
		   modalShareData['building_key'] = data.key;
		   modalShareData['building'] = data;
		   
	   }else if(type == "unit"){
		   modalShareData['building_key'] = data.building;
		   modalShareData['property_key'] = data.key;
		   modalShareData['property'] = data; 
	   }
	   
	   this.setState(prevState => ({ modal: !prevState.modal, modalShareData: modalShareData, shareType: type }));
   }

  _openPrint(){
	  var getUrl = window.location;
	  var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1] + "/" + getUrl.pathname.split('/')[2];
	  window.open(baseUrl+'?exportMode=true', "print", "width=1366,height=768");
  }
  
  _template = ({ divNumber, numberOfdivs }) => {
	 const {sharePageData} = this.props;
	 this.setState({divNumber:divNumber})
	return (<div color={"secondary"}
	  justifyContent="space-between"
	  position="absolute"
	  bottom={0}
	  width={1}
	  backgroundColor="#5C6178"
	  className="bottom-bar"
	>
	  <div padding="0 1em" color="background">
		<a className="mp-branding" style={{pointerEvents:"all", cursor:"pointer"}} href="https://marketproof.com/new-development" target="_blank">
		  <img src="/img/marketproof-newdev-logo-white.png" />
		</a>
		<span className="divider"> | </span>
		<span>
			{sharePageData.custom_data && sharePageData.custom_data.report_title &&
				 <span className="bold-text">{sharePageData.custom_data.report_title}</span>
			 } ({divNumber} of {numberOfdivs})
		</span>
	  </div>
  
	  <div padding="0em 2em">
		  
	
			  
		  <Tooltip title="Print Presentation">
			<IconButton onClick={()=>{this.printDocument()}}  style={{pointerEvents:"all", cursor:"pointer"}}>
			  <ExportIcon color={"#FFF"}/>
			</IconButton>
		  </Tooltip>
		  
	  </div>
	</div>
  )};
  // printDocumentBackup() {
  //  const {sharePageData} = this.props;
	// this.setState({exportProgress:true});
	// const input = document.getElementsByClassName('pdf-report')[0];
	// const pages = document.querySelectorAll('.pdf-page').length;
	// 
	// const reportName = sharePageData.custom_data && sharePageData.custom_data.report_title ? `${sharePageData.custom_data.report_title}.pdf` : sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_name ?  `${sharePageData.custom_data.group.group_name}.pdf` : "Property Collection Report.pdf";
	// 
	// 
	// html2canvas(input, {
	// 	//allowTaint: true,
	// 	//useCORS: true,
	// 	logging:true,
	// 	proxy: process.env.API_ROOT+"/proxy"
	// }).then((canvas) => {
	// 	
	// 	  const imgData = canvas.toDataURL('image/png');
	// 	  
	// 	  const imgWidth = canvas.width;
	// 	  const pageHeight = canvas.height/pages;
	// 	  const imgHeight = ((canvas.height * imgWidth) / canvas.width);
	// 	  
	// 	  console.log(canvas.height, canvas.width, imgHeight, pageHeight);
	// 	  let heightLeft = imgHeight;
	// 	  const doc = new jsPDF('l', 'px', [imgWidth, (pageHeight+2)]);
	// 	  let position = 0;
	// 	  doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
	// 	  heightLeft -= pageHeight;
	// 	  while (heightLeft >= 0) {
	// 		  position = heightLeft - imgHeight;
	// 		  console.log(position, imgWidth, imgHeight);
	// 		  doc.addPage();
	// 		  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
	// 		  heightLeft -= pageHeight;
	// 	  }
	// 	  doc.save(reportName);
	// 	  this.setState({exportProgress:false});
  // 
	//   })
	// ;
  // }
  
 printDocument() {
	 const { sharePageData } = this.props;
	 this.setState({ exportProgress: true });
 	 const input = document.getElementsByClassName('pdf-report')[0];
	 const elements = document.querySelectorAll('.pdf-page');
	 const PAGES_BATCH = 20;
	 const totalPages = Math.ceil(elements.length / PAGES_BATCH);
	 const existingDiv = document.querySelector('.pdf-export');
	 const reportName = sharePageData.custom_data && sharePageData.custom_data.report_title 
		 ? `${sharePageData.custom_data.report_title}.pdf`
		 : sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_name 
			 ? `${sharePageData.custom_data.group.group_name}.pdf`
			 : "Property Collection Report.pdf";
 
	 if (!existingDiv) {
		 console.error('No element with class "pdf-export" found.');
		 return;
	 }
 
	let doc = null;  //new jsPDF('l');
 	let originalMapContent = null;

	 const addBatchToPDF = (batch, pageIndex) => {
		 console.log("addBatchToPDF", batch, pageIndex);
		 return new Promise((resolve) => {
			 // Create a container div
			 const container = document.createElement('div');
			 container.classList.add('pdf-report');
			 container.style.position = 'absolute';
			 container.style.top = '0';
			 container.style.left = '0';
			 container.style.zIndex = '-1';
			 
			 // Append the batch of elements to the container
			 batch.forEach(element => {
				 const clonedElement = element.cloneNode(true);
				 
				 // Check if the element contains a Google Map
				 const mapElement = clonedElement.querySelector('.map-page');
				 if (mapElement && originalMapContent) {
					 // Replace the cloned map element's content with the stored original map content
					 mapElement.innerHTML = '';
					 mapElement.appendChild(originalMapContent.cloneNode(true));
				 }
				 container.appendChild(clonedElement);
			 });
 
			 existingDiv.appendChild(container);
 
			 html2canvas(container, {
				 logging: false,
				 proxy: process.env.API_ROOT + "/proxy"
			 }).then((canvas) => {
				 const imgData = canvas.toDataURL('image/png');
				   
				   const imgWidth = canvas.width;
				   const pageHeight = canvas.height/batch.length;
				   const imgHeight = ((canvas.height * imgWidth) / canvas.width);
				   
				   //console.log(canvas.height, canvas.width, imgHeight, pageHeight);
				   let heightLeft = imgHeight;
				   
				   let position = 0;
				   if(doc === null){
					   doc = new  jsPDF('l', 'px', [imgWidth, pageHeight]);
				   }
				   doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
				   heightLeft -= pageHeight;
				  // console.log(heightLeft, pageHeight)
				   while (heightLeft >= 0) {
					   
					   if(heightLeft > 100){
						  position = heightLeft - imgHeight;
						  console.log(position, imgWidth, imgHeight);
						  doc.addPage([imgWidth, pageHeight]);
						  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
						  heightLeft -= pageHeight;
					   }else{
						   break;
					   }
					   
				   }
 
				 existingDiv.removeChild(container);
				 resolve();
			 });
		 });
	 };
	 
	 // Store the original map content
	 const originalMapElement = document.querySelector('.map-page');
	 if (originalMapElement) {
		 originalMapContent = originalMapElement.cloneNode(true);
	 }
 
	 const processBatches = async () => {
		 for (let i = 0; i < totalPages; i++) {
			 const start = i * PAGES_BATCH;
			 const end = start + PAGES_BATCH;
			 const batch = Array.from(elements).slice(start, end);
			 await addBatchToPDF(batch, i);
		 }
		 doc.save(reportName);
		 this.setState({ exportProgress: false });
	 };
 
	 processBatches();
 }
	
	
  render() {
	const { rtl, sharePageData, preview } = this.props;
	const { buildingRes, unitRes, unitStats, buildingStats, urlState, userRes, fetchInProgress, buildingsFetchInProgress, activeTab, modal, modalShareData, shareType, statsRes, isPrint, divNumber, marketStats, exportProgress, contentLoaded } = this.state;
	
	const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
	}	
	
	const user = Object.assign({}, defaultUser, userRes);
	
	if(marketStats === null || statsRes === null || userRes === null || buildingRes === null || unitRes === null || fetchInProgress || buildingsFetchInProgress){
		return <LinearLoading />;
	}
	
	
	
	const group = sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false;
	const showTabs = group && group.group_data && group.group_data.buildings && group.group_data.buildings.length>0 && group.group_data.units && group.group_data.units.length>0
	const theme = {
	  colors: {
		primary: '#171F26',
		secondary: '#171F26',
		background : "#FFF"
	  },
	  fontSizes: {
		header: '24px',
		paragraph: '28px'
	  },
	  fontFamily:{
		header: 'Montserrat'
	  },
	  space: [0, 0, 0],
	  sizes: {
		  width: 1000,
		  height: 1000
	  }
	};
	// SPECTACLE_CLI_TEMPLATE_START
	const bldgPerPage = 6;
	let mapdiv = Math.ceil(buildingRes.length / bldgPerPage)+2;
	if(sharePageData.custom_data && sharePageData.custom_data.report_title) mapdiv++;
	//console.log(mapdiv)
	
	const reportName = sharePageData.custom_data && sharePageData.custom_data.report_title ? `${sharePageData.custom_data.report_title}` : sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_name ?  `${sharePageData.custom_data.group.group_name}` : "Property Collection Report";
	buildingRes.map(bldg => {bldg['elliman_status'] = ellimanStatus(bldg)});
	console.log(buildingRes);
	let pageIncrement = 0;
	let tocPageIncrement = 0;
	return (
		<Fragment>
		
		<div  className={`share__wrapper presentation pdf-export`}>
		<div className={"pdf-export-loader"} style={{display: (exportProgress || !contentLoaded) ? "block" : "none"}}>
			<LinearLoading />
			<h2>{!contentLoaded ? "Loading Report..." : "Generating PDF, Please Wait..."}</h2>
		</div>
		<div className="pdf-report">
		{reportName &&
		<div className={"pdf-page"}>
			<div className="flex-container" padding="0" height="100%">
			  <div padding="0" className="title-left">
				  <div className="branding" />
				  <h2 class="bold-text text-center">{reportName}</h2>
				  {sharePageData.custom_data.report_subtitle &&
				  <h3 class="bold-text mb-3 text-center">{sharePageData.custom_data.report_subtitle}</h3>
					}
				  <h5 class="bold-text mb-3 text-center">{moment().format('MMMM YYYY')}</h5>
			  </div>
			  {sharePageData && sharePageData.custom_data && sharePageData.custom_data.message &&
					<div padding="0" className="title-right">  
						  <div
							dangerouslySetInnerHTML={{
							  __html: sharePageData.custom_data.message 
							}}>
						</div>
					</div>
			   }
			</div>
		</div>
		}
		<div className={"pdf-page"}>
			<div className="flex-container" padding="0" height="100%">
		    	<div padding="0" className="title-left">
				  <div className="branding" />
				  <h2 class="bold-text text-center">{sharePageData.custom_data.report_title ? sharePageData.custom_data.report_title : "Building Report"}</h2>
				  {sharePageData.custom_data.report_subtitle &&
				  <h3 class="bold-text mb-3 text-center">{sharePageData.custom_data.report_subtitle}</h3>
					}
				  <h5 class="bold-text mb-3 text-center">{moment().format('MMMM YYYY')}</h5>
			  	</div>
				<div padding="0" className="title-right table-of-contents">  
					<h3 className="page-title mb-3 mt-3">Table of Contents</h3>
					<ul>
						<li><span className="content">Select Buildings</span> <span>{1}</span> </li>
						<li><span className="content">Building Map</span> <span>{mapdiv-1}</span> </li>
						<li><span className="content">Building Unit Matrix by Status</span> <span>{mapdiv}</span> </li>
						<li><span className="content">Building Summaries</span> <span></span></li>
					</ul>
					<ul>
								{buildingRes.map((building, i) => {
									const _boro = building.borough;
									const _hood = building.neighborhood[0];
									const buildingNameOrAdr = building.name 
									  ? _capitalizeText(building.name)
									  : building.display_full_street_address
										? _capitalizeText(building.display_full_street_address)
										: _capitalizeText(building.full_street_address);
									const adr = building.display_full_street_address ? building.display_full_street_address : building.full_street_address;
									
									let unitsRes = i>0 && buildingRes[i-1].property_stats && buildingRes[i-1].property_stats.docs ? buildingRes[i-1].property_stats.docs : [];
									   
									   let sponsorUnits = unitsRes.filter(unit => unit['times_sold'] <=1 && !(unit['times_sold']==1 && ['active', 'in contract', 'pending'].indexOf(unit['current_status'])>=0));
									   let resaleUnits = unitsRes.filter(unit => unit['times_sold'] > 1 || (unit['times_sold']==1 && ['active', 'in contract', 'pending'].indexOf(unit['current_status'])>=0));
									   
									   if(i>0){
										   tocPageIncrement += Math.ceil((sponsorUnits.length/25));
										   tocPageIncrement += resaleUnits.length>0 ? Math.ceil((resaleUnits.length/25)) + 1 : 0;
										   tocPageIncrement += 2;
										   
									   }
									return <li><span className="content">{buildingNameOrAdr.replace("Of", "of").replace("By", "by")}{building.name  ? <span> - {_capitalizeText(building.display_full_street_address)}</span> : ""}, {_capitalizeText(_hood)}</span><span>{(mapdiv+tocPageIncrement)}</span></li>
								})}
							</ul>
						
					
					<Row>
					  <Col md={12}>
							<p className={"small-text light-text mt-4 mb-2"} style={{color:'#858AA1'}}>All information is from sources deemed reliable but is subject to errors and omissions. No representation is made as to the accuracy of any information. All information is subject to change. All rights to content, photographs, and graphics are reserved for the content source.</p>
						</Col>
					 </Row>
				</div>
			   
			</div>
		</div>
		
		{false && marketStats &&
			<div className={"pdf-page"}>
			  {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
			  <div padding="1em 2em 1em 1em">
				  <h4 class="bold-text mb-3 mt-3">Condo Market Overview</h4>
				  
				  <div className="dashboard">
				  <Row>
					  <Col md={6} xl={6} lg={6} xs={6}>
						<h5 class="bold-text mb-3 mt-3">New Development Market</h5>
						<hr/>
						  <Row>
							<Col md={6} xl={6} lg={6} xs={6}>
							  <StatBox stat={marketStats.unsold.count > 0 ? _formatNumber(marketStats.unsold.count) : "--"} desc={"Available Inventory"}  icon={<DomainIcon className="dashboard__trend-icon" />}  />
							</Col>
							<Col md={6} xl={6} lg={6} xs={6}>
							<StatBox stat={marketStats.asking_ppsf.sponsor.avg_ppsf > 0 ? '$'+_formatNumber(marketStats.asking_ppsf.sponsor.avg_ppsf) : "--"} desc={"Avg Asking PPSF"} />
							</Col>
							<Col md={6} xl={6} lg={6} xs={6}>
								<Borough facetData={marketStats.unsold.by_borough} dataKey={"count"} desc={"Inventory by Borough"}/>
							</Col>
							<Col md={6} xl={6} lg={6} xs={6}>
								<Borough facetData={marketStats.asking_ppsf.sponsor.by_borough} dataKey={"ppsf"} desc={"Asking PPSF by Borough"} />
							</Col>
						  </Row>
					</Col>
					<Col md={6} xl={6} lg={6} xs={6}>
						  <h5 class="bold-text mb-3 mt-3">Resale Market</h5>
						  <hr/>
						  <Row>
							<Col md={6} xl={6} lg={6} xs={6}>
							<StatBox stat={marketStats.active.count > 0 ? _formatNumber(marketStats.active.count) : "--"} desc={"Available Inventory"}  icon={<DomainIcon className="dashboard__trend-icon" />} />
							</Col>
							<Col md={6} xl={6} lg={6} xs={6}>
							<StatBox stat={marketStats.asking_ppsf.resale.avg_ppsf > 0 ? '$'+_formatNumber(marketStats.asking_ppsf.resale.avg_ppsf) : "--"} desc={"Avg Asking PPSF"} />
							</Col>
							<Col md={6} xl={6} lg={6} xs={6}>
								<Borough facetData={marketStats.active.by_borough} dataKey={"count"} desc={"Inventory by Borough"} />
							</Col>
							<Col md={6} xl={6} lg={6} xs={6}>
								<Borough facetData={marketStats.asking_ppsf.resale.by_borough} dataKey={"ppsf"} desc={"Asking PPSF by Borough"} />
							</Col>
						  </Row>
					</Col>
				  </Row>
				  </div>
			  </div>
		</div>
	}
		{(Array.from(Array(Math.ceil(buildingRes.length / bldgPerPage)).keys())).map(i => {
			return (<div className={"pdf-page"}>
						  {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
						  <div padding=".5em 2em 1em 1em">
							  <FreezeTableBuildings buildingRes={buildingRes.slice(i*bldgPerPage,(i+1)*bldgPerPage)} toggleModal={this.toggleModal.bind(this)} />
						  </div>
						  <span className="page-num">Page {i+1}</span>
					  </div>
					)
		})}
		<div className={"pdf-page map-page"}>
			  {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
			  <Container className="share dashboard">
			  <div padding="1em 2em 1em 1em">
					<h3 className="page-title mb-3 mt-3">{sharePageData.custom_data && sharePageData.custom_data.report_title}</h3>
				  <Row>
					<Col xl={9} lg={9} >
						<div className="report-container">
							<ul className="status-legend">
							
								<li className="building sponsor"><span className={`status`} /> In Sales</li>
								<li className="building closings"><span className={`status`} /> Closings</li>
								<li className="building sold-out"><span className={`status`} /> Sold Out</li>
								<li className="building closings-resale"><span className={`status`} /> Closings/Resales</li>
								<li className="building resale"><span className={`status`} /> Resales</li>
								{/*<li className="building pipeline"><span className={`status`} /> Pipeline</li>*/}
							</ul>
						</div>
					  { (isPrint || divNumber == mapdiv) && <Map divNumber={divNumber} locations={buildingRes} height={660} mapLoaded={this._mapLoaded} /> }
					</Col>
					<Col xl={3} lg={3} >
						<h4>Buildings</h4>
						<ol>
						{buildingRes.map((loc, i) => {
							const buildingNameOrAdr = loc.name 
							  ? _capitalizeText(loc.name)
							  : loc.display_full_street_address
								? _capitalizeText(loc.display_full_street_address)
								: _capitalizeText(loc.full_street_address);
							return (<li>{buildingNameOrAdr}</li>);
						})}
						</ol>
					</Col>
				  </Row>
				
			  </div>
			  <span className="page-num">Page {mapdiv-1}</span>
			  </Container>
			  
		</div>
		{false && statsRes && statsRes.count>0 &&
			<Fragment>
				<div className={"pdf-page"}>
				
					  {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
					  <Container className="share dashboard">
					  <div padding="1em 2em 1em 1em">
							<Row>
								<Col md={12} lg={12} xl={12}>
									<Card>
									  <CardBody className="no-border no-padding mg-btm-0"> 
										 <div className="card__title">							  
										   <h3 className="page-title">{sharePageData.custom_data && sharePageData.custom_data.report_title}</h3>
										 </div>
									</CardBody>
									</Card>
								  </Col>
								 
							</Row>
						  <Row>
						  <UnitMix mixData={statsRes.has_bed} />
						  <UnitBreakdown mixData={statsRes.has_bed} />
						  </Row>
					  </div>
				  </Container>
				</div>
		
			</Fragment>
		   }
		   {statsRes && 
			   <div className={"pdf-page"}>
				 	{!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
				 	<div padding="1em 2em 1em 1em">
					 	
					 	<CompMatrix statsRes={statsRes} sharePageData={sharePageData} isModal pageNum={(mapdiv)} />
						 
				 	</div>
					 
			   </div>
			   
		   }
		  
		   {buildingRes.map((building, i) => {
			   let unitsRes = i>0 && buildingRes[i-1].property_stats && buildingRes[i-1].property_stats.docs ? buildingRes[i-1].property_stats.docs : [];
			   
			   let sponsorUnits = unitsRes.filter(unit => unit['times_sold'] <=1 && !(unit['times_sold']==1 && ['active', 'in contract', 'pending'].indexOf(unit['current_status'])>=0));
			   let resaleUnits = unitsRes.filter(unit => unit['times_sold'] > 1 || (unit['times_sold']==1 && ['active', 'in contract', 'pending'].indexOf(unit['current_status'])>=0));
			   
			   if(i>0){
				   pageIncrement += Math.ceil((sponsorUnits.length/25));
				   pageIncrement += resaleUnits.length>0 ? Math.ceil((resaleUnits.length/25)) + 1 : 0;
				   pageIncrement += 2;
				   
			   }
			  
			   
			   return (
				   <Fragment>
						   <div className={"pdf-page"}>
								 {!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
								 <div padding="1em 2em 1em 1em">
									 <BuildingProfile sharePageData={{"building_key": building.key, "building" : building}} isModal />
									 <span className="page-num">Page {mapdiv+pageIncrement+(1 * (i==0))}</span>
								 </div>
						   </div>
						   <BuildingMatrix sharePageData={{ "building_key": building.key, "building" : building}} isModal pageNum={(mapdiv+pageIncrement+(1 * (i==0)))+1} />
								 
				   </Fragment>
				   
			   )
		   })}
		
		
		</div>
		
			<Modal
			  isOpen={modal}
			  toggle={this.toggleModal.bind(this)}
			  modalClassName={`ltr-support`}
			  className={`modal-dialog contact__modal`}
			>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggleModal.bind(this)} />
			</div>
			<div className="modal__body">
				{shareType == "building" ? 
				<SingleBuildingShare sharePageData={modalShareData} isModal />
				:
				<SingleUnitShare sharePageData={modalShareData} isModal />
				}
			</div>
			</Modal>
			</div>
			{contentLoaded &&
			<Tooltip title={`Export Report as PDF`}>
				<Fab
				  variant="extended"
				  size="medium"
				  color="secondary"
				  aria-label="add"
				  style={{fontWeight:400,background:'#780F9E', position:'fixed',bottom:"20px",right:"20px",zIndex:999}}
				  onClick={()=>{this.printDocument()}}
				  className={"print-button"}
				>
				  <ExportIcon style={{marginRight:5}} /> {` `} Export PDF
				</Fab>
			</Tooltip>
			}
		 </Fragment>
	  );
	
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(withOktaAuth(CompReportShare))));
