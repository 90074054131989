/* eslint-disable react/no-children-prop */
import React, { useState, Fragment } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar, Nav, NavLink, NavItem, TabContent, TabPane, Container
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import SaveIcon from 'mdi-react/ContentSaveOutlineIcon';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { nanoid } from 'nanoid'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';

import RichTextEditor from "../../../../shared/components/form/RichTextEditor";
import LinearLoading from '../../../../shared/components/LinearLoading';
import FormAutocompleteSelect from './FormAutocompleteSelect';
import TextEditIcon from 'mdi-react/FileDocumentEditOutlineIcon';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import CameraIcon from 'mdi-react/CameraIcon';
import FinanceIcon from 'mdi-react/FinanceIcon';
import ChartPieIcon from 'mdi-react/ChartPieIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon';
import RobotIcon from 'mdi-react/RobotIcon';


import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import CustomizedSlider from './FormSlideControl';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_slugifyText,
	_privateKey,
	matTheme,
	_urlSafe,
	_capitalizeText
} from '../../../../shared/helpers/utils';
import{
	_axiosCall
} from '../../../../shared/helpers/apicalls';
import{
	_shareThemes,
	_hasPermission
} from '../../../../shared/helpers/access';
const countries = [
  {value: 'AlbaniaCountry', label: 'Albania'},
  {value:'AlgeriaCountry', label: 'Algeria'},
  {value:'AndorraCountry', label: 'Andorra'},
  {value:'AngolaCountry', label: 'Angola'}
];
const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    multiline={rows}
	    rows={rows}
		onFocus={e=>{e.stopPropagation();}}
		onClick={e=>{e.stopPropagation();}}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
		variant="outlined"
	  />
	</ThemeProvider>
);
const renderLinkField = ({
	  input, label, meta: { asyncValidating, touched, error }, children, select, rows
	}) => {
		console.log(touched, error)
		return (
		<ThemeProvider theme={matTheme}>
		  <TextField
		    className="material-form__link"
		    label={label}
		    error={error!==undefined}
		    helperText={error}
		    value={_urlSafe(input.value)}
		    children={children}
		    select={select}
		    multiline={rows}
		    rows={rows}
		    onChange={(e) => {
		      e.preventDefault();
		      
		      input.onChange(e.target.value);
		      //checkLink(e.target.value)
		      
		    }}	
		  />
		</ThemeProvider>
		)
};

const renderCheckbox = ({ input, label }) => {
	return (
		  <FormControlLabel
		      control={
		        <Checkbox
		          checked={input.value ? true : false}
		          onChange={input.onChange}
		        />
		      }
		      label={label}
		    />
   )
}



const renderRichTextEditor = ({
  input, label, meta: { touched, error }, children, select, rows, placeholder, readonly, streamvalue
}) => {
	
	const editorConfig = {
			readonly: readonly, // all options from https://xdsoft.net/jodit/doc/
			minHeight:400,
			allowResizeX: false,
			enableDragAndDropFileToEditor: false,
			buttons: [],
			buttonsMD: [],
			buttonsXS: [],
			colorPickerDefaultTab: 'text',
			style: {
				fontSize:16
			},
			placeholder: placeholder
			
	}
	return (
		<RichTextEditor
			  value={streamvalue}
			  onChange={(content) => {
				input.onChange(content);
			  }}	
			  editorConfig={editorConfig}
		/>
   )
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const apiUrl = process.env.API_ROOT + '/buyerslist/';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const checkLink = (formValues) => {
	
	  if(!formValues.customLink || formValues.customLink.length < 3){
		  return sleep(1).then(() => {
		  	throw { customLink: 'Must be 3 or more characters.' }
		  
		  })
	  }
	        
      let dupquery = { "custom_link" : formValues.customLink };
            
      let apiData = {
			url: apiUrl+'sharepage/query',
			method: "post",
			query: dupquery
	  	  };
	  
	  
	  return _axiosCall(apiData)
	    .then(res => {
		    if(res.length>0){
			    throw { customLink: 'Link has already been used.' }
		    }
		   
		})	
	  
 }

const wordMarks = [
   {
	 value: 200,
	 label: "200"
   },
   {
	 value: 250,
	 
   },
   {
	   value: 300,
	   label: "300"
	 },
	 {
	   value: 350,
	 },
	 {
	   value: 400,
	   label: "400"
	 },
	 {
	   value: 450,
	 },
	 {
	   value: 500,
	   label: "500"
	 },
	 {
	   value: 550,
	 },
	 {
	   value: 600,
	   label: "600"
	 },
 ];
 
 const toneMarks = [
	{
	  value: 1,
	  label: "Professional"
	},
	{
	  value: 2,
	  label: "Casual"
	  
	},
	{
		value: 3,
		label: "Neutral"
	  },
	  
  ];

const AIAssistantForm = ( props ) => {
	
	const { handleSubmit, reset, t, buildingRes, user, toggle, property, values, listing, saved, handleGenerate, generateShareBase, generateShareLink, formValues, fetchInProgress, disabled, dispatch, classes, aiExtractionProgress, streamvalue, hasGen} = props;
	const [copied, setCopied] = useState(false);
	
	const [activeTab, setActiveTab] = useState('overview');	
	
	const isPDF = !(formValues && (!formValues['summaryType'] || formValues['summaryType'] !== "pdf"));
	const renderRadioThemes = ({ input, label, disabled }) => {
		return (
		  <RadioGroup aria-label="reportTheme" name="reportTheme" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>
			<FormControlLabel value="marketproof" control={<Radio />} label="Marketproof" />
			{user && user.themes && user.themes.length > 0 &&
				<Fragment>
					{user.themes.map(theme => {
						
						if(_shareThemes[theme]){
							return (
								<FormControlLabel value={theme} control={<Radio />} label={_shareThemes[theme]['display_name']} />
							)
						}
					})}
				</Fragment>
			}
		  </RadioGroup>
	   )
	}	
	const renderRadioModel = ({ input, label, disabled }) => {
		
		return (
		  <RadioGroup aria-label="aiModel" name="aiModel" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>
				
			<FormControlLabel value="text-davinci-003" control={<Radio />} label="Davinci-03 (Best: $.02/1K)" />		
			<FormControlLabel value="text-curie-001" control={<Radio />} label="Curie-01 ($.002/1K)" />
			<FormControlLabel value="text-babbage-001" control={<Radio />} label="Babbage-01 ($.0005/1K)" />
			<FormControlLabel value="text-ada-001" control={<Radio />} label="Ada-01 (Fastest: $.0004/1K)" />
		  </RadioGroup>
	   )
	}
	
	const renderRadioType = ({ input, label, disabled }) => {		
		return (
		  <RadioGroup aria-label="summaryType" name="summaryType" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>							
			<FormControlLabel value="listing" control={<Radio />} label="Listing Description" />
			<FormControlLabel value="social" control={<Radio />} label="Social Media" />
			<FormControlLabel value="general" control={<Radio />} label="General Summary" />		
		  </RadioGroup>
	   )
	}
	const renderRadioToneType = ({ input, label, disabled, helperText }) => {		
		return (
		 <div>
		 <div className="material-form__label bold-text">{label}</div> 
		  <div className="material-form__label">{helperText}</div> 
		  <RadioGroup aria-label="tone" name="tone" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>							
			<FormControlLabel value="professional" control={<Radio />} label="Professional" />
			<FormControlLabel value="neutral" control={<Radio />} label="Neutral" />
			<FormControlLabel value="casual" control={<Radio />} label="Casual" />		
		  </RadioGroup>
		 </div>
	   )
	}
	return(
	    <form className="material-form" onSubmit={handleSubmit}>
		
	    <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"type"} defaultExpanded>
		<ExpansionPanelSummary
		  expandIcon={<ExpandMoreIcon />}
		  aria-label="Expand"
		  aria-controls=""
		  id={"type"}
		>
		<Container>
		
				<span className="material-form__label bold">1) Select a Task <span className="small-text">(Required)</span></span>
				<div className="small-text light-text">Let our AI Assistant do the work, select a task to get started.  More tasks coming soon.</div>
			
		</Container>	 
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>	
		<Container>
		 <Col sm={12} md={12} lg={12} xl={12}>				 
		 		<div>
			 				<Card>
		 				
				  				<div className="account" style={{ minHeight:0, height:'auto', background:'transparent' }}>
					 				<div className="form__form-group account__type">
						 				<Container>
							 				
							 				<Row>
								 				
								 				<Col md={3} xl={3} sm={6} className="no-padding">
									 				<div 
										 				className={`btn btn-outline-secondary account__btn-type ${formValues && formValues['taskType'] == "listing-desc" ? "selected" : ""}`} 
										 				onClick={()=> {dispatch(change(form, 'taskType', "listing-desc"))}}>
										 				<TextEditIcon />
										 				<div>
											 				<span className="small-text">Listing Description</span>
										 				</div>
									 				</div>
								 				</Col>
												<Col md={3} xl={3} sm={6} className="no-padding">
													  <div 
														  className={`btn disabled btn-outline-secondary account__btn-type ${formValues && formValues['taskType'] == "comp-report" ? "selected" : ""}`} 
														  onClick={()=> {dispatch(change(form, 'taskType', "comp-report"))}}>
														  <FinanceIcon />
														  <div>
															  <span className="small-text">Pricing (Coming soon)</span>
														  </div>
													  </div> 
												</Col>		
								 				<Col md={3} xl={3} sm={6} className="no-padding">
									  				<div 
										  				className={`btn disabled btn-outline-secondary account__btn-type ${formValues && formValues['taskType'] == "analyze-price" ? "selected" : ""}`} 
										  				onClick={()=> {dispatch(change(form, 'taskType', "analyze-price"))}}>
										  				<CameraIcon />
										  				<div>
											  				<span className="small-text">Virtual Staging (Coming soon)</span>
										  				</div>
									  				</div> 
								  				</Col>	
								 				<Col md={3} xl={3} sm={6} className="no-padding">
									 				<div 
										 				className={`btn disabled btn-outline-secondary account__btn-type ${formValues && formValues['taskType'] == "floorplan-image" ? "selected" : ""}`} 
										 				onClick={()=> {dispatch(change(form, 'taskType', "floorplan-image"))}}>
										 				<FloorPlanIcon />
										 				<div>
											 				<span className="small-text">Floor Plan (Coming soon)</span>
										 				</div>
									 				</div> 
								 				</Col>							        								
								 				
							 				</Row>
						 				</Container>
						 				
						 				</div>
				  				</div>
		 				
			 				</Card>
		 				</div>					
			 		</Col>
		 		</Container>
	 		</ExpansionPanelDetails>
 		</ExpansionPanel>
		 
		
		 <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"title"} >
		 <ExpansionPanelSummary
		   expandIcon={<ExpandMoreIcon />}
		   aria-label="Expand"
		   aria-controls=""
		   id={"title"}
		 >
		 <Container>
		 
				 <span className="material-form__label bold">2) Adjust Preferences  <span className="small-text">(Optional)</span></span>
				 <div className="small-text light-text">Highlight unique features, alter the tone or customize the length of your listing description.</div>
			 
		 </Container>	 
		 </ExpansionPanelSummary>
		   <ExpansionPanelDetails>	
			 <Container>			
			 <Row>
		     	<Col sm={12} md={12} lg={6} xl={6}> 
				   <div className="share__link">
					 
						  <Field
						   name="length"
						   label={`Length (${formValues && formValues['length'] ? formValues['length'] : ""} Words)`}
						   helperText={"Adjust the length of the description"}
						   defaultVal={formValues && formValues['length'] ? formValues['length'] : 400}
						   max={600}
						   min={200}
						   marks={wordMarks}
						   component={CustomizedSlider}
						 
						 />
					   </div> 
				 </Col>
				 <Col sm={12} md={12} lg={6} xl={6}> 
					<div className="share__link">
					  
					  
						   <Field
							name="tone"
							helperText={"Adjust the tone of the description"}
							label={`Tone (${formValues && formValues['tone'] ? _capitalizeText(formValues['tone']) : "Neutral"})`}
							
							component={renderRadioToneType}
						  
						  />
						</div> 
				  </Col>
				 
				  <Col sm={12} md={12} lg={12} xl={12} className={"mt-3"}> 
				  		<div className="material-form__label bold-text">Key Features</div> 
						<div className="material-form__label">Highlight unique characterstics about this unit, e.g. interior finishes, brand of appliances, views.  </div> 
						<div className="material-form__label mb-2">When possible, the AI Assistant generates features from Marketproof Data about this property for you to review.</div>
				  	    {aiExtractionProgress ? 
						<div>
						  <LinearLoading />
						  <div className="text-center bold-text">AI Assistant is auto-generating features from Marketproof Data about this property.</div>
						</div>
						  
						:
						<Fragment>
				  		<Field
							name="highlights"
							id="highlights"
							label="Additional Features"
							helperText="Add unique characterstics about this unit to highlght in the description, e.g. interior finishes, brand of appliances, views."
							margin="normal"
							fullWidth
							placeholder={"Add a feature"}
							component={FormAutocompleteSelect}
						  />
						  
						</Fragment>
					}
				  </Col>
			 	</Row>
		  	</Container>
		  </ExpansionPanelDetails>
		</ExpansionPanel>	
		
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"type"} defaultExpanded>
		<ExpansionPanelSummary
		   expandIcon={<ExpandMoreIcon />}
		   aria-label="Expand"
		   aria-controls=""
		   id={"title"}
		 >
		 <Container>
		 	<Row>
			 	<Col sm={12} md={12} lg={8} xl={8}> 
				 <span className="material-form__label bold">3) {hasGen ? "Not quite what you had in mind? Regenerate or Edit Description" : "Generate Listing Description"}</span>
				 <div className="small-text light-text">{hasGen ? "Generate another version or you edit the description below." : "Description will based your preferences using Marketproof data."}</div>
			 	</Col>
				<Col sm={12} md={12} lg={4} xl={4} className={`${isMobile ? "mt-3" : "text-right"}`}>  
			 		<Tooltip title="Generate Listing Description">	
					 <Button
						  variant="contained"
						  color="primary"
						  className="buyersList__primary-button"		         
						  startIcon={<RobotIcon />}
						 onClick={(e) => {e.stopPropagation();e.preventDefault(); handleGenerate()}}
						 disabled={fetchInProgress || aiExtractionProgress || disabled}
						 style={{width: isMobile ? "160px" : "180px"}}
					  >	{fetchInProgress ? "Generating..." : (aiExtractionProgress || disabled) ? "Loading AI..." : hasGen ? "Regenerate" : "Generate"}
					  </Button>	
				   </Tooltip>
				</Col>
			 </Row>
		 </Container>	 
		 </ExpansionPanelSummary>
		<ExpansionPanelDetails>	
		<Col sm={12} md={12} lg={12} xl={12}> 
		  <div className="share__link">
			
				{fetchInProgress && <LinearLoading />} 
				 <Field
				  name="summary"
				  component={renderRichTextEditor}
				  placeholder="Your AI generated summary will appear here.."
				  readonly={fetchInProgress}
				  multiline
				  rows="2"
				  rowsMax="2"
				  streamvalue={streamvalue}
				/>
			  </div> 
		</Col>
		</ExpansionPanelDetails>
		</ExpansionPanel>	
		<Row>      
		
		</Row> 
			  <ButtonToolbar className="modal__footer ai">
			  	{/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
				<Tooltip title="Copy to Clipboard">
				<CopyToClipboard text={formValues && formValues['summary'] ? formValues['summary'].replace(/<\/p>/g, "\n\n").replace(/<br>/g, "\n\n").replace(/<br\/>/g, "\n\n").replace(/(<([^>]+)>)/gi, "") : ""}>
					<Button
						 variant="outlined"
						  color="#780F9E"
						 className="buyersList__secondary-button"	
						 disabled={fetchInProgress || aiExtractionProgress || !formValues || !formValues['summary']}         
						 startIcon={<ContentCopyIcon />}
					 >	Copy
					 </Button>	
				</CopyToClipboard>			 
				</Tooltip>{' '}
				
				
			  	
	         </ButtonToolbar>
	    </form>
    )
};

AIAssistantForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
const form = 'ai_summary_form';


export default connect(
  (state, props) => {
	  
  let savedValues = {};
  savedValues['summary'] = ""; 
  savedValues['summaryType'] = "listing";
  savedValues['taskType'] = 'listing-desc';
  savedValues['length'] = 400;
  savedValues['tone'] = "neutral";
  savedValues['highlights'] = [];
  savedValues['userPrompt'] = "";
  savedValues['includeNeighborhoodInfo'] = false;
  savedValues['aiModel'] = "text-davinci-003";
  const initalValues = Object.assign(savedValues , props.user);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
  asyncValidate: checkLink,
  asyncChangeFields: ["customLink"]
})(AIAssistantForm));
