import React, { PureComponent, useState, Fragment }  from 'react';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';

import UnitList from './components/UnitList';
import UnitsSearchTable from './../../../Units/components/UnitsSearchTable';
import StackedUnits from './../../../Reports/StackedUnits/components/StackedUnits';
import classnames from 'classnames';
import { Publisher } from '../../../../shared/pubsub/publisher.js';

import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';

const Units = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'list');
	const { unitsRes, buildingRes, statsRes, user } = props;
	
	const _toggleTab = (tab) => {
	    if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/units/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
	      setActiveTab(tab)
	      Publisher.publish(`track.buildingUnitView.${tab}`, {building: buildingRes.key});
	    }
	  };
	if(true || ['active', 'offering plan required', 'coming soon', 'condo declaration vetted'].indexOf(props.buildingRes.offering_verified_status)  >= 0 || props.user.role.indexOf('admin')>=0){
	
		return (
		   
			  <Container className="dashboard">
			  {/*['sponsor', 'resale', 'pipeline'].indexOf(buildingRes.building_status)>=0 &&   
					<div className="tabs"> 	
		            <div className="tabs__wrap mb-2">
		                <Nav tabs>
		                   <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'list' })}
		                      onClick={() => {
		                        _toggleTab('list');
		                      }}
		                    >
		                      <span className="tab-title">Unit List</span>
		                    </NavLink>
		                  </NavItem>
		                   <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'status' })}
		                      onClick={() => {
		                        _toggleTab('status');
		                      }}
		                    >
		                      <span className="tab-title">Stacking Plan</span>
		                    </NavLink>
		                  </NavItem>
						  {['resale'].indexOf(buildingRes.building_status)>=0 ?
							  <Fragment>
				                  <NavItem>
				                    <NavLink
				                      className={classnames({ active: activeTab === 'sold' })}
				                      onClick={() => {
				                        _toggleTab('sold');
				                      }}
				                    >
				                      <span className="tab-title">Sold PPSF</span>
				                    </NavLink>
				                  </NavItem>
				                  <NavItem>
				                    <NavLink
				                      className={classnames({ active: activeTab === 'ownership' })}
				                      onClick={() => {
				                        _toggleTab('ownership');
				                      }}
				                    >
				                      <span className="tab-title">Ownership Years</span>
				                    </NavLink>
				                  </NavItem>
				             </Fragment>
		                  :
		                  	 <Fragment>
				                  <NavItem>
				                    <NavLink
				                      className={classnames({ active: activeTab === 'pricing' })}
				                      onClick={() => {
				                        _toggleTab('pricing');
				                      }}
				                    >
				                      <span className="tab-title">Asking PPSF</span>
				                    </NavLink>
				                  </NavItem>
				                  
				                  <NavItem>
				                    <NavLink
				                      className={classnames({ active: activeTab === 'discount' })}
				                      onClick={() => {
				                        _toggleTab('discount');
				                      }}
				                    >
				                      <span className="tab-title">Discounts</span>
				                    </NavLink>
				                  </NavItem>
		                     </Fragment>
		                  
		                  }
		                </Nav>
		              </div>
		            </div>
		        */}
				
				 {activeTab === 'list' ?
					<Row>
						<UnitsSearchTable buildingView building={props.buildingRes} />			                
					</Row>
					:
					<StackedUnits {...props} activeTab={activeTab} />
				}
			   
			  </Container>
		 )
	}else{
		return (	 
		  	  <Container className="dashboard">
			   <Row>
			   <Col md={12} xs={12}>
			   <Card>
			   <CardBody className="dashboard__booking-card card__link">
			   <div className="">
			    <h4 className="">Coming Soon</h4>
			   	<p> 
			   		Marketproof is currently processing this building and verifying the accuracy of information.
			   		<br/>
			   		More details will be added to this building shortly.		                
				</p>
				</div>
				</CardBody>
				</Card>
				</Col>
				
			   </Row>
			  </Container>
		  )
	}
  
};

export default Units;