import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';
import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ContactForm from '../form/contact/ContactForm';

import SnackBar from '../../../shared/components/SimpleSnackBar';

import { Publisher } from './../../pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

class ContactDeveloperButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: props.share ? 'Contact Listing Agent' :  'Contact',
	    modal: false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success'
	    
	};
	this.toggle = this.toggle.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
    
  }
  
   handleSubmit(values) {
   		 const { authState, authService, dispatch, user, formValues, buildingRes, property, listing } = this.props;	
   		 const { saveBuilding, saveUnit } = this.props.actions; 
   		 let propertyName = buildingRes.name 
	      ? _capitalizeText(buildingRes.name)
	      : buildingRes.display_full_street_address
	        ? _capitalizeText(buildingRes.display_full_street_address)
	        : _capitalizeText(buildingRes.full_street_address);
        
        if(property && property.address_2_normalized) propertyName += ' - ' + _formatUnitNumber(property.address_2_normalized);
		let hood = buildingRes.neighborhood ? _capitalizeText(buildingRes.neighborhood[0])+', ' : '';
		hood += _capitalizeText(buildingRes.borough);
   		  	  
   		// console.log(values, user, formValues);
   		 let unitType = [];
   		 if(formValues.studio) unitType.push('Studios');
   		 if(formValues.one_bed) unitType.push('1 Beds');
   		 if(formValues.two_bed) unitType.push('2 Beds');
   		 if(formValues.three_bed) unitType.push('3 Beds');
   		 if(formValues.four_bed) unitType.push('4 or More Beds');
   		 
   		 let interestedIn = [];
   		 if(formValues.info) interestedIn.push('Request Information');
   		 if(formValues.tour) interestedIn.push('Schedule Tour');
   		 if(formValues.offer) interestedIn.push('Make an Offer');
   		 
   		 let salesContact = {
	   		  "Email" : "info@marketproof.com",
	   		  "Name" : "Marketproof Inquiry"
   		 }
   		 
   		 let listingContacts = false;
   		 
   		 if(listing && listing.contacts_json){
				try{
					const agents = JSON.parse(listing.contacts_json);					
					listingContacts = agents.filter(agent => agent.email && agent.email.indexOf('@') >=0).map(agent=>{
						return{
							"Email" : agent.email,
							"Name" : agent.name
						}
					})					
				}catch(e){
										
				}
		}

   		 
   		 if(!listingContacts && buildingRes.sales_office_details){
	   		 try{
		   		 const salesOffice = JSON.parse(buildingRes.sales_office_details);
		   		 if(salesOffice && salesOffice.email){
			   		 salesContact["Email"] = salesOffice.email;
			   		 salesContact["Name"] = propertyName;
		   		 }
	   		 }catch(e){
		   		 //cannot parse JSON
	   		 }	   		 
   		 }
   		 
   		    		 
   		 let contactData = {
	   		 to: listingContacts ? listingContacts : [salesContact],
	   		 from: [{
		   		 "Email" : formValues.email,
		   		 "Name" : formValues.fullName
		   		}],
	   		 subject: `${propertyName}: Inquiry from ${formValues.fullName}${user.company ? ', ' + user.company : ''}`,
	   		 variables: {
		   		 property : propertyName,
		   		 fullName : formValues.fullName,
		   		 phone: formValues.primaryPhone ? formValues.primaryPhone : 'Not provided',
		   		 email: formValues.email ? formValues.email : 'Not provided',
		   		 title: user.title ? user.title : 'Not provided',
		   		 company: user.company ? user.company : 'Not provided',
		   		 message: formValues.message ? formValues.message : 'Not provided',
		   		 priceRange : formValues.priceRange ? formValues.priceRange : 'Not provided',
		   		 preferredUnit: unitType.length > 0 ? unitType.join(', ') : 'Not provided',
		   		 interestedIn: interestedIn.length > 0 ? interestedIn.join(', ') : 'Not provided',
		   		 neighborhood: hood,
		   		 buildingKey: buildingRes.key,
		   		 unitKey: property ? property.key : false
	   		 },
	   		 user: user
   		 }
   		 if(formValues.ccme === true){
	   		 contactData['cc'] = [{
		   		 "Email" : formValues.email,
		   		 "Name" : formValues.fullName
		   		}]
   		 }

   		 let apiData = {
			url: apiUrl+'sales-contact',
			method: "post",
			query: contactData
	  	  };
	  
	  
		  _axiosCall(apiData)
		    .then(res => {
		      console.log("_getUserData res back ,", res);
		      
		      if(res && res.result && res.result.body && res.result.body.Messages && res.result.body.Messages[0] && res.result.body.Messages[0].Status && res.result.body.Messages[0].Status === "success"){		      
			      
			      const buildingKey = buildingRes.key;
			      if(formValues.saveme === true){
				    
				    if(property){
						const unitKey = property.key;
					    saveUnit(unitKey, buildingKey, user);
					    this.snackOpen('Your Message Has Been Sent and Unit Has Been Saved to My Units.', 'success');
					    Publisher.publish('track.contact.unit', {building: buildingKey, unit:unitKey});
					    Publisher.publish('track.save.unit', {building: buildingKey, unit:unitKey});
				    }else{
					    saveBuilding(buildingKey, user);
					    this.snackOpen('Your Message Has Been Sent and Building Has Been Saved to My Buildings.', 'success');
					    Publisher.publish('track.contact.building', {building: buildingKey});	
					    Publisher.publish('track.save.building', {building: buildingKey});
				    }
				  }else{
					  this.snackOpen('Your Message Has Been Sent.', 'success');	
					  
					  if(property){
					  	Publisher.publish('track.contact.unit', {building: buildingKey, unit:property.key});
					  }else{
						Publisher.publish('track.contact.building', {building: buildingKey});
					  }
					  				  
				  }
				  
				  this.setState(prevState => ({ modal: false }));
			  }else{			  
				 if(res.message){
					this.snackOpen(<Fragment><span>Oops, Your Message Was Not Sent Successfully</span></Fragment>, 'error');
				 }else{
					this.snackOpen(<Fragment><span>Oops, Your Message Was Not Sent Successfully</span></Fragment>, 'error');
				 }
				  
			  }
		  })
		  .catch((error) => {
		    console.log("_getUserData error ", error);
		    this.snackOpen('Oops, Your Message Was Not Sent Successfully.', 'error');
		  }); 

    
   }
  
  	snackOpen = (message, type) => {
		this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	};
	
	snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: '' } )
	};
	
	
	
	
  toggle() {
	//this.snackOpen('Your Message Has Been Sent!', 'success');
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, listing, share
    } = this.props;
    
    const { modal, snackShow, snackMessage, snackType } = this.state;
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;

	let salesOffice = false;
	let listingAgents = false;
	
	const hood = buildingRes.neighborhood ? _capitalizeText(buildingRes.neighborhood[0])+',' : '';
	
	try{
		salesOffice = JSON.parse(buildingRes.sales_office_details);
	}catch(e){
		
	}
	
	if(listing && listing.contacts_json){
		try{
			listingAgents = JSON.parse(listing.contacts_json);
		}catch(e){
			
		}
	}
	
	console.log(listingAgents);
	
	const unitKey = property ? property.key : false;
	const buildingKey = buildingRes.key;
	
	const saved = unitKey ? 
    			savedUnits.findIndex(item => (item.key === unitKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 
    			: 
    			savedBuildings.findIndex(item => (item.key === buildingKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				{(listingAgents || salesOffice) &&
		    	<Tooltip title={`Contact the ${listingAgents ? 'Listing Agent' : 'Sales Gallery'} About this ${property ? 'Unit' : 'Building'}`}>
		        	<Button
			         	onClick={this.toggle}
			         	variant="contained"
			         	color="secondary"
			         	className="buyersList__secondary-button"		         
			         	startIcon={!isMobile && <EmailOutlineIcon />}
						style={{"width" : share ? "260px" : "120px"}}
			     	>	{this.state.buttonText}
			     	</Button>				 
		  		</Tooltip>
		  	}
		  	
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
				  <Row>
		        	<Col xl={12} lg={12} md={12}>
		        		<div className="modal__body background_white" style={{height:"auto"}}>
				            <h4 className="contact__title">{buildingNameOrAdr}</h4>
				            <div className="contact__address light-text">
				            	{_capitalizeText(adr)}, {hood && hood} {_capitalizeText(buildingRes.borough)}
				            </div>
				            <hr/>
				            {(listingAgents || salesOffice) &&
					            <Fragment>
					            
							     
							     {listingAgents ?
											<Fragment>
											{listingAgents.map(agent => {
												
													return (
															<div className="sales__info">
															   {agent.name && agent.name.length > 1 &&
														        <Fragment>
														           <div className="info__item-title"><AgentIcon size={14} color="#BFC4D1"/> Listing Agent: </div>
														           <div className="info__item">{_capitalizeText(agent.name)}</div>
													           </Fragment>
													           }
													           {agent.phone && agent.phone.length > 1 &&
														        <Fragment>
														           <div className="info__item-title"><PhoneIcon size={14} color="#BFC4D1"/> Phone: </div>
														           <div className="info__item"><a href={`tel:${agent.phone}`}>{agent.phone}</a></div>
													           </Fragment>
													           }
													           {agent.email && agent.email.length > 1 &&
														        <Fragment>
															           <div className="info__item-title"><EmailOutlineIcon size={14} color="#BFC4D1"/> Email: </div>
															           <div className="info__item"><a href={`mailto:${agent.email}`} target="_blank">{agent.email}</a></div>
															    </Fragment>
													           }
													           <hr/>													      										           
													        </div>
													)
												})
												
										    }
										    </Fragment>	
										:

										     <div className="sales__info">
									           {/*<h5 className="info__title">Sales Information</h5> */}
									           {buildingRes.sales_office && buildingRes.sales_office.length > 1 && (!salesOffice.contact_name || (salesOffice.contact_name && buildingRes.sales_office != salesOffice.contact_name)) &&
										        <Fragment>
										           <div className="info__item-title"><AgentIcon size={14} color="#BFC4D1"/> Office / Team: </div>
										           <div className="info__item">{_capitalizeText(buildingRes.sales_office)}</div>
									           </Fragment>
									           }
									           {salesOffice.contact_name && salesOffice.contact_name.length > 1 &&
										        <Fragment>
										           <div className="info__item-title"><AgentIcon size={14} color="#BFC4D1"/> Contact: </div>
										           <div className="info__item">{_capitalizeText(salesOffice.contact_name)}</div>
									           </Fragment>
									           }
									           {salesOffice.telephone && salesOffice.telephone.length > 1 &&
										        <Fragment>
										           <div className="info__item-title"><PhoneIcon size={14} color="#BFC4D1"/> Phone: </div>
										           <div className="info__item"><a href={`tel:${salesOffice.telephone}`}>{salesOffice.telephone}</a></div>
									           </Fragment>
									           }
									           {salesOffice.email && salesOffice.email.length > 1 &&
										        <Fragment>
											           <div className="info__item-title"><EmailOutlineIcon size={14} color="#BFC4D1"/> Email: </div>
											           <div className="info__item"><a href={`mailto:${salesOffice.email}`} target="_blank">{salesOffice.email}</a></div>
											    </Fragment>
									           }
									           {salesOffice.address && salesOffice.address.length > 1 &&
										       <Fragment>
										           <div className="info__item-title"><LocationIcon size={14} color="#BFC4D1"/> Sales Office: </div>
										           <div className="info__item">
											           <a href={`https://www.google.com/maps/search/${salesOffice.address}, ${salesOffice.city}, ${salesOffice.state} ${salesOffice.zip}`} target="_blank">
											           	<p>{salesOffice.address}</p>
											           	<p>{salesOffice.city}, {salesOffice.state} {salesOffice.zip} </p> 
											           </a>
										           </div>
										       </Fragment>
									           }
									           {buildingRes.notes &&
										        <div>
										           <hr/>
										           <div className="info__item-title"><InfoIcon size={14} color="#BFC4D1"/> Additional Details: </div>
										           <div className="info__notes">
										           	<p>{buildingRes.notes.split('\n').map((item, key) => {
															return <React.Fragment>{item}<br/></React.Fragment>
														})}
													</p>						           	
										           </div>
										        </div>
									           }
									        </div> 
									}
						        </Fragment>
					            
				            }
				        </div>
		        	
					</Col>
					{/*<Col xl={8} lg={8} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">Contact {listingAgents ? 'The Listing Agent' : buildingNameOrAdr}
								{property && <span> - {_formatUnitNumber(property.address_2_normalized)}</span>}
							</h4>
							
							{property && 
								<h5 className="contact__sub-title">
									${_formatNumber(listing ? listing.price : property.offering_price)} 
									<span className="small-divider">|</span>
					              	{property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
					              	<span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? ' Bed' : ' Beds'}</span>
					              	<span className="small-divider">|</span>
					              	{property.baths}
					              	<span className="sub-text">{property.baths === 1 ? ' Bath' : ' Baths'}</span>
					              	<span className="small-divider">|</span>
					              	{_formatNumber(property.sq_ft)}
					              	<span className="sub-text"> Sq Ft</span>				              	

				               </h5>
					            
				            }

				            <hr/>      
				             <ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} handleSubmit={this.handleSubmit} saved={saved} />				             
				             
				        </div>	
				        	        	
					</Col>*/}
				  </Row>
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("contact_form")(state),
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [] 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ContactDeveloperButton));
