import React, { Fragment, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from "axios";
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import classNames from 'classnames';

import { userEditFieldsObj } from "../../../shared/helpers/userEditFields.js";


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	_formatPrice,
	buildingStageToolTips,
	_formatNumber,
	_formatDate,
	_isEmpty
} from "../../../shared/helpers/utils";
import { _axiosCall } from '../../../shared/helpers/apicalls';

const useStyles = makeStyles(theme => ({
  root: {
  	width: '100%',
  	'& > * + *': {
		marginTop: theme.spacing(3),
  	},
  	
	},
	'.MuiIconButton-root' : {
		padding:'12px'
	},
  formAutocomplete: {
	  fontSize:14,
	  '& .MuiInputLabel-root': {
		color:'#363347'
	  },
	  '& .MuiOutlinedInput-root': {
		'& fieldset': {
		  borderColor: '#780F9E', // default border color
		 
		},
		'&:hover fieldset': {
		  borderColor: '#9600BF', // border color when hovered
		},
		'&.Mui-focused fieldset': {
		  borderColor: '#9600BF', // border color when focused
		},
		
	  },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
    maxWidth: 300,
    fontSize: '12px',
  },
  formControlPop: {
    margin: '6px',
    minWidth: 160,
	width:400,
    fontSize: '12px',
	padding:10	
  },
  formLabel: {
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px'
	   
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: '5px 10px 10px'
  },
  button:{
	'&.selected' :{
		background:"#EDDEF5"
	}
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const MultiMenuProps = {
  PaperProps: {
    style: {
      marginTop: 0 
    },
  },
  InputProps:{
	  style:{
		  fontSize:'12px'
	  }
  }
};
const apiUrl = process.env.API_ROOT + '/query/';
const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}
const mapAmenitiesToGroups = (amenitiesList, buildingAmenities) => {
  // Create a mapping from key to group name
  const keyToGroupName = {};
  const groupOrder = [];

  buildingAmenities.forEach((group, index) => {
	const capitalizedGroupName = _capitalizeText(group.name);
	groupOrder.push(capitalizedGroupName);
	group.values.forEach(amenity => {
	  keyToGroupName[amenity.key] = capitalizedGroupName;
	});
  });

  // Assign group name to amenities, default to "Other" if not matched
  const mappedAmenities = amenitiesList.map(amenity => ({
	...amenity,
	groupName: keyToGroupName[amenity.key] || "Other"
  }));

  // Sort the amenities by group name according to the order in buildingAmenities
  mappedAmenities.sort((a, b) => {
	const groupA = a.groupName;
	const groupB = b.groupName;

	const orderA = groupOrder.indexOf(groupA);
	const orderB = groupOrder.indexOf(groupB);

	if (groupA === "Other") return 1;
	if (groupB === "Other") return -1;

	return orderA - orderB;
  });

  // Sort the amenities within each group alphabetically by label
  const sortedAmenities = mappedAmenities.reduce((acc, amenity) => {
	if (!acc[amenity.groupName]) {
	  acc[amenity.groupName] = [];
	}
	acc[amenity.groupName].push(amenity);
	return acc;
  }, {});

  Object.keys(sortedAmenities).forEach(group => {
	sortedAmenities[group].sort((a, b) => a.label.localeCompare(b.label));
  });

  // Flatten the sorted object back into an array
  return Object.values(sortedAmenities).flat();
};

const fuzzyMatch = (input, array, filter) => {
  // Normalize the input: replace "-", "_" with spaces and convert to lowercase
  input = input.toLowerCase().replace(/[-_]/g, ' ');

  let result = array.filter(item => item.val && item.val!="")
	.map(item => {
	  // Normalize the item: replace "-", "_" with spaces and convert to lowercase
	  let lowerItem = item.val.toLowerCase().replace(/[-_]/g, ' ');
	  let index = lowerItem.indexOf(input);
	  
	  let score;
	  if (index === 0) {
		// Best match: at the beginning of the string
		score = 3;
	  } else if (index > 0) {
		// Match within the string but not at the start
		score = 2;
	  } else {
		// No match
		score = 0;
	  }
	  
	  return { item: item, score: score, index: index };
	})
	.filter(result => result.index !== -1) // Remove items with no match
	.sort((a, b) => {
	  // Sort by score first, then by the position of the match
	  if (b.score === a.score) {
		return a.index - b.index; // Prefer matches closer to the start
	  } else {
		return b.score - a.score; // Higher score comes first
	  }
	})
	.map(result => {
	  // Format the item as required
	  return {
		label: _capitalizeText(result.item.val.replace(/[^a-zA-Z0-9]/g, ' ')), // Capitalize and replace special characters with spaces
		key: result.item.val, // Use original value as ID
		count: result.item.count // Preserve the count
	  };
	});

  // Deduplicate based on 'label'
  let dedupedResult = [];
  let seenLabels = new Set();

  for (let item of result) {
	if (!seenLabels.has(item.label)) {
	  seenLabels.add(item.label);
	  dedupedResult.push(item);
	}
  }
  if(filter && filter.group_amenities){
	  return mapAmenitiesToGroups(dedupedResult, userEditFieldsObj.buildingAmenities)
  }

  return dedupedResult;
};


const keysToOptions = (keys) => {
	console.log(keys);
	if(_isEmpty(keys)) return [];
	if(!Array.isArray(keys)){
		keys = [keys]
	}
	return keys.filter(key => (key !== null && key !== undefined)).map(key => {
	  if(typeof key === 'object' && !Array.isArray(key)){
		  return key;
	  }
	  // Format the item as required
	  return {
		label: _capitalizeText(key.replace(/[^a-zA-Z0-9]/g, ' ')), // Capitalize and replace special characters with spaces
		key: key, // Use original value as ID
		count: false // Preserve the count
	  };
	});
}

const removeOperator = (values) =>{
	if(values && Array.isArray(values)){
		values = values.map(val => val.split("~")[0])
	}
	return values;
}

const checkOperator = (values) => {
	let operator = "AND";
	if(_isEmpty(values)) return operator;
	values =  Array.isArray(values) ? values : [values];
	values = values.map(val => {
		if(val.indexOf("~")>=0){
			const parts = val.split("~");
			operator = parts[parts.length-1];
			return parts[0];
		}
	})
	return operator;
}


export default function AutocompleteFilter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, filter, _filters, setSubFilters, q, fq, autocompleteFQ} = props;
  const [value, setValue] = React.useState(subFilters[filter.search_key] ? removeOperator(subFilters[filter.search_key]) : []);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [ searchTextFocus, setSearchTextFocus ] = useState(true);
  const [ minMaxValues, setMinMaxValues ] = useState([]);
  
  const [open, setOpen] = React.useState(false);
  
  const [operator, setOperator] = React.useState(checkOperator(subFilters[filter.search_key]));
  const autocompleteRef = useRef(null);
  const OperatorType = () => {
	  const classes = useStyles();
	  return (
		  <div>
		  
			   <ButtonGroup size="small" aria-label="small button group">		
			   		<Tooltip title="Match all selected" aria-label="Match all selected">
						 <Button onClick={()=> setOperator("AND")} className={operator === "AND" ? classNames(classes.button, "selected") : classNames(classes.button)}>Match All</Button>
					 </Tooltip>
						 					        
				   <Tooltip title="Match any selected" aria-label="Match any selected">
						  <Button onClick={()=> setOperator("OR")} className={operator === "OR" ? classNames(classes.button, "selected") : classNames(classes.button)}>Match Any</Button>
					  </Tooltip>
					  
			   </ButtonGroup>
		   </div>
	  )
  }
  
  React.useEffect(() => {
		let active = true;
		console.log("here")
		//if(!inputValue) return;
		let facetFQ = [];
		if(!_isEmpty(filter.fq)){
			if(typeof filter.fq === 'string'){
				facetFQ = [...facetFQ, ...[filter.fq]];
			}else if(Array.isArray(autocompleteFQ)){
				facetFQ = [...facetFQ, ...filter.fq];
			}
		}
		
		
		let axiosData = {
			url: apiUrl + filter.core_name,
			method: "post",
			query: {
				q: `${filter.search_key}:*${inputValue.replace(/[^a-zA-Z0-9]/g, '*')}*`,
				wt: "json",
				fq: facetFQ,
				rows: 0,
				"json.facet": {
					"autocomplete" : {
						"type" : "terms",
						"field" : filter.search_key,
						'mincount' : 0,
						"limit" : 99
					}
					
				}
			}
		};
		
		_axiosCall(axiosData)
			.then(res => {
		
				if(res && res.facets && res.facets.autocomplete && res.facets.autocomplete.buckets){
					let matches = fuzzyMatch(inputValue, res.facets.autocomplete.buckets, filter);
					setOptions(matches);
				}
		
			})
			.catch(error => {
				console.log("error: " + error);
			});
	
		return () => {
		  active = false;
		};
	}, [value, inputValue, open]);
  
  
  const handleChangeMultiple = ()  => {
	setOpen(false); 
    let search = {};

	search[filter.search_key] = value && Array.isArray(value) ? value.map(item => item.key ? `${item.key}~${operator}` : `${item}~${operator}`) : [];
    
    setSubFilters(search);
  };
  
 const handleClear = () => {
	 if (autocompleteRef.current) {
	   autocompleteRef.current.value = null; // Clear input value
	   autocompleteRef.current.blur(); // Remove focus
	 }
	 setValue([]);
	 setInputValue("")
	 
   };
  
  
  const handleClose = () => {
    setOpen(false); 
  };

  const handleOpen = () => {
    setOpen(true);
  };
  
  const setTitle = () => {
	  let title = filter.name;
	  if(!_isEmpty(value)){
		  let count = Array.isArray(value) && value.length>0 ? value.length : 1;
		  title = <><span>{filter.name}</span><span className="small-text light-text">{` (${count} Selected)`}</span></>
	  }
	  return title;
	  
  }
      
  return (
	  		<FormControl className={classes.formControl}>
		        <InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
		        	{setTitle()}
		        </InputLabel>
		        <Select
		          labelId={`${filter.search_key}-label`}
		          id={`${filter.search_key}`}
		          name={filter.search_key}
				  value={minMaxValues}		          
				  onClose={()=>{handleChangeMultiple()}}
				  open={open}
				  onOpen={handleOpen}
				  //onBlur={(e)=> {e.stopPropagation();e.preventDefault();}}
		          input={<Input id={`${filter.search_key}`} />}
		          renderValue={selected => (
		            <div>			           
		               {selected.join(', ')}			
		            </div>
		          )}
		          multiple
		          MenuProps={MultiMenuProps}
		          className={classes.menuItem} 
		        >	
					<div style={{padding:"10px 17px"}}>
					<h5 style={{fontWeight:600,textTransform:'uppercase',paddingBottom:10,borderBottom:"1px solid #CCC",marginBottom:15}}>Search Amenities</h5>
					
		        	<OperatorType />
					</div>
					<div></div>
		        	<FormControl className={classes.formControlPop}>
		        	  <Autocomplete
					  	  ref={autocompleteRef}
						  multiple
						  limitTags={5}
						  id="multiple-limit-tags"
						  options={options}
						  groupBy={filter.group_amenities ? (option) => option.groupName : undefined}
						  getOptionSelected={(option, value) => (value.label ? value.label : value) === (option.label ? option.label : option)}
						  freeSolo
						  onChange={(event, newValue) => {
							console.log("onChange",newValue)
							setValue(newValue)
							
						  }}
						  onInputChange={(event, newInputValue) => {
							console.log("onInputChange",newInputValue)
							setInputValue(newInputValue)
						  }}
						  
						  getOptionLabel={(option) => option.label ? option.label : option}
						  defaultValue={keysToOptions(value)}
						  renderInput={(params) => (
							<TextField {...params} 
								className={classes.formAutocomplete}
								onClick={(event) => {
								event.stopPropagation();
								}} 
								variant="outlined" 
							  label={filter.name} 
							  placeholder={`Search ${filter.name}`} />
						  )}
						  renderOption={(option) => {
							  if(option.type=="add"){
								 return (
								   <div className="autocomplete-search"><AddIcon size={20} style={{ marginRight:"3px", marginTop:"-2px"}} color={'#780F9E'}  />{inputValue ? <span>Add <strong>{inputValue}</strong> As New Company</span> : <span>Add New Company</span>}</div>
								 );
							  }else{
								 return (
									<div className="autocomplete-search">{option.label} </div> //<span className="small-text light-text pl-2">({option.count} Matches{option.count > 1 && 's'})</span>
								  ); 
							  }
								
							  
							}}
						/>
			        </FormControl>
			        
			         <hr />
			         <div style={{padding:'0px 16px 10px',textAlign:'right'}}>	
			         	<Tooltip title={`Clear search`}>
					        <Button
						         onClick={handleClose}
						         size="small"
						         style={{marginRight:5}}
						     >	Cancel
						     </Button>				 
					  	</Tooltip>		         
				         <Tooltip title={`Apply search`}>
					        <Button
						         onClick={()=>{handleChangeMultiple()}}
						         variant="outlined"
						         size="small"
						     >	Search
						     </Button>				 
					  	</Tooltip>
				  	</div>
		        </Select>
		        
		    </FormControl>	
	    )	
  
}
