import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

const rows = [
 
  {
    id: 'name', disablePadding: true, label: 'Building Name',
  },
  {
    id: 'inventory_unsold', disablePadding: false, label: 'Unsold',
  },
  {
    id: 'inventory_total', disablePadding: false, label: 'Total Units',
  },
  {
    id: 'sales_start_date', disablePadding: false, label: 'Sales Started',
  },
  {
    id: 'inventory_stats_listings_average_ppsf', disablePadding: false, label: 'Price / Sq Ft',
  },
  {
    id: 'inventory_stats_listings_low_price', disablePadding: false, label: 'Price Range',
  },
  {
    id: '', noSort: true, disablePadding: false, label: 'Contact Info',
  }
 
];

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, user
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/*<Checkbox
              className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />*/}
          </TableCell>
          {rows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.noSort ? 
	              <span>{row.label}</span>
	            :
	              <TableSortLabel
	                active={orderBy === row.id}
	                direction={order}
	                onClick={this.createSortHandler(row.id)}
	                className="material-table__sort-label"
	                dir="ltr"
	              >
	                {row.label}
	              </TableSortLabel>
	              
              }
              
            </TableCell>
            
          ), this)}
           {user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
	            <TableCell
	              className="material-table__cell material-table__cell--sort material-table__cell-right"
	             
	            >   
	            	Status
	            </TableCell>
	                          
	         }
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(MatTableHead);
