import {_formatPropertyType} from './utils.js'

export const solrFieldObj = {
  buildings: {
    architects: 'architect',
    buildingName: 'name',
    buildingFeatures: 'building_features',
    buildingType: 'ownership_type',
    buildingTypeSecondary: 'ownership_occupancy_sub_types',
    constructionType: 'building_style',
    developers: 'developers',
    displayAdr: 'display_full_street_address',
    floors: 'total_floors',
    images: 'pictures',
    interiorDesigners: 'interior_designers',
    manager: 'manager',
    owner: 'owner',
    salesOffice: 'sales_office',
    unitsAffordableRentCount: 'total_units_rental_affordable',
    unitsAffordableSaleCount: 'total_units_sellable_affordable',
    unitsCommercialCount: "total_units_commercial",
    unitsCount: 'total_units',
    unitsHotelCondoCount: 'total_units_hotel_condo',
    unitsHotelCount: 'total_units_hotel',
    unitsParkingCount: 'total_units_parking',
    unitsRentalCount: 'total_units_rental',
    unitsRentalMarketCount: 'total_units_rental_market',
    unitsResidentialCount: 'residential_units',
    unitsSellableCount: 'total_units_sellable',
    unitsStorageCount: 'total_units_storage',
    unitsTimeshareCount: 'total_units_timeshare',
    website: 'website',
    yrBuilt: 'year_built',
    yrBuiltStarted: 'year_built_start',
    yrBuiltFinished: 'year_built_finished',
    yrConverted: 'year_converted',
    yrSalesStarted: 'year_sales_start',
    description: 'description',
  },
  properties: {
    bathrooms: 'baths',
    bathroomsHalf: 'half_baths',
    bedrooms: 'bedrooms',
    floorNum: 'floor',
    floorplan: 'floorplans',
    images: 'pictures',
    propertyFeatures: 'property_features',
    roomsCount: 'rooms',
    sqFtExterior: 'exterior_sq_ft',
    sqFtInterior: 'sq_ft',
    unitDisplay: 'unit_display',
    unitLine: 'line',
    yrBuilt: 'year_built',
    propertyType: 'property_type',
  },
  houses: {
    bathrooms: 'baths',
    bathroomsHalf: 'half_baths',
    bedrooms: 'bedrooms',
    images: 'pictures',
    propertyFeatures: 'property_features',
    roomsCount: 'rooms',
    sqFtExterior: 'exterior_sq_ft',
    sqFtInterior: 'sq_ft',
  },
};

// ANY FIELD ADDITIONS OR DELETIONS SHOULD ALSO BE UPDATED IN apicalls.js solrFieldObj - used to fire calls in Forms
export const userEditFieldsObj = {
	buildingAmenities: [
		{
			"name": "service level",
			"values": [
				{ "display": "full service", "key": "full-service", "val": "building_features" },
				{ "display": "doorman full-time", "key": "doorman-full-time", "val": "building_features" },
				{ "display": "doorman part-time", "key": "doorman-part-time", "val": "building_features" },
				{ "display": "virtual doorman", "key": "virtual-doorman", "val": "building_features" },
				{ "display": "lobby attendant", "key": "lobby-attendant", "val": "building_features" },
				{ "display": "intercom", "key": "intercom", "val": "building_features" },
				{ "display": "concierge", "key": "concierge", "val": "building_features" },
				{ "display": "live in super", "key": "live-in-super", "val": "building_features" },
				{ "display": "attended lobby", "key": "attended-lobby", "val": "building_features" },
				{ "display": "hotel services", "key": "hotel-services", "val": "building_features" },
				{ "display": "maid service", "key": "maid-service", "val": "building_features" },
				{ "display": "full-time doorman", "key": "full-time-doorman", "val": "building_features" },
				{ "display": "valet services", "key": "valet-services", "val": "building_features" }
			]
		},
		{
			"name": "access",
			"values": [
				{ "display": "elevator", "key": "elevator", "val": "building_features" },
				{ "display": "walk-up", "key": "walk-up", "val": "building_features" },
				{ "display": "private elevator", "key": "private-elevator", "val": "building_features" },
				{ "display": "wheelchair accessible", "key": "wheelchair-accessible", "val": "building_features" },
				{ "display": "wide doorways", "key": "wide-doorways", "val": "building_features" },
				{ "display": "stoop entry", "key": "stoop-entry", "val": "building_features" },
				{ "display": "driveway", "key": "driveway", "val": "building_features" },
				{ "display": "porte cochère", "key": "porte-cochère", "val": "building_features" },
				{ "display": "second entry", "key": "second-entry", "val": "building_features" }
			]
		},
		{
			"name": "common space",
			"values": [
				{ "display": "lounge", "key": "lounge", "val": "building_features" },
				{ "display": "conference room", "key": "conference-room", "val": "building_features" },
				{ "display": "playroom", "key": "playroom", "val": "building_features" },
				{ "display": "nursery", "key": "nursery", "val": "building_features" },
				{ "display": "cinema room", "key": "cinema-room", "val": "building_features" },
				{ "display": "party room", "key": "party-room", "val": "building_features" },
				{ "display": "billiards room", "key": "billiards-room", "val": "building_features" },
				{ "display": "wine room", "key": "wine-room", "val": "building_features" },
				{ "display": "restaurant", "key": "private-restaurant", "val": "building_features" },
				{ "display": "bar", "key": "bar", "val": "building_features" },
				{ "display": "coffee shop", "key": "coffee-shop", "val": "building_features" },
				{ "display": "laundry room", "key": "laundry", "val": "building_features" },
				{ "display": "library", "key": "library", "val": "building_features" },
				{ "display": "game room", "key": "game-room", "val": "building_features" },
				{ "display": "business center", "key": "business-center", "val": "building_features" },
				{ "display": "community room", "key": "community-room", "val": "building_features" },
				{ "display": "media room", "key": "media-room", "val": "building_features" }
			]
		},
		{
			"name": "outdoor space",
			"values": [
				{ "display": "garden", "key": "garden", "val": "building_features" },
				{ "display": "terrace", "key": "terrace", "val": "building_features" },
				{ "display": "roof deck", "key": "roof-deck", "val": "building_features" },
				{ "display": "courtyard", "key": "courtyard", "val": "building_features" },
				{ "display": "balcony", "key": "balcony", "val": "building_features" },
				{ "display": "patio", "key": "patio", "val": "building_features" },
				{ "display": "porch", "key": "porch", "val": "building_features" },
				{ "display": "playground", "key": "playground", "val": "building_features" },
				{ "display": "outdoor entertainment space", "key": "outdoor-entertainment", "val": "building_features" },
				{ "display": "outdoor kitchen", "key": "outdoor-kitchen", "val": "building_features" },
				{ "display": "outdoor recreation area", "key": "outdoor-recreation-area", "val": "building_features" },
				{ "display": "outdoor grill", "key": "outdoor-grill", "val": "building_features" },
				{ "display": "rooftop terrace", "key": "rooftop-terrace", "val": "building_features" },
				{ "display": "backyard", "key": "backyard", "val": "building_features" }
			]
		},
		{
			"name": "health and fitness",
			"values": [
				{ "display": "fitness center", "key": "fitness-center", "val": "building_features" },
				{ "display": "basketball court", "key": "basketball-court", "val": "building_features" },
				{ "display": "tennis court", "key": "tennis-court", "val": "building_features" },
				{ "display": "squash court", "key": "squash-court", "val": "building_features" },
				{ "display": "golf simulator", "key": "golf-simulator", "val": "building_features" },
				{ "display": "bowling alley", "key": "bowling-alley", "val": "building_features" },
				{ "display": "swimming pool", "key": "swimming-pool", "val": "building_features" },
				{ "display": "sauna", "key": "sauna", "val": "building_features" },
				{ "display": "steam room", "key": "steam", "val": "building_features" },
				{ "display": "spa", "key": "spa", "val": "building_features" },
				{ "display": "yoga studio", "key": "yoga-studio", "val": "building_features" },
				{ "display": "fitness room", "key": "fitness-room", "val": "building_features" },
				{ "display": "health club", "key": "health-club", "val": "building_features" },
				{ "display": "exercise room", "key": "exercise-room", "val": "building_features" },
				{ "display": "locker rooms", "key": "locker-rooms", "val": "building_features" },
				{ "display": "yoga room", "key": "yoga-room", "val": "building_features" },
				{ "display": "hot tub", "key": "hot-tub", "val": "building_features" }
			]
		},
		{
			"name": "security and maintenance",
			"values": [
				{ "display": "alarm system", "key": "alarm-system", "val": "building_features" },
				{ "display": "sprinkler system", "key": "sprinkler-system", "val": "building_features" },
				{ "display": "video security", "key": "video-security", "val": "building_features" },
				{ "display": "virtual doorman", "key": "virtual-doorman", "val": "building_features" },
				{ "display": "LEED Certified", "key": "leed-certification", "val": "building_features" },
				{ "display": "Green Building", "key": "green", "val": "building_features" },
				{ "display": "fireplace", "key": "fireplace", "val": "building_features" },
				{ "display": "security cameras", "key": "security-cameras", "val": "building_features" },
				{ "display": "video intercom", "key": "video-intercom", "val": "building_features" },
				{ "display": "smoke-free", "key": "smoke-free", "val": "building_features" }
			]
		},
		{
			"name": "storage",
			"values": [
				{ "display": "basement storage", "key": "basement-storage", "val": "building_features" },
				{ "display": "package room", "key": "package-room", "val": "building_features" },
				{ "display": "bike storage", "key": "bike-storage", "val": "building_features" },
				{ "display": "storage", "key": "storage", "val": "building_features" },
				{ "display": "refrigerated storage", "key": "refrigerated-storage", "val": "building_features" },
				{ "display": "cold storage", "key": "cold-storage", "val": "building_features" },
				{ "display": "private storage", "key": "private-storage", "val": "building_features" },
				{ "display": "storage room", "key": "storage-room", "val": "building_features" }
			]
		},
		{
			"name": "parking",
			"values": [
				{ "display": "garage", "key": "garage", "val": "building_features" },
				{ "display": "parking", "key": "parking", "val": "building_features" },
				{ "display": "driveway", "key": "driveway", "val": "building_features" },
				{ "display": "valet", "key": "valet", "val": "building_features" },
				{ "display": "on-site parking", "key": "on-site-parking", "val": "building_features" },
				{ "display": "garage parking", "key": "garage-parking", "val": "building_features" },
				{ "display": "valet parking", "key": "valet-parking", "val": "building_features" }
			]
		},
		{
			"name": "building policies",
			"values": [
				{ "display": "pets allowed", "key": "pets-allowed", "val": "building_features" },
				{ "display": "cats only", "key": "cats-allowed", "val": "building_features" },
				{ "display": "corporate owners allowed", "key": "corporate-owner-allowed", "val": "building_features" },
				{ "display": "pied-a-terre allowed", "key": "pied-a-terre", "val": "building_features" },
				{ "display": "open houses allowed", "key": "open-houses-allowed", "val": "building_features" },
				{ "display": "sublets allowed", "key": "sublet-allowed", "val": "building_features" },
				{ "display": "guarantors allowed", "key": "guarantors-allowed", "val": "building_features" },
				{ "display": "parents buying allowed", "key": "parents-buying-allowed", "val": "building_features" },
				{ "display": "shares allowed", "key": "shares-allowed", "val": "building_features" }
			]
		}
	],
	filterOtherAmenities : ["concirege", "dooman_full_time", "swiming_pool", "prewar", "pre_war", "pre-war", "per_war", "pre-war-building", "post_war", "post war", "post-war", "hight_rise", "high-rise", "hight rise", "high-rise", "hight_rise", "high rise", "low_rise", "low-rise", "low rise", "colonial", "vacant", "new-construction", "commercial", "landmark", "single-occupancy", "ranch", "contemporary",], 
	buildingSnapshotFields: [
		{
			display: "building type",
			id: "buildingType",
			val: solrFieldObj.buildings["buildingType"],
			rowStart: true,
			rowEnd: 1,
			cols: 2,
			dropdown: true,
			secondDropdown: true,
			secondDropdownTypes: ['hybrid', 'publicFacilities'],
			secondDropdownBtn: "Add another type",
			hybridDropdown: {
				display: "secondary type",
				id: "buildingTypeSecondary",
				val: solrFieldObj.buildings["buildingTypeSecondary"],
				cols: 2,
				dropdownVals: ["hotel", "office", "retail", "rental", "public housing", "reset"]
			},
			publicFacilitiesDropdown: {
				display: "type of facility",
				id: "buildingTypeSecondary",
				val: solrFieldObj.buildings["buildingTypeSecondary"],
				cols: 2,
				dropdownVals: [
					"hospital",
					"museum",
					"library",
					"firehouse",
					"police station",
					"education",
					"stadium",
					"park",
					"religious",
					"transportation",
					"utility",
					"reset"
				]
			},
			dropdownVals: [
				"condo",
				"co-op",
				"condop",
				"timeshare",
				"single family",
				"multi-family",
				"commercial",
				"rental",
				"retail",
				"office",
				"industrial",
				"hotel",
				"parking",
				"landmark",
				"public facilities",
				"vacant land",
				"reset"
			]
		},
		
/* ROW */
		{
			display: "building name",
			id: "buildingName",
			val: solrFieldObj.buildings["buildingName"],
			cols: 2,
			rowStart: true,
			rowEnd: 2
		},
		{
			display: "website",
			id: "website",
			val: solrFieldObj.buildings["website"],
			cols: 2
		},

/* ROW */
		{
			display: "primary address",
			id: "displayAdr",
			val: solrFieldObj.buildings["displayAdr"],
			cols: 2,
			rowStart: true,
			rowEnd: 3
		},
		{
			display: "year built",
			id: "yrBuilt",
			val: solrFieldObj.buildings["yrBuilt"],
			cols: 4,
			type: "number"
		},

		{
			display: "year converted",
			id: "yrConverted",
			val: solrFieldObj.buildings["yrConverted"],
			cols: 4,
			type: "number"
		},
		// {
		// 	display: "construction type",
		// 	id: "constructionType",
		// 	val: solrFieldObj.buildings["constructionType"],
		// 	cols: 4,
		// },
		
/* ROW */
		{
			display: "floors",
			id: "floors",
			val: solrFieldObj.buildings["floors"],
			cols: 4,
			rowStart: true,
			rowEnd: 4,
			type: "number",
			
		},
		{
			display: "# of units",
			id: "unitsCount",
			val: solrFieldObj.buildings["unitsCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "notHouse"
			}
		},
		{
			display:
				"# of condo units",
			id: "unitsSellableCount",
			val: solrFieldObj.buildings["unitsSellableCount"],
			cols: 4,
			
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "condo"
			}
		},
		{
			display:
				"# of co-op units",
			id: "unitsSellableCount",
			val: solrFieldObj.buildings["unitsSellableCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				valOR: ["coop", "co-op"]
			}
		},
		
		
		
/* ROW */	
		{
			display: "# of residential units",
			id: "unitsResidentialCount",
			val: solrFieldObj.buildings["unitsResidentialCount"],
			cols: 4,
			rowStart: true,
			rowEnd: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: ["residential", "notHouse"]
			}
		},
		{
			display:
				"# of commercial units",
			id: "unitsCommercialCount",
			val: solrFieldObj.buildings["unitsCommercialCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				valOR: ['commercial', 'retail', 'office', 'industrial']
			}
		},
		{
			display: "# of rental units",
			id: "unitsRentalCount",
			val: solrFieldObj.buildings["unitsRentalCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "rental"
			}
		},
		{
			display: "# of hotel units",
			id: "unitsHotelCount",
			val: solrFieldObj.buildings["unitsHotelCount"],
			cols: 4,
			type: "number",
			// rowStart: true,
			// rowEnd: 4,
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "hotel"
			}
		},

/* ROW */		
		{
			display: "# of affordable units (sale)",
			id: "unitsAffordableSaleCount",
			val: solrFieldObj.buildings["unitsAffordableSaleCount"],
			cols: 4,
			rowStart: true,
			rowEnd: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				valOR: ["condo", "coop", "condop"]
			}
		},
		{
			display: "# of affordable units (rent)",
			id: "unitsAffordableRentCount",
			val: solrFieldObj.buildings["unitsAffordableRentCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "rental"
			}
		},
		{
			display: "# of market units (rent)",
			id: "unitsRentalMarketCount",
			val: solrFieldObj.buildings["unitsRentalMarketCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "rental"
			}
		},
		{
			display: "# of timeshare units",
			id: "unitsTimeshareCount",
			val: solrFieldObj.buildings["unitsTimeshareCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "timeshare"
			}
		},
/* ROW */
		{
			display: "# of storage units",
			id: "unitsStorageCount",
			val: solrFieldObj.buildings["unitsStorageCount"],
			rowStart: true,
			rowEnd: 2,
			cols: 4,
			type: "number"
		},
		{
			display: "# of parking spaces",
			id: "unitsParkingCount",
			val: solrFieldObj.buildings["unitsParkingCount"],
			cols: 4,
			type: "number"
		},

/* ROW */
		
		{
			display: "year construction started",
			id: "yrBuiltStarted",
			val: solrFieldObj.buildings["yrBuiltStarted"],
			cols: 4,
			rowStart: true,
			rowEnd: 3,
			type: "number"
		},
		{
			display: "year construction finished",
			id: "yrBuiltFinished",
			val: solrFieldObj.buildings["yrBuiltFinished"],
			cols: 4,
			type: "number"
		},
		{
			display: "year sales started",
			id: "yrSalesStarted",
			val: solrFieldObj.buildings["yrSalesStarted"],
			cols: 4,
			type: "number"
		}
		
		
	],
	buildingMoreFields: [
		{
			display: "# of hotel units",
			id: "unitsHotelCount",
			val: solrFieldObj.buildings["unitsHotelCount"],
			cols: 4,
			type: "number",
			rowStart: true,
			rowEnd: 4,
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "hotel"
			}
		},
		{
			display: "# of hotel condo units",
			id: "unitsHotelCondoCount",
			val: solrFieldObj.buildings["unitsHotelCondoCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: ["hotel", "condo"]
			}
		},
		{
			display: "# of affordable units (sale)",
			id: "unitsAffordableSaleCount",
			val: solrFieldObj.buildings["unitsAffordableSaleCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "residential"
			}
		},
		{
			display: "# of affordable units (rent)",
			id: "unitsAffordableRentCount",
			val: solrFieldObj.buildings["unitsAffordableRentCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "residential"
			}
		},
		{
			display: "# of market units (rent)",
			id: "unitsRentalMarketCount",
			val: solrFieldObj.buildings["unitsRentalMarketCount"],
			cols: 4,
			type: "number",
			rowStart: true,
			rowEnd: 4,
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "rental"
			}
		},
		{
			display: "# of timeshare units",
			id: "unitsTimeshareCount",
			val: solrFieldObj.buildings["unitsTimeshareCount"],
			cols: 4,
			type: "number",
			show: {
				name: [
					solrFieldObj.buildings["buildingType"],
					solrFieldObj.buildings["buildingTypeSecondary"]
				],
				val: "timeshare"
			}
		},
		{
			display: "# of storage units",
			id: "unitsStorageCount",
			val: solrFieldObj.buildings["unitsStorageCount"],
			cols: 4,
			type: "number"
		},
		{
			display: "# of parking spaces",
			id: "unitsParkingCount",
			val: solrFieldObj.buildings["unitsParkingCount"],
			cols: 4,
			type: "number"
		},
		// {
		// 	display: "architect",
		// 	id: "architects",
		// 	val: solrFieldObj.buildings["architects"],
		// 	cols: 2,
		// 	rowStart: true,
		// 	rowEnd: 2
		// },
		// {
		// 	display: "developer",
		// 	id: "developers",
		// 	val: solrFieldObj.buildings["developers"],
		// 	cols: 2
		// },

		// {
		// 	display: "owner",
		// 	id: "owner",
		// 	val: solrFieldObj.buildings["owner"],
		// 	cols: 2,
		// 	rowStart: true,
		// 	rowEnd: 2
		// },
		// {
		// 	display: "interior designer",
		// 	id: "interiorDesigners",
		// 	val: solrFieldObj.buildings["interiorDesigners"],
		// 	cols: 2
		// },

		// {
		// 	display: "property manager",
		// 	id: "manager",
		// 	val: solrFieldObj.buildings["manager"],
		// 	cols: 2,
		// 	rowStart: true,
		// 	rowEnd: 2
		// },
		// {
		// 	display: "sales office",
		// 	id: "salesOffice",
		// 	val: solrFieldObj.buildings["salesOffice"],
		// 	cols: 2
		// },
		{
			display: "year construction started",
			id: "yrBuiltStarted",
			val: solrFieldObj.buildings["yrBuiltStarted"],
			cols: 4,
			rowStart: true,
			rowEnd: 3,
			type: "number"
		},
		{
			display: "year construction finished",
			id: "yrBuiltFinished",
			val: solrFieldObj.buildings["yrBuiltFinished"],
			cols: 4,
			type: "number"
		},
		{
			display: "year sales started",
			id: "yrSalesStarted",
			val: solrFieldObj.buildings["yrSalesStarted"],
			cols: 4,
			type: "number"
		}
	],

// PROPERTY/UNIT FIELDS
	unitSnapshotFields: [
		{
			display: "unit type",
			id: "unitType",
			val: solrFieldObj.properties["propertyType"],
			cols: 2,
			rowStart: true,
			rowEnd: 1,
			dropdown: true,
			secondDropdown: false,
			formatFunction: _formatPropertyType,
			dropdownVals: [
				"condo unit",
				"co-op unit",
				"condop unit",
				"rental unit",
				"storage room",
				"parking space",
				"retail unit",
				"commercial unit",
				"office unit",
				"other",
				"reset"
			]

		},
		{
			display: "bedrooms",
			id: "bedrooms",
			val: solrFieldObj.properties["bedrooms"],
			cols: 3,
			rowStart: true,
			rowEnd: 3,
			plusMinusBtn: true
		},
		{
			display: "full baths",
			id: "bathrooms",
			val: solrFieldObj.properties["bathrooms"],
			cols: 3,
			type: "number",
			plusMinusBtn: true
		},
		// {display: "unit number", id:'unitDisplay', val: solrFieldObj.properties["unitDisplay"], cols: 3},
		{ display: "line", id:'unitLine', val: solrFieldObj.properties['unitLine'], cols: 3 },
		{
			display: "total rooms",
			id: "roomsCount",
			val: solrFieldObj.properties["roomsCount"],
			cols: 3,
			type: "number",
			rowStart: true,
			rowEnd: 3,
			plusMinusBtn: true
		},
		{
			display: "1/2 baths",
			id: 'bathroomsHalf',
			val: solrFieldObj.properties["bathroomsHalf"],
			cols: 3,
			type: "number",
			plusMinusBtn: true
		},
		// { display: "line", id:'unitLine', val: solrFieldObj.properties['unitLine'], cols: 3 },
		{ display: "floor number", id: 'floorNum', val: solrFieldObj.properties["floorNum"], cols: 3, type: "number" },
		{
			display: "interior square feet",
			id:'interiorSqFt',
			val: solrFieldObj.properties["sqFtInterior"],
			val: "sq_ft",
			cols: 3,
			type: "number",
			rowStart: true,
			rowEnd: 2
		},
		{
			display: "exterior square feet",
			id:'interiorSqFt',
			val: solrFieldObj.properties["sqFtExterior"],
			cols: 3,
			type: "number"
		},
		// { display: "floor number", id: 'floorNum', val: solrFieldObj.properties["floorNum"], cols: 3, type: "number" }

	],
	singleFamilyHouseSnapshotFields: [
		{
			display: "building type",
			id: "buildingType",
			val: solrFieldObj.buildings["buildingType"],
			// val: "ownership_type",
			cols: 2,
			rowStart: true,
			rowEnd: 1,
			dropdown: true,
			secondDropdown: true,
			secondDropdownTypes: ['hybrid', 'publicFacilities'],
			secondDropdownBtn: "Add another type",
			hybridDropdown: {
				display: "secondary type",
				id: "buildingTypeSecondary",
				val: solrFieldObj.buildings["buildingTypeSecondary"],
				cols: 2,
				dropdownVals: ["hotel", "office", "retail", "rental", "public housing", "reset"]
			},
			publicFacilitiesDropdown: {
				display: "type of facility",
				id: "buildingTypeSecondary",
				val: solrFieldObj.buildings["buildingTypeSecondary"],
				cols: 2,
				dropdownVals: [
					"hospital",
					"museum",
					"library",
					"firehouse",
					"police station",
					"education",
					"stadium",
					"park",
					"religious",
					"transportation",
					"utility",
					"reset"
				]
			},
			dropdownVals: [
				"condo",
				"co-op",
				"condop",
				"timeshare",
				"single family",
				"multi-family",
				"commercial",
				"rental",
				"retail",
				"office",
				"industrial",
				"hotel",
				"parking",
				"landmark",
				"public facilities",
				"vacant land",
				"reset"
			]
		},
		{
			display: "bedrooms",
			id: "bedrooms",
			val: solrFieldObj.houses["bedrooms"],
			cols: 4,
			rowStart: true,
			rowEnd: 4,
			plusMinusBtn: true
		},
		{
			display: "full baths",
			id: "bathrooms",
			val: solrFieldObj.houses["bathrooms"],
			cols: 4,
			type: "number",
			plusMinusBtn: true
		},
		{
			display: "1/2 baths",
			id: 'bathroomsHalf',
			val: solrFieldObj.houses["bathroomsHalf"],
			cols: 4,
			type: "number",
			plusMinusBtn: true
		},
		{
			display: "total rooms",
			id: "roomsCount",
			val: solrFieldObj.houses["roomsCount"],
			cols: 4,
			type: "number",
			plusMinusBtn: true
		},
		{
			display: "interior square feet",
			id:'interiorSqFt',
			val: solrFieldObj.houses["sqFtInterior"],
			val: "sq_ft",
			rowStart: true,
			rowEnd: 4,
			cols: 4,
			type: "number",
		},
		{
			display: "exterior square feet",
			id:'interiorSqFt',
			val: solrFieldObj.houses["sqFtExterior"],
			cols: 4,
			type: "number"
		},
		{
			display: "floors",
			id: "floors",
			val: solrFieldObj.buildings["floors"],
			cols: 4,
			type: "number",
		},
		{ display: "year built", id: "yrBuilt", val: solrFieldObj.buildings["yrBuilt"], cols: 4, type: "number" },
		// {
		// 	display: "construction type",
		// 	id: "constructionType",
		// 	val: solrFieldObj.buildings["constructionType"],
		// 	cols: 4,
		// },
	],
	unitFeatures: [
		{
			name: "interior and finishes",
			values: [
				{ display: "hardwood floors", key: "hardwood-floors", val: "property_features" },
				{ display: "exposed brick", key: "exposed-brick", val: "property_features" },
				{ display: "fireplace", key: "fireplace", val: "property_features" },
				{ display: "walkin closet", key: "walkin-closet", val: "property_features" },
				{ display: "loft layout", key: "loft-layout", val: "property_features" },
				{ display: "double pane windows", key: "doublepane-windows", val: "property_features" },
				{ display: "oversized windows", key: "oversized-windows", val: "property_features" },
				{ display: "floor to ceiling windows", key: "floor-to-ceiling-windows", val: "property_features" },
				{ display: "corner unit", key: "corner-apartment", val: "property_features" },
				{ display: "penthouse unit", key: "penthouse", val: "property_features" },
			]
		},
		{
			name: "kitchen",
			values: [
				{ display: "modern kitchen", key: "modern-kitchen", val: "property_features" },
				{ display: "open kitchen", key: "open-kitchen", val: "property_features" },
				{ display: "eat-in kitchen", key: "eat-in-kitchen", val: "property_features" },
				{ display: "pass-through kitchen", key: "pass-through-kitchen", val: "property_features" },
				{ display: "pullman kitchen", key: "pullman-kitchen", val: "property_features" },
				{ display: "galley kitchen", key: "galley-kitchen", val: "property_features" },				
				{ display: "separate kitchen", key: "separate-kitchen", val: "property_features" },			
				{ display: "stone countertops", key: "stone-countertop", val: "property_features" },
			]
		},
		{
			name: "appliances",
			values: [
				{ display: "washer / dryer", key: "washer-dryer", val: "property_features" },
				{ display: "dishwasher", key: "dishwasher", val: "property_features" },
				{ display: "gas range", key: "gas-range", val: "property_features" },
				{ display: "microwave", key: "microwave", val: "property_features" },
				{ display: "trash compactor", key: "trash-compactor", val: "property_features" },
				{ display: "garbage disposal", key: "garbage-disposal", val: "property_features" },
				{ display: "stainless steel appliances", key: "stainless-steel-appliances", val: "property_features" },
			]
		},
		{
			name: "rooms",
			values: [
				{ display: "dining room", key: "dining-room", val: "property_features" },
				{ display: "living room", key: "living-room", val: "property_features" },
				{ display: "home office", key: "home-office", val: "property_features" },
				{ display: "foyer", key:"foyer", val: "property_features"},
				{ display: "den", key: "den", val: "property_features" },
				{ display: "library", key: "library", val: "property_features" },
				{ display: "dressing room", key: "dressing-area", val: "property_features" },
			
			]
		},
		{
			name: "views and exposures",
			values: [
				{ display: "city view", key: "city-view", val: "property_features" },
				{ display: "water view", key: "river-view", val: "property_features" },
				{ display: "park view", key: "park-view", val: "property_features" },
				{ display: "skyline view", key: "skyline-view", val: "property_features" },
				{ display: "northern exposure", key: "north-exposure", val: "property_features" },
				{ display: "southern exposure", key: "south-exposure", val: "property_features" },
				{ display: "eastern exposure", key: "east-exposure", val: "property_features" },
				{ display: "western exposure", key: "west-exposure", val: "property_features" }
			]
		},
		{
			name: "private outdoor space",
			values: [
				// { display: "garden", key: "private-garden", val: "property_features" },
				{ display: "terrace", key: "private-terrace", val: "property_features" },
				{ display: "roof deck", key: "private-roof-deck", val: "property_features" },
				{ display: "balcony", key: "private-balcony", val: "property_features" },
				{ display: "patio", key: "private-patio", val: "property_features" },
				{ display: "porch", key: "private-porch", val: "property_features" },
				// { display: "yard", key: "private-yard", val: "property_features" },
			]
		},
	],
	houseFeatures: [
		{
			name: "interior and finishes",
			values: [
				
				{ display: "exposed brick", key: "exposed-brick", val: "property_features" },
				{ display: "hardwood floors", key: "hardwood-floors", val: "property_features" },
				{ display: "fireplace", key: "fireplace", val: "property_features" },
				{ display: "walkin closet", key: "walkin-closet", val: "property_features" },
				{ display: "loft layout", key: "loft-layout", val: "property_features" },
				{ display: "double pane windows", key: "doublepane-windows", val: "property_features" },
				{ display: "oversized windows", key: "oversized-windows", val: "property_features" },
				{ display: "floor to ceiling windows", key: "floor-to-ceiling-windows", val: "property_features" },
			]
		},
		{
			name: "kitchen type",
			values: [
				{ display: "modern kitchen", key: "modern-kitchen", val: "property_features" },
				{ display: "open kitchen", key: "open-kitchen", val: "property_features" },
				{ display: "eat-in kitchen", key: "eat-in-kitchen", val: "property_features" },
				{ display: "pass-through kitchen", key: "pass-through-kitchen", val: "property_features" },
				{ display: "pullman kitchen", key: "pullman-kitchen", val: "property_features" },
				{ display: "galley kitchen", key: "galley-kitchen", val: "property_features" },				
				{ display: "separate kitchen", key: "separate-kitchen", val: "property_features" },
				{ display: "stone countertops", key: "stone-countertop", val: "property_features" },				
			]
		},
		{
			name: "appliances",
			values: [
				{ display: "washer / dryer", key: "washer-dryer", val: "property_features" },
				{ display: "dishwasher", key: "dishwasher", val: "property_features" },
				{ display: "gas range", key: "gas-range", val: "property_features" },
				{ display: "microwave", key: "microwave", val: "property_features" },
				{ display: "trash compactor", key: "trash-compactor", val: "property_features" },
				{ display: "garbage disposal", key: "garbage-disposal", val: "property_features" },
				{ display: "stainless steel appliances", key: "stainless-steel-appliances", val: "property_features" },
			]
		},
		{
			name: "rooms",
			values: [
				{ display: "dining room", key: "dining-room", val: "property_features" },
				{ display: "lounge", key: "lounge", val: "building_features" },
				{ display: "home office", key: "home-office", val: "property_features" },
				{ display: "living room", key: "living-room", val: "property_features" },
				{ display: "den", key: "den", val: "property_features" },
				{ display: "foyer", key:"foyer", val: "property_features"},
				{ display: "library", key: "library", val: "property_features" },
				{ display: "dressing room", key: "dressing-area", val: "property_features" },
				{ display: "basement", key: "basement", val: "property_features" },
				{ display: "attic", key: "attic", val: "property_features" },
			]
		},
		{
			name: "outdoor space",
			values: [
				{ display: "garden", key: "private-garden", val: "property_features", building: {display: "garden", key: "garden", val: "building_features"} },
				{ display: "terrace", key: "private-terrace", val: "property_features", building: {display: "terrace", key: "terrace", val: "building_features"} },
				{ display: "roof deck", key: "private-roof-deck", val: "property_features", building: {display: "roof deck", key: "roof-deck", val: "building_features"} },
				{ display: "balcony", key: "private-balcony", val: "property_features", building: {display: "balcony", key: "balcony", val: "building_features"} },
				{ display: "patio", key: "private-patio", val: "property_features", building: {display: "patio", key: "patio", val: "building_features"} },
				{ display: "porch", key: "private-porch", val: "property_features", building: {display: "porch", key: "porch", val: "building_features"} },
				{ display: "pool", key: "swimming-pool", val: "building_features" },
				{ display: "yard", key: "private-yard", val: "property_features", building: {display: "yard", key: "backyard", val: "building_features"} },
			]
		},
		{
			name: "access",
			values: [
				{ display: "elevator", key: 'elevator', val: "building_features" },
				{ display: "wheelchair accessible", key: "wheelchair-accessible", val: "building_features" },
				{ display: "walk-up", key: "walk-up", val: "building_features" },
				{ display: "wide doorways", key: "wide-doorways", val: "building_features" },
			]
		},
		{
			name: "auto",
			values: [
				{ display: "garage", key: "garage", val: "building_features" },
				{ display: "parking", key: "parking", val: "building_features" },
				{ display: "driveway", key: "driveway", val: "building_features" },
				{ display: "carport", key: "carport", val: "building_features" },
			]
		}
	]
}

