import React, { useState, Fragment } from "react";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge
} from 'reactstrap';

import { GoogleMap, LoadScript, Marker, InfoBox, InfoWindow, OverlayView, MarkerWithLabel } from '@react-google-maps/api';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus
} from '../../../../../shared/helpers/utils';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import Button from '@material-ui/core/Button';

 const _parseCoordinates = (coordinates) => {
  if (!coordinates) {
	return;
  }
  let mapPoints = {};
  if (typeof coordinates === "string") {
	mapPoints.lat = parseFloat(
	  coordinates.substring(0, coordinates.indexOf(","))
	);
	mapPoints.long = parseFloat(
	  coordinates.substring(coordinates.indexOf(",") + 1, coordinates.length)
	);
  } else if (coordinates.lat) {
	mapPoints.lat = parseFloat(coordinates.lat);
	mapPoints.long = parseFloat(coordinates.lng);
  } else if (coordinates.coordinates && coordinates.coordinates.length > 1) {
	mapPoints.lat = coordinates.coordinates[1];
	mapPoints.long = coordinates.coordinates[0];
  } else {
	mapPoints.lat = coordinates[0];
	mapPoints.long = coordinates[1];
  }
  return {lat : mapPoints.lat, lng: mapPoints.long};

  //coordinates.split(", ").map(coord => Number(coord)).reverse()
};

const styles = {
  default: [
	{ stylers: [{ hue: "#009ECB" }, { saturation: -60 }] },
	{
	  //points of interest
	  featureType: "poi.business",
	  stylers: [{ visibility: "off" }]
	},
	{
	  featureType: "transit",
	  elementType: "labels.icon",
	  stylers: [{ visibility: "off" }]
	}
  ],
  listing: [
	{ stylers: [{ hue: "#009ECB" }, { saturation: -60 }] },
	{
	  //points of interest
	  featureType: "poi.business",
	  stylers: [{ visibility: "on" }]
	},
	{
	  featureType: "transit",
	  elementType: "labels.icon",
	  stylers: [{ visibility: "off" }]
	}
  ]
};

const map_marker = {
  path:
	"M242.606,0C142.124,0,60.651,81.473,60.651,181.955s151.631,303.257,181.956,303.257c30.326,0,181.955-202.775,181.955-303.257S343.089,0,242.606,0zM242.606,303.257c-66.9,0-121.302-54.433-121.302-121.302S175.706,60.651,242.606,60.651c66.902,0,121.302,54.435,121.302,121.304S309.509,303.257,242.606,303.257z",
  fillColor: "#376ee2",
  fillOpacity: 0.9,
  strokeWeight: 1,
  scale: 0.06
};

const listingMarker = {
  path:
	"m1152,640q0,-106 -75,-181t-181,-75t-181,75t-75,181t75,181t181,75t181,-75t75,-181zm256,0q0,109 -33,179l-364,774q-16,33 -47.5,52t-67.5,19t-67.5,-19t-46.5,-52l-365,-774q-33,-70 -33,-179q0,-212 150,-362t362,-150t362,150t150,362z",
  fillColor: "#780F9E",
  fillOpacity: .9,
  // strokeWeight: 1,
  scale: 0.025,
};
const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
	return {
		x: offsetWidth + labelAnchor.x,
		y: offsetHeight + labelAnchor.y,
	};
};
const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl:false,
  streetViewControl:false,
  mapTypeControl:false,
  styles:  styles.default
};
const GMap = ( props ) => {
	const { locations, slideNumber, containerStyle, mapLoaded } = props;
	if(!locations) return null;
	const [mapRef, setMapRef] = useState(null);
	const [infoOpen, setInfoOpen] = useState(false);
	const [item, setItem] = useState(null);
	const [markerMap, setMarkerMap] = useState({});
	React.useEffect(() => {
		
	}, [mapRef])	
	const latLng = _parseCoordinates(locations[0].geocode);
	const fitBounds = map => {
		const bounds = new window.google.maps.LatLngBounds();
		locations.forEach(loc => {
			var coords = _parseCoordinates(loc.geocode);
			bounds.extend(coords);
		  });
		map.fitBounds(bounds);
	};
	const loadHandler = map => {
		// Store a reference to the google map instance in state
		setMapRef(map);
		// Fit map bounds to contain all markers
		fitBounds(map);
		mapLoaded(true);
	};
	const markerLoadHandler = (marker, loc) => {
		return setMarkerMap(prevState => {
		  return { ...prevState, [loc.key]: marker };
		});
	  };
	const markerClickHandler = (event, loc) => {
		// Remember which place was clicked
		setItem(loc);
	
		// Required so clicking a 2nd marker works as expected
		if (infoOpen) {
		  setInfoOpen(false);
		}
	
		setInfoOpen(true);
	
	  };
	const getMarkerIconColor = (status) => {
		let color = "#780F9E";
		if(status == "Resales"){
			color = "#28C2FF";
		}
		if(status == "Sold Out"){
			color = "#FF691E";
		}
		if(status == "Closings/Resales"){
			color = "#F53246";
		}
		if(status == "Closings/Resales"){
			color = "#F53246";
		}
		if(status == "Closings"){
			color = "#FFD23F";
		}
		
		return color;
	}
	return (
		
		  <GoogleMap
			mapContainerStyle={containerStyle}
			center={latLng}
			zoom={10}
			onLoad={loadHandler}
			options={ defaultMapOptions}
			defaultOptions={defaultMapOptions}
		  >
			{locations.slice(0).reverse().map((loc, i) => {
				const buildingNameOrAdr = loc.name 
				  ? _capitalizeText(loc.name)
				  : loc.display_full_street_address
					? _capitalizeText(loc.display_full_street_address)
					: _capitalizeText(loc.full_street_address);
					
				return (
					<Fragment>
		
					  	<OverlayView
						  	key='mwl'
						  	position={_parseCoordinates(loc.geocode)}
						  	mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
						  	//getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {     x: -30, y: -15 })}
							>
						  	<div
							  	style={{
								  	background: getMarkerIconColor(loc.elliman_status),
									border:'1px solid #FFF',
								  	width:'25px',
									height:'25px',
								  	fontSize: '11px',
									fontWeight:'800',
								  	color: `#FFF`,
								  	borderRadius: '50%',
									textAlign:'center',
									lineHeight:'23px'
							  	}}
						  	>
							  	{locations.length - i}
						  	</div>
					  	</OverlayView>
					</Fragment>
					
				)
				
			})}
			
		  </GoogleMap>
		
	)
	
}
const Map = ( { locations, height, slideNumber, mapLoaded} ) => {
	const containerStyle = {
	  width: '100%',
	  height: height ? height : `300px`
	};
	return( 
	  
	  <GMap 
		locations={locations}
		isListing={true}
		isMarkerShown={true}
		googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEYB3e7_LMx9R5aEx3y8w99Cv2s3XJy_o&libraries=geometry,drawing,places"
		loadingElement={<div style={{ height: `100%` }} />}
		containerElement={<div style={{ height: `100%` }} />}
		mapElement={<div style={{ height: height ? height : `300px` }} />}
		mapPadding={undefined}
		slideNumber={slideNumber}
		containerStyle={containerStyle}
		mapLoaded={mapLoaded}
	  />

	);
};
export default Map;
