import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Checkbox from '@material-ui/core/Checkbox';
import UncheckedIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import CheckedIcon from 'mdi-react/CheckboxMarkedIcon';
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon';
import HeartIcon from 'mdi-react/HeartIcon';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CollectionTags from './CollectionTags';
import EditIcon from 'mdi-react/PencilOutlineIcon';
import moment from 'moment';
import RichTextEditor from "../../../shared/components/form/RichTextEditor";
import NotifyOnIcon from "mdi-react/BellIcon";
import NotifyOffIcon from "mdi-react/BellOffIcon";
const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	overflowY: 'auto',
	width: '100%',
	maxHeight: 'calc(100vh - 420px)',
	'& label.Mui-focused': {
	  color: '#780F9E',
	},
	'& .MuiInput-underline:after': {
	  borderBottomColor: '#780F9E',
	},
	'& .MuiOutlinedInput-root': {
	  
	  '& fieldset': {
		borderColor: '#780F9E',
	  },
	  '&:hover fieldset': {
		borderColor: '#780F9E',
	  },
	  '&.Mui-focused fieldset': {
		borderColor: '#780F9E',
	  },
	}
  },
  formControl: {
	margin: 0,
	width: '100%'
  },
  helpText:{
	  marginBottom:10
  },
  chip: {
	  margin: theme.spacing(0.5),
	},
}));

const PurpleSwitch = withStyles({
  switchBase: {
	color: '#A3ADC2',
   
	'&$checked': {
	  color: '#A3ADC2',
	},
	'&$checked + $track': {
	  backgroundColor: '#D1D6E0',
	},
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);


	
const editorConfig = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		height:300,
		allowResizeX: false,
		enableDragAndDropFileToEditor: true,
		buttons: [
			'bold',
			'italic',
			'underline',			
			'fontsize',
			'|',
			'link', 'hr', 'align', 
			'|',
			'undo', 'redo'
		],
		buttonsMD: [
			'bold',
			'italic',
			'underline',			
			'fontsize',
			'|',
			'link', 'hr', 'align', 
			'|',
			'undo', 'redo'
		],
		buttonsXS: [
			'bold',
			'italic',
			'underline',			
			'fontsize',
			'|',
			'link', 'hr', 'align', 
			'|',
			'undo', 'redo'
		],
		colorPickerDefaultTab: 'text',
		
}


export default function CollectionList(props) {
  const { buildingRes, property, groups, updateGroups, add, edit, addMultiple, selectedUnits, user, copy } = props;
  const classes = useStyles();
  const [ notifyOn, setNotify ] = useState(groups && groups[0] && groups[0]['notify'] && groups[0]['notify'] == true);
  const [ isPublic, setPublic ] = useState(groups && groups[0] && groups[0]['group_access'] && groups[0]['group_access'] == "public");
  const [ tag, setTag ] = useState(false);
  const [chipData, setChipData] = React.useState([
	  { key: 0, label: 'Angular' },
	  { key: 1, label: 'jQuery' },
	  { key: 2, label: 'Polymer' },
	  { key: 3, label: 'React' },
	  { key: 4, label: 'Vue.js' },
	]);
  const handleChange = (event, i) => {
	  let newGroupState = groups.slice();
	  newGroupState[i]['group_name'] = event.target.value;
	  updateGroups(newGroupState)

  };
  const handleChangeNotify = (event) => {
		//event.preventDefault();
		//event.stopPropagation();
		let newGroupState = groups.slice();
		
		if(notifyOn){
			setNotify(false);
			newGroupState[0]['notify'] = false;		  
			
		}else{
			setNotify(true);
			newGroupState[0]['notify'] = true;		 
		}
		updateGroups(newGroupState)
	};
  const handleChangeAccess = (event) => {
		let newGroupState = groups.slice();
		
		if(isPublic){
			setPublic(false);
			newGroupState[0]['group_access'] = "private";		  
			
		}else{
			setPublic(true);
			newGroupState[0]['group_access'] = "public";		 
		}
		updateGroups(newGroupState)
		
		
	};
	const handleChangeDescription = (content) => {
		  let newGroupState = groups.slice();
		
		   newGroupState[0]['group_description'] = content;
		   updateGroups(newGroupState)
		 
		  
	};
	const handleTagChange = (tags) => {		
		let newGroupState = groups.slice();
		newGroupState[0]['group_tags'] = tags;
		updateGroups(newGroupState)
	};
	
  const handleClickOut = ( i ) =>{
	  let newGroupState = groups.slice();
	  newGroupState[i]['edit'] = !newGroupState[i]['edit'];
	  updateGroups(newGroupState)
  }
  const editGroup = ( i ) => {
	   let newGroupState = groups.slice();
	   
	   newGroupState.map(grp => {
		   delete grp['edit']
	   })
	   console.log(newGroupState, i);
	   newGroupState[i]['edit'] = true;
	   
	   updateGroups(newGroupState)
	   
  }
  const selectGroup = ( i ) => {
		 let newGroupState = groups.slice();
		 
		 if(addMultiple){
			 if(selectedUnits){
				 const multiSelected = isSelected(i);
				 Object.keys(selectedUnits).map(key => {
					 const item = selectedUnits[key];
					 
					 if(item.address_2_normalized !== undefined){
						if(multiSelected){				   
							 groups[i]['group_data']['units'].splice(groups[i]['group_data']['units'].indexOf(item.key), 1);
						}else{
							 groups[i]['group_data']['units'].push(item.key);
						}
					 }else{
						if(multiSelected){				   
							groups[i]['group_data']['buildings'].splice(groups[i]['group_data']['buildings'].indexOf(item.key), 1);
						}else{
							groups[i]['group_data']['buildings'].push(item.key);
						}
					 }
				 })
			 }
		 }else{
			 if(property){
				if(groups[i]['group_data']['units'].indexOf(property.key)>=0){				   
					groups[i]['group_data']['units'].splice(groups[i]['group_data']['units'].indexOf(property.key), 1);
				}else{
					groups[i]['group_data']['units'].push(property.key);
				}
			 }else if(buildingRes){
				if(groups[i]['group_data']['buildings'].indexOf(buildingRes.key)>=0){				   
				   groups[i]['group_data']['buildings'].splice(groups[i]['group_data']['buildings'].indexOf(buildingRes.key), 1);
			   }else{
				   groups[i]['group_data']['buildings'].push(buildingRes.key);
			   }
			 }
		 }
		 
		 groups[i]['group_data']['units'].filter((value, index, self) => self.indexOf(value) === index);
		 groups[i]['group_data']['buildings'].filter((value, index, self) => self.indexOf(value) === index);
		 updateGroups(newGroupState)
		 
  }
  
  const isSelected = ( i ) => {
	  if(!groups[i] || !groups[i]['group_data']) return false;
	  
	  if(addMultiple){
		   if(selectedUnits){
			   let multiSelected = true;
			   Object.keys(selectedUnits).map(key => {
				   const item = selectedUnits[key];
				   
				   if(item.address_2_normalized !== undefined){
					 	if(groups[i]['group_data']['units'] && groups[i]['group_data']['units'].indexOf(item.key)<0){
							 multiSelected = false;
						}
				   }else{
					   if(groups[i]['group_data']['buildings'] && groups[i]['group_data']['buildings'].indexOf(item.key)<0){
							multiSelected = false;
					   }
				   }
			   })
			   
			   return multiSelected;
		   }
	   }else{
	  		if(property){
		  		return groups[i]['group_data']['units'] && groups[i]['group_data']['units'].indexOf(property.key)>=0
	  		}else if(buildingRes){
		  		return groups[i]['group_data']['buildings'] && groups[i]['group_data']['buildings'].indexOf(buildingRes.key)>=0
	  		}
	  }
	  return false;
  }
  return (
	<div className={`${classes.root}`}>
	  
	  <FormControl component="fieldset" className={classes.formControl}>
		  
		  {!add && !edit && !copy && groups && groups.length > 0 ? 
			<Fragment>  
				{groups.map((group, i) => {
			  	return (
				  	<div className="collection">
					  	<div className="collection-info">
							  
							  
							  {group.edit ? 
								  	<TextField 
									  	id="empty-collection" 
										inputRef={(input) => {
									  	if(input != null) {
										 	input.focus();
									  	}
										}}
										label="Collection Name" 
										variant="outlined" 
										value={group.group_name}
										autoComplete={'off'}
										autoFocus
										style={{width:'100%'}}
										onChange={(event)=>handleChange(event, i)}
										onBlur={()=>handleClickOut(i)}
										onKeyPress= {(e) => {
											if (e.key === 'Enter') {
											  handleClickOut(i);
											  // write your functionality here
											}
										}}
										/>
								: 
								<Fragment>
									<div>{group.group_name}</div>
									<div>
								   	{group.created_timestamp ?
									   <span className="small-text light-text">Created: {moment(group.created_timestamp).format('M/D/YYYY')}</span>
									   :
									   <span className="small-text light-text">Created: {moment().format('M/D/YYYY')}</span>
									}
									<span className="small-text light-text">{' | '}</span>
								   	<span onClick={() => {editGroup(i)}} className="link-text small-text light-text" style={{fontSize:11,cursor:"pointer"}}>Rename</span>
							    	</div> 
						  		</Fragment>
							  }
							 
						</div>
					  	<div>
					  	<Checkbox 
						    onClick={() => {selectGroup(i)}} 
						  	checked={isSelected(i)} 
							icon={<UncheckedIcon color={"#9600BF"} />}
						  	checkedIcon={<CheckedIcon color={"#9600BF"} />}
						/>
					  	</div>
				  	</div>
			  	)
		  	 })}
			</Fragment>
			: add || edit || copy ?
			<Fragment>  
				<div className="collection">
				  <TextField 
				  	  id="empty-collection" 
					 
					  label="Collection Name" 
					  variant="outlined" 
					  value={groups[0]['group_name']} 
					  autoComplete={'off'}
					  style={{width:'100%'}}
					  onChange={(event)=>handleChange(event, 0)}
					  />
			  	</div>
				<div>
				   <hr/>
				   <h5 className="contact__title mt-4 mb-2">Notifications</h5>
				   <div className="material-form__label light-text">Enable notifications for this collection</div>
				   <div className={`unit_building_toggle`}>
					
					<span>Off</span>
					<Tooltip title={`${notifyOn ? "Disable notifications for this collection" : "Enable notifications for this collection"}`}>
					  <PurpleSwitch 
						  checked={notifyOn}
						  onChange={(event)=>handleChangeNotify(event)}
					  />
					</Tooltip>
					<span>On</span>					      	
				  </div>
				</div>
			  	{user && user.role && user.role.indexOf("admin")>=0 &&
			  	<div>
					 <hr/>
					 <h5 className="contact__title mt-4 mb-2">Community Access (Admins Only)</h5>
					 <div className="material-form__label light-text">Enable the community to use this collection</div>
					 <div className={`unit_building_toggle`}>
					  
					  {/*<BuildingIcon color="#DBE0E8" />*/}
					  <span>Private</span>
					  <Tooltip title={`${isPublic ? "Remove community access to this collection" : "Share this collection with the community"}`}>
						<PurpleSwitch 
							checked={isPublic}
							onChange={(event)=>handleChangeAccess(event)}
						/>
					  </Tooltip>
					  <span>Public</span>					      	
					  {/*<ListIcon color="#DBE0E8" />	*/}
					    
					</div>
					
					<Fragment>
						<div className="mt-3">
							<div className="material-form__label light-text  mb-2">Tell the community about this collection</div> 
							{/*<TextField
						  	id="standard-multiline-static"
						  	label="Description" 
						  	variant="outlined" 
						  	style={{width:'100%'}}
						  	multiline
						  	rows={4}
						  	defaultValue=""
						  	value={groups[0]['group_description']} 
						  	onChange={(event)=>handleChangeDescription(event)}
							/>*/}
						  	<RichTextEditor
									value={groups[0]['group_description']}
									onChange={(content) => {
									  handleChangeDescription(content);
									}}	
									editorConfig={editorConfig}
							  />
						</div>
						<div className="mt-3">
					  	<div className="material-form__label light-text  mb-2">Add one or more tags to organize this collection</div> 
						 {/* <div className="tag-controller">
					  			<TextField
									id="add-tag"
									label="Add a Tag" 
									variant="outlined" 
									onChange={null}
									style={{width:'100%'}}
									
					  			/>
								<IconButton
						  		aria-label="toggle password visibility"
						  		onClick={null}
						  		onMouseDown={null}
						  		edge="end"
								>
						  			<AddIcon color={'#780F9E'} /> 
								</IconButton>
				  		  </div>*/}
							<CollectionTags tags={groups[0]['group_tags'] && Array.isArray(groups[0]['group_tags']) ? groups[0]['group_tags'] : []} handleTagChange={handleTagChange} />
						</div>
						
				  </Fragment>
				
				</div>
			   	}
			</Fragment>
			: null
		}
		
	  </FormControl>
	  
	</div>
  );
}
