import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Row, Col, Progress, Badge, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import MatTableFiltersSelect from './MatTableFiltersSelect';

import MatAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import PreviewIcon from 'mdi-react/EyeOutlineIcon';
import OpenInNew from 'mdi-react/OpenInNewIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import LinearLoading from '../../../shared/components/LinearLoading';


import ListViewIcon from 'mdi-react/FormatListBulletedIcon';
import AnalysisViewIcon from 'mdi-react/ChartBarIcon';

import CondoDecIcon from 'mdi-react/AlphaCCircleOutlineIcon';

import Supply from './analysis/Supply';
import Active from './analysis/Active';
import TotalUnits from './analysis/TotalUnits';
import AveragePPSF from './analysis/AveragePPSF';
import AveragePrice from './analysis/AveragePrice';
import TotalSelloutPrice from './analysis/TotalSelloutPrice';
import SelloutPrice from './analysis/SelloutPrice';
import PriceRange from './analysis/PriceRange';
import TotalUnsold from './analysis/TotalUnsold';
import NeighborhoodAnalysis from './analysis/NeighborhoodAnalysis';
import CurrentProgress from './analysis/CurrentProgress';
import BuildingIcons from '../../../shared/components/BuildingIcons';

import AddCollectionButton from '../../../shared/components/collections/AddCollectionButton';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";


import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	SalesProgress,
	BuildingStatus,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	getCohortQuery,
	calcuateTCODate,
	calcuateSalesStart,
	CurrentStatus
} from "../../../shared/helpers/utils";

import { 
	_buildingAdminFilters,
	_buildingStageFilter
} from "../../../shared/helpers/filters";

import EnterpriseGate from '../../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../../shared/helpers/access";

import queryString from "query-string";


let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}
const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);



const buildingUrl = process.env.API_ROOT + '/query/';

const subFilterKey = 'subFilters';
const buildingStagesKey = 'buildingStages';
const defaultSubFilter = {offering_verified_status:["not-abandoned"]};
//const defaultStage = ["sponsor"];

const getSubFilters = ( { stage } ) => {
	console.log(stage)
	return _buildingAdminFilters.filter(filter => !(filter.exclude_stage && filter.exclude_stage.indexOf(stage)>=0));
	
}

class MatTable extends PureComponent {
	
  
  buildingSubFilters = getSubFilters( this.props.match.params );
  
  defaultStage = this.props.match && this.props.match.params.stage ? [this.props.match.params.stage] : ["sponsor"];
  lsPre = `BDBV4_${this.defaultStage[0]}_`;
  
  state = {
    order: syncGenericFilter('order', this.lsPre, 'order', "desc"),
    orderBy: this.defaultStage.length == 1 && this.defaultStage.indexOf("resale") >=0 ? syncGenericFilter('order_by', this.lsPre, 'orderBy', "inventory_total") : syncGenericFilter('order_by', this.lsPre, 'orderBy', "inventory_unsold"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	// filterFetchInProgress: false,
	filterStage: "all_projects",
	futureInventoryData: false,
	// headerHeight: 'auto',
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, this.buildingSubFilters, subFilterKey, this.lsPre, defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	searchText: syncURLSearch(this.props, 'searchText'),
	statView: false,
	facetData: null,
	buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, this.lsPre, this.defaultStage),
	selectedUnits: {}
  };
  
  q = '';
  fq = '';
  sort = '';
  
  componentDidMount() {
  	this._getInventoryStats();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
		  
		  console.log(prevProps.searchTags, this.props.searchTags)
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
	    let subFilterChanged =
			this.buildingSubFilters.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			searchTagsChange ||
			subFilterChanged ||
			buildingStageChange
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)	
			  this._getInventoryBuildings(this.props, resetPage,  {
				callback: function(response) {
					let effective_year = moment().year() - 5;
					response.docs = response.docs.map((doc, i) => {
						doc["is_completed"] =
							moment(doc["building_effective_start"]).year() >=
							effective_year;
						doc["has_sales_started"] =
							moment(doc["sales_start_date"]).year() > 1969;
						return doc;
					});
		
					this._formatStats(response, resetPage);
				}.bind(this),
				url: buildingUrl
			});
		}

	  
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  
  shouldLinkToMP = (building) => {
	   const { user } = this.props;
	   return user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 || ['active', 'coming soon', 'offering plan required', 'offering plan acquired', 'condo declaration vetted'].indexOf(building.offering_verified_status) < 0
  }
  
  handleBuildingClick = (event, building) => {
	
	 this.handleBuildingPreviewClick(event, building);
	                            
/*
	 if(this.shouldLinkToMP(building)){
		 const buildingURL = _getBuildingURL(building, 'building_slugs');	 
		 window.open(`https://marketproof.com${buildingURL.replace('buildings', 'building')}?tab=overview`)
	 }else{
		 this.handleBuildingPreviewClick(event, building);

	 }
*/
	 
	 //this.props.history.push(buildingURL);
  };
  
  handleBuildingPreviewClick = (event, building) => {
	 event.preventDefault();
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building);
	 
	 window.open(buildingURL);
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  _getInventoryBuildings = async (query, resetPage, obj) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		const { user } = this.props;
		const { buildingStages } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		
		this.q = "";

		if(locQuery){
			this.q += locQuery;
		}
		let isActive = false;
		let isExcluded = false;
		if (this.state.filterStage !== "all_projects") {
			this.q += this.q.length > 0 ? " AND " : "";
			if (this.state.filterStage === "completed_only") {
				this.q += "(building_effective_start:[NOW-5YEARS TO NOW])";
			} else if (this.state.filterStage === "under_construction_only") {
				this.q +=
					"(sales_start_date:[NOW-5YEARS TO NOW] AND -building_effective_start:[NOW-5YEARS TO NOW])";
			}
		}
		this.fq = [
					"deleted:false"
				];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this.buildingSubFilters.filter(filter => filter.search_key === key);
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){		
							
							if(theFilter[0].target && theFilter[0].target == "fq" && theFilter[0].join_query){
								
								this.fq.push(`${theFilter[0].join_query}(${qVal.join(" OR ")})`);
							}else{
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += `(${key}:(${qVal.join(" ")}))`;
							}			
								
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						console.log(theFilter, subFilterVal)	
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						console.log(searchValue)
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += `(${searchKey}:(${qVal}))`;
					}else{
						const qVal = theFilter && theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						
									
						if(qVal!==null){
							if(theFilter[0].target && theFilter[0].target == "fq"){
								
								
								this.fq.push(qVal);
							}else{
								this.q += this.q.length > 0 ? " AND " : "";
								
								if(key === 'offering_verified_status' && ['active', '("offering plan required")', '("offering plan acquired")', '("condo declaration vetted")'].indexOf(qVal)>=0){
									isActive = true;
								}
								if(key === 'offering_verified_status' && ['excluded'].indexOf(qVal)>=0){
									isExcluded = true;
								}
								this.q += `(${key}:${qVal})`;
							}
						}
					}
				}
			});
		}
		
			
		/****Price & Date query***/
		let priceQ = {};
		

		let replaceKeys = {};
		
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this.buildingSubFilters.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this.buildingSubFilters.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						let dateQ = '';
						let dateReplaceKeys = [];
						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];					
							if(dateQ){
								let regex = new RegExp(`{${key}}`, "g");
								dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
							}else{		
								dateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
								
							}	
							
							if(dateQ){
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									dateQ = dateQ.replace(regex, '*')
								
								})
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += '(';
								this.q += dateQ;
								this.q += ')';			
							}	
						})									
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this.buildingSubFilters.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		
		/****Price& Date query***/
		
		setURLFromState(this.props, this.state);
		
		
		if(buildingStages && buildingStages.length>0){
			if(buildingStages.indexOf("all")<0){
				this.fq.push(`building_status:("${buildingStages.join("\" OR \"")}")`)
			}
			
		}
		
		if(!isExcluded){
			this.fq.push("-offering_verified_status:\"excluded\"");
		}
		
		
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `(name:(*${text}* OR *${textCaps}*) OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		this.q = this.q.length > 0 ? this.q : "*:*";
		
/*
		if(isActive){			
			var index = fq.indexOf("offering_accepted_date:[NOW-3YEARS TO NOW]");
			if (index !== -1) this.fq.splice(index, 1);
		}else{
			fq.push("offering_accepted_date:[NOW-3YEARS TO NOW]");
		}
*/
		this.fq.push(getCohortQuery(user));
		
		if(!(user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0)){
			this.fq.push("total_units:[1 TO *]");
			
		}
		
		
		
		this.sort = "total_units desc";
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			if((this.state.orderBy === 'inventory_unsold') && buildingStages.indexOf('sponsor')<=-1){
				this.sort = `building_status desc, ${this.state.orderBy} ${this.state.order}`;
			}else if((this.state.orderBy === 'inventory_total') && buildingStages.indexOf('sponsor')<=-1){
				this.fq.push("(inventory_total:[1 TO *] OR residential_units:[1 TO *])");
				this.sort = `building_status desc, ${this.state.orderBy} ${this.state.order}`;
			}else if(this.state.orderBy === 'year_built'){
				this.fq.push("year_built:[1700 TO *]");
				this.sort = `${this.state.orderBy} ${this.state.order}`;
			}else{
				this.sort = `${this.state.orderBy} ${this.state.order}`;
			}
			
		}
		
		

		if (query.fq && query.fq.length > 0) {
			this.fq = this.fq.concat(query.fq);
		}
		
		this.sort = this.sort + ', id asc';
console.log(this.fq)
		let axiosData = {
			url: obj.url + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: this.q,
				fq: this.fq,
				sort: this.sort,
				fl: buildingFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"json.facet": {
					"total_unsold": "sum(inventory_unsold)",
					"total_inventory_unsold": "sum(inventory_unsold)",
					"total_inventory": "sum(tax_assessed_residential_units)",
					"total_inventory_active" : "sum(inventory_active)",		
					"total_inventory_contract" : "sum(inventory_contract)",
					"total_inventory_not_yet_recorded" : "sum(inventory_not_yet_recorded)",	
					"total_inventory_listed" : "sum(inventory_listed)",
					"total_inventory_resale" : "sum(inventory_resale)",
					"total_inventory_shadow" : "sum(inventory_shadow)",	
					"total_inventory_sold" : "sum(inventory_sold)",		
					"pipeline": {
						"type": "query",
						"q": "-offering_accepted_date:[NOW-25YEARS TO NOW]",						
						"facet": {		
							"total_unsold": "sum(inventory_unsold)",
							"total_inventory_unsold": "sum(inventory_unsold)",
							"total_inventory": "sum(inventory_total)",
							"total_inventory_active" : "sum(inventory_active)",		
							"total_inventory_contract" : "sum(inventory_contract)",
							"total_inventory_not_yet_recorded" : "sum(inventory_not_yet_recorded)",	
							"total_inventory_listed" : "sum(inventory_listed)",
							"total_inventory_resale" : "sum(inventory_resale)",
							"total_inventory_shadow" : "sum(inventory_shadow)",	
							"total_inventory_sold" : "sum(inventory_sold)",									
						}
					},	
					"unsold_stats_all": {
						"type": "query",
						"q": "(offering_accepted_date:[NOW-10YEARS TO NOW]) AND -offering_construction:(conversion) AND ownership_type:*condo* AND inventory_unsold:[1 TO *] AND -offering_verified_status:(\"sold out\")",						
						"facet": {		
							"total_inventory_unsold": "sum(inventory_unsold)",
							"total_inventory": "sum(inventory_total)",						
							"by_borough" : {
								"type" : "terms",
								"field" : "borough",
								"limit" : 20,
								"facet" : {
									"total_inventory_unsold": "sum(inventory_unsold)",	
									"total_inventory": "sum(inventory_total)",							
									}
							},
							
						}
					},
					"by_borough" : {
						"type" : "terms",
						"field" : "borough",
						"limit" : 20,
						"facet" : {
							"total_inventory_unsold": "sum(inventory_unsold)",	
							"total_inventory": "sum(inventory_total)",							
						}
					},
					"by_neighborhood" : {
						"type" : "terms",
						"field" : "neighborhood",
						"limit" : 20,
						"sort" : "total_inventory_unsold desc",
						"facet" : {
							"total_inventory_unsold": "sum(inventory_unsold)",
							"total_inventory": "sum(inventory_total)",
							"total_inventory_active" : "sum(inventory_active)",		
							"total_inventory_contract" : "sum(inventory_contract)",
							"total_inventory_not_yet_recorded" : "sum(inventory_not_yet_recorded)",	
							"total_inventory_listed" : "sum(inventory_listed)",
							"total_inventory_resale" : "sum(inventory_resale)",
							"total_inventory_shadow" : "sum(inventory_shadow)",	
							"total_inventory_sold" : "sum(inventory_sold)",						
						}
					},
					"sellout" : {
						"type": "query",
						"q": "offering_price_current:[1 TO *]",
						"facet": {		
							"average_sellout": "avg(offering_price_current)",
							"total_sellout": "sum(offering_price_current)",
							"max_sellout": "max(offering_price_current)",	
							"min_sellout": "min(offering_price_current)"
						}	
						
					},
					"non_zero_price": {
						"type": "query",
						"q": "inventory_stats_listings_average_ppsf:[1 TO *]",						
						"facet": {		
							"average_ppsf": "avg(inventory_stats_listings_average_ppsf)",
							"max_ppsf": "max(inventory_stats_listings_average_ppsf)",	
							"min_ppsf": "min(inventory_stats_listings_average_ppsf)",	
							"min_listing_low_price" : "min(inventory_stats_listings_low_price)",
							"max_listing_high_price" : "max(inventory_stats_listings_high_price)",			
							"by_borough" : {
								"type" : "terms",
								"field" : "borough",
								"limit" : 20,
								"facet" : {
									"average_ppsf": "avg(inventory_stats_listings_average_ppsf)",
									"max_ppsf": "max(inventory_stats_listings_average_ppsf)",	
									"min_ppsf": "min(inventory_stats_listings_average_ppsf)",								
									}
							},
							
						}
					},
					"inventory_sponsor": {
						"type": "query",
						"q": "building_status:(sponsor pipeline)",						
						"facet": {		
							"total": "sum(inventory_total)"							
						}
					},
					"inventory_resale": {
						"type": "query",
						"q": "building_status:(resale)",						
						"facet": {		
							"total": "sum(inventory_total)"							
						}
					}
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof obj.callback === "function") {
					let formattedRes = obj.callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};

	_getInventoryStats = (newStart = false) => {
		console.log("_getInventoryStats this.props ", this.props);
		console.log("_getInventoryStats called Inventory state", this.state);

		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({
				buildingsFetchInProgress: true
			}));
		}

		this._getInventoryBuildings(this.props, true, {
			callback: function(response) {
				let effective_year = moment().year() - 5;
				response.docs = response.docs.map((doc, i) => {
					doc["is_completed"] =
						moment(doc["building_effective_start"]).year() >= effective_year;
					doc["has_sales_started"] =
						moment(doc["sales_start_date"]).year() > 1969;
					return doc;
				});
				this._formatStats(response, false);
			}.bind(this),
			url: buildingUrl
		});
	};

	_formatStats = (response, resetPage, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		// number to gate info at
		let showUnsigned = this.props.isDesktop ? this.state.showCounts.desktop : this.props.isTablet ? this.state.showCounts.tablet : this.state.showCounts.mobile;
		console.log('showUnsigned', showUnsigned)
		let formattedData = response
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
		let effective_year = moment().year() - 5;
		let hide_fields = [
			"name",
			"full_street_address",
			"display_full_street_address"
		];

		let docs = formattedData.map((doc, i) => {
			let item = Object.assign({}, doc);
			
			// adds "Sign up ..." as field value for the fields in hide_fields
			if (!signedIn && i >= showUnsigned) {
				Object.keys(doc).map(function(key, index) {
					if (hide_fields.indexOf(key) >= 0) {
						return item[key] = "Sign up to see";
					} else {
						return item[key] = doc[key];
					}
				});
			
			// replace hidden fields with originalData fields bc shouldn't be hidden 
			} else if(!signedIn && i < showUnsigned && item[hide_fields[0]].includes('Sign up to see')) {
				const originalData = this.state.originalData.docs
				Object.keys(doc).map(function(key, index) {
					if (hide_fields.indexOf(key) >= 0) {
						return item[key] = originalData[i][key];
					} else {
						return item[key] = doc[key];
					}
				});
			}
			
			// no need to do again for hidden field rerenders
			if(response) {
				item["sold"] = item["inventory_sold"];
				item["sold"] += item["inventory_not_yet_recorded"];
				item["Sold"] += item["inventory_sold"];
				item["Sold"] += item["inventory_resale"];
				item["pct_sold"] =
					100 -
					Math.round((item.inventory_unsold / item.inventory_total) * 100);
			}

			return item;
		});

		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			tableData:  docs,
			reactTableFiltered:  docs,
			originalData: redoHiddenFields ? prevState.originalData : response,
			// filterFetchInProgress: false,
			totalCount: response.numFound,
			start: resetPage ? 0 : this.state.start,
			page: resetPage ? 0 : this.state.page,
			stats: redoHiddenFields 
				? prevState.stats 
				: Object.assign(prevState.stats, {
					total_buildings: response.facets.count,
					total_units: response.facets.total_unsold,
					total_inventory: response.facets.total_inventory,
					inventory_sponsor: response.facets.inventory_sponsor,
					inventory_resale: response.facets.inventory_resale,
			}),
			facetData: response.facets,
			selectedUnits: {}
			
		}));
	};
	
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	_filterResponseTableData = eValue => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter(item => {
						// console.log("filterData item", item)
						const inputVal = eValue.toLowerCase();
						let nameVal, adrVal, hoodVal;
						if (item.name) {
							// console.log('item has name')
							nameVal = item.name.toLowerCase();
						}

						if (
							(item.display_full_street_address &&
								_shouldShow(item.display_full_street_address)) ||
							(item.full_street_address &&
								_shouldShow(item.full_street_address))
						) {
							const field = item.display_full_street_address
								? "display_full_street_address"
								: "full_street_address";
							// console.log('item has adr', field)

							adrVal = item[field].toLowerCase();
						}

						if (item.neighborhood && _shouldShow(item.neighborhood)) {
							const hood =
								Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
									? item.neighborhood[0]
									: item.neighborhood;
							hoodVal = hood.toLowerCase();
						}

						// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
						// searching name and ONE adr field - could update to search both display and full and all alt adrs
						return (
							(nameVal && nameVal.indexOf(inputVal) >= 0) ||
							(adrVal && adrVal.indexOf(inputVal) >= 0) ||
							(hoodVal && hoodVal.indexOf(inputVal) >= 0)
						);
				  })
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState(prevState => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
		    //tableData: filteredData,
		    //originalData: response,
		    //filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = ["sponsor"]
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = ['all'];
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(this.lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages : this.defaultStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(this.lsPre, buildingStagesKey);
		deleteLocalStorage(this.lsPre, subFilterKey);
	}
	
	_resetActive = () => {
		
		let isDefault = this.props.searchTags.length===0 &&
						_objectEqual(defaultSubFilter, this.state.subFilters) &&
	  					_objectEqual(this.defaultStage, this.state.buildingStages);
	    return !isDefault;
		
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		
		if(this.state.statView){
			this.setState( { statView : false } )
		}else{
			this.setState( { statView : true } )
		}
		
	}
   _selectUnit(property){
	   const { selectedUnits } = this.state;
	   const newSelected = Object.assign({}, selectedUnits);
	   
	   if(newSelected[property.key]){
		   delete newSelected[property.key];			
	   }else{
		   newSelected[property.key] = property;
	   }
	   
	   this.setState( { selectedUnits: Object.assign({}, newSelected) } );
   }
   _handleSelectAllClick(){
	   const { selectedUnits, reactTableFiltered } = this.state;
	   let newSelected = {}
	   console.log(reactTableFiltered)
	   if(Object.keys(selectedUnits).length < reactTableFiltered.length){
		   
	   
		   reactTableFiltered.forEach(item => {
			   newSelected[item.key] = item;
		   })
	   }
	   
	   this.setState( { selectedUnits: newSelected } );
	   
   }
   _clearSelectedUnits(){
	   this.setState({selectedUnits:{}})
   }
  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, statView, buildingsFetchInProgress, stats, selectedUnits, buildingStages
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
     const paginationCount = totalCount;
    if(!tableData){
	    return null;
    }
    const table = reactTableFiltered;
    
    let unitCount = (stats && stats.inventory_sponsor && stats.inventory_sponsor.total) ? stats.inventory_sponsor.total : 0;
    
    unitCount += (stats && stats.inventory_resale && stats.inventory_resale.total ? stats.inventory_resale.total : 0);
	
	if(!_hasPermission(user, 'sponsor-buildings') && buildingStages.indexOf("sponsor")>=0){
		return(
			  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/newdev-stage-buildings.png`}
					copy={<span>Access to new development buildings requires an upgrade to your subscription.<br/>  You can downgrade at any time.</span>}
					upgrade
					user={user}
					nextPlan={"complete-199"}
				/>
		)
	} 
	if(!_hasPermission(user, 'pipeline-buildings') && buildingStages.indexOf("pipeline")>=0){
		return(
			  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-macbook-pipeline-buildings.png`}
					copy={<span>Access to pipeline buildings requires an update to your subscription.</span>}
					upgrade
					user={user}
					nextPlan={"complete-199"}
				/>
		)
	} 
	if(!_hasPermission(user, 'resale-buildings') && buildingStages.indexOf("resale")>=0){
		return(
		
			  <EnterpriseGate
				  copy={<span>Access to resale buildings requires an update to your subscription.</span>}
			  />
		
		)
	} 
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
           {/*<div className="card__title">
              <h5 className="bold-text">Select and Analyze</h5>
            </div>*/}
            <MatAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				resetSubFilter={this._resetSubFilter}
				buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	setSubFilter={this._setSubFilter}
            	setSubFilters={this._setSubFilters}	
            	_filters={(user && user.role.indexOf('admin')>=0) ?  this.buildingSubFilters : this.buildingSubFilters.filter(item => !item.admin_only)}
            	placeHolder={'Building, Neighborhood or Borough'}         
            	buildingAttributes={[]}  
            	goToBuilding={true}	
            	resetActive={this._resetActive()}
            	showExport				
				q={this.q}
				fq={this.fq}
				autocompleteFQ={this.fq}
				fl={buildingExportFL}
				sort={this.sort}
				totalCount={totalCount}
				core_name={'we3-buildings'}
				title={'Export This Building Search (CSV)'} 
				export_name={'Building Search'} 
          		description={<Fragment><h4><strong>Export this building search for your own analysis.</strong></h4> <br/>   The export will include the profile of the building, sales progress, key dates and more.</Fragment>}
             />
            <div style={{padding:"0 10px",clear:"both"}}>
            	{buildingsFetchInProgress ? <LinearLoading /> : <div style={{height:isMobile ? 0 : 20}} />}
			</div>	
            <div className="material-table__header-info">
            
            
            	<Row>
            		<Col md={6} lg={6}>
            		  <div className={'count_subheader'}>       		
            			<span className="bold-text">{tableCount > 0 ? _formatNumber(tableCount) : '--'}</span>{` `}Buildings
            			{/*<span> | </span>
            			<span className="bold-text">{unitCount > 0 ? _formatNumber(unitCount) : '--'}</span>{` `}Units*/}
            			
            		  </div>
            		</Col>
		            {!this.props.buildingView &&
					<Col md={6} lg={6} style={{ textAlign:"right" }}>
					{false && user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
		            	<span>
						  
						  <ListViewIcon color="#DBE0E8" />
				          <Tooltip title={`${this.state.statView ? "Switch to List View" : "Switch to Analysis View"}`}>
					        <PurpleSwitch 
					            checked={this.state.statView}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>					      	
					      <AnalysisViewIcon color="#DBE0E8" />				       
					    </span>
					}
			    	</Col>
			    	}
            	</Row>
			</div>
            
            {statView ? 
	            <Container className="dashboard">
	            	 <hr/>
				    <Row>
				   	  <Col md={12} xl={12} lg={12} xs={12}>
				   	  	<CurrentProgress  {...this.state} />
				   	  </Col>
				   </Row>
				   <Row >
				   	 <Col md={12} xl={3} lg={6} xs={6}>
				   	  	<Supply {...this.state} />
				   	  </Col> 				   	  	 
				   	 <Col md={12} xl={3} lg={6} xs={6}>
				   	 	<AveragePPSF {...this.state} />
				     </Col>
				      <Col md={12} xl={3} lg={6} xs={6}>
					 	<TotalSelloutPrice  {...this.state} />	
				     </Col>
				     <Col md={12} xl={3} lg={6} xs={6}>
				   	 	<SelloutPrice  {...this.state} />	
				   	 </Col>
				     		                
				   </Row>
				   <Row>
				   	  <Col md={12} xl={12} lg={12} xs={12}>
				   	  	<NeighborhoodAnalysis  {...this.state}  {...this.props} />
				   	  </Col>
				   </Row>
				</Container>
	            
	         :  
	            
            <Fragment>
				<AddCollectionButton selectedUnits={selectedUnits} groupType={"buildings"} clearSelectedUnits={this._clearSelectedUnits.bind(this)}  {...this.props} addMultiple />
	            <div className="material-table__wrap">
	              <Table className="material-table">
	                <MatTableHead
	                  numSelected={Object.keys(selectedUnits).length}
	                  order={order}
	                  orderBy={orderBy}
	                  onSelectAllClick={this._handleSelectAllClick.bind(this)}
	                  onRequestSort={this.handleRequestSort}
	                  rowCount={tableData.length}
	                />
	                
	                <TableBody>
	                  {table
	                      .map((d) => {
	                      const isSelected = this.isSelected(d.id);
	                      //If building is sold out override stats
/*
	                      if(d.offering_verified_status === 'sold out'){
		                      d.inventory_unsold = 0;
		                      d.inventory_pct_unsold = 0;
	                      }
	                      
*/
						  let inventory_pct_unsold = d.inventory_pct_bulk_unsold ? d.inventory_pct_bulk_unsold : d.inventory_pct_unsold;
						  let inventory_unsold = d.inventory_bulk_unsold ? d.inventory_bulk_unsold : d.inventory_unsold;
						  let offering_effective_year = d.offering_effective_date ?  parseInt(moment(d.offering_effective_date).format('YYYY')) : 0;
						  if(offering_effective_year <= 1970){
							  offering_effective_year = 0;
						  }
						  let current_year = new Date().getFullYear();
	                      return (
	                        <TableRow
	                          className="material-table__row"
	                          role="checkbox"
	                          onClick={event => this.handleBuildingClick(event, d)}
	                          aria-checked={isSelected}
	                          tabIndex={-1}
	                          key={d.id}
	                          selected={isSelected}
	                        >
	                          <TableCell className="material-table__cell" padding="checkbox">
	                            <Checkbox checked={selectedUnits[d.key]===undefined ? false : true} onClick={(e)=>{e.preventDefault();e.stopPropagation(); this._selectUnit(d);}} className={`material-table__checkbox ${selectedUnits[d.key]!==undefined && 'material-table__checkbox--checked'}`} />	
	                            {user.role && Array.isArray(user.role) 
		                            && user.role.indexOf('admin')>=0 
		                            && d.offering_file_number && d.offering_file_number &&
							  		<Fragment>
							  			<IconButton onClick={(e) => { e.preventDefault();e.stopPropagation(); window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`)}} >
							  				<OpenIcon color={'#BFC4D1'} />
							  			</IconButton>
							  		</Fragment>
						  		}
	                            
	                          </TableCell>
	                           
	                          <TableCell
	                            className="material-table__cell material-table__cell-right"
	                            component="th"
	                            scope="row"
	                            padding="none"
	                          >
	                            <span className={"building_name"}>{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
	                            	                            									  	
								  	<BuildingIcons building={d} />
								  	

	                            </span>
	                            <br/><span className="light-text small-text">{d.name && _capitalizeText(d.display_full_street_address)+", "} {d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
	                          </TableCell>
	                         
	                          {_hasPermission(this.props.user, 'sponsor-buildings') && 
							  <Fragment>
							  
							  	<TableCell
	                            	className="material-table__cell material-table__cell-right"
	                            	size="small"                          
	                          	>
	                          	
	                          	{d.offering_construction==='conversion' 
	                          	 	?
	                          	 	<SalesProgress progress={parseFloat(inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min color={"grey"} resale={d.building_status === 'resale'}/>
	                          	 	: d.building_status === 'resale' ?
	                          	 	<SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min color={"grey"} resale={d.building_status === 'resale'} />
	                          	 	:
				                 	<SalesProgress progress={parseFloat(inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min resale={d.building_status === 'resale'} />
			                  	}
	                          	</TableCell> 
	                         	<TableCell
	                            	className="material-table__cell light-text small-text"
	                            	size="small" 
	                            	align="center"
	                          	>
	                          	  	{d.inventory_total > 0 && d.building_status !== 'resale' ?		 
		                          	                         	  	
					                	<span className="light-text small-text">{inventory_unsold > 0 ? _formatNumber(inventory_unsold) : 0}</span>
					                	:
										<span className="light-text small-text">--</span>
								  	}
				                  	
	                          	</TableCell>
							  </Fragment>
						      }                     
	                          <TableCell
	                            className="material-table__cell light-text small-text"
	                            size="small" 
	                            align=""
	                          >
	                           <span className="light-text small-text" >
	                          {d.inventory_total > 0 ? `${d.ownership_type.replace('-', '') === 'coop' ? _formatNumber(d.inventory_total) + ' Co-op' : _formatNumber(d.inventory_total) + ' Condo'}` : '' }
	                          </span>
	                          <br/>
	                          <span className="light-text small-text" style={{color:"#858AA1"}}>{d.building_status == 'resale' && d.number_of_buildings > 1 && d.tax_assessed_total_units > 1 ?  _formatNumber(d.tax_assessed_total_units) :  _formatNumber(d.total_units)} Total</span>
	                          
	                         
	                          </TableCell>
	                           <TableCell
	                            className="material-table__cell"
	                            align=""
	                          ><span className="light-text small-text">{d.year_built > 1700 ? d.year_built : '--'} {d.year_built > current_year && '(Proj)'}</span>
	                          
	                          {d.year_converted > 1700 && d.year_converted > d.year_built ? 
		                          <Fragment>
		                            <br/>
		                          	<span className="light-text small-text" style={{color:"#858AA1"}}>{d.year_converted} {d.year_converted > current_year ? '(Proj Conv)' : '(Conv)'}</span>
		                          </Fragment>
		                       : (offering_effective_year - d.year_built) > 10 ?
		                       	  <Fragment>
		                            <br/>
		                          	<span className="light-text small-text" style={{color:"#858AA1"}}>{offering_effective_year} (Conv)</span>
		                          </Fragment>
		                       :
		                       	  null
		                      }
	                          </TableCell> 
	                          <TableCell
	                            className="material-table__cell"
	                            align=""
	                          ><span className="light-text small-text">{moment(d.offering_submitted_date).year() > 1970 ? _capitalizeText(moment(d.offering_submitted_date).fromNow()) : '--'}</span>
	                          </TableCell> 
	                          <TableCell
	                            className="material-table__cell"
	                            align=""
	                          ><span className="light-text small-text">{moment(d.offering_accepted_date).year() > 1970 ? _capitalizeText(moment(d.offering_accepted_date).fromNow()) : '--'}</span>
	                          </TableCell>
	                         {_hasPermission(this.props.user, 'sponsor-buildings') && 
							 	<Fragment>
							   	<TableCell
	                            	className="material-table__cell light-text small-text"
	                            	size="small" 
	                            	align=""
	                          	><span className="light-text small-text">{_capitalizeText(calcuateSalesStart(d, 'fromNow'))}</span>
	                          	</TableCell>                       
							  	<TableCell
	                            	className="material-table__cell"
	                            	align=""
	                          	><span className="light-text small-text">{_capitalizeText(calcuateTCODate(d, 'fromNow'))}</span>
	                          	</TableCell>
							 	</Fragment>
						 	 }
	                          <TableCell
	                            className="material-table__cell material-table__cell-right"
	                            align="right"
	                          ><span className="light-text small-text">{d.inventory_stats_listings_low_price > 100 ? `${_formatPrice(d.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(d.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</span>
	                          </TableCell>
	                         {(user && user.role.indexOf('admin')>=0) &&  <TableCell
	                            className="material-table__cell material-table__cell-right"
	                            align="center"
	                          ><span className="light-text small-text">{user.role.indexOf('admin')>=0 ? <BuildingStatus item={d} /> : <CurrentStatus item={d} /> }</span>
	                          </TableCell> }
	                          
	                        </TableRow>
	                      );
	                    })}
	                  {emptyRows > 0 && (
	                    <TableRow style={{ height: 49 * emptyRows }}>
	                      <TableCell colSpan={6} />
	                    </TableRow>
	                  )}
	                </TableBody>
	              </Table>
	            </div>
	            {paginationCount ? <TablePagination
	              component="div"
	              className="material-table__pagination"
	              count={paginationCount}
	              rowsPerPage={rowsPerPage}
	              page={page}
	              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
	              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
	              onChangePage={this.handleChangePage}
	              onChangeRowsPerPage={this.handleChangeRowsPerPage}
	              rowsPerPageOptions={[25, 50, 100]}
	              dir="ltr"
	              SelectProps={{
	                inputProps: { 'aria-label': 'rows per page' },
	                native: true,
	              }}
	            /> : null}
	        </Fragment>
	        }
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(MatTable));