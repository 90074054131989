import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Buildings from '../Buildings/index';
import SingleBuilding from '../SingleBuilding/index';
import Units from '../Units/index';
import BuyersLists from '../BuyersLists/index';
import SingleUnit from '../SingleUnit/index';
import SavedUnits from '../BuyersLists/components/SavedUnits/index';
import Dashboard from '../Dashboard/index';
import Analysis from '../Analysis/index';
import BuyerLeads from '../Dashboard/components/BuyerLeads/index';
import News from '../News/index';
import TransactionCenter from '../TransactionCenter/index';
import KnowledgeCenter from '../KnowledgeCenter/index';

import Profile from '../Profile/index';

import SingleUnitShare from '../SingleUnit/components/Share/index';
import SingleBuildingShare from '../SingleBuilding/components/Share/index';
import SharePage from '../SharePage/index';
import StackedUnits from '../Reports/StackedUnits/index';


import { Security, LoginCallback } from '@okta/okta-react';
import { withOktaAuth } from '@okta/okta-react';

import Home from '../../shared/components/auth/okta/Home';
import OktaLogin from '../../shared/components/auth/okta/Login';
import OktaSignUp from '../../shared/components/auth/okta/SignUp';

import Pay from '../../shared/components/payment/Pay';
import PricingPlans from '../../shared/components/payment/PricingCards';
import LandingPage from '../../shared/components/payment/LandingPage';

import WrappedRoutes from './WrappedRoutes';

import WithTracker from './WithTracker';

import OktaSignIn from '@okta/okta-signin-widget';


import CBWarburg from '../../shared/components/auth/sso/CBWarburg';
import RealPlus from '../../shared/components/auth/sso/RealPlus';
import MPSSO from '../../shared/components/auth/sso/MPSSO';

const onAuthRequired = ( authService ) => {
	//alert("auth required");
	//console.log(props);
  //history.push('/login');
console.log(onAuthRequired);
console.log(authService);
  return <Redirect to={{ pathname: "/login" }} />

}

const config = {
  issuer: 'https://dev-397854.okta.com/oauth2/default',
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: '0oa4wydnwZCyv8NLI4x6',
  onAuthRequired: onAuthRequired,
  onSessionExpired: onAuthRequired,
  pkce: false
};

/* const cbwconfig = {
  issuer: 'https://realogy.oktapreview.com/oauth2/ausdtpyw647fbrcPi0h7',
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: '0oa19flgc6pHgjDDO0h8',
  onAuthRequired: onAuthRequired,
  onSessionExpired: onAuthRequired,
  pkce: true
}; */

/*


const Pages = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/buildings/:borough/:neighborhood/:building_slug" component={SingleBuilding} />
  	<Route exact path="/buildings/:borough/:neighborhood/:building_slug/tab/:tab" component={SingleBuilding} />
  	<Route exact path="/buildings/:borough/:neighborhood/:building_slug/:unit" component={SingleUnit} />
    <Route path="/buildings" component={Buildings} />
    <Route path="/inventory" component={Units} />
    <Route path="/my-buildings" component={BuyersLists} />
    <Route path="/my-units" component={SavedUnits} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/news" component={News} />
    <Route path="/analysis/new-development" component={Analysis} />
    <Route path="/my-leads" component={BuyerLeads} />
    <Route path="/transaction-center" component={TransactionCenter} />
    <Route path="/community-center" component={KnowledgeCenter} />
    <Route path="/my-account" component={Profile} />
  </Switch>
);

const WrappedRoutes = withOktaAuth(( props ) => {
	console.log(props);
	if (props.authState.isPending) return null;
	if (props.authState.isAuthenticated){		 
       return(
			 <div>
			    <Layout />
			    <div className="container__wrap">
			      <Route path="/" component={Pages} />
			    </div>
			 </div>
		)
	     
	}else{
		return <Redirect to={{ pathname: "/login" }} />
	}
	
});
*/

const oktaWidgetConfig = {
  baseUrl: `https://${process.env.OKTA_DOMAIN}`,
  authParams: {
    pkce: true
  },
  features: {
    rememberMe: false,
    selfServiceUnlock: false,
    registration: true,
    router: true
  },
  logo: `${process.env.PUBLIC_URL}/img/marketproof-pro-logo.png`,
  colors: {
	  brand: '#780F9E'
   },
   i18n: {
// Overriding English properties
	  'en': {
	    'primaryauth.title': 'Sign IN',
	    'primaryauth.username.placeholder': 'Email'
	  }
   },
   customButtons: [
    {
      title: 'About Marketproof New Development',
      className: 'btn-learnMore',
      click: function() {
          // clicking on the button navigates to another page
          window.open('https://marketproof.com/new-development');
        }
    }]
};
        

let oktaWidget = new OktaSignIn(oktaWidgetConfig);

const Stacked = WithTracker(WrappedRoutes);

class Router extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
	 console.log("router"); 
  }
  render(){	 
	  
	 
	 return (
	 	<Security {...config}>
		  <MainWrapper>
		    <main>
		      <Switch>
		        <Route exact path="/log_in" component={WithTracker(LogIn)} />
              
            <Route exact path="/cbwarburg" component={WithTracker(CBWarburg)} />
            <Route exact path="/sso/cbwarburg" component={CBWarburg} />
            <Route exact path="/dashboard/cbwarburg" component={CBWarburg} />
            {/* <Route exact path="/realplus" component={WithTracker(RealPlus)} />
            <Route exact path="/realplus/:customer" component={WithTracker(RealPlus)} />
            <Route exact path="/sso/realplus/:customer" component={RealPlus} />
            <Route exact path="/dashboard/realplus/:customer" component={RealPlus} /> */}
            <Route exact path="/login" render={() => <OktaLogin oktaWidget={oktaWidget} />} />
            <Route exact path="/mplogin" component={WithTracker(MPSSO)} />
            <Route exact path="/mpsso/redirect" component={MPSSO} />
            <Route exact path="/dashboard/mpsso" component={MPSSO} />
                
		        <Route exact path="/building/:encrypted_key" component={WithTracker(SingleBuildingShare)} />
		        <Route exact path="/share/:custom_link" component={WithTracker(SharePage)} />
            
		        <Route exact path="/unit/:encrypted_key" component={WithTracker(SingleUnitShare)} />
		        <Route exact path="/report/stackedunits/:encrypted_key" component={WithTracker(WrappedRoutes, {}, {component: <StackedUnits />, nochrome:true})}/>
		        		        
		        <Route exact path="/" component={WithTracker(Home)} />	        
		        <Route exact path="/signin/register/:plan" render={() => <OktaSignUp oktaWidget={oktaWidget} />} />
		        <Route exact path="/signin/register" render={() => <OktaSignUp oktaWidget={oktaWidget} />} />
		        <Route exact path="/signin/reset-password/:reset_token" render={() => <OktaLogin oktaWidget={oktaWidget} />} />
		        <Route exact path="/signin/recovery/:reset_token" render={() => <OktaLogin oktaWidget={oktaWidget} />} />
		        <Route path='/implicit/callback' component={WithTracker(LoginCallback)}/>
		        <Route exact path="/payment" component={WithTracker(Pay)} />
		        <Route exact path="/pricing" component={WithTracker(PricingPlans)} />
            <Route exact path="/learn-more" component={WithTracker(LandingPage)} />
		        <Route exact path="/accounts" component={WithTracker(LogIn)} />
		        <Route path="/" component={WithTracker(WrappedRoutes)} />
              
            {/*  */}
		        
		      </Switch>
		    </main>
		  </MainWrapper>
	    </Security>
	)
 }
}

export default Router;
