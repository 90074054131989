import { TextEncoder } from 'text-encoding';

import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
//Add pubsub subscriber and subscribe to all events
import { Subscriber } from "./shared/pubsub/subscriber.js";
Subscriber.subscribe();

/* global shim for okta window */
if (typeof window.TextEncoder === 'undefined') {
  window.TextEncoder = TextEncoder;
}

render(
  <App />,
  document.getElementById('root'),
);
