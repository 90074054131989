import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BuildingTabContainer from './components/BuildingTabContainer';
import { RTLProps } from '../../shared/prop-types/ReducerProps';
import LinearLoading from '../../shared/components/LinearLoading';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import AlertIcon from 'mdi-react/AlertDecagramOutlineIcon';
import Alert from '../../shared/components/Alert';


import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	buildingFL,
	_isHouse
} from '../../shared/helpers/utils';

import{
	_axiosCall,
	_makeBuildingAxiosData,
} from '../../shared/helpers/apicalls';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";
const apiURL = process.env.API_ROOT + '/query/';
	
class SingleBuilding extends PureComponent {
	
	
	_boro = _urlToName(this.props.match.params.borough);
    _hood = _urlToName(this.props.match.params.neighborhood);

	state = {
      buildingRes: false,
      offeringPlan: false,
      fetchInProgress: true,
      tab: false
	}
	
	componentDidMount() {
		this._isMounted = true; 
		if(!this.state.buildingRes && this.state.fetchInProgress) {
			this._getBuildingData();
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {	
		console.log(this.props);	
		if(prevProps.match.params.building_slug !== this.props.match.params.building_slug) {
			this._getBuildingData();
		}
		
		if(prevProps.match.params.tab !== this.props.match.params.tab){
			this.setState({tab:this.props.match.params.tab })
		}
		 
    }
	
	_getBuildingData = () => {
     // console.log('_getBuildingData called', this.state)
     if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  const buildingSlug = this.props.match.params.building_slug;
	  
    
/*
	  let buildingData = {
			url: apiURL + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: 'key:*-'+buildingSlug,
				fq: 'deleted:false',
 				fl: buildingFL,
				wt: "json",
				rows: 1
			}
	  };
*/
	  
	  let buildingData = {
			url: process.env.API_ROOT + `/buildings/${buildingSlug}?type=minimum&cache=15`,
			method: "get"
	  };
	  
	  _axiosCall(buildingData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    //if(this._isMounted && res && res.docs && res.docs[0]) {
		if(this._isMounted && res && res.building && res.building[0]) {
		  this._getBuildingOPData(res.building[0]);
		  //If building is sold out override stats
		  if(res.building[0].offering_verified_status === 'sold out'){
			  res.building[0].inventory_sold += res.building[0].inventory_unsold;
              res.building[0].inventory_unsold = 0;
              res.building[0].inventory_pct_unsold = 0;
              res.building[0].inventory_pct_sold = 100;
              
          }
	      this.setState({
	      	buildingRes: res.building[0],
	      	fetchInProgress: false
	      });
	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}
	
	_getBuildingOPData = async (buildingRes) => {		
		const addressQ = buildingRes.alternate_addresses.join('" OR "');					
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}") AND id:c* AND -id:cp*)`;
		
		if(buildingRes.offering_file_number && buildingRes.offering_file_number != ""){
			q += ` OR id:${buildingRes.offering_file_number}`
		}

		let axiosData = {
			url: apiURL + "we3-offering-plans?cache=60",
			method: "post",
			query: {
				q: q,
				sort: "submitted_date desc",
				wt: "json",
				rows: 999,
				
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){
					
					const offeringPlan = res.docs.map(item => {
						try{
							const data = JSON.parse(item.amendments);
							item.amendments = Object.keys(data).map(key => data[key]);
						}catch(e){
							item.amendments = false;
						}
						return item;					
					});
					this.setState({offeringPlan: offeringPlan});
				}	
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};

	render(){

		const { buildingRes, offeringPlan, fetchInProgress, tab } = this.state;
		const { user } = this.props;
		
		if(!buildingRes || fetchInProgress){
			 return <LinearLoading />;
		}
		let isHouse = _isHouse(buildingRes);
		if(!_hasPermission(user, 'sponsor-buildings') && buildingRes.building_status == "sponsor"){
			return(
				<Row>
				  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/newdev-stage-buildings.png`}
					  copy={<span>Access to new development buildings requires an upgrade to your subscription.  You can downgrade at any time.</span>}
					  upgrade
					  user={user}
					  nextPlan={"complete-199"}
				  />
				</Row>
			)
		} 
		if(!_hasPermission(user, 'pipeline-buildings') && buildingRes.building_status == "pipeline"){
			return(
				<Row>
				  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/pipeline-stage-buildings.png`}
					  copy={<span>Access to new development buildings requires an upgrade to your subscription.  You can downgrade at any time.</span>}
					  upgrade
					  user={user}
				  />
				</Row>
			)
		} 
		if(!_hasPermission(user, 'resale-buildings') && buildingRes.building_status == "resale"){
			return(
				<Row>
				  <EnterpriseGate
					  copy={<span>Access to new development buildings requires an upgrade to your subscription.  You can downgrade at any time.</span>}
					  user={user}
				  />
				</Row>
			)
		} 
		return (
			 <Container className="dashboard">
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    {/*(buildingRes.offering_verified_status !== 'active' ) &&
			    <Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="vettingProgress" icon iconItem={<AlertIcon />} minimizeText={`Vetting in Progress`}>
				          <p>
				          	<span className="bold-text">{`Vetting in Progress`}</span> 
				            <div>{`Marketproof New Development is currently processing this building and verifying information.  More details will be added to this building shortly.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row>
			    */}
			    <Row>
			      <BuildingTabContainer buildingRes={buildingRes} offeringPlan={offeringPlan} tab={tab} />
			    </Row>
			</Container>
		)
		
	}
  };

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(SingleBuilding)));
