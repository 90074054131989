import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container, Modal } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import Project from './Project';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	SalesProgress
} from "../../../shared/helpers/utils";

import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';
const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
	 <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}
class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 25,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
		totalCount:0,
		buildingCount:0,
		modal:false,
		buildingRes:false
	};

	componentDidMount() {
		
		if(this.props.fq || this.props.fq === ""){
			this.queryUnits();
		}
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		console.log(this.props.fq, prevProps.fq)
		  if(!_objectEqual(this.props.fq, prevProps.fq) ||
		  this.props.q !== prevProps.q ||
		  (this.props.buildingQuery !== prevProps.buildingQuery ||
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start ||
		   this.state.rowsPerPage !== prevState.rowsPerPage)){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 console.log(this.props)		

			 this.queryUnits(reset);
		  }		 
		
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};
	
	handleUnitClick = (event, building) => {
		
	 this.setState( { modal:true, buildingRes: building })
	 const buildingURL = 'https://marketproof.com/'+_getBuildingURL(building, 'building').replace('buildings/', 'building/');
	 //this.props.history.push(buildingURL);
	 //window.open(buildingURL);
    };
    
    handleChangePage = (event, page) => {
	    this.setState({ page: page, start: page * this.state.rowsPerPage });
	};
	
	handleChangeRowsPerPage = (event) => {
	    this.setState({ rowsPerPage: event.target.value });
	};
	
	toggle() {
		//this.snackOpen('Your Message Has Been Sent!', 'success');
	    this.setState(prevState => ({ modal: !prevState.modal }));
	}


	
	queryUnits = async(reset) => {
		
		if(this.state.fetchInProgress) return false;
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const {q, fq, fl, buildingQuery } = this.props;
		
		const { start, rowsPerPage, page } = this.state;	
		
		let sort = "time desc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			sort = `${this.state.orderBy} ${this.state.order}`;
			
			
		}
			
		
		const unitsFq = [buildingQuery];			
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=15",
			method: "post",
			query: {
				q: q,
				fq: [...fq, ...unitsFq],
				sort: sort,
				wt: "json",
				rows: rowsPerPage,
				start: start,
				"json.facet": {
					"unique_projects": "unique(building)"
				}
						
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				console.log(res);
				if(res){
					const newUnitListData = res.docs;
					
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, totalCount: res.numFound, buildingCount:res.facets.unique_projects  })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	render(){
		const { reactTableFiltered, handleBuildingClick, isSelected, handleClick, selected, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, selectedDate, dataStart, dataEnd, eventTypes} = this.props;
		
		const { totalCount, page, rowsPerPage, buildingCount, order, orderBy, modal, buildingRes} = this.state;
		if(!reactTableFiltered && (this.state.start===0 && (this.props.fetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const paginationCount =  totalCount;
		
		const data = this.state.unitListData;
		
		
		
		return (
		<Fragment>
			<div>
				
            	{/*
	            	
	            	<hr/>	
	            	<div className="material-table__header-info pb-3 desktop__show">
			
					
					{buildingCount ? <Fragment> <span className="bold-text">{_formatNumber(buildingCount)}</span> Projects </Fragment> : ''}
					| Date Range: { selectedDate ? 
						 <Fragment> Week of{` `} 
						 	<span className="bold-text">{moment(selectedDate).format('M/D/YY')}</span>
						 </Fragment>
						:
						 <Fragment> 
						 	<span className="bold-text">{moment(dataStart).format('M/D/YY')}</span> to{` `}  
						 	<span className="bold-text">{moment(dataEnd).format('M/D/YY')}</span>
						</Fragment>
					}	
				</div>
				<div className="material-table__header-info pb-3 mobile__show">
					
					
					{buildingCount ? <Fragment> <span className="bold-text">{_formatNumber(buildingCount)}</span> Projects </Fragment> : ''}
					<br/> Date Range: { selectedDate ? 
						 <Fragment> Week of{` `} 
						 	<span className="bold-text">{moment(selectedDate).format('M/D/YY')}</span>
						 </Fragment>
						:
						 <Fragment> 
						 	<span className="bold-text">{moment(dataStart).format('M/D/YY')}</span> to{` `}  
						 	<span className="bold-text">{moment(dataEnd).format('M/D/YY')}</span>
						</Fragment>
					}	
				</div>*/}
		        <Table className="material-table">
		        <UnitsSearchTableHead
		          numSelected={[...selected].filter(el => el[1]).length}
		          order={order}
		          orderBy={orderBy}
		          onSelectAllClick={handleSelectAllClick}
		          onRequestSort={this._handleRequestUnitsSort}
		          rowCount={reactTableFiltered.length}
		        />
		        <TableBody>
		          {data
		            .map((d, i) => {
		              const itemSelected = isSelected(d.id);
		              const floorplan = false; //_getUnitFloorPlan(d);
					  const buildingData = d;
					  let data = null;
					  if(d.data){
					   		 try{
						   		 data = JSON.parse(d.data);						   		
					   		 }catch(e){
						   		 //cannot parse JSON
					   		 }	   		 
				   	  }

		              return (
		                <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleUnitClick(event, d)}
                          aria-checked={itemSelected}
                          tabIndex={-1}
                          key={i}
                          selected={itemSelected}
                        >
                           <TableCell className="material-table__cell" padding="checkbox" onClick={event => handleClick(event, d.id)}>
		                  	{/*<Tooltip title="Add to List">
		                    	<Checkbox checked={itemSelected} className="material-table__checkbox" />
		                    </Tooltip>   */}                       
		                  </TableCell>
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{moment(d.time).format('M/D/YY')}</span>
                          </TableCell>   
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding=""
                          >
                           <span className="light-text small-text">
                           		{d.topic[0] === "permits" && (d.event_tags.indexOf("nb")>=0 || d.event_tags.indexOf("a1")>=0) &&  
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#FFD23F", borderRadius:3, marginRight:3}} /> Permit Filed
	                           		</Fragment>
	                           	}
                           		{d.topic[0] === "offeringplans" && d.event_tags.indexOf("submitted")>=0 && 
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#fc83ae", borderRadius:3, marginRight:3}} /> Offering Submitted
	                           		</Fragment>
	                           	}
	                           	{d.topic[0] === "offeringplans" && d.event_tags.indexOf("accepted")>=0 && 
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#FF206E", borderRadius:3, marginRight:3}} /> Offering Accepted
	                           		</Fragment>
	                           	}
	                           	{d.topic[0] === "offeringplans" && d.event_tags.indexOf("effective")>=0 &&  
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#82012e", borderRadius:3, marginRight:3}} /> Offering Effective
	                           		</Fragment>
	                           	}
	                           	{d.topic[0] === "coo" &&  
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#FF691E", borderRadius:3, marginRight:3}} /> {d.event_tags.indexOf('coo-temporary') >= 0 ? 'TCO Issued' : 'CO Issued'}
	                           		</Fragment>
	                           	}
                           	</span>

                          </TableCell>    
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <span>
                            	{d.display_full_street_addresses 
	                            	? _capitalizeText(d.display_full_street_addresses[0]) 
	                            	: _capitalizeText(d.display_full_street_address)
	                            }
                            </span>
                            <br/>
                            <span className="light-text small-text" style={{color:"#858AA1"}}>
                            	{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}
                            </span>

                            

                          </TableCell>
                           <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                    <span className="light-text small-text">{
			                    d.topic[0] === "offeringplans" ?
			                    	data ? _capitalizeText(`${data.construction_type} ${data.plan_type}`) : ""
			                    :
			                    d.event_tags.indexOf("nb")>=0 ?	
			                    	_capitalizeText('New Building')
			                    	: _capitalizeText('Major Alteration')
			                    
			                    }
			                </span>
		                  </TableCell>

                            <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	<span className="light-text small-text">
		                  		{
			                  	d.topic[0] === "offeringplans" ?
			                  		d.units > 0 ? d.units : '--' //data.numunits_residential > -1 ? data.numunits_residential :  '--'
			                  	:
			                  		d.units > 0 ? d.units : '--'
			                  	}</span>
		                  </TableCell>
		                                          
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{d.price ? '$'+_formatNumber(d.price) : '--'}</span>
                          </TableCell>                         
                          
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          >
                         
	                         <span className="light-text small-text">
                          		<a href={`javascript:void(0)`} className="alert_show"
                          		onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`https://marketproof.com/article/${d.slugs[0]}`);}}>
                          			See Event
                          		</a><br/>
                          	</span>
	                                           
                          </TableCell>
                          
                        </TableRow>
		              );
		            })}
		          {emptyRows > 0 && (
		            <TableRow style={{ height: 49 * emptyRows }}>
		              <TableCell colSpan={6} />
		            </TableRow>
		          )}
		        </TableBody> 
		        
              
		      </Table>
		      </div>
		      
		      {paginationCount ? <TablePagination
	              component="div"
	              className="material-table__pagination"
	              count={paginationCount}
	              rowsPerPage={rowsPerPage}
	              page={page}
	              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
	              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
	              onChangePage={this.handleChangePage}
	              onChangeRowsPerPage={this.handleChangeRowsPerPage}
	              rowsPerPageOptions={[25, 50, 100]}
	              dir="ltr"
	              SelectProps={{
	                inputProps: { 'aria-label': 'rows per page' },
	                native: true,
	              }}
	            /> : null}
		     
			  <Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle.bind(this)}
		          modalClassName={`ltr-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle.bind(this)} />
		        </div>
		        <div className="modal__body">
		        	<Project buildingRes={buildingRes} />
		        </div>
		        </Modal>
		     </Fragment>
			</Fragment>
			
			
		);
	}
}

export default connect(state => ({
  user: state.user
}))(UnitsListTable);