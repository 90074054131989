import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';

import UncheckedIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import CheckedIcon from 'mdi-react/CheckboxMarkedIcon';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	unitBedToolTips
} from "../../../../shared/helpers/utils";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    fontSize: '12px',
  },
  formLabel: {
	  fontSize: '12px'
  },
  menuItem:{
	   fontSize: '12px'
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: 0,
	  fontSize: '12px'
  },
  label: {
	  marginBottom:0
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BedFilter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { unitBeds, setUnitBed, label, customToolTip } = props;

  
  const handleChip = chip => {
	  console.log(chip)
	 // setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);
	  setUnitBed(chip);

  };
  
 
  const slice = isMobile ? 99 : 7;
  
  const chips = ['all units', 'studios', '1 beds', '2 beds', '3 beds', '4+ beds'];

  return (
    <div className={`${classes.filterContainer} unit_toggle_filter`}> 
    	<div style={{display:isMobile ? "block" : "flex",alignItems:"left"}} >
    		{!isMobile &&<span className="label" style={{lineHeight:"40px", marginRight:10, fontSize:'12px', fontWeight:600}}>{label ? label : 'Show:'}</span>}
	    	<div className={classes.chips+' unit-filter'}>
			     {chips && chips.map(chip => {	
				     const selected = unitBeds && unitBeds.indexOf(chip)>=0;     
				     console.log(chip, unitBeds, selected)
				     return <Tooltip title={`Show ${_capitalizeText(chip)} Units`}>	
						     	<FormControlLabel
							 		control={
							     		<Checkbox
									        checked={selected}
									        onChange={()=>handleChip(chip)}
									        inputProps={{ 'aria-label': 'primary checkbox' }}
									        icon={<UncheckedIcon size={22} />}
									        checkedIcon={<CheckedIcon size={22} />}
									      />
								      }
							        label={`${_capitalizeText(chip)}`}
							      />
					      </Tooltip>
					      				     
			     })}      
			</div>
			<div style={{marginLeft:20}}>
				{/*<a href="https://blocksandlots.com/new-condo-lifecycle-stages-pipeline-sponsor-sale-resale/" target="_blank" style={{fontSize:"11px",color:"#B070CC"}}>
					 Learn More <HelpCircleOutlineIcon size={12} style={{marginTop:"-3px"}}/> 
	    		</a>*/}
			</div>	
	    </div> 	  
    </div>
  );
}
