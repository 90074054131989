import React, { Fragment } from 'react';

import { Col, Container, Row } from 'reactstrap';
import MatTable from './components/MatTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';

import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

import QuickStartGuide from '../../shared/components/QuickStartGuide';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
} from '../../shared/helpers/utils';

const Buildings = ( {user, match } ) => {
	

	  return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Single & Multi-Families</h3>
		       {!isMobile && <h3 className="page-subhead subhead">Search for Houses by Location, Size, Price and More.</h3> }
		      </Col>
		    </Row>
		    {_hasPermission(user, 'building-search') ?
			    <Fragment>
				    <Row>
				      <Col md={12} className={'mb-3'}>
				       	<TrialAlert />        
				      </Col>
				    </Row>
				     {/*<Row>
				    	<Col md={12} className={'mb-3'}>
							<Alert color="success" className="alert--bordered"  alertId="buildingDatabase" icon iconItem={<AlertIcon />} minimizeText={`About The Building Database`}>
					          <p>
					          	<span className="bold-text">{`About The Building Database`}</span> 
					            <div>{`The Building Database is provided 'As-Is'. The information provided herein is provisional and subject to change.`}</div>
					          </p>
					        </Alert>
						</Col>
				    </Row>*/}
					<QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_200234db82"} type={"Houses"} />
				    <Row>
				      <MatTable />
				    </Row>
			    </Fragment>
			    :
			     <Row>
				    <EnterpriseGate />
				 </Row>
		    }
		  </Container>
		  )

};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(withRouter(Buildings)));

