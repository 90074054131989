import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import PhoneOutlineIcon from 'mdi-react/PhoneOutlineIcon';
import ReportIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';

import moment from 'moment';

import{
	_capitalizeText,
} from '../helpers/utils';

class ShareContractAgents extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
	this.unit = false;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
 
  render(){
	  return null;
	  const { property, user } = this.props;
	  
	  let customFields = false;
	  let contractAgents = false;
	  if(property && property.custom_fields_json){
		  try{
			  customFields = JSON.parse(property.custom_fields_json);
		  }catch(e){
			  
		  }
		  
		  if(customFields && customFields.mpnd_user_contract){
			   try{
				  contractAgents = JSON.parse(customFields.mpnd_user_contract);
			  }catch(e){
				  
			  }
		  }
		  
		   
	  }
console.log(contractAgents);	  
	  
	  if(!contractAgents) return null;
	  

	  return (
		<Row>
			<Col md={12} lg={12} xl={12}>
			    <Card className="agent_contract__card">
		          <CardBody> 
		          	  
		          	  
		          	  
		          	  <div className="content">
		          	  	 <div className="contract_date text-center">
		          	  	 	<p className="light-text small-text">Contract Signed</p>
		          	  	 	
		          	  	 	<div><ReportIcon color="#D6BAE8" size={50} /></div>
		          	  	 	<h5 className="contact__title mt-1">{moment.unix(property.last_contract_date).format("M/D/YYYY")}</h5>
				  	  	 </div>
				  	  	 <div className="agent_container">
			             {contractAgents && contractAgents.contract_buyers_agent &&
				          <div className="content__left">
				            <p className="light-text small-text">Buyer Represented By</p>
				          	<h5 className="contact__title mt-1">{contractAgents.contract_buyers_agent.buyerAgentFullName}</h5>
				          	<h6 className="mb-1">
				          		{contractAgents.contract_buyers_agent.buyerAgentFirmName && 
						          	<span> {contractAgents.contract_buyers_agent.buyerAgentFirmName}</span>
					          	}
				          	
				          	</h6>
				          	
				          	{contractAgents.contract_buyers_agent.buyerAgentEmail &&
				          		<p className="small-text light-text mt-1">
					          		<a className="product__external-link" 
				                		href={`mailto:${contractAgents.contract_buyers_agent.buyerAgentEmail}`} 
				                		target="_blank">
				                		<EmailOutlineIcon size={12} /> {(contractAgents.contract_buyers_agent.buyerAgentEmail)} 
				                	</a>
			                	</p>
				          	}
				          	{contractAgents.contract_buyers_agent.buyerAgentPhone &&
				          		<p className="small-text light-text mt-1">
					          		<a className="product__external-link" 
				                		href={`tel:${contractAgents.contract_buyers_agent.buyerAgentPhone}`} 
				                		target="_blank">
				                		<PhoneOutlineIcon size={12} /> {(contractAgents.contract_buyers_agent.buyerAgentPhone)} 
				                	</a>
			                	</p>
				          	}
				          	
				          </div>
				          }
				          {contractAgents && contractAgents.contract_sellers_agent &&
				          <div className="content__right">
				            <p className="light-text small-text">Seller Represented By</p>
				          	<h5 className="contact__title mt-1">{contractAgents.contract_sellers_agent.sellerAgentFullName}</h5>
				          	<h6 className="mb-1">
				          		{contractAgents.contract_sellers_agent.sellerAgentFirmName && 
						          	<span> {contractAgents.contract_sellers_agent.sellerAgentFirmName}</span>
					          	}
				          	
				          	</h6>
				          	{contractAgents.contract_sellers_agent.sellerAgentEmail &&
				          		<p className="small-text light-text mt-1">
					          		<a className="product__external-link" 
				                		href={`mailto:${contractAgents.contract_sellers_agent.sellerAgentEmail}`} 
				                		target="_blank">
				                		<EmailOutlineIcon size={12} /> {(contractAgents.contract_sellers_agent.sellerAgentEmail)} 
				                	</a>
			                	</p>
				          	}
				          	{contractAgents.contract_sellers_agent.sellerAgentPhone &&
				          		<p className="small-text light-text mt-1">
					          		<a className="product__external-link" 
				                		href={`tel:${contractAgents.contract_sellers_agent.sellerAgentPhone}`} 
				                		target="_blank">
				                		<PhoneOutlineIcon size={12} /> {(contractAgents.contract_sellers_agent.sellerAgentPhone)} 
				                	</a>
			                	</p>
				          	}
				          	
				          </div>
				          }
				          </div>
			          </div>
		          </CardBody>
		        </Card>
			</Col>
		</Row>
	
	  );
	}
};
export default ShareContractAgents;