import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import TrialAlert from '../../shared/components/payment/TrialAlert';

import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';

import { _hasPermission	} from "../../shared/helpers/access";
import QuickStartGuide from '../../shared/components/QuickStartGuide';


class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user } = this.props;
	    if(!user) return null;

		console.log("market activity")

		return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Market Activity</h3>
		        <h3 className="page-subhead subhead">Analyze Historical Listing, Contract and Sales.</h3>
		      </Col>
		    </Row>
		     {_hasPermission(user, 'market-activity-chart') ?
			 <Fragment>
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			  {/* <Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
				          <p>
				          	<span className="bold-text">{`About The Data`}</span> 
				            <div>{`The information provided herein is provisional and subject to change as more buildings and units are vetted.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row>*/}
			   <QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_c6ebb68427"} type={"Market Activity"} />			    <Row>
			      <UnitsSearchTable />
			    </Row>
			 </Fragment>
			 :
		     <Row>
			    <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-discount-analysis-mockup.jpeg`}/>
			 </Row>

		    }
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



