import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import SpeedometerIcon from 'mdi-react/SpeedometerIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 

import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';


  
const calculateVelocity = (buildingRes) => {	
	
	if(!buildingRes.sales_start_date) return '--';
	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);	
	const velocity = (totalSold / months).toFixed(1);
	
	return velocity;
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes) => {	
	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalSold, fill: getDefaultChartColors[0] },
  				{ value: totalAvail, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const SalesVelocity = (props, { t }) => (
  <Col md={12} xl={6} lg={6} sm={12} xs={12}>
    <Card>
      <CardBody className="dashboard__health-chart-card">
        <div className="card__title">
          <h5 className="bold-text card__title-center">{`Monthly Sales Velocity`}</h5>
        </div>
        <div className="dashboard__health-chart"> 
          <ResponsiveContainer height={180}>
            <PieChart>
              <Pie data={calculateBarData(props.buildingRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
            </PieChart>
          </ResponsiveContainer>
          <div className="dashboard__health-chart-info">
            <SpeedometerIcon style={{ fill: getDefaultChartColors[0] }} />
            <p className="dashboard__health-chart-number">{calculateVelocity(props.buildingRes)}</p>
            <p className="dashboard__health-chart-units">sales/mo</p>
          </div>
        </div>
        <p className="dashboard__goal">Sales Started: {calcuateSalesStart(props.buildingRes, 'fromNow')} {calcuateSalesStart(props.buildingRes)}</p>
      </CardBody>
    </Card>
  </Col>
);

SalesVelocity.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(SalesVelocity);
