import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row,  Card, CardBody } from 'reactstrap';import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setUserMyReports } from '../../redux/actions/authActions';

import List from './components/List';

import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';

import showResults from './Show';

import SnackBar from '../../shared/components/SimpleSnackBar';

import TrialAlert from '../../shared/components/payment/TrialAlert';

import EmptyList from './components/EmptyList';


import fileDownload from 'js-file-download';

import{
	_capitalizeText,
	_objectEqual
} from '../../shared/helpers/utils';
import{
	_axiosCall
} from '../../shared/helpers/apicalls';

import { 
	_reportFilters
} from "../../shared/helpers/filters";


const oktaToken = process.env.OKTA_TOKEN;

const apiUrl = process.env.API_ROOT;
//const apiUrl = 'http://localhost:8000/buyerslist/';
const apiURL = process.env.API_ROOT + '/query/';

//const apiUrl = 'https://newsapi.we3.com/buyerslist/'
const defaultSubFilter = {};
class MyReports extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      snackShow: false,
	      snackMessage: false,
	      snackType: 'success',
	      reports: null,
	      refresh:false,
		  order:  "desc",
		  orderBy: "created_timestamp",
		  page: 0,
		  rowsPerPage: 10,
		  numFound: 0,
		  searchText : '',
		  subFilters : defaultSubFilter,
		  buildingRes: null,
		  unitRes: null
	    };
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);
	    this._getReports = this._getReports.bind(this);
	    this.toggleRefresh = this.toggleRefresh.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);	 
		this.handleSearch = this.handleSearch.bind(this);	
		this._setSubFilter = this._setSubFilter.bind(this);	
		this._resetSubFilter = this._resetSubFilter.bind(this);	     
		this._setSubFilters = this._setSubFilters.bind(this);
	}
	  
    componentDidMount(){
	  const { authState, authService, dispatch, user } = this.props;
	  console.log(authState);
	  this._getReports();
    }
    componentDidUpdate(prevProps, prevState) {
	    
	    const { user } = this.props;
	    let subFilterChanged =
		_reportFilters.filter(item => {
			return (
				Array.isArray(item.search_key) ? 
					item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
				:
					this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
			);
		}).length > 0;
		
	    if(subFilterChanged ||
			prevState.refresh !== this.state.refresh ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.searchText !== prevState.searchText
		){
		     this._getReports();

	    }
	    
	}
   	
	toggleRefresh = () => {
    	this.setState(prevState => ({ refresh: !prevState.refresh }));;
    };
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_getReports = () => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user, group, subTab } = this.props;	  	  
 	  const { orderBy, order, rowsPerPage, page, searchText, subFilters } = this.state;
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  let sort = {};
	  sort[orderBy] = order == "desc" ? -1 : 1;
	  
	  let query = {
		  user_key: user.id,
		  source : { "$ne" : "PIPELINE-APP"},
		  deleted : { "$exists" : false }
	  }
	  if(searchText){
		  query['$or'] = [ 
			  {"custom_data.report_title" : { "$regex" : searchText, "$options" : "i"}}, 
			  {"building.name" : { "$regex" : searchText, "$options" : "i"}}, 
			  {"building.display_full_street_address" : { "$regex" : searchText, "$options" : "i"}}, 
			  {"property.address_2_normalized" : { "$regex" : searchText, "$options" : "i"}},
			  {"custom_data.group.group_name" : { "$regex" : searchText, "$options" : "i"}},
			  {"custom_link" : { "$regex" : searchText, "$options" : "i"}}
		  ];
	  }
	  
	  if(subFilters){
		  Object.keys(subFilters).map(key => {
			  const qVal = subFilters[key];
			  const theFilter = _reportFilters.filter(filter => filter.search_key === key)[0];
			  const theVal = theFilter.values.filter(val => val.filter == qVal)[0]
			  query = Object.assign(query, theVal.search_value);
		  })
	  }
	  
	  if(subTab && group && group.group_id){
		  query['group_id'] = group.group_id;
	  }
	  let userData = {
			url: apiUrl+'/buyerslist/sharepage/query',
			method: "post",
			
			query: {
				query : query,
				sort : sort,
				limit : rowsPerPage,
				skip : (page) * rowsPerPage
				
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getReports res back ,", res);
	      const unitKeys = [];
		  const buildingKeys = [];
		  
	      if(res && res.numFound>0){	
			  
			  res.docs.map(item => {
				  if(item.custom_data && item.custom_data.group && item.custom_data.group.group_data){
					  if(item.custom_data.group.group_data.buildings && item.custom_data.group.group_data.buildings[0]){
						  buildingKeys.push(item.custom_data.group.group_data.buildings[0])
					  }else if(item.custom_data.group.group_data.units && item.custom_data.group.group_data.units[0]){
						  unitKeys.push(item.custom_data.group.group_data.units[0])
					  }
				  }
			  });
			  this.setState( { numFound: res.numFound, reports: res.docs } );
			  if(buildingKeys.length>0){
				  this._getBuildings(buildingKeys)
			  }else{
				  this.setState({buildingRes:false});
			  }
			  if(unitKeys.length>0){
					this._getUnits(unitKeys)
			  }else{
					this.setState({unitRes:false});
				}
		      
		   }else{
			  this.setState( { reports: [], buildingRes:false, unitRes:false } ); 
		   }
	  })
	  .catch((error) => {
	    console.log("_getReports error ", error);
	  });

	}
	_getBuildings = (keys) => {
		  
		  var q = "key:("+keys.join(" ")+")";
		  
		  var fq = [
			  "deleted:false"		
		  ];
  
  
		  let axiosData = {
			  url: apiURL + "/we3-buildings?cache=60",
			  method: "post",
			  query: {
				  q: q,
				  fq: fq,
				  fl: ["key", "pictures", "image", "display_full_street_address", "full_street_address", "city", "neighborhood", "borough", "zip"],
				  wt: "json",
				  rows: 50
			  }
		  };
		  
  
		  let formattedData = _axiosCall(axiosData)
			  .then(res => {
				  //this._setBuyersList(res);
				  if(res && res.docs){
					  
					  this.setState({ buildingRes : res.docs, fetchInProgress : false, buildingsFetchInProgress: false });
				  }else{
					  this.setState({ buildingRes : [], fetchInProgress : false, buildingsFetchInProgress: false });
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
			  
  
		  // console.log(formattedData)
		  return formattedData;
	};

   _getUnits = (keys) => {
		
		
		 let q = "key:("+keys.join(" ")+")";
		 
		 let fq = [
			 "deleted:false"			
		 ];
   
   
		 let axiosData = {
			 url: apiURL + "/we3-properties?cache=60",
			 method: "post",
			 query: {
				 q: q,
				 fq: fq,
				 fl: ["key", "pictures", "floorplans", "offering_floorplans", "display_full_street_address", "full_street_address", "city", "neighborhood", "borough", "zip", "last_listed_sale_key_all"],
				 wt: "json",
				 rows: 50
			 }
		 };
		 
   
		 let formattedData = _axiosCall(axiosData)
			 .then(res => {
				 //this._setBuyersList(res);
				 if(res && res.docs){
					 let lKeys = res.docs.filter(unit => unit.last_listed_sale_key_all && unit.last_listed_sale_key_all != "").map(unit => unit.last_listed_sale_key_all);
					 if(!lKeys || lKeys.length<=0){
						this.setState({ unitRes : res.docs, fetchInProgress : false, fetchInProgress: false });
					 }else{
						this._getListingData(res.docs, lKeys);
					 }
					 
					 
				 }else{
					 this.setState({ unitRes : [], fetchInProgress : false, fetchInProgress: false });
				 }
				 
			 })
			 .catch(error => {
				 console.log("error: " + error);
			 });
			 
   
		 // console.log(formattedData)
		 return formattedData;
   };
   
   _getListingData = (unitRes, keys) => {
			console.log(keys)
		  if(!keys || keys.length<=0) {
			  return false;
		  }
		  
		  let q = "key:("+keys.join(" ")+")";
   
		  let axiosData = {
			  url: apiURL + "search-listings?cache=15",
			  method: "post",
			  query: {
				  q: q,
				  fq: ["deleted:false","sale_status:active"],
				  sort: "solr_updated_date desc",
				  fl: ["key", "pictures", "main_photo",  "display_full_street_address", "full_street_address", "city", "neighborhood", "borough", "zip"],
				  wt: "json",
				  rows: 10
			  }
		  };
   
		  let formattedData = _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  if(res.docs && res.docs.length>0){
					  unitRes.map(unit => {
						  unit['listing'] = res.docs.filter(l => l.key == unit['last_listed_sale_key_all']).length > 0 ? res.docs.filter(l => l.key == unit['last_listed_sale_key_all'])[0] : false;
					  })					
					  this.setState({unitRes: unitRes});
				  }else{
					  this.setState({unitRes: unitRes});
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
		  return formattedData;
	};
	handleRequestSort = (event, orderBy) => {
		let order = 'desc';
		const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
		if (stateOrderBy === orderBy && stateOrder === 'desc') { order = 'asc'; }
	
		this.setState({ order, orderBy, page : 0 });
	};
	handleChangePage = (event, page) => {
		this.setState({ page: page, start: page * this.state.rowsPerPage });
	  };
	
	  handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
	  };
	
	handleSearch = (searchText) => {
		this.setState({ searchText : searchText});
	}
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
	
	}
	
	_resetActive = () => {
		
		let isDefault = _objectEqual(defaultSubFilter, this.state.subFilters);
		return !isDefault;
		
	}
	render(){
		const { snackShow, snackMessage, snackType, reports, subFilters, searchText, unitRes, buildingRes } = this.state;
		const { user, subTab } = this.props;
		
		
		if(reports === null || unitRes === null || buildingRes === null) return null;
		if(subTab){
			
			return (
				<Fragment> 
					<SnackBar 
						snackShow={snackShow} 
						snackMessage={snackMessage} 
						snackClose={this.snackClose} 
						snackDuration={5000} 
						snackType={snackType} 
					/>
				{reports.length<=0 ?
					<Row>
				  	<EmptyList {...this.props} subTab/>			      
					</Row>
					:
					<Row>
				  	<List 
					  	{...this.props} 
						{...this.state}
						toggleRefresh={this.toggleRefresh} 
						handleRequestSort={this.handleRequestSort} 
						handleChangePage={this.handleChangePage}  
						handleChangeRowsPerPage={this.handleChangeRowsPerPage}
						handleSearch={this.handleSearch}
						setSubFilter={this._setSubFilter}
						setSubFilters={this._setSubFilters}
						resetSubFilter={this._resetSubFilter}
						filters={[]}
						resetActive={this._resetActive()}
						subTab
					 	/>			      
					</Row>
					
					
					
				}
			</Fragment>
			
			)
			
			
		}
		
		return (
			<Container>
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
			    <Row>
			      <Col md={12}>
			        <h3 className="page-title">{`My Shared`}</h3>
			        <h3 className="page-subhead subhead">Manage Your Shared Buidings, Units and Collections.</h3>
			      </Col>
			    </Row>
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
				<Fragment>  
								    
				    {reports.length<=0 && !searchText && Object.keys(subFilters).length<=0 ?
						<Row>
						  <EmptyList {...this.props}/>			      
						</Row>
						:
					    <Row>
					      <List 
						  	{...this.props} 
							{...this.state}
							toggleRefresh={this.toggleRefresh} 
							handleRequestSort={this.handleRequestSort} 
							handleChangePage={this.handleChangePage}  
							handleChangeRowsPerPage={this.handleChangeRowsPerPage}
							handleSearch={this.handleSearch}
							setSubFilter={this._setSubFilter}
							setSubFilters={this._setSubFilters}
							resetSubFilter={this._resetSubFilter}
							filters={_reportFilters}
							resetActive={this._resetActive()}
							 />			      
					    </Row>
					    
					    
					    
				    }
			    </Fragment>
				    
			    

			</Container>
		);	
	}
  
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(MyReports));
