import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, IconButton, Radio, RadioGroup, FormControlLabel, ThemeProvider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Container, Tooltip } from '@material-ui/core';
import { ButtonToolbar } from 'reactstrap';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { matTheme } from '../../../../shared/helpers/utils';
import FileDocumentEditOutlineIcon from 'mdi-react/FileDocumentEditOutlineIcon';
import ClearIcon from 'mdi-react/TrashCanOutlineIcon';
import UpdateIcon from 'mdi-react/ContentSaveEditOutlineIcon';

import LinearLoading from '../../../../shared/components/LinearLoading';

const apiUrl = process.env.API_ROOT + '/';

const styles = theme => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft: "-5px",
	marginTop: "0px",
	marginBottom: "20px",
	alignItems: 'left',
	maxWidth: 400
  },
  button: {
	'&.selected': {
	  background: "#780F9E",
	  color: "#FFF"
	},
	fontSize: 14,
	margin: "0 5px 0 0"
  },
  '.btn-toolbar': {
	justifyContent: 'center'
  }
});
const detailOptions = {
  "no-details": "No Details",
  "latest-50": "Show Latest Activity - 30 Max",
  "top-50": "Show Top Activity by Price - 30 Max"
}
const ExportSettingsForm = (props) => {
  const { t, user, classes, setPreview, reportParams, setParentFetchInProgress, formValues, setFormValues, handleAI, aiInProgress, aiRun } = props;

  const [localFormValues, setLocalFormValues] = useState(formValues);
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const editorRef = useRef(null);

  useEffect(() => {
	setParentFetchInProgress(fetchInProgress);
  }, [fetchInProgress]);

  useEffect(() => {
	setLocalFormValues(formValues);
	if (formValues.aiRefresh) {
	  setPreview();
	}
  }, [formValues]);

  const handleFieldChange = (field, value) => {
	setLocalFormValues(prevState => ({ ...prevState, [field]: value }));
	setFormValues(prevState => ({ ...prevState, [field]: value }));
	setPreview();
  };

  const handleLocalFieldChange = (field, value) => {
	setLocalFormValues(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSave = () => {
	setFormValues(localFormValues);
	setPreview();
  };

  const handleEditorChange = (field, value) => {
	if (typingTimeout) {
	  clearTimeout(typingTimeout);
	}
	handleLocalFieldChange(field, value);
	const newTimeout = setTimeout(() => {
	  setFormValues(prevState => ({ ...prevState, [field]: value }));
	  setPreview();
	}, 500);

	setTypingTimeout(newTimeout);
  };

  const clearField = (field) => {
	setLocalFormValues(prevState => ({ ...prevState, summary:"" }));
	setFormValues(prevState => ({ ...prevState, summary:"" }));
	setPreview();
  };

  return (
	<form className="material-form">
	  <ExpansionPanel TransitionProps={{ unmountOnExit: true }} defaultExpanded id={"title"}>
		<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="" id={"title"}>
		  <Container>
			<div style={{ display: "flex", justifyContent: "space-between", marginRight: -30 }}>
			  <div>
				<span className="material-form__label bold">Edit Title and Summary</span>
				<div className="small-text light-text">Provide context and background for the reader</div>
			  </div>
			  <Tooltip title="Use Marketproof AI to generate a title and summary">
				<Button variant="contained" color="primary" className="buyersList__primary-button" onClick={(e) => { handleAI(e); }} startIcon={<FileDocumentEditOutlineIcon size={16} style={{ marginTop: -2 }} />} style={{ fontSize: 13 }} disabled={fetchInProgress || aiInProgress}>
				  Rewrite
				</Button>
			  </Tooltip>
			</div>
		  </Container>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
		  <ThemeProvider theme={matTheme}>
			<Container>
			  <div className={classes.root} style={{ marginTop: -25 }}>
				<div style={{ marginLeft: -5, height: 20 }}>{aiRun && aiInProgress && <LinearLoading />}</div>
				<div className="share__link">
				  <span className="material-form__label bold">Title</span>
				 
				  <TextField 
					value={localFormValues.reportTitle || ''} 
					onChange={(e) => handleEditorChange('reportTitle', e.target.value)} 
					variant="outlined" 
					className="material-form__field" 
				  />
				 
				</div>
				
				<div className="share__link">
				<hr/>
				 <span className="material-form__label bold">Summary</span>
					<JoditEditor 
					  ref={editorRef} 
					  value={localFormValues.summary || ''} 
					  onBlur={(newContent) => { handleLocalFieldChange('summary', newContent); }} 
					  config={{ readonly: false, height: 300, maxWidth: 460, allowResizeX: false, enableDragAndDropFileToEditor: false, buttons: ['bold', 'italic', 'underline', 'fontsize', '|', 'link', 'hr', 'align', '|', 'undo', 'redo'], buttonsMD: ['bold', 'italic', 'underline', 'fontsize', '|', 'link', 'hr', 'align', '|', 'undo', 'redo'], buttonsXS: ['bold', 'italic', 'underline', 'fontsize', '|', 'link', 'hr', 'align', '|', 'undo', 'redo'], placeholder: "Report summary", style: { fontSize: 16 } }} 
					/>
					
				</div>
			  </div>
			  <ButtonToolbar className="modal__footer mb-3" style={{ justifyContent: "space-between" }}>
	
				<Tooltip title="Remove summary">
				  <Button variant="outlined" color="secondary" style={{minWidth:50}} className="buyersList__secondary-button" startIcon={<ClearIcon  size={16} style={{ marginTop: -2 }} />} disabled={fetchInProgress || aiInProgress} onClick={() => clearField()}>Remove</Button>
				</Tooltip>
				<Tooltip title="Update summary and title">
					<Button variant="outlined" color="secondary" className="buyersList__secondary-button" disabled={fetchInProgress || aiInProgress} onClick={handleSave} startIcon={<UpdateIcon size={16} style={{ marginTop: -2 }} />} style={{ fontSize: 13 }} disabled={fetchInProgress || aiInProgress}>
					  Update Summary
					</Button>
				  </Tooltip>
				
			  </ButtonToolbar>
			</Container>
		  </ThemeProvider>
		</ExpansionPanelDetails>
	  </ExpansionPanel>
	  <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"details"}>
		  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="" id={"details"}>
			<Container>
			  <span className="material-form__label bold">Include Snapshot Details <span className="small-text">({`${localFormValues.details ? detailOptions[localFormValues.details] : detailOptions['no-details']}`})</span></span>
			  <div className="small-text light-text">Choose how to display additional information</div>
			</Container>
		  </ExpansionPanelSummary>
		  <ExpansionPanelDetails>
			<Container>
			  <div className={classes.root}>
				<RadioGroup aria-label="details" name="details" value={localFormValues.details || 'no-details'} onChange={(e) => handleFieldChange('details', e.target.value)} onClick={e => e.stopPropagation()} row>
				  {Object.keys(detailOptions).map((key) => (
					<div style={{ width: "100%" }}><FormControlLabel key={key} value={key} control={<Radio />} label={detailOptions[key]} /></div>
				  ))}
				</RadioGroup>
			  </div>
			</Container>
		  </ExpansionPanelDetails>
		</ExpansionPanel>
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"contact"}>
		  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="" id={"contact"}>
			<Container>
			  <span className="material-form__label bold">Select Branding Options <span className="small-text">(Optional)</span></span>
			  <div className="small-text light-text">Choose the logo that appears in the header {user && user.logo ? <span>(Update under <Link to={`/my-account`} className="link-text">My Account</Link>)</span> : null}</div>
			</Container>
		  </ExpansionPanelSummary>
		  <ExpansionPanelDetails>
			<Container>
			  <div className={classes.root}>
				{user && !user.logo ?
				  <p style={{ padding: '10px', fontSize: '14px', background: '#F5F7FA', marginBottom: '20px', marginTop: '-25px', border: '1px solid #B070CC' }}>To use your branding, add a logo under <Link to={`/my-account`} className="link-text">My Account</Link>.</p>
				  : null
				}
				<RadioGroup aria-label="branding" name="branding" value={localFormValues.branding || 'marketproof'} onChange={(e) => handleFieldChange('branding', e.target.value)} onClick={e => e.stopPropagation()}>
				  {user && user.logo &&
					<div style={{ width: "100%" }}>
					  <FormControlLabel value="mylogo" control={<Radio />} label={
						<div style={{ display: 'flex', alignItems: 'center' }}>
						  <img src={user.logo} style={{ width: 160 }} alt="User Logo" /> <span className="ml-3 small-text">(My Branding)</span>
						</div>
					  } />
					</div>
				  }
				  <div style={{ width: "100%" }}>
					<FormControlLabel value="marketproof" control={<Radio />} label={
					  <div style={{ display: 'flex', alignItems: 'center' }}>
						<img src="https://s3.amazonaws.com/buyerslist/users/image-upload/1718754011_be3bf6835dbc3854f2d0a170e7ee8681.png" style={{ width: 160 }} alt="Marketproof" />
						<span className="ml-3 small-text">(Marketproof)</span>
					  </div>
					} />
				  </div>
				  <div style={{ width: "100%" }}><FormControlLabel value="hide" control={<Radio />} label="No Branding" /></div>
				</RadioGroup>
			  </div>
			</Container>
		  </ExpansionPanelDetails>
		</ExpansionPanel>
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"contact"}>
		  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="" id={"contact"}>
			<Container>
			  <span className="material-form__label bold">Choose a Contact Method <span className="small-text">(Optional)</span></span>
			  <div className="small-text light-text">Display your contact information (Update under <Link to={`/my-account`} className="link-text">My Account</Link>)</div>
			</Container>
		  </ExpansionPanelSummary>
		  <ExpansionPanelDetails>
			<Container>
			  <div className={classes.root}>
				<RadioGroup aria-label="contact" name="contact" value={localFormValues.contact || 'hide'} onChange={(e) => handleFieldChange('contact', e.target.value)} onClick={e => e.stopPropagation()} row>
				  <FormControlLabel value="hide" control={<Radio />} label="Hide My Contact Info" />
				  <FormControlLabel value="show" control={<Radio />} label="Show My Contact Info" />
				</RadioGroup>
			  </div>
			</Container>
		  </ExpansionPanelDetails>
		</ExpansionPanel>
		<hr />
	</form>
  );
};

ExportSettingsForm.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setPreview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
	user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(ExportSettingsForm));
