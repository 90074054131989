/* eslint-disable react/no-children-prop */
import React, { Fragment } from 'react';
import {
   Card, CardBody, Col, Badge, Row, Container
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from "react-router-dom";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import { Tooltip } from '@material-ui/core';
// import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import PreviewIcon from 'mdi-react/EyeIcon';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from 'mdi-react/CloudDownloadIcon';
import IconShare from 'mdi-react/AccountPlusOutlineIcon';
import ShareButton from '../../../shared/components/buttons/ShareButton';
import ReportButton from '../../SharePage/ReportButton';
import AddListIcon from 'mdi-react/ViewGridPlusIcon';
import Table from '@material-ui/core/Table';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';

import UnitsSearchAppBar from './UnitsSearchAppBar';

import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	subscriptionPlans,
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	_getBuildingURL,
	_formatPrice,
	BuildingPhoto,
	UnitCurrentStatus,
	_getUnitFloorPlan,
	_nameToUrl
} from '../../../shared/helpers/utils';
import{
	_shareThemes,
} from '../../../shared/helpers/access';


const styles = theme => ({
  root: {
	marginTop:"15px",
	display: 'flex',
	flexDirection: 'column',
	//marginLeft:"-8px",
	marginBottom:"15px",
	alignItems: 'left',
	'& > *': {
	   
	},
  },
  button:{
	'&.selected' :{
		background:"#D1D6E0"
	},
	fontSize:13,
	width:120
  }
});
const generateShareLink = ( report ) => {		 
		
		let shareBaseURL = window.location.protocol + "//" + window.location.host + "/share/"
		  if(report && report.report_type && ['building-comp'].indexOf(report.report_type)>=0){
			  shareBaseURL = window.location.protocol + "//" + window.location.host + "/private-report/"
		  }
		 if(report && report.custom_link){
			 return shareBaseURL + report.custom_link; 
		 }
		 
		 return shareBaseURL; 
	}

const GetGroupImage = ( {group,  unitRes, buildingRes} ) => {
	  let bldg = false;
	  let unit = false;
	  if(buildingRes && group && group.group_data && group.group_data.buildings && group.group_data.buildings[0]){
		bldg = buildingRes.filter(b => b.key == group.group_data.buildings[0]);		  
		
	  }else if(unitRes && group.group_data && group.group_data.units && group.group_data.units[0]){
		unit = unitRes.filter(u => u.key ==  group.group_data.units[0])
	  }
	  //console.log(bldg, unit)
	  if(bldg && bldg[0]){
		return <BuildingPhoto item={bldg[0]} />
	  }else if(unit && unit[0] && _getUnitFloorPlan(unit[0])){
		return <div class="todo__lead-image fp" style={{backgroundImage: `url(${_getUnitFloorPlan(unit[0])[0].src})`}} />
	  }else{
		return <AddListIcon />
	  }
	  
	}
const ShowReports = ({data, numFound,  orderBy, order, rowsPerPage, page, paginationCount, toggleRefresh, handleRequestSort, handleChangePage, handleChangeRowsPerPage, unitRes, buildingRes }) => {

	return (
		<Fragment>
		
		<div className="material-table__wrap">
			{data && 
			<Table className="material-table trend-buildings">
			<MatTableHead
			  	numSelected={0}
			  	order={order}
			  	orderBy={orderBy}
			  	onSelectAllClick={null}
			  	onRequestSort={handleRequestSort}
			  	rowCount={data.length}
				/>
				
				<TableBody>
			  
		  {data && Array.isArray(data) && data.map((report, i) => {
			  
				const item = report.building ? report.building : false;
				const shareLink = generateShareLink(report);
				let buildingNameOrAdr, adr, unitURL = false;
				
				if(item){
					 buildingNameOrAdr = item.name 
					  ? _capitalizeText(item.name)
					  : item.display_full_street_address
						? _capitalizeText(item.display_full_street_address)
						: _capitalizeText(item.full_street_address);
					 adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
					 unitURL = _getBuildingURL(item, 'key');
					if(report.property && report.property.address_2_normalized){
						unitURL += '/' + _nameToUrl(report.property.address_2_normalized);
					}
				}
				  
			  return (
			 
					 <TableRow
					   className="material-table__row"
					   role="checkbox"
					   //onClick={(e) => {e.stopPropagation();window.open(shareLink)}}
					   aria-checked={false}
					   tabIndex={-1}
					   key={report.link_id}
					   selected={false}
					 >
					   <TableCell className="material-table__cell" padding="">
							 <div className="report-subject">
								{report.property && report.property.address_2_normalized ? 
									<Fragment>
										<div >
											<Link to={unitURL}>
												{_getUnitFloorPlan(item) ?
													<div class="todo__lead-image fp" style={{backgroundImage: `url(${_getUnitFloorPlan(item)[0].src})`}} />
													:
													  <FloorPlanIcon size={200} color="#CCC" />
												  }	
											</Link>
										</div>
										<div className="subject">
											
											<div><Link to={unitURL}>{buildingNameOrAdr}, {report.property.address_2_normalized.toUpperCase().replace("APT", "Apt")}</Link><br/>
											<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''}
								 			{_capitalizeText(item.borough)}
								 			</span>
								 			</div>
											{report.property.baths>0 ?
								 			<Fragment>
												<span className="light-text small-text">{report.property.bedrooms > -1 ? report.property.bedrooms === 0 ? 'Studio' : report.property.bedrooms+' Bed' : '--'}</span>
												{` | `}<span className="light-text small-text">{report.property.baths > -1 ? report.property.baths+' Bath' : '--'}</span>
												
											</Fragment>								 		
											:
								 			report.property.sq_ft>0 ? 
												<span className="light-text small-text">
													{_formatNumber(report.property.sq_ft)} sq ft
												</span>
											:
												<span className="light-text small-text">--</span>
											}
										</div>
									</Fragment>	
								
								: report.building ?
								    <Fragment>
										<div><Link to={unitURL}><BuildingPhoto item={item} /></Link></div>
										<div className="subject">
											<div><Link to={unitURL}>{buildingNameOrAdr}</Link> <BuildingStageBadge item={item} small /></div>
											<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
										 	{/*_capitalizeText(item.borough)*/}
										 	</span>
											<br/> 	
											<span className="light-text small-text" style={{color:"#858AA1"}}>{item.building_status == 'resale' && item.number_of_buildings > 1 && item.tax_assessed_total_units > 1 ?  _formatNumber(item.tax_assessed_total_units) :  _formatNumber(item.total_units)} Total Units</span>
										</div>
									</Fragment>
									:
									<Fragment>
									<div className="c-image"><GetGroupImage group={report.custom_data.group} unitRes={unitRes} buildingRes={buildingRes} /> <span className="c-icon"><AddListIcon size="12" /></span></div>
									<div className="subject">
										{report.custom_data && report.custom_data.report_title ? _capitalizeText(report.custom_data.report_title) : "Untitled"}
										<br/>
										<span className="light-text small-text">Collection:{' '}
											{report.custom_data && report.custom_data.group ? _capitalizeText(report.custom_data.group.group_name) : "--"}
										</span>
										
										<div>
											<span className="small-text light-text">									  
												  Buildings: {report.custom_data && report.custom_data.group && report.custom_data.group.group_data && report.custom_data.group.group_data.buildings && report.custom_data.group.group_data.buildings.length} {' '}
											  </span>				          
											<span className="small-text light-text">{' | '}</span>
											<span className="small-text light-text">									  
												  Units: {report.custom_data && report.custom_data.group && report.custom_data.group.group_data && report.custom_data.group.group_data.units && report.custom_data.group.group_data.units.length} {' '}
											</span>							  	
											  
										  </div>
									</div>
									</Fragment>
							    }
								
							</div>
							
						</TableCell>
						 
						  <TableCell
							className="material-table__cell material-table__cell-right"
							size="small"                          
						  >	 
							<span className="small-text"><IconShare color={'#BFC4D1'} size={20} style={{marginRight:5,marginTop:-3}}/> {report.property ? "Single Unit" : report.building ? "Single Building" : _capitalizeText(report.report_type) + ' Report'}</span>
							<br/>
							<span className="light-text small-text" style={{color:"#858AA1"}}>Link:{' '} 
								<a href={shareLink} className="link-text" target="_blank">{report.custom_link}</a>
							</span>
							<br/>
							{report.custom_data && report.custom_data.prepared_for &&
							<span className="light-text small-text" style={{color:"#858AA1"}}>Prepared For:{' '} 
								{report.custom_data.prepared_for}
							</span>
							}
							{/*
							<br/>
							<span className="light-text small-text" style={{color:"#858AA1"}}>Theme:{' '} 
							   {report.custom_data && _shareThemes[report.custom_data.report_theme] ? _shareThemes[report.custom_data.report_theme]['display_name'] : "Marketproof"}
							</span>
							*/}
						</TableCell>
						 
						  <TableCell
							className="material-table__cell material-table__cell-right"
							size="small"                          
						  >		 
							<span className="light-text small-text" style={{color:"#858AA1"}}>{moment(report.created_timestamp).format('M/D/YYYY hh:mm A')}</span>
							
						</TableCell>
						 
						  <TableCell
							className="material-table__cell material-table__cell-right"
							size="small"                          
						  >	 
							<span className="light-text small-text" style={{color:"#858AA1"}}>{moment(report.modified_timestamp).format('M/D/YYYY hh:mm A')}</span>
							
						</TableCell>
						 
						  <TableCell
							className="material-table__cell material-table__cell-right"
							size="small"                          
						  >
							{!report.report_type || report.report_type == "single" ?
								<ShareButton buildingRes={item} linkID={report.link_id} property={report.property ? report.property  : false} report={report} toggleRefresh={toggleRefresh} shareLink={shareLink}/>
								:
								<ReportButton group={report.custom_data.group} linkID={report.link_id}  report={report} toggleRefresh={toggleRefresh} shareLink={shareLink}/>
							}
							
							<Tooltip title="View This Shared Page">	            		
							  <IconButton	
								  className="material-table__toolbar-button"
								  onClick={(e) => {e.stopPropagation();window.open(shareLink)}}
								  
							  >
							  <PreviewIcon /> 
							  </IconButton>
		  					</Tooltip>
							
		  				</TableCell>
						</TableRow>
				)
			  
		  })} 
		 
			</TableBody>
			  </Table>
		 }
		 </div>
			 {numFound ? <TablePagination
			   component="div"
			   className="material-table__pagination"
			   count={numFound}
			   rowsPerPage={rowsPerPage}
			   page={page}
			   backIconButtonProps={{ 'aria-label': 'Previous Page' }}
			   nextIconButtonProps={{ 'aria-label': 'Next Page' }}
			   onChangePage={handleChangePage}
			   onChangeRowsPerPage={handleChangeRowsPerPage}
			   rowsPerPageOptions={[10, 25, 50, 100]}
			   dir="ltr"
			   SelectProps={{
				 inputProps: { 'aria-label': 'rows per page' },
				 native: true,
			   }}
			 /> : null}
		 </Fragment>

	)
}


const List = ( props ) => {
	const { user, reports, toggleRefresh, handleRequestSort, filters, subTab, classes, viewType, setSubFilter, subFilters} = props;
	//console.log(user);
	const { subscriptionPlan } = user;
	const currentPlan = subscriptionPlans.filter(plan => plan.id === subscriptionPlan)[0];
	console.log(reports, subTab)
	return(	  	
		<Col md={12} lg={12}>
			{!subTab ?
			<Card>
			  <CardBody className="unit_search" >
			  <UnitsSearchAppBar 				  
				  onRequestSort={handleRequestSort} 				 
				  _filters={filters}      
				  {...props}  						
				  numToShow={7}				  
			   />
			   {!isMobile && filters.length>0 &&
			   <Row>
				  <Col md={12}>
			   		<div className={classes.root}>
			   		   <ButtonGroup size="small" aria-label="small outlined button group">
							  {filters.filter(f => f.search_key == "share_type")[0].values.map(val => {
								 return(
									  <Tooltip title={`See events grouped by buildings`}>
									   <Button  onClick={() => {setSubFilter("share_type", val.filter)}} className={((subFilters['share_type'] === val.filter) || (!subFilters['share_type'] && val.filter=="")) ? classNames(classes.button, "selected") : classNames(classes.button)}>{val.dropdown_name}</Button>
								  	 </Tooltip>
									)
								  
							  })}
							    
					   </ButtonGroup>		
			   		</div>
			   </Col>
				</Row>
			   }
			   {!reports || reports.length ===0 ?		              
				   <Container>
					   <Row className="no__data">
						   <Col lg={12} xs={12} className="no__data-text">			                          
								 Nothing here yet.
						   </Col>	
					   </Row>
				   </Container>
				:
			    <ShowReports data={reports} {...props} />
			  }	
			  </CardBody>
			 </Card>
			 :
			 <Fragment>
				 {!isMobile && filters.length>0 &&
					<Row>
					   <Col md={12}>
							<div className={classes.root}>
							   <ButtonGroup size="small" aria-label="small outlined button group">
								   {filters.filter(f => f.search_key == "share_type")[0].values.map(val => {
									  return(
										   <Tooltip title={`See events grouped by buildings`}>
											<Button  onClick={() => {setSubFilter("share_type", val.filter)}} className={((subFilters['share_type'] === val.filter) || (!subFilters['share_type'] && val.filter=="")) ? classNames(classes.button, "selected") : classNames(classes.button)}>{val.dropdown_name}</Button>
											</Tooltip>
										 )
									   
								   })}
									 
							</ButtonGroup>		
							</div>
					</Col>
					 </Row>
					}
					{(!reports || reports.length === 0) ?		              
						<Container>
							<Row className="no__data">
								<Col lg={12} xs={12} className="no__data-text">			                          
									 Nothing here yet.
								</Col>	
							</Row>
						</Container>
					 :
					 <ShowReports data={reports} {...props} />
				   }	
				</Fragment>
			}
			 
			 
		</Col>    
	)
};


export default withStyles(styles, { withTheme: true })(List);
