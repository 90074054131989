import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/TagOutlineIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";
import ToolTip from '../../../../../shared/components/ToolTip';

const AveragePPSF = (props) => {
	
		//if(!props.statsRes) return null;
		let ppsf = props.buildingRes.inventory_stats_listings_average_ppsf && props.buildingRes.inventory_stats_listings_average_ppsf > 0 ? props.buildingRes.inventory_stats_listings_average_ppsf : 0;
		if(!props.statsRes) ppsf = 0;
		if(props.statsRes.ppsf && props.statsRes.ppsf.sum_listed_price && props.statsRes.ppsf.sum_sq_ft){
			ppsf = props.statsRes.ppsf.sum_listed_price / props.statsRes.ppsf.sum_sq_ft;
		}
		if(props.buildingRes.building_status === "sponsor"){
			return (
				 <Col md={12} xl={4} lg={6} xs={12}>
				    <Card>
				    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
				      <CardBody className="dashboard__booking-card card__link">
				        <div className="dashboard__booking-total-container">
				          <h5 className="dashboard__booking-total-title">
				            ${props.buildingRes.offering_verified_status !== "stalled" && ppsf > 0 ? _formatNumber(ppsf)
					            : '--'}
				          </h5>
				          <TagOutlineIcon className="dashboard__trend-icon" />
				        </div>
				        <h5 className="dashboard__booking-total-description">Avg Asking PPSF 	
							<ToolTip 
							title={"Average asking price per square foot based on lastest offering plan price or listing price. Sum of listing prices divided by sum of square feet of listed units."} 
							
							/>
						</h5>
				      </CardBody>
				    </Link>
				    </Card>
				  </Col>
			)
		}

		return (
			 <Col md={12} xl={4} lg={6} xs={12}>
			    <Card>
			    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
			      <CardBody className="dashboard__booking-card card__link">
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            ${props.buildingRes.offering_verified_status !== "stalled" && props.statsRes && props.statsRes.ppsf && props.statsRes.ppsf.avg_ppsf ? 
				            _formatNumber(props.statsRes.ppsf.avg_ppsf) 
				            : props.buildingRes.offering_verified_status !== "stalled" &&  ppsf > 0 ? _formatNumber(ppsf) : '--'}
			          </h5>
			          <TagOutlineIcon className="dashboard__trend-icon" />
			        </div>
			        <h5 className="dashboard__booking-total-description">Avg Asking PPSF <ToolTip 
					title={"Average asking price per square foot based on listing prices during the selected timespan. Sum of listing prices divided by sum of square feet of listed units."} 
					
					/></h5>
			      </CardBody>
			    </Link>
			    </Card>
			  </Col>
		)
		
	
	 	
		
	
};

export default AveragePPSF;
