/* eslint-disable react/no-children-prop */
import React, { Fragment } from 'react';
import {
   Card, CardBody, Col, Badge, Table, Button
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { Tooltip } from '@material-ui/core';
// import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from 'mdi-react/CloudDownloadIcon';
import{
	subscriptionPlans,
	_capitalizeText,
	_formatNumber,
	_slugifyText
} from '../../../shared/helpers/utils';

const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined


const Exports = ( props ) => {
	const { user, exports, getDownload } = props;
	//console.log(user);
	const { subscriptionPlan } = user;
	const currentPlan = subscriptionPlans.filter(plan => plan.id === subscriptionPlan)[0];
	return(	  
	  <Col md={12} lg={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'Download History'}</h5>
	          <h5 className="subhead"></h5>
	        </div>		     
	     	<Table striped responsive>
          <thead>
            <tr>
              <th>Status</th>
              <th style={{minWidth:150}}>Export Date</th> 
              <th>Name</th>  
              <th>Data Credits</th>           
              <th>Data Type</th>
              <th>Records</th>
                          
              <th style={{minWidth:150}}></th>


            </tr>
          </thead>
          <tbody>
          {exports && exports.map(exp => {
	          let dataType = exp.core_name.replace('we3-', '').replace(/-[0-9]/, '');
	          let download_name = (exp.export_name ? _slugifyText(exp.export_name) : 'mp-data-export-'+ dataType)  + '-' + moment(exp.created_timestamp).format('MM-DD-YYYY-h:mm') + '.csv'
	          return (
		          <tr>
		          	  <td>		 
					  	  	<Badge color="active">Ready</Badge>
					  </td>
		              <td>{moment(exp.created_timestamp).format('M/D/YYYY hh:mm A')}</td>
		              <td>{exp.export_name ? exp.export_name : '--'}
		              	{exp.is_owner_list &&
			              	
			              	<Tooltip title={`Owner List Export`}>
					  			<IconButton size={`small`} style={{background:"transparent"}}>
					  				<span className="unit-type_badge" style={{background:'#B070CC'}}>Owner List</span>
					  			</IconButton>
					  		</Tooltip>
					  	
			              	
		              	}
		              	{exp.request_owner_email &&
			              	
			              	<Tooltip title={`Owner Email Requested`}>
					  			<IconButton size={`small`} style={{background:"transparent"}}>
					  				<span className="unit-type_badge" style={{background:"#28C2FF"}}>Email Requested</span>
					  			</IconButton>
					  		</Tooltip>
			              	
		              	}
		              </td>	
		              <td>{exp.credits ? _formatNumber(exp.credits) + ' Credits' : '--'} </td>	              
		              <td>{_capitalizeText(dataType)}</td>
		              <td>{_formatNumber(exp.rows)}</td>	
			          	              		              		                
			          <td>			          	
						<Button 
							onClick={(e) => {e.stopPropagation();getDownload(exp._id, download_name)}}
							className="modal_ok" type="button" color={'primary'}>
				        	<DownloadIcon style={{marginTop:-3}} /> Download
					    </Button>
			          </td>
		            </tr>
	          )
	          
          })}           
          </tbody>
        </Table>        
	      </CardBody>
	    </Card>
	  </Col>
	)
};


export default Exports;
