import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MatTable from './components/MatTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';

const Buildings = ( {user} ) => {
	
  if(((user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0)) || (user.accountType && ['broker'].indexOf(user.accountType)>=0)){
	  return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Market Analysis</h3>
		        <h3 className="page-subhead subhead">New Development Condo Analysis (Excludes Conversions)</h3>
		      </Col>
		    </Row>
		    <Row>
		      <Col md={12} className={'mb-3'}>
		       	<TrialAlert />        
		      </Col>
		    </Row>
		     <Row>
		    	<Col md={12} className={'mb-3'}>
					<Alert color="success" className="alert--bordered"  alertId="buildingDatabase" icon iconItem={<AlertIcon />} minimizeText={`About The Building Database`}>
			          <p>
			          	<span className="bold-text">{`About The Data`}</span> 
			            <div>{`The information provided herein is provisional and subject to change.`}</div>
			          </p>
			        </Alert>
				</Col>
		    </Row>
		    <Row>
		      <MatTable />
		    </Row>
		  </Container>
		  )

  }else{
	  return <Redirect to={{ pathname: '/dashboard' }}/>
  }
	
};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(Buildings));

