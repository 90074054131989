/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import CloseIcon from 'mdi-react/CloseIcon';
import FullscreenIcon from 'mdi-react/FullscreenIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import { 
	_capitalizeText,
	_getUnitFloorPlan
} from "../../../../../shared/helpers/utils";


const CustomHeader = ({ innerProps, currentView, modalProps, isModal }) => {
	    console.log(innerProps, currentView);	
	    const download = () => {
		    var element = document.createElement("a");
		    var file = new Blob(
		      [
		        currentView.url
		      ],
		      { type: "image/*" }
		    );
		    element.href = URL.createObjectURL(file);
		    element.download = "floorplan.jpg";
		    element.click();
		};
	    if(!isModal){
	    	return null;
	    }else{  
		    return(
				<div className="react-images__custom-header">
					<Tooltip title={`Close`}>
						<IconButton onClick={modalProps.onClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={`Expand Fullscreen`}>
						<IconButton onClick={modalProps.toggleFullscreen}>
							<FullscreenIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={`Open Image In New Tab`}>
						<IconButton onClick={() => window.open(`${currentView.src}`)}>
							<OpenInNewIcon size={22} />
						</IconButton>
					</Tooltip>
				</div>			    		
		    );
	    }
	    
}

export default class ProfileGallery extends PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
    })).isRequired,
  };

  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      currentImagePreview: 0,
    };
  }

  changeImg = (i, e) => {
    e.preventDefault();
    this.setState({
      currentImagePreview: i,
      currentImage: i,
    });
  };

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImagePreview, lightboxIsOpen: false }));
  };

  gotoPrevious = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImage - 1 }));
  };

  gotoNext = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImage + 1 }));
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  handleClickImage = () => {
    const { images } = this.props;
    const { currentImage } = this.state;
    if (currentImage === images.length - 1) return;
    this.gotoNext();
  };

  render() {
    const { badge, property, button, listing, share } = this.props;
    const { currentImage, currentImagePreview, lightboxIsOpen } = this.state;
    if(!property) return null;
    
    let fp = _getUnitFloorPlan(property);
    
    if(!fp && listing){
	     fp = _getUnitFloorPlan(listing);
    }
    fp = !fp ? [] : fp;
    let images = [];
    
    if(listing && listing.sale_status &&  listing.main_photo){
	    if(listing.sale_status === 'active' && listing.pictures && Array.isArray(listing.pictures) && listing.pictures.length>0){
		    //Change to show only 1 photo
		    listing.pictures.slice(0,1).map(img => {
			    images.push({'src': img.replace("cdn-images.we3.com", "dc2nzxe4zztjf.cloudfront.net").replace("cdn.images.we3.com", "dmaaf11jcu066.cloudfront.net"), 
			    'caption': `Photo Courtesy of ${listing.broker_display_name && _capitalizeText(listing.broker_display_name)}  (See listing for more photos)`});
		    })
	    }else if(listing.main_photo && (listing.main_photo.indexOf('we3')>=0 || listing.main_photo.indexOf('marketproof')>=0)){
		    images.push({'src': listing.main_photo.replace("cdn-images.we3.com", "dc2nzxe4zztjf.cloudfront.net").replace("cdn.images.we3.com", "dmaaf11jcu066.cloudfront.net"), 
		    	'caption': `Photo Courtesy of ${listing.broker_display_name && _capitalizeText(listing.broker_display_name)}`});
	    }
	    
	    if(images.length === 0 && listing.floorplans && listing.floorplans.length>0 &&  (listing.floorplans[0].indexOf('we3')>=0 || listing.floorplans[0].indexOf('marketproof')>=0)){
		    images.push({'src': listing.floorplans[0].replace("cdn-images.we3.com", "dc2nzxe4zztjf.cloudfront.net").replace("cdn.images.we3.com", "dmaaf11jcu066.cloudfront.net"), 
		    	'caption': `Floor Plan Courtesy of ${listing.broker_display_name && _capitalizeText(listing.broker_display_name)}`});
	    }    
		
    }
    
    images = share ? [...fp] : [...images, ...fp];
    
    const slice = (fp.length>0 ? 3 : 4);
    const fpPos = fp.length>0 ? images.length-1 : false;
   
    return (
      <div className="product-gallery">
      	{button}
        <a
          className="product-gallery__current-img"
          onClick={e => this.openLightbox(currentImage, e)}
          href={images.length > 0 ? images[currentImage].src : null}
        >
          {images.length > 0 ?
	          <img src={images[currentImage].src} / >
          :
	          <FloorPlanIcon size={440} color="#DBE0E8" />
          }
          
          {/*<img src={images[currentImagePreview].src} alt="product-img" />*/}
          {badge && badge}
        </a>
        {images.length > 0 &&
        <div className="product_gallery__gallery">
          {images.length > 5 ?
	        <Fragment>  
	        	
		       {images.slice(0,slice).map((img, i) => (
	            <button type="button" key={i} onClick={e => this.openLightbox(i, e)} className="product-gallery__img-preview">
	              <img src={img.src} alt="product-img" />
	            </button>          
	           ))}
	           <button type="button" key={5} onClick={e => this.openLightbox(slice, e)} className="product-gallery__img-preview preview-more" style={{backgroundImage:`url(${images[slice].src})`}}>
	              <span>+ {images.length-slice}</span>
	           </button> 
	           {fpPos &&
		          <button type="button" key={fpPos} onClick={e => this.openLightbox(fpPos, e)} className="product-gallery__img-preview">
	              <img src={images[fpPos].src} alt="product-img" />
	           </button> 
		           
	           }
           </Fragment>  	       
	       :
	       <Fragment>  
		       {images.map((img, i) => (
	            <button type="button" key={i} onClick={e => this.openLightbox(i, e)} className="product-gallery__img-preview">
	              <img src={img.src} alt="product-img" />
	            </button>          
	           ))}
           </Fragment>
          }
        </div>
        }
   
        <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={this.closeLightbox}>
            <Carousel components={{ Header: CustomHeader }} currentIndex={currentImage} views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
     {/*   <Lightbox
          currentImage={currentImage}
          views={images}
          isOpen={lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        /> */}
      </div>
    );
  }
}
