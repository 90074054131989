import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import moment from 'moment';


import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import Profile from './../../../../SingleBuilding/components/Profile';

import AddToBuyersListButton from '../../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../../shared/components/buttons/ShareButton';

import ProductCard from './../../../../SingleBuilding/components/Profile/components/ProfileCard';
import ShowMap from './../../../../../shared/components/map';

import BuildingProductCard from './../../../../SingleBuilding/components/Profile/components/ProfileCard';
import BuildingAmenities from './../../../../SingleBuilding/components/Profile/components/BuildingAmenities';

import ShareTopBar from './../../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../../shared/components/ShareContact'
import ShareFooter from './../../../../../shared/components/ShareFooter'

import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';
import BlendedMatrix from './analysis/BlendedMatrix';
import { withOktaAuth } from '@okta/okta-react';

import { Publisher } from './../../../../../shared/pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	_privateKey,
	_validUnitSources,
	propertyExportFL,
	_getCrossStreet
} from '../../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../../shared/components/auth/sso/ssoutils';
const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;


class BuildingProfile extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
	  statsRes: null,
	  fetchInProgress: false,
	  buildingRes: this.props.sharePageData.building,
	  userRes:undefined
	};
	this.buildingSlug = false;
	this.urlState = {};
  }
  
  componentDidMount() {
	  
	const { sharePageData } = this.props;
	const { buildingRes } = this.state;
	if(sharePageData){
		this.buildingSlug = sharePageData.building_key;
		
		this.urlState = sharePageData.custom_data ? sharePageData.custom_data : {};
		
		//this._getBuildingOPData(buildingRes)
		this.setState({statsRes: buildingRes.property_stats.facets, unitsRes: []});
	}
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {	
	 const { sharePageData } = this.props;
	 if(!prevState.buildingRes && this.state.buildingRes){
		Publisher.publish(`track-mp.shareView.building`, {building: this.state.buildingRes, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true }); 
	 }	
	 
  }

  toggle = (tab) => {
	const { activeTab } = this.state;
	if (activeTab !== tab) {
	  this.setState({
		activeTab: tab,
	  });
	}
  };
  
  _getUserData = (key) => {
	 // console.log('_getBuildingData called', this.state)
	  const { sharePageData, user } = this.props;
	  if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
	  }
	  
	  if(!(sharePageData && sharePageData.user_key) && user){
		  this.setState({
				userRes: user,
				fetchInProgress: false
		  })
		  
		  return false;
	  }
			
	  let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${sharePageData && sharePageData.user_key ? sharePageData.user_key : key}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  if(res && res.errorCode && res.errorCode == "E0000007"){
				_getUser({id:sharePageData && sharePageData.user_key ? sharePageData.user_key : key}).then(ssoUser  => {
					console.log(ssoUser)
					if(ssoUser){
						this.setState({
							userRes:ssoUser,
							fetchInProgress: false
						})	
					}
					
				})
		 }else{
				  this.setState({
					  userRes:res.profile,
					  fetchInProgress: false
				  })
			}

	  })
	  .catch(error => {
		console.log("_getUserData error ", error);
		this.setState({
		  fetchInProgress: false
		})
	  });
	}

  

   _getBuildingOPData = async (buildingRes) => {
		  const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		  const addressQ = buildingRes.alternate_addresses.join('" OR "');
		  
		  let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}"))`
		  
		  let statsQ = '';
		  
		  if(['active'].indexOf(buildingRes.offering_verified_status)>=0){
			  statsQ = `((offering_verified_status:active AND source_organizations:(CDEC NYAG ADEC) AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"") OR verification_source:(user-verified-valid))`
			   q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}))`;		
		  }else if(buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970){
			  let unitSource = "source_organizations:(CDEC ADEC)";
				if(buildingRes.cdec_units_properties < (buildingRes.inventory_total * .8)){
				   unitSource = `(source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}))`
				}
			   statsQ = `(${unitSource} AND property_type:(*condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial* AND -property_type:*multi*)`;
			   q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;
		  }else if(['coming soon'].indexOf(buildingRes.offering_verified_status)>=0){
			  statsQ = `(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"")`;
			   q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
		  }else{
			  q += ` AND (source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
		  }
		  const isResale = ['resale'].indexOf(buildingRes.building_status)>=0;
		  
		  if(statsQ.length>0){
			  statsQ = 'AND ' + statsQ;
		  }
		  
		  this.q = q;
		  const priceField = isResale ? "last_sold_price" : "last_listed_sale_price_all";
		  let axiosData = {
			  url: apiURL + "we3-properties?cache=15",
			  method: "post",
			  query: {
				  q: q,
				  fq: ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""'],
				  fl: [...propertyExportFL, ...["floor", "line", "offering_submitted_date", "div(last_sold_price, sq_ft)", "div(last_listed_sale_price_all, sq_ft)", "div(last_contract_price, sq_ft)", "offering_exterior_sq_ft", "times_exchanged", "last_exchanged_date"]],
				  sort: 'floor asc, address_2_normalized asc',
				  wt: "json",
				  rows: 0,
				  "json.facet": {
					  "avg_price": `avg(${priceField})`,
					  "avg_sq_ft": "avg(sq_ft)",
					  "low_bed": "min(bedrooms)",
					  "has_bed" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit"`,
						  "facet": {
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)",
											  "med_price": "percentile(last_listed_sale_price_all,25,50,75)",
										  },
									  },
									  "sold_price": {
											 "type": "query",
											 "q": `last_sold_price:[10000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
											 "facet": {
												 "avg_unit_size" : "avg(sq_ft)",
												 "med_unit_size": "percentile(sq_ft,25,50,75)",
												 "avg_fees" : "avg(offering_monthly_fees)",
												 "med_fees": "percentile(offering_monthly_fees,25,50,75)",
												 "avg_taxes" : "avg(offering_monthly_taxes)",
												 "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
												 "avg_ppsf": `avg(div(last_sold_price, sq_ft))`,
												 "avg_price": `min(last_sold_price)`,
												 "min_price": `min(last_sold_price)`,
												 "max_price": `max(last_sold_price)`,
												 "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
												 "sum_price" : "sum(last_sold_price)",
												 "sum_sq_ft" : "sum(sq_ft)"
									   },
									 },
									
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  },
							  "four_more" :{
								  "type" : "query",
								  "q": "bedrooms:[4 TO *]",
								  "facet" : {
									  "med_price": "percentile(last_listed_sale_price_all,25,50,75)"
								  }
								  
							  },
						  }
					  },
					
					  "total_sold": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*) ${statsQ}`,
						  "facet": {
							  "low_price": "min(last_listed_sale_price_all)",
							  "high_price": "max(last_listed_sale_price_all)",
							  "average_price": "avg(last_listed_sale_price_all)",
							  "average_sq_ft": "avg(last_listed_sale_price_all)",
							  "sum_price": "sum(last_listed_sale_price_all)",							
							  "low_sq_ft": "min(sq_ft)",
							  "high_sq_ft": "max(sq_ft)",
							  "low_bed": "min(bedrooms)",
							  "high_bed": "max(bedrooms)",
							  
						  }
					  },
					
					  "total_sq_ft": {
						  "type": "query",
						  "q": `sq_ft:[1 TO *]  ${statsQ} AND -property_type:"resident manager unit"`,
						  "facet" : {
									  "avg_sq_ft": "avg(sq_ft)",
									  "sum_sq_ft": "sum(sq_ft)"
								  }
						  
					  },
					  "last_12_closed" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit" AND last_sold_date_formatted:[NOW-12MONTHS TO NOW] AND last_contract_date_formatted:[NOW-12MONTHS TO NOW]`,
						  "facet": {
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(${priceField}, sq_ft))`,
											  "min_price": `min(${priceField})`,
											  "max_price": `max(${priceField})`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "last_12_signed" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit" AND last_contract_date_formatted:[NOW-12MONTHS TO NOW]`,
						  "facet": {
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)"
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "on_the_market" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit" AND current_status:active`,
						  "facet": {
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)"
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  },
					  "shadow" : {
						  "type": "query",
						  "q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit" AND current_status:shadow AND -offering_construction:conversion AND property_status:sponsor`,
						  "facet": {
							  "unit_mix": {
								  "type" : "terms",
								  "field" : "bedrooms",
								  "facet": {
									  "avg_unit_size" : "avg(sq_ft)",
									  "med_unit_size": "percentile(sq_ft,0,50,100)",
									  
									  "non_zero_price": {
										  "type": "query",
										  "q": `last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										  "facet": {
											  "avg_unit_size" : "avg(sq_ft)",
											  "med_unit_size": "percentile(sq_ft,25,50,75)",
											  "avg_fees" : "avg(offering_monthly_fees)",
											  "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											  "avg_taxes" : "avg(offering_monthly_taxes)",
											  "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											  "avg_ppsf": `avg(div(last_listed_sale_price_all, sq_ft))`,
											  "min_price": `min(last_listed_sale_price_all)`,
											  "max_price": `max(last_listed_sale_price_all)`,
											  "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
											  "sum_price" : "sum(last_listed_sale_price_all)",
											  "sum_sq_ft" : "sum(sq_ft)"
										  },
									  },
									  "by_status" : {
										  "type" : "terms",
										  "field" : "offering_status"
									  },
									  
								  }
							  }
						  }
					  }
   
				  }
			  }
		  };
   
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  //console.log(res.docs);
				  this.setState({statsRes: res.facets, unitsRes: res.docs.slice()});
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
   
		  // console.log(formattedData)
		  return formattedData;
	  };
  
	
  render() {
	const { rtl, sharePageData, isModal } = this.props;
	const {statsRes, unitsRes, urlState, userRes, buildingRes } = this.state;
	
	const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
	}	
	
	const user = Object.assign({}, defaultUser, userRes);
	
	  if( !buildingRes || !statsRes ){
	 	return <LinearLoading />;
	 }
	
	const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	

	
	return (
		<div className={sharePageData && sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
		<div className={`share__wrapper ${isModal ? "is-modal" : ""}`}>
			{!isModal && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} /> }
			<Container className="share dashboard">
				{!isModal && <ShareContact  user={user} urlState={this.urlState} sharePageData={sharePageData} isModal={isModal} />}
				
				<Row>
					<Col md={12} lg={12} xl={12}>
						<Card style={{padding:0}}>
						  <CardBody className="no-border no-padding mg-btm-0"> 
							{!isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								<div className="top__toolbar" style={{display:"flex"}}>
									 <ContactDeveloperButton  buildingRes={buildingRes} share  />
							   </div>  
							}      	
							<div className="card__title" key={buildingRes.id}>							  
							   <h3 className="page-title">{buildingNameOrAdr}</h3>
							   {isMobile ?
								   <Fragment>
								  <h3 className="page-subhead subhead">			        	
										{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
										{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
										
										
								  </h3> 
								  
								  </Fragment>
							   :
								  <h3 className="page-subhead subhead">			        	
											{buildingRes.name ? _capitalizeText(adr) + ', ': ''} {buildingRes.name && isMobile && <br/>}{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
											
											
								  </h3>
							   }
							   </div>
							   {isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								   <div className="top__toolbar" style={{textAlign:"center"}}>
										<ContactDeveloperButton  buildingRes={buildingRes} share  />
								  </div>  
							   } 
						  </CardBody>
						</Card>
					  </Col>
				</Row>
				
				
				<Row>
				  <ProductCard {...this.state} share />
				
				</Row>
				
				<Row className="custom-report-bottom">
				  <div className="custom-report-amenities">
						  <h4 class="bold-text mb-3 mt-3">Amenities & Features</h4>
						<hr/>
						  <BuildingAmenities {...this.state} small />
				  </div>
				  <div className="custom-report-unit-matrix">
						  <BlendedMatrix mixData={statsRes} />			
				
				  </div>
				 </Row>  
				
								
			  
				  
				
			</Container>
			<ShareFooter sharePageData={sharePageData} />

		</div>
		</div>
	);
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(BuildingProfile)));
