import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import SuccessIcon from 'mdi-react/CheckCircleOutlineIcon';
import ErrorIcon from 'mdi-react/CloseCircleOutlineIcon';

export default function SimpleSnackbar({ snackShow, snackMessage, snackClose, snackType, snackDuration }) {
  
	
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackShow}
        autoHideDuration={snackDuration ? snackDuration : 5000}
        onClose={snackClose}
        message={	     
	      <React.Fragment>
	     	{snackType === 'error' ? <ErrorIcon color="#F53246" /> : <SuccessIcon color="#4ce1b6" />} {snackMessage}
	      </React.Fragment>  
	    }
        action={
          <React.Fragment>
            {/*<Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>*/}
            <IconButton size="small" aria-label="close" color="inherit" onClick={snackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}