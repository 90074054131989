import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import MatTableToolbar from './MatTableToolbar';
import UnitsSearchAppBar from './UnitsSearchAppBar';
import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {isMobile} from 'react-device-detect';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';

import MonthlyIcon from 'mdi-react/CalendarMonthOutlineIcon';
import WeeklyIcon from 'mdi-react/CalendarClockIcon';

import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import SupplyIcon from 'mdi-react/HomeOutlineIcon';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Panel from '../../../shared/components/Panel';


import queryString from "query-string";

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	syncGenericFilter,
	_objectEqual
} from "../../../shared/helpers/utils";

import { 
	_pipeLineFilter	
} from "../../../shared/helpers/filters";

import { _hasPermission	} from "../../../shared/helpers/access";

let counter = 0;
am4core.useTheme(am4themes_animated);
function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';
const lsPre = "FP_";
const subFilterKey = 'subFilters';
const gap = syncGenericFilter('gap', lsPre, 'gap', "+7DAY");
const defaultGap = gap === '+1MONTH' ? 'month' : 'week';
const dateStart = '01/05/2019';
const isDST = moment().isDST();

class UnitsSearchTable extends PureComponent {
  
  state = {
    order: syncGenericFilter('order', lsPre, 'order', "desc"),
    orderBy:  syncGenericFilter('order_by', lsPre, 'orderBy', "solr_updated_date"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 25,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _pipeLineFilter, subFilterKey, lsPre),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').add(isDST ? -1 : 0, 'hour').toDate().toISOString(),
	endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
	gap:gap,
	chartData:false,
	dataStart:isMobile 
				? syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf('week').add(1, 'day').toDate().toISOString()) 
				: syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf('week').add(1, 'day').toDate().toISOString()),
	dataEnd:syncGenericFilter('end_date', lsPre, 'dataEnd', moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString()),
	buildingEventCount:0,
	selectedDate:syncGenericFilter('selected_date', lsPre, 'selectedDate', false),
	eventTypes:syncGenericFilter('event_types', lsPre, 'eventTypes', ["Permit Filed", "Offering Submitted", "Offering Accepted", "Offering Effective"/*, "CO Issued"*/], true)
  };
  
  defaultQ = "*:*";
  q='';
  fq = [		
		"hierarchy:[0 TO 1]"
	];
  chartLoaded = false;
	
  fl = buildingFL;
  chart;
  _unitSubFilter = _pipeLineFilter.filter(filter => !(this.props.buildingView && filter.building_hide));
  timeout = null;
   _matchedBuildingIds = [];
   formattedData;
   tempEventTypes = this.state.eventTypes.slice(0);
   noDataIndicator;
  componentDidMount() {
  	 this._getBuildings();
  	 
  	
  }
 
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;	
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.gap !== prevState.gap ||
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange || 
			subFilterChanged
			) {
			  const resetPage = this.state.page === prevState.page;	
			  		
			  this._getBuildings(resetPage);
		}else if(this.state.dataStart !== prevState.dataStart ||
			this.state.dataEnd !== prevState.dataEnd ||
			this.state.selectedDate !== prevState.selectedDate ||
			!_isEqual(this.state.eventTypes, prevState.eventTypes)){
				console.log("_getEvents");
				this._getEvents(null, res => {
					this.setState(prevState => ({				
							stats: res.facets,
							buildingsFetchInProgress: false
					}));
				});
		}
		
		

	  
  }
  
  buildChart = (data) => {
	  let chart = am4core.create("chartdiv", am4charts.XYChart);
  	    chart.scrollbarX = new am4core.Scrollbar();
  	 // Add data
		const { user } = this.props;
		const { eventTypes, selectedDate } = this.state;

		chart.data = data;

		function showIndicator(noDataIndicator) {
		  if (noDataIndicator) {
		    noDataIndicator.show();
		  }
		  else {
		    noDataIndicator = chart.tooltipContainer.createChild(am4core.Container);
		    noDataIndicator.background.fill = am4core.color("#fff");
		    noDataIndicator.background.fillOpacity = 0.8;
		    noDataIndicator.width = am4core.percent(100);
		    noDataIndicator.height = am4core.percent(100);
		
		    var indicatorLabel = noDataIndicator.createChild(am4core.Label);
		    indicatorLabel.text = "No data found for your search.";
		    indicatorLabel.align = "center";
		    indicatorLabel.valign = "middle";
		    indicatorLabel.fontSize = 14;
		  }
		}
		
		function hideIndicator(noDataIndicator) {
		   noDataIndicator.hide();
		}
		
		function highLightColumn(categoryX){
			chart.series.each(function(series) {				    
			    series.columns && series.columns.values.forEach(c => {
				    if (c.dataItem.categoryX == categoryX) {
				          c.isActive = true;
				    }
				    
				 });
			}.bind(this));
		}
		
		chart.events.on("beforedatavalidated", function(ev) {
		  // check if there's data
		  if (ev.target.data.length == 0) {
		    showIndicator(this.noDataIndicator);
		  }
		  else if (this.noDataIndicator) {
		    hideIndicator(this.noDataIndicator);
		  }
		}.bind(this));
		
		chart.events.on("ready", function(ev) {
			    if(selectedDate){
					const columnX  = this.getIntervalName(selectedDate);
					highLightColumn(columnX);
				}
			  this.chartLoaded = true;
		}.bind(this));
		
		// Create axes
		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "time";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = "right";
		categoryAxis.renderer.labels.template.verticalCenter = "middle";
		categoryAxis.renderer.labels.template.rotation = 290;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.minHeight = 110;
		
		
		const slideChange = (ev) => {
			if(this.timeout) clearTimeout(this.timeout);
			
			const { dataStart, dataEnd } = this.state;
			let axis = ev.target;
			let start = axis.getPositionLabel(axis.start);
			let end = axis.getPositionLabel(axis.end);
			
			const formattedStart = moment(start+"T00:00", 'M/D/YYTHH:mm');
			const formattedEnd = moment().diff(moment(end+"T00:00", 'M/D/YYTHH:mm')) < 0
								 ? moment()
								 : moment(end+"T00:00", 'M/D/YYTHH:mm');
			
			if(start && end && (dataStart !== start || dataEnd !== end)){
				this.timeout = setTimeout(function (start, end, series) {
				//  
				   if(this.chartLoaded){
					   chart.series.each(function(series) {
					      series.columns.values.forEach(c => c.isActive = false);
					    });
				       this.setState({
					       dataStart: formattedStart.toDate().toISOString(), 
					       dataEnd: formattedEnd.toDate().toISOString(),
					       selectedDate: false,
					       eventTypes: this.tempEventTypes.slice(0)
					   });
				   }

				}.bind(this, start, end, chart), 300)				
			}			
		}
		
		categoryAxis.events.on("endchanged", slideChange);
		categoryAxis.events.on("startchanged", slideChange);
		
		
		categoryAxis.start = isMobile ? 0.875 : 0.5;
		categoryAxis.end = 1;
		categoryAxis.keepSelection = true;
		
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.minWidth = 50;
		
		
		// Create series
		const createSeries = (field, name) => {
  
		  // Set up series
		  var series = chart.series.push(new am4charts.ColumnSeries());
		  series.name = name;
		  series.dataFields.valueY = field;
		  series.dataFields.categoryX = "time";
		  series.sequencedInterpolation = true;
		  series.defaultState.transitionDuration = 300;
		  series.hiddenState.transitionDuration = 300;
		  series.interpolationDuration = 10;
		  
		  // Make it stacked
		  series.stacked = true;
		  
		  series.showOnInit = false;
		  
		  // Configure columns
		  series.columns.template.width = am4core.percent(80);
		  series.columns.template.tooltipText = "{categoryX}\n[font-size:14px]{name}: [bold]{valueY}[/]";

		  
		
		  series.columns.template.column.cornerRadiusTopLeft = 0;
		  series.columns.template.column.cornerRadiusTopRight = 0;
		  series.columns.template.column.fillOpacity = 0.8;
		  series.columns.template.strokeWidth = 0;
		
		  series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
		  	  
		  var hoverState = series.columns.template.column.states.create("hover");
			hoverState.properties.cornerRadiusTopLeft = 0;
			hoverState.properties.cornerRadiusTopRight = 0;
			hoverState.properties.fillOpacity = 1;
	
			
			let activeColor = "#9600BF";
			if(field === "offering_accepted") activeColor = "#780F9E";
			if(field === "offering_effective") activeColor = "#5C1F7A";
			if(field === "new_building_filed") activeColor = "#420F6B";
			if(field === "co_received") activeColor = "#290059";

			//series.columns.template.column.togglable=true;
			var activeState = series.columns.template.states.create("active");
			activeState.properties.fill = am4core.color(activeColor);
			activeState.dx = 6;
			activeState.dy = 6;
			activeState.opacity = 0.3;
			

			series.columns.template.events.on("hit", function(ev) {
			  const { dataStart, dataEnd, gap } = this.state;
			  console.log(ev)
			  chart.series.each(function(series) {
				    series.columns.values
					  .filter(c => c !== ev.target)
					  .forEach(c => c.isActive = false);
			  });
			  
			  ev.target.isActive = !ev.target.isActive;
			  
			  if(ev.target.isActive){
				  const columnX = ev.target.dataItem.categoryX;
				  highLightColumn(columnX);
				  
				  let start = ev.target.dataItem.categories.categoryX;
				  let end = gap === '+1MONTH' ? this.getIntervalTime(start).add(1, 'month') : this.getIntervalTime(start).add(1, 'week');
				  
				  const formattedStart = gap === '+1MONTH' ? this.getIntervalTime(start).startOf('month') : this.getIntervalTime(start).startOf('week').add(1, 'day');
				  let type = ev.target.dataItem.component.dataFields.valueY;
				  const translateType = {
					  "new_building_filed" : "Permit Filed",
					  "offering_effective" : "Offering Effective",
					  "offering_accepted" : "Offering Acceptetd",
					  "offering_submitted" : "Offering Submitted",
					  "co_received" :  "CO Issued"
				  }
				  
				  if(dataStart !== start || dataEnd !== end){
					 setTimeout(function (start, end, series, translateType) {
					       this.setState({
						        selectedDate: formattedStart.toDate().toISOString(),  
						       //eventTypes:[translateType[type]]
						   });
		
					}.bind(this, start, end, series, translateType), 100)
					   
				  }			  
			  }else{			  
				  this.setState({
					  selectedDate:false,
					  //eventTypes: this.tempEventTypes.slice(0)
				  })			  
			  }
			  
			}.bind(this));

			let color = "#fc83ae";
			if(field === "offering_accepted") color = "#FF206E";
			if(field === "offering_effective") color = "#82012e";
			if(field === "new_building_filed") color = "#FFD23F";
			if(field === "co_received") color = "#FF691E";
			
			series.columns.template.adapter.add("fill", function(fill, target) {
			  //return chart.colors.getIndex(target.dataItem.index);
			  
			  return am4core.color(color);
			}.bind(this));
			
			
			if(eventTypes.indexOf(name) < 0){
			  series.hidden = true;
			  series.hide();
		    }
		  
		  return series;
		};
		
		
		createSeries("new_building_filed", "Permit Filed");
		createSeries("offering_submitted", "Offering Submitted");
		createSeries("offering_accepted", "Offering Accepted");
		createSeries("offering_effective", "Offering Effective");
		//createSeries("co_received", "CO Issued");
		
/*
		var series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = "offering_submitted";
		series.dataFields.categoryX = "time";
		series.tooltipText = "Week of {categoryX} - [{categoryX}: bold]{valueY}[/] Listings";
		series.columns.template.strokeWidth = 0;
		
		series.tooltip.pointerOrientation = "vertical";
		
		series.columns.template.column.cornerRadiusTopLeft = 0;
		series.columns.template.column.cornerRadiusTopRight = 0;
		series.columns.template.column.fillOpacity = 0.8;
		
		series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
*/

		
		// on hover, make corner radiuses bigger
		
		
		
		// Cursor
		chart.cursor = new am4charts.XYCursor();	
		chart.cursor.lineX.stroke = am4core.color("#000");	
		chart.cursor.lineX.strokeWidth = 2;
		chart.cursor.lineX.strokeOpacity = 1;
		chart.cursor.lineX.strokeDasharray = "6,4";
		
		chart.cursor.lineY.stroke = am4core.color("#000");
		chart.cursor.lineY.strokeWidth = 2;
		chart.cursor.lineY.strokeOpacity = 1;
		chart.cursor.lineY.strokeDasharray = "6,4";
			
		chart.exporting.menu = new am4core.ExportMenu();		
		chart.zoomOutButton.background.fill = am4core.color("#780F9E");
		chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#9600BF");
		
		
		// Legend
		chart.legend = new am4charts.Legend();
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = 15;
		markerTemplate.height = 15;

		chart.legend.itemContainers.template.events.on("hit", function(ev) {
			console.log(ev);
		  
		  const { eventTypes } = this.state;
		  const type = ev.target.dataItem.name;
		   chart.series.each(function(series) {
		      series.columns.values.forEach(c => c.isActive = false);
		    });
		   setTimeout(function (eventTypes, type) {
			 console.log(eventTypes, type)
			  if(this.tempEventTypes.indexOf(type) >= 0){
				  this.tempEventTypes = this.tempEventTypes.filter(item => item !== type).slice(0);
				  this.setState( { eventTypes: this.tempEventTypes.slice(0) })
				  setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }else{
				 this.tempEventTypes = [...this.tempEventTypes, ...[type]];
				 this.setState( { eventTypes: this.tempEventTypes.slice(0) } )
				 setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }
		    }.bind(this, eventTypes, type), 100);
		  //let seriesColumn = ev.target.dataItem.dataContext.columns.template;
		  
		}.bind(this));

	

		chart.exporting.menu.items = [{
		  "label": "Export",
		  "menu": [
		    { "type": "png", "label": "Image (PNG)" },
		    { "type": "pdf", "label": "PDF" },
// 		    { "type": "csv", "label": "Data (CSV)" },
		  ]
		}];
		if(_hasPermission(user, 'data-export')){
			chart.exporting.menu.items[0].menu.push({ "type": "csv", "label": "Data (CSV)" });			
		}
		chart.exporting.filePrefix="Marketproof New Development - Pipeline Activity";
		this.chart = chart;
  }
  
  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'key');
	 
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _getDetailQuery = () => {
	  	const { dataStart, dataEnd, selectedDate, eventTypes, gap } = this.state;
    
    	const buildingStart = selectedDate ? moment(selectedDate).add(defaultGap == 'month' ? 0 : 0, 'day').toDate().toISOString() : dataStart;
		const buildingEnd = selectedDate ? gap === '+1MONTH' ? moment(selectedDate).add(1, 'month').add(-1, 'hour').toDate().toISOString()  : moment(selectedDate).add(1, 'week').add(0, 'day').add(-1, 'hour').toDate().toISOString() : dataEnd;
		
	    const detailQuery = eventTypes.map(type => {
			switch (type) {
				case "Offering Submitted" : 
					return `(topic:offeringplans AND event_tags:submitted AND time:[${buildingStart} TO ${buildingEnd}])`;
					break;
				case "Offering Accepted" : 
					return `(topic:offeringplans AND event_tags:accepted AND time:[${buildingStart} TO ${buildingEnd}])`;
					break;
				case "Offering Effective" : 
					return `(topic:offeringplans AND event_tags:effective AND time:[${buildingStart} TO ${buildingEnd}])`
					break;
				case "Permit Filed" : 
					return `(topic:permits AND event_tags:(nb a1) AND event_tags:"permit-filing" AND -event_tags:"public-housing" AND -search_string:(*hpd* OR *housing*) AND event_tags:(mixed-use residential) AND units:[3 TO *] AND time:[${buildingStart} TO ${buildingEnd}])`
					break;
				case "CO Issued" : 
					return `(topic:coo AND -event_tags:"public-housing" AND -search_string:(*hpd* OR *housing*) AND units:[3 TO *] AND hierarchy:1 AND slugs:*residential* AND time:[${buildingStart} TO ${buildingEnd}])`
					break;
			}
				
		}).join(" OR ");
		
		return detailQuery ? detailQuery : 'id:none';
	  
  }
  
  getIntervalName(date){	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date).add(1, 'hour').format('MMM, YYYY');
	  }
	  
	  return moment(date).add(1, 'hour').format("M/D/YY");
	  
  }
  
  getIntervalTime(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date, 'MMM, YYYY');
	  }
	 
	  return moment(date, "M/D/YY");
	  
	  
	  
  }

  
  _getBuildings = async (resetPage) => {
		this._getEvents([], function(res){					
	
			let chartData = [];
			if(res && res.facets){
				let facetData = {};
				
				if(res.facets.offering_submitted && res.facets.offering_submitted.by_time){
					
					chartData = res.facets.offering_submitted.by_time.buckets.map(bucket => {
						const key = this.getIntervalName(bucket.val);
						return { time: key, offering_submitted: bucket.count}
					})
					
				}
				
				if(res.facets.offering_accepted && res.facets.offering_accepted.by_time){
					
					res.facets.offering_accepted.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);								
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0]){
							existingItem[0].offering_accepted = bucket.count
						}else{
							chartData.push({ time: key, offering_accepted: bucket.count});
						}
					})
					
				}
				
				if(res.facets.offering_effective && res.facets.offering_effective.by_time){
					
					res.facets.offering_effective.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);								
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0]){
							existingItem[0].offering_effective = bucket.count
						}else{
							chartData.push({ time: key, offering_effective: bucket.count});
						}
					})
					
				}
				
				if(res.facets.new_building_filed && res.facets.new_building_filed.by_time){
					
					res.facets.new_building_filed.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);								
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0]){
							existingItem[0].new_building_filed = bucket.count
						}else{
							chartData.push({ time: key, new_building_filed: bucket.count});
						}
					})
					
				}
				
				if(res.facets.co_received && res.facets.co_received.by_time){
					
					res.facets.co_received.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);								
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0]){
							existingItem[0].co_received = bucket.count
						}else{
							chartData.push({ time: key, co_received: bucket.count});
						}
					})
					
				}
				
			}
			
			this.buildChart(chartData);
			
			this.setState(prevState => ({
				fetchInProgress: false,
				buildingsFetchInProgress: false,
				buildingEventCount: res.facets.by_building ? res.facets.by_building.count : 0,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page,
				chartData: chartData,
				stats: res.facets
			}));
		}.bind(this))
	
	};
	
	_getEvents = async (_matchedBuildingIds, callback) => {
		if (this.state.buildingsFetchInProgress) return false;
		this.q = '';
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes, rowsPerPage } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		

		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}


		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._unitSubFilter.filter(filter => filter.search_key === key);
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else{
						const qVal = theFilter && theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
									
						if(qVal!==null){
							this.q += this.q.length > 0 ? " AND " : "";
						
							this.q += `(${key}:${qVal})`;
						}
					}
				}
			});
		}
		
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._unitSubFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this._unitSubFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						dateReplaceKeys = [...dateReplaceKeys, ...dateArrayFilter[0].search_key];					
						if(dateQ){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._unitSubFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		if(dateQ){
			dateReplaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				dateQ = dateQ.replace(regex, '*')
			
			})
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += '(';
			this.q += dateQ;
			this.q += ')';			
		}
		/****Price& Date query***/
		
		setURLFromState(this.props, this.state);
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `(display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		
		
		
		const buildingQuery = this._getDetailQuery();

		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q: this.q,
				fq: this.fq,
				wt: "json",
				rows: 0,
				"json.facet": {
					"new_building_filed": {
						"type": "query",
						"q": `topic:permits AND event_tags:(nb a1) AND event_tags:"permit-filing" AND -event_tags:"public-housing" AND -search_string:(*hpd* OR *housing*) AND event_tags:(mixed-use residential) AND units:[3 TO *]`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
								}
							},
							"stats": {
								"type": "query",
								"q": `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
								"facet" : {
									"total_units" : "sum(units)",
									"by_type" : {
										"type" : "terms",
										"field" : "event_tags",
										"facet" : {
											"total_units" : "sum(units)",
										}
									}
								
								}
							}
						}
					},
					"offering_submitted": {
						"type": "query",
						"q": `topic:offeringplans AND event_tags:submitted`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
								}
							},
							"stats": {
								"type": "query",
								"q": `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
								"facet" : {
									"total_units" : "sum(units)",
									"by_type" : {
										"type" : "terms",
										"field" : "event_tags",
										"facet" : {
											"total_units" : "sum(units)",
										}
									}
								}
							}
						}
					},
					"offering_accepted": {
						"type": "query",
						"q": "topic:offeringplans AND event_tags:accepted",
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
								}
							},
							"stats": {
								"type": "query",
								"q": `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
								"facet" : {
									"total_units" : "sum(units)",
									"by_type" : {
										"type" : "terms",
										"field" : "event_tags",
										"facet" : {
											"total_units" : "sum(units)",
										}
									}
								}
							}
						}
					},
					"offering_effective": {
						"type": "query",
						"q":  "topic:offeringplans AND event_tags:effective",
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
								}
							},
							"stats": {
								"type": "query",
								"q": `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
								"facet" : {
									"total_units" : "sum(units)",
									"by_type" : {
										"type" : "terms",
										"field" : "event_tags",
										"facet" : {
											"total_units" : "sum(units)",
										}
									}
								}
							}
						}
					},
					/*"co_received": {
						"type": "query",
						"q":  'topic:coo AND -event_tags:"public-housing" AND -search_string:(*hpd* OR *housing*) AND units:[3 TO *] AND hierarchy:1 AND slugs:*residential*',
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
								}
							},
							"stats": {
								"type": "query",
								"q": `${buildingQuery}`, //`time:[${buildingStart} TO ${buildingEnd}]`,
								"facet" : {
									"total_units" : "sum(units)",
									"by_type" : {
										"type" : "terms",
										"field" : "event_tags",
										"facet" : {
											"total_units" : "sum(units)",
										}
									}
								}
							}
						}
					},*/
					"by_building": {
						"type" : "query",
						"q" : `${buildingQuery}`,
						"facet" : {
							"unique_projects": "unique(building)"							
						}
						
					}
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof callback === "function") {
					let formattedRes = callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	
	_formatStats = (response, redoHiddenFields = false) => {

		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_resetSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, {});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_resetActive = () => {

		let	isDefault = this.props.searchTags.length===0 &&
			Object.keys(this.state.subFilters).length === 0

	    return !isDefault;
		
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		const { startDate } = this.state;
		
		const isStartDST = moment(dateStart, 'MM/DD/YYYY').isDST();
		if(this.state.gap === '+7DAY'){		
			
			this.setState( { gap : '+1MONTH', selectedDate: false, 
				startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf('month').add(0, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
				dataStart:isMobile 
				? moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf('month').toDate().toISOString() 
				: moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf('month').toDate().toISOString(),
				dataEnd:moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString()} )
				setLocalStorage(lsPre, 'gap', '+1MONTH');
		}else{
			this.setState( { gap : '+7DAY', selectedDate: false, 
				startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf('week').add(1, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
				dataStart:isMobile 
				? moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf('week').add(1, 'day').toDate().toISOString() 
				: moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf('week').add(1, 'day').toDate().toISOString(),
				dataEnd:moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString() } )
				setLocalStorage(lsPre, 'gap', '+7DAY');

		}
		
	}
	
	_toggleTime = () => {
		
		if(this.state.soldTimeField === 'event_time'){
			this.setState( { soldTimeField : 'time', selectedDate: false } )
			setLocalStorage(lsPre, 'soldTimeField', 'time');
		}else{
			this.setState( { soldTimeField : 'event_time', selectedDate: false } )
			setLocalStorage(lsPre, 'soldTimeField', 'event_time');
		}
		
	}
	
  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, chartData, stats
    } = this.state;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    	
	const buildingQuery = this._getDetailQuery();

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >

            <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	setSubFilters={this._setSubFilters}
            	resetSubFilter={this._resetSubFilter}
            	_filters={this._unitSubFilter}      
            	{...this.props}  
            	groupedView   
            	resetActive={this._resetActive()}	
             />
             <div style={{height:10,padding:"0 10",margin:10}} >
              {(buildingsFetchInProgress || fetchInProgress) && this.state.groupedView && <LinearLoading /> }
             </div>
            
            <div className="material-table__header-info">
            	<Row>
            		<Col md={12} lg={12}>
		            	<h5 className="bold-text mb-3">
		            	<div className={"mobile__show"}>
		            		{this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"}  {` `}
		            		{eventTypes.length===1 
			            	? eventTypes[0] : eventTypes.length===2 
			            	?  eventTypes.join(" and ") : 'Pipeline'
			            	} Activity <br/>
							{ selectedDate ? 
							 <Fragment>  {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `}  
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							 {` `} (24 Months Max)
							 
		            	</div>
		            	<span className={"desktop__show"}>
		            		{this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"}  {` `}
		            		{eventTypes.length===1 
			            	? eventTypes[0] : eventTypes.length===2 
			            	?  eventTypes.join(" and ") : 'Pipeline'
			            	} Activity - {` `}
		            		{ selectedDate ? 
							 <Fragment>  {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `}  
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							 {` `} (24 Months Max)
						</span>	 
		            	</h5>
		            	
					</Col>
					
					{/*!this.props.buildingView &&
					<Col md={6} lg={6} style={{ textAlign:"right" }}>
		            	<span>
						  
						  <BuildingIcon color="#DBE0E8" />
				          <Tooltip title={`${this.state.groupedView ? "Expand to Unit View" : "Condense to Building View"}`}>
					        <PurpleSwitch 
					            checked={!this.state.groupedView}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>					      	
					      <ListIcon color="#DBE0E8" />				       
					    </span>
			    	</Col>
			    	*/}
            	</Row>
            </div>
           
			 <div className="material-table__header-info">
            	<Row>	
					<Col md={6} lg={6} xl={6} sm={6} style={{ textAlign:"left" }}>
		            	<span>						  
						  
						  {/*<WeeklyIcon color="#DBE0E8" />	*/}
						  <span>Weekly</span>
				          <Tooltip title={`${this.state.gap === '+7DAY' ? "Change to Monthly Chart" : "Change to Weekly Chart"}`}>
					        <PurpleSwitch 
					            checked={this.state.gap !== '+7DAY'}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>
					       <span>Monthly</span>	
					      {/*<MonthlyIcon color="#DBE0E8" />*/}					      						      				       
					    </span>				
			    	</Col>			    	
            	</Row>
            </div>
			<div id="chartdiv" style={{ width: "100%", height: "500px", marginTop:"30px", opacity: fetchInProgress ? .5 : 1 }}></div>
			
			{stats && stats.new_building_filed &&	
				<Fragment>
					<hr/>
	            	<div className="material-table__header-info desktop__show">
						{stats.by_building ? <Fragment> <span className="bold-text">{_formatNumber(stats.by_building.unique_projects)}</span> Projects </Fragment> : ''}
						| Date Range: { selectedDate ? 
							 <Fragment>  {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `}  
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
						}	
					</div>	
					<div className="material-table__header-info mobile__show">
					
					
					{stats.by_building ? <Fragment> <span className="bold-text">{_formatNumber(stats.by_building.unique_projects)}</span> Projects </Fragment> : ''}
					<br/> Date Range: { selectedDate ? 
						 <Fragment>  {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `}  
						 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
						 </Fragment>
						:
						 <Fragment> 
						 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
						 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
						</Fragment>
					}	
				</div>		
					<Card style={{height:'auto', paddingBottom:0}}>
						<CardBody className="dashboard" style={{border:0}}>
							<Row>
							   	 <Col md={12} xl={3} lg={6} xs={12}>	
									 <Card style={{height:'auto', paddingBottom:5}}>
								        <CardBody className="dashboard__card-widget">
									            <div className="card__title">
									              <h5 className="bold-text">{`Permit Filed`}</h5>
									            </div>
									        
									      <div className="mobile-app-widget">
									          <div className="mobile-app-widget__top-line">
									            <p className="mobile-app-widget__total-stat">
									            	{stats.new_building_filed.stats && stats.new_building_filed.stats.total_units 
										            	? _formatNumber(stats.new_building_filed.stats.total_units) 
										            	: '--'}
										        </p>
									            <SupplyIcon className="dashboard__trend-icon" />
									          </div>
									          <div className="mobile-app-widget__title">
									            <h5>{'Residential Units'}</h5>
									          </div>
									          <p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{stats.new_building_filed.stats && stats.new_building_filed.stats.count 
										          			? _formatNumber(stats.new_building_filed.stats.count) : '--'}
									          		</span> <span className="small-text light-text">Projects</span>
									          </p>
									          {/* <p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{_formatNumber(stats.new_building_filed.stats.by_type.buckets.filter(item => item.val === 'nb')[0].total_units)}
									          		</span> <span className="small-text light-text">New</span>
									          		<span className="small-text light-text" style={{padding:'0 5px'}}> | </span>
									          		<span style={{ color:"#9600BF" }}>
									          			{_formatNumber(stats.new_building_filed.stats.by_type.buckets.filter(item => item.val === 'a1')[0].total_units)}
									          		</span> <span className="small-text light-text">Alteration</span>
									          </p>*/}
								          </div>
										</CardBody>
								    </Card>
								 </Col>
								 <Col md={12} xl={3} lg={6} xs={12}>	
									 <Card style={{height:'auto', paddingBottom:5}}>
								        <CardBody className="dashboard__card-widget">
									            <div className="card__title">
									              <h5 className="bold-text">{`Offering Submitted`}</h5>
									            </div>
									        
									      <div className="mobile-app-widget">
									          <div className="mobile-app-widget__top-line">
									            <p className="mobile-app-widget__total-stat">
									            	{stats.offering_submitted.stats && stats.offering_submitted.stats.total_units 
										            	? _formatNumber(stats.offering_submitted.stats.total_units) 
										            	: '--'}
										        </p>
									            <SupplyIcon className="dashboard__trend-icon" />
									          </div>
									          <div className="mobile-app-widget__title">
									            <h5>{'Condo & Co-op Units'}</h5>
									          </div>
									          <p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{stats.offering_submitted.stats && stats.offering_submitted.stats.count 
										          			? _formatNumber(stats.offering_submitted.stats.count) : '--'}
									          		</span> <span className="small-text light-text">Projects</span>
									          </p>
									          {/*<p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{_formatNumber(stats.offering_submitted.stats.by_type.buckets.filter(item => item.val === 'new-condo')[0].total_units
										          				+ stats.offering_submitted.stats.by_type.buckets.filter(item => item.val === 'condo-conversion')[0].total_units)}
									          		</span> <span className="small-text light-text">Condo</span>
									          		<span className="small-text light-text" style={{padding:'0 5px'}}> | </span>
									          		<span style={{ color:"#9600BF" }}>
									          			{_formatNumber(stats.offering_submitted.stats.by_type.buckets.filter(item => item.val === 'new-coop')[0].total_units
										          				+ stats.offering_submitted.stats.by_type.buckets.filter(item => item.val === 'coop-conversion')[0].total_units)}
									          		</span> <span className="small-text light-text">Co-op</span>
									          </p>*/}
								          </div>
										</CardBody>
								    </Card>
								 </Col>
								 <Col md={12} xl={3} lg={6} xs={12}>	
									 <Card style={{height:'auto', paddingBottom:5}}>
								        <CardBody className="dashboard__card-widget">
									            <div className="card__title">
									              <h5 className="bold-text">{`Offering Accepted`}</h5>
									            </div>
									        
									      <div className="mobile-app-widget">
									          <div className="mobile-app-widget__top-line">
									            <p className="mobile-app-widget__total-stat">
									            	{stats.offering_accepted.stats && stats.offering_accepted.stats.total_units 
										            	? _formatNumber(stats.offering_accepted.stats.total_units) 
										            	: '--'}
										        </p>
									            <SupplyIcon className="dashboard__trend-icon" />
									          </div>
									          <div className="mobile-app-widget__title">
									            <h5>{'Condo & Co-op Units'}</h5>
									          </div>
									          <p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{stats.offering_accepted.stats && stats.offering_accepted.stats.count 
										          			? _formatNumber(stats.offering_accepted.stats.count) : '--'}
									          		</span> <span className="small-text light-text">Projects</span>
									          </p>
									          {/*<p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{_formatNumber(stats.offering_accepted.stats.by_type.buckets.filter(item => item.val === 'new-condo')[0].total_units
										          				+ stats.offering_accepted.stats.by_type.buckets.filter(item => item.val === 'condo-conversion')[0].total_units)}
									          		</span> <span className="small-text light-text">Condo</span>
									          		<span className="small-text light-text" style={{padding:'0 5px'}}> | </span>
									          		<span style={{ color:"#9600BF" }}>
									          			{_formatNumber(stats.offering_accepted.stats.by_type.buckets.filter(item => item.val === 'new-coop')[0].total_units
										          				+ stats.offering_accepted.stats.by_type.buckets.filter(item => item.val === 'coop-conversion')[0].total_units)}
									          		</span> <span className="small-text light-text">Co-op</span> 
									          </p>*/}
								          </div>
										</CardBody>
								    </Card>
								 </Col>
								 <Col md={12} xl={3} lg={6} xs={12}>	
									 <Card style={{height:'auto', paddingBottom:5}}>
								        <CardBody className="dashboard__card-widget">
									            <div className="card__title">
									              <h5 className="bold-text">{`Offering Effective`}</h5>
									            </div>
									        
									      <div className="mobile-app-widget">
									          <div className="mobile-app-widget__top-line">
									            <p className="mobile-app-widget__total-stat">
									            	{stats.offering_effective.stats && stats.offering_effective.stats.count ? 
										            	_formatNumber(stats.offering_effective.stats.total_units) 
										            	: '--'}
										        </p>
									            <SupplyIcon className="dashboard__trend-icon" />
									          </div>
									          <div className="mobile-app-widget__title">
									            <h5>{'Condo & Co-op Units'}</h5>
									          </div>
									          <p className="progress__label">
									          		<span style={{ color:"#9600BF" }}>
									          			{stats.offering_effective.stats && stats.offering_effective.stats.count ? 
										          			_formatNumber(stats.offering_effective.stats.count) : '--'}
									          		</span> <span className="small-text light-text">Projects</span>
									          </p>
								          </div>
										</CardBody>
								    </Card>
								 </Col>
				            </Row> 
			            </CardBody>
					</Card> 
					
				</Fragment>
			}      
            
            {!chartData === false && <div className="material-table__wrap">
	          
	           		<UnitsListTable 
	              		isSelected={this.isSelected}
	              		handleBuildingClick={this.handleBuildingClick}
	              		handleClick={this.handleClick}
	              		handleRequestSort={this.handleRequestSort}
	              		handleSelectAllClick={this.handleSelectAllClick}
	              		{...this.state} 
	              		{...this.props} 
	              		reactTableFiltered={false}	
	              		isGrouped
	              		q={this.q ? this.q : '*:*'}
	              		fq={this.fq}
	              		fl={this.fl}	
	              		buildingQuery={buildingQuery}
	             />
	          
            
            </div>}
            
          
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(UnitsSearchTable));