import PropTypes from 'prop-types';

const {
  string, bool, shape, func,
} = PropTypes;

export const SidebarProps = shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
});

export const ThemeProps = shape({
  className: string,
});

export const RTLProps = shape({
  direction: string,
});

export const UserProps = shape({
  fullName: string,
  avatar: string,
});