import React, { PureComponent, useState, Fragment, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import EnergyTrends from './components/EnergyTrends';
import EnergyScoreCard from './components/EnergyScoreCard';
import EnergyGradeCard from './components/EnergyGradeCard';
import EnergyUseCard from './components/EnergyUseCard';
import WaterUseCard from './components/WaterUseCard';
import CarbonEmissionsCard from './components/CarbonEmissionsCard';
import EnergyUseTrends from './components/EnergyUseTrends';
import CarbonEmissionsTrends from './components/CarbonEmissionsTrends';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import AlertIcon from 'mdi-react/InformationOutlineIcon';


import LinearLoading from '../../../../shared/components/LinearLoading';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../shared/helpers/apicalls';
const apiURL = process.env.API_ROOT + '/query/';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft:"-8px",
    alignItems: 'left',
    '& > *': {
      margin: theme.spacing(1),      
    },
    
  },
  button:{
	'&.selected' :{
	    background:"#D1D6E0"
    }
  }
}));

const _translateScore = (score) => {
	let grade = "Unknown";
	if(score >= 85){
		grade = "A";
	}else if(score >= 70 && score < 85){
		grade = "B";
	}else if(score >= 55 && score < 70){
		grade = "C";
	}else if(score > 0 && score < 55){
		grade = "D";
	}else{
		grade = "F";
	}
	return grade;
}

const Energy = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'summary');
	
	const [period, setPeriod] = useState("all-time");
	
	const [listingStats, setListingStats] = useState(null);
	const [dataRes, setDataRes] = useState(null);
	const [statsRes, setStatsRes] = useState(null);
	
    const { unitsRes, buildingRes, user } = props;  
    const _boro = buildingRes.borough;
    const _hood = buildingRes.neighborhood[0];
    const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
    const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
    const summaryTitle = 'Building Analysis - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
    const _toggleTab = (tab) => {
	    if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/analysis/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
	      setActiveTab(tab)
	      Publisher.publish(`track.buildingAnalysisView.${tab}`, {building: buildingRes.key});
	    }
	  };

	const getEnergyTrend = async () => { 
			const addressQ = buildingRes.alternate_addresses.join('" OR "');					
			const q = `alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}")`;
			const fq = [
				
			];
			let axiosData = {
				url: apiURL + "we3-energy-score?cache=3600",
				method: "post",
				query: {
					q: q,
					sort: "year_ending asc",
					wt: "json",
					fq: fq,
					rows:99,
				}
			}
			let formattedData = await _axiosCall(axiosData)
			.then(res => {
				setDataRes(res);
			});
	}
	const getEnergyStats = async () => { 
			const addressQ = buildingRes.alternate_addresses.join('" OR "');					
			const q = `largest_property_use_type:"multifamily housing" AND energy_star_score:[1 TO 100]`;
			const fq = [
				
			];
			let axiosData = {
				url: apiURL + "we3-energy-score?cache=3600",
				method: "post",
				query: {
					q: "*:*",
					sort: "year_ending asc",
					wt: "json",
					fq: fq,
					rows:0,
					"json.facet": {
						nyc: {
							type : "query",
							q : "borough_code:[1 TO 5]",
							facet : {
								by_year: {
									range: {
										field: "year_ending",
										start: 2010,
										end: 2023,
										gap: 1,
										facet: {
											  avg_site_eui_kbtu_ft: "avg(site_eui_kbtu_ft)",
											  med_site_eui_kbtu_ft: "percentile(site_eui_kbtu_ft, 50)",
											  avg_energy_star_score: "avg(energy_star_score)",
											  med_energy_star_score: "percentile(energy_star_score, 50)",
											  avg_total_ghg_emissions_intensity_kgco2e_ft: "avg(total_ghg_emissions_intensity_kgco2e_ft)",
											  med_total_ghg_emissions_intensity_kgco2e_ft: "percentile(total_ghg_emissions_intensity_kgco2e_ft, 50)"
										 }
									}
								}
							  	
							}
						},
						borough: {
							type : "query",
							q : `borough:"${buildingRes.borough.toLowerCase()}"`,
							facet : {
								by_year: {
									range: {
										field: "year_ending",
										start: 2010,
										end: 2023,
										gap: 1,
										facet: {
											  avg_site_eui_kbtu_ft: "avg(site_eui_kbtu_ft)",
											  med_site_eui_kbtu_ft: "percentile(site_eui_kbtu_ft, 50)",
											  avg_energy_star_score: "avg(energy_star_score)",
											  med_energy_star_score: "percentile(energy_star_score, 50)",
											  avg_total_ghg_emissions_intensity_kgco2e_ft: "avg(total_ghg_emissions_intensity_kgco2e_ft)",
											  med_total_ghg_emissions_intensity_kgco2e_ft: "percentile(total_ghg_emissions_intensity_kgco2e_ft, 50)"
										 }
									}
								}
								  
							}
						},
						hood: {
							type : "query",
							q : `neighborhood:"${buildingRes.neighborhood ? buildingRes.neighborhood[0].toLowerCase() : ""}"`,
							facet : {
								by_year: {
									range: {
										field: "year_ending",
										start: 2010,
										end: 2023,
										gap: 1,
										facet: {
											  avg_site_eui_kbtu_ft: "avg(site_eui_kbtu_ft)",
											  med_site_eui_kbtu_ft: "percentile(site_eui_kbtu_ft, 50)",
											  avg_energy_star_score: "avg(energy_star_score)",
											  med_energy_star_score: "percentile(energy_star_score, 50)",
											  avg_total_ghg_emissions_intensity_kgco2e_ft: "avg(total_ghg_emissions_intensity_kgco2e_ft)",
											  med_total_ghg_emissions_intensity_kgco2e_ft: "percentile(total_ghg_emissions_intensity_kgco2e_ft, 50)"
										 }
									}
								}
								  
							}
						}
					}	
				}
			}
			let formattedData = await _axiosCall(axiosData)
			.then(res => {
				setStatsRes(res);
			});
	}
	useEffect(() => {
	  getEnergyTrend();
	  getEnergyStats();
	  //getListingTrend();
	}, []);
	const classes = useStyles();
	
	if(!dataRes || !statsRes){
		return  (
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		<div>
		<LinearLoading />
		</div>
		</Container>	  
		</div>	
		)
	}
	const { docs } = dataRes;
	if(!docs || docs.length<=0 ){
		return (
		  <Col md={12}>
			<Card>
			  <CardBody className={`small`}>
				<div className={`email-confirmation`} >
				  <div className="email-confirmation__icon">
					<AlertIcon size={50} color={`#D6BAE8`} />			            
				  </div>
				  <h3 className="email-confirmation__title">No Energy Info Found</h3>
				  <p className="email-confirmation__sub">New buildings that have not been in operation for a full year and buildings under 25,000 sq ft are not required to report energy benchmarks.  </p>
				  
				</div>
			  </CardBody>
			</Card>
		  </Col>
		  )
	}
	//statsRes = statsRes && statsRes.
	const statData = {
		nyc : {},
		borough:{},
		hood:{}
	};
	statsRes.facets && statsRes.facets.nyc.by_year && statsRes.facets.nyc.by_year.buckets.forEach(item => statData.nyc[item.val] = item);
	statsRes.facets && statsRes.facets.borough.by_year && statsRes.facets.borough.by_year.buckets.forEach(item => statData.borough[item.val] = item);
	statsRes.facets && statsRes.facets.hood.by_year && statsRes.facets.hood.by_year.buckets.forEach(item => statData.hood[item.val] = item);
    return (

	    <div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		 {dataRes &&
			<Row>
				<Col md={12} xl={3} lg={6} sm={12} xs={12}>		  	
					<EnergyGradeCard dataRes={dataRes} statData={statData} translateScore={_translateScore}/>
				</Col>
				<Col md={12} xl={3} lg={6} sm={12} xs={12}>		  	
					<EnergyScoreCard dataRes={dataRes} statData={statData}  translateScore={_translateScore}/>
				</Col>
				<Col md={12} xl={3} lg={6} sm={12} xs={12}>		  	
					<EnergyUseCard dataRes={dataRes} statData={statData}   translateScore={_translateScore}/>
				</Col>
				<Col md={12} xl={3} lg={6} sm={12} xs={12}>		  	
					<CarbonEmissionsCard dataRes={dataRes} statData={statData}   translateScore={_translateScore}/>
				</Col>
			</Row>
		}
			<Row>
		
			<EnergyTrends buildingNameOrAdr={buildingNameOrAdr} buildingRes={props.buildingRes} dataRes={dataRes}  statData={statData} translateScore={_translateScore} />
			</Row>
			<Row>
			
			<EnergyUseTrends buildingNameOrAdr={buildingNameOrAdr} buildingRes={props.buildingRes} dataRes={dataRes}  statData={statData} translateScore={_translateScore} />
			</Row>
			<Row>
			
			<CarbonEmissionsTrends buildingNameOrAdr={buildingNameOrAdr} buildingRes={props.buildingRes} dataRes={dataRes}  statData={statData} translateScore={_translateScore} />
			</Row>
		</Container>	  	
		   
		</div>
		    
    )
    
	
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Energy));