import { PubSub } from 'pubsub-js';
import { Analytics } from './events/analytics.js';
import { Intercom } from './events/intercom.js';
import { Marketproof } from './events/marketproof.js';
// import { FullStory } from './events/fullstory.js';
// import { AppCues } from './events/appcues.js';

export const Subscriber = (function () {
    
    function loginEvent(event, data){	    
	    Analytics.doTrackLogin(event, data); 	    
	    Intercom.doLogin(event, data);
	    // FullStory.doLogin(event, data);
	    // AppCues.doLogin(event, data);
    }
    
    function userUpdateEvent(event, data){	    
	    Intercom.doUpdate(event, data);
	    // FullStory.doLogin(event, data);
	    // AppCues.doLogin(event, data);
    }
    
    function generalEvent(event, data){	    
	    Analytics.doTrackGeneralEvent(event, data); 
	    Intercom.doTrackEvent(event, data);
    }
    
    function logoutEvent(event, data){	 
	    Analytics.doTrackGeneralEvent(event, data);   	    	    
	    Intercom.doLogout(event, data);
    }
    
    function trackMPEvent(event, data){
	    Marketproof.trackEvent(event, data);
    }
    
    
    function subscribe(){		        
	    PubSub.subscribe('login', loginEvent);
	    PubSub.subscribe('user-update', userUpdateEvent);
	    PubSub.subscribe('logout', logoutEvent);
	    PubSub.subscribe('follow', generalEvent);
	    PubSub.subscribe('unfollow', generalEvent);
	    PubSub.subscribe('search', generalEvent);
	    PubSub.subscribe('article', generalEvent);
	    PubSub.subscribe('my-feed', generalEvent);
	    PubSub.subscribe('track', generalEvent);
	    PubSub.subscribe('track-mp', trackMPEvent);
	}
    return {
	    subscribe : subscribe
    }
})();