/* eslint-disable react/no-children-prop */
import React, { Fragment, useState  } from 'react';
import {
  Card, CardBody, Row, Col, ButtonToolbar
} from 'reactstrap';

import { connect } from 'react-redux';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../../shared/components/Modal';
import{
	subscriptionPlans,
	_capitalizeText
} from '../../../shared/helpers/utils';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import '../../../shared/components/payment/Payment.scss'
import UpdateCCForm from '../../../shared/components/payment/stripe/UpdateCCForm';

const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);




const BillingForm = ( props ) => {
	const { cancelSubscription, reset, user, paymentMethod, customer, snackOpen, toggleRefresh } = props;
	//console.log(user);
	const { subscriptionPlan } = user;
	const currentPlan = subscriptionPlans.filter(plan => plan.id === subscriptionPlan)[0];
	if(!paymentMethod.card){
		return(	  
			  <Col md={12} lg={4}>
			    <Card>
			      <CardBody>
			        <div className="card__title">
			          <h5 className="bold-text">{'My Billing Information'}</h5>
			          <div className="page-subhead subhead"></div>
			          <hr/>
			        </div>	
			        <div>
			        	Credit Card Type: <strong className="plan-bold">--</strong>
			        </div>
			        <div>
			        	Last 4 Digits: <strong className="plan-bold">--</strong>
			        </div>
			        <div>
			        	Expiration: <strong className="plan-bold">--</strong>
			        </div>
			        <div>
			        	Billing Zip: <strong className="plan-bold">--</strong>
			        </div>
			        <div>
			        	&nbsp;
			        </div>
			        <hr/>   
			        <div className="text-right">	        		        	
							        	
			        </div>     
			      </CardBody>
			    </Card>
			  </Col>
			)
		
	}
	
	return(	  
	  <Col md={12} lg={4}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'My Billing Information'}</h5>
	          <div className="page-subhead subhead"></div>
	          <hr/>
	        </div>	
	        <div>
	        	Credit Card Type: <strong className="plan-bold">{_capitalizeText(paymentMethod.card.brand)}</strong>
	        </div>
	        <div>
	        	Last 4 Digits: <strong className="plan-bold">{paymentMethod.card.last4}</strong>
	        </div>
	        <div>
	        	Expiration: <strong className="plan-bold">{paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</strong>
	        </div>
	        <div>
	        	Billing Zip: <strong className="plan-bold">{paymentMethod.billing_details.address.postal_code}</strong>
	        </div>
	        <div>
	        	&nbsp;
	        </div>
	        <hr/>   
	        <div className="text-right">
	        	<ButtonToolbar>        		        	
					<Elements stripe={stripePromise}>
				      <UpdateCCForm user={user} customer={customer} snackOpen={snackOpen} toggleRefresh={toggleRefresh}  />
				    </Elements>	
			    </ButtonToolbar>	        	
	        </div>     
	      </CardBody>
	    </Card>
	  </Col>
	)
};

const form = 'password_form';

export default connect(
  (state, props) => {
  const initalValues = Object.assign({}, props.user);
  console.log(initalValues);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
})(BillingForm));
