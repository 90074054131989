import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import CrosshairsGpsIcon from 'mdi-react/CrosshairsGpsIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import Panel from '../../../../../shared/components/Panel';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  autoplay: false,
  swipeToSlide: true,
  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
  className: "promo",
  responsive: [
  { breakpoint: 768, settings: { slidesToShow: 1 } },
    { breakpoint: 992, settings: { slidesToShow: 1 } },
    { breakpoint: 1200, settings: { slidesToShow: 1 } },
    { breakpoint: 1536, settings: { slidesToShow: 1 } },
    { breakpoint: 100000, settings: { slidesToShow: 1 } },
  ],
};	

const reports = [
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-july-2024`,
    title : "Jul 2024",
    subtitle: "New dev market cools",
    excerpt : `Market activity cooled, suggesting a summer lull`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-june-2024`,
    title : "Jun 2024",
    subtitle: "New dev market returns to pre-pandemic norms",
    excerpt : `Key metrics showed mixed performance, deals slow but prices rise`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-may-2024`,
    title : "May 2024",
    subtitle: "New dev market shows modest gains",
    excerpt : `Key metrics showed modest gains, signaling stability heading into summer`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-april-2024`,
    title : "Apr 2024",
    subtitle: "New dev market stabilizes after growth",
    excerpt : `Deal volume retreats after four-month climb`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-march-2024`,
    title : "Mar 2024",
    subtitle: "New dev market continues on upward trajectory",
    excerpt : `Deal volume rose by 7% for the fourth month of increases`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-february-2024`,
    title : "Feb 2024",
    subtitle: "Early momentum continues to strengthen",
    excerpt : `Deal count ticked 21% from January, marking third months of inreases`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-january-2024`,
    title : "Jan 2024",
    subtitle: "New year kicks off in right direction",
    excerpt : `Deal count ticked up 30% from December, marking first time to break 200 since August 2023`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-year-end-2023`,
    title : "2023 Report",
    subtitle: "Purchases far outpaced new listings",
    excerpt : `Inventory contracted from 12.7K units in 2022 to 10.2K units in 2023`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-november-2023`,
    title : "Nov 2023",
    subtitle: "Mixed signals continue as we head toward year’s end",
    excerpt : `Deal volume slid 9% from October due to the Thanksgiving holiday and lackluster activity in Queens`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-october-2023`,
    title : "Oct 2023",
    subtitle: "Sales slowing, inventory continues to decline",
    excerpt : `Though sales have slowed, inventory is still declining as even fewer units are coming on to the market`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-september-2023`,
    title : "Sep 2023",
    subtitle: "Fall begins with some mixed signals",
    excerpt : `31% decrease in deal volume from August.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-august-2023`,
    title : "Aug 2023",
    subtitle: "Summer looks to close on a high note",
    excerpt : `5% increase in deal volume from July.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-july-2023`,
    title : "Jul 2023",
    subtitle: "Summer in the city: new dev holds on",
    excerpt : `24% increase in YTD deal volume compared to the average January–July.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-june-2023`,
    title : "Jun 2023",
    subtitle: "First Half of 2023 Posts Historically Strong Numbers",
    excerpt : `June contracts were 28% greater than the historical average.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-may-2023`,
    title : "May 2023",
    subtitle: "Significant Demand in Luxury",
    excerpt : `May contracts were 46% greater than the historical average.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-april-2023`,
    title : "Apr 2023",
    subtitle: "YTD Outpaces Pre-Pandemic",
    excerpt : `Compared to the pre-pandemic 2015-2020, 8% more deals were signed during Jan-April.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-march-2023`,
    title : "Mar 2023",
    subtitle: "New Dev's March Madness",
    excerpt : `Compared to the pre-pandemic 2015-2020 average, 25% more deals were signed across NYC.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-february-2023`,
    title : "Feb 2023",
    subtitle: "New Devs Did Nicely",
    excerpt : `Compared to the pre-pandemic 2015-2020 average, 6% more deals were signed across NYC.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-january-2023`,
    title : "Jan 2023",
    subtitle: "Fewer Deals than Pre-Pandemic",
    excerpt : `Condo contract activity declined compared pre-pandemic levels, but were more expensive.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-year-end-2022`,
    title : "2022 Report",
    subtitle: "Demand Declined Mid-Year",
    excerpt : `After record-setting 2021, demand declined mid-year, but PPSF remained stable`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-november-2022`,
    title : "November 2022",
    subtitle: "New Dev Contracts Up 11%",
    excerpt : `Activity increased 11% from October and down 21% from pre-pandemic`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-october-2022`,
    title : "Oct 2022",
    subtitle: "New Dev Contracts Drop",
    excerpt : `Activity dipped 20% from September and down 36% from pre-pandemic`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-september-2022`,
    title : "Sept 2022",
    subtitle: "Luxury Activity Gained",
    excerpt : `Modest dip in new dev contracts yet luxury activity gained`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-august-2022`,
    title : "Aug 2022",
    subtitle: "Contracts Bounce Back",
    excerpt : `NYC new developments made 250 sales, surpassing pre-pandemic levels`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-july-2022`,
    title : "Jul 2022",
    subtitle: "Contracts Fall",
    excerpt : `Contract volume fell below pre-pandemic levels for the first time in 2022`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-june-2022`,
    title : "Jun 2022",
    subtitle: "Luxury Performs Well",
    excerpt : `Fewer contracts but luxury tier performs well`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-may-2022`,
    title : "May 2022",
    subtitle: "Activity Remains Strong",
    excerpt : `Buying frenzy tapering but still well above pre-pandemic levels, especially Manhattan`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-april-2022`,
    title : "Apr 2022",
    subtitle: "Contracts Dip",
    excerpt : `Sponsor contracts dip but market still elevated`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-march-2022`,
    title : "Mar 2022",
    subtitle: "Best First Quarter",
    excerpt : `March closes best first quarter for sponsor contracts`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-february-2022`,
    title : "Feb 2022",
    subtitle: "Queens is Gaining an Edge",
    excerpt : `NYC new developments report 26% more sponsor contracts`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-january-2022`,
    title : "Jan 2022",
    subtitle: "Market Activity Normalizing",
    excerpt : `Condo contract activity matches pre-pandemic levels, but prices are increasing.`
  },
  {
    url : `https://marketproof.com/reports/nyc-new-development-market-report-december-2021`,
    title : "Dec 2021",
    subtitle: "Hot Month for New Dev",
    excerpt : `Condo contracts soared 65% above the same period pre-pandemic`
  }
]

const ShortReminders = ({ t }) => (
  <Panel md={12} lg={12} xl={3} sm={12} xs={12} noButtons>
    <Slider {...settings} className="dashboard__carousel promo">
      {reports.map((report, i) =>{
         return (
           <div key={i}>
             <a href={report.url} target="_blank">
             <div className="dashboard__carousel-slide slide__link">
               <CrosshairsGpsIcon />
               <p className="dashboard__carousel-title">{report.title} <br/><span className="light-text smaller-text">{report.subtitle}</span></p>
               <p>{report.excerpt}</p>
             </div>
             </a>
           </div>
         )
      })}
    </Slider>
  </Panel>
);

ShortReminders.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ShortReminders);
