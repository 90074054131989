import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Button, Tooltip } from '@material-ui/core';
import UncheckedIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import CheckedIcon from 'mdi-react/CheckboxMarkedIcon';
import HomeCityIcon from 'mdi-react/HomeCityIcon';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 250,
    maxWidth: 250,
    fontSize: '14px',
  },
  menuItem: {
    fontSize: '15px',
    '&.Mui-selected': {
      backgroundColor: 'transparent !important', // Prevent background change on selection
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent !important', // Prevent background change on focus
    },
  },
  listItemText: {
    fontSize: '15px',
    '& .MuiListItemText-primary': {
      fontSize: '15px',
    },
  },
  select: {
    maxWidth: '230px',
    fontSize: '15px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  inputLabel: {
    color:'#780F9E',
    '&.Mui-focused': {
      // Styles for the active state
     color:'#9600BF'
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      marginTop: 8,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export default function StatTypesDropdown(props) {
  const classes = useStyles();
  const { statTypes, setPropertyTypeFilter, eventTypes } = props;
  const [tempSelectedTypes, setTempSelectedTypes] = useState(eventTypes);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setTempSelectedTypes(event.target.value);
  };

  const handleCancel = (event) => {
    event.stopPropagation(); // Prevent triggering select item logic
    setTempSelectedTypes(eventTypes);
    setOpen(false); // Close dropdown
  };

  const handleApply = (event) => {
    event.stopPropagation(); // Prevent triggering select item logic
    setPropertyTypeFilter(tempSelectedTypes);
    setOpen(false); // Close dropdown
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderSelectedValue = (selected) => {
    if (selected.length === 0) return '';

    const firstSelected = statTypes.find(stat => stat.key === selected[0]);
    if (!firstSelected) return '';

    if (selected.length > 1) {
      return <span><HomeCityIcon size={16} color="#BF96D9" style={{marginTop:-2}} /> {firstSelected.name} <span className="small-text">(+{selected.length - 1} More)</span></span>;
    }
    
    return <span><HomeCityIcon size={16} color="#BF96D9" style={{marginTop:-2}}  /> {firstSelected.name}</span>;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.inputLabel}>Select Property Types</InputLabel>
      <Select
        multiple
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={tempSelectedTypes}
        onChange={handleChange}
        renderValue={renderSelectedValue}
        MenuProps={MenuProps}
        className={classes.select}
      >
        {statTypes.map((stat) => (
          <MenuItem key={stat.key} value={stat.key} className={classes.menuItem}>
            <Checkbox
              checked={tempSelectedTypes.indexOf(stat.key) > -1}
              icon={<UncheckedIcon size={24} />}
              checkedIcon={<CheckedIcon size={24} />}
              style={{ color: stat.checkedColor }}
            />
            <ListItemText primary={stat.name} className={classes.listItemText} />
          </MenuItem>
        ))}
        <div className={classes.actionButtons}>
          <Tooltip title="Cancel selection">
            <Button onClick={handleCancel} size="small" style={{ marginRight: 5 }}>
              Cancel
            </Button>
          </Tooltip>
          <Tooltip title="Apply filter">
            <Button onClick={handleApply} variant="outlined" size="small">
              Apply
            </Button>
          </Tooltip>
        </div>
      </Select>
    </FormControl>
  );
}
