import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import SupplyIcon from 'mdi-react/HomeOutlineIcon';
import ToolTip from '../../../../../shared/components/ToolTip';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	setURLFromState
} from "../../../../../shared/helpers/utils";

const Supply = (props) => {
	  if(!props.facetData ) return null;
	
/*
 	  const total_unsold = props.facetData.by_status.buckets
 	  	.filter(status => ["", "withdrawn", "tom 30 days", "expired", "active", "shadow"].indexOf(status.val)>-1)
 	  	.reduce((total, current)=>{ return total+current.count },0);
*/

	const total = props.facetData && props.facetData.unsold && props.facetData.unsold.count > 0 ? props.facetData.unsold.count : "--" //props.facetData.numFound ? props.facetData.numFound : 0;
 	 const newState = Object.assign({}, props); 	
	 if(newState.subFilters && newState.subFilters.current_status){
		 	delete( newState.subFilters.current_status);
	 }
 	 const baseURL = setURLFromState({}, newState, true);

	  return (
		    <Card>
		    <Link to={`/inventory${baseURL}`}>
		      <CardBody className="dashboard__booking-card card__link">
		      	
		      	
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            {total ? _formatNumber(total) : '--'}
		          </h5>
		          <SupplyIcon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">Unsold Units 
		        	<ToolTip 
		        		title={`Based on ${props.groupedCount} Marketproof Vetted buildings`} 
		        		body={`Total units include all units that meet the filter parameters.`}
		        	/>
		        </h5>
		      
		      </CardBody>
		    </Link>
		    </Card>
	  );
};

export default Supply;
