import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _getCrossStreet,
  _isResidential,
  _isHouse,
  _formatNum,
  _getServiceLevel,
  _validateLink,
  _showFeatures,
  _ordinalSuffix,
  _titleCase,
  _formatDate,
  _formatOwnerName,
  _getOccupancy,
  _getOccupancyCode,
} from "../../../shared/helpers/utils";

import { Grid } from "@material-ui/core";
import FeatureIcon from "mdi-react/CheckCircleOutlineIcon";
import NoFeatureIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";

const Construction = ({ eventData, buildingRes, trxnRes }) => {
  let data = eventData && eventData.data ? eventData.data : false;

  if (eventData && eventData.data && eventData.data.filings_data) {
    data = eventData.data.filings_data;
  }

  return (
    <Fragment>
      <Card>
        <CardBody className="no-border no-padding">
          <div className="profile-card d-block">
            <Grid className="profile-card__info" container>
              <Grid item xs={12} md={5} lg={5} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {eventData && eventData.event_time && (
                      <p
                        className={`${
                          eventData && eventData.event_time
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Date:
                        <span className="project_details-text_style">
                          <Fragment>
                            {moment(eventData.event_time).format("MM/DD/YYYY")}
                          </Fragment>
                        </span>
                      </p>
                    )}

                    {eventData && eventData.topic && eventData.topic[0] && (
                      <p
                        className={`${
                          eventData && eventData.event_time
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Activity Type:
                        <span className="project_details-text_style">
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "permits" &&
                            (eventData.event_tags.indexOf("nb") >= 0 ||
                              eventData.event_tags.indexOf("a1") >= 0) && (
                              <Fragment>Construction</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "permits" &&
                            eventData.event_tags.indexOf("dm") >= 0 && (
                              <Fragment>Demolition</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "offeringplans" && (
                              <Fragment>Offering Plan</Fragment>
                            )}

                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "coo" && (
                              <Fragment>Certificate of Occupancy</Fragment>
                            )}
                        </span>
                      </p>
                    )}

                    {eventData && eventData.topic && eventData.topic[0] && (
                      <p
                        className={`${
                          eventData.topic && eventData.topic[0]
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Activity Subtype:
                        <span className="project_details-text_style">
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "offeringplans" &&
                            eventData.event_tags.indexOf("submitted") >= 0 && (
                              <Fragment>Offering Submitted</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "offeringplans" &&
                            eventData.event_tags.indexOf("accepted") >= 0 && (
                              <Fragment>Offering Accepted</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "offeringplans" &&
                            eventData.event_tags.indexOf("effective") >= 0 && (
                              <Fragment>Offering Effective</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "permits" &&
                            eventData.event_tags.indexOf("nb") >= 0 && (
                              <Fragment>New Building Filed</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "permits" &&
                            eventData.event_tags.indexOf("a1") >= 0 && (
                              <Fragment>Major Alteration Filed</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "permits" &&
                            eventData.event_tags.indexOf("dm") >= 0 && (
                              <Fragment>Demolition Permit Filed</Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "coo" &&
                            eventData.event_tags.indexOf("coo-temporary") <
                              0 && (
                              <Fragment>
                                Final Certificate of Occupancy Issued
                              </Fragment>
                            )}
                          {eventData &&
                            eventData.topic &&
                            eventData.topic[0] === "coo" &&
                            eventData.event_tags.indexOf("coo-temporary") >=
                              0 && (
                              <Fragment>
                                Temporary Certificate of Occupancy Issued
                              </Fragment>
                            )}
                        </span>
                      </p>
                    )}

                    {data.occupancy_classification_proposed && (
                      <p
                        className={`${
                          data.occupancy_classification_proposed
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Proposed Use:
                        <span className="project_details-text_style">
                          {data.occupancy_classification_proposed &&
                          _getOccupancyCode(
                            data.occupancy_classification_proposed
                          )
                            ? _getOccupancyCode(
                                data.occupancy_classification_proposed
                              )
                            : data.proposed_occupancy &&
                              _getOccupancy(
                                data.proposed_occupancy.replace(/-/g, "")
                              )
                            ? _getOccupancy(
                                data.proposed_occupancy.replace(/-/g, "")
                              )
                            : "--"}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {eventData && eventData.units && (
                      <p
                        className={`${
                          eventData.units ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Units:
                        <span className="project_details-text_style">
                          {eventData.units > 0 ? (
                            <Fragment>
                              {eventData.units} {" dwelling units"}
                            </Fragment>
                          ) : (
                            "--"
                          )}
                        </span>
                      </p>
                    )}

                    {eventData && eventData.floors && (
                      <p
                        className={`${
                          eventData.floors ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Floors:
                        <span className="project_details-text_style">
                          {eventData.floors > 0 ? (
                            <Fragment>
                              {eventData.floors}

                              {eventData.floors > 1 ? " stories" : " story"}
                            </Fragment>
                          ) : (
                            "--"
                          )}
                        </span>
                      </p>
                    )}

                    {eventData && eventData.sq_ft && (
                      <p
                        className={`${
                          eventData.sq_ft ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Total Area:
                        <span className="project_details-text_style">
                          {eventData.sq_ft > 0 ? (
                            <Fragment>
                              {_formatNumber(eventData.sq_ft)}{" "}
                              <span className="small-text">sf</span>
                            </Fragment>
                          ) : (
                            "--"
                          )}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {eventData && eventData.foreign_id && (
                      <p
                        className={`${
                          eventData.foreign_id ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Filing ID:
                        <span className="project_details-text_style">
                          {eventData.foreign_id}
                        </span>
                      </p>
                    )}

                    {data && data.job_description && (
                      <p
                        className={`${
                          data.job_description ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Filing Description:
                        <span className="project_details-text_style">
                          {_capitalizeText(data.job_description)}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Construction;
