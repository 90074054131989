import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CardBody } from 'reactstrap';

import GMap from "./GMap";

import {
	_formatLatLong,
} from "./../../helpers/utils";

const ShowMap = ( { item, height, print } ) => {
	const mapPoints = _formatLatLong(item.geocode); 	
	const containerStyle = {
      width: '100%',
      height: height ? height : `300px`
    };
	return( 
	  
      <GMap 
        buildingData={item}
        isListing={true}
        isMarkerShown={true}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEYB3e7_LMx9R5aEx3y8w99Cv2s3XJy_o&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: height ? height : `300px` }} />}
        lat={mapPoints ? mapPoints.lat : null}
        long={mapPoints ? mapPoints.long : null}
        mapPadding={undefined}
        containerStyle={containerStyle}
        print={print}
      />

    );
};

export default ShowMap;
