import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Row, Col, Progress, Badge, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import MatTableFiltersSelect from './MatTableFiltersSelect';

import MatAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import PreviewIcon from 'mdi-react/EyeOutlineIcon';
import OpenInNew from 'mdi-react/OpenInNewIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import LinearLoading from '../../../shared/components/LinearLoading';


import ListViewIcon from 'mdi-react/FormatListBulletedIcon';
import AnalysisViewIcon from 'mdi-react/ChartBarIcon';

import CondoDecIcon from 'mdi-react/AlphaCCircleOutlineIcon';

import Supply from './analysis/Supply';
import Active from './analysis/Active';
import TotalUnits from './analysis/TotalUnits';
import AveragePPSF from './analysis/AveragePPSF';
import AveragePrice from './analysis/AveragePrice';
import TotalSelloutPrice from './analysis/TotalSelloutPrice';
import SelloutPrice from './analysis/SelloutPrice';
import PriceRange from './analysis/PriceRange';
import TotalUnsold from './analysis/TotalUnsold';
import NeighborhoodAnalysis from './analysis/NeighborhoodAnalysis';
import CurrentProgress from './analysis/CurrentProgress';
import BuildingIcons from '../../../shared/components/BuildingIcons';

import AddCollectionButton from '../../../shared/components/collections/AddCollectionButton';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";


import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	propertyFL,
	SalesProgress,
	BuildingStatus,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	buildingExportFL,
	getHouseCohortQuery,
	calcuateTCODate,
	calcuateSalesStart,
	CurrentStatus,
	buildingFL,
	HouseCurrentStatus,
	propertyExportFL
} from "../../../shared/helpers/utils";

import { 
	_houseAdminFilters,
	_buildingStageFilter
} from "../../../shared/helpers/filters";

import EnterpriseGate from '../../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../../shared/helpers/access";

import queryString from "query-string";


let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}
const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);



const buildingUrl = process.env.API_ROOT + '/query/';

const subFilterKey = 'subFilters';


class MatTable extends PureComponent {
	
  
  
  lsPre = `BDBV4_HOUSES_`;
  defaultOwnershipType = this.props.match && this.props.match.params.type ? this.props.match.params.type : false;
  propertySubFilters = _houseAdminFilters.filter(filter => !filter.filter_type || filter.filter_type !== 'building');
  _buildingFilter = _houseAdminFilters.filter(filter => filter.filter_type && filter.filter_type === 'building');
  defaultSubFilter = this.defaultOwnershipType ? {ownership_type :[this.defaultOwnershipType]} : {}
  state = {
    order: syncGenericFilter('order', this.lsPre, 'order', "desc"),
    orderBy:  syncGenericFilter('order_by', this.lsPre, 'orderBy', "last_listed_sale_date_all"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	// filterFetchInProgress: false,
	filterStage: "all_projects",
	futureInventoryData: false,
	// headerHeight: 'auto',
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, this.propertySubFilters, subFilterKey, this.lsPre, this.defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	searchText: syncURLSearch(this.props, 'searchText'),
	statView: false,
	facetData: null,
	buildingStages: ['all'],
	selectedUnits: {}
  };
  
  q = '';
  fq = '';
  sort = '';
  
  componentDidMount() {
  	this._getInventoryStats();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	  console.log(this.state.page)
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
	    let subFilterChanged =
			_houseAdminFilters.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			searchTagsChange ||
			subFilterChanged ||
			buildingStageChange
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)	
			  this._getInventoryBuildings(this.props, resetPage, {
				  callback: function(response) {
					  let effective_year = moment().year() - 5;
					  let buildingIds = response.docs.map((doc, i) => {return doc['building']});
					  this._getBuildings(buildingIds, response);
					  
				  }.bind(this),
				  url: buildingUrl
			  });
		}

	  
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  
  shouldLinkToMP = (building) => {
	   const { user } = this.props;
	   return user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 || ['active', 'coming soon', 'offering plan required', 'offering plan acquired', 'condo declaration vetted'].indexOf(building.offering_verified_status) < 0
  }
  
  handleBuildingClick = (event, building) => {
	
	 this.handleBuildingPreviewClick(event, building);
	                            
  };
  
  handleBuildingPreviewClick = (event, building) => {
	 event.preventDefault();
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building);
	 
	 window.open(buildingURL);
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  _getInventoryBuildings = async (query, resetPage, obj) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		const { user } = this.props;
		const { orderBy, order } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true}));
		}
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		
		this.q = "";

		if(locQuery){
			this.q += locQuery;
		}
		let isActive = false;
		let isExcluded = false;
		if (this.state.filterStage !== "all_projects") {
			this.q += this.q.length > 0 ? " AND " : "";
			if (this.state.filterStage === "completed_only") {
				this.q += "(building_effective_start:[NOW-5YEARS TO NOW])";
			} else if (this.state.filterStage === "under_construction_only") {
				this.q +=
					"(sales_start_date:[NOW-5YEARS TO NOW] AND -building_effective_start:[NOW-5YEARS TO NOW])";
			}
		}
		this.fq = [
					"deleted:false"
				];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this.propertySubFilters.filter(filter => filter.search_key === key);
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){		
							
							if(theFilter[0].target && theFilter[0].target == "fq" && theFilter[0].join_query){
								
								this.fq.push(`${theFilter[0].join_query}(${qVal.join(" OR ")})`);
							}else{
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += `(` + qVal.map(v => `(${key}:${v})`).join(" OR ") + `)`;
								//this.q += `(${key}:(${qVal.join(" ")}))`;
							}			
								
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						console.log(theFilter, subFilterVal)	
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						console.log(searchValue)
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += `(${searchKey}:(${qVal}))`;
					}else{
						const qVal = theFilter && theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						
									
						if(qVal!==null){
							if(theFilter[0].target && theFilter[0].target == "fq"){
								
								
								this.fq.push(qVal);
							}else{
								this.q += this.q.length > 0 ? " AND " : "";
								
								if(key === 'offering_verified_status' && ['active', '("offering plan required")', '("offering plan acquired")', '("condo declaration vetted")'].indexOf(qVal)>=0){
									isActive = true;
								}
								if(key === 'offering_verified_status' && ['excluded'].indexOf(qVal)>=0){
									isExcluded = true;
								}
								this.q += `(${key}:${qVal})`;
							}
						}
					}
				}
			});
		}
		
			
		/****Price & Date query***/
		let priceQ = {};
		

		let replaceKeys = {};
		
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this.propertySubFilters.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this.propertySubFilters.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						let dateQ = '';
						let dateReplaceKeys = [];
						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];					
							if(dateQ){
								let regex = new RegExp(`{${key}}`, "g");
								dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
							}else{		
								dateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
								
							}	
							
							if(dateQ){
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									dateQ = dateQ.replace(regex, '*')
								
								})
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += '(';
								this.q += dateQ;
								this.q += ')';			
							}	
						})									
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this.propertySubFilters.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		
		/****Price& Date query***/
		/****Bldg Price & Date query***/
		let bldgPriceQ = {};
		let defaultBuidingQ = "";
		
		replaceKeys = {};
		
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._buildingFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(bldgPriceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							bldgPriceQ[filterKey] = bldgPriceQ[filterKey] ?  bldgPriceQ[filterKey].replace(regex, subFilterVal) : "";
						}else{
							
							bldgPriceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					console.log(bldgPriceQ);
					const dateArrayFilter = this._buildingFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						let bldgDateQ = '';
						let dateReplaceKeys = [];
						
						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];					
							if(bldgDateQ){
								let regex = new RegExp(`{${key}}`, "g");
								bldgDateQ = bldgDateQ.replace(regex, moment(subFilterVal).toISOString())
							}else{		
								bldgDateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
								
							}					
							
							if(bldgDateQ){
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									bldgDateQ = bldgDateQ.replace(regex, '*')
								
								})
								defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
								defaultBuidingQ += '(';
								defaultBuidingQ += bldgDateQ;
								defaultBuidingQ += ')';			
							}	
							
						})
											
						console.log("priceQ", bldgDateQ, dateReplaceKeys)
					}										
				}
			});		
		}	
		
		if(bldgPriceQ && Object.keys(bldgPriceQ).length>0){
			
			Object.keys(bldgPriceQ).map(filterKey => {
				const qArrayFilter = this._buildingFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						bldgPriceQ[filterKey] = bldgPriceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target && qArrayFilter.target=="fq"){
							bldgPriceQ[filterKey] = bldgPriceQ[filterKey].replace(regex, '99999999')
						}else{
							bldgPriceQ[filterKey] = bldgPriceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(bldgPriceQ[filterKey]);
				}else{
					defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
					defaultBuidingQ += '(';
					defaultBuidingQ += bldgPriceQ[filterKey];
					defaultBuidingQ += ')';	
				}
				
				
			})
					
		}
		
		if(defaultBuidingQ != ""){
			this.fq.push(`{!join from=alternate_keys fromIndex=we3-buildings to=building}${defaultBuidingQ}`);
		}
		
		
		setURLFromState(this.props, this.state);
		
		
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `(name:(*${text}* OR *${textCaps}*) OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		this.q = this.q.length > 0 ? this.q : "*:*";

		this.fq.push(getHouseCohortQuery(user));
		
		if(!(user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0)){
			this.fq.push("total_units:[1 TO *]");
			
		}
		this.sort = `${orderBy} ${order}`;
		if (query.fq && query.fq.length > 0) {
			this.fq = this.fq.concat(query.fq);
		}
		
		this.sort = this.sort;
		let axiosData = {
			url: obj.url + "we3-properties?cache=15",
			method: "post",
			query: {
				q: this.q,
				fq: this.fq,
				sort: this.sort,
				fl: propertyFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"json.facet": {
					"total_units": "sum(total_units)"
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof obj.callback === "function") {
					let formattedRes = obj.callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	_getBuildings = async (_matchedBuildingIds, properties) => {
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"alternate_keys:("+_matchedBuildingIds.join(" ")+")",
			"deleted:false"			
		];
	
		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: buildingFL,
				wt: "json",
				rows: _matchedBuildingIds.length
			}
		};
	
		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
		
		properties.docs = properties.docs.map(prop => {
			let bldg = formattedData.filter(bldg => bldg.key == prop.building);
			if(bldg[0]){
				prop = Object.assign({}, bldg[0], prop);
			}
			return prop;
		})
		this._formatStats(properties, false);
		
		
	
	};

	_getInventoryStats = (newStart = false) => {
		console.log("_getInventoryStats this.props ", this.props);
		console.log("_getInventoryStats called Inventory state", this.state);

		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({
				buildingsFetchInProgress: true
			}));
		}

		this._getInventoryBuildings(this.props, true, {
			callback: function(response) {
				let effective_year = moment().year() - 5;
				let buildingIds = response.docs.map((doc, i) => {return doc['building']});
				this._getBuildings(buildingIds, response);
				
			}.bind(this),
			url: buildingUrl
		});
	};

	_formatStats = (response, resetPage, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		this.setState(prevState => ({ tableData: [], reactTableFiltered:  []}));
		
	
		let formattedData = response
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
		
		let docs = formattedData;

		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			tableData:  docs,
			reactTableFiltered:  docs,
			originalData: redoHiddenFields ? prevState.originalData : response,
			// filterFetchInProgress: false,
			totalCount: response.numFound,
			start: resetPage ? 0 : this.state.start,
			page: resetPage ? 0 : this.state.page,
			stats: redoHiddenFields 
				? prevState.stats 
				: Object.assign(prevState.stats, {
					total_buildings: response.facets.count,
					total_units: response.facets.total_unsold,
					total_inventory: response.facets.total_inventory,
					inventory_sponsor: response.facets.inventory_sponsor,
					inventory_resale: response.facets.inventory_resale,
			}),
			facetData: response.facets,
			selectedUnits: {}
			
		}));
	};
	
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	_filterResponseTableData = eValue => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter(item => {
						// console.log("filterData item", item)
						const inputVal = eValue.toLowerCase();
						let nameVal, adrVal, hoodVal;
						if (item.name) {
							// console.log('item has name')
							nameVal = item.name.toLowerCase();
						}

						if (
							(item.display_full_street_address &&
								_shouldShow(item.display_full_street_address)) ||
							(item.full_street_address &&
								_shouldShow(item.full_street_address))
						) {
							const field = item.display_full_street_address
								? "display_full_street_address"
								: "full_street_address";
							// console.log('item has adr', field)

							adrVal = item[field].toLowerCase();
						}

						if (item.neighborhood && _shouldShow(item.neighborhood)) {
							const hood =
								Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
									? item.neighborhood[0]
									: item.neighborhood;
							hoodVal = hood.toLowerCase();
						}

						// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
						// searching name and ONE adr field - could update to search both display and full and all alt adrs
						return (
							(nameVal && nameVal.indexOf(inputVal) >= 0) ||
							(adrVal && adrVal.indexOf(inputVal) >= 0) ||
							(hoodVal && hoodVal.indexOf(inputVal) >= 0)
						);
				  })
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState(prevState => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
		    //tableData: filteredData,
		    //originalData: response,
		    //filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = ["sponsor"]
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = ['all'];
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(this.lsPre, "", JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.defaultSubFilter);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(this.lsPre, subFilterKey);
	}
	
	_resetActive = () => {
		
		let isDefault = this.props.searchTags.length===0 &&
						_objectEqual(this.defaultSubFilter, this.state.subFilters)
	    return !isDefault;
		
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		
		if(this.state.statView){
			this.setState( { statView : false } )
		}else{
			this.setState( { statView : true } )
		}
		
	}
   _selectUnit(property){
	   const { selectedUnits } = this.state;
	   const newSelected = Object.assign({}, selectedUnits);
	   
	   if(newSelected[property.key]){
		   delete newSelected[property.key];			
	   }else{
		   newSelected[property.key] = property;
	   }
	   
	   this.setState( { selectedUnits: Object.assign({}, newSelected) } );
   }
   _handleSelectAllClick(){
	   const { selectedUnits, reactTableFiltered } = this.state;
	   let newSelected = {}
	   console.log(reactTableFiltered)
	   if(Object.keys(selectedUnits).length < reactTableFiltered.length){
		   
	   
		   reactTableFiltered.forEach(item => {
			   newSelected[item.key] = item;
		   })
	   }
	   
	   this.setState( { selectedUnits: newSelected } );
	   
   }
   _clearSelectedUnits(){
	   this.setState({selectedUnits:{}})
   }
  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, statView, buildingsFetchInProgress, stats, selectedUnits, buildingStages
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
     const paginationCount = totalCount;
    if(!tableData){
	    return null;
    }
    const table = reactTableFiltered;
    
    let unitCount = (stats && stats.inventory_sponsor && stats.inventory_sponsor.total) ? stats.inventory_sponsor.total : 0;
    
    unitCount += (stats && stats.inventory_resale && stats.inventory_resale.total ? stats.inventory_resale.total : 0);
	
	if(!_hasPermission(user, 'sponsor-buildings') && buildingStages.indexOf("sponsor")>=0){
		return(
			  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/newdev-stage-buildings.png`}
					copy={<span>Access to new development buildings requires an upgrade to your subscription.<br/>  You can downgrade at any time.</span>}
					upgrade
					user={user}
					nextPlan={"complete-199"}
				/>
		)
	} 
	if(!_hasPermission(user, 'pipeline-buildings') && buildingStages.indexOf("pipeline")>=0){
		return(
			  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-macbook-pipeline-buildings.png`}
					copy={<span>Access to pipeline buildings requires an update to your subscription.</span>}
					upgrade
					user={user}
					nextPlan={"complete-199"}
				/>
		)
	} 
	if(!_hasPermission(user, 'resale-buildings') && buildingStages.indexOf("resale")>=0){
		return(
		
			  <EnterpriseGate
				  copy={<span>Access to resale buildings requires an update to your subscription.</span>}
			  />
		
		)
	} 
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
           {/*<div className="card__title">
              <h5 className="bold-text">Select and Analyze</h5>
            </div>*/}
            <MatAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				resetSubFilter={this._resetSubFilter}
				hideBuildingStage
				buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	setSubFilter={this._setSubFilter}
            	setSubFilters={this._setSubFilters}	
            	_filters={(user && user.role.indexOf('admin')>=0) ?  _houseAdminFilters : _houseAdminFilters.filter(item => !item.admin_only)}
            	placeHolder={'Building, Neighborhood or Borough'}         
            	buildingAttributes={[]}  
            	goToBuilding={true}	
            	resetActive={this._resetActive()}
            	showExport				
				q={this.q}
				fq={this.fq}
				fl={propertyExportFL}
				sort={this.sort}
				totalCount={totalCount}
				core_name={'we3-properties'}
				title={'Export This House Search (CSV)'} 
				export_name={'House Search'} 
          		description={<Fragment><h4><strong>Export this building search for your own analysis.</strong></h4> <br/>   The export will include the profile of the building, sales progress, key dates and more.</Fragment>}
             />
            <div style={{padding:"0 10px",clear:"both"}}>
            	{buildingsFetchInProgress ? <LinearLoading /> : <div style={{height:isMobile ? 0 : 20}} />}
			</div>	
            <div className="material-table__header-info">
            
            
            	<Row>
            		<Col md={6} lg={6}>
            		  <div className={'count_subheader'}>       		
            			<span className="bold-text">{tableCount > 0 ? _formatNumber(tableCount) : '--'}</span>{` `}Properties
            			{/*<span> | </span>
            			<span className="bold-text">{unitCount > 0 ? _formatNumber(unitCount) : '--'}</span>{` `}Units*/}
            			
            		  </div>
            		</Col>
		            {!this.props.buildingView &&
					<Col md={6} lg={6} style={{ textAlign:"right" }}>
					{false && user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
		            	<span>
						  
						  <ListViewIcon color="#DBE0E8" />
				          <Tooltip title={`${this.state.statView ? "Switch to List View" : "Switch to Analysis View"}`}>
					        <PurpleSwitch 
					            checked={this.state.statView}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>					      	
					      <AnalysisViewIcon color="#DBE0E8" />				       
					    </span>
					}
			    	</Col>
			    	}
            	</Row>
			</div>
            
          
	            
            <Fragment>
				<AddCollectionButton selectedUnits={selectedUnits} groupType={"properties"} clearSelectedUnits={this._clearSelectedUnits.bind(this)}  {...this.props} addMultiple />
	            <div className="material-table__wrap">
	              <Table className="material-table">
	                <MatTableHead
	                  numSelected={Object.keys(selectedUnits).length}
	                  order={order}
	                  orderBy={orderBy}
	                  onSelectAllClick={this._handleSelectAllClick.bind(this)}
	                  onRequestSort={this.handleRequestSort}
	                  rowCount={tableData.length}
	                />
	                
	                <TableBody>
	                  {table
	                      .map((d) => {
	                      const isSelected = this.isSelected(d.id);
	                      //If building is sold out override stats
/*
	                      if(d.offering_verified_status === 'sold out'){
		                      d.inventory_unsold = 0;
		                      d.inventory_pct_unsold = 0;
	                      }
	                      
*/
						  let inventory_pct_unsold = d.inventory_pct_bulk_unsold ? d.inventory_pct_bulk_unsold : d.inventory_pct_unsold;
						  let inventory_unsold = d.inventory_bulk_unsold ? d.inventory_bulk_unsold : d.inventory_unsold;
						  let offering_effective_year = d.offering_effective_date ?  parseInt(moment(d.offering_effective_date).format('YYYY')) : 0;
						  if(offering_effective_year <= 1970){
							  offering_effective_year = 0;
						  }
						  let current_year = new Date().getFullYear();
						  
						  let renovated_year = Math.max(d.year_converted, d.year_altered_1, d.year_altered_2);
	                      return (
	                        <TableRow
	                          className="material-table__row"
	                          role="checkbox"
	                          onClick={event => this.handleBuildingClick(event, d)}
	                          aria-checked={isSelected}
	                          tabIndex={-1}
	                          key={d.id}
	                          selected={isSelected}
	                        >
	                          <TableCell className="material-table__cell" padding="checkbox">
	                            <Checkbox checked={selectedUnits[d.key]===undefined ? false : true} onClick={(e)=>{e.preventDefault();e.stopPropagation(); this._selectUnit(d);}} className={`material-table__checkbox ${selectedUnits[d.key]!==undefined && 'material-table__checkbox--checked'}`} />	
	                           
	                            
	                          </TableCell>
	                          {!isMobile && <TableCell
								  className="material-table__cell material-table__cell-right"
								  size="small"                          
								>
									 <HouseCurrentStatus status={d.current_status} unit={d} />
									 {/*d.current_status === 'not_yet_recorded' && 
										 <div className="light-text xsmall-text no-wrap text-center" style={{marginTop:3}}>(Not Yet Recorded)
										 </div>*/}
								</TableCell> }
	                          <TableCell
	                            className="material-table__cell material-table__cell-right"
	                            component="th"
	                            scope="row"
	                            padding="none"
	                          >
	                            <span className={"building_name"}>{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
	                            	                            									  	
								  	{/* <BuildingIcons building={d} /> */}
								  	

	                            </span>
	                            <br/><span className="light-text small-text">{d.name && _capitalizeText(d.display_full_street_address)+", "} {d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
								<br/>
								<span className="light-text small-text" style={{color:"#858AA1"}}>{d.total_units > 1 ? "Multi-Family (" + _formatNumber(d.total_units) + ' Units)' : "Single-Family"}</span>
	                          </TableCell>
	                         <TableCell
								 className="material-table__cell"
								 align=""
							   >
							   <span className="light-text small-text">{d.sq_ft > 1 ? _formatNumber(d.sq_ft) : '--'} sf</span>
							   {d.building_frontage > 1 && <span className="light-text small-text"  style={{color:"#858AA1"}}><br/>Bldg Width: {_formatNumber(d.building_frontage)} sf</span>}
								   
							   </TableCell> 
	                                             
	                        
	                        
								<TableCell
									className="material-table__cell material-table__cell-right"
									size="small"                          
								  >
									  
									  <span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
									  
								  </TableCell>
								  <TableCell
									className="material-table__cell material-table__cell-right"
									size="small"                          
								  >
									  
									  <span className="light-text small-text">{d.baths > 0 ? d.baths+' Bath' : '--'}</span>
									  
								  </TableCell>
							  
								<TableCell
									className="material-table__cell material-table__cell-right"
									size="small" 
									align="center"
								  >
									  {  (d.last_listed_sale_price_all && d.last_listed_sale_price_all > 100) ?
										  <Fragment>
											  <span className="small-text">{_shouldShow(d.last_listed_sale_price_all) ? `$${_formatNumber(d.last_listed_sale_price_all)}` : "--"}</span>
											  <br/>
											  {d.last_listed_sale_date_all > 0 && <span className="light-text small-text" style={{color:"#858AA1"}}>{moment.unix(d.last_listed_sale_date_all).fromNow()}</span>}
										  </Fragment>
									  :
										  <Fragment>
											  <span className="light-text small-text">--</span>
										  </Fragment>
									  }
								  </TableCell>
								  <TableCell
									className="material-table__cell material-table__cell-right"
									size="small" 
									align="center"
								  >
								  
									{typeof d["last_listed_sale_ppsf_all"] === 'number' && d["last_listed_sale_ppsf_all"] > 100 ? 
										<span className="small-text">
													  ${_formatNumber(d["last_listed_sale_ppsf_all"])}
										  </span>
										  :
										  <span className="small-text">
											  --
										  </span>
									  }
								  </TableCell>
								   <TableCell
									className="material-table__cell material-table__cell-right"
									size="small"                          
								  >
									  {	d.last_listed_sale_date_all && d.last_listed_sale_date_all > 0 ?
										  <Fragment> 
										<span className="light-text small-text">{moment.unix(d.last_listed_sale_date_all).format('MM/DD/YYYY')}</span>
										
										</Fragment>	
										:
										  <span className="light-text small-text">--</span>
									  }
								  </TableCell> 	
							  <TableCell
								  className="material-table__cell material-table__cell-right"
								  size="small" 
								  align="center"
								>
									{  (d.last_sold_price && d.last_sold_price > 100) ?
										<Fragment>
											<span className="small-text">{_shouldShow(d.last_sold_price) ? `$${_formatNumber(d.last_sold_price)}` : "--"}</span>
											<br/>
											  {d.last_sold_date > 0 && <span className="light-text small-text" style={{color:"#858AA1"}}>{moment.unix(d.last_sold_date).fromNow()}</span>}
										</Fragment>
									:
										<Fragment>
											<span className="light-text small-text">--</span>
										</Fragment>
									}
								</TableCell>
								<TableCell
								  className="material-table__cell material-table__cell-right"
								  size="small" 
								  align="center"
								>
								
								  {typeof d["div(last_sold_price, sq_ft)"] === 'number' && d["div(last_sold_price, sq_ft)"] > 100 ? 
									  <span className="small-text">
													${_formatNumber(d["div(last_sold_price, sq_ft)"])}
										</span>
										:
										<span className="small-text">
											--
										</span>
									}
								</TableCell>
								 <TableCell
								  className="material-table__cell material-table__cell-right"
								  size="small"                          
								>
									{	d.current_status === 'in contract' ? 
										<Fragment>
										{parseInt(moment.unix(d.last_contract_date).format('YYYY')) > 1970 ? 
											<Fragment>
										  <span className="light-text small-text">{moment.unix(d.last_contract_date).format('MM/DD/YYYY')}</span>
											<span className="light-text xsmall-text no-wrap"><br/>(Contract Date)</span>
											</Fragment>	
									  :
										  <span className="light-text xsmall-text no-wrap">--</span>
									  }	
									  </Fragment> 
									               		
										: d.last_sold_date && d.last_sold_date >0 ?
										<Fragment> 
									  <span className="light-text small-text">{moment.unix(d.last_sold_date).format('MM/DD/YYYY')}</span>
									  {d.current_status === 'not_yet_recorded' && <span className="light-text xsmall-text no-wrap"><br/>(Not Yet Recorded)</span>}
									  </Fragment>	
									  :
										<span className="light-text small-text">--</span>
									}
								</TableCell> 
								<TableCell
								  className="material-table__cell"
								  align=""
								><span className="light-text small-text">{d.year_built > 1700 ? d.year_built : '--'} {d.year_built > current_year && '(Proj)'}</span>
								
								{renovated_year > 1700 && renovated_year > d.year_built ? 
									<Fragment>
									  <br/>
										<span className="light-text small-text" style={{color:"#858AA1"}}>{renovated_year} {renovated_year > current_year ? '(Proj Reno)' : '(Reno)'}</span>
									</Fragment>
								 : 
									   null
								}
								</TableCell> 
							    
								  <TableCell
									  className="material-table__cell"
									  align=""
									>
									<span className="light-text small-text">{d.lot_area > 1 ? _formatNumber(d.lot_area) : '--'} sf</span>
									{d.lot_frontage > 1 && <span className="light-text small-text"  style={{color:"#858AA1"}}><br/>Lot Width: {_formatNumber(d.lot_frontage)} sf</span>}
									
									</TableCell>                       
	                          
	                        </TableRow>
	                      );
	                    })}
	                  {emptyRows > 0 && (
	                    <TableRow style={{ height: 49 * emptyRows }}>
	                      <TableCell colSpan={6} />
	                    </TableRow>
	                  )}
	                </TableBody>
	              </Table>
	            </div>
	            {paginationCount ? <TablePagination
	              component="div"
	              className="material-table__pagination"
	              count={paginationCount}
	              rowsPerPage={rowsPerPage}
	              page={page}
	              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
	              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
	              onChangePage={this.handleChangePage}
	              onChangeRowsPerPage={this.handleChangeRowsPerPage}
	              rowsPerPageOptions={[25, 50, 100]}
	              dir="ltr"
	              SelectProps={{
	                inputProps: { 'aria-label': 'rows per page' },
	                native: true,
	              }}
	            /> : null}
	        </Fragment>
	        
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(MatTable));