import React, { useState, Fragment }  from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import ContactIcon from 'mdi-react/ForumOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/LockOpenIcon';
import UpgradeIcon from 'mdi-react/StarCircleOutlineIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import ViewedBuildings from '../../../containers/Dashboard/components/Summary/components/ViewedBuildings/index';
import TrialSubscribe from './TrialSubscribe';
import ChangePlan from './ChangePlan';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Publisher } from "./../../pubsub/publisher.js";
import { subscriptionPlans } from './plans.js';


export default function EnterpriseGate(props) { 
	const { img, copy, upgrade, user, nextPlan, hideIcon, overrideText, trialSubscribe } = props;
	const [ modal, setModal ] = useState(false);
	const [subscribe, setSubscribe ] = useState(false); 
	
	const nextPlanDetails = nextPlan ? subscriptionPlans.filter(subPlan => subPlan.id === nextPlan)[0] : false;
	
	if(!user) return null;
	
	if(overrideText && overrideText!=""){
		return (
			<Fragment>
				<a href="javascript:void(0)" className="link-text" onClick={(e) => {e.preventDefault(); e.stopPropagation(); setModal(true); Publisher.publish("track.upgrade", {source:"gate", plan:nextPlan});}} >{overrideText}</a>
				<ChangePlan modal={modal} setModal={setModal} changePlan={nextPlan} />
			</Fragment>
		)
	}

	return (
		  <Col md={12}>
		    <Card>
		      <CardBody style={{padding:"30px 0 0 0",width:"100%"}}>
		        <div className="email-confirmation" style={{minHeight:0}}>
		          {!hideIcon && <div className="email-confirmation__icon" style={{paddingTop:0}}>
		            {upgrade ? <UpgradeIcon  className="email-confirmation__mail" /> : <ContactIcon className="email-confirmation__mail" />}
		            <CheckboxMarkedCircleIcon className="email-confirmation__check" style={{top:0}} />
		          </div>}
		          <h3 className="email-confirmation__title">{copy ? copy : <span>Access to this section requires an upgrade to your subscription.<br/> Contact us to learn more.</span>}</h3>
		          <div className="email-confirmation__buttons"  style={{marginTop:30}}>
			          <ButtonToolbar>
			          	<div>
			          	{upgrade && (!user.subscriptionHierarchy || (user.subscriptionHierarchy && user.subscriptionHierarchy != "child")) ?
				          	 
				          	<Button
						         variant="contained"
						         color="primary"
						         className="buyersList__primary-button"	
						         style={{minWidth:200}}	   
						          onClick={(e) => {e.preventDefault(); e.stopPropagation(); setModal(true); Publisher.publish("track.upgrade", {source:"gate", plan:nextPlan});}}      
						     >	Upgrade {nextPlanDetails ? ' to ' + nextPlanDetails.name + ' Plan' : 'Now'}
						     </Button>			          	
				          	: trialSubscribe ?
				          		 <Fragment>
							    	<Button
								         variant="contained"
								         color="primary"
								         className="buyersList__primary-button"	
								         style={{minWidth:200}}	   
								          onClick={(e) => {e.preventDefault(); e.stopPropagation(); setSubscribe(!subscribe); Publisher.publish("track.activate", {source:"gate", plan:nextPlan});}}      
								     >	Activiate Trial
								     </Button>
							    	<TrialSubscribe subscribe={subscribe} setSubscribe={setSubscribe} />
							    </Fragment>
				          	
				          	:
				          	<Fragment>
					          	<Button
							         variant="contained"
							         color="primary"
							         className="buyersList__primary-button intercom_launch"	
							         style={{width:200}}	         
							     >	Contact Us
							     </Button>
						     </Fragment>	
				          	
				          	
			          	}	
			          		<div className="mt-2"><Link to="/pricing" className="link-text">See All Plans</Link></div>
			          	</div>
			          				   		 
			          </ButtonToolbar>
					  
					 
		           </div>
		        </div>
		        {img &&
		        	<div><img src={img} style={{width:"100%"}}/></div>
		        }
		      </CardBody>
		    </Card>
		    <ChangePlan modal={modal} setModal={setModal} changePlan={nextPlan} />
		  </Col>
    )
};


