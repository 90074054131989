import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';

import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
	buildingFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	UnitCurrentStatus,
	_nameToUrl
} from "../../../../../shared/helpers/utils";


const buildingUrl = process.env.API_ROOT + '/query/';


class MyUnits extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      savedUnits: null,
	      totalCount: null
	    };
	}
	
	componentWillReceiveProps(nextProps) {
	  this._getBuildings(nextProps);

    }

	componentDidMount() {
		this._getBuildings(this.props);
		
	  	
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
    }
	_getBuildings = async (props) => {
		
		const savedUnits = props.savedUnits.filter(item => !item.deleted);
		const _propertyIDs = savedUnits.map(item => item.key).join(' ');
		
		if(!props.dataSet || this.state.fetchInProgress){
			return false;
		}
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		var q = "*:*";
		
		
		const defaultFq = [
		    'deleted:false',
		    "key:("+_propertyIDs+")"
		  
		  ];
				 
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=60",
			method: "post",
			query: {
				q: q,
				fq: defaultFq,
				sort: null,
				fl: null,
				wt: "json",
				rows: 4,
				start: 0				
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});

		let formattedData = response && response.docs
			? response.docs.slice(0)
			: [];
		
		let docs = formattedData.map((doc, i) => {
			let item = Object.assign({}, doc);			
			return item;
		});
		
		this.setState({ savedUnits : docs, totalCount: savedUnits.length, fetchInProgress : false });
  };
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  render(){
	const { savedUnits, totalCount } = this.state;
	
	if(savedUnits === null){
		
		 return (
		 <Panel lg={5} title={'My Units'} subhead={`Units Added to My Dashboard`}>		 
		 </Panel>
		 );
	}
		
	if(savedUnits.length <= 0){
		
		 return (
		 <Panel lg={5} title={'My Units'} subhead={`Units Added to My Dashboard`}>		 
		 	<EmptyBuyersList small={true} savedUnits />
		 </Panel>
		 );
	}
	return (
	  <Panel lg={5} 
	  	title={`My Units (${totalCount})`}
	  	subhead={`Units Added to My Dashboard`}
	  	>
	    <Table responsive className="table--bordered">
	      <thead>
	        <tr>
	          <th>Unit</th>
	          <th>Status</th>
	        </tr>
	      </thead>
	      <tbody>
	      {savedUnits.map((item, i) => {
		      	const buildingNameOrAdr = item.name 
			      ? _capitalizeText(item.name)
			      : item.display_full_street_address
			        ? _capitalizeText(item.display_full_street_address)
			        : _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building_slugs') + '/' + _nameToUrl(item.address_2_normalized);
		      return (
			      
				      <tr key={i}>     				       	
						<td>
							<Link to={unitURL}>
								{buildingNameOrAdr}, {item.address_2_normalized.toUpperCase().replace("APT", "Apt")}
								<br/><span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}</span>
								<br/>
								
								 {(item.offering_verified_status === 'active' || item.offering_baths > 0 ) ?
										<Fragment>
											<span className="light-text small-text">{item.offering_bedrooms > -1 ? item.offering_bedrooms === 0 ? 'Studio' : item.offering_bedrooms+' Bed' : '--'}</span>
											{` | `}<span className="light-text small-text">{item.offering_baths > -1 ? item.offering_baths+' Bath' : '--'}</span>
											
										</Fragment>
								  : item.baths > 0 ?
								  		<Fragment>
											<span className="light-text small-text">{item.bedrooms > -1 ? item.bedrooms === 0 ? 'Studio' : item.bedrooms+' Bed' : '--'}</span>
											{` | `}<span className="light-text small-text">{item.baths > -1 ? item.baths+' Bath' : '--'}</span>
											
										</Fragment>
								  : null
								}
								{(item.offering_verified_status === 'sold' || item.offering_verified_status === 'resale') && item.last_sold_price > 0 ?
									<Fragment>
									{` - `}<span className="light-text small-text">{`$${_formatNumber(item.last_sold_price)}`}</span>
									</Fragment>
								 : item.last_listed_sale_price_all > 0 ?
								 	<Fragment>
									{` - `}<span className="light-text small-text">{`$${_formatNumber(item.last_listed_sale_price_all)}`}</span>
									</Fragment>
								 : (item.price > 0 || item.offering_price >0 ) ?
								 	<Fragment>
									{` - `}<span className="light-text small-text">{_shouldShow(item.offering_price) ? `$${_formatNumber(item.offering_price)}` : "--"}</span>
									</Fragment>
								  : null
								}
							</Link> 
						</td>   
						<td>
							<Link to={unitURL}>
								<UnitCurrentStatus unit={item} />
							</Link> 
						</td> 
						    
			          </tr>
			      
			    )
		      
	      })}
	        
	       
	      </tbody>
	    </Table>
	    <hr/>
	    <div className="unit__load text-right semi-bold-text">			   		
		    <Tooltip title="See All My Units">
		        <Link to="/my-units">See All &raquo;</Link>			 
		  	</Tooltip>				
	    </div>
	  </Panel>
    )
   }
};

MyUnits.propTypes = {
  t: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, 
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],  
	  		dataSet : state.buyersLists.dataSet };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MyUnits));
