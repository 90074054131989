import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TimelineHistory from './components/TimelineHistory';

const Timeline = (props) => (
 
      <TimelineHistory {...props} />

);


export default Timeline;
