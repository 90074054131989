import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";
import ToolTip from '../../../../../shared/components/ToolTip';

const PriceRangeSold = (props) => {
	if(!props) return null;

	return (

			  <Col md={12} xl={4} lg={6} xs={12}>
			    <Card>
			    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
			      <CardBody className="dashboard__booking-card card__link">
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            {props.transactionRes && props.transactionRes.sold_range && props.transactionRes.sold_range.min_price ? 
				              <span>{_formatPrice(props.transactionRes.sold_range.min_price)} - {_formatPrice(props.transactionRes.sold_range.max_price)}</span> 
				        
				            : <span>--</span>}
			          </h5>
			        {/*  <TrendingUpIcon className="dashboard__trend-icon" /> */}
			        </div>
			        <h5 className="dashboard__booking-total-description">Sold Price Range
						<ToolTip title={"Sold price range of ACRIS verified closed units during the selected timespan."} />
					</h5>
			        
			      </CardBody>
			    </Link>
			    </Card>
			  </Col>
	  )

};

export default PriceRangeSold;
