import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Button, ButtonToolbar, Modal, Col, Container, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';

import ChangePlan from './../payment/ChangePlan';
import { subscriptionPlans } from './../payment/plans.js';


import searchActions from '../../../redux/actions/searchActions';
import OfferingStatus from '../../../shared/components/OfferingStatus';
import SearchMapPolygon from '../../../shared/components/map/SearchMapPolygon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import algoliasearch from 'algoliasearch/lite';

import SearchTags from './SearchTags';
import {   
  connectStateResults,
  connectHits,
  InstantSearch,
  Hits,
  Configure,
  SearchBox,
  Index } from 'react-instantsearch-dom';
import 'instantsearch.css/themes/reset.css';
import ASearch from "./ASearch";
import {
  _isEqual,
  _sortDataObj,
  _validArrTags,
  _validStrTags,
  _nameToUrl,
  _urlToName,
  _formatAddressAutoComplete,
  _capitalizeText,
  _shouldShow,
  _checkBuildingNameVsAdr,
  _replaceAbbreviations,
  _getBuildingURL,
  _selectBestSlug,
  _relatedHoods,
  BuildingStageBadge,
} from '../../../shared/helpers/utils';
import { 
	_hasPermission
} from "../../../shared/helpers/access";
const searchClient = algoliasearch('0X3WLXN3NU', 'a1e5007fe4f76f333a047b8caaaa4941');



const AllResults = connectStateResults(
  ({
    allSearchResults,
    children,
  }) => {
    const noResults =
      allSearchResults &&
      Object.values(allSearchResults).every(
        results => results.nbHits === 0
      );

    return noResults ? (
      <div>
        
      </div>
    ) : (
      children
    );
  }
);

const IndexResults = connectStateResults(
  ({ searchState, searchResults, children, refresh }) => {
    if (refresh && searchState.query) {
      searchState.query = "";
    }

    return searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="" />
    );
  }
);
const BoroughHits = connectHits(
  ({ hits, boroTags, boroTagClick, hoodTags }) => (
	<Fragment>
	 {hits && hits.length>0 &&
	    <div className="c_search-results">
	      <h5 className="">BOROUGHS</h5>
	      {hits.map(hit => {
	        // const urlTopic = topicTags.length === 1 ? topicInfo[topicTags[0]].urlHash : '';
	
	        return (
	          <div className="c_result" key={hit.objectID}>
	            <div
	              onClick={boroTagClick.bind(this, hit.name.toLowerCase())}
	              id="tags"
	              className="c_search-hit"
	            >
	              <span>{hit.name}</span>
	            </div>
	          </div>
	        );
	      })}
	    </div>
    }
    </Fragment>
  )
);


//hoods and boros are data type STRINGS for hood Index
const HoodHits = connectHits(
  ({ hits, hoodTagClick, hoodTags, boroTags, query }) => (
    <Fragment>
     {hits && hits.length>0 &&
	    <div className={`c_search-results ${query === "" ? "hidden" : ""}`}>
	      <h5 className="">NEIGHBORHOODS</h5>
	      {hits.map(hit => {
	        // const urlTopic = topicTags.length === 1 ? topicInfo[topicTags[0]].urlHash : '';
	        const boro = _validStrTags(hit.borough);
	        const hood = _validStrTags(hit.neighborhood);
	        // console.log(hit);
	        let urlBoro = boro ? _nameToUrl(boro) : "new-york";
	        let urlHood = hood ? hood : "nyc";
	        // takes out all hoods that we don't want/have in our uiValidation list
	        // if(!hoodsWithBoros[hood]){
	        //   return;
	        // }
	
	        return (
	          <div className="c_result" key={hit.objectID}>
	            <div
	              onClick={hoodTagClick.bind(this, {
	                hood: _urlToName(urlHood.toString()),
	                boro: _urlToName(urlBoro.toString())
	              })}
	              id="tags"
	              className="c_search-hit"
	            >
	              <span>
	                {hit.neighborhood_display
	                  ? hit.neighborhood_display
	                  : hit.neighborhood}
	                , {hit.borough}
	              </span>
	            </div>
	          </div>
	        );
	      })}
	    </div>
	}
	</Fragment>
  )
);

const BuildingHits = connectHits(({ hits, onSelect, query, tabInfo, addBuilding, hideAddIcon, alwaysAddTag }) => (
 <Fragment>
 {hits && hits.length>0 &&
   <div className={`c_search-results ${query === "" ? "hidden" : ""}`}>
    <h5 className="">BUILDINGS</h5>
    {hits
      .filter(hit => hit.display_full_street_address)
      .map(hit => {
        hit.primary_adr = true;
        hit = _formatAddressAutoComplete(hit, query);
        hit = _selectBestSlug(hit);
        const boro = _validStrTags(hit.borough);
        const hood = _validArrTags(hit.neighborhood);
        const urlBoro = boro ? _nameToUrl(boro) : "new-york";
        const urlHood = hood ? _nameToUrl(hood) : "nyc";
        const buildingName = _checkBuildingNameVsAdr(hit);
        const address = hood ? hood + ", " + boro : boro;

        return (
          <div className="c_result" key={hit.objectID}>
            <div
              onClick={onSelect.bind(
                this,
                {
                  building: {
                    slug: hit.building_slugs[0],
                    display_adr: hit.display_full_street_address,
                    hood: _urlToName(urlHood),
                    boro: _urlToName(urlBoro)
                  },
                  // tab: Object.assign(tabInfo.building.tabs[0], {
                  //   data: false,
                  //   type: "building"
                  // })
                },
                hit,
                alwaysAddTag
              )}
              id="tags"
              className="c_search-hit"
            >
              {/*<Highlight attributeName="display_text" hit={hit} tagName="strong" /> */}
                {!addBuilding && !hideAddIcon && <span className={'c_blg-add'}>
                	<Tooltip title="Add this building to search">
                      	<IconButton
				            onClick={(e) => {e.stopPropagation();e.preventDefault();onSelect(				                
				                {
				                  building: {
				                    slug: hit.building_slugs[0],
				                    display_adr: hit.display_full_street_address,
				                    hood: _urlToName(urlHood),
				                    boro: _urlToName(urlBoro)
				                  },
				                  // tab: Object.assign(tabInfo.building.tabs[0], {
				                  //   data: false,
				                  //   type: "building"
				                  // })
				                },
				                hit,
				                true
				              )}}

				            className=""
				            size={'small'}
				            style={{background:"transparent"}} 
				          >
						  <AddIcon size={20} style={{ marginRight:"3px"}} color={'#780F9E'}  />
					  	</IconButton>
				  	</Tooltip>	
				</span>
				}
				<span className={`c_bldg_container`}>
	                <span className={`c_bldg`}>
	                {hit.building_display
	                  ? _capitalizeText(hit.building_display)
	                  : _capitalizeText(
	                      _replaceAbbreviations(hit.display_full_street_address)
	                    )}
	                </span>
	                <span className={`c_name`}>
	                	{_shouldShow(buildingName)
	                  ? " (" + _capitalizeText(buildingName) + ")"
	                  : ""}
	                </span>
	                 {!isMobile && <OfferingStatus building={hit} /> }
	                 {!isMobile && hit.building_status && <BuildingStageBadge item={hit} small /> }
	              
	                <span className="c_search-hit-details">{address}</span>
	            </span>
            </div>
          </div>
        );
      })}
  </div>
  }
  </Fragment>
));

const AttributeHit = connectStateResults(
  ({ searchResults, onSelect, query, attribute, title, doCaps, hideInitial }) => {

	const data = searchResults && searchResults.facets && searchResults.facets[0] && Object.keys(searchResults.facets[0].data).map(key => {
				 		if(key.toLowerCase().indexOf(query.toLowerCase())<0) return null;
						return key;
				 }).filter(item => item !== null);
	  
	if(!data || data.length <=0 ) return null;
	return(  
	    <div className={`c_search-results ${hideInitial && query === "" ? "hidden" : ""}`}>
	      <h5 className="">{title}</h5>
	      {data.map(item => {
	        return (
	          <div className="c_result">
	            <div
	              id="tags"
	              className="c_search-hit"
	               onClick={onSelect.bind(
	                this,
	                item,
	                attribute
	              )}
	            >
	              <span>{doCaps ? _capitalizeText(item) : item}</span>
	            </div>
	          </div>
	        );
	      })}
	    </div>
	); 
});


class Search extends Component {
	
	
  constructor(props) {
	super(props);
	this.state = {
      acTop: "50px",
      displayFullIndices: false,
      modalIsOpen: false,
      pTop: "",
      refresh: false,
      searchClicked: false,
      searchOpen: false,
      searchQuery: "",
      secondL: "",
      showMoreTags: false,
      width: "28px",
      updatePlaceholder: false,
      searchTags:[],
      modal: false,
      nextPlan: false,
      modalMessage: "Access to requires an upgrade to your current subscription",
      exitModalOpen: false,
      exitLink : "",
      selectedHit : false
    }; 
    
    this.onSearchStateChange = this.onSearchStateChange.bind(this);
    this._handleTags = this._handleTags.bind(this);
    this._handleHoodTags = this._handleHoodTags.bind(this);
    this._handleBoroTags = this._handleBoroTags.bind(this);
    this._handleBuildingTags = this._handleBuildingTags.bind(this);
    this._searchClicked = this._searchClicked.bind(this);
    this._getActiveEl = this._getActiveEl.bind(this);
    this._handleAttrTags = this._handleAttrTags.bind(this);
    this._setModal = this._setModal.bind(this);
    this._setExitModal = this._setExitModal.bind(this);
  }
  
  componentDidMount() {
    // console.log('AlgoSB didMount calling _move');
    const { clearAttributes } = this.props.actions;
    clearAttributes();
    window.addEventListener("click", this._getActiveEl);
    
  }

  componentWillUnmount() {
    window.removeEventListener("click", this._getActiveEl);
  }

  _getActiveEl() {
	  
    const alInputEl = document.getElementById("tag-input");
    const alTagsContainerEl = document.getElementById("tags");
    const expandSearch = document.getElementById("expand-search");
    let isFocused =
      document.activeElement === alInputEl ||
      document.activeElement === alTagsContainerEl ||
      document.activeElement === expandSearch;
    this.setState({
      searchOpen: isFocused,
      searchClicked: isFocused,
      showMoreTags: isFocused
    });
  }

  onSearchStateChange(searchState) {
    //searchState (algolia controlled obj that keeps track of all widget states)
    const oldQuery = searchState.query;
    //takes the - out of user types inputs before sending it to algolia
    const validQuery = searchState.query
      ? searchState.query.replace("-", "")
      : "";
    //updates aloglia obj sending the cleaned text to the search engine
    searchState.query = validQuery;

    //keeps the uncleaned text to show the user in the input
    this.setState({
      searchQuery: oldQuery,
      refresh: false
    });
  }

  
  
  _searchClicked(e) {
    
    if (e.target.tagName === "I") {
      return document.getElementById("tag-input").blur();
    }

    this.setState({
      searchClicked: true
    });
  }
  
   _closeAutoSearch() {
    this.setState({
      searchClicked: false,
      searchQuery: "",
      refresh: true
    });
    //blur focus here
  }
  
   _handleTags(obj) {
    this._closeAutoSearch();
    return this.props.tagSelection(obj);
  }

  //pass hood tags
  _handleHoodTags(obj) {
	const { addTag } = this.props.actions;
    this._closeAutoSearch();
    
    //return this.props.hoodTagClick(obj);
    //const newTags = this.state.searchTags.slice(0);    
    const id = _nameToUrl(obj.hood + '_' + obj.boro);
    if(_relatedHoods[id] && _relatedHoods[id].length>0){
	    _relatedHoods[id].map(hood =>{
		    addTag(hood);
	    })
    }else{
      console.log({type:'neighborhood', data: obj, id: id, name: obj.hood}, "polyArea12")
	   addTag({type:'neighborhood', data: obj, id: id, name: obj.hood}); 
    }
    //return this.props.handleSearch('neighborhood', obj);
  }
  
  
  _setModal(modal) {
    this.setState({modal: modal})
  }
  _setExitModal(modal){
    this.setState({exitModalOpen: modal})
  }

  //pass building tags
  _handleBuildingTags(obj, hit, doAdd) {
	  
	const { buildingTab, addBuilding, user } = this.props;
	const { addTag } = this.props.actions;
  
  if((hit.ownership_type.indexOf("condo")<0 && hit.ownership_type.indexOf("coop")<0 && hit.ownership_type.indexOf("co-op")<0) && ["sponsor", "pipeline"].indexOf(hit.building_status)<0 && hit.ownership_type.indexOf("family")<0 && !(hit.ownership_type.indexOf("rental")>-1 && hit.total_units<=3)){
    
    this.setState({exitModalOpen: true, exitLink: `https://marketproof.com/${_getBuildingURL(hit, 'building_slugs').replace('buildings', 'building')}`, selectedHit: hit});
   // window.open(`https://marketproof.com/${_getBuildingURL(hit, 'building_slugs').replace('buildings', 'building')}`);
    return false;
  }
	
	// if(hit.building_status === "resale" && !_hasPermission(user, "resale-buildings")){
	// 			  
	// 	  this.setState({modal: true, modalMessage: <span>Access to resale data with over 20 years of history requires an upgrade to your current plan.</span>, nextPlan: false});
	// 	  return false;
  //   }else if(hit.building_status === "pipeline" && !_hasPermission(user, "pipeline-buildings")){
	//       this.setState({modal: true, modalMessage: <span>Access to pipeline buildings and inventory requires  an upgrade to your current plan.</span>, nextPlan: false});
	// 	  return false;
  // 	}
	  
    this._closeAutoSearch();
    
    if(addBuilding || doAdd){
	    console.log(obj, hit);
	    const id = _nameToUrl(hit.key);
	    addTag({type:'building', data: hit, id: id, name: hit.name ?_capitalizeText(hit.name) : _capitalizeText(hit.display_full_street_address) });	   
    }else{
	    let buildingURL = _getBuildingURL(hit, 'building_slugs');
	    if(buildingTab){
		    buildingURL += '/tab/'+buildingTab;
	    }    
		this.props.history.push(buildingURL);
    }
    


    if (!hit.primary_adr) {
      // if((hit.building_display.indexOf('(') > 0)) {
      //  //takes building name out of msg if present
      //  hit.building_display =  hit.building_display.slice(0, hit.building_display.indexOf('('));
      // }
      // console.log(hit.building_display)
/*

      const toastMsg = (
        <div>
          <span style={{ fontWeight: 500 }}>{hit.building_display}</span> is an
          alternate address for{" "}
          <span style={{ fontWeight: 500 }}>{hit.original_display_text}</span>.
        </div>
      );
      toast.info(toastMsg, { autoClose: 3000 });
*/
    }

    //return this.props.handleSearch(obj);
  }

  //pass borough tags
  _handleBoroTags(obj) {
	const { addTag } = this.props.actions;
    this._closeAutoSearch();
    //const newTags = this.state.searchTags.slice(0);    
    //newTags.push({type:'borough', data: obj, id: obj, name: obj});   
    //this.setState( { searchTags : newTags } )    
    
    const id = _nameToUrl(obj);
    addTag({type:'borough', data: obj, id: id, name: obj});
    //return this.props.handleSearch('borough', obj);
  }
  
  _handleAttrTags(item, attribute) {
	const { addTag } = this.props.actions;
    this._closeAutoSearch();
    //const newTags = this.state.searchTags.slice(0);    
    //newTags.push({type:'borough', data: obj, id: obj, name: obj});   
    //this.setState( { searchTags : newTags } )    
    
    const id = _nameToUrl(item);
    addTag({type:attribute, data: item, id: id, name: item});
    //return this.props.handleSearch('borough', obj);
  }
  
  render(){
	const { buildingFilter, buildingAttribute, buildingAttributeTitle, placeHolder, doCaps, hideBuilding, buildingAttributes, hideInitial, addBuilding, hideBoroHood, hideAddIcon, alwaysAddTag, hideMapDraw, searchTags } = this.props;
	const {  modal, nextPlan, modalMessage, exitModalOpen, exitLink, selectedHit } = this.state;
	
	const placeHolderText = placeHolder ? placeHolder : "Neighborhood, Borough or Building";
	
	let filters = "";// buildingFilter ?  buildingFilter : '(offering_verified_status:"active" OR offering_verified_status:"coming soon" OR offering_verified_status:"offering plan required" OR  offering_verified_status:"condo declaration vetted") AND (NOT offering_accepted_date:1 TO 1199145600)';
	const hasAreaSearch = searchTags.filter(tag => tag.type == "area").length > 0;
	return (
		<Fragment>
			<InstantSearch 
				searchClient={searchClient} 
				indexName="we3-buildings-search-3"
				refresh={this.state.refresh}
				onSearchStateChange={this.onSearchStateChange}			
				>
			    <ASearch
		          value={this.state.searchQuery}
		          onIcon={null}
		          onFocus={null}
		          height={this.state.height}
		          isDesktop={this.props.isDesktop}
		          isListing={this.props.isListing}
		          isMobile={this.props.isMobile}
		          pTop={this.state.pTop}
		          placeholder={isMobile ? "Search.." : placeHolderText}
		          openAConClick={this._searchClicked.bind(this)}
		          resetTags={this._resetTags}
		          showMoreTags={this.state.showMoreTags}
              hideMapDraw={hideMapDraw}
              // hasAreaSearch={hasAreaSearch}
		        />

            {/* commented hasAreaSerach to add tags also  */}
            {/* {!hasAreaSearch &&   */}

          {
			    <div
		          className={`c_search-result-container ${isMobile || isTablet ? 'c_mobile' : ''} ${
		            this.state.searchOpen || this.state.searchClicked
		              ? "s_active-show"
		              : "hidden"
		          }`}
		          id="c_search-result-container"
		         
		        >
		        
		          <SearchTags />
		          <div>
		          
		          		{buildingAttribute && <Index indexName="we3-buildings-search-3" indexId="building-attribute">
			                <Configure hitsPerPage={0} filters={filters} restrictSearchableAttributes={[buildingAttribute]} facets={[buildingAttribute]} maxValuesPerFacet={3} />
							<AttributeHit
			                  attribute={buildingAttribute}
			                  title={buildingAttributeTitle ? buildingAttributeTitle : 'RESULTS'}
			                  query={this.state.searchQuery}
			                  onSelect={this._handleAttrTags}
			                  doCaps
			                  hideInitial
			                />
			            </Index>}
			            
			            {buildingAttributes && buildingAttributes.length>0 &&
				            <Fragment>		            
				            {buildingAttributes.map(item => {
					            return (
						            <Index indexName="we3-buildings-search-3" indexId={`building-${item.buildingAttribute}`}>
						                <Configure hitsPerPage={0} filters={filters} restrictSearchableAttributes={[item.buildingAttribute]} facets={[item.buildingAttribute]} maxValuesPerFacet={3} />
										<AttributeHit
						                  attribute={item.buildingAttribute}
						                  title={item.buildingAttributeTitle ? item.buildingAttributeTitle : 'RESULTS'}
						                  query={this.state.searchQuery}
						                  onSelect={this._handleAttrTags}
						                  doCaps
						                  hideInitial
						                />
						            </Index>			            
					            )
				            })}
				            </Fragment>			            
			            }
			            
		          		{!hideBuilding && <Index indexName="we3-buildings-search-3" indexId="building">
			                <Configure hitsPerPage={3} filters={filters} />
							<BuildingHits
			                  onSelect={this._handleBuildingTags}
			                  query={this.state.searchQuery}
			                  tabInfo={this.props.tabInfo}
			                  addBuilding={addBuilding}
                        hideAddIcon={hideAddIcon}
                              alwaysAddTag={alwaysAddTag}
			                />
			            </Index>}
				        
			           {!hideBoroHood && <Index indexName="we3-neighborhoods">
			                <Configure hitsPerPage={3} />
			                <HoodHits
			                  hoodTagClick={this._handleHoodTags}
			                  hoodTags={this.props.hoodTags}
			                  boroTags={this.props.boroTags}
			                  query={this.state.searchQuery}
			                />
			            </Index>}
			            {!hideBoroHood && <Index indexName="we3-boroughs">
			                <Configure hitsPerPage={5} />
			                <BoroughHits
			                  boroTagClick={this._handleBoroTags}
			                  boroTags={this.props.boroTags}
			                  hoodTags={this.props.hoodTags}
			                />
			            </Index>}
					    
			        </div>
			    
	            </div>
            }
			</InstantSearch>
            {/* <SearchMapPolygon standardView /> */}
			<ChangePlan modal={modal} setModal={this._setModal} changePlan={nextPlan} message={modalMessage} />
      <Modal
        isOpen={exitModalOpen}
        toggle={() => this._setExitModal(false)}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary contact__modal share__modal `}
        
      >
        
         

          <div className="modal__body" >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => this._setExitModal(false)} />
              <h4 className="contact__title mb-3">Leaving Marketproof Pro</h4>
            </div>
            <div style={{fontSize:16,background:"#F5F7FA",padding:10,border:"1px solid #780F9E"}}>
            <span>Information about {selectedHit && selectedHit.display_text ? selectedHit.display_text : "this building"} is available on our public website, where you can find comprehensive details about its units, history, availabilities, and much more.</span>
            </div>	

             <hr />
            <ButtonToolbar className="modal__footer">
              <Button className="modal_cancel" onClick={() => this._setExitModal(false)}>Cancel</Button>{' '}
            
              <Button
               variant="contained"
               color="primary"
               className="buyersList__primary-button intercom_launch"	
               onClick={()=>{window.open(exitLink);this._setExitModal(false)}}       
             >	Continue
            </Button>
            </ButtonToolbar>
          </div>
          
      </Modal>
		</Fragment>
	
  )
  }
  
};

function mapStateToProps(state) {
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
