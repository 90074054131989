import React, { Component } from 'react';
import { Redirect, withRouter, Link, Route, useLocation } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import OktaAuth from '@okta/okta-auth-js';

import pkceChallenge from 'pkce-challenge';
import WithTracker from './../../../../containers/App/WithTracker';

import FreeAccount from './../../payment/PricingCards/components/FreeAccount';

import TopBar from './../../TopBar'
import Footer from './../../Footer'
import { _axiosCall } from '../../../helpers/apicalls';
import axios from "axios";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Collapse
} from 'reactstrap';
import{
	setLocalStorage,
	getLocalStorage
} from '../../../helpers/utils';

import{
	_getUserSSO,
	_setSSOToken,
	_getSSOToken
} from './ssoutils';


export default withRouter(class Login extends Component {
  constructor(props) {
	super(props);
	this.onSuccess = this.onSuccess.bind(this);
	this.onError = this.onError.bind(this);
	this.state = {
	  authenticated: null,
	  fetchInProgress: false,
	  authToken: false,
	  error: false,
	  errorMessage:false
	};

  }
  
  componentDidMount() {
	   if(window.location.href.indexOf('dashboard')>=0){
		   const codes = pkceChallenge();
		   sessionStorage.setItem('code_challenge', codes.code_challenge);
		   sessionStorage.setItem('code_verifier', codes.code_verifier);
		   //const REALPLUS_AUTH_URL = `${process.env.REALPLUS_BASE_URL}authorize?client_id=${process.env.REALPLUS_CLIENT_ID}&code_challenge=${codes.code_challenge}&code_challenge_method=S256&redirect_uri=${process.env.REALPLUS_REDIRECT_URI}&response_mode=query&response_type=code&state=testing&scope=openid`;
		   
		   //https://auth.berkshire.realplusonline.com/openid/authorize?client_id=Marketproof.Berkshire.OpenId&redirect_uri=https%3A%2F%2Fnewdev.marketproof.com%2Fsso%2Frealplus%2Fberkshire&response_type=code&scope=openid%20&state=58df5ac782ff4b509e9d670e037a887a&code_challenge=Mm1xvzaqSZpeoTbpJMS6uiG1MOkLqa29AoVomxvUbeQ&code_challenge_method=S256&response_mode=query
		   
		  const REALPLUS_AUTH_URL = `${process.env.REALPLUS_BASE_URL}authorize?client_id=${process.env.REALPLUS_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.REALPLUS_REDIRECT_URI)}&response_type=code&scope=openid%20&state=58df5ac782ff4b509e9d670e037a887a&code_challenge=${codes.code_challenge}&code_challenge_method=S256&response_mode=query`;
		  
		  https://auth.berkshire.realplusonline.com/openid/authorize?client_id=Marketproof.Berkshire.OpenId&redirect_uri=https://newdev.marketproof.com/sso/realplus&response_type=code&scope=openid%20&state=58df5ac782ff4b509e9d670e037a887a&code_challenge=Qzk231SSrsZFhrEN_03CHgDaBTjuh5r_E23PF7RTXR8&code_challenge_method=S256&response_mode=query
		  
		   console.log(REALPLUS_AUTH_URL);
		   window.location.href = REALPLUS_AUTH_URL;
	   }else if(window.location.href.indexOf('sso')>=0){   
		   const params = new URLSearchParams(window.location.search);
		   console.log(params)
		   // Get a single query parameter
		   console.log(params.get('code'))
		   const hashObject = {code : params.get('code')};
		  
		   if(hashObject['error']){
			   this.setState({error:hashObject['error'], errorMessage: hashObject['error_description'] ? decodeURI(hashObject['error_description'].replace(/\+/g, " ")) : "Authorization failed, please contact your support team."})
		   }else{
			   this._getAuthToken(hashObject)
		   }
		   
		   
	   }else{
		  var hash = window.location.search.replace("?", "");
			  
		  var hashObject = hash.split('&').reduce(function (res, item) {
			  var parts = item.split('=');
			  res[parts[0]] = parts[1];
			  return res;
		  }, {});
		  console.log(hashObject);
		  if(hashObject['error']){
			  this.setState({error:hashObject['error'], errorMessage: hashObject['error_description'] ? decodeURI(hashObject['error_description'].replace(/\+/g, " ")) : "Authorization failed, please contact your support team."})
		  }
	   }
  }
  
  _getAuthToken = (hashObject) => {
	   // console.log('_getBuildingData called', this.state)
	   if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const params = new URLSearchParams();
		params.append('client_id', process.env.REALPLUS_CLIENT_ID);
		params.append('redirect_uri', process.env.REALPLUS_REDIRECT_URI);
		params.append('grant_type', "authorization_code");
		params.append('code', hashObject.code);
		params.append('code_verifier', sessionStorage.getItem('code_verifier'));
		axios.post(`${process.env.REALPLUS_BASE_URL}token`, params).then(token => {
			console.log(token.data.id_token);
			
			if(token.data && token.data.id_token){
				_setSSOToken(token.data, "REALPLUS");
				this.setState({authToken : token.data})
				
			}
			
			console.log(this._oktaAuth)
				
		})
		.catch(error => {
			console.log("error: " + error);
		});
		
	
	}

 
  onSuccess(res) {
	if (res.status === 'SUCCESS') {
	  return this.props.authService.redirect({
		sessionToken: res.session.token
	  });
   } else {
	
	}
  }

  onError(err) {
	console.log('error logging in', err);
  }

  render() {
	const { location, match } = this.props;
	const { authToken, error, errorMessage } = this.state;
	  
	
	if(authToken){
		return <Redirect to={{ pathname: `/dashboard`}} />
	}
	if(window.location.href.indexOf('sso')>=0 && !error) return null;
	const isError = error;
	return (
		<div className="plan__wrapper share__wrapper">
			<TopBar goTo={'plans'} />
			<Row className="login-container login">
				<Col lg={12} className="pt-0 signin">
				<div className={`widget-container login realplus ${match.params['customer']}`}>
				<div className={"sso-logo-container"}>
				  <div className={"sso-logo"} />
				</div>
				<h4 className="text-center mt-5 mb-2">
					RealPlus User Login
				</h4>
				
				<div className="sub-text">For RealPlus users only, you will be redirected to sign in.</div>
				
				<Link className="mt-5 button" to={`/dashboard/realplus/${match.params['customer']}`}>
					Sign In
				</Link>
				{isError &&
					<div className="sub-text mt-3" style={{color:"#F53246"}}>{errorMessage}</div>
				}
				<div className={'signin-toggle mt-5 '}>{`Not a RealPlus user?`} <Link to={`/pricing`}>Sign Up</Link> or <Link to={`/login`}>Sign In</Link></div>
					  
				<p className="disclaimer">By signing in, you agree to our{` `}<a href="https://marketproof.com/terms-of-use" target="_blank">Terms of Service</a>{` `}and 
				{` `}<a href="https://marketproof.com/privacy-policy" target="_blank">Privacy Policy</a>.</p> 
				</div>
				  
					
				</Col>
			</Row>
			<Footer />
		</div>
	)
	
	  

		
  }
});