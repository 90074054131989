import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import TrialAlert from '../../shared/components/payment/TrialAlert';


class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user } = this.props;
	    if(!user) return null;

		return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Market Activity</h3>
		        <h3 className="page-subhead subhead">Listing, Contract and Sales Events in Active New Development Projects</h3>
		      </Col>
		    </Row>
		    <Row>
		      <Col md={12} className={'mb-3'}>
		       	<TrialAlert />        
		      </Col>
		    </Row>
		    <Row>
		    	<Col md={12} className={'mb-3'}>
					<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
			          <p>
			          	<span className="bold-text">{`About The Data`}</span> 
			            <div>{`The information provided herein is provisional and subject to change as 200+ more buildings are vetted and added.`}</div>
			          </p>
			        </Alert>
				</Col>
		    </Row>
		    
		    <Row>
		      <UnitsSearchTable />
		    </Row>
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



