import React, { Fragment } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import searchActions from '../../../redux/actions/searchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from 'mdi-react/MenuIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import AccountCircle from 'mdi-react/AccountCircleIcon';
import ResetIcon from 'mdi-react/AutorenewIcon';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import MoreIcon from 'mdi-react/MoreVertIcon';
import ChartIcon from 'mdi-react/ChartPieIcon';

import UnitsSearchTableSortButton from './UnitsSearchTableSortButton';
import UnitsSearchTableFiltersSelect from './UnitsSearchTableFiltersSelect';
import FilertIcon from 'mdi-react/FilterVariantIcon'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

import Search from '../../../shared/components/algolia/Search';

import ExportButton from '../../../shared/components/data_export/ExportButton';


import { 
	_capitalizeText,
	searchBarStyles
} from "../../../shared/helpers/utils";


function mapStateToProps(state) {
	console.log(state)
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : [], user: state.user};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(function UnitsSearchAppBar(props) {
  const classes = searchBarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
  const { handleSearch, numSelected, handleDeleteSelected, onRequestSort, subFilters, setSubFilter, resetSubFilter, _filters, searchText, buildingStages, setBuildingStage,
	  buildingFilter,  buildingAttributes, placeHolder, goToBuilding, numToShow, advFilterName, UnitStageFilter, setSubFilters, buildingTab, resetActive, showExport } = props;
	  
  const { clearTags } = props.actions;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  
  const handleReset = () => {
	  clearTags();
	  resetSubFilter();
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <div style={{textAlign:'right'}}>
          	{resetActive ?
		       <Tooltip title="Reset Filters to Default">	 
	          	  <IconButton
	              aria-label="Reset Filters to Default"
	              onClick={handleReset}
	              color="inherit"
	            >
	            	
	          			<ResetIcon style={{color:'#9600BF'}}/>
	          		
			  		</IconButton>
			  	</Tooltip>
			  :
	          	  <IconButton
	              aria-label="Reset Filters to Default"
	              onClick={handleReset}
	              color="inherit"
	              disabled
	            >          	
					<ResetIcon style={{color:'#BFC4D1'}}/>
				</IconButton>
	          	
			}
          	  <IconButton
              aria-label="Close Filters"
              onClick={handleMobileMenuClose}
              color="inherit"
            >
          			<CloseCircleOutlineIcon style={{color:'#BFC4D1'}}/>
		  	</IconButton>		  	
	   </div>
      <UnitsSearchTableFiltersSelect
	      	subFilters={subFilters}
	        setSubFilter={setSubFilter}
	        _filters={_filters}
	        {...props}
	        buildingStages={buildingStages}
			setBuildingStage={setBuildingStage}
			numToShow={numToShow}
			advFilterName={advFilterName}
			UnitStageFilter={UnitStageFilter}
			selectedFilters={selectedFilters}
			setSubFilters={setSubFilters}
	       />
    </Menu>
  );
  let timeout = null;
  
  let selectedFilters = Object.keys(subFilters).filter(key => {
	  if(Array.isArray(subFilters[key])){
		  return subFilters[key].length>0;
	  }else{
		  return (subFilters[key] && subFilters[key] !=="")
	  }	
	  
	 }).length;
  selectedFilters += buildingStages.length > 0;
  console.log("selectedFilters", selectedFilters, subFilters)
  
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
       {/*   <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            {/*<InputBase
              placeholder={`${props.searchText ? _capitalizeText(props.searchText) : "Company, Building or Neighborhood…"}`}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyUp={e =>{
	              clearTimeout(timeout);
	              const searchText = e.target.value;
	              console.log('key up', e.target.value);
	              timeout = setTimeout(function (searchText) {
		              	 console.log('handle search', searchText);
				        handleSearch(
							"name",
							searchText
						)
				  }.bind(this, searchText), 800);
              }}
			 
            />*/}
             <Search      
             	buildingFilter={buildingFilter ? buildingFilter : '(ownership_type:"condo" OR ownership_type:"condo-retail" OR building_status:"sponsor") AND (NOT ownership_type:"rental")'
	             	
	             	//'(offering_verified_status:"active" OR offering_verified_status:"coming soon" OR offering_verified_status:"offering plan required" OR offering_verified_status:"offering plan acquired" OR offering_verified_status:"monitoring" OR offering_verified_status:"sold out" OR offering_verified_status:"reviewed" OR offering_verified_status:"vetting required" OR offering_verified_status:"vetted" OR  offering_verified_status:"condo declaration vetted") AND (NOT offering_accepted_date:1 TO 946783296)'
	             	
	             	
             	}       
            	handleSearch={handleSearch}
            	buildingAttributes={buildingAttributes ? buildingAttributes : [{buildingAttribute:'marketing_company', buildingAttributeTitle: 'SALES AGENTS'}, {buildingAttribute:'developers', buildingAttributeTitle: 'DEVELOPERS'}, {buildingAttribute:'architect', buildingAttributeTitle: 'ARCHITECTS'} ]}
            	placeHolder={placeHolder ? placeHolder : 'Borough, Neighborhood, Firm or Building'}
            	addBuilding={goToBuilding ? false : true}
            	buildingTab={buildingTab}
            	classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              	{...props}
              />
          </div>
          
          <div className={classes.grow} />
          
        
          <div className={classes.sectionDesktop}>	
          	    {showExport &&    
	          	    <ExportButton 		          		
		          		{...props}
		          	/>     
		        }   
	      </div>
 {/*         <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div> */}
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.sectionMobile + ' mobile_filter'}>
      	 <span onClick={handleMobileMenuOpen}><FilertIcon size={14} style={{marginTop:"-3px"}} /> {selectedFilters > 0 ? `${selectedFilters} Filter${selectedFilters>1 ? 's' : ''} Selected` : `Filter Results`}</span>
      </div>
      <div className={classes.sectionDesktop}>
	      <UnitsSearchTableFiltersSelect
	      	subFilters={subFilters}
	        setSubFilter={setSubFilter}
	        _filters={_filters}
	        {...props}
	        buildingStages={buildingStages}
			setBuildingStage={setBuildingStage}
			numToShow={numToShow}
			advFilterName={advFilterName}
			UnitStageFilter={UnitStageFilter}
			selectedFilters={selectedFilters}
			setSubFilters={setSubFilters}

	       />
	       <div style={{marginTop:20}}>
	       {resetActive ?
		       <Tooltip title="Reset Filters to Default">	 
	          	  <IconButton
	              aria-label="Reset Filters to Default"
	              onClick={handleReset}
	              color="inherit"
	            >
	            	
	          			<ResetIcon style={{color:'#9600BF'}}/>
	          		
			  		</IconButton>
			  	</Tooltip>
			  :
	          	<IconButton
	              aria-label="Reset Filters to Default"
	              onClick={handleReset}
	              color="inherit"
	              disabled
	            >          	
					<ResetIcon style={{color:'#BFC4D1'}}/>
				</IconButton>
	          	
			} 
		   </div>
	  </div>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
})


