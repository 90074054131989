import React, { PureComponent, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal, Col, Container, Row } from 'reactstrap';
import {
  Link
} from "react-router-dom";
import classNames from 'classnames';
import './Payment.scss'
import moment from 'moment';

import { bindActionCreators } from 'redux';

import buyersListActions from '../../../redux/actions/buyersListActions';
import { setUserProfile } from '../../../redux/actions/authActions';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import SnackBar from '../../components/SimpleSnackBar';
import LinearLoading from '../../components/LinearLoading';
import CheckIcon from 'mdi-react/CheckCircleOutlineIcon';
import CircleIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import RightIcon from 'mdi-react/ArrowRightCircleOutlineIcon';
import InfoIcon from 'mdi-react/InformationOutlineIcon';

import { Publisher } from "./../../pubsub/publisher.js";

import CouponForm from './stripe/CouponForm';

import{
	_capitalizeText,
	_formatNumber
} from '../../helpers/utils';
import{
	_axiosCall
} from '../../helpers/apicalls';

import { subscriptionPlans, appFeatures } from './plans.js';

const apiUrl = process.env.API_ROOT + '/buyerslist/';
//const apiUrl = 'http://localhost:8000/buyerslist/';
const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);
class ChangePlan extends PureComponent { 
	constructor(props) {
	    super(props);
	    this.state={
		    error:false,
		    fetchInProgress:false,
		    customer:false,
		    subscription:false,
		    paymentMethod:false,
		    snackShow: false,
			snackMessage: false,
			snackType: 'success',
			coupon:false,
			trial:true,
			subscriptionSchedule: false,
			downgrade: false
	    }
	    
	    this._getSubscriptionDetails = this._getSubscriptionDetails.bind(this);
	    this._updateSubscriptionDetails = this._updateSubscriptionDetails.bind(this);
		this._setSubscriptionSchedule = this._setSubscriptionSchedule.bind(this);
	    this._updateUserProfile = this._updateUserProfile.bind(this);
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);
		this.setCoupon = this.setCoupon.bind(this);

    }
    
    componentDidUpdate() {
	    const { modal } = this.props;
	    const { subscription } = this.state;
	    if(modal && !subscription){
		    this._getSubscriptionDetails();
	    }
		
	}
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
 
  
    _getSubscriptionDetails = () => {
	  const { changePlan } = this.props;
	  const { subscriptionId } = this.props.user;
	  
	  if(!subscriptionId) return false;
	
	  let userData = {
			url: apiUrl+'stripe/get-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId
			}
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.customer){	
			  let downgrade = false;
			  let newPlan = false;
			  subscriptionPlans.map((sub, i) => {		    
				  if(sub.id === changePlan){
					  newPlan = sub;
				  }	    
			  });
			  
			  if(res.subscription && res.subscription.plan && res.subscription.plan.amount && (res.subscription.plan.amount / 100) > newPlan.pricePerMonth){
				  downgrade = true;
			  }
		      this.setState(
			      {
				      customer: res.customer,
				      subscription: res.subscription,
				      paymentMethod: res.paymentMethod,
					  trial: res.subscription && res.subscription.status === 'trialing',
					  subscriptionSchedule: res.subscriptionSchedule ? res.subscriptionSchedule : false,
					  downgrade: downgrade
			      }
		      )
		  }else{
			  this.setState({
				  customer: this.props.user,
			      subscription: {},
			      paymentMethod: {},
				  subscriptionSchedule:false,
				  downgrade: false
		      });		  
						  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
	}
	_setSubscriptionSchedule = () => {
	  
	  const { changePlan, user } = this.props;
	  const { subscriptionId } = user;
	  const { subscription, coupon } = this.state;
	  
	  const plan = subscriptionPlans.filter(plan => plan.id === changePlan)[0];
	  
	  if(!subscriptionId) return false;
	  
	  if(!plan) return false;
	  
	  if(this.state.fetchInProgress) return false;
	  
	  this.setState({fetchInProgress : true});
	
	  let userData = {
			url: apiUrl+'stripe/create-schedule-from-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId,
			}
	  };
	 
		
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  
		  if(res && res.id && res.phases){	
			  const subscriptionScheduleId = res.id;
			  const initialPhase = res.phases[0]
			  let currentPhase = {
				  
					  "end_date": initialPhase.end_date,
					  "plans": [
						  {
							  "plan": initialPhase.plans[0].plan,
							  "price": initialPhase.plans[0].plan,
							  "quantity": 1,
							   "tax_rates": [initialPhase.plans[0].tax_rates[0].id]
						  }
					  ],
					  "prorate": false,
					  "start_date": initialPhase.start_date,
				  
			  }
			  let futurePhase = {
					
						"plans": [
							{
								"plan": plan.stripeProductId,
								"price": plan.stripeProductId,
								"quantity": 1,
								 "tax_rates": [initialPhase.plans[0].tax_rates[0].id]
							}
						],
						"prorate": false,
						"start_date": initialPhase.end_date
					
				}
				
				let phaseData = {
						url: apiUrl+'stripe/update-subscription-schedule',
						method: "post",
						query: {
							subscription_schedule_id: subscriptionScheduleId,
							subscription_schedule: {
								phases: [currentPhase, futurePhase]
							}
						}
				  };
				  _axiosCall(phaseData)
				  .then(res => {
					console.log("phaseData res back ,", res);
					if(res && res.phases){
						this.snackOpen('Your subscription change has been scheduled.');
						this.toggle();
					}
					
				  })
			
			
		
			  
		  }else{
			 if(res && res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not activated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
			 }		  
		  }
	  })
	  .catch((error) => {
		console.log("_getUserData error ", error);
	  });
	
	
	}
	
	_updateSubscriptionDetails = () => {
	  
	  const { changePlan, user } = this.props;
	  const { subscriptionId } = user;
	  const { subscription, coupon } = this.state;
	  
	  const plan = subscriptionPlans.filter(plan => plan.id === changePlan)[0];
	  
	  if(!subscriptionId) return false;
	  
	  if(!plan) return false;
	  
	  if(this.state.fetchInProgress) return false;
	  
	  this.setState({fetchInProgress : true});
	
	  let userData = {
			url: apiUrl+'stripe/update-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId,
				subscriptionData: {
				  cancel_at_period_end: false,
				  proration_behavior: 'always_invoice',
				  items: [{
				    id: subscription.items.data[0].id,
				    price: plan.stripeProductId,
				  }],
				  coupon : coupon && coupon.id ? coupon.id : ''
				}
			}
	  };
	 
  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.subscription && res.subscription.id){	
		     
		     let updateProfile = { 
			     subscriptionStatus:res.subscription.status,
			     subscriptionPlan:plan.stripeProductId
		     };
		     
		     if(user.role && Array.isArray(user.role) && user.role.length > 0){
			     let newRoles = user.role.slice();
			     let subIds = subscriptionPlans.map(plan => plan.id);			     
			     newRoles = newRoles.filter(role => subIds.indexOf(role) < 0);
			     updateProfile.role = newRoles.concat(changePlan);
		     }else{
			     updateProfile.role = [changePlan];
		     }
		     
		     if(res.subscription.trial_end){
			     updateProfile['trialEnd'] = res.subscription.trial_end;
			 }
		     
		     this._updateUserProfile(updateProfile);
		      
		  }else{
			 if(res && res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not activated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
			 }		  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
	}
	
	_updateUserProfile= (values) => {
     // console.log('_getBuildingData called', this.state)
           
      const { dispatch, user } = this.props;	  	  
      
	  let profile = Object.assign({}, values);
	  const user_id = user.id;
	  let userData = {
			url: apiUrl+'user/update',
			method: "post",
			query: {
				id: user_id,
				profile: profile
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      this.setState({fetchInProgress : false});
	      if(res && res.profile){
			 
		      this.snackOpen('Your subscription has been updated.');
		      this.toggle();
		      setTimeout(function(){  
			       let oktaUser = Object.assign({}, res.profile);
				   oktaUser['id']= res.id;
				   dispatch(setUserProfile(oktaUser));		
				   Publisher.publish("user-update", oktaUser);	      
			  }.bind(this), 3000);
		     
		      
		  }else{
			  if(res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not updated.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, something went wrong, your subscription was not updated.', 'error');
	    this.setState({fetchInProgress : false});
	  });
	}

	setCoupon(coupon) {
	   this.setState( {coupon: coupon} )
	}
  
   toggle = () => {
	  const { user, modal, setModal } = this.props;
	  setModal(!modal);
   }
  
    modalClass = classNames({
      'modal-dialog--colored': false,
      'modal-dialog--header': true,
   });
   
   
    render(){
	    const { user, modal, setModal, changePlan, message } = this.props;
	    const { error, fetchInProgress, subscription, paymentMethod, snackShow, snackMessage, snackType, coupon, trial, setCoupon, downgrade } = this.state;
	    const { subscriptionPlan } = user;

		
		let currentPlan = subscriptionPlans.filter(subPlan => subPlan.stripeProductId === subscriptionPlan)[0];
	    let plan = false;
	    subscriptionPlans.map((sub, i) => {		    
		    if(sub.id === changePlan){
			    plan = sub;
		    }	    
		});
		
	
	    let totalAtCheckout = plan.pricePerMonth;
		
		if(coupon){
			if(coupon.percent_off){
				totalAtCheckout = Math.round(totalAtCheckout * ((100-coupon.percent_off)/100));
			}else if(coupon.amount_off){
				totalAtCheckout = Math.round(totalAtCheckout - (coupon.amount_off/100));
			}
		}
		
		
	    
	    return (
			<div>
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={3000} 
					snackType={snackType} 
				/>
			    <Modal
			      isOpen={modal}
			      toggle={this.toggle}
			      modalClassName={`ltr-support`}
			      className={`modal-dialog--primary ${this.modalClass}`}
			    >
			      
				      <div className="modal__header">
				        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				        <h4 className="text-modal  modal__title">Change to {plan.name} Plan</h4>
				      </div>
				      
				      {subscription ?
				      <div className="modal__body" >
				      
				      	{message && 
					      <div style={{fontSize:16,background:"#F5F7FA",padding:10,border:"1px solid #780F9E"}}>{message}</div>	
					      	
				      	}
				      	<hr />
				     	 <div style={{display:"flex"}}>   				      	
							<div className="pricing" style={{padding:10, border:"1px solid #A3ADC2", borderRadius:"10px", textAlign:"center", marginRight:20,minWidth:160}}>							 
					          <div className="pricing-plan cc-title" style={{margin:"10px 0 0 0"}}>{plan.name}</div>				          
					          <h3 className="pricing-plan">${plan.pricePerMonth % 1 != 0 ? totalAtCheckout : _formatNumber(totalAtCheckout)}
					          	<span className="small-text light-text">/mo</span></h3>			  
							  <div style={{marginLeft:-10,marginTop:5}}>
							  		{coupon && coupon.name &&
										 <div className="small-text light-text">({coupon.name})</div>
									  }
								   <Elements stripe={stripePromise}>
									 <CouponForm user={user} setCoupon={this.setCoupon} currentCoupon={coupon} currentPlan={currentPlan} trial={trial} selectedPlan={plan} />
								   </Elements>
							   </div>          			                   				          
					        </div>
							{plan && <p className="plan-recommended"><div className="title">Key Features:</div>{plan.users.join(", ")}</p>}
							
						 </div>
						 {(downgrade && subscription.status !== "trialing") && 
							 <div style={{background:"#F5F7FA",padding:10,border:"1px solid #780F9E",marginTop:20}}>
							 	<InfoIcon size={20} color="#780F9E" style={{marginTop:-3}}/> Plan change will take affect at the end of the billing period on <strong className="plan-bold">{moment.unix(subscription.current_period_end).format('L')}</strong>
							 </div>
						 }
						 
				        <hr />
						
				      {/* <strong>Features Included</strong>
				    
				        <Row className={"mt-2"}>
				        {appFeatures.map(feature => {
						     if(feature.plans.indexOf(plan.id)>=0){
							     return <Col lg={6}  className="pricing-card__feature">
							     			<p style={{marginBottom:5}}><CheckIcon size={16} color={'#780F9E'} style={{marginTop:-3}} /> {feature.name}</p>
							     		</Col>
						     }						    
					    })}
				        </Row>
				        <hr /> */}
				        <p>
				        	<Link to="/pricing" className="link-text float-right">See All Plans <RightIcon size={14} style={{marginTop:-2}} /></Link>
				        </p>
				       </div> 
				       :
				        <div className="modal__body" style={{ height:150 }}>
				        	<LinearLoading />
				        </div>
				      }
				      
				      <ButtonToolbar className="modal__footer">
				        <Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}
						{subscription ?
				        <Button className="modal_ok" type="button" style={{ width:200 }} color={'primary'} onClick={() => { if(downgrade && subscription.status !== "trialing"){ this._setSubscriptionSchedule() }else { this._updateSubscriptionDetails();} }}>
				        	{fetchInProgress ? <span>Processing...	</span> : <span>Change Plan</span>}
				        </Button>
						:
						<Button
							 variant="contained"
							 color="primary"
							 className="buyersList__primary-button intercom_launch"	
							 style={{width:200}}	         
						 >	Contact Us
						 </Button>
					 	}
				      </ButtonToolbar>
			    </Modal>
			</div>
		    
		  );
	    
    }  
}

function mapStateToProps(state) {
	console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings) ? state.buyersLists.viewedBuildings.slice() : [],
	  		dataSet : state.buyersLists.dataSet
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch), dispatch: dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlan);