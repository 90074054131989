import React, { PureComponent, Fragment } from 'react';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';

import UnitList from './components/UnitList';
import { _axiosCall } from '../../../../shared/helpers/apicalls';

const apiURL = process.env.API_ROOT + '/query/';

class Listings extends PureComponent {
	
	constructor(props) {
		super(props);
		this.state = {	     
		  forSaleRes: null,
		  forRentRes: null,
		  fetchInProgress:false
		};
	  }
	  
	  componentDidMount() {
			  this._getListings();
	  }
	 _getListings = async () => {
	  		const { buildingRes } = this.props;
	  		const addressQ = buildingRes.alternate_addresses.join('" OR "');	
			
	  		const q = `((alternate_addresses:("${addressQ}") AND (zip:${this.props.buildingRes.zip} OR borough:"${this.props.buildingRes.borough.toLowerCase()}")))`;	
	  		const fq = [			 
		  		`sale_status:(active *contract* *pending* *coming*)`,
				`source_namespace:(BKMLS REBNY-VOW MARKETPROOF-ONEKEY)`,
				`deleted:false`		
	  		]
	  		let axiosData = {
		  		url: apiURL + "search-listings",
		  		method: "post",
		  		query: {
			  		q: q,
			  		fq: fq,
					sort: "sale_status asc, price desc",
					fl: ["key", "display_full_street_address", "neighborhood", "borough", "address_2*", "key", "bedrooms", "baths", "price", "listing_type", "sale_status", "*date*", "building", "sq_ft", "broker*", "agents", "contacts_json", "*commission*"],
			  		wt: "json",
			  		rows: 9999
		  		}
	  		}
			let formattedData = await _axiosCall(axiosData)
			.then(res => {
					if(res && res.numFound > 0){
						res.docs.map(l => {
							try{
								l['contacts'] = JSON.parse(l['contacts_json']);
							}catch(e){
								console.log('error parsing contact')
							}
						})
						this.setState({forSaleRes:res.docs.filter(l => l.listing_type=="sale"), forRentRes:res.docs.filter(l => l.listing_type=="rent")})
					}else{
						this.setState({forSaleRes:[], forRentRes:[]})
					}	
			})
			.catch(error => {
				console.log("error: " + error);
			});	
  		}
	
		render() {
			const { forSaleRes, forRentRes } = this.state;
			return (
		   	
			  	<Container className="dashboard">
			   	<Row>
			   	 	<UnitList unitsRes={forSaleRes} title={"For Sale"} />			                
			   	</Row>
			    <Row>
					<UnitList unitsRes={forRentRes} title={"For Rent"} />			                
				</Row>
			  	</Container>
		 	)
	 	}
	
  
};

export default Listings;