import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';
import { getFormValues } from 'redux-form';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import ReportIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AgentIcon from 'mdi-react/AccountOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import EmailIcon from 'mdi-react/EmailIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ReportUnitStatusForm from '../form/contact/ReportUnitStatusForm';
import ShareContractAgents from '../../../shared/components/ShareContractAgents'

import SnackBar from '../../../shared/components/SimpleSnackBar';
import moment from 'moment';

import { Publisher } from './../../pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_nameToUrl
} from '../../../shared/helpers/utils';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';

class ReportUnitStatusButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: isMobile ? '+Contract' : 'Report Contract',
	    modal: false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
	    confirmedAgent:false,
	    confirmSubmit:false,
	   
	    
	};
	this.toggle = this.toggle.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
	this.setActiveStep = this.setActiveStep.bind(this);
	this.setFormValues = this.setFormValues.bind(this);
    
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
  		const { authState, authService, dispatch, user, formValues, buildingRes, property, listing } = this.props;	
  }
  
  setFormValues(){
	   const { authState, authService, dispatch, user, formValues, buildingRes, property, listing } = this.props;	
	   if(!formValues) return false;
	      		 
   		 let updatedState = {
	   		 "offering_status": "in contract"
	   	 };
	   	 if(formValues.contractDate){
		   	 updatedState["last_contract_date"] = moment(formValues.contractDate, "M/D/YYYY").unix();
	   	 }
	   	 if(formValues.contractPrice){
		   	 updatedState["last_contract_price"] = parseInt(formValues.contractPrice);
	   	 }
	   	 
	   	 
	   	 
	   	 
	   	 updatedState['custom_fields'] = {};
	   	 	   	 
	   	 

	   const contractRequest = {};
	   	 contractRequest['meta'] = {
		   	 userEmail : user.email,
		   	 userKey: user.id,
		   	 submitTime : moment().unix()
	   	 }
	   	 if(formValues.buyerAgentFullName){
		   	 contractRequest['contract_buyers_agent'] = {
			   	 buyerAgentFullName : formValues.buyerAgentFullName ? formValues.buyerAgentFullName : "",
			   	 buyerAgentFirmName : formValues.buyerAgentFirmName ? formValues.buyerAgentFirmName : "",
			   	 buyerAgentEmail : formValues.buyerAgentEmail ? formValues.buyerAgentEmail : "",
			   	 buyerAgentPhone : formValues.buyerAgentPhone ? formValues.buyerAgentPhone : ""
		   	 };
	   	 }
	   	 if(formValues.sellerAgentFullName){
		   	 contractRequest['contract_sellers_agent'] = {
			   	 sellerAgentFullName : formValues.sellerAgentFullName ? formValues.sellerAgentFullName : "",
			   	 sellerAgentFirmName : formValues.sellerAgentFirmName ? formValues.sellerAgentFirmName : "",
			   	 sellerAgentEmail : formValues.sellerAgentEmail ? formValues.sellerAgentEmail : "",
			   	 sellerAgentPhone : formValues.sellerAgentPhone ? formValues.sellerAgentPhone : ""
		   	 };
	   	 }
	   	 
	   	 if(formValues.message){
		   	 contractRequest['message'] = formValues.message;
	   	 }
		 contractRequest['reported_mls'] = formValues.isReported ? "Yes" : "No";
	   	 
	   	 if(formValues.closeDate){
		   	 contractRequest['contract_expected_close_date'] = formValues.closeDate ? moment(formValues.closeDate, "M/D/YYYY").unix() : -1;
	   	 }
	   	 updatedState['custom_fields']["mpnd_user_contract"] = JSON.stringify(contractRequest);
	   	 return updatedState;
	   	 
	   	 
   }
  
   handleSubmit(values) {
	  
   		 const { authState, authService, dispatch, user, formValues, buildingRes, property, listing } = this.props;	
   		 const { saveBuilding, saveUnit } = this.props.actions; 
   		 let propertyName = buildingRes.name 
	      ? _capitalizeText(buildingRes.name)
	      : buildingRes.display_full_street_address
	        ? _capitalizeText(buildingRes.display_full_street_address)
	        : _capitalizeText(buildingRes.full_street_address);
        
        if(property && property.address_2_normalized) propertyName += ' - ' + _formatUnitNumber(property.address_2_normalized);
		let hood = buildingRes.neighborhood ? _capitalizeText(buildingRes.neighborhood[0])+', ' : '';
		hood += _capitalizeText(buildingRes.borough);
		
		let unitURL = "https://newdev.marketproof.com" + _getBuildingURL(buildingRes, 'building_slugs') + '/' + _nameToUrl(property.address_2_normalized ? property.address_2_normalized : "property");
   		  	  
   		 let updatedState = this.setFormValues();
   		
	   	 
   		 const address = {
		    display_full_street_address : buildingRes.display_full_street_address ? buildingRes.display_full_street_address : false,
		    full_street_address : buildingRes.display_full_street_address ? buildingRes.display_full_street_address : false,
		    address_2 : property && property.address_2 ? property.address_2 : false,
		    address_2_normalized : property && property.address_2_normalized ? property.address_2_normalized : false,
		    city : buildingRes.city ? buildingRes.city : false,
		    state : buildingRes.state ? buildingRes.state : false,
		    zip : buildingRes.zip ? buildingRes.zip : false,
		    borough : buildingRes.borough ? buildingRes.borough : false,
		    borough_code : buildingRes.borough_code ? buildingRes.borough_code : false
		  }
		  
		 const requestData = {
		      user_key: user.id,
		      master_key: property.master_key, 
		      changes: updatedState,
		      address: address ? address : false,
		      unit_url : unitURL
		 }
   		 

   		 let apiData = {
			url: apiUrl+'user-request',
			method: "post",
			query: requestData
	  	  };
	  
	  
		  _axiosCall(apiData)
		    .then(res => {
		      console.log("_getUserData res back ,", res);
		      
		      if(res && res.success){		      
			      
			      this.snackOpen('Your contract has been reported.', 'success');				  
				  this.setState(prevState => ({ modal: false }));
			  }else{			  
				 if(res.message){
					this.snackOpen(<Fragment><span>Oops, something went wrong.</span></Fragment>, 'error');
				 }else{
					this.snackOpen(<Fragment><span>Oops,  something went wrong.</span></Fragment>, 'error');
				 }
				  
			  }
		  })
		  .catch((error) => {
		    console.log("_getUserData error ", error);
		    this.snackOpen('Oops, Your Message Was Not Sent Successfully.', 'error');
		  }); 

    
   }
  
  	snackOpen = (message, type) => {
		this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	};
	
	snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: '' } )
	};
	
	
	
	
  toggle() {
	//this.snackOpen('Your Message Has Been Sent!', 'success');
    this.setState(prevState => ({ modal: !prevState.modal,  activeStep : 0 }));
  }
  
  setActiveStep(step){
	
	  
	  this.setState({ activeStep : step });
  }

  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, savedBuildings, savedUnits, listing, textButton
    } = this.props;
    
    const { modal, snackShow, snackMessage, snackType, confirmedAgent, confirmSubmit, activeStep, formValues } = this.state;
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;

	let salesOffice = false;
	let listingAgents = false;
	
	const hood = buildingRes.neighborhood ? _capitalizeText(buildingRes.neighborhood[0])+',' : '';
	
	try{
		salesOffice = JSON.parse(buildingRes.sales_office_details);
	}catch(e){
		
	}
	
	if(listing && listing.contacts_json){
		try{
			listingAgents = JSON.parse(listing.contacts_json);
		}catch(e){
			
		}
	}
	
	console.log(listingAgents);
	
	const unitKey = property ? property.key : false;
	const buildingKey = buildingRes.key;
	
	const saved = unitKey ? 
    			savedUnits.findIndex(item => (item.key === unitKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 
    			: 
    			savedBuildings.findIndex(item => (item.key === buildingKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
    
    const mockProperty = {
			"custom_fields_json": "{\"mpnd_user_contract\":\"{\\\"meta\\\":{\\\"userEmail\\\":\\\"ning@marketproof.com\\\",\\\"userKey\\\":\\\"00u57xs7bfNsi46jh4x6\\\",\\\"submitTime\\\":1642629306},\\\"contract_buyers_agent\\\":{\\\"buyerAgentFullName\\\":\\\"Linda Crawford\\\",\\\"buyerAgentFirmName\\\":\\\"Best in Town Brokerage \\\",\\\"buyerAgentEmail\\\":\\\"linda@bestintown.com\\\",\\\"buyerAgentPhone\\\":\\\"212-555-1234\\\"},\\\"contract_sellers_agent\\\":{\\\"sellerAgentFullName\\\":\\\"Jake Elsdon\\\",\\\"sellerAgentFirmName\\\":\\\"Acme Brokers\\\",\\\"sellerAgentEmail\\\":\\\"jake@acmebrokers.com\\\",\\\"sellerAgentPhone\\\":\\\"917-555-2345\\\"},\\\"contract_expected_close_date\\\":1644469200}\"}",
			"last_contract_date" : moment().unix()
		}
	
	
	    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				
			{ textButton ? 
				<Tooltip title={`If you were the seller's agent, report your contract and receive recognition for playing a role in the transaction. `}>
				<span onClick={this.toggle} style={{fontSize:"12px",color:"#B070CC",textTransform:"none",cursor:"pointer"}}>
					<ReportIcon size={12} style={{marginTop:"-3px"}}/> 
						{property.current_status != "in contract" ? 
							<span> {`Is this unit in contract?`}</span>
							:
							<span> {`Are you the seller's agent?`}</span>
						}
	    		</span>
	    		</Tooltip>
				:
			    <Tooltip title={`Is this unit in contract? Let us know.`}>
			        <Button
				         onClick={this.toggle}
				         variant="contained"
				         color="secondary"
				         className="buyersList__secondary-button"		         
				         startIcon={<ReportIcon />}
				         style={{width:isMobile ? 130 : 200}}
				     >	{this.state.buttonText}
				     </Button>				 
			  	</Tooltip>
		  	}
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
				  <Row>
		        	
					<Col xl={12} lg={12} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">Report Contract: 
								{property && <span> {buildingNameOrAdr}, {_formatUnitNumber(property.address_2_normalized)}</span>}
							</h4>
							
							{property && 
								<h5 className="contact__sub-title">
									${_formatNumber(listing ? listing.price : property.offering_price)} 
									<span className="small-divider">|</span>
					              	{property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
					              	<span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? ' Bed' : ' Beds'}</span>
					              	<span className="small-divider">|</span>
					              	{property.baths}
					              	<span className="sub-text">{property.baths === 1 ? ' Bath' : ' Baths'}</span>
					              	<span className="small-divider">|</span>
					              	{_formatNumber(property.sq_ft)}
					              	<span className="sub-text"> Sq Ft</span>				              	

				               </h5>  
				            }

				            <hr/>
				            <ReportUnitStatusForm 
				            	buildingRes={buildingRes} 
				            	user={user} 
				            	property={property} 
				            	toggle={this.toggle} 
				            	saved={saved} 
				            	handleSubmit={this.handleSubmit} 
				            	buildingNameOrAdr={buildingNameOrAdr} 
				            	setFormValues={this.setFormValues}/>
				            
				            
				        </div>	
				        	        	
					</Col>
				  </Row>
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: 
	  		state.rtl, 
	  		user: state.user, 
	  		formValues: getFormValues("contract_form")(state),
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [] 
	   };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ReportUnitStatusButton));
