import React, { Fragment } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import searchActions from '../../../redux/actions/searchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from 'mdi-react/MenuIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import AccountCircle from 'mdi-react/AccountCircleIcon';
import ResetIcon from 'mdi-react/AutorenewIcon';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import MoreIcon from 'mdi-react/MoreVertIcon';
import ChartIcon from 'mdi-react/ChartPieIcon';

import UnitsSearchTableSortButton from './UnitsSearchTableSortButton';
import UnitsSearchTableFiltersSelect from './UnitsSearchTableFiltersSelect';
import FilertIcon from 'mdi-react/FilterVariantIcon'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

import Search from '../../../shared/components/algolia/Search';

import ExportButton from '../../../shared/components/data_export/ExportButton';


import { 
  _capitalizeText,
  searchBarStylesPurple
} from "../../../shared/helpers/utils";


function mapStateToProps(state) {
  console.log(state)
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : [], user: state.user};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(function GeneralBuildingSearchBar(props) {
  const classes = searchBarStylesPurple();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
  const { handleSearch, numSelected, handleDeleteSelected, onRequestSort, subFilters, setSubFilter, resetSubFilter, _filters, searchText, buildingStages, setBuildingStage,
    buildingFilter,  buildingAttributes, placeHolder, goToBuilding, numToShow, advFilterName, UnitStageFilter, setSubFilters, buildingTab, resetActive, showExport } = props;
    
  const { clearTags } = props.actions;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  
  const handleReset = () => {
    clearTags();
    resetSubFilter();
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <div style={{textAlign:'right'}}>
            {resetActive ?
           <Tooltip title="Reset Filters to Default">	 
                <IconButton
                aria-label="Reset Filters to Default"
                onClick={handleReset}
                color="inherit"
              >
                
                  <ResetIcon style={{color:'#9600BF'}}/>
                
            </IconButton>
          </Tooltip>
        :
                <IconButton
                aria-label="Reset Filters to Default"
                onClick={handleReset}
                color="inherit"
                disabled
              >          	
          <ResetIcon style={{color:'#BFC4D1'}}/>
        </IconButton>
              
      }
              <IconButton
              aria-label="Close Filters"
              onClick={handleMobileMenuClose}
              color="inherit"
            >
                <CloseCircleOutlineIcon style={{color:'#BFC4D1'}}/>
        </IconButton>		  	
     </div>
  
    </Menu>
  );
  let timeout = null;
  
  
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
      
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
           
             <Search      
               buildingFilter={buildingFilter ? buildingFilter : '(ownership_type:"condo" OR ownership_type:"condo-retail" OR building_status:"sponsor") AND (NOT ownership_type:"rental")'
                 
                 //'(offering_verified_status:"active" OR offering_verified_status:"coming soon" OR offering_verified_status:"offering plan required" OR offering_verified_status:"offering plan acquired" OR offering_verified_status:"monitoring" OR offering_verified_status:"sold out" OR offering_verified_status:"reviewed" OR offering_verified_status:"vetting required" OR offering_verified_status:"vetted" OR  offering_verified_status:"condo declaration vetted") AND (NOT offering_accepted_date:1 TO 946783296)'
                 
                 
               }  
                 
              handleSearch={handleSearch}
              buildingAttributes={buildingAttributes ? buildingAttributes : [{buildingAttribute:'marketing_company', buildingAttributeTitle: 'SALES AGENTS'}, {buildingAttribute:'developers', buildingAttributeTitle: 'DEVELOPERS'}, {buildingAttribute:'architect', buildingAttributeTitle: 'ARCHITECTS'} ]}
              placeHolder={placeHolder ? placeHolder : 'Borough, Neighborhood, Firm or Building'}
              buildingTab={buildingTab}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
                {...props}
              />
          </div>
          
          <div className={classes.grow} />
          
        
          <div className={classes.sectionDesktop}>	
     
        </div>

         
        </Toolbar>
      </AppBar>
  
    </div>

  );
})


