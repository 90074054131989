import { 
	recentCohortQuery
} from "./utils";
const today = new Date();
const new_building_start = today.getFullYear() - 7;
const current_year = today.getFullYear();
export const _buildingFilters = [
	{
		name: "Construction",
		show_name: true,
		search_key: "sales_start_date",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready (Has CO/TCO)",
				search_value: "[NOW-25YEARS TO NOW] OR building_status:(resale) OR offering_verified_status:(\"sold out\") OR first_closing_date:[NOW-25YEARS TO NOW]",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction (No CO/TCO)",
				search_value: "[* TO NOW-25YEARS] AND -building_status:(resale) AND -offering_verified_status:(\"sold out\")",
			}
			
		]
	},
	{
		name: "Price / Sq Ft",
		show_name: true,
		search_key: "inventory_stats_listings_average_ppsf",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: "[100 TO 750]"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "[751 TO 1000]"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "[1001 TO 1500]"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "[1500 TO 2000]"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: "[2001 to 2500]"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "[2501 to 5000]"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "[5001 TO *]"
			}
		]
	},
	{
		name: "Starting From",
		show_name: true,
		search_key: "inventory_stats_listings_low_price",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "From: < $500,000 ",
				filter: "less-than-500",
				dropdown_name: "Less than $500K",
				search_value: "[100 TO 500000]"
			},
			{
				name: "From: $500K to $750K",
				filter: "500-to-750",
				dropdown_name: "$500K to $750K",
				search_value: "[500000 TO 750000]"
			},
			{
				name: "From: $750K to $1M",
				filter: "750-to-1000",
				dropdown_name: "$750K to $1M",
				search_value: "[750001 TO 1000000]"
			},
			{
				name: "From: $1M to $1.5M",
				filter: "1000-to-1500",
				dropdown_name: "$1M to $1.5M",
				search_value: "[1000001 TO 1500000]"
			},
			{
				name: "From: $1.5M to $2M",
				filter: "1500-to-2000",
				dropdown_name: "$1.5M to $2M",
				search_value: "[1500001 TO 2000000]"
			},
			{
				name: "From: $2M+",
				filter: "2000+",
				dropdown_name: "$2M+",
				search_value: "[2000000 TO *]"
			}
		]
	},
	
	{
		name: "Unsold Units",
		show_name: true,
		search_key: "inventory_unsold",
		values: [
			{
				name: "Any Unsold Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Unsold Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Unsold Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Unsold Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Unsold Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Unsold Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Unsold Units",
				filter: "201-or-more",
				dropdown_name: "201 or more",
				search_value: "[201 TO *]"
			}
		]
	},
	/*{
		name: "Percent Sold",
		show_name: true,
		search_key: "inventory_pct_sold",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Less Than 15%",
				filter: "0-to-15",
				dropdown_name: "Less Than 15%",
				search_value: "[0 TO 15]"
			},
			{
				name: "Less Than 50%",
				filter: "0-to-49",
				dropdown_name: "Less Than 50%",
				search_value: "[0 TO 49]"
			},
			{
				name: "50% to 79%",
				filter: "50-to-79",
				dropdown_name: "50% to 79%",
				search_value: "[50 TO 79]"
			},
			{
				name: "80% to 99%",
				filter: "80-to-99",
				dropdown_name: "80% to 99%",
				search_value: "[80 TO 99]"
			},
			{
				name: "Sold Out",
				filter: "100 TO *",
				dropdown_name: "Sold Out",
				search_value: "[100 TO *]"
			}
		]
	},*/
	{
		name: "% Sold",
		show_name: true,
		search_key: "inventory_pct_sold",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Sales",
				filter: "has-sales",
				dropdown_name: "Has Sales",
				search_value: "[1 TO *] OR offering_verified_status:\"sold out\""
			},
			{
				name: "Has No Sales",
				filter: "no-sales",
				dropdown_name: "Has No Sales",
				search_value: "[* TO 0] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Less Than 15%",
				filter: "0-to-15",
				dropdown_name: "Less Than 15%",
				search_value: "[0 TO 15]"
			},
			{
				name: "Less Than 35%",
				filter: "0-to-35",
				dropdown_name: "Less Than 35%",
				search_value: "[0 TO 34]"
			},
			{
				name: "Less Than 50%",
				filter: "0-to-49",
				dropdown_name: "Less Than 50%",
				search_value: "[0 TO 49]"
			},
			{
				name: "More Than 15%",
				filter: "15-to-100",
				dropdown_name: "More Than 15%",
				search_value: "[15 TO *]"
			},
			{
				name: "More Than 50%",
				filter: "50-to-100",
				dropdown_name: "More Than 50%",
				search_value: "[50 TO *]"
			},
			{
				name: "50% to 79%",
				filter: "50-to-79",
				dropdown_name: "50% to 79%",
				search_value: "[50 TO 79]"
			},
			{
				name: "80% to 99%",
				filter: "80-to-99",
				dropdown_name: "80% to 99%",
				search_value: "[80 TO 99]"
			},
			{
				name: "Not Sold Out",
				filter: "not-sold-out",
				dropdown_name: "Not Sold Out",
				search_value: "[* TO 99] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Sold Out",
				filter: "sold-out",
				dropdown_name: "Sold Out",
				search_value: "[100 TO *]"
			}
			
		]
	},
	{
		name: "Condo Units",
		show_name: true,
		search_key: "inventory_total",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Condo Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10 Units",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Condo Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Condo Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Condo Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Condo Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201-400 Condo Units",
				filter: "201-to-400",
				dropdown_name: "201 to 400 Units",
				search_value: "[201 TO 400]"
			},
			{
				name: "401 + Condo Units",
				filter: "401-or-more",
				dropdown_name: "401 or more Units",
				search_value: "[401 TO *]"
			}
		]
	},
	{
		name: "Offering Type",
		show_name: true,
		search_key: "offering_construction",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "New Construction",
				filter: "new-construction",
				dropdown_name: "New Construction",
				search_value: "new",
			},
			{
				name: "Conversion",
				filter: "conversion",
				dropdown_name: "Conversion",
				search_value: "conversion",
			},			
			{
				name: "Rehab",
				filter: "rehab",
				dropdown_name: "Rehab",
				search_value: "rehab",
			}
			
		]
	},
	{
		name: "Status",
		show_name: true,
		search_key: "offering_verified_status",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Vetted by Marketproof",
				filter: "vetted",
				dropdown_name: "Vetted by Marketproof",
				search_value: "active"
			},
			{
				name: "Vetting In Progress",
				filter: "in-progress",
				dropdown_name: "Vetting In Progress",
				search_value: "(\"coming soon\" \"offering plan required\" \"condo declaration vetted\")"
			}
		]
	},	
	{
		name: "Added",
		show_name: false,
		search_key: "offering_verified_active_date",
		values: [
			{
				name: "Any Time",
				filter: "",
				dropdown_name: "Any Time",
				search_value: ""
			},
			{
				name: "Recently Added",
				filter: "recently-added",
				dropdown_name: "Recenly Added",
				search_value: "[NOW-6MONTHS TO NOW]"
			}
		]
	},
];


export const _unitFilters = [
	{
		name: "Status",
		show_name: true,
		search_key: "current_status",
		multi_filter: true,
		hide_analysis:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Available (Active or Shadow)",
				filter: "available",
				dropdown_name: "Available (Active or Shadow)",
				search_value: `(((current_status:shadow AND -ownership_type:(*co?op* *coop* !condo*p)) AND -offering_construction:conversion AND property_status:sponsor AND ${recentCohortQuery}) OR (current_status:transfer AND -offering_construction:conversion AND property_status:sponsor AND ${recentCohortQuery})) (active)`
			},
			{
				name: "Shadow",
				filter: "shadow",
				dropdown_name: "Shadow",
				search_value: `(((current_status:shadow AND -ownership_type:(*co?op* *coop* !condo*p)) AND -offering_construction:conversion AND property_status:sponsor AND ${recentCohortQuery}) OR (current_status:transfer AND -offering_construction:conversion AND property_status:sponsor AND ${recentCohortQuery}))`
			},
			
			{
				name: "Active",
				filter: "active",
				dropdown_name: "Active",
				search_value: "(active)"
			},
			{
				name: "Sold (Contract or Closed)",
				filter: "sold",
				dropdown_name: "Sold (Contract or Closed)",
				search_value: "((*contract* OR *pending*) (not_yet_recorded OR *sold* OR closed OR resale) OR (offering_status:sold not_yet_recorded OR transfer) OR (current_status:shadow AND offering_submitted_date:[1971-01-01T00:00:00Z TO 2010-11-01T00:00:00Z]))"
			},
			{
				name: "In Contract",
				filter: "in-contract",
				dropdown_name: "In Contract",
				search_value: "(*contract* OR *pending*)"
			},
			{
				name: "Closed",
				filter: "closed",
				dropdown_name: "Closed",
				search_value: "(not_yet_recorded OR *sold* OR closed OR resale) OR (offering_status:sold not_yet_recorded OR transfer) OR (current_status:shadow AND offering_submitted_date:[* TO 2010-11-01T00:00:00Z])"
			}
		]
	},
	{
		name: "Ownership",
		show_name: true,
		search_key: "ownership_type",
		multi_filter:true,
		hide_building:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "(*condo* !condo*p)",
			},
			{
				name: "Co-op",
				filter: "coop",
				dropdown_name: "Co-op",
				search_value: "(*co?op* *coop* !condo*p)",
			},			
			{
				name: "Condop",
				filter: "condop",
				dropdown_name: "Condop",
				search_value: "(condop*)",
			}
			
		]
	},
	
	{
		name: "Asking Price",
		show_name: true,
		search_key: ["min_price", "max_price"],
		filter_key : "asking_price",
		price_filter: true,
		search_value: ['last_listed_sale_price_all:[{min_price} TO {max_price}]']		
	},
	{
		name: "Asking PPSF",
		show_name: true,
		search_key: ["min_ppsf_price", "max_ppsf_price"],
		filter_key : "asking_ppsf",
		price_filter: true,
		target: 'fq',
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['{!frange l={min_ppsf_price} u={max_ppsf_price}}div(last_listed_sale_price_all,sq_ft)'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: "{!frange l=100 u=750}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "{!frange l=751 u=1000}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "{!frange l=1000 u=1500}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "{!frange l=1500 u=2000}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: "{!frange l=2000 u=2500}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "{!frange l=2500 u=5000}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "{!frange l=5000 u=99999}div(last_listed_sale_price_all,sq_ft)"
			}
		]
	},
	{
		name: "Bedrooms",
		show_name: true,
		search_key: "bedrooms",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Studio",
				filter: "studio",
				dropdown_name: "Studio",
				search_value: "0",
			},
			{
				name: "1 Bedroom",
				filter: "1-bedroom",
				dropdown_name: "1 Bedroom",
				search_value: "1",
			},
			{
				name: "2 Bedroom",
				filter: "2-bedroom",
				dropdown_name: "2 Bedroom",
				search_value: "2",
			},
			{
				name: "3 Bedroom",
				filter: "3-bedroom",
				dropdown_name: "3 Bedroom",
				search_value: "3",
			},
			{
				name: "4+ Bedroom",
				filter: "4-or-more",
				dropdown_name: "4+ Bedroom",
				search_value: "[4 TO *]",
			},
		]
	},
	{
		name: "Amenities",
		show_name: true,
		search_key: "building_features",
		autocomplete: true,
		core_name: "we3-properties",
		fq: ["deleted:false"],
		group_amenities: true,
		hide_building:true,
	},
	{
		name: "Unit Type",
		show_name: true,
		search_key: "property_type",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Residential",
				filter: "residential",
				dropdown_name: "Residential Units",
				search_value: "(*residential*condo* condo *co-op* \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*)"
			},
			{
				name: "Commercial",
				filter: "commercial",
				dropdown_name: "Commercial Units",
				search_value: "(*commercial* *office* *retail*)"
			},
			{
				name: "Storage",
				filter: "storage",
				dropdown_name: "Storage Units",
				search_value: "(*storage*)"
			},
			{
				name: "Parking",
				filter: "parking",
				dropdown_name: "Parking",
				search_value: "(*parking* *garage*)"
			},
			{
				name: "Amenities",
				filter: "amenities",
				dropdown_name: "Amenities",
				search_value: "(*gym* *common* *lobby* *pool* *entertainment*)"
			}
		]
	},
	{
		name: "Baths",
		show_name: true,
		search_key: "baths",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},			
			{
				name: "1 Bath",
				filter: "1-bath",
				dropdown_name: "1 Bath",
				search_value: "1 OR baths:1",
			},
			{
				name: "2 Bath",
				filter: "2-bath",
				dropdown_name: "2 Bath",
				search_value: "2 OR baths:2",
			},
			{
				name: "3 Bath",
				filter: "3-bath",
				dropdown_name: "3 Bath",
				search_value: "3 OR baths:3",
			},
			{
				name: "4+ Bath",
				filter: "4-or-more",
				dropdown_name: "4+ Bath",
				search_value: "[4 TO *] OR baths:[4 TO *]",
			},
		]
	},

	{
		name: "Interior SF",
		show_name: true,
		search_key: ["min_sq_ft", "max_sq_ft"],
		filter_key : "sq_ft",
		price_filter: true,
		labels : ["Min Sq Ft", "Max Sq Ft"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['sq_ft:[{min_sq_ft} TO {max_sq_ft}]'], 
		value_prefix : "",
		title_suffix : "SF",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< 500 ",
				filter: "less-than-500",
				dropdown_name: "Less than 500 SF",
				search_value: "[10 TO 500] OR sq_ft:[10 TO 500] "
			},
			{
				name: "501 to 750 SF",
				filter: "501-to-750",
				dropdown_name: "501 to 750 SF",
				search_value: "[501 TO 750] OR sq_ft:[501 TO 750] "
			},
			{
				name: "751 to 1,000 SF",
				filter: "751-to-1000",
				dropdown_name: "751 to 1000 SF",
				search_value: "[751 TO 1000] OR sq_ft:[751 TO 1000]"
			},
			{
				name: "1,001 to 1,500 SF",
				filter: "1001-to-1500",
				dropdown_name: "1,001 to 1,500 SF",
				search_value: "[1001 TO 1500] OR sq_ft:[1001 TO 1500]"
			},
			{
				name: "1,501 to 2,000 SF",
				filter: "1501-to-2000",
				dropdown_name: "1,501 to 2,000 SF",
				search_value: "[1501 TO 2000] OR sq_ft:[1501 TO 2000]"
			},
			{
				name: "2,001 to 3,000 SF",
				filter: "2001-to-3000",
				dropdown_name: "2,001 to 3,000 SF",
				search_value: "[2001 TO 3000] OR sq_ft:[2001 TO 3000]"
			},
			{
				name: "3,000+ SF",
				filter: "3001-or-more",
				dropdown_name: "More than 3000 SF",
				search_value: "[3001 TO *] OR sq_ft:[3001 TO *]"
			}
		]
	},
	{
		name: "Exterior SF",
		show_name: true,
		search_key : "offering_exterior_sq_ft",
		multi_filter: true,
/*
		search_key: ["min_ext_sq_ft", "max_ext_sq_ft"],
		filter_key : "ext_sq_ft",
		price_filter: true,
		labels : ["Min Exterior SF", "Max Exterior SF"],
		min_max_values : [50, 500],
		min_max_increment : 50,
		format_type : "number",
		search_value: ['offering_exterior_sq_ft:[{min_ext_sq_ft} TO {max_ext_sq_ft}]'], 
		value_prefix : "",	
		title_suffix : "SF",
*/	
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Exterior SF",
				filter: "has-exterior-sf",
				dropdown_name: "Has Exterior SF",
				search_value: "[1 TO *]"
			},
			{
				name: "No Exterior SF",
				filter: "no-exterior-sf",
				dropdown_name: "No Exterior SF",
				search_value: "[* TO 0]"
			},
			{
				name: "< 50 ",
				filter: "less-than-50",
				dropdown_name: "Less than 50 SF",
				search_value: "[0 TO 50]"
			},
			{
				name: "50 to 100 SF",
				filter: "50-to-100",
				dropdown_name: "50 to 100 SF",
				search_value: "[51 TO 100]"
			},
			{
				name: "101 to 200 SF",
				filter: "100-to-200",
				dropdown_name: "101 to 200 SF",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 to 500 SF",
				filter: "200-to-500",
				dropdown_name: "201 to 500 SF",
				search_value: "[201 TO 500]"
			},			
			{
				name: "500+ SF",
				filter: "500-more",
				dropdown_name: "More than 500 SF",
				search_value: "[501 TO *]"
			}
		]
	},
	{
		name: "Floors",
		show_name: true,
		search_key: ["min_floor", "max_floor"],
		filter_key : "floor",
		price_filter: true,
		// target: 'fq',
		labels : ["Min Floor", "Max Floor"],

		custom_min_max_values : {
			"min" : [1, 11, 21, 31, 41],
			"max" : [10, 20, 30, 40, 50, "No Max"]

		},	
		no_max:true,
		format_type : "number",
		search_value: ['floor:[{min_floor} TO {max_floor}]']		,
		format_type : "number",
		value_prefix : "",	
		
		
	},
	/*{
		name: "Offering Discount",
		show_name: true,
		search_key: "offering_discount",
		target: 'fq',
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Less than 1%",
				filter: "less-than-sold",
				dropdown_name: "Asking Less than Sold",
				search_value: "{!frange l=-0.01 u=0}div(sub(initial_offering_price,last_listed_sale_price_all),initial_offering_price))"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "{!frange l=751 u=1000}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "{!frange l=1000 u=1500}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "{!frange l=1500 u=2000}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: "{!frange l=2000 u=2500}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "{!frange l=2500 u=5000}div(last_listed_sale_price_all,sq_ft)"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "{!frange l=5000 u=99999}div(last_listed_sale_price_all,sq_ft)"
			}
		]
	},*/
		
	
	{
		name: "Sold Date",
		show_name: true,
		filter_key: "sold_date",
		search_key: ["min_date", "max_date"],
		date_filter: true,
		search_value: ['last_sold_date_formatted:[{min_date} TO {max_date}]']		
	},
	{
		name: "Contract Date",
		show_name: true,
		filter_key: "contract_date",
		search_key: ["min_contract_date", "max_contract_date"],
		date_filter: true,
		search_value: ['last_contract_date_formatted:[{min_contract_date} TO {max_contract_date}]']		
	},
	{
		name: "Sold Price",
		show_name: true,
		search_key: ["min_sold_price", "max_sold_price"],
		filter_key : "sold_price",
		price_filter: true,
		search_value: ['last_sold_price:[{min_sold_price} TO {max_sold_price}]']		
	},
	{
		name: "Sold PPSF",
		show_name: true,
		search_key: ["min_sold_ppsf_price", "max_sold_ppsf_price"],
		filter_key : "sold_ppsf",
		price_filter: true,
		target: 'fq',
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['{!frange l={min_sold_ppsf_price} u={max_sold_ppsf_price}}div(last_sold_price,sq_ft)'],
		
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: "{!frange l=100 u=750}div(last_sold_price,sq_ft)"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "{!frange l=751 u=1000}div(last_sold_price,sq_ft)"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "{!frange l=1000 u=1500}div(last_sold_price,sq_ft)"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "{!frange l=1500 u=2000}div(last_sold_price,sq_ft)"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: "{!frange l=2000 u=2500}div(last_sold_price,sq_ft)"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "{!frange l=2500 u=5000}div(last_sold_price,sq_ft)"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "{!frange l=5000 u=99999}div(last_sold_price,sq_ft)"
			}
		]
	},
	{
		name: "Unit Number",
		show_name: true,
		search_key: "address_2_normalized",
		text_filter: true
	},
	{
		name: "Special Pricing",
		show_name: true,
		search_key: "special_pricing",
		target: 'fq',
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Asking Below Sold",
				filter: "below-sold",
				dropdown_name: "Asking Below Sold",
				search_value: "{!frange l=1 u=1}if(lt(last_listed_sale_price_all,last_sold_price),1,0)",
				additional_fq : ["{!frange l=1 u=1}if(gt(last_listed_sale_date_all,last_sold_date),1,0)"]
			},
			{
				name: "Unpriced Units",
				filter: "unpriced-units",
				dropdown_name: "Unpriced Units",
				search_value: "last_listed_sale_price_all:[* TO -1] AND source_organizations:NYAG",
			}
		]
	},
	{
		name: "Carrying Cost",
		show_name: true,
		search_key: ["min_carry_cost", "max_carry_cost"],
		filter_key : "monthly_carrying_charges",
		price_filter: true,
		labels : ["Min Carry Cost", "Max Carry Cost"],
		min_max_values : [500, 5000],
		min_max_increment : 500,
		format_type : "number",		
		search_value: ['offering_monthly_carrying_charges:[{min_carry_cost} TO {max_carry_cost}]'], 	
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $1,000",
				filter: "less-than-1000",
				dropdown_name: "Less than $1000",
				search_value: "[0 TO 1000] OR monthly_fees:[0 TO 1000]"
			},
			{
				name: "$1,000 to $1,500",
				filter: "1001-to-1501",
				dropdown_name: "$1,000 to $1,500",
				search_value: "[1001 TO 1500] OR monthly_fees:[1001 TO 1500]"
			},
			{
				name: "$1,500 to $2,000",
				filter: "1501-to-2001",
				dropdown_name: "$1,500 to $2,000",
				search_value: "[1501 TO 2000] OR monthly_fees:[1501 TO 2000]"
			},
			{
				name: "$2,000 to $3,000",
				filter: "2001-to-3001",
				dropdown_name: "$2,000 to $3,000",
				search_value: "[2001 TO 3000] OR monthly_fees:[2001 TO 3000]"
			},
			{
				name: "$3,000 to $5,000",
				filter: "3,000-to-5000",
				dropdown_name: "$3,000 to $5,000",
				search_value: "[3001 TO 5000] OR monthly_fees:[3001 TO 5000]"
			},
			{
				name: "$5,000+",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "[5001 TO *] OR monthly_fees:[5001 TO *]"
			}
		]
	},
	{
		name: "Owner Type",
		show_name: true,
		search_key: "owner",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Original Owner",
				filter: "original",
				dropdown_name: "Original Owner",
				search_value: "* AND times_exchanged:[0 TO 1]"
			},
			{
				name: "Corporate Owner",
				filter: "corporate",
				dropdown_name: "Corporate Owner",
				search_value: "(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)"
			}
		]
	},
	{
		name: "Condo Units",
		show_name: true,
		search_key: "inventory_total",
		filter_type: "building",
		multi_filter: true,
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Condo Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10 Units",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Condo Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Condo Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Condo Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Condo Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Condo Units",
				filter: "201-or-more",
				dropdown_name: "201 or more Units",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "Unsold Units",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "inventory_unsold",
		filter_type: "building",
		multi_filter: true,
		values: [
			{
				name: "Any Unsold Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Unsold Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Unsold Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Unsold Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Unsold Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Unsold Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Unsold Units",
				filter: "201-or-more",
				dropdown_name: "201 or more",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "Starting From",
		show_name: true,
		search_key: "inventory_stats_listings_low_price",
		filter_type: "building",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "From: < $500,000 ",
				filter: "less-than-500",
				dropdown_name: "Less than $500K",
				search_value: "[100 TO 500000]"
			},
			{
				name: "From: $500K to $750K",
				filter: "500-to-750",
				dropdown_name: "$500K to $750K",
				search_value: "[500000 TO 750000]"
			},
			{
				name: "From: $750K to $1M",
				filter: "750-to-1000",
				dropdown_name: "$750K to $1M",
				search_value: "[750001 TO 1000000]"
			},
			{
				name: "From: $1M to $1.5M",
				filter: "1000-to-1500",
				dropdown_name: "$1M to $1.5M",
				search_value: "[1000001 TO 1500000]"
			},
			{
				name: "From: $1.5M to $2M",
				filter: "1500-to-2000",
				dropdown_name: "$1.5M to $2M",
				search_value: "[1500001 TO 2000000]"
			},
			{
				name: "From: $2M+",
				filter: "2000+",
				dropdown_name: "$2M+",
				search_value: "[2000000 TO *]"
			}
		]
	},
	{
		name: "% Sold",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "inventory_pct_sold",
		filter_type: "building",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Sales",
				filter: "has-sales",
				dropdown_name: "Has Sales",
				search_value: "[1 TO *] OR offering_verified_status:\"sold out\""
			},
			{
				name: "Has No Sales",
				filter: "no-sales",
				dropdown_name: "Has No Sales",
				search_value: "[* TO 0] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Less Than 15%",
				filter: "0-to-15",
				dropdown_name: "Less Than 15%",
				search_value: "[0 TO 15]"
			},
			{
				name: "Less Than 35%",
				filter: "0-to-35",
				dropdown_name: "Less Than 35%",
				search_value: "[0 TO 34]"
			},
			{
				name: "Less Than 50%",
				filter: "0-to-49",
				dropdown_name: "Less Than 50%",
				search_value: "[0 TO 49]"
			},
			{
				name: "More Than 15%",
				filter: "15-to-100",
				dropdown_name: "More Than 15%",
				search_value: "[15 TO *]"
			},
			{
				name: "More Than 50%",
				filter: "50-to-100",
				dropdown_name: "More Than 50%",
				search_value: "[50 TO *]"
			},
			{
				name: "50% to 79%",
				filter: "50-to-79",
				dropdown_name: "50% to 79%",
				search_value: "[50 TO 79]"
			},
			{
				name: "80% to 99%",
				filter: "80-to-99",
				dropdown_name: "80% to 99%",
				search_value: "[80 TO 99]"
			},
			{
				name: "Not Sold Out",
				filter: "not-sold-out",
				dropdown_name: "Not Sold Out",
				search_value: "[* TO 99] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Sold Out",
				filter: "sold-out",
				dropdown_name: "Sold Out",
				search_value: "[100 TO *]"
			},
			
		]
	},
	{
		name: "Construction",
		show_name: true,
		search_key: "building_effective_start",
		filter_type: "building",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready (Has CO/TCO)",
				search_value: "[NOW-25YEARS TO NOW] OR building_status:(resale) OR offering_verified_status:(\"sold out\") OR first_closing_date:[NOW-25YEARS TO NOW]",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction (No CO/TCO)",
				search_value: "[* TO NOW-25YEARS] AND -building_status:(resale) AND -offering_verified_status:(\"sold out\") AND first_closing_date:[* TO NOW-25YEARS]",
			}
			
		]
	},	
	{
		name: "Offering Type",
		show_name: true,
		search_key: "offering_construction",
		filter_type: "building",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "New Construction",
				filter: "new-construction",
				dropdown_name: "New Construction",
				search_value: "new",
			},
			{
				name: "Conversion",
				filter: "conversion",
				dropdown_name: "Conversion",
				search_value: "conversion",
			},			
			{
				name: "Rehab",
				filter: "rehab",
				dropdown_name: "Rehab",
				search_value: "rehab",
			},
			{
				name: "Exclude Conversion",
				filter: "exclude-conversion",
				dropdown_name: "Exclude Conversion",
				search_value: "(new rehab \"\")",
			}
			
		]
	},	
	// {
	// 	name: "Ownership",
	// 	show_name: true,
	// 	search_key: "ownership_type",
	// 	values: [
	// 		{
	// 			name: "Any",
	// 			filter: "",
	// 			dropdown_name: "Any",
	// 			search_value: ""
	// 		},
	// 		{
	// 			name: "Condo",
	// 			filter: "condo",
	// 			dropdown_name: "Condo",
	// 			search_value: "condo AND -ownership_type:cooperative",
	// 		},
	// 		{
	// 			name: "Co-op",
	// 			filter: "co-op",
	// 			dropdown_name: "Co-op",
	// 			search_value: "coop",
	// 		}
	// 		
	// 	]
	// },
	{
		name: "Condo Dec",
		show_name: true,
		exclude_stage: ["pipeline", "resale"],
		search_key: "cdec_date",
		filter_type: "building",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Condo Dec",
				filter: "no-cdec",
				dropdown_name: "No Condo Dec",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND building_status:(pipeline sponsor)",
			},
			{
				name: "Has Condo Dec",
				filter: "has-cdec",
				dropdown_name: "Has Condo Dec",
				search_value: "[1970-01-05T00:00:00Z TO NOW] OR building_status:(resale)",
			}		
		]
	},
	{
		name: "Year Built",
		show_name: true,
		exclude_stage: ["pipeline"],
		format_type : "number",
		search_key: ["min_year_built", "max_year_built"],
		filter_key : "year_built",
		price_filter: true,
		labels : ["Min Year Built", "Max Year Built"],
		min_max_values : [1900, current_year],
		min_max_increment : 10,
		custom_min_max_values : {
			"min" : [1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020],
			"max" : [1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, current_year]
		},		
		format_type : "year",
		search_value: ['year_built:[{min_year_built} TO {max_year_built}]'], 
		value_prefix : "",	
		title_suffix : ""
	},
	{
		name: "Sales Started",
		show_name: true,
		search_key: ["min_start_date", "max_start_date"],
		exclude_stage: ["pipeline"],
		filter_type: "building",
		fiter_key: "sales_start_date",
		date_filter: true,
		search_value: ['sales_start_date:[{min_start_date} TO {max_start_date}]']		
	},
	
	{
		name: "Offering Submitted",
		show_name: true,
		search_key: ["min_submitted_date", "max_submitted_date"],
		date_filter: true,
		filter_type: "building",
		fiter_key: "offering_submitted_date",
		search_value: ['offering_submitted_date:[{min_submitted_date} TO {max_submitted_date}]']		
	},
	{
		name: "Offering Accepted",
		show_name: true,
		search_key: ["min_accepted_date", "max_accepted_date"],
		date_filter: true,
		filter_type: "building",
		fiter_key: "offering_accepted_date",
		search_value: ['offering_accepted_date:[{min_accepted_date} TO {max_accepted_date}]']		
	},
	{
		name: "Offering Effective",
		show_name: true,
		search_key: ["min_effective_date", "max_effective_date"],
		date_filter: true,
		filter_type: "building",
		fiter_key: "offering_effective_date",
		search_value: ['offering_effective_date:[{min_effective_date} TO {max_effective_date}]']		
	},
	// {
	// 	name: "Sales Started",
	// 	show_name: true,
	// 	exclude_stage: ["pipeline"],
	// 	search_key: "sales_start_date",
	// 	filter_type: "building",
	// 	multi_filter: true,
	// 	values: [
	// 		{
	// 			name: "Any",
	// 			filter: "",
	// 			dropdown_name: "Any",
	// 			search_value: ""
	// 		},
	// 		{
	// 			name: "Not Started",
	// 			filter: "not-started",
	// 			dropdown_name: "Not Started",
	// 			search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
	// 		},
	// 		{
	// 			name: "Has Started",
	// 			filter: "has-started",
	// 			dropdown_name: "Has Started",
	// 			search_value: "[NOW-15YEARS TO NOW]",
	// 		},
	// 		{
	// 			name: "Within 1 Year",
	// 			filter: "1-year",
	// 			dropdown_name: "Within 1 Year",
	// 			search_value: "[NOW-1YEARS TO NOW]",
	// 		},
	// 		{
	// 			name: "1 to 2 Years",
	// 			filter: "2-year",
	// 			dropdown_name: "Last 1 to 2 Years",
	// 			search_value: "[NOW-2YEARS TO NOW-1YEARS]",
	// 		},
	// 		{
	// 			name: "2 to 5 Years",
	// 			filter: "2-5-year",
	// 			dropdown_name: "Last 2 to 5 Years",
	// 			search_value: "[NOW-5YEARS TO NOW-2YEARS]",
	// 		},
	// 		{
	// 			name: "5 to 7 Years",
	// 			filter: "5-7-year",
	// 			dropdown_name: "Last 5 to 7 Years",
	// 			search_value: "[NOW-7YEARS TO NOW-5YEARS]",
	// 		},
	// 		{
	// 			name: "7 to 10 Years",
	// 			filter: "7-10-year",
	// 			dropdown_name: "Last 5 to 10 Years",
	// 			search_value: "[NOW-10YEARS TO NOW-7YEARS]",
	// 		},
	// 		{
	// 			name: "10 to 15 Years",
	// 			filter: "10-15-year",
	// 			dropdown_name: "Last 10 to 15 Years",
	// 			search_value: "[NOW-15YEARS TO NOW-10YEARS]",
	// 		},
	// 		{
	// 			name: "More Than 15 Years",
	// 			filter: "more-15-year",
	// 			dropdown_name: "More Than 15 Years Ago",
	// 			search_value: "[NOW-20YEARS TO NOW-15YEARS]",
	// 		}				
	// 	]
	// },
	{
		name: "Building Last Listing",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "last_listed_sale_date_formatted",
		filter_type: "building",
		search_key: ["min_listed_date", "max_listed_date"],
		date_filter: true,
		search_value: ['last_listed_sale_date_formatted:[{min_listed_date} TO {max_listed_date}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Building Last Sale",
		show_name: true,
		exclude_stage: ["pipeline"],
		filter_type: "building",
		multi_filter:true,
		search_key: ["min_sold_date", "max_sold_date"],
		date_filter: true,
		search_value: ['last_sold_date_formatted:[{min_sold_date} TO {max_sold_date}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -offering_verified_status:\"sold out\"",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW] OR offering_verified_status:\"sold out\"",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},	
/*
	{
		name: "Source",
		show_name: false,
		search_key: "source_organizations",
		default_name: 'All Sources',
		building_hide: false,
		admin_only: true,
		values: [
			{
				name: "All",
				filter: "",
				dropdown_name: "All",
				search_value: ""
			},
			{
				name: "Offering Plan (NYAG)",
				filter: "nyag",
				dropdown_name: "Offering Plan (NYAG)",
				search_value: "NYAG",
			},
			{
				name: "Condo Declaration (CDEC)",
				filter: "cdec",
				dropdown_name: "Condo Declaration (CDEC)",
				search_value: "CDEC",
			},
			{
				name: "Amended Condo Declaration (CDEC)",
				filter: "adec",
				dropdown_name: "Amended Condo Declaration (CDEC)",
				search_value: "ADEC",
			},
			{
				name: "City Records",
				filter: "city-records",
				dropdown_name: "City Records",
				search_value: '* AND (verification_source:* OR secondary_normalization_grade:A) AND (property_type:("single residential condo unit" OR "single residential coop unit" OR "condo unit" OR "coop unit" OR "co-op unit" OR "condop unit"))',
			}
		]
	},	
*/	
	
];

export const _buildingComingSoonFilters = [
	{
		name: "Borough",
		show_name: true,
		default_name: 'All Boroughs',
		search_key: "borough",
		values: [
			{
				name: "All Boroughs",
				filter: "",
				dropdown_name: "All Boroughs",
				search_value: ""
			},
			{
				name: "Manhattan",
				filter: "manhattan",
				dropdown_name: "Manhattan",
				search_value: "manhattan",
			},
			{
				name: "Brooklyn",
				filter: "brooklyn",
				dropdown_name: "Brooklyn",
				search_value: "brooklyn",
			},
			{
				name: "Queens",
				filter: "queens",
				dropdown_name: "Queens",
				search_value: "queens",
			},
		]
	},
	{
		name: "Construction",
		show_name: true,
		search_key: "sales_start_date",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready",
				search_value: " * AND building_effective_start:[NOW-25YEARS TO NOW]",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction",
				search_value: "[NOW-5YEARS TO NOW] AND -building_effective_start:[NOW-25YEARS TO NOW]",
			}
			
		]
	},	
	{
		name: "Price / Sq Ft",
		show_name: true,
		search_key: "inventory_stats_listings_average_ppsf",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: "[100 TO 750]"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "[751 TO 1000]"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "[1001 TO 1500]"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "[1500 TO 2000]"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: "[2001 to 2500]"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "[2501 to 5000]"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "[5001 TO *]"
			}
		]
	},
	{
		name: "Starting From",
		show_name: true,
		search_key: "inventory_stats_listings_low_price",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "From: < $500,000 ",
				filter: "less-than-500",
				dropdown_name: "Less than $500K",
				search_value: "[100 TO 500000]"
			},
			{
				name: "From: $500K to $750K",
				filter: "500-to-750",
				dropdown_name: "$500K to $750K",
				search_value: "[500000 TO 750000]"
			},
			{
				name: "From: $750K to $1M",
				filter: "750-to-1000",
				dropdown_name: "$750K to $1M",
				search_value: "[750001 TO 1000000]"
			},
			{
				name: "From: $1M to $1.5M",
				filter: "1000-to-1500",
				dropdown_name: "$1M to $1.5M",
				search_value: "[1000001 TO 1500000]"
			},
			{
				name: "From: $1.5M to $2M",
				filter: "1500-to-2000",
				dropdown_name: "$1.5M to $2M",
				search_value: "[1500001 TO 2000000]"
			},
			{
				name: "From: $2M+",
				filter: "2000+",
				dropdown_name: "$2M+",
				search_value: "[2000000 TO *]"
			}
		]
	},
	
	{
		name: "Unsold Units",
		show_name: true,
		search_key: "inventory_unsold",
		values: [
			{
				name: "Any Unsold Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Unsold Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Unsold Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Unsold Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Unsold Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Unsold Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Unsold Units",
				filter: "201-or-more",
				dropdown_name: "201 or more",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "% Sold",
		show_name: true,
		search_key: "inventory_pct_sold",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Sales",
				filter: "has-sales",
				dropdown_name: "Has Sales",
				search_value: "[1 TO *] OR offering_verified_status:\"sold out\""
			},
			{
				name: "Has No Sales",
				filter: "no-sales",
				dropdown_name: "Has No Sales",
				search_value: "[* TO 0] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Less Than 15%",
				filter: "0-to-15",
				dropdown_name: "Less Than 15%",
				search_value: "[0 TO 15]"
			},
			{
				name: "Less Than 35%",
				filter: "0-to-35",
				dropdown_name: "Less Than 35%",
				search_value: "[0 TO 34]"
			},
			{
				name: "Less Than 50%",
				filter: "0-to-49",
				dropdown_name: "Less Than 50%",
				search_value: "[0 TO 49]"
			},
			{
				name: "More Than 15%",
				filter: "15-to-100",
				dropdown_name: "More Than 15%",
				search_value: "[15 TO *]"
			},
			{
				name: "More Than 50%",
				filter: "50-to-100",
				dropdown_name: "More Than 50%",
				search_value: "[50 TO *]"
			},
			{
				name: "50% to 79%",
				filter: "50-to-79",
				dropdown_name: "50% to 79%",
				search_value: "[50 TO 79]"
			},
			{
				name: "80% to 99%",
				filter: "80-to-99",
				dropdown_name: "80% to 99%",
				search_value: "[80 TO 99]"
			},
			{
				name: "Not Sold Out",
				filter: "not-sold-out",
				dropdown_name: "Not Sold Out",
				search_value: "[* TO 99] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Sold Out",
				filter: "sold-out",
				dropdown_name: "Sold Out",
				search_value: "[100 TO *]"
			},
			
		]
	},
	{
		name: "Building Size",
		show_name: true,
		search_key: "inventory_total",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Condo Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10 Units",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Condo Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Condo Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Condo Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Condo Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Condo Units",
				filter: "201-or-more",
				dropdown_name: "201 or more Units",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "Status",
		show_name: true,
		search_key: "offering_verified_status",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Coming Soon",
				filter: "coming-soon",
				dropdown_name: "Coming Soon",
				search_value: '("coming soon")'
			},
			{
				name: "Submit Offering Plan",
				filter: "submit-offering-plan",
				dropdown_name: "Submit Offering Plan",
				search_value: '("offering plan required")'
			},
			{
				name: "Condo Dec Vetted",
				filter: "condo-dec-vetted",
				dropdown_name: "Condo Dec Vetted",
				search_value: '("condo declaration vetted")'
			},
		]
	}	
];
export const _houseAdminFilters = [
{
	name: "Type",
	show_name: true,
	search_key: "ownership_type",
	multi_filter:true,
	values: [
		{
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: ""
		},	
		{
			name: "Single-Family",
			filter: "single-family",
			dropdown_name: "Single-Family",
			search_value: "(single?family) AND total_units:[1 TO 1]",
		},			
		{
			name: "Multi-Family (All)",
			filter: "multi-family",
			dropdown_name: "Multi-Family (All)",
			search_value: "(multi?family) OR (ownership_type:rental AND total_units:[* TO 3])",
		},			
		{
			name: "Multi-Family (2 Units)",
			filter: "two-family",
			dropdown_name: "Multi-Family (2 Units)",
			search_value: "((multi?family) OR (ownership_type:rental AND total_units:[* TO 3])) AND total_units:[2 TO 2]",
		},			
		{
			name: "Multi-Family (3 Units)",
			filter: "three-family",
			dropdown_name: "Multi-Family (3 Units)",
			search_value: "((multi?family) OR (ownership_type:rental AND total_units:[* TO 3])) AND total_units:[3 TO 3]",
		},			
		{
			name: "Multi-Family (4+ Units)",
			filter: "four-family",
			dropdown_name: "Multi-Family (4+ Units)",
			search_value: "((multi?family) OR (ownership_type:rental AND total_units:[* TO 3])) AND total_units:[4 TO 6]",
		}
		
	]
},
{
	name: "Status",
	show_name: true,
	search_key: "current_status",
	multi_filter: true,
	values: [
		{
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: ""
		},
		{
			name: "Active",
			filter: "active",
			dropdown_name: "Active",
			search_value: "(active)"
		},
		{
			name: "Coming Soon",
			filter: "coming-soon",
			dropdown_name: "Coming Soon",
			search_value: "(\"coming soon\")"
		},
		{
			name: "In Contract",
			filter: "in-contract",
			dropdown_name: "In Contract",
			search_value: "(\"in contract\")"
		},
		{
			name: "Off Market",
			filter: "off-market",
			dropdown_name: "Off Market",
			search_value: "(sold shadow not_yet_recorded transfer \"bulk sold\" \"\" \"never listed\")"
		}
	]
},
{
	name: "Asking Price",
	show_name: true,
	search_key: ["min_listed_price", "max_listed_price"],
	filter_key : "listed_price",
	price_filter: true,
	search_value: ['last_listed_sale_price_all:[{min_listed_price} TO {max_listed_price}]']		
},
{
	name: "Asking PPSF",
	show_name: true,
	search_key: ["min_listed_ppsf_price", "max_listed_ppsf_price"],
	filter_key : "listed_ppsf",
	price_filter: true,
	target: 'fq',
	labels : ["Min PPSF", "Max PPSF"],
	min_max_values : [500, 4500],
	min_max_increment : 500,
	format_type : "number",
	search_value: ['{!frange l={min_listed_ppsf_price} u={max_listed_ppsf_price}}div(last_listed_sale_price_all,sq_ft)'],
	
	
},
	{
	name: "Bedrooms",
	show_name: true,
	search_key: "bedrooms",
	multi_filter: true,
	values: [
		{
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: ""
		},
		{
			name: "Studio",
			filter: "studio",
			dropdown_name: "Studio",
			search_value: "0",
		},
		{
			name: "1 Bedroom",
			filter: "1-bedroom",
			dropdown_name: "1 Bedroom",
			search_value: "1",
		},
		{
			name: "2 Bedroom",
			filter: "2-bedroom",
			dropdown_name: "2 Bedroom",
			search_value: "2",
		},
		{
			name: "3 Bedroom",
			filter: "3-bedroom",
			dropdown_name: "3 Bedroom",
			search_value: "3",
		},
		{
			name: "4+ Bedroom",
			filter: "4-or-more",
			dropdown_name: "4+ Bedroom",
			search_value: "[4 TO *]",
		},
	]
},
{
	name: "Baths",
	show_name: true,
	search_key: "baths",
	multi_filter: true,
	values: [
		{
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: ""
		},			
		{
			name: "1 Bath",
			filter: "1-bath",
			dropdown_name: "1 Bath",
			search_value: "1 OR baths:1",
		},
		{
			name: "2 Bath",
			filter: "2-bath",
			dropdown_name: "2 Bath",
			search_value: "2 OR baths:2",
		},
		{
			name: "3 Bath",
			filter: "3-bath",
			dropdown_name: "3 Bath",
			search_value: "3 OR baths:3",
		},
		{
			name: "4+ Bath",
			filter: "4-or-more",
			dropdown_name: "4+ Bath",
			search_value: "[4 TO *] OR baths:[4 TO *]",
		},
	]
},
{
	name: "Amenities",
	show_name: true,
	search_key: "building_features",
	autocomplete: true,
	core_name: "we3-properties",
	fq: ["deleted:false"],
	group_amenities: true
},
{
		name: "Square Feet",
		show_name: true,
		search_key: ["min_sq_ft", "max_sq_ft"],
		filter_key : "sq_ft",
		price_filter: true,
		labels : ["Min Sq Ft", "Max Sq Ft"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['sq_ft:[{min_sq_ft} TO {max_sq_ft}]'], 
		value_prefix : "",
		title_suffix : "SF"
},	
{
	name: "Style",
	show_name: true,
	search_key: "building_class",
	multi_filter:true,
	values: [
		{
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: ""
		},	
		{
			name: "Townhouse",
			filter: "townhouse",
			dropdown_name: "Townhouse",
			search_value: "(A7 A4 A9 B1 B2 B3 B9) OR building_style:townhouse",
		},			
		{
			name: "Detached House",
			filter: "detached-house",
			dropdown_name: "Detached House",
			search_value: "(A1) AND -building_style:townhouse",
		},			
		{
			name: "Semi-Detached House",
			filter: "semi-detached-house",
			dropdown_name: "Semi-Detached House",
			search_value: "(A5) AND -building_style:townhouse",
		}
		
	]
},


	{
		name: "Features",
		show_name: true,
		search_key: "building_features",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},	
			{
				name: "Basement",
				filter: "basement",
				dropdown_name: "Basement",
				search_value: "(basement)",
			},
			{
				name: "Outdoor Space",
				filter: "outdoor",
				dropdown_name: "Outdoor Space",
				search_value: "(patio deck garden deck terrace porch)",
			},		
			{
				name: "Garage",
				filter: "garage",
				dropdown_name: "Garage",
				search_value: "(garage)",
			},	
			{
				name: "Driveway",
				filter: "driveway",
				dropdown_name: "Driveway",
				search_value: "(driveway)",
			},
			{
				name: "Elevator",
				filter: "elevator",
				dropdown_name: "Elevator",
				search_value: "(elevator)",
			},			
			{
				name: "Fireplace",
				filter: "fireplace",
				dropdown_name: "Fireplace",
				search_value: "(fireplace)",
			}
			
		]
	},

	{
			name: "Building Width",
			show_name: true,
			filter_type:'building',
			search_key: ["min_building_width", "max_building_width"],
			filter_key : "lot_frontage",
			price_filter: true,
			labels : ["Min Width", "Max Width"],
			min_max_values : [10, 40],
			min_max_increment : 5,
			format_type : "number",
			search_value: ['building_frontage:[{min_building_width} TO {max_building_width}]'], 
			value_prefix : "",
			title_suffix : "FT"
	},	
	{
			name: "Lot Width",
			show_name: true,
			filter_type:'building',
			search_key: ["min_lot_width", "max_lot_width"],
			filter_key : "lot_frontage",
			price_filter: true,
			labels : ["Min Width", "Max Width"],
			min_max_values : [10, 40],
			min_max_increment : 5,
			format_type : "number",
			search_value: ['lot_frontage:[{min_lot_width} TO {max_lot_width}]'], 
			value_prefix : "",
			title_suffix : "FT"
	},	
	{
			name: "Lot Size",
			show_name: true,
			filter_type:'building',
			search_key: ["min_lot_area", "max_lot_area"],
			filter_key : "lot_area",
			price_filter: true,
			labels : ["Min Size", "Max Size"],
			min_max_values : [1000, 10000],
			min_max_increment : 1000,
			format_type : "number",
			search_value: ['lot_area:[{min_lot_area} TO {max_lot_area}]'], 
			value_prefix : "",
			title_suffix : "FT"
	},	
	{
		name: "Listed Date",
		show_name: true,
		filter_key: "listed_date",
		search_key: ["min_listed_date", "max_listed_date"],
		date_filter: true,
		search_value: ['last_listed_sale_date_all:[{min_listed_date} TO {max_listed_date}]']		
	},
	{
		name: "Contract Date",
		show_name: true,
		filter_key: "contract_date",
		search_key: ["min_contract_date", "max_contract_date"],
		date_filter: true,
		search_value: ['last_contract_date_formatted:[{min_contract_date} TO {max_contract_date}]']		
	},
	{
		name: "Sold Date",
		show_name: true,
		filter_key: "sold_date",
		search_key: ["min_date", "max_date"],
		date_filter: true,
		search_value: ['last_sold_date_formatted:[{min_date} TO {max_date}]']		
	},
	{
		name: "Sold Price",
		show_name: true,
		search_key: ["min_sold_price", "max_sold_price"],
		filter_key : "sold_price",
		price_filter: true,
		search_value: ['last_sold_price:[{min_sold_price} TO {max_sold_price}]']		
	},
	{
		name: "Sold PPSF",
		show_name: true,
		search_key: ["min_sold_ppsf_price", "max_sold_ppsf_price"],
		filter_key : "sold_ppsf",
		price_filter: true,
		target: 'fq',
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['{!frange l={min_sold_ppsf_price} u={max_sold_ppsf_price}}div(last_sold_price,sq_ft)'],
		
		
	},
	{
		name: "Total Units",
		show_name: true,
		search_key: ["min_units", "max_units"],
		filter_key : "total_units",
		price_filter: true,
		labels : ["Min Units", "Max Units"],
		min_max_values : [1, 6],
		min_max_increment : 1,
		custom_min_max_values : {
			"min" : [1, 2, 3, 4, 5],
			"max" : [2, 3, 4, 5, 6]
		},		
		format_type : "number",
		search_value: ['total_units:[{min_units} TO {max_units}]'], 
		value_prefix : "",	
		title_suffix : "Units",
		
	},
	{
		name: "Year Built",
		show_name: true,
		exclude_stage: ["pipeline"],
		format_type : "number",
		search_key: ["min_year_built", "max_year_built"],
		filter_key : "year_built",
		price_filter: true,
		labels : ["Min Year Built", "Max Year Built"],
		min_max_values : [1900, current_year],
		min_max_increment : 10,
		custom_min_max_values : {
			"min" : [1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020],
			"max" : [1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, current_year]
		},		
		format_type : "year",
		search_value: ['year_built:[{min_year_built} TO {max_year_built}]'], 
		value_prefix : "",	
		title_suffix : ""
	},
	{
		name: "Year Renovated",
		show_name: true,
		exclude_stage: ["pipeline"],
		format_type : "number",
		filter_type: "building",
		search_key: ["min_year_converted", "max_year_converted"],
		filter_key : "year_converted",
		price_filter: true,
		labels : ["Min Year Renovated", "Max Year Renovated"],
		min_max_values : [1965, current_year],
		min_max_increment : 10,
		custom_min_max_values : {
			"min" : [1965, 1970, 1980, 1990, 2000, 2010, 2020],
			"max" : [1970, 1980, 1990, 2000, 2010, 2020, current_year]
		},		
		format_type : "year",
		search_value: ['year_converted:[{min_year_converted} TO {max_year_converted}]', 'year_altered_1:[{min_year_converted} TO {max_year_converted}]', 'year_altered_2:[{min_year_converted} TO {max_year_converted}]'], 
		value_prefix : "",	
		title_suffix : "",
	},
	// {
	// 		name: "Year Renovated",
	// 		show_name: true,
	// 		filter_type:'building',
	// 		search_key: ["min_year_renovated", "max_year_renovated"],
	// 		filter_key : "year_altered_1",
	// 		price_filter: true,
	// 		labels : ["Min Year", "Max Year"],
	// 		min_max_values : [2000, 2024],
	// 		min_max_increment : 10,
	// 		format_type : "number",
	// 		search_value: ['year_altered_1:[{min_year_renovated} TO {max_year_renovated}]'], 
	// 		value_prefix : "",
	// 		title_suffix : ""
	// }
	
]

export const _buildingAdminFilters = [
	{
		name: "Ownership",
		show_name: true,
		search_key: "ownership_type",
		multi_filter:true,
		hide_velocity:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "(*condo* !condo*p)",
			},
			{
				name: "Co-op",
				filter: "coop",
				dropdown_name: "Co-op",
				search_value: "(*co?op* *coop* !condo*p)",
			},			
			{
				name: "Condop",
				filter: "condop",
				dropdown_name: "Condop",
				search_value: "(condop*)",
			}
			
		]
	},
	
	{
		name: "Residential Units",
		show_name: true,
/*
		search_key: "residential_units",
		multi_filter:true,
*/
		search_key: ["min_units", "max_units"],
		filter_key : "inventory_total",
		price_filter: true,
		labels : ["Min Units", "Max Units"],
		min_max_values : [25, 200],
		min_max_increment : 25,
		custom_min_max_values : {
			"min" : [1, 11, 31, 51, 101, 201],
			"max" : [10, 30, 50, 100, 200, 300]
		},		
		format_type : "number",
		search_value: ['inventory_total:[{min_units} TO {max_units}]'], 
		value_prefix : "",	
		title_suffix : "Units",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Condo Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10 Units",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Condo Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Condo Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Condo Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Condo Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Condo Units",
				filter: "201-or-more",
				dropdown_name: "201 or more Units",
				search_value: "[201 TO *]"
			}
		]
	},
	
	{
		name: "Unsold Units",
		show_name: true,
		exclude_stage: ["pipeline"],
/*
		search_key: "inventory_unsold",
		multi_filter:true,
*/
		search_key: ["min_unsold_units", "max_unsold_units"],
		filter_key : "inventory_unsold",
		price_filter: true,
		labels : ["Min Unsold Units", "Max Unsold Units"],
		min_max_values : [25, 200],
		min_max_increment : 25,
		custom_min_max_values : {
			"min" : [1, 11, 31, 51, 101, 201],
			"max" : [10, 30, 50, 100, 200, 300]
		},		
		format_type : "number",
		search_value: ['inventory_unsold:[{min_unsold_units} TO {max_unsold_units}]'], 
		value_prefix : "",	
		title_suffix : "Units",
		values: [
			{
				name: "Any Unsold Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Unsold Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Unsold Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Unsold Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Unsold Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Unsold Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Unsold Units",
				filter: "201-or-more",
				dropdown_name: "201 or more",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "Amenities",
		show_name: true,
		search_key: "building_features",
		autocomplete: true,
		core_name: "we3-buildings",
		fq: ["deleted:false"],
		group_amenities: true
	},
	{
		name: "% Sold",
		show_name: true,
		exclude_stage: ["pipeline"],
		//search_key: "inventory_pct_sold",
		search_key: ["min_pct", "max_pct"],
		filter_key : "inventory_pct_sold",
		price_filter: true,
		labels : ["Min %", "Max %"],
		min_max_values : [10, 100],
		min_max_increment : 10,
		format_type : "number",
		search_value: ['inventory_pct_sold:[{min_pct} TO {max_pct}]'], 
		value_prefix : "",	
		value_suffix : "%",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Sales",
				filter: "has-sales",
				dropdown_name: "Has Sales",
				search_value: "[1 TO *] OR offering_verified_status:\"sold out\""
			},
			{
				name: "Has No Sales",
				filter: "no-sales",
				dropdown_name: "Has No Sales",
				search_value: "[* TO 0] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Less Than 15%",
				filter: "0-to-15",
				dropdown_name: "Less Than 15%",
				search_value: "[0 TO 15]"
			},
			{
				name: "Less Than 35%",
				filter: "0-to-35",
				dropdown_name: "Less Than 35%",
				search_value: "[0 TO 34]"
			},
			{
				name: "Less Than 50%",
				filter: "0-to-49",
				dropdown_name: "Less Than 50%",
				search_value: "[0 TO 49]"
			},
			{
				name: "More Than 15%",
				filter: "15-to-100",
				dropdown_name: "More Than 15%",
				search_value: "[15 TO *]"
			},
			{
				name: "More Than 50%",
				filter: "50-to-100",
				dropdown_name: "More Than 50%",
				search_value: "[50 TO *]"
			},
			{
				name: "50% to 79%",
				filter: "50-to-79",
				dropdown_name: "50% to 79%",
				search_value: "[50 TO 79]"
			},
			{
				name: "80% to 99%",
				filter: "80-to-99",
				dropdown_name: "80% to 99%",
				search_value: "[80 TO 99]"
			},
			{
				name: "Not Sold Out",
				filter: "not-sold-out",
				dropdown_name: "Not Sold Out",
				search_value: "[* TO 99] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Sold Out",
				filter: "sold-out",
				dropdown_name: "Sold Out",
				search_value: "[100 TO *]"
			}
		]
	},
	
	/*{
		name: "Building Stage",
		show_name: true,
		search_key: "state",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Pipeline",
				filter: "pipeline",
				dropdown_name: "Pipeline",
				search_value: "* AND offering_submitted_date:[NOW-25YEARS TO NOW] AND offering_accepted_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -offering_verified_status:(\"sold out\")",
			},
			{
				name: "Sponsor Sale",
				filter: "sponsor",
				dropdown_name: "Sponsor Sale",
				search_value: "* AND offering_accepted_date:[NOW-25YEARS TO NOW] AND inventory_resale:[* TO 0] AND -offering_verified_status:(\"sold out\")",
			},
			{
				name: "Resale",
				filter: "resale",
				dropdown_name: "Resale",
				search_value: "* AND offering_effective_date:[NOW-25YEARS TO NOW] AND (inventory_resale:[1 TO *] OR offering_verified_status:(\"sold out\"))",
			}
			
		]
	},*/
	
	
	{
		name: "Asking PPSF",
		show_name: true,
/*
		search_key: "inventory_stats_listings_average_ppsf",
		multi_filter:true,
*/
		search_key: ["min_ppsf_price", "max_ppsf_price"],
		filter_key : "asking_ppsf",
		price_filter: true,
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['inventory_stats_listings_average_ppsf:[{min_ppsf_price} TO {max_ppsf_price}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: "[100 TO 750]"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "[751 TO 1000]"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "[1001 TO 1500]"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "[1500 TO 2000]"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: "[2001 to 2500]"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "[2501 to 5000]"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "[5001 TO *]"
			}
		]
	},
	{
			name: "Sold PPSF",
			show_name: true,
			search_key: ["min_sold_ppsf_price", "max_sold_ppsf_price"],
			filter_key : "sold_ppsf",
			price_filter: true,
			labels : ["Min PPSF", "Max PPSF"],
			min_max_values : [500, 4500],
			min_max_increment : 500,
			format_type : "number",
			search_value: ['inventory_stats_transactions_average_ppsf:[{min_sold_ppsf_price} TO {max_sold_ppsf_price}]'],
		},
	{
		name: "Starting From",
		show_name: true,
		search_key: "inventory_stats_listings_low_price",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "From: < $500,000 ",
				filter: "less-than-500",
				dropdown_name: "Less than $500K",
				search_value: "[100 TO 500000]"
			},
			{
				name: "From: $500K to $750K",
				filter: "500-to-750",
				dropdown_name: "$500K to $750K",
				search_value: "[500000 TO 750000]"
			},
			{
				name: "From: $750K to $1M",
				filter: "750-to-1000",
				dropdown_name: "$750K to $1M",
				search_value: "[750001 TO 1000000]"
			},
			{
				name: "From: $1M to $1.5M",
				filter: "1000-to-1500",
				dropdown_name: "$1M to $1.5M",
				search_value: "[1000001 TO 1500000]"
			},
			{
				name: "From: $1.5M to $2M",
				filter: "1500-to-2000",
				dropdown_name: "$1.5M to $2M",
				search_value: "[1500001 TO 2000000]"
			},
			{
				name: "From: $2M+",
				filter: "2000+",
				dropdown_name: "$2M+",
				search_value: "[2000000 TO *]"
			}
		]
	},
	{
		name: "Tax Benefits",
		show_name: true,
		search_key: "tax_benefits",
		target : "fq",
		multi_filter : true,
		join_query : "{!join from=alternate_buildings fromIndex=we3-tax-abatements-subsidies to=key}",
		values: [
			
			{
				name: "421a",
				filter: "421a",
				dropdown_name: "421a",
				search_value: "(program_421a:true)",
			},
			{
				name: "J51",
				filter: "j51",
				dropdown_name: "J51",
				search_value: "(program_j51:true)",
			},
			{
				name: "421a (10+ Years Left)",
				filter: "421a-10",
				dropdown_name: "421a (10+ Years Left)",
				search_value: "(program_421a_end_date:[NOW+10YEAR TO *])",
			},
			{
				name: "421a (5-10 Years Left)",
				filter: "421a-5",
				dropdown_name: "421a (5-10 Years Left)",
				search_value: "(program_421a_end_date:[NOW+5YEAR TO *])",
			},
			{
				name: "421a (<5 Years Left)",
				filter: "421a-4",
				dropdown_name: "421a (<5 Years Left)",
				search_value: "(program_421a_end_date:[NOW TO NOW+5YEAR])",
			},
			{
				name: "421a (Expired)",
				filter: "421a-exp",
				dropdown_name: "421a (Expired)",
				search_value: "(program_421a_end_date:[* TO NOW])",
			},
			
			{
				name: "J51 (10+ Years Left)",
				filter: "j51-10",
				dropdown_name: "J51 (10+ Years Left)",
				search_value: "(program_j51:true AND program_j51_end_date:[NOW+10YEAR TO *])",
			},
			{
				name: "J51 (5-10 Years Left)",
				filter: "j51-5",
				dropdown_name: "J51 (5-10 Years Left)",
				search_value: "(program_j51:true AND program_j51_end_date:[NOW+5YEAR TO NOW+10YEAR])",
			},
			{
				name: "J51 (<5 Years Left)",
				filter: "j51-4",
				dropdown_name: "J51 (<5 Years Left)",
				search_value: "(program_j51:true AND program_j51_end_date:[NOW TO NOW+5YEAR])",
			},
			{
				name: "J51 (Expired)",
				filter: "j51-exp",
				dropdown_name: "J51 (Expired)",
				search_value: "(program_j51_end_date:[* TO Now])",
			},
			
			
		]
	},
	{
		name: "Floors",
		show_name: true,
		search_key: ["min_floor", "max_floor"],
		filter_key : "floor",
		price_filter: true,
		// target: 'fq',
		labels : ["Min Floor", "Max Floor"],

		custom_min_max_values : {
			"min" : [1, 11, 21, 31, 41],
			"max" : [10, 20, 30, 40, 50,"No Max"]
		},	
		format_type : "number",
		search_value: ['floor:[{min_floor} TO {max_floor}]']		,
		format_type : "number",
		value_prefix : "",	
		no_max:true,
		
		
	},
	{
		name: "Construction",
		show_name: true,
		search_key: "building_effective_start",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready (Has CO/TCO)",
				search_value: "[NOW-25YEARS TO NOW] OR building_status:(resale) OR offering_verified_status:(\"sold out\")",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction (No CO/TCO)",
				search_value: "[* TO NOW-25YEARS] AND -building_status:(resale) AND -offering_verified_status:(\"sold out\")",
			}
			
		]
	},
	{
		name: "Offering Type",
		show_name: true,
		search_key: "offering_construction",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "New Construction",
				filter: "new-construction",
				dropdown_name: "New Construction",
				search_value: "new",
			},
			{
				name: "Conversion",
				filter: "conversion",
				dropdown_name: "Conversion",
				search_value: "conversion",
			},			
			{
				name: "Rehab",
				filter: "rehab",
				dropdown_name: "Rehab",
				search_value: "rehab",
			},
			{
				name: "Exclude Conversion",
				filter: "exclude-conversion",
				dropdown_name: "Exclude Conversion",
				search_value: "(new rehab \"\")",
			}
			
		]
	},
	// {
	// 	name: "Ownership",
	// 	show_name: true,
	// 	search_key: "ownership_type",
	// 	values: [
	// 		{
	// 			name: "Any",
	// 			filter: "",
	// 			dropdown_name: "Any",
	// 			search_value: ""
	// 		},
	// 		{
	// 			name: "Condo",
	// 			filter: "condo",
	// 			dropdown_name: "Condo",
	// 			search_value: "condo AND -ownership_type:cooperative",
	// 		},
	// 		{
	// 			name: "Co-op",
	// 			filter: "co-op",
	// 			dropdown_name: "Co-op",
	// 			search_value: "coop",
	// 		}
	// 		
	// 	]
	// },
	{
		name: "Building Age",
		show_name: true,
		search_key: "year_built",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: `New (After ${new_building_start})`,
				filter: "new-contruction",
				dropdown_name: `New (After ${new_building_start})`,
				search_value: `[ ${(new_building_start + 1)} TO *]`,
			},
			{
				name: `Recent (1991 to ${new_building_start})`,
				filter: "recent-construction",
				dropdown_name: `Recent (1991 to ${new_building_start})`,
				search_value: `[1991 TO ${new_building_start}]`,
			},
			{
				name: "Post-War (1946 to 1990)",
				filter: "post-war",
				dropdown_name: "Post-War (1946 to 1990)",
				search_value: "[1946 TO 1990]",
			},
			{
				name: "Pre-War (Before 1946)",
				filter: "pre-war",
				dropdown_name: "Pre-War (Before 1946)",
				search_value: "[1600 TO 1945]",
			}
			
		]
	},
	{
		name: "Year Built",
		show_name: true,
		exclude_stage: ["pipeline"],
		format_type : "number",
		search_key: ["min_year_built", "max_year_built"],
		filter_key : "year_built",
		price_filter: true,
		labels : ["Min Year Built", "Max Year Built"],
		min_max_values : [1900, current_year],
		min_max_increment : 10,
		custom_min_max_values : {
			"min" : [1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020],
			"max" : [1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, current_year]
		},		
		format_type : "year",
		search_value: ['year_built:[{min_year_built} TO {max_year_built}]'], 
		value_prefix : "",	
		title_suffix : ""
	},
	{
		name: "Year Converted",
		show_name: true,
		exclude_stage: ["pipeline"],
		format_type : "number",
		search_key: ["min_year_converted", "max_year_converted"],
		filter_key : "max_year_converted",
		price_filter: true,
		labels : ["Min Year Converted", "Max Year Converted"],
		min_max_values : [1965, current_year],
		min_max_increment : 10,
		custom_min_max_values : {
			"min" : [1965, 1970, 1980, 1990, 2000, 2010, 2020],
			"max" : [1970, 1980, 1990, 2000, 2010, 2020, current_year]
		},		
		format_type : "year",
		search_value: ['year_converted:[{min_year_converted} TO {max_year_converted}]'], 
		value_prefix : "",	
		title_suffix : "",
	},
	{
		name: "Sales Started",
		show_name: true,
		search_key: ["min_date", "max_date"],
		exclude_stage: ["pipeline"],
		date_filter: true,
		search_value: ['sales_start_date:[{min_date} TO {max_date}]']		
	},
	{
		name: "Closing Started",
		show_name: true,
		search_key: ["min_close_date", "max_close_date"],
		exclude_stage: ["pipeline"],
		date_filter: true,
		search_value: ['first_closing_date:[{min_close_date} TO {max_close_date}]']		
	},
	{
		name: "Sponsor PPSF",
		show_name: true,
		search_key: ["min_sponsor_sold_ppsf_price", "max_sponsor_sold_ppsf_price"],
		filter_key : "sponsor_sold_ppsf",
		price_filter: true,
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['inventory_stats_transactions_average_sponsor_ppsf:[{min_sponsor_sold_ppsf_price} TO {max_sponsor_sold_ppsf_price}]'],
	},
	{
		name: "Resale PPSF",
		show_name: true,
		search_key: ["min_resale_sold_ppsf_price", "max_resale_sold_ppsf_price"],
		filter_key : "sponsor_sold_ppsf",
		price_filter: true,
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['inventory_stats_transactions_average_resale_ppsf:[{min_resale_sold_ppsf_price} TO {max_resale_sold_ppsf_price}]'],
	},
	{
		name: "Offering Submitted",
		show_name: true,
		search_key: ["min_submitted_date", "max_submitted_date"],
		date_filter: true,
		search_value: ['offering_submitted_date:[{min_submitted_date} TO {max_submitted_date}]']		
	},
	{
		name: "Offering Accepted",
		show_name: true,
		search_key: ["min_accepted_date", "max_accepted_date"],
		date_filter: true,
		search_value: ['offering_accepted_date:[{min_accepted_date} TO {max_accepted_date}]']		
	},
	{
		name: "Offering Effective",
		show_name: true,
		search_key: ["min_effective_date", "max_effective_date"],
		date_filter: true,
		search_value: ['offering_effective_date:[{min_effective_date} TO {max_effective_date}]']		
	},
/*	{
		name: "Sales Started",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "sales_start_date",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Not Started",
				filter: "not-started",
				dropdown_name: "Not Started",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Has Started",
				filter: "has-started",
				dropdown_name: "Has Started",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "5 to 7 Years",
				filter: "5-7-year",
				dropdown_name: "Last 5 to 7 Years",
				search_value: "[NOW-7YEARS TO NOW-5YEARS]",
			},
			{
				name: "7 to 10 Years",
				filter: "7-10-year",
				dropdown_name: "Last 5 to 10 Years",
				search_value: "[NOW-10YEARS TO NOW-7YEARS]",
			},
			{
				name: "10 to 15 Years",
				filter: "10-15-year",
				dropdown_name: "Last 10 to 15 Years",
				search_value: "[NOW-15YEARS TO NOW-10YEARS]",
			},
			{
				name: "More Than 15 Years",
				filter: "more-15-year",
				dropdown_name: "More Than 15 Years Ago",
				search_value: "[NOW-20YEARS TO NOW-15YEARS]",
			}			
		]
	}, */
	{
		name: "Last Listing",
		show_name: true,
		exclude_stage: ["pipeline"],
		//search_key: "last_listed_sale_date_formatted",
		multi_filter:true,
		search_key: ["min_listed_date", "max_listed_date"],
		date_filter: true,
		search_value: ['last_listed_sale_date_formatted:[{min_listed_date} TO {max_listed_date}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -building_status:resale AND -offering_verified_status:\"sold out\"",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW] OR offering_verified_status:\"sold out\" OR building_status:resale",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Last Contract",
		show_name: true,
		exclude_stage: ["pipeline"],
		//search_key: "last_contract_date_formatted",
		multi_filter:true,
		search_key: ["min_contract_date", "max_contract_date"],
		date_filter: true,
		search_value: ['last_contract_date_formatted:[{min_contract_date} TO {max_contract_date}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -building_status:resale AND -offering_verified_status:\"sold out\" AND -inventory_sold:[1 TO *] AND -inventory_contract:[1 TO *]",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW] OR offering_verified_status:\"sold out\" OR building_status:resale",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Last Sale",
		show_name: true,
		exclude_stage: ["pipeline"],
		//search_key: "last_sold_date_formatted",
		multi_filter:true,
		search_key: ["min_sold_date", "max_sold_date"],
		date_filter: true,
		search_value: ['last_sold_date_formatted:[{min_sold_date} TO {max_sold_date}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -offering_verified_status:\"sold out\" AND inventory_sold:[* TO 0]",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW] OR offering_verified_status:\"sold out\"",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Offering Status",
		show_name: true,
		admin_only: true,
		search_key: "offering_submitted_date",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "All Submitted",
				filter: "submitted",
				dropdown_name: "All Submitted",
				search_value: "[NOW-25YEARS TO NOW]",
			},
			{
				name: "All Accepted",
				filter: "accepted",
				dropdown_name: "All Accepted",
				search_value: "[NOW-25YEARS TO NOW] AND offering_accepted_date:[NOW-25YEARS TO NOW]",
			},
			{
				name: "All Effective",
				filter: "effective",
				dropdown_name: "All Effective",
				search_value: "[NOW-25YEARS TO NOW] AND offering_effective_date:[NOW-25YEARS TO NOW]",
			},
			{
				name: "Only Submitted",
				filter: "submitted-only",
				dropdown_name: "Only Submitted",
				search_value: "[NOW-25YEARS TO NOW] AND offering_accepted_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Only Accepted",
				filter: "accepted-only",
				dropdown_name: "Only Accepted",
				search_value: "[NOW-25YEARS TO NOW] AND offering_accepted_date:[NOW-25YEARS TO NOW] AND offering_effective_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Only Effectiv Only",
				filter: "effective-only",
				dropdown_name: "Only Effective",
				search_value: "[NOW-25YEARS TO NOW] AND offering_effective_date:[NOW-25YEARS TO NOW]",
			}
			
		]
	},
	{
		name: "Condo Dec",
		show_name: true,
		exclude_stage: ["pipeline", "resale"],
		search_key: "cdec_date",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Condo Dec",
				filter: "no-cdec",
				dropdown_name: "No Condo Dec",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND building_status:(pipeline sponsor)",
			},
			{
				name: "Has Condo Dec",
				filter: "has-cdec",
				dropdown_name: "Has Condo Dec",
				search_value: "[1970-01-05T00:00:00Z TO NOW] OR building_status:(resale)",
			}			
		]
	},
	{
		name: "Status",
		show_name: true,
		search_key: "offering_verified_status",
		admin_only: true,
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Monitoring",
				filter: "monitoring",
				dropdown_name: "Monitoring",
				search_value: 'monitoring'
			},
			{
				name: "Reviewed",
				filter: "reviewed",
				dropdown_name: "Reviewed",
				search_value: 'reviewed'
			},
			{
				name: "Requesting Offering Plan",
				filter: "offering-plan-requested",
				dropdown_name: "Requesting Offering Plan",
				search_value: '("offering plan required")'
			},
			{
				name: "Offering Plan Acquired",
				filter: "offering-plan-acquired",
				dropdown_name: "Offering Plan Acquired",
				search_value: '("offering plan acquired")'
			},
			{
				name: "Condo Dec Vetted",
				filter: "condo-dec-vetted",
				dropdown_name: "Condo Dec Vetted",
				search_value: '("condo declaration vetted")'
			},
			{
				name: "Vetting Required",
				filter: "vetting-required",
				dropdown_name: "Vetting Required",
				search_value: '("vetting required")'
			},
			{
				name: "Vetted",
				filter: "vetted",
				dropdown_name: "Vetted",
				search_value: "vetted"
			},
			{
				name: "Coming Soon",
				filter: "coming-soon",
				dropdown_name: "Coming Soon",
				search_value: '("coming soon")'
			},
			{
				name: "Active",
				filter: "active",
				dropdown_name: "Active",
				search_value: 'active'
			},
			{
				name: "Abandoned",
				filter: "abandoned",
				dropdown_name: "Abandoned",
				search_value: "abandoned"
			},
			{
				name: "Not Abandoned",
				filter: "not-abandoned",
				dropdown_name: "Not Abandoned",
				search_value: '("" "monitoring" "reviewed" "offering plan acquired" "offering plan required" "condo declaration vetted" "vetting required" "vetted" "coming soon" "active" "sold out")'
			},			
			{
				name: "Sold Out",
				filter: "sold-out",
				dropdown_name: "Sold Out",
				search_value: '("sold out")'
			},
			{
				name: "Excluded",
				filter: "excluded",
				dropdown_name: "Excluded",
				search_value: "excluded"
			},

		]
	},	
/*	{
		name: "Total Financed",
		show_name: true,
		search_key: "total_loan_amount",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $5M",
				filter: "less-than-5m",
				dropdown_name: "Less than $5M",
				search_value: "[0 TO 5000000]"
			},
			{
				name: "$5M to $10M",
				filter: "5-to-10",
				dropdown_name: "$5M to $10M",
				search_value: "[5000000 TO 10000000]"
			},
			{
				name: "$10M to $25M",
				filter: "10-to-25",
				dropdown_name: "$10M to $25M",
				search_value: "[10000000 TO 25000000]"
			},
			{
				name: "$25M to $50M",
				filter: "25-to-50",
				dropdown_name: "$25M to $50M",
				search_value: "[25000000 TO 50000000]"
			},
			{
				name: "$50M to $100M",
				filter: "50-to-100",
				dropdown_name: "$50M to $100M",
				search_value: "[50000000 TO 100000000]"
			},
			{
				name: "$100M to $250M",
				filter: "100-to-250",
				dropdown_name: "$100M to $250M",
				search_value: "[100000000 TO 250000000]"
			},
			{
				name: "$250M to $500M",
				filter: "250-to-500",
				dropdown_name: "$250M to $500M",
				search_value: "[250000000 TO 500000000]"
			},
			{
				name: "$500M to $1B",
				filter: "500-to-1b",
				dropdown_name: "$500M to $1B",
				search_value: "[500000000 TO 1000000000]"
			},

			{
				name: "$1B+",
				filter: "1b-or-more",
				dropdown_name: "$1B or more",
				search_value: "[1000000000 TO *]"
			}
		]
	},*/

];

export const _marketActivityFilters = [
	{
		name: "Ownership",
		show_name: true,
		search_key: "event_tags",
		multi_filter:true,
		transaction_hide:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "(condo* !condo*p)",
			},
			{
				name: "Co-op",
				filter: "coop",
				dropdown_name: "Co-op",
				search_value: "(co?op* coop* !condo*p)",
			},			
			{
				name: "Condop",
				filter: "condop",
				dropdown_name: "Condop",
				search_value: "(condop*)",
			},
			{
				name: "Single-Family",
				filter: "single-family",
				dropdown_name: "Single-Family",
				search_value: "(single?family) AND total_units:[1 TO 1]",
			},			
			{
				name: "Multi-Family",
				filter: "multi-family",
				dropdown_name: "Multi-Family",
				search_value: "((multi?family) OR (ownership_type:rental AND total_units:[* TO 3]))",
			}
			
		]
	},
	{
		name: "Ownership",
		show_name: true,
		search_key: "ownership_type",
		multi_filter:true,
		activity_hide:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "(*condo* !condo*p)",
			},
			{
				name: "Co-op",
				filter: "coop",
				dropdown_name: "Co-op",
				search_value: "(*co?op* *coop* !condo*p)",
			},			
			{
				name: "Condop",
				filter: "condop",
				dropdown_name: "Condop",
				search_value: "(condop*)",
			},
			{
				name: "Single-Family",
				filter: "single-family",
				dropdown_name: "Single-Family",
				search_value: "(single?family) AND total_units:[1 TO 1]",
			},			
			{
				name: "Multi-Family",
				filter: "multi-family",
				dropdown_name: "Multi-Family",
				search_value: "((multi?family) OR (ownership_type:rental AND total_units:[* TO 3]))",
			}
			
		]
	},
	{
		name: "Price",
		show_name: true,
		search_key: ["min_price", "max_price"],
		filter_key : "price",
		price_filter: true,
		search_value: ['price:[{min_price} TO {max_price}]']					
	},
	{
		name: "Bedrooms",
		show_name: true,
		search_key: "bedrooms",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Studio",
				filter: "studio",
				dropdown_name: "Studio",
				search_value: "0",
			},
			{
				name: "1 Bedroom",
				filter: "1-bedroom",
				dropdown_name: "1 Bedroom",
				search_value: "1",
			},
			{
				name: "2 Bedroom",
				filter: "2-bedroom",
				dropdown_name: "2 Bedroom",
				search_value: "2",
			},
			{
				name: "3 Bedroom",
				filter: "3-bedroom",
				dropdown_name: "3 Bedroom",
				search_value: "3",
			},
			{
				name: "4+ Bedroom",
				filter: "4-or-more",
				dropdown_name: "4+ Bedroom",
				search_value: "[4 TO *]",
			},
		]
	},
	{
		name: "Baths",
		show_name: true,
		search_key: "baths",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},			
			{
				name: "1 Bath",
				filter: "1-bath",
				dropdown_name: "1 Bath",
				search_value: "1",
			},
			{
				name: "2 Bath",
				filter: "2-bath",
				dropdown_name: "2 Bath",
				search_value: "2",
			},
			{
				name: "3 Bath",
				filter: "3-bath",
				dropdown_name: "3 Bath",
				search_value: "3",
			},
			{
				name: "4+ Bath",
				filter: "4-or-more",
				dropdown_name: "4+ Bath",
				search_value: "[4 TO *]",
			},
		]
	},	
	{
		name: "Amenities",
		show_name: true,
		search_key: "building_features",
		autocomplete: true,
		core_name: "we3-buildings",
		filter_type: "building",
		fq: ["deleted:false"],
		group_amenities: true
		
	},
	{
		name: "Interior SF",
/*
		show_name: true,
		search_key: "sq_ft",
*/
		name: "Interior SF",
		show_name: true,
		search_key: ["min_sq_ft", "max_sq_ft"],
		filter_key : "sq_ft",
		price_filter: true,
		labels : ["Min Sq Ft", "Max Sq Ft"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['sq_ft:[{min_sq_ft} TO {max_sq_ft}]'], 
		value_prefix : "",
		title_suffix : "SF",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< 500 ",
				filter: "less-than-500",
				dropdown_name: "Less than 500 SF",
				search_value: "[10 TO 500]"
			},
			{
				name: "501 to 750 SF",
				filter: "501-to-750",
				dropdown_name: "501 to 750 SF",
				search_value: "[501 TO 750]"
			},
			{
				name: "751 to 1,000 SF",
				filter: "751-to-1000",
				dropdown_name: "751 to 1000 SF",
				search_value: "[751 TO 1000]"
			},
			{
				name: "1,001 to 1,500 SF",
				filter: "1001-to-1500",
				dropdown_name: "1,001 to 1,500 SF",
				search_value: "[1001 TO 1500]"
			},
			{
				name: "1,501 to 2,000 SF",
				filter: "1501-to-2000",
				dropdown_name: "1,501 to 2,000 SF",
				search_value: "[1501 TO 2000]"
			},
			{
				name: "2,001 to 3,000 SF",
				filter: "2001-to-3000",
				dropdown_name: "2,001 to 3,000 SF",
				search_value: "[2001 TO 3000]"
			},
			{
				name: "3,000+ SF",
				filter: "3001-or-more",
				dropdown_name: "More than 3000 SF",
				search_value: "[3001 TO *]"
			}
		]
	},	
	{
		name: "Exterior SF",
		show_name: true,
		search_key: "offering_exterior_sq_ft",
		filter_type: "property",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Exterior SF",
				filter: "has-exterior-sf",
				dropdown_name: "Has Exterior SF",
				search_value: "[1 TO *]"
			},
			{
				name: "No Exterior SF",
				filter: "no-exterior-sf",
				dropdown_name: "No Exterior SF",
				search_value: "[* TO 0]"
			},
			{
				name: "< 50 ",
				filter: "less-than-50",
				dropdown_name: "Less than 50 SF",
				search_value: "[0 TO 50]"
			},
			{
				name: "50 to 100 SF",
				filter: "50-to-100",
				dropdown_name: "50 to 100 SF",
				search_value: "[51 TO 100]"
			},
			{
				name: "101 to 200 SF",
				filter: "100-to-200",
				dropdown_name: "101 to 200 SF",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 to 500 SF",
				filter: "200-to-500",
				dropdown_name: "201 to 500 SF",
				search_value: "[201 TO 500]"
			},			
			{
				name: "500+ SF",
				filter: "500-more",
				dropdown_name: "More than 500 SF",
				search_value: "[501 TO *]"
			}
		]
	},
// 	{
// 		name: "Floors",
// 		show_name: true,
// 		search_key: ["min_floor", "max_floor"],
// 		filter_key : "floor",
// 		price_filter: true,
// 		// target: 'fq',
// 		labels : ["Min Floor", "Max Floor"],
// 
// 		custom_min_max_values : {
// 			"min" : [1, 11, 21, 31, 41],
// 			"max" : [10, 20, 30, 40, 50,"No Max"]
// 		},	
// 		format_type : "number",
// 		search_value: ['floor:[{min_floor} TO {max_floor}]']		,
// 		format_type : "number",
// 		value_prefix : "",	
// 		no_max:true,
// 		
// 		
// 	},
	{
		name: "Floors",
		show_name: true,
		search_key: ["min_floor", "max_floor"],
		filter_key : "floor",
		price_filter: true,
		activity_hide:true,
		labels : ["Min Floor", "Max Floor"],
	
		custom_min_max_values : {
			"min" : [1, 11, 21, 31, 41],
			"max" : [10, 20, 30, 40, 50, "No Max"]
	
		},	
		no_max:true,
		format_type : "number",
		search_value: ['floor:[{min_floor} TO {max_floor}]']		,
		format_type : "number",
		value_prefix : "",	
		
		
	},
	{
		name: "Year Built",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "year_built",
		filter_type: "building",
		multi_filter: true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: `[${(current_year - 1)} TO ${current_year}] OR year_converted:[${(current_year - 1)} TO ${current_year}]`,
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: `[${(current_year - 2)} TO ${current_year-1}] OR year_converted:[${(current_year - 2)} TO ${current_year-1}]`,
			},			
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: `[${(current_year - 5)} TO ${current_year-2}] OR year_converted:[${(current_year - 5)} TO ${current_year-2}]`,
			},
			{
				name: "5 to 7 Years",
				filter: "5-7-year",
				dropdown_name: "Last 5 to 7 Years",
				search_value: `[${(current_year - 7)} TO ${current_year-5}] OR year_converted:[${(current_year - 7)} TO ${current_year-5}]`,
	
			},
			{
				name: "7 to 10 Years",
				filter: "7-10-year",
				dropdown_name: "Last 5 to 10 Years",
				search_value: `[${(current_year - 10)} TO ${current_year-7}] OR year_converted:[${(current_year - 10)} TO ${current_year-7}]`,
			},
			{
				name: "10 to 15 Years",
				filter: "10-15-year",
				dropdown_name: "Last 10 to 15 Years",
				search_value: `[${(current_year - 15)} TO ${current_year - 10}] OR year_converted:[${(current_year - 15)} TO ${current_year - 10}]`,
			},
			{
				name: "More Than 15 Years",
				filter: "more-15-year",
				dropdown_name: "More Than 15 Years Ago",
				search_value: `[* TO ${current_year - 15}] OR year_converted:[* TO ${current_year - 15}]`,
			}				
		]
	},
	{
		name: "Condo Units",
		show_name: true,
		search_key: "inventory_total",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Total Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10 Units",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Total Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Total Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Total Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Total Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Total Units",
				filter: "201-or-more",
				dropdown_name: "201 or more Units",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "Unsold Units",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "inventory_unsold",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any Unsold Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Unsold Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Unsold Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Unsold Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Unsold Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Unsold Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Unsold Units",
				filter: "201-or-more",
				dropdown_name: "201 or more",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "% Sold",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "inventory_pct_sold",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Sales",
				filter: "has-sales",
				dropdown_name: "Has Sales",
				search_value: "[1 TO *] OR offering_verified_status:\"sold out\""
			},
			{
				name: "Has No Sales",
				filter: "no-sales",
				dropdown_name: "Has No Sales",
				search_value: "[* TO 0] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Less Than 15%",
				filter: "0-to-15",
				dropdown_name: "Less Than 15%",
				search_value: "[0 TO 15]"
			},
			{
				name: "Less Than 35%",
				filter: "0-to-35",
				dropdown_name: "Less Than 35%",
				search_value: "[0 TO 34]"
			},
			{
				name: "Less Than 50%",
				filter: "0-to-49",
				dropdown_name: "Less Than 50%",
				search_value: "[0 TO 49]"
			},
			{
				name: "More Than 15%",
				filter: "15-to-100",
				dropdown_name: "More Than 15%",
				search_value: "[15 TO *]"
			},
			{
				name: "More Than 50%",
				filter: "50-to-100",
				dropdown_name: "More Than 50%",
				search_value: "[50 TO *]"
			},
			{
				name: "50% to 79%",
				filter: "50-to-79",
				dropdown_name: "50% to 79%",
				search_value: "[50 TO 79]"
			},
			{
				name: "80% to 99%",
				filter: "80-to-99",
				dropdown_name: "80% to 99%",
				search_value: "[80 TO 99]"
			},
			{
				name: "Not Sold Out",
				filter: "not-sold-out",
				dropdown_name: "Not Sold Out",
				search_value: "[* TO 99] AND -offering_verified_status:\"sold out\""
			},
			{
				name: "Sold Out",
				filter: "sold-out",
				dropdown_name: "Sold Out",
				search_value: "[100 TO *]"
			}
		]
	},
	{
		name: "Construction",
		show_name: true,
		search_key: "building_effective_start",
		filter_type: "building",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready (Has CO/TCO)",
				search_value: "[NOW-25YEARS TO NOW] OR building_status:(resale) OR offering_verified_status:(\"sold out\")",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction (No CO/TCO)",
				search_value: "[* TO NOW-25YEARS] AND -building_status:(resale) AND -offering_verified_status:(\"sold out\")",
			}
			
		]
	},	
	{
		name: "Offering Type",
		show_name: true,
		search_key: "offering_construction",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "New Construction",
				filter: "new-construction",
				dropdown_name: "New Construction",
				search_value: "new",
			},
			{
				name: "Conversion",
				filter: "conversion",
				dropdown_name: "Conversion",
				search_value: "conversion",
			},			
			{
				name: "Rehab",
				filter: "rehab",
				dropdown_name: "Rehab",
				search_value: "rehab",
			},
			{
				name: "Exclude Conversion",
				filter: "exclude-conversion",
				dropdown_name: "Exclude Conversion",
				search_value: "(new rehab \"\")",
			}
			
		]
	},	
	{
		name: "Ownership",
		show_name: true,
		search_key: "ownership_type",
		filter_type: "building",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "condo AND -ownership_type:cooperative",
			},
			{
				name: "Co-op",
				filter: "co-op",
				dropdown_name: "Co-op",
				search_value: "coop",
			}
			
		]
	},	
	
	{
		name: "Sales Started",
		show_name: true,
		search_key: ["min_date", "max_date"],
		exclude_stage: ["pipeline"],
		filter_type: "building",
		date_filter: true,
		search_value: ['sales_start_date:[{min_date} TO {max_date}]']		
	},
	{
		name: "Offering Submitted",
		show_name: true,
		search_key: ["min_submitted_date", "max_submitted_date"],
		date_filter: true,
		filter_type: "building",
		search_value: ['offering_submitted_date:[{min_submitted_date} TO {max_submitted_date}]']		
	},
	{
		name: "Offering Accepted",
		show_name: true,
		search_key: ["min_accepted_date", "max_accepted_date"],
		date_filter: true,
		filter_type: "building",
		search_value: ['offering_accepted_date:[{min_accepted_date} TO {max_accepted_date}]']		
	},
	{
		name: "Offering Effective",
		show_name: true,
		search_key: ["min_effective_date", "max_effective_date"],
		date_filter: true,
		filter_type: "building",
		search_value: ['offering_effective_date:[{min_effective_date} TO {max_effective_date}]']		
	},
	/*	{
		name: "Sales Started",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "sales_start_date",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Not Started",
				filter: "not-started",
				dropdown_name: "Not Started",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Has Started",
				filter: "has-started",
				dropdown_name: "Has Started",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "5 to 7 Years",
				filter: "5-7-year",
				dropdown_name: "Last 5 to 7 Years",
				search_value: "[NOW-7YEARS TO NOW-5YEARS]",
			},
			{
				name: "7 to 10 Years",
				filter: "7-10-year",
				dropdown_name: "Last 5 to 10 Years",
				search_value: "[NOW-10YEARS TO NOW-7YEARS]",
			},
			{
				name: "10 to 15 Years",
				filter: "10-15-year",
				dropdown_name: "Last 10 to 15 Years",
				search_value: "[NOW-15YEARS TO NOW-10YEARS]",
			},
			{
				name: "More Than 15 Years",
				filter: "more-15-year",
				dropdown_name: "More Than 15 Years Ago",
				search_value: "[NOW-20YEARS TO NOW-15YEARS]",
			}				
		]
	},*/
	{
		name: "Last Listing",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "last_listed_sale_date_formatted",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Last Contract",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "last_contract_date_formatted",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Last Sale",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "last_sold_date_formatted",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Activity",
				filter: "no-activity",
				dropdown_name: "No Activity",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND -offering_verified_status:\"sold out\"",
			},
			{
				name: "Any Activity",
				filter: "any-activity",
				dropdown_name: "Any Activity",
				search_value: "[NOW-15YEARS TO NOW] OR offering_verified_status:\"sold out\"",
			},
			{
				name: "Within 1 Year",
				filter: "1-year",
				dropdown_name: "Within 1 Year",
				search_value: "[NOW-1YEARS TO NOW]",
			},
			{
				name: "1 to 2 Years",
				filter: "2-year",
				dropdown_name: "Last 1 to 2 Years",
				search_value: "[NOW-2YEARS TO NOW-1YEARS]",
			},
			{
				name: "2 to 5 Years",
				filter: "2-5-year",
				dropdown_name: "Last 2 to 5 Years",
				search_value: "[NOW-5YEARS TO NOW-2YEARS]",
			},
			{
				name: "More Than 5 Years",
				filter: "more-5-year",
				dropdown_name: "More Than 5 Years Ago",
				search_value: "[NOW-15YEARS TO NOW-5YEARS]",
			}			
		]
	},
	{
		name: "Condo Dec",
		show_name: true,
		exclude_stage: ["pipeline"],
		search_key: "cdec_date",
		filter_type: "building",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "No Condo Dec",
				filter: "no-cdec",
				dropdown_name: "No Condo Dec",
				search_value: "[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z] AND building_status:(pipeline sponsor)",
			},
			{
				name: "Has Condo Dec",
				filter: "has-cdec",
				dropdown_name: "Has Condo Dec",
				search_value: "[1970-01-05T00:00:00Z TO NOW] OR building_status:(resale)",
			}			
		]
	},
	{
		name: "Offering Status",
		show_name: true,
		search_key: "offering_submitted_date",
		filter_type: "building",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "All Submitted",
				filter: "submitted",
				dropdown_name: "All Submitted",
				search_value: "[NOW-25YEARS TO NOW]",
			},
			{
				name: "All Accepted",
				filter: "accepted",
				dropdown_name: "All Accepted",
				search_value: "[NOW-25YEARS TO NOW] AND offering_accepted_date:[NOW-25YEARS TO NOW]",
			},
			{
				name: "All Effective",
				filter: "effective",
				dropdown_name: "All Effective",
				search_value: "[NOW-25YEARS TO NOW] AND offering_effective_date:[NOW-25YEARS TO NOW]",
			},
			{
				name: "Only Submitted",
				filter: "submitted-only",
				dropdown_name: "Only Submitted",
				search_value: "[NOW-25YEARS TO NOW] AND offering_accepted_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Only Accepted",
				filter: "accepted-only",
				dropdown_name: "Only Accepted",
				search_value: "[NOW-25YEARS TO NOW] AND offering_accepted_date:[NOW-25YEARS TO NOW] AND offering_effective_date:[1970-01-01T00:00:00Z TO 1970-01-02T00:00:00Z]",
			},
			{
				name: "Only Effectiv Only",
				filter: "effective-only",
				dropdown_name: "Only Effective",
				search_value: "[NOW-25YEARS TO NOW] AND offering_effective_date:[NOW-25YEARS TO NOW]",
			}
			
		]
	},


];


export const _pipeLineFilter = [
	
/*	{
		name: "Stage",
		show_name: true,
		search_key: "event_tags",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Permit Filed",
				filter: "permit-filed",
				dropdown_name: "Permit Filed",
				search_value: "permit-filing",
			},
			{
				name: "Offering Submitted",
				filter: "offering-submitted",
				dropdown_name: "Offering Submitted",
				search_value: "submitted",
			},
			{
				name: "Offering Accepted",
				filter: "offering-accepted",
				dropdown_name: "Offering Accepted",
				search_value: "accepted",
			},
			{
				name: "Offering Effective",
				filter: "offering-effective",
				dropdown_name: "Offering Effective",
				search_value: "effective",
			},
			
		]
	},	*/
	{
		name: "Planned Units",
		show_name: true,
		//search_key: "units",
		search_key: ["min_units", "max_units"],
		filter_key : "inventory_unsold",
		price_filter: true,
		labels : ["Min Unsold Units", "Max Unsold Units"],
		min_max_values : [25, 200],
		min_max_increment : 25,
		custom_min_max_values : {
			"min" : [1, 11, 31, 51, 101, 201],
			"max" : [10, 30, 50, 100, 200, 300]
		},		
		format_type : "number",
		search_value: ['units:[{min_units} TO {max_units}]'], 
		value_prefix : "",	
		title_suffix : "Units",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "3-10 Units",
				filter: "3-to-10",
				dropdown_name: "3 to 10 Units",
				search_value: "[3 TO 10]"
			},
			{
				name: "11-30 Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Units",
				filter: "201-or-more",
				dropdown_name: "201 or more Units",
				search_value: "[201 TO *]"
			}
		]
	},
	{
		name: "Permit Type",
		show_name: true,
		search_key: "topic",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "New Building",
				filter: "new-building",
				dropdown_name: "New Building",
				search_value: "permits AND event_tags:nb",
			},
			{
				name: "Major Alteration",
				filter: "major-alteration",
				dropdown_name: "Major Alteration",
				search_value: "permits AND event_tags:a1",
			}
			
		]
	},
	{
		name: "Offering Type",
		show_name: true,
		search_key: "event_tags",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "New Condo",
				filter: "new-condo",
				dropdown_name: "New Condo",
				search_value: "new-condo",
			},
			{
				name: "Condo Conversion",
				filter: "condo-conversion",
				dropdown_name: "Condo Conversion",
				search_value: "condo-conversion",
			},
			{
				name: "New Co-op",
				filter: "new-coop",
				dropdown_name: "New Co-op",
				search_value: "new-coop",
			},
			{
				name: "Co-op Conversion ",
				filter: "coop-conversion",
				dropdown_name: "Co-op Conversion",
				search_value: "coop-conversion",
			},
			
		]
	},
	{
		name: "Offering Sellout",
		show_name: true,
		search_key: "price",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Under $10M",
				filter: "under-10m",
				dropdown_name: "Under $10M",
				search_value: "[* TO 10000000]"
			},
			{
				name: "$10M to $50M",
				filter: "10-to-50",
				dropdown_name: "$10M to $50M",
				search_value: "[10000000 TO 50000000]"
			},
			{
				name: "$50M to $100M",
				filter: "50-to-100",
				dropdown_name: "$50M to $100M",
				search_value: "[50000000 TO 100000000]"
			},
			{
				name: "$100M to $200M",
				filter: "100-to-200",
				dropdown_name: "$100M to $200M",
				search_value: "[100000000 TO 200000000]"
			},
			{
				name: "More Than $200M",
				filter: "more-than-200",
				dropdown_name: "More Than $200M",
				search_value: "[200000000 TO *]"
			},

			
		]
	},	
	
];


export const _buildingAdminFiltersRental = [
	{
		name: "Borough",
		show_name: true,
		default_name: 'All Boroughs',
		search_key: "borough",
		values: [
			{
				name: "All Boroughs",
				filter: "",
				dropdown_name: "All Boroughs",
				search_value: ""
			},
			{
				name: "Manhattan",
				filter: "manhattan",
				dropdown_name: "Manhattan",
				search_value: "manhattan",
			},
			{
				name: "Brooklyn",
				filter: "brooklyn",
				dropdown_name: "Brooklyn",
				search_value: "brooklyn",
			},
			{
				name: "Queens",
				filter: "queens",
				dropdown_name: "Queens",
				search_value: "queens",
			},
			{
				name: "Bronx",
				filter: "bronx",
				dropdown_name: "Bronx",
				search_value: "bronx",
			},
			{
				name: "Staten Island",
				filter: "staten-island",
				dropdown_name: "Staten Island",
				search_value: "staten-island",
			},
		]
	},
	{
		name: "Type",
		show_name: true,
		search_key: "ownership_type",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Hybrid - Condo",
				filter: "hybrid",
				dropdown_name: "Hybrid - Condo",
				search_value: "* AND ((ownership_type:*condo* AND ownership_occupancy_sub_types:*rental*) OR (ownership_occupancy_sub_types:*condo* AND ownership_type:*rental*))",
			},
			{
				name: "OP Filed",
				filter: "op-filed",
				dropdown_name: "Offering Plan Filed",
				search_value: "* AND (offering_submitted_date:[NOW-15YEARS TO NOW])",
			}
			
		]
	},
	{
		name: "Total Floors",
		show_name: true,
		search_key: "total_floors",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-5 Floors",
				filter: "1-to-10",
				dropdown_name: "1 to 5 Floors",
				search_value: "[1 TO 5]"
			},
			{
				name: "6-10 Floors",
				filter: "6-to-10",
				dropdown_name: "6 to 10 Floors",
				search_value: "[6 TO 10]"
			},
			{
				name: "11-20 Floors",
				filter: "11-to-20",
				dropdown_name: "11 to 20 Floors",
				search_value: "[11 TO 20]"
			},
			{
				name: "21-30 Floors",
				filter: "21-to-30",
				dropdown_name: "21 to 30 Floors",
				search_value: "[21 TO 30]"
			},
			{
				name: "31-40 Floors",
				filter: "31-to-40",
				dropdown_name: "31 to 40 Floors",
				search_value: "[31 TO 40]"
			},
			{
				name: "40+ Floors",
				filter: "40-or-more",
				dropdown_name: "41 or More Floors",
				search_value: "[41 TO *]"
			}
		]
	},
/*
	{
		name: "Construction",
		show_name: true,
		search_key: "building_effective_start",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Move-in Ready",
				filter: "completed",
				dropdown_name: "Move-in Ready",
				search_value: "[NOW-15YEARS TO NOW]",
			},
			{
				name: "Under Construction",
				filter: "not-completed",
				dropdown_name: "Under Construction",
				search_value: "[* TO NOW-15YEARS]",
			}
			
		]
	},
*/
	{
		name: "Condo Units",
		show_name: true,
		search_key: "inventory_total",
		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "1-10 Condo Units",
				filter: "1-to-10",
				dropdown_name: "1 to 10 Units",
				search_value: "[1 TO 10]"
			},
			{
				name: "11-30 Condo Units",
				filter: "11-to-30",
				dropdown_name: "11 to 30 Units",
				search_value: "[11 TO 30]"
			},
			{
				name: "31-50 Condo Units",
				filter: "31-to-50",
				dropdown_name: "31 to 50 Units",
				search_value: "[31 TO 50]"
			},
			{
				name: "51-100 Condo Units",
				filter: "51-to-100",
				dropdown_name: "51 to 100 Units",
				search_value: "[51 TO 100]"
			},
			{
				name: "101-200 Condo Units",
				filter: "101-to-200",
				dropdown_name: "101 to 200 Units",
				search_value: "[101 TO 200]"
			},
			{
				name: "201 + Condo Units",
				filter: "201-or-more",
				dropdown_name: "201 or more Units",
				search_value: "[201 TO *]"
			}
		]
	}
];

export const _buildingStageFilter = [{
		search_key: "building_stage",
		type: "array",
		values: [
			{
				filter: "pipeline"
			},
			{
				filter: "sponsor"
			},
			{
				filter: "resale"
			},
			{
				filter: "all"
			}
			
		]
}]

export const _unitStageFilter = [{
		search_key: "unit_stage",
		type: "array",
		values: [
			{
				filter: "sponsor"
			},
			{
				filter: "resale"
			}			
		]
}]

export const _salesFilters = [	
	{
		name: "Ownership",
		show_name: true,
		search_key: "ownership_type",
		multi_filter:true,
		hide_building:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "(*condo* !condo*p)",
			},
			{
				name: "Co-op",
				filter: "coop",
				dropdown_name: "Co-op",
				search_value: "(*co?op* *coop* !condo*p)",
			},			
			{
				name: "Condop",
				filter: "condop",
				dropdown_name: "Condop",
				search_value: "(condop*)",
			},
			{
				name: "Single-Family",
				filter: "single-family",
				dropdown_name: "Single-Family",
				search_value: "(single?family) AND total_units:[1 TO 1]",
			},			
			{
				name: "Multi-Family",
				filter: "multi-family",
				dropdown_name: "Multi-Family",
				search_value: "((multi?family) OR (ownership_type:rental AND total_units:[* TO 3]))",
			}
			
		]
	},
	{
		name: "Sold Date",
		show_name: true,
		search_key: ["min_date", "max_date"],
		filter_key : "sold_date",
		date_filter: true,
		search_value: ['close_date_formatted:[{min_date} TO {max_date}]']		
	},
	{
		name: "Contract Date",
		show_name: true,
		search_key: ["min_contract_date", "max_contract_date"],
		filter_key : "contract_date",
		date_filter: true,
		search_value: ['contract_date_formatted:[{min_contract_date} TO {max_contract_date}]']		
	},
	{
		name: "Price",
		show_name: true,
		search_key: ["min_price", "max_price"],
		price_filter: true,
		search_value: ['price:[{min_price} TO {max_price}]']		
	},	
	{
		name: "Bedrooms",
		show_name: true,
		search_key: "bedrooms",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Studio",
				filter: "studio",
				dropdown_name: "Studio",
				search_value: "0",
			},
			{
				name: "1 Bedroom",
				filter: "1-bedroom",
				dropdown_name: "1 Bedroom",
				search_value: "1",
			},
			{
				name: "2 Bedroom",
				filter: "2-bedroom",
				dropdown_name: "2 Bedroom",
				search_value: "2",
			},
			{
				name: "3 Bedroom",
				filter: "3-bedroom",
				dropdown_name: "3 Bedroom",
				search_value: "3",
			},
			{
				name: "4+ Bedroom",
				filter: "4-or-more",
				dropdown_name: "4+ Bedroom",
				search_value: "[4 TO *]",
			},
		]
	},
	
	{
		name: "Baths",
		show_name: true,
		search_key: "baths",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},			
			{
				name: "1 Bath",
				filter: "1-bath",
				dropdown_name: "1 Bath",
				search_value: "1",
			},
			{
				name: "2 Bath",
				filter: "2-bath",
				dropdown_name: "2 Bath",
				search_value: "2",
			},
			{
				name: "3 Bath",
				filter: "3-bath",
				dropdown_name: "3 Bath",
				search_value: "3",
			},
			{
				name: "4+ Bath",
				filter: "4-or-more",
				dropdown_name: "4+ Bath",
				search_value: "[4 TO *]",
			},
		]
	},
	{
		name: "Amenities",
		show_name: true,
		search_key: "building_features",
		autocomplete: true,
		core_name: "we3-transactions",
		fq: ["status:sold"],
		group_amenities: true,
		hide_building:true,
	},
	{
		name: "Interior SF",
		show_name: true,
		search_key: ["min_sq_ft", "max_sq_ft"],
		filter_key : "sq_ft",
		price_filter: true,
		labels : ["Min Sq Ft", "Max Sq Ft"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['sq_ft:[{min_sq_ft} TO {max_sq_ft}]'], 
		value_prefix : "",
		title_suffix : "SF",

		values: [
			{
				name: "Any Units",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< 500 ",
				filter: "less-than-500",
				dropdown_name: "Less than 500 SF",
				search_value: "[10 TO 500] OR sq_ft:[10 TO 500] "
			},
			{
				name: "501 to 750 SF",
				filter: "501-to-750",
				dropdown_name: "501 to 750 SF",
				search_value: "[501 TO 750] OR sq_ft:[501 TO 750] "
			},
			{
				name: "751 to 1,000 SF",
				filter: "751-to-1000",
				dropdown_name: "751 to 1000 SF",
				search_value: "[751 TO 1000] OR sq_ft:[751 TO 1000]"
			},
			{
				name: "1,001 to 1,500 SF",
				filter: "1001-to-1500",
				dropdown_name: "1,001 to 1,500 SF",
				search_value: "[1001 TO 1500] OR sq_ft:[1001 TO 1500]"
			},
			{
				name: "1,501 to 2,000 SF",
				filter: "1501-to-2000",
				dropdown_name: "1,501 to 2,000 SF",
				search_value: "[1501 TO 2000] OR sq_ft:[1501 TO 2000]"
			},
			{
				name: "2,001 to 3,000 SF",
				filter: "2001-to-3000",
				dropdown_name: "2,001 to 3,000 SF",
				search_value: "[2001 TO 3000] OR sq_ft:[2001 TO 3000]"
			},
			{
				name: "3,000+ SF",
				filter: "3001-or-more",
				dropdown_name: "More than 3000 SF",
				search_value: "[3001 TO *] OR sq_ft:[3001 TO *]"
			}
		]
	},
	{
		name: "Sold PPSF",
		show_name: true,
		//search_key: "price_per_sqft",
		search_key: ["min_ppsf_price", "max_ppsf_price"],
		filter_key : "price_per_sqft",
		price_filter: true,
		labels : ["Min PPSF", "Max PPSF"],
		min_max_values : [500, 4500],
		min_max_increment : 500,
		format_type : "number",
		search_value: ['price_per_sqft:[{min_ppsf_price} TO {max_ppsf_price}]'],
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: "[100 TO 749]"
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "75-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: "[750 TO 1000]"
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: "[1000 TO 1500]"
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: "[1500 TO 2000]"
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
			    search_value: "[2000 TO 2500]"
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: "[2500 TO 5000]"
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: "[5000 TO *]"
			}
		]
	},
	{
		name: "Floors",
		show_name: true,
		search_key: ["min_floor", "max_floor"],
		filter_key : "floor",
		price_filter: true,
		// target: 'fq',
		labels : ["Min Floor", "Max Floor"],

		custom_min_max_values : {
			"min" : [1, 11, 21, 31, 41],
			"max" : [10, 20, 30, 40, 50,"No Max"]
		},	
		format_type : "number",
		search_value: ['floor:[{min_floor} TO {max_floor}]']		,
		format_type : "number",
		value_prefix : "",	
		no_max:true,
		
	},
	{
		name: "Sale Type",
		show_name: true,
		search_key: "parcels_count",
		multi_filter:true,
		no_key:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Market Sale",
				filter: "market-sale",
				dropdown_name: "Market Sale",
				search_value: "(parcels_count:1 AND (price:[5001 TO *] OR price:[* TO -1]))"
			},
			
			{
				name: "Non-Market Transfer",
				filter: "non-market",
				dropdown_name: "Non-Market Transfer",
				search_value: "(price:[* TO 5000])"
			},
			{
				name: "Bulk Sale (Any)",
				filter: "bulk-sale",
				dropdown_name: "Bulk Sale (Any)",
				search_value: "(parcels_count:[2 TO *] AND parcels_condocoop_count:[* TO 0]) OR (parcels_condocoop_count:[2 TO *])"
			},
			{
				name: "Bulk Sale (2 to 5 Units)",
				filter: "bulk-sale-2-to-5",
				dropdown_name: "Bulk Sale  (2 to 5 Units)",
				search_value: "(parcels_count:[2 TO 5] AND parcels_condocoop_count:[* TO 0]) OR (parcels_condocoop_count:[2 TO 5])"
			},
			{
				name: "Bulk Sale (6 to 10 Units)",
				filter: "bulk-sale-6-to-5",
				dropdown_name: "Bulk Sale  (6 to 10 Units)",
				search_value: "(parcels_count:[6 TO 10] AND parcels_condocoop_count:[* TO 0]) OR (parcels_condocoop_count:[6 TO 10])"
			},
			{
				name: "Bulk Sale (11 to 20 Units)",
				filter: "bulk-sale-11-to-20",
				dropdown_name: "Bulk Sale  (11 to 20 Units)",
				search_value: "(parcels_count:[11 TO 20] AND parcels_condocoop_count:[* TO 0]) OR (parcels_condocoop_count:[11 TO 20])"
			},
			{
				name: "Bulk Sale (20+ Units)",
				filter: "bulk-sale-more-20",
				dropdown_name: "Bulk Sale (20+ Units)",
				search_value: "(parcels_count:[21 TO *] AND parcels_condocoop_count:[* TO 0]) OR (parcels_condocoop_count:[21 TO *])"
			}
		]
	},
	{
		name: "Ownership",
		show_name: true,
		search_key: "ownership_type",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Condo",
				filter: "condo",
				dropdown_name: "Condo",
				search_value: "condo AND -ownership_type:cooperative",
			},
			{
				name: "Co-op",
				filter: "co-op",
				dropdown_name: "Co-op",
				search_value: "coop",
			}
			
		]
	},
	{
		name: "Discount",
		show_name: true,
		search_key: "discount",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Discount",
				filter: "has-discount",
				dropdown_name: "Has Discount",
				search_value: "[-90 TO -.01]"
			},
			{
				name: "1% to 3%",
				filter: "1-to-3",
				dropdown_name: "1% to 3%",
				search_value: "[-3 TO -1]"
			},
			{
				name: "3% to 5%",
				filter: "3-to-5",
				dropdown_name: "3% to 5%",
				search_value: "[-5 TO -3]"
			},
			{
				name: "5% to 10%",
				filter: "5-to-10",
				dropdown_name: "5% to 10%",
				search_value: "[-10 TO -5]"
			},
			{
				name: "10% to 15%",
				filter: "10-to-15",
				dropdown_name: "10% to 15%",
				search_value: "[-15 TO -10]"
			},
			{
				name: "More Than 15%",
				filter: "more-15",
				dropdown_name: "More Than 15%",
				search_value: "[-90 TO -15]"
			},
			{
				name: "Sold for More Than Asking",
				filter: "more-than",
				dropdown_name: "Sold for More Than Asking",
				search_value: "[.01 TO 90]"
			},
		]
	},
	{
		name: "% Financed",
		show_name: true,
		search_key: "percent_financed",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Has Financing",
				filter: "has-financing",
				dropdown_name: "Has Financing",
				search_value: "[.01 TO 100]"
			},
			{
				name: "No Financing",
				filter: "no-financing",
				dropdown_name: "No Financing",
				search_value: "[* TO 0]"
			},
			{
				name: "90% to 100%",
				filter: "90-to-100",
				dropdown_name: "90% to 100%",
				search_value: "[90 TO 100]"
			},
			{
				name: "80% to 90%",
				filter: "80-to-90",
				dropdown_name: "80% to 90%",
				search_value: "[80 TO 90]"
			},
			{
				name: "70% to 80%",
				filter: "70-to-80",
				dropdown_name: "70% to 80%",
				search_value: "[70 TO 80]"
			},
			{
				name: "50% to 70%",
				filter: "50-to-70",
				dropdown_name: "50% to 70%",
				search_value: "[50 TO 70]"
			},
			{
				name: "30% to 50%",
				filter: "30-to-50",
				dropdown_name: "30% to 50%",
				search_value: "[30 TO 50]"
			},
			{
				name: "Less than 30%",
				filter: "1-to-30",
				dropdown_name: "Less than 30%",
				search_value: "[1 TO 30]"
			}
		]
	},
	{
		name: "Owner Type",
		show_name: true,
		search_key: "type",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Original Owner",
				filter: "original",
				dropdown_name: "Original Owner",
				search_value: "* AND owner:* AND sponsored_sale:false"
			},
			{
				name: "Corporate Owner",
				filter: "corporate",
				dropdown_name: "Corporate Owner",
				search_value: "* AND owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)"
			}
		]
	},
	{
		name: "Year Built",
		show_name: true,
		exclude_stage: ["pipeline"],
		format_type : "number",
		search_key: ["min_year_built", "max_year_built"],
		filter_key : "year_built",
		price_filter: true,
		labels : ["Min Year Built", "Max Year Built"],
		min_max_values : [1900, current_year],
		min_max_increment : 10,
		custom_min_max_values : {
			"min" : [1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020],
			"max" : [1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, current_year]
		},		
		format_type : "year",
		search_value: ['year_built:[{min_year_built} TO {max_year_built}]'], 
		value_prefix : "",	
		title_suffix : ""
	},
	{
		name: "Sale Includes",
		show_name: true,
		search_key: "parcels_property_types",
		multi_filter:true,
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "Includes Parking",
				filter: "parking",
				dropdown_name: "Includes Parking",
				search_value: "ps"
			},			
			{
				name: "Includes Storage",
				filter: "storage",
				dropdown_name: "Includes Storage",
				search_value: "sr"
			},
			{
				name: "Includes Parking & Storage",
				filter: "parking-storage",
				dropdown_name: "Includes Parking & Storage",
				search_value: "ps AND parcels_property_types:sr"
			},
			{
				name: "Excludes Parking",
				filter: "exclude-parking",
				dropdown_name: "Excludes Parking",
				search_value: "* AND -parcels_property_types:ps"
			},			
			{
				name: "Excludes Storage",
				filter: "exclude-storage",
				dropdown_name: "Excludes Storage",
				search_value: "* AND -parcels_property_types:sr"
			},
			{
				name: "Excludes Parking & Storage",
				filter: "exclude-parking-storage",
				dropdown_name: "Excludes Parking & Storage",
				search_value: "* AND -parcels_property_types:ps AND -parcels_property_types:sr"
			},
		]
	},
	{
		name: "Buyer Name",
		show_name: true,
		search_key: "owner",
		text_filter: true
	},
	{
		name: "Seller Name",
		show_name: true,
		search_key: "seller",
		text_filter: true
	},
	{
		name: "Lender",
		show_name: true,
		search_key: "lender",
		search_fields: ["lender", "verified_lender_name", "verified_lender_nmls_id"],
		text_filter: true
	},
	{
		name: "Loan Officer",
		show_name: true,
		search_key: "loan_officer",
		search_fields: ["verified_loan_officer_first_name", "verified_loan_officer_middle_name", "verified_loan_officer_last_name", "verified_loan_officer_nmls_id"],
		text_filter: true
	},
	{
		name: "Title Company",
		show_name: true,
		search_key: "title_company",
		search_fields: ["verified_title_company_name"],
		text_filter: true
	},
	{
		name: "Buyer's Law Firm",
		show_name: true,
		search_key: "buyer_law_firm",
		search_fields: ["verified_buyers_attorney_firm_name"],
		text_filter: true
	},
	{
		name: "Buyer's Attorney",
		show_name: true,
		search_key: "buyer_attorney",
		search_fields: ["verified_buyers_attorney_first_name", "verified_buyers_attorney_middle_name", "verified_buyers_attorney_last_name"],
		text_filter: true
	},
	{
		name: "Listing Broker",
		show_name: true,
		search_key: "broker",
		text_filter: true
	},
	{
		name: "Listing Agent",
		show_name: true,
		search_key: "agents",
		text_filter: true
	},
	// {
	// 	name: "Verified Sale",
	// 	show_name: true,
	// 	search_key: "verified_close_price",
	// 	multi_filter:true,
	// 	no_key:true,
	// 	values: [
	// 		{
	// 			name: "Any",
	// 			filter: "",
	// 			dropdown_name: "Any",
	// 			search_value: ""
	// 		},
	// 		{
	// 			name: "Verified",
	// 			filter: "verified",
	// 			dropdown_name: "Verified",
	// 			search_value: "(verified_close_price:[0 TO *])"
	// 		},
	// 		
	// 		{
	// 			name: "Not Verified",
	// 			filter: "not-verified",
	// 			dropdown_name: "Not Verified",
	// 			search_value: "verified_close_price:[0 TO *]"
	// 		},
	// 	]
	// },
	
]

export const _reportFilters = [
{
	name: "Share Type",
	show_name: true,
	search_key: "share_type",
	values: [
		{
			name: "Any",
			filter: "",
			dropdown_name: "Any",
			search_value: ""
		},
		{
			name: "Buildings",
			filter: "buildings",
			dropdown_name: "Buildings",
			search_value: {"$and" : [ {"building.key" : { "$exists" : true}}, {"property.key" : { "$exists" : false}} ]}
		},
		{
			name: "Units",
			filter: "units",
			dropdown_name: "Units",
			search_value: {"$and" : [ {"property.key" : { "$exists" : true}} ]}
		},
		{
			name: "Collections",
			filter: "collections",
			dropdown_name: "Collections",
			search_value: {"report_type" : {"$in" : ["comp", "grid"]}},
		}		
	]
}]

export const _permitFilters = [
	{
	  name: "Filing Date",
	  show_name: true,
	  search_key: ["filed_min_date", "filed_max_date"],
	  filter_key: 'filing_date',
	  date_filter: true,
	  search_value: ["filing_date:[{filed_min_date} TO {filed_min_date}]"],
	},
	{
	  name: "Issuance Date",
	  show_name: true,
	  search_key: ["min_date", "max_date"],
	  filter_key: 'issuance_date',
	  date_filter: true,
	  search_value: ["issuance_date:[{min_date} TO {max_date}]"],
	},
	{
	  name: "Job Type",
	  show_name: true,
	  search_key: "job_type",
	  multi_filter: true,
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "New Building",
		  filter: "nb",
		  dropdown_name: "New Building",
		  search_value: "(nb 5 6)",
		},
		{
		  name: "Demolition",
		  filter: "dm",
		  dropdown_name: "Demolition",
		  search_value: "(dm 7)",
		},
		{
		  name: "Major Alteration",
		  filter: "a1",
		  dropdown_name: "Major Alteration",
		  search_value: "(a1 4)",
		},
		{
		  name: "Minor Alteration",
		  filter: "a3",
		  dropdown_name: "Minor Alteration",
		  search_value: "(a3 alt 1)",
		}
	  ],
	},
	{
	  name: "Work Type",
	  show_name: true,
	  search_key: "work_type",
	  multi_filter: true,
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "General Construction",
		  filter: "gc",
		  dropdown_name: "General Construction",
		  search_value: "(gc *general*const*)",
		},
		{
		  name: "Plumbing",
		  filter: "pl",
		  dropdown_name: "Plumbing",
		  search_value: "(pl *plumbing*)",
		},
		{
		  name: "Construction Equipment",
		  filter: "eq",
		  dropdown_name: "Construction Equipment",
		  search_value: "(eq */eq* *eq/*)",
		},
		{
		  name: "Foundation/Earthwork",
		  filter: "fo",
		  dropdown_name: "Foundation/Earthwork",
		  search_value: "(fo */fo* *fo/* foundation ea)",
		},
		{
		  name: "Sidewalk Shed",
		  filter: "sh",
		  dropdown_name: "Sidewalk Shed",
		  search_value: "(sh */sh* *sh/* sidewalk*shed)",
		},
		{
		  name: "Scaffolding",
		  filter: "sf",
		  dropdown_name: "Scaffolding",
		  search_value: "(sf */sf* *sf/* supported*scaffold sc */sc *sc/)",
		},
		{
		  name: "Boiler",
		  filter: "bl",
		  dropdown_name: "Boiler",
		  search_value: "(bl */bl* *bl/* boiler)",
		},
		{
		  name: "Construction Fence",
		  filter: "fn",
		  dropdown_name: "Construction Fence",
		  search_value: "(fn */fn *fn/* construction*fence)",
		},
		{
		  name: "Mechanical Systems",
		  filter: "mh",
		  dropdown_name: "Mechanical Systems",
		  search_value: "(mh */md *md/* mechanical*systems)",
		},
		{
		  name: "Standpipe",
		  filter: "sd",
		  dropdown_name: "Standpipe",
		  search_value: "(sd */sd *sd/* standpipe)",
		},
		
		{
		  name: "Fire Suppression",
		  filter: "fp",
		  dropdown_name: "Fire Suppression",
		  search_value: "(fp */fp* *fp/* fire*suppression)",
		},
		{
		  name: "Sprinklers",
		  filter: "sp",
		  dropdown_name: "Sprinklers",
		  search_value: "(*/sp* *sp/* sp sprinklers)",
		},
		{
		  name: "Fuel Storage",
		  filter: "fs",
		  dropdown_name: "Fuel Storage",
		  search_value: "(fs */fs* *fs/* fuel*storage)",
		},
		{
		  name: "Fuel Burning",
		  filter: "fb",
		  dropdown_name: "Fuel Burning",
		  search_value: "(fb */fb* *fb/* fuel*burning)",
		},
		{
		  name: "Sign",
		  filter: "sg",
		  dropdown_name: "Sign",
		  search_value: "(sg */sg* *sg/* *sign*)",
		},
		{
		  name: "Electrical",
		  filter: "el",
		  dropdown_name: "Electrical",
		  search_value: "(el */el* *el/* *electrical*)",
		},
		{
		  name: "Curb Cut",
		  filter: "cc",
		  dropdown_name: "Curb Cut",
		  search_value: "(cc */cc* *cc/* curb*cut)",
		},
		{
		  name: "Protection and Mechanical Methods",
		  filter: "pm",
		  dropdown_name: "Protection and Mechanical Methods",
		  search_value: "(pm pmm */pm* *pm/*)",
		}
	  ],
	},
	{
	  name: "Status",
	  show_name: true,
	  search_key: "filing_status",
	  multi_filter: true,
	  hide_issuance:false,
	  values: [
		{
		  name: "Any",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "Assigned",
		  filter: "assign",
		  dropdown_name: "Assigned",
		  search_value: "(3 25 33 67 84 7)",
		},
		{
		  name: "Review",
		  filter: "review",
		  dropdown_name: "Review",
		  search_value: "(4 26 5 68 13 2 71 85 24 25 8 47 10 11 50 52 72)",
		},
		{
		  name: "Objections",
		  filter: "objections",
		  dropdown_name: "Objections",
		  search_value: "(19 70 8 86 16 14)",
		},
		{
		  name: "Approved",
		  filter: "approved",
		  dropdown_name: "Approved",
		  search_value: "(6 8 16 37 13 102 15 52)",
		},
		{
		  name: "Issued - Initial",
		  filter: "issued-initial",
		  dropdown_name: "Issued - Initial",
		  search_value: "(initial)",
		},
		{
		  name: "Issued - Renewal",
		  filter: "issued-renewal",
		  dropdown_name: "Issued - Renewal",
		  search_value: "(renewal)",
		}
	  ],
	},
	{
	  name: "Applicant Company Name",
	  show_name: true,
	  search_key: "applicant_business_name",
	  text_filter: true,
	  hide_issuance:false
	},
	{
	  name: "Applicant Contact Name",
	  show_name: true,
	  search_key: "applicant_contact_name",
	  search_fields: ["applicant_first_name", "applicant_first_name"],
	  text_filter: true,
	  hide_issuance:false
	},
	{
	  name: "Filing Rep Company Name",
	  show_name: true,
	  search_key: "filing_applicant_business_name",
	  text_filter: true,
	  hide_issuance:false
	},
	{
	  name: "Filing Rep Contact Name",
	  show_name: true,
	  search_key: "filing_applicant_contact_name",
	  search_fields: ["filing_applicant_irst_name", "filing_applicant_first_name"],
	  text_filter: true,
	  hide_issuance:true
	},
	{
	  name: "Permittee Company Name",
	  show_name: true,
	  search_key: "permittee_business_name",
	  text_filter: true,
	},
	{
	  name: "Permittee Contact Name",
	  show_name: true,
	  search_key: "permittee_contact_name",
	  search_fields: ["permittee_first_name", "permittee_last_name"],
	  text_filter: true,
	},
	{
	  name: "Owner Company Name",
	  show_name: true,
	  search_key: "owner_business_name",
	  text_filter: true,
	},
	{
	  name: "Owner Contact Name",
	  show_name: true,
	  search_key: "owner_contact_name",
	  search_fields: ["owner_first_name","owner_last_name"],
	  text_filter: true,
	},
  ];
  
  export const _proMapFilters = [

	{
	  name: "Total Units",
	  show_name: true,
	  search_key: "residential_units",
	  type: "residential",
	  values: [
		{
		  name: "Any Units",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "1-10 Total Units",
		  filter: "1-to-10",
		  dropdown_name: "1 to 10 Units",
		  search_value: [
			"all",
			[">=", "residential_units", 1],
			["<=", "residential_units", 10],
		  ],
		},
		{
		  name: "11-30 Total Units",
		  filter: "11-to-30",
		  dropdown_name: "11 to 30 Units",
		  search_value: [
			"all",
			[">=", "residential_units", 11],
			["<=", "residential_units", 30],
		  ],
		},
		{
		  name: "31-50 Total Units",
		  filter: "31-to-50",
		  dropdown_name: "31 to 50 Units",
		  search_value: [
			"all",
			[">=", "residential_units", 31],
			["<=", "residential_units", 50],
		  ],
		},
		{
		  name: "51-100 Total Units",
		  filter: "51-to-100",
		  dropdown_name: "51 to 100 Units",
		  search_value: [
			"all",
			[">=", "residential_units", 51],
			["<=", "residential_units", 100],
		  ],
		},
		{
		  name: "101-200 Total Units",
		  filter: "101-to-200",
		  dropdown_name: "101 to 200 Units",
		  search_value: [
			"all",
			[">=", "residential_units", 101],
			["<=", "residential_units", 200],
		  ],
		},
		{
		  name: "201 + Total Units",
		  filter: "201-or-more",
		  dropdown_name: "201 or more Units",
		  search_value: [">", "residential_units", 200],
		},
	  ],
	},
	{
	  name: "Total Floors",
	  show_name: true,
	  search_key: "total_floors",
	  values: [
		{
		  name: "Any Units",
		  filter: "",
		  dropdown_name: "Any",
		  search_value: "",
		},
		{
		  name: "1 Floor",
		  filter: "1",
		  dropdown_name: "1 Floor",
		  search_value: ["==", "total_floors", 1],
		},
		{
		  name: "2 Floors",
		  filter: "2",
		  dropdown_name: "2 Floors",
		  search_value: ["==", "total_floors", 2],
		},
		{
		  name: "3-5 Floors",
		  filter: "3-to-5",
		  dropdown_name: "3 to 5 Floors",
		  search_value: [
			"all",
			[">=", "total_floors", 3],
			["<=", "total_floors", 5],
		  ],
		},
		{
		  name: "6-10 Floors",
		  filter: "6-to-10",
		  dropdown_name: "6 to 10 Floors",
		  search_value: [
			"all",
			[">=", "total_floors", 6],
			["<=", "total_floors", 10],
		  ],
		},
		{
		  name: "11-20 Floors",
		  filter: "11-to-20",
		  dropdown_name: "11 to 20 Floors",
		  search_value: [
			"all",
			[">=", "total_floors", 11],
			["<=", "total_floors", 20],
		  ],
		},
		{
		  name: "21-40 Floors",
		  filter: "21-to-40",
		  dropdown_name: "21 to 40 Floors",
		  search_value: [
			"all",
			[">=", "total_floors", 21],
			["<=", "total_floors", 40],
		  ],
		},
		{
		  name: "41+ Floors",
		  filter: "41-or-more",
		  dropdown_name: "41+ Floors",
		  search_value: [">", "total_floors", 40],
		},
	  ],
	},
	{
	  name: "Building Age",
	  show_name: true,
	  search_key: "year_built",
	  values: [
		  {
			  name: "Any",
			  filter: "",
			  dropdown_name: "Any",
			  search_value: ""
		  },
		  {
			  name: `New (After ${new_building_start})`,
			  filter: "new-contruction",
			  dropdown_name: `New (After ${new_building_start})`,
			  search_value: [
				  "all",
				  [">=", "year_built", new_building_start],
				],
		  },
		  {
			  name: `Recent (1991 to ${new_building_start})`,
			  filter: "recent-construction",
			  dropdown_name: `Recent (1991 to ${new_building_start})`,
			  search_value: [
				"all",
				[">=", "year_built", 1991],
				["<=", "year_built", new_building_start],
			  ],
		  },
		  {
			  name: "Post-War (1946 to 1990)",
			  filter: "post-war",
			  dropdown_name: "Post-War (1946 to 1990)",
			  search_value: [
				  "all",
				  [">=", "year_built", 1946],
				  ["<=", "year_built", 1990],
				],
		  },
		  {
			  name: "Pre-War (Before 1946)",
			  filter: "pre-war",
			  dropdown_name: "Pre-War (Before 1946)",
			  search_value: [
				"all",
				[">=", "year_built", 1600],
				["<=", "year_built", 1946],
			  ],
		  }
		  
	  ],
	},
	{
		name: "Asking PPSF",
		show_name: true,
		search_key: "inventory_stats_listings_average_ppsf",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: [
					"all",
					["<=", "inventory_stats_listings_average_ppsf", 750],
				  ]
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "750-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: [
					"all",
					[">=", "inventory_stats_listings_average_ppsf", 750],
					["<=", "inventory_stats_listings_average_ppsf", 1000],
				  ]
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: [
					"all",
					[">=", "inventory_stats_listings_average_ppsf", 1001],
					["<=", "inventory_stats_listings_average_ppsf", 1500],
				  ]
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: [
					"all",
					[">=", "inventory_stats_listings_average_ppsf", 1501],
					["<=", "inventory_stats_listings_average_ppsf", 2000],
				  ]
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: [
					"all",
					[">=", "inventory_stats_listings_average_ppsf", 2001],
					["<=", "inventory_stats_listings_average_ppsf", 2500],
				  ]
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: [
					"all",
					[">=", "inventory_stats_listings_average_ppsf", 2501],
					["<=", "inventory_stats_listings_average_ppsf", 5000],
				  ]
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: [
					"all",
					[">=", "inventory_stats_listings_average_ppsf", 5001],
				  ]
			}
		]
	},
	{
		name: "Sold PPSF",
		show_name: true,
		search_key: "inventory_stats_transactions_average_ppsf",
		values: [
			{
				name: "Any",
				filter: "",
				dropdown_name: "Any",
				search_value: ""
			},
			{
				name: "< $750 PPSF ",
				filter: "less-than-750",
				dropdown_name: "Less than $750",
				search_value: [
					"all",
					["<=", "inventory_stats_transactions_average_ppsf", 750],
				  ]
			},
			{
				name: "$750 to $1,000 PPSF",
				filter: "750-to-1000",
				dropdown_name: "$750 to $1,000",
				search_value: [
					"all",
					[">=", "inventory_stats_transactions_average_ppsf", 750],
					["<=", "inventory_stats_transactions_average_ppsf", 1000],
				  ]
			},
			{
				name: "$1,000 to $1,500 PPSF",
				filter: "1000-to-1500",
				dropdown_name: "$1,000 to $1,500",
				search_value: [
					"all",
					[">=", "inventory_stats_transactions_average_ppsf", 1001],
					["<=", "inventory_stats_transactions_average_ppsf", 1500],
				  ]
			},
			{
				name: "$1,500 to $2,000 PPSF",
				filter: "1500-to-2000",
				dropdown_name: "$1,500 to $2,000",
				search_value: [
					"all",
					[">=", "inventory_stats_transactions_average_ppsf", 1501],
					["<=", "inventory_stats_transactions_average_ppsf", 2000],
				  ]
			},
			{
				name: "$2,000 to $2,500 PPSF",
				filter: "2000-to-2500",
				dropdown_name: "$2,000 to $2,500",
				search_value: [
					"all",
					[">=", "inventory_stats_transactions_average_ppsf", 2001],
					["<=", "inventory_stats_transactions_average_ppsf", 2500],
				  ]
			},
			{
				name: "$2,500 to $5,000 PPSF",
				filter: "2500-to-5000",
				dropdown_name: "$2,500 to $5,000",
				search_value: [
					"all",
					[">=", "inventory_stats_transactions_average_ppsf", 2501],
					["<=", "inventory_stats_transactions_average_ppsf", 5000],
				  ]
			},
			{
				name: "$5,000+ PPSF",
				filter: "5000-or-more",
				dropdown_name: "$5,000 or more",
				search_value: [
					"all",
					[">=", "inventory_stats_transactions_average_ppsf", 5001],
				  ]
			}
		]
	},
	
  ];