import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container } from 'reactstrap';
import ProductCard from './components/ProfileCard';
import RelatedBuildings from './components/RelatedBuildings';
import ProfileTabs from './components/ProfileTabs';
import BuildingAmenities from './components/BuildingAmenities';

import BuildingManagement from './components/BuildingManagement';
import BuildingDetails from './components/BuildingDetails';

import CommercialPercent from './components/CommercialPercent';

import OfferingHistory from './components/OfferingHistory';

import LendingHistory from './components/LendingHistory';

import ShowMap from './../../../../shared/components/map';
import BuildingPeopleCompanies from './components/BuildingPeopleCompanies';
import BuildingEnergy from './components/BuildingEnergy';
import LatestStatus from './components/LatestStatus';
import LatestActivities from './components/LatestActivities';


import BuildingProfile from './components/BuildingProfile';
import { Publisher } from '../../../../shared/pubsub/publisher.js';

import HouseProfile from './../HouseProfile';
import LinearLoading from '../../../../shared/components/LinearLoading';

import classnames from 'classnames';

import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL,
	_isHouse
} from '../../../../shared/helpers/utils';

const ShowAmenities = (props) => {
	const { bldgArea, unitSize } = props;
	return (
		<Fragment>
		{bldgArea.totalArea > 0 && unitSize > 300 ? 
			<Row>
				<Col md={8}>
					<h3 className="section-title section-title--not-last">Amenities & Features</h3>
					<h3 className="section-title section-title--not-last subhead"> </h3>
					<hr/>
					<BuildingAmenities {...props} small />
				</Col>
				<Col md={4}>
					<h3 className="section-title section-title--not-last">&nbsp;</h3>
					<h3 className="section-title section-title--not-last subhead"> </h3>
					<CommercialPercent {...props} bldgArea={bldgArea} />
				</Col>
			</Row>
		:
			<Row>
				<Col md={12}>
					<h3 className="section-title section-title--not-last">Amenities & Features</h3>
					<h3 className="section-title section-title--not-last subhead"> </h3>
					<hr/>
					<BuildingAmenities {...props} />
				</Col>
			</Row>
		
		}
		</Fragment>
	)
	
}

const Profile = (props) => {
	
	const { buildingRes, unitQuery } = props;
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab :  "summary");
	const _toggleTab = (tab) => {
		if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/overview/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
		  setActiveTab(tab)
		  Publisher.publish(`track.buildingOverview.${tab}`, {building: buildingRes.key});
		}
	  };
	const bldgArea = {
		totalArea : buildingRes && buildingRes.total_building_floor_area ? parseInt(buildingRes.total_building_floor_area.replace(/,/g, "")) : 0,
		totalAreaResi : buildingRes && buildingRes.residential_floor_area ? parseInt(buildingRes.residential_floor_area.replace(/,/g, "")) : 0,
		totalAreaRetail : buildingRes && buildingRes.retail_floor_area ? parseInt(buildingRes.retail_floor_area.replace(/,/g, "")) : 0,
		totalAreaComm : buildingRes && buildingRes.commercial_floor_area ? parseInt(buildingRes.commercial_floor_area.replace(/,/g, "")) : 0,
		totalAreaGarage : buildingRes && buildingRes.garage_floor_area ? parseInt(buildingRes.garage_floor_area.replace(/,/g, "")) : 0,
		totalAreaFactory : buildingRes && buildingRes.factory_floor_area ? parseInt(buildingRes.factory_floor_area.replace(/,/g, "")) : 0,
	}
	
	if(bldgArea.totalArea <=0 && buildingRes.pipeline_total_construction_floor_area && buildingRes.pipeline_total_construction_floor_area>1000){
		bldgArea.totalArea = buildingRes.pipeline_total_construction_floor_area;
	}
	
	if(bldgArea.totalArea < bldgArea.totalAreaResi || bldgArea.totalArea < bldgArea.totalAreaComm || bldgArea.totalArea < bldgArea.totalAreaRetail){
		bldgArea.totalArea = bldgArea.totalAreaResi + bldgArea.totalAreaRetail + bldgArea.totalAreaComm + bldgArea.totalAreaFactory;
	}
	bldgArea["totalCommercial"] =  bldgArea.totalAreaComm;
	if(bldgArea.totalAreaRetail > bldgArea.totalAreaComm){
		bldgArea["totalCommercial"] = bldgArea.totalAreaRetail;
	}
	
	bldgArea["totalOther"] =  bldgArea.totalArea - bldgArea["totalCommercial"];
	
	const totalArea = bldgArea.totalArea;
	const unitSize =  totalArea / buildingRes.inventory_total;
	let isHouse = _isHouse(buildingRes);
	if(isHouse && !props.unitsRes) return <LinearLoading />;
	return (
			  <Container className="mobile-no-padding">
			  {(true || buildingRes.building_status == "resale") &&
			  <div className="tabs"> 	
			  <div className="tabs__wrap mb-2">
				  <Nav tabs>
					
					 <NavItem>
					  <NavLink
						className={classnames({ active: activeTab === 'summary' })}
						onClick={() => {
						  _toggleTab('summary');
						}}
					  >
						<span className="tab-title">Summary</span>
					  </NavLink>
					</NavItem>
					<NavItem>
					  <NavLink
						className={classnames({ active: activeTab === 'details' })}
						onClick={() => {
						  _toggleTab('details');
						}}
					  >
						<span className="tab-title">Details</span>
					  </NavLink>
					</NavItem>
					 
				  </Nav>
				</div>
			  </div>
		  		}
				  
				{activeTab === 'summary' &&
				  <TabPane tabId="summary"> 
				  {isHouse && props.unitsRes[0] ? 
					  <HouseProfile {...props} property={props.unitsRes[0]} />
				   :
				   <Fragment>
			    			<Row>
			      			<ProductCard {...props} />
			    			</Row>
							
							{['sponsor', 'resale'].indexOf(buildingRes.building_status)>=0 &&
								<Fragment>
									{unitQuery &&
										<LatestActivities {...props} />
									}
									<BuildingPeopleCompanies {...props} />
									{/*unitQuery &&
										<LatestStatus {...props} />
									*/}
									
									<BuildingManagement {...props} />
								</Fragment>
							}
							
			   			
							<Row>
			      			<Col md={8}>
			        			
			        			
			      			</Col>
			    			</Row>
							{props.buildingRes.user_description && props.buildingRes.user_description != "" ?
								<Fragment>
									
									<Row>
										<Col md={12}>
											<h3 className="section-title section-title--not-last">About This Building</h3>
											<h3 className="section-title section-title--not-last subhead"> </h3>
											<hr/>
										</Col>
									</Row>
									<Row>	
										<Col xl={6} lg={12} className="mb-3">
							 			{props.buildingRes.user_description && props.buildingRes.user_description != "" && 
								   			<p className="typography-message">
								  			{props.buildingRes.user_description.split('\n').map((item, key) => {
									  			return <React.Fragment>{item}<br/></React.Fragment>
								  			})}
								   			</p>
							   			}
										
										</Col>
										<Col xl={6} lg={12} >
							   			<ShowMap item={props.buildingRes} height={`360px`} />
							 			</Col>
									</Row>
									<ShowAmenities {...props} bldgArea={bldgArea} unitSize={unitSize} />
					  			</Fragment>
			    			:
								<Fragment>
									<ShowAmenities {...props} bldgArea={bldgArea} unitSize={unitSize} />
									<Row>
										<Col md={12}>
											<h3 className="section-title section-title--not-last">Location</h3>
											<h3 className="section-title section-title--not-last subhead"> </h3>
											<hr/>
										</Col>
										<Col xl={12} lg={12} >
							   			<ShowMap item={props.buildingRes} height={`360px`} />
							 			</Col>
									</Row>
					  			</Fragment>
							}
							
			    			
							
							
			    			
			    			<Row>
			      			<Col md={12}>
			        			<h3 className="section-title section-title--not-last"></h3>
			        			<h3 className="section-title section-title--not-last subhead"><p/> </h3>
			      			</Col>
			    			</Row>
			   			
			    				
			   			<Row className="mt-3">
			      			<Col md={12}>
			        			<h3 className="section-title section-title--not-last">Similar Bulidings</h3>
			        			<h3 className="section-title section-title--not-last subhead"> </h3>
			      			</Col>
			    			</Row>
			    			<Row> 
			     			<RelatedBuildings {...props} /> 
			    			</Row>
				   		 </Fragment>}
				  </TabPane>}
				  {activeTab === 'details' &&
					<TabPane tabId="details">  
						<BuildingProfile
							buildingDetails={props.buildingRes}
							{...props}
						  />
					</TabPane>
				  }
			  </Container>
		)
};

export default connect(state => ({
  user: state.user,
}))(Profile);
