import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  StreetViewPanorama,
  OverlayView,
} from "react-google-maps";
import {
  _getGeocodesForMapBox,
  _getGeocoding,
  _axiosCall
} from "../../helpers/apicalls";

const baseURL = process.env.API_ROOT + "/query/";

const GStreetMap = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${process.env.GMAP_API_KEY[0]}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  
  const { buildingRes, center } = props;
  const [mapPos, setMapPos] = useState(false);
  const [heading, setHeading] = useState(false);
  const [pano, setPano] = useState(false);
  const _getStreetMeta = async (address) => {
    
    var queryUrl = `${baseURL}gmapmeta?size=600x300&location=${address}&fov=90&key=${process.env.GMAP_API_KEY[0]}`;
    var gmapCallData = {
      url: queryUrl,
      method: "get"
    };
  
    let formattedData = await _axiosCall(gmapCallData)
    return formattedData;
  };
  useEffect(() => {
    
     
      
    var address = "";
    
    if(buildingRes.address){
      address = buildingRes.display_full_street_address && buildingRes.display_full_street_address.toLowerCase() +
      ", " + (buildingRes.address.borough ? buildingRes.address.borough.toLowerCase() : "new york");
    }else{
      var street_address = buildingRes && buildingRes.display_full_street_address ?  
      buildingRes.display_full_street_address.toLowerCase() : 
      buildingRes.full_street_address ? 
      buildingRes.full_street_address.toLowerCase() 
      : "";
      address = street_address;
      
      if(buildingRes.borough){
        address += ", " + buildingRes.borough.toLowerCase()
      }else{
        address += ", new york"
      }
   
      
    }
    console.log(buildingRes, address)
    _getStreetMeta(address).then(res => {
      console.log(res)
      
      var sv = new window.google.maps.StreetViewService();
      function checkNearestStreetView(panoData) {
        console.log(panoData);
        if (panoData) {
          if (panoData.location) {
            if (panoData.location.latLng) {
              const latt = panoData.location.latLng.lat();
              const longg = panoData.location.latLng.lng();
              //setMapPos({lat:latt, lng:longg});
            }
          }
        }
      }
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == 'OK') {
          //console.log({lat:results[0].geometry.location.lat(), lng:results[0].geometry.location.lng()});
          var checkaround = 50;
          sv.getPanoramaByLocation(
            results[0].geometry.location,
            checkaround,
            checkNearestStreetView
          );
          
          setMapPos({lat:results[0].geometry.location.lat(), lng:results[0].geometry.location.lng()});
          sv.getPanorama({ pano: res.pano_id }, function (data, status) {
            if (status === 'OK') { 
              //console.log(data)
              //console.log(data.location.latLng.lat(), data.location.latLng.lng());
              //console.log(results[0].geometry.location.lat(), results[0].geometry.location.lng());
              var heading = window.google.maps.geometry.spherical.computeHeading(data.location.latLng, results[0].geometry.location);
              setPano(data.location.pano);
              setHeading(heading)
            }
          })
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
      
   
    });
    
    
  }, []);
  if(mapPos === false || heading === false) return null;
  console.log(heading, mapPos)
  return(
  <GoogleMap defaultZoom={8} defaultCenter={props.center}>
    <StreetViewPanorama
      defaultPosition={{lat: mapPos.lat, lng: mapPos.lng}}
      defaultPano={pano}
      defaultPov={{heading:heading,pitch:10}}
      visible
      options={{
        streetViewControl: false,
        linksControl: false,
        panControl: false,
        addressControl: false,
        enableCloseButton: false,
        zoomControl: false,
        // fullscreenControl: false,
      }}
    ></StreetViewPanorama>
  </GoogleMap>
  )
});

export default GStreetMap;
