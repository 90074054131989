import React, { PureComponent } from 'react';
import { Progress, Table, Row, Container, Col } from 'reactstrap';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, 
} from 'recharts';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import SendMessageButton from '../../../../../shared/components/buttons/SendMessageButton';


import Panel from '../../../../../shared/components/Panel';


const baseAPI = process.env.API_ROOT + '/query/';

const colors = {
	Low: '#ff4861',
	Moderate: '#f6da6e',
    Middle: '#70bbfd',
    Upper: '#4ce1b6',
};

class BuyerLeadList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    themeName: PropTypes.string.isRequired,
  };
 
  
  constructor() {
    super();
    this.state = {
      data: false,
    };
  }
  
  _getBuyers = async (obj) => {
	
		const callData = {
		    url: baseAPI + 'typeform?form=U6laaV',
		    method: 'get',
		};
	    const getBuyers = await _axiosCall(callData)
	    .then(res => {
	        let data = [];
	        
	        if(res && res.items && res.items.length > 0){		
		        console.log(res.items)        
		        res.items.map(item => {
			        if(item.answers && item.answers[1] && item.answers[1].choice && item.answers[1].choice.label && item.answers[1].choice.label !== 'Real Estate Developer - have properties to sell' ){
				        const name = item.answers.filter(item => item.field.ref === '69242e43-dd41-4712-931e-ac13f7349e50').length > 0 ? item.answers.filter(item => item.field.ref === '69242e43-dd41-4712-931e-ac13f7349e50')[0].text : ''; 
				        item['name'] = name;
				        data.push(item);
			        }
		        })		        
		        this.setState( { data: data } );
	        }
	        
	        
	    })
	    .catch(error => {
	      console.log('_getBuyers transactions error: ', error);
	    });
	   
   };


  
  componentDidMount() {
	const query = '*:*';
	const lenderData = [];
	const { type } = this.props;  
	this._getBuyers();
  }
 	
  render() {
      const { t, dir, themeName, type, title, subhead } = this.props;    
      const { data } = this.state;
	  if(!data) return null;
	  const profileColors = ['#9decd6', '#9eb3c1', '#f9b2b2', '#66abf5'];
	
	  return (
		  
		  
			  <Panel 
			  	md={12}
			  	lg={12}
			  	xl={12} 
			  	title={`Latest Buyer Leads`}
			  	subhead={null}
			  >
			     <Table responsive striped className="dashboard__table-orders">
			      <thead>
			        <tr>
			          <th>Name</th>
			          <th>Submitted</th>		          
			          <th>Buyer Type</th>
			          <th># Units</th>
			          <th>Unit Type</th>
			          <th>Price Range</th>
			          <th>Have Agent?</th>
			          <th>Pre-Approved</th>
			          <th/>
			        </tr>
			      </thead>
			      <tbody>
			        {data.map((buyer) => (
			          <tr key={buyer.response_id}>
			          	
			            <td className="dashboard__table-orders-title">
			              <div className="dashboard__table-orders-img-wrap">
			              	<div className="dashboard__table-orders-img" style={{ background: profileColors[Math.floor(Math.random() * profileColors.length)], textAlign: 'center', lineHeight: '32px', fontSize:'15px', color: '#FFF' }}>{buyer && buyer.name ? buyer.name[0]  : ''}</div>
			                
			              </div>
			              {buyer.name}
			            </td>
			            <td style={{ textTransform:'capitalize' }}><span className="small-text light-text">{moment(buyer.submitted_at).fromNow()}</span></td>
			            <td style={{ textTransform: 'capitalize' }}>
			              <span className="small-text light-text">{buyer.answers[1].choice.label}</span>
			            </td>
			            <td>
			            	<span className="small-text light-text">{buyer.answers[2].boolean ? 'More than 1' : "1 Unit"}</span>
			            </td>
			            <td>
			              <span className="small-text light-text">{buyer.answers.filter(answer => answer.field.id === 'lsMqjDbq4SYk')[0] && buyer.answers.filter(answer => answer.field.id === 'lsMqjDbq4SYk')[0].choices.labels.join(' , ')}</span>
			            </td>
			            <td>
			              <span className="small-text light-text">{buyer.answers[4] 
				              && buyer.answers[4].choice 
				              && buyer.answers[4].choice.label
				          }</span>
			            </td>
			            <td>
			              <span className="small-text light-text">{buyer.answers[5] 
				              && buyer.answers[5].choice 
				              && buyer.answers[5].choice.label
						  }</span>
			            </td>
			            <td>
			              <span className="small-text light-text">{buyer.answers[6] 
				              && buyer.answers[6].choice 
				              && buyer.answers[6].choice.label
						  }</span>
			            </td>
			            <td style={{padding:'10px'}}>			  
							<SendMessageButton /> 
			            </td>
			          </tr>
			        ))}
			      </tbody>
			    </Table>	
			  </Panel>			
		);
	}
}

export default connect(state => ({
  rtl: state.rtl,
  dir: PropTypes.string.isRequired,
  theme: state.theme,
  user: state.user
}))(withTranslation('common')(BuyerLeadList));
