import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Avatar from '@material-ui/core/Avatar';

import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'

import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
} from "../../../shared/helpers/utils";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    fontSize: '12px',
  },
  formLabel: {
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px'
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: '5px 10px 10px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MatTableFiltersSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, _filters, buildingStages, setBuildingStage } = props;

  
  const handleChange = event => {
	  setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);
  };
  
  const handleChip = chip => {
	  console.log(chip)
	 // setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);
	  setBuildingStage(chip);

  };
  
  const setTitle = (search_key, search_value) => {
	  
	  return _filters.filter(filter => filter.search_key === search_key)[0].values.filter(value => value.filter === search_value)[0].name;
  }
  
  const slice = isMobile ? 99 : 7;
  
  const chips = ['sponsor', 'resale', 'pipeline', 'all'];
  return (
    <div className={classes.filterContainer}> 
    	<div className={"building_stage_selector"} style={{display:"flex",alignItems:"center",padding:"15px 0", margin:"0 10px", borderBottom:"1px solid #DBE0E8"}} >
	    	<span className="label">Building Stage:</span>
	    	<div className={classes.chips}>
			     {chips && chips.map(chip => {	
				     const selected = buildingStages && buildingStages.indexOf(chip)>=0;     
				     return selected ? 
				     	<Chip
						        size="medium"
						        label={`${_capitalizeText(chip)}`}
						        clickable
						        onClick={()=>handleChip(chip)}
						        value={chip}
						        color='primary'
						        deleteIcon={<DoneIcon />}
						        className={classes.chip}
					      /> :
					      <Chip
						        size="medium"
// 						        variant='outlined'
						        label={`${_capitalizeText(chip)}`}
						        clickable
						        onClick={()=>handleChip(chip)}
						        value={chip}
						        deleteIcon={<DoneIcon />}
						        className={classes.chip}
					      /> 
					      
				     
			     })}      
			</div> 
		</div>  
		<div>
      {_filters.slice(0, slice).map((filter, i) => {
	      	return (
		      	<FormControl className={classes.formControl}>
			        <InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
			        	{filter.default_name && !subFilters[filter.search_key] ? filter.default_name: filter.name}
			        </InputLabel>
			        <Select
			          labelId={`${filter.search_key}-label`}
			          id={`${filter.search_key}`}
			          name={filter.search_key}
			          value={subFilters[filter.search_key] ? setTitle(filter.search_key, subFilters[filter.search_key]) : ''}
			          onChange={handleChange}
			          input={<Input id={`${filter.search_key}`} />}
			          renderValue={selected => (
			            <div>			           
			               {selected}			
			            </div>
			          )}
			          MenuProps={MenuProps}
			          className={classes.menuItem} 
			        >
			          {filter.values.map(item => (
			            <MenuItem key={item.filter} value={item.filter} className={classes.menuItem}>
						  {item.dropdown_name}
			            </MenuItem>
			          ))}
			        </Select>
			    </FormControl>
			);
	      
	      }    
	  )}
	  </div>
	  {!isMobile &&
		  <Alert color="" className="alert--bordered" search hideOnStart alertId="advancedBuildingDatabaseFilters" minimizeText={`Advanced Filters`} condensedIcon={<AdvancedFilertIcon size={14} />} >
	      <div>
	      	{_filters.slice(slice).map((filter, i) => {
		      	return (
			      	<FormControl className={classes.formControl}>
				        <InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
				        	{filter.default_name && !subFilters[filter.search_key] ? filter.default_name: filter.name}
				        </InputLabel>
				        <Select
				          labelId={`${filter.search_key}-label`}
				          id={`${filter.search_key}`}
				          name={filter.search_key}
				          value={subFilters[filter.search_key] ? setTitle(filter.search_key, subFilters[filter.search_key]) : ''}
				          onChange={handleChange}
				          input={<Input id={`${filter.search_key}`} />}
				          renderValue={selected => (
				            <div>			           
				               {selected}			
				            </div>
				          )}
				          MenuProps={MenuProps}
				          className={classes.menuItem} 
				        >
				          {filter.values.map(item => (
				            <MenuItem key={item.filter} value={item.filter} className={classes.menuItem}>
							  {item.dropdown_name}
				            </MenuItem>
				          ))}
				        </Select>
				    </FormControl>
				);
		      
		      }    
		  )}
	      
	      </div>
	    </Alert>
    }
	  
    </div>
  );
}
