export const boroughTileset = {
  id: "mp-boroughs",
  url: "mapbox://ningzhou.cjvqztxde014j2untp542tmhc-6gzy8",
  source_layer: "nyc-boroughs",
};
export const majorMarketTileset = {
  id: "mp-major-markets",
  url: "mapbox://ningzhou.cjyowzl9l04jg2tocsino5qpp-16knh",
  source_layer: "nyc-major-markets-v2",
};
export const neighborhoodTileset = {
  id: "mp-neighborhoods",
  url: "mapbox://ningzhou.cjyhuu6sg05a52onyjsix2wm3-2t5i9",
  source_layer: "nyc-neighborhoods-v3",
};

export const BOROUGHS = {
  Brooklyn: { center: [-73.949997, 40.650002] },
  Bronx: { center: [-73.865433, 40.837048] },
  Manhattan: { center: [-73.984016, 40.754932] },
  // "Staten Island": { center: [-74.151535, 40.579021] },
  "Staten Island": {
    center: [-74.151535, 40.579021],
    geocode: [-74.133224, 40.591618],
  },
  Queens: { center: [-73.8448, 40.7181] },
};

export const pipeline_tileset = [
  {
    id: "mp-pipeline-buildings",
    layer_id: "mp-pipeline-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project",
    source_layer: "pipeline",
  },
  {
    id: "mp-sponsor-buildings",
    layer_id: "mp-sponsor-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project",
    source_layer: "mpnd",
    default_filter: ["==", "building_status", "sponsor"],
  },
];
export const pipeline_lot_tileset = [
  {
    id: "mp-pipeline-lots",
    layer_id: "mp-pipeline-lot-layer",
    url: "mapbox://ningzhou.pipeline-project",
    source_layer: "lot",
    default_filter: [
      "any",
      ["==", "source_provider", "permits"],
      [
        "all",
        ["==", "source_provider", "mpnd"],
        ["==", "building_status", "sponsor"],
      ],
    ],
  },
];
export const building_tileset = [
  // {
  //   id: "mp-resale-buildings",
  //   layer_id: "mp-resale-buildings-layer",
  //   url: "mapbox://ningzhou.pipeline-project",
  //   source_layer: "mpnd",
  //   default_filter: ["==", "building_status", "resale"],
  // },

  {
    id: "mp-building-manhattan",
    layer_id: "mp-building-manhattan-layer",
    url: "mapbox://ningzhou.nyc-buildings-manhattan",
    source_layer: "manhattan"
  },
  {
    id: "mp-building-staten",
    layer_id: "mp-building-staten-layer",
    url: "mapbox://ningzhou.nyc-buildings-staten-is",
    source_layer: "staten-is",
  },
  {
    id: "mp-building-bronx",
    layer_id: "mp-building-bronx-layer",
    url: "mapbox://ningzhou.nyc-buildings-bronx",
    source_layer: "bronx",
  },
  {
    id: "mp-building-brooklyn",
    layer_id: "mp-building-brooklyn-layer",
    url: "mapbox://ningzhou.nyc-buildings-brooklyn",
    source_layer: "brooklyn",
  },
  {
    id: "mp-building-queens",
    layer_id: "mp-building-queens-layer",
    url: "mapbox://ningzhou.nyc-buildings-queens",
    source_layer: "queens",
  },
  // {
  //   id: "mp-buildings-queens-0-4000",
  //   layer_id: "mp-buildings-queens-0-4000-layer",
  //   url: "mapbox://ningzhou.cjvlyvou11dzg2ws1sspk9u4k-5t9og",
  //   source_layer: "nyc-buildings-queens-0-4000",
  // },
  // {
  //   id: "mp-buildings-queens-4001-8000",
  //   layer_id: "mp-buildings-queens-4001-8000-layer",
  //   url: "mapbox://ningzhou.cjvmg56vh1jng2ykw5rikl569-1e9gm",
  //   source_layer: "nyc-buildings-queens-4001-8000",
  // },
  // {
  //   id: "mp-buildings-queens-8001-12000",
  //   layer_id: "mp-buildings-queens-8001-12000-layer",
  //   url: "mapbox://ningzhou.cjvmkfknk0vgi31s37e954hpa-9u6m1",
  //   source_layer: "nyc-buildings-queens-8001-12000",
  // },
  // {
  //   id: "mp-buildings-queens-12001",
  //   layer_id: "mp-buildings-queens-12001-layer",
  //   url: "mapbox://ningzhou.cjvmw4b5615xe33o9dqcpo0ae-9mm6a",
  //   source_layer: "nyc-buildings-queens-12001",
  // },
];
export const pro_tileset = [
 
  {
    id: "mp-pipeline-buildings",
    layer_id: "mp-pipeline-buildings-layer",
    url: "mapbox://ningzhou.pipeline-project-v2?version=1.1",
    source_layer: "mpnd",
    default_filter:[
    "all", 
      ["==", "ownership_type", "condo"],
      ["any",
        ["==", "building_status", "pipeline"],
        ["==", "building_status", "sponsor"],
      ]
    ]
  },
  {
    id: "mp-pipeline-buildings-pipeline",
    layer_id: "mp-pipeline-buildings-pipeline-layer",
    url: "mapbox://ningzhou.pipeline-project-v2?version=1.1",
    source_layer: "pipeline",
    default_filter:[
    "all", 
      ["==", "ownership_type", "condo"],
      ["any",
        ["==", "building_status", "pipeline"],
        ["==", "building_status", "sponsor"],
      ]
    ]
  },
  {
    id: "mp-pro-buildings",
    layer_id: "mp-pro-buildings-layer",
    url: "mapbox://ningzhou.mp-nyc-buildings",
    source_layer: "all"
   
  }
];
export const _addZoningLayer = (map, visibility) => {
  if(!map) return null;
  const layerTileset = {
    id: "mp-zoning",
    url: "mapbox://ningzhou.nyc-layers",
    source_layer: "nyzd",
  };
  if (!map.getSource("zoningdistdata")) {
    // map.addSource('zoningdistdata', {
    //     type: 'geojson',
    //     data: 'https://data.cityofnewyork.us/resource/wktb-rwje.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=99999',
    // })
    map.addSource("zoningdistdata", {
      type: "vector",
      url: layerTileset.url,
    });
  }
  
  if (!map.getLayer("zoning-dist-fills")) {
    map.addLayer({
       id: 'zoning-dist-fills',
       type: 'fill',
       source: 'zoningdistdata',
       "source-layer": layerTileset.source_layer,
       layout: { 'visibility': 'none'},
       paint: {
        'fill-color': ["match",["slice",["get",'ZONEDIST'],0,1],
          "C",["rgb",229,0,0], // Commercial
          "M",["rgb",207,92,230], // Manufacturing
          "R",["rgb",255,234,0], // Residential
          "P",["rgb",120,210,113], // Park
          "B",["rgb",128,128,128], // Battery Park City
          '#888888'],
        'fill-opacity': 0.15,
        }
    });
  }
  
  if (!map.getLayer("zoning-dist-lines")) {
    map.addLayer({
       id: 'zoning-dist-lines',
       type: 'line',
       source: 'zoningdistdata',
       "source-layer": layerTileset.source_layer,
       layout: {
         'visibility': 'none',
       },
       paint: {
        'line-color': '#000',
        'line-width': .5
        }
    });
  }
  
  if (!map.getLayer("zoning-dist-labels")) {
    map.addLayer({
      id: 'zoning-dist-labels',
      type: 'symbol',
      source: 'zoningdistdata',
      "source-layer": layerTileset.source_layer,
      minzoom: 13,
      layout: {
        'visibility': 'none',
        'text-field': ['get', 'ZONEDIST'],
        'text-font': [
          'Open Sans Semibold',
          'Arial Unicode MS Bold'
        ],
        "text-size": 12,
        'text-allow-overlap': true,
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2
      }
    });
  }
  
   
  map.setLayoutProperty('zoning-dist-fills', 'visibility', visibility);
  map.setLayoutProperty('zoning-dist-lines', 'visibility', visibility);
  map.setLayoutProperty('zoning-dist-labels', 'visibility', visibility);


};

export const _addCommercialOverlayLayer = (map, visibility) => {
  if(!map) return null;
  const layerTileset = {
    id: "mp-zoning",
    url: "mapbox://ningzhou.nyc-layers",
    source_layer: "nyco",
  };
  if (!map.getSource("codata")) {
    // map.addSource('zoningdistdata', {
    //     type: 'geojson',
    //     data: 'https://data.cityofnewyork.us/resource/wktb-rwje.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=99999',
    // })
    map.addSource("codata", {
      type: "vector",
      url: layerTileset.url,
    });
  }
  if (!map.getLayer("codata-dist-fills")) {
    map.addLayer({
       id: 'codata-dist-fills',
       type: 'fill',
       source: 'codata',
       "source-layer": layerTileset.source_layer,
       layout: { 'visibility': 'none'},
       paint: {
        'fill-color': '#10A8A8',
        'fill-opacity': 0.4,
        }
    });
  }
  
  if (!map.getLayer("codata-dist-lines")) {
    map.addLayer({
       id: 'codata-dist-lines',
       type: 'line',
       source: 'codata',
       "source-layer": layerTileset.source_layer,
       layout: {
         'visibility': 'none',
       },
       paint: {
         'line-color': '#10A8A8',
         'line-width': 2,
         'line-dasharray': [2, 1],
         }
    });
  }
  
  if (!map.getLayer("codata-dist-labels")) {
    map.addLayer({
      id: 'codata-dist-labels',
      type: 'symbol',
      source: 'codata',
      "source-layer": layerTileset.source_layer,
      minzoom: 13,
      layout: {
        'visibility': 'none',
        'text-field': ['get', 'OVERLAY'],
        'text-font': [
          'Open Sans Semibold',
          'Arial Unicode MS Bold'
        ],
        "text-size": 12,
        'text-allow-overlap': true,
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2
      }
    });
  }
  
   
  map.setLayoutProperty('codata-dist-fills', 'visibility', visibility);
  map.setLayoutProperty('codata-dist-lines', 'visibility', visibility);
  map.setLayoutProperty('codata-dist-labels', 'visibility', visibility);


};

export const _addZoningAmendmentsLayer = (map, visibility, filterOverride, idsuffix) => {
  if(!map) return null;
  let filter = filterOverride ? filterOverride : [
       "all",
       [">", "EFFECTIVE", 1577854800000],
  //     ["!=", "STATUS", "Adopted"]
     ]
  const layerTileset = {
    id: "mp-zoning",
    url: "mapbox://ningzhou.nyc-layers",
    source_layer: "nyzma",
  };
  if (!map.getSource("zmadata")) {
    // map.addSource('zoningamendmentsdata', {
    //     type: 'geojson',
    //     data: 'https://data.cityofnewyork.us/resource/r8d8-qrth.geojson?$$app_token=GBWfxNULOkBGzQ6O4IeyMr42B&$limit=99999&$where=status!="Adopted"%20OR%20effective>="2021-01-01T00:00:00"',
    // });
    map.addSource("zmadata", {
      type: "vector",
      url: layerTileset.url,
    });
  }
  if (!map.getLayer(`zmadata-fills-${idsuffix}`)) {
    map.addLayer({
       id: `zmadata-fills-${idsuffix}`,
       type: 'fill',
       source: 'zmadata',
       "source-layer": layerTileset.source_layer,
       layout: { 'visibility': 'none'},
       paint: {
         'fill-color': ['match',
         ['get', 'STATUS'],
         'Adopted',
         ["rgb",124,181,24],
         ["rgb",243,222,44]], // Pending
         'fill-opacity': 0.4,
       },
       filter: filter
    });
  }

  if (!map.getLayer(`zmadata-lines-${idsuffix}`)) {
    map.addLayer({
       id: `zmadata-lines-${idsuffix}`,
       type: 'line',
       source: 'zmadata',
       "source-layer": layerTileset.source_layer,
       layout: {
         'visibility': 'none',
       },
       paint: {
        'line-color': ['match',
        ['get', 'STATUS'],
        'Adopted',
        ["rgb",124,181,24],
        ["rgb",243,222,44]], // Pending
        'line-width': 2,
        'line-dasharray': [2, 1],
        },
        filter: filter
    });
  }
  
  if (!map.getLayer(`zmadata-labels-${idsuffix}`)) {
    map.addLayer({
      id: `zmadata-labels-${idsuffix}`,
      type: 'symbol',
      source: 'zmadata',
      "source-layer": layerTileset.source_layer,
      layout: {
        'visibility': 'none',
        'text-field': ["concat",['get', 'PROJECT_NAME']],
        'text-font': [
          'Open Sans Semibold',
          'Arial Unicode MS Bold'
        ],
        'text-variable-anchor': ['bottom', 'top', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        "text-size": 12,
        'text-allow-overlap': true,
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2
      },
      filter: filter
    });
  }

  map.setLayoutProperty(`zmadata-fills-${idsuffix}`, 'visibility', visibility);
  map.setLayoutProperty(`zmadata-lines-${idsuffix}`, 'visibility', visibility);
  map.setLayoutProperty(`zmadata-labels-${idsuffix}`, 'visibility', visibility);
};

export const _addRezoning2020 = (map, visibility) => {
  let filter = [
       "all",
       [">=", "EFFECTIVE", 1577854800000],
       ["==", "STATUS", "Adopted"]
     ];
  _addZoningAmendmentsLayer(map, visibility, filter, "nyzma2020");
}
export const _addRezoning2015 = (map, visibility) => {
  let filter = [
       "all",
       [">=", "EFFECTIVE", 1420088400000],
       ["<", "EFFECTIVE", 1577854800000],
       ["==", "STATUS", "Adopted"]
     ];
  _addZoningAmendmentsLayer(map, visibility, filter, "nyzma2015");
}
export const _addRezoning2010 = (map, visibility) => {
  let filter = [
       "all",
       [">=", "EFFECTIVE", 1262322000000],
       ["<", "EFFECTIVE", 1420088400000],
       ["==", "STATUS", "Adopted"]
     ];
  _addZoningAmendmentsLayer(map, visibility, filter, "nyzma2010");
}
export const _addRezoningBefore2010 = (map, visibility) => {
  let filter = [
       "all",
       ["<", "EFFECTIVE", 1262322000000],
       ["==", "STATUS", "Adopted"]
     ];
  _addZoningAmendmentsLayer(map, visibility, filter, "nyzmabefore2010");
}

export const _addPendingRezoning = (map, visibility) => {
  let filter = [
       "all",
       ["!=", "STATUS", "Adopted"]
     ];
  _addZoningAmendmentsLayer(map, visibility, filter, "nyzmapending");
}
export const _addHistoricDistrinctLayer = (map, visibility) => {
  if(!map) return null;
  const layerTileset = {
    id: "mp-zoning",
    url: "mapbox://ningzhou.nyc-layers",
    source_layer: "hist-dst",
  };
  if (!map.getSource("histdistdata")) {
    // map.addSource('histdistdata', {
    //     type: 'geojson',
    //     data: 'https://data.cityofnewyork.us/resource/carn-tkei.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=99999',
    // });
    map.addSource("histdistdata", {
      type: "vector",
      url: layerTileset.url,
    });
  }
  
  if (!map.getLayer("hist-dist-fills")) {
    map.addLayer({
       id: 'hist-dist-fills',
       type: 'fill',
       source: 'histdistdata',
       "source-layer": layerTileset.source_layer,
       layout: { 'visibility': 'none'},
       paint: {
        'fill-color': ["rgb",209, 208, 163],
        'fill-opacity': 0.4,
        }
    });
  }
  
  if (!map.getLayer("hist-dist-lines")) {
    map.addLayer({
       id: 'hist-dist-lines',
       type: 'line',
       source: 'histdistdata',
       "source-layer": layerTileset.source_layer,
       layout: {
         'visibility': 'none',
       },
       paint: {
        'line-color': ["rgb",209, 208, 163],
        'line-width': 2,
        'line-dasharray': [6, 3],
        }
    });
  }
  
  if (!map.getLayer("hist-dist-labels")) {
    map.addLayer({
      id: 'hist-dist-labels',
      type: 'symbol',
      source: 'histdistdata',
      "source-layer": layerTileset.source_layer,
      layout: {
        'visibility': 'none',
        'text-field': ['get', 'area_name'],
        'text-font': [
          'Open Sans Semibold',
          'Arial Unicode MS Bold'
        ],
        "text-size": 12
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2
      }
    });
  }
  
  map.setLayoutProperty('hist-dist-fills', 'visibility', visibility);
  map.setLayoutProperty('hist-dist-lines', 'visibility', visibility);
  map.setLayoutProperty('hist-dist-labels', 'visibility', visibility);
}

export const _addFloodZoneLayer = (map, visibility) => {
  if(!map) return null;
  const layerTileset = {
    id: "mp-zoning",
    url: "mapbox://ningzhou.nyc-layers",
    source_layer: "sea-lvl",
  };
 
  if (!map.getSource("flooddata")) {
    // map.addSource('flooddata', {
    //     type: 'geojson',
    //     data: 'https://data.cityofnewyork.us/resource/inra-wqx3.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=99999',
    // });
    map.addSource("flooddata", {
      type: "vector",
      url: layerTileset.url,
    });
  }
  
  if (!map.getLayer("flood-fills")) {
    map.addLayer({
       id: 'flood-fills',
       type: 'fill',
       source: 'flooddata',
       "source-layer": layerTileset.source_layer,
       layout: { 'visibility': 'none'},
       paint: {
        'fill-color': ["rgb",223, 41, 53],
        'fill-opacity': 0.2,
        }
    });
  }
  
  if (!map.getLayer("flood-lines")) {
    map.addLayer({
       id: 'flood-lines',
       type: 'line',
       source: 'flooddata',
       "source-layer": layerTileset.source_layer,
       layout: {
         'visibility': 'none',
       },
       paint: {
        'line-color': ["rgb",223, 41, 53],
        'line-width': 1,
        'line-dasharray': [6, 3],
        }
    });
  }
 
  
  map.setLayoutProperty('flood-fills', 'visibility', visibility);
  map.setLayoutProperty('flood-lines', 'visibility', visibility);
}

export const _addSubwayLayer = (map, visibility) => {
  if(!map) return null;
  // if (!map.getSource("subwayentrances")) {
  //   map.addSource('subwayentrances', {
  //       type: 'geojson',
  //       buffer:0,
  //       data: 'https://data.cityofnewyork.us/resource/he7q-3hwy.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=999999&$select=the_geom,objectid'
  //   });
  // }
  if (!map.getSource("subwaylines")) {
    map.addSource('subwaylines', {
        type: 'geojson',
        data: 'https://data.cityofnewyork.us/resource/s7zz-qmyz.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=99999&$select=the_geom,objectid,name'
    });
  }
  if (!map.getSource("subwaystationdata")) {
    map.addSource('subwaystationdata', {
        type: 'geojson',
        buffer:0,
        data: 'https://data.cityofnewyork.us/resource/kk4q-3rt2.geojson?$$app_token=RWOgTcMLbzZMrTyKBwK04qlUS&$limit=99999&$select=the_geom,objectid'
    });
  }
  if (!map.getLayer("subway-lines")) {
    map.addLayer({
       id: 'subway-lines',
       type: 'line',
       source: 'subwaylines',
       layout: { 'visibility': 'none'}, // hidden by default
       'paint': {
          'line-width': {
            'base': 1.75,
            'stops': [
            [12, 2],
            [22, 180]
            ]
          },
          'line-color': [
          'match',
          ['get', 'name'], // Color based on line
          '1',
          '#EE352E',
          '2',
          '#EE352E',
          '3',
          '#EE352E',
          '1-2',
          '#EE352E',
          '2-3',
          '#EE352E',
          '1-2-3',
          '#EE352E',
          '4',
          '#00933C',
          '5',
          '#00933C',
          '6',
          '#00933C',
          '4-5',
          '#00933C',
          '5-6',
          '#00933C',
          '4-5-6',
          '#00933C',
          '7',
          '#B933AD',
          'A',
          '#0039A6',
          'C',
          '#0039A6',
          'E',
          '#0039A6',
          'A-C',
          '#0039A6',
          'C-E',
          '#0039A6',
          'A-C-E',
          '#0039A6',
          'B',
          '#FF6319',
          'D',
          '#FF6319',
          'F',
          '#FF6319',
          'M',
          '#FF6319',
          'B-D',
          '#FF6319',
          'F-M',
          '#FF6319',
          'B-D-F',
          '#FF6319',
          'B-D-F-M',
          '#FF6319',
          'N',
          '#FCCC0A',
          'Q',
          '#FCCC0A',
          'R',
          '#FCCC0A',
          'W',
          '#FCCC0A',
          'N-Q',
          '#FCCC0A',
          'N-R',
          '#FCCC0A',
          'N-W',
          '#FCCC0A',
          'Q-R',
          '#FCCC0A',
          'R-W',
          '#FCCC0A',
          'N-Q-R',
          '#FCCC0A',
          'N-R-W',
          '#FCCC0A',
          'N-Q-R-W',
          '#FCCC0A',
          'G',
          '#6CBE45',
          'L',
          '#A7A9AC',
          'S',
          '#808183',
          'J',
          '#996633',
          'Z',
          '#996633',
          'J-Z',
          '#996633',
          'T',
          '#00add0',
          '#000' // any other line
          ]
      }
    });
  }
  if (!map.getLayer("subwaystations")) {
    map.addLayer({
       id: 'subwaystations',
       type: 'circle',
       source: 'subwaystationdata',
       layout: { 'visibility': 'none'}, // hidden by default
       paint: {
         'circle-radius': {
           'stops': [[8, 1.25], [11, 3.5], [16, 12]]
         },
         'circle-stroke-width': 2,
         'circle-stroke-color': '#000',
         'circle-color': '#fff'
        }
    });
  }
  // if (!map.getLayer("subway-entrances")) {
  //   map.addLayer({
  //      id: 'subway-entrances',
  //      type: 'circle',
  //      source: 'subwayentrances',
  //      layout: { 'visibility': 'none'}, // hidden by default
  //      'paint': {
  //         'circle-radius': {
  //           'stops': [[8, 0.75], [11, 1.5], [16, 6]]
  //         },
  //         'circle-stroke-width': 1,
  //         'circle-stroke-color': '#000',
  //         'circle-color': '#ddd'
  //     }
  //   });
  // }
  map.setLayoutProperty('subway-lines', 'visibility', visibility);
  map.setLayoutProperty('subwaystations', 'visibility', visibility);
  // map.setLayoutProperty('subway-entrances', 'visibility', visibility);
}