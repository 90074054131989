import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../../shared/helpers/utils";

const PriceRange = (props) => {
		const { buildingStats } = props;
		return (

			  <Col md={12} xl={4} lg={6} xs={12}>
			    <Card>
			      <CardBody className="dashboard__booking-card card__link">
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            {buildingStats && buildingStats.listing_price && buildingStats.listing_price.min_price ? 
				              <span>{_formatPrice(buildingStats.listing_price.min_price)} - {_formatPrice(buildingStats.max_price)}</span> 
				            : <span>--</span>}
			          </h5>
			          <CurrencyUsdIcon className="dashboard__trend-icon" />
			        </div>
			        <h5 className="dashboard__booking-total-description">Price Range</h5>
			        
			      </CardBody>
			    </Card>
			  </Col>
	  )
	
};

export default PriceRange;
