import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import { withOktaAuth } from '@okta/okta-react';
import WithTracker from './../../../../containers/App/WithTracker';

import FreeAccount from './../../payment/PricingCards/components/FreeAccount';

import TopBar from './../../TopBar'
import Footer from './../../Footer'

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Collapse
} from 'reactstrap';

export default withRouter(WithTracker(withOktaAuth(class Login extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      authenticated: null
    };
    
  }
  
  componentDidMount() {
	   
  }

 
  onSuccess(res) {
    if (res.status === 'SUCCESS') {
      return this.props.authService.redirect({
        sessionToken: res.session.token
      });
   } else {
    
    }
  }

  onError(err) {
    console.log('error logging in', err);
  }

  render() {
	const { location } = this.props;
	
	const resetToken = this.props.match.params.reset_token ? this.props.match.params.reset_token : false;
    if (this.props.authState.isPending) return null;
    if(this.props.authState.isAuthenticated) {
	    
	    return <Redirect to={{ pathname: '/' }}/>
	    
	    
	}else {
		return (
			<div className="plan__wrapper share__wrapper">
				<TopBar goTo={'plans'} />
				<FreeAccount />
				<Row className="login-container login">
		    		<Col lg={12} className="pt-0 signin">
		    		<div className={"widget-container login"}>
				      <OktaSignInWidget
				        baseUrl={this.props.baseUrl}
				        onSuccess={this.onSuccess}
				        onError={this.onError}
				        resetToken={resetToken}
				        {...this.props}
				    />
				    
				    <div className={'signin-toggle'}>{`Don't have an account?`} <Link to={`/pricing`}>Sign Up</Link></div>
					      
					<p className="disclaimer">By creating an account, you agree to our{` `}<a href="https://marketproof.com/terms-of-use" target="_blank">Terms of Service</a>{` `}and 
				    {` `}<a href="https://marketproof.com/privacy-policy" target="_blank">Privacy Policy</a>.</p> 
					</div>
				      
				        
				    </Col>
				</Row>
				<Footer />
			</div>
		)
		
	}
      

    	
  }
})));