import React from 'react';

import { Col, Container, Row } from 'reactstrap';
import MatTable from './components/MatTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import LockIcon from 'mdi-react/LockOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

import{
	_capitalizeText,
} from '../../shared/helpers/utils';

const Buildings = ( {user, match } ) => {
	
 
	  return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Coming Soon <LockIcon color={'#A3ADC2'} size={20} style={{marginTop:-3}}/></h3>
		        <h3 className="page-subhead subhead">Buildings Currently Undergoing Data Validation - Offering Accepted from 2000 - 2010</h3>
		      </Col>
		    </Row>
			{_hasPermission(user, 'coming-soon-buildings') ?
			    <Row>
			      <MatTable />
			    </Row>
			    :
				<Row>
			      <EnterpriseGate/>
			    </Row>			
			}
		  </Container>
		  )

 
	
};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(withRouter(Buildings)));

