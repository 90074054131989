import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";
import QuickStartGuide from '../../shared/components/QuickStartGuide';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user } = this.props;
	    if(!user) return null;

		return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Past Sales</h3>
		        {!isMobile && <h3 className="page-subhead subhead">Search Closed Sales by Date, Property Type, Price and More.</h3> }
		      </Col>
		    </Row>
		     {_hasPermission(user, 'past-sales') ?
			   <Fragment>
			     <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    {/* <Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
				          <p>
				          	<span className="bold-text">{`About The Data`}</span> 
				            <div>{`The information provided herein is provisional and subject to change as more buildings and units are vetted.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row> */}
			    <QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_c2ed641782"} type={"Past Sales"} />
			    <Row>
			      <UnitsSearchTable />
			    </Row>
		    </Fragment>
		    :
			     <Row>
				    <EnterpriseGate 
				    	copy={<span>Access to Past Sales requires an upgrade to your subscription.</span>}
			    		upgrade={true}
						user={user}
						img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-past-sales-mockup.jpg`}
						nextPlan={"complete-199"} />
				 </Row>
			}
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



