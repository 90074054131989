import React, { PureComponent } from 'react';
import { withRouter, Link } from "react-router-dom";

import { Col, Card, CardBody } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import LinearLoading from '../../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';
import ProfessionalItems from './ProfessionalItems';
import Panel from '../../../../shared/components/Panel';


import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_replaceAbbreviations,
	_formatBed,
	_calPercentage,
	_checkFieldForValue,
	_isCCC,
	propertyFL,
} from "../../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + '/query/';



class Professionals extends PureComponent {

  state = {
		rowsPerPage: 35,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		noMoreResults:false,
		Professionals: []
  }


  componentDidMount() {	  
	  const { buildingRes, property } = this.props;	  
	  //this._getProfessionals(buildingRes, property);
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	  if(prevProps.match.params.building_slug !== this.props.match.params.building_slug ||
		   prevProps.match.params.unit !== this.props.match.params.unit){
		  this.setState( { start:0, Professionals: []})
	  }else if(this.state.start !== prevState.start){
		 const { buildingRes, property } = this.props;
		// this._getProfessionals(buildingRes, property);
	  }		 
	
  }
  
  _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
   }
  
  _getProfessionals = async (building, property, obj) => {
	  
	  
	 if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
     }
	 const fl = propertyFL; 
	
	 const unitField = 'total_units';
	 	  
	  const defaultFq = [
		"building:(" + _buildingIDs + ")",
 		"-offering_status:(\"in contract\" sold)",
 		"offering_price:[100 TO *]",
	    'deleted:false',
	    "source_organizations:NYAG AND baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:*",
	  ];
	  
	 
	  
	  let relatedCallData = {
	    url: apiURL + "we3-properties?cache=86400",
	    method: "post",
	    query: {
	      // q: 'bin:*',
	      q: `*:*`,
	      fq: defaultFq,
	      fl: [...fl, "score"],
	      wt: "json",
	      sort: "modified_date desc",
	      start: this.state.start,
	      rows: this.state.rowsPerPage,
	    }
	  };
      let formattedData = await _axiosCall(relatedCallData)
	    .then(res => {
		    if(res && res.docs && res.docs.length>0){
				const newUnitListData = [...this.state.Professionals, ...res.docs];
				this.setState( { Professionals: newUnitListData, fetchInProgress: false, noMoreResults: res.docs.length < this.state.rowsPerPage })
			}else if(res.docs.length === 0 ){
				this.setState( { noMoreResults: true, fetchInProgress: false })
			}
	      
	    })
	    .catch(error => {
	      console.log("error: " + error);
	    });
	
	  return formattedData;
	}


	
  render(){
	  const { Professionals } = this.state;
	  const {  title, subhead, label,  professionals } = this.props;
	  if(!Professionals){
		  return null;
	  }
	 	  return(
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={title}
		  	subhead={subhead}
		  	label={label}
		  >
		  
		    <ProfessionalItems items={professionals} />
		   
		   {/* <div className="unit__load text-right semi-bold-text">			   		
			    <Tooltip title="See all professionals">
			        <Link to="/transaction-center">See All &raquo;</Link>			 
			  	</Tooltip>				
		    </div> */}
		  </Panel>
	  )
	  
  }
  
}

export default withRouter(Professionals);
