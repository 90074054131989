import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import ClockOutlineIcon from 'mdi-react/ArrowDownCircleOutlineIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';

  
const calculateAbsorption = (buildingRes) => {		
	if(!buildingRes.sales_start_date) return '--';	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);	
	const velocity = totalSold / months;
	
	const soldPct = totalSold / buildingRes.inventory_total;
	
	if(velocity <=0 || totalSold <=0 || soldPct < .03){
		return '--';
	}
	
	const monthsLeft = (buildingRes.inventory_unsold / velocity).toFixed(1);	
	
	return monthsLeft;
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes, transactionRes) => {		
	const totalAvail = transactionRes && transactionRes.discount && transactionRes.discount.avg_discount ? Math.abs(transactionRes.discount.avg_discount) : 0;
	const totalSold = 100 - totalAvail;
	return [{ value: totalAvail, fill: getDefaultChartColors[2] },
  				{ value: totalSold, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSellout = (buildingRes, type) => {		
	const monthsLeft = calculateAbsorption(buildingRes);
	if( monthsLeft === '--' ){
		if(type === 'fromNow'){
			return 'Not Enough Info';
		}else{
			return null;
		}
		
	}
	
	
	if(type === 'fromNow'){
		return moment().add(monthsLeft, 'M').fromNow();
	}	
	return `(${moment().add(monthsLeft, 'M').format("M/D/YYYY")})`;
}

const AverageDiscount = (props) => {
	const { buildingRes, location, transactionRes } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	
	return (
	  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
	    <Card>
	    <Link to={`${base_url}`}>
	      <CardBody className="dashboard__health-chart-card card__link">
	        <div className="card__title">
	          <h5 className="bold-text card__title-center">{`Average Discount`}
			  <ToolTip title={"Average different of initial listing price to sold price of units that closed during the selected timespan."} />
			  </h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={calculateBarData(buildingRes, transactionRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="dashboard__health-chart-info">
	            <ClockOutlineIcon style={{ fill: getDefaultChartColors[2] }} />
	            <p className="dashboard__health-chart-number">{transactionRes && transactionRes.discount && transactionRes.discount.avg_discount ? transactionRes.discount.avg_discount.toFixed(1) + '%' : '--'}</p>
	            <p className="dashboard__health-chart-units">off listing price</p>
	          </div>
	        </div>
	        <p className="dashboard__goal">Based On: {transactionRes && transactionRes.discount && transactionRes.discount.count ? transactionRes.discount && transactionRes.discount.count : null} Closed Sale(s)</p>
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
	)
};


export default AverageDiscount;
