import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";
import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import ActiveIcon from 'mdi-react/CheckDecagramIcon';
import ComingIcon from 'mdi-react/AlertDecagramOutlineIcon';
import VettedIcon from 'mdi-react/AlertDecagramOutlineIcon';
import CondoDecIcon from 'mdi-react/AlphaCCircleOutlineIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import MoveIcon from 'mdi-react/AlphaTCircleOutlineIcon';
import ConstructIcon from 'mdi-react/WrenchOutlineIcon';
import MultipleIcon from 'mdi-react/GoogleCirclesCommunitiesIcon';
import OfferingStatus from './OfferingStatus';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	UnitCurrentStatus,
	_formatUnitNumber,
	_getBuildingURL,
	_isResiUnit
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;

export const unitStageToolTips = {
	  'sponsor' : 'Unit is owned by the Sponsor.',
	  'resale' : ' Unit has been sold by the Sponsor.',
	  'unverified' : 'Unit has not been verified.',
	  'all' : ' All sponsor, resale and unverified units.',
	  
}


class UnitIcons extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
 

  render(){
	  const { modal } = this.state;
	  const { building, user, isUnit, unit, detail, eventView} = this.props;
	  
	  if(!unit) return null;
	  let hasContact = false;
                      
      const iconColor = '#BF96D9';
      
      let isAdmin = user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0;
      
      if(eventView){
	      if(isAdmin){
		      let status = false;
		      let statusList = false;
		      if(unit.topic[0] === "listings" && unit.event_tags.indexOf("just-listed")>=0){
			      statusList = ['active', 'in contract', 'sold', 'not_yet_recorded'];
			      status = 'active'
			  }else if(unit.topic[0] === "listings" && unit.event_tags.indexOf("contract-signed")>=0){
				  statusList = ['in contract', 'sold', 'not_yet_recorded'];
				  status = 'in contract'
		      }else if(unit.topic[0] === "transactions"){
			      statusList = ['sold'];
			      status = 'sold'
              }
		      return (
			  	<span className="unit_icons">
			  	
			  		{(unit.property_data && unit.property_data.verification_source && unit.property_data.verification_source.filter(source => ['NYAG', 'CDEC', 'ADEC', 'NYCDOF', 'user-verified-valid'].indexOf(source) >= 0 || source.indexOf("dof-acris") >=0).length <= 0) &&
				  		
				  	<Fragment>
				  	{(building.offering_verified_status === 'active' || (building && building.cdec_date && parseInt(moment(building.cdec_date).format('YYYY')) > 1970)) ?
				  		<Tooltip title={`Warning: Unit does not match a verified unit in this building from offering plan or condo dec - May Require Unit Merge (Admin)`}>
				  			<IconButton size={`small`} style={{background:'transparent'}} 
				  				onClick={(e)=>{e.stopPropagation();window.open(`https://admin.marketproof.com${_getBuildingURL(building, 'building_slugs')}`)}}
				  			>
				  				<span className="unit-type_badge" style={{background:"#F53246"}}>Unverified Unit <OpenInNewIcon size={11} /></span>
				  			</IconButton>
				  		</Tooltip>
				  	:
				  		<Tooltip title={`Notice: Unit is unverified because this building does not have any verified units from offering plan or condo dec - typically this is not an error (Admin)`}>
				  			<IconButton size={`small`} style={{background:'transparent'}} 
				  				onClick={(e)=>{e.stopPropagation();window.open(`https://admin.marketproof.com${_getBuildingURL(building, 'building_slugs')}`)}}
				  			>
				  				<span className="unit-type_badge" style={{background:'#FFF',color:'#A3ADC2',border:'1px solid #A3ADC2'}}>Unverified Unit <OpenInNewIcon size={11} /></span>
				  			</IconButton>
				  		</Tooltip>
			  		}
			  		</Fragment>
			  		}
			  		
			  		{unit.property_data && unit.property_data && unit.property_data &&  unit.property_data.current_status !== status && moment().diff(moment(unit.event_time), 'days')< 90 &&
				  		<Fragment>
				  			{statusList && statusList.indexOf(unit.property_data.current_status) < 0 ?
					  			
					  			
						  			<Tooltip title={`Warning: Unit status does not match this event and this event is less than 90 days old - May indicate an error that requires investigation (Admin)`}>
							  			<IconButton size={`small`} style={{background:'transparent'}} 
							  				
							  			>
							  				<span className="unit-type_badge" style={{background:"#F53246"}}>Currently: {_capitalizeText(unit.property_data.current_status)} <OpenInNewIcon size={11} /></span>
							  			</IconButton>
							  		</Tooltip>
						  			
					  			
					  		:	
					  			
						  		<Tooltip title={`Notice: Unit status is at a later sales stage than this event - typically this is not an error (Admin)`}>
						  			<IconButton size={`small`} style={{background:'transparent'}} 
						  				
						  			>
						  				<span className="unit-type_badge" style={{background:'#FFF',color:'#A3ADC2',border:'1px solid #A3ADC2'}} >Currently: {_capitalizeText(unit.property_data.current_status)} <OpenInNewIcon size={11} /></span>
						  			</IconButton>
						  		</Tooltip>
						  	}
				  		</Fragment>
			  		}
			  	</span>
			  )		      
		  }else{
			  return null;
		  }
	      
	      
      }
      
      if(isMobile && !detail){
	     return (
		    <span className="unit_icons">
			  	<UnitCurrentStatus status={unit.offering_status} unit={unit} />
			</span>
	     )
      }
      
	  return (
		   <span className="building_icons">
		   		{(_isResiUnit(unit) && unit.verification_source && 
			   		(unit.verification_source.filter(source => ['NYAG', 'CDEC', 'ADEC', 'NYCDOF', 'user-verified-valid'].indexOf(source) > -1 || source.indexOf("dof-acris") >=0 ).length > 0
			   		|| unit.source_organizations.filter(source => ['CDEC', 'ADEC'].indexOf(source) > -1).length > 0) && unit.property_status) 
			   		?
			   		<Tooltip title={`${_capitalizeText(unitStageToolTips[unit.property_status])}`}>
			  			<IconButton size={`small`} style={{background:"transparent"}}>
			  				<span className="unit-type_badge">{_capitalizeText(unit.property_status)} Unit</span>
			  			</IconButton>
			  		</Tooltip>
			  		:
			  		<Tooltip title={`This unit could not be verified by public record.`}>
			  			<IconButton size={`small`} style={{background:"transparent"}}>
			  				<span className="unit-type_badge">{unit.verification_source && 
				  				(unit.verification_source.indexOf('user-verified-valid') >= 0 || unit.verification_source.indexOf('dof-acris-adec') >= 0 || unit.verification_source.indexOf('dof-acris-cdec') >= 0)
				  				 && unit.property_type ? _capitalizeText(unit.property_type) : `Unverified Unit`}</span>
			  			</IconButton>
			  		</Tooltip>
            	 }
		   		{unit.property_type==='affordable unit' &&
			   		<Tooltip title={`Unit is affordable`}>
			  			<IconButton size={`small`} style={{background:"transparent"}}>
			  				<span className="unit-type_badge">Affordable</span>
			  				</IconButton>
			  		</Tooltip>
                }
                {unit.property_type==='resident manager unit' &&
	                <Tooltip title={`Unit is reserved for the resident manager`}>
			  			<IconButton size={`small`} style={{background:"transparent"}}>
			  				<span className="unit-type_badge">Res Mgr</span>
			  			</IconButton>
			  		</Tooltip>
                }	
                {unit.alternate_unitcodes && unit.alternate_unitcodes.length > 1  && 
			  		<Fragment>
				  		<Tooltip title={`This unit is also known as ${unit.alternate_unitcodes.filter(u => u !== unit.address_2_normalized).map(u => _formatUnitNumber(u)).join(', ')}`}>
				  			<IconButton size={`small`}>
				  				<MultipleIcon size={16} style={{ marginTop:"-3px", marginLeft:"0px"}} color={iconColor} />
				  			</IconButton>
				  		</Tooltip>
			  		</Fragment>
		  		}  		
		  		
		  </span>
	  )
	}
};
export default connect(state => ({
  user: state.user,
}))(withRouter(UnitIcons));

