import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BuyersListItem from './BuyersListItem';
import todoCard from '../types';

class BuyersList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editTodo: PropTypes.func,
      completeTodo: PropTypes.func,
      deleteTodo: PropTypes.func,
    }).isRequired,
    todos: PropTypes.arrayOf(todoCard).isRequired,
    completed: PropTypes.bool,
  };

  static defaultProps = {
    completed: false,
  };

  render() {
    const { list, actions, completed, analytics } = this.props;

    const todoListClass = classNames({
      'todo__item-comleted': completed,
    });

    return (
      list.map(item => (
        <div xs={12} key={item.id}>
          <BuyersListItem
            item={item}
            actions={actions}
            analytics={analytics}
          />
        </div>
      ))
    );
  }
}
export default BuyersList;
