import React, { PureComponent, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { setUserProfile } from '../../redux/actions/authActions';
import { withOktaAuth } from '@okta/okta-react';

import buyersListActions from '../../redux/actions/buyersListActions';
import { Publisher } from "./../pubsub/publisher.js";

import PropTypes from 'prop-types';
import { CardBody, Collapse, Button } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';

import{
	_capitalizeText,
	setLocalStorage,
	getLocalStorage
} from '../helpers/utils';
import{
	_axiosCall
} from '../helpers/apicalls';

import{
	_getUserSSO,
	_setSSOToken,
	_getSSOToken,
	_doSSOLogout
} from '../components/auth/sso/ssoutils';

const oktaToken = process.env.OKTA_TOKEN;

class  ExternalTopBar extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
	this.unit = false;
	this.logout = this.logout.bind(this);
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
  
  _getUserOktaData = (user) => {
     // console.log('_getBuildingData called', this.state)
     if(!user) return false;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
      
      const { authState, authService, dispatch } = this.props;
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://${process.env.OKTA_DOMAIN}/api/v1/users/${user.sub}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res){
		      this.setState({ fetchInProgress : false });
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
	      }
		  

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({ fetchInProgress : false });
	    dispatch(setUserProfile(user));
	  });
  }
  
    _getUserMongoData = (user) => {
  		if(!user) return false;
        if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
         }
      
        const { authState, authService, dispatch } = this.props;
      
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/userdata/${user.sub}`,
			method: "get"
		};

		 _axiosCall(axiosData)
			.then(res => {
				console.log('Data', res);
				if(res && res[0]){
					this.setState({ fetchInProgress : false });
					let mongoUser = Object.assign({}, res[0]);
					dispatch(buyersListActions.setUserData(mongoUser))
				}else{
					dispatch(buyersListActions.setUserData({}))
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

   }
   
   componentDidUpdate(prevProps, prevState, snapshot){
	  const { authState, authService, dispatch, dataSet, user } = this.props;
	  const ssoToken = _getSSOToken();
	  try{
		  console.log(authState);

		  if (!authState.isPending && !authState.isAuthenticated && !ssoToken) {
		      // When user isn't authenticated, forget any user info
		      //setUserInfo(null);
		      dispatch(setUserProfile(null));
		   } else {
			   
			  if(!user && !ssoToken){
				  authService.getUser().then((user) => {
		        
			        this._getUserOktaData(user);
			        if(!dataSet){
				        this._getUserMongoData(user); 
			        }
			         
			     });
				  
			  }else if(ssoToken && ssoToken.access_token && !user){
				   console.log(ssoToken)
				   _getUserSSO(ssoToken, this.props, setUserProfile)
					
			  }
		      
	
		  }
	  }catch(e){
		  console.log("Oauth Error");
	  }

  }
  
  async logout() {
	const { authState, authService, dispatch, user, dataSet } = this.props;
	const ssoToken = _getSSOToken();
	if(!authState.isPending && authState.isAuthenticated){
		authService.logout('/');
		dispatch(setUserProfile(null));
		
		Publisher.publish("logout", {});
	}else if(user.authType === "sso"){
		_doSSOLogout(this.props, setUserProfile);

	}else if(ssoToken){
		_doSSOLogout(this.props, setUserProfile);
	}
  }

 
  render(){
	  const { user, urlState, goTo, buttonText } = this.props;
	  const { collapse } = this.state;
	  return (
	    <div className="topbar">
	        <div className="topbar__wrapper container">
	          <div className="topbar__left">
	            {user && false ? 
		            <Link to={`/`} className="topbar__logo" />
		            :
	            	<a className="topbar__logo" href="https://marketproof.com/new-development"/>
	            }
	          </div>
	          <div className="topbar__right">
	          	{goTo === 'plans' ?
		            <Link to={`/pricing`}>
		          		<Button className="sign-in" color="primary">{buttonText ? buttonText : 'Sign Up'}</Button>
		          	</Link>

		        :
		        <Fragment>
		          	{user ? 
			          	<Button className="sign-in" color="primary" onClick={this.logout}>{buttonText ? buttonText : 'Sign Out'}</Button>
			          	:
			          	<Link to={`/mplogin`}>
		          			<Button className="sign-in" color="primary">{buttonText ? buttonText : 'Sign In'}</Button>
				  		</Link>
				  	}
				</Fragment>
	          	}
	          </div>
	        </div>
	    </div>
	
	  );
	}
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(ExternalTopBar))