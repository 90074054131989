import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { Progress, Table, Badge } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';
import TrendDownIcon from 'mdi-react/TrendingDownIcon';
import TrendUpIcon from 'mdi-react/TrendingUpIcon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import searchActions from '../../../../redux/actions/searchActions';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_nameToUrl,
	_scrollTo
} from "../../../../shared/helpers/utils";


class TradeHistory extends PureComponent {
  static propTypes = {
   	t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  
  }
  
  handleClick(e,unit) {
  	console.log(e, e.target);

  	const { history, name, itemKey, toggle, setFilter } = this.props;
  	const { addTag } = this.props.actions; 
  	let qString = '';
  	if(itemKey === 'by_bedrooms'){
	  	
	  	if(unit.val === 0){
		  	qString='studio';
	  	}else if(unit.val === 1){
		  	qString=`${unit.val}-bedroom`;
	  	}else if(unit.val < 4){
		  	qString=`${unit.val}-bedroom`;
	  	}else{
		  	qString=`4-or-more`;
	  	}
	  	setFilter("offering_bedrooms", qString);
	  	toggle('buildings');
	  	_scrollTo();
	  	
  	}else if(itemKey === 'by_boroughs'){
	  	const id = _nameToUrl(unit.val);
	  	addTag({type:'borough', data: unit.val, id: id, name: unit.val});
	  	toggle('buildings');
	  	_scrollTo();
	  	//history.push(`/analysis/discount/tab/buildings`);
  	}else if(itemKey === 'by_neighborhoods'){
	  	const hood = unit.val;
	  	const boro = unit.by_borough && unit.by_borough.buckets && unit.by_borough.buckets[0] ? unit.by_borough.buckets[0].val : '';
	  	const obj = {
		  	hood: hood,
		  	boro: boro
	  	}
	  const id = _nameToUrl(obj.hood + obj.boro);
	  addTag({type:'neighborhood', data: obj, id: id, name: obj.hood});
	  toggle('buildings');
	  _scrollTo();
	  //history.push(`/analysis/discount/tab/buildings`);
  	}
  	

  }



 render() {
	 
	 const { t, stats, name, itemKey, col } = this.props;
	 let statData = stats && stats.rank_analysis && stats.rank_analysis[itemKey] && stats.rank_analysis[itemKey].buckets ? stats.rank_analysis[itemKey].buckets : []
	 if(itemKey==='by_bedrooms'){
		 statData = stats && stats.rank_analysis && 
		 		stats.rank_analysis.by_bedrooms && 
		 		stats.rank_analysis.by_bedrooms.bedrooms && stats.rank_analysis.by_bedrooms.bedrooms.buckets ? stats.rank_analysis.by_bedrooms.bedrooms.buckets : []
	 }
	 return (
			  <Panel
			    xl={col ? col : 4}
			    lg={col ? col : 6}
			    md={12}
			    xs={12}
			    title={`Avg PPSF By ${name}`}
			    noButtons
			  >
			    <Table responsive striped className="table--bordered dashboard__audience-table">
			      <thead>
			        <tr>
			          <th>Rank</th>
			          <th>{name}</th>
			          <th>Avg PPSF</th>
			          <th>Med PPSF</th>
			          <th>$ Avg Unit</th>
			          <th># of Sales</th>
			        </tr>
			      </thead>
			      <tbody>
			      { statData.filter(item => !(item.val==="" || item.val === -1)).map((item, index) => {
					      const lastPPSF = item.avg_ppsf && item.avg_ppsf > 0 ? Math.round(item.avg_ppsf) : false;
					      const medPPSF = item.med_ppsf && item.med_ppsf > 0 ? Math.round(item.med_ppsf) : false;
					      const avgPrice = item.avg_price && item.avg_price > 0 ? Math.round(item.avg_price) : false;
					      
					      
					      return (
						      	<tr onClick={(e) => this.handleClick(e, item)} className="tr__link">
						      	  <td>
						      	  	<Badge color="matching" style={{ width:50, minWidth:50, color:'#B070CC', border:'1px solid #B070CC', background:'#FFF', padding: "6px 0" }}>
								  		<span className="bold-text">{index+1}</span>
								  	</Badge>
								  </td>							  
						          <td>
						          		<p>{itemKey === 'by_bedrooms' ? _formatBed(item.val) : _capitalizeText(item.val.toString())}
						          			{itemKey === 'by_neighborhoods' &&  
									  		<span className="small-text light-text" style={{display:"block"}}>
									  			{item.by_borough && item.by_borough.buckets && item.by_borough.buckets[0] ? _capitalizeText(item.by_borough.buckets[0].val) : ''}
									  	    </span>}
						          		</p>
								  		
						          </td>
						          <td>
							          	<span className="bold-text">{lastPPSF ? '$' + _formatNumber(lastPPSF) : '--'}</span>
						          	</td>
						          	<td>
						          		<span className="bold-text">{medPPSF ? '$' + _formatNumber(medPPSF) : '--'}</span>
						          	</td>
						          	<td>
						          		<span className="bold-text">{avgPrice ? _formatPrice(avgPrice) : '--'}</span>
						          	</td>
						          	<td>
						          		{_formatNumber(item.count)}
						          	</td>
						        </tr>
					      )
				      })
				      
			      }   
			      </tbody>
			    </Table>
			  </Panel>
		)
	}
};

function mapStateToProps(state) {
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TradeHistory));
