import React from "react";
import { Col, Container, Row, Modal, ButtonToolbar } from "reactstrap";

import { Tooltip } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import MapIcon from "mdi-react/FullscreenIcon";

import mapboxgl from "mapbox-gl";
// import GMap from './GMap';
import BuildingMapBox from "./BuildingMapBox";
import SalesMapBox from "./SalesMapBox";

class MapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  render() {
    const { modal } = this.state;
    if (!mapboxgl.supported()) {
      return null;
    }
    const customStyles = {
      content: {
        height: window.innerHeight - 150,
        maxHeight: window.innerHeight,
        width: window.innerWidth,
        maxWidth: window.innerWidth,
        padding: "0px",
      },
    };

    // console.log(window.innerWidth / window.innerHeight )
    return (
      <div className={this.props.section ? "sales-map-model" : `map-modal`}>
        {/* showMapViewButton props is used for show and hide expand and view button */}
        {this.props.showStreetView && (
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Tooltip
              title={!this.props.streetView ? "Street View" : "Map View"}
            >
              <Button
                onClick={this.props.handleStreetView}
                // startIcon={<MapIcon size={20} color={"#780F9E"} />}
              >
                {!this.props.streetView ? "Street View" : "Map View"}
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
        {!this.props.showMapViewButton ? (
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Tooltip title={`Expand map to explore surrounding properties`}>
              <Button
                onClick={this.toggle}
                startIcon={<MapIcon size={20} color={"#780F9E"} />}
              >
                Expand
              </Button>
            </Tooltip>
          </ButtonGroup>
        ) : this.props.streetView ? null : (
          <ButtonGroup
            size="small"
            aria-label="small outlined button group"
            className="map_view_button"
          >
            <Tooltip title={`Change map view`}>
              <Button
                onClick={this.props.toggleView}
                // startIcon={<MapIcon size={20} color={"#780F9E"} />}
              >
                {!this.props.standardView ? "Standard View" : "Satellite View"}
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName={`ltr-support`}
          className={`modal-dialog map__modal`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.toggle}
            />
          </div>
          <div className="modal__body">
            {this.props.section ? (
              <SalesMapBox
                {...this.props}
                {...customStyles.content}
                fullSize={true}
              />
            ) : (
              <BuildingMapBox
                {...this.props}
                {...customStyles.content}
                fullSize={true}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default MapModal;
