/* eslint-disable react/no-children-prop */
import React, { useState, Fragment } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar, Nav, NavLink, NavItem, TabContent, TabPane, Container
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import SaveIcon from 'mdi-react/ContentSaveOutlineIcon';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { _hasPermission	} from "../../../shared/helpers/access";
import { nanoid } from 'nanoid'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';

import RichTextEditor from "../../../shared/components/form/RichTextEditor";
import LinearLoading from '../../../shared/components/LinearLoading';
import FormAutocompleteSelect from './FormAutocompleteSelect';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_slugifyText,
	_privateKey,
	matTheme,
	_urlSafe
} from '../../../shared/helpers/utils';
import{
	_axiosCall
} from '../../../shared/helpers/apicalls';
import{
	_shareThemes,
} from '../../../shared/helpers/access';
const countries = [
  {value: 'AlbaniaCountry', label: 'Albania'},
  {value:'AlgeriaCountry', label: 'Algeria'},
  {value:'AndorraCountry', label: 'Andorra'},
  {value:'AngolaCountry', label: 'Angola'}
];
const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    multiline={rows}
	    rows={rows}
		onFocus={e=>{e.stopPropagation();}}
		onClick={e=>{e.stopPropagation();}}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
		variant="outlined"
	  />
	</ThemeProvider>
);
const renderLinkField = ({
	  input, label, meta: { asyncValidating, touched, error }, children, select, rows
	}) => {
		console.log(touched, error)
		return (
		<ThemeProvider theme={matTheme}>
		  <TextField
		    className="material-form__link"
		    label={label}
		    error={error!==undefined}
		    helperText={error}
		    value={_urlSafe(input.value)}
		    children={children}
		    select={select}
		    multiline={rows}
		    rows={rows}
		    onChange={(e) => {
		      e.preventDefault();
		      
		      input.onChange(e.target.value);
		      //checkLink(e.target.value)
		      
		    }}	
		  />
		</ThemeProvider>
		)
};

const renderCheckbox = ({ input, label }) => {
	return (
		  <FormControlLabel
		      control={
		        <Checkbox
		          checked={input.value ? true : false}
		          onChange={input.onChange}
		        />
		      }
		      label={label}
		    />
   )
}



const renderRichTextEditor = ({
  input, label, meta: { touched, error }, children, select, rows, placeholder
}) => {
	
	const editorConfig = {
			readonly: false, // all options from https://xdsoft.net/jodit/doc/
			height:500,
			allowResizeX: false,
			enableDragAndDropFileToEditor: true,
			buttons: [
				'bold',
				'italic',
				'underline',			
				'fontsize',
				'|',
				'link', 'hr', 'align', 
				'|',
				'undo', 'redo'
			],
			buttonsMD: [
				'bold',
				'italic',
				'underline',			
				'fontsize',
				'|',
				'link', 'hr', 'align', 
				'|',
				'undo', 'redo'
			],
			buttonsXS: [
				'bold',
				'italic',
				'underline',			
				'fontsize',
				'|',
				'link', 'hr', 'align', 
				'|',
				'undo', 'redo'
			],
			colorPickerDefaultTab: 'text',
			style: {
				fontSize:16
			},
			placeholder: placeholder
			
	}
	return (
		<RichTextEditor
			  value={input.value}
			  onChange={(content) => {
				input.onChange(content);
			  }}	
			  editorConfig={editorConfig}
		/>
   )
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const apiUrl = process.env.API_ROOT + '/buyerslist/';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const checkLink = (formValues) => {
	
	  if(!formValues.customLink || formValues.customLink.length < 3){
		  return sleep(1).then(() => {
		  	throw { customLink: 'Must be 3 or more characters.' }
		  
		  })
	  }
	        
      let dupquery = { "custom_link" : formValues.customLink };
            
      let apiData = {
			url: apiUrl+'sharepage/query',
			method: "post",
			query: dupquery
	  	  };
	  
	  
	  return _axiosCall(apiData)
	    .then(res => {
		    if(res.length>0){
			    throw { customLink: 'Link has already been used.' }
		    }
		   
		})	
	  
 }



const AISummaryForm = ( props ) => {
	
	const { handleSubmit, reset, t, buildingRes, user, toggle, property, values, listing, saved, handleGenerate, generateShareBase, generateShareLink, formValues, fetchInProgress, disabled} = props;
	const [copied, setCopied] = useState(false);
	
	const [activeTab, setActiveTab] = useState('overview');	
	
	const isPDF = !(formValues && (!formValues['summaryType'] || formValues['summaryType'] !== "pdf"));
	const renderRadioThemes = ({ input, label, disabled }) => {
		return (
		  <RadioGroup aria-label="reportTheme" name="reportTheme" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>
			<FormControlLabel value="marketproof" control={<Radio />} label="Marketproof" />
			{user && user.themes && user.themes.length > 0 &&
				<Fragment>
					{user.themes.map(theme => {
						
						if(_shareThemes[theme]){
							return (
								<FormControlLabel value={theme} control={<Radio />} label={_shareThemes[theme]['display_name']} />
							)
						}
					})}
				</Fragment>
			}
		  </RadioGroup>
	   )
	}	
	const renderRadioModel = ({ input, label, disabled }) => {
		
		return (
		  <RadioGroup aria-label="aiModel" name="aiModel" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>
				
			<FormControlLabel value="text-davinci-003" control={<Radio />} label="Davinci-03 (Best: $.02/1K)" />		
			<FormControlLabel value="text-curie-001" control={<Radio />} label="Curie-01 ($.002/1K)" />
			<FormControlLabel value="text-babbage-001" control={<Radio />} label="Babbage-01 ($.0005/1K)" />
			<FormControlLabel value="text-ada-001" control={<Radio />} label="Ada-01 (Fastest: $.0004/1K)" />
		  </RadioGroup>
	   )
	}
	
	const renderRadioType = ({ input, label, disabled }) => {		
		return (
		  <RadioGroup aria-label="summaryType" name="summaryType" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>							
			<FormControlLabel value="listing" control={<Radio />} label="Listing Description" />
			<FormControlLabel value="social" control={<Radio />} label="Social Media" />
			<FormControlLabel value="general" control={<Radio />} label="General Summary" />		
		  </RadioGroup>
	   )
	}
	return(
	    <form className="material-form" onSubmit={handleSubmit}>
	    
	    <Row>
	      
	{/*	  <Col sm={12} md={12} lg={12} xl={12}> 
				<div className="share__link">
				<span className="material-form__label  bold">Summary Type</span> 
				<div className="small-text light-text">Choose how you intend to use the summary.</div>
				  <Field name="summaryType" component={renderRadioType} label="Select Share Type" value="web" /> 
				</div>
			   
		  </Col> */}
		  <Col sm={12} md={12} lg={12} xl={12}> 
		  <div className="share__link">
		  <span className="material-form__label  bold">Your Request</span> 
		  <div className="small-text light-text mb-3">Give our AI assistant a little direction on how to write the summary or choose one from the list.</div>
		  <Field
			  name="userPrompt"
			  id="userPrompt"
			  label="Prompt"
			  margin="normal"
			  fullWidth
			  placeholder={"Type a custom request or click to choose.."}
			  component={FormAutocompleteSelect}
			/>
			</div>
				   
			  </Col>
		 {/* <Col sm={12} md={12} lg={12} xl={12}> 
			  <div className="mb-2">    
				<Field name="includeNeighborhoodInfo" component={renderCheckbox} label="Include Neighborhood Information" value="neighborhood" /> 
			  </div>
		  </Col> */}
		  {false && user && _hasPermission(user, "admin-tools") ?  
		  <Col sm={12} md={12} lg={12} xl={12} > 
				  <div className="" style={{background:"#F5F7FA",padding:"20px",border:'1px solid #D6BAE8'}}>
				  
				  <span className="material-form__label  bold">AI Model (Admin Only)</span> 
				  <div className="small-text light-text">Test results using different AI models for performance and cost</div>
					<Field name="aiModel" component={renderRadioModel} label="Choose AI Model" value="web" /> 
				  </div>
				 
			</Col>
			:
			null
		  }
		   <Col sm={12} md={12} lg={12} xl={12}> 
		      <div className="share__link">
			  		<hr style={{borderColor:"#EEE"}} />
				
		             <Field
		              name="summary"
		              component={renderRichTextEditor}
		              placeholder="Your AI generated summary will appear here.."
					  disabled={fetchInProgress}
		              multiline
		              rows="2"
		              rowsMax="2"
		            />
		          </div> 
		    </Col>
			

		</Row> 
		<Row>      
		{fetchInProgress && <LinearLoading />} 
		</Row> 
			  <ButtonToolbar className="modal__footer ai">
			  	{/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
				<Tooltip title="Save & Copy Share Link to Clipboard">
				<CopyToClipboard text={formValues && formValues['summary'] ? formValues['summary'].replace(/<\/p>/g, "\n").replace(/<br>/g, "\n").replace(/(<([^>]+)>)/gi, "") : ""}>
					<Button
						 variant="outlined"
						  color="#780F9E"
						 className="buyersList__secondary-button"	
						 disabled={fetchInProgress || !formValues || !formValues['summary']}         
						 startIcon={<ContentCopyIcon />}
					 >	Copy
					 </Button>	
				</CopyToClipboard>			 
				</Tooltip>{' '}
				<Tooltip title="Generate">	
			        	<Button
				         	variant="contained"
				         	color="primary"
				         	className="buyersList__primary-button"		         
				         	startIcon={<CogOutlineIcon />}
							onClick={() => handleGenerate()}
							disabled={fetchInProgress || disabled}
							style={{width: isMobile ? "160px" : "180px"}}
				     	>	{fetchInProgress ? "Generating..." : "Generate"}
				     	</Button>	
			  		</Tooltip>
				
			  	
	         </ButtonToolbar>
	    </form>
    )
};

AISummaryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
const form = 'ai_summary_form';


export default connect(
  (state, props) => {
	  
  let savedValues = {};
  savedValues['summary'] = ""; 
  savedValues['summaryType'] = "listing";
  savedValues['userPrompt'] = "";
  savedValues['includeNeighborhoodInfo'] = false;
  savedValues['aiModel'] = "text-davinci-003";
  const initalValues = Object.assign(savedValues , props.user);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
  asyncValidate: checkLink,
  asyncChangeFields: ["customLink"]
})(AISummaryForm));
