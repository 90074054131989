import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import { Link } from "react-router-dom";

import NewsItem from './NewsItem';
import { _axiosCall, _buildingIDs } from '../../../../../../shared/helpers/apicalls';
import moment from 'moment';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import Slider from 'react-slick';
import Panel from '../../../../../../shared/components/Panel';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	mpndCohortQuery,
	pipelineCohortQuery
} from "../../../../../../shared/helpers/utils";
const apiURL = process.env.API_ROOT + '/query/';


class TimelineHistory extends PureComponent {  
  state = {
		rowsPerPage: 10,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		data: [],
		noMoreResults:false
  }
	
  componentDidMount() {
  	  this._getNews();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  if(this.state.start !== prevState.start){
		  this._getNews();
	  }  
  }
  
  _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
  }
  
  _getNews = async () => {
	  	  
	  	if(this.state.fetchInProgress === true){
		  	return false;
	  	}
	  	this.setState({ fetchInProgress: true });
	  
	    const fq = [
			
			`{!join from=key fromIndex=we3-buildings to=building}((${pipelineCohortQuery}) OR (${mpndCohortQuery}))`
		    
	    ]	    
	    const q = this.props.buildingRes ? `'topic:stories' AND building:${this.props.buildingRes.key}` : 'topic:stories';
		const axiosData = {
			url: apiURL + "we3-activities-3?cache=30",
			method: "post",
			query: {
				q,
				fq,
				sort: "time desc",
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start
			}
		};

		const formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					this.setState( { data: [...this.state.data, ...res.docs], fetchInProgress: false })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false })
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};


  render() {
	  
	  const { data } = this.state;	  
	  	  
	  const settings = {
		  dots: false,
		  infinite: false,
		  initialSlide:0,
		  speed: 500,
		  autoplay: false,
		  swipeToSlide: true,
		  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
		  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
		  responsive: [
			{ breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1  } },
		    { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1  } },
		    { breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 2  } },
		    { breakpoint: 1536, settings: { slidesToShow: 2, slidesToScroll: 2  } },
		    { breakpoint: 100000, settings: { slidesToShow: 2, slidesToScroll: 2  } },
		  ],
	  };
 
	  return (
			 <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={'Latest News'}
		  	subhead={''}
		  	label={null}
		  >
			    <div className="news__container">
			    {data ? 
			    <Slider {...settings} className="timeline">
				    {data.map(item => {
					    try{			
							const data = JSON.parse(item.data);
							item.data = data;	
						}catch(e){
							//ToDo
						}
					    
					    return <NewsItem item={item} />
						       				    
				    })}
			    </Slider>
			    : null
			    }
			    </div>
			     <div className="unit__load text-right semi-bold-text">			   		
			    <Tooltip title="See more news">
			        <Link to="/news">See More News &raquo;</Link>			 
			  	</Tooltip>				
		    </div>
			  </Panel>
	    )
   };
}

export default TimelineHistory;
