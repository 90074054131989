import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row,  Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Link } from "react-router-dom";

import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';
import SnackBar from '../../../../shared/components/SimpleSnackBar';
import TrialAlert from '../../../../shared/components/payment/TrialAlert';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import Slider from 'react-slick';
import Panel from '../../../../shared/components/Panel';
import { Tooltip } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

import fileDownload from 'js-file-download';
import GroupItem from './GroupItem';
import UnitsSearchAppBar from './UnitsSearchAppBar';


import{
	_capitalizeText,
	_objectEqual,
	_scrollTo
} from '../../../../shared/helpers/utils';
import{
	_axiosCall
} from '../../../../shared/helpers/apicalls';

import { 
	_reportFilters
} from "../../../../shared/helpers/filters";


const oktaToken = process.env.OKTA_TOKEN;

const apiUrl = process.env.API_ROOT;
//const apiUrl = 'http://localhost:8000/buyerslist/';
const apiURL = process.env.API_ROOT + '/query/';

//const apiUrl = 'https://newsapi.we3.com/buyerslist/'
const defaultSubFilter = {};
class CommunityGroups extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		  fetchInProgress: false,
		  snackShow: false,
		  snackMessage: false,
		  snackType: 'success',
		  groups: null,
		  refresh:false,
		  order:  "desc",
		  orderBy: "modified_timestamp",
		  page: 0,
		  rowsPerPage: 10,
		  numFound: 0,
		  searchText : '',
		  subFilters : defaultSubFilter,
		  buildingRes: null,
		  unitRes: null,
		  tags : []
		};
		this.snackOpen = this.snackOpen.bind(this);
		this.snackClose = this.snackClose.bind(this);
		this._getCommunityGroups = this._getCommunityGroups.bind(this);
		this.toggleRefresh = this.toggleRefresh.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);	 
		this.handleSearch = this.handleSearch.bind(this);	
		this._setSubFilter = this._setSubFilter.bind(this);	
		this._resetSubFilter = this._resetSubFilter.bind(this);	     
		this._setSubFilters = this._setSubFilters.bind(this);
	}
	  
	componentDidMount(){
	  const { authState, authService, dispatch, user } = this.props;
	  console.log(authState);
	  this._getCommunityGroups();
	}
	componentDidUpdate(prevProps, prevState) {
		
		const { user } = this.props;
		let subFilterChanged =
		_reportFilters.filter(item => {
			return (
				Array.isArray(item.search_key) ? 
					item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
				:
					this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
			);
		}).length > 0;
		
		if(subFilterChanged ||
			prevState.refresh !== this.state.refresh ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.searchText !== prevState.searchText ||
			JSON.stringify(this.state.tags) !== JSON.stringify(prevState.tags)
		){
			 this._getCommunityGroups();

		}
		
	}
	   
	toggleRefresh = () => {
		this.setState(prevState => ({ refresh: !prevState.refresh }));;
	};
	
	snackOpen = (message, type) => {
		this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	};

	snackClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}	
		this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_getCommunityGroups = () => {
	 // console.log('_getBuildingData called', this.state)
		   
	  const { authState, authService, dispatch, user, group, subTab } = this.props;	  	  
	   const { orderBy, order, rowsPerPage, page, searchText, subFilters, tags } = this.state;
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  
	  let query = {
		  group_access: "public",
		  deleted : { "$exists" : false },
		  source: { "$ne" : "PIPELINE-APP"},
	  }
	  
	  if(searchText){
		  query['$or'] = [ 
			  {"group_name" : { "$regex" : searchText, "$options" : "i"}}
		  ];
	  }
	  if(tags && tags.length>0){
		  query['group_tags'] = { "$in" : tags };
	  }else{
		  delete query['group_tags'];
	  }
	  let sort = {};
	  
		sort[orderBy] = order == "desc" ? -1 : 1;
		let userData = {
		  	url: apiUrl+'/buyerslist/groups/query',
		  	method: "post",
		  	query: {
			  	query : query,
			  	sort : sort,
			  	limit : rowsPerPage,
			  	skip : (page) * rowsPerPage
			  	
		  	}
		};
	  
	  
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getCommunityGroups res back ,", res);
		  const unitKeys = [];
		  const buildingKeys = [];
		  
		  if(res && res.numFound>0){	
			  
			  res.docs.map(item => {
				  if(item && item.group_data){
					  if(item.group_data.buildings && item.group_data.buildings[0]){
						  item.group_data.buildings.slice(0,4).map(bldg => {
							  buildingKeys.push(bldg)
						  })
						  
					  }else if(item.group_data.units && item.group_data.units[0]){
						  item.group_data.units.slice(0,4).map(unit => {
							  unitKeys.push(unit)
						  })
						  
					  }
				  }
			  });
			  this.setState( { numFound: res.numFound, groups: res.docs } );
			  if(buildingKeys.length>0){
				  this._getBuildings(buildingKeys)
			  }else{
				  this.setState({buildingRes:false});
			  }
			  if(unitKeys.length>0){
					this._getUnits(unitKeys)
			  }else{
					this.setState({unitRes:false});
				}
			  
		   }else{
			  this.setState( { groups: [], buildingRes:false, unitRes:false } ); 
		   }
	  })
	  .catch((error) => {
		console.log("_getCommunityGroups error ", error);
	  });

	}
	_getBuildings = (keys) => {
		  
		  var q = "key:("+keys.join(" ")+")";
		  
		  var fq = [
			  "deleted:false"		
		  ];
  
  
		  let axiosData = {
			  url: apiURL + "/we3-buildings?cache=60",
			  method: "post",
			  query: {
				  q: q,
				  fq: fq,
				  fl: ["key", "pictures", "image", "display_full_street_address", "full_street_address", "city", "neighborhood", "borough", "zip"],
				  wt: "json",
				  rows: 50
			  }
		  };
		  
  
		  let formattedData = _axiosCall(axiosData)
			  .then(res => {
				  //this._setBuyersList(res);
				  if(res && res.docs){
					  
					  this.setState({ buildingRes : res.docs, fetchInProgress : false, buildingsFetchInProgress: false });
				  }else{
					  this.setState({ buildingRes : [], fetchInProgress : false, buildingsFetchInProgress: false });
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
			  
  
		  // console.log(formattedData)
		  return formattedData;
	};

   _getUnits = (keys) => {
		
		
		 let q = "key:("+keys.join(" ")+")";
		 
		 let fq = [
			 "deleted:false"			
		 ];
   
   
		 let axiosData = {
			 url: apiURL + "/we3-properties?cache=60",
			 method: "post",
			 query: {
				 q: q,
				 fq: fq,
				 fl: ["key", "pictures", "floorplans", "offering_floorplans", "display_full_street_address", "full_street_address", "city", "neighborhood", "borough", "zip", "last_listed_sale_key_all"],
				 wt: "json",
				 rows: 50
			 }
		 };
		 
   
		 let formattedData = _axiosCall(axiosData)
			 .then(res => {
				 //this._setBuyersList(res);
				 if(res && res.docs){
					 let lKeys = res.docs.filter(unit => unit.last_listed_sale_key_all && unit.last_listed_sale_key_all != "").map(unit => unit.last_listed_sale_key_all);
					 if(!lKeys || lKeys.length<=0){
						this.setState({ unitRes : res.docs, fetchInProgress : false, fetchInProgress: false });
					 }else{
						this._getListingData(res.docs, lKeys);
					 }
					 
					 
				 }else{
					 this.setState({ unitRes : [], fetchInProgress : false, fetchInProgress: false });
				 }
				 
			 })
			 .catch(error => {
				 console.log("error: " + error);
			 });
			 
   
		 // console.log(formattedData)
		 return formattedData;
   };
   
   _getListingData = (unitRes, keys) => {
			console.log(keys)
		  if(!keys || keys.length<=0) {
			  return false;
		  }
		  
		  let q = "key:("+keys.join(" ")+")";
   
		  let axiosData = {
			  url: apiURL + "search-listings?cache=15",
			  method: "post",
			  query: {
				  q: q,
				  fq: ["deleted:false","sale_status:active"],
				  sort: "solr_updated_date desc",
				  fl: ["key", "pictures", "main_photo",  "display_full_street_address", "full_street_address", "city", "neighborhood", "borough", "zip"],
				  wt: "json",
				  rows: 10
			  }
		  };
   
		  let formattedData = _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  if(res.docs && res.docs.length>0){
					  unitRes.map(unit => {
						  unit['listing'] = res.docs.filter(l => l.key == unit['last_listed_sale_key_all']).length > 0 ? res.docs.filter(l => l.key == unit['last_listed_sale_key_all'])[0] : false;
					  })					
					  this.setState({unitRes: unitRes});
				  }else{
					  this.setState({unitRes: unitRes});
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
		  return formattedData;
	};
	handleRequestSort = (event, orderBy) => {
		let order = 'desc';
		const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
		if (stateOrderBy === orderBy && stateOrder === 'desc') { order = 'asc'; }
	
		this.setState({ order, orderBy, page : 0 });
	};
	handleChangePage = (event, page) => {
		this.setState({ page: page, start: page * this.state.rowsPerPage });
	  };
	
	  handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
	  };
	
	handleSearch = (searchText) => {
		this.setState({ searchText : searchText});
	}
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
	
	}
	
	_resetActive = () => {
		
		let isDefault = _objectEqual(defaultSubFilter, this.state.subFilters);
		return !isDefault;
		
	}
	
	_setTags = (tag) => {
		const { tags } = this.state;
		
		let newTags = tags.slice();
		const index = newTags.indexOf(tag);	
		if(index >=0 ){
			newTags.splice(index, 1);
		}else{
			newTags.push(tag);
		}	
		this.setState({tags:newTags});
		
	}
	
	_resetTags = () =>{
		this.setState({tags:[]});
	}
	
	_seeAll = () => {
		const { setTab } = this.props;
		
		if(setTab){
			//_scrollTo(400, 0);
			setTab('community-collections');
		}else{
			window.location = "/collections/community-collections";
		}
		
		
	}
	
	
	render(){
		const { snackShow, snackMessage, snackType, groups, subFilters, searchText, unitRes, buildingRes, rowsPerPage, page, numFound, tags } = this.state;
		const { user, toggleRefresh, full, setTab } = this.props;
		const paginationCount = numFound;
		
		if(groups === null) return null;
		
		
		if(full){
			
			return (
			  <Panel 
				  md={12}
				  lg={12}
				  xl={12} 
				  title={'Shared Collections'}
				  subhead={'Ready-made collections for you to copy or share'}
				  label={"admin"}
			  >
			  <div className="news__container collections__container dashboard">
				<div className="mb-3">
			  <UnitsSearchAppBar 				  
					onRequestSort={this.handleRequestSort} 				 
					tags={tags}
					setTags={this._setTags.bind(this)}      
					{...this.state}  						
					numToShow={7}	
					handleSearch={this.handleSearch}	
					resetTags={this._resetTags.bind(this)}		  
				 />
				 </div>
			  <div className="collection-items">
			  {groups && groups.length>0 ? 
				  <Fragment>
				  	{groups.map(item => {
					  	try{			
						  	const data = JSON.parse(item.data);
						  	item.data = data;	
					  	}catch(e){
						  	//ToDo
					  	}
					  	
					  	return (<div className="collection-item">
							  	<GroupItem item={item} unitRes={unitRes} buildingRes={buildingRes} toggleRefresh={toggleRefresh} snackOpen={this.snackOpen} snackClose={this.snackClose} full />
							   </div>)
												 	
				  	})}
			  		</Fragment>
			  	  : <Container>
						<Row className="no__data">
							<Col lg={12} xs={12} className="no__data-text">			                          
								  No collections found for your search.
							</Col>	
						</Row>
					</Container>
			  }
			  
			 
			  </div>
			  {paginationCount ? <TablePagination
				  component="div"
				  className="material-table__pagination"
				  count={paginationCount}
				  rowsPerPage={rowsPerPage}
				  page={page}
				  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
				  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
				  onChangePage={this.handleChangePage}
				  onChangeRowsPerPage={this.handleChangeRowsPerPage}
				  rowsPerPageOptions={[10, 20, 40, 100]}
				  dir="ltr"
				  SelectProps={{
					inputProps: { 'aria-label': 'rows per page' },
					native: true,
				  }}
				/> : null}
			  </div>
			  </Panel>
			  
			 )
			
			
		}
		
		const settings = {
			  dots: false,
			  infinite: false,
			  initialSlide:0,
			  speed: 500,
			  autoplay: false,
			  swipeToSlide: true,
			  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
			  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
			  responsive: [
				{ breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1  } },
				{ breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1  } },
				{ breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 2  } },
				{ breakpoint: 1536, settings: { slidesToShow: 2, slidesToScroll: 2  } },
				{ breakpoint: 100000, settings: { slidesToShow: 3, slidesToScroll: 3  } },
			  ],
		  };
		
		  return (
			  <Fragment>
				  <SnackBar 
					  snackShow={snackShow} 
					  snackMessage={snackMessage} 
					  snackClose={this.snackClose} 
					  snackDuration={5000} 
					  snackType={snackType} 
				  />
				 <Panel 
				  md={12}
				  lg={12}
				  xl={12} 
				  title={'Latest Community Collections'}
				  subhead={'Ready-made collections for you to share'}
				  label={"admin"}
			  >
					<div className="news__container collections__container dashboard">
					{groups ? 
					<Slider {...settings} className="timeline">
						{groups.map(item => {
							try{			
								const data = JSON.parse(item.data);
								item.data = data;	
							}catch(e){
								//ToDo
							}
							
							return <GroupItem item={item} unitRes={unitRes} buildingRes={buildingRes} toggleRefresh={toggleRefresh} snackOpen={this.snackOpen} snackClose={this.snackClose} />
													   
						})}
					</Slider>
					: null
					}
					</div>
				 <div className="unit__load text-right semi-bold-text">			   		
					<Tooltip title="See All Community Collections">
						<a href="javascript:void(0)" onClick={() => this._seeAll()}> See All Community Collections &raquo;</a>			 
					  </Tooltip>				
					  </div>
				  </Panel>
			  </Fragment>
			)
	}
  
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(CommunityGroups));
