import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MatTable from './components/MatTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

const Buildings = ( {user} ) => {
	
	  return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Rental Buildings</h3>
		        <h3 className="page-subhead subhead">New Development Rental Buildings Built in the Last 10 Years</h3>
		      </Col>
		    </Row>
		     {_hasPermission(user, 'rental-building-search') ?
			    <Fragment>
				     <Row>
				    	<Col md={12} className={'mb-3'}>
							<Alert color="success" className="alert--bordered"  alertId="buildingDatabase" icon iconItem={<AlertIcon />} minimizeText={`About The Building Database`}>
					          <p>
					          	<span className="bold-text">{`About The Building Database`}</span> 
					            <div>{`The Building Database is provided 'As-Is'. The information provided herein is provisional and subject to change.`}</div>
					          </p>
					        </Alert>
						</Col>
				    </Row>
				    <Row>
					      <MatTable />
					</Row>
				</Fragment>
			:
				<Row>
			      <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-rental-mockup.jpg`}
			      		copy={<span>Access to Rental Buildings requires an upgrade to your subscription.</span>}
			    		upgrade={true}
						user={user}
						nextPlan={"complete"}
			      />
			    </Row>			
			}
		  </Container>
		  )	
};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(Buildings));

