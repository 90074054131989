import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row,  Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setUserProfile } from '../../redux/actions/authActions';
import ProfileForm from './components/ProfileForm';
import PasswordForm from './components/PasswordForm';

import Cache from './components/Cache';

import showResults from './Show';

import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';

import SnackBar from '../../shared/components/SimpleSnackBar';

import TrialAlert from '../../shared/components/payment/TrialAlert';
import QuickStartGuide from '../../shared/components/QuickStartGuide';

import{
	_capitalizeText,
} from '../../shared/helpers/utils';
import{
	_axiosCall
} from '../../shared/helpers/apicalls';

import{
	_getSSOOrg
} from '../../shared/components/auth/sso/ssoutils'
const oktaToken = process.env.OKTA_TOKEN;
const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';
class Profile extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      snackShow: false,
	      snackMessage: false,
	      snackType: 'success'
	    };
	    this.updateUser = this.updateUser.bind(this);
	    this._updateUserPassword = this._updateUserPassword.bind(this);
	    	
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);
	}
	  
    componentDidMount(){
	  const { authState, authService, dispatch, user } = this.props;
	  console.log(authState);
    }
    
    updateUser(values) {
	    console.log(values);
	    this._updateUserProfile(values);
	  
	}
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_updateUserProfile= (values) => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  
	  let profile = Object.assign({}, values);
	  const user_id = profile.id;
	  if(profile.id) delete profile.id
	  if(profile.login) delete profile.login
	  if(profile.role) delete profile.role
	  if(Array.isArray(profile.avatar) && profile.avatar[0]){
		  profile.avatar = profile.avatar[0].preview;
	  }else if (Array.isArray(profile.avatar) && profile.avatar.length <=0){
		  profile.avatar = "";
	  }
	  if(Array.isArray(profile.logo) && profile.logo[0]){
			profile.logo = profile.logo[0].preview;
	  }else if (Array.isArray(profile.logo) && profile.logo.length <=0){
		  profile.logo = "";
	  }
	  profile['organization'] = '';

	  
	  let userData = {
			url: apiUrl+'user/update',
			method: "post",
			query: {
				id: user_id,
				profile: profile
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.profile){
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
		      this.snackOpen('Your Profile Has Been Saved.');
		  }else{
			  if(res.message){
				this.snackOpen(<Fragment><span>Oops, Your Profile Was Not Updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, Something Went Wrong, Your Profile Was Not Updated.', 'error');
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, Something Went Wrong, Your Profile Was Not Updated.', 'error');
	  });
	}
	
	
	_updateUserPassword = (values) => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
   console.log(values, user);  
      const { currentPassword, newPassword } = values

	  let userData = {
			url: apiUrl+'user/passwordchange',
			method: "post",
			query: {
				id: user.id,
				old_password: currentPassword,
				new_password: newPassword
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.success){		      
		      this.snackOpen('Your Password Has Been Changed.', 'success');
		  }else{			  
			 if(res.message){
				this.snackOpen(<Fragment><span>Oops, Your Password Was Not Changed. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, Something Went Wrong, Your Password Was Not Changed.', 'error'); 
			 }
			  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, Something Went Wrong, Your Password Was Not Changed.', 'error');
	  });

	}
	
	

	
	
	render(){
		const { snackShow, snackMessage, snackType } = this.state;
		const { user } = this.props;
		if(!user) return null;
		const ssoOrg = _getSSOOrg();
		return (
			<Container>
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				
			    <Row>
			      <Col md={12}>
			        <h3 className="page-title">{`Profile & Password`}</h3>
			        <h3 className="page-subhead subhead">Update your profile and password</h3>
			      </Col>
			    </Row>
				<QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_4122483d28"} type={"Profile & Password"} />

			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    {user && user.firstName &&
					<Fragment>
					  {user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
							<Cache cacheId={`admin-cache-off`}/>
					  }
				      <ProfileForm {...this.props} onSubmit={this.updateUser} />
					 {(!(user && user.authType && user.authType == "sso") || ssoOrg == "MPSSO") &&
				      <PasswordForm {...this.props} onSubmit={this._updateUserPassword} />
					 }
				    </Fragment>
			    }
			   
			</Container>
		);	
	}
  
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(Profile));
