import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import ActiveIcon from 'mdi-react/CheckCircleOutlineIcon';
import VetIcon from 'mdi-react/AlertDecagramOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';



import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class ListingAgentInfo extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
 
  render(){
	  const { modal } = this.state;
	  const { agent, contract, admin, noIcon } = this.props;
	  console.log(agent);
	 if(!agent) return null;
	  
	  const modalClass = classNames({
	      'modal-dialog--colored': false,
	      'modal-dialog--header': true,
	   });
	   //<a href={`https://www.nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/${agent.verified_loan_officer_nmls_id[0]}`} className="link-text" target="_blank">
	   if(agent && agent.name && agent.name.length>0 ){
			 return (
			   <Fragment>
					 <span className="link-text" onClick={this.toggle}><EmailOutlineIcon size={14} style={{marginTop:-3,marginRight:2}} />{agent.name}</span>
					 <Fragment>
					   <Modal
						 isOpen={modal}
						 toggle={this.toggle}
						 modalClassName={`ltr-support`}
						 className={`modal-dialog--primary ${modalClass}`}
					   >
					   <div className="modal__header">
						   <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
						   <h4 className="text-modal  modal__title"><EmailOutlineIcon size={24} color={'#EDDEF5'} /> Listing Agent Information</h4>
						 </div>
						 <div className="modal__body">
							
							 <p style={{fontSize:16}}><strong>Brokerage Firm:</strong> {_capitalizeText(agent.company)}</p>
							
							 {agent.name &&
							 	<p style={{fontSize:16}}><strong>Listing Agent:</strong> {_capitalizeText(agent.name)}</p>
							 }
							
							 {agent.phone &&
									<p style={{fontSize:16}}><strong>Phone:</strong> <a className="link-text" target="_blank" href={`tel:${agent.phone}`}>{agent.phone} <OpenInNewIcon size={14} style={{marginTop:-3}} /></a></p>
							  }
							{agent.email &&
									<p style={{fontSize:16}}><strong>Email:</strong> <a className="link-text" target="_blank" href={`mailto:${agent.email}`}>{agent.email} <OpenInNewIcon size={14} style={{marginTop:-3}} /></a></p>
							  }	   
						 </div>
						 
						 <ButtonToolbar className="modal__footer offering-status">
						   {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
						   <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
							   Ok
						   </Button>
						 </ButtonToolbar>
					   </Modal>
				   </Fragment>
			   </Fragment>
			 )
		}else if(admin){
		 return (
			 <Fragment>
		 		<span className="offering-status">
			   	<Tooltip title="Verify Buyer's Attorney Information">
				   	<IconButton size={`small`} onClick={(e) => {e.preventDefault();e.stopPropagation();this.openAcris(agent)}} style={{ marginTop:"-1px"}} >
					   	<VetIcon size={16} color={'#780F9E'} />
				   	</IconButton>
			   	</Tooltip>
		   		</span>		
		 	</Fragment>
	 	)
	 }else{
		 return null;
	 }
 }
	
};


export default ListingAgentInfo;
