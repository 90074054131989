import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import AttorneyVettedStatus from '../../../../../shared/components/AttorneyVettedStatus';
import LoanVettedStatus from '../../../../../shared/components/LoanVettedStatus';
import AgentStatus from '../../../../../shared/components/AgentStatus';
import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
	buildingFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl,
	ProfileAvatar
} from "../../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
const buildingUrl = process.env.API_ROOT + '/query/';
const styles = theme => ({
	  root: {
		display: 'flex',
		flexDirection: 'column',
		//marginLeft:"-8px",
		marginBottom:"15px",
		alignItems: 'left',
		'& > *': {
		   
		},
	  },
	  button:{
		'&.selected' :{
			background:"#D1D6E0"
		},
		fontSize:11,
		width:90
	  }
	});
	
const ShowList = ({data, title, header, color, type}) => {
	
	
	return (
		<Card className={`pb-0`}>
		<CardBody style={{padding:15}} className={`${isMobile && "mb-3"}`}>
		 <div className="card__title">
		  <h5 className="bold-text">{title}</h5>
		 </div>
		<Table responsive className="table-striped">
			{header &&
			<tr>
				<th colSpan={2} className="no-padding">{header}</th>
			</tr>
			}
		  <tbody>
		  {data && Array.isArray(data) && data.length>0 ? 
			  <Fragment>
			  {data.slice(0, 3).map((item, i) => {
			  const display = item.val.display_name;
			  return (
			 
					  <tr key={i} style={{border:0,cursor:"pointer"}}>
						  <td>
							  <Badge style={{ width:70,background:color }}>
								  <div className="bold-text">{_formatNumber(item.count)}</div> 
							  </Badge>
						</td>      				       	
						<td>
							<div className="bold-text">{display}</div>
							{item.val.company &&
								<div className="small-text light-text">{item.val.company}</div>
							}
							{type == 'attorney' && <AttorneyVettedStatus transaction={item.val} admin={false} noIcon />}
							{type == 'originator' && <LoanVettedStatus transaction={item.val} admin={false} noIcon />}
							{type == 'agent' && <AgentStatus transaction={item.val} admin={false} noIcon />}
						</td>   
						{/*<td>
							<ProfileAvatar 
							 contact={item.val}                          
							 companyCard={true}
							/>
						</td>*/}   	
					  </tr>
				  
				)
			  
		  	})}
			  </Fragment>
			:
			<tr>
				<td colSpan={2} className="text-center">
					<div className="bold-text">No {title} Found</div>
				</td>
			</tr>
			}
		   
		  </tbody>
		</Table>
		
		</CardBody>
		</Card>

	)
}
function parseDate(dateString) {
  const [date, time] = dateString.split('T');
  const [year, month, day] = date.split('-').map(part => parseInt(part, 10));
  const [hours, minutes, seconds] = time.slice(0, 8).split(':').map(part => parseInt(part, 10));
  return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
}
class BuildingPeopleCompanies extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		  fetchInProgress: false,
		  data: null,
		  attorneyData: null,
		  originatorData: null,
		  period: "1"
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.period !== prevState.period){
			this._getBuildingPeopleCompany();
			if(this.props.transactionDocs){
				this._appendAttorneyData();
				this._appendOrginatorData();
			}
		}
		if(this.props.transactionDocs && this.props.transactionDocs != prevProps.transactionDocs){
			this._appendAttorneyData();
			this._appendOrginatorData();
		}
		
	}

	componentDidMount() {
		this._getBuildingPeopleCompany();
		
		if(this.props.transactionDocs){
			this._appendAttorneyData();
			this._appendOrginatorData();
		}
		
		  
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
	}
	
	_appendAttorneyData = () => {
		const { transactionDocs } = this.props;
		const { data, period } = this.state;
		
				
		// Function to filter data based on close_date_formatted and a given number of years
		function filterAttorneyDataByYears(data, yearsBack) {
		  const currentDate = new Date();
		  const pastDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - parseInt(yearsBack)));
		
		  return data.filter(item => {
			if (item.close_date_formatted) {
			  const closeDate = parseDate(item.close_date_formatted);
			  return closeDate >= pastDate && item.verified_buyers_attorney_first_name && item.verified_buyers_attorney_first_name !="";
			}
			return false;
		  });
		}
		
		let attorneys = transactionDocs ? filterAttorneyDataByYears(transactionDocs, period) : [];
		const countAttorneys = (data) => {
		  const countMap = new Map();
		
		  data.forEach(item => {
			if (!item.verified_buyers_attorney_unavailable) {
			  const key = item.verified_buyers_attorney_first_name + '_' + item.verified_buyers_attorney_last_name;
			  if (countMap.has(key)) {
				countMap.get(key).count++;
			  } else {
				countMap.set(key, {
				  count: 1,
				  val: Object.assign({}, item, {
					display_name: _capitalizeText(item.verified_buyers_attorney_first_name + " " + item.verified_buyers_attorney_last_name),
					company : _capitalizeText(item.verified_buyers_attorney_firm_name)
				  })
				});
			  }
			}
		  });
		
		  return Array.from(countMap.values()).sort((a, b) => b.count - a.count);
		}
		let attorneyData = {}
		if(attorneys.length>0){
			attorneyData = countAttorneys(attorneys);
		}
		this.setState({attorneyData: attorneyData});
	}
	_appendOrginatorData = () => {
		const { transactionDocs } = this.props;
		const { data, period } = this.state;
		
		
		
		
		// Function to filter data based on close_date_formatted and a given number of years
		function filterOriginatorDataByYears(data, yearsBack) {
		  const currentDate = new Date();
		  const pastDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - parseInt(yearsBack)));
		
		  return data.filter(item => {
			if (item.close_date_formatted) {
			  const closeDate = parseDate(item.close_date_formatted);
			  return closeDate >= pastDate && item.verified_loan_officer_first_name && item.verified_loan_officer_first_name[0];
			}
			return false;
		  });
		}
		
		let originators = transactionDocs ? filterOriginatorDataByYears(transactionDocs, period) : [];
		const countOriginators = (data) => {
		  const countMap = new Map();
		
		  data.forEach(item => {
			if (item.verified_loan_officer_first_name[0]) {
			  const key = item.verified_loan_officer_first_name[0] + '_' + item.verified_loan_officer_last_name[0];
			  if (countMap.has(key)) {
				countMap.get(key).count++;
			  } else {
				countMap.set(key, {
				  count: 1,
				  val: Object.assign({}, item, {
					  display_name: _capitalizeText(item.verified_loan_officer_first_name[0] + " " + item.verified_loan_officer_last_name[0]),
					  company : _capitalizeText(item.verified_lender_name[0])
					})
				});
			  }
			}
		  });
		
		  return Array.from(countMap.values()).sort((a, b) => b.count - a.count);
		}
		let originatorData = {}
		if(originators.length>0){
			originatorData = countOriginators(originators);
		}
		this.setState({originatorData: originatorData});
	}

			
			
	_getBuildingPeopleCompany = async () => {
			const { buildingRes, transactionDocs } = this.props;
			const { period, data} = this.state;
			this.setState({fetchInProgress:true})
			
			let axiosData = {
				url: process.env.API_ROOT + "/search",
				method: "post",
				query: {
					"query": {
						"borough": [],
						"building_slugs": [buildingRes.building_slugs[0]],
						"building_units": [],
						"company_slugs": [],
						"neighborhood": [],
						"person_slugs": []
					},
					"bedrooms": [],
					"company_slugs": [],
					"event_tags": [],
					"person_slugs": [],
					"price": [],
					"rows": 0,
					"sort": "time desc",
					"start": 0,
					"topic": ["listings", "permits", "offeringplans", "transactions"],
					"units": [],
					"json_stats": {
						
						"company_brokers": {
							"type": "terms",
							"field": "company_slugs",
							"limit": 20,
							"domain": {
								"filter": `time:[NOW-${period}YEAR TO NOW] AND topic:listings AND event_tags:just-listed AND -event_tags:rent`
							}
						},
						"company_lenders": {
							"type": "terms",
							"field": "company_slugs",
							"limit": 20,
							"domain": {
								"filter": `time:[NOW-${period}YEAR TO NOW] AND topic:transactions`
							}
						},
						"people_agents": {
							"type": "terms",
							"field": "person_slugs",
							"limit": 20,
							"domain": {
								"filter": `time:[NOW-${period}YEAR TO NOW] AND topic:listings  AND -event_tags:rent AND event_tags:just-listed AND -person_slugs:(*group* OR *office* OR *gallery* OR *center* OR *sale*)`
							}
						},
						"people_loan_originator": {
							"type": "terms",
							"field": "person_slugs",
							"limit": 20,
							"domain": {
								"filter": `time:[NOW-${period}YEAR TO NOW] AND topic:transactions`
							}
						}
					}
				}
			}
			
			
			
			
			
			let formattedData = await _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					if(res && res.stats){
						this.setState({data: res.stats, fetchInProgress:false});
					}else{
						this.setState({data: false,  fetchInProgress:false});
					}
						
				})
				.catch(error => {
					console.log("error: " + error);
				});
		
	}
		

  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  setPeriod = (period) => {
	  this.setState({period:period})
  }
  
  render(){
	const { savedUnits, totalCount, period, data, fetchInProgress, attorneyData, originatorData } = this.state;
	const { classes, type } = this.props;
	if(!data || (data.company_brokers && !data.company_brokers.brokerage && period == "99")) return null;
	
	return (
		<Card>
		   <CardBody>
			   <Row>
					   <Col md={12} className={"mb-0"}>
						   <div className="card__title">
						  <h5 className="bold-text">{'Top Professionals In This Building'}</h5>
						  {/*<h5 className="subhead">Top Listings, Contracts and Closing for The {type == "resale" ? "Resale Condo Market" : 'New Development Market'}</h5>*/}
						</div>
						   <div className={classes.root}>
								 
						  <ButtonGroup size="small" aria-label="small button group" >
						  	  {/*<Tooltip title="Top consumer lenders and brokerges" aria-label="Top consumer lenders and brokerges">
									   <Button onClick={()=> this.setPeriod("99")} className={period === "99" ? classNames(classes.button, "selected") : classNames(classes.button)}>All-Time</Button>
								  </Tooltip>*/}								        
							  <Tooltip title="Top consumer lenders and brokerges over the last 1 year" aria-label="Top consumer lenders and brokerges over the last 1 year">
								<Button onClick={()=> this.setPeriod("1")} className={period === "1" ? classNames(classes.button, "selected") : classNames(classes.button)}>1-Year</Button>
							</Tooltip>
							<Tooltip title="Top consumer lenders and brokerges over the last 2 year" aria-label="Top consumer lenders and brokerges over the last 2 year">
								<Button onClick={()=> this.setPeriod("2")} className={period === "2" ? classNames(classes.button, "selected") : classNames(classes.button)}>2-Year</Button>
							</Tooltip>
							<Tooltip title="Top consumer lenders and brokerges over the last 5 year" aria-label="Top consumer lenders and brokerges over the last 5 year">
								<Button onClick={()=> this.setPeriod("5")} className={period === "5" ? classNames(classes.button, "selected") : classNames(classes.button)}>5-Year</Button>
							</Tooltip>
							{/*<Tooltip title="Top consumer lenders and brokerges over the last 10 year" aria-label="Top consumer lenders and brokerges over the last 10 year">
									<Button onClick={()=> this.setPeriod("10")} className={period === "10" ? classNames(classes.button, "selected") : classNames(classes.button)}>10-Year</Button>
							   </Tooltip>*/}
						  
						  </ButtonGroup>
					  </div>
					   </Col>
			   </Row>
			   
			   {(!data || fetchInProgress) ?
				   <div>
				   <LinearLoading />
				   <div style={{height:300}}>
					   
				   </div>
				   </div>
				   
				   :
				 
				   <Row>
					  {originatorData ?
					   <Col md={4} className={"mb-0"}>
								<ShowList type="originator" data={originatorData ? originatorData : false} header={"Loans in This Building"} title={"Loan Originators"} color={"#B070CC"} />
						  </Col>
						:
						<Col md={4} className={"mb-0"}>
							  <ShowList data={data && data.company_lenders && data.company_lenders.lender ? data.company_lenders.lender : false} header={"Loans in This Building"} title={"Consumer Lenders"} color={"#B070CC"} />
						  </Col>
						}
						<Col md={4} className={"mb-0"}>
								 <ShowList type="attorney" data={attorneyData ? attorneyData : false} header={"Buyers Represented in This Building"} title={"Buyer's Attorney"} color={"#B070CC"} />
						  </Col>	
					   <Col md={4} className={"mb-0"}>
							  <ShowList type="agent" data={data && data.people_agents && data.people_agents['real-estate-agent'] ? data.people_agents['real-estate-agent'] : false} header={"Listings in This Building"} title={"Listing Agents"} color={"#B070CC"} />
					   </Col>	
					  
					   {/*
						   <Col md={6} className={"mb-0"}>
								  <ShowList data={data && data.company_lenders && data.company_lenders.lender ? data.company_lenders.lender : false} header={"# of Loans"} title={"Consumer Lenders"} color={"#B070CC"} />
							  </Col>
						   <Col md={6} className={"mb-0"}>
						   <ShowList data={data && data.company_brokers && data.company_brokers.brokerage ? data.company_brokers.brokerage : false} header={"# of Listings"} title={"Brokerages"} color={"#B070CC"} />
					   </Col>*/}
					  				   	  
						
				   </Row>
			   }
			 
			 </CardBody>
		</Card>
	
	)
	
   }
};

BuildingPeopleCompanies.propTypes = {
  t: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
	? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
	: state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, 
			  savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],  
			  dataSet : state.buyersLists.dataSet };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,  { withTheme: true })(BuildingPeopleCompanies));
