import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';

import{
	_capitalizeText,
} from '../helpers/utils';

class BrandedTopBar extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
	this.unit = false;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
 
  render(){
	  const { user, urlState } = this.props;
	  const { collapse } = this.state;
	  return (
	    <div className="topbar">
	        <div className="topbar__wrapper container">
	          <div className="topbar__left">
	            
	            <a className="topbar__logo" target="_blank"/>
	          </div>
	          <div className="topbar__right">
	         
	          </div>
	        </div>
	    </div>
	
	  );
	}
};
export default BrandedTopBar;