import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import CardSection from './CardSection';
import '../Payment.scss'

const apiUrl = process.env.API_ROOT + '/buyerslist/';
//const apiUrl = 'http://localhost:8000/buyerslist/';

export default function CouponForm(props) {
  const stripe = useStripe();
  const elements = useElements(); 
  const { user, selectedPlan, updateUserProfile, customer, snackOpen, toggleRefresh, setCoupon, currentCoupon, currentPlan, trial, updateSubscriptionDetails, showButton } = props;
  const [ fetchInProgress, setFetchInProgress ] = useState(false);
  const [ subscription, setSubscription ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ couponCode, setCouponCode ] = useState('');
  
  
  

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    event.stopPropagation();
    if(fetchInProgress) return null;
    
    if(!couponCode){
	    setCoupon(false);
	    setModal(false);
		return null;
    }
    
    

      fetch(`${apiUrl}stripe/check-coupon`, {
	      method: 'post',
	      headers: {'Content-Type': 'application/json'},
	      body: JSON.stringify({
	        coupon: couponCode
	      }),
	    }).then(function(coupon) {		  
		  coupon.json().then(couponResult => {
			  
			  if(couponResult && couponResult.valid){
				  
				  if(couponResult.duration_in_months && couponResult.duration_in_months > 0 && couponResult.duration_in_months < currentPlan.billingFrequencyMonths){
					  setFetchInProgress(false);
					  setError('This coupon can only be applied to a monthly plan');
				  }else if(couponResult.duration_in_months && couponResult.duration_in_months > 0 && trial){
					  setFetchInProgress(false);
					  setError('This coupon cannot be applied to a free trial');					  
				  }else if(couponResult.amount_off && selectedPlan && (couponResult.amount_off/100) > selectedPlan.pricePerMonth){
						setFetchInProgress(false);
						setError('This coupon cannot be applied to this plan');					  
				  }else{
					  setFetchInProgress(false);
					  setError(false);
					  setCoupon(couponResult);
					  setModal(false);
					  if(updateSubscriptionDetails){
						  updateSubscriptionDetails(couponResult);
					  }
					  
				  }
				  
				 
			  }else{
				  setFetchInProgress(false);
				  setError('The coupon code is invalid');
			  }
			  
			 
		  })	      
	      
	    })
    
  };
  
  const toggle = () => {
	  setModal(!modal);
  }
  
  const handleCouponChange = (event) => {
	  setCouponCode(event.target.value);
  }
  
  const modalClass = classNames({
      'modal-dialog--colored': false,
      'modal-dialog--header': true,
   });
 
  return (
	<div>
		{showButton ?
		<Button
			 variant="contained"
			 color="primary"
			 className="buyersList__primary-button"	
			   
			  onClick={toggle}      
		 >	
			{currentCoupon ? "Update Coupon" : "Add Coupon"}
		 </Button>
		 :
	     <a href="javascript:void()" className="coupon-link" onClick={toggle}>{currentCoupon ? "Update Coupon" : "Add Coupon"}</a>
	 	}
	    <Modal
	      isOpen={modal}
	      toggle={toggle}
	      modalClassName={`ltr-support`}
	      className={`modal-dialog--primary ${modalClass}`}
	    >
	      
		      <div className="modal__header">
		        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
		        <h4 className="text-modal  modal__title">Add Coupon</h4>
		      </div>
		      <form onSubmit={handleSubmit}>
		      <div className="modal__body" style={{minHeight:250}}>
		      	<div className="cc-title">Enter Your Coupon Code</div>		        
		      	<div className="CardNumberField-input-wrapper">
			    	<input className="coupon-code StripeElement" 
			    	value={couponCode} 
			    	onChange={handleCouponChange} 
			    	name="couponcode" 
			    	ariaLabel="Enter your coupon code" 
			    	placeholder="Enter your coupon code"/>
			    </div>	
			    {error && <div className="payment-error">{error}</div>}	      
		      </div>
		      <hr/>
		      <ButtonToolbar className="modal__footer">
		        <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
		        <Button className="modal_ok" type="submit" color={'primary'} >
		        	{fetchInProgress ? <span>Processing...	</span> : <span>Apply Coupon</span>}
		        </Button>
		      </ButtonToolbar>
	      </form>
	    </Modal>
	</div>
    
  );
}