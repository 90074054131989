import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';

import { ExportToCsv } from 'export-to-csv';


import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

import { Tooltip } from '@material-ui/core';
import moment from "moment";
import DownloadIcon from 'mdi-react/FileDownloadOutlineIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	SalesProgress
} from "../../../shared/helpers/utils";
import { _hasPermission	} from "../../../shared/helpers/access";
import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';

class DataExport extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 500,
	    fetchInProgress: false,
		buildingKey:false,
		exportData:false,
		start:0,
		noMoreResults:false,
		doExport:false
	};

	componentDidMount() {
		
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(this.state.doExport !== prevState.doExport && this.state.doExport===true){			   
			 this.queryData();
		  	 
		}
		 
    }
    
	
	queryData = async() => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const {building, q, fq, fl } = this.props;	
		
		let sort = "time desc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			sort = `${this.state.orderBy} ${this.state.order}`;
			
			
		}
			
		
		const unitsFq = ["hierarchy:[0 TO 1]"];			
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=15",
			method: "post",
			query: {
				q: q,
				fq: [...fq, ...unitsFq],
				fl: "display_full_street_address, building_name, building_complex, address_2_normalized, markets_major, markets_sub, neighborhood, borough, city, zip, time, event_time, price, event_tags, bedrooms, baths, sq_ft, property",
				sort: sort,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};
		
		
		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					
					const propertyIDs=[];
					res.docs.map(row => {
						
						propertyIDs.push(row.property);
						Object.keys(row).map(key => {
							if(Array.isArray(row[key])){
								row[key] = row[key][0] ? row[key][0] : "";
							}else if(row[key] === -1){
								row[key] = "";
							}
							
							if(key==='time' || key==='event_time'){
								row[key] = moment(row[key]).format('MM/DD/YYYY');
							}														
						})
					})
					
					 this.queryProperties(propertyIDs, res.docs);
		
					
					 this.setState( { fetchInProgress: false, doExport: false })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, doExport: false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	queryProperties = async(matchingProperties, data) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
			const unitsFq = ["hierarchy:[0 TO 1]"];			
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=15",
			method: "post",
			query: {
				q: `key:(${matchingProperties.join(" ")})`,
				fl: "key,offering_bedrooms,offering*",
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};
		
		
		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					
					data.map(item =>{
						let property = res.docs.filter(prop => prop.key === item.property);
						if(property.length>0){
							property = property[0];							
							item['offering_price_initial'] = property['offering_price_initial'] && property['offering_price_initial'] > -1 ? property['offering_price_initial'] : "";
							item['offering_exterior_sq_ft'] = property['offering_exterior_sq_ft'] && property['offering_exterior_sq_ft'] > -1 ? property['offering_exterior_sq_ft'] : "";
							item['offering_sq_ft'] = property['offering_sq_ft'] && property['offering_sq_ft'] > -1 ? property['offering_sq_ft'] : "";
							item['offering_bedrooms'] = property['offering_bedrooms'] && property['offering_bedrooms'] > -1 ? property['offering_bedrooms'] : "";
							item['offering_baths'] = property['offering_baths'] && property['offering_baths'] > -1 ? property['offering_baths'] : "";
						}else{
							item['offering_price_initial'] = "";
							item['offering_exterior_sq_ft'] = "";
							item['offering_sq_ft'] = "";
							item['offering_bedrooms'] = "";
							item['offering_baths'] = "";
						}
						
					})
					
					const options = { 
					    filename: `Market Activities - Data Export - ${moment().format('YYYY-MM-DD')}`,	
					    useKeysAsHeaders: true				    
					  };					 

					const csvExporter = new ExportToCsv(options);					 
					csvExporter.generateCsv(data);


					
				}else if(res.docs.length === 0 ){
					return []
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	render(){
		const { exportData } = this.state;
		
		const { user } = this.props;
		
		if(_hasPermission(user, 'data-export')){
			return (
				<Fragment>
					<Tooltip title={`Export Data as CSV (Recent 500 Rows Max)`}>
			        <IconButton
				         onClick={()=>this.setState({doExport:true, exportData:false})}			        
			             className="material-table__toolbar-button"
			             style={{marginTop:-12,marginRight:20}}
				     >
				     	<DownloadIcon />
				     </IconButton>				 
			  	</Tooltip>
			  	
				</Fragment>
			);
		}
		
		return null;
			
		
	}
}

export default connect(state => ({
  user: state.user
}))(DataExport);