import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import MatTableToolbar from './MatTableToolbar';
import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import BuildingSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';
import UnitsSearchAppBar from './UnitsSearchAppBar';

import UnitStageFilter from '../../../shared/components/unit_filters/UnitStageFilter';


import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import queryString from "query-string"

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	salesExportFL,
	getCohortQuery,
	_isCoop
} from "../../../shared/helpers/utils";

import { 
	_salesFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../shared/helpers/filters";

import { _hasPermission	} from "../../../shared/helpers/access";

let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';

let lsPre = "S1_";
const subFilterKey = 'subFilters';
const unitStagesKey = 'unitStages';
const buildingStagesKey = 'buildingStages';
//const defaultStage = ["all"];
const defaultSubFilter = { parcels_count : ["market-sale"] };


const groupedView = false; //syncGenericFilter('group_view', lsPre, 'groupedView', false);

class UnitsSearchTable extends PureComponent {
	
	
  defaultUnitStage = this.props.buildingView ? ['all'] : ['all'];
  defaultStage = _hasPermission(this.props.user, 'sponsor-buildings') && _hasPermission(this.props.user, 'resale-buildings') ? ['all'] : _hasPermission(this.props.user, 'sponsor-buildings') ? ["sponsor"] : ['resale'];	
  state = {
    order: !this.props.buildingView && groupedView === true ? syncGenericFilter('order', lsPre, 'order', "desc") : syncGenericFilter('order', lsPre, 'order', ""),
    orderBy: !this.props.buildingView && groupedView === true ? syncGenericFilter('order_by', lsPre, 'orderBy', "count") : syncGenericFilter('order_by', lsPre, 'orderBy', ""),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: !this.props.buildingView ? syncURLFilters(this.props, _salesFilters, subFilterKey, lsPre, defaultSubFilter) : syncURLFilters(this.props, _salesFilters, false, false, false),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : groupedView, //getLocalStorage(lsPre, 'groupedView') === false ? false : true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	unitStages:  !this.props.buildingView ? syncStageFilters(this.props, _unitStageFilter, unitStagesKey, lsPre, this.defaultUnitStage) : syncStageFilters(this.props, _unitStageFilter, false, false, this.defaultUnitStage),
	buildingStages: !this.props.buildingView ? this.defaultStage : syncStageFilters(this.props, _buildingStageFilter, false, false, this.defaultStage)
	//getLocalStorage(lsPre, buildingStagesKey) ? getLocalStorage(lsPre, buildingStagesKey) : defaultStage
  };
  
  defaultQ = ((this.props.building && this.props.building.offering_verified_status === "active") 
  		|| (!this.props.buildingView)) ? 
  		'' //"(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:*)"
  		: '*:*'; //-address_2_normalized:""';
  
  
  defaultBuidingQ = getCohortQuery(this.props.user, true);
  
  _propertyFilter = _salesFilters.filter(filter => !filter.filter_type);
  _buildingFilter = _salesFilters.filter(filter => filter.filter_type && filter.filter_type === 'building');

  
  		
  
  q='';
  fq = this.props.buildingView ? 
  [		
		`alternate_addresses:("${this.props.building.alternate_addresses.join('" OR "')}") AND zip:${this.props.building.zip}${this.props.unitView && this.props.unit ? " AND address_2_normalized:\"" + this.props.property.address_2_normalized + "\"" : ''}`,
		//`-address_2_normalized:""`,
		`status:sold`,
		`transaction_source:ACRIS*`,
		`-property_type:sr`,
		`-property_type:ps`,
		`close_date_formatted:[${parseInt(moment(this.props.building.offering_submitted_date).format('YYYY')) > 1990 ? this.props.building.offering_submitted_date : "*" } TO NOW]`
  ] :
  [		
		//`offering_verified_status:active`,
		//`-address_2_normalized:""`,
		`status:sold`,
		`transaction_source:ACRIS*`,
		`-property_type:sr`,
		`-property_type:ps`,

		//`-offering_construction:(conversion)`,
  ]
  sort='';
  unitFq=null;
  
  
	
  fl = propertyFL;
  _unitSubFilter = _salesFilters.filter(filter => !(this.props.buildingView && filter.building_hide));
    
  componentDidMount() {
		
  	 this._getInventoryUnits();
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let unitStageChange = !_isEqual(prevState.unitStages, this.state.unitStages);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);

	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged ||
			unitStageChange ||
			buildingStageChange
			) {
			  const resetPage = this.state.page === prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage;	
			  console.log("component update", prevState, this.state)
			  this.defaultBuidingQ = getCohortQuery(this.props.user, true)		
			  this._getInventoryUnits(resetPage);
		}

	  
  }
  
  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, unit) => {
	  
	 const { building } = this.props;
	 
	 const buildingURL = (building ? _getBuildingURL(building) : _getBuildingURL(unit, 'building')) + '/' + _nameToUrl(unit.address_2_normalized);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'key');
	 
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _getInventoryUnits = async (resetPage) => {
		this.q = this.defaultQ;
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, groupedData:false, tableData:false, reactTableFiltered:false }));
		}
		
		
		const { searchTags } = this.props;
		const { orderBy, order, unitStages, buildingStages } = this.state;
		let locQuery = _tranformTagsQuery(searchTags, 'building');
		
		this.unitFq = this.fq.slice();
		
/*
		if (this.state.filterBorough !== "all_boroughs") {
			this.q += `(borough:"${this.state.filterBorough
				.replace("_", " ")
				.toLowerCase()}")`;
		}
*/
		if(locQuery && !this.props.buildingView){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._propertyFilter.filter(filter => filter.search_key === key);
					let qVal = null;
					
					if(theFilter && theFilter[0] && theFilter[0].text_filter){
						
						if(theFilter[0].search_fields){
							qVal = subFilterVal.split(" ").map(term => {
									return "(" + theFilter[0].search_fields.map(field => {
										return `${field}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*)`
									}).join(" OR ") + ")"
								}).join(" AND ");
								
							
							
						}else{
							qVal = subFilterVal.split(" ").map(term => {
								return `(${key}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*))`
							}).join(" AND ");
						}
						
						
						if(qVal!==null){						
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${qVal})`;
							
						}
						
					}else if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							if(theFilter[0].no_key){
								this.q += `(${qVal.join(" OR ")})`;
							}else{
								this.q += `(${key}:(${qVal.join(" ")}))`;
							}
								
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += `(${searchKey}:(${qVal}))`;
					}else{
						qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
								? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
								: null;
						if(qVal!==null){
						
							if(theFilter[0].target && theFilter[0].target === 'fq'){
								this.unitFq.push(qVal);
							}else{							
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += `(${key}:${qVal})`;
								
							}
							
						}
					}
				}
			});		
		}
		
		const isContract = this.state.subFilters.current_status && this.state.subFilters.current_status === 'in-contract';		
		const isAvailable = this.state.subFilters.current_status && ['available', 'active', 'shadow'].indexOf(this.state.subFilters.current_status)>=0;

		
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = {};

		let replaceKeys = {};
		let dateReplaceKeys = {};
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._propertyFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							if(isAvailable && filterKey == "asking_price"){
								priceQ[filterKey] = 'last_listed_sale_price_all:[{min_price} TO {max_price}]'.replace(`{${key}}`, subFilterVal);
							}else{
								priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							}
							
						}											
					}	
					
					const dateArrayFilter = this._propertyFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
				
					if(dateArrayFilter.length>0){	
						const filterKey = dateArrayFilter[0].filter_key;
						
						if(dateReplaceKeys[filterKey]){
							dateReplaceKeys[filterKey]  = [...dateReplaceKeys[filterKey], ...dateArrayFilter[0].search_key];
						}else{
							dateReplaceKeys[filterKey] = [...[], ...dateArrayFilter[0].search_key]
						}	
						
											
						if(dateQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ[filterKey] = dateQ[filterKey].replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ[filterKey] = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}	
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter && qArrayFilter.target && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter && qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		if(dateQ && Object.keys(dateQ).length>0){
			Object.keys(dateQ).map(filterKey => {
				const dateArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				dateReplaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					dateQ[filterKey] = dateQ[filterKey].replace(regex, '*')
					
				
				})
				
				if(dateArrayFilter && dateArrayFilter.target && dateArrayFilter.target =="fq"){
					this.unitFq.push(dateQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += dateQ[filterKey];
					this.q += ')';	
				}
			})
			
					
		}
		/****Price& Date query***/
		

		
		
		if(unitStages && unitStages.length>0){
			if(unitStages.indexOf("all")<0 && !(unitStages.indexOf("sponsor")>=0 && unitStages.indexOf("resale")>=0)){
				this.q += this.q.length > 0 ? " AND " : "";
				this.q += `${unitStages.indexOf("sponsor")>=0 ? "(sponsored_sale:true AND ownership_type:condo)" : "-(sponsored_sale:true AND ownership_type:condo)"}`;
			}
			
		}
		
		
		
		let defaultBuidingQ = this.defaultBuidingQ;
		
		if(!this.props.buildingView){
			
			if(buildingStages && buildingStages.length>0){
				if(buildingStages.indexOf("all")<0){
					defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
					defaultBuidingQ += `building_status:("${buildingStages.join("\" OR \"")}")`;
	
				}
			}		
			if (this.state.subFilters &&
				Object.keys(this.state.subFilters).length > 0
			) {
				Object.keys(this.state.subFilters).map(key => {
					let subFilterVal = this.state.subFilters[key];
					if (subFilterVal && subFilterVal != "") {
						const theFilter = this._buildingFilter.filter(filter => filter.search_key === key);
					
						if(theFilter && theFilter[0] && theFilter[0].multi_filter){
							const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
										? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
										: null;
							if(qVal!==null){					
								defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
								defaultBuidingQ += `(${key}:(${qVal.join(" ")}))`;	
							}		
						}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
							const searchKey = theFilter[0] && theFilter[0].search_key;
							let operator = "AND";
							let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
							searchValue = searchValue.map(val => {
								if(val.indexOf("~")>=0){
									const parts = val.split("~");
									operator = parts[parts.length-1];
									return parts[0];
								}
							})
							
							const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
							defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
							defaultBuidingQ += `(${searchKey}:(${qVal}))`;
						}else{
							const qVal = theFilter && theFilter.length>0 
										? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
										: null;
							if(qVal!==null){
								defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
								defaultBuidingQ += `(${key}:${qVal})`;
							}
						}						
					}
				});
			}
			this.unitFq.push(`{!join from=alternate_keys fromIndex=we3-buildings to=building}${defaultBuidingQ}`);
			
		}

		setURLFromState(this.props, this.state);
	
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += this.props.buildingView ? `(address_2_normalized:apt*${text}* OR (-address_2:apt* AND address_2:*${text}*))` : `(building_name:(*${text}* OR *${textCaps}*)  OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		let sort = this.state.groupedView ? "count desc" : "close_date_formatted desc";
		
		if(orderBy && orderBy.length > 0){
			if(orderBy === 'address_2_normalized'){
				sort = `floor ${order}, ${orderBy} ${order}`;
			}else{
				sort = `${orderBy} ${order}`;
			}
			if(orderBy && orderBy !== 'count'){
				this.sort = sort;
			}
			if(orderBy === 'recorded_datetime'){
				this.unitFq.push(`recorded_datetime:*`);
			}else if(orderBy === 'discount'){
				this.unitFq.push(`discount:[-100 TO 100]`);
			}else if(orderBy === 'percent_financed'){
				this.unitFq.push(`percent_financed:[.01 TO 100]`);
			}else if(orderBy === 'days_to_contract'){
				this.unitFq.push(`days_to_contract:[1 TO 1460]`);
			}
			
		}
		
		this.sort = !this.state.groupedView ? sort : null;

		let axiosData = {
			url: buildingUrl + "we3-transactions?cache=60",
			method: "post",
			query: {
				q: this.q,
				fq: this.unitFq,
				sort: !this.state.groupedView ? sort : null,
				//fl: this.fl,
				rows:this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				wt: "json",
				
/*
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"group.field":"building",
				"group.sort":"offering_price asc",
				"group.ngroups": true,
				"group":this.state.groupedView,	
*/			
				
			}
		};
		
		if(this.state.groupedView){
			axiosData.query.rows = 0;
			axiosData.query.start = 0;
			axiosData.query["json.facet"] = {
					"building_count" : "unique(building)",
					"by_building": {
						"type": "terms",
						"field": "building",
						"sort" : sort,
						"offset": resetPage ? 0 : this.state.start,
						"limit": this.state.rowsPerPage,
						"facet": {
							"non_zero_price": {
								"type": "query",
								"q": "offering_price:[1 TO *] AND offering_bedrooms:[0 TO *]",
								"facet": {
									"low_price": "min(price)",
									"high_price": "max(price)",
									"average_price": "avg(price)",
									"low_sq_ft": "min(sq_ft)",
									"high_sq_ft": "max(sq_ft)",
									"low_bed": "min(bedrooms)",
									"high_bed": "max(bedrooms)",
								}
							},
/*
							"by_beds" : {
								"type" : "terms",
								"field" : "bedrooms"
							},
*/
							"avg_price": "avg(price)",
							"avg_sq_ft": "avg(price)",
							"low_bed": "min(bedrooms)",
						}
					}
				}
			
		}

	
		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});
		if(this.state.groupedView){
			
			let formattedDataBuilding = response && response.facets && response.facets.by_building && response.facets.by_building.buckets
			? response.facets.by_building.buckets.slice(0)
			: false;
			
			
			const _matchedBuildingIds = [];
			
			if(formattedDataBuilding){
				formattedDataBuilding.map(item => {
/*
					if(item.val === 'b-3-280-cadman-plz-w-11201'){
						item.val = 'b-3-1-clinton-st-11201';
					}
*/
					
					_matchedBuildingIds.push(item.val);
					
					
				})
			}
			
			if(_matchedBuildingIds.length>0){
				this._getInventoryBuildings(_matchedBuildingIds.join(" "), function(res){					
					if(res && res.docs){
						res.docs.map(building => {
							formattedDataBuilding.filter(item => item.val === building.key)[0]['building'] = building;
						})
					}
					
					this.setState(prevState => ({
						fetchInProgress: false,
						groupedData:  formattedDataBuilding,
						groupedCount: response.facets.building_count, //response.building.ngroups,	
						totalCount: response.numFound,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page
					}));
				}.bind(this))
			}else{
				this.setState(prevState => ({
						fetchInProgress: false,
						groupedData:  [],
						groupedCount: 0, //response.building.ngroups,	
						totalCount: 0,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page
				}));
			}

		}else{
			let formattedData = response && response.docs
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
			
			let docs = formattedData && formattedData.map((doc, i) => {
				let item = Object.assign({}, doc);			
				return item;
			});
	
			this.setState(prevState => ({
				fetchInProgress: false,
				tableData:  docs,
				reactTableFiltered:  docs,	
				totalCount: response.numFound,
				groupedCount: false,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page
			}));
		}
		
		
			
	};
	
	_getInventoryBuildings = async (_matchedBuildingIds, callback) => {
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"key:("+_matchedBuildingIds+")",
			"deleted:false",			
		];

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				sort: "inventory_unsold desc",
				fl: buildingFL,
				wt: "json",
				rows: 999,
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof callback === "function") {
					let formattedRes = callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
		
	_formatStats = (response, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = this.defaultStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		
		if(newStages.length === 0 ) newStages = ['all'];
		
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	
	_setUnitStage = (stage) => {
	
		let newStages = this.state.unitStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = this.defaultUnitStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = this.defaultUnitStage;
		this.setState({
			unitStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = !this.props.buildingView ? Object.assign({}, defaultSubFilter) : {};
		this.setState({
			buildingStages : !this.props.buildingView ? this.defaultStage : false,
			unitStages : this.defaultUnitStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, unitStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}	
	
	_resetActive = () => {
		
		
		
		let isDefault = true;
		if(this.props.buildingView){
			isDefault = Object.keys(this.state.subFilters).length <=0 &&
			_objectEqual(this.defaultUnitStage, this.state.unitStages)
		}else{
			isDefault = this.props.searchTags.length===0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(this.defaultUnitStage, this.state.unitStages) &&
			_objectEqual(this.defaultStage, this.state.buildingStages);
		}
		
	  	
	    return !isDefault;
		
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		
		if(this.state.groupedView){
			this.setState( { groupedView : false, orderBy : "", order : "" } )
			setLocalStorage(lsPre, 'groupedView', false);
		}else{
			this.setState( { groupedView : true, orderBy : "count", order : "desc" } )
			setLocalStorage(lsPre, 'groupedView', true);
		}
		
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, searchText, unitStages
    } = this.state;
    const { user, building } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;
    let showUnitStageFilter = true;
	if(this.props.buildingView && _isCoop(building) && building.year_built < 2014 && building.year_converted < 2014){
		showUnitStageFilter = false;	
	}
  

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} className="unit_search" >
           {/*<div className="card__title">
              <h5 className="bold-text">Select and Analyze</h5>
            </div>*/}
            
            {!this.props.buildingView ?
            <BuildingSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={(user && user.role.indexOf('admin')>=0) ?  this._unitSubFilter : this._unitSubFilter.filter(item => !item.admin_only)}      
            	{...this.props}  
            	groupedView   
            	building={this.props.building}	
            	placeHolder={'Building, Neighborhood or Borough'}
            	unitStages={unitStages}
            	setUnitStage={this._setUnitStage}
            	buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	buildingAttributes={[]}
            	goToBuilding
            	numToShow={7}
				UnitStageFilter={<UnitStageFilter
								unitStages={unitStages}
								setUnitStage={this._setUnitStage}
							/>}
				setSubFilters={this._setSubFilters}
				buildingTab={'units'}
				resetActive={this._resetActive()}				
				q={this.q}
				fq={this.unitFq}
				fl={salesExportFL}
				sort={this.sort}
				totalCount={totalCount}
				showExport
				core_name={'we3-transactions'}
				export_name={'Past Sales Search'} 
				title={'Export This Sales Search (CSV)'}  
          		description={<Fragment><h4><strong>Export this past sales search for your own analysis.</strong></h4> <br/>   The export will include details about unit sold price, sold date, bed, bath and size.</Fragment>}
             />
             : !this.props.unitView ?
             <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={(user && user.role.indexOf('admin')>=0) ?  this._propertyFilter : this._propertyFilter.filter(item => !item.admin_only)}      
            	{...this.props}  
            	groupedView   
            	building={this.props.building}
            	setSubFilters={this._setSubFilters}	
            	UnitStageFilter={showUnitStageFilter ? <UnitStageFilter
								unitStages={unitStages}
								setUnitStage={this._setUnitStage}
							/> : null}
				numToShow={7}
				resetActive={this._resetActive()}
				unitStages={unitStages}
				setUnitStage={this._setUnitStage}
				q={this.q}
				fq={this.unitFq}
				fl={salesExportFL}
				sort={this.sort}
				totalCount={totalCount}
				showExport
				core_name={'we3-transactions'}
				export_name={`${building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)} Sales`} 
				title={'Export Sales in This Building (CSV)'}  
          		description={<Fragment><h4><strong>Export past sales for your own analysis.</strong></h4> <br/>   The export will include details about unit sold price, sold date, bed, bath and size.</Fragment>}
             />
             : null
             }
            
            <div className="material-table__header-info unit_search">
            	<Row>
            		<Col md={12} lg={12}>
            			{!isMobile && this.props.buildingView &&  !this.props.unitView &&
	            			<div className={`unit_toggle_filter`}>
            				{showUnitStageFilter && <UnitStageFilter
								unitStages={unitStages}
								setUnitStage={this._setUnitStage}
							/>}
						</div>}
						<div className={`count_toggle`}>
						
						    <div>
								<span className="bold-text">{totalCount > 0 ? _formatNumber(totalCount) : '--'}</span>{` `}Past Sales {_formatNumber(groupedCount) ? <Fragment> in <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings</Fragment> : ''}
							</div>
						
							{false && !this.props.buildingView &&
								 <div className={`unit_building_toggle`}>
								  
								  {/*<BuildingIcon color="#DBE0E8" />*/}
								  <span>{!isMobile ? 'Building View' : 'Bldgs'}</span>
						          <Tooltip title={`${this.state.groupedView ? "Expand to See Unit List" : "Condense to Group by Buildings"}`}>
							        <PurpleSwitch 
							            checked={!this.state.groupedView}
							        	onChange={this._toggleView}
							        />
							      </Tooltip>
							      <span>{!isMobile ? 'List View' : 'Units'}</span>					      	
							      {/*<ListIcon color="#DBE0E8" />	*/}			       
							    </div>
						    }
						</div>

					</Col>
					
					
					
            	</Row>
            </div>
            
            <div className="material-table__wrap">
              
              {fetchInProgress && this.state.groupedView && <LinearLoading />}
              {this.state.groupedView && groupedData && 
	              
	           <div className="material-table expansion__table">	
	           		
	           	   <Container>
				        <Row className="expansion__summary__header">
							<Col lg={2} xs={12}>	                          
							  	<TableSortLabel
					                active={orderBy === "count"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "count")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Matching Units
					              </TableSortLabel>
							</Col>		                        
	                        <Col lg={4} className="no-padding">	                          
							 	Building
							</Col>	
							<Col lg={2} className="no-padding">
	                      		<TableSortLabel
					                active={orderBy === "avg_price"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "avg_price")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Asking Price
					              </TableSortLabel>
	                        </Col>
	                                               
	                        <Col lg={2} >
	                      		<TableSortLabel
					                active={orderBy === "low_bed"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "low_bed")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Bedrooms
					              </TableSortLabel>
	                        </Col> 
	                       
	                        <Col lg={2} className="no-padding">
	                      		% Unsold
	                        </Col>	
	                    </Row>
	                </Container>
	              {groupedData.length ===0 &&		              
		            <Container>
				        <Row className="no__data">
							<Col lg={12} xs={12} className="no__data-text">			                          
							  	No data found for your search.
							</Col>	
						</Row>
					</Container>
	              }  	           
	              {groupedData.map((d) => {
		                const item = d
		                const numFound = item.count;
		                const isSelected = this.isSelected(item.val);
		                const building = item.building;
			            return(  			            
			              <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
					        <ExpansionPanelSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-label="Expand"
					          aria-controls=""
					          id={d.groupValue}
					        >
					        <Container>
						        <Row className="expansion__summary">
									<Col lg={2} xs={12} >
			                          
									  	<Badge color="matching"><span className="bold-text">{numFound}</span> Units</Badge>
									</Col>		                        
			                        <Col lg={4} xs={12} className="no-padding">			                          
									  <div className="expansion__header">									  
									  	<span className={"building_name"} onClick={event => this.handleGroupedBuildingClick(event, building)}>
									  		{building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}
									  		<BuildingIcons building={building} />

									  	</span>
									  </div>				          
									  <span className="light-text small-text">
									  	{building.neighborhood && building.neighborhood[0] ? `${_capitalizeText(building.neighborhood[0])}, ` : ''} {_capitalizeText(building.borough)}
									  	</span>
									</Col>			                        
			                        <Col lg={2} xs={7} className="no-padding">
			                        	<span className={"mobile__show light-text small-text"}>Price:</span>
		                          		<div className="light-text small-text">
		                          			{item.non_zero_price && item.non_zero_price.low_price > 100 ? `${_formatPrice(item.non_zero_price.low_price).toUpperCase()} - ${_formatPrice(item.non_zero_price.high_price).toUpperCase()}` : '--'}
		                          		</div>
		                          		<span className="light-text small-text">
		                          			{item.non_zero_price && item.non_zero_price.average_price > 100 ? `${_formatPrice(item.non_zero_price.average_price).toUpperCase()} Avg` : '--'}
		                          		</span>
		                            </Col> 
		                            <Col lg={2} xs={5}>
		                            	<span className={"mobile__show light-text small-text"}>Bedrooms:</span>
		                            	<div className="light-text small-text">
		                            	{item.non_zero_price && item.non_zero_price.low_bed > -1 ? `${_formatBed(item.non_zero_price.low_bed)} - ${_formatBed(item.non_zero_price.high_bed)}` : '--'}
		                            	</div>
		                          		<span className="light-text small-text">
		                          			{item.non_zero_price && item.non_zero_price.low_sq_ft > 100 ? `${_formatNumber(item.non_zero_price.low_sq_ft).toUpperCase()} SF - ${_formatNumber(item.non_zero_price.high_sq_ft).toUpperCase()} SF` : '--'}
		                          		</span>
		                            </Col>
		                            
		                            <Col lg={2}  xs={12} className="no-padding">
		                            	{isMobile && <div style={{height:10}}/>}
		                            	<span className={"mobile__show light-text small-text"}>% Unsold:</span>
		                            	{!isMobile ?
			                            	<Fragment>
		                            		{building.offering_construction==='conversion' 
						                          	 ?
						                          	 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total} min color={"grey"} resale={building.building_status === 'resale'}/>
						                          	 : building.building_status === 'resale' ?
						                          	 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total} min color={"grey"} resale={building.building_status === 'resale'} />
						                          	 :
									                 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total} min resale={building.building_status === 'resale'} />
								                  }
								            </Fragment>
		                            		:
		                            		<span className="light-text small-text">
		                            			{building.inventory_unsold} of {building.inventory_total} Units ({parseFloat(building.inventory_pct_unsold).toFixed(0)}%)
		                            		</span>
		                            	}
		                            </Col>	
		                        </Row>
		                    </Container>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>				        
					        	<UnitsListTable 
				              		isSelected={this.isSelected}
				              		handleBuildingClick={this.handleBuildingClick}
				              		handleClick={this.handleClick}
				              		handleRequestSort={this.handleRequestSort}
				              		handleSelectAllClick={this.handleSelectAllClick}
				              		{...this.state} 
				              		{...this.props} 
				              		reactTableFiltered={false}	
				              		isGrouped
				              		q={this.q}
				              		fq={this.unitFq}
				              		fl={this.fl}	
				              		buildingKey={item.val === 'b-3-1-clinton-st-11201' ? 'b-3-280-cadman-plz-w-11201' : item.val}		     
									showUnitStageFilter={showUnitStageFilter ? true : false}         		
				              />
					        </ExpansionPanelDetails>
					     </ExpansionPanel>
					    )
					 })
	             }  
	            </div>
	          }
            
            
              {!this.state.groupedView && 	              
	              <UnitsListTable 
	              		isSelected={this.isSelected}
	              		handleBuildingClick={this.handleBuildingClick}
	              		handleClick={this.handleClick}
	              		handleRequestSort={this.handleRequestSort}
	              		handleSelectAllClick={this.handleSelectAllClick}
	              		{...this.state} 
	              		{...this.props} 
	              		showUnitStageFilter={showUnitStageFilter ? true : false}   	
	              		
	              />      
              }
            </div>
            {paginationCount ? <TablePagination
              component="div"
              className="material-table__pagination"
              count={paginationCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            /> : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(UnitsSearchTable));