import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ToolTip from '../../../../../shared/components/ToolTip';

import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

  
const calculateAbsorption = (buildingRes, statsRes, period) => {		
	if(!buildingRes.sales_start_date) return '--';	
	let totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	let months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);
	
	if(period && period === "1-yr"){
		if(statsRes && statsRes.sold_1_yr && statsRes.sold_1_yr.count > 0){
			totalSold = statsRes.sold_1_yr.count;
			if(months > 12){
				months = 12;
			}
		}
	}else if(period && period === "3-mo"){
		if(statsRes && statsRes.sold_3_mo && statsRes.sold_3_mo.count > 0){
			totalSold = statsRes.sold_3_mo.count;
			if(months > 3){
				months = 3;
			}
		}
	}else if(period && period === "6-mo"){
		if(statsRes && statsRes.sold_6_mo && statsRes.sold_6_mo.count > 0){
			totalSold = statsRes.sold_6_mo.count;
			if(months > 6){
				months = 6;
			}
		}
	}
	
	const velocity = totalSold / months;
	
	const soldPct = totalSold / buildingRes.inventory_total;
	
	if(velocity <=0 || totalSold <=0 || soldPct < .01){
		return '--';
	}
	
	const monthsLeft = (buildingRes.inventory_unsold / velocity).toFixed(1);	
	
	return monthsLeft;
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes) => {		
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + + buildingRes.inventory_contract;
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalAvail, fill: getDefaultChartColors[1] },
  				{ value: totalSold, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSellout = (buildingRes, statsRes, period, type) => {		
	const monthsLeft = calculateAbsorption(buildingRes, statsRes, period);
	if( monthsLeft === '--' ){
		if(type === 'fromNow'){
			return 'Not Enough Info';
		}else{
			return null;
		}
		
	}
	
	
	if(type === 'fromNow'){
		return moment().add(monthsLeft, 'M').fromNow();
	}	
	return `(${moment().add(monthsLeft, 'M').format("M/D/YYYY")})`;
}

const AbsorptionRate = (props) => {
	const { buildingRes, location, statsRes, period, activityStatsRes } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	
	return (
	  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
	    <Card>
	    <Link to={`${base_url}`}>
	      <CardBody className="dashboard__health-chart-card card__link">
	        <div className="card__title">
	          <h5 className="bold-text card__title-center">{`Absorption Rate (${period === '3-mo' ? '3 Mo' : period === '6-mo' ? '6 Mo' : period === '1-yr' ? '12 Mo' : 'All Time'})`}
	          		<ToolTip 
		        		title={`Based on average sales velocity ${period === '3-mo' ? 'in the last 3 months of sales' : period === '6-mo' ? 'in the last 6 months of sales' : period === '1-yr' ? 'in the last 1 year of sales' : ' since sales launched'}.`} 
		        	/>
	          </h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={calculateBarData(buildingRes, activityStatsRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="dashboard__health-chart-info">
	            <ClockOutlineIcon style={{ fill: getDefaultChartColors[1] }} />
	            <p className="dashboard__health-chart-number">{calculateAbsorption(buildingRes, activityStatsRes, period)}</p>
	            <p className="dashboard__health-chart-units">months remaining</p>
	          </div>
	        </div>
	        <p className="dashboard__goal">Est Sellout: {_capitalizeText(calcuateSellout(buildingRes, activityStatsRes, period, 'fromNow'))} {calcuateSellout(buildingRes, activityStatsRes, period)}</p>
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
	)
};


export default AbsorptionRate;
