import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";
import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import ActiveIcon from 'mdi-react/CheckDecagramIcon';
import ComingIcon from 'mdi-react/AlertDecagramOutlineIcon';
import VettedIcon from 'mdi-react/AlertDecagramOutlineIcon';
import CondoDecIcon from 'mdi-react/AlphaCCircleOutlineIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import MoveIcon from 'mdi-react/AlphaTCircleOutlineIcon';
import ConstructIcon from 'mdi-react/WrenchOutlineIcon';
import ClosingIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import OfferingStatus from './OfferingStatus';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	buildingStageToolTips,
	BuildingStageBadge
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class BuildingIcons extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
 

  render(){
	  const { modal } = this.state;
	  const { building, user, isUnit} = this.props;
	  let hasContact = false;
                      
      if(building.sales_office_details && building.sales_office_details.length > 2){
          try{
              const contactData = JSON.parse(building.sales_office_details);
              if(contactData && contactData.email && contactData.email.indexOf('@')>=0){
                  hasContact = true;
              }
              
          }catch(e){
              
          }
      }
      const iconColor = '#BF96D9';
      
      let isAdmin = user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0;
      
      if(isMobile) return null;
	  return (
		   <span className="building_icons">
		   		{(!isUnit && building.building_status) &&
			   		<BuildingStageBadge item={building} small />
            	 }
            	{building.offering_construction && building.offering_construction==='conversion' &&
	            	<Tooltip title={`Building is a condo conversion`}>
			  			<IconButton size={`small`} style={{background:"transparent"}}>
			  				<span className="unit-type_badge">Conv</span>
			  			</IconButton>
			  		</Tooltip>
            	 }
            	 {(building.ownership_type && building.ownership_type.indexOf('rental')>=0 || (building.ownership_occupancy_sub_types && building.ownership_occupancy_sub_types.indexOf('rental')>=0)) &&
                    <Tooltip title={`Building has both condo and rental components`}>
			  			<IconButton size={`small`} style={{background:"transparent"}}>
			  				<span className="unit-type_badge">Hybrid - Rental</span>
			  			</IconButton>
			  		</Tooltip>
            	 }
            	 <OfferingStatus building={building} />
            	 
            	{!isMobile &&
	            	<Fragment>
			        	{hasContact && isAdmin &&
					  		<Tooltip title={`Building has sales contact`}>
					  			<IconButton size={`small`}>
					  				<ContactIcon size={16} style={{ marginTop:"-3px", marginLeft:"0px"}} color={iconColor} />
					  			</IconButton>
					  		</Tooltip>
					  		
				  		}
				  		{building.offering_plan_documents && building.offering_plan_documents.length > 0  && isAdmin && 
					  		<Fragment>
						  		<Tooltip title={`Download offering plan`}>
						  			<IconButton size={`small`} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(building.offering_plan_documents[0]); }} >
						  				<DocIcon size={16} style={{ marginTop:"-3px", marginLeft:"0px"}} color={iconColor} />
						  			</IconButton>
						  		</Tooltip>
					  		</Fragment>
				  		}
				  		{building.offering_effective_date && moment(building.offering_effective_date).format('YYYY') > 1970 &&
					  		<Fragment>
					  			<Tooltip title={`Offering plan effective as of ${moment(building.offering_effective_date).format('MM/DD/YYYY')}`}>
						  			<IconButton size={`small`} style={{ marginTop:"-3px", marginLeft:"0px"}} onClick={(e) => { e.preventDefault();e.stopPropagation();}} >
						  				<CheckedIcon size={16} color={iconColor} />				
						  			</IconButton>
						  		</Tooltip>
					  		</Fragment>
				  		}
				  		{building.cdec_date && parseInt(moment(building.cdec_date).format('YYYY')) > 1970 &&
					  		<Fragment>
					  			<Tooltip title={`Condo Declaration filed ${moment(building.cdec_date).format('MM/DD/YYYY')} `}>
						  			<IconButton size={`small`} style={{ marginTop:"-3px", marginLeft:"0px"}}  onClick={(e) => { e.preventDefault();e.stopPropagation();}}>
						  				<CondoDecIcon size={16} color={iconColor} />				
						  				</IconButton>
						  		</Tooltip>
					  		</Fragment>									  		
				  		}
				  		{building.building_effective_start && moment(building.building_effective_start).year() > 1970 &&
					  		<Fragment>
					  			<Tooltip title={`Received TCO/CO on ${moment(building.building_effective_start).format('MM/DD/YYYY')}`}>
						  			<IconButton size={`small`} style={{ marginTop:"-3px", marginLeft:"0px"}}  onClick={(e) => { e.preventDefault();e.stopPropagation();}} >
						  				<MoveIcon size={16} color={iconColor} />				
						  			</IconButton>
						  		</Tooltip>
					  		</Fragment>						  				
					  	}
					  	{isAdmin && building.first_closing_date && moment(building.first_closing_date).year() > 1970 &&
					  		<Fragment>
					  			<Tooltip title={`First Closing on ${moment(building.first_closing_date).format('MM/DD/YYYY')}`}>
						  			<IconButton size={`small`} style={{ marginTop:"-3px", marginLeft:"0px"}}  onClick={(e) => { e.preventDefault();e.stopPropagation();}} >
						  				<ClosingIcon size={16} color={iconColor} />				
						  			</IconButton>
						  		</Tooltip>
					  		</Fragment>						  				
					  	}
					</Fragment>
				}		  		
		  		
		  </span>
	  )
	}
};
export default connect(state => ({
  user: state.user,
}))(withRouter(BuildingIcons));

