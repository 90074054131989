import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import UnitsSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';
import UnitStageFilter from '../../../shared/components/unit_filters/UnitStageFilter';
import searchActions from '../../../redux/actions/searchActions';

import classNames from 'classnames';
import classnames from 'classnames';
import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {isMobile} from 'react-device-detect';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import QuarterlyIcon from 'mdi-react/CalendarBlankIcon';
import MonthlyIcon from 'mdi-react/CalendarMonthOutlineIcon';
import WeeklyIcon from 'mdi-react/CalendarClockIcon';
import OpenNewIcon from 'mdi-react/OpenInNewIcon';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import ListViewIcon from 'mdi-react/FormatListBulletedIcon';
import GroupViewIcon from 'mdi-react/DomainIcon';
import StatsViewIcon from 'mdi-react/ChartTimelineVariantIcon';

import ClosedDateIcon from 'mdi-react/ClockIcon';
import ReportedDateIcon from 'mdi-react/ClockOutlineIcon';

import DataExport from './DataExport';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Panel from '../../../shared/components/Panel';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';
import EnterpriseGate from '../../../shared/components/payment/EnterpriseGate';
import ToolTip from '../../../shared/components/ToolTip';

import queryString from "query-string";

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';

import { getDefaultChartColors, getStatusColors } from '../../../shared/helpers';
import { makeStyles } from '@material-ui/core/styles';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	urlSlugBuilding,
	_isNewUser,
	_isEnterpriseUser,
	_objectEqual,
	getCohortQuery,
	_activityFQ,
	_isHouse
} from "../../../shared/helpers/utils";

import { 
	_marketActivityFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../shared/helpers/filters";

import { _hasPermission	} from "../../../shared/helpers/access";

let counter = 0;
am4core.useTheme(am4themes_animated);
function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}
const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft:"-8px",
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),      
	},
	
  },
  button:{
	'&.selected' :{
		background:"#EDDEF5"
	},
	'width': 110
  }
}));
const ChartType = ({chartType, setChartType}) => {
	const classes = useStyles();
	return (
		<div style={{position:'absolute',marginTop:15}}>
		
			 <ButtonGroup size="small" aria-label="small button group">							        
				 <Tooltip title="Show contract activity" aria-label="Show contract activity">
				   <Button onClick={()=> setChartType("contracts")} className={chartType === "contracts" ? classNames(classes.button, "selected") : classNames(classes.button)}>Contracts</Button>
			   </Tooltip>
			   <Tooltip title="Show new listing activity" aria-label="Show new listing activity">
				   <Button onClick={()=> setChartType("listings")} className={chartType === "listings" ? classNames(classes.button, "selected") : classNames(classes.button)}>Listings</Button>
			   </Tooltip>
			   <Tooltip title="Show closing activity" aria-label="Show closing activity">
					   <Button onClick={()=> setChartType("closings")} className={chartType === "closings" ? classNames(classes.button, "selected") : classNames(classes.button)}>Closings</Button>
				  </Tooltip>	
			    <Tooltip title="Show price drop activity" aria-label="Show price drop activity">
					   <Button onClick={()=> setChartType("price-decreases")} className={chartType === "price-decreases" ? classNames(classes.button, "selected") : classNames(classes.button)}>Price Drops</Button>
				  </Tooltip> 
				<Tooltip title="Show price hike activity" aria-label="Show price hike activity">
					   <Button onClick={()=> setChartType("price-increases")} className={chartType === "price-increases" ? classNames(classes.button, "selected") : classNames(classes.button)}>Price Hikes</Button>
				  </Tooltip>
			 </ButtonGroup>
		 </div>
	)
}


const buildingUrl = process.env.API_ROOT + '/query/';

const lsPre = "MAV3_2_";
const subFilterKey = 'subFilters';
const unitStagesKey = 'unitStages';
const defaultSubFilter = {offering_verified_status:["not-abandoned"]};
const buildingStagesKey = 'buildingStages';
const defaultStage = ["all"];
const defaultUnitStage = ['all'];
const gap = syncGenericFilter('gap', lsPre, 'gap', "+7DAY");
const defaultGap = gap === '+1MONTH' ? 'month' : 'week';

const dateStart = '01/05/2015';
const isDST = true; //moment().isDST();

class UnitsSearchTable extends PureComponent {
  defaultStage = _hasPermission(this.props.user, 'sponsor-buildings') ? ['all'] : ['resale'];
  defaultUnitStage = _hasPermission(this.props.user, 'sponsor-buildings') ? ['all'] : ['all'];
  state = {
    order: syncGenericFilter('order', lsPre, 'order', "desc"),
    orderBy:  syncGenericFilter('order_by', lsPre, 'orderBy', "count"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    showBottomLoader: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _marketActivityFilters, subFilterKey, lsPre, defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').add(isDST ? -1 : 0, 'hour').toDate().toISOString(),
	endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
	gap:  gap,
	chartData:[],
	dataStart:isMobile 
				? syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1 , 'day').toDate().toISOString())
				: syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').toDate().toISOString()),
	dataEnd: syncGenericFilter('end_date', lsPre, 'dataEnd', moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString()),
	buildingEventCount:0,
	selectedDate:syncGenericFilter('selected_date', lsPre, 'selectedDate', false),
	eventTypes:syncGenericFilter('event_types', lsPre, 'eventTypes', [ "Listings", "Contracts", "Sales", "Price Drops", "Price Hikes", "New Dev", "Resale Condo", "Resale Co-op", "Single-Family", "Multi-Family"], true),
	soldTimeField: syncGenericFilter('sold_time', lsPre, 'soldTimeField', "event_time"),
	buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, lsPre, this.defaultStage),
	unitStages:  ['all'], //syncStageFilters(this.props, _unitStageFilter, unitStagesKey, lsPre, this.defaultUnitStage),
	viewType : "list",
	buildingData: false,
	chartType : "contracts",
	chartView: "property"
  };
  
  defaultQ = getCohortQuery(this.props.user, true);
  q='';
  fq = [];
  property_q = '';
  chartLoaded = false;
  
/*
  	[		
		"offering_verified_status:(active \"coming soon\" \"offering plan required\" \"condo declaration vetted\")",
		//"offering_verified_status:(active)",
		"deleted:false",
		//"-key:b-4-2315-44th-dr-11101"
	];
*/
	
  fl = buildingFL;
  chart;
  _activityFilter = _marketActivityFilters.filter(filter => !filter.filter_type && !filter.activity_hide);
  _propertyFilter = _marketActivityFilters.filter(filter => filter.filter_type && filter.filter_type === 'property' && !filter.activity_hide);
  _buildingFilter = _marketActivityFilters.filter(filter => filter.filter_type && filter.filter_type === 'building' && !filter.activity_hide);
  _unitSubFilter = _marketActivityFilters.filter(filter => !(this.props.buildingView && filter.building_hide) && !filter.activity_hide);
  timeout = null;
   _matchedBuildingIds = [];
   _matchedBuildingSlugs = [];
   _building_q = this.defaultQ;
   formattedData;
   tempEventTypes = this.state.eventTypes.slice(0);
   noDataIndicator;
  componentDidMount() {
  	 //this._getBuildings();
  console.log(this.state)	 
  	 this._getEvents(true);
  	 
  	
  }
 
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
	  	let unitStageChange = !_isEqual(prevState.unitStages, this.state.unitStages);
	  	
	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||			
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.gap !== prevState.gap ||
			this.state.soldTimeField !== prevState.soldTimeField || 
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged ||
			unitStageChange ||
			buildingStageChange ||
			this.state.chartType !== prevState.chartType ||
			this.state.chartView !== prevState.chartView
			//this.state.viewType !== prevState.viewType
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)
			  this.defaultQ = getCohortQuery(this.props.user, true);		
			  this._getEvents(resetPage);
		}else if(this.state.dataStart !== prevState.dataStart ||
			this.state.dataEnd !== prevState.dataEnd ||
			this.state.selectedDate !== prevState.selectedDate ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			!_isEqual(this.state.eventTypes, prevState.eventTypes)){
				this._getEvents(false)
		}
		
		

	  
  }
  
  buildChart = (data) => {
	  let chart = am4core.create("chartdiv", am4charts.XYChart);
  	  const { user } = this.props;
  	  const { eventTypes, selectedDate, startDate,  dataStart, endDate, dataEnd, gap, chartType, chartView} = this.state;
  	    chart.scrollbarX = new am4core.Scrollbar();
  	 // Add data
		
		chart.data = data;

		function showIndicator(noDataIndicator) {
		  if (noDataIndicator) {
		    noDataIndicator.show();
		  }
		  else {
		    noDataIndicator = chart.tooltipContainer.createChild(am4core.Container);
		    noDataIndicator.background.fill = am4core.color("#fff");
		    noDataIndicator.background.fillOpacity = 0.8;
		    noDataIndicator.width = am4core.percent(100);
		    noDataIndicator.height = am4core.percent(100);
		
		    var indicatorLabel = noDataIndicator.createChild(am4core.Label);
		    indicatorLabel.text = "No data found for your search.";
		    indicatorLabel.align = "center";
		    indicatorLabel.valign = "middle";
		    indicatorLabel.fontSize = 14;
		  }
		}
		
		function hideIndicator(noDataIndicator) {
		   noDataIndicator.hide();
		}
		
		function highLightColumn(categoryX){
			console.log(categoryX);
			chart.series.each(function(series) {				    
			    series.columns && series.columns.values.forEach(c => {
				    if (c.dataItem.categoryX == categoryX) {
				          c.isActive = true;
				    }
				    
				 });
			}.bind(this));
		}
		
		chart.events.on("beforedatavalidated", function(ev) {
		  // check if there's data
		  if (ev.target.data.length == 0) {
		    showIndicator(this.noDataIndicator);
		  }
		  else if (this.noDataIndicator) {
		    hideIndicator(this.noDataIndicator);
		  }
		}.bind(this));
		
		chart.events.on("ready", function(ev) {
			    console.log("here", selectedDate)
			    if(selectedDate){
					const columnX  = this.getIntervalName(selectedDate);
					highLightColumn(columnX);
				}
			    this.chartLoaded = true;
		}.bind(this));
		

		
		// Create axes
		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "time";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = "right";
		categoryAxis.renderer.labels.template.verticalCenter = "middle";
		categoryAxis.renderer.labels.template.rotation = 290;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.minHeight = 110;
		
		
		const slideChange = (ev) => {
			if(this.timeout) clearTimeout(this.timeout);
			
			const { dataStart, dataEnd } = this.state;
			let axis = ev.target;
			let start = axis.getPositionLabel(axis.start);
			let end = axis.getPositionLabel(axis.end);
console.log(start, end);	
			const isStartDST = this.getIntervalTime(start).isDST();		
			 const formattedStart = gap === '+1MONTH' ? this.getIntervalTime(start).startOf('month').add(isStartDST !== isDST ? -1 : 0, 'hour') : this.getIntervalTime(start).startOf('week').add(isStartDST !== isDST ? -1 : 0, 'hour');
			let formattedEnd = gap === '+1MONTH' ? this.getIntervalTime(end).endOf('month') : this.getIntervalTime(end).endOf('week');
			
			if(moment().diff(formattedEnd) < 0){
				formattedEnd = moment();
			}
//console.log(formattedStart, formattedEnd);			
			if(start && end && (dataStart !== start || dataEnd !== end)){
				this.timeout = setTimeout(function (start, end, series) {
				//  
				   if(this.chartLoaded){
					   chart.series.each(function(series) {
						   
					      series.columns.values.forEach(c => c.isActive = false);
					   });
				       this.setState({
					       dataStart: formattedStart.toDate().toISOString(), 
					       dataEnd: formattedEnd.toDate().toISOString(),
					       selectedDate: false,
					       eventTypes: this.tempEventTypes.slice(0)
					   });					   
				   }
				   

				}.bind(this, start, end, chart), 100)				
			}			
		}
		categoryAxis.events.on("endchanged", slideChange);
		categoryAxis.events.on("startchanged", slideChange);
		
		const totalSpan = moment(endDate).diff(moment(startDate));
		const dateDiffStart = moment(dataStart).diff(moment(startDate));
		const dateDiffEnd = moment(dataEnd).diff(moment(startDate));


		categoryAxis.start = dateDiffStart / totalSpan;//isMobile ? 0.875 : 0.8;
		categoryAxis.end = dateDiffEnd / totalSpan;
		categoryAxis.keepSelection = true;
		
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.minWidth = 50;
		
		
		// Create series
		const createSeries = (field, name) => {
  
		  // Set up series
		  var series = chart.series.push(new am4charts.ColumnSeries());
		  series.name = name;
		  series.dataFields.valueY = field;
		  series.dataFields.categoryX = "time";
 		  series.sequencedInterpolation = true;
/*
		  series.defaultState.transitionDuration = 300;
		  series.hiddenState.transitionDuration = 300;
		  series.interpolationDuration = 10;
*/
		  
		  series.showOnInit = false;
		  
		  // Make it stacked
		  series.stacked = true;
		  
		 		  
		  
		  // Configure columns
		  series.columns.template.width = am4core.percent(80);
		  series.columns.template.tooltipText = "{categoryX}\n[font-size:14px]{name}: [bold]{valueY}[/]";		  
		
		  series.columns.template.column.cornerRadiusTopLeft = 0;
		  series.columns.template.column.cornerRadiusTopRight = 0;
		  series.columns.template.column.fillOpacity = 0.8;
		  series.columns.template.strokeWidth = 0;
		
		  series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
		  
	  
		  var hoverState = series.columns.template.column.states.create("hover");
			hoverState.properties.cornerRadiusTopLeft = 0;
			hoverState.properties.cornerRadiusTopRight = 0;
			hoverState.properties.fillOpacity = 1;

			//series.columns.template.column.togglable=true;
			let activeColor = getStatusColors['active'];
			if(field === "contract_signed") activeColor = getStatusColors['contract'];
			if(field === "recorded_sold") activeColor = getStatusColors['sold'];
			
			
			
			var activeState = series.columns.template.states.create("active");
			activeState.properties.fill = am4core.color('#5C1F7A');
			activeState.dx = 6;
			activeState.dy = 6;
			activeState.opacity = 0.3;
			

			series.columns.template.events.on("hit", function(ev) {
			  const { dataStart, dataEnd, gap } = this.state;
			  console.log(ev)
			  chart.series.each(function(series) {
				    series.columns.values
					  .filter(c => c !== ev.target)
					  .forEach(c => c.isActive = false);
			  });
			  
			  
			  
			  ev.target.isActive = !ev.target.isActive;
			  
			  if(ev.target.isActive){
				  
				  const columnX = ev.target.dataItem.categoryX;
				  highLightColumn(columnX);
				  
				  let start = ev.target.dataItem.categories.categoryX;
				  let end = gap === '+1MONTH' ? this.getIntervalTime(start).add(1, 'month') : this.getIntervalTime(start).add(1, 'week');
				  
				  const formattedStart = gap === '+1MONTH' ? this.getIntervalTime(start).startOf('month') : this.getIntervalTime(start).startOf('week').add(1, 'day');
				  
				  let type = ev.target.dataItem.component.dataFields.valueY;
				  const translateType = {
					  "recorded_sold" : "Sales",
					  "contract_signed" : "Contracts Reported Signed",
					  "new_listings" : "Listings"
				  }
				  
				  if(dataStart !== start || dataEnd !== end){
					 setTimeout(function (start, end, series, translateType) {
						 console.log(formattedStart.toDate().toISOString());
					       this.setState({
						       selectedDate: formattedStart.toDate().toISOString(), 
						       //eventTypes:[translateType[type]]
						   });
		
					}.bind(this, start, end, series, translateType), 100)
					   
				  }			  
			  }else{			  
				  this.setState({
					  selectedDate:false,
					  //eventTypes: this.tempEventTypes.slice(0)
				  })			  
			  }
			  
			  
			  
			}.bind(this));

			let color = "#4ce1b6";
			if(field === "contract_signed") color = "#FFD23F";
			if(field === "recorded_sold") color = "#28C2FF";
			if(field === "price_decrease") color = "#F53246";
			if(field === "price_increase") color = "#2B1ACC";
			
			if(chartView == "property"){
				color = "#A3ADC2";
				if(field.indexOf("condo")>=0) color = "#780F9E";
				if(field.indexOf("coop")>=0) color = "#28C2FF";
				if(field.indexOf("newdev")>=0) color = "#FF206E";
				if(field.indexOf("single")>=0) color = "#FFD23F";
				if(field.indexOf("multi")>=0) color = "#FF691E";
			}
			series.columns.template.adapter.add("fill", function(fill, target) {
			  //return chart.colors.getIndex(target.dataItem.index);
			  
			  return am4core.color(color);
			}.bind(this));
			
			if(eventTypes.indexOf(name) < 0){
			  series.hidden = true;
			  series.hide();
		    }
		    		  
		  return series;
		};
		
		if(chartView == "property"){
			if(chartType == "contracts"){
				if(_hasPermission(this.props.user, 'sponsor-buildings')){
					createSeries("contract_signed_newdev", "New Dev");
				}
				
				createSeries("contract_signed_condo", "Resale Condo");
				createSeries("contract_signed_coop", "Resale Co-op");
				createSeries("contract_signed_single_family", "Single-Family");
				createSeries("contract_signed_multi_family", "Multi-Family");
			}else if(chartType == "listings"){
				if(_hasPermission(this.props.user, 'sponsor-buildings')){
					createSeries("new_listings_newdev", "New Dev");
				}
				createSeries("new_listings_condo", "Resale Condo");
				createSeries("new_listings_coop", "Resale Co-op");
				createSeries("new_listings_single_family", "Single-Family");
				createSeries("new_listings_multi_family", "Multi-Family");
			}else if(chartType == "closings"){
				if(_hasPermission(this.props.user, 'sponsor-buildings')){
					createSeries("recorded_sold_newdev", "New Dev");
				}
				createSeries("recorded_sold_condo", "Resale Condo");
				createSeries("recorded_sold_coop", "Resale Co-op");
				createSeries("recorded_sold_single_family", "Single-Family");
				createSeries("recorded_sold_multi_family", "Multi-Family");
			}else if(chartType == "price-decreases"){
				if(_hasPermission(this.props.user, 'sponsor-buildings')){
					createSeries("price_decrease_newdev", "New Dev");
				}
				createSeries("price_decrease_condo", "Resale Condo");
				createSeries("price_decrease_coop", "Resale Co-op");
				createSeries("price_decrease_single_family", "Single-Family");
				createSeries("price_decrease_multi_family", "Multi-Family");
			}else if(chartType == "price-increases"){
				if(_hasPermission(this.props.user, 'sponsor-buildings')){
					createSeries("price_increase_newdev", "New Dev");
				}
				createSeries("price_increase_condo", "Resale Condo");
				createSeries("price_increase_coop", "Resale Co-op");
				createSeries("price_increase_single_family", "Single-Family");
				createSeries("price_increase_multi_family", "Multi-Family");
			}
		}else{
			createSeries("new_listings", "Listings");
			createSeries("contract_signed", "Contracts");
			createSeries("recorded_sold", "Sales");
			createSeries("price_decrease", "Price Drops");
			createSeries("price_increase", "Price Hikes");
		}
		
		
		
/*
		var series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = "new_listings";
		series.dataFields.categoryX = "time";
		series.tooltipText = "{this.state.gap === '+1MONTH' ? "Month" : "Week"} of {categoryX} - [{categoryX}: bold]{valueY}[/] Listings";
		series.columns.template.strokeWidth = 0;
		
		series.tooltip.pointerOrientation = "vertical";
		
		series.columns.template.column.cornerRadiusTopLeft = 0;
		series.columns.template.column.cornerRadiusTopRight = 0;
		series.columns.template.column.fillOpacity = 0.8;
		
		series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
*/

		
		// on hover, make corner radiuses bigger
		
		
		
		// Cursor
		chart.cursor = new am4charts.XYCursor();
		chart.cursor.lineX.stroke = am4core.color("#000");	
		chart.cursor.lineX.strokeWidth = 2;
		chart.cursor.lineX.strokeOpacity = 1;
		chart.cursor.lineX.strokeDasharray = "6,4";
		
		chart.cursor.lineY.stroke = am4core.color("#000");
		chart.cursor.lineY.strokeWidth = 2;
		chart.cursor.lineY.strokeOpacity = 1;
		chart.cursor.lineY.strokeDasharray = "6,4";
		chart.exporting.menu = new am4core.ExportMenu();		
		chart.zoomOutButton.background.fill = am4core.color("#780F9E");
		chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#9600BF");
		
		
		// Legend
		chart.legend = new am4charts.Legend();
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = 15;
		markerTemplate.height = 15;

		chart.legend.itemContainers.template.events.on("hit", function(ev) {		  
		  const { eventTypes } = this.state;
		  const type = ev.target.dataItem.name;
/*
		   chart.series.each(function(series) {
		      series.columns.values.forEach(c => c.isActive = false);
		    });
*/
		   setTimeout(function (eventTypes, type) {
			 console.log(eventTypes, type)
			  if(this.tempEventTypes.indexOf(type) >= 0){
				  this.tempEventTypes = this.tempEventTypes.filter(item => item !== type).slice(0);
				  this.setState( { eventTypes: this.tempEventTypes.slice(0) })
				  setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }else{
				 this.tempEventTypes = [...this.tempEventTypes, ...[type]];
				 this.setState( { eventTypes: this.tempEventTypes.slice(0) } )
				 setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }
		    }.bind(this, eventTypes, type), 100);
		  //let seriesColumn = ev.target.dataItem.dataContext.columns.template;
		  
		}.bind(this));
		
		
		
		
	
	

		chart.exporting.menu.items = [{
		  "label": "Export",
		  "menu": [
		    { "type": "png", "label": "Image (PNG)" },
		    { "type": "pdf", "label": "PDF" },
		  ]
		}];
		if(_hasPermission(user, 'data-export')){
			chart.exporting.menu.items[0].menu.push({ "type": "csv", "label": "Data (CSV)" });
			
		}
		chart.exporting.filePrefix="Marketproof Pro - Market Activity";
		this.chart = chart;
  }
  
  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'building_slugs');
	 window.open(buildingURL);
	 //this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _handleBuildingOpen(building) {
	 let baseUrl = window.location.href.split('?')[0];
	window.open(`${baseUrl}?building=${urlSlugBuilding(building)}`);
  }
  
  _getDetailQuery = () => {
	  	const { dataStart, dataEnd, selectedDate, eventTypes, gap, soldTimeField, unitStages, chartType, chartView} = this.state;
    
    	const buildingStart = selectedDate ? moment(selectedDate).add(defaultGap == 'month' ? 0 : 0, 'day').add(isDST ? 0 : 0, 'hour').toDate().toISOString() : dataStart;
		const buildingEnd = selectedDate ? gap === '+1MONTH' ? moment(selectedDate).add(1, 'month').add(isDST ? 0 : 0, 'hour').toDate().toISOString()  : moment(selectedDate).add(1, 'week').add(0, 'day').add(isDST ? -1 : 0, 'hour').toDate().toISOString() : dataEnd;
		
		
		let stageQuery = '';
		if(unitStages && unitStages.length>0){
			if(unitStages.indexOf("all")<0){
				if(unitStages.indexOf("resale")>=0){
					stageQuery = ` AND event_tags:("event-stage_${[...["unknown"], ...unitStages].join("\" OR \"event-stage_")}")`;
				}else{
					stageQuery = ` AND event_tags:("event-stage_${unitStages.join("\" OR \"event-stage_")}")`;
				}
				
			}
			
		}
		
		let eventQ = '';
		
		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._activityFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							eventQ += ` AND (${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						eventQ += eventQ.length > 0 ? " AND " : "";
						eventQ += `(${searchKey}:(${qVal}))`;
					}else{
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						if(qVal!==null){
							eventQ += ` AND (${key}:${qVal})`;
						}
					}
					
				}
			});
		}
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._activityFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this._activityFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						dateReplaceKeys = [...dateReplaceKeys, ...dateArrayFilter[0].search_key];					
						if(dateQ){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._activityFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					eventQ +=  " AND ";
					eventQ += '(';
					eventQ += priceQ[filterKey];
					eventQ += ')';	
				}
				
				
			})
					
		}
		if(dateQ){
			dateReplaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				dateQ = dateQ.replace(regex, '*')
			
			})
			eventQ += eventQ.length > 0 ? " AND " : "";
			eventQ += '(';
			eventQ += dateQ;
			eventQ += ')';			
		}
		/****Price& Date query***/
		
		/****Price query***/
/*
		let priceQ = '';
		let replaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._activityFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						replaceKeys = [...replaceKeys, ...qArrayFilter[0].search_key];					
						if(priceQ){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ = priceQ.replace(regex, subFilterVal)
						}else{
							priceQ = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
						}											
					}										
				}
			});		
		}	
		if(priceQ){
			replaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				if(key == "min_price"){
					priceQ = priceQ.replace(regex, '0')
				}else{
					priceQ = priceQ.replace(regex, '*')
				}

			
			})
			eventQ += " AND ";
			eventQ += '(';
			eventQ += priceQ;
			eventQ += ')';			
		}
*/
		/****Price query***/
		
		let eventTypeQuery = "";
		
		if(chartView == 'property'){
			if(chartType == "contracts"){
				eventTypeQuery = `(topic:listings AND event_tags:contract-signed AND -event_tags:rent) AND `;
			}else if(chartType == 'listings'){
				eventTypeQuery = `(topic:listings AND event_tags:contract-signed AND -event_tags:rent) AND `;
			}else if(chartType == 'closings'){
				eventTypeQuery = `topic:transactions AND event_tags:recorded-sale) AND `;
			}else if(chartType == 'price-decreases'){
				eventTypeQuery = `(topic:listings AND event_tags:price-decrease AND -event_tags:price-change_off-market AND -event_tags:rent) AND `;
			}else if(chartType == 'price-increases'){
				eventTypeQuery = `(topic:listings AND event_tags:price-increase AND -event_tags:price-change_off-market AND -event_tags:rent) AND `;
			}
		}
				
		const detailQuery = eventTypes.filter(type => type !== "").map(type => {
			
			if(chartView == 'event'){
				switch (type) {
					case "Listings" : 
						return `(topic:listings AND event_tags:just-listed AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial*${stageQuery}${eventQ})`;
						break;
					case "Contracts" : 
						return `(topic:listings AND event_tags:contract-signed AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial*${stageQuery}${eventQ})`;
						break;
					case "Sales" : 
						return `(topic:transactions AND event_tags:recorded-sale AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
						break;
					case "Price Drops" : 
						return `(topic:listings AND event_tags:price-decrease AND -event_tags:price-change_off-market AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial*${stageQuery}${eventQ})`;
						break;
					case "Price Hikes" : 
						return `(topic:listings AND event_tags:price-increase AND -event_tags:price-change_off-market AND -event_tags:rent AND time:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial*${stageQuery}${eventQ})`;
						break;
					
				}
			}else{
				switch (type) {
					case "New Dev" : 
						return `(${eventTypeQuery}event_tags:(event-stage_sponsor) AND -address_2_normalized:"" AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
						break;
					case "Resale Condo" : 
						return `(${eventTypeQuery}event_tags:(event-stage_unknown event-stage_resale) AND -address_2_normalized:"" AND event_tags:(condo* !condo*p) AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}] AND -address_2_normalized:"" AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
						break;
					case "Resale Co-op" : 
						return `(${eventTypeQuery}event_tags:(event-stage_unknown event-stage_resale) AND -address_2_normalized:"" AND event_tags:(co?op* coop* condo*p) AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}] AND -address_2_normalized:"" AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
						break;
					case "Single-Family" : 
						return `(${eventTypeQuery}event_tags:(single?family) AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial* AND  -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
						break;
					case "Multi-Family" : 
						return `(${eventTypeQuery}event_tags:(multi?family) AND ${soldTimeField}:[${buildingStart} TO ${buildingEnd}] AND -event_tags:*commercial* AND  -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
						break;
				}
			}
				
		}).filter(f => f && f != "").join(" OR ");
		console.log(detailQuery, "detailQuery")
		return detailQuery ? detailQuery : 'id:none';
	  
  }
  
  getIntervalName(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date).add(1, 'hour').format('MMM, YYYY');
	  }
	  
	  return moment(date).add(1, 'hour').format("M/D/YY")
	  
	  
  }
  
  getIntervalTime(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date, 'MMM, YYYY');
	  }
	 
	  return moment(date, "M/D/YY");  
	  
  }
 
	
	_getEvents = async (resetPage) => {
		this.q = this.defaultQ;
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, buildingStages, unitStages } = this.state;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: resetPage, showBottomLoader:true, groupedData: false }));
		}
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		
		let bldSearchCount = searchTags.filter(item => item.type === 'building').length;
		
		let isBuildingQ = bldSearchCount > 0 && bldSearchCount === searchTags.length;
		

		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}

		if (!isBuildingQ &&
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._buildingFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += `(${searchKey}:(${qVal}))`;
					}else{
							
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						if(qVal!==null){
							this.q += this.q.length > 0 ? " AND " : "";
						    this.q += `(${key}:${qVal})`;
						}
						
						
					}
					
					const dateArrayFilter = this._buildingFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						let bldgDateQ = '';
						let dateReplaceKeys = [];
						
						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];					
							if(bldgDateQ){
								let regex = new RegExp(`{${key}}`, "g");
								bldgDateQ = bldgDateQ.replace(regex, moment(subFilterVal).toISOString())
							}else{		
								bldgDateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
								
							}					
							
							if(bldgDateQ){
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									bldgDateQ = bldgDateQ.replace(regex, '*')
								
								})
								this.q  += this.q .length > 0 ? " AND " : "";
								this.q  += '(';
								this.q  += bldgDateQ;
								this.q  += ')';			
							}	
							
						})
											
						console.log("priceQ", bldgDateQ, dateReplaceKeys)
					}
					
				}
			});
		}
		
		this.property_q = '';
		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._propertyFilter.filter(filter => filter.search_key === key);
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.property_q += this.property_q.length > 0 ? " AND " : "";
							this.property_q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.property_q += this.property_q.length > 0 ? " AND " : "";
						this.property_q += `(${searchKey}:(${qVal}))`;
					}else{ 
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;

						if(qVal!==null){
							this.property_q += this.property_q.length > 0 ? " AND " : "";
						    this.property_q += `(${key}:${qVal})`;
						}
					}
					
				}
			});
		}

		
		
		
		
		if(buildingStages && buildingStages.length>0){
			if(buildingStages.indexOf("all")<0){
				this.q += this.q.length > 0 ? " AND " : "";
				this.q += `building_status:("${buildingStages.join("\" OR \"")}")`;

			}
			
		}
		
		let eventQ = '';
		
		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._activityFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							eventQ += eventQ.length > 0 ? " AND " : "";
							eventQ += ` AND (${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						eventQ += eventQ.length > 0 ? " AND " : "";
						eventQ += `(${searchKey}:(${qVal}))`;
					}else{
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						if(qVal!==null){
							eventQ += ` AND (${key}:${qVal})`;
						}
					}

					
				}
			});
		}
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._activityFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this._activityFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						let dateQ = '';
						let dateReplaceKeys = [];
						
						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];					
							if(dateQ){
								let regex = new RegExp(`{${key}}`, "g");
								dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
							}else{		
								dateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
								
							}					
							
							if(dateQ){
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									dateQ = dateQ.replace(regex, '*')
								
								})
								eventQ += eventQ.length > 0 ? " AND " : "";
								eventQ += '(';
								eventQ += dateQ;
								eventQ += ')';			
							}	
							
						})
											
						
					}											
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._activityFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					eventQ +=  " AND " ;
					eventQ += '(';
					eventQ += priceQ[filterKey];
					eventQ += ')';	
				}
				
				
			})
					
		}
		
		/****Price& Date query***/
		/****Price query***/
/*
		let priceQ = '';
		let replaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._activityFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						replaceKeys = [...replaceKeys, ...qArrayFilter[0].search_key];					
						if(priceQ){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ = priceQ.replace(regex, subFilterVal)
						}else{
							priceQ = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
						}											
					}										
				}
			});		
		}	
		if(priceQ){
			replaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				priceQ = priceQ.replace(regex, '*')
			
			})
			eventQ += " AND ";
			eventQ += '(';
			eventQ += priceQ;
			eventQ += ')';			
		}
*/
		/****Price query***/
		
		
		
		
		setURLFromState(this.props, this.state);
		
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		
		
		this._building_q = this.q;
		
		let sort = null;
		if(this.state.orderBy && this.state.orderBy.length > 0){			
				sort = `${this.state.orderBy} ${this.state.order}`;			
		}
		
		
		this.fq = [..._activityFQ.slice(), ...[`{!join from=alternate_keys fromIndex=we3-buildings to=building}${this.q}`]];	
		
		
		
		if(this.property_q){
			this.property_q = `{!join from=key fromIndex=we3-properties to=property}${this.property_q} AND deleted:false`;
			this.fq.push(this.property_q);
		}
		
		if(unitStages && unitStages.length>0){
			if(unitStages.indexOf("all")<0){
				
				if(unitStages.indexOf("resale")>=0){
					this.fq.push(`event_tags:("event-stage_${[...["unknown"], ...unitStages].join("\" OR \"event-stage_")}")`);
				}else{
					this.fq.push(`event_tags:("event-stage_${unitStages.join("\" OR \"event-stage_")}")`);
				}
				
				//fq.push(`{!join from=key fromIndex=we3-properties to=property}deleted:false AND source_organizations:(NYAG CDEC ADEC)`);
			}
			
		}
		
		
		
		const buildingStart = selectedDate ? selectedDate : dataStart;
		const buildingEnd = selectedDate ? gap === '+1MONTH' ? moment(selectedDate).add(1, 'month').toDate().toISOString()  : moment(selectedDate).add(1, 'week').toDate().toISOString() : dataEnd;
	
					
		const buildingQuery = this._getDetailQuery();

		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: this.fq,
				wt: "json",
				rows: 0,
				"json.facet": {
					"new_listings": {
						"type": "query",
						"q": `topic:listings AND event_tags:just-listed AND -event_tags:rent AND -event_tags:*commercial*${eventQ}`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
										"buildings" : "unique(building)",
										"newdev" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_sponsor) AND -address_2_normalized:\"\""
										},
										"resale_condo" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(condo* !condo*p) AND -address_2_normalized:\"\""
										},
										"resale_coop" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(co?op* coop* condo*p) AND -address_2_normalized:\"\""
										},
										"single_family" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(single?family)"
										},
										"multi_family" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(multi?family)"
										}
									}
								}
							},
						}
					},
					"contract_signed": {
						"type": "query",
						"q": `topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -event_tags:*commercial*${eventQ}`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
										"buildings" : "unique(building)",
										"newdev" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_sponsor) AND -address_2_normalized:\"\""
										},
										"resale_condo" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(condo* !condo*p) AND -address_2_normalized:\"\""
										},
										"resale_coop" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(co?op* coop* condo*p) AND -address_2_normalized:\"\""
										},
										"single_family" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(single?family)"
										},
										"multi_family" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(multi?family)"
										}
									}
								}
							}
						}
					},
					"recorded_sold": {
						"type": "query",
						"q": `topic:transactions AND event_tags:recorded-sale AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${eventQ}`,
						"facet": {
							"by_time": {
								"range": {
									"field": soldTimeField,
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
										"buildings" : "unique(building)",
										"newdev" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_sponsor) AND -address_2_normalized:\"\""
										},
										"resale_condo" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(condo* !condo*p) AND -address_2_normalized:\"\""
										},
										"resale_coop" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(co?op* coop* condo*p) AND -address_2_normalized:\"\""
										},
										"single_family" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(single?family)"
										},
										"multi_family" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_unknown event-stage_resale) AND event_tags:(multi?family)"
										}
									}
								}
							}
						}
					},
					"price_decrease": {
						"type": "query",
						"q": `topic:listings AND event_tags:price-decrease AND -event_tags:price-change_off-market AND -event_tags:rent AND -event_tags:*commercial*${eventQ}`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
										"buildings" : "unique(building)",
										"newdev" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_sponsor) AND -address_2_normalized:\"\""
										},
										"resale_condo" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor AND event_tags:(condo* !condo*p) AND -address_2_normalized:\"\""
										},
										"resale_coop" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor AND event_tags:(co?op* coop* condo*p) AND -address_2_normalized:\"\""
										},
										"single_family" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor AND event_tags:(single?family)"
										},
										"multi_family" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor AND event_tags:(multi?family)"
										}
									}
								}
							}
						}
					},
					"price_increase": {
						"type": "query",
						"q": `topic:listings AND event_tags:price-increase AND -event_tags:price-change_off-market AND -event_tags:rent AND -event_tags:*commercial*${eventQ}`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
										"buildings" : "unique(building)",
										"newdev" : {
											"type" : "query",
											"q" : "event_tags:(event-stage_sponsor) AND -address_2_normalized:\"\""
										},
										"resale_condo" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor  AND event_tags:(condo* !condo*p) AND -address_2_normalized:\"\""
										},
										"resale_coop" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor  AND event_tags:(co?op* coop* condo*p) AND -address_2_normalized:\"\""
										},
										"single_family" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor  AND event_tags:(single?family)"
										},
										"multi_family" : {
											"type" : "query",
											"q" : "-event_tags:event-stage_sponsor  AND event_tags:(multi?family)"
										}
									}
								}
							}
						}
					},
					"by_building": {
						"type" : "query",
						"q" : `${buildingQuery}`,
						"facet" : {
							"unique_buildings" : "unique(building)",
							"buildings" : {
								"type": "terms",
								"field": "building",
								"mincount": 0,
								"sort" : sort,
								"offset": resetPage ? 0 : this.state.start,
								"limit": this.state.rowsPerPage
							}
							
						}
						
					}
				}
			}
		};

		let res = await _axiosCall(axiosData)
			.then(res => {
				
				return res;

			})
			.catch(error => {
				console.log("error: " + error);
			});
			
			let chartData = [];
			this._matchedBuildingIds = [];		
	
			if(res && res.facets){
				let facetData = {};
				
				
				if(res.facets.by_building && res.facets.by_building.buildings && res.facets.by_building.buildings.buckets){
					const formattedBuildings = res.facets.by_building.buildings.buckets.map(bucket => {
						this._matchedBuildingIds.push(bucket.val);
						return bucket;
					})
							
					this._getBuildings(this._matchedBuildingIds, formattedBuildings);
					if(res.facets.new_listings && res.facets.new_listings.by_time){
							
						chartData = res.facets.new_listings.by_time.buckets.map(bucket => {
							const key = this.getIntervalName(bucket.val);
							return { time: key, 
								new_listings: bucket.count, 
								new_listings_newdev: bucket.newdev ? bucket.newdev.count : 0,
								new_listings_condo: bucket.resale_condo ? bucket.resale_condo.count : 0,
								new_listings_coop: bucket.resale_coop ? bucket.resale_coop.count : 0,
								new_listings_single_family: bucket.single_family ? bucket.single_family.count : 0,
								new_listings_multi_family: bucket.multi_family ? bucket.multi_family.count : 0,}
						})
						
					}
					
					if(res.facets.contract_signed && res.facets.contract_signed.by_time){
						
						res.facets.contract_signed.by_time.buckets.forEach(bucket => {
							const key = this.getIntervalName(bucket.val);								
							let existingItem = chartData.filter(item => item.time === key);
							if(existingItem && existingItem[0]){
								existingItem[0].contract_signed = bucket.count;
								existingItem[0].contract_signed_newdev = bucket.newdev ? bucket.newdev.count : 0;
								existingItem[0].contract_signed_condo = bucket.resale_condo ? bucket.resale_condo.count : 0;
								existingItem[0].contract_signed_coop = bucket.resale_coop ? bucket.resale_coop.count : 0;
								existingItem[0].contract_signed_single_family = bucket.single_family ? bucket.single_family.count : 0;
								existingItem[0].contract_signed_multi_family = bucket.multi_family ? bucket.multi_family.count : 0;
							}
						})
						
					}
					
					if(res.facets.recorded_sold && res.facets.recorded_sold.by_time){
						
						res.facets.recorded_sold.by_time.buckets.forEach(bucket => {
							const key = this.getIntervalName(bucket.val);								
							let existingItem = chartData.filter(item => item.time === key);
							if(existingItem && existingItem[0]){
								existingItem[0].recorded_sold = bucket.count;
								existingItem[0].recorded_sold_newdev = bucket.newdev ? bucket.newdev.count : 0;
								existingItem[0].recorded_sold_condo = bucket.resale_condo ? bucket.resale_condo.count : 0;
								existingItem[0].recorded_sold_coop = bucket.resale_coop ? bucket.resale_coop.count : 0;
								existingItem[0].recorded_sold_single_family = bucket.single_family ? bucket.single_family.count : 0;
								existingItem[0].recorded_sold_multi_family = bucket.multi_family ? bucket.multi_family.count : 0;
							}
						})
						
					}
					
					if(res.facets.price_decrease && res.facets.price_decrease.by_time){
						
						res.facets.price_decrease.by_time.buckets.forEach(bucket => {
							const key = this.getIntervalName(bucket.val);								
							let existingItem = chartData.filter(item => item.time === key);
							if(existingItem && existingItem[0]){
								existingItem[0].price_decrease = bucket.count;
								existingItem[0].price_decrease_newdev = bucket.newdev ? bucket.newdev.count : 0;
								existingItem[0].price_decrease_condo = bucket.resale_condo ? bucket.resale_condo.count : 0;
								existingItem[0].price_decrease_coop = bucket.resale_coop ? bucket.resale_coop.count : 0;
								existingItem[0].price_decrease_single_family = bucket.single_family ? bucket.single_family.count : 0;
								existingItem[0].price_decrease_multi_family = bucket.multi_family ? bucket.multi_family.count : 0;
							}
						})
						
					}
					
					if(res.facets.price_increase && res.facets.price_increase.by_time){
						
						res.facets.price_increase.by_time.buckets.forEach(bucket => {
							const key = this.getIntervalName(bucket.val);								
							let existingItem = chartData.filter(item => item.time === key);
							if(existingItem && existingItem[0]){
								existingItem[0].price_increase = bucket.count;
								existingItem[0].price_increase_newdev = bucket.newdev ? bucket.newdev.count : 0;
								existingItem[0].price_increase_condo = bucket.resale_condo ? bucket.resale_condo.count : 0;
								existingItem[0].price_increase_coop = bucket.resale_coop ? bucket.resale_coop.count : 0;
								existingItem[0].price_increase_single_family = bucket.single_family ? bucket.single_family.count : 0;
								existingItem[0].price_increase_multi_family = bucket.multi_family ? bucket.multi_family.count : 0;
							}
						})
						
					}
					
					
					if(resetPage){
						this.buildChart(chartData);
					}
						
					this.setState(prevState => ({
						fetchInProgress: false,
						//buildingsFetchInProgress: false,
						//groupedData:  formattedDataBuilding,
						groupedCount: res.facets.by_building && res.facets.by_building.unique_buildings ? res.facets.by_building.unique_buildings : 0, //response.building.ngroups,	
						totalCount: res.facets.new_listings && res.facets.new_listings.count ? res.facets.new_listings.count : 0,
						buildingEventCount: res.facets.by_building && res.facets.by_building.count ? res.facets.by_building.count : 0,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page,
						chartData: chartData,
					}));
					
				}else{
					if(resetPage){
						this.buildChart(chartData);
					}
					this.setState(prevState => ({
						fetchInProgress: false,
						buildingsFetchInProgress: false,
						showBottomLoader:false,
						groupedData:  [],
						groupedCount: res.facets.by_building && res.facets.by_building.unique_buildings ? res.facets.by_building.unique_buildings : 0, //response.building.ngroups,	
						totalCount: res.facets.new_listings && res.facets.new_listings.count ? res.facets.new_listings.count : 0,
						buildingEventCount: res.facets.by_building && res.facets.by_building.count ? res.facets.by_building.count : 0,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page,
						chartData: chartData,
					}));
					
				}
				
				
			}

		// console.log(formattedData)
		//return formattedData;
	};
	
	 
  
  _getBuildings = async (_matchedBuildingIds, formattedBuildings) => {
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"alternate_keys:("+_matchedBuildingIds.join(" ")+")",
			"deleted:false"			
		];

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: buildingFL,
				wt: "json",
				rows: _matchedBuildingIds.length
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
			
		
		const groupedData = formattedBuildings.map(bucket => {
			bucket['building'] = formattedData.filter(building => (building.key == bucket.val || (building.alternate_keys && building.alternate_keys.indexOf(bucket.val)>=0)))[0];
			return bucket;
		})	
		
		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			groupedData:  groupedData,
			buildingData: formattedData,
			showBottomLoader: false
		}));
		
	
	};
	
	_formatStats = (response, redoHiddenFields = false) => {

		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		
		if(newStages.length === 0 ) newStages = ['all'];
		
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	_setUnitStage = (stage) => {
	
		let newStages = this.state.unitStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultUnitStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = defaultUnitStage;
		this.setState({
			unitStages: newStages.filter(s => s !==""),
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = !this.props.buildingView ? Object.assign({}, defaultSubFilter) : {};
		this.setState({
			buildingStages : !this.props.buildingView ? defaultStage : false,
			unitStages : defaultUnitStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, unitStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}
	_resetActive = () => {

		let	isDefault = this.props.searchTags.length===0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultUnitStage, this.state.unitStages) &&
			_objectEqual(defaultStage, this.state.buildingStages);

	    return !isDefault;
		
	}	
		
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	_buildingToggleView = (e) => {
		console.log(e.target.checked, "checking")
		this.setState({ viewType: e.target.checked ? "list" : "building" });
	  };
	_toggleView = () => {
		const { startDate } = this.state;
		
		const isStartDST = moment(dateStart, 'MM/DD/YYYY').isDST();
		if(this.state.gap === '+7DAY'){		
			
			this.setState( { gap : '+1MONTH', selectedDate: false, 
				startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf('month').add(0, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
				dataStart:isMobile 
				? moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf('month').toDate().toISOString() 
				: moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf('month').toDate().toISOString(),
				dataEnd:moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString()} )
				setLocalStorage(lsPre, 'gap', '+1MONTH');
		}else{
			this.setState( { gap : '+7DAY', selectedDate: false, 
				startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf('week').add(1, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
				dataStart:isMobile 
				? moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf('week').add(1, 'day').toDate().toISOString() 
				: moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf('week').add(1, 'day').toDate().toISOString(),
				dataEnd:moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString() } )
				setLocalStorage(lsPre, 'gap', '+7DAY');

		}
		
	}
	
	
	_toggleListView = (type) => {
		this.setState({viewType:type})
		
	}
	
	_toggleTime = () => {
		
		if(this.state.soldTimeField === 'event_time'){
			this.setState( { soldTimeField : 'time', selectedDate: false } )
			setLocalStorage(lsPre, 'soldTimeField', 'time');
		}else{
			this.setState( { soldTimeField : 'event_time', selectedDate: false } )
			setLocalStorage(lsPre, 'soldTimeField', 'event_time');
		}
		
	}
	
	_setChartType = (type) => {
		this.setState({chartType:type})
	}
	
	_toggleChartView = () => {
		
		const { chartView } = this.state;
		
		if(chartView == 'event'){
			this.setState({
				chartView:'property',
				unitStages: ["all"],
				start: 0,
				end: this.state.rowPerPage
			});	
		}else{
			this.setState({chartView:'event'})
		}
	
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, gap, showBottomLoader, unitStages, viewType, chartType, chartView
    } = this.state;
    
    const { searchTags, user, classes } = this.props;
    
    let companyShow = { name:"Sales Agents", type:"marketing_company"};
    
    const attributeTag = searchTags.filter(item => ['building', 'neighborhood', 'borough'].indexOf(item.type) < 0);
    
    if(attributeTag && attributeTag.length>0){
	    if(attributeTag[0].type === 'developers'){
		    companyShow = { name:"Developer", type:"developers"};
	    }else if(attributeTag[0].type === 'architect'){
		    companyShow = { name:"Architect", type:"architect"};
	    }
    }
    
    
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;
    
   	
	const buildingQuery = this._getDetailQuery();
	
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >

            <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={_hasPermission(user, 'admin-filters') ?  this._unitSubFilter : this._unitSubFilter.filter(item => !item.admin_only)}   
            	placeHolder={_hasPermission(user, 'search-firms') ? false : "Borough, Neighborhood or Building"}
            	buildingAttributes={_hasPermission(user, 'search-firms') ? false : []}   
            	{...this.props}  
            	groupedView   
            	buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	setSubFilter={this._setSubFilter}	
            	numToShow={5}
				UnitStageFilter={<UnitStageFilter
							unitStages={unitStages}
							setUnitStage={this._setUnitStage}
							customToolTip={{
								  'sponsor' : 'Units owned by the Sponsor at the time of the market event.',
								  'resale' : ' Units that have been sold by the Sponsor at the time of the market event.',
								  'unverified' : 'Units that have not been verified at the time of the market event.',
								  'all' : 'All sponsor, resale and unverified units.',	  
							}}
						/> }
				setSubFilters={this._setSubFilters}
				resetActive={this._resetActive()}
				showExport
				q={'*:*'}
		  		fq={[...this.fq, ...[`${buildingQuery}`], ...["hierarchy:[0 TO 1]"]]}
		  		fl={"display_full_street_address, building_name, address_2_normalized, markets_major, markets_sub, neighborhood, borough, city, zip, time, event_time, price, event_tags, bedrooms, baths, sq_ft".split(", ")}	     	
          		core_name={'we3-activities-3'}
          		sort={"time desc"}
				export_name={`${this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"} Market Activity - ${selectedDate ? this.getIntervalName(selectedDate) : this.getIntervalName(dataStart) + ' - ' + this.getIntervalName(dataEnd)}`} 
				title={'Export Latest Market Activity (CSV)'}  
          		description={<Fragment><h4><strong>Export the latest market activity for your own analysis.</strong></h4> <br/>   The export will include details about activty type, date, price, bed, bath, size and more.</Fragment>}           	

             />
             {/*<div className="material-table__header-info">
            	<Row>
            		
            		<Col md={12} lg={12}>
             
		             <div className={`unit_toggle_filter`}>
						<UnitStageFilter
							unitStages={unitStages}
							setUnitStage={this._setUnitStage}
							customToolTip={{
								  'sponsor' : 'Units owned by the Sponsor at the time of the market event.',
								  'resale' : ' Units that have been sold by the Sponsor at the time of the market event.',
								  'unverified' : 'Units that have not been verified at the time of the market event.',
								  'all' : 'All sponsor, resale and unverified units.',	  
							}}
						/>  
					  </div>
					</Col>
				</Row>
			</div>*/}	
			
             <div style={{height:10,padding:"0 10",margin:10,clear:"both"}} >
              {fetchInProgress && this.state.groupedView && <LinearLoading /> }
             </div>
            
            <div className="material-table__header-info">
            	<Row>
            		
            		<Col md={12} lg={12}>
		            	<h5 className="bold-text mb-3">
		            	<div className={"mobile__show"}>
		            		{this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"} {` `}
		            		{eventTypes.length===1 
			            	? eventTypes[0] : eventTypes.length===2 
			            	?  eventTypes.join(" and ") : 'Market'
			            	} Activity <br/>
							{ selectedDate ? 
							 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							{` `}<span className="small-text light-text">(Max Range: Jan, 2015 - Present)</span>
							 
		            	</div>
		            	<span className={"desktop__show"}>
		            		{this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"} {` `}
		            		{eventTypes.length===1 
			            	? eventTypes[0] : eventTypes.length===2 
			            	?  eventTypes.join(" and ") : 'Market'
			            	} Activity - {` `}
		            		{ selectedDate ? 
							 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							{` `}<span className="small-text light-text">(Max Range: Jan, 2015 - Present)</span>
						</span>	 
						
		            	</h5>
		            	
					</Col>
					
					{/*!this.props.buildingView &&
					<Col md={6} lg={6} style={{ textAlign:"right" }}>
		            	<span>
						  
						  <BuildingIcon color="#DBE0E8" />
				          <Tooltip title={`${this.state.groupedView ? "Expand to Unit View" : "Condense to Building View"}`}>
					        <PurpleSwitch 
					            checked={!this.state.groupedView}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>					      	
					      <ListIcon color="#DBE0E8" />				       
					    </span>
			    	</Col>
			    	*/}
            	</Row>
            </div>
            <div className="material-table__header-info">
				{console.log(this.state.viewType, "viewType")}
            	<Row>	
					<Col md={12} lg={12} xl={12} sm={12} style={{ textAlign:"left" }}>
		            	<span>						  
						  
						  {/*<WeeklyIcon color="#DBE0E8" />	*/}
						  <span>Weekly</span>
				          <Tooltip title={`${this.state.gap === '+7DAY' ? "Change to Monthly Chart" : "Change to Weekly Chart"}`}>
					        <PurpleSwitch 
					            checked={this.state.gap !== '+7DAY'}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>
					       <span>Monthly</span>	
					      {/*<MonthlyIcon color="#DBE0E8" />*/}				      						      				       
					    </span>
					    <span style={{margin:"0 10px", color:"#D1D6E0", textAlign: "center"}}> | </span>
					    <span>						  
						  {/*/<ClosedDateIcon color="#DBE0E8" />*/}
						  <span>Closed Date</span>
				          <Tooltip title={`${this.state.soldTimeField === 'event_time' ? "Change Sold Activity to Recorded Date" : "Change Sold Activity to Closed Date"}`}>
					        <PurpleSwitch 
					            checked={this.state.soldTimeField !== 'event_time'}
					        	onChange={this._toggleTime}
					        />
					      </Tooltip>
					      <span>Recorded Date</span>					      	
					     {/* <ReportedDateIcon color="#DBE0E8" />	*/}		
						 <span style={{margin:"0 10px", color:"#D1D6E0", textAlign: "center"}}> | </span>
						 <span>						  
						  <span>Type View</span>
						   <Tooltip title={`${this.state.chartView === 'property' ? "Change Chart to Event View and organize by listings, contracts and closing" : "Change Chart to Property Type View to segment by new dev and resales"}`}>
							 <PurpleSwitch 
								 checked={this.state.chartView !== 'property'}
								 onChange={this._toggleChartView}
							 />
						   </Tooltip>
						   <span>Event View</span>					      	
						 </span>	       
					    </span>
						
			    	</Col>
			    	
            	</Row>
				{this.state.chartView == 'property' && <ChartType chartType={chartType} setChartType={this._setChartType} />}
						  	
            </div>


			<div id="chartdiv" style={{ width: "100%", height: "500px", marginTop:"30px", opacity: fetchInProgress ? .5 : 1 }}></div>

			
			
			{!_hasPermission(user, 'market-activity-detail') ? 
				<div style={{marginTop:50}}>
					<EnterpriseGate 
						copy={<span>Access to Market Activity Details requires an upgrade to your subscription.</span>}
						img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-market-activity-detail.png`}
						upgrade={true}
						user={user}
						nextPlan={"advanced"}
					/>
					
				</div>
				
			:
            <Fragment>
		            {(showBottomLoader || buildingsFetchInProgress) && <LinearLoading />}
		            
		            
		            <div className="material-table__wrap">
		              
		              
		              {!buildingsFetchInProgress && !fetchInProgress && this.state.groupedView && groupedData && 
			              
			         
			          
			           <div className="material-table expansion__table">
			           		 <hr/>
			           		 <Col >
			           		<Row>
			           			<Col >
					           		<div className="material-table__header-info pb-3 desktop__show">
										<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
										{chartView == "property" ? _capitalizeText(chartType) : eventTypes.length===1 ? eventTypes[0] : 'Events'} 
										
										{groupedCount ? <Fragment> Across <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings </Fragment> : ''}
										| Date Range: { selectedDate ? 
											 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
											 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
											 </Fragment>
											:
											 <Fragment> 
											 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
											 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
											</Fragment>
										}	
									</div>
									<div className="material-table__header-info pb-3 mobile__show">
										<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
										{eventTypes.length===1 ? eventTypes[0] : 'Events'}  
										
										{groupedCount ? <Fragment> Across <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings </Fragment> : ''}
										<br/> Date Range: { selectedDate ? 
											 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
											 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
											 </Fragment>
											:
											 <Fragment> 
											 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
											 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
											</Fragment>
										}	
									</div>
								</Col>
								<Col  className={"text-right"}>
									       <div style={{marginRight:"20px", marginTop:"-3px"}}>
                          <span style={{ color: "#5C6178" ,fontWeight:"300"}}>
                            {!isMobile ? "Building View" : "Bldgs"}
                          </span>
                          <Tooltip
                            title={`${
                              this.state.viewType == "building"
                                ? "Expand to See Unit List"
                                : "Condense to Group by Buildings"
                            }`}
                          >
                            <PurpleSwitch onChange={this._buildingToggleView}  checked={this.state.viewType ==="list"}/>
                          </Tooltip>
                          <span style={{ color: "#5C6178" , fontWeight:"300"}}>
                            {!isMobile ? "List View" : "Units"}
                          </span>
                        </div>
					           		{/* <div className={`material-table__header-info  pb-3 unit_building_toggle`} style={{marginTop:-10}}>								      
								       <ButtonGroup size="small" aria-label="small outlined button group">
									        	<Tooltip title={`See events grouped by buildings`}>
									        		<Button disabled={viewType=="building"} onClick={() => {this._toggleListView("building")}} startIcon={<GroupViewIcon size={14} color={viewType!="building" ? "#780F9E" : "#A3ADC2"} />}>Group</Button>
									        	</Tooltip>
									        	<Tooltip title={`See event details by units`}>
									        		<Button disabled={viewType=="list"} onClick={() => {this._toggleListView("list")}} startIcon={<ListViewIcon size={14} color={viewType!="list" ? "#780F9E" : "#A3ADC2"} />}>List</Button>
									        	</Tooltip>
									        	<Tooltip title={`See analytics about the events`}>
									        		<Button disabled={viewType=="map"} onClick={() => {this._toggleView("map")}} startIcon={<StatsViewIcon size={14} color={viewType!="map" ? "#780F9E" : "#A3ADC2"} />}>Stats</Button>
									        	</Tooltip>	  
										</ButtonGroup>			      	
								    </div>*/}

						             
					            </Col>
					          </Row>	
							 </Col>
			           	{viewType == "building" ?
				          <Fragment>	
			           	   <Container>
						        <Row className="expansion__summary__header">
									<Col lg={1} xs={12}>	                          
									  	<TableSortLabel
							                active={orderBy === "count"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "count")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Events
							              </TableSortLabel>
									</Col>
									 <Col lg={5} className="">	 
									 	Building
			                        	{/*<TableSortLabel
							                active={orderBy === "index"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "index")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Building
							              </TableSortLabel>  */}                       
									 	
									</Col>	

									{console.log(companyShow.name, "companyShow.name")}
									<Col lg={2} xs={12} className="no-padding">	
										{companyShow.name}                          
									  	{/*<TableSortLabel
							                active={orderBy === companyShow.type}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, companyShow.type)}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                {companyShow.name}
							              </TableSortLabel>*/}
									</Col>
									<Col lg={2} xs={12} className="no-padding">	
										Total Units                          
									  	{/*<TableSortLabel
							                active={orderBy === "inventory_total"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "inventory_total")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Total Units
							              </TableSortLabel>*/}
									</Col>
									{_hasPermission(this.props.user, 'sponsor-buildings') && <Col lg={2} xs={12} className="no-padding">
										Unsold Units	                          
									  	{/*<TableSortLabel
							                active={orderBy === "inventory_unsold"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "inventory_unsold")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Unsold Units
							              </TableSortLabel>*/}
									</Col>	}
									
									                     
			                       
									
			                    </Row>
			                </Container>
			              {groupedData.length ===0 &&		              
				            <Container>
						        <Row className="no__data">
									<Col lg={12} xs={12} className="no__data-text">			                          
									  	No data found for your search.
									</Col>	
								</Row>
							</Container>
			              }  	           
			              {groupedData.filter(item => item.building).map((d) => {
							console.log(groupedData)
				                const item = d
				                const numFound = item.count;
				                const isSelected = this.isSelected(item.val);
				                const building = item.building;
					            return(  			            
					              <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
							        <ExpansionPanelSummary
							          expandIcon={<ExpandMoreIcon />}
							          aria-label="Expand"
							          aria-controls=""
							          id={d.groupValue}
							        >
							        <Container>
								        <Row className="expansion__summary">
											<Col lg={1} xs={12} >			                          
											  	<Tooltip title={`See Market Activity - ${building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}`}>	   
													<IconButton	size={`small`} style={{background:"transparent"}} onClick={(e) => {e.stopPropagation();e.preventDefault(); this._handleBuildingOpen(building);}}> 			                          
											  			<Badge color="matching" style={{ width:50 }}><span className="bold-text">{_formatNumber(numFound)}</span> 
											  				<OpenNewIcon size={12} color={'#FFF'} style={{marginLeft:3,marginTop:-2}} />
											  			</Badge>
											  		</IconButton>
												</Tooltip>
											</Col>	
											<Col lg={5} xs={12} >			                          
											  <div className="expansion__header">									  									  	
											  		<span className="building_name"  onClick={event => this.handleGroupedBuildingClick(event, building)}>{building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}
								                	 <BuildingIcons building={building} />
											  		 
											  		</span>
						                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{building.neighborhood && building.neighborhood[0] ? `${_capitalizeText(building.neighborhood[0])}, ` : ''} {_capitalizeText(building.borough)}
						                            
						                            
						                            </span>
											  
											  </div>				          									  
											</Col>
											<Col lg={2} xs={12}  className="no-padding">			                          
											  <div className="expansion__header">									  									  	
											  		<span className="light-text small-text">{building[companyShow.type] ? _capitalizeText(building[companyShow.type]) : building.building_status==='sponsor' ? 'Not Available' : "Not Applicable"}</span>
											  </div>								  			          									  
											</Col>
											<Col lg={2} xs={12} >			                          
											  	<span className="light-text small-text">{_formatNumber(building.inventory_total)} Units</span>
											</Col>	
											{_hasPermission(this.props.user, 'sponsor-buildings') && <Col lg={2} xs={12}  className="no-padding">	
												{building.building_status==='resale' ? 
													<span className="light-text small-text">Not Applicable</span>
															                          
											  	: building.offering_construction==='conversion' 
					                          	 ?
					                          	 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total}  color={"grey"} />
					                          	 : _isHouse(building) ? <span className="light-text small-text">Not Applicable</span>
												 
												 :
								                 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total}  />
							                    } 
											</Col>	}
											
										             
					                       		                        			                        	
				                        </Row>
				                    </Container>
							        </ExpansionPanelSummary>
							        <ExpansionPanelDetails>				        
							        	<UnitsListTable 
						              		isSelected={this.isSelected}
						              		handleBuildingClick={this.handleBuildingClick}
						              		handleClick={this.handleClick}
						              		handleRequestSort={this.handleRequestSort}
						              		handleSelectAllClick={this.handleSelectAllClick}
						              		{...this.state} 
						              		{...this.props} 
						              		reactTableFiltered={false}	
						              		isGrouped
						              		q={'*:*'}
						              		fq={this.property_q ? [this.property_q, `${buildingQuery}`] : [`${buildingQuery}`]}
						              		fl={this.fl}	
						              		building={building}
						              		handleGroupedBuildingClick={this.handleGroupedBuildingClick}	
						              		buildingKey={building.key}              		
						              />
							        </ExpansionPanelDetails>
							     </ExpansionPanel>
							    )
							 })
			             }  
			              </Fragment>
		            
			              :	              
				              <Fragment>
				              <UnitsListTable 
				              		isSelected={this.isSelected}
				              		handleBuildingClick={this.handleBuildingClick}
				              		handleClick={this.handleClick}
				              		handleRequestSort={this.handleRequestSort}
				              		handleSelectAllClick={this.handleSelectAllClick}
				              		{...this.state} 
				              		{...this.props} 
				              		reactTableFiltered={false}	
				              		isGrouped
				              		q={'*:*'}
				              		fq={this.property_q ? [this.property_q, `${buildingQuery}`] : [`${buildingQuery}`]}
				              		fl={this.fl}	
				              />
				              <hr/>
				              </Fragment>     
			              }
			            </div>
			          }
		             
		            </div>
		            {viewType=="building" && paginationCount && groupedData ? <TablePagination
		              component="div"
		              className="material-table__pagination"
		              count={paginationCount}
		              rowsPerPage={rowsPerPage}
		              page={page}
		              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
		              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
		              onChangePage={this.handleChangePage}
		              onChangeRowsPerPage={this.handleChangeRowsPerPage}
		              rowsPerPageOptions={[25, 50, 100]}
		              dir="ltr"
		              SelectProps={{
		                inputProps: { 'aria-label': 'rows per page' },
		                native: true,
		              }}
		            /> : null}
			</Fragment>
			}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
	  user: state.user, 
	  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UnitsSearchTable));