import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import AlertIcon from 'mdi-react/InformationOutlineIcon';
import UnitIcons from '../../../shared/components/UnitIcons';
import AddCollectionButton from '../../../shared/components/collections/AddCollectionButton';
import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import ArrowEqualIcon from 'mdi-react/MinusCircleOutlineIcon';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_formatUnitNumber
} from "../../../shared/helpers/utils";

import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';

class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 20,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
		selectedUnits: {}
	};

	componentDidMount() {
		
		if(this.props.buildingKey){
			this.queryUnits();
		}
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(this.props.buildingKey){
		  if(!_objectEqual(this.props.q, prevProps.q) ||
		  (this.props.buildingKey !== prevProps.buildingKey ||
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start)){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 this.queryUnits(reset);
		  }		 
		}
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};
	

	
	queryUnits = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const {buildingKey, q, fq, fl } = this.props;	
		const { orderBy, order } = this.state;
		
		const isContract = this.props.subFilters.offering_status && this.props.subFilters.offering_status === 'in-contract';
		
		let sort = "floor asc, address_2_normalized asc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			if(orderBy === 'address_2_normalized'){
				sort = `floor ${order}, ${orderBy} ${order}`;
			}else if(orderBy === 'offering_price'){
				sort = `last_listed_sale_price_all ${order}, ${orderBy} ${order}`;
			}else if(orderBy === 'last_sold_date'){
				if(isContract){
					sort = `last_contract_date ${order}`;
				}else{
					sort = `${orderBy} ${order}, last_contract_date ${order}`;
				}
			}else{
				sort = `${orderBy} ${order}`;
			}
			
		}
		
		const unitsFq = [...fq, `building:${buildingKey}`];			
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=60",
			method: "post",
			query: {
				q: q,
				fq: unitsFq,
				sort: sort,
				fl,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	_selectUnit(property){
		const { selectedUnits } = this.state;
		const { handleSelectParent } = this.props;
		const newSelected = Object.assign({}, selectedUnits);
		
		if(newSelected[property.key]){
			delete newSelected[property.key];			
		}else{
			newSelected[property.key] = property;
		}
		handleSelectParent(property);
		
		this.setState( { selectedUnits: Object.assign({}, newSelected) } );
	}
	
	_handleSelectAllClick(){
		const { selectedUnits, unitListData } = this.state;
		const { handleSelectParentAll } = this.props;
		const { reactTableFiltered, groupedView, buildingKey } = this.props;
		let newSelected = {}
		if(groupedView || buildingKey){
			if(Object.keys(selectedUnits).length < unitListData.length){
				unitListData.forEach(item => {
					newSelected[item.key] = item;
				})
				handleSelectParentAll(unitListData, "add");
			}else{
				handleSelectParentAll(unitListData, "remove");
			}
		}else if(!groupedView){
			if(Object.keys(selectedUnits).length < reactTableFiltered.length){
				reactTableFiltered.forEach(item => {
					newSelected[item.key] = item;
				})
				handleSelectParentAll(reactTableFiltered, "add");
			}else{
				handleSelectParentAll(reactTableFiltered, "remove");
			}
		}
		
		
		this.setState( { selectedUnits: newSelected } );
		
	}
	
	render(){
		const { reactTableFiltered, handleBuildingClick, isSelected, handleClick, order, orderBy, selected, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, building, selectedUnitsAll} = this.props;
		
		const { selectedUnits } = this.state;
		if(!reactTableFiltered && (this.state.start===0 && (this.state.unitListData.length===0 || this.props.fetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const data = reactTableFiltered || this.state.unitListData;
		
		
		if(building && (building.offering_verified_status != 'active' &&  parseInt(moment(building.cdec_date).format('YYYY')) <= 1970) && data.length <= 0 ){
			
			return (
			  <Col md={12}>
			    <Card>
			      <CardBody className={`small`}>
			        <div className={`email-confirmation`} >
			          <div className="email-confirmation__icon">
			            <AlertIcon size={50} color={`#D6BAE8`} />			            
			          </div>
			          <h3 className="email-confirmation__title">Unit Info Not Available</h3>
			          <p className="email-confirmation__sub">Marketproof is currently acquiring and verifying information for the building.
More details will be added to this building as they become available.</p>
			          
			        </div>
			      </CardBody>
			    </Card>
			  </Col>
			  )

		}else if( data.length <= 0 ){
			return (
			  <Col md={12}>
			    <Card>
			      <CardBody className={`small`}>
			        <div className={`email-confirmation`} >
			          <div className="email-confirmation__icon">
			            <AlertIcon size={50} color={`#D6BAE8`} />			            
			          </div>
			          <h3 className="email-confirmation__title">No Units Found</h3>
			          <p className="email-confirmation__sub"></p>
			          
			        </div>
			      </CardBody>
			    </Card>
			  </Col>
			  )
		}
		
		
		return (
		<Fragment>
			<div>
			
			<Table className="material-table">
		        <UnitsSearchTableHead
		          numSelected={Object.keys(selectedUnits).length}
		          order={order}
		          orderBy={orderBy}
		          onSelectAllClick={this._handleSelectAllClick.bind(this)}
		          onRequestSort={isGrouped ? this._handleRequestUnitsSort : handleRequestSort}
		          rowCount={data.length}
		        />
		        <TableBody>
		          {data
		            .map((d) => {
		              const itemSelected = isSelected(d.id);
		              const floorplan = false; //_getUnitFloorPlan(d);
					  const buildingData = d;
					  //If building is sold out override stats
					  if(building && building.offering_verified_status === 'sold out'){						  
						  if(["active", "not_yet_recorded", "sold", "in contract", "resale"].indexOf(d.offering_status) < 0){
							  d.offering_status = "sold";
						  }						  
					  }
					  
					  if(d.baths % 1 == 0 && d.half_baths>0){
						  d.baths = d.baths + .5;
						  					  
					  }
					  let opPriceChange = false;
					  let soldPriceChange = false;
					  
					  if(building && building.building_status === 'sponsor'){	
					  	if((["active", "in contract", "pending"].indexOf(d.current_status)>=0) && typeof d["div(sub(offering_price, last_listed_sale_price_all), offering_price)"] === 'number' && d["offering_price"]>1000 ){
						 	opPriceChange = Math.round(d["div(sub(offering_price, last_listed_sale_price_all), offering_price)"] * 10000 * -1)/100;
					  	}
					  	if(d.last_sold_price > 1000 && typeof d["div(sub(offering_price, last_sold_price), offering_price)"] === 'number' && d["offering_price"]>1000 ){
						   	soldPriceChange = Math.round(d["div(sub(offering_price, last_sold_price), offering_price)"] * 10000 * -1)/100;
						}
					  }
		              return (
		                <TableRow
		                  className="material-table__row"
		                  role="checkbox"
		                  onClick={event => handleBuildingClick(event, d)}
		                  aria-checked={itemSelected}
		                  tabIndex={-1}
		                  key={d.id}
		                  selected={itemSelected}
		                >
		                 
		                  <TableCell className="material-table__cell" padding="checkbox">
								 <Checkbox checked={selectedUnitsAll[d.key]===undefined ? false : true} onClick={(e)=>{e.preventDefault();e.stopPropagation(); this._selectUnit(d);}} className={`material-table__checkbox ${selectedUnitsAll[d.key]!==undefined && 'material-table__checkbox--checked'}`} />							
						  </TableCell>
		                 {!isMobile && <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	 <UnitCurrentStatus status={d.offering_status} unit={d} />
		                  	 {/*d.current_status === 'not_yet_recorded' && 
			                  	 <div className="light-text xsmall-text no-wrap text-center" style={{marginTop:3}}>(Not Yet Recorded)
			                  	 </div>*/}
		                  </TableCell> }
		                
		                  <TableCell
		                    className="material-table__cell material-table__cell-right nowrap"
		                    component="td"
		                    scope="row"
		                    padding=""
		                  >
		                  	 {isGrouped || this.props.buildingView ?
		                      <div className="icon__floorplan"><span>{_formatUnitNumber(d.address_2_normalized, building).replace("#", "Apt")}</span>		                   
			                    
			                    <UnitIcons building={building} unit={d} />
			                   	<Fragment>
						  			<OpenInNewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`${_getBuildingURL(buildingData, 'building_slugs')}/${_nameToUrl(d.address_2_normalized)}`); }} />
						  		</Fragment>
				                   	
				                 
		                      </div>
			                 :
			                  <div className="icon__floorplan">
			                  	<span>{d.name ? _capitalizeText(d.name) : _capitalizeText(_replaceAbbreviations(d.display_full_street_address))}, {d.address_2_normalized.toUpperCase().replace("APT", "Apt")}</span>
			                  	
			                    <UnitIcons building={building} unit={d} />
			                    {!isMobile && <Fragment>
						  			<OpenInNewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`${_getBuildingURL(buildingData, 'building_slugs')}/${_nameToUrl(d.address_2_normalized)}`); }} />
						  		</Fragment>}
			                    <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>							
			                   
		                   	  </div>
		                   	  
		                   	}
		                   	
		                   	
		                   	
		                   	
		                  </TableCell>
		                  
		                 
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	
		                  	<span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
		                  	
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	
		                  	<span className="light-text small-text">{d.baths > -1 ? d.baths+' Bath' : '--'}</span>
		                  	
		                  </TableCell>
						  
		                   <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small" 
		                    align="center"
		                  >
		                  	{ (d.offering_status === 'in contract') && _shouldShow(d.last_contract_price) && d.last_contract_price > 100  ? 
		                  		<Fragment>
		                  			<span className="small-text">
		                  			
		                  				{_shouldShow(d.last_contract_price) ? `$${_formatNumber(d.last_contract_price)}` : "--"}
		                  			</span>
		                  			
		                  		</Fragment>
		                  	: _shouldShow(d.last_listed_sale_price_all) && d.last_listed_sale_price_all > 100 ? 
		                  		<Fragment>
		                  			<span className="small-text">
		                  				{_shouldShow(d.last_listed_sale_price_all) ? `$${_formatNumber(d.last_listed_sale_price_all)}` : "--"} {opPriceChange === false ? null : opPriceChange > 0 ? 
											  <Tooltip title={`Unit was last listed for ${opPriceChange}% above the initial offering price`}>
											  	<IconButton size={`small`} style={{background:'transparent'}}>
												  <ArrowUpIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#4ce1b6" />
												</IconButton>
											  </Tooltip> : 
										  opPriceChange == 0 ?
										  	<Tooltip title={`Unit was last listed for the same price as the initial offering price`}>
													<IconButton size={`small`} style={{background:'transparent'}}>
													<ArrowEqualIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#A3ADC2" />
												  </IconButton>
												</Tooltip>
												:
											  <Tooltip title={`Unit was last listed for ${opPriceChange}% below the initial offering price`}>
											  	<IconButton size={`small`} style={{background:'transparent'}}>
											  	<ArrowDownIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#FF206E" />
												</IconButton>
											  </Tooltip>
										} 
		                  			</span>
									{opPriceChange !== false ? 
									  <span className="light-text small-text" style={{color:"#858AA1"}}>
											<br/> {opPriceChange > 0 ? "+": ""}{opPriceChange ==0 ? '-0%' : `${opPriceChange}%`} vs. OP
										</span>
										
										:null
									} 
		                  			
		                  		</Fragment>
		                  		
		                  	: 
		                  		<Fragment>
		                  			<span className="small-text">{_shouldShow(d.offering_price) ? `$${_formatNumber(d.offering_price)}` : "--"}</span>
						  			{_shouldShow(d.offering_price) && <span className="light-text xsmall-text no-wrap"></span>}
		                  		</Fragment>
		                  	}
		                  </TableCell>
						   {/* <TableCell
							  className="material-table__cell material-table__cell-right"
							  size="small" 
							  align="center"
							>
							
							  {opPriceChange !== false ? 
								  <Fragment>
								<span className="small-text">
									   {opPriceChange === false ? null : opPriceChange > 0 ? 
											<Tooltip title={`Unit was last listed for ${opPriceChange}% above the initial offering price`}>
												<IconButton size={`small`} style={{background:'transparent'}}>
												<ArrowUpIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#4ce1b6" />
											  </IconButton>
											</Tooltip> : 
										opPriceChange == 0 ?
											<Tooltip title={`Unit was last listed for the same price as the initial offering price`}>
												  <IconButton size={`small`} style={{background:'transparent'}}>
												  <ArrowEqualIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#A3ADC2" />
												</IconButton>
											  </Tooltip>
											  :
											<Tooltip title={`Unit was last listed for ${opPriceChange}% below the initial offering price`}>
												<IconButton size={`small`} style={{background:'transparent'}}>
												<ArrowDownIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#FF206E" />
											  </IconButton>
											</Tooltip>
									  } {opPriceChange > 0 ? "+": ""}{opPriceChange ==0 ? 'No Change' : `${opPriceChange}%`}
								  </span>
								  <span className="light-text small-text" style={{color:"#858AA1"}}>
									  <br/> (OP: ${_formatNumber(d.offering_price)})
								  </span> 
								  </Fragment>
								  : "--"
							  } 
							</TableCell>  */}
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small" 
		                    align="center"
		                  >
		                  
		                    {typeof d["div(last_listed_sale_price_all, sq_ft)"] === 'number' && d["div(last_listed_sale_price_all, sq_ft)"] > 100 ? 
			                    <span className="small-text">
			                  				${_formatNumber(d["div(last_listed_sale_price_all, sq_ft)"])}
			                  	</span>
			                  	:
			                  	<span className="small-text">
			                  		--
			                  	</span>
			                  	
		                  	}
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small" 
		                    align="center"
		                  >
		                  	{  (d.last_sold_price && d.last_sold_price > 100) ?
			                  	<Fragment>
		                  			<span className="small-text">{_shouldShow(d.last_sold_price) ? `$${_formatNumber(d.last_sold_price)}` : "--"}
									{soldPriceChange === false ? null : soldPriceChange > 0 ? 
										  <Tooltip title={`Unit was last listed for ${soldPriceChange}% above the last asking price`}>
											  <IconButton size={`small`} style={{background:'transparent'}}>
											  <ArrowUpIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#4ce1b6" />
											</IconButton>
										  </Tooltip> : 
									  soldPriceChange == 0 ?
										  <Tooltip title={`Unit was last listed for the same price as the last asking price`}>
												<IconButton size={`small`} style={{background:'transparent'}}>
												<ArrowEqualIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#A3ADC2" />
											  </IconButton>
											</Tooltip>
											:
										  <Tooltip title={`Unit was last listed for ${soldPriceChange}% below the last asking price`}>
											  <IconButton size={`small`} style={{background:'transparent'}}>
											  <ArrowDownIcon size={15} style={{marginTop:-2,maringLeft:-3}} color="#FF206E" />
											</IconButton>
										  </Tooltip>
									}   
									</span>
									{soldPriceChange !== false ? 
									  <span className="light-text small-text" style={{color:"#858AA1"}}>
											<br/> {soldPriceChange > 0 ? "+": ""}{soldPriceChange ==0 ? '-0%' : `${soldPriceChange}%`} vs. OP
										</span>
										
										:null
									} 
		                  		</Fragment>
		                  	:
		                  		<Fragment>
		                  			<span className="light-text small-text">--</span>
		                  		</Fragment>
		                  	}
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small" 
		                    align="center"
		                  >
		                  
		                    {typeof d["div(last_sold_price, sq_ft)"] === 'number' && d["div(last_sold_price, sq_ft)"] > 100 ? 
			                    <span className="small-text">
			                  				${_formatNumber(d["div(last_sold_price, sq_ft)"])}
			                  	</span>
			                  	:
			                  	<span className="small-text">
			                  		--
			                  	</span>
		                  	}
		                  </TableCell>
		                   <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	{	d.current_status === 'in contract' ? 
			                  	<Fragment>
			                  	{parseInt(moment.unix(d.last_contract_date).format('YYYY')) > 1970 ? 
				                  	<Fragment>
					                <span className="light-text small-text">{moment.unix(d.last_contract_date).format('MM/DD/YYYY')}</span>
				                  	<span className="light-text xsmall-text no-wrap"><br/>(Contract Date)</span>
				                  	</Fragment>	
				                :
				                	<span className="light-text xsmall-text no-wrap">--</span>
				                }	
				                </Fragment> 
				                : d.current_status === 'not_yet_recorded' ? 	   
				                	<span className="light-text small-text no-wrap">Not Yet Recorded</span>               		
			                  	: d.last_sold_date && d.last_sold_date !== 0 ?
			                  	<Fragment> 
		                    	<span className="light-text small-text">{moment.unix(d.last_sold_date).format('MM/DD/YYYY')}</span>
		                    	{d.current_status === 'not_yet_recorded' && <span className="light-text xsmall-text no-wrap"><br/>(Not Yet Recorded)</span>}
		                    	</Fragment>	
		                    	:
		                  		<span className="light-text small-text">--</span>
		                  	}
		                  </TableCell>  
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small" 
		                    align="center"
		                  >
		                  	{d.offering_verified_status === "active"  || d.offering_sq_ft > -1 ? 
		                  		<span className="light-text small-text">{_shouldShow(d.offering_sq_ft) ? _formatNumber(d.offering_sq_ft)+' sf' : '--'}</span>
		                  	:
		                  		<span className="light-text small-text">{_shouldShow(d.sq_ft) ? _formatNumber(d.sq_ft)+' sf' : '--'}</span>
		                  	}
		                  </TableCell>    
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small" 
		                    align="center"
		                  ><span className="light-text small-text">{_shouldShow(d.offering_exterior_sq_ft) ? <React.Fragment>{_formatNumber(d.offering_exterior_sq_ft)} sf</React.Fragment>  : `--`}</span>
		                </TableCell>
		                  
		                {/*   <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    align="center"
		                  >
		                  {
			                 d.offering_monthly_carrying_charges && d.offering_monthly_carrying_charges > 0 ?
			                  	<span className="light-text small-text">${_formatNumber(d.offering_monthly_carrying_charges)}</span>
			                : ((d.monthly_carrying_charges && d.monthly_carrying_charges > 0) && (d.monthly_taxes && d.monthly_taxes > 0)) ? 
		                  	<span className="light-text small-text">${d.monthly_carrying_charges && d.monthly_carrying_charges > 0 ? _formatNumber(d.monthly_carrying_charges) :  (d.monthly_fees + d.monthly_taxes) > 0 ? _formatNumber(d.monthly_fees + d.monthly_taxes) : ' --'} / mo</span>
		                  	: <span className="light-text small-text"> -- </span>
		                  }
		                  </TableCell> */}
		                           
		                
		                </TableRow>
		              );
		            })}
		          {emptyRows > 0 && (
		            <TableRow style={{ height: 49 * emptyRows }}>
		              <TableCell colSpan={6} />
		            </TableRow>
		          )}
		        </TableBody> 
		        
              
		      </Table>
		      </div>
		     
			   {!reactTableFiltered && 
				<div className="unit__load">			   		
				    <Tooltip title="Load more units">
				        <Button
					        onClick={(e) => {this._loadMore(e)}}
					         variant="contained"
					         color="news"
					         className={`timeline__load-more`}		         
					         startIcon={<LoadMoreIcon />}
					         disabled={this.state.fetchInProgress || this.state.noMoreResults}
					     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "No More Units" : "Load More"}`}
					     </Button>				 
				  	</Tooltip>				
			    </div>
			    }
			</Fragment>
		);
	}
}

export default connect(state => ({
  user: state.user
}))(UnitsListTable);