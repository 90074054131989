/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/first: off */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import { bindActionCreators } from "redux";
import {
  Hits,
  Index,
  Highlight,
  Configure,
  connectSearchBox,
} from "react-instantsearch-dom";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
import { _relatedHoods } from "./../../../shared/helpers/utils";
import L from "leaflet";
import searchActions from "../../../redux/actions/searchActions";
import _ from "lodash";
import { Tooltip as TOOLTip, capitalize } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as MapUtil from "../../helpers/mapUtil";
import ShapePolygonPlusIcon from "mdi-react/MapMarkerPathIcon";
import PolygonEditIcon from "mdi-react/MapSearchIcon";
import { polygon, polygons, multiPolygon } from "@turf/helpers";
import center from "@turf/center";
import area from "@turf/area";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import LayerToggle from "./LayerToggle";
import { _getNeighborhoods } from "./../../../shared/helpers/apicalls";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  _isEqual,
  _capitalizeText,
  _getBuildingPhotos,
  _formatQueensAddress,
  _urlToName,
  _nameToUrl,
  _replaceAbbreviations,
  _shouldShow,
  _generateRandomAPI,
  StatusBadge,
  BuildingStageBadge,
  _formatNumber,
  _getStreetPhoto,
} from "../../helpers/utils";
import StarIcon from "mdi-react/StarCircleIcon";
import {
  TooltipMPND,
  pipelineStyle,
  TooltipBuilding,
  LocationsPadding,
} from "./CommonMapComponent";
import { ButtonToolbar, Modal, Col, Container, Row } from "reactstrap";
import {
  pipeline_tileset,
  majorMarketTileset,
  neighborhoodTileset,
  boroughTileset,
  _addZoningLayer,
  _addCommercialOverlayLayer,
  _addZoningAmendmentsLayer,
  _addRezoning2020,
  _addRezoning2015,
  _addRezoning2010,
  _addRezoningBefore2010,
  _addPendingRezoning,
  _addHistoricDistrinctLayer,
  _addFloodZoneLayer,
  _addSubwayLayer,
} from "../../helpers/commonMapFilters";
import SearchTags from "../algolia/SearchTags";
const url = process.env.API_ROOT + "/query/";
const BOROUGHS = {
  Brooklyn: {
    center: [-73.949997, 40.650002],
  },
  Bronx: {
    center: [-73.865433, 40.837048],
  },
  Manhattan: {
    center: [-73.984016, 40.754932],
  },
  "Staten Island": {
    center: [-74.151535, 40.579021],
  },
  Queens: {
    center: [-73.769417, 40.742054],
  },
};

const layers = [
  { header: "Zoning" },
  {
    name: "Zoning District",
    id: "nyzd",
    fun: _addZoningLayer,
    tooltip:
      "Areas regulated for land use - Residential (R), Commercial (C) and Manufacturing (M)",
  },
  {
    name: "Commercial Overlay",
    id: "nyco",
    fun: _addCommercialOverlayLayer,
    tooltip:
      "Area located in a residential zoning district that allows for commercial use",
  },
  {
    name: "Historic Districts",
    id: "hisdist",
    fun: _addHistoricDistrinctLayer,
    tooltip: "Landmark Preservation Commission designed historic areas",
  },
  { header: "Rezoning" },
  {
    name: "Rezoning (Pending)",
    id: "nyzmapending",
    fun: _addPendingRezoning,
    tooltip: "Pending zoning amendments",
  },
  {
    name: "Rezoning (After 2020)",
    id: "nyzma2020",
    fun: _addRezoning2020,
    tooltip: "Zoning amendments adopted after year 2020",
  },
  {
    name: "Rezoning (2015 - 2020)",
    id: "nyzma2015",
    fun: _addRezoning2015,
    tooltip: "Zoning amendments adopted from 2015 to 2020",
  },
  {
    name: "Rezoning (2010 - 2015)",
    id: "nyzma2010",
    fun: _addRezoning2010,
    tooltip: "Zoning amendments adopted from 2010 to 2015",
  },
  {
    name: "Rezoning (Before 2010)",
    id: "nyzmabefore2010",
    fun: _addRezoningBefore2010,
    tooltip: "Zoning amendments adopted before year 2010",
  },
  { header: "Transportation" },
  {
    name: "Flood Zones",
    id: "flood",
    fun: _addFloodZoneLayer,
    tooltip:
      "100-Year Floodplain for the 2020s based on FEMA's Preliminary Work Map data and the New York Panel on Climate Change's 90th Percentile Projects for Sea-Level Rise",
  },
  {
    name: "Subways",
    id: "subway",
    fun: _addSubwayLayer,
    tooltip: "MTA subway lines and stations",
  },
];
mapboxgl.accessToken =
  "pk.eyJ1IjoibmluZ3pob3UiLCJhIjoiY2lwaXBiaWtyMDFxZXVnbmpkaWR4dXd0MSJ9.GJX2c6TviB6opu84mCEOIg";

var Draw = new window.MapboxDraw({
  displayControlsDefault: false,
  styles: [
    // Style for active polygon edges
    {
      id: "gl-draw-polygon-and-line-vertex-active",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 6,
        "circle-color": "#780F9E",
      },
    },
    // Style for the active polygon line
    {
      id: "gl-draw-line-active",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#780F9E",
        "line-dasharray": [0.2, 2],
        "line-width": 4,
      },
    },
    // Style for the polygon fill during drawing
    {
      id: "gl-draw-polygon-fill",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
      paint: {
        "fill-color": "#780F9E",
        "fill-opacity": 0.4,
      },
    },
  ],
  controls: { polygon: true, trash: true },
});

class SearchMapPolygon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingsSelected: [],
      lat: 40.7379366,
      lng: -73.9949712,
      mapContainerWidth: 1000,
      mapLoading: false,
      mapLoaded: false,
      mapUnsupported: false,
      zoom: 13,
      occupancyTypes: [],
      mapBounds: [],
      modal: this.props.mapModal,
      polyArea: this.props.searchTags.length ? this.props.searchTags : false,
      justDeleted: false,
      polyHoods: [],
      centerPoint: [],
      tilesAndSourcesLoaded: false,
      locationChange: false,
      deletedTagsID: [],
      styleLoaded: false,
      mapRendered: false,
      showLayers: [],
      selectedFeature: null,
      selectedLayerId: null,
      clickedNeighborhoods: this.props.searchTags,
      isDrawing: false,
      isMapClose: false,
      isFlying: false,
    };
    this.isHovering = false;
    this.isClickable = false;
    this.mapContainer = React.createRef();
    this.tooltipContainer = document.createElement("div");
    this._calcDefaultZoomLevel = this._calcDefaultZoomLevel.bind(this);
    this._toggleModal = this._toggleModal.bind(this);
    this._savePolyArea = this._savePolyArea.bind(this);
    this._setMapBoundaries = this._setMapBoundaries.bind(this);
    this._toggleLayer = this._toggleLayer.bind(this);
    this._clearAllLayers = this._clearAllLayers.bind(this);
    this._showSelectedLayers = this._showSelectedLayers.bind(this);
    this._getBoundsByName = this._getBoundsByName.bind(this);
  }
  tooltip;
  map;
  marker;
  layersAdded = [];

  _calcDefaultZoomLevel() {
    const { buildingRes } = this.props;

    let zoom = 12;

    return zoom;
  }

  _toggleModal() {
    const { modal } = this.state;

    if (modal) {
      this.map.remove();
      this.setState({ modal: false, mapLoaded: false });
    } else {
      this.setState({ modal: true });
    }
  }

  _showSelectedLayers() {
    const { showLayers } = this.state;
    const { searchTags } = this.props;

    showLayers.map((layerId) => {
      const layerObj = layers.filter((l) => l.id && l.id == layerId)[0];
      layerObj.fun(this.map, "visible");
    });
  }

  _toggleLayer(layerId) {
    const { showLayers } = this.state;
    const index = showLayers.indexOf(layerId);
    const layerObj = layers.filter((l) => l.id && l.id == layerId)[0];

    if (index >= 0) {
      let newLayers = showLayers.slice();
      newLayers.splice(index, 1).filter((item) => item);
      layerObj.fun(this.map, "none");
      this.setState({ showLayers: newLayers });
    } else {
      layerObj.fun(this.map, "visible");
      this.setState({ showLayers: [...showLayers, layerId] });
    }
  }

  _clearAllLayers() {
    layers.forEach((layerObj) => {
      if (layerObj.fun) {
        layerObj.fun(this.map, "none");
      }
    });
    this.setState({ showLayers: [] });
  }

  _addSourceAndLayers(tileset) {
    this.map.addSource(tileset.id, {
      type: "vector",
      url: tileset.url,
    });
    this.map.addLayer({
      id: `${tileset.id}-layer-transparent`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-outline-color": "#000",
        "fill-color": "#363347",
        "fill-opacity": 0,
      },
    });
    this.map.addLayer({
      id: `${tileset.id}-layer`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#ccc",
        "fill-opacity": 0.1,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });
    this.map.addLayer({
      id: `${tileset.id}-layer-highlighted`,
      source: tileset.id,
      "source-layer": tileset.source_layer,
      type: "fill",
      paint: {
        "fill-color": "#BF96D9",
        "fill-opacity": 0.3,
      },
      filter: [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ],
    });
    const hoverLayerId = `${tileset.id}-layer-hover`;
    if (!this.map.getLayer(hoverLayerId)) {
      this.map.addLayer({
        id: hoverLayerId,
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "fill",
        paint: {
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#BF96D9",
            "#BF96D9",
          ],
          "fill-opacity": 0.3,
        },
        filter: ["==", "id", ""],
      });
    }
    const defaultLayerId = `${tileset.id}-layer-default`;
    if (!this.map.getLayer(defaultLayerId)) {
      this.map.addLayer({
        id: defaultLayerId,
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "fill",
        paint: {
          "fill-color": "#ccc",
          "fill-opacity": 0.1,
        },
      });
    }
    const outlineLayerId = `${tileset.id}-layer-outline`;
    if (!this.map.getLayer(outlineLayerId)) {
      this.map.addLayer({
        id: outlineLayerId,
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "line",
        paint: {
          "line-color": "#abb3d4",
          "line-width": 2,
        },
      });
    }
    const clickLayerId = `${tileset.id}-layer-click`;
    if (!this.map.getLayer(clickLayerId)) {
      this.map.addLayer({
        id: clickLayerId,
        source: tileset.id,
        "source-layer": tileset.source_layer,
        type: "fill",
        paint: {
          "fill-color": "#780F9E",
          "fill-opacity": 0.4,
        },
        filter: ["==", "id", ""],
      });
    }
    const minWidth = 1;
    const minZoom = 10;
    const cb4 = (e) => {
      this.isClickable = true;
      if(this.map.getZoom()<13){
   
      const draw = Draw.getMode();

      if (draw === "draw_polygon") return;

      const polygonLayers = [
        "gl-draw-polygon-fill.cold",
        "gl-draw-polygon-fill.hot",
        "gl-draw-polygon-and-line-vertex-active.cold",
        "gl-draw-polygon-and-line-vertex-active.hot",
        "gl-draw-line-active.cold",
        "gl-draw-line-active.hot",
      ];

      let polygonFeatures = [];
      polygonLayers.forEach((layer) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: [layer],
        });
        if (features.length > 0) {
          polygonFeatures = features;
        }
      });

      if (polygonFeatures.length > 0) {
        return;
      }
      if (e.features.length > 0) {
        console.log(e.features, "features");
        const highlightFilter = tileset.id.includes("boroughs")
          ? e.features[0].properties.boro_name
          : e.features[0].properties.id;
        let areaData = {};
        const layerName = e.features[0].layer.id;
        const borough_name = e.features[0].properties.boro_name;
        if (layerName.includes("boroughs")) {
          areaData = {
            type: "borough",
            data: borough_name.toLowerCase(),
            id: borough_name.toLowerCase(),
            name: borough_name.toLowerCase(),
            clicked: true,
          };
        } else {
          areaData = {
            type: "neighborhood",
            data: {
              hood: e.features[0].properties.name.toLowerCase(),
              boro: e.features[0].properties.region,
            },
            id: e.features[0].properties.id,
            name: e.features[0].properties.name.toLowerCase(),
            clicked: true,
          };
        }
        console.log(highlightFilter, "highlight");
        this.setState(
          (prevState) => {
            let newClickedNeighborhoods = prevState.clickedNeighborhoods || [];
            let newPolyArea = prevState.polyArea || [];
            let newdeletedTagsID = prevState.deletedTagsID;

            if (newClickedNeighborhoods.includes(highlightFilter)) {
              newClickedNeighborhoods = newClickedNeighborhoods.filter(
                (id) => id !== highlightFilter
              );
              newPolyArea = newPolyArea.filter((area) =>
                e.features[0].properties.boro_name
                  ? e.features[0].properties.boro_name.toLowerCase() !==
                    area.name.toLowerCase()
                  : e.features[0].properties.name.toLowerCase() !==
                    area.name.toLowerCase()
              );
              newdeletedTagsID = [...newdeletedTagsID, areaData.id];
            } else {
              newClickedNeighborhoods = [
                ...newClickedNeighborhoods,
                highlightFilter,
              ];
              newPolyArea = [...newPolyArea, areaData];
            }

            return {
              clickedNeighborhoods: newClickedNeighborhoods,
              polyArea: newPolyArea,
              deletedTagsID: newdeletedTagsID,
              zoom: 12,
            };
          },
          () => {
            this.map.setFilter(`${tileset.id}-layer-click`, [
              "any",
              ["in", "id", ...this.state.clickedNeighborhoods],
              ["in", "boro_name", ...this.state.clickedNeighborhoods],
            ]);
          }
        );

        console.log(this.state.clickedNeighborhoods, "highlighted");
      }
    }else{
      this.isClickable=false
      const draw = Draw.getMode();
      console.log(draw, "myDraw");
      console.log(this.map.getZoom(), "myDraw1")
          if (draw === "draw_polygon") return;
  
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["mp-neighborhoods-layer-default"],
      });
  
      if (features.length > 0 && this.isClickable) {
        const feature = features[0];
        const neighborhoodId = feature.properties.id;
        const neighborhoodName = feature.properties.name;
        const boroughName = feature.properties.region;
        const formattedNeighborhoodName = neighborhoodName
          .toLowerCase()
          .replace(/\s+/g, "-");
  
        const areaData = {
          type: "neighborhood",
          data: {
            hood: neighborhoodName.toLowerCase(),
            boro: boroughName,
          },
          id: `${formattedNeighborhoodName}_${boroughName.toLowerCase()}`,
          name: neighborhoodName,
          clicked: true,
        };
  
        this.setState(
          (prevState) => {
            let newClickedNeighborhoods = prevState.clickedNeighborhoods || [];
            let newPolyArea = prevState.polyArea || [];
            let newdeletedTagsID = prevState.deletedTagsID;
            if (newClickedNeighborhoods.includes(neighborhoodId)) {
              newClickedNeighborhoods = newClickedNeighborhoods.filter(
                (id) => id !== neighborhoodId
              );
              newPolyArea = newPolyArea.filter(
                (area) => neighborhoodName !== area.name
              );
              newdeletedTagsID = [...newdeletedTagsID, areaData.id];
            } else {
              newClickedNeighborhoods = [
                ...newClickedNeighborhoods,
                neighborhoodId,
              ];
              newPolyArea = [...newPolyArea, areaData];
            }
            return {
              clickedNeighborhoods: newClickedNeighborhoods,
              polyArea: [...newPolyArea],
              deletedTagsID: newdeletedTagsID,
              zoom: 13,
            };
          },
          () => {
            this.map.setFilter("mp-neighborhoods-layer-click", [
              "in",
              "id",
              ...this.state.clickedNeighborhoods,
            ]);
          }
        );
      }
    }
    };

    const cb1 = (e) => {
      //hovering varibale is used to disbale other mouse move, as by default this is not running after map is
      //loading and zoom in two mouse events are working on boroughs & neighborhood
      console.log(this.map.getZoom(), "zooming")
  if(this.map.getZoom()<13){
    if (
      this.state.isDrawing ||
      Draw.getMode() === "draw_polygon" ||
      Draw.getMode() === "direct_select"
    ) {
      this._onMouseOut();
      return;
    }

    this.isHovering = true;
    if (e.features.length > 0) {
      {
        this.currentlyHoveredLocation = tileset.id.includes("boroughs")
          ? e.features[0].properties.boro_name
          : e.features[0].properties.id;
        const highlightFilter = tileset.id.includes("boroughs")
          ? ["in", "boro_name", e.features[0].properties.boro_name]
          : ["in", "id", e.features[0].properties.id];
        this.map.setFilter(
          `${tileset.id}-layer-highlighted`,
          highlightFilter
        );
      }
    }
   
  }

    else {
      const isZoomed = this.map.getZoom() >= 13;
    const hoverLayerId = "mp-neighborhoods-layer-hover";
    if (
      this.state.isDrawing ||
      Draw.getMode() === "draw_polygon" ||
      Draw.getMode() === "direct_select"
    ) {
      this._onMouseOut();
      return;
    }
    if (!this.map.getLayer(hoverLayerId)) {
      console.warn("Hover layer does not exist.");
      return;
    }

    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ["mp-neighborhoods-layer-default"],
    });

    if (features.length > 0) {
      const feature = features[0];
      const neighborhoodId = feature.properties.id;
      const neighborhoodName = feature.properties.name;

      this.map.setFilter(hoverLayerId, ["==", "id", neighborhoodId]);
    } else {
      this.map.setFilter(hoverLayerId, ["==", "id", ""]);
    }
    
  }
    };
    const cb2 = (e) => {
      this.map.setFilter(`${tileset.id}-layer-highlighted`, [
        "in",
        `${tileset.id.includes("boroughs") ? "boro_name" : "name"}`,
        "FALSE",
      ]);
      this.currentlyHoveredLocation = null;
    };
    const cb3 = (e) => {
      this.map.off("mousemove", `${tileset.id}-layer`, cb1);
      this.map.off("mouseleave", `${tileset.id}-layer`, cb2);
      this.map.off("touchstart", `${tileset.id}-layer`, cb3);
    };
    this.map.on("mousemove", `${tileset.id}-layer`, cb1);
    this.map.on("mouseleave", `${tileset.id}-layer`, cb2);
    this.map.on("touchstart", `${tileset.id}-layer`, cb3);
    this.map.on("click", `${tileset.id}-layer`, cb4);
    if (this.props.searchTags && this.props.searchTags.length > 0) {
      let neighborhoodIds = [];
      if (tileset.id.includes("boroughs")) {
        console.log("one");
        neighborhoodIds = this.props.searchTags.map((tag) =>
          tag.id.replace(/^\w/, (c) => c.toUpperCase())
        );
        this.map.setFilter(`${tileset.id}-layer-click`, [
          "in",
          "boro_name",
          ...neighborhoodIds,
        ]);
        this.setState({ clickedNeighborhoods: neighborhoodIds });
      } else {
        if (
          this.props.searchTags.some((item) => typeof item.data !== "string")
        ) {
          neighborhoodIds = this.props.searchTags
            .map((tag) => (tag.id.includes("_ny") ? tag.id : `${tag.id}_ny`))
            //this is to remove _major for id as few neighbourhood Id has _major in it...like roosevelt
            .map((tag) =>
              tag.includes("_major") ? tag.replace("_major", "") : tag
            );

          this.map.setFilter(`${tileset.id}-layer-click`, [
            "in",
            "id",
            ...neighborhoodIds,
          ]);
          this.setState({ clickedNeighborhoods: neighborhoodIds });
        }
      }
    }
  }

  _createPolyQueryKey(feature, featureType) {
    switch (featureType) {
      case "single":
        return "single";
      case "borough":
        return `borough:"${feature.properties.boro_name.toLowerCase()}"`;
      case "market":
        return `markets_major:"${feature.properties.name.toLowerCase()}"`;
      case "neighborhood":
        return `neighborhood:"${feature.properties.name.toLowerCase()}" AND borough:"${feature.properties.region.toLowerCase()}"`;
    }
  }

  _createPolygonsObj(features = [], featureType) {
    const poly = {};
    features.forEach((feature) => {
      const key = this._createPolyQueryKey(feature, featureType);
      if (!poly[key]) poly[key] = [];

      if (feature.geometry.type === "MultiPolygon")
        feature.geometry.coordinates.forEach((polygon) =>
          poly[key].push(polygon)
        );
      else poly[key].push(feature.geometry.coordinates);
    });

    return poly.single || poly;
  }

  _createPolyDataInfoObj(polygons, featureType, key) {
    const locationName = key.split('"')[1].toLowerCase();

    if (featureType === "borough") {
      return {
        location: _capitalizeText(locationName),
        geocode: BOROUGHS[_capitalizeText(locationName)].geocode
          ? BOROUGHS[_capitalizeText(locationName)].geocode
              .slice()
              .reverse()
              .join(", ")
          : BOROUGHS[_capitalizeText(locationName)].center
              .slice()
              .reverse()
              .join(", "),
        fontSize: 24,
        offset: [0, -1.5],
      };
    } else {
      const polyDataInfo = {
        fontSize: this.map.getZoom() < 11 || this.map.getZoom() >= 14 ? 16 : 12,
      };
      const multiPlygn = multiPolygon(polygons);

      if (featureType === "neighborhood" && this.map.getZoom() >= 11) {
        const mapBounds = this.map.getBounds();
        const boundsPoly = polygon([
          [
            mapBounds.getSouthWest().toArray(),
            mapBounds.getSouthEast().toArray(),
            mapBounds.getNorthEast().toArray(),
            mapBounds.getNorthWest().toArray(),
            mapBounds.getSouthWest().toArray(),
          ],
        ]);
        const areaPercent = MapUtil._areaPercentage(
          area(multiPlygn),
          area(boundsPoly)
        );

        const hoodTags = []; //this.props.hoodTags.map(tag => tag.hood.toLowerCase());

        polyDataInfo.location =
          areaPercent > 1.7 || hoodTags.includes(locationName)
            ? _capitalizeText(locationName)
            : "";
      } else {
        polyDataInfo.location = _capitalizeText(locationName);
      }

      const geoCenter = center(multiPlygn).geometry.coordinates.slice();
      if (LocationsPadding[polyDataInfo.location]) {
        geoCenter[0] =
          geoCenter[0] + LocationsPadding[polyDataInfo.location][0];
        geoCenter[1] =
          geoCenter[1] + LocationsPadding[polyDataInfo.location][1];
      }
      polyDataInfo.geocode = geoCenter.reverse().join(", ");

      return polyDataInfo;
    }
  }

  _setSourceFilters(boros = [], markets = [], hoods = []) {
    if (!this.map) return false;
    this.map.setFilter("mp-boroughs-layer", ["in", "boro_name", ...boros]);

    this.map.setFilter("mp-boroughs-layer-outline", [
      "in",
      "boro_name",
      ...boros,
    ]);

    this.map.setFilter("mp-major-markets-layer", ["in", "name", ...markets]);

    this.map.setFilter("mp-major-markets-layer-outline", [
      "in",
      "name",
      ...markets,
    ]);

    this.map.setFilter("mp-neighborhoods-layer", ["in", "id", ...hoods]);

    this.map.setFilter("mp-neighborhoods-layer-outline", [
      "in",
      "id",
      ...hoods,
    ]);
  }

  _setMapBoundaries() {
    const { searchTags } = this.props;
    const { polyHoods } = this.state;
    const boroTags = [];
    const hoodTags = [];

    if (
      this.state.mapLoaded &&
      this.state.styleLoaded &&
      this.state.mapRendered &&
      this.state.tilesAndSourcesLoaded
    ) {
      //console.log("compent update zoom", boroTags, hoodTags)
      const zoom = this.map.getZoom();
      const polygonData = {};
      let polygonQueries =
        zoom < 14
          ? MapUtil._createPolygonQueries(
              this.map.getBounds(),
              this.map.getZoom(),
              false,
              false
            )
          : MapUtil._createPolygonQuery(this.map.getBounds(), false);
      if (this.map.getZoom() < 15) polygonQueries.groupQuery = {};
      if (this.map.getZoom() < 11) {
        const features = this.map.queryRenderedFeatures({
          layers: ["mp-boroughs-layer-transparent"],
          filter:
            hoodTags && hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "boro_name"]
              : [
                  "in",
                  "boro_name",
                  ...boroTags.map((tag) => _capitalizeText(tag)),
                ],
        });

        const poly = this._createPolygonsObj(features, "borough");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "borough",
              key
            ))
        );

        //const hoodTags = []; //this.props.hoodTags.map(tag => tag.hood.toLowerCase().trim());

        let hoods = [];

        const hood_keys = polyHoods.map((tag) => tag.key);
        if (polyHoods) {
          polyHoods.forEach((hood) => {
            const key = this._createPolyQueryKey(
              { properties: hood },
              "neighborhood"
            );
            polygonData[key] = {};
            const plygn = polygon([
              hood.coordinates.map((coord) => [
                Number(coord[0]),
                Number(coord[1]),
              ]),
            ]);
            const cent = center(plygn);

            polygonData[key].location = _capitalizeText(hood.name);
            polygonData[key].geocode = cent.geometry.coordinates
              .reverse()
              .join(", ");
            polygonData[key].fontSize = 16;
            polygonData[key].offset = [0, -1.5];
          });
        }

        const boroNames = features.map((feat) => feat.properties.boro_name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        // this._setLocationNames(plygnDataArr);

        this._setSourceFilters(boroNames, [], hood_keys);

        console.log("DUET BOROUGHS");
      } else if (this.map.getZoom() < 13) {
        const features1 = this.map.queryRenderedFeatures({
          layers: ["mp-major-markets-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "name"]
              : ["in", "region", ...boroTags],
        });

        const hood_keys = polyHoods.map((tag) => tag.key);

        const features2 = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["!in", "region", "manhattan", "brooklyn"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  [
                    "in",
                    "region",
                    ...boroTags.filter(
                      (tag) => tag !== "manhattan" && tag !== "brooklyn"
                    ),
                  ],
                ],
        });

        const poly = this._createPolygonsObj(features1, "market");
        const poly2 = this._createPolygonsObj(features2, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "market",
              key
            ))
        );
        Object.keys(poly2).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly2[key],
              "neighborhood",
              key
            ))
        );

        const majorMarkets = features1.map((feat) => feat.properties.name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });

        this._setSourceFilters([], majorMarkets, [
          ...hood_keys,
          ...features2.map((feat) => feat.properties.id),
        ]);

        console.log("DUET MAJOR MARKETS");
      } else if (this.map.getZoom() < 15) {
        const hood_keys = polyHoods.map((tag) => tag.key);

        const features = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "id"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  ["in", "region", ...boroTags],
                ],
        });

        const poly = this._createPolygonsObj(features, "neighborhood");
        console.log(poly, "poly")
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "neighborhood",
              key
            ))
        );

        const hoodNames = features.map((feat) => feat.properties.name);

        const plygnDataArr = [];
        Object.keys(polygonData).forEach((key) => {
          polygonQueries.groupQuery[key] = key;
          plygnDataArr.push(polygonData[key]);
        });
        console.log(plygnDataArr, "poly")
        //this._setLocationNames(plygnDataArr);

        this._setSourceFilters(
          [],
          [],
          [...hood_keys, ...features.map((feat) => feat.properties.id)]
        );

        console.log("DUET NEIGHBORHOODS");
      } else {
        console.log("DUET LISTINGS");

        const hood_keys = polyHoods.map((tag) => tag.key);
console.log(hood_keys, "poly")
        const features = this.map.queryRenderedFeatures({
          layers: ["mp-neighborhoods-layer-transparent"],
          filter:
            hoodTags.length === 0 && boroTags.length === 0
              ? ["has", "id"]
              : [
                  "any",
                  ["in", "id", ...hood_keys],
                  ["in", "region", ...boroTags],
                ],
        });

        const poly = this._createPolygonsObj(features, "neighborhood");
        Object.keys(poly).forEach(
          (key) =>
            (polygonData[key] = this._createPolyDataInfoObj(
              poly[key],
              "neighborhood",
              key
            ))
        );

        const plygnDataArr = Object.keys(polygonData).map(
          (key) => polygonData[key]
        );
        this._setLocationNames(plygnDataArr);

        this._setSourceFilters(
          [],
          [],
          [...hood_keys, ...features.map((feat) => feat.properties.id)]
        );
      }
      const plygnDataArr = Object.keys(polygonData).map(
        (key) => polygonData[key]
      );
      this._setLocationNames(plygnDataArr);
    }
  }


  _setLocationNames(plygnDataArr = []) {
    if (!this.map) return false;
    this.map
      .getSource("location-names")
      .setData(MapUtil._parseToGeoJSON(plygnDataArr));
  }

  _addLocationNames(data) {
    if (!this.map) return false;
    this.map.addSource("location-names", {
      type: "geojson",
      data,
    });

    this.map.addLayer({
      id: "location-names",
      type: "symbol",
      source: "location-names",
      layout: {
        "text-field": ["get", "location"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": ["get", "fontSize"],
        "text-offset": ["get", "offset"],
      },
      paint: {
        "text-color": "#171F26",
      },
    });
  }
  _getPolyHoods() {
    const { searchTags } = this.props;
    const hoodTags = [];

    _getNeighborhoods(searchTags, {
      callback: function (hoods) {

        if (hoods) {
          const boundingBox = this.calculateBoundingBox(hoods);
          this.map.fitBounds(boundingBox, {
            // padding: { top: 50, bottom: 50, left: 50, right: 50 }, // Set the desired padding
            zoom: this.state.zoom,
          });
        } else {
          if (
            this.props.searchTags.length &&
            BOROUGHS.hasOwnProperty(
              _capitalizeText(this.props.searchTags[0].name)
            )
          ) {
            this.map.flyTo({
              center:
                BOROUGHS[_capitalizeText(this.props.searchTags[0].name)].center,
              zoom: 8,
              essential: true,
            });
          }
        }
      }.bind(this),
      url: url,
    });
    if (hoodTags.length >= 0) {
    } else {
      this.setState({ polyHoods: [] });
    }
  }
  _openBuilding(building) {
    let address = _replaceAbbreviations(
      building.display_full_street_address
    ).toLowerCase();
    if (address.indexOf("garage") < 0 && address.indexOf("rear") < 0) {
      window.open(
        "/building/" +
          _nameToUrl(building.borough) +
          "/" +
          _nameToUrl(building.main_neighborhood) +
          "/" +
          building.key
      );
    }
  }

  _showBuilding(building) {
    const gmapApiArr = process.env.GMAP_API_KEY;
    const gApiKey = gmapApiArr[_generateRandomAPI(gmapApiArr)];
    let buildingImages = _getBuildingPhotos(building, gApiKey);
    let image = `https://maps.googleapis.com/maps/api/streetview?${gApiKey}&size=700x700&location=${
      building.display_full_street_address
        ? building.display_full_street_address
        : building.full_street_address
    },${building.zip}&fov=90&pitch=10&source=outdoor`;
    if (buildingImages.length == 0) {
      buildingImages.push(image);
    }
    let adr = building.display_full_street_address
      ? _capitalizeText(building.display_full_street_address).trim()
      : building.primary_full_street_address
      ? _capitalizeText(building.primary_full_street_address).trim()
      : building.full_street_address
      ? _capitalizeText(building.full_street_address).trim()
      : false;

    if (
      building.borough == "queens" &&
      buildingName &&
      building.alternate_addresses
    ) {
      adr = _formatQueensAddress(adr, building.alternate_addresses);
    }

    let buildingName =
      building.name && building.name != building.display_full_street_address
        ? _capitalizeText(_urlToName(building.name.replace("corporation", "")))
        : false;

    return (
      <div
        className={`c_map-building-container`}
        key={`${building.key}`}
        onClick={(e) =>
          e.target.id != "mp_close" && this._openBuilding(building)
        }
      >
        <div className={`row`}>
          <div className={`col-xs-5 u_pd-rt-none`}>
            <div
              className={`img-responsive c_building-img`}
              style={{ backgroundImage: "url('" + buildingImages[0] + "')" }}
            >
              <span className={`c_building-type`}>
                {_capitalizeText(_urlToName(building.ownership_type))}
              </span>
            </div>
          </div>
          <div className={`col-xs-7 u_pd-left-none c_building-info`}>
            <div className={`u_pd-5px`}>
              <div className="c_title u_600-wgt">
                {buildingName ? buildingName : adr}{" "}
                <i
                  id="mp_close"
                  className="fa fa-times u_font-20"
                  aria-hidden="true"
                  onClick={() => this.setState({ buildingsSelected: [] })}
                ></i>
              </div>
              <div className="u_font-11">{buildingName ? adr + ", " : ""}</div>
              <div className="u_font-11">
                {building.main_neighborhood
                  ? _capitalizeText(building.main_neighborhood) + ", "
                  : ""}
                {_capitalizeText(building.borough)}
              </div>

              <div className="u_font-12 u_pd-top-5px">
                {_shouldShow(building.year_built)
                  ? moment().year() < building.year_built
                    ? "Proj Completion " + building.year_built
                    : "Year Built " + building.year_built
                  : ""}
              </div>
              <div className="u_font-12">
                {building.total_units &&
                  (building.total_units > 1
                    ? building.total_units + " Units"
                    : "")}
                {building.total_floors && building.total_floors > 0
                  ? building.total_units > 1
                    ? " | " +
                      (building.total_floors > 1
                        ? building.total_floors + " Floors"
                        : building.total_floors + " Floor")
                    : building.total_floors > 1
                    ? building.total_floors + " Floors"
                    : building.total_floors + " Floor"
                  : false}
              </div>
            </div>
            <div className="c_tooltip-hint u_font-11">
              Click for details{" "}
              <i className="fa fa-external-link" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.tooltipContainer = document.createElement("div");
    const buildingRes = this.props.buildingRes
      ? this.props.buildingRes
      : this.props.axios &&
        this.props.axios.buildingAxios &&
        this.props.axios.buildingAxios[0]
      ? this.props.axios.buildingAxios[0]
      : false;

    if (this.props.mapContainerWidth === 0) {
      this.setState({ mapContainerWidth: this.props.mapContainerWidth });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mapUnsupported === true) return;

    if (
      (this.props.mapModal &&
        !this.state.mapLoading &&
        !this.state.mapLoaded) ||
      !_.isEqual(this.props.standardView, prevProps.standardView)
    ) {
      this._makeMap();
      if (this.state.mapContainerWidth !== this.props.mapContainerWidth) {
        this.setState((prevState) => ({
          mapContainerWidth: this.props.mapContainerWidth,
        }));
      }
    }

    if (!this.props.mapModal && this.props.mapModal !== prevProps.mapModal) {
      if (this.map) {
        this.map.remove();
        this.setState({
          mapLoaded: false,
          mapLoading: false,
          styleLoaded: false,
          mapRendered: false,
          tilesAndSourcesLoaded: false,
        });
      }
    }

    if (this.props.clearMap && this.props.clearMap !== prevProps.clearMap) {
      this.setState({ polyArea: [], clickedNeighborhoods: [] });
      this.props.resetClearMap();
    }

    if (this.map && this.props.mapModal) {
      this._setMapBoundaries();
    }
  }

  _getPipelineFitlers() {
    const { occupancyTypes } = this.state;
    let filter = ["all"];
    if (occupancyTypes.length < 3) {
      let occupancyFilter = ["in", "pipeline_occupancy_type"].concat(
        occupancyTypes
      );
      filter.push(occupancyFilter);
    }
    if (filter.length === 1) {
      filter = ["has", "key"];
    }
    return filter;
  }

  _addPipelineTiles() {
    let filter = this._getPipelineFitlers();
    pipeline_tileset.forEach((tileset) => {
      if (!this.map.getSource(tileset.id)) {
        this.map.addSource(tileset.id, {
          type: "vector",
          url: tileset.url,
        });
      }
    });

    pipeline_tileset.forEach((tileset, i) => {
      const default_filter = tileset.default_filter
        ? tileset.default_filter
        : false;
      let theFilter = filter.slice(0);
      if (default_filter) {
        theFilter = ["all", default_filter, filter];
      }
      this.map.addLayer({
        id: tileset.layer_id,
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        minZoom: 18,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": [
            "match",
            ["get", "pipeline_occupancy_type"],
            "commercial",
            "#FF206E",
            "residential",
            "#780F9E",
            "mixed-use",
            "#28C2FF",
            pipelineStyle.fillColor,
          ],
          "fill-opacity": pipelineStyle.fillOpacity,
        },
        filter: theFilter,
      });
      this.layersAdded.push(tileset.layer_id);
    });

    pipeline_tileset.forEach((tileset) => {
      this.map.addLayer({
        id: tileset.layer_id + "-hover",
        type: "fill",
        source: tileset.id,
        "source-layer": tileset.source_layer,
        paint: {
          "fill-outline-color": "#000",
          "fill-color": "#FFD23F",
          "fill-opacity": 1,
        },
        filter: ["==", "key", false],
      });
    });
  }

  moveDrawLayersToTop = () => {
    const drawLayerIds = [
      "gl-draw-polygon-fill",
      "gl-draw-polygon-stroke",
      "gl-draw-polygon-fill-cold",
      "gl-draw-polygon-stroke-cold",
      "gl-draw-polygon-and-line-vertex-active",
      "gl-draw-line-active",
    ];
    drawLayerIds.forEach((layerId) => {
      if (this.map.getLayer(layerId)) {
        this.map.moveLayer(layerId);
      }
    });
  };

  moveClickLayerBelowDrawLayers = (clickLayerId) => {
    const drawLayerIds = [
      "gl-draw-polygon-fill",
      "gl-draw-polygon-stroke",
      "gl-draw-polygon-fill-cold",
      "gl-draw-polygon-stroke-cold",
      "gl-draw-polygon-and-line-vertex-active",
      "gl-draw-line-active",
    ];

    for (let layerId of drawLayerIds) {
      if (this.map.getLayer(layerId)) {
        this.map.moveLayer(clickLayerId, layerId);
        return;
      }
    }
  };

  _onMouseOut() {
    const hoverLayerId = "mp-neighborhoods-layer-hover";
    this.map.setFilter(hoverLayerId, ["==", "id", false]);

    this.map.getCanvas().style.cursor = "";
  }

  _setMouseMove(e) {
    const isZoomed = this.map.getZoom() >= 13;
    const hoverLayerId = "mp-neighborhoods-layer-hover";
    if (
      this.state.isDrawing ||
      Draw.getMode() === "draw_polygon" ||
      Draw.getMode() === "direct_select"
    ) {
      this._onMouseOut();
      return;
    }
    if (isZoomed && !this.isHovering) {
      if (!this.map.getLayer(hoverLayerId)) {
        console.warn("Hover layer does not exist.");
        return;
      }

      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["mp-neighborhoods-layer-default"],
      });

      if (features.length > 0) {
        const feature = features[0];
        const neighborhoodId = feature.properties.id;
        const neighborhoodName = feature.properties.name;

        this.map.setFilter(hoverLayerId, ["==", "id", neighborhoodId]);
      } else {
        this.map.setFilter(hoverLayerId, ["==", "id", ""]);
      }
    }
  }
  //function to get bounds
  calculateBoundingBox(polyHoods) {
    let minLng = Infinity,
      minLat = Infinity,
      maxLng = -Infinity,
      maxLat = -Infinity;

    polyHoods.forEach((polygon) => {
      polygon.coordinates.forEach((coord) => {
        const [lng, lat] = coord.map(Number); // Convert string to number
        if (lng < minLng) minLng = lng;
        if (lat < minLat) minLat = lat;
        if (lng > maxLng) maxLng = lng;
        if (lat > maxLat) maxLat = lat;
      });
    });

    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ];
  }
 
  componentWillUnmount() {
    if (this.draw) {
      this.map.removeControl(this.draw);
    }

    if (this.state.isMapClose) {
      this.map.remove();
    }
  }
  _getBoundsByName(name) {
    if (!this.state.mapLoaded) return;

    const features = this.map.queryRenderedFeatures({
      layers: ["mp-neighborhoods-layer-default"],
      filter: ["==", "name", name],
    });

    if (features.length > 0) {
      const feature = features[0];
      const coordinates = feature.geometry.coordinates[0];

      const bounds = coordinates.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

      this.map.fitBounds(bounds, { padding: 20 });
    } else {
      console.log("Bounds not found for the name:", name);
    }
  }
  _makeMap = () => {
    if (
      this.props.mapContainerWidth === 0 &&
      this.state.mapContainerWidth === 0 &&
      !this.props.isListingForMobileHeroCarousel
    ) {
      return;
    }

    if (!mapboxgl.supported()) {
      return this.setState({ mapUnsupported: true });
    }

    this.setState({ mapLoading: true });

    const defaultZoom = this._calcDefaultZoomLevel();
    const { lng, lat, zoom, polyArea, centerPoint, polyHoods, mapLoaded } =
      this.state;

    let bounds = false;
    let mapData;

    if (!this.props.standardView) {
      mapData = {
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/ckyuong66001614ofy64tjg7c",
        zoom: zoom,
        minZoom: 9,
        center: [lng, lat],
      };
    } else {
      mapData = {
        container: this.mapContainer,
        style: "mapbox://styles/ningzhou/cjxxu65iq088b1cqgzu7sbxy2",
        zoom: zoom,
        minZoom: 9,
        center: [lng, lat],
      };
    }

    // if (polyArea && polyArea.length > 0) {
    //   var polygon = polyArea[0].geometry.coordinates;
    //   var fit = new L.Polygon(polygon).getBounds();
    //   var southWest = new mapboxgl.LngLat(
    //     fit["_southWest"]["lat"],
    //     fit["_southWest"]["lng"]
    //   );
    //   var northEast = new mapboxgl.LngLat(
    //     fit["_northEast"]["lat"],
    //     fit["_northEast"]["lng"]
    //   );
    //   var boundBox = new mapboxgl.LngLatBounds(southWest, northEast);

    //   bounds = [
    //     boundBox.getSouthWest().toArray(),
    //     boundBox.getNorthEast().toArray(),
    //   ];
    //   mapData.bounds = bounds;
    // }

    this.map = new mapboxgl.Map(mapData);
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(Draw, "top-right");
    this.geoLocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      fitBoundsOptions: {
        maxZoom: 17,
      },
      trackUserLocation: false,
    });

    this.map.addControl(this.geoLocate);

    const canvas = this.map ? this.map.getCanvas() : undefined;

    if (
      (this.props.mapContainerWidth > 0 &&
        this.state.mapContainerWidth === 0) ||
      (this.props.mapContainerWidth === 0 &&
        canvas &&
        canvas.offsetWidth !== undefined &&
        (canvas.offsetWidth === 0 || canvas.offsetWidth === 400)) ||
      (this.props.mapContainerWidth === 0 &&
        canvas &&
        canvas.offsetWidth &&
        canvas.offsetWidth < this.props.mapContainerWidth - 32)
    ) {
      var map = L.map(this.mapContainer);
      map.invalidateSize();
    }

    this.map.once("styledata", () => this.setState({ styleLoaded: true }));
    this.map.once("render", () => this.setState({ mapRendered: true }));

    this.map.on(
      "load",
      function () {
        this._addSourceAndLayers(boroughTileset);
        this._addSourceAndLayers(majorMarketTileset);
        this._addSourceAndLayers(neighborhoodTileset);
        this._addLocationNames(MapUtil._parseToGeoJSON([]));

        if (!this.map.getLayer("mp-neighborhoods-layer-click")) {
          this.map.addLayer({
            id: "mp-neighborhoods-layer-click",
            source: "neighborhoodTileset",
            "source-layer": "neighborhoods",
            type: "fill",
            paint: {
              "fill-color": "#000",
              "fill-opacity": 0.5,
            },
          });
        }

        // Add saved polygons
        // this.renderSavedPolygons();

        this.moveClickLayerBelowDrawLayers("mp-neighborhoods-layer-click");

        const { searchTags } = this.props;
        // if (searchTags && searchTags.length > 0) {
        //   const neighborhoodIds = searchTags.map((tag) => `${tag.id}_ny`);
        //   const clickLayerId = "mp-neighborhoods-layer-click";
        //   this.setState({ clickedNeighborhoods: neighborhoodIds });
        //   this.map.setFilter(clickLayerId, ["in", "id", ...neighborhoodIds]);
        // }
        console.log(polyArea, "polyarea")

        if (searchTags && searchTags.length > 0) {
          searchTags
            .filter((item) => Boolean(item.geometry))
            .forEach((polygon) => {
              // Draw.add(polygon.geometry);
              Draw.add({
                id: polygon.id,
                type: "Feature",
                geometry: polygon.geometry,
              });
            });
const refereshPolyArea=searchTags.filter((item)=>item.id==="custom-area")
  console.log(refereshPolyArea, "polyarearefereshPolyArea")
if(refereshPolyArea.length){
  refereshPolyArea[0].data.forEach((polygon) => {
    // Draw.add(polygon.geometry);
    Draw.add({
      id: polygon.polygon_id,
      type: "Feature",
      geometry: {
        type: polygon.type,
        coordinates: polygon.coordinates
      }
    });
  });
}
          const allFeatures = Draw.getAll();
          // if (allFeatures.features.length > 0) {
          //   Draw.changeMode("direct_select", {
          //     featureId: allFeatures.features[0].id,
          //   });

          // }
        } else {
          Draw.changeMode("simple_select");
        }

        this.map.on("draw.selectionchange", (e) => {
          this.setState({ isDrawing: false });
        });
        this.map.on("draw.create", this.createMapArea.bind(this));
        this.map.on("draw.update", this.updateMapArea.bind(this));
        this.map.on("draw.delete", this.deleteMapArea.bind(this));
        //this is default mouse move, as soon as map loads its activated
       this.map.on("mousemove", this._setMouseMove.bind(this));
        this.map.on("mouseout", this._onMouseOut.bind(this));
         this.map.on("click", this.handleMapClick.bind(this));
        this.moveDrawLayersToTop();

        this.map.on(
          "styledata",
          function () {
            const drawLayerIds = [
              "gl-draw-polygon-fill-cold",
              "gl-draw-polygon-fill",
              "gl-draw-polygon-stroke-cold",
              "gl-draw-polygon-stroke",
              "gl-draw-polygon-fill.cold",
              "gl-draw-polygon-fill.hot",
              "gl-draw-polygon-and-line-vertex-active.cold",
              "gl-draw-polygon-and-line-vertex-active.hot",
              "gl-draw-line-active.cold",
              "gl-draw-line-active.hot",
            ];
            drawLayerIds.forEach((layerId) => {
              if (this.map.getLayer(layerId)) {
                this.map.moveLayer(layerId);
              }
            });
          }.bind(this)
        );

        if (this.props.killMobileScroll) {
          this.map.dragPan.disable();
        }

        this._getPolyHoods();
        if (bounds) {
          // this.map.fitBounds(bounds, {
          //   padding: 250,
          // });
        } else {
        }

        this.tooltip = new mapboxgl.Marker(this.tooltipContainer, {
          offset: [0, -165],
        })
          .setLngLat([0, 0])
          .addTo(this.map);

        this.setState({ mapLoaded: true, mapLoading: false });
        const hoodTags =
          searchTags.filter((item) => item.type === "neighborhood").length > 0
            ? searchTags.filter((item) => item.type === "neighborhood")
            : [];
        if (
          hoodTags &&
          hoodTags.length &&
          hoodTags[0].data.boro !== "manhattan"
        ) {
          // const bounds = this.calculateBoundingBox.bind(this)();
          // console.log(bounds, "bounds")
          //  if(this.state.polyHoods.length){
          //   map.fitBounds([], {
          //     padding: 20
          //   });
          // //  }
          //   setTimeout(()=>{this.setState({isFlying:true})},1000)
        }
        setTimeout(() => {
          this.setState({ isFlying: true });
          // this.map.flyTo({
          //   center: centerPoint,
          //   zoom: 13,
          //   essential: true,
          // });
        }, 3000);
        this.map.on("moveend", () => {
          if (!this.justFetched) {
            if (!this.state.locationChange && !this.justResized) {
              this.justSetMapBounds = true;
            }
          }

          this.setState({ locationChange: !this.state.locationChange });
          this.currentlyFlying = false;
        });

        this.checkTilesAndSourcesInterval = setInterval(() => {
          if (
            this.map.areTilesLoaded() &&
            this.map.isSourceLoaded("mp-boroughs") &&
            this.map.isSourceLoaded("mp-major-markets") &&
            this.map.isSourceLoaded("mp-neighborhoods")
          ) {
            clearInterval(this.checkTilesAndSourcesInterval);
            this.setState({ tilesAndSourcesLoaded: true });
          }
        }, 100);
      }.bind(this)
    );
  };



  updateMapArea(e) {
    this._onMouseOut();
    if (!e.features || e.features.length === 0) return;

    const updatedPolygons = e.features.map((feature) => ({
      id: feature.id,
      geometry: feature.geometry,
    }));

    this.setState(
      (prevState) => {
        const updatedPolyArea = prevState.polyArea.map((polygon) => {
          const updatedPolygon = updatedPolygons.find(
            (upPolygon) => upPolygon.id === polygon.id
          );
          return updatedPolygon ? updatedPolygon : polygon;
        });

        return { polyArea: updatedPolyArea };
      },
      () => {
        console.log("Polygon areas after update:", this.state.polyArea);
      }
    );
  }

  handleMapClick(e) {
    const draw = Draw.getMode();
console.log(draw, "myDraw");
console.log(this.map.getZoom(), "myDraw1")
    if (draw === "draw_polygon") return;

    const polygonLayers = [
      "gl-draw-polygon-fill.cold",
      "gl-draw-polygon-fill.hot",
      "gl-draw-polygon-and-line-vertex-active.cold",
      "gl-draw-polygon-and-line-vertex-active.hot",
      "gl-draw-line-active.cold",
      "gl-draw-line-active.hot",
    ];

    let polygonFeatures = [];
    polygonLayers.forEach((layer) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [layer],
      });
      if (features.length > 0) {
        polygonFeatures = features;
      }
    });

    if (polygonFeatures.length > 0) {
      return;
    }

    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ["mp-neighborhoods-layer-default"],
    });

    if (features.length > 0 && !this.isClickable) {
      const feature = features[0];
      const neighborhoodId = feature.properties.id;
      const neighborhoodName = feature.properties.name;
      const boroughName = feature.properties.region;
      const formattedNeighborhoodName = neighborhoodName
        .toLowerCase()
        .replace(/\s+/g, "-");

      const areaData = {
        type: "neighborhood",
        data: {
          hood: neighborhoodName.toLowerCase(),
          boro: boroughName,
        },
        id: `${formattedNeighborhoodName}_${boroughName.toLowerCase()}`,
        name: neighborhoodName,
        clicked: true,
      };

      this.setState(
        (prevState) => {
          let newClickedNeighborhoods = prevState.clickedNeighborhoods || [];
          let newPolyArea = prevState.polyArea || [];
          let newdeletedTagsID = prevState.deletedTagsID;
          if (newClickedNeighborhoods.includes(neighborhoodId)) {
            newClickedNeighborhoods = newClickedNeighborhoods.filter(
              (id) => id !== neighborhoodId
            );
            newPolyArea = newPolyArea.filter(
              (area) => neighborhoodName !== area.name
            );
            newdeletedTagsID = [...newdeletedTagsID, areaData.id];
          } else {
            newClickedNeighborhoods = [
              ...newClickedNeighborhoods,
              neighborhoodId,
            ];
            newPolyArea = [...newPolyArea, areaData];
          }
          return {
            clickedNeighborhoods: newClickedNeighborhoods,
            polyArea: [...newPolyArea],
            deletedTagsID: newdeletedTagsID,
            zoom: 13,
          };
        },
        () => {
          this.map.setFilter("mp-neighborhoods-layer-click", [
            "in",
            "id",
            ...this.state.clickedNeighborhoods,
          ]);
        }
      );
    }
  }

  createMapArea(e) {
    this.setState({ isDrawing: false });
    if (!e.features || e.features.length === 0) return;

    const newPolygons = e.features.map((feature) => ({
      id: feature.id,
      geometry: feature.geometry,
    }));

    this.setState(
      (prevState) => ({
        polyArea: [...(prevState.polyArea || []), ...newPolygons],
      }),
      () => {
        console.log("Polygon areas after creation:", this.state.polyArea);
      }
    );
  }

  deleteMapArea(e) {
    const deletedPolygonIds = e.features.map((feature) => feature.id);
    this.setState((prevState) => {
      const DeletedPolygons = prevState.polyArea.filter((polygon) =>
        deletedPolygonIds.includes(polygon.id)
      );
      let newdeletedTagsID = [...prevState.deletedTagsID, ...deletedPolygonIds];
      const remainingPolygons = (prevState.polyArea || []).filter(
        (polygon) => !deletedPolygonIds.includes(polygon.id)
      );

      return {
        polyArea: remainingPolygons,
        justDeleted: true,
        deletedTagsID: newdeletedTagsID,
      };
    });
  }

  _savePolyArea() {
    const { handleMapAreaTag, toggleMapModal, searchTags } = this.props;
    const { removeTag, removeMapTag } = this.props.actions;
    const { polyArea, deletedTagsID } = this.state;
    {console.log(searchTags, "serachTags11")}
    const updatedSearchTags = searchTags.filter(
      (tag) => deletedTagsID.map(tag => tag.replace('_ny', '')).includes(tag.id) && tag.type !== "area"
    );
console.log(updatedSearchTags,"serachTags111")
console.log(deletedTagsID, "serachTags111")
    if (updatedSearchTags.length) {
      console.log("serachTagsChintan")
      updatedSearchTags.forEach((item) => {
        removeTag(item);
      });
    }

    const updatedMapTag = searchTags.filter((tag) => tag.type == "area");
    if (updatedMapTag.length) {
      removeMapTag(deletedTagsID);
    }
    handleMapAreaTag(polyArea);
    toggleMapModal();
    this.isHovering = false;
    this.isClickable = false;
    this.setState({ deletedTagsID: [] });
  }

  render() {
    let dragging = false;
    const { modal, polyArea, justDeleted, showLayers, deletedTagsID } =
      this.state;
    const { mapModal, toggleMapModal, disableDraw } = this.props;
    const { hasClear } = this.props;

    if (this.state.mapUnsupported === true) {
      return (
        <div
          className={`c_map-wrapper ${this.props.isMobile ? "is-mobile" : ""}`}
          style={{
            position: "relative",
            height: this.props.fullSize
              ? "100%"
              : this.props.mapBoxSize
              ? this.props.mapBoxSize
              : "400px",
            width: "100%",
            background: "#363347",
            padding: "10px",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          <h4>Oops, your browser is not compatible with our maps.</h4>
          <p>Update your browser for the best experience on Marketproof.</p>
          <p>
            <a href="https://browsehappy.com/" target="_blank">
              <button className="c_primary-btn">Update Browser</button>
            </a>
          </p>
        </div>
      );
    }

    return (
      <>
        <TOOLTip title="Select locations or draw boundaries on the map to search">
          <Button
            onClick={toggleMapModal}
            variant="outlined"
            color=""
            className=""
            startIcon={isMobile ? null : <ShapePolygonPlusIcon />}
            style={{
              position: "absolute",
              right: "5px",
              background: "#858AA1",
              border: "none",
              color: "#FFF",
              fontSize: 12,
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 1px -3px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px 0px",
            }}
          >
            Map
          </Button>
        </TOOLTip>

        <Modal
          isOpen={mapModal}
          toggle={toggleMapModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary contact__modal share__modal `}
        >
          {console.log(this.props.searchTags, "searchTags")}
          {console.log(this.state.deletedTagsID, "searchTagsDeleted")}
          <div className="modal__body">
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={() => {
                  this.setState({
                    clickedNeighborhoods: [],
                    deletedTagsID: [],
                  });
                  this.isHovering = false;
                  this.isClickable = false;
                  toggleMapModal();
                }}
              />
              <h4 className="contact__title mb-0">Search Map Area</h4>
              <div className="mb-2">
                Draw boundaries on the map to search in the area.{" "}
              </div>
            </div>
            <div
              className={`c_map-wrapper ${
                this.props.isMobile ? "is-mobile" : ""
              }`}
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <LayerToggle
                showLayers={showLayers}
                layers={layers}
                clearAllLayers={this._clearAllLayers}
                toggleLayer={this._toggleLayer}
              />
              <div
                className={`c_map-container ${
                  this.props.isMobile ? "is-mobile" : ""
                }`}
                ref={(el) => (this.mapContainer = el)}
                style={{
                  height: "70vh",
                  width: "100%",
                }}
              />
              {this.props.fullSize &&
                this.props.isMobile &&
                this.state.buildingsSelected && (
                  <div className={`c_map-mobile-slider-outer`}>
                    {this.state.buildingsSelected.map((building) => {
                      return this._showBuilding(building);
                    })}
                  </div>
                )}

              <div
                style={{
                  fontSize: 14,
                  background: "#F5F7FA",
                  padding: 10,
                  border: "1px solid #780F9E",
                  position: "absolute",
                  margin: "-120px 50px 0",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 1px -3px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px 0px",
                }}
              >
                <span>
                  Click on the map to mark each corner of your boundary, and
                  double-click to finish your shape; double-click on any point
                  to adjust it, or click the trash can to remove it.
                </span>
              </div>
            </div>
            <ButtonToolbar className="modal__footer editPolygonBtn">
              {console.log(
                this.state.clickedNeighborhoods,
                "clickedNeighborhoods"
              )}
              <TOOLTip title="Cancel">
                <Button
                  onClick={() => {
                    this.setState({
                      clickedNeighborhoods: [],
                      isMapClose: true,
                      deletedTagsID: [],
                    });
                    this.isHovering = false;
                    this.isClickable = false;
                    toggleMapModal();
                  }}
                  variant="outlined"
                  color=""
                  className=""
                  startIcon={null}
                  disabled={this.props.saveInProgress}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
              </TOOLTip>
              {console.log(this.state.deletedTagsID, "deletedTagsID")}
              <TOOLTip title="Save">
                <Button
                  onClick={this._savePolyArea}
                  variant="contained"
                  color="primary"
                  className="buyersList__primary-button"
                  // disabled={( !polyArea || !polyArea.length ) && !justDeleted}
                  disabled={
                    (!polyArea || !polyArea.length) &&
                    this.props.searchTags.length === 0
                  }
                >
                  Search This Area
                </Button>
              </TOOLTip>
            </ButtonToolbar>
          </div>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  //  const filterID=["midtown-east_manhattan", "midtown_manhattan", "midtown-west_manhattan"]
  return {
    searchTags: Array.isArray(state.search.searchTags)
      ? state.search.searchTags.slice().map((tag)=>{
        if(tag.type==="neighborhood" && !tag.data.hood.includes("manhattan")){
          return {...tag, id: `${ tag.data.hood.toLowerCase()
            .replace(/\s+/g, "-")}_${tag.data.boro}`}
        }
        else if(tag.type==="neighborhood" && tag.data.hood.includes("manhattan")){
          return {...tag, id: `${ tag.data.hood.replace("manhattan", "").trim().toLowerCase()
            .replace(/\s+/g, "-")}_${tag.data.boro}`}
        }
        else return tag
      })
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchMapPolygon);
