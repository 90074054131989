import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from 'mdi-react/SortIcon';
import FilterListIcon from 'mdi-react/FilterListIcon';

class MatTableFilterButton extends React.Component {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
  };

  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSort = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <IconButton
          className=""
          color="inherit"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <SortIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <MenuItem onClick={this.handleSort('name')} className="material-table__filter-menu-item">Building Name</MenuItem>
          <MenuItem onClick={this.handleSort('borough')} className="material-table__filter-menu-item">Borough</MenuItem>
          <MenuItem onClick={this.handleSort('inventory_pct_unsold')} className="material-table__filter-menu-item">
            % Unsold
          </MenuItem>
          <MenuItem onClick={this.handleSort('sales_start_date')} className="material-table__filter-menu-item">Sales Started</MenuItem>
          <MenuItem onClick={this.handleSort('inventory_unsold')} className="material-table__filter-menu-item">Units Unsold</MenuItem>
          <MenuItem onClick={this.handleSort('inventory_total')} className="material-table__filter-menu-item">Total Units</MenuItem>
          <MenuItem onClick={this.handleSort('inventory_stats_listings_average_ppsf')} className="material-table__filter-menu-item">Price / Sq Ft</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default MatTableFilterButton;
