import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import CommercialIcon from 'mdi-react/StoreIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
	_formatNumber
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (statsRes, bldgArea) => {	

	return [{ value: bldgArea.totalCommercial, fill: '#780F9E' },
  				{ value: bldgArea.totalOther, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, statsRes, data, buildingRes, bldgArea } ) => {
	let totalArea = bldgArea.totalArea;
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10, borderRadius:0, maxHeight:300 }}  >
	    	<div className="card__title">
	          <h5 className="card__title-center">{`% Commercial Space`}
	            <ToolTip 
	        		title={`% of total building area designated for non-residential use such as retail.`} 
	        	/>
	          </h5>
	        </div>
	        <div className="dashboard__health-chart product-card"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={data} dataKey="value" cy={75} innerRadius={70} outerRadius={80} />
	
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="pie-chart-info">
	            <CommercialIcon style={{ fill: '#780F9E' }} />
	            <h5 className="profile__chart-number">{data[0].value > 0 ? parseFloat((data[0].value / (data[0].value + (data[1].value ? data[1].value : 0)) * 100) ).toFixed(0) + '%' : '0%'}</h5>
	            <div className="product-card__subtext">commercial</div>
	          </div>
	           
	        </div>
	        <div className="product-card__subtext">({data[0].value > 0 ? _formatNumber(data[0].value) : '0'} sq ft of {totalArea > 0 ? _formatNumber(totalArea) : '--'} total sq ft)</div>
		</CardBody>);
};

const CommercialPercent = (props) => {
	const { buildingRes, statsRes, col, bldgArea } = props;
	const data = calculateBarData(statsRes, bldgArea);
	
	
	return (
		    <Card>
		
				<CardPercent data={data} statsRes={statsRes} buildingRes={buildingRes} bldgArea={bldgArea} />
			
			</Card>
    )
};



export default CommercialPercent;
