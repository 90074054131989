import React, { PureComponent, Fragment } from 'react';

import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Modal
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import AddToBuyersListButton from '../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../shared/components/buttons/ShareButton';

import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import moment from "moment";
import Slider from 'react-slick';

import ShareTopBar from './../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../shared/components/ShareContact'
import ShareFooter from './../../../../shared/components/ShareFooter'
import UnitIcons from './../../../../shared/components/UnitIcons';

import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';

import { FreezeTableBuildings, FreezeTableUnits } from './components/FreezeTables';
import { BuildingGridView, UnitsGridView } from './components/GridViews';

import { BuildingAnalysisView, UnitsAnalysisView } from './components/AnalysisViews';


import { withOktaAuth } from '@okta/okta-react';

import SingleUnitShare from '../../../SingleUnit/components/Share/index';
import SingleBuildingShare from '../../../SingleBuilding/components/Share/index';

import { Publisher } from './../../../../shared/pubsub/publisher.js';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus
} from '../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../shared/components/auth/sso/ssoutils';

const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;


const settings = {
	  dots: false,
	  infinite: false,
	  speed: 500,
	  autoplay: false,
	  swipeToSlide: true,
	  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
	  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
	  responsive: [
		{ breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
		{ breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
		{ breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
		{ breakpoint: 1536, settings: { slidesToShow: 4, slidesToScroll: 4 } },
		{ breakpoint: 100000, settings: { slidesToShow: 4, slidesToScroll: 4} },
	  ],
  };	


class CompReportShare extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  activeTab:  'buildings',      
	  fetchInProgress: false,
	  buildingRes: null,
	  unitRes: null,
	  userRes:null,
	  listingRes: null,
	  buildingsFetchInProgress: false,
	  modal: false,
	  modalShareData:false,
	  shareType:false,
	  unitStats: false,
	  buildingStats: false
	};
	this.buildingSlug = false;
	this.urlState = {};
  }
  
  componentDidMount() {
	  
	const { sharePageData } = this.props;
	
	if(sharePageData){
		this.buildingSlug = sharePageData.building_key;
		
		this.urlState = sharePageData.custom_data ? sharePageData.custom_data : {};
		if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.buildings && sharePageData.custom_data.group.group_data.buildings.length>0)){
			this.setState({activeTab:"units"})
		}
		
	}
	
	this._getBuildings();
	this._getUnits();
	this._getUserData();
		
	Publisher.publish(`track-mp.shareView.collection`, {collection: sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true });   
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {	
	 const { sharePageData } = this.props;
	 if(!prevState.buildingRes && this.state.buildingRes){
		Publisher.publish(`track-mp.shareView.collection`, {collection: sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true }); 
	 }	
	 
  }


  
  _getUserData = (key) => {
	 // console.log('_getBuildingData called', this.state)
	  const { sharePageData, updateShareData } = this.props;
	  if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
	  }
			
	  let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  
	  let userKey = sharePageData && sharePageData.user_key ? sharePageData.user_key : key;
	  
	  if(!userKey && sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.user_key){
		  userKey = sharePageData.custom_data.group.user_key;
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${userKey}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  
		  if(res && res.errorCode && res.errorCode == "E0000007"){
			  _getUser({id:sharePageData && sharePageData.user_key ? sharePageData.user_key : key}).then(ssoUser  => {
				  console.log(ssoUser)
				  if(ssoUser){
					  this.setState({
						  userRes:ssoUser,
						  fetchInProgress: false
					  })
					  
					  updateShareData(Object.assign({}, sharePageData, {user:ssoUser} ))
				  }
				  
			  })
		  }else{
			    this.setState({
					userRes:res.profile,
					fetchInProgress: false
				})
				
				updateShareData(Object.assign({}, sharePageData, {user:res.profile} ))
		  }
		  

	  })
	  .catch(error => {
		console.log("_getUserData error ", error);
		this.setState({
		  fetchInProgress: false
		})
	  });
	}

  
  _getBuildings = () => {
		 const { sharePageData } = this.props;
		 const { user } = sharePageData;
		  if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.buildings && sharePageData.custom_data.group.group_data.buildings.length>0)){
			    this.setState({ buildingRes : [] });
				return false
			}
		  const isPublic = sharePageData.custom_data.group && sharePageData.custom_data.group.group_access && sharePageData.custom_data.group.group_access == "public"; 
		  if (!this.state.buildingsFetchInProgress) {
			  this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		  }
		  
		  
		  var q = "key:("+sharePageData.custom_data.group.group_data.buildings.join(" ")+")";
		  
		  var fq = [
			  "deleted:false"		
		  ];
  
  //console.log(user && user.role )
		  let axiosData = {
			  url: apiURL + "/we3-buildings?cache=60",
			  method: "post",
			  query: {
				  q: q,
				  fq: fq,
				  fl: buildingFL,
				  wt: "json",
				  sort: "building_status desc, inventory_total desc",
				  rows: user && user.role && user.role.indexOf("admin")>=0 || isPublic ? 999 : 50
			  }
		  };
		  
		  if(sharePageData.custom_data && sharePageData.custom_data.show_stats){
		  	axiosData.query["json.facet"] = {
			  		"inventory_total" : "sum(inventory_total)",
					"inventory_unsold" : "sum(inventory_unsold)",
					"inventory_active" : "sum(inventory_active)",
					"avg_ppsf" : "sum(product(inventory_stats_listings_average_ppsf, inventory_total))",
					"min_price" : "min(inventory_stats_listings_low_price)",
					"max_price" : "max(inventory_stats_listings_high_price)",
					"listing_price" : {
						"type" : "query",
						"q" : "inventory_stats_listings_low_price:[250000 TO *]",
						"facet" : {
							"min_price" : "min(inventory_stats_listings_low_price)",
						}
					},
					"status" : {
						"type" : "terms",
						"field" : "building_status"
					}
			 }
		  }
		  
  
		  let formattedData = _axiosCall(axiosData)
			  .then(res => {
				  //this._setBuyersList(res);
				  if(res && res.docs){
					  
					  this.setState({ buildingRes : res.docs, buildingStats: res.facets, fetchInProgress : false, buildingsFetchInProgress: false });
				  }else{
					  this.setState({ buildingRes : [], buildingStats:{}, fetchInProgress : false, buildingsFetchInProgress: false });
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
			  
  
		  // console.log(formattedData)
		  return formattedData;
	};

   _getUnits = () => {
		const { sharePageData } = this.props;
		const { user } = sharePageData;
		 if(!(sharePageData && sharePageData.custom_data && sharePageData.custom_data.group && sharePageData.custom_data.group.group_data && sharePageData.custom_data.group.group_data.units && sharePageData.custom_data.group.group_data.units.length>0)){
			   this.setState({ unitRes : [] });
			   return false
		 }
		 
		 const isPublic = sharePageData.custom_data.group && sharePageData.custom_data.group.group_access && sharePageData.custom_data.group.group_access == "public";
		  
		 if (!this.state.fetchInProgress) {
			 this.setState(prevState => ({ fetchInProgress: true, }));
		 }
		
		 let q = "key:("+sharePageData.custom_data.group.group_data.units.join(" ")+")";
		 
		 let fq = [
			 "deleted:false"			
		 ];
   
		 let axiosData = {
			 url: apiURL + "/we3-properties?cache=60",
			 method: "post",
			 query: {
				 q: q,
				 fq: fq,
				 fl: [...propertyFL],
				 wt: "json",
				 sort: "building_name asc, display_full_street_address asc, address_2_normalized asc",
				 rows: user && user.role && user.role.indexOf("admin")>=0 || isPublic ? 999 : 50,
				 
			 }
		 };
		 
		 if(sharePageData.custom_data && sharePageData.custom_data.show_stats){
			axiosData.query["json.facet"] = {
					  "asking_price": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *]`,
						  "facet": {
							  "low": `min(last_listed_sale_price_all)`,
							  "high": `max(last_listed_sale_price_all)`,
							  "average": `avg(last_listed_sale_price_all)`,
							  "sum": `sum(last_listed_sale_price_all)`
							  
						  }
					  },
					  "asking_ppsf": {
						   "type": "query",
						   "q": `last_listed_sale_price_all:[100000 TO *] AND sq_ft:[100 TO *]`,
						   "facet": {
							   "low": `min(div(last_listed_sale_price_all, sq_ft))`,
							   "high": `max(div(last_listed_sale_price_all, sq_ft))`,
							   "average": `avg(div(last_listed_sale_price_all, sq_ft))`,
						   }
					   },
					   "sold_price": {
							"type": "query",
							"q": `last_sold_price:[100000 TO *] AND sq_ft:[100 TO *]`,
							"facet": {
								"low": `min(last_sold_price)`,
								"high": `max(last_sold_price)`,
								"average": `avg(last_sold_price)`,
								"sum": `sum(last_sold_price)`
								
							}
						},
						"sold_ppsf": {
							 "type": "query",
							 "q": `last_sold_price:[100000 TO *] AND sq_ft:[100 TO *]`,
							 "facet": {
								 "low": `min(div(last_sold_price, sq_ft))`,
								 "high": `max(div(last_sold_price, sq_ft))`,
								 "average": `avg(div(last_sold_price, sq_ft))`,
							 }
						 },
					  "total_sold": {
						  "type": "query",
						  "q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)`,
						  "facet": {
							  "low_price": "min(last_listed_sale_price_all)",
							  "high_price": "max(last_listed_sale_price_all)",
							  "average_price": "avg(last_listed_sale_price_all)",
							  "average_sq_ft": "avg(last_listed_sale_price_all)",
							  "sum_price": "sum(last_listed_sale_price_all)",							
							  "low_sq_ft": "min(sq_ft)",
							  "high_sq_ft": "max(sq_ft)",
							  "low_bed": "min(bedrooms)",
							  "high_bed": "max(bedrooms)",
							 
							  
						  }
					  },
					  "sq_ft": {
						  "type": "query",
						  "q": `sq_ft:[1 TO *]  AND -property_type:"resident manager unit"`,
						  "facet" : {
									  "avg_sq_ft": "avg(sq_ft)",
									  "sum_sq_ft": "sum(sq_ft)"
								  }
						  
					  },						
					  "original_owner" : {
						  "type": "query",
						  "q": `times_exchanged:[0 TO *] AND owner:*`,
						  "facet" : {
							  "times_sold" : {
								  "type" : "terms",
								  "field" : "times_sold"
							  },
							  "times_exchanged" : {
								  "type" : "terms",
								  "field" : "times_exchanged"
							  }
							  
						  }
												  
					  },
					  "corp_owner" : {
						  "type": "query",
						  "q": `(owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c))`,																	
					  }
			  }
		 }
		 
   
		 let formattedData = _axiosCall(axiosData)
			 .then(res => {
				 //this._setBuyersList(res);
				 if(res && res.docs){
					 let keys = res.docs.map(unit => unit.last_listed_sale_key_all);
					 if(!keys || keys.length<=0){
						this.setState({ unitRes : res.docs, unitStats: res.facets ? res.facets : {}, fetchInProgress : false, fetchInProgress: false });
					 }else{
						this._getListingData(res.docs, keys, res.facets);
					 }
					 
					 
				 }else{
					 this.setState({ unitRes : [], fetchInProgress : false, fetchInProgress: false });
				 }
				 
			 })
			 .catch(error => {
				 console.log("error: " + error);
			 });
			 
   
		 // console.log(formattedData)
		 return formattedData;
   };
   
   _getListingData = async (unitRes, keys, unitStats) => {
			
		  if(!keys || keys.length<=0){
			  return false;
		  }
		  
		  let q = "key:("+keys.join(" ")+")";
   
		  let axiosData = {
			  url: apiURL + "search-listings?cache=15",
			  method: "post",
			  query: {
				  q: q,
				  fq: ["deleted:false","sale_status:active"],
				  sort: "solr_updated_date desc",
				  wt: "json",
				  rows: 10
			  }
		  };
   
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  if(res.docs && res.docs.length>0){
					  unitRes.map(unit => {
						  unit['listing'] = res.docs.filter(l => l.key == unit['last_listed_sale_key_all']).length > 0 ? res.docs.filter(l => l.key == unit['last_listed_sale_key_all'])[0] : false;
					  })					
					  this.setState({unitRes: unitRes, unitStats: unitStats ? unitStats : {}});
				  }else{
					  this.setState({unitRes: unitRes, unitStats: unitStats ? unitStats : {}});
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
		  return formattedData;
	};
   
   _getACRISData = async () => {
			if (!this.state.fetchInProgress) {
			   this.setState(prevState => ({ fetchInProgress: true, }));
			}
   
		   const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		   const { buildingRes } = this.props;		
		   const unit = this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*");		
		   const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip}) AND address_2_normalized:(${unit})`;
   
		   let axiosData = {
			   url: apiURL + "we3-transactions?cache=15",
			   method: "post",
			   query: {
				   q: q,
				   fq: ["source_providers:*ACRIS*"],
				   sort: "close_date_formatted desc",
				   wt: "json",
				   rows: 10
			   }
		   };
   
		   let formattedData = await _axiosCall(axiosData)
			   .then(res => {
				   // console.log('Chart Data', res);
				   if(res.docs && res.docs.length>0){						
					   this.setState({transactions:res.docs, fetchInProgress: false});
				   }else{
					   this.setState({transactions:false, fetchInProgress: false});
				   }				
			   })
			   .catch(error => {
				   console.log("error: " + error);
				   this.setState({transactions:false, fetchInProgress: false});
			   });
		   return formattedData;
	 };
   
	 
	 
   
   toggle = (tab) => {
	   const { activeTab } = this.state;
	   this.setState({activeTab: tab})
   };
   
   toggleModal(type, data) {
	   //this.snackOpen('Your Message Has Been Sent!', 'success');
	   const { sharePageData } = this.props;
	   
	   let modalShareData = JSON.parse(JSON.stringify(sharePageData));
	   
	   delete modalShareData['group'];
	   modalShareData['report_type'] = "single";
	   if(type == "building"){
		   modalShareData['building_key'] = data.key;
		   modalShareData['building'] = data;
		   
	   }else if(type == "unit"){
		   modalShareData['building_key'] = data.building;
		   modalShareData['property_key'] = data.key;
		   modalShareData['property'] = data; 
	   }
	   
	   this.setState(prevState => ({ modal: !prevState.modal, modalShareData: modalShareData, shareType: type }));
   }

  
	
  render() {
	const { rtl, sharePageData, preview } = this.props;
	const { buildingRes, unitRes, unitStats, buildingStats, urlState, userRes, fetchInProgress, buildingsFetchInProgress, activeTab, modal, modalShareData, shareType } = this.state;
	
	const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
	}	
	
	const user = Object.assign({}, defaultUser, userRes);
	
	if(userRes === null || buildingRes === null || unitRes === null || fetchInProgress || buildingsFetchInProgress){
		return <LinearLoading />;
	}
	
	const group = sharePageData && sharePageData.custom_data && sharePageData.custom_data.group ? sharePageData.custom_data.group : false;
	const showTabs = group && group.group_data && group.group_data.buildings && group.group_data.buildings.length>0 && group.group_data.units && group.group_data.units.length>0
	
	return (
		<div className="share__wrapper">
			{!preview && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />}
			<Container className="share dashboard">
				{!preview && <ShareContact  user={user} urlState={this.urlState} sharePageData={sharePageData}/>}
				{/*buildingRes && buildingRes.length > 0 &&
					<ShowBuildingsTable data={buildingRes} title={"Manhattan"} color={"#4ce1b6"} />
				*/}
				{(sharePageData.custom_data && sharePageData.custom_data.report_title || sharePageData.custom_data.prepared_for) &&
					<div className={`mb-3 ${isMobile ? "" : "mt-5"}`}>
						{sharePageData.custom_data && sharePageData.custom_data.report_title &&
							<h3 className="page-title mb-1">{sharePageData.custom_data.report_title}</h3>
						}
						{sharePageData.custom_data && sharePageData.custom_data.report_subtitle &&
							<h4 className="mb-1">{sharePageData.custom_data.report_subtitle}</h4>
						}
						<hr/>
					</div>
				}
				
				{showTabs &&
				<div className="tabs tabs--bordered-bottom mb-0">
				  <div className="tabs__wrap">
					<Nav tabs>
						{group && group.group_data && group.group_data['buildings'] && group.group_data['buildings'].length>0 &&
							  <NavItem>
								<NavLink
								  className={classnames({ active: activeTab === 'buildings' })}
								  onClick={() => {
									this.toggle('buildings');
								  }}
								>
								  <span className="tab-title">Buildings</span>
								</NavLink>
							  </NavItem>
						  }
						{group && group.group_data && group.group_data['units'] && group.group_data['units'].length>0 &&
							  <NavItem>
								  <NavLink
									className={classnames({ active: activeTab === 'units' })}
									onClick={() => {
									  this.toggle('units');
									}}
								  >
									<span className="tab-title">Units</span>
								  </NavLink>
								</NavItem>
						}
					</Nav>
				  </div>
				</div>
			}
				<TabContent activeTab={activeTab}>
				 {activeTab === 'buildings' && 
				   <TabPane tabId="buildings">
						{buildingRes && buildingRes.length > 0 &&
							<div className="mb-5">
								{sharePageData.custom_data && sharePageData.custom_data.show_stats &&
									<BuildingAnalysisView buildingStats={buildingStats} />
								}
								{sharePageData.report_type == "comp" && !isMobile ? 
									<FreezeTableBuildings buildingRes={buildingRes} toggleModal={this.toggleModal.bind(this)} />
								:
									<BuildingGridView buildingRes={buildingRes} toggleModal={this.toggleModal.bind(this)} />
								}
							</div>
						}
					</TabPane>
				}
				{activeTab === 'units' && 
				   <TabPane tabId="units">
						{unitRes && unitRes.length > 0 &&
							<div className="mb-5">
								{sharePageData.custom_data && sharePageData.custom_data.show_stats &&
									<UnitsAnalysisView unitStats={unitStats} />
								}
								{sharePageData.report_type == "comp" && !isMobile ? 
									<FreezeTableUnits unitRes={unitRes} toggleModal={this.toggleModal.bind(this)} />
									:
									<UnitsGridView unitRes={unitRes} toggleModal={this.toggleModal.bind(this)} />							
								}
								
							</div>
						}
					</TabPane>
				}
				</TabContent>
				<Row>
				  <Col md={12}>
						<p className={"small-text light-text mt-4 mb-2"} style={{color:'#858AA1'}}>All information is from sources deemed reliable but is subject to errors and omissions. No representation is made as to the accuracy of any information. All information is subject to change. All rights to content, photographs, and graphics are reserved for the content source.</p>
					</Col>
				 </Row>
			</Container>
			{!preview && <ShareFooter sharePageData={sharePageData}/>}
			<Fragment>
				<Modal
				  isOpen={modal}
				  toggle={this.toggleModal.bind(this)}
				  modalClassName={`ltr-support`}
				  className={`modal-dialog contact__modal`}
				>
				<div className="modal__header">
					<button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggleModal.bind(this)} />
				</div>
				<div className="modal__body">
					{shareType == "building" ? 
					<SingleBuildingShare sharePageData={modalShareData} isModal />
					:
					<SingleUnitShare sharePageData={modalShareData} isModal />
					}
				</div>
				</Modal>
			 </Fragment>
		</div>
	);
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(withOktaAuth(CompReportShare))));
