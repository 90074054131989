/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NewOrderTableProps } from '../../../../shared/prop-types/TablesProps';
import moment from "moment"; 
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';

import BuyersList from './Chat';

import Panel from '../../../../shared/components/Panel';

const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}><DropdownItem>Messsage</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const buyersList =  [
  {
    avatar: `${process.env.PUBLIC_URL}/img/11.png`,
    name: 'Peter Jackson',
    userName: 'littleOrc',
    firm: 'Corcoran',
    messages: [],
  },
  {
    avatar: `${process.env.PUBLIC_URL}/img/12.png`,
    name: 'Lora Melbourn',
    userName: 'dragon666',
    firm: 'Douglas Ellimam',
    messages: [],
  },
  {
    avatar: `${process.env.PUBLIC_URL}/img/15.png`,
    name: 'Cathy Mew',
    userName: 'blackCat',
    firm: 'Compass',
    messages: [],
  },
  {
    avatar: `${process.env.PUBLIC_URL}/img/14.png`,
    name: 'Liza Orly',
    userName: 'lily',
    firm: 'Halstead',
    messages: [],
  },
  {
    avatar: `${process.env.PUBLIC_URL}/img/24_mail_ava.png`,
    name: 'Michael Bro',
    userName: 'bro',
    firm: 'Brown Harris Stevens',
    messages: [],
  },
];


DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const ListAmount = ({ lists }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': lists.count <= 5,
    'dashboard__table-orders-amount--low': lists.count <= 3,
  });
  return (
      <div className={amountClass}>
       {lists.map(list => <div />)}
        <span>{lists.length}</span>
      </div>
    );
};

ListAmount.propTypes = {
  lists: PropTypes.array,
};

ListAmount.defaultProps = {
  lists: ['key', 'key', 'key'],
};

const NewOrders = ({ t, onDeleteRow }) => (

<div>
  <Panel
    xl={12}
    lg={12}
    md={12}
    title={`On the BuyersList`}
    subhead=""
  >
    <Table responsive striped className="dashboard__table-orders">
      <thead>
        <tr>
          <th>Name</th>          
          <th>Firm</th>
          <th>Lists Joined</th>
          <th>Date Joined</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {buyersList.map((person, index) => (
          <tr key={index}>
            <td className="dashboard__table-orders-title">
              <div className="dashboard__table-orders-img-wrap">
                <div className="dashboard__table-orders-img" style={{ backgroundImage: `url(${person.avatar})` }} />
              </div>
              {person.name}
            </td>
            <td className="dashboard__table-orders-total" dir="ltr">{person.firm}</td>
            <td>
              <ListAmount lists={['key', 'key', 'key']} />
            </td>
            <td>{moment().fromNow()}</td>
            
            <td style={{padding:0}}>
            	<Tooltip title="Send Private Message">
                  	<IconButton
			            onClick={() => {}}
			            className="material-table__toolbar-button"
			          >

					  <MessageTextOutlineIcon color="#ddd" className="material-table__checkbox" /> 
				  	</IconButton>
			  	</Tooltip>

              {/*<DropDownMore index={index} handleDeleteRow={e => onDeleteRow(index, e)} />*/}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

  </Panel>
{/*  <BuyersList /> */}
  </div>
);

NewOrders.propTypes = {
  newOrder: NewOrderTableProps.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(NewOrders);
