import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import { connect } from 'react-redux';

import LogIn from '../LogIn/index';
import Buildings from '../Buildings/index';
import ComingSoon from '../ComingSoonBuildings/index';
import BuildingAdmin from '../BuildingAdministration/index';
import RentalBuildingAdmin from '../RentalBuildingAdministration/index';
import HouseAdmin from '../Houses/index';

import SingleBuilding from '../SingleBuilding/index';
import Units from '../Units/index';
import BuyersLists from '../BuyersLists/index';
import SingleUnit from '../SingleUnit/index';
import SavedUnits from '../BuyersLists/components/SavedUnits/index';
import Dashboard from '../Dashboard/index';
import Analysis from '../Analysis/index';
import BuyerLeads from '../Dashboard/components/BuyerLeads/index';
import News from '../News/index';
import TransactionCenter from '../TransactionCenter/index';
import KnowledgeCenter from '../KnowledgeCenter/index';

import DataExport from '../DataExport/index';
import Sales from '../Sales/index';
import MyReports from '../MyReports/index';
import MyGroups from '../MyGroups/index';

import NewDevSales from '../NewDevSales/index';
import ListingAnalysis from '../ListingAnalysis/index';
import DiscountAnalysis from '../DiscountAnalysis/index';
import PriceAnalysis from '../PriceAnalysis/index';

import MarketActivity from '../MarketActivity/index';
import SalesVelocity from '../SalesVelocity/index';

import Developers from '../Developers/index';
import Architects from '../Architects/index';
import Lenders from '../Lenders/index';

import Pipeline from '../Pipeline/index';

import Profile from '../Profile/index';
import Billing from '../Billing/index';

import MarketAnalysis from '../MarketAnalysis/index';
import SharePage from '../SharePage/index';

import SingleUnitShare from '../SingleUnit/components/Share/index';
import SingleBuildingShare from '../SingleBuilding/components/Share/index';

import PropertyMap from "../PropertyMap/index";


import { Security, LoginCallback } from '@okta/okta-react';
import { withOktaAuth } from '@okta/okta-react';
import { setUserProfile } from '../../redux/actions/authActions';
import buyersListActions from '../../redux/actions/buyersListActions';

import Home from '../../shared/components/auth/okta/Home';
import OktaLogin from '../../shared/components/auth/okta/Login';

import Shareables from '../Marketing/Shareables/index';


import{
	_axiosCall
} from '../../shared/helpers/apicalls';

import{
	_getUserSSO,
	_setSSOToken,
	_getSSOToken,
	_getMPUserSSO
} from '../../shared/components/auth/sso/ssoutils';

import{
	setLocalStorage,
	getLocalStorage
} from '../../shared/helpers/utils';
import MarketPulse from '../MarketPulse/index';
const oktaToken = process.env.OKTA_TOKEN;

const onAuthRequired = ( props ) => {
	alert("auth required");
	//console.log(props);
  //history.push('/login');

  return <Redirect to={{ pathname: `/login`}} />

}

const config = {
  issuer: `https://${process.env.OKTA_DOMAIN}/oauth2/default`,
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: `${process.env.OKTA_CLIENT_ID}`,
  onAuthRequired: onAuthRequired,
  pkce: true
};

const _getToken = () => {
	let ssoToken = _getSSOToken();
  	let type = "CBWarburg";
  	if(!ssoToken){
	 	ssoToken = _getSSOToken('MPSSO');
	 	if(ssoToken){
		 	type = "MPSSO";
	 	}
  	}
	return {ssoToken : ssoToken, type : type};
}

const Pages = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/buildings/:borough/:neighborhood/:building_slug" component={SingleBuilding} />
	<Route exact path="/buildings/:borough/:neighborhood/:building_slug/tab/:tab/:subtab/:subsubtab?" component={SingleBuilding} />
    <Route exact path="/buildings/:borough/:neighborhood/:building_slug/tab/:tab/:subtab?" component={SingleBuilding} />
  	<Route exact path="/buildings/:borough/:neighborhood/:building_slug/:unit" component={SingleUnit} />
  	<Route exact path="/buildings/:borough/:neighborhood/:building_slug/:unit/tab/:tab" component={SingleUnit} />
	  
	  
	<Route exact path="/inventory/:stage" component={Units} />
  	<Route exact path="/inventory" component={Units} />
	
    <Route path="/buildings" component={Buildings} />
    <Route path="/coming-soon" component={ComingSoon} />
    <Route exact path="/building-database/sale/:stage" component={BuildingAdmin} />
    <Route exact path="/building-database/sale" component={BuildingAdmin} />
    <Route path="/building-database/rental" component={RentalBuildingAdmin} />
	<Route path="/building-database/house/:type" component={HouseAdmin} />
	<Route path="/building-database/house" component={HouseAdmin} />

	<Route path="/map" component={PropertyMap} />
    
    <Route path="/my-buildings" component={BuyersLists} />
    <Route path="/my-units" component={SavedUnits} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/my-exports" component={DataExport} />
	<Route path="/my-shared" component={MyReports} />
	<Route path="/collections/:tab?" component={MyGroups} />
    <Route path="/news/:subtab?" component={News} />
    <Route path="/analysis/new-development" component={Analysis} />
	<Route path="/inventory-analysis" component={Analysis} />
    <Route path="/my-leads" component={BuyerLeads} />
    <Route path="/transaction-center" component={TransactionCenter} />
    <Route path="/community-center" component={KnowledgeCenter} />
    <Route path="/my-account" component={Profile} />
    <Route path="/my-subscription" component={Billing} />
	
	<Route path="/marketing/shareables" component={Shareables} />
    
    <Route path="/sales-agents" component={NewDevSales} />
    <Route path="/developers" component={Developers} />
    <Route path="/architects" component={Architects} />
    <Route path="/lenders" component={Lenders} />
    
    <Route path="/past-sales" component={Sales} />
    
    <Route path="/analysis/market-pulse" component={MarketActivity} />
	<Route path="/analysis/market-snapshots" component={MarketPulse} />
    
    <Route path="/analysis/sales-velocity" component={SalesVelocity} />
    
    <Route path="/analysis/market" component={MarketAnalysis} />
    
    <Route exact path="/analysis/discount" component={DiscountAnalysis} />
    <Route exact path="/analysis/discount/tab/:tab" component={DiscountAnalysis} />
    
    <Route exact path="/analysis/pricing" component={PriceAnalysis} />
    <Route exact path="/analysis/pricing/tab/:tab" component={PriceAnalysis} />
    
    <Route path="/filings" component={Pipeline} />
	
	<Route exact path="/private-report/:custom_link" component={SharePage} />
  </Switch>
);

class wrappedRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      fetchInProgress: false
    };
  }
  
  componentDidMount(){
	  const { authState, authService, dispatch, user, dataSet } = this.props;
	  const ssoToken = _getSSOToken();
	  
	   if (!authState.isPending && !authState.isAuthenticated && !ssoToken) {
	      // When user isn't authenticated, forget any user info
	      //setUserInfo(null);
	      dispatch(setUserProfile(null));
		  
	   } else {

		   if(!authState.isPending && !user && !ssoToken){
			  authService.getUser().then((user) => {
	        
		        this._getUserOktaData(user);
		        if(!dataSet){
			        this._getUserMongoData(user); 
		        }
		         
		     }, error => {
			      console.error(error);
		      });
		  }else if(ssoToken && ssoToken.access_token && !user){
			  console.log(ssoToken)
				 _getUserSSO(ssoToken, this.props, setUserProfile).then(() => {
					 
				  })
			 
			 
			  
		  }
	      
	  }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
	  const { authState, authService, dispatch, dataSet, user } = this.props;
	  console.log(authState, authService, user);
/*
	  if (!authState.isPending && !authState.isAuthenticated) {
	      // When user isn't authenticated, forget any user info
	      //setUserInfo(null);
	      dispatch(setUserProfile(null));
	   } else {
		   

		  if(!authState.isPending && !user){
			  authService.getUser().then((user) => {
	        
		        this._getUserOktaData(user);
		        if(!dataSet){
			        this._getUserMongoData(user); 
		        }
		         
		     }, error => {
			      console.error(error);
		      });
			  
		  }
	      

	  }
*/
  }
  
  _getUserOktaData = (user) => {
     // console.log('_getBuildingData called', this.state)
     if(!user) return false;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
      
      const { authState, authService, dispatch } = this.props;
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://${process.env.OKTA_DOMAIN}/api/v1/users/${user.sub}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res){
		      this.setState({ fetchInProgress : false });
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
	      }
		  

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({ fetchInProgress : false });
	    //dispatch(setUserProfile(user));
	  });
  }
  
  
  _getUserMongoData = (user) => {
  		if(!user) return false;
        if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
         }
      
        const { authState, authService, dispatch } = this.props;
      
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/userdata/${user.sub}`,
			method: "get"
		};

		 _axiosCall(axiosData)
			.then(res => {
				console.log('Data', res);
				if(res && res[0]){
					this.setState({ fetchInProgress : false });
					let mongoUser = Object.assign({}, res[0]);
					dispatch(buyersListActions.setUserData(mongoUser))
				}else{
					dispatch(buyersListActions.setUserData({}))
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

   }
  
  
  render(){	 
	  
	 	const { authService, authState, user, component, nochrome } = this.props;
		const ssoToken = _getSSOToken();		
		console.log(ssoToken, user)
		if (authState.isPending || (ssoToken && !user)) return null;
		if (authState.isAuthenticated || (user && user.authType === 'sso')){		
			
			if(user === null) return null;
			if(user && !user.subscriptionPlan){
				return <Redirect to={{ pathname: "/payment" }} />
			}
			
			
			if(nochrome && component){
				return <div>{component}</div>;
			}
		
		  if(window.location.pathname && window.location.pathname.indexOf("/private-report/")>=0){
			   
			   return(
				   <Route path="/" component={Pages} />
				)
		  }else{
			  return(
				   <div>
					  <Layout />
					  <div className="container__wrap">
						<Route path="/" component={Pages} />
					
						<div className="disclaimer">
						
						Marketproof, Inc. &copy; {new Date().getFullYear()}
						{` `} |  {` `}
						<a href="https://marketproof.com/terms-of-use" target="_blank">Terms of Service</a>
						{` `} |  {` `}
						<a href="https://marketproof.com/privacy-policy" target="_blank">Privacy Policy</a>
						
						</div> 
					  </div>
				   </div>
			  )
		  }
		   
	       
		     
		}else{
			if(window.location.pathname && window.location.pathname != "/login"){
				setLocalStorage("mp", "redirect", window.location.pathname);
			}
			
			return <Redirect to={{ pathname: `/learn-more` }} />;
		}

	
 }
}

export default connect(state => ({
  user: state.user,
  dataSet : state.buyersLists.dataSet
}))(withOktaAuth(wrappedRoutes));
