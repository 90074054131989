import { format as formatDate, parseISO, subYears, subWeeks } from 'date-fns';

const _sliceToLastNonZero = (arr, type) => {
  let lastIndex = arr.length - 1;
  while (lastIndex >= 0 && (!arr[lastIndex][type.key] || arr[lastIndex][type.key].count === 0)) {
	lastIndex--;
  }
  return arr.slice(0, lastIndex + 1);
};

function getPreviousMonthsData(cardListViewData) {
  if (!cardListViewData || cardListViewData.length <= 0 || !cardListViewData[cardListViewData.length - 1]) return [];
  return [cardListViewData[cardListViewData.length - 2], cardListViewData[cardListViewData.length - 1]];
}

function getPreviousYearData(cardListViewData, currentDate, isWeekly) {
  if (!cardListViewData) return [];
  let oneYearBackDate = new Date(currentDate);
  if (isWeekly === "+7DAY") {
	oneYearBackDate = subWeeks(oneYearBackDate, 52); // Subtract 52 weeks
  } else {
	oneYearBackDate = subYears(oneYearBackDate, 1); // Subtract 1 year
  }
  return cardListViewData.find(item => {
	let itemDate = parseISO(item.val);
	return itemDate.getTime() === oneYearBackDate.getTime();
  });
}

function getValueFromDotSeparatedKey(jsonObject, keyString) {
  if (!jsonObject || !keyString) return undefined;
  const keys = keyString.split('.');
  const getValue = (obj, keys) => {
	if (keys.length === 0) return obj;
	const [firstKey, ...restKeys] = keys;
	if (obj.hasOwnProperty(firstKey)) {
	  return getValue(obj[firstKey], restKeys);
	} else {
	  return undefined; // Return undefined if any key is not found
	}
  };
  return getValue(jsonObject, keys);
}
function formatTime(data, isWeekly, simple) {
	let month = data && data.val;
	
	if (month) {
	  const date = new Date(month);
	  const monthName = simple ? date.toLocaleString('default', { month: 'short', timeZone: 'UTC' }) : date.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
	  const year = date.getUTCFullYear();
	  if (isWeekly === "+7DAY") {
		const day = date.getDate();
		if(simple){
		  return `${monthName} ${day}, ${year}`
		}else{
		  return `Week of ${monthName} ${day}, ${year}`;
		}
		
		
	  } else if (isWeekly === "+1MONTH") {
		return `${monthName} ${year}`;
	  } else if (isWeekly === "+3MONTH") {
		const month = date.getUTCMonth(); // Get the month (0-11)
		const year = date.getUTCFullYear(); // Get the full year
		
		// Determine the quarter based on the month
		let quarter;
		if (month >= 0 && month <= 2) {
			quarter = 'Q1';
		} else if (month >= 3 && month <= 5) {
			quarter = 'Q2';
		} else if (month >= 6 && month <= 8) {
			quarter = 'Q3';
		} else {
			quarter = 'Q4';
		}
		
		return `${quarter} ${year}`;
	  }else{
		return `${year}`;
	  }
	} else {
	  return ""; 
	}
  }

const getPercentage = (val1, val2) => {
  if (val1 && val2) {
	const percentageChange = ((val1 - val2) / val2) * 100;
	return `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(2)}%`;
  } else {
	return "";
  }
};

export default function MarketCardToJson({ cardListViewData, isWeekly, checkedType, title, subTitle, fetchInProgress, type, statKey, formatter, returnData }) {
  cardListViewData = _sliceToLastNonZero(cardListViewData, type);
  
  const monthlyData = getPreviousMonthsData(cardListViewData);
  const yearlyData = getPreviousYearData(cardListViewData, monthlyData && monthlyData[1] && monthlyData[1].val, isWeekly);

  const keyStat = monthlyData && monthlyData[1] && monthlyData[1][type.key] && getValueFromDotSeparatedKey(monthlyData[1][type.key], statKey) ? formatter(getValueFromDotSeparatedKey(monthlyData[1][type.key], statKey)) : "--";
  const newVal = monthlyData && monthlyData[1] && monthlyData[1][type.key] ? getValueFromDotSeparatedKey(monthlyData[1][type.key], statKey) : false;
  const oldVal = monthlyData && monthlyData[0] && monthlyData[0][type.key] ? getValueFromDotSeparatedKey(monthlyData[0][type.key], statKey) : false;
  const yearVal = yearlyData && yearlyData[type.key] ? getValueFromDotSeparatedKey(yearlyData[type.key], statKey) : false;

  
  return {
	"Stat_Name" : title,
	"Stat_Period" : formatTime((monthlyData && monthlyData[1] ? monthlyData[1] : false), isWeekly),
	"Stat" : keyStat,
	"Stat_Percent_Change": getPercentage(newVal, oldVal),
	"Stat_Percent_Change_Type": isWeekly === "+7DAY" ? "vs. prior week" : isWeekly === "+1MONTH" ? "vs. prior month" : isWeekly === "+3MONTH" ? "vs. prior quarter" : "vs. prior year"
  };
}
