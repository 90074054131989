import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";

import { Col } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';
import RelatedUnitItems from './RelatedUnitItems';

import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_replaceAbbreviations,
	_formatBed,
	_calPercentage,
	_checkFieldForValue,
	_isCCC,
	propertyFL,
} from "../../../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + '/query/';

class RelatedUnits extends PureComponent {

  state = {
		rowsPerPage: 8,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		noMoreResults:false,
		relatedUnits: []
  }


  componentDidMount() {	  
	  const { buildingRes, property } = this.props;	  
	  this._getRelatedUnits(buildingRes, property);
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	  if(prevProps.match.params.building_slug !== this.props.match.params.building_slug ||
		   prevProps.match.params.unit !== this.props.match.params.unit){
		  this.setState( { start:0, relatedUnits: []})
	  }else if(this.state.start !== prevState.start){
		 const { buildingRes, property } = this.props;
		 this._getRelatedUnits(buildingRes, property);
	  }		 
	
  }
  
  _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
   }
  
  _getRelatedUnits = async (building, property, obj) => {
	  
	  
	 if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
     }
	 const fl = propertyFL; 
	
	 const unitField = 'total_units';
	 const totalUnitsPerc = _shouldShow(building[unitField])
	    ? building[unitField] >= 20
	      ? _calPercentage(building[unitField], 
	          (building[unitField] >= 300 ? 70 : building[unitField] >= 100 ? 30 : building[unitField] >= 40 ? 15 : 5)
	        )
	      : 10
	    : undefined; 
	
	  // console.log("unitField", unitField, "totalUnitsPerc", totalUnitsPerc)
	
	  const boro = obj && obj.boro
	    ? obj.boro
	    : _shouldShow(building.borough)
	      ? Array.isArray(building.borough) && _shouldShow(building.borough)
	        ? building.borough[0]
	        : building.borough
	      : 'new york';
	
	    const hood = obj && obj.hood
	      ? obj.hood
	      : _shouldShow(building.neighborhood)
	        ? Array.isArray(building.neighborhood) && _shouldShow(building.neighborhood)
	          ? building.neighborhood[0]
	          : building.neighborhood
	        : 'nyc';
	  
	  const defaultFq = building.offering_verified_status == 'active' ? [
		//"building:(" + _buildingIDs + ")",
		"-key:"+property.key,
		"offering_status:(active OR \"\")",
	    'deleted:false',
	    "source_organizations:NYAG AND baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:*",
	   // `offering_price:[${Math.round(property.offering_price*.5)} TO ${Math.round(property.offering_price*2)}]`
	  ] : [
		"-key:"+property.key,
		"offering_status:(active OR \"\")",
	    'deleted:false',
	    "source_organizations:NYAG AND baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:*"	  
	  ];
	  
	  const bedroomQuery = property.bedrooms > -1 
	      ? `bedrooms:${property.bedrooms}^3.5`
	      : '';
	      
	  const bathsQuery = property.baths > 0
	      ? `baths:${property.baths}^3.0`
	      : '';
	      
	  const priceQuery = _shouldShow(property.offering_price)
	      ? `offering_price:[${Math.round(property.offering_price*.9)} TO ${Math.round(property.offering_price*1.1)}]^10.0`
	      : '';  
	      
	  const statusQuery = `offering_status:(active)^3`;
	      	  
	  const sqFtQuery = _shouldShow(property.offering_sq_ft)
	      ? `offering_sq_ft:[${Math.round(property.offering_sq_ft*.8)} TO ${Math.round(property.offering_sq_ft*1.2)}]^2.0`
	      : ''; 
	  
	  const exSqFtQuery = _shouldShow(property.offering_exterior_sq_ft)
	      ? `offering_exterior_sq_ft:[${Math.round(property.offering_exterior_sq_ft*.7)} TO ${Math.round(property.offering_exterior_sq_ft*1.3)}]^2.0`
	      : '';    	      
	
	  const buildingYearQuery = _shouldShow(building.year_built)
	      ? `year_built:[${(building.year_built - 10) + ' TO ' + (building.year_built + 15)}]^1`
	      : '';
	
	  const buildingYearConvertedQuery = _shouldShow(building.year_converted)
	    ? `year_converted:[${(building.year_converted - 10) + ' TO ' + (building.year_converted + 15)}]^1.0`
	    : '';
	    
	  
	
	  const isNewDev = true;
	
	  const amenitiesToQueryForArr = [
	    { val: 'doorman',
	      field: 'building_service_level',
	      query: '*doorman*' 
	    },
	    { val: 'elevator',
	      field: 'building_access',
	      query: '*elevator' 
	    },
	    { val: ["gym", "fitness-room", "fitness room", "fitness_room", "fitness-center", "fitness_center", "fitness center", "health club", "health-club", "health_club"], 
	      field: 'amenities_health', 
	      query: '(gym fitness?room fitness?center health?club fitness?facilit*)'
	    },
	    { val: ['swimming-pool', 'swimming', 'pool', 'facilities-fitness-pool'],
	      field:'amenities_health',
	      query: '(*pool swimming*)'
	    },
	    { val: ['parking', 'garage', 'parking_garage', 'parking-garage', "facilities-parking-parking-garage", "parking-available", "outdoor-parking"],
	      field: 'amenities_auto',
	      query: '(*parking* *garage*)'
	    },
	    { val: 'storage',
	      field: 'amenities_building_storage',
	      query: '(storage basement-storage)'
	    },
	    { val: 'bike',
	      field: 'amenities_building_storage',
	      query: '*bike*'
	    },
	    { val: ["playground", "outdoor-space", "terrace", "garden", "courtyard", "backyard", "roof deck", "roof-deck", "roof_deck"],
	      field: 'amenities_outdoor_space',
	      query: '(outdoor* outdoor?space terrace* *garden* courtyard backyard roof?deck)'
	    },
	  ];
	
	  const amenitiesQueryValues = amenitiesToQueryForArr.reduce((acc, current) => {
	      if(_checkFieldForValue(building, current.field, current.val)) {
	        acc.push(`${Array.isArray(current.val) ? current.val[0] : current.val}`);
	      }
	      
	      return acc;
	    } 
	  , []);
	  console.log("amenitiesQueryValues", amenitiesQueryValues, amenitiesQueryValues.length > 0)
	
	  const amenitiesQuery = [];
	  if(amenitiesQueryValues.length > 0) {
	    for(let i = 0; i < amenitiesQueryValues.length; i++) {
	      const amenitiesVal = amenitiesQueryValues[i];
	      const amenitiesObj = amenitiesToQueryForArr.find(el => el.val.indexOf(amenitiesVal) > -1);
	
	      // amenitiesQuery.push('(building_features:' + amenitiesObj.query + ' OR ' + amenitiesObj.field + ':' + amenitiesObj.query + ')') 
	      amenitiesQuery.push('(building_features:' + amenitiesObj.query + ')') 
	    }
	  }
	  // console.log("amenitiesQuery", amenitiesQuery)
	
	  const amentitiesStr = amenitiesQuery.join(' OR ');
	  console.log('amentitiesStr', amentitiesStr)
	
	
	  const majorMarkets = _shouldShow(building.markets_major)
	    ? building.markets_major.length > 1
	      ? 'markets_major:("' + building.markets_major.join('" "') + '")^1.7' 
	      : 'markets_major:"' + building.markets_major + '"^1.7'
	    : false;
	
		  const onFloor = _shouldShow(property.floor) && property.floor > 0 
	    ? `floor:[${parseInt(property.floor * .7) + ' TO ' + parseInt(property.floor * 1.3)}]^2.5`
	    : false;
	    
      let q = `(borough:${boro}^1.5${buildingYearQuery ? ' OR ' + buildingYearQuery : ''}${buildingYearConvertedQuery ? ' OR ' + buildingYearConvertedQuery : ''}${onFloor ? ' OR ' + onFloor : ''}${bedroomQuery ? ' OR ' + bedroomQuery : ''}${bathsQuery ? ' OR ' + bathsQuery : ''}${priceQuery ? ' OR ' + priceQuery : ''}${sqFtQuery ? ' OR ' + sqFtQuery : ''}${exSqFtQuery ? ' OR ' + exSqFtQuery : ''}${statusQuery ? ' OR ' + statusQuery : ''}`;
	
	  // query for unit count if NOT a house or commercial
	  const lowEnd = Math.round(building.total_units - totalUnitsPerc);
	  const highEnd = Math.round(building.total_units + totalUnitsPerc);
	  q += `${totalUnitsPerc  ? ' OR total_units:[' + (lowEnd < 0 ? 0 : lowEnd) + ' TO ' + (highEnd) + ']^4.5' : ''}`;
		
	  // query for lower hood and add pictures boost if a new dev AND isCCC
	  if(isNewDev && _isCCC(building)) {
	    q += ` OR neighborhood:"${hood}"^2.0`
	  } else{
	    q += ` OR neighborhood:"${hood}"^2.0`  
	  }
	  q += `${amentitiesStr ? ' OR ' + amentitiesStr : ''}`
	  
	  let relatedCallData = {
	    url: apiURL + "we3-properties?cache=86400",
	    method: "post",
	    query: {
	      // q: 'bin:*',
	      q: `${q})`,
	      fq: defaultFq,
	      fl: [...fl, "score"],
	      wt: "json",
	      sort: "score desc",
	      start: this.state.start,
	      rows: this.state.rowsPerPage,
	    }
	  };
      let formattedData = await _axiosCall(relatedCallData)
	    .then(res => {
		    if(res && res.docs && res.docs.length>0){
				const newUnitListData = [...this.state.relatedUnits, ...res.docs];
				this.setState( { relatedUnits: newUnitListData, fetchInProgress: false, noMoreResults: res.docs.length < this.state.rowsPerPage })
			}else if(res.docs.length === 0 ){
				this.setState( { noMoreResults: true, fetchInProgress: false })
			}
	      
	    })
	    .catch(error => {
	      console.log("error: " + error);
	    });
	
	  return formattedData;
	}


	
  render(){
	  const { relatedUnits } = this.state;
	  if(!relatedUnits){
		  return null;
	  }
	  return(
		  <Col md={12} lg={12} className="pt-2">
		    <RelatedUnitItems items={this.state.relatedUnits} />
		    <div className="unit__load text-center">			   		
			    <Tooltip title="Load more units">
			        <Button
				        onClick={(e) => {this._loadMore(e)}}
				         variant="contained"
				         color="news"
				         className={`timeline__load-more`}		         
				         startIcon={<LoadMoreIcon />}
				         disabled={this.state.fetchInProgress || this.state.noMoreResults}
				     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "No More Similar Units" :  "Load More"}`}
				     </Button>				 
			  	</Tooltip>				
		    </div>

		  </Col>
	  )
	  
  }
  
}

export default withRouter(RelatedUnits);
