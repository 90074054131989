import React, { Fragment } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import ProductCard from './components/ProfileCard';
import RelatedUnits from './components/RelatedUnits';
import UnitHistory from './components/UnitHistory';

import PercentFinanced from './components/PercentFinanced';
import TimesSold from './components/TimesSold';
import AverageDOM from './components/AverageDOM';

import Timeline from './../../../SingleUnit/components/Timeline';
import ShowMap from './../../../../shared/components/map';
import BuildingAmenities from './components/BuildingAmenities';



const Profile = (props) => (
  <div>
    <Row>
      <ProductCard {...props} />
    </Row>
    {props.property && (props.property.property_features || props.property.building_features) &&
	<Row>
    <Col md={12}>
      <h3 className="section-title section-title--not-last">Amenities & Features</h3>
      <h3 className="section-title section-title--not-last subhead"> </h3>
      <hr/>
      <BuildingAmenities {...props} isHouse={true} small/>
    </Col>
  </Row>
  }
	<Row>
      <Col md={12} >
        <h3 className="section-title section-title--not-last">Location</h3>
        <h3 className="section-title section-title--not-last subhead"> </h3>
      </Col>
    </Row>
    <Row className="mb-2">
    	<Col md={12}>
    		<Card>
    		 <CardBody>
    		<ShowMap item={props.buildingRes} />
    		 </CardBody>
    		</Card>
		</Col>
    </Row>
    
    <Row>
		<Timeline {...props} />
    </Row>
    {!props.share &&
      <Fragment>
        <Row className="mt-3">
          <Col md={12} style={{ padding:"30px auto" }}>
    
            <h3 className="section-title section-title--not-last timeline__header-center">Similar Properties</h3>
            <h3 className="section-title section-title--not-last subhead"> </h3>
            <hr/>
          </Col>
        </Row>
        
        <Row>
          <RelatedUnits {...props} />
        </Row>
      </Fragment>
    }
  </div>
);

export default Profile;
