import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Listed from './components/Listed';
import ListedPrice from './components/ListedPrice';
import Sold from './components/Sold';
import SoldPrice from './components/SoldPrice';
 
import NewUnitsGraph from './components/NewUnitsGraph';
import NewBuildingsGraph from './components/NewBuildingsGraph';
import TopSellingProducts from './components/TopSellingProducts';
import BasicCard from './components/BasicCard';
import SalesStatistic from './components/SalesStatistic';
import MyUnits from './components/MyUnits';
import ProductSales from './components/ProductSales';
import NewOrders from './components/NewOrders';
import SalesStatistisBar from './components/SalesStatistisBar';
import MyTodos from './components/MyTodos';
import Emails from './components/Emails';
import SalesReport from './components/SalesReport';
import Promotions from './components/Promotions';
import InternalPromotion from './components/InternalPromotion';
import TotalInventory from './components/TotalInventory';
import ActiveInventory from './components/ActiveInventory';
import TotalResaleInventory from './components/TotalResaleInventory';

/*
import CurrentInventory from './components/CurrentInventory';
import ShadowInventory from './components/ShadowInventory';
*/

import ResaleInventory from './components/ResaleInventory';


import MarketActivity from './components/MarketActivity';
import DiscountAnalysis from './components/DiscountAnalysis';


import RecentlyAdded from './components/RecentlyAdded';
import ComingSoon from './components/ComingSoon';
import Alert from '../../../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/GiftOutlineIcon';

import { NewOrderTableProps } from '../../../../shared/prop-types/TablesProps';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

import NewUnits from './components/NewUnits/index';
import MyBuildings from './components/MyBuildings/index';
import News from './components/News/index';

import BuildingsByContracts from './components/BuildingsByContracts';
import TrendingBuildings from './components/TrendingBuildings';
import RecentHistory from './components/RecentHistory';
import PopularBuildings from './components/PopularBuildings/index';

import CommunityGroups from './../../../MyGroups/components/CommunityGroups';

import GeneralBuildingSearchBar from '../../../../shared/components/building_filters/GeneralBuildingSearchBar';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import TrialAlert from '../../../../shared/components/payment/TrialAlert';

import moment from 'moment';

import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { 
	propertyFL,
	mpndCohortQuery,
	getCohortQuery,
	recentCohortQuery,
	_validUnitSources,
	searchBarStyles,
	getCohortQueryAll
} from "../../../../shared/helpers/utils";
import { _hasPermission	} from "../../../../shared/helpers/access";

const buildingUrl = process.env.API_ROOT + '/query/';

class BuyersListDashboard extends PureComponent {
  static propTypes = {
    newOrder: NewOrderTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };
  
  state = {
    order: !this.props.buildingView ? "desc" : "",
    orderBy: !this.props.buildingView ? "count" : "",
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: "",
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: {},
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	facetData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false
  };

  
  defaultQ = `((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR ((verification_source:(${_validUnitSources}) OR ownership_type:co*op) AND -address_2_normalized:\"\") OR building_class:R3)`;
  q='';
  fq = [		
		"deleted:false",
		`{!join from=alternate_keys fromIndex=we3-buildings to=building}${getCohortQueryAll()}`,
		"-offering_unit_abandoned:true",
		"-offering_verified_status:stalled"
	];
	
  fl = propertyFL;


  onDeleteRow = (index, e) => {
    const { dispatch, newOrder } = this.props;

    e.preventDefault();
    const arrayCopy = [...newOrder];
    arrayCopy.splice(index, 1);
  };
  
  componentDidMount() {
  	this._getInventoryUnits();
  	
  }
  
  _getInventoryUnits = async (resetPage) => {
		this.q = this.defaultQ;
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, facetData:false, tableData:false, reactTableFiltered:false }));
		}
		
	
			
		
		let axiosData = {
			url: buildingUrl + "we3-properties?cache_time=1440&always-cache=true",
			method: "post",
			query: {
				q: "*:*",
				fq: this.fq,
				fl: this.fl,
				rows:this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				wt: "json",		
				
			}
		};	
		
		axiosData.query.rows = 0;
		axiosData.query.start = 0;
		axiosData.query["json.facet"] = {
			// 
			// 	"total" : {
			// 		"type" : "query",
			// 		"q" : '-offering_unit_abandoned:true AND property_type:(*residential*condo* condo "condo unit" "resident manager unit" *coop* *affordable* "" *dwelling* *timeshare* *residential* *apartment*)',
			// 		"facet" : {
			// 			"buildings" : "unique(building)"
			// 		}
			// 	},
				"unsold" : {
					"type" : "query",
					"q" : `((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF) AND -address_2_normalized:\"\") OR building_class:R3) AND (current_status:((active) OR (current_status:shadow AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])) OR (current_status:transfer AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])))) AND (property_type:((*residential*condo* condo \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*))) AND property_status:(\"sponsor\") AND (source_organizations:(NYAG CDEC ADEC) OR verification_source:(user-verified-valid CDEC ADEC NYCDOF) OR current_status:active) AND -address_2_normalized:\"\"`,
					"facet" : {
						"buildings" : "unique(building)",
						"active" : {
							"type" : "query",
							"q" : "current_status:active"
						}
					}
				},
				// "active" : {
				// 	"type" : "query",
				// 	"q" : '((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF NYAG) AND -address_2_normalized:\"\") OR building_class:R3) AND (current_status:((active))) AND (property_type:((*residential*condo* condo \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*)))',
				// 	"facet" : {
				// 		"buildings" : "unique(building)"
				// 	}
				// },
				"active_condo" : {
					"type" : "query",
					"q" : '((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR ((verification_source:(user-verified-valid CDEC ADEC NYCDOF NYAG dof-acris*) OR ownership_type:co*cop) AND -address_2_normalized:\"\") OR building_class:R3) AND (property_type:((*residential*condo* condo *co-op* \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*))) AND (current_status:((active))) AND (ownership_type:((*condo* !condo*p))) AND property_status:(\"resale\") AND (source_organizations:(NYAG CDEC ADEC) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF NYAG dof-acris*) OR ownership_type:co*cop) OR current_status:active) AND -address_2_normalized:\"\"',
					"facet" : {
						"buildings" : "unique(building)"
					}
				},
				"active_coop" : {
					"type" : "query",
					"q" : '((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR ((verification_source:(user-verified-valid CDEC ADEC NYCDOF NYAG dof-acris*) OR ownership_type:co*cop) AND -address_2_normalized:\"\") OR building_class:R3) AND (current_status:((active))) AND (ownership_type:((*co?op* *coop* !condo*p))) AND (property_type:((*residential*condo* condo *co-op* \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*))) AND property_status:(\"resale\") AND (source_organizations:(NYAG CDEC ADEC) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF NYAG dof-acris*) OR ownership_type:co*cop) OR current_status:active) AND -address_2_normalized:\"\"',
					"facet" : {
						"buildings" : "unique(building)"
					}
				},
				"active_single_family" : {
					"type" : "query",
					"q" : `ownership_type:(single?family) AND total_units:[1 TO 1] AND current_status:active`
				},
				"active_multi_family" : {
					"type" : "query",
					"q" : `(ownership_type:(multi?family) OR (ownership_type:rental AND total_units:[* TO 3])) AND current_status:active`
				},
				// "shadow" : {
				// 	"type" : "query",
				// 	"q" : 'offering_status:("" OR withdrawn OR tom* OR expired) AND -offering_construction:(conversion) AND -offering_unit_abandoned:true',
				// 	"facet" : {
				// 		"buildings" : "unique(building)"
				// 	}
				// }
		}
					
				
			
		

	
		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});

			
		let facetData = response && response.facets && response.facets;
		
		this.setState(prevState => ({
			fetchInProgress: false,
			facetData:  facetData,
			totalCount: response ? response.numFound : 0,
			start: resetPage ? 0 : this.state.start,
			page: resetPage ? 0 : this.state.page
		}));
			

					
	};

  render() {
    const { t, newOrder, rtl, user } = this.props;
    
    const { facetData } = this.state;
   // if(!facetData) return null;
   
    return (
	  <div className="dashboard">
      <Container>
	  
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`Dashboard`}</h3>
            <h3 className="page-subhead subhead"></h3>
          </Col>
        </Row>
       <Row>
	    	<Col md={12} className={'mb-3'}>
	    		<TrialAlert />
				{/*<Alert color="success" className="alert--bordered" alertId="dashboardGetOfferingPlan" icon iconItem={<AlertIcon />} minimizeText={'Have an offering plan?'}>
		          <p>
		          	<span className="bold-text">{`Have an Offering Plan?`} </span> 
		          	<div><Link to="/coming-soon?offering_verified_status=submit-offering-plan">Get a free month &raquo;</Link></div>
		          </p>
		         
		        </Alert>*/}
			</Col>
	    </Row>
	  
	    <Row>
	    	<Col md={12} className={'text-right'}>
				<span style={{fontSize:'12px',marginBottom:'15px',color:'#858AA1'}}>
					{!isMobile && <span>Want to see what's new? {` `}</span>}
					{/*<a href="javascript:void(0)" 
						  onClick={(e) => {e.stopPropagation();e.preventDefault();window.Intercom('startTour', 181184);}} 
						  style={{color:'#9600BF'}}>*/}
					 <a href="http://help.marketproof.com/en/articles/6063466-new-on-marketproof-new-dev-condo" className="link-text" target="_blank">
						 Latest Features
					</a>
				</span>
				<span className="small-text light-text" style={{margin:"0 10px"}}> | </span>
	    		<span style={{fontSize:'12px',marginBottom:'15px',color:'#858AA1'}}>
					{!isMobile && <span>Need a refresher? {` `}</span>}
	    			{/*<a href="javascript:void(0)" 
		              	onClick={(e) => {e.stopPropagation();e.preventDefault();window.Intercom('startTour', 181184);}} 
					  	style={{color:'#9600BF'}}>*/}
					 <a href="https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide" className="link-text" target="_blank">
						 Quick Start Guide
					</a>
				</span>
	    	</Col>
	    </Row>
	    <Row className={"mb-3"}>
		<Col md={12} >
        <GeneralBuildingSearchBar      
		   buildingFilter={'(ownership_type:"condo" OR ownership_type:"condo-retail" OR building_status:"sponsor" OR ownership_type:"coop" OR ownership_type:"rental")'}       
		  handleSearch={null}
		  buildingAttributes={[]}
		  placeHolder={'NEW! Search for any NYC building'}
		  addBuilding={false}
		  buildingTab={null}
		  hideBoroHood={true}
		  hideAddIcon={true}
		  hideMapDraw={true}
		/>
		</Col>
		</Row>
		{true || _hasPermission(user, "sponsor-buildings") ?
          <Row>
     		  
          <TotalInventory data={facetData} user={user} />
          <ResaleInventory data={facetData} type={"condo"}  />
		  <ResaleInventory data={facetData} type={"house"}  />
          <Promotions />
     
          </Row>
		  :
		  <Row>
				 
			<ActiveInventory data={facetData}  />
			<TotalResaleInventory data={facetData}   />
			<Promotions />
		  
			</Row>
		  }
		  {_hasPermission(user, "sponsor-buildings") ?
				<Row>
					<Col md={12} lg={6}>
					  <TrendingBuildings status="sponsor" title={"Trending Sponsor Buildings (Most Viewed)"} />
					</Col>
					<Col md={12} lg={6}>
					<TrendingBuildings status="resale" title={"Trending Resale Buildings (Most Viewed)"} />
					</Col>
				  </Row>
				: 
				<Row>
					<Col md={12} lg={12}>
						<TrendingBuildings full status="resale" title={"Trending Resale Buildings (Most Viewed)"} />
					</Col>
				</Row>
				  
		  }
		  
		  {/*<Row>
		  	<CommunityGroups />
		  </Row>*/}
		  <RecentHistory {...this.props} />
		  <Row>
			  <News />
		  </Row>
        {/*<Row>
          <InternalPromotion />
          <Promotions />
        </Row>*/}
         
         <Row>
                    
         </Row>
        {/*<Row>
          <InternalPromotion />
          <Promotions />
        </Row>*/}
		{_hasPermission(user, "sponsor-buildings") &&
         <BuildingsByContracts />
		}
        {/*<Row>
        	<PopularBuildings />
        </Row>*/}
		{_hasPermission(user, "resale-buildings") &&
         <BuildingsByContracts type={"resale"}/>
		}
          {/*<Row>
       <MyBuildings />
         <MyUnits />               
        </Row>*/}
        
        
         
        
       {/* <Row>
        	<Col lg={12} className="mb-3" >
        		<hr/>
        	</Col>
        </Row>*/}
        
       
       
        
        
        {/*  <Row>
          
          <NewUnitsGraph />
          <NewBuildingsGraph />
          <SalesReport />
        </Row>

        
        <Row>
        <ProductSales rtl={rtl.direction} />
          <BasicCard />
          <SalesStatistic />
          <MyTodos />
          <SalesStatistisBar />
          <SalesReport />
          <Emails />
          <TopSellingProducts dir={rtl.direction} />
          {/*<NewOrders newOrder={newOrder} onDeleteRow={this.onDeleteRow} />
         
        </Row>*/}
      </Container>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(withTranslation('common')(BuyersListDashboard));
