import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/BorderNoneIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../shared/helpers/utils";

const AveragePPSF = (props) => {

  if(!props.facetData) return null;

  const ppsf = props.facetData.non_zero_price && props.facetData.non_zero_price.average_ppsf > 0 ? props.facetData.non_zero_price.average_ppsf.toFixed(0) : 0;
  return (
	  	<Card>
	      <CardBody className="dashboard__booking-card">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            ${_formatNumber(ppsf)}
	          </h5>
	          <TagOutlineIcon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Avg Listed Price / Sq Ft</h5>
	      </CardBody>
	    </Card>
	 )
};

export default AveragePPSF;
