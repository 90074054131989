import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';

import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import {
  Button
} from 'reactstrap';

class SendMessageButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { buttonText: 'Message' };
  }
  
   handleSubmit(event, buildingKey) {
    event.preventDefault();
    const { addMyBuyersList } = this.props.actions;
	const priority = 'high';
   // addMyBuyersList(buildingKey, priority);
   // this.setState({ buttonText: 'Building Saved'})
    
  }



  render() {
    const {
      actions, t, theme, rtl, buildingKey
    } = this.props;

    return (
	    <Tooltip title="Send Private Message">
	        <Button
		        onClick={(e) => {this.handleSubmit(e, buildingKey)}}
		         className="icon icon--right"
		         color="primary"
		         outline      
		     >	<p>{this.state.buttonText} <MessageTextOutlineIcon /></p>
		     </Button>	
	  	</Tooltip>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SendMessageButton));
