import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';


const calculateBarData = (transaction) => {		
	const totalSold = transaction.days_to_contract && transaction.days_to_contract ? Math.abs(transaction.days_to_contract) : 0;
	const totalAvail = 1000 - totalSold;
	return [{ value: totalAvail, fill: getDefaultChartColors[1] },
  				{ value: totalSold, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}


const AverageDOM = (props) => {
	const { buildingRes, location, transactions } = props;	
	const base_url = location.pathname.replace('/tab/overview', '') + '/tab/sales';
	const transaction = transactions.filter(item => item.price > 0)[0];

	return (
	  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
	    <Card>
	    <Link to={`${base_url}`}>
	      <CardBody className="dashboard__health-chart-card card__link">
	        <div className="card__title">
	          <h5 className="bold-text card__title-center">{`Days on Market`}</h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={calculateBarData(transaction)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="dashboard__health-chart-info">
	            <ClockOutlineIcon style={{ fill: getDefaultChartColors[1] }} />
	            <p className="dashboard__health-chart-number">{transaction.days_to_contract > 0 ? transaction.days_to_contract : '--'}</p>
	            <p className="dashboard__health-chart-units">days to contract</p>
	          </div>
	        </div>
	        <p className="dashboard__goal">
	        	{transaction.days_to_contract > 0 ? 
		        <Fragment>Based On: {transaction.sponsored_sale? 'Sponsor sale' : 'Resale'} {moment(transaction.close_date_formatted).fromNow()}</Fragment>
		    	:
		    	<Fragment>Days on market not available for {transaction.sponsored_sale? 'sponsor sale' : 'last resale'}</Fragment>   
		    	} 
		    </p>
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
	)
};


export default AverageDOM;
