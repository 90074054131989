import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';
import LinearLoading from '../../../../../shared/components/LinearLoading';

import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
	propertyExportFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl
} from "../../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import IconButton from '@material-ui/core/IconButton';

const buildingUrl = process.env.API_ROOT + '/query/';
const styles = theme => ({
	  root: {
		display: 'flex',
		flexDirection: 'column',
		//marginLeft:"-8px",
		marginBottom:"15px",
		alignItems: 'left',
		'& > *': {
		   
		},
	  },
	  button:{
		'&.selected' :{
			background:"#D1D6E0"
		},
		fontSize:11,
		width:90
	  }
	});
	
const ShowUnits = ({data, title, color, priceField, status, dateField}) => {
	let units_url = window.location.pathname.replace('/tab/overview', '');
	if(status == 'active'){
		units_url += '/tab/news/listings'
	}else if(status=='in-contract'){
		units_url += '/tab/news/contracts'
	}else if(status=='price-change'){
		units_url += '/tab/news/price-changes'
	}else if(status=='closed'){
		units_url += '/tab/news/sales'
	}
	
	return (
		<Card>
		<CardBody style={{padding:15}}>
		 <div className="card__title flex">
		  <h5 className="bold-text">{title} </h5>
		  {data && data.doclist && data.doclist.numFound > 0 &&
		  <div className="unit__load text-right">			   		
			   <Tooltip title="See More">
				   <Link to={units_url} className="link-text">See All &raquo;</Link>			 
				 </Tooltip>				
		   </div>
	   		}
		 </div>
		 
		<Table responsive className="table-striped">
		  <tbody>
		  {data && data.doclist && Array.isArray(data.doclist.docs) && data.doclist.docs.length>0 ? 
			<Fragment>
		    	{data && data.doclist && data.doclist.docs.slice(0, 3).map((item, i) => {
				  const buildingNameOrAdr = item.building_name 
				  ? _capitalizeText(item.building_name)
				  : item.display_full_street_address
					? _capitalizeText(item.display_full_street_address)
					: _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
				item[priceField] = item[priceField] ? parseInt(item[priceField].toString().replace(/[$,]/g, '')) : false;
				
				let priceChange = false;
				
				if(status == "price-change"){
					
					if(item["price_change_percent"]){
						priceChange = Math.round(item["price_change_percent"] * 10000) / 100;
					}else if(item["original_price"]){
						let origPrice = parseInt(item["original_price"].toString().replace(/[$,]/g, ''))
						priceChange = Math.round(((origPrice-item[priceField]) / origPrice) * -10000) / 100;
					}
				}
				const isRent = item.event_tags.indexOf('rent') >=0
			  return (
			 
					  <tr key={i} style={{border:0,cursor:"pointer"}}>
						  <td style={{paddingRight:5}}>
						  	{status == "price-change" ? 
							  	<Badge style={{ width:80,background:'#FFF',padding:"6px 5px",border:"1px solid #D6BAE8",color:"#333" }}>
									
									{priceChange > 0 ?
										<span className="bold-text"> <ArrowUpIcon size={16} style={{marginTop:-2,maringLeft:-3}} color="#4ce1b6" /> {priceChange >= 0 ? "+": ""}{priceChange}%</span>
											:
										<span className="bold-text"> <ArrowDownIcon size={16} style={{marginTop:-2,maringLeft:-3}} color="#FF206E" /> {priceChange}%</span>
									}
								</Badge>
							  	
							  :
							  <Badge style={{ width:70,background:color }}>
								  <span className="bold-text">{_formatPrice(item[priceField])}</span> 
							  </Badge>
							}
							<div style={{marginTop:5}}><span className="light-text small-text" style={{color:"#858AA1",whiteSpace:"nowrap"}}>{moment(item.event_time).fromNow()}</span></div>
						</td>      				       	
						<td style={{width:"65%"}}>
							<Link to={unitURL}>
								
								<div>{item.address_2_normalized.toUpperCase().replace("APT", "Apt")}</div>
								
								<div ><span className="light-text small-text" style={{color:"#858AA1",whiteSpace:"nowrap"}}>{item.headline1}</span></div>
								{item.baths>0 ?
								 <Fragment>
									<span className="light-text small-text">{item.bedrooms > -1 ? item.bedrooms === 0 ? 'Studio' : item.bedrooms+' Bed' : '--'}</span>
									{` | `}<span className="light-text small-text">{item.baths > -1 ? item.baths+' Bath' : '--'}</span>
									
								</Fragment>
								 
								:
								 item.sq_ft>0 ? 
									<span className="light-text small-text">
										{_formatNumber(item.sq_ft)} sq ft
									</span>
								:
									<span className="light-text small-text">--</span>
								}
								{/*<br/>
								<span className="light-text small-text">{moment.unix(item[dateField]).fromNow()}</span>*/}
							</Link>	
							
						</td>   
						
							
					  </tr>
				  
				)
			  
		  })}
		  </Fragment>
		  :
		  <tr>
			  <td colSpan={2} className="text-center">
				  <div className="bold-text">No Activity Yet</div>
			  </td>
		  </tr>
		  }
			
		   
		  </tbody>
		</Table>
		
		</CardBody>
		</Card>

	)
}
class LatestActivities extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		  fetchInProgress: false,
		  data: null,
		  period: "7-day"
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.period !== prevState.period){
			this._getUnits();
		}
		
	}

	componentDidMount() {
		this._getUnits();
		
		  
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
	}
	_getUnits = async () => {
			
		const { period } = this.state;
		const { buildingRes } = this.props;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		const addressQ = buildingRes.alternate_addresses.join('" OR "');
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}"))`
		
		const defaultFq = ["-event_tags:*commercial*", "hierarchy:[0 TO 2]", "-address_2_normalized:\"\""];
				 
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=3600",
			method: "post",
			query: {
				q: q,
				fq: defaultFq,
				//fl: [...propertyExportFL, ...["floor", "building"]],
				wt: "json",
				rows: 0,
				sort : "event_time desc",
				"group": true,
				"group.limit": 5,
				// "group.sort" : [
				// 	"last_listed_sale_date_all desc",
				// 	"last_contract_date desc",
				// 	"last_sold_date desc"
				// ],
				"group.query": [
					`topic:listings AND event_tags:just-listed`, 
					`topic:listings AND event_tags:contract-signed`,
					`topic:transactions AND event_tags:recorded-sale AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale`,
					`topic:listings AND event_tags:(price-decrease price-increase)`]		
			}
		};

		let response = await _axiosCall(axiosData)
		.then(res => {
			return res;
		})
		.catch(error => {
			console.log("error: " + error);
		});
		this.setState({ data : response, fetchInProgress : false });
	
  };
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  setPeriod = (period) => {
	  this.setState({period:period})
  }
  
  render(){
	const { savedUnits, totalCount, period, data, fetchInProgress } = this.state;
	const { classes, type } = this.props;
	//if(!data) return null;
	if(data && data[Object.keys(data)[3]]){
		let tempData = data[Object.keys(data)[3]];
		if(tempData.doclist && Array.isArray(tempData.doclist.docs) && tempData.doclist.docs.length>0){
			tempData.doclist.docs = tempData.doclist.docs.filter(item => item.original_price);
		}
		
	} 
	return (
		<div>
			  
			   
			   {(!data || fetchInProgress) ?
				   <div>
				   <LinearLoading />
				   <div style={{height:300}}>
					   
				   </div>
				   </div>
				   
				   :
				   <Card>
					  <CardBody style={{padding:0,border:0}}>
						  <Row>
								  <Col md={12} className={"mb-0"}>
				   <div className="card__title">
					 <h5 className="bold-text">{'Recent Activity In This Building'}</h5>
					 {/*<h5 className="subhead">Top Listings, Contracts and Closing for The {type == "resale" ? "Resale Condo Market" : 'New Development Market'}</h5>*/}
				   </div>
				   <Row>
				   		<Col md={3} className={"mb-2"}>
							<ShowUnits data={data && data[Object.keys(data)[3]] ? data[Object.keys(data)[3]] : false} title={"Price Changes"} color={"#4ce1b6"} priceField={"price"} status={"price-change"} dateField={"last_listed_sale_date_all"}  />
					   </Col>
					   <Col md={3} className={"mb-2"}>
						   <ShowUnits data={data && data[Object.keys(data)[0]] ? data[Object.keys(data)[0]] : false} title={"Listings"} color={"#4ce1b6"} priceField={"price"} status={"active"} dateField={"last_listed_sale_date_all"}  />
					   </Col>
					   <Col md={3} className={"mb-2"}>
						   <ShowUnits data={data && data[Object.keys(data)[1]] ? data[Object.keys(data)[1]] : false} title={"Contracts"} color={"#FFD23F"} priceField={"price"} status={"in-contract"} dateField={"last_contract_date"}  />
					   </Col>
					   <Col md={3} className={"mb-2"}>
						   <ShowUnits data={data && data[Object.keys(data)[2]] ? data[Object.keys(data)[2]] : false} title={"Closings"}  color={"#28C2FF"} priceField={"price"} status={"closed"} dateField={"last_sold_date"} />
					   </Col>					   	  
						
				   </Row>
				   </Col>
						  </Row>
					  </CardBody></Card>
			   }
		</div>
	
	)
	
   }
};

LatestActivities.propTypes = {
  t: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
	? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
	: state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, 
			  savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],  
			  dataSet : state.buyersLists.dataSet };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,  { withTheme: true })(LatestActivities));
