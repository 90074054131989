import React, { PureComponent } from 'react';
import {
  Bar, CartesianGrid, ComposedChart, BarChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList, Cell,
} from 'recharts';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';
import StatIcon from 'mdi-react/MapMarkerIcon';
import ToolTip from '../../../../../../shared/components/ToolTip';

import { getDefaultChartColors } from '../../../../../../shared/helpers';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	setURLFromState
} from "../../../../../../shared/helpers/utils";




const _generateBoroughData = (statsRes) => {
	 if(!statsRes.buckets){
		  return [];
	  }
	
	let data = [
	  {
	    'name': 'Manhattan',
	    'count': 0,
		'ppsf' : 0,
	    'color': getDefaultChartColors[0],
	    'search' : 'manhattan'
	  },
	  {
	    'name': 'Brooklyn',
	    'count': 0,
		'ppsf' : 0,
	    'color': getDefaultChartColors[1],
	    'search' : 'brooklyn'
	  },
	  {
	    'name': 'Queens',
	    'count': 0,
		'ppsf' : 0,
	    'color': getDefaultChartColors[2],
	    'search' : 'queens'
	  }
	 
	];
	  
	 statsRes.buckets.map((bucket, i) => {
		 
		 data = data.map(b => {
			 if(b.search == bucket.val){
				 b.count = bucket.count;
				 b.ppsf = bucket.avg_ppsf;
			 }
			 return b;
		  })
		 
	  });
	 
	  return data;
	 	  
  }


class Borough extends PureComponent {
  
  toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  handleClick(data, index) {
  	console.log(data, index);
  	const { history } = this.props;
  	
  	const newState = Object.assign({}, this.props); 	
	 if(newState.subFilters && newState.subFilters.offering_bedrooms){
		 	delete( newState.subFilters.offering_bedrooms);
	 }
 	 const baseURL = setURLFromState({}, newState, true);
  	
  	history.push(`/inventory${baseURL}&bedrooms=${data.search}`);
  }
  
   renderCustomizedLabel = (props) => {
	const { dataKey } = this.props;
	const { x, y, width, height, value } = props;
	const radius = 10;
  
	return (
	  <g>
	   {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#780F9E" />*/}
		<text x={x + width / 2} y={y - radius} fill="#780F9E" textAnchor="middle" dominantBaseline="middle">
		  {value === 0 ? 'N/A' : dataKey == "ppsf" ? '$'+ _formatNumber(value) :  _formatNumber(value)}
		</text>
	  </g>
	);
  };

  render() {
    const { facetData, desc, dataKey } = this.props;
    
    if(!facetData){
	    return null;
    }
    
    const stat = _generateBoroughData(facetData);
   	console.log(stat);
   	
   
    let key = dataKey ? dataKey : "count"

    return ( 		  	
	    <Card>
		    <CardBody className="dashboard__booking-card">
		      <div className="dashboard__booking-total-container">
	          	<h5 className="dashboard__booking-total-description">{desc}</h5>
			  	<StatIcon className="dashboard__trend-icon" />
	          </div>
	          <ResponsiveContainer height={280} className="dashboard__area">
	            <BarChart data={stat} margin={{ top:40, left: 0 }}>
	              <XAxis dataKey="name" tickLine={true} padding={{ left:25, right:25 }} />
	              {/*<YAxis tickLine={false} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} orientation={dir === 'rtl' ? 'right' : 'left'} />
	              <Tooltip content={<TopCensusTooltipContent colorForKey={{ uv: '#555555' }} theme={themeName} />} />*/}
	             {/* <CartesianGrid vertical={false} />*/}
	              <Tooltip formatter={(value, name, props) => ( name=="ppsf" ? '$'+ _formatNumber(value) :  _formatNumber(value) )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1}}/>

	              <Bar dataKey={key}  name={key} barSize={50}>
	                {stat.map((item, index) => <Cell fill={item.color} key={index} />)})}
	                <LabelList dataKey={key} content={this.renderCustomizedLabel} />
				  </Bar>
	            </BarChart>
	          </ResponsiveContainer>
       </CardBody>
	</Card>
    );
  }
}

export default (Borough);
