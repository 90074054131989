import React, { PureComponent, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Progress,
  Badge,
  Row,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import UnitsSearchTableHead from "./UnitsSearchTableHead";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import searchActions from "../../../redux/actions/searchActions";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import FloorPlanIcon from "mdi-react/FloorPlanIcon";
import LinearLoading from "../../../shared/components/LinearLoading";
import Button from "@material-ui/core/Button";
import LoadMoreIcon from "mdi-react/RefreshIcon";
import LoadingIcon from "mdi-react/DotsHorizontalIcon";
import CharityIcon from "mdi-react/CharityIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import IconButton from "@material-ui/core/IconButton";
import DocIcon from "mdi-react/FileDownloadOutlineIcon";
import OpenIcon from "mdi-react/FileDocumentOutlineIcon";
import CertificateOutlineIcon from "mdi-react/CertificateIcon";
import ContactIcon from "mdi-react/EmailOutlineIcon";
import UnitIcons from "../../../shared/components/UnitIcons";
import ArrowUpIcon from "mdi-react/ChevronUpCircleOutlineIcon";
import ArrowDownIcon from "mdi-react/ChevronDownCircleOutlineIcon";
import ArrowEqualIcon from "mdi-react/MinusCircleOutlineIcon";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _objectEqual,
  StatusBadge,
  _getUnitFloorPlan,
  SalesProgress,
  propertyFL,
  _activityFQ,
} from "../../../shared/helpers/utils";
import { _hasPermission } from "../../../shared/helpers/access";
import { _axiosCall } from "../../../shared/helpers/apicalls";



const UnitStatus = ({ event, type }) => {
  return event.event_tags.indexOf("event-stage_sponsor") >= 0 ? (
    <Tooltip
      title={`This unit was owned by the Sponsor at the time of the event.`}
    >
      <IconButton size={`small`} style={{ background: "transparent" }}>
        <span
          className="unit-type_badge"
          style={{
            borderColor: "#BFC4D1",
            background: "#FFF",
            color: "#BFC4D1",
          }}
        >
          Sponsor Sale
        </span>
      </IconButton>
    </Tooltip>
  ) : event.event_tags.indexOf("event-stage_resale") >= 0 ? (
    <Tooltip
      title={`This unit was already sold by the Sponsor at the time of the event.`}
    >
      <IconButton size={`small`} style={{ background: "transparent" }}>
        <span
          className="unit-type_badge"
          style={{
            borderColor: "#BFC4D1",
            background: "#FFF",
            color: "#BFC4D1",
          }}
        >
          Resale
        </span>
      </IconButton>
    </Tooltip>
  ) : null;
};


const rows = [
  {
    id: "time",
    disablePadding: false,
    label: "Date",
  },
  {
    id: "topic",
    disablePadding: false,
    label: "Event Type",
  },
  {
    id: "address_2_normalized",
    disablePadding: true,
    label: "Unit",
  },

  {
    id: "bedrooms",
    disablePadding: false,
    label: "Bedrooms",
  },
  {
    id: "baths",
    disablePadding: false,
    label: "Baths",
  },
  {
    id: "price",
    disablePadding: false,
    label: "Price",
  },
  {
    id: "event_tags",
    disablePadding: false,
    label: "",
  },
];
export default class ListTable extends PureComponent {
  state = {
    order: "",
    orderBy: "",
    selected: new Map([]),
    page: 0,
    rowsPerPage: 20,
    fetchInProgress: false,
    buildingKey: false,
    unitListData: [],
    start: 0,
    noMoreResults: false,
    propertiesFetchInProgress: false,
  };

  isAdmin = false;

  render() {
    const {
      building,
      soldTimeField,
      buildingKey,
      viewType,
      handleGroupedBuildingClick,
      chartType,
      chartView,
    } = this.props;
    const data = this.props.data && this.props.data.doclist&& this.props.data.doclist.docs && this.props.data.doclist.docs.length? this.props.data.doclist.docs:[];
    const style = {
      display: "table-cell",
      padding: "16px",
      fontSize: "0.875rem",
      textAlign: "left",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1.43,
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      letterSpacing: "0.01071em",
      verticalAlign: "inherit"
    };
    
    return (
      <Table className="material-table" style={{width:"100%"}}>
        <TableHead>
          <TableRow>
            <TableCell style={style}></TableCell>
            {rows.map(
              (row) => (
                <TableCell
                 style={style}
                  key={row.id}
                >
                  <span>{row.label}</span>
                </TableCell>
              ),
              this
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d) => {
            let showStatus, eventType;
            if (
              d.topic[0] === "listings" &&
              d.event_tags.indexOf("just-listed") >= 0
            ) {
              showStatus = (
                <Fragment>
                  <span className="status-small listed" />
                  Listed <UnitStatus event={d} />
                </Fragment>
              );
              eventType = "Listed";
            } else if (
              d.topic[0] === "listings" &&
              d.event_tags.indexOf("contract-signed") >= 0
            ) {
              showStatus = (
                <Fragment>
                  <span className="status-small contract" /> Contract{" "}
                  <UnitStatus event={d} />
                </Fragment>
              );
              eventType = "Contract Signed";
            } else if (d.topic[0] === "transactions") {
              showStatus = (
                <Fragment>
                  <span className="status-small sold" /> Closed
                  <UnitStatus event={d} />
                </Fragment>
              );
              eventType = "Closed";
            } else if (
              d.topic[0] === "listings" &&
              d.event_tags.indexOf("price-decrease") >= 0
            ) {
              showStatus = (
                <Fragment>
                  <span className="status-small price-drop" /> Price Drop{" "}
                  <UnitStatus event={d} />
                </Fragment>
              );
              eventType = "Price Drop";
            } else if (
              d.topic[0] === "listings" &&
              d.event_tags.indexOf("price-increase") >= 0
            ) {
              showStatus = (
                <Fragment>
                  <span className="status-small price-hike" /> Price Hike{" "}
                  <UnitStatus event={d} />
                </Fragment>
              );
              eventType = "Price Hike";
            }
            if (chartView == "property") {
              if (d.event_tags.indexOf("event-stage_sponsor") >= 0) {
                console.log(eventType, "eventType");
                showStatus = (
                  <Fragment>
                    <span className="status-small newdev" />
                    New Dev ({eventType}){" "}
                  </Fragment>
                );
              } else if (d.event_tags.indexOf("condo") >= 0) {
                showStatus = (
                  <Fragment>
                    <span className="status-small condo" /> Resale Condo (
                    {eventType})
                  </Fragment>
                );
              } else if (d.event_tags.indexOf("single-family") >= 0) {
                showStatus = (
                  <Fragment>
                    <span className="status-small single" /> Single-Family (
                    {eventType})
                  </Fragment>
                );
              } else if (d.event_tags.indexOf("multi-family") >= 0) {
                showStatus = (
                  <Fragment>
                    <span className="status-small multi" /> Multi-Family (
                    {eventType}){" "}
                  </Fragment>
                );
              } else {
                showStatus = (
                  <Fragment>
                    <span className="status-small coop" /> Resale Co-op (
                    {eventType})
                  </Fragment>
                );
              }
            }

            return (
              <TableRow
                className="material-table__row"
                role="checkbox"
                tabIndex={-1}
                key={d.id}
              >
                <TableCell
                 style={{width: "48px",
                  padding: '0 0 0 4px', ...style}}
             
                ></TableCell>
                <TableCell
                  style={style}
                  size="small"
                >
                  <span className="light-text small-text">
                    {d.topic[0] === "transactions" ? (
                      <span>
                        {moment(d[soldTimeField]).format("M/D/YY")}{" "}
                        {soldTimeField === "time" ? "(Recorded Date)" : ""}
                      </span>
                    ) : (
                      moment(d.time).format("M/D/YY")
                    )}
                  </span>
                </TableCell>
                <TableCell
                 
                  component="th"
                  scope="row"
               style={style}
                >
                  <span className="light-text small-text">{showStatus}</span>
                </TableCell>

                <TableCell
                style={style}
                  component="th"
                  scope="row"
                  padding="none"
                >
                  {viewType == "list" && (
                    <Fragment>
                      {d && d.building_data ? (
                        <span className="building_name">
                          {d.building_data.name
                            ? _capitalizeText(d.building_data.name)
                            : _capitalizeText(
                                d.building_data.display_full_street_address
                              )}{" "}
                          {d.address_2_normalized
                            ? ", " +
                              d.address_2_normalized
                                .toUpperCase()
                                .replace("APT", "Apt")
                            : ""}{" "}
                        </span>
                      ) : (
                        <span>
                          {d.building_name
                            ? _capitalizeText(d.building_name)
                            : _capitalizeText(d.display_full_street_address)}
                          {d.address_2_normalized
                            ? ", " +
                              d.address_2_normalized
                                .toUpperCase()
                                .replace("APT", "Apt")
                            : ""}{" "}
                        </span>
                      )}
                    </Fragment>
                  )}
                  <span>
                    {viewType == "list" ? (
                      <div className="light-text small-text">
                        {d.neighborhood && d.neighborhood[0]
                          ? `${_capitalizeText(d.neighborhood[0])}, `
                          : ""}{" "}
                        {_capitalizeText(d.borough)}
                      </div>
                    ) : (
                      <>
                        {" "}
                        {d.address_2_normalized
                          .toUpperCase()
                          .replace("APT", "Apt")}{" "}
                        <UnitIcons building={building} unit={d} eventView />
                      </>
                    )}
                  </span>
                </TableCell>
                <TableCell
                 style={style}
                >
                  <span className="light-text small-text">
                    {d.bedrooms > -1
                      ? d.bedrooms === 0
                        ? "Studio"
                        : d.bedrooms + " Bed"
                      : "--"}
                  </span>
                </TableCell>
                <TableCell
                style={style}
                >
                  <span className="light-text small-text">
                    {d.baths > -1 ? d.baths + " Bath" : "--"}
                  </span>
                </TableCell>

                <TableCell
                  style={style}
                >
                  {["Price Drop", "Price Hike"].indexOf(eventType) >= 0 &&
                  d.price_change_percent ? (
                    <span className="small-text">
                    {eventType == "Price Drop" ? <ArrowDownIcon size={16} color="#FF206E" style={{marginTop:-2}} /> : <ArrowUpIcon size={16}  color="#4ce1b6" style={{marginTop:-2}} />} {d.price_change_percent ? `${Math.round(d.price_change_percent * 10000)/100}%` : "--"}<span className="small-text light-text"><br/>(
                    {d.price_change_amount ? '$'+_formatNumber(Math.abs(d.price_change_amount)) : '--'})</span>
                    
                    </span>
                  ) : (
                    <span className="light-text small-text">
                      {parseInt(d.price.toString().replace(/\D/g, "")) > 0
                        ? "$" +
                          _formatNumber(d.price.toString().replace(/\D/g, ""))
                        : "--"}
                    </span>
                  )}
                </TableCell>
                <TableCell
                 style={style}
                  align="center"
                >
                  <span className="light-text small-text">
                    <a href={""}></a>
                    <br />
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}
