import React, { useState }  from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Modal,
} from 'reactstrap';
import CalendarClockIcon from 'mdi-react/CalendarClockIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ActiveIcon from 'mdi-react/CheckDecagramIcon';


import ViewedBuildings from '../../../containers/Dashboard/components/Summary/components/ViewedBuildings/index';
import TrialSubscribe from './TrialSubscribe';


export default function TrialLimit(props) { 
	
	const [ subscribe, setSubscribe ] = useState(false); 
	
	
	
	return (
		  <Col md={12}>
		    <Card>
		      <CardBody>
		        <div className="email-confirmation">
		          <div className="email-confirmation__icon">
		            <CalendarClockIcon className="email-confirmation__mail" />
		            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
		          </div>
		          <h3 className="email-confirmation__title">Free Trial Limit Reached</h3>
		          <p className="email-confirmation__sub">
		          <br/>{`Please activate your subscription to get full access!`}</p>
		          <ButtonToolbar className="email-confirmation__buttons">
				        <Button
					         onClick={() => {setSubscribe(true)}}
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					     >	Activate Subscription
					     </Button>				 
		          </ButtonToolbar>
		        </div>
		        <TrialSubscribe subscribe={subscribe} setSubscribe={setSubscribe} />
		      </CardBody>
		    </Card>
		  </Col>
    )
};


