import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import TimeLineItem from './TimeLineItem';
import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import moment from 'moment';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_replaceAbbreviations,
	_formatBed
} from "../../../../../shared/helpers/utils";

const Ava1 = `${process.env.PUBLIC_URL}/img/14.png`;
const Ava2 = `${process.env.PUBLIC_URL}/img/15.png`;

const apiURL = process.env.API_ROOT + '/query/';


class TimelineHistory extends PureComponent {  
  state = {
		rowsPerPage: 50,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		data: [],
		noMoreResults:false,
		timeLine: false
  }
  
  componentDidMount() {
	  const {  property } = this.props;
	  
	  this._getNews();
	  if(property.last_listed_sale_key_rebny && property.last_listed_sale_key_rebny !== ""){
		  const listingKey = property.last_listed_sale_key_rebny.replace("REBNY-LISTING-", "");
		  //this._getListingHistory( listingKey );	  	
	  }else{
		  //this._assembleTimeline([]);
	  }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	 
  }
  
  _formatTimeItem = ( type, item, prevItem ) => {
	  
	  let timeLineItem = {
		  time : null,
		  headline : null,
		  icon : null,
		  body: null
	  }
	  if(item.price < 0) item.price = 0;
	  switch( type ) {		  
		  case "offering" : 
			  timeLineItem.time = item.submitted_date;		  	
			  if(item.document_number === 0){
				  
				  if(item.price){
					  timeLineItem.headline = `Initial Offering for $${_formatNumber(item.price)}`;
				  }else{
					  timeLineItem.headline = `Initial Offering (Unavailable Price)`;
				  }
					  
				  
				  if(item.sq_ft && item.sq_ft > 0){
					   timeLineItem.headline += ` with a Sq Ft of ${_formatNumber(item.sq_ft)}`;
				  }
				  timeLineItem.icon = "offering";
				  timeLineItem.body=`(Plan ID: ${item.file_number.toString().toUpperCase()})`;
			  }else{
				  timeLineItem.time = item.submitted_date;
				  if(prevItem){				  					  	
					  if(item.price && prevItem.price && prevItem.price !== item.price){
						  const pct = (Math.abs(item.price - prevItem.price) / prevItem.price * 100).toFixed(1);
						  if(item.price > prevItem.price){
							  timeLineItem.icon = "up";					  							  	
							  timeLineItem.headline = `Offering Price Increased ${pct}% to $${_formatNumber(item.price)}`;
						  }else{
							  timeLineItem.icon = "down";
							  timeLineItem.headline = `Offering Price Decreased ${pct}% to $${_formatNumber(item.price)}`;
						  }
					  }
					  
					  if(prevItem.sq_ft !== item.sq_ft){
						  const pct = (Math.abs(item.sq_ft - prevItem.sq_ft) / prevItem.sq_ft * 100).toFixed(1);
						  let sq_headline = '';
						  if(item.sq_ft > prevItem.sq_ft){
							  sq_headline = `Sq Ft Increased ${pct}% to ${_formatNumber(item.sq_ft)}`;
						}else{
							sq_headline = `Sq Ft Decreased ${pct}% to ${_formatNumber(item.sq_ft)}`;
						}
						  if(timeLineItem.headline !== null){
							  timeLineItem.headline += ` and ${sq_headline}`;
						  }else{
							  timeLineItem.headline = sq_headline;
						  }
						  
					  }
						  
					  timeLineItem.body=`(Amendment: ${item.document_number})`;		
					  console.log(timeLineItem)	  		
				  }else if(item.price && item.price > 0){
					  timeLineItem.headline = `Offering for $${_formatNumber(item.price)}`;
				  
					  if(item.sq_ft && item.sq_ft > 0){
						   timeLineItem.headline += ` with a Sq Ft of ${_formatNumber(item.sq_ft)}`;
					  }
					  timeLineItem.icon = "offering";
					  timeLineItem.body=`(Plan ID: ${item.file_number.toString().toUpperCase()})`;
					  timeLineItem.body=`(Amendment: ${item.document_number})`;	
				  }
				  
			  }
			  break;
		  case "listing" :
			  timeLineItem.time = item.changed_date;
			  if(item.UpdateName){
				  let data = false;			  	
				  if(item.Data){
					  try{
						  data = JSON.parse(item.Data)
					  }catch(e){
						  
					  }
				  }
				  const listing_type = data && data.Class == 'UnitRent' ? 'Rent' : data.Class == 'UnitSale' ? 'Sale' : '';
				  
				  if(item.UpdateName === 'Add' && item.price){
					  timeLineItem.headline = `For ${listing_type} at $${_formatNumber(item.price)}`;
					  timeLineItem.icon = "listed";
					  timeLineItem.body=`(Listing ID: ${item.KeyFieldValue})`;
					  if(item.status !== 'Active'){
						  timeLineItem.headline = `Reported ${_capitalizeText(item.status)} for $${_formatNumber(item.price)}`;
						  if(["contract signed", "in contract"].indexOf(item.status.toLowerCase()) > -1){
							  timeLineItem.icon = "contract";
						}
						if(["sold"].indexOf(item.status.toLowerCase()) > -1){
							  timeLineItem.icon = "sold";
						}
					  }
				  }else if(item.UpdateName === 'Change'){
					  if(item.price && prevItem && prevItem.price){
						  
						  if(prevItem.price && item.price && item.price > prevItem.price){
							  const pct = (Math.abs(item.price - prevItem.price) / prevItem.price * 100).toFixed(1);
							  timeLineItem.icon = "up";
							  timeLineItem.headline = `${listing_type} Price Increased by ${pct}% to $${_formatNumber(item.price)}`;
						  }else{
							  const pct = (Math.abs(item.price - prevItem.price) / prevItem.price * 100).toFixed(1);
							  timeLineItem.icon = "down";
							  timeLineItem.headline = `${listing_type} Price Decreased by ${pct}% to $${_formatNumber(item.price)}`;
						  }
						  timeLineItem.body=`(Listing ID: ${item.KeyFieldValue})`;
						  
					  }else if(item.status){
						  if(["in contract", "contract signed", "sold", "active"].indexOf(item.status.toLowerCase()) > -1){
							  timeLineItem.headline = `Status Changed to ${_capitalizeText(item.status)}`;
							  if(item.price){
								  timeLineItem.headline += ` at $${_formatNumber(item.price)}`;
							  }		
							  if(["contract signed", "in contract"].indexOf(item.status.toLowerCase()) > -1){
								  timeLineItem.headline = `Reported Contract Signed`;
								  if(item.price){
									timeLineItem.headline += `for $${_formatNumber(item.price)}`;
								}
								  timeLineItem.icon = "contract";
							}
							if(["sold"].indexOf(item.status.toLowerCase()) > -1){
								timeLineItem.headline = `Reported Sold`;
								if(item.price){
									timeLineItem.headline += ` for $${_formatNumber(item.price)}`;
								}
								  timeLineItem.icon = "sold";
							}
							if(["active"].indexOf(item.status.toLowerCase()) > -1){
								  timeLineItem.icon = "listed";
							}				  	
						  }else{
/* Remove off-market listng events
							  if(item.status.toLowerCase().indexOf('tom')>-1){
								  timeLineItem.headline = `Listing Temporarily Taken Off Market`;
							  }else{
								  timeLineItem.headline = `Listing Taken Off  `;
							  }
							  
							  timeLineItem.icon = "off";
*/
						  }
						  //timeLineItem.body=`(Listing ID: ${item.KeyFieldValue})`;
						  
					  }	
				  }
				  
			  }
			  break;
		  case "listing-other" :
			  if(item.time && item.price){
				item.price = parseInt(item.price.replace(/[$,]/g, ''));
				
				item.foreign_id = item.foreign_id.replace("REBNY-LISTING-", "").replace("REBNY-IDX-", "").replace("REBNY-VOW-", "")
				
				  timeLineItem.time = item.event_time ? moment(item.event_time).toISOString() : moment(item.time).toISOString();
				if(item.event_tags && item.event_tags.indexOf("contract-signed") > -1){
					const listing_type = item.event_tags.indexOf('rent') >=0 ? 'Rental ' : '';
					if(item.price>0){
					   timeLineItem.headline = `${listing_type}Contract Signed for $${_formatNumber(item.price)}`; 
					}else{
						timeLineItem.headline = `${listing_type}Contract Signed ${item.price}`; 
					}
					  
					  timeLineItem.icon = "contract";
					  if(item.event_tags && item.event_tags.indexOf("manual-contract") > -1){
						  timeLineItem.body=`(Reported by Marketproof Research)`;
					  }else if(item.foreign_id){
						  timeLineItem.body=`(Listing ID: ${item.foreign_id})`;
					  }
					  
				  }
				  
				  if(item.event_tags && item.event_tags.indexOf("just-listed") > -1){
					  const listing_type = item.event_tags.indexOf('rent') >=0 ? 'Rent' : 'Sale';
					  
					if(item.price>0){
					   timeLineItem.headline = `For ${listing_type} $${_formatNumber(item.price)}`; 
					}else{
						timeLineItem.headline = `For ${listing_type} ${item.price}`; 
					}
					if(item.foreign_id){
						timeLineItem.body=`(Listing ID: ${item.foreign_id})`;
					}
					  
					  timeLineItem.icon = "listed";
					  
					  
				  }
				  
				  if(item.event_tags && item.event_tags.indexOf("price-decrease") > -1){
						const listing_type = item.event_tags.indexOf('rent') >=0 ? 'Rent' : 'Sale';
						
					  if(item.price>0){
						 timeLineItem.headline = item.headline3 ? `${listing_type} Price ${item.headline3.split(" on")[0]} to $${_formatNumber(item.price)}`: `Price Decreased to $${_formatNumber(item.price)}`; 
					  }else{
						  timeLineItem.headline = item.headline3 ? `${listing_type} Price ${item.headline3.split(" on")[0]}` : `Price Decreased`; 
					  }
					  if(item.original_price){
						  timeLineItem.body = `Original Price: ${item.original_price} (Listing ID: ${item.foreign_id})`
					  }
					  timeLineItem.icon = "down";
					}
				  if(item.event_tags && item.event_tags.indexOf("price-increase") > -1){
					  const listing_type = item.event_tags.indexOf('rent') >=0 ? 'Rent' : 'Sale';
					  
					if(item.price>0){
					   timeLineItem.headline = item.headline3 ? `${listing_type} Price ${item.headline3.split(" on")[0]} to $${_formatNumber(item.price)}`: `Price Increased to $${_formatNumber(item.price)}`; 
					}else{
						timeLineItem.headline = item.headline3 ? `${listing_type} Price ${item.headline3.split(" on")[0]}` : `Price Increased`; 
					}
					if(item.original_price){
						timeLineItem.body = `Original Price: ${item.original_price} (Listing ID: ${item.foreign_id})`
					}
					timeLineItem.icon = "up";
				  }
				  
				  
			  }
			  break;
		  case "property" :
			  if(item.last_sold_date){
				  timeLineItem.time = moment(item.last_sold_date * 1000).toISOString();
				if(item.last_sold_price > 0){
					timeLineItem.headline = `Property Sold for $${_formatNumber(item.last_sold_price)}`;
				}else{
					timeLineItem.headline = `Property Sold for Unknown Price`;
				}
				  
				  timeLineItem.icon = "sold";
				  
			  }
			  break;
		  case "transaction" :
			let data = false;			  	
			  if(item.data && item.data[0]){
				  try{
					  data = JSON.parse(item.data[0])
				  }catch(e){
					  
				  }
			  }
			  if(item.close_date_formatted){
				  
				  
				  
				  console.log(data)
				  timeLineItem.time = item.close_date_formatted ? moment(item.close_date_formatted).toISOString() : moment(item.recorded_datetime).toISOString();
				  if(item.price ==0){
					  timeLineItem.headline = `Unit Transferred Ownership for $0`;
				  }else if(item.price < 0){
					  timeLineItem.headline = `Unit Sold - Price Not Available`;
				  }else if(item && item.parcels_condocoop_count && item.parcels_condocoop_count>2){
					  timeLineItem.headline = `Unit Included in Bulk Sale of ${item.parcels_condocoop_count} Units for $${_formatNumber(item.price)}`;
				  }else{
					  timeLineItem.headline = `Unit Closed for $${_formatNumber(item.price)}`;
				  }
					  
				  if(item.sales_document_ids){
					  timeLineItem.body=`(${item.sales_document_ids[0] && item.sales_document_ids[0].indexOf('NYCPUB')<0 ? 'ACRIS Document ID: ' + item.sales_document_ids[0] : 'ACRIS Source: DOF Rolling Sales'} | Recorded: ${moment(item.recorded_datetime).format("MM/DD/YYYY")})`;
				  }
									
				  timeLineItem.icon = "sold";
				  
			  }
			  break;
		  
	  }
	  
	  if(timeLineItem.headline === null) return false;
	  
	  return timeLineItem;
	  
  }
  
  _assembleTimeline = (listingHistory, activityTransactions, otherListings) => {
	  const { unitHistory, property, buildingRes, transactions } = this.props;
	  const timeLine = [];

	  unitHistory && unitHistory.map((item, i) => {		  
		  const prevItem = i > 0 ? unitHistory[i-1] : false;
		  const timeItem = this._formatTimeItem('offering', item, prevItem);
		  if(timeItem) timeLine.push(timeItem);
	  });
	  
	  listingHistory && listingHistory.map((item, i) => {		  
		  const prevItem = i > 0 ? listingHistory[i-1] : false;
		  const timeItem = this._formatTimeItem('listing', item, prevItem);
		  if(timeItem) timeLine.push(timeItem);
	  });
	  
	  otherListings && otherListings.map((item, i) => {		  
		  const prevItem = i > 0 ? otherListings[i-1] : false;
		  const timeItem = this._formatTimeItem('listing-other', item, prevItem);
		  if(timeItem) timeLine.push(timeItem);
	  });

	  
	  transactions && transactions.map((item, i) => {
		  const prevItem = i > 0 ? transactions[i-1] : false;
		  const timeItem = this._formatTimeItem('transaction', item, prevItem);
		  if(timeItem) timeLine.push(timeItem);
	  })
	 
	  if(!transactions){
		  const timeItem = this._formatTimeItem('property', property, null);
		  if(timeItem) timeLine.push(timeItem);
	  }
	  
	  if(timeLine.length>0){
		  timeLine.sort( (a,b) => a.time < b.time ? 1 : -1);
	  }
	  this.setState({timeLine});
	  
	  
  }
  
  _getListingHistory = async ( listingKey, transactions, otherListings ) => {
			
/*
		  if(this.state.fetchInProgress === true){
			  return false;
		  }
		  this.setState({ fetchInProgress: true });
*/
	  
		const q = `KeyFieldValue:(${listingKey.join(' ')})`;
		
		const fq = ['ResourceID:Listing', "-(Timestamp:[2021-09-09T09:00:00Z TO 2021-09-10T10:00:00Z] AND (ProviderID:PDES))", "status:* OR price:*"];
		const axiosData = {
			url: apiURL + "rebny-history",
			method: "post",
			query: {
				q,
				fq,
				sort: "changed_date asc",
				wt: "json",
				rows: 999,
				start: this.state.start
			}
		};

		const formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs){
					this._assembleTimeline(res.docs, transactions, otherListings);
				}else{
					this._assembleTimeline([], transactions, otherListings);
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};
	
	_getNews = async () => {
			
		  if(this.state.fetchInProgress === true){
			  return false;
		  }
		  this.setState({ fetchInProgress: true });
		  
		  const { property } = this.props;
		  
		const address2 = property.alternate_unitcodes &&  property.alternate_unitcodes.length>0 ? '"' + property.alternate_unitcodes.join('" "') + '"' : '"' + property.address_2_normalized + '"';
	   
		const fq = [
			'topic:(listings)',
			//'-event_tags:rent',
			'-event_tags:just-sold',
			'hierarchy:[0 TO 2]',
			"-(time:[2021-09-09T13:00:00Z TO 2021-09-10T20:00:00Z] AND (foreign_id:REBNY-LISTING-RPLU* OR foreign_id:REBNY-LISTING-PDES*) AND slugs:*elliman*)",
			"-(time:[2021-09-30T13:00:00Z TO 2021-10-01T01:00:00Z] AND (foreign_id:REBNY-LISTING-RPLU*) AND slugs:*elliman* AND building:b-1-132-madison-ave-10016)"
			//'-event_tags:parcels_bulk-sale'
		]	    
		let q = '*:*';
		if(property){
			 const addressQ = property.alternate_addresses.join('" OR "');
			 q = `full_street_address:("${addressQ}") AND address_2_normalized:(${address2}) AND (zip:${property.zip} OR borough:"${property.borough}")`;		     
		}
				
		const axiosData = {
			url: apiURL + "we3-activities-3",
			method: "post",
			query: {
				q,
				fq,
				sort: "time desc",
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start
			}
		};

		const formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					//this.setState( { data: [...this.state.data, ...res.docs], fetchInProgress: false })
					const keys = res.docs.filter(item => item.topic[0] === 'listings').map(item => item.foreign_id.replace('REBNY-LISTING-', ''));
					const transactions = res.docs.filter(item => item.topic[0] === 'transactions');
					const otherListings = res.docs.filter(item => item.topic[0] === 'listings' && item.foreign_id.indexOf('REBNY-LISTING-') < 0);
					
					this._getListingHistory(keys, transactions, otherListings);
					
				}else if(res.docs.length === 0 ){
					this._assembleTimeline([]);
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};
	render() {

		
		const { timeLine } = this.state;
		
		if(!timeLine || timeLine.length <= 0){
			return null;
		}
		
		return (
			<Container>   
			<Row className="mt-3">
			  <Col md={12}>
				<h3 className="section-title section-title--not-last timeline__header-center">Property History</h3>
				<h3 className="section-title section-title--not-last subhead"> </h3>
			  </Col>
			</Row>
			<Row>
			  <Col md={12}>
				<div className="timeline">			    
				  {timeLine.map(item => 
					  <TimeLineItem item={item} type={moment(item.time).fromNow()} title={item.headline} date={moment(item.time).format('M/D/YYYY')}>
						<p>
						</p>
					  </TimeLineItem>
					  
				  )}
				</div>
			  </Col>
			</Row>
			</Container>
	  )
	}
};

export default TimelineHistory;