import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { _hasPermission	} from "../../../shared/helpers/access";

let rows = [
  {
    id: 'status', disablePadding: false, label: 'Status',
  }, 
  {
    id: 'display_full_street_address', disablePadding: true, label: 'Address',
  },
 
 {
   id: 'sq_ft', disablePadding: false, label: 'Size',
 },
  {
    id: 'bedrooms', disablePadding: false, label: 'Beds',
  },
  {
    id: 'baths', disablePadding: false, label: 'Baths',
  },
  {
    id: 'last_listed_sale_price_all', disablePadding: false, label: 'Asking Price',
  },
  {
    id: 'last_listed_sale_ppsf_all', disablePadding: false, label: 'Asking PPSF',
  },
  {
    id: 'last_listed_sale_date_all', disablePadding: false, label: 'Listed Date',
  },
  {
    id: 'last_sold_price', disablePadding: false, label: 'Sold Price',
  },
  {
    id: 'div(last_sold_price, sq_ft)', disablePadding: false, label: 'Sold PPSF',
  },
   {
    id: 'last_sold_date', disablePadding: false, label: 'Sold Date',
  },
  {
    id: 'year_built', disablePadding: false, label: 'Year Built',
  },
  
  {
    id: 'lot_area', disablePadding: false, disableSort:true, label: 'Lot Size',
  },
  
  
  
  
 
];

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, user
    } = this.props;
    
  
    rows = (user && user.role.indexOf('admin')>=0) ?  rows : rows.filter(item => !item.admin_only)

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={() => onSelectAllClick('buildings')}
            />
          </TableCell>
          {rows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.disableSort ? 
                <span>{row.label}</span>
                :
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  {row.label}
                </TableSortLabel>
              }
              
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(MatTableHead);
