import queryString from "query-string";
const storageKey = 'searchState';

/*
let initialState = {
  searchTags: [],
};
*/

export const syncLocations = () => {
	  let urlState = { ...queryString.parse(window.location.search) }
	  let filters = ['borough', 'neighborhood', 'building', 'marketing_company', 'developers', 'architects', 'lenders', 'area'];
	  
	  let overrideState = {
		  searchTags: [],
	  };
	  
	  const translateUrlTag = (k, value) => {
		
		if(k === 'neighborhood'){
			let val = value.split("_");
			return {id:val[0], name:val[0].replace(/-/g, ' '), type:k, data:{hood:val[0].replace(/-/g, ' '),boro:val[1].replace(/-/g, ' ')}};
		}else if(k === 'borough'){

			return {id:value.replace(/-/g, ' '), name:value.replace(/-/g, ' '), type:k, data:value.replace(/-/g, ' ')};
		}else if(k === 'building'){
			let bldg = atob(value).split("_");
			return {id:bldg[0], name:bldg[1].replace(/-/g, ' '), type:k, data:{key:bldg[0], name:bldg[1].replace(/-/g, ' ')}};
		}else if(k === 'area'){
			let area = JSON.parse(atob(value));
			return {id: "custom-area", name: "Map Area", type:k, data:area};
		}else if(['marketing_company', 'developers', 'architects', 'lenders'].indexOf(k) >=0 ){
		    let data = atob(value).split("_");
			return {id:data[0], name:data[1].replace(/-/g, ' '), type:k, data:data[1].replace(/-/g, ' ')};
		}
	 }
	  
	  
	  Object.keys(urlState).forEach((k) => {
		  if(filters.indexOf(k) < 0){
			   delete urlState[k];
		  } 
	  });	
	  
	  if(urlState.length<=0){
		  if(localStorage && localStorage.getItem(storageKey)){
				try{
					overrideState = JSON.parse(localStorage.getItem(storageKey));
				}catch(e){
					console.log("error initialState", e)
				}
		  }
	  }else{
		  
		  console.log(urlState);
		  Object.keys(urlState).forEach((k) => {	
			 // if(['borough','neighborhood'].indexOf(k)>=0){
				  urlState[k].split(",").map(value => {
					  overrideState.searchTags.push(translateUrlTag(k, value))
				  })
			 /* }
else if(k === 'building'){
				  index.search('', {
					  filters: `building_slugs:${urlState[k]}`
				  }).then(({ hits }) => {
					  console.log(hits);
				  });
			  }
*/
			  	  
			  	 
		  });	

	  } 	
	  
	  console.log(overrideState);
	  return overrideState;
}

let initialState = syncLocations();




function addTag(state, action) {
	console.log(action, "tagAction")
  const existingItemIndex = state.searchTags.findIndex(item => item.id === action.tag.id);
  let newState = Object.assign({}, state);
  
  if(['neighborhood', 'borough'].indexOf(action.tag.type) < 0 && state.searchTags.filter(item => [action.tag.type, 'neighborhood', 'borough'].indexOf(item.type) < 0).length>0){	  
	  newState.searchTags = newState.searchTags.filter(item => ['neighborhood', 'borough'].indexOf(item.type) >= 0);
  }
  console.log(existingItemIndex, "existingIndex");
  if(existingItemIndex < 0){
	console.log(action.tag, "tagstate")
	newState.searchTags.push(action.tag)	    
  }
  
  else if(action.tag.type == "area"){
	  newState.searchTags[existingItemIndex] = Object.assign({}, action.tag);
  }
  
  if(localStorage) localStorage.setItem('searchState', JSON.stringify(newState));
  return newState;
  
   
}

function removeTag(state, action) {
	console.log(state, action, "actionReducer")
	// const existingItemIndex = state.searchTags.findIndex(item => action.tag.id.includes(item.id));
	const existingItemIndex = state.searchTags.findIndex(item => {
		if (typeof action.tag.data.hood === "string") {
		  return `${item.data.hood}_${item.data.boro}` === `${action.tag.data.hood}_${action.tag.data.boro}`;
		} else {
		  return item.id === action.tag.id;
		}
	  });
	  
  let newState = Object.assign({}, state);
  if(existingItemIndex >= 0){
	newState.searchTags.splice(existingItemIndex, 1);	    
  }
  if(localStorage) localStorage.setItem('searchState', JSON.stringify(newState));
  return newState;  
   
}
function removeMapTag(state, action) {
	const polygonIdsToRemove = action.tag;
  
	// Filter the searchTags array
	const newState = {
	  ...state,
	  searchTags: state.searchTags.map(item => {
		// If the item is of type "area", check its data array for polygon_id
		if (item.type === "area" && Array.isArray(item.data)) {
		  // Filter out the objects with matching polygon_id within the data array
		  item.data = item.data.filter(dataItem => !polygonIdsToRemove.includes(dataItem.polygon_id));
		}
		return item;
	  }).filter(item => {
		// Remove the whole item if it is an area and its data array is empty
		return !(item.type === "area" && Array.isArray(item.data) && item.data.length === 0);
	  })
	};
  
	// Update localStorage if it exists
	if (localStorage) {
	  localStorage.setItem('searchState', JSON.stringify(newState));
	}
  
	// Return the updated state
	return newState;
  }
function removeAttributes(state) {
	
  let newState = Object.assign({}, state);
  newState.searchTags = newState.searchTags.filter(item => ['neighborhood', 'borough', 'building', 'marketing_company', 'developers', 'architects', 'lenders', 'area'].indexOf(item.type) >= 0);
  if(localStorage) localStorage.setItem('searchState', JSON.stringify(newState));
  return newState;  
   
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TAG':
      return addTag(state, action);      
    case 'REMOVE_TAG':
      return removeTag(state, action);
	  case 'REMOVE_MAP_TAG':
		return removeMapTag(state, action)
    case 'CLEAR_TAGS': 
      const clearState = {
	  	searchTags: [],
	  }; 
      if(localStorage) localStorage.setItem('searchState', JSON.stringify(clearState));
	  return clearState;
	case 'CLEAR_ATTRIBUTES' : 
	  return removeAttributes(state);
    default:
      return state;
  }
};
export default searchReducer;
