import axios from "axios";
import React, { Component } from 'react';
import JoditEditor, { Jodit } from "jodit-react";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_formatUnitNumber,
} from '../../helpers/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = (options, { inputValue }) => { return options }
const apiUrl = process.env.API_ROOT;  
const imageUploadUrl = process.env.API_ROOT + '/pipeline/imageupload';
const instagramEmbed = {
				tooltip: 'Instagram Embed',
				iconURL: 'https://marketproof-hosting.s3.amazonaws.com/assets/instagram-light.svg',
				popup: (editor, current, self, close) => {
					const form = editor.create.fromHTML(
						`<form>
							<div class="">
								<span class="jodit-ui-text-area__label">Instagram embed code</span>
								<textarea class="jodit-ui-instagram__input" name="instagram_code" type="text" required="true" placeholder="" rows="5" col="50"></textarea>
								
							</div>
							<button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_primary jodit-ui-button_text-icons_true" type="submit">
								<span class="jodit-ui-button__icon"></span><span class="jodit-ui-button__text">Insert</span>
							</button>
						 </form>`
					);
			
					editor.e.on(form, 'submit', (e) => {
						e.preventDefault();
						editor.s.insertHTML(form.querySelector('.jodit-ui-instagram__input').value);
/*
						if(window.instgrm){
							 window.instgrm.Embeds.process()
						}
*/
						close();
					});
			
					return form;
				}
			}
const defaultConfig = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		height:500,
		allowResizeX: false,
		enableDragAndDropFileToEditor: true,
		buttons: [
	        'bold',
	        'strikethrough',
	        'underline',
	        'italic', '|',
	        'ul',
	        'ol', '|',
	        'outdent', 'indent',  '|',
	        'font',
	        'fontsize',
	        'brush',
	        'paragraph', '|',
	        'image',
	        'video',
	        instagramEmbed,
	        'table',
	        'link', '|',
	        'align', 'undo', 'redo', '|',
	        'hr',
	        'eraser',
	        'copyformat', '|',
	        'symbol', '|',
	        'source', '|',
	        
	    ],
	    colorPickerDefaultTab: 'text',
	    uploader: {
		    insertImageAsBase64URI: false,
	        url: imageUploadUrl,
	        format: 'json',
	        method: 'POST',
	        pathVariableName: 'path',
	        filesVariableName: function(i){
		     	return "file"; 
		    },	        
	        prepareData: function (data) {
	            return data;
	        },
	        isSuccess: function (resp) {
	            return !resp.error;
	        },
	        getMsg: function (resp) {
	            return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
	        },
	        process: function (resp) {
	            return {
	                file: resp || []
	            };
	        },
	        error: function (e) {
	            this.events.fire('errorPopap', [e.getMessage(), 'error', 4000]);
	        },
	        defaultHandlerSuccess: function (data, resp) {
		        console.log(data, resp)
	            var i; //, field = this.options.uploader.filesVariableName;
	            if (data['file'] && data['file'].length) {
	                for (i = 0; i < data['file'].length; i += 1) {
		                console.log(data['file'][i])
	                    this.selection.insertImage(data['file'][i]);
	                }
	            }
	        },
	        defaultHandlerError: function (resp) {
	            this.events.fire('errorPopap', [this.options.uploader.getMsg(resp)]);
	        }
	    }
}


class RichTextEditor extends Component {
	
  
  
  constructor(props) {
    super(props);
    this.state = { 
	    instagram : false
 	    
	};

	this.editor = React.createRef();
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

	  
  }


  render() {
    const { onChange, editorConfig, value } = this.props;
	
	const config = editorConfig ? editorConfig : defaultConfig;
    return (
      <div>
      	<JoditEditor
            	ref={this.editor}
                value={value}
                config={config}
				tabIndex={1} // tabIndex of textarea
				onClick={(e) => e.stopPropagation()}
				onBlur={newContent => { onChange(newContent)} } // preferred to use only this option to update the content for performance reasons
                //onChange={newContent => { onChange(newContent)} }
            />
       
      </div>
    );
  }
}

export default RichTextEditor;