import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';

import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/ImageSizeSelectSmallIcon';
import OpenIcon from 'mdi-react/OpenInNewIcon';

import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
	_formatBigNumber,
	urlSlugBuilding
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';



  
const calculateAbsorption = (buildingRes, statsRes, period) => {		
	if(!buildingRes.sales_start_date) return '--';	
	let totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	let months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);
	
	if(period && period === "1-yr"){
		if(statsRes && statsRes.sold_1_yr && statsRes.sold_1_yr.count > 0){
			totalSold = statsRes.sold_1_yr.count;
			if(months > 12){
				months = 12;
			}
		}
	}else if(period && period === "3-mo"){
		if(statsRes && statsRes.sold_3_mo && statsRes.sold_3_mo.count > 0){
			totalSold = statsRes.sold_3_mo.count;
			if(months > 3){
				months = 3;
			}
		}
	}else if(period && period === "6-mo"){
		if(statsRes && statsRes.sold_6_mo && statsRes.sold_6_mo.count > 0){
			totalSold = statsRes.sold_6_mo.count;
			if(months > 6){
				months = 6;
			}
		}
	}
	
	const velocity = totalSold / months;
	
	const soldPct = totalSold / buildingRes.inventory_total;
	
	if(velocity <=0 || totalSold <=0 || soldPct < .01){
		return '--';
	}
	
	const monthsLeft = (buildingRes.inventory_unsold / velocity).toFixed(1);	
	
	return monthsLeft;
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes) => {		
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + + buildingRes.inventory_contract;
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalAvail, fill: getDefaultChartColors[1] },
  				{ value: totalSold, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSellout = (buildingRes, statsRes, period, type) => {		
	const monthsLeft = calculateAbsorption(buildingRes, statsRes, period);
	if( monthsLeft === '--' ){
		if(type === 'fromNow'){
			return 'Not Enough Info';
		}else{
			return null;
		}
		
	}
	
	
	if(type === 'fromNow'){
		return moment().add(monthsLeft, 'M').fromNow();
	}	
	return `(${moment().add(monthsLeft, 'M').format("M/D/YYYY")})`;
}

const AbsorptionRateCard = (props, { t }) => {
	const { buildingRes, location, statsRes, period, link, activityStatsRes } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	let months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);

	let doPeriod = period;
	if(months < 12){
		doPeriod = "6-mo";
	}
	if(months < 6){
		doPeriod = "3-mo";
	}
	return (
		      <CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10, borderRadius:0 }}  >
		        <div className="card__title">
		          <h5 className="card__title-center">{`Absorption`}</h5>
		        </div>
		        <div className="dashboard__health-chart"> 
		          <ResponsiveContainer height={160}>
		            <PieChart>
		              <Pie data={calculateBarData(buildingRes, activityStatsRes, doPeriod)} dataKey="value" cy={65} innerRadius={50} outerRadius={60} />
		            </PieChart>
		          </ResponsiveContainer>
		           <div className="pie-chart-info" style={{paddingTop:10}}>
		            <ClockOutlineIcon style={{ fill: getDefaultChartColors[1] }} />
		            <h5 className="profile__chart-number">{calculateAbsorption(buildingRes, activityStatsRes, doPeriod)}</h5>

		            <div className="product-card__subtext">months</div>
		          </div>
		        </div>
		        

		        <div className="product-card__subtext">Sellout {_capitalizeText(calcuateSellout(buildingRes, statsRes, doPeriod, 'fromNow'))}</div>
		      </CardBody>
	)
};

const AbsorptionRateSmall = (props) => {
	const { buildingRes, statsRes, col } = props;
	
	//if(!statsRes) return null;
	const data = calculateBarData(buildingRes);
	
	
	return (
		  <Col md={12} xl={col ? col : 2} lg={6} sm={12} xs={12}>		  	
		    <Card>
		    {props.location ? 
			    <Link to={`${props.location.pathname.replace('/tab/overview', '')}/tab/analysis`}>
				    <AbsorptionRateCard {...props} link />
				</Link>
			:
				<AbsorptionRateCard {...props} />
			}
			</Card>
		  </Col>
    )
};



export default AbsorptionRateSmall;








