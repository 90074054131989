import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import ActiveIcon from 'mdi-react/CheckCircleOutlineIcon';
import VetIcon from 'mdi-react/AlertDecagramOutlineIcon';


import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class ACRISVettedStatus extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
  
  
  openAcris = (transaction)  => {
	if(transaction.sales_document_ids & transaction.sales_document_ids[0]){
		window.open(`https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${transaction.sales_document_ids[0]}`)
	}
	
  }
 

  render(){
	  const { modal } = this.state;
	  const { transaction, contract, admin } = this.props;
	  
	  if(transaction && transaction.property_type == "sp") return null;
	  const modalClass = classNames({
	      'modal-dialog--colored': false,
	      'modal-dialog--header': true,
	   });
	   if(contract && transaction && transaction.verified_contract_date && transaction.verified_contract_date > 100 ){
			 return (
			   <Fragment>
				   <span className="offering-status">
						 <Tooltip title="Verified Contract Date">
							 <IconButton size={`small`} onClick={this.toggle} style={{ marginTop:"-1px"}} >
								 <ActiveIcon size={16} color={'#780F9E'} />
							 </IconButton>
						 </Tooltip>
					 </span>		  		
					 <Fragment>
					   <Modal
						 isOpen={modal}
						 toggle={this.toggle}
						 modalClassName={`ltr-support`}
						 className={`modal-dialog--primary ${modalClass}`}
					   >
					   <div className="modal__header">
						   <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
						   <h4 className="text-modal  modal__title"><ActiveIcon size={24} color={'#EDDEF5'} />Verified Contract Date</h4>
						 </div>
						 <div className="modal__body">
							 <p style={{fontSize:16}}>The contract date of this transaction has been verified by the RP-5217 Real Property Transfer Report.</p>
					
								   
						 </div>
						 
						 <ButtonToolbar className="modal__footer offering-status">
						   {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
						   <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
							   Ok
						   </Button>
						 </ButtonToolbar>
					   </Modal>
				   </Fragment>
			   </Fragment>
			 )
		}else if(transaction && transaction.verified_close_price && transaction.verified_close_price > -1 ){
		  return (
			<Fragment>
				<span className="offering-status">
		  			<Tooltip title="Verified Sold Price">
		  				<IconButton size={`small`} onClick={this.toggle} style={{ marginTop:"-1px"}} >
		  					<ActiveIcon size={16} color={'#780F9E'} />
		  				</IconButton>
		  			</Tooltip>
		  		</span>		  		
		  		<Fragment>
			        <Modal
			          isOpen={modal}
			          toggle={this.toggle}
			          modalClassName={`ltr-support`}
					  className={`modal-dialog--primary ${modalClass}`}
			        >
			        <div className="modal__header">
				        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				        <h4 className="text-modal  modal__title"><ActiveIcon size={24} color={'#EDDEF5'} />Verified Sold Price</h4>
				      </div>
				      <div className="modal__body">
				      	<p style={{fontSize:16}}>The sold price of this transaction has been verified by Marketproof using the RP-5217 Real Property Transfer Report.</p>
				 
				      	      
				      </div>
				      
				      <ButtonToolbar className="modal__footer offering-status">
				        {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
				        <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
				        	Ok
				        </Button>
				      </ButtonToolbar>
			        </Modal>
			    </Fragment>
		    </Fragment>
		  )
	 }else if(admin){
		 return (
			 <Fragment>
		 		<span className="offering-status">
			   	<Tooltip title="Verify Sold Price and Contract Date">
				   	<IconButton size={`small`} onClick={(e) => {e.preventDefault();e.stopPropagation();this.openAcris(transaction)}} style={{ marginTop:"-1px"}} >
					   	<VetIcon size={16} color={'#780F9E'} />
				   	</IconButton>
			   	</Tooltip>
		   		</span>		
		 	</Fragment>
	 	)
	 }else{
		 return null;
	 }
 }
	
};


export default ACRISVettedStatus;
