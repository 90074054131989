import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';


import { RTLProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import buyersListActions from '../../redux/actions/buyersListActions';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import ShareIcon from 'mdi-react/AccountPlusOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { getFormValues } from 'redux-form';

import ReportForm from './components/ReportForm';
import CustomReportForm from './components/CustomReportForm';

import ReportStepperForm from './components/ReportStepperForm';
import SnackBar from '../../shared/components/SimpleSnackBar';

import { Publisher } from './../../shared/pubsub/publisher.js';
import ChangePlan from '../../shared/components/payment/ChangePlan';
import { _hasPermission	} from "../../shared/helpers/access";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_slugifyText,
	setLocalStorage
} from '../../shared/helpers/utils';


import{
	_axiosCall
} from '../../shared/helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/buyerslist/'; //'http://localhost:8000/buyerslist/';
const redux_form_name = 'share_report_form';
class ReportButton extends Component {
  static propTypes = {
	actions: PropTypes.shape({
	  editMyBuyersList: PropTypes.func,
	  completeMyBuyersList: PropTypes.func,
	  deleteMyBuyersList: PropTypes.func,
	  addMyBuyersList: PropTypes.func,
	  togglePriorityFilter: PropTypes.func,
	}).isRequired,
	theme: ThemeProps.isRequired,
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
	buildingKey: PropTypes.string,
  };

  constructor(props) {
	super(props);
	this.state = { 
		buttonText: 'Contact',
		modal: false,
		saved: false,
		link_id: this.props.linkID ? this.props.linkID : false,
		snackShow: false,
		snackMessage: false,
		snackType: 'success',
		deleteModal: false
		
	};
	this.toggle = this.toggle.bind(this);
	this.handleSave = this.handleSave.bind(this);
	this.checkLink = this.checkLink.bind(this);
	this.toggleDelete = this.toggleDelete.bind(this);
	this.handleDelete = this.handleDelete.bind(this);
	this.setFormValues = this.setFormValues.bind(this);
	
  }
  
   handleSave(preview) {
   // event.preventDefault();
	 const { authState, authService, dispatch, user, formValues, buildingRes, property, listing, group } = this.props;
	 const { link_id } = this.state;	
	 
	 if(!formValues.customLink || formValues.customLink.length<3){
		 this.snackOpen(`Custom Link Must Be 3 or More Characters.`, 'error');
		 return false;
	 }

	 const customData = {
		   message : formValues.message,
		   ap : formValues.contactForm && formValues.contactForm == "ap" ? true : false,
		   lk : formValues.lk,
		   contact_broker : formValues.contactBroker,
		   group: group,
		   report_title : formValues.reportTitle ? formValues.reportTitle : "",
		   report_subtitle : formValues.reportSubTitle ? formValues.reportSubTitle : "",
		   report_theme : formValues.reportTheme ? formValues.reportTheme : "",
		   prepared_for : formValues.preparedFor ? formValues.preparedFor : "",
		   show_stats : formValues.showStats ? formValues.showStats : false,
		   contact_form : formValues.contactForm ? formValues.contactForm : ""
	 }
	const saveData = {
			link_id : link_id,
			custom_link: formValues.customLink,
			report_type: formValues.reportType ? formValues.reportType : false,
			group_id : group.group_id,
			user : user,
			   building_key: false,
			   building : false,
			   property : false,
			   listing : false,	   		
			   custom_data : preview ? customData : JSON.stringify(customData)
		}
 	 
	  
	if(preview){
		console.log(saveData)
		saveData['user_key'] = user && user.id;
		setLocalStorage("share_", formValues.customLink, saveData);
		
		let link = this.generateShareLink();
		window.open(link+"?preview=true"); 
		return false;
	}  
	 let apiData = {
			url: apiUrl+'sharepage/save',
			method: "post",
			query: saveData
			};
	  
	  
	  _axiosCall(apiData)
		.then(res => {
			console.log(res)
			if(res && res.result && res.result.link_id){
				this.setState({saved:true, link_id: res.result.link_id});
				
				if(preview){
				  window.open(this.generateShareLink()); 
				}else{
				  this.snackOpen('Share Link Copied To Clipboard.', 'success');  
				}
				
			}else if(res && res.result && res.result.error){
				if(res.result.message && res.result.message == "link_exists"){
					this.snackOpen(`Custom Share Link "${formValues.customLink}" Already Exists.  Try Another.`, 'error');
				}
				
			}
		})
   // addMyBuyersList(buildingKey, priority);
   // this.setState({ buttonText: 'Building Saved'})
	
  }
  
  handleDelete(preview) {
	 // event.preventDefault();
	   const { authState, authService, dispatch, user, formValues, buildingRes, property, listing, report, toggleRefresh } = this.props;
	   const { link_id } = this.state;	
	   
	 
	  const saveData = {
			  link_id : link_id,
			  custom_link: report.custom_link,
			  user : user,
			  deleted:true
		  }
	  
	   let apiData = {
			  url: apiUrl+'sharepage/save',
			  method: "post",
			  query: saveData
		};
		
		
		_axiosCall(apiData)
		  .then(res => {
			  console.log(res)
			  if(res && res.result && res.result.link_id){
				  
					this.snackOpen('Your Report Has Been Deleted.', 'success');  
					this.setState({deleteModal:false, link_id:false});
					toggleRefresh();
				  
				  
			  }else if(res && res.result && res.result.error){
				  this.snackOpen(`Delete error, please try again.`, 'error');
				  
			  }
		  })
	 // addMyBuyersList(buildingKey, priority);
	 // this.setState({ buttonText: 'Building Saved'})
	  
	}
  
  checkLink(customLink) {
	  const { authState, authService, dispatch, user, formValues, buildingRes, property, listing } = this.props;
	  const { link_id } = this.state;
	  
	  let dupquery = { "custom_link" : customLink };
	  if( link_id ){
		   dupquery["link_id"] = { "$ne" : link_id }
	  }
	  
	  let apiData = {
			url: apiUrl+'sharepage/query',
			method: "post",
			query: dupquery
			};
	  
	  
	  _axiosCall(apiData)
		.then(res => {
			console.log(res)
		   
		})	
	  
  }
  snackOpen = (message, type) => {
	  const { snackOpen } = this.props;
	  
	  if(snackOpen){
		  snackOpen(message, type)
	  }else{
		  this.setState( { snackShow: true, snackMessage: message, snackType : type} )
	  }
	  
  };
  
  snackClose = (event, reason) => {
	  const { snackClose } = this.props;
	  event && event.stopPropagation();
	  if (reason === 'clickaway') {
		return;
	  }	
	  
	  if(snackClose){
		  snackClose(event, reason);
	  }else{
		  this.setState( { snackShow: false, snackMessage: '' } )
	  }
	  
  };
	


  toggle(e) {
	e && e.stopPropagation();
	const { buildingRes, property, toggleRefresh, report } = this.props;
	
	const { modal } = this.state;
	
	if(modal && report){
		toggleRefresh();
	}
	this.setState(prevState => ({ modal: !prevState.modal }));
	
	
  }
  
  toggleDelete() {
	  const { buildingRes, property, toggleRefresh, report } = this.props;
	  
	  const { deleteModal } = this.state;
	  
	  if(deleteModal && report){
		  toggleRefresh();
	  }
	  this.setState(prevState => ({ deleteModal: !prevState.deleteModal }));
	 
	}
  
  generateShareBase = (reportType) => {		 
	 
	 //let shareBaseURL = `https://newdev.marketproof.com/share/`	 
	 let shareBaseURL = window.location.protocol + "//" + window.location.host + "/share/"
	 if(reportType && ['building-comp'].indexOf(reportType)>=0){
		 shareBaseURL = window.location.protocol + "//" + window.location.host + "/private-report/"
	 }
	 
	 return shareBaseURL;
  }
  generateShareLink = ( ) => {		 
	
	 const { buildingRes, property, formValues, initialValues, generateShareBase } = this.props;
	 
	 
	 let shareBaseURL = this.generateShareBase(formValues && formValues['reportType'] ? formValues['reportType'] : false);
	 
	 if(formValues && formValues.customLink){
		 return shareBaseURL + formValues.customLink; 
	 }else if(initialValues && initialValues.customLink){
		 return shareBaseURL + initialValues.customLink; 
	 }
	
	 return shareBaseURL;
}

  setFormValues(){
	   const { authState, authService, dispatch, user, formValues, buildingRes, property, listing } = this.props;	
	  
			
			
   }
  
  

  render() {
	const {
	  actions, t, theme, rtl, buildingRes, user, property, listing, report, shareLink
	} = this.props;
	
	const { modal, snackShow, snackMessage, saved, snackType, deleteModal } = this.state;
	const upgradeMessage = <div><span>Access to share Collections requires an upgrade to your subscription.</span> 
	 <p><a href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo" style={{color:'#780F9E',fontSize:16}} target="_blank">
		 Learn about Collections <OpenInNewIcon size={16} style={{marginTop:-2}} /></a></p></div>;
	return (
		<Fragment>
			<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				{report ? 
				<Fragment>
					<Tooltip title="Edit This Shared Page">	            		
					  <IconButton	
						  className="material-table__toolbar-button"
						  onClick={(e) => {e.preventDefault();e.stopPropagation();this.toggle()}}						  
					  >
					  <EditIcon /> 
					  </IconButton>
					</Tooltip>
					<Tooltip title="Delete This Shared Page">	            		
					  <IconButton	
						  className="material-table__toolbar-button"
						  onClick={(e) => {e.preventDefault();e.stopPropagation();this.toggleDelete()}}						  
					  >
					  <DeleteIcon /> 
					  </IconButton>
					</Tooltip>
					<Modal
					  isOpen={deleteModal}
					  toggle={(e) => {e.preventDefault();e.stopPropagation();this.toggleDelete()}}	
					  modalClassName={`${rtl.direction}-support`}
					  className={`modal-dialog contact__modal share__modal`}
					>
					<div className="modal__header">
						<button className="lnr lnr-cross modal__close-btn" type="button" onClick={(e) => {e.preventDefault();e.stopPropagation();this.toggleDelete()}}	 />
					  </div>
					  <Row>
											  
						<Col xl={12} lg={12} md={12}>
							<div className="modal__body background_white">
								<h4 className="contact__title">Delete This Shared Page</h4>
								
								
								<hr/> 
								<div style={{fontSize:16}} className="mb-3">
									<p>Once deleted, this page will no longer be accssible to anyone with the shared link.</p>
									<p>View: {shareLink && report.custom_link && <span><a href={shareLink} className="link-text" target="_blank">{shareLink}</a></span>} </p>
									<p>Are you sure you want to delete this page?</p>
								</div>
								<hr/>      
								{/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}				             
								 <ButtonToolbar className="modal__footer">
									   {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
									 <Tooltip title="Cancel">
										 <Button
											  onClick={this.toggleDelete}
											  variant="outlined"
											  color=""
											  className=""		         
											  startIcon={null}
										  >	Cancel
										  </Button>				 
									   </Tooltip>{' '}
									   
									   <Tooltip title="Confirm Delete of Report">
											<Button
												onClick={this.handleDelete}
												variant="contained"
												  color="secondary"
												  className="buyersList__primary-button"	         
												 startIcon={<DeleteIcon />}
											 >	Delete
											 </Button>				 
										  </Tooltip>{' '}
								  </ButtonToolbar>
							</div>	
											
						</Col>
							  
					  </Row>
					</Modal>
				</Fragment>
				:
				<Tooltip title={`Share this collection`}>
					<Button
						 onClick={(e) => {e.stopPropagation();this.toggle()}}
						 variant="contained"
						 size="small"
						 color="secondary"
						 className="buyersList__secondary-button"			         
						 startIcon={<ShareIcon />}
					 >	Share
					 </Button>				 
				</Tooltip>
			    }
				
			
			  {!_hasPermission(user, 'collections') ?
			  <ChangePlan modal={modal} setModal={this.toggle} changePlan={'complete-199'} message={upgradeMessage} />
				 
				:
			  
			  <Fragment>
				<Modal
				  isOpen={modal}
				  toggle={this.toggle}
				  modalClassName={`${rtl.direction}-support`}
				  className={`modal-dialog contact__modal share__modal`}
				>
				<div className="modal__header">
					<button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				  </div>
				  <Row>
										  
					<Col xl={12} lg={12} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">Personalize & Share This Collection</h4>
							<hr/>      
							{/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}				             
							<CustomReportForm 
								 user={user} 
								 toggle={this.toggle} 
								 saved={saved} 
								 handleSave={this.handleSave} 
								 generateShareBase={this.generateShareBase} 
								 generateShareLink={this.generateShareLink}
								 checkLink={this.checkLink}
								 report={this.props.report}
								 {...this.props}
								 />
							{/* <ReportStepperForm  
							 user={user} 
							 toggle={this.toggle} 
							 {...this.props}
							 saved={saved} 
							 handleSave={this.handleSave} 
							 generateShareBase={this.generateShareBase} 
							 generateShareLink={this.generateShareLink}
							 checkLink={this.checkLink}
							report={this.props.report}
							setFormValues={this.setFormValues}  /> */}
							
						</div>	
										
					</Col>
						  
				  </Row>
				</Modal>
			</Fragment>
			}

		</Fragment>
	);
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
	? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
	: state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, formValues: getFormValues(redux_form_name)(state), rtl: state.rtl, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ReportButton));
