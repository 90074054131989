import React from 'react';
import { Container, Row } from 'reactstrap';
import EmptyBuildings from './components/EmptyBuildings';
import EmptyUnits from './components/EmptyUnits';

const EmailConfirmation = ( props ) => (
  <Container>
    <Row className={`${props.small ? 'small' : ''}`}>
    {props.savedUnits ? 
	    <EmptyUnits />
	    :
	    <EmptyBuildings />
    }
      
    </Row>
  </Container>
);

export default EmailConfirmation;
