import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import searchActions from '../../../redux/actions/searchActions';
import RemoveIcon from 'mdi-react/CloseCircleIcon';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DropdownIcon from 'mdi-react/ArrowDownDropCircleIcon';
import { _capitalizeText } from '../../../shared/helpers/utils';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

function truncate(input, len) {
   if (input.length > len) {
      return input.substring(0, len) + '..';
   }
   return input;
};

//currentRefinement = user query, refine is algolia defined function
class SearchTags extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.log('ASearch didUpdate ', prevProps, this.props, prevState, this.state);
	}

	_inputFocus(e) {
		const input = document.getElementById("tag-input");
		input.focus();
	}

	_openResults = () => {
		//return this.props.onFocus();
	};
	
	_deleteTag = (tag) => {
		const {clearMap} = this.props;
		const {removeTag} = this.props.actions;
		removeTag(tag)
		// if(tag && tag.type == "area"){
		// 	clearMap();
		// }
	}
	_clickTag = (tag) => {
		const {toggleMapModal} = this.props;
		if(tag && tag.type == "area"){
			toggleMapModal();
		}
	}


	render() {
		
		const {clearMap} = this.props;
		const {removeTag} = this.props.actions;
		const searchTags = this.props.searchTags;
		const tagLen = searchTags ? searchTags.length : 0;
		
		
		if(!searchTags || tagLen <= 0) return null;
		
		if(this.props.searchBar){
			
			if(isMobile){
				return (
					<div
						className="c_input-selected"
						onClick={null}
					>
						{tagLen == 1 ? 
							
						<span className={`c_input-tag ${searchTags[0].type=='area' ? "c_is_area" : ""}`} onClick={(e) => {e.stopPropagation();e.preventDefault();this._clickTag(searchTags[0])}}>
							{truncate(_capitalizeText(searchTags[0].name), 18)}
							
							<Tooltip title={`Remove ${_capitalizeText(searchTags[0].name)} From Search`}>
		                      	<IconButton
		                      		size={'small'}
						            onClick={(e) => {e.stopPropagation();e.preventDefault();this._deleteTag(searchTags[0])}}
						            className="material-table__toolbar-button"
						          >
		
								  <RemoveIcon size={14}  />
							  	</IconButton>
						  	</Tooltip>
						</span>
						:
						<span className={`c_input-tag ${searchTags[0].type=='area' ? "c_is_area" : ""}`}>
								{tagLen} Selected
								<Tooltip title={`Expand to See All`}>
			                      	<IconButton
			                      		size={'small'}
							            onClick={(e) => {}}
							            className="material-table__toolbar-button"
							            id={'expand-search'}
							          >
			
									  <DropdownIcon size={14}  />
								  	</IconButton>
						  	</Tooltip>
						</span>
						}
					</div>				
				)
				
				
			}else{
				return (
					<div
						className="c_input-selected"
						
						onClick={null}
					>
						<span className={`c_input-tag ${searchTags[0].type==='area' ? "c_is_area" : ""}`} onClick={(e) => {e.stopPropagation();e.preventDefault();this._clickTag(searchTags[0])}}>
							{truncate(_capitalizeText(searchTags[0].name), 20)}
							
							<Tooltip title={`Remove ${_capitalizeText(searchTags[0].name)} From Search`}>
		                      	<IconButton
		                      		size={'small'}
						            onClick={(e) => {e.stopPropagation();e.preventDefault();this._deleteTag(searchTags[0])}}
						            className="material-table__toolbar-button"
						          >
		
								  <RemoveIcon size={14}  />
							  	</IconButton>
						  	</Tooltip>
						</span>
						{searchTags[1] &&
						<span className={`c_input-tag ${searchTags[1].type=='area' ? "c_is_area" : ""}`} onClick={(e) => {e.stopPropagation();e.preventDefault();this._clickTag(searchTags[1])}}>
							{truncate(_capitalizeText(searchTags[1].name), 20)}
							
							<Tooltip title={`Remove ${_capitalizeText(searchTags[1].name)} From Search`}>
		                      	<IconButton
		                      		size={'small'}
						            onClick={(e) => {e.stopPropagation();e.preventDefault();this._deleteTag(searchTags[1])}}
						            className="material-table__toolbar-button"
						          >
		
								  <RemoveIcon size={14}  />
							  	</IconButton>
						  	</Tooltip>
						</span>
						}
						{tagLen > 2 &&
							<span className={`c_input-tag`}>
								+{tagLen-2} More
								<Tooltip title={`Expand to See All`}>
			                      	<IconButton
			                      		size={'small'}
							            onClick={(e) => {}}
							            className="material-table__toolbar-button"
							            id={'expand-search'}
							          >
			
									  <DropdownIcon size={14}  />
								  	</IconButton>
						  	</Tooltip>
							</span>
						}
					</div>				
				)
			}
			
			
		}
		
		
		return (
			<div
				className="c_input-tag-container"
				id="tag-container"
			   >
				{searchTags &&
				tagLen > 0 &&
				searchTags.map((tag, i) => {
						if (tag.name) {
							return (
								<span
									key={`${tag.name}-${i}`}
									className={`c_input-tag ${tag.type=='area' ? "c_is_area" : ""}`}
									onClick={(e) => {e.stopPropagation();e.preventDefault();this._clickTag(searchTags[1])}}
								>
									{_capitalizeText(tag.name)}
									
									<Tooltip title={`Remove ${_capitalizeText(tag.name)} From Search`}>
				                      	<IconButton
				                      		size={'small'}
								            onClick={(e) => {e.stopPropagation();e.preventDefault();this._deleteTag(tag)}}
								            className="material-table__toolbar-button c_expanded-tag"
								          >
				
										  <RemoveIcon size={14}  />
									  	</IconButton>
								  	</Tooltip>
									
								</span>
							);
						}
					})
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	console.log(state)
  return { searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchTags);
