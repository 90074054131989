import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import HomeVariantOutlineIcon from 'mdi-react/HomeVariantOutlineIcon';
import GaugeIcon from 'mdi-react/GaugeIcon';
import TagOutlineIcon from 'mdi-react/TagOutlineIcon';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../../shared/helpers/utils";

const Inventory = (props) => {
		const { buildingStats } = props;

			return (
				 <Col md={12} xl={props.sold || props.active ? 2 : 3} lg={6} xs={12}>
				    <Card>
				      <CardBody className="dashboard__booking-card card__link">
				        <div className="dashboard__booking-total-container">
						 {props.sold ?
							<h5 className="dashboard__booking-total-title">
								{buildingStats.inventory_unsold && buildingStats.inventory_unsold > 0 ? `${_formatNumber(buildingStats.inventory_total - buildingStats.inventory_unsold)}`
									: '--'}
							  </h5>
						  : props.active ?
							  <h5 className="dashboard__booking-total-title">
								  {buildingStats.inventory_active && buildingStats.inventory_active > 0 ? `${_formatNumber(buildingStats.inventory_active)}`
									  : '0'}
								</h5>
						  : props.unsold ?
								<h5 className="dashboard__booking-total-title">
									{buildingStats.inventory_unsold && buildingStats.inventory_unsold > 0 ? `${_formatNumber(buildingStats.inventory_unsold)}`
										: '0'}
								  </h5>
						  : props.percent_sold	?
						    <h5 className="dashboard__booking-total-title">
						 	{buildingStats.inventory_unsold && buildingStats.inventory_unsold > 0 ? `${Math.round(((buildingStats.inventory_total - buildingStats.inventory_unsold)/buildingStats.inventory_total) * 10000)/100} %`
							 : '--'}
							</h5>
				          : 
						  	<h5 className="dashboard__booking-total-title">
				            	{buildingStats.inventory_total && buildingStats.inventory_total > 0 ? _formatNumber(buildingStats.inventory_total)
								: '--'}
				          	</h5>
						  }
				          {props.sold ? 
							  <HomeVariantOutlineIcon className="dashboard__trend-icon" />
						    : props.active ?
							  <TagOutlineIcon className="dashboard__trend-icon" />
							: props.percent_sold	?
							  <GaugeIcon className="dashboard__trend-icon" />
							: 
							  <HomeCityOutlineIcon className="dashboard__trend-icon" />
						  }
				        </div>
				        <h5 className="dashboard__booking-total-description">{props.sold  ? "Sold Units" : props.unsold ? "Available Units" : props.active ? "Listed Units" : props.percent_sold ? "Percent Sold" : "Total Units"} </h5>
				      </CardBody>
				    </Card>
				  </Col>
			)

	
	 	
		
	
};

export default Inventory;
