import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/HomeMinusIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (buildingRes) => {	
	
	let totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	
	if(buildingRes.inventory_bulk_sold && buildingRes.inventory_bulk_sold > 0){
		totalSold+= buildingRes.inventory_bulk_sold;
	}
	
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalSold, fill: getDefaultChartColors[2] },
  				{ value: totalAvail, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, buildingRes, data } ) => {
	let totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	
	if(buildingRes.inventory_bulk_sold && buildingRes.inventory_bulk_sold > 0){
		totalSold+= buildingRes.inventory_bulk_sold;
	}
	
	const percentSold = Math.round((totalSold / buildingRes.inventory_total) * 100 );
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10 }}  >
	    	<div className="card__title">
	          <div className="card__title">
		          <h5 className="bold-text card__title-center">{`% Unit Sold`}
				  	<ToolTip 
			        		title={`Percent sold based on total number of sellable units`} 
			        	/>	          
		          </h5>
		        </div>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={data} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="dashboard__health-chart-info" style={{marginTop:-15}}>
	            <TagOutlineIcon style={{ fill: getDefaultChartColors[2] }} />
	            <p className="dashboard__health-chart-number">{percentSold > 0 ? Math.round(percentSold) : '--'}%</p>
	            <p className="dashboard__health-chart-units">sold</p>
	             
	          </div>
	          <p className="dashboard__goal">({totalSold >= 0 ? totalSold : '--'} of {buildingRes.inventory_total > 0 ? buildingRes.inventory_total : '--'} units)</p>
	        </div>
		</CardBody>);
};

const PercentSold = (props) => {
	const { buildingRes, col } = props;
	const data = calculateBarData(buildingRes);
	
	
	return (
		  <Col md={12} xl={col ? col : 2} lg={6} sm={12} xs={12}>		  	
		    <Card>
		    {props.location ? 
			    <Link to={`${props.location.pathname.replace('/tab/overview', '')}/tab/units?offering_status=sold`}>
				    <CardPercent data={data} buildingRes={buildingRes} link />
				</Link>
			:
				<CardPercent data={data} buildingRes={buildingRes} />
			}
			</Card>
		  </Col>
    )
};



export default PercentSold;
