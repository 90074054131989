import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Col, Progress, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import MatTableFiltersSelect from './MatTableFiltersSelect';
import MatAppBar from './MatAppBar';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import PreviewIcon from 'mdi-react/EyeOutlineIcon';

import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	SalesProgress,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	_objectEqual
} from "../../../shared/helpers/utils";

import { 
	_buildingAdminFiltersRental	
} from "../../../shared/helpers/filters";

import queryString from "query-string";


let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}
const CurrentStatus = ({ item }) => {
 
  if(['active', 'sold out'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="success">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['coming soon'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="available">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['offering plan required'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="buyerslist">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  return (
    <div>
      <Badge>{_capitalizeText(item.offering_verified_status)}</Badge>
    </div>
  );
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(buildingRes.inventory_pct_unsold === 100 
		&& moment(buildingRes.offering_accepted_date).format('YYYY') <= 2015
		&& moment(buildingRes.sales_start_date).diff(moment(buildingRes.offering_accepted_date), 'days') <= 1){
		return "--";
		
	}
	if(buildingRes.offering_verified_status === 'abandoned'){
		return "--";
	}
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}



const buildingUrl = process.env.API_ROOT + '/query/';
const lsPre = "RDB_";
const subFilterKey = 'subFilters';

class MatTable extends PureComponent {
  state = {
    order: 'desc',
    orderBy: 'active_listings_rent',
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	// filterFetchInProgress: false,
	filterStage: "all_projects",
	futureInventoryData: false,
	// headerHeight: 'auto',
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _buildingAdminFiltersRental, subFilterKey, lsPre),
	tableData: false,
	totalUnsoldUnits: false,
	searchText: syncURLSearch(this.props, 'searchText'),
  };
  
  componentDidMount() {
  	this._getInventoryStats();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	    let subFilterChanged =
			_buildingAdminFiltersRental.filter(item => {
				return (
					this.state.subFilters[item.search_key] !==
					prevState.subFilters[item.search_key]
				);
			}).length > 0;
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			subFilterChanged
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)	
			  this._getInventoryBuildings(this.props, resetPage,  {
				callback: function(response) {
					let effective_year = moment().year() - 5;
					response.docs = response.docs.map((doc, i) => {
						doc["is_completed"] =
							moment(doc["building_effective_start"]).year() >=
							effective_year;
						doc["has_sales_started"] =
							moment(doc["sales_start_date"]).year() > 1969;
						return doc;
					});
		
					this._formatStats(response, resetPage);
				}.bind(this),
				url: buildingUrl
			});
		}

	  
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building, 'building_slugs');
	 
	 window.open(`https://marketproof.com${buildingURL.replace('buildings', 'building')}?tab=overview`)
	 //this.props.history.push(buildingURL);
  };
  
  handleBuildingPreviewClick = (event, building) => {
	 event.preventDefault();
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building);
	 
	 window.open(buildingURL);
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  _getInventoryBuildings = async (query, resetPage, obj) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		const { user } = this.props;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var q = "";
		if (this.state.filterBorough !== "all_boroughs") {
			q += `(borough:"${this.state.filterBorough
				.replace("_", " ")
				.toLowerCase()}")`;
		}
		let isActive = false;
		if (this.state.filterStage !== "all_projects") {
			q += q.length > 0 ? " AND " : "";
			if (this.state.filterStage === "completed_only") {
				q += "(building_effective_start:[NOW-5YEARS TO NOW])";
			} else if (this.state.filterStage === "under_construction_only") {
				q +=
					"(sales_start_date:[NOW-5YEARS TO NOW] AND -building_effective_start:[NOW-5YEARS TO NOW])";
			}
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qVal = _buildingAdminFiltersRental.filter(filter => filter.search_key === key)[0].values.filter(value => value.filter === subFilterVal)[0].search_value;
					q += q.length > 0 ? " AND " : "";
					
					if(key === 'offering_verified_status' && ['active', '("offering plan required")'].indexOf(qVal)>=0){
						isActive = true;
					}
					return q += `(${key}:${qVal})`;
				}
			});
		}
		
		setURLFromState(this.props, this.state);
		var fq = [
			"deleted:false",
			//"offering_verified_status:* AND -offering_verified_status:\"\" AND -offering_verified_status:\"excluded\"",
			"ownership_type:(*rental*) OR ownership_occupancy_sub_types:*rental*",
			`year_built:[${moment().subtract(10, 'years').format('YYYY')} TO *] OR year_converted:[${moment().subtract(10, 'years').format('YYYY')} TO *]`
			//"ownership_type:*condo*",
			//"total_units_residential:[10 TO *]",
// 			"sales_start_date:[NOW-5YEARS TO NOW]"
		];
		
		
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			q += q.length > 0 ? " AND " : "";
			q += `(name:(*${text}* OR *${textCaps}*) OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		q = q.length > 0 ? q : "*:*";
		
/*
		if(isActive){			
			var index = fq.indexOf("offering_accepted_date:[NOW-3YEARS TO NOW]");
			if (index !== -1) this.fq.splice(index, 1);
		}else{
			fq.push("offering_accepted_date:[NOW-3YEARS TO NOW]");
		}
*/
		//fq.push("offering_accepted_date:[NOW-10YEARS TO NOW] OR offering_verified_status:(active \"coming soon\" \"offering plan required\")");
		
		if(!(user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0)){
			fq.push("total_units:[1 TO *]");
			
		}
		
		
		
		let sort = "total_units desc";
		if(this.state.orderBy && this.state.orderBy.length > 0){
			sort = `${this.state.orderBy} ${this.state.order}`;
		}

		if (query.fq && query.fq.length > 0) {
			fq = fq.concat(query.fq);
		}

		let axiosData = {
			url: obj.url + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: sort,
				fl: buildingFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"json.facet": {
					total_units: "sum(total_units)",
					
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof obj.callback === "function") {
					let formattedRes = obj.callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};

	_getInventoryStats = (newStart = false) => {
		console.log("_getInventoryStats this.props ", this.props);
		console.log("_getInventoryStats called Inventory state", this.state);

		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({
				buildingsFetchInProgress: true
			}));
		}

		this._getInventoryBuildings(this.props, true, {
			callback: function(response) {
				let effective_year = moment().year() - 5;
				response.docs = response.docs.map((doc, i) => {
					doc["is_completed"] =
						moment(doc["building_effective_start"]).year() >= effective_year;
					doc["has_sales_started"] =
						moment(doc["sales_start_date"]).year() > 1969;
					return doc;
				});
				this._formatStats(response, false);
			}.bind(this),
			url: buildingUrl
		});
	};

	_formatStats = (response, resetPage, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		// number to gate info at
		let showUnsigned = this.props.isDesktop ? this.state.showCounts.desktop : this.props.isTablet ? this.state.showCounts.tablet : this.state.showCounts.mobile;
		console.log('showUnsigned', showUnsigned)
		let formattedData = response
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
		let effective_year = moment().year() - 5;
		let hide_fields = [
			"name",
			"full_street_address",
			"display_full_street_address"
		];

		let docs = formattedData.map((doc, i) => {
			let item = Object.assign({}, doc);
			
			// adds "Sign up ..." as field value for the fields in hide_fields
			if (!signedIn && i >= showUnsigned) {
				Object.keys(doc).map(function(key, index) {
					if (hide_fields.indexOf(key) >= 0) {
						return item[key] = "Sign up to see";
					} else {
						return item[key] = doc[key];
					}
				});
			
			// replace hidden fields with originalData fields bc shouldn't be hidden 
			} else if(!signedIn && i < showUnsigned && item[hide_fields[0]].includes('Sign up to see')) {
				const originalData = this.state.originalData.docs
				Object.keys(doc).map(function(key, index) {
					if (hide_fields.indexOf(key) >= 0) {
						return item[key] = originalData[i][key];
					} else {
						return item[key] = doc[key];
					}
				});
			}
			
			// no need to do again for hidden field rerenders
			if(response) {
				item["sold"] = item["inventory_sold"];
				item["sold"] += item["inventory_not_yet_recorded"];
				item["Sold"] += item["inventory_sold"];
				item["Sold"] += item["inventory_resale"];
				item["pct_sold"] =
					100 -
					Math.round((item.inventory_unsold / item.inventory_total) * 100);
			}

			return item;
		});

		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			tableData:  docs,
			reactTableFiltered:  docs,
			originalData: redoHiddenFields ? prevState.originalData : response,
			// filterFetchInProgress: false,
			totalCount: response.numFound,
			start: resetPage ? 0 : this.state.start,
			page: resetPage ? 0 : this.state.page,
			stats: redoHiddenFields 
				? prevState.stats 
				: Object.assign(prevState.stats, {
					total_buildings: response.facets.count,
					total_units: response.facets.total_unsold
			})
		}));
	};
	
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	_filterResponseTableData = eValue => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter(item => {
						// console.log("filterData item", item)
						const inputVal = eValue.toLowerCase();
						let nameVal, adrVal, hoodVal;
						if (item.name) {
							// console.log('item has name')
							nameVal = item.name.toLowerCase();
						}

						if (
							(item.display_full_street_address &&
								_shouldShow(item.display_full_street_address)) ||
							(item.full_street_address &&
								_shouldShow(item.full_street_address))
						) {
							const field = item.display_full_street_address
								? "display_full_street_address"
								: "full_street_address";
							// console.log('item has adr', field)

							adrVal = item[field].toLowerCase();
						}

						if (item.neighborhood && _shouldShow(item.neighborhood)) {
							const hood =
								Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
									? item.neighborhood[0]
									: item.neighborhood;
							hoodVal = hood.toLowerCase();
						}

						// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
						// searching name and ONE adr field - could update to search both display and full and all alt adrs
						return (
							(nameVal && nameVal.indexOf(inputVal) >= 0) ||
							(adrVal && adrVal.indexOf(inputVal) >= 0) ||
							(hoodVal && hoodVal.indexOf(inputVal) >= 0)
						);
				  })
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState(prevState => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
		    //tableData: filteredData,
		    //originalData: response,
		    //filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, {});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, subFilterKey);
	}
	_resetActive = () => {
		
		let isDefault = this.state.subFilters.length===0
	    return !isDefault;
		
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
     const paginationCount = totalCount;
    if(!tableData){
	    return null;
    }
    const table = reactTableFiltered;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
           {/*<div className="card__title">
              <h5 className="bold-text">Select and Analyze</h5>
            </div>*/}
            <MatAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				resetSubFilter={this._resetSubFilter}
            	setSubFilter={this._setSubFilter}
            	_filters={_buildingAdminFiltersRental}
            	resetActive={this._resetActive()}           	
             />
            
            <div className="material-table__header-info"><span className="bold-text">{tableCount > 0 ? _formatNumber(tableCount) : '--'}</span>{` `}Buildings</div>
            <div className="material-table__wrap">
              <Table className="material-table">
                <MatTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={tableData.length}
                />
                
                <TableBody>
                  {table
                      .map((d) => {
                      const isSelected = this.isSelected(d.id);
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleBuildingClick(event, d)}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={isSelected}
                        >
                          <TableCell className="material-table__cell" padding="checkbox">
                            {/*<Tooltip title="Add to Cohort">
                            	<Checkbox checked={isSelected} className="material-table__checkbox" />
                            </Tooltip>*/}
                            {user.role && Array.isArray(user.role) 
	                            && user.role.indexOf('admin')>=0 
	                            && d.offering_file_number && d.offering_file_number &&
						  		<Fragment>
						  			<IconButton onClick={(e) => { e.preventDefault();e.stopPropagation(); window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`)}} >
						  				<OpenIcon color={'#BFC4D1'} />
						  			</IconButton>
						  		</Fragment>
					  		}
                            
                          </TableCell>
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                            style={{padding:10}}
                          >
                            <span>{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
                            	{(d.ownership_type.indexOf('condo')>=0 || (d.ownership_occupancy_sub_types && d.ownership_occupancy_sub_types.indexOf('condo')>=0)) &&
				                    <span className="unit-type_badge">Hybrid - Condo</span>
			                	 }
			                	 {
				                	 !(d.ownership_type.indexOf('condo')>=0 || (d.ownership_occupancy_sub_types && d.ownership_occupancy_sub_types.indexOf('condo')>=0)) &&
				                	 (d.offering_submitted_date && (parseInt(moment(d.offering_submitted_date).format('YYYY')) > 1970)) &&
				                    <span className="unit-type_badge">OP Filed</span>
			                	 }
                            	{user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
		                            <Fragment>
	                            	
							  		{d.offering_plan_documents && d.offering_plan_documents.length > 0 &&
								  		<Fragment>
								  			<DocIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(d.offering_plan_documents[0]); }} />
								  		</Fragment>
							  		}
							  		<PreviewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={e => this.handleBuildingPreviewClick(e, d)} />
							  		</Fragment>
							  	}
                            </span>
                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
                          </TableCell>
                            <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	{d.active_listings_rent > 0 ? 
	                          	<Badge color="active" style={{ width:80 }}><span className="bold-text">{_formatNumber(d.active_listings_rent)}</span> Units</Badge> 
	                          	: <Badge color="shadow" style={{ width:80 }}>--</Badge>
	                        }
	                       
                          
                          </TableCell>  
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          
                          <span className="light-text small-text">{d.year_built > 0 ? d.year_built : '--'}</span>
                          </TableCell>
                          
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          
                          <span className="light-text small-text">{d.total_floors>0 ? d.total_floors : '--'}</span>
                          </TableCell>
                                                
                          <TableCell
                            className="material-table__cell light-text small-text"
                            size="small" 
                            align=""
                          >
                          <span className="light-text small-text">
						   {d.total_units_rental > 0 ?  _formatNumber(d.total_units_rental)
							   : d.residential_units > 0 ? _formatNumber(d.residential_units)
	                          	: d.total_units > 0 ? _formatNumber(d.total_units) 
	                          	: '--'  
						   }
                          </span>
                          {/*<br/>
                          <span className="light-text small-text" style={{color:"#858AA1"}}>
                          {d.total_units_rental > 0 && d.total_units > 0 && d.total_units !== d.total_units_rental && `${_formatNumber(d.total_units)} Total `}
                          </span>*/}
                          
                          
                          </TableCell>
                         
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            {paginationCount ? <TablePagination
              component="div"
              className="material-table__pagination"
              count={paginationCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            /> : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user
}))(withRouter(MatTable));