export const _hasPermission = (user, features) => {
  if(!user) return false;
  const _roleMap = {
	   "all":[
	      "inventory-search",
	      "inventory-analysis",
	      "building-search",
	      "market-activity-chart",
		  "ownership",
		  "originator",
		  "buyer-attorney",
		   "document-search"
		  
	   ],
	   "basic":[
			 "inventory-search",
			 "inventory-analysis",
			 "building-search",
			 "market-activity-chart",
			 "sponsor-buildings",
			  "document-search"
		  ],
	   "advanced":[
	      "inventory-search",
	      "inventory-analysis",
	      "building-search",
	      "market-activity-chart",
	      "market-activity-detail",
	      "resale-buildings",
		  "past-sales",	 
		  "discount-analysis",
		  "price-trends",
		  "stacked-report",
		  "stacked-report",
		   "finance-tab",
		  "building-management",
		  "collections",
		  "ownership",
		  "originator",
		  "buyer-attorney",
		   "document-search"
	   ],
	   "resale":[
			 "inventory-search",
			 "inventory-analysis",
			 "building-search",
			 "market-activity-chart",
			 "market-activity-detail",
			 "resale-buildings",
			 "past-sales",	 
			 "discount-analysis",
			 "price-trends",
			 "stacked-report",
			 "finance-tab",
			 "building-management",
			 "collections",
			 "ownership",
			 "originator",
			 "buyer-attorney",
			 "document-search"
		  ],
	   "complete":[
	      "inventory-search",
			"inventory-analysis",
			"building-search",
			"rental-building-search",
			"market-activity-chart",
			"market-activity-detail",
			"discount-analysis",
			"price-trends",
			"sales-agents",
			"architects",
			"developers",
			"search-firms",
			"resale-buildings",
			"pipeline",
			"pipeline-buildings",
			"building-management",
			"ownership",
			"past-sales",
			"stacked-report",
			"download-image",
			"ag-link",
			"sales-velocity",
			"collections",
			"sponsor-buildings",
			"originator",
			"buyer-attorney",
			 "document-search"
	   ],
	    "complete-199":[
	      "inventory-search",
	      "inventory-analysis",
	      "building-search",
	      "rental-building-search",
	      "market-activity-chart",
	      "market-activity-detail",
	      "discount-analysis",
	      "price-trends",
	      "sales-agents",
	      "architects",
	      "developers",
	      "search-firms",
	      "resale-buildings",
	      "pipeline",
	      "pipeline-buildings",
	      "building-management",
	      "ownership",
	      "past-sales",
	      "stacked-report",
	      "download-image",
	      "ag-link",
	      "sales-velocity",
		  "collections",
		  "sponsor-buildings",
		  "originator",
		  "buyer-attorney",
		   "document-search"
	   ],
	   "enterprise":[
	      "inventory-search",
	      "inventory-analysis",
	      "building-search",
	      "rental-building-search",
	      "market-activity-chart",
	      "market-activity-detail",
	      "discount-analysis",
	      "price-trends",
	      "sales-agents",
	      "architects",
	      "developers",
	      "lenders",
	      "pipeline",
	      "search-firms",
	      "finance-tab",
	      "offering-tab",
	      "resale-buildings",
	      "pipeline-buildings",
	      "building-management",
	      "ownership",
	      "past-sales",
	      "stacked-report",
	      "download-image",
	      "ag-link",
	      "firm-contracts",
	      "sales-velocity",
		  "collections",
		  "sponsor-buildings",
		  "originator",
		  "buyer-attorney",
		   "document-search"
	   ],
	   "admin":[
	      "inventory-search",
	      "inventory-analysis",
	      "building-search",
	      "rental-building-search",
	      "market-activity-chart",
	      "market-activity-detail",
	      "discount-analysis",
	      "price-trends",
	      "sales-agents",
	      "architects",
	      "developers",
	      "lenders",
	      "pipeline",
	      "coming-soon-buildings",
	      "admin-filters",
	      "search-firms",
	      "market-activity-error-checking",
	      "admin-tools",
	      "finance-tab",
	      "offering-tab",
	      "resale-buildings",
	      "pipeline-buildings",
	      "building-management",
	      "ownership",
	      "past-sales",
	      "stacked-report",
	      "download-image",
	      "change-branding",
	      "ag-link",
	      "firm-contracts",
	      "sales-velocity",
		  "collections",
		  "sponsor-buildings",
		  "originator",
		  "community-collections",
		  "buyer-attorney",
		   "document-search"
	   ],
	   "data-export":[
	      "data-export"
	   ]}
  
  features = !Array.isArray(features) ? [features] : features;
  
  const userRoles = !Array.isArray(user.role) || user.role.length<=0 ? ["basic"] : user.role.slice(0);
  
	userRoles.push('all');
 
  
  if(!_isNewUser(user)){
	  //userRoles.push('advanced');
  }
  //console.log(userRoles)
  let hasPermission = false;
 
  if(userRoles && Array.isArray(userRoles)){ 	  
	  userRoles.map(role => {
		  if(_roleMap[role] && Array.isArray(_roleMap[role])){
			  _roleMap[role].map(feature => {
				  if(features.indexOf(feature)>=0){
					  hasPermission = true;
				  }				  
			  });			  
		  }		  
	  });		 		 
  }
  
  return hasPermission;
};

export const _isNewUser = (user) => {
		
	if(user && user.trialStart && user.trialStart > 1607558400){
		return true;
	}
	return false;	
}


export const _shareThemes = {
	"douglas-elliman" : {
		"display_name" : "Douglas Elliman",
		"header_link" : "https://www.elliman.com/"
	},
	"eklund-gomes" : {
		"display_name" : "Eklund | Gomes",
		"header_link" : "https://theeklundgomesteam.elliman.com"
	},
	"nestseekers" : {
		"display_name" : "NestSeekers",
		"header_link" : "https://www.nestseekers.com/"
	},
	"serhant" : {
		"display_name" : "SERHANT",
		"header_link" : "https://www.serhant.com/"
	},
	"bhsdm" : {
		"display_name" : "BHSDM",
		"header_link" : "https://www.bhsusa.com/about-development-marketing"
	}
}

export const _canSelfServe = (user) => {	
	if(user.role && Array.isArray(user.role) && user.role.filter(role => ['admin', 'enterprise'].indexOf(role) >=0).length > 0){
		return false;
	}	
	return true;
	
}