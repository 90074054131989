/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import {
  AreaChart, Tooltip, Area, ResponsiveContainer, XAxis,
} from 'recharts';
import TrendingUpIcon from 'mdi-react/SaleIcon';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment";

import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	mpndCohortQuery,
	resaleCohortQuery
} from "../../../../../shared/helpers/utils";


const buildingUrl = process.env.API_ROOT + '/query/';

const isDST = moment().isDST();
const defaultQ = "offering_verified_status:(active \"coming soon\" \"offering plan required\" \"condo declaration vetted\") AND deleted:false";

const startDate = moment().set({hour:0,minute:0,second:0,millisecond:0}).add(-1, 'year').startOf('year').toDate().toISOString();

const endDate = moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString();


const CustomLabelList = (props) => {
  console.log('CustomLabelList : ', props); // It is Top Result. not have x, y
  const {x, y, stroke, value} = props;

  return (
    <text x={x} dx={5} y={65} fill={stroke} fontSize={10} textAnchor="middle">{value[0].toUpperCase()}</text>
  )
};

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="dashboard__total-tooltip" style={{background:"#171F26",padding:"5px 10px",borderRadius:3}}>
        <p className="label small-text" style={{color:"#FFF",fontSize:"11px"}}>{`${_capitalizeText(payload[0].payload.time)}:`}
        <span style={{fontWeight:500}}> {`${(payload[0].value).toFixed(2)}%`}</span></p>
      </div>
    );
  }

  return null;
};

const getIntervalName = (date) => {
	  
	  const m = moment(date).format('M');
	  const y = moment(date).format('YYYY');
	  const tMap={
		  1 : 'Q1',
		  2 : 'Q1',
		  3 : 'Q1',
		  4 : 'Q2',
		  5 : 'Q2',
		  6 : 'Q2',
		  7 : 'Q3',
		  8 : 'Q3',
		  9 : 'Q3',
		  10 : 'Q4',
		  11 : 'Q4',
		  12 : 'Q4'
	  }
	  return tMap[m] + ', ' + y;
  }
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
  })),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

class DiscountAnalysis extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      data : false
    };
  }
   componentDidMount() {
  	 //this._getBuildings();
  	 
  	 this._getEvents(true);
  	 
  	
  }


  handleClick = (item) => {
	const { data } = this.state;
    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };
  
  _getEvents = async (resetPage) => {
		

		var fq = [
			`close_date_formatted:[${startDate} TO ${endDate}]`, 
		  	"sponsored_sale:true", 
		  	"status:sold", 
		  	"transaction_source:(*ACRIS* *acris*)", 
		  	"price:[300000 TO 100000000]", 
		  	"offering_discount_without_tax:[-90 TO 90]",
		  	"discount_without_tax:[-90 TO 90]",
		  	"price_per_sqft:[300 TO 20000]",
		  	"parcels_condocoop_count:1", 
		  	"property_type:sc", 
		  	"estimated_transfer_tax:[1000 TO *]",
 			`{!join from=key fromIndex=we3-buildings to=building}(${mpndCohortQuery})`
		
			
		];
		let axiosData = {
			url: buildingUrl + "we3-transactions?cache=60",
			method: "post",
			query: {
				q: "discount:[-90 TO 30]",
				fq: fq,
				wt: "json",
				rows: 0,
				"json.facet": {
					"by_time": {
						"range": {
							"field": "recorded_datetime",
							"start": startDate,
							"end": endDate,
							"gap": "+3MONTH",
							"facet": {
								"avg_offering_discount_without_tax": "avg(discount_without_tax)",
								"med_offering_discount_without_tax": "percentile(discount_without_tax, 50)",
							}
							
						}
					}
				}
			}
		};
		
		let chartData = [];

		let res = await _axiosCall(axiosData)
			.then(res => {
				
				return res;

			})
			.catch(error => {
				console.log("error: " + error);
			});
			
		if(res && res.facets){
			if(res.facets.by_time){
							
				chartData = res.facets.by_time.buckets.filter(b => b.count > 0).map(bucket => {
					const key = getIntervalName(bucket.val);
					return { time: key, count: bucket.count, avg_offering_discount_without_tax : -1 * bucket.avg_offering_discount_without_tax, med_offering_discount_without_tax : -1 * bucket.med_offering_discount_without_tax}
				})
					
			}
			
			this.setState({
				data: chartData
			})
		}
			
		// console.log(formattedData)
		//return formattedData;
	};

  render() {
    const { activeIndex, data } = this.state;

    return (
      <Col md={12} xl={4} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget card__link">
          <Link to={`/analysis/discount`}>
            <div className="card__title">
              <h5 className="bold-text">{'Discount Analysis'}</h5>
            </div>
            {data &&
	        <Fragment>
	            <div className="dashboard__total">
	              
	              {/*<div className="dashboard__total-stat">
	              	<span style={{ color:"#9600BF", fontWeight:500 }}>{_formatNumber(data[activeIndex].count)}</span>
	              	<div className="small-text light-text">(Events - MTD)</div>
	              </div>*/}
	              <div className="dashboard__chart-container" style={{maxWidth:"none"}}>
	                <ResponsiveContainer height={70}>
		                  <AreaChart
		                    data={data}
		                    margin={{
		                      top: 0, left: 0, bottom: 0, right: 20
		                    }}
		                  >
		                    <XAxis
		                      hide
		                    />
		                    <Tooltip content={<CustomTooltip />} />
		                    <Area
		                      name="time"
		                      type="monotone"
		                      dataKey="avg_offering_discount_without_tax"
		                      fill="#BF96D9"
		                      stroke="#9600BF"
		                      fillOpacity={0.2}
		                    />
		                  </AreaChart>
		                </ResponsiveContainer>
	              </div>  
	              <TrendingUpIcon className="dashboard__trend-icon" />        
	            </div>
	            <p className="progress__label"><span style={{ color:"#9600BF", fontWeight:500 }}>{(data[data.length-1].avg_offering_discount_without_tax).toFixed(2)}%</span> <span className="small-text light-text">Average Discount This Quarter</span></p> 
            </Fragment>
            }
           </Link>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default DiscountAnalysis;
