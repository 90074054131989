/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const data = [
  { name: 'Page A', pv: 60 },
  { name: 'Page B', pv: 98 },
  { name: 'Page C', pv: 16 },
  { name: 'Page D', pv: 80 },
  { name: 'Page E', pv: 50 },
  { name: 'Page F', pv: 40 },
  { name: 'Page G', pv: 50 },
  { name: 'Page H', pv: 20 },
  { name: 'Page I', pv: 10 },
  { name: 'Page J', pv: 10 },
];

class OrdersToday extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeIndex: 0,
    };
  }

  handleClick = (item) => {
    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { activeIndex } = this.state;
    const activeItem = data[activeIndex];
    const { t } = this.props;

    return (
      <Col md={12} xl={3} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget">
          <Link to={`/inventory`}>
            <div className="card__title">
              <h5 className="bold-text">{`New Units`} <span className="small-text light-text">(30 Days)</span></h5>
            </div>
          </Link>
            <div className="dashboard__total">
             {/* <TrendingDownIcon className="dashboard__trend-icon" /> */}
              <p className="dashboard__total-stat">
                {(activeItem.pv)} 
                <div className="small-text light-text">Units</div>
              </p>
              
              <div className="dashboard__chart-container">
                <ResponsiveContainer height={140}>
                  <BarChart data={data}>
                    <Bar dataKey="pv" onClick={this.handleClick}>
                      {
                        data.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? '#780F9E' : '#D6BAE8'}
                            key={`cell-${index}`}
                          />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withTranslation('common')(OrdersToday);
