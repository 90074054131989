import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import moment from 'moment';


import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import Profile from './../Profile';

import AddToBuyersListButton from '../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../shared/components/buttons/ShareButton';

import ProductCard from './../Profile/components/ProfileCard';
import ShowMap from './../../../../shared/components/map';

import BuildingProductCard from './../../components/Profile/components/ProfileCard';
import BuildingAmenities from './../../components/Profile/components/BuildingAmenities';

import ShareTopBar from './../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../shared/components/ShareContact'
import ShareFooter from './../../../../shared/components/ShareFooter'

import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';

import ExportButton from './../../../SharePage/ExportButton';
import HouseProfile from './../HouseProfile';


import { withOktaAuth } from '@okta/okta-react';

import { Publisher } from './../../../../shared/pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	_privateKey,
	_validUnitSources,
	propertyExportFL,
	_isHouse,
	propertyFL
} from '../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../shared/components/auth/sso/ssoutils';
const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;


class SingleBuildingShare extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
      statsRes: null,
      fetchInProgress: false,
      buildingRes: false,
      userRes:undefined
    };
    this.buildingSlug = false;
	this.urlState = {};
  }
  
  componentDidMount() {
	  
	const { sharePageData } = this.props;
	
	if(sharePageData){
		this.buildingSlug = sharePageData.building_key;
		
		this.urlState = sharePageData.custom_data ? sharePageData.custom_data : {};
		
		
	}else{
		
		const urlLink = this.props.match.params && this.props.match.params.encrypted_key ? CryptoJS.AES.decrypt(atob(this.props.match.params.encrypted_key), _privateKey).toString(CryptoJS.enc.Utf8) : false;
		
		const queryParts = urlLink.split('?');
		const pathParts = queryParts[0].split('/');
		
		this.buildingSlug = pathParts[0] ? pathParts[0] : false;
		this.urlState = queryParts[1] ? queryString.parse(queryParts[1]) : false;
		
	   console.log(this.buildingSlug, this.urlState);
	   
		
	}
	
	this._getBuildingData();
	//if(this.urlState.ap){
	this._getUserData(this.urlState.ap);
	//}
	Publisher.publish(`track.shareView.building`, {building: this.buildingSlug, label: this.buildingSlug});
	
	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {	
	 const { sharePageData } = this.props;
	 if(!prevState.buildingRes && this.state.buildingRes){
		Publisher.publish(`track-mp.shareView.building`, {building: this.state.buildingRes, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true }); 
	 }	
	 
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  
  _getUserData = (key) => {
     // console.log('_getBuildingData called', this.state)
      const { sharePageData, user } = this.props;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  
	  if(!(sharePageData && sharePageData.user_key) && user){
		  this.setState({
				userRes: user,
				fetchInProgress: false
		  })
		  
		  return false;
	  }
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${sharePageData && sharePageData.user_key ? sharePageData.user_key : key}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
		  if(res && res.errorCode && res.errorCode == "E0000007"){
				_getUser({id:sharePageData && sharePageData.user_key ? sharePageData.user_key : key}).then(ssoUser  => {
					console.log(ssoUser)
					if(ssoUser){
						this.setState({
							userRes:ssoUser,
							fetchInProgress: false
						})	
					}
					
				})
		 }else{
	      		this.setState({
		      		userRes:res.profile,
		      		fetchInProgress: false
	      		})
	  	  }

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

  
  _getBuildingData = () => {
     // console.log('_getBuildingData called', this.state)
      const { sharePageData } = this.props;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  	  
    
/*
	  let buildingData = {
			url: apiURL + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: 'key:*-'+this.buildingSlug,
				fq: 'deleted:false',
				fl: buildingFL,
				wt: "json",
				rows: 1
			}
	  };
*/
	  
	  let buildingData = {
			url: process.env.API_ROOT + `/buildings/${this.buildingSlug}?type=minimum`,
			method: "get"
	  };
	  
	  _axiosCall(buildingData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    if(res && res && res.building && res.building[0]) {
	      this.setState({
	      	buildingRes: res.building[0],
	      	fetchInProgress: false
	      });
	      
	      
	      
	      this._getBuildingOPData(res.building[0]);

	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

   _getBuildingOPData = async (buildingRes) => {
		   const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		   const addressQ = buildingRes.alternate_addresses.join('" OR "');
		   let isHouse = _isHouse(buildingRes);
		   let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}"))`
		   
		   let statsQ = '';
		   if(!isHouse){
		   		if(['active'].indexOf(buildingRes.offering_verified_status)>=0){
			   		statsQ = `((offering_verified_status:active AND source_organizations:(CDEC NYAG ADEC) AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"") OR verification_source:(user-verified-valid))`
						q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}))`;		
		   		}else if(buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970){
			   		let unitSource = "source_organizations:(CDEC ADEC)";
				 		if(buildingRes.cdec_units_properties < (buildingRes.inventory_total * .8)){
							unitSource = `(source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}))`
				 		}
						statsQ = `(${unitSource} AND property_type:(*condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial* AND -property_type:*multi*)`;
						q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;
		   		}else if(['coming soon'].indexOf(buildingRes.offering_verified_status)>=0){
			   		statsQ = `(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"")`;
						q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
		   		}else{
			   		q += ` AND (source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
		   		}
	   	   }
		   const isResale = ['resale'].indexOf(buildingRes.building_status)>=0;
		   
		   if(statsQ.length>0){
			   statsQ = 'AND ' + statsQ;
		   }
		   
		   this.q = q;
		   const priceField = isResale ? "last_sold_price" : "last_listed_sale_price_all";
		   let fq = ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""'];
		   let fl = [...propertyExportFL, ...["floor", "line", "offering_submitted_date", "div(last_sold_price, sq_ft)", "div(last_listed_sale_price_all, sq_ft)", "div(last_contract_price, sq_ft)", "offering_exterior_sq_ft", "times_exchanged", "last_exchanged_date"]]
		   if(isHouse){
			   fq = ["-deleted:true", "-offering_unit_abandoned:true", "address_2_normalized:\"\""];
			   fl = [...propertyFL, ...["description"]];
		   }
		   let axiosData = {
			   url: apiURL + "we3-properties?cache=15",
			   method: "post",
			   query: {
				   q: q,
				   fq: fq,
				   fl: fl,
				   sort: 'floor asc, address_2_normalized asc',
				   wt: "json",
				   rows: 999,
				   "json.facet": {
					   "avg_price": `avg(${priceField})`,
					   "avg_sq_ft": "avg(sq_ft)",
					   "low_bed": "min(bedrooms)",
					   "has_bed" : {
						   "type": "query",
						   "q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit"`,
						   "facet": {
							   "unit_mix": {
								   "type" : "terms",
								   "field" : "bedrooms",
								   "facet": {
									   "avg_unit_size" : "avg(sq_ft)",
									   "med_unit_size": "percentile(sq_ft,0,50,100)",
									   
									   "non_zero_price": {
										   "type": "query",
										   "q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										   "facet": {
											   "avg_unit_size" : "avg(sq_ft)",
											   "med_unit_size": "percentile(sq_ft,25,50,75)",
											   "avg_fees" : "avg(offering_monthly_fees)",
											   "med_fees": "percentile(offering_monthly_fees,25,50,75)",
											   "avg_taxes" : "avg(offering_monthly_taxes)",
											   "med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											   "avg_ppsf": `avg(div(${priceField}, sq_ft))`,
											   "min_price": `min(${priceField})`,
											   "max_price": `max(${priceField})`,
											   "avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
										   },
									   },
									   "by_status" : {
										   "type" : "terms",
										   "field" : "offering_status"
									   },
									   
								   }
							   }
						   }
					   },
					   "non_zero_price": {
						   "type": "query",
						   "q": `${priceField}:[100000 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
						   "facet": {
							   "low_price": `min(${priceField})`,
							   "high_price": `max(${priceField})`,
							   "average_price": `avg(${priceField})`,
							   "average_sq_ft": `avg(${priceField})`,
							   "sum_price": `sum(${priceField})`,
							   "low_sq_ft": `min(sq_ft)`,
							   "high_sq_ft": `max(sq_ft)`,
							   "low_bed": `min(bedrooms)`,
							   "high_bed": `max(bedrooms)`,
							   
						   }
					   },
					   "total_sold": {
						   "type": "query",
						   "q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*) ${statsQ}`,
						   "facet": {
							   "low_price": "min(last_listed_sale_price_all)",
							   "high_price": "max(last_listed_sale_price_all)",
							   "average_price": "avg(last_listed_sale_price_all)",
							   "average_sq_ft": "avg(last_listed_sale_price_all)",
							   "sum_price": "sum(last_listed_sale_price_all)",							
							   "low_sq_ft": "min(sq_ft)",
							   "high_sq_ft": "max(sq_ft)",
							   "low_bed": "min(bedrooms)",
							   "high_bed": "max(bedrooms)",
							   "by_neighborhood" : {
								   "type" : "terms",
								   "field" : "neighborhood",
								   "sort" : "avg_ppsf desc",
								   "limit" : 20,
								   "facet" : {
									   "avg_price": "avg(offering_price)",
									   "avg_ppsf": "avg(div(offering_price, sq_ft))",
								   }
							   },
							   
						   }
					   },
					   "total_sellout": {
						   "type": "query",
						   "q": `last_listed_sale_price_all:[1 TO *]  ${statsQ} AND -property_type:"resident manager unit"`,
						   "facet": {
							   "low_price": "min(last_listed_sale_price_all)",
							   "high_price": "max(last_listed_sale_price_all)",
							   "average_price": "avg(last_listed_sale_price_all)",
							   "average_sq_ft": "avg(last_listed_sale_price_all)",
							   "sum_price": "sum(last_listed_sale_price_all)",
							   "sum_sq_ft": "sum(sq_ft)",
							   "low_sq_ft": "min(last_listed_sale_price_all)",
							   "high_sq_ft": "max(sq_ft)",
							   "low_bed": "min(offering_bedrooms)",
							   "high_bed": "max(offering_bedrooms)",
							   "sold_volume" : {
								   "type" : "query",
								   "q" : "offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)",
								   "facet" : {
									   "sum_price": "sum(last_listed_sale_price_all)",
									   "sum_sq_ft": "sum(sq_ft)"
								   }
							   }														
						   }
					   },
					   "total_sq_ft": {
						   "type": "query",
						   "q": `sq_ft:[1 TO *]  ${statsQ} AND -property_type:"resident manager unit"`,
						   "facet" : {
									   "avg_sq_ft": "avg(sq_ft)",
									   "sum_sq_ft": "sum(sq_ft)"
								   }
						   
					   },
					   "first_sold": {
						   "type": "query",
						   "q": `last_sold_date:[1000 TO *] ${statsQ}`,
						   "facet": {
							   "first_sold_date" : "min(last_sold_date)"
						   },
					   },
					   "sold_1_yr": {
						   "type": "query",
						   "q": `last_contract_date_formatted:[NOW-1YEAR TO NOW]`
						   
					   },
					   "sold_3_mo": {
						   "type": "query",
						   "q": `last_contract_date_formatted:[NOW-3MONTH TO NOW]`
						   
					   },
					   "sold_6_mo": {
						   "type": "query",
						   "q": `last_contract_date_formatted:[NOW-6MONTH TO NOW]`
						   
					   },
					   "listed_1_yr": {
						   "type": "query",
						   "q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-1, "year").unix()} TO ${moment().endOf('day').unix()}]`
						   
					   },
					   "listed_3_mo": {
						   "type": "query",
						   "q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-3, "month").unix()} TO ${moment().endOf('day').unix()}]`
						   
					   },
					   "listed_6_mo": {
						   "type": "query",
						   "q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-6, "month").unix()} TO ${moment().endOf('day').unix()}]`
						   
					   },
   
					   "by_status" : {
						   "type": "query",
						   "q": `property_status:sponsor ${statsQ} AND -property_type:"resident manager unit"`,
						   "facet": {
							   "sponsor": {
								   "type" : "terms",
								   "field" : "current_status"
							   },													
						   }	
						   
					   },
					   "resale":{
						   "type": "query",
						   "q": `-property_status:sponsor ${statsQ} AND -property_type:"resident manager unit"`,
					   },
					   "ppsf":{
						   "type": "query",
						   "q": `last_listed_sale_price_all:[500000 TO 300000000] AND sq_ft:[100 TO *]  ${statsQ} ${isResale ? " AND last_listed_sale_date_all:[" + moment().endOf('day').add(-2, "year").valueOf() + " TO *]" : ""}`,
						   "facet": {
							   "avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
						   }
					   },
					   "original_owner" : {
						   "type": "query",
						   "q": `times_exchanged:[0 TO *] AND owner:* ${statsQ}`,
						   "facet" : {
							   "times_sold" : {
								   "type" : "terms",
								   "field" : "times_sold"
							   },
							   "times_exchanged" : {
								   "type" : "terms",
								   "field" : "times_exchanged"
							   }
							   
						   }
												   
					   },
					   "corp_owner" : {
						   "type": "query",
						   "q": `(owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)) ${statsQ}`,																	
					   },
   /*
					   "person_owner" : {
						   "type": "query",
						   "q": `(-owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) AND -owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)) ${statsQ}`,																	
					   }
   */
   
				   }
			   }
		   };
   
		   let formattedData = await _axiosCall(axiosData)
			   .then(res => {
				   // console.log('Chart Data', res);
				   //console.log(res.docs);
				   this.setState({statsRes: res.facets, unitsRes: res.docs.slice()});
			   })
			   .catch(error => {
				   console.log("error: " + error);
			   });
   
		   // console.log(formattedData)
		   return formattedData;
	   };
  
	
  render() {
    const { rtl, sharePageData, isModal, isPDF} = this.props;
    const { buildingRes, statsRes, unitsRes, urlState, userRes } = this.state;
    
    const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
    }	
    
    const user = Object.assign({}, defaultUser, userRes);
    
     if(!statsRes || !buildingRes || userRes===undefined){
	    return <LinearLoading />;
    }
    
    const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	

	let isHouse = _isHouse(buildingRes);
	console.log(isHouse)
	if(isHouse && !unitsRes) return <LinearLoading />;
    return (
		<Fragment>
		<div className={`${sharePageData && sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}${isPDF ? " share-pdf-report" : ""}`}>
	    <div className={`share__wrapper ${isModal ? "is-modal" : ""}`}>
	    	{!isModal && <ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} /> }
		    <Container className="share dashboard">
		    	{!isModal && <ShareContact  user={user} urlState={this.urlState} sharePageData={sharePageData} isModal={isModal}  print={isPDF} />}
				
			    <Row>
				    <Col md={12} lg={12} xl={12}>
				        <Card>
				          <CardBody className="no-border no-padding mg-btm-0"> 
							{!isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								<div className="top__toolbar" style={{display:"flex"}}>
									 <ContactDeveloperButton  buildingRes={buildingRes} share  />
							   </div>  
							}      	
				            <div className="card__title" key={buildingRes.id}>							  
				               <h3 className="page-title">{buildingNameOrAdr}</h3>
				               {isMobile ?
					               <Fragment>
					              <h3 className="page-subhead subhead">			        	
										{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
										{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
								  </h3> 
								  
								  </Fragment>
					           :
							      <h3 className="page-subhead subhead">			        	
											{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>}{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
							      </h3>
						       }
						       </div>
							   {isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								   <div className="top__toolbar" style={{textAlign:"center"}}>
										<ContactDeveloperButton  buildingRes={buildingRes} share  />
								  </div>  
							   } 
				          </CardBody>
				        </Card>
				      </Col>
				</Row>
				
			    {isHouse && unitsRes[0] ? 
					  <HouseProfile {...this.props} {...this.state} property={unitsRes[0]} share/>
				   :
				<Row>
			      <ProductCard {...this.state} share />
			    </Row>
				}
				
				{!isHouse &&
					<Fragment>
				
						{buildingRes.user_description && buildingRes.user_description != "" ?
							<Fragment>
								
								<Row>
									<Col md={12}>
										<h3 className="section-title section-title--not-last">About This Building</h3>
										<h3 className="section-title section-title--not-last subhead"> </h3>
										<hr/>
									</Col>
								</Row>
								<Row>	
									<Col xl={6} lg={12} className="mb-3">
									
								   		<p className="typography-message">
								  		{buildingRes.user_description.split('\n').map((item, key) => {
									  		return <React.Fragment>{item}<br/></React.Fragment>
								  		})}
								   		</p>
							   		
									
									</Col>
									<Col xl={6} lg={12} >
							   		<ShowMap item={buildingRes} height={`360px`} print={isPDF} />
							 		</Col>
								</Row>
								<Row>
									<Col md={12}>
							 		<h3 className="section-title section-title--not-last">Amenities & Features</h3>
							 		<h3 className="section-title section-title--not-last subhead"> </h3>
							 		<hr/>
								
							 		<BuildingAmenities {...this.state} />
							 		</Col>
								</Row>
					  		</Fragment>
						:
							<Fragment>
								<Row>
									<Col md={12}>
							 		<h3 className="section-title section-title--not-last">Amenities & Features</h3>
							 		<h3 className="section-title section-title--not-last subhead"> </h3>
							 		<hr/>
								
							 		<BuildingAmenities {...this.state} />
							 		</Col>
								</Row>
								<Row>
									<Col md={12}>
										<h3 className="section-title section-title--not-last">Location</h3>
										<h3 className="section-title section-title--not-last subhead"> </h3>
										<hr/>
									</Col>
									<Col xl={12} lg={12} >
							   		<ShowMap item={buildingRes} height={`360px`} print={isPDF} />
							 		</Col>
								</Row>
					  		</Fragment>
						}
			   
			    	</Fragment>
				}		    
			  
				  
				<Row>
				  <Col md={12}>
						<p className={"small-text light-text mt-4 mb-2"} style={{color:'#858AA1'}}>All information is from sources deemed reliable but is subject to errors and omissions. No representation is made as to the accuracy of any information. All information is subject to change. All rights to content, photographs, and graphics are reserved for the content source.</p>
					</Col>
				 </Row>
			    {/* <Row>
			     	<Col md={2} />
			    	<Col md={8}>
						<div className="mp-signup">
					 		<span>
					 		<h4>Not a Marketproof Subscriber?</h4>
					 		<p>Access all NYC new development and condo inventory, past sales for 250K condo units and pipeline of future projects.</p>
					 		</span>
					 		<Button
						         variant=""
						         color="primary"
						         className="buyersList__secondary-button"
						         onClick={(e) => {e.stopPropagation();window.open(`https://marketproof.com/new-development`)}}		         
						         
						     >	Sign Up Here
						     </Button>	
						 </div>
				
      
				    </Col>
				    <Col md={2} />
			    </Row>*/}

			</Container>
			<ShareFooter sharePageData={sharePageData} />
			
		</div>
		</div>
		{isPDF && <ExportButton sharePageData={sharePageData} title={`${buildingNameOrAdr} Building Report`} />}
		</Fragment>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(withOktaAuth(SingleBuildingShare))));
