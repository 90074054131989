/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Row, Modal, ButtonToolbar } from 'reactstrap';
import { Link } from "react-router-dom";
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import Icon from 'mdi-react/ChartBarIcon';
import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/FileDocumentOutlineIcon';
import { Tooltip as MUITooltip} from '@material-ui/core';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment";
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/InformationIcon';
import { _buildingIDs, _axiosCall } from '../helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	pipelineCohortQuery,
	mpndCohortQuery,
	resaleCohortQuery,
	_formatUnitNumber,
	_zeroPad
} from "../helpers/utils";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import InfoIcon from 'mdi-react/InformationOutlineIcon';
import LinearLoading from './LinearLoading';

const apiURL = process.env.API_ROOT + '/query/';

const isDST = moment().isDST();
const defaultQ = `((${pipelineCohortQuery}) OR (${mpndCohortQuery}) OR (${resaleCohortQuery})) AND deleted:false`;

const startDate = moment().set({hour:0,minute:0,second:0,millisecond:0}).add(-1, 'year').startOf('month').toDate().toISOString();

const endDate = moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString();
var phaseOut={
	"421a_10" :{
		"1" : 1,
		"2" : 1,
		"3" : .8,
		"4" : .8,
		"5" : .6,
		"6" : .6,
		"7" : .4,
		"8" : .4,
		"9" : .2,
		"10" : .2
	},
	"421a_15" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : 1,
		"11" : 1,
		"12" : .8,
		"13" : .6,
		"14" : .4,
		"15" : .2	
	},
	"421a_18" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : 1,
		"11" : .8,
		"12" : .8,
		"13" : .6,
		"14" : .6,
		"15" : .4,
		"16" : .4,
		"17" : .2,
		"18" : .2
	},
	"421a_20" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : 1,
		"11" : 1,
		"12" : 1,
		"13" : .8,
		"14" : .8,
		"15" : .6,
		"16" : .6,
		"17" : .4,
		"18" : .4,
		"19" : .2,
		"20" : .2	
	},
	"421a_25" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : 1,
		"11" : 1,
		"12" : 1,
		"13" : 1,
		"14" : 1,
		"15" : 1,
		"16" : 1,
		"17" : 1,
		"18" : 1,
		"19" : 1,
		"20" : 1,
		"21" : 1,
		"22" : .8,
		"23" : .6,
		"24" : .4,
		"25" : .2,	
	},
	"421g_12" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : .8,
		"10" : .6,
		"11" : .4,
		"12" : .2
	},
	"421g_13" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : .8,
		"11" : .6,
		"12" : .4,
		"13" : .2
	},
	"J51_14" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : 1,
		"11" : .8,
		"12" : .6,
		"13" : .4,
		"14" : .2
	},
	"J51_34" :{
		"1" : 1,
		"2" : 1,
		"3" : 1,
		"4" : 1,
		"5" : 1,
		"6" : 1,
		"7" : 1,
		"8" : 1,
		"9" : 1,
		"10" : 1,
		"11" : 1,
		"12" : 1,
		"13" : 1,
		"14" : 1,
		"15" : 1,
		"16" : 1,
		"17" : 1,
		"18" : 1,
		"19" : 1,
		"20" : 1,
		"21" : 1,
		"22" : 1,
		"23" : 1,
		"24" : 1,
		"25" : 1,	
		"26" : 1,
		"27" : 1,
		"28" : 1,
		"29" : 1,
		"30" : 1,
		"31" : .8,
		"32" : .6,
		"33" : .4,
		"34" : .2					
	}
}

export const _endOfTaxYear = () => {
  return moment().isLeapYear()
	? moment().dayOfYear() < 183
	: moment().dayOfYear() < 182;
}

export const _cacluateTaxes = (assessment, phaseOut = false) => {
  console.log('')
  var taxRates = {
	 
	 "2023": {
		"1": 0.20309,
		"2": 0.12267,
		"3": 0.12755,
		"4": 0.10646
	  },
	 "2022": {
	   "1": 0.19963,
	   "2": 0.12235,
	   "3": 0.12289,
	   "4": 0.10755
	 },
	 "2021": {
	   "1": 0.21045,
	   "2": 0.12267,
	   "3": 0.12826,
	   "4": 0.10694
	 },
	 "2020": {
	  "1": 0.21167,
	  "2": 0.12473,
	  "3": 0.12536,
	  "4": 0.10537
	},
	"2019": {
	  "1": 0.20919,
	  "2": 0.12612,
	  "3": 0.12093,
	  "4": 0.10514
	},
	"2018": {
	  "1": 0.20385,
	  "2": 0.12719,
	  "3": 0.11891,
	  "4": 0.10514
	},
	"2017": {
	  "1": 0.19991,
	  "2": 0.12892,
	  "3": 0.10934,
	  "4": 0.10574
	},
	"2016": {
	  "1": 0.19554,
	  "2": 0.12883,
	  "3": 0.10813,
	  "4": 0.10656
	},
	"2015": {
	  "1": 0.19157,
	  "2": 0.12855,
	  "3": 0.11125,
	  "4": 0.10684
	},
	"2014": {
	  "1": 0.19191,
	  "2": 0.13145,
	  "3": 0.11902,
	  "4": 0.10323
	},
	"2013": {
	  "1": 0.18569,
	  "2": 0.13181,
	  "3": 0.12477,
	  "4": 0.10288
	},
	"2012": {
	  "1": 0.18205,
	  "2": 0.13433,
	  "3": 0.12473,
	  "4": 0.10152
	},
	"2011": {
	  "1": 0.17364,
	  "2": 0.13353,
	  "3": 0.12631,
	  "4": 0.10312
	},
	"2010": {
	  "1": 0.17088,
	  "2": 0.13241,
	  "3": 0.12743,
	  "4": 0.10426
	},
	"2009": {
	  "1": 0.16196,
	  "2": 0.12596,
	  "3": 0.12137,
	  "4": 0.10241
	},
	"2008": {
	  "1": 0.15434,
	  "2": 0.11928,
	  "3": 0.11577,
	  "4": 0.10059
	},
	"2007": {
	  "1": 0.16118,
	  "2": 0.12737,
	  "3": 0.12007,
	  "4": 0.10997
	},
	"2006": {
	  "1": 0.15746,
	  "2": 0.12396,
	  "3": 0.12309,
	  "4": 0.11306
	},
	"2005": {
	  "1": 0.15094,
	  "2": 0.12216,
	  "3": 0.12533,
	  "4": 0.11558
	},
	"2004": {
	  "1": 0.1455,
	  "2": 0.1262,
	  "3": 0.12418,
	  "4": 0.11431
	},
	"2003": {
	  "1": 0.13048,
	  "2": 0.11539,
	  "3": 0.11586,
	  "4": 0.10678
	},
	"2002": {
	  "1": 0.11609,
	  "2": 0.10792,
	  "3": 0.10541,
	  "4": 0.9712
	},
	"2001": {
	  "1": 0.11255,
	  "2": 0.10847,
	  "3": 0.1054,
	  "4": 0.9768
	},
	"2000": {
	  "1": 0.11167,
	  "2": 0.10851,
	  "3": 0.9398,
	  "4": 0.9989
	}
  };
  

  var taxes = {
	taxable_amt: false,
	tax_rate: false,
	tax_rate_pct: false,
	yearly_taxes: false,
	quarterly_taxes: false,
	monthly_taxes: false
  };

  var taxable_total = assessment.final_actual_assessed_value_total ? assessment.final_actual_assessed_value_total : assessment.current_actual_assessed_value_total;
  var exempt_total = assessment.final_actual_exempt_value_total ? assessment.final_actual_exempt_value_total : assessment.current_actual_exempt_value_total;

  if (!phaseOut) {
	if (
	  taxable_total > assessment.final_transitional_assessed_value_total &&
	  assessment.final_transitional_assessed_value_total > 0
	) {
	  console.log('final_transitional_assessed_value_total')
	  taxable_total = assessment.final_transitional_assessed_value_total;
	  exempt_total = assessment.final_transitional_exempt_value_total;
	}
	
	if (
	  taxable_total > assessment.final_transitional_assessed_value_total &&
	  assessment.final_transitional_assessed_value_total > 0
	) {
	  console.log('final_transitional_assessed_value_total')
	  taxable_total = assessment.final_transitional_assessed_value_total;
	  exempt_total = assessment.final_transitional_exempt_value_total;
	}

	if (
	  (taxable_total > assessment.tentative_transitional_assessed_value_total &&
	  assessment.tentative_transitional_assessed_value_total > 0)
	  || taxable_total === 0
	) {
	  console.log('tentative_transitional_assessed_value_total')

	  taxable_total = assessment.tentative_transitional_assessed_value_total;
	  exempt_total = assessment.tentative_transitional_exempt_value_total;
	}
	
	console.log(assessment.year, 'nonPhaseOut taxable_total', taxable_total, 'exempt_total', exempt_total, assessment)
  } else if(taxable_total === 0) {
	const varName = assessment.final_transitional_assessed_value_total > 0
	  ? 'final_transitional_assessed_value_total'
	  : assessment.tentative_actual_assessed_value_total > 0 
		? 'tentative_actual_assessed_value_total'
		: assessment.tentative_transitional_assessed_value_total > 0
		  ? 'tentative_transitional_assessed_value_total'
		  : '';

	taxable_total = assessment.final_transitional_assessed_value_total > 0
	  ? assessment.final_transitional_assessed_value_total
	  : assessment.tentative_actual_assessed_value_total > 0 
		? assessment.tentative_actual_assessed_value_total
		: assessment.tentative_transitional_assessed_value_total > 0
		  ? assessment.tentative_transitional_assessed_value_total
		  : 0;

	exempt_total = assessment[varName.replace('assessed', 'exempt')];

	console.log(assessment.year, 'phaseOut taxable_total', taxable_total, 'exempt_total', exempt_total, 'varName', varName, varName.replace('assessed', 'exempt'))

  }

  /*
	if(exempt_total<assessment.current_actual_exempt_value_total){
	  exempt_total = assessment.current_actual_exempt_value_total;
	  taxable_total = assessment.current_actual_assessed_value_total;
	  }
*/
  console.log('_cacluateTaxes', assessment.key, assessment.assessment_year);
  console.log('_cacluateTaxes taxable_total',assessment.year, taxable_total, 'exempt_total', exempt_total);
  // console.log('_cacluateTaxes', assessment);
  taxes.taxable_amt = taxable_total - exempt_total;
  if(taxes.taxable_amt === 0)  {
	console.log('taxes.taxable_amt', taxes.taxable_amt, assessment.key)
	console.log('assessment.final_actual_assessed_value_total', assessment.final_actual_assessed_value_total, )
	console.log('assessment.final_transitional_assessed_value_total', assessment.final_transitional_assessed_value_total, )
	console.log('assessment.tentative_transitional_assessed_value_total', assessment.tentative_transitional_assessed_value_total, )
	console.log('phaseOut', phaseOut)
  }

  let taxYear = _endOfTaxYear() ? assessment.assessment_year + 2 : assessment.assessment_year + 1;
  if(!taxRates[taxYear]) {
	taxYear = taxRates[assessment.assessment_year + 1] ? assessment.assessment_year + 1 : assessment.assessment_year;
  }
  taxes.tax_rate = taxRates[taxYear][assessment.tax_class[0]];
  taxes.tax_rate_pct = Math.round(taxes.tax_rate * 100000) / 1000;
  console.log('taxes.taxable_amt', taxes.taxable_amt, 'taxes.tax_rate', taxes.tax_rate)
  taxes.yearly_taxes = Math.round(taxes.taxable_amt * taxes.tax_rate);
  taxes.quarterly_taxes = Math.round(taxes.yearly_taxes / 4);
  taxes.monthly_taxes = Math.round(taxes.yearly_taxes / 12);

  return taxes;
};

var transitionalYearly = {
	  "1" : .06,
	  "2" : .2,
	  "2a" : .08,
	  "2b" : .08,
	  "2c" : .08,
	  "4" : .2,
	  "4" : .2
}

const CustomTooltip = ({ active, payload }) => {
  if (active) {
	return (
	  <div className="dashboard__total-tooltip" style={{background:"#171F26",padding:"5px 10px",borderRadius:3}}>
		<p className="label small-text" style={{color:"#FFF",fontSize:"11px"}}>Tax Year: {`${payload[0].payload.name} -`}
		<span style={{fontWeight:500}}> {`${payload[0].value}% Tax Abatement`}</span></p>
	  </div>
	);
  }

  return null;
};

const CustomLabelList = (props) => {
  console.log('CustomLabelList : ', props); // It is Top Result. not have x, y
  const {x, y, stroke, value} = props;

  return (
	<text x={x} dx={5} y={65} fill={stroke} fontSize={10} textAnchor="middle">{value[0].toUpperCase()}</text>
  )
};

const ShowStat = ({stat, subtext, icon}) => {
	return (
	<Card>
	  <CardBody className="dashboard__booking-card">
		<div className="dashboard__booking-total-container">
		  <h5 className="dashboard__booking-total-title">
			{stat}
		  </h5>
		  {icon}
		</div>
		<h5 className="dashboard__booking-total-description">{subtext}</h5>
	  </CardBody>
	</Card>
	)
}


const _createTaxYearDisplay = (year) => {
	if(moment().month() <7 ){
		year++;
	}
	return year + '/'+ (year+1).toString().slice(-2).toString();
}


const ShowDetails = ({type, data}) =>{
	return (
		<div className="dashboard">      
			<Row>
				<Col md={12} className={"mb-3"}>
					<h5 className="contact__title">
						{type} Tax Abatement
					</h5>
				</Col>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={type} subtext={'Tax Benefit Type'} icon={null} />
				</Col>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={data['curYear']+"%"} subtext={`% Tax Deducted This Year`} icon={null} />
				</Col>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={data['yearRemain'] + " Years"} subtext={`Remaining (${data['duration']} Years Total)`} icon={null} />
				</Col>
				
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={data['start'].year() + " - " + data['end'].year()} subtext={'Benefit Years'} icon={null} />
				</Col>
			</Row>
			<Row>
				
			<Col md={12} xl={12} lg={12} xs={12}>
				<Card>
			  	<CardBody className="dashboard__booking-card">
					<Col md={12} className={"mb-3"}>
						<h5 className="dashboard__booking-total-description">
							{type} Phase Out - Percent Tax Deducted by Year
						</h5>
					</Col>
				  	<div dir="ltr">
						<ResponsiveContainer height={250} className="dashboard__area">
					  	<AreaChart data={data['chartData']} margin={{ top: 20, left: -15, bottom: 20 }}>
							<XAxis dataKey="name" tickLine={true}  angle={-45} textAnchor="end" />
							<YAxis interval={0} tickLine={false} orientation={'left'} tickFormatter={(val)=> `${val}%`} />
							<Tooltip content={<CustomTooltip />} />
							<Legend />
							<CartesianGrid />
							<Area
						  	name="Percent Tax Deducted by Year"
						  	type="monotone"
						  	dataKey="percent"
						  	fill="#780F9E"
						  	stroke="#780F9E"
						  	fillOpacity={0.2}
							/>
					  	</AreaChart>
						</ResponsiveContainer>
				  	</div>
			  	</CardBody>
				</Card>
		  	</Col>
			</Row>
			</div>	
	)
	
}


const ShowTaxes = ({taxAssessments, _421a, _j51, property, min, setTab}) =>{
	let currentYear = moment().year();
	let currentMonth = moment().month();
	
	
	
	let currentAssessment = false;
	if(currentMonth <= 7 ){
		currentAssessment = taxAssessments.filter(item => item.assessment_year == (currentYear-2))[0]
		if(!currentAssessment && taxAssessments.filter(item => item.assessment_year == (currentYear-1))[0]){
			currentAssessment = taxAssessments.filter(item => item.assessment_year == (currentYear-1))[0]
		}
	}else{
		currentAssessment = taxAssessments.filter(item => item.assessment_year == (currentYear-1))[0]
	}
	
	if(!currentAssessment) return null;
	
	
	
	if(min){
		return (

				  <tr>
					<th>Assessed Taxes:</th>
					<td>{`$${_formatNumber(currentAssessment.taxes.monthly_taxes)}`}
						{' '}<span className="small-text"><a className="link-text" href={`javascript:void(0)`} onClick={() => setTab('taxes')}>(See Assessment)</a></span>
					</td>
				  </tr>
			
		)
	}
	var borough = currentAssessment.borough_code;
	var block = _zeroPad(currentAssessment.block, 5);
	var lot = _zeroPad(currentAssessment.lot, 4);
	var bbl = borough+block+lot;
	
	const chartData = [...taxAssessments].reverse().map(item => {
		item.taxes.monthly_taxes = item.taxes.monthly_taxes ? item.taxes.monthly_taxes : 0;
		return Object.assign({name: _createTaxYearDisplay(item.assessment_year)}, {year: item.assessment_year}, item.taxes, {project_monthly_taxes : 0});
	});
	let hasP = false;

	const phaseOutChart = [];
	if(_421a){
		const endYear = _421a.end.year();
		const startYear = _421a.start.year();
		var lastProjected = false;
		var phaseOutStartYear = false;
		
		console.log(chartData[0].year, startYear, endYear)
		for(let i= (chartData[0].year); i < (endYear-2); i++){
			var year = (i+1);		
			const phaseOutKey = `421a_${_421a['duration']}`;
			let projectedAssessment = Object.assign({}, JSON.parse(JSON.stringify(taxAssessments[0])));					
			projectedAssessment.year = year;
			
			console.log("year:",  year, phaseOut[phaseOutKey])
			/*** Do Transitional Additions***/
			if(year > taxAssessments[0].assessment_year){
				if(lastProjected === false){
				  lastProjected = Object.assign({}, JSON.parse(JSON.stringify(taxAssessments[0])));
				}
				if(projectedAssessment.final_actual_assessed_value_total > lastProjected.final_transitional_assessed_value_total){
					var diff = projectedAssessment.final_actual_assessed_value_total - lastProjected.final_transitional_assessed_value_total;
					var addition = diff*transitionalYearly[projectedAssessment.tax_class.toLowerCase()];
					
					projectedAssessment.final_transitional_assessed_value_total = Math.round((lastProjected.final_transitional_assessed_value_total + addition)); 
					// console.log(lastProjected.final_transitional_assessed_value_total, addition, projectedAssessment.final_transitional_assessed_value_total);
				}		
				lastProjected = Object.assign({}, projectedAssessment);			
			}															
			/*** Do Transitional Additions***/
			
			if(phaseOut[phaseOutKey]){
		
				var benefitYear = (year - startYear)+2;
				
				let taxes = {};
				if(phaseOut[phaseOutKey][benefitYear] !== undefined && phaseOut[phaseOutKey][benefitYear]>0 && phaseOut[phaseOutKey][benefitYear]<1){
					console.log(year, benefitYear, startYear, endYear)
					projectedAssessment.final_transitional_exempt_value_total = Math.round(projectedAssessment.final_transitional_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);
					projectedAssessment.final_actual_exempt_value_total = Math.round(projectedAssessment.final_actual_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);
					projectedAssessment.current_actual_exempt_value_total = Math.round(projectedAssessment.current_actual_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);		
					projectedAssessment.tentative_actual_exempt_value_total = Math.round(projectedAssessment.tentative_actual_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);
					projectedAssessment.current_transitional_exempt_value_total = Math.round(projectedAssessment.current_transitional_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);	
					projectedAssessment.current_taxable_exempt_value_total = Math.round(projectedAssessment.current_taxable_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);
					projectedAssessment.tentative_transitional_exempt_value_total = Math.round(projectedAssessment.tentative_transitional_exempt_value_total * phaseOut[phaseOutKey][benefitYear]);
					
					if(phaseOutStartYear===false){
						phaseOutStartYear = year;
						// console.log(phaseOutStartYear);
					}
					
					console.log("chartData pushed projected, key", phaseOut[phaseOutKey], benefitYear);
					taxes = _cacluateTaxes(projectedAssessment);
					console.log("taxes:", year, taxes);
					//projectedAssessment = Object.assign({}, projectedAssessment,  Object.assign({}, taxes));
				}else if(year >= endYear){
					projectedAssessment.final_transitional_exempt_value_total = 0;
					projectedAssessment.final_actual_exempt_value_total = 0;
					projectedAssessment.current_actual_exempt_value_total = 0;
					projectedAssessment.tentative_actual_exempt_value_total = 0;
					taxes = _cacluateTaxes(projectedAssessment, true);
					//console.log(taxes);
					//projectedAssessment = Object.assign({}, projectedAssessment, taxes);
				}else{
					taxes = _cacluateTaxes(taxAssessments[0])
				}
				
				if(year>taxAssessments[0].assessment_year){
					console.log('benefitYear', year, benefitYear, phaseOut[phaseOutKey][benefitYear], projectedAssessment);
					const monthly = parseInt(taxes.monthly_taxes);
					hasP = true;
					chartData.push( Object.assign({name: _createTaxYearDisplay((year)) + "^"}, {year: year}, taxes, { project_monthly_taxes : monthly, benefit_year: benefitYear }));
					
					//phaseOutChart.push( Object.assign({name: _createTaxYearDisplay((year))}, {year: year}, taxes,  {project_monthly_taxes : taxes.monthly_taxes, benefit_year: benefitYear }));
					
				}
			}
			
			
		}
	}
	console.log(chartData)
	
	
	const CustomTooltipTax = ({ active, payload }) => {
	  if (active) {
		return (
		  <div className="dashboard__total-tooltip" style={{background:"#171F26",padding:"5px 10px",borderRadius:3}}>
			<p className="label small-text" style={{color:"#FFF",fontSize:"11px"}}>Tax Year: {`${_createTaxYearDisplay(payload[0].payload.year)} -`}
			<span style={{fontWeight:500}}> {payload[0].value ? `$${_formatNumber(payload[0].value)} Monthly` : "--"}</span></p>
		  </div>
		);
	  }
	
	  return null;
	};
	
	var statementDate = '20231118';
	var nopvDate = {
		1 : '20220217',
		2 : '20220115',
		3 : '20220115',
		4 : '20220115',
		5 : '20220115'
	}
	return (
		<div className="dashboard">      
			<Row style={{alignItems:"end"}}>
				<Col md={6} className={"mb-3"}>
					{isMobile ?
					<h5 className="contact__title">
						<div className="mb-2">
						Tax Assessment (Block: {property.block} | Lot: {property.lot})
						</div>
						<div className="mb-2">
						Current Tax Year ({_createTaxYearDisplay(currentAssessment.assessment_year)})
						</div>
					</h5>
					:
					<h5 className="contact__title">
						Tax Assessment (Block: {property.block} | Lot: {property.lot}) - Current Tax Year ({_createTaxYearDisplay(currentAssessment.assessment_year)})<br/>
						
					</h5>
				}
				</Col>
				<Col md={6} className={"mb-3 text-right"}>
				<div className="">
						<MUITooltip title={`See latest tax statement`}>
							<Button
								 variant="outlined"
								 color="secondary"
								 className="buyersList__secondary-button"		         
								 startIcon={<ListingIcon />}
								 style={{marginRight:10}}
								 onClick={(e) => {e.stopPropagation();
									    //_windowOpenInPost("https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=persprop", "tax", params)
									 	window.open(`https://a836-edms.nyc.gov/dctm-rest/repositories/dofedmspts/StatementSearch?bbl=${bbl}&stmtDate=${statementDate}&stmtType=SOA`)
								}}
							 >Tax Bill
							 </Button>				 
						</MUITooltip>
						
						<MUITooltip title={`See more tax info on NYC Dept. of Finance.  Search block: ${property.block} and lot: ${property.lot}`}>
							<Button
								 variant="outlined"
								 color="secondary"
								 className="buyersList__secondary-button"		         
								 startIcon={<OpenInNewIcon />}
								 type="button"
								 onClick={(e) => {e.stopPropagation();
										//_windowOpenInPost("https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=persprop", "tax", params)
										window.open(`https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=persprop`)
										 //window.open(`https://a836-edms.nyc.gov/dctm-rest/repositories/dofedmspts/StatementSearch?bbl=${bbl}&stmtDate=${nopvDate[property.borough_code]}`)
								}}
							 >NYC DOF
							 </Button>				 
						</MUITooltip>
						
						
				  </div>
				</Col>
			</Row>
			<Row>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={`$${_formatNumber(currentAssessment.taxes.monthly_taxes)}`} subtext={'Monthly Taxes*'} icon={null} />
				</Col>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={`$${_formatNumber(currentAssessment.taxes.yearly_taxes)}`} subtext={'Annual Taxes*'} icon={null} />
				</Col>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={`$${_formatNumber(currentAssessment.current_taxable_assessed_value_total)}`} subtext={`Taxable Assessed Value`} icon={null} />
				</Col>
				<Col md={12} xl={3} lg={6} xs={12}>
					<ShowStat stat={`${currentAssessment.taxes.tax_rate_pct}%`} subtext={`Tax Rate`} icon={null} />
				</Col>
				
				
			
			
				
			</Row>
			<Row>
				
			<Col md={12} xl={12} lg={12} xs={12}>
				<Card>
				  <CardBody className="dashboard__booking-card">
					<Col md={12} className={"mb-3"}>
						<h5 className="dashboard__booking-total-description">
							Monthly Taxes by Year
						</h5>
					</Col>
					  <div dir="ltr">
						<ResponsiveContainer height={250} className="dashboard__area">
						  <AreaChart data={chartData} margin={{ top: 20, left: -15, bottom: 30 }}>
							<XAxis dataKey="name" tickLine={true}  angle={-45} textAnchor="end" />
							<YAxis interval={0} tickLine={false} orientation={'left'} tickFormatter={(val)=> val ? `$${_formatNumber(val)}` : 0} />
							<Tooltip content={<CustomTooltipTax />} />
							<Legend />
							<CartesianGrid />
						
							<Area
							  name={hasP ? "Monthly Taxes by Tax Year (^ - Projected)" : "Monthly Taxes by Tax Year"}
							  type="monotone"
							  dataKey="monthly_taxes"
							  fill="#780F9E"
							  stroke="#780F9E"
							  fillOpacity={0.2}
							  stackId="1"
							/>
							
						  </AreaChart>
						  
						</ResponsiveContainer>
					  </div>
				  </CardBody>
				</Card>
			  </Col>
			</Row>
			<Row>
			<Col md={12} className={"mb-3"}>
			<div className="small-text light-text">*Additional adjustments may apply that affect the final tax bill such as the <a href="https://www1.nyc.gov/site/finance/benefits/landlords-coop-condo.page" className="link-text" target="_blank">co-op/condo abatement</a>.</div>
			</Col>
				
				
			</Row>
			</div>	
	)
	
}

const NoTaxAssessments = ( props ) => {

  return (
  <Col md={12}>
	<Card>
	  <CardBody>
		<div className={`email-confirmation`} style={{minHeight:600}}>
		  <div className="email-confirmation__icon">
			<ListingIcon className="email-confirmation__mail" />
			<CheckboxMarkedCircleIcon className="email-confirmation__check" />
		  </div>
		  <h4 className="email-confirmation__title">No Tax Assessment Information Available for This Unit</h4>

		</div>
	  </CardBody>
	</Card>
  </Col>
  )
};



class TaxAbatement extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  activeIndex: 0,
	  data : false,
	  taxAbatement: null,
	  modal: false,
	  taxAssessments:null
	};
  }
   componentDidMount() {
	   //this._getBuildings();
	   const {  property } = this.props;
	  
	   
	   
	   
	   this._getBuildingTaxAbatementData();
	   
	  
  }


  handleClick = (item) => {
	const { data } = this.state;
	const index = data.indexOf(item.payload);
	this.setState({
	  activeIndex: index,
	});
  };
  
  _getBuildingTaxAbatementData = async () => {
	 const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
	 const { buildingRes, property } = this.props;
	 const addressQ = buildingRes.alternate_addresses.join('" OR "');
	 
	 let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}"))`
	 
	 let axiosData = {
		 url: apiURL + "we3-tax-abatements-subsidies?cache=120",
		 method: "post",
		 query: {
			 q: q,
			 fq: [],
			 sort: "report_date desc",
			 wt: "json",
			 rows: 10,
		 }
	 }
	 
	 let formattedData = await _axiosCall(axiosData)
		 .then(res => {
			 // console.log('Chart Data', res);
			 if(res && res.docs){
				 this.setState({taxAbatement: res.docs});
			 }else{
				 this.setState({taxAbatement: false});
			 }
			 
			 if(property){
					this._getBuildingTaxAssessmentData();
			 }
				 
		 })
		 .catch(error => {
			 console.log("error: " + error);
		 });
 
 }
 
 _getBuildingTaxAssessmentData = () => {
	  const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
	  const { buildingRes, property } = this.props;
	  const addressQ = buildingRes.alternate_addresses.join('" OR "');
	  
	  let q = `(alternate_addresses:("${addressQ}") AND address_2_normalized:("${property.address_2_normalized}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}"))`
	  
	  if(property.block && property.lot){
		  q += ` OR (borough_code:${property.borough_code} AND block:${property.block} AND lot:${property.lot})`;
	  }
	  
	  let axiosData = {
		  url: apiURL + "dof-assessments-od?cache=120",
		  method: "post",
		  query: {
			  q: q,
			  fq: ["period:3"],
			  sort: "assessment_year desc",
			  wt: "json",
			  rows: 10,
		  }
	  }
	  var taxUnits = [];
	  _axiosCall(axiosData)
		  .then(res => {
			  // console.log('Chart Data', res);
			  axiosData['url'] =  apiURL + "dob-assessments?cache=120";			  
			  axiosData['query']['fq'] = ["assessment_year:[* TO 2019]"];
			  _axiosCall(axiosData).then(res2 => {
				    if(res && res.docs && res2 && res2.docs){
						//this.setState({taxAbatement: res.docs});
						res.docs.map(assessment => {
							let taxes = _cacluateTaxes(assessment);
							assessment.taxes = taxes;
							taxUnits.push(assessment);
						})
						res2.docs.map(assessment => {
							let taxes = _cacluateTaxes(assessment);
							assessment.taxes = taxes;
							taxUnits.push(assessment);
						});
						this.setState({taxAssessments: taxUnits});
						
					}else{
						this.setState({taxAssessments: false});
					}
				  
			  }).catch(error => {
				  console.log("error: " + error);
			  })
			  
				  
		  })
		  .catch(error => {
			  
		  });
  
  }
 toggle() {
	 const { buildingRes, property } = this.props;
	 this.setState(prevState => ({ modal: !prevState.modal }));
	
   }
  render() {
	const { buildingRes, property, min, share } = this.props;
	const { activeIndex, data, taxAbatement, taxAssessments, modal } = this.state;
	
	if(property && (taxAssessments === null || taxAbatement === null)) return null
	
	if(taxAbatement === null) return null
	
	
	
	let abt = false;
	if(taxAbatement.length>0){
		abt = taxAbatement[0]
	}
	
	let abts = [];
	const curYear = moment().year();
	let _421a = false;
	let _j51 = false;
	let _421g = false;
	if(abt){
		if(abt.program_421a){
			_421a = {};
			let chartData = [];
			let start = false;
			let end = false;
			let duration = false;
			if(abt.program_421a_start_date){
				_421a['start'] = moment(abt.program_421a_start_date)
			}
			if(abt.program_421a_end_date){
				_421a['end'] = moment(abt.program_421a_end_date)
				_421a['yearRemain'] = _421a['end'].year() - curYear;
				_421a['yearRemain'] = _421a['yearRemain'] < 0 ? 0 : _421a['yearRemain'];
			}
			
			if(_421a['start'] && _421a['end']){
				_421a['duration'] = _421a['end'].year() - _421a['start'].year();
			}
			{/*{duration ? duration + " Years | " : ""} {start.year()} - {end.year()}*/}
			
			const phaseKey = `421a_${_421a['duration']}`;
			//console.log(phaseKey)
			const startYear = _421a['start'].year();
			for(let i=1; i<=(_421a['duration']+1); i++){
				const y = startYear + (i-1);
				const p = phaseOut[phaseKey] && phaseOut[phaseKey][i] ? (phaseOut[phaseKey][i] * 100) : 0;

				chartData.push({name: `${parseInt(y-1)}/${(parseInt(y)).toString().substring(2)}`, percent : p});
				if(y == curYear){
					_421a['curYear'] = p;
				}
				
			}
			_421a['chartData'] = chartData;
			abts.push(<span><a href="javascript:void(0)" className="link-text"  onClick={this.toggle.bind(this)}>421a  <span className="link-text"> - {_421a['yearRemain'] >=0 ? `Expires in ${_421a['end'].year()}` :  `Expired ${_421a['end'].year()}`} </span> <OpenInNewIcon size={14} /> </a></span>);
		}
		//console.log(_421a)
		if(abt.program_421g){
			_421g = {};
			let chartData = [];
			let start = false;
			let end = false;
			let duration = false;
			if(abt.program_421g_start_date){
				_421g['start'] = moment(abt.program_421g_start_date)
			}
			if(abt.program_421g_end_date){
				_421g['end'] = moment(abt.program_421g_end_date)
				_421g['yearRemain'] = _421g['end'].year() - curYear;				
				_421g['yearRemain'] = _421g['yearRemain'] < 0 ? 0 : _421g['yearRemain'];
			}
			
			if(_421g['start'] && _421g['end']){
				_421g['duration'] = _421g['end'].year() - _421g['start'].year();
			}
			{/*{duration ? duration + " Years | " : ""} {start.year()} - {end.year()}*/}
			
			const phaseKey = `421g_${_421g['duration']}`;
			const startYear = _421g['start'].year();
			for(let i=1; i<=(_421g['duration']+1); i++){
				const y = startYear + (i-1);
				const p = phaseOut[phaseKey] && phaseOut[phaseKey][i] ? (phaseOut[phaseKey][i] * 100) : 0;
		
				chartData.push({name: `${parseInt(y-1)}/${(parseInt(y)).toString().substring(2)}`, percent : p});
				if(y == curYear){
					_421g['curYear'] = p;
				}
				
			}
			_421g['chartData'] = chartData;
			abts.push(<span><a href="javascript:void(0)" className="link-text"  onClick={this.toggle.bind(this)}>421g  <span className="link-text"> - {_421g['yearRemain'] >=0 ? `Expires in ${_421g['end'].year()}` :  `Expired ${_421g['end'].year()}`} </span> <OpenInNewIcon size={14} /> </a></span>);
		}
		if(abt.program_j51){
			_j51 = {};
			let chartData = [];
			let start = false;
			let end = false;
			let duration = false;
			if(abt.program_j51_start_date){
				_j51['start'] = moment(abt.program_j51_start_date)
			}
			if(abt.program_j51_end_date){
				_j51['end'] = moment(abt.program_j51_end_date)
				_j51['yearRemain'] = _j51['end'].year() - curYear;
				_j51['yearRemain'] = _j51['yearRemain'] < 0 ? 0 : _j51['yearRemain'];
			}
			
			if(_j51['start'] && _j51['end']){
				_j51['duration'] = _j51['end'].year() - _j51['start'].year();
			}
			{/*{duration ? duration + " Years | " : ""} {start.year()} - {end.year()}*/}
			
			const phaseKey = `J51_${_j51['duration']}`;
			const startYear = _j51['start'].year();
			for(let i=1; i<=(_j51['duration']+1); i++){
				const y = startYear + (i-1);
				const p = phaseOut[phaseKey] && phaseOut[phaseKey][i] ? (phaseOut[phaseKey][i] * 100) : 0;
		
				chartData.push({name:`${parseInt(y-1)}/${(parseInt(y)).toString().substring(2)}`, percent : p});
				if(y == curYear){
					_j51['curYear'] = p;
				}
				
			}
			_j51['chartData'] = chartData;
			abts.push(<span><a href="javascript:void(0)" className="link-text"  onClick={this.toggle.bind(this)}>J51  <span className="link-text"> - {_j51['yearRemain'] >=0 ? `Expires in ${_j51['end'].year()}` :  `Expired ${_j51['end'].year()}`} </span> <OpenInNewIcon size={14} /> </a></span>);
		}
	}
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;	
	
	if(property){
		
		
		return (
		<Fragment>
			
			{taxAssessments && taxAssessments.length>0 ?
				<ShowTaxes {...this.state} {...this.props} _421a={_421a} _j51={_j51} min={min} />
				
			: !min ?
				<NoTaxAssessments />
			: null
			}
			
			{!min && abts && abts.length>0 && 
				<Fragment>
				<div><hr/></div>
				{_421a && _421a['chartData'] && 
					<ShowDetails data={_421a} type={"421a"} />
				}
				{_421g && _421g['chartData'] && 
					<ShowDetails data={_421g} type={"421g"} />
				}
				{_j51 && _j51['chartData'] && 
					<ShowDetails data={_j51} type={"J51"} />
				}
				</Fragment>	
			}
			
		</Fragment>
		)	
		
		
	}
	
	return (
		<Fragment>
			{abts && abts.length>0 ? 
	  		<tr>
				<th>{`Tax Abatement:`}</th>
				<td>
					{abts.map(a => <span>{a}</span>)}
						
				</td>
	  		</tr> 
	  		:
	  		null}
			<Fragment>
				<Modal
				  isOpen={modal}
				  toggle={this.toggle.bind(this)}
				  modalClassName={`ltr-support`}
				  className={`modal-dialog contact__modal`}
				>
				<div className="modal__header">
					<button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle.bind(this)} />
				  </div>
				  <Row>
										  
					<Col xl={12} lg={12} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">{buildingNameOrAdr} Tax Benefits
								{property && <span> - {_formatUnitNumber(property.address_2_normalized)}</span>}
							</h4>
							
							{property && 
								<h5 className="contact__sub-title">
									{property.offering_price && property.offering_price > 0 ? <Fragment>${_formatNumber(property.offering_price)}<span className="small-divider">|</span></Fragment> : null} 
									
									  {property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
									  <span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? ' Bed' : ' Beds'}</span>
									  <span className="small-divider">|</span>
									  {property.baths}
									  <span className="sub-text">{property.baths === 1 ? ' Bath' : ' Baths'}</span>
									  <span className="small-divider">|</span>
									  {_formatNumber(property.sq_ft)}
									  <span className="sub-text"> Sq Ft</span>				              	
			
							   </h5>
								
							}
			
							<hr/>
							
							{_421a && _421a['chartData'] && 
								<ShowDetails data={_421a} type={"421a"} />
							}
							{_421g && _421g['chartData'] && 
								<ShowDetails data={_421g} type={"421g"} />
							}
							{_j51 && _j51['chartData'] && 
								<ShowDetails data={_j51} type={"J51"} />
							}	
							</div>
																
							</Col>	  
				  		</Row>
				  		
				</Modal>
			</Fragment>
		</Fragment>
	);
  }
}

export default TaxAbatement;
