/* eslint-disable react/no-children-prop */
import React, { Component, Fragment } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import ClearIcon from "mdi-react/CloseCircleOutlineIcon";
import AlertIcon from 'mdi-react/AlertCircleOutlineIcon';
import Button from '@material-ui/core/Button';
import ReportIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import moment from 'moment';
import ShareContractAgents from '../../../../shared/components/ShareContractAgents'

import ReportContractStepper from '../ReportContractStepper';


import {
  DatePicker,
  KeyboardDatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import{
	matTheme,
	
} from '../../../helpers/utils';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_nameToUrl
} from '../../../../shared/helpers/utils';
import { _hasPermission	} from "../../../../shared/helpers/access";
const redux_form_name = "contract_form";

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows, disabled
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    multiline={rows}
	    rows={rows}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    variant="outlined"
	    disabled={disabled}
		
	  />
	  {touched && ((error && <span>{error}</span>))}
	</ThemeProvider>
);
renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};
renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

const required = value => value ? undefined : 'Required'
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
const renderDateField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<div>
	<MuiPickersUtilsProvider utils={MomentUtils}>
	  <DatePicker 
	    autoOk
        clearable
	    placeholder="M/D/YYYY"
	   	value={input.value ? input.value : null} 
	   	onChange={date => input.onChange(moment(date).format('M/D/YYYY'))} 
	   	format="M/D/YYYY"
	   	 variant="inline"
        inputVariant="outlined"
	   />
	</MuiPickersUtilsProvider>
	</div>
);

const contractFormatter = (number) => {
  if (!number) return;

    return '$' + number
        .replace(/,/g , '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const contractParser = (number) => {
  return number.replace(/[^0-9\.]+/g , '');
};

const renderCheckbox = ({ input, label, disabled }) => {

	return (
		  <FormControlLabel
		      control={
		        <Checkbox
		          checked={input.value ? true : false}
		          onChange={input.onChange}
		        />
		      }
		      label={label}
		      disabled={disabled}
		    />
   )
}
const renderRadio = ({ input, label, disabled }) => {
	
	console.log(input.value);
	return (
	  <RadioGroup aria-label="agentType" name="agentType" value={input.value} onChange={input.onChange} row>
        <FormControlLabel value="buyer" control={<Radio />} label="Buyer" />
        <FormControlLabel value="seller" control={<Radio />} label="Seller" />
      </RadioGroup>
   )
}


const mockProperty = {
			"custom_fields_json": "{\"mpnd_user_contract\":\"{\\\"meta\\\":{\\\"userEmail\\\":\\\"ning@marketproof.com\\\",\\\"userKey\\\":\\\"00u57xs7bfNsi46jh4x6\\\",\\\"submitTime\\\":1642629306},\\\"contract_buyers_agent\\\":{\\\"buyerAgentFullName\\\":\\\"Linda Crawford\\\",\\\"buyerAgentFirmName\\\":\\\"Best in Town Brokerage \\\",\\\"buyerAgentEmail\\\":\\\"linda@bestintown.com\\\",\\\"buyerAgentPhone\\\":\\\"212-555-1234\\\"},\\\"contract_sellers_agent\\\":{\\\"sellerAgentFullName\\\":\\\"Jake Elsdon\\\",\\\"sellerAgentFirmName\\\":\\\"Acme Brokers\\\",\\\"sellerAgentEmail\\\":\\\"jake@acmebrokers.com\\\",\\\"sellerAgentPhone\\\":\\\"917-555-2345\\\"},\\\"contract_expected_close_date\\\":1644469200}\"}",
			"last_contract_date" : moment().unix()
		}


class ReportUnitStatusForm  extends Component {
	
	constructor(props) {
	    super(props);
	    this.state = { 
		   activeStep : 0,
		   errors: false
		};
		
		
		this.setActiveStep = this.setActiveStep.bind(this);
			    
	}
	disabledFields = [];
	
	componentDidMount() {
	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		const { formValues, dispatch, user } = this.props;
		this.disabledFields = [];
		if(formValues.isBuyer){
			if((!formValues.buyerAgentFullName || formValues.buyerAgentFullName == "" || formValues.buyerAgentFullName != formValues.fullName) && formValues.fullName) {
				 dispatch(change(redux_form_name, 'buyerAgentFullName', formValues.fullName)); 
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("buyerAgentFullName");
			}else if(formValues.buyerAgentFullName){
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("buyerAgentFullName");
			}
			
			if((!formValues.buyerAgentFirmName || formValues.buyerAgentFirmName == "") && formValues.company){
				 dispatch(change(redux_form_name, 'buyerAgentFirmName', formValues.company)); 
				 //this.disabledFields.push("buyerAgentFirmName");
			}
			
			if((!formValues.buyerAgentEmail || formValues.buyerAgentEmail == "" || formValues.buyerAgentEmail != formValues.email) && formValues.email){
				 dispatch(change(redux_form_name, 'buyerAgentEmail', formValues.email)); 
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("buyerAgentEmail");
			}else if(formValues.buyerAgentEmail){
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("buyerAgentEmail");
			}
			
			if((!formValues.buyerAgentPhone || formValues.buyerAgentPhone == "") && formValues.primaryPhone) {
				dispatch(change(redux_form_name, 'buyerAgentPhone', formValues.primaryPhone)); 
				//this.disabledFields.push("buyerAgentPhone");
			}
			
		}else{
			if(formValues.buyerAgentFullName && formValues.buyerAgentFullName == formValues.fullName) dispatch(change(redux_form_name, 'buyerAgentFullName', ""));
			if(formValues.buyerAgentFirmName && formValues.buyerAgentFirmName == formValues.company) dispatch(change(redux_form_name, 'buyerAgentFirmName', ""));
			if(formValues.buyerAgentEmail && formValues.buyerAgentEmail == formValues.email) dispatch(change(redux_form_name, 'buyerAgentEmail', ""));
			if(formValues.buyerAgentPhone && formValues.buyerAgentPhone == formValues.primaryPhone) dispatch(change(redux_form_name, 'buyerAgentPhone', ""));
		}
		
		if(formValues.isSeller){
			if((!formValues.sellerAgentFullName || formValues.sellerAgentFullName == "" || formValues.sellerAgentFullName != formValues.fullName) && formValues.fullName){
				 dispatch(change(redux_form_name, 'sellerAgentFullName', formValues.fullName));
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("sellerAgentFullName");
			}else if(formValues.sellerAgentFullName){
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("sellerAgentFullName");
			}
			if((!formValues.sellerAgentFirmName || formValues.sellerAgentFirmName == "") && formValues.company){
				 dispatch(change(redux_form_name, 'sellerAgentFirmName', formValues.company));
				 //this.disabledFields.push("sellerAgentFirmName");
			}
			if((!formValues.sellerAgentEmail || formValues.sellerAgentEmail == "" || formValues.sellerAgentEmail != formValues.email) && formValues.email){
				 dispatch(change(redux_form_name, 'sellerAgentEmail', formValues.email));
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("sellerAgentEmail");
			}else if(formValues.sellerAgentEmail){
				 if(!_hasPermission(user, "firm-contracts")) this.disabledFields.push("sellerAgentEmail");
			}
			if((!formValues.sellerAgentPhone || formValues.sellerAgentPhone == "") && formValues.primaryPhone){
				 dispatch(change(redux_form_name, 'sellerAgentPhone', formValues.primaryPhone));
				 //this.disabledFields.push("sellerAgentPhone");
			}
			
		}else{
			if(formValues.sellerAgentFullName && formValues.sellerAgentFullName == formValues.fullName) dispatch(change(redux_form_name, 'sellerAgentFullName', ""));
			if(formValues.sellerAgentFirmName && formValues.sellerAgentFirmName == formValues.company) dispatch(change(redux_form_name, 'sellerAgentFirmName', ""));
			if(formValues.sellerAgentEmail && formValues.sellerAgentEmail == formValues.email) dispatch(change(redux_form_name, 'sellerAgentEmail', ""));
			if(formValues.sellerAgentPhone && formValues.sellerAgentPhone == formValues.primaryPhone) dispatch(change(redux_form_name, 'sellerAgentPhone', ""));
		}
		
		
		
	}
	
	setActiveStep(step){
	  const { formValues, user} = this.props;
	  
	  let errors = [];
	  if(step > 1){

		  if(!formValues.isBuyer && !formValues.isSeller){
			  if(_hasPermission(user, "firm-contracts")){
				  errors.push("You or your firm must have represented the seller to submit a contract.")
			  }else{
				  errors.push("You must be the seller's agent to submit a contract.")
			  }
			  
		  }
		  if(formValues.isBuyer){			  
			  if((!formValues.buyerAgentFullName || !formValues.buyerAgentEmail)){
				  errors.push("Please provide buyer's agent name and email.")
			  }			  
		  }
		  if(formValues.isSeller){			  
			  if((!formValues.sellerAgentFullName || !formValues.sellerAgentEmail)){
				  errors.push("Please provide seller's agent name and email.")
			  }			  
		  }
		  
		  if(formValues.contractDate && formValues.contractDate && moment(formValues.contractDate, "M/D/YYYY").unix() > moment(formValues.closeDate, "M/D/YYYY").unix()){			  		errors.push("Contract date cannot be after the close date.")  
			  			  
		  }
	  }
	  
	  if(errors.length<=0){
		  this.setState({ activeStep : step, errors : false });
	  }else{
		  this.setState({ errors : errors });
	  }
	  
	  
  }
  
	render(){
		const { handleSubmit, reset, t, buildingRes, user, toggle, property, saved, buildingNameOrAdr, setFormValues, formValues } = this.props;
		const { activeStep, errors } = this.state;
		
		let realProperty = false;
		if(activeStep>=1){
			let updatedState = setFormValues();
			console.log(updatedState)
			if(updatedState){
				
				realProperty = {
					 "custom_fields_json" : JSON.stringify(updatedState['custom_fields']),
					 "last_contract_date" : updatedState["last_contract_date"]
				}
			}
		}

	console.log(realProperty);
		return(
		
		 <ReportContractStepper activeStep={activeStep} setActiveStep={this.setActiveStep} cancel={toggle} handleSubmit={handleSubmit} >
		  {activeStep == 0 ?
		 
		 	<div>
			 		<div className="agent-confirmation" style={{textAlign:"left",margin:"20px auto"}}>
				          
				          {_hasPermission(user, "firm-contracts") ?
					          
					           <Fragment>
							  	<h3 className="page-title">Did You or Your Firm Represent The Seller?</h3>
							  	<p className="email-confirmation__sub mb-3">If you or your firm represented the seller of <strong>{buildingNameOrAdr}, {_formatUnitNumber(property.address_2_normalized)}</strong>, report your contract and receive recognition for playing a role in the transaction. </p>
							  </Fragment>
					          :
					          <Fragment>
							  	<h3 className="page-title">Are You The Seller’s Agent?</h3>
							  	<p className="email-confirmation__sub mb-3">If you represented the seller of <strong>{buildingNameOrAdr}, {_formatUnitNumber(property.address_2_normalized)}</strong>, report your contract and receive recognition for playing a role in the transaction. </p>
							  </Fragment>
						  }
				           <div className="report_box">
				          	
				          	<ShareContractAgents property={mockProperty} />
				          	<p className="text-center light-text">Example badge for agent recognition</p>
				        
				          </div>
				          <p className="email-confirmation__sub mb-3">{`At this time, this feature is limited to seller's agents only. `} <br/> A Marketproof analyst will verify the details and notify you when your submission is approved.</p>
				        </div>
				      <hr/>
				   
            </div>
            
            : activeStep == 2 ?
            
            	<div>
			 		<div className="agent-confirmation" style={{textAlign:"left",  margin:"20px auto"}}>
				          
				          <h3 className="page-title">Review & Submit Your Contract</h3>
						  <p className="email-confirmation__sub mb-3">Once the details of your contract have been verified by a Marketproof analyst, the following badge will be displayed on the property page to recognize your role in the transaction.</p>
				          <div className="report_box">
				          	
				          	<ShareContractAgents property={realProperty} />
				          	<p className="text-center light-text">Actual badge for your role in the transaction</p>
						  	 {/*<Field name="hideContractInfo" component={renderCheckbox} label={`Don't Display Agent Badge`}  />*/}
				          </div>
				          <p className="email-confirmation__sub mb-3">You will be notified when your submission is approved. </p>
				        </div>
				      <hr/>
				   
				</div>
            :
		 
			<div style={{textAlign:"left",  margin:"20px auto"}}>
			{property.last_contract_date > 0  && !property.custom_fields_json ? 
				<Fragment>
					<h3 className="page-title">Verify Contract Information</h3>
					<div style={{margin:"20px 0", fontSize:15, border:"1px solid #780F9E"}}>
						<div className="email-confirmation__sub" style={{display:"flex",alignItems:"center"}}>
						<div style={{ background:"#780F9E", padding:20 }}><ReportIcon size={26} style={{marginTop:-3}} color={"#FFF"} /> </div>
						<div style={{ padding:"5px 15px" }}>A contract was reported to REBNY with a date of <strong>{moment.unix(property.last_contract_date).format("M/D/YYYY")}</strong>.  Please certify that you represented the seller and verify the contract information.</div></div>
					</div>
				</Fragment>
				:				
				<Fragment>
					<h3 className="page-title">Add Contract Information</h3>
					<p className="email-confirmation__sub mb-3">Please complete the following and certify that you represented the seller.</p>
				</Fragment>
			}
			<hr/>
			{errors && errors.length>0 &&
				<div style={{margin:"20px 0", padding:15, fontSize:15, background:"#F5F7FA", border:"1px solid #F53246", color:"#F53246"}}>
					<p><strong><AlertIcon size={18} /> Please correct the following errors:</strong></p>
					{errors.map(error => 
						<p>{error}</p>	
					)}
				</div>
				
			}
	        <form className="material-form" onSubmit={handleSubmit}>
	         <Row>
			  
			  <Col sm={12} md={12} lg={3} xl={3}>         	
		          <div>		           
		            <div className="material-form__label">Did you{_hasPermission(user, "firm-contracts") && " or your firm "} represent the seller?</div>
		          {/*  <Field component={renderCheckbox} label={`Buyer`} value="buyer" name="isBuyer" /> */}
		            <Field component={renderCheckbox} label={`Yes, I certify.`} value="seller" name="isSeller" />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={2} xl={2}>         	
		          <div>
		            <span className="material-form__label">Contract Date*</span>
		            <Field
		              name="contractDate"
		              component={renderDateField}
		              placeholder=""
		              validate={[required]}
		            />
		          </div>
	          </Col>
	          
	          <Col sm={12} md={12} lg={2} xl={2}>         	
		          <div>
		            <span className="material-form__label">Anticipated Close Date </span>
		            <Field
		              name="closeDate"
		              component={renderDateField}
		              placeholder=""
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}> 
		          <div>
		            <span className="material-form__label">Contract Price (Optional)</span>
		            <Field
		              name="contractPrice"
		              component={renderTextField}
		              placeholder=""
		              type="text"
		              format={contractFormatter}
		              normalize={contractParser}
		            />
		          </div>
	          </Col>
	          
	         
	          <Col sm={12} md={12} lg={12} xl={12}>
			  	<hr style={{borderColor:"#DBE0E8"}} />
	            <div style={{fontSize:16}}>
				   	<p className={"semi-bold-text"}>{`Seller's Agent`}</p>
				   	<div className="material-form__label mb-2">{`Name and contact information of the seller's agent. Leave blank if seller did not use an agent.`}</div>	
				</div>
			  </Col>
			   <Col sm={12} md={12} lg={3} xl={3}>         	
		          <div>
		            <span className="material-form__label">Agent Name{formValues.isSeller && "*"}</span>
		            <Field
		              name="sellerAgentFullName"
		              component={renderTextField}
		              placeholder="Name"
		              disabled={this.disabledFields.indexOf("sellerAgentFullName")>=0}
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}>         	
		          <div>
		            <span className="material-form__label">Brokerage Firm</span>
		            <Field
		              name="sellerAgentFirmName"
		              component={renderTextField}
		              placeholder="Name"
		              disabled={this.disabledFields.indexOf("sellerAgentFirmName")>=0}
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}> 
		          <div>
		            <span className="material-form__label">Agent Email{formValues.isSeller && "*"}</span>
		            <Field
		              name="sellerAgentEmail"
		              component={renderTextField}
		              placeholder="example@mail.com"
		              type="email"
		              validate={email}
		              disabled={this.disabledFields.indexOf("sellerAgentEmail")>=0}
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}>
	          <div>
	            <span className="material-form__label">Agent Phone</span>
	            <Field
	              name="sellerAgentPhone"
	              component={renderTextField}
	              placeholder=""
	              type="phone"
	              disabled={this.disabledFields.indexOf("sellerAgentPhone")>=0}
	            />
	          </div>
		      </Col>
		      {/*<Col sm={12} md={12} lg={12} xl={12}>         	
		          <div>		           
		            <div className="material-form__label">Has this contract been reported to the REBNY RLS or a MLS?</div>
		         
		            <Field component={renderCheckbox} label={`Yes, contract has been reported.`} value="reportedToRLS" name="isReported" />
		          </div>
	          </Col>*/}
		      <Col sm={12} md={12} lg={12} xl={12}>
			  	<hr style={{borderColor:"#DBE0E8"}} />
	            <div style={{fontSize:16}}>
	            
				   	<p className={"semi-bold-text"}>{`Buyer's Agent (Optional)`}</p>
				   	<div className="material-form__label mb-2">{`Give credit to the buyer's agent or leave blank.`}</div>	
				</div>
			  </Col>
			  <Col sm={12} md={12} lg={3} xl={3}>         	
		          <div>
		            <span className="material-form__label">Agent Name{formValues.isBuyer && "*"}</span>
		            <Field
		              name="buyerAgentFullName"
		              component={renderTextField}
		              placeholder="Name"
		              disabled={this.disabledFields.indexOf("buyerAgentFullName")>=0}
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}>         	
		          <div>
		            <span className="material-form__label">Brokerage Firm</span>
		            <Field
		              name="buyerAgentFirmName"
		              component={renderTextField}
		              placeholder="Name"
		              disabled={this.disabledFields.indexOf("buyerAgentFirmName")>=0}
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}> 
		          <div>
		            <span className="material-form__label">Agent Email{formValues.isBuyer && "*"}</span>
		            <Field
		              name="buyerAgentEmail"
		              component={renderTextField}
		              placeholder="example@mail.com"
		              type="email"
		              validate={email}
		              disabled={this.disabledFields.indexOf("buyerAgentEmail")>=0}
		            />
		          </div>
	          </Col>
	          <Col sm={12} md={12} lg={3} xl={3}>
	          <div>
	            <span className="material-form__label">Agent Phone</span>
	            <Field
	              name="buyerAgentPhone"
	              component={renderTextField}
	              placeholder=""
	              type="phone"
	              disabled={this.disabledFields.indexOf("buyerAgentPhone")>=0}
	            />
	          </div>
		      </Col>
		      
	          <Col sm={12} md={12} lg={12} xl={12}>
	          		<hr style={{borderColor:"#DBE0E8"}} /> 
		          	<div style={{fontSize:16}}>
		            	<p className={"semi-bold-text"}>{`Notes (Optional)`}</p>
		            <div className="material-form__label mb-2">{`Disclose any additional details such as discounts, concessions or commissions.`}</div>
		            <Field
		              name="message"
		              component={renderTextField}
		              placeholder=""
		              multiline
		              rows="3"
		              rowsMax="3"
		            />
		          </div>         
	          </Col>
	          <Col sm={12} md={12} lg={12} xl={12}> 
		          <div>
		            <span className="material-form__label">*Required</span>
		          </div>         
	          </Col>
	          </Row>
	          <hr/>
	          <a className="reset__link" onClick={reset}>Reset Form</a>
			  <ButtonToolbar className="modal__footer">
			  
			  	
	           {/* <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
	            <Tooltip title="Send">
			        <Button
			        	 onClick={handleSubmit}
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"		         
				         startIcon={<ReportIcon />}
				     >	Submit
				     </Button>				 
			  	</Tooltip>*/}
	         </ButtonToolbar>
	    </form>
	    </div>
	    }
	    </ReportContractStepper>
	    )
	}
};

ReportUnitStatusForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default connect(
  (state, props) => ({
    initialValues: Object.assign({ccme : true, saveme : !props.saved, fullName: props.user.firstName + ' ' + props.user.lastName, 
	   // closeDate : props.property.last_sold_date > 0 ? moment.unix(props.property.last_sold_date).format("M/D/YYYY") : null,
	    isReported: props.property.last_contract_date > 0 && !props.property.custom_fields_json ? true : null,
	    contractDate: props.property.last_contract_date > 0 && !props.property.custom_fields_json ? moment.unix(props.property.last_contract_date).format("M/D/YYYY") : moment().format("M/D/YYYY")}, props.user), 
	    
	    formValues: getFormValues(redux_form_name)(state)
  })
)(reduxForm({
  form: 'contract_form', // a unique identifier for this form
})(ReportUnitStatusForm));
