import React, { Fragment } from "react";
import {
  Container, Col, Row, ButtonToolbar,
} from 'reactstrap';

import FeatureIcon from 'mdi-react/CheckCircleOutlineIcon';


import {
	_capitalizeText,
	_chunkArray,
	_getFeaturesArr,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from './../../../../../shared/helpers/utils.js';

import { amenitiesList } from "./../../../../../shared/helpers/bp-utils.js";
import { userEditFieldsObj } from "./../../../../../shared/helpers/userEditFields.js";

export default function BuildingAmenities (props) {
	const { isHouse, buildingRes, property, small } = props;
	if(!(property && (property.building_features || property.property_features))) return null;
	const amenities = [...(property.building_features || []), ...(property.property_features || [])];
	if(!amenities.length > 2) {
	// if((!rowCount || (rowCount && highlightedAmenities.length < 2 )) && ((!otherAmenities || otherAmenities && otherAmenities.length < 2) && !showFeatures)) {
		return null;
	} else {
		return(
			<Fragment>
				
					<Container className="mobile-no-padding">
					
					
						{((amenities && amenities.length > 0)) &&
							<Fragment>		
								{/*<p>&nbsp;</p>
								<h3>Additional Amenities</h3>*/}
								<Row className="more-amenities">
									{amenities.map((item, i) => {
											return (
												<Col xl={small ? 4 : 3} lg={small ? 4 : 3} md={4} sm={6} xs={6}  className="amenity" key={`bp-amenities-small-${i}`}>
													<h5>
														<FeatureIcon /> {_capitalizeText(item.replace(/-/g, " ").replace(/_/g, " "))}
													</h5>
												</Col>
											);
										})
									}
								
								</Row>
							</Fragment>
						}
	
						<p>&nbsp;</p>
						
					</Container> 
					
			</Fragment>
		);
	}
}
