/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { CardBody, Badge,  Modal, ButtonToolbar  } from 'reactstrap';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ProIcon from 'mdi-react/AccountStarIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import MergeImages from './MergeImages';


import moment from 'moment';
import Slider from 'react-slick';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	StatusBadge,
	_getUnitFloorPlan
} from "../../../../../shared/helpers/utils";


const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

class ShareableContentItems extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      description: PropTypes.string,
      colors: PropTypes.arrayOf(PropTypes.string),
      new: PropTypes.bool,
    })),
  };

  static defaultProps = {
    items: [],
  };
  state = {
    modal: false
  }
  
  getUnitLink = (item) => {
	 return _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
  };
  
  toggle = () => {
    const { modal } = this.state;
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const { items, user } = this.props;
    const { modal } = this.state;
    const settings = {
		  dots: false,
		  infinite: false,
		  speed: 500,
		  autoplay: false,
		  swipeToSlide: true,
		  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
		  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
		  responsive: [
			{ breakpoint: 768, settings: { slidesToShow: 1 } },
		    { breakpoint: 992, settings: { slidesToShow: 2 } },
		    { breakpoint: 1200, settings: { slidesToShow: 3 } },
		    { breakpoint: 1536, settings: { slidesToShow: 4 } },
		    { breakpoint: 100000, settings: { slidesToShow: 4 } },
		  ],
	  };


    return (
      <div className="catalog-items_small catalog-items__wrap">
        <div className="catalog-items">
          <Slider {...settings} className="">
          {items.map((item, i) => (
            <CardBody className={`catalog-item ${!item.name ? 'empty__item' : ''}`} key={i} onClick={this.toggle}  >
              <div  style={{cursor:"pointer"}}>
                <div className="catalog-item__img-wrap" >
                {item.img ? 
	                <img src={item.img} />
	                :
	                <ProIcon size={200} color="#CCC" />
                }
                  	

                </div>
                {/*<<Badge color="primary">Mortgage</Badge>*/}
                {/*<StatusBadge status={item.offering_status} />*/}
                <div className="catalog-item__info">
                   <h4 className="catalog-item__price">
                   		{_capitalizeText(item.name)}                  		
                   </h4>
                   
                   <h5 className="product-card__title">
		              <span>{item.focus && _capitalizeText(item.focus)} </span>		              
		          </h5>
              
                  <p className="catalog-item__description light-text small-text">{item.description} </p>
                                 
                </div>
                </div>
              <Modal
                  isOpen={modal}
                  toggle={this.toggle}
                  modalClassName={`ltr-support`}
                  className={`modal-dialog contact__modal share__modal`}
                >
                <div className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
                  </div>
                     
   
                <div className="modal__body background_white">
                  <h4 className="contact__title"></h4>
                  <MergeImages 
                    image1Src={"https://marketproof-hosting.s3.amazonaws.com/logos/marketproof-logo-horizonal-black.png"} 
                    image2Src={"https://mcusercontent.com/b884b837c32ec731c7d2fab47/images/8bca9481-78a9-23df-e91a-6ed773863685.png"}
                  />
               
                </div>
             
                </Modal>
            </CardBody>
          ))}
           </Slider>
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  user: state.user,
}))(withRouter(ShareableContentItems));
