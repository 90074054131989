import { PubSub } from 'pubsub-js';

export const Publisher = (function () {

    function publish(event, data){	    
	    PubSub.publish(event, data);
    }
    
    return {
	    publish : publish
    }
})(); 