/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar, Nav, NavLink, NavItem, TabContent, TabPane, Container
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import LinkIcon from 'mdi-react/FileDocumentOutlineIcon';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";


import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import{
	_slugifyText,
	_privateKey
} from '../../../../shared/helpers/utils';

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
  <TextField
    className="material-form__field readonly"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    multiline={rows}
    rows={rows}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    InputProps={{
	    readOnly: true,
	}}
  />
);

const renderCheckbox = ({ input, label }) => {
	
	console.log(input.value);
	return (
		  <FormControlLabel
		      control={
		        <Checkbox
		          checked={input.value ? true : false}
		          onChange={input.onChange}
		        />
		      }
		      label={label}
		    />
   )
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const domain = 'https://newdev.marketproof.com'
const generateShareLink = ( props, activeTab ) => {		 
	
	 const { buildingRes, property, formValues } = props;
	 let building_slug =  buildingRes.key.substr(4);
	 
	 let shareBaseURL = `/report/stackedunits/`
		 
	 if(activeTab === 'stacked'){
		 shareBaseURL = `/report/stackedunits/`
	 }
	
	 const encryptedBuildingSlug = btoa(CryptoJS.AES.encrypt(building_slug, _privateKey));
	 let shareLink = `${building_slug}`;
	 if(property && property.address_2_normalized){			 
		 shareBaseURL = `/unit/`;
		 shareLink = `${building_slug}/${_slugifyText(property.address_2_normalized)}`;
	 }
	 let queryArray = {};
	 
	 
	 let queryStr = false;
	 if(formValues){
		 
		 Object.keys(formValues).filter((key) => ['ap','lk', 'theme'].indexOf(key)>-1).map(key => {
			 if(formValues[key] === true){
				 
				 if(key === 'ap'){
					 queryArray[key] = props.user.id ? props.user.id : ''; //formValues[key];
				 }else if(key === 'theme'){
					 queryArray[key] = 'elegran';
				 }else{
					 queryArray[key] = formValues[key];
				 }
				 
			 }
			 
		 })		 
		//.filter((key) => ['ap', 'addcontact', 'addsalesgallery'].indexOf(key)>-1)
	 }
	 
	 queryStr = queryString.stringify(queryArray); 
	 if(queryStr){		
		shareLink += '?' + queryStr;
	 }
	 console.log(shareLink);
	 shareLink = btoa(CryptoJS.AES.encrypt(shareLink, _privateKey));
	 
	 return shareBaseURL + shareLink;
}

const ReportForm = ( props ) => {
	
	const { handleSubmit, reset, t, buildingRes, user, toggle, property, values, listing } = props;
	const [copied, setCopied] = useState(false);
	const [activeTab, setActiveTab] = useState('stacked');		
 	const shareLink = generateShareLink( props, activeTab );
		console.log(shareLink);
	
	return(
	    <form className="material-form" onSubmit={handleSubmit}>
	    
	    {!property && (user.email.indexOf('elegran.com')>=0 || _hasPermission(user, 'stacked-report')) &&  
	    <div className="tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
                <Nav tabs>
                 

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'stacked' })}
                      onClick={() => {
                        setActiveTab('stacked');
                      }}
                    >
                      <span className="tab-title">Stacked Units Report</span>
                    </NavLink>
                  </NavItem>
                                  
                </Nav>
              </div>
        </div> 
        }
	    <Row>
	    {(buildingRes && buildingRes.building_status === 'sponsor') &&
	      <Col sm={12} md={12} lg={12} xl={12} style={{visibility:!_hasPermission(user, 'stacked-report') ? 'hidden' : 'visible'}}> 
	          <div className="checkbox__items mb-2">            
	            <Field name="theme" component={renderCheckbox} label={<span>Use Elegran Branding</span>} value="elegran" /> 
	          </div>
	          
		  </Col>
		}
	      <Col sm={12} md={12} lg={12} xl={12}> 
	          <div className="checkbox__items mb-2">            
	            <Field name="ap" component={renderCheckbox} label={<span>Include My Name and Contact (Update in <Link to={`/my-account`} className="link-text">My Account</Link>)</span>} value="info" /> 
	          </div>
	          
		  </Col>
		  {listing && listing.sale_status === 'active' &&
			  <Col sm={12} md={12} lg={12} xl={12}> 
		          <div className="checkbox__items mb-2">            
		            <Field name="lk" component={renderCheckbox} label="Include a Link to the Active Listing" value="link" /> 
		          </div>
			  </Col>
		  }   
		{/*  <Col sm={12} md={12} lg={12} xl={12}> 
		      <div className="share__link">
		            <span className="material-form__label">Report Link <a href={shareLink} target="_blank">Preview <OpenInNewIcon size={12} /></a></span>
		             {/*<Field
		              name="shareLink"
		              component={renderTextField}
		              placeholder=""
		              multiline
		              rows="2"
		              rowsMax="2"
		            />
		            <div className="share__link-container">{`${domain}${shareLink}`}</div>
		          </div> 
		    </Col>*/}
		</Row>        
			  <ButtonToolbar className="modal__footer">
	            <Tooltip title="Cancel">
			        <Button
				         onClick={toggle}
				         variant="outlined"
				         color=""
				         className=""		         
				         startIcon={null}
				     >	Cancel
				     </Button>				 
			  	</Tooltip>{' '}
	            <Tooltip title="Generate Report">
			        <Button
			        	 onClick={() => window.open(shareLink)}
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"		         
				         startIcon={<LinkIcon />}
				     >	Generate Report
				     </Button>			 
			  	</Tooltip>
	         </ButtonToolbar>
	    </form>
    )
};

ReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
const form = 'share_form';

export default connect(
  (state, props) => {
  const initalValues = Object.assign({ saveme: true, ap : true, theme: props && props.buildingRes && props.buildingRes.building_status === 'sponsor' ? true : false }, props.user);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
})(ReportForm));
