import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	SalesProgress,
	BuildingStatus
} from "../../../shared/helpers/utils";

import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';
const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
	 <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}
class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 20,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
	};

	componentDidMount() {
		
		if(this.props.architectureCompany || this.props.architectureCompany === ""){
			this.queryUnits();
		}
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(this.props.architectureCompany || this.props.architectureCompany === ""){
		  if(!_objectEqual(this.props.q, prevProps.q) ||
		  (this.props.buildingKey !== prevProps.buildingKey ||
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start)){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 this.queryUnits(reset);
		  }		 
		}
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};

	
	queryUnits = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const {architectureCompany, q, fq, fl } = this.props;	
		
		let sort = "inventory_total desc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			sort = `${this.state.orderBy} ${this.state.order}`;
			
			
		}
			
		
		const unitsFq = fq.concat([`architect:"${architectureCompany}"`, "deleted:false"]);			
		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: q,
				fq: unitsFq,
				sort: sort,
				fl,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	render(){
		const { reactTableFiltered, handleBuildingClick, isSelected, handleClick, order, orderBy, selected, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, building} = this.props;
		if(!reactTableFiltered && (this.state.start===0 && (this.state.unitListData.length===0 || this.props.fetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const data = reactTableFiltered || this.state.unitListData;
		
		
		
		return (
		<Fragment>
			<div>
			<Table className="material-table">
		        <UnitsSearchTableHead
		          numSelected={[...selected].filter(el => el[1]).length}
		          order={order}
		          orderBy={orderBy}
		          onSelectAllClick={handleSelectAllClick}
		          onRequestSort={isGrouped ? this._handleRequestUnitsSort : handleRequestSort}
		          rowCount={reactTableFiltered.length}
		          user={user}
		        />
		        <TableBody>
		          {data
		            .map((d) => {
		              const itemSelected = isSelected(d.id);
		              const floorplan = false; //_getUnitFloorPlan(d);
					  const buildingData = d;
					  let salesOffice = null;
					  if(d.sales_office_details){
					   		 try{
						   		 salesOffice = JSON.parse(d.sales_office_details);						   		
					   		 }catch(e){
						   		 //cannot parse JSON
					   		 }	   		 
				   	  }

		              return (
		                <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => handleBuildingClick(event, d)}
                          aria-checked={itemSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={itemSelected}
                          user={user}
                        >
                          <TableCell className="material-table__cell" padding="checkbox">
                            {/*<Tooltip title="Add to Cohort">
                            	<Checkbox checked={isSelected} className="material-table__checkbox" />
                            </Tooltip>*/}
                            {user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
	                            <Fragment>
	                            {d.offering_file_number && d.offering_file_number &&
							  		<Fragment>
							  			<IconButton onClick={(e) => { e.preventDefault();e.stopPropagation(); window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`)}} >
							  				<OpenIcon color={'#BFC4D1'} />
							  			</IconButton>
							  		</Fragment>
						  		}
						  		</Fragment>
					  		}
                          </TableCell>
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <span className="building_name" >{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
                            	
								 <BuildingIcons building={d} />
                            </span>
                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
                          </TableCell>
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	{d.offering_construction==='conversion' 
                          	 ?
                          	 <SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total}  color={"grey"} />
                          	 :
			                 <SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total}  />
		                    }
                          </TableCell>                         
                          <TableCell
                            className="material-table__cell light-text small-text"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{d.inventory_total > 0 ? _formatNumber(d.inventory_total) : d.total_units > 0 ? _formatNumber(d.total_units) : '--'}</span>
                          </TableCell>
                           <TableCell
                            className="material-table__cell light-text small-text"
                            size="small" 
                            align=""
                          ><span className="light-text small-text">{_capitalizeText(calcuateSalesStart(d, 'fromNow'))}</span>
                          </TableCell>                       
                          <TableCell
                            className="material-table__cell"
                            align="center"
                          ><span className="light-text small-text">{d.inventory_stats_listings_average_ppsf > 100 ? `$${_formatNumber(d.inventory_stats_listings_average_ppsf)}` : '--'}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="right"
                          ><span className="light-text small-text">{d.inventory_stats_listings_low_price > 100 ? `${_formatPrice(d.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(d.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</span>
                          </TableCell>
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          >
                          {salesOffice && salesOffice.email && 
	                          	<span className="light-text small-text">
	                          		<a href={`javascript:void(0)`} className="alert_show"
	                          		onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`mailto:${salesOffice.email}`);}}>{salesOffice.email.toLowerCase()}</a><br/>
	                          	</span>
	                      }
                          {salesOffice && salesOffice.telephone && 
	                           <span className="light-text small-text">
	                           	<a href={`javascript:void(0)`} className="alert_show"
	                           	onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`mailto:tel:${salesOffice.telephone}`);}}>{salesOffice.telephone.toLowerCase()}</a>
	                           </span>
	                      }                          
                          </TableCell>
                           {user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
	                       <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          ><span className="light-text small-text"><BuildingStatus item={d} /></span>
                          </TableCell>    
	                          
	                      }
                        </TableRow>
		              );
		            })}
		          {emptyRows > 0 && (
		            <TableRow style={{ height: 49 * emptyRows }}>
		              <TableCell colSpan={6} />
		            </TableRow>
		          )}
		        </TableBody> 
		        
              
		      </Table>
		      </div>
		     
			   {!reactTableFiltered && 
				<div className="unit__load">			   		
				    <Tooltip title="Load more units">
				        <Button
					        onClick={(e) => {this._loadMore(e)}}
					         variant="contained"
					         color="news"
					         className={`timeline__load-more`}		         
					         startIcon={<LoadMoreIcon />}
					         disabled={this.state.fetchInProgress || this.state.noMoreResults}
					     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "No More Buildings" : "Load More"}`}
					     </Button>				 
				  	</Tooltip>				
			    </div>
			    }
			</Fragment>
		);
	}
}

export default connect(state => ({
  user: state.user
}))(UnitsListTable);