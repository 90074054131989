/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/NewBoxIcon';
import Panel from './../../../../../shared/components/Panel';
import Button from '@material-ui/core/Button';

import ArrowIcon from 'mdi-react/ChevronRightIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_formatPrice,
	_formatNumber
} from "../../../../../shared/helpers/utils";
import { _hasPermission	} from "../../../../../shared/helpers/access";

import {
  Card, CardBody, Col, Progress, ButtonToolbar
} from 'reactstrap';
import { Badge } from 'reactstrap';

const TotalInventory = ( { data, user } ) => {

  //if(!data) return null;
  
  let link = _hasPermission(user, "sponsor-buildings") ? `/inventory?building_stage=all&unit_stage=sponsor&current_status=available&property_type=residential` : `/building-database/sale/sponsor`;
	
  return (	
	  <Col md={12} xl={3} lg={3} xs={12}>
	    <Card>
	        <CardBody className="dashboard__card-widget card__link">
	           {!isMobile ?
		        <Link to={link}>
		            <div className="card__title">
		              <h5 className="bold-text">{`New Development`} {!_hasPermission(user, "sponsor-buildings") && <Badge className="dashboard__link-badge" style={{width:60,border:'1px solid #ff4861', color:'#ff4861', background:'#FFF'}}><span>Upgrade</span></Badge>}
		              
		              </h5>
		            </div>
		        
		      <div className="mobile-app-widget">
		          <div className="mobile-app-widget__top-line">
		            <p className="mobile-app-widget__total-stat">{data && data.unsold ? _formatNumber(data.unsold.count) : "--"}</p>
		            <Icon className="dashboard__trend-icon" />
		          </div>
		          <div className="mobile-app-widget__title">
		            <h5>{'Sponsor Inventory'}</h5>
		          </div>
		          <p className="progress__label">
		          	<span className="small-text light-text">Listed: </span> <span style={{ color:"#9600BF" }}>{data && data.unsold ? _formatNumber(data.unsold.active.count) : "--"}</span>  <span className="small-text light-text"> | Shadow: </span><span style={{ color:"#9600BF" }}>{data && data.unsold ? _formatNumber((data.unsold.count - data.unsold.active.count)) : "--"}</span>
		          {/*	<a href="javascript:void(0)" 
		              	onClick={(e) => {e.stopPropagation();e.preventDefault();window.Intercom('startTour', 180617);}} 
		              	style={{fontSize:'11px',color:'#9600BF',textTransform:'uppercase',fontWeight:'400',float:'right',marginTop:'3px'}}>
		              	Take a Tour<ArrowIcon size={12} />
		            </a>	*/}
		          </p> 
		          {/*<div className="progress-wrap progress-wrap--small
		          progress-wrap--turquoise-gradient progress-wrap--label-top"
		          >
		            <Progress value={62}>
		            	<div style={{ position:"absolute", right:0, fontSize:"10px", bottom:"10px", color:"#4ce1b6" }}>62%</div>
		            		<p className="progress__label">8,850 <span className="small-text light-text">Unsold</span></p> 
		            </Progress>
		          </div>*/}
		       </div>
	          </Link>
			  :
			  
		        <div>
		        	<div className="panel__title">
		              <h5 className="bold-text">{`New Development`} {!_hasPermission(user, "sponsor-buildings") && <Badge className="dashboard__link-badge" style={{width:60,border:'1px solid #ff4861', color:'#ff4861', background:'#FFF'}}><span>Upgrade</span></Badge>}</h5>
		            </div>
		            <Card style={{padding:'0px 20px'}}>
					<CardBody>
		        	<div className="mobile-app-widget" style={{textAlign:'center'}}>
			          <div className="mobile-app-widget__top-line" style={{display:'block'}}>
			            <p className="mobile-app-widget__total-stat">{data && data.unsold ? _formatNumber(data.unsold.count) : "--"}</p>
			          </div>
			          <div className="mobile-app-widget__title" style={{textAlign:'center'}}>
			            <h5>{'Available Units'}</h5>
			          </div>
			          <p className="progress__label">
			          	<span className="small-text light-text">Listed: </span> <span style={{ color:"#9600BF" }}>{data && data.unsold ? _formatNumber(data.unsold.active.count) : "--"}</span>  <span className="small-text light-text">| Shadow: </span><span style={{ color:"#9600BF" }}>{data && data.unsold ? _formatNumber((data.unsold.count - data.unsold.active.count)) : "--"}</span>
			          </p>
			          <Link to={link}>
			            <Button
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					         startIcon={null}
					     >	Search New Devs
					     </Button>	
					 </Link>
			        </div>
		            </CardBody>
					</Card>
		        </div>
	          }
			</CardBody>
	    </Card>
	  </Col>
  )
};


export default withTranslation('common')(TotalInventory);
