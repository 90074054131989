/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import EditIcon from 'mdi-react/PencilIcon';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	StatusBadge,
	_getUnitFloorPlan
} from "../../../../../shared/helpers/utils";


const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

class RelatedUnitItems extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      description: PropTypes.string,
      colors: PropTypes.arrayOf(PropTypes.string),
      new: PropTypes.bool,
    })),
  };

  static defaultProps = {
    items: [],
  };
  
  getUnitLink = (item) => {
	 return _getBuildingURL(item, 'building_slugs') + '/' + _nameToUrl(item.address_2_normalized);
  };

  render() {
    const { items, user } = this.props;

    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {items.map((item, i) => (
	          
	        
            <CardBody className="catalog-item" key={i}>
              <Link className="catalog-item__link" to={`${this.getUnitLink(item)}`}>
                {/*item.new ? <img className="catalog-item__label" src={New} alt="new" /> : ''}
                {item.sale ? <img className="catalog-item__label" src={Sale} alt="sale" /> : ''*/}
               
                <div className="catalog-item__img-wrap">
                  {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
                  {_getUnitFloorPlan(item) ?
	                <img src={_getUnitFloorPlan(item)[0].src} / >
	                :
				  	<FloorPlanIcon size={200} color="#CCC" />
				  }
                </div>
                <StatusBadge status={item.offering_status} />
                <div className="catalog-item__info">
                   <h4 className="catalog-item__price">${item.price ? _formatNumber(item.price) : _formatNumber(item.offering_price)}</h4>
                    {user && ['seller', 'enterprise'].indexOf(user.role) > -1 &&
	                    <div style={{ position:'absolute', right:10, top:-10 }}>
		              	  <Tooltip title="Update Unit Details">	            		
			              	<IconButton	
			              		className="material-table__toolbar-button"
			              		onClick={(e) => {e.stopPropagation()}}
			              	>
							  <EditIcon color="#ddd" /> 
						  	</IconButton>
						  </Tooltip>
						  </div>
	              	  
              	   }
                   <h5 className="product-card__title">
		              	{item.offering_bedrooms > -1 ? item.offering_bedrooms === 0 ? 'Studio' : item.offering_bedrooms : '--'} 
		              	<span className="sub-text">{item.offering_bedrooms === 0 ? '' : item.offering_bedrooms === 1 ? 'Bed' : 'Beds'}</span>
		              	<span className="small-divider">|</span>
		              	{item.offering_baths}
		              	<span className="sub-text">{item.offering_baths === 1 ? 'Bath' : 'Baths'}</span>
		              	<span className="small-divider">|</span>
		              	{_formatNumber(item.offering_sq_ft)}
		              	<span className="sub-text">Sq Ft</span>
		              	{/*item.exterior_sq_ft ?
			              	<React.Fragment>
			              		<span className="small-divider">|</span>
						  		{_formatNumber(item.exterior_sq_ft)}
						  		<span className="sub-text">Exterior Sq Ft</span>
			              	</React.Fragment>
			              	: null
			            */}
		          </h5>
                  <h5 className="catalog-item__title">
                  <span>
                  		{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address))}
                  </span>
                  <br/>
                  <span>
                  	{item.address_2_normalized.toUpperCase().replace("APT", "Apt")}
                  </span>
                  <br/>
                  <span className="light-text small-text" style={{color:"#858AA1"}}>
                  	{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
                  </span>
                  </h5>
                  <p className="catalog-item__description"></p>
                 
                  
                  {/*item.colors.map((c, index) => (
                    <span
                      key={index}
                      className="catalog-item__color"
                      style={{ background: c }}
                    />
                  ))*/}
                </div>
              </Link>
            </CardBody>
          ))}
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  user: state.user,
}))(withRouter(RelatedUnitItems));
