import React, { PureComponent, Fragment } from 'react';
import { Progress, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';
import { getDefaultChartColors } from '../../../../../../shared/helpers';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from '../../../../../../shared/helpers/utils';


	
class UnitBreakdown extends PureComponent {
  

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }
 
  _generateUnitMixBreakdown = (mixData) => {
	 if(!mixData || !mixData.unit_mix || !mixData.unit_mix.buckets){
		  return [];
	  }
	 const total = mixData.unit_mix.buckets.map(bucket => bucket.count).reduce((accumulator, current) => { return accumulator + current}, 0);
	 let other = 0;
	 let data = mixData.unit_mix.buckets.sort((a, b) =>  a.val - b.val).filter(bucket => bucket.val <=6).map((bucket, i) => {
		 const stat = bucket.non_zero_price ? bucket.non_zero_price : false;
		 return {name: bucket.val > 0 ? `${bucket.val} Bed` : `Studio`,
			 	 count:  bucket.count,
			 	 size: bucket.avg_unit_size,
			 	 price: stat.avg_ppsf,
			 	 carrying_cost: stat.avg_monthly_carrying_charges,
			 	 avg_fees: stat.avg_fees,
			 	 min_price: stat.min_price,
			 	 max_price: stat.max_price,
			 	 color: getDefaultChartColors[i],
			 	 search : bucket.val == 0 ? 'studio' :  bucket.val > 3 ? '4-or-more' :  bucket.val+'-bedroom'
	}
	 });
	 	 
	 return data;
	  
  }
  
  handleClick(e,unit) {
  	
  	const { history, location } = this.props; 
  	const base_url = location.pathname.replace('analysis', 'units');	
  	history.push(`${base_url}?offering_bedrooms=${unit.search}`);

  }

  
  render() {
	const { t, mixData } = this.props;
    if(!mixData){
	    return null;
    }
    const data = this._generateUnitMixBreakdown(mixData);

	return (
		  <Panel lg={7} xl={7} md={7} sm={7}  xs={7} title={`Unit Breakdown`}>
		    <Table responsive striped className="table--bordered dashboard__audience-table">
		      <thead>
		        <tr>
		          <th>Unit Type</th>
		          <th>Count</th>
		          <th>Avg Size</th>
		          <th>Avg Asking PPSF</th>
		          <th>Avg CC</th>
		          <th>Range</th>
		        </tr>
		      </thead>
		      <tbody>
		        {data.map(unit => 
		        <tr onClick={(e) => this.handleClick(e, unit)} className="tr__link">
		          <td><span style={{ backgroundColor: unit.color, width:"10px", height:"10px", display:"inline-block" }} /> {unit.name}</td>
		          <td>{unit.count}</td>
		          <td>{unit.size > 0 ? _formatNumber(unit.size) + ' sq ft' : '--'}</td>
		          <td>{unit.price && unit.price > 0 ? `$${_formatNumber(unit.price)}` : '--'}</td>
		          <td>{unit.avg_fees && unit.avg_fees>0 ? <Fragment>${_formatNumber(unit.avg_fees)}<span className="small-text">/mo</span></Fragment> : '--'}</td>
		          <td>
		            {unit.min_price && unit.min_price > 0 ? `${_formatPrice(unit.min_price)} - ${_formatPrice(unit.max_price)}` : '--'}
		          </td>
		        </tr>
		        )}
		      </tbody>
		    </Table>
		  </Panel>
	  )
	  
  }
}

export default UnitBreakdown;
