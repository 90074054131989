import {
  SET_PLAN,
  CLEAR_PLAN,
} from '../actions/planActions';

const initialState = {
  id : null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PLAN:
      localStorage && localStorage.setItem('mpnd-plan', action.plan);
      return { id: action.plan };
    case CLEAR_PLAN:
      return { id: null };
    default:
      return state;
  }
}
