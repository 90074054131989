import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';

import UnitsSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';
import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';


import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import queryString from "query-string"

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	getCohortQuery
} from "../../../shared/helpers/utils";

import { 
	_buildingAdminFilters,
	_buildingStageFilter	
} from "../../../shared/helpers/filters";

let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';
const lsPre = "LN_";
const subFilterKey = 'subFilters';
const defaultSubFilter = {offering_verified_status:["not-abandoned"]};
const buildingStagesKey = 'buildingStages';
const defaultStage = ["sponsor"];

class UnitsSearchTable extends PureComponent {
  
  state = {
    order: syncGenericFilter('order', lsPre, 'order', "desc"),
    orderBy:  syncGenericFilter('order_by', lsPre, 'orderBy', "count"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 100,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _buildingAdminFilters, subFilterKey, lsPre, defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, lsPre, defaultStage)
  };
  
  defaultQ = "*:*";
  q='';
  fq = [		
		"offering_verified_status:* AND -offering_verified_status:\"\" AND -offering_verified_status:\"excluded\"",
		"deleted:false"
	];
	
  fl = buildingFL;
  _unitSubFilter = _buildingAdminFilters.filter(filter => !(this.props.buildingView && filter.building_hide));
  componentDidMount() {
	 console.log("componentDidMount", this.state)
  	 this._getInventoryUnits();
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	    let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);

	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange || 
			subFilterChanged ||
			buildingStageChange
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)		
			  this._getInventoryUnits(resetPage);
		}

	  
  }
  
  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'key');
	 
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _getInventoryUnits = async (resetPage) => {
	  
	  	const { user, searchTags } = this.props;
		this.q = this.defaultQ;
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, groupedData:false, tableData:false, reactTableFiltered:false }));
		}
		const { buildingStages } = this.state;
		
		let locQuery = _tranformTagsQuery(searchTags);

		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}
		
		let isActive = false;

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._unitSubFilter.filter(filter => filter.search_key === key);
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else{
						
						const qVal = theFilter && theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
									
						if(qVal!==null){
							this.q += this.q.length > 0 ? " AND " : "";
							
							if(key === 'offering_verified_status' && ['active', '("offering plan required")', '("offering plan acquired")', '("condo declaration vetted")'].indexOf(qVal)>=0){
								isActive = true;
							}
							this.q += `(${key}:${qVal})`;
						}
					}
				}
			});
		}
		if(buildingStages && buildingStages.length>0){
			if(buildingStages.indexOf("all")<0){
				this.q += this.q.length > 0 ? " AND " : "";
				this.q += `building_status:("${buildingStages.join("\" OR \"")}")`;

			}
			
		}
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._unitSubFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this._unitSubFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						dateReplaceKeys = [...dateReplaceKeys, ...dateArrayFilter[0].search_key];					
						if(dateQ){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._unitSubFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		if(dateQ){
			dateReplaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				dateQ = dateQ.replace(regex, '*')
			
			})
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += '(';
			this.q += dateQ;
			this.q += ')';			
		}
		/****Price& Date query***/
		setURLFromState(this.props, this.state);
		
		
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `lenders:(*${text}* OR *${textCaps}*) OR (name:(*${text}* OR *${textCaps}*) OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		let sort = this.state.groupedView ? "count desc" : "full_street_address asc, floor asc, address_2_normalized asc";
		if(this.state.orderBy && this.state.orderBy.length > 0){			
				sort = `${this.state.orderBy} ${this.state.order}`;			
		}
		
		this.fq.push(getCohortQuery(this.props.user));
		
		
/*
		if(isActive){			
			var index = this.fq.indexOf("offering_accepted_date:[NOW-10YEARS TO NOW]");
			if (index !== -1) this.fq.splice(index, 1);
		}else{
			this.fq.push("offering_accepted_date:[NOW-10YEARS TO NOW]");
		}
*/
		
		if(!(user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0)){
			this.fq.push("-lenders:\"\" AND -lenders:mers AND -lenders:\"mortgage electronic registration systems inc\"");
		}
		
				


		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: this.q,
				fq: this.fq,
				sort: !this.state.groupedView ? sort : null,
				fl: this.fl,
				rows:this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				wt: "json",
				
/*
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"group.field":"building",
				"group.sort":"offering_price asc",
				"group.ngroups": true,
				"group":this.state.groupedView,	
*/			
				
			}
		};
		
		if(this.state.groupedView){
			axiosData.query.rows = 0;
			axiosData.query.start = 0;
			axiosData.query["json.facet"] = {
					"company_count" : "unique(lenders)",
					"by_lenders": {
						"type": "terms",
						"field": "lenders",
// 						"prefix" : searchTags && searchTags[0] ? searchTags[0].data.replace(/ /g, "\\ ") : '',
						"sort" : sort,
						"offset": resetPage ? 0 : this.state.start,
						"limit": this.state.rowsPerPage,
						"facet" : {
							"inventory_total": "sum(inventory_total)",
							"inventory_unsold": "sum(inventory_unsold)",
							"inventory_stats_listings_average_ppsf" : "avg(inventory_stats_listings_average_ppsf)",
							"non_zero_price": {
								"type": "query",
								"q": "inventory_stats_listings_average_ppsf:[100 TO *]",
								"facet": {
									"inventory_stats_listings_average_ppsf" : "avg(inventory_stats_listings_average_ppsf)"
								}
							}
						}
					}
					
				}
			
		}

	
		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});
		if(this.state.groupedView){	
			let totalCount = response.numFound;
			
			if(searchTags && searchTags.filter(item => item.type === 'lenders').length > 0){
				const vals = searchTags.filter(item => item.type === 'lenders').map(item => item.data.toLowerCase());
				
				response.facets.by_lenders.buckets = response.facets.by_lenders.buckets.filter(item => {
					return vals.indexOf(item.val.toLowerCase()) >= 0;
				})
				
				totalCount = 0;
				response.facets.by_lenders.buckets.map(item => {totalCount += item.count})
				
				response.facets.company_count = response.facets.by_lenders.buckets.length;
			}	
			
			
			
			this.setState(prevState => ({
				fetchInProgress: false,
				groupedData: response.facets.by_lenders ?  response.facets.by_lenders.buckets : [],
				groupedCount: response.facets.company_count ? response.facets.company_count : 0, //response.building.ngroups,	
				totalCount: totalCount,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page
			}));
		}else{
			let formattedData = response && response.docs
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
			
			let docs = formattedData && formattedData.map((doc, i) => {
				let item = Object.assign({}, doc);			
				return item;
			});
	
			this.setState(prevState => ({
				fetchInProgress: false,
				tableData:  docs,
				reactTableFiltered:  docs,	
				totalCount: response.numFound,
				groupedCount: false,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page
			}));
		}
		
		
			
	};
	
	_getInventoryBuildings = async (_matchedBuildingIds, callback) => {
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"lenders:("+_matchedBuildingIds+")",
			"deleted:false",			
		];

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				sort: "inventory_unsold desc",
				fl: buildingFL,
				wt: "json",
				rows: 999,
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof callback === "function") {
					let formattedRes = callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	
	_formatStats = (response, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(this.lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		
		if(newStages.length === 0 ) newStages = ['all'];
		
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages : defaultStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}
	_resetActive = () => {

		let	isDefault = this.props.searchTags.length===0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultStage, this.state.buildingStages);

	    return !isDefault;
		
	}
	
	_toggleView = () => {
		
		if(this.state.groupedView){
			this.setState( { groupedView : false, orderBy : "", order : "" } )
		}else{
			this.setState( { groupedView : true, orderBy : "count", order : "desc" } )
		}
		
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, searchText
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;
   

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
           {/*<div className="card__title">
              <h5 className="bold-text">Select and Analyze</h5>
            </div>*/}
            <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	setSubFilters={this._setSubFilters}	
            	resetSubFilter={this._resetSubFilter}
            	_filters={(user && user.role.indexOf('admin')>=0) ?  this._unitSubFilter : this._unitSubFilter.filter(item => !item.admin_only)}      
            	{...this.props}  
            	groupedView  
            	user={user} 
            	buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	setSubFilter={this._setSubFilter}	
            	placeHolder={'Lender, Neighborhood or Borough'}
            	buildingAttributes={[{buildingAttribute:'lenders', buildingAttributeTitle: 'LENDERS'}]}
            	resetActive={this._resetActive()}
             />
            
            <div className="material-table__header-info">
            	<Row>
            		<Col md={12} lg={12}>
            			<span className="bold-text">{totalCount > 0 ? _formatNumber(totalCount) : '--'}</span>{` `}Buildings
						{groupedCount ? <Fragment> across <span className="bold-text">{_formatNumber(groupedCount)}</span> Lenders </Fragment> : ''}   
					</Col>
					
					{/*!this.props.buildingView &&
					<Col md={6} lg={6} style={{ textAlign:"right" }}>
		            	<span>
						  
						  <BuildingIcon color="#DBE0E8" />
				          <Tooltip title={`${this.state.groupedView ? "Expand to Unit View" : "Condense to Building View"}`}>
					        <PurpleSwitch 
					            checked={!this.state.groupedView}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>					      	
					      <ListIcon color="#DBE0E8" />				       
					    </span>
			    	</Col>
			    	*/}
            	</Row>
            </div>
            
            <div className="material-table__wrap">
              
              {fetchInProgress && this.state.groupedView && <LinearLoading />}
              {this.state.groupedView && groupedData && 
	              
	           <div className="material-table expansion__table">	
	           		
	           	   <Container>
				        <Row className="expansion__summary__header">
							<Col lg={2} xs={12}>	                          
							  	<TableSortLabel
					                active={orderBy === "count"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "count")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Buildings
					              </TableSortLabel>
							</Col>
							 <Col lg={5} className="">	 
	                        	<TableSortLabel
					                active={orderBy === "index"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "index")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Lender
					              </TableSortLabel>                         
							 	
							</Col>	
							<Col lg={2} xs={12}>	                          
							  	<TableSortLabel
					                active={orderBy === "inventory_total"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "inventory_total")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Total Units
					              </TableSortLabel>
							</Col>
							<Col lg={2} xs={12} className="no-padding">	                          
							  	<TableSortLabel
					                active={orderBy === "inventory_unsold"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "inventory_unsold")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Unsold Units
					              </TableSortLabel>
							</Col>	
							
							 <Col lg={1} xs={12} className="no-padding">	                          
							  	<TableSortLabel
					                active={orderBy === "inventory_stats_listings_average_ppsf"}
					                direction={order}
					                onClick={event => this.handleRequestSort(event, "inventory_stats_listings_average_ppsf")}
					                className="material-table__sort-label"
					                dir="ltr"
					              >
					                Avg PPSF
					              </TableSortLabel>
							</Col>                       
	                       
							
	                    </Row>
	                </Container>	 
	              {groupedData.length ===0 &&		              
		            <Container>
				        <Row className="no__data">
							<Col lg={12} xs={12} className="no__data-text">			                          
							  	No data found for your search.
							</Col>	
						</Row>
					</Container>
	              }           
	              {groupedData.map((d) => {
		                const item = d
		                const numFound = item.count;
		                const isSelected = this.isSelected(item.val);
		                const building = item.building;
			            return(  			            
			              <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
					        <ExpansionPanelSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-label="Expand"
					          aria-controls=""
					          id={d.groupValue}
					        >
					        <Container>
						        <Row className="expansion__summary">
									<Col lg={2} xs={12} >			                          
									  	<Badge color="matching" style={{ width:110 }}><span className="bold-text">{_formatNumber(numFound)}</span> Buildings</Badge>
									</Col>	
									<Col lg={5} xs={12} >			                          
									  <div className="expansion__header">									  									  	
									  		{item.val ? _capitalizeText(item.val) : 'Not Available'}									  
									  </div>				          									  
									</Col>
									<Col lg={2} xs={12} >			                          
									  	<span className="light-text small-text">{_formatNumber(item.inventory_total)} Units</span>
									</Col>	
									<Col lg={2} xs={12}  className="no-padding">			                          
									  	<SalesProgress progress={parseFloat((item.inventory_unsold /item.inventory_total)*100).toFixed(0)} inventory_unsold={item.inventory_unsold > 0 ?_formatNumber(item.inventory_unsold) : 0} inventory_total={item.inventory_unsold > 0 ? _formatNumber(item.inventory_total) : 0} />  
									</Col>	
									
									<Col lg={1} xs={12} >	
										 <span className={"mobile__show light-text small-text"}>Price / Sq Ft:</span>		                          
									  	 <span className="light-text small-text">{item.non_zero_price.inventory_stats_listings_average_ppsf>0 ? '$'+_formatNumber(item.non_zero_price.inventory_stats_listings_average_ppsf) : '--'}</span>
									</Col> 
									                     
			                       		                        			                        	
		                        </Row>
		                    </Container>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>				        
					        	<UnitsListTable 
				              		isSelected={this.isSelected}
				              		handleBuildingClick={this.handleBuildingClick}
				              		handleClick={this.handleClick}
				              		handleRequestSort={this.handleRequestSort}
				              		handleSelectAllClick={this.handleSelectAllClick}
				              		{...this.state} 
				              		{...this.props} 
				              		reactTableFiltered={false}	
				              		isGrouped
				              		q={this.q}
				              		fq={this.fq}
				              		fl={this.fl}	
				              		lendingCompany={item.val}		              		
				              />
					        </ExpansionPanelDetails>
					     </ExpansionPanel>
					    )
					 })
	             }  
	            </div>
	          }
            
            
              {!this.state.groupedView && 	              
	              <UnitsListTable 
	              		isSelected={this.isSelected}
	              		handleBuildingClick={this.handleBuildingClick}
	              		handleClick={this.handleClick}
	              		handleRequestSort={this.handleRequestSort}
	              		handleSelectAllClick={this.handleSelectAllClick}
	              		{...this.state} 
	              		{...this.props} 
	              		
	              />      
              }
            </div>
            {paginationCount ? <TablePagination
              component="div"
              className="material-table__pagination"
              count={paginationCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            /> : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : [],
  user: state.user
}))(withRouter(UnitsSearchTable));