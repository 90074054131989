/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from 'reactstrap';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


class TotalProducts extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeIndex: 0,
    };
  }

  handleClick = (item) => {
	const { data } = this.props;	

    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };

  render() {
	const { data } = this.props;	
	if(!data) return null;
    const { activeIndex } = this.state;
    const activeItem = data[activeIndex];
    const { t } = this.props;

    return (
      <Col md={12} xl={3} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget">
            <Link to={`/inventory?offering_status=active`}>
            <div className="card__title">
              <h5 className="bold-text">{`Listed`} <span className="small-text light-text">(30 Days)</span></h5>
            </div>
            </Link>
            <div className="dashboard__total">
             {data[0].count >= data[1].count ? 
              	<TrendingUpIcon className="dashboard__trend-icon trend-up" />
              :
              	<TrendingDownIcon className="dashboard__trend-icon trend-down" />
             }	
              <p className="dashboard__total-stat">
                {activeItem.count}
                <div className="small-text light-text">Units</div>
              </p>
              <div className="dashboard__chart-container">
                <ResponsiveContainer height={70}>
                  <BarChart data={data}>
                    <Bar dataKey="count" onClick={this.handleClick}>
                      {
                        data.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? '#4ce1b6' : '#bdefe0'}
                            key={`cell-${index}`}
                          />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withTranslation('common')(TotalProducts);
