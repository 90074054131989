import React, { useState, Fragment } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Badge,  Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';

import HeartIcon from 'mdi-react/HeartIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import { Link } from 'react-router-dom';
import images from './imgs';
import ProfileTabs from './ProfileTabs';
import ColorSelect from './ColorSelect';
import classnames from 'classnames';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import CryptoJS from 'crypto-js';
import moment from 'moment';

import StackedUnits from './StackedUnits';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_formatBath,
	_getBuildingURL,
	_nameToUrl,
	_formatUnitNumber,
	_privateKey,
	_slugifyText
} from '../../../../shared/helpers/utils';



const InventoryStack = (props) => {
  const [color, setColor] = useState('white');
  const [activeTab, setActiveTab] = useState('status');
  const { unitsRes, buildingRes, statsRes } = props;   
  
  return (
    <Col md={12} lg={12}>
    	<div className="tabs tabs--bordered-bottom">
    	{['sponsor', 'pipeline'].indexOf(buildingRes.building_status)>=0 ?    	
            <div className="tabs__wrap">
                <Nav tabs>
                   <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'status' })}
                      onClick={() => {
                        setActiveTab('status');
                      }}
                    >
                      <span className="tab-title">Status</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'pricing' })}
                      onClick={() => {
                        setActiveTab('pricing');
                      }}
                    >
                      <span className="tab-title">Asking PPSF</span>
                    </NavLink>
                  </NavItem>
                  
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'discount' })}
                      onClick={() => {
                        setActiveTab('discount');
                      }}
                    >
                      <span className="tab-title">Discounts</span>
                    </NavLink>
                  </NavItem>
                 
                </Nav>
              </div>
              :
              <div className="tabs__wrap">
                <Nav tabs>
                   <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'status' })}
                      onClick={() => {
                        setActiveTab('status');
                      }}
                    >
                      <span className="tab-title">Status</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'sold' })}
                      onClick={() => {
                        setActiveTab('sold');
                      }}
                    >
                      <span className="tab-title">Sold Price</span>
                    </NavLink>
                  </NavItem>
                  
                 {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'ownership' })}
                      onClick={() => {
                        setActiveTab('ownership');
                      }}
                    >
                      <span className="tab-title">Ownership Age</span>
                    </NavLink>
                  </NavItem> */}
                 
                </Nav>
              </div>
            }
        </div>
        <StackedUnits {...props} activeTab={activeTab} />
    </Col>
  );
};

export default InventoryStack;
