import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/GasCylinderIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
	_formatBigNumber,
	_formatNumberDecimal
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (lastScore) => {	
	
	
	
	return [{ value: 100-(lastScore && lastScore.total_ghg_emissions_intensity_kgco2e_ft ? lastScore.total_ghg_emissions_intensity_kgco2e_ft*10 : 0),fill: getDefaultChartColors[7] },
				  { value: (lastScore && lastScore.total_ghg_emissions_intensity_kgco2e_ft ? lastScore.total_ghg_emissions_intensity_kgco2e_ft*10 : 0),  fill: '#DBE0E8' }];
}

const CardPercent = ( { link, data, lastScore } ) => {
	
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10 }}  >
			<div className="card__title">
			  <div className="card__title">
				  <h5 className="bold-text card__title-center">{`Annual Carbon Emissions`}
					  <ToolTip 
							title={`LL97 mandates that buildings >25,000 SF in NYC must meet emissions intensity limits (in kg CO2e/SF) by building type starting in 2024.  The threshold for residential multi-family for 2024-2029 is 6.75 kg CO2e/SF/year and 2030-2034 is 4.07 kg CO2e/SF/year.`} 
						/>	          
				  </h5>
				</div>
			</div>
			<div className="dashboard__health-chart"> 
			  <ResponsiveContainer height={180}>
				<PieChart>
				  <Pie data={data} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	
				</PieChart>
			  </ResponsiveContainer>
			  <div className="dashboard__health-chart-info" style={{marginTop:-15}}>
				<TagOutlineIcon style={{ fill: getDefaultChartColors[7] }} />
				<p className="dashboard__health-chart-number">{lastScore && lastScore.total_ghg_emissions_intensity_kgco2e_ft ? _formatNumberDecimal(lastScore.total_ghg_emissions_intensity_kgco2e_ft, 1) : '--'}</p>
				<p className="dashboard__health-chart-units">kg CO2e / SF</p>
				 
			  </div>
			  <p className="dashboard__goal">Latest avail year {lastScore && lastScore.year_ending ? lastScore.year_ending : "--"} </p>
			</div>
		</CardBody>);
};

const CarbonEmissionsCard = (props) => {
	const { dataRes, col, translateScore } = props;
	const lastScore = dataRes.docs.filter(item => item.total_ghg_emissions_intensity_kgco2e_ft).slice(-1).pop();
	
	const data = calculateBarData(lastScore);
	
	
	return (
			<Card>
				<CardPercent data={data} lastScore={lastScore} />
				
			</Card>
	)
};



export default CarbonEmissionsCard;
