import React, { Fragment } from "react";
import { Card, CardBody, Col, Progress } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { Grid } from "@material-ui/core";
import FeatureIcon from "mdi-react/CheckCircleOutlineIcon";
import NoFeatureIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";
import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _getCrossStreet,
  _isResidential,
  _isHouse,
  _formatNum,
  _getServiceLevel,
  _validateLink,
  _showFeatures,
  _ordinalSuffix,
  _titleCase,
  _formatDate,
  _formatOwnerName,
} from "../../../shared/helpers/utils";

const Transfer = ({ trxnRes }) => {
  return (
    
      <Card>
        <CardBody className="no-border no-padding">
          <Grid className="profile-card__info" container>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <div className="product-summary__info project-product-summary_info">
                <div>
                  {trxnRes && trxnRes.close_date_formatted && (
                    <p
                      className={`${
                        trxnRes.close_date_formatted ? "has-info" : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Transfer Date:
                      <span className="project_details-text_style">
                        <Fragment>
                          {trxnRes && trxnRes.close_date_formatted
                            ? moment(trxnRes.close_date_formatted).format(
                                "MM/DD/YYYY"
                              )
                            : ""}{" "}
                          <span className="small-text">
                            (
                            {trxnRes && trxnRes.close_date_formatted
                              ? moment(trxnRes.close_date_formatted).fromNow()
                              : ""}
                            )
                          </span>
                        </Fragment>
                      </span>
                    </p>
                  )}

                  <p
                    className={`${
                      trxnRes && trxnRes.property_type ? "has-info" : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Parcel Type:
                    <span className="project_details-text_style">
                      <Fragment>
                        {trxnRes &&
                        trxnRes.property_type &&
                        trxnRes.property_type != "pa"
                          ? _capitalizeText(trxnRes.property_type_desc)
                          : "--"}
                      </Fragment>
                    </span>
                  </p>

                  <p
                    className={`${
                      trxnRes && trxnRes.owner ? "has-info" : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Buyer:
                    <span className="project_details-text_style">
                      <Fragment>
                        {trxnRes && trxnRes.owner ? (
                          <>
                            {_formatOwnerName(trxnRes.owner.split(/,(?=\S)/))}
                            {_formatOwnerName(
                              trxnRes.owner.split(/,(?=\S)/)
                            ).indexOf("LLC") >= 0 && (
                              <span className="small-text">
                                {" "}
                                (Owner Entity)
                              </span>
                            )}
                          </>
                        ) : (
                          "--"
                        )}
                      </Fragment>
                    </span>
                  </p>

                  <p
                    className={`${
                      trxnRes && trxnRes.seller ? "has-info" : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Seller:
                    <span className="project_details-text_style">
                      <Fragment>
                        {trxnRes && trxnRes.seller
                          ? _formatOwnerName(trxnRes.seller, true)
                          : "--"}
                      </Fragment>
                    </span>
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <div className="product-summary__info project-product-summary_info">
                <div>
                  <p
                    className={`${
                      trxnRes && trxnRes.price ? "has-info" : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Price:
                    <span className="project_details-text_style">
                      <Fragment>
                        {trxnRes && trxnRes.price > 0
                          ? "$" + _formatNumber(trxnRes.price)
                          : "$0 (Non-market)"}

                        {trxnRes && trxnRes.parcels_count > 1 && (
                          <span className="small-text">
                            {" "}
                            ({trxnRes.parcels_count} Parcels)
                          </span>
                        )}
                      </Fragment>
                    </span>
                  </p>

                  <p
                    className={`${
                      trxnRes && trxnRes.percent_financed
                        ? "has-info"
                        : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Financing:
                    <span className="project_details-text_style">
                      <Fragment>
                        <span className="small-text">
                          {trxnRes &&
                          trxnRes.percent_financed > 0 &&
                          trxnRes.percent_financed <= 100
                            ? "$" +
                              _formatNumber(trxnRes.financed_amt) +
                              " (" +
                              _formatNumber(trxnRes.percent_financed) +
                              "%)"
                            : trxnRes &&
                              trxnRes.percent_financed == -1 &&
                              trxnRes &&
                              trxnRes.financed_amt > 100
                            ? "$" + _formatNumber(trxnRes.financed_amt)
                            : "--"}
                        </span>
                        {trxnRes &&
                          trxnRes.percent_financed > 0 &&
                          trxnRes.percent_financed <= 100 && (
                            <Progress value={trxnRes.percent_financed} />
                          )}
                      </Fragment>
                    </span>
                  </p>

                  <p
                    className={`${
                      trxnRes && trxnRes.lender ? "has-info" : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Lender:
                    <span className="project_details-text_style">
                      <Fragment>
                        {trxnRes && trxnRes.lender
                          ? _formatOwnerName(trxnRes.lender, true)
                          : "--"}
                      </Fragment>
                    </span>
                  </p>

                  <p
                    className={`${
                      trxnRes && trxnRes.recorded_datetime
                        ? "has-info"
                        : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    Recorded Date:
                    <span className="project_details-text_style">
                      <Fragment>
                        {trxnRes && trxnRes.recorded_datetime
                          ? moment(trxnRes.recorded_datetime).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </Fragment>
                    </span>
                  </p>

                  <p
                    className={`${
                      trxnRes &&
                      trxnRes.sales_document_ids &&
                      trxnRes.sales_document_ids[0]
                        ? "has-info"
                        : "no-info"
                    }`}
                  >
                    <FeatureIcon className="has-info" />
                    <NoFeatureIcon className="no-info" />
                    ACRIS Document:
                    <span className="project_details-text_style">
                      <Fragment>
                        <Link
                          to={{
                            pathname: `https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id=${
                              trxnRes &&
                              trxnRes.sales_document_ids &&
                              trxnRes.sales_document_ids[0]
                            }`,
                          }}
                          target="_blank"
                          className="fa fa-external-link"
                        >
                          <span className="small-text">
                            {trxnRes &&
                              trxnRes.sales_document_ids &&
                              trxnRes.sales_document_ids[0]}
                          </span>{" "}
                          <OpenInNewIcon size={12} style={{ marginTop: -2 }} />
                        </Link>
                      </Fragment>
                    </span>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
  );
};

export default Transfer;
