import React, { PureComponent, Fragment } from 'react';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ShareIcon from 'mdi-react/AccountPlusOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles
} from "@material-ui/core";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_getCrossStreet
} from '../../../../../shared/helpers/utils';


const useStyles = makeStyles((theme) => ({
  root: {
	width: "100%",
	marginTop: theme.spacing(3)
  },
  head: {
	backgroundColor: "#fff",
	minWidth: "50px"
  },
  tableContainer: {
	maxHeight: "none"
  },
  cell: {
	minWidth: "160px",
	maxWidth: "160px"
  },
  freezCell: {
	  minWidth: "100px",
	  fontWeight:500,
	  textAlign:"right",
	  padding:"5px 0",
	  float:"right"
	}
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
	backgroundColor: theme.palette.common.black,
	color: theme.palette.common.white,
	left: 0,
	position: "sticky",
	zIndex: 99
  },
  body: {
	backgroundColor: "#ddd",
	minWidth: "100px",
	left: 0,
	position: "sticky",
	zIndex: 98,
	fontWeight:500,
	width: "150px"
  }
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
	backgroundColor: theme.palette.common.black,
	color: theme.palette.common.white
  },
  body: {
	fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
	"&:nth-of-type(odd)": {
	  backgroundColor: theme.palette.action.hover
	}
  }
}))(TableRow);

const ellimanStatus = (buildingRes) => {
	let status = "In Sales";
	if(buildingRes.building_status == "resale" && buildingRes.inventory_resale > 0){
		status = "Resales";
	}else if(buildingRes.building_status == "resale" && buildingRes.inventory_resale == 0){
		status = "Sold Out";
	}else if(buildingRes.first_closing_date && parseInt(moment(buildingRes.first_closing_date).format('YYYY')) > 1990 && buildingRes.inventory_resale > 0){
		status = "Closings/Resales";
	}else if(buildingRes.first_closing_date && parseInt(moment(buildingRes.first_closing_date).format('YYYY')) > 1990){
		status = "Closings";
	}
	
	return status;
}

export const FreezeTableBuildings = ({buildingRes, toggleModal}) => {
  const classes = useStyles();
  
  const hashMap = {};
  buildingRes.map(bldg => {
	  hashMap[bldg.key] = bldg;
  })
  
  const compData = Object.assign(...Array.from(
	  new Set(buildingRes.reduce((keys, o) => keys.concat(Object.keys(o)), [] )),
	  key => ({ [key]: buildingRes.map( o => o[key] ) })
  ));
  console.log(compData)
  return (
	<div className="comp-report">
	  <TableContainer className={classes.tableContainer}>
		<Table stickyHeader>
		  
		  <TableBody>
			  <StyledTableRow key={"photo"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  
				</StyledTableCell>
			  </StickyTableCell>	
				{compData.key.map(key => {
				  return (
						<StyledTableCell
						  numeric
						  align="center"
						  className={classes.cell}
						  onClick={()=>toggleModal("building", hashMap[key])}
						>
						<div className="building-photo">
						  <BuildingPhoto item={hashMap[key]} />	
			  			</div>  
					  </StyledTableCell>
					)
				})}
			  </StyledTableRow> 
			  <StyledTableRow key={"name"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Status
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.key.map(key => {
					return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
							
						  >
							<span className="bold-text">{ellimanStatus(hashMap[key])}</span>
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>  
			  <StyledTableRow key={"name"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Name
				</StyledTableCell>
			  </StickyTableCell>	
				{compData.key.map(key => {
				  const item = hashMap[key];	
				  return (
						<StyledTableCell
						  numeric
						  align="center"
						  className={classes.cell}
						>
							
							<span className="bold-text">{item.name ? _capitalizeText(item.name) : _capitalizeText(item.display_full_street_address)}</span>
								 
					  </StyledTableCell>
					)
				})}
			  </StyledTableRow>  
			  <StyledTableRow key={"address"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Address
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.display_full_street_address.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_capitalizeText(val)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>  
				<StyledTableRow key={"cross"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Cross Street
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.key.map(key => {
					const item = hashMap[key];	
					return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							  
							  <span className="bold-text">{item.goatData ? _getCrossStreet(item) : "--"}</span>
								   
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>  
				<StyledTableRow key={"neighborhood"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Neighborhood
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.neighborhood.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_capitalizeText(val[0])}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				{/*<StyledTableRow key={"neighborhood"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Borough
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.borough.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_capitalizeText(val)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> */}
				<StyledTableRow key={"sales_start"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Sales Start
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.sales_start_date.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{moment(val).year() > 1970 ? moment(val).format("MM/YYYY") : "--"}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"developer"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Developer
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.developers.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val.split(",").map((v, i) => <div>{_capitalizeText(v)}{i<(val.split(",").length-1) && <span> /</span>}</div>)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"architect"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Architect
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.architect.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val.split(",").map((v, i) => <div>{_capitalizeText(v)}{i<(val.split(",").length-1) && <span> /</span>}</div>)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"interior"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Interiors
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.interior_designers.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val.split(",").map((v, i) => <div>{_capitalizeText(v)}{i<(val.split(",").length-1) && <span> /</span>}</div>)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"inventory"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Total Units
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.inventory_total.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_formatNumber(val)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				
				<StyledTableRow key={"pct_sold"}>
				  <StickyTableCell>
					<StyledTableCell
					  numeric
					  align="right"
					  className={classes.freezCell}
					>
					  Unit Sold
					</StyledTableCell>
				  </StickyTableCell>	
					{compData.key.map(key => {
					  const building = hashMap[key];
					  let inventory_pct_unsold = building.inventory_pct_bulk_unsold ? building.inventory_pct_bulk_unsold : building.inventory_pct_unsold;
					  let inventory_unsold = building.inventory_bulk_unsold ? building.inventory_bulk_unsold : building.inventory_unsold;
					  return (
							<StyledTableCell
							  numeric
							  align="center"
							  className={classes.cell}
							>
							{building.offering_construction==='conversion' 
							   ?
							   <SalesProgress label={"Units Sold"} 
								 progress={parseFloat(((building.inventory_total - building.inventory_unsold) / building.inventory_total) * 100).toFixed(0)} 
								 inventory_unsold={building.inventory_total - building.inventory_unsold} inventory_total={building.inventory_total}  color={"grey"} />
							   
							   : building.building_status === 'resale' ?
							   <SalesProgress label={"Units Sold"}  progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total} min color={"grey"} resale={building.building_status === 'resale'} />
							   :
							   <SalesProgress label={"Units Sold"} 
								 progress={parseFloat(((building.inventory_total - building.inventory_unsold) / building.inventory_total) * 100).toFixed(0)} 
								 inventory_unsold={building.inventory_total - building.inventory_unsold} inventory_total={building.inventory_total}  />
							} 
							  
						  </StyledTableCell>
						)
					})}
				  </StyledTableRow> 
				  <StyledTableRow key={"volume_sold"}>
				  <StickyTableCell>
					<StyledTableCell
					  numeric
					  align="right"
					  className={classes.freezCell}
					>
					$ Volume Sold
					</StyledTableCell>
				  </StickyTableCell>	
					{compData.key.map(key => {
						  const building = hashMap[key];
						  const val = building.property_stats ? building.property_stats : false;
						  return (
							  <StyledTableCell
								numeric
								align="center"
								className={classes.cell}
							  >
								{building.building_status == "resale" ? "100%" : val && val.facets.total_volume && val.facets.total_volume.sum_price > 0 && val.facets.total_sold.sum_price >= 0  ? `${Math.round(((val.facets.total_sold.sum_price/val.facets.total_volume.sum_price) * 100))}%` : "--"}
							</StyledTableCell>
						  )
					  })}
				  </StyledTableRow> 
				  <StyledTableRow key={"avg_sf"}>
					<StickyTableCell>
					  <StyledTableCell
						numeric
						align="right"
						className={classes.freezCell}
					  >
						 Avg Unit SF
					  </StyledTableCell>
					</StickyTableCell>	
					  {compData.inventory_stats_listings_average_sqft.map(val => {
						  
						  return (
							  <StyledTableCell
								numeric
								align="center"
								className={classes.cell}
							  >
								{val > 100 ? `${_formatNumber(val)} SF` : "--"}
							</StyledTableCell>
						  )
					  })}
					</StyledTableRow> 
					<StyledTableRow key={"blended_ppsf"}>
					  <StickyTableCell>
						<StyledTableCell
						  numeric
						  align="right"
						  className={classes.freezCell}
						>
						Blended PPSF
						</StyledTableCell>
					  </StickyTableCell>	
						{compData.key.map(key => {
							  const building = hashMap[key];
							  const val = building.property_stats && building.property_stats.facets && building.property_stats.facets.total_volume ? building.property_stats.facets.total_volume : false;
							  if(val){
								  const sum_sold_price = val.total_closed && val.total_closed.sum_price ? val.total_closed.sum_price : 0;
								  const sum_not_sold_price = val.total_not_closed && val.total_not_closed.sum_price ? val.total_not_closed.sum_price : 0;
								  const total = sum_sold_price + sum_not_sold_price;
								  const total_sf = val.sum_sq_ft;
								  
								  const blended = total / total_sf;
								  
								  return (
										<StyledTableCell
										  numeric
										  align="center"
										  className={classes.cell}
										>
										  {blended > 0  ? `$${_formatNumber(blended)}` : "--"}
									  </StyledTableCell>
									)
							  }else {
								  return ( <StyledTableCell
										numeric
										align="center"
										className={classes.cell}
									  >
									 --
									</StyledTableCell>
								)
							  }
							  
						  })}
					  </StyledTableRow> 
				  <StyledTableRow key={"inventory"}>
				  <StickyTableCell>
					<StyledTableCell
					  numeric
					  align="right"
					  className={classes.freezCell}
					>
					   On Market PPSF
					</StyledTableCell>
				  </StickyTableCell>	
					{compData.property_stats.map(val => {
						  
						  return (
							  <StyledTableCell
								numeric
								align="center"
								className={classes.cell}
							  >
								{val.facets.on_the_market && val.facets.on_the_market.non_zero_price && val.facets.on_the_market.non_zero_price.avg_ppsf > 0  ? `$${_formatNumber(val.facets.on_the_market.non_zero_price.avg_ppsf)}` : "--"}
							</StyledTableCell>
						  )
					  })}
				  </StyledTableRow> 
				  <StyledTableRow key={"sold_psf"}>
				  <StickyTableCell>
					<StyledTableCell
					  numeric
					  align="right"
					  className={classes.freezCell}
					>
					   Sold PPSF
					</StyledTableCell>
				  </StickyTableCell>	
					{compData.inventory_stats_transactions_average_ppsf.map(val => {
						
						return (
							<StyledTableCell
							  numeric
							  align="center"
							  className={classes.cell}
							>
							  {val > 100 ? `$${_formatNumber(val)}` : "--"}
						  </StyledTableCell>
						)
					})}
				  </StyledTableRow> 
				  <StyledTableRow key={"shadow_psf"}>
					<StickyTableCell>
					  <StyledTableCell
						numeric
						align="right"
						className={classes.freezCell}
					  >
						 Unclassified PPSF
					  </StyledTableCell>
					</StickyTableCell>	
					  {compData.property_stats.map(val => {
						  
						  return (
							  <StyledTableCell
								numeric
								align="center"
								className={classes.cell}
							  >
								{val.facets.shadow && val.facets.shadow.non_zero_price && val.facets.shadow.non_zero_price.avg_ppsf > 0  ? `$${_formatNumber(val.facets.shadow.non_zero_price.avg_ppsf)}` : "--"}
							</StyledTableCell>
						  )
					  })}
					</StyledTableRow> 
					
					  <StyledTableRow key={"cc_psf"}>
					  <StickyTableCell>
						  <StyledTableCell
							numeric
							align="right"
							className={classes.freezCell}
						  >
							 Monthly CC PSF
						  </StyledTableCell>
						</StickyTableCell>	
						  {compData.property_stats.map(val => {
								
								return (
									<StyledTableCell
									  numeric
									  align="center"
									  className={classes.cell}
									>
									  {val.facets.cc_ppsf && val.facets.cc_ppsf && val.facets.cc_ppsf.avg_ppsf > 0.05  ? `$${Math.round((val.facets.cc_ppsf.avg_ppsf*100))/100}` : "--"}
								  </StyledTableCell>
								)
							})}
						</StyledTableRow> 
						<StyledTableRow key={"tax_psf"}>
						<StickyTableCell>
							<StyledTableCell
							  numeric
							  align="right"
							  className={classes.freezCell}
							>
							   Monthly Tax PSF
							</StyledTableCell>
						  </StickyTableCell>	
							{compData.property_stats.map(val => {
								  
								  return (
									  <StyledTableCell
										numeric
										align="center"
										className={classes.cell}
									  >
										{val.facets.tax_ppsf && val.facets.tax_ppsf.avg_ppsf > 0.05  ? `$${Math.round((val.facets.tax_ppsf.avg_ppsf*100))/100}` : "--"}
									</StyledTableCell>
								  )
							  })}
						  </StyledTableRow> 
				  <StyledTableRow key={"range"}>
					<StickyTableCell>
					  <StyledTableCell
						numeric
						align="right"
						className={classes.freezCell}
					  >
						Price Range
					  </StyledTableCell>
					</StickyTableCell>	
					  {compData.key.map(key => {
						const building = hashMap[key];
					  
						return (
							  <StyledTableCell
								numeric
								align="center"
								className={classes.cell}
							  >
								{building.inventory_stats_listings_low_price > 100 ? `${_formatPrice(building.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(building.inventory_stats_listings_high_price).toUpperCase()}` : '--'}
							  
							</StyledTableCell>
						  )
					  })}
					</StyledTableRow> 
					
		  </TableBody>
		</Table>
	  </TableContainer>
	</div>
  );
}


export const FreezeTableUnits = ({unitRes, toggleModal}) => {
  const classes = useStyles();
  
  const hashMap = {};
  unitRes.map(bldg => {
	  hashMap[bldg.key] = bldg;
  })
  
  const compData = Object.assign(...Array.from(
	  new Set(unitRes.reduce((keys, o) => keys.concat(Object.keys(o)), [] )),
	  key => ({ [key]: unitRes.map( o => o[key] ) })
  ));
  console.log(compData)
  return (
	<div className="comp-report">
	  <TableContainer className={classes.tableContainer}>
		<Table stickyHeader>
		  
		  <TableBody>
			  <StyledTableRow key={"photo"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  
				</StyledTableCell>
			  </StickyTableCell>	
				{compData.key.map(key => {
				  const item = hashMap[key];	
				  const img = item.listing && item.listing.main_photo ? item.listing.main_photo : _getUnitFloorPlan(item) ? _getUnitFloorPlan(item)[0].src : false;
				  return (
						<StyledTableCell
						  numeric
						  align="center"
						  className={classes.cell}
						  onClick={()=>toggleModal("unit", hashMap[key])}
						>
							 {img ?
								  <div class="todo__lead-image fp" style={{backgroundImage: `url(${img})`}} >
									  <UnitCurrentStatus status={hashMap[key].offering_status} unit={hashMap[key]} />
								  </div>
								  :
									<div class="todo__lead-image" style={{background:"#FFF",width:"100%",height:"198px",paddingTop:35,minWidth:"150px",marginTop:"-3px"}}>
										<FloorPlanIcon size={120} color="#CCC" />
										<UnitCurrentStatus status={hashMap[key].offering_status} unit={hashMap[key]} />
									</div>
							}	
					  </StyledTableCell>
					)
				})}
			  </StyledTableRow> 
			  <StyledTableRow key={"unit"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.key.map(key => {
					const item = hashMap[key];	
					const img = item.listing && item.listing.main_photo ? item.listing.main_photo : _getUnitFloorPlan(item) ? _getUnitFloorPlan(item)[0].src : false;
					return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							  
							  <Tooltip title={`View unit details`}>
								  <Button
									   onClick={()=>toggleModal("unit", hashMap[key])}
									   variant="outlined"
									   size="small"
									   color="primary"
									   className="detail-view"		         
								   >	See Details
								   </Button>				 
							  </Tooltip>
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
			  
				
				<StyledTableRow key={"unit"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Unit
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.address_2_normalized.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							<span className="bold-text">{_formatUnitNumber(val)}</span>
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
			  <StyledTableRow key={"building"}>
			  <StickyTableCell>
				<StyledTableCell
				  numeric
				  align="right"
				  className={classes.freezCell}
				>
				  Building
				</StyledTableCell>
			  </StickyTableCell>	
				{compData.building_name.map(val => {
					
					return (
						<StyledTableCell
						  numeric
						  align="center"
						  className={classes.cell}
						>
						  {_capitalizeText(val)}
					  </StyledTableCell>
					)
				})}
			  </StyledTableRow>  
			  <StyledTableRow key={"address"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Address
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.display_full_street_address.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_capitalizeText(val)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
				  
				<StyledTableRow key={"neighborhood"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Neighborhood
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.neighborhood.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_capitalizeText(val[0])}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"borough"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Borough
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.borough.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{_capitalizeText(val)}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"stage"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Stage
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.key.map(key => {
					const unit = hashMap[key];
					return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							  {unit.times_sold <= 0 ? "Sponsor Owned" :  unit.times_sold == 1 ? "First Owner" :  "Sold " + _formatNumber(unit.times_sold) + " Times"}
							 
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
				<StyledTableRow key={"last_listed"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Last Listed
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.key.map(key => {
					const unit = hashMap[key];
					return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
									   <span className="bold-text">{unit.last_listed_sale_price_all > 0 ? '$'+_formatNumber(unit.last_listed_sale_price_all) : '--'}</span>
								{typeof unit["div(last_listed_sale_price_all, sq_ft)"] === 'number' && unit["div(last_listed_sale_price_all, sq_ft)"] > 100 &&
									<Fragment>
									<br/>
									<span className="">
												  ${_formatNumber(unit["div(last_listed_sale_price_all, sq_ft)"])} / Sq Ft
									</span>
									</Fragment>	
								  }
								{unit.last_listed_sale_date_all > 0 &&
									<Fragment>
										<br/>
										<span className="light-text small-text">({moment.unix(unit.last_listed_sale_date_all).format('MM/DD/YYYY')})</span>
									</Fragment>	
								}
								
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow> 
				<StyledTableRow key={"last_sold"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Last Sold
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.key.map(key => {
					const unit = hashMap[key];
					return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							  <span className="bold-text">{unit.last_sold_price > 0 ? '$'+_formatNumber(unit.last_sold_price) : '--'}</span>
							  {typeof unit["div(last_sold_price, sq_ft)"] === 'number' && unit["div(last_sold_price, sq_ft)"] > 100 &&
								  <Fragment>
								  <br/>
								  <span className="">
												${_formatNumber(unit["div(last_sold_price, sq_ft)"])} / Sq Ft
								  </span>
								  </Fragment>	
								}
							  {unit.last_sold_date > 0 &&
								  <Fragment>
									  <br/>
									  <span className="light-text small-text">({moment.unix(unit.last_sold_date).format('MM/DD/YYYY')})</span>
								  </Fragment>	
							  }
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>				
				<StyledTableRow key={"beds"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Bedrooms
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.bedrooms.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val > -1 ? val === 0 ? 'Studio' :val+' Bed' : '--'}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
				<StyledTableRow key={"baths"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Baths
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.baths.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val > -1 ? val+' Bath' : '--'}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
				<StyledTableRow key={"sf"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Sq Ft
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.sq_ft.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val > 0 ? _formatNumber(val)+' Sq Ft' : '--'}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
				<StyledTableRow key={"esf"}>
				<StickyTableCell>
				  <StyledTableCell
					numeric
					align="right"
					className={classes.freezCell}
				  >
					Exterior SF
				  </StyledTableCell>
				</StickyTableCell>	
				  {compData.offering_exterior_sq_ft.map(val => {
					  
					  return (
						  <StyledTableCell
							numeric
							align="center"
							className={classes.cell}
						  >
							{val > 0 ? _formatNumber(val)+' Sq Ft' : '--'}
						</StyledTableCell>
					  )
				  })}
				</StyledTableRow>
				
		  </TableBody>
		</Table>
	  </TableContainer>
	</div>
  );
}