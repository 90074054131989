import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Gallery from './Gallery';
import Items from './imgs';

const tags = [
  { tag: 'floor', title: 'Floor Plates', show: false },
  { tag: 'elevation-map', title: 'Elevation Maps', show: false }, 
  { tag: 'site-plan', title: 'Site Plans', show: false },
  { tag: 'zoning', title: 'Zoning', show: false },
  { tag: 'storage', title: 'Storage', show: false },
  { tag: 'maisonette', title: 'Maisonette', show: false },
  { tag: 'bulkhead', title: 'Bulkhead', show: false },
  { tag: 'mechanical', title: 'Mechanical', show: false },
  { tag: 'elevator', title: 'Elevator', show: false },
  { tag: 'roof', title: 'Roof', show: false },
  { tag: 'lobby', title: 'Lobby', show: false },
  { tag: 'other', title: 'Other', show: false },
];

const FilterGallery = ( props ) => {
	const { buildingRes } = props;
	
	let plans = null;
	if(buildingRes.offering_floorplans){		
		try{
			plans = JSON.parse(buildingRes.offering_floorplans);
		}catch(e){
			
		}
		
	}
	
	if(!plans){
		return null;
	}
	const sortTypes = ['elevation-map', 'site-plan', 'zoning', 'floor','storage'];
	const allPlans = plans.map(plan => {
		 plan['src'] = plan['url'];
		 if(tags.filter(tag => tag.tag === plan.type).length>0){
			 tags.filter(tag => tag.tag === plan.type)[0].show=true;
			 plan['tag'] = plan['type'];
		 }else{
			 tags.filter(tag => tag.tag === 'other')[0].show=true;
			 plan['tag'] = 'other';
		 }
		 let sortScore = sortTypes.indexOf(plan.type)>-1 ? (sortTypes.indexOf(plan.type)+1)*1000 : 10000;
		 if(plan.type === 'floor'){
			 
			const typeIds = plan.type_id.split(',');
			if(!isNaN(typeIds[0])){
				sortScore+= parseInt(typeIds[0]);
				if(typeIds.length > 1){
					sortScore+=1;
				}
			}else{
				sortScore+=200;
			}
			
		 }		 			 	 
		 plan['sort'] = sortScore;
		 return plan;
	})
	
	allPlans.sort((a, b) => {
		return a.sort > b.sort ? 1 : -1;		
	});
	
	let images = {};
	allPlans.map(image => {	
		if(!images[image.source_id]){
			images[image.source_id] = image;
			images[image.source_id]['sub_tags'] = [];
		}
		if(image.type_id.split(',').length>1){
			images[image.source_id]['sub_tags'] = [...images[image.source_id]['sub_tags'], ...image.type_id.split(',')];
		}else{
			images[image.source_id]['sub_tags'].push(image.type_id);
		}
		
		
	})
	console.log(Object.values(images));
	return (
		  <Col md={12} lg={12}>
		    <Card>
		      <CardBody>
		        <Gallery images={Object.values(images)} tags={tags.filter(tag => tag.show)} {...props} />
		      </CardBody>
		    </Card>
		  </Col>
    )
};

export default FilterGallery;
