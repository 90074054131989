import React from 'react';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';

import getTooltipStyles from '../../../../../shared/helpers';

const data = [
{
  name: 'Q3 2018', plan: 1490, pv: 800, current: 1800,
},
{
  name: 'Q4 2018', plan: 1268, pv: 967, current: 1506,
},
{
  name: 'Q1 2019', plan: 1197, pv: 1098, current: 1490,
},
{
  name: 'Q2 2019', plan: 1180, pv: 1200, current: 1328,
},
{
  name: 'Q3 2019', plan: 1120, pv: 108, current: 1300,
},
{
  name: 'Q4 2019', plan: 1120, pv: 1108, current: 1260,
},
{
  name: 'Q1 2020', plan: 1000, pv: 680, current: 1100,
}];

const ProductSales = ({ t, rtl, themeName }) => (
  <Panel lg={12} title={`Offering Price Trend`}>
    <div dir="ltr">
      <ResponsiveContainer height={250} className="dashboard__area">
        <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
          <XAxis dataKey="name" tickLine={false} reversed={rtl === 'rtl'} />
          <YAxis tickLine={false} orientation={rtl === 'rtl' ? 'right' : 'left'} />
          <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
          <Legend />
          <CartesianGrid />
          <Area
            name="Average PPSF"
            type="monotone"
            dataKey="current"
            fill="#4ce1b6"
            stroke="#4ce1b6"
            fillOpacity={0.2}
          />
          <Area name="Median PPSF" type="monotone" dataKey="plan" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  </Panel>
);

ProductSales.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(withTranslation('common')(ProductSales));
