import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/BorderNoneIcon';
import ToolTip from '../../../../../shared/components/ToolTip';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	setURLFromState
} from "../../../../../shared/helpers/utils";

const AveragePPSF = (props) => {

  if(!props.soldData || !props.soldData.non_zero_price) return null;

  const ppsf = props.soldData.non_zero_price ? (props.soldData.non_zero_price.average_ppsf).toFixed(0) : false;
  const newState = Object.assign({}, props); 	
	 if(newState.subFilters && newState.subFilters.current_status){
		 	delete( newState.subFilters.current_status);
	 }
 	 const baseURL = setURLFromState({}, newState, true);

  return (
	  	<Card>
	  	<Link to={`/inventory${baseURL}&current_status=available`}>
	      <CardBody className="dashboard__booking-card card__link">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            ${_formatNumber(ppsf)}
	          </h5>
	          <TagOutlineIcon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Avg Sold PPSF
	        	<ToolTip 
	        		title={`Based on closed price from ACRIS over the past 24 months`} 
	        		body={<span>Average sold price per square foot is based only on the units that have closed within the {props.groupedCount} Marketproof Vetted buildings.<br/><br/>The sold price is the ACRIS recorded price and may include property taxes and other fees.</span>}
	        	/>
	        </h5>
	      </CardBody>
	    </Link>
	    </Card>
	 )
};

export default AveragePPSF;
