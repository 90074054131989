export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';

export function setUserProfile(user) {
	
  const defaultUser = {
	  title: '',
	  avatar: 'https://buyerslist.s3.amazonaws.com/account.png',
	  role: 'buyer',
  }	
  //user['id'] = user['sub'] = user['user_key'] = "00u17q8cygr4XygaC4x7";
 
  const setUser = user===null ? null : Object.assign({}, defaultUser, user);
  
  console.log(defaultUser, user, setUser)
  return {
    type: AUTHENTICATE,
    user: setUser, //roles[role],
  }; 
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}
