import React from 'react';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';

import UnitList from './components/UnitList';
import UnitsSearchTable from './../../../Sales/components/UnitsSearchTable';


const Units = (props) => {
	
	if(true || ['active', 'offering plan required', 'coming soon', 'condo declaration vetted'].indexOf(props.buildingRes.offering_verified_status)  >= 0 || props.user.role.indexOf('admin')>=0){
	
		return (
		   
			  <Container className="dashboard">
			   <Row>
			   	 <UnitsSearchTable buildingView unitView building={props.buildingRes} {...props} />			                
			   </Row>
			  </Container>
		 )
	}else{
		return (	 
		  	  <Container className="dashboard">
			   <Row>
			   <Col md={12} xs={12}>
			   <Card>
			   <CardBody className="dashboard__booking-card card__link">
			   <div className="">
			    <h4 className="">Coming Soon</h4>
			   	<p> 
			   		Marketproof is currently processing this building and verifying the accuracy of information.
			   		<br/>
			   		More details will be added to this building shortly.		                
				</p>
				</div>
				</CardBody>
				</Card>
				</Col>
				
			   </Row>
			  </Container>
		  )
	}
  
};

export default Units;