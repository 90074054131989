import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';
import LinearLoading from '../../../../../shared/components/LinearLoading';

import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
	buildingFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl
} from "../../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const buildingUrl = process.env.API_ROOT + '/query/';
const styles = theme => ({
	  root: {
	    display: 'flex',
	    flexDirection: 'column',
	    //marginLeft:"-8px",
	    marginBottom:"15px",
	    alignItems: 'left',
	    '& > *': {
		   
	    },
	  },
	  button:{
		'&.selected' :{
		    background:"#D1D6E0"
	    },
	    fontSize:11,
		width:90
	  }
	});
	
const ShowUnits = ({data, title, color}) => {
	
	
	return (
		<Card>
		<CardBody style={{padding:15}}>
		 <div className="card__title">
          <h5 className="bold-text">{title}</h5>
         </div>
	    <Table responsive className="table-striped">
	      <tbody>
	      {data && data.doclist && data.doclist.docs.slice(0, 3).map((item, i) => {
		      	const buildingNameOrAdr = item.building_name 
			      ? _capitalizeText(item.building_name)
			      : item.display_full_street_address
			        ? _capitalizeText(item.display_full_street_address)
			        : _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
		      return (
			 
				      <tr key={i} style={{border:0,cursor:"pointer"}}>
				      	<td>
				  			<Badge style={{ width:70,background:color }}>
				  				<span className="bold-text">{_formatPrice(item.price)}</span> 
				  			</Badge>
						</td>      				       	
						<td>
							<Link to={unitURL}>
								<div>{buildingNameOrAdr}, {item.address_2_normalized.toUpperCase().replace("APT", "Apt")}</div>
								<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}</span>
								<br/>
								{item.baths>0 ?
								 <Fragment>
									<span className="light-text small-text">{item.bedrooms > -1 ? item.bedrooms === 0 ? 'Studio' : item.bedrooms+' Bed' : '--'}</span>
									{` | `}<span className="light-text small-text">{item.baths > -1 ? item.baths+' Bath' : '--'}</span>
									
								</Fragment>
								 
								:
								 item.sq_ft>0 ? 
									<span className="light-text small-text">
										{_formatNumber(item.sq_ft)} sq ft
									</span>
								:
									<span className="light-text small-text">--</span>
								}
							</Link>	
							
						</td>   
						
						    
			          </tr>
			      
			    )
		      
	      })}
	        
	       
	      </tbody>
	    </Table>
	    
		</CardBody>
		</Card>

    )
}
class BuildingsByContracts extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      data: null,
	      period: "7-day"
	    };
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.period !== prevState.period){
			this._getActivity();
		}
		
	}

	componentDidMount() {
		this._getActivity();
		
	  	
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
    }
	_getActivity = async () => {
			
		const { period } = this.state;
		const { type } = this.props;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		var q = "*:*"; //"time:[NOW-7DAY TO NOW]";
		let time = "time:[NOW-7DAY TO NOW]";
		
		if(period == "30-day"){
			time = "time:[NOW-30DAY TO NOW]";
		}else if(period == "120-day"){
			time = "time:[NOW-120DAY TO NOW]";
		}
		
		let stage = "event_tags:(\"event-stage_sponsor\")";
		
		if(type=="resale"){
			stage = "event_tags:(\"event-stage_resale\")";
		}
		
		const defaultFq = [
			time,
			stage,
			"hierarchy:[0 TO 2]",	
 			"-(time:[2020-03-18T20:42:19Z TO 2020-03-19T00:47:46Z] AND building:b-4-2315-44th-dr-11101 AND foreign_id:*RPLU*)",
 			"-(time:[2021-09-09T13:00:00Z TO 2021-09-10T20:00:00Z] AND (foreign_id:REBNY-LISTING-RPLU* OR foreign_id:REBNY-LISTING-PDES*) AND slugs:*elliman*)",
 			"-(time:[2021-09-30T13:00:00Z TO 2021-10-01T01:00:00Z] AND (foreign_id:REBNY-LISTING-RPLU*) AND slugs:*elliman* AND building:b-1-132-madison-ave-10016)",
 			"-foreign_id:(REBNY-LISTING-RPLU-3346205670 REBNY-LISTING-RPLU-3346199729 REBNY-LISTING-RPLU-5121552553 REBNY-LISTING-RPLU-5121552555 REBNY-LISTING-RPLU-33421317535 REBNY-LISTING-PRCH-3694032 REBNY-LISTING-PRCH-3694023 REBNY-LISTING-RPLU-3346392995 REBNY-LISTING-RPLU-3346364853 REBNY-LISTING-RPLU-5121413140)",
 			"-(time:[2022-01-18T20:42:19Z TO 2022-01-23T00:47:46Z] AND slugs:*corcoran* AND foreign_id:*RPLU* AND event_tags:just-listed)",
			
		];
				 
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=3600",
			method: "post",
			query: {
				q: q,
				fq: defaultFq,
				sort: "price desc",				
				fl: "display_full_street_address,full_street_address,address_2_normalized,baths,bedrooms,neighborhood,borough,building,building_name,price,time,event_time,building_slugs,property,sq_ft,event_tags",
				wt: "json",
				rows: 0,
				"json.facet" : {
					"by_building": {				
						"type": "terms",
						"field": "building",
						"mincount": 1,
						"offset": 0,
						"limit": 5
					}
				},
				 "no_contacts": true,
		        "group": true,
		        "group.limit": 5,
		        "group.query": [
		        	`topic:listings AND event_tags:just-listed AND -event_tags:rent AND -address_2_normalized:"" AND -event_tags:*commercial*`, 
		        	`topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -address_2_normalized:"" AND -event_tags:*commercial*`,
		        	`topic:transactions AND event_tags:recorded-sale AND -address_2_normalized:"" AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale AND -event_tags:property-type_timeshare`]		
			}
		};

		let response = await _axiosCall(axiosData)
		.then(res => {
			return res;
		})
		.catch(error => {
			console.log("error: " + error);
		});
		this.setState({ data : response, fetchInProgress : false });
	
  };
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  setPeriod = (period) => {
	  this.setState({period:period})
  }
  
  render(){
	const { savedUnits, totalCount, period, data, fetchInProgress } = this.state;
	const { classes, type } = this.props;
	//if(!data) return null;
	return (
		<Card>
		   <CardBody>
			   <Row>
			   		<Col md={12} className={"mb-2"}>
			   			<div className="card__title">
			              <h5 className="bold-text">{type == "resale" ? "Resales" : 'New Developments'}</h5>
			              {/*<h5 className="subhead">Top Listings, Contracts and Closing for The {type == "resale" ? "Resale Condo Market" : 'New Development Market'}</h5>*/}
			            </div>
			   			<div className={classes.root}>
			   			  	
					      <ButtonGroup size="small" aria-label="small button group" >							        
					      	<Tooltip title="Sales velocity, absorption rate and units listed based on the last 3 months of sales." aria-label="See sales velocity and absorption rate based on the last 3 months of sales.">
					        	<Button onClick={()=> this.setPeriod("7-day")} className={period === "7-day" ? classNames(classes.button, "selected") : classNames(classes.button)}>7-Day</Button>
					        </Tooltip>
					        <Tooltip title="Sales velocity, absorption rate and units listed based on the last 6 months of sales." aria-label="See sales velocity and absorption rate based on the last 6 months of sales.">
					        	<Button onClick={()=> this.setPeriod("30-day")} className={period === "30-day" ? classNames(classes.button, "selected") : classNames(classes.button)}>30-Day</Button>
					        </Tooltip>
					        <Tooltip title="Sales velocity, absorption rate and units listed based on the last 1 year of sales." aria-label="See sales velocity and absorption rate based on the last 1 year of sales.">
					       	 	<Button onClick={()=> this.setPeriod("120-day")} className={period === "120-day" ? classNames(classes.button, "selected") : classNames(classes.button)}>120-Day</Button>
					       	</Tooltip>	
					       
					      </ButtonGroup>
					  </div>
			   		</Col>
			   </Row>
			   
			   {(!data || fetchInProgress) ?
				   <div>
				   <LinearLoading />
				   <div style={{height:360}}>
				   	
				   </div>
				   </div>
				   
				   :
				 
				   <Row>
			   		<Col md={4} className={"mb-2"}>
			   			<ShowUnits data={data ? data[Object.keys(data)[0]] : false} title={"Top Listings"} color={"#4ce1b6"} />
			   		</Col>
			   		<Col md={4} className={"mb-2"}>
			   			<ShowUnits data={data ? data[Object.keys(data)[1]] : false} title={"Top Contracts"} color={"#FFD23F"}/>
			   		</Col>
			   		<Col md={4} className={"mb-2"}>
			   			<ShowUnits data={data ? data[Object.keys(data)[2]] : false} title={"Top Closings"}  color={"#28C2FF"} />
			   		</Col>					   	  
				   	 
				   </Row>
			   }
			   
			    <div className="unit__load text-right semi-bold-text">			   		
				    <Tooltip title="See More">
				        <Link to={`/analysis/market-pulse?unit_stage=${type=="resale" ? "resale" : "sponsor"}`}>See All {type=="resale" ? "Resale" : "New Development"} Market Activity &raquo;</Link>			 
				  	</Tooltip>				
			    </div>
			 </CardBody>
		</Card>
	
	)
	
   }
};

BuildingsByContracts.propTypes = {
  t: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, 
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],  
	  		dataSet : state.buyersLists.dataSet };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withStyles(styles,  { withTheme: true })(BuildingsByContracts)));
