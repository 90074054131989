import React from "react";
import { LngLat, LngLatBounds } from "mapbox-gl";
import L from "leaflet";
import distance from "@turf/distance";
import queryString from "query-string";
import circle from "@turf/circle";
import {
  _capitalizeText,
  _formatBaths,
  _formatBeds,
  _formatFilingType,
  _formatListingHeadline,
  _formatNumber,
  _formatPrice,
  _formatUnitDisplay,
  _getListingDisplayRules,
  _getOccupancy,
  _getOccupancyCode,
  _isEqual,
  _listingPrice,
  _nameToUrl,
  _shouldShow,
  _urlToName,
  _formatOwnerName,
  _truncateWords
} from "./utils";
import {
  _getListingAdr,
  _getListingTagInfo,
  _getListingUnitTag,
  _getUnitDisplay,
  _getUrlObj,
} from "./listingUtils";
import moment from "moment";
export const _parseToGeoJSON = (features = []) => ({
  type: "FeatureCollection",
  features: _parseFeatures(features),
});

const _parseFeatures = (features = []) => {
  return features.map((feature) => {
    return {
      type: "Feature",
      properties: feature,
      geometry: {
        type: feature.geocode ? "Point" : "Polygon",
        coordinates: feature.geocode
          ? _parseCoordinatesMap(feature.geocode)
          : [feature.cell],
      },
    };
  });
};

export const _parseCoordinatesMap = (coordinates) =>
  coordinates
    .split(", ")
    .map((coord) => Number(coord))
    .reverse();

export const _parseCoordinates = (coordinates) => {
  if (!coordinates) {
    return;
  }
  let mapPoints = {};
  if (typeof coordinates === "string") {
    mapPoints.long = parseFloat(
      coordinates.substring(0, coordinates.indexOf(","))
    );
    mapPoints.lat = parseFloat(
      coordinates.substring(coordinates.indexOf(",") + 1, coordinates.length)
    );
  } else if (coordinates.lat) {
    mapPoints.lat = parseFloat(coordinates.lat);
    mapPoints.long = parseFloat(coordinates.lng);
  } else if (coordinates.coordinates && coordinates.coordinates.length > 1) {
    mapPoints.lat = coordinates.coordinates[1];
    mapPoints.long = coordinates.coordinates[0];
  } else {
    mapPoints.lat = coordinates[0];
    mapPoints.long = coordinates[1];
  }
  return [mapPoints.lat, mapPoints.long];

  //coordinates.split(", ").map(coord => Number(coord)).reverse()
};

const _createCell = (bounds, pad = 0) => {
  const [swLng, swLat] = bounds.getSouthWest().toArray(); // start coordinate
  const [seLng, seLat] = bounds.getSouthEast().toArray();
  const [neLng, neLat] = bounds.getNorthEast().toArray();
  const [nwLng, nwLat] = bounds.getNorthWest().toArray(); // start/close coordinate

  return {
    cell: [
      [swLng - pad, swLat - pad],
      [seLng + pad, seLat - pad],
      [neLng + pad, neLat + pad],
      [nwLng - pad, neLat + pad],
      [swLng - pad, swLat - pad],
    ],
  };
};

const _createGrid = (mapBounds, mobile, splitView) => {
  const grid = [];
  const divVal = mobile ? 3 : splitView ? 4 : 4;
  const [swPoint, nePoint] = mapBounds.toArray();
  const lngDif = (nePoint[0] - swPoint[0]) / divVal;
  const latDif = (nePoint[1] - swPoint[1]) / divVal;

  for (let i = 0; i < divVal; i++) {
    for (let j = 0; j < divVal; j++) {
      const sw = new LngLat(swPoint[0] + lngDif * j, swPoint[1] + latDif * i);
      const ne = new LngLat(
        swPoint[0] + lngDif * (j + 1),
        swPoint[1] + latDif * (i + 1)
      );
      const cellBounds = new LngLatBounds(sw, ne);
      grid.push(_createCell(cellBounds));
    }
  }

  return grid;
};

export const _parsePolygon = (polygon) => {
  const result = [];
  polygon.forEach((poly) => {
    const start = 'geocode:"Intersects(POLYGON((';
    const polygonString = poly.map((coord) => coord.join(" ")).join(", ");

    result.push(`${start}${polygonString})))\"`);
  });

  return result.join(" OR ");
};

export const _parseToPolygonQuery2 = (polygons) => {
  const polygonQueries = {
    groupQuery: {},
    groupLimit: 1,
    rows: 0,
  };

  const polys = {};

  Object.keys(polygons).forEach((key) => {
    polys[key] = _parsePolygon(polygons[key]);
  });

  polygonQueries.groupQuery = polys;

  return polygonQueries;
};

export const _parseToPolygonQuery = (polygon) => {
  const result = 'geocode:"Intersects(POLYGON((';
  const polygonString = polygon.map((coord) => coord.join(" ")).join(", ");

  return `${result}${polygonString})))\"`;
};

export const _createFacet = ({ doclist }, polygon, centerArg) => {
  const bounds = polygon ? new L.polygon(polygon).getBounds() : undefined;

  const center = bounds
    ? [bounds.getCenter().lng, bounds.getCenter().lat].join(", ")
    : doclist.docs[0].geocode;

  return {
    count: doclist.numFound,
    geocode: centerArg && doclist.numFound > 1 ? centerArg : center,
    docs: doclist.docs,
  };
};

export const _createPolygonQueries = (
  mapBounds,
  mapZoom,
  mobile,
  splitView
) => {
  const polygonQueries = {
    groupQuery: {},
    groupLimit: 1,
    rows: 0,
  };
  if (mapZoom <= 11) {
    polygonQueries.groupQuery = {
      'borough:"manhattan"': `borough:"manhattan"`,
      'borough:"brooklyn"': `borough:"brooklyn"`,
      'borough:"queens"': `borough:"queens"`,
      'borough:"bronx"': `borough:"bronx"`,
      'borough:"staten island"': `borough:"staten island"`,
    };

    polygonQueries.geoJson = _parseToGeoJSON([]);
  } else {
    const grid = _createGrid(mapBounds, mobile, splitView);
    for (let i = 0; i < grid.length; i++) {
      const query = _parseToPolygonQuery(grid[i].cell);
      polygonQueries.groupQuery[query] = query;
    }

    polygonQueries.geoJson = _parseToGeoJSON(grid); // ?????
  }
  return polygonQueries;
};

export const _createPolygonQuery = (mapBounds, mobile) => {
  const polygonQuery = {
    q: "",
    rows: mobile ? 50 : 100,
  };

  const pad = mobile ? 0 : 0;
  const cell = _createCell(mapBounds, pad);

  polygonQuery.q = _parseToPolygonQuery(cell.cell);
  polygonQuery.geoJson = _parseToGeoJSON([cell]);

  return polygonQuery;
};

export const _combineDocs = (docs) => {
  const combinedDocs = {};

  docs.forEach((doc) => {
    if (combinedDocs[doc.geocode]) {
      combinedDocs[doc.geocode].push(doc);
    } else {
      combinedDocs[doc.geocode] = [doc];
    }
  });

  return combinedDocs;
};

const _distanceTwoPoints = (point1, point2) =>
  distance(_parseCoordinates(point1), _parseCoordinates(point2), "miles");

export const _existingGeoCode = (features, geocode, zoom) => {
  for (let i = 0; i < features.length; i++) {
    if (
      features[i].geocode === geocode ||
      (zoom < 14 && _distanceTwoPoints(features[i].geocode, geocode) <= 0.18)
    ) {
      return i;
    }
  }

  return -1;
};

export const _createMapBounds = (bounds) => ({
  sw: bounds.getSouthWest().toArray(),
  se: bounds.getSouthEast().toArray(),
  ne: bounds.getNorthEast().toArray(),
  nw: bounds.getNorthWest().toArray(),
});

export const _boundsEqual = (bounds, prevBounds) => {
  if (!bounds || !prevBounds) return false;
  const poly1 = bounds.sw
    ? [
        [bounds.sw[0], bounds.sw[1]],
        [bounds.se[0], bounds.se[1]],
        [bounds.ne[0], bounds.ne[1]],
        [bounds.nw[0], bounds.nw[1]],
      ]
    : [];

  const poly2 = prevBounds.sw
    ? [
        [prevBounds.sw[0], prevBounds.sw[1]],
        [prevBounds.se[0], prevBounds.se[1]],
        [prevBounds.ne[0], prevBounds.ne[1]],
        [prevBounds.nw[0], prevBounds.nw[1]],
      ]
    : [];

  return _parseToPolygonQuery(poly1) === _parseToPolygonQuery(poly2);
};

export const getBoundFromUrl = () => {
  if (window && window.location.search.includes("&mapBounds")) {
    let query = queryString.parse(window.location.search);
    return JSON.parse(query["mapBounds"]);
  } else {
    return {};
  }
};
export const removeMapBoundsfromUrl = () => {
  if (window && window.location.search.includes("&mapBounds")) {
    let urlStr = window.location.search.split("&mapBounds");
    if (urlStr && urlStr.length > 1) {
      urlStr[0] = urlStr[0].slice(1);
      let searchString = urlStr[0];
      window && window.history.pushState({}, "", "?" + searchString);
    }
  }
};
export const setURLForMapBounds = (props, bounds) => {
  let jsonBounds = JSON.stringify(bounds);
  let setbounds = "";
  if (window && window.location.search.includes("&mapBounds")) {
    let urlStr = window.location.search.split("&mapBounds");
    if (urlStr && urlStr.length > 1) {
      urlStr[0] = urlStr[0].slice(1);
      let searchString = urlStr[0] + "&mapBounds=" + jsonBounds;
      props.history.location.search = "?" + searchString;
      window && window.history.pushState({}, "", "?" + searchString);
    }
  } else {
    setbounds = window.location.search + `&mapBounds=${jsonBounds}`;
    window && window.history.pushState({}, "", setbounds);
  }
};

export const _urlAndTagBoundsEqual = (urlBounds, tagBounds) => {
  const tagToUrlBounds = _createUrlBounds(tagBounds, " ");

  return urlBounds === tagToUrlBounds;
};

export const _createUrlBounds = (tagBounds, joinChar) =>
  tagBounds.sw
    ? `sw:${tagBounds.sw.join(joinChar)},ne:${tagBounds.ne.join(joinChar)}`
    : "";

export const _createTagBoundsFromParams = (paramBounds) => {
  if (paramBounds) {
    const bSArr = paramBounds.split(",");
    const swSArr = bSArr[0].slice(3).split(" ");
    const neSArr = bSArr[1].slice(3).split(" ");
    const n = Number(neSArr[1]);
    const e = Number(neSArr[0]);
    const s = Number(swSArr[1]);
    const w = Number(swSArr[0]);

    return {
      sw: [w, s],
      se: [e, s],
      ne: [e, n],
      nw: [w, n],
    };
  }

  return {};
};

export const _areaPercentage = (polygonArea, mapArea) =>
  (polygonArea / mapArea) * 100.0;

export const circlePoly = (center, radius) => {
  var options = { steps: 360, units: "kilometers", properties: { foo: "bar" } };
  var cir = circle(center, radius, options);

  return cir;
};

export const ListingMarker = ({ combinedDoc, isfiling }) => {
  function _displayText() {
    if (isfiling) {
      return combinedDoc.length > 1
        ? `${combinedDoc.length} Activities`
        : _formatFilingType(combinedDoc[0].topic[0], combinedDoc[0].event_tags);
    }
    return combinedDoc.length > 1
      ? `${combinedDoc.length} Transfers`
      : _formatPrice(combinedDoc[0].price);
  }

  return <div className="u_font-12">{_displayText()}</div>;
};

export const ListingPopup = ({
  authData,
  fromTabbedContainer,
  isMobile,
  listings,
  onClick,
  tabInfo,
}) => {
  function _displayAddress(adr, unit, showUnit) {
    if (adr && unit && showUnit & !fromTabbedContainer) {
      return (
        <div className="map-listing-popup-address">
          <div className="c_title u_600-wgt">{unit}</div>
          <div className="u_font-12">{adr}</div>
        </div>
      );
    } else if (adr && !fromTabbedContainer) {
      return <div className="c_title u_600-wgt">{adr}</div>;
    }
  }

  function _listingItem(listing) {
    const hood = _shouldShow(listing.neighborhood)
      ? Array.isArray(listing.neighborhood)
        ? listing.neighborhood[0]
        : listing.neighborhood
      : "nyc";

    const type =
      (((listing && listing.type && listing.type.includes("townhouse")) ||
        (listing && listing.type && listing.type.includes("family")) ||
        (listing && listing.type && listing.type.includes("families")) ||
        (listing && listing.type && listing.type.includes("building"))) &&
        listing &&
        listing.listing_type == "sale") ||
      !listing.address_2_normalized
        ? //  (listing.address_2_normalized &&
          //    (listing.address_2_normalized === 'th' ||
          //      listing.address_2_normalized === 'building' ||
          //      listing.address_2_normalized === 'house'
          //    )
          //  )
          "building"
        : "unit";

    const listingDisplay = listing
      ? _getListingDisplayRules(listing, authData)
      : false;

    const showUnit = listingDisplay && listingDisplay.showUnit;

    const listingKey =
      listing.web_id && _shouldShow(listing.web_id) ? listing.web_id : false;
    const unit = _getUnitDisplay(listing, type);
    const adr = _getListingAdr(listing);
    const listingTagInfo = _getListingTagInfo(type, listing, adr, unit);
    const unitObj = _getListingUnitTag(listingTagInfo, type);
    const urlObj = _getUrlObj(listing, listingTagInfo, hood);
    
    const owner = listing.owner
    ? _formatOwnerName(listing.owner.split(/,(?=\S)/))
    : false;
    const seller = listing.seller
    ? _formatOwnerName(listing.seller.split(/,(?=\S)/))
    : false;
    return (
      <li
        key={listing.id}
        className={`map-listing-popup-list-item ${
          isMobile ? "is-mobile" : "is-desktop"
        }`}
        onClick={onClick(urlObj, listing, {
          building: {
            display_adr: listingTagInfo.display_adr,
            slug: listingTagInfo.building_slug,
            hood: hood ? hood : "nyc",
            boro: listing.borough ? listing.borough : "new york",
          },
          unit: unitObj,
          clearAll: true,
        })}
      >
        <div
          className={`map-listing-popup-img-container ${
            isMobile ? "mobile-popup-padding" : "u_pd-5px"
          }`}
        >
          <div className="ribbon">
            <span>
              {listing.property_type != "pa"
                ? _capitalizeText(listing.property_type_desc)
                : ""}
            </span>
          </div>

          <img
            src={`https://newsapi.marketproof.com/buildings/pictures/${listing.building}`}
          />
        </div>

        <div
          className={`map-listing-popup-list-item-info ${
            isMobile ? "mobile-popup-padding" : ""
          }`}
          style={{ marginLeft: "10px" }}
        >
          <div className="c_title map-listing-popup-price u_pd-top-5px">
            <span className={"bold-text"}>
              {listing.price > 0
                ? _listingPrice(listing.price)
                : "$0 (Non-market)"}{" "}
            </span>
          </div>
          <div>
            <span>
              {_shouldShow(listing.close_date_formatted) &&
                moment(listing.close_date_formatted).format("M/D/YYYY")}{" "}
            </span>
          </div>

          {_displayAddress(adr, unit, showUnit)}

          {false && <div className="u_font-11">{_capitalizeText(hood)}</div>}

          <div className="u_font-12">
            <div>
              {_shouldShow(listing.sq_ft) && listing.sq_ft > 10000
                ? _formatNumber(listing.sq_ft) + " SF"
                : ""}
              {/* {_shouldShow(listing.ownership_type)
                ? listing.ownership_type
                : ""} */}
              {/* need to be done add sold date of building */}
            </div>
          </div>
          {seller && <div style={{fontSize:"10px",color:"#A3ADC2",marginTop:3}}><span style={{display:"inline-block",width:28,textAlign:"right"}}>From:</span> {_truncateWords(seller, 5)}</div>}
          {owner && <div style={{fontSize:"10px",color:"#A3ADC2",marginTop:0}}><span style={{display:"inline-block",width:28,textAlign:"right"}}>To:</span> {_truncateWords(owner, 5)}</div>}
        </div>
      </li>
    );
  }

  return (
    <ul className="map-listing-popup-list">
      {listings
        .sort((a, b) =>
          a.close_date_formatted > b.close_date_formatted ? -1 : 1
        )
        .map((listing) => _listingItem(listing))}
    </ul>
  );
};

export const ListingFilingPopup = ({
  authData,
  fromTabbedContainer,
  isMobile,
  listings,
  onClick,
  tabInfo,
}) => {
  function _displayAddress(adr, unit, showUnit) {
    if (adr && unit && showUnit & !fromTabbedContainer) {
      return (
        <div className="map-listing-popup-address">
          {/* <div className="c_title u_600-wgt">{unit}</div> */}
          <div className="u_font-12">{adr}</div>
        </div>
      );
    } else if (adr && !fromTabbedContainer) {
      return <div className="c_title u_600-wgt">{adr}</div>;
    }
  }

  function _listingItem(listing) {
    const hood = _shouldShow(listing.neighborhood)
      ? Array.isArray(listing.neighborhood)
        ? listing.neighborhood[0]
        : listing.neighborhood
      : "nyc";

    const type =
      (((listing && listing.type && listing.type.includes("townhouse")) ||
        (listing && listing.type && listing.type.includes("family")) ||
        (listing && listing.type && listing.type.includes("families")) ||
        (listing && listing.type && listing.type.includes("building"))) &&
        listing &&
        listing.listing_type == "sale") ||
      !listing.address_2_normalized
        ? //  (listing.address_2_normalized &&
          //    (listing.address_2_normalized === 'th' ||
          //      listing.address_2_normalized === 'building' ||
          //      listing.address_2_normalized === 'house'
          //    )
          //  )
          "building"
        : "unit";

    const listingDisplay = listing
      ? _getListingDisplayRules(listing, authData)
      : false;

    const showUnit = listingDisplay && listingDisplay.showUnit;

    const listingKey =
      listing.web_id && _shouldShow(listing.web_id) ? listing.web_id : false;
    const unit = _getUnitDisplay(listing, type);
    const adr = _getListingAdr(listing);
    const listingTagInfo = _getListingTagInfo(type, listing, adr, unit);
    const unitObj = _getListingUnitTag(listingTagInfo, type);
    const urlObj = _getUrlObj(listing, listingTagInfo, hood);
    let listingData = listing.data ? listing.data : false;

    if (listing && listing.data && listing.data.filings_data) {
      listingData = listing.data.filings_data;
    }
    return (
      <li
        key={listing.id}
        className={`map-listing-popup-list-item ${
          isMobile ? "is-mobile" : "is-desktop"
        }`}
        onClick={onClick(urlObj, listing, {
          building: {
            display_adr: listingTagInfo.display_adr,
            slug: listingTagInfo.building_slug,
            hood: hood ? hood : "nyc",
            boro: listing.borough ? listing.borough : "new york",
          },
          unit: unitObj,
          clearAll: true,
        })}
      >
        <div
          className={`map-listing-popup-img-container ${
            isMobile ? "mobile-popup-padding" : "u_pd-5px"
          }`}
        >
          <div className="ribbon">
            <span>
              {listingData.occupancy_classification_proposed &&
              _getOccupancyCode(listingData.occupancy_classification_proposed)
                ? _getOccupancyCode(
                    listingData.occupancy_classification_proposed
                  )
                : listingData.proposed_occupancy &&
                  _getOccupancy(
                    listingData.proposed_occupancy.replace(/-/g, "")
                  )
                ? _getOccupancy(
                    listingData.proposed_occupancy.replace(/-/g, "")
                  )
                : "--"}
            </span>
          </div>

          <img
            src={`https://newsapi.marketproof.com/buildings/pictures/${listing.building}`}
          />
        </div>

        <div
          className={`map-listing-popup-list-item-info ${
            isMobile ? "mobile-popup-padding" : ""
          }`}
          style={{ marginLeft: "10px" }}
        >
          <div className="c_title map-listing-popup-price u_pd-top-5px">
            <span className={"bold-text"}>
              {_formatFilingType(listing.topic[0], listing.event_tags)}
            </span>
          </div>
          <div className="c_title map-listing-popup-price u_pd-top-5px">
            <span>
              {_shouldShow(listing.units)
                ? _formatNumber(listing.units) + " Units"
                : ""}
            </span>
          </div>

          <div>
            <span>
              {_shouldShow(listing.time) &&
                moment(listing.time).format("M/D/YYYY")}{" "}
            </span>
          </div>

          {_displayAddress(adr, unit, showUnit)}

          {false && <div className="u_font-11">{_capitalizeText(hood)}</div>}

          <div className="u_font-12">
            <div>
              {_shouldShow(listing.sq_ft)
                ? _formatNumber(listing.sq_ft) + " SF"
                : ""}
              {/* {_shouldShow(listing.ownership_type)
                ? listing.ownership_type
                : ""} */}
              {/* need to be done add sold date of building */}
            </div>
          </div>
        </div>
      </li>
    );
  }

  return (
    <ul className="map-listing-popup-list">
      {listings
        .sort((a, b) =>
          a.close_date_formatted > b.close_date_formatted ? -1 : 1
        )
        .map((listing) => _listingItem(listing))}
    </ul>
  );
};



export const _getAssociatedHoods = (obj, existingHoods) => {
  let nabeMap = {
    "upper east side": [
      { hood: "lenox hill", boro: "manhattan" },
      { hood: "yorkville", boro: "manhattan" },
      { hood: "carnegie hill", boro: "manhattan" },
    ],
    "upper west side": [
      { hood: "manhattan valley", boro: "manhattan" },
      { hood: "lincoln square", boro: "manhattan" },
    ],
    "midtown west": [
      { hood: "clinton", boro: "manhattan" },
      { hood: "hudson yards", boro: "manhattan" },
      // {hood: "jacob javits center", boro: "manhattan"},
    ],
    "midtown east": [
      { hood: "turtle bay", boro: "manhattan" },
      { hood: "murray hill", boro: "manhattan" },
      { hood: "sutton place", boro: "manhattan" },
      { hood: "kips bay", boro: "manhattan" },
      { hood: "beekman", boro: "manhattan" },
    ],
    midtown: [
      { hood: "turtle bay", boro: "manhattan" },
      { hood: "murray hill", boro: "manhattan" },
      { hood: "sutton place", boro: "manhattan" },
      { hood: "kips bay", boro: "manhattan" },
      { hood: "beekman", boro: "manhattan" },
      { hood: "clinton", boro: "manhattan" },
      { hood: "hudson yards", boro: "manhattan" },
      { hood: "midtown south", boro: "manhattan" },
      { hood: "central park south", boro: "manhattan" },
      // {hood: "kips bay", boro: "manhattan"},
      // {hood: "jacob javits center", boro: "manhattan"},
    ],
    "financial district": [{ hood: "fulton seaport", boro: "manhattan" }],
    "lower east side": [{ hood: "two bridges", boro: "manhattan" }],
    dumbo: [{ hood: "vinegar hill", boro: "brooklyn" }],
    "east new york": [
      { hood: "city line", boro: "brooklyn" },
      { hood: "new lots", boro: "brooklyn" },
      { hood: "starrett city", boro: "brooklyn" },
      { hood: "spring creek", boro: "brooklyn" },
    ],
    "long island city": [{ hood: "hunter's point", boro: "queens" }],
  };

  let hoodsConcat = [];
  if (nabeMap && obj.hood && nabeMap[obj.hood]) {
    hoodsConcat.push(obj);
    hoodsConcat = hoodsConcat.concat(nabeMap[obj.hood]);
  } else {
    hoodsConcat.push(obj);
  }

  const existingHoodsObj = {};
  existingHoods.forEach(
    (tag) => (existingHoodsObj[`${tag.hood}_${tag.boro}`] = true)
  );

  return hoodsConcat.filter(
    (tag) => !existingHoodsObj[`${tag.hood}_${tag.boro}`]
  );
};
