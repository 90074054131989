/* eslint-disable react/no-children-prop */
import React, { Fragment } from 'react';
import {
   Card, CardBody, Col, Badge, Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from 'mdi-react/CloudDownloadIcon';
import{
	subscriptionPlans,
	_capitalizeText
} from '../../../shared/helpers/utils';

const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined


const Invoices = ( props ) => {
	const { cancelSubscription, reset, user, invoices } = props;
	//console.log(user);
	const { subscriptionPlan } = user;
	const currentPlan = subscriptionPlans.filter(plan => plan.id === subscriptionPlan)[0];
	return(	  
	  <Col md={12} lg={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'Invoice History'}</h5>
	          <h5 className="subhead"></h5>
	        </div>		     
	     	<Table striped responsive>
          <thead>
            <tr>
              <th></th>
              <th>Date</th>
              
              <th>Amount Due</th>
              <th>Description</th>
              <th>Invoice #</th>
              
              <th>Status</th>
              

            </tr>
          </thead>
          <tbody>
          {invoices && invoices.map(invoice => {
	          return (
		          <tr>
		          	  <td style={{ padding: '3px 5px'}}>
			          	<Tooltip title="Download Invoice as PDF">	  
				          	<IconButton	
				              		className="material-table__toolbar-button"
				              		onClick={(e) => {e.stopPropagation()}}
				              	>          		
								  <DownloadIcon size={18} onClick={(e) => {window.open(`${invoice.invoice_pdf}`)}} /> 
							</IconButton>
						</Tooltip>	
			          </td>
		              <td>{moment.unix(invoice.period_start).format('L')}</td>
		              
		              <td>${(invoice.amount_due/100).toFixed(2)}</td>
		              <td>
			              {invoice.lines && invoice.lines.data && invoice.lines.data.map(line => {
				              return <div>{_capitalizeText(line.description)} Plus Tax</div>
			              })}
			              
			           </td>	
			           <td><a href={`${invoice.hosted_invoice_url}`} target="_blank" style={{color:'#9600BF'}}>{invoice.number}</a></td>	              		              		              
			          
					 <td>{invoice.status === 'paid' ? 
			              <Badge color="success">{invoice.lines && invoice.lines.data && invoice.lines.data[0]
				              	&& invoice.lines.data[0].description.indexOf('Trial') >= 0 ? 'Trial' : _capitalizeText(invoice.status)}</Badge>
			              :
			              <Badge color="">{invoice.status}</Badge>
			              }
			          </td>
			          
		            </tr>
	          )
	          
          })}           
          </tbody>
        </Table>        
	      </CardBody>
	    </Card>
	  </Col>
	)
};


export default Invoices;
