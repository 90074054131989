import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import Icon from 'mdi-react/CurrencyUsdIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";
import ToolTip from '../../../../../shared/components/ToolTip';

const AveragePPSFSold = (props) => {
	//if(!props) return null;
	//if(!props.transactionRes) return null;
	let ppsf = props.transactionRes && props.transactionRes.ppsf && props.transactionRes.ppsf.avg_ppsf > 0 ? props.transactionRes.ppsf.avg_ppsf : 0;
	if(!props.transactionRes) ppsf = 0;
	if(props.transactionRes && props.transactionRes.ppsf && props.transactionRes.ppsf.sum_sold_price && props.transactionRes.ppsf.sum_sq_ft && props.transactionRes.ppsf.sum_sold_price && props.transactionRes.ppsf.sum_sq_ft>0){
		ppsf = props.transactionRes.ppsf.sum_sold_price / props.transactionRes.ppsf.sum_sq_ft;
	}
	return (
		 <Col md={12} xl={4} lg={6} xs={12}>
		    <Card>
		    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
		      <CardBody className="dashboard__booking-card card__link">
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            ${ppsf ? 
			            _formatNumber(ppsf) 				       
			            : '--'}
		          </h5>
		          <Icon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">Avg Sold PPSF
					{props.buildingRes.building_status == "sponsor" ?
					
						<ToolTip title={"Average price per square foot of verified ACRIS sponsor closings excluding multi-parcel or non-market transfers.  Sum prices divided by sum square feet."} />
					:
						<ToolTip 
						title={"Average price per square foot of verified ACRIS closings during the selected timespan, excluding multi-parcel or non-market transfers.  Sum prices divided by sum square feet."}
						/>
					}
				</h5>
		      </CardBody>
		    </Link>
		    </Card>
		  </Col>
	)
		

	 	
		
	
};

export default AveragePPSFSold;
