import React, { PureComponent, Fragment } from 'react';
import { Progress, Table, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../shared/components/Panel';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	SalesProgress,
	setURLFromState
} from '../../../../../shared/helpers/utils';


	
class UnitBreakdown extends PureComponent {
  static propTypes = {
   	t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }
  _generateUnitMixBreakdown = (statsRes) => {
	 if(!statsRes.unit_mix || !statsRes.unit_mix.buckets){
		  return [];
	  }
	 const total = statsRes.unit_mix.buckets.map(bucket => bucket.count).reduce((accumulator, current) => accumulator + current );
	 
	 let data = [
	   {
	    'name': 'Studio',
	    'search' : 'studio',
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0
	  },
	  {
	    'name': '1 Bed',
	    'search' : '1-bedroom',
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0
	  },
	  {
	    'name': '2 Bed',
	    'search' : '2-bedroom',
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0
	  },
	  {
	    'name': '3 Bed',
	    'search' : '3-bedroom',
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0
	  },
	  {
	    'name': '4+ Bed',
	    'search' : '4-or-more',
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0
	  },

	 
	];

	 let other = 0;
	 statsRes.unit_mix.buckets.sort((a, b) =>  a.val - b.val).map((bucket, i) => {
		 if(bucket.val<0){
			 return false;
		 }		 
		 
		 const stat = bucket.non_zero_price ? bucket.non_zero_price : false;		 
		 if(bucket.val < 4){
			 data[bucket.val]['count'] = bucket.count;
			 data[bucket.val]['size'] = stat.avg_unit_size;
			 data[bucket.val]['price'] = stat.avg_ppsf;
			 data[bucket.val]['carrying_cost'] = stat.avg_monthly_carrying_charges;
			 data[bucket.val]['avg_fees'] = stat.avg_fees;
			 data[bucket.val]['min_price'] = stat.min_price;
			 data[bucket.val]['max_price'] = stat.max_price;
			 data[bucket.val]['color'] = getDefaultChartColors[bucket.val];	
			 
			 data[bucket.val].unsold = bucket.unsold && bucket.unsold.count ? bucket.unsold.count : 0;	
/*
			 if(bucket.by_status && bucket.by_status.buckets){
				 bucket.by_status.buckets.map((status, i) => {					 
					 if(["sold", "not_yet_recorded", "in contract", "pending"].indexOf(status.val) >=0 ){
						 data[bucket.val].sold += status.count;
						 data[bucket.val].unsold -= status.count;
					 }					 
				 })
			} 
*/	
		}
	 });
	 
	 const fourMore = statsRes['4_more_beds'];
	 const fourmoreStats = statsRes['4_more_beds_status'];
	 if(fourMore && fourMore.count > 0){
		 data[4]['count'] = fourMore.count;
		 data[4]['size'] = fourMore.non_zero_price && fourMore.non_zero_price.avg_unit_size ? fourMore.non_zero_price.avg_unit_size : 0;
		 data[4]['price'] = fourMore.non_zero_price && fourMore.non_zero_price.avg_ppsf ? fourMore.non_zero_price.avg_ppsf : 0;
		 data[4]['carrying_cost'] = fourMore.non_zero_price && fourMore.non_zero_price.avg_monthly_carrying_charges ? fourMore.non_zero_price.avg_monthly_carrying_charges : 0;
		 data[4]['avg_fees'] = fourMore.non_zero_price && fourMore.non_zero_price.avg_fees ? fourMore.non_zero_price.avg_fees : 0;
		 data[4]['min_price'] = fourMore.non_zero_price && fourMore.non_zero_price.min_price ? fourMore.non_zero_price.min_price : 0;
		 data[4]['max_price'] = fourMore.non_zero_price && fourMore.non_zero_price.max_price ? fourMore.non_zero_price.max_price : 0;
		 data[4]['color'] = getDefaultChartColors[4];		 
		 //data[4].unsold = fourMore.count;	
		 data[4].unsold = fourMore.unsold && fourMore.unsold.count ? fourMore.unsold.count : 0;	
/*
		 if(fourmoreStats.by_status && fourmoreStats.by_status.buckets){
			 fourmoreStats.by_status.buckets.map((status, i) => {					 
				 if(["sold", "not_yet_recorded", "in contract", "pending"].indexOf(status.val) >=0 ){
					 data[4].sold += status.count;
					 data[4].unsold -= status.count;
				 }					 
			 })
		} 
*/
		 
	  }	 
	 return data;
	  
  }
  
  handleClick(e,unit) {
  	console.log(e, e.target);

  	const { history } = this.props; 
    const newState = Object.assign({}, this.props); 	
	 if(newState.subFilters && newState.subFilters.offering_bedrooms){
		 	delete( newState.subFilters.offering_bedrooms);
	 }
 	 const baseURL = setURLFromState({}, newState, true);
	
  	history.push(`/inventory${baseURL}&bedrooms=${unit.search}`);

  }

  
  render() {

    const { facetData } = this.props;
    
    if(!facetData){
	    return null;
    }
    
    const data = this._generateUnitMixBreakdown(facetData);

	return (
		<Card>
		    <CardBody className="dashboard__booking-card" >
		    <Table responsive striped className="table--bordered dashboard__audience-table" style={{borderTop:0, marginTop:-10}}>
		      <thead>
		        <tr>
		          <th>Unit Type</th>
		          <th>Unsold Sponsor Units</th>
		          <th>Avg Size</th>
		          <th>Avg PPSF</th>
		          <th>Avg CC</th>
		          <th>Range</th>
		        </tr>
		      </thead>
		      <tbody>
		        {data.map(unit => 
		        <tr onClick={(e) => this.handleClick(e, unit)} className="tr__link">
		          <td><span style={{ backgroundColor: unit.color, width:"10px", height:"10px", display:"inline-block" }} /> {unit.name}</td>
		          <td>
		            <SalesProgress progress={parseFloat((unit.unsold / unit.count) * 100).toFixed(0)} inventory_unsold={unit.unsold ? _formatNumber(unit.unsold) : "0"} inventory_total={unit.count ? _formatNumber(unit.count) : "0"} />
		          </td>
		          <td>{unit.size > 0 ? _formatNumber(unit.size) + ' sq ft' : '--'}</td>
		          <td>{unit.price && Math.abs(unit.price) > 0 ? `$${_formatNumber(Math.abs(unit.price))}` : '--'}</td>
		          <td>${_formatNumber(unit.avg_fees)}<span className="small-text">/mo</span></td>
		          <td>
		            {unit.min_price && unit.min_price > 0 ? `${_formatPrice(unit.min_price)} - ${_formatPrice(unit.max_price)}` : '--'}
		          </td>
		          
		        </tr>
		        )}
		      </tbody>
		    </Table>
			</CardBody>
		</Card>
	  )
	  
  }
}

export default withTranslation('common')(UnitBreakdown);
