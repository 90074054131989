import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import CircleSlice4Icon from 'mdi-react/CircleSlice4Icon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';



const numberListed = (buildingRes, statsRes, period) => {	
	
	if(!buildingRes.sales_start_date) return '--';
	
	let listed = buildingRes.inventory_listed;
	if(period && period === "1-yr"){
		if(statsRes && statsRes.listed_1_yr && statsRes.listed_1_yr.count >= 0){
			listed = statsRes.listed_1_yr.count
			
		}
	}else if(period && period === "3-mo"){
		if(statsRes && statsRes.listed_3_mo && statsRes.listed_3_mo.count >= 0){
			listed = statsRes.listed_3_mo.count
			
		}
	}else if(period && period === "6-mo"){
		if(statsRes && statsRes.listed_6_mo && statsRes.listed_6_mo.count >= 0){
			listed = statsRes.listed_6_mo.count			
		}
	}
	
	
	return listed;
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

  
const calculateListed = (buildingRes, statsRes, period) => {	
	
	if(!buildingRes.sales_start_date) return '--';
	
// 	let listed = parseFloat(buildingRes.inventory_pct_listed).toFixed(1);
	
	let listed = parseFloat((numberListed(buildingRes, statsRes, period) / buildingRes.inventory_total) * 100).toFixed(1);
	
	
	
	return listed;
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes, statsRes, period) => {	
	
	const listed = numberListed(buildingRes, statsRes, period);
	const shadow = 	buildingRes.inventory_total - listed;
	return [{ value: listed, fill: getDefaultChartColors[2] },
  				{ value: shadow, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const PercentShadow = (props) => {	
	const { buildingRes, statsRes, period, location } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	
	return (
	  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
	    <Card>
	    <Link to={`${base_url}`}>
	      <CardBody className="dashboard__health-chart-card card__link">
	        <div className="card__title">
	          <h5 className="bold-text card__title-center">{`Listed vs. Shadow (${period === '3-mo' ? '3 Mo' : period === '6-mo' ? '6 Mo' : period === '1-yr' ? '12 Mo' : 'All Time'})`}
	          	<ToolTip 
		        		title={`Number of units listed ${period === '3-mo' ? 'in the last 3 months' : period === '6-mo' ? 'in the last 6 months' : period === '1-yr' ? 'in the last 1 year' : ' since sales launched'}.`} 
		        	/>
	          </h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={180}>
	            <PieChart>
	              <Pie data={calculateBarData(buildingRes, statsRes, period)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="dashboard__health-chart-info">
	            <CircleSlice4Icon style={{ fill: getDefaultChartColors[2] }} />
	            <p className="dashboard__health-chart-number">{calculateListed(buildingRes, statsRes, period)}<span className="small-text">%</span></p>
	            <p className="dashboard__health-chart-units">{numberListed(buildingRes, statsRes, period)} units listed</p>
	          </div>
	        </div>
	        <p className="dashboard__goal">{props.buildingRes.inventory_shadow} of {props.buildingRes.inventory_total} Units Never Listed</p>
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
    )
};

export default PercentShadow;
