import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Badge, Progress } from 'reactstrap';
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import { connect } from 'react-redux';

import EditIcon from 'mdi-react/PencilIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import classNames from 'classnames';
import todoCard from '../types';
import BuyersListCount from './BuyersListCount';
import AddToBuyersListButton from '../../../shared/components/buttons/AddToBuyersListButton';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import ShareButton from '../../../shared/components/buttons/ShareButton';


import moment from "moment";


import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_shouldShow,
	_getBuildingURL,
	_getBuildingURLBase,
	BuildingCurrentStatus,
	BuildingPhoto,
	SalesProgress
} from '../../../shared/helpers/utils';

const UnitsAvailable = ({ item }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': item.priority === 'medium',
    'dashboard__table-orders-amount--low': item.priority === 'low',
    'dashboard__table-orders-amount--high': item.priority === 'high',
  });
  if (item.priority === 'high') {
    return (
	  <div className="dashboard__stat-item"  >
        <div className="dashboard__stat">
          <div className="progress-wrap progress-wrap--small progress-wrap--purple-gradient progress-wrap--rounded">
            <p className="progress__label">{item.inventory_unsold} Available</p>
		      <div className={amountClass}>
		        <div />
		        <div />
		        <div />
		        <div />
		        <div />
		      </div>
			 </div>
        </div>
      </div>
    );
  } if (item.priority === 'medium') {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
      </div>
    );
  } 
  return (
    <div className={amountClass}>
      <div />
    </div>
  );
};



class BuyersListItem extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editTodo: PropTypes.func,
      completeTodo: PropTypes.func,
      deleteTodo: PropTypes.func,
      updateTodo: PropTypes.func,
    }).isRequired,
    todo: todoCard.isRequired,
  };

  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = {
      completed: item.completed,
      priority: item.priority,
      listCount: item.listCount,
    };
  }

  handleComplete() {
    const { item, actions } = this.props;
    const { checked } = this.state;

    actions.completeTodo(item.id);
    this.setState({
      checked: !checked,
    });
  }

  handleDelete(e) {
	e.stopPropagation(); 
    const { item, actions } = this.props;

    actions.deleteTodo(item.id);
  }

  handleEdit() {
    const { item, actions } = this.props;

    actions.editTodo(item.id);
  }

  handleUpdate() {
    const { item, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updateTodo(item.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 window.open(buildingURL);
  };
  

  render() {
    const {
      completed, priority, listCount,
    } = this.state;
    
    const { item, user, analytics } = this.props;
    
    
	const buildingNameOrAdr = item.name 
      ? _capitalizeText(item.name)
      : item.display_full_street_address
        ? _capitalizeText(item.display_full_street_address)
        : _capitalizeText(item.full_street_address);
	const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
	
	const _boro = item.borough;
	const _hood = item.neighborhood[0];
	
	const buildingURLBase = _getBuildingURLBase(item, 'key');

    const priorityColorsClass = classNames({
      'todo__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });
    
    const savedCount = analytics && analytics.length>0 && analytics.filter(a => a.key === item.key)[0] ? analytics.filter(a => a.key === item.key)[0].total : '--'

    return (
      <Card>
        <CardBody className="todo__item card__link" onClick={event => this.handleBuildingClick(event, item)}>
          
         {/* <label htmlFor={title} className="todo__label-checkbox">
            <input
              id={title}
              type="checkbox"
              className="todo__complete-toggle"
              defaultChecked={completed}
              required
              onClick={this.handleComplete.bind(this)}
            />
            <span className="checkbox-indicator" />
          </label>*/}
          <Row>
	          <Col sm={12} md={12} lg={2} xl={2}>
	          	<BuildingPhoto item={item} />
	          </Col>
	          <Col sm={12} md={12} lg={8} xl={8}>
		          <div className="todo__info">
		            <div className="todo__header">
		              <h3>
		              	{buildingNameOrAdr}
					  	<BuildingIcons building={item} />
		              </h3>
		              <div className="todo__description">{item.name ? _capitalizeText(adr) + ', ': ''}{_capitalizeText(_boro)}, {_capitalizeText(_hood)}</div>
		              <div className="todo__tools">
		              	  {user && ['seller', 'enterprise'].indexOf(user.accountType) > -1 &&
			              	  <Tooltip title="Update Building Details">	            		
				              	<IconButton	
				              		className="material-table__toolbar-button"
				              		onClick={(e) => {e.stopPropagation()}}
				              	>
								  <EditIcon /> 
							  	</IconButton>
							  </Tooltip>
			              	  
		              	  }
		              	  <ShareButton buildingRes={item} small />
		              	  <AddToBuyersListButton buildingKey={item.key} removeOnly />
		              
			             <Tooltip title="See Building Profile">	            		
				              	<IconButton	
				              		className="material-table__toolbar-button"
				              		onClick={(e) => {e.stopPropagation();window.open(`https://marketproof.com/building/${buildingURLBase}`)}}
				              	>
								  <OpenInNewIcon /> 
							  	</IconButton>
						  </Tooltip>
			
			               {/*<Tooltip title="Remove From My BuyersList">
			              	<IconButton
					            onClick={(e) => this.handleDelete(e)}
					            className="material-table__toolbar-button"
					          >
							  <TrashCanOutlineIcon color="#ddd"/>
						  	</IconButton>
					  	  </Tooltip> */}
			              
			            </div>
		              <div className="todo__additional">
		            {/*    <p className="todo__due-date">Joined: {moment(item.solr_updated_date).fromNow()}</p>
		                <span className="todo__small-divider" /> */}
		                
		                <BuildingCurrentStatus item={item} />
		                <span className="todo__small-divider">|</span>
						<span className="light-text small-text">{item.inventory_stats_listings_low_price > 100 ? `${_formatPrice(item.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(item.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</span>				    <span className="todo__small-divider">|</span>

						<span className="light-text small-text">{_formatNumber(item.inventory_unsold)} Unsold Units</span>
						<span className="todo__small-divider">|</span>
		                <span className="light-text small-text">{_formatNumber(item.inventory_total)} Total Units</span>

		                
		              </div>
		            </div>
					</div>
				</Col>
				{!isMobile &&
				<Col sm={12} md={12} lg={2} xl={2} className="todo-app__vertical-divider-line">
				   {/*<div onClick={event => this.handleBuildingClick(event, item, 'tab/buyerslist')}>
						<BuyersListCount listCount={listCount} />
					</div>*/}
					<div>
						<BuyersListCount listCount={savedCount} />
					</div>
				</Col>
				}
			</Row>
                        
            
		  
        </CardBody>
      </Card>
    );
  }
}
export default withRouter(connect(state => ({
  user: state.user,
}))(BuyersListItem));
