import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import SupplyIcon from 'mdi-react/HomeOutlineIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
} from "../../../../shared/helpers/utils";

const Supply = (props) => {
	  if(!props.facetData) return null;
	
 	  const total_unsold = props.facetData.total_inventory_unsold;
	  return (
		    <Card>
		      <CardBody className="dashboard__booking-card">
		      
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            {total_unsold ? _formatNumber(total_unsold) : '--'}
		          </h5>
		          <SupplyIcon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">Unsold Units</h5>
		      
		      </CardBody>
		    </Card>
	  );
};

export default Supply;
