import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import ActiveIcon from 'mdi-react/CheckCircleOutlineIcon';
import VetIcon from 'mdi-react/AlertDecagramOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';


import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class TitleVettedStatus extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
  
  
  openAcris = (transaction)  => {
	if(transaction.borough_code && transaction.block && transaction.lot){
		window.open(`https://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${transaction.borough_code}&block=${transaction.block}&lot=${transaction.lot}`)
	}
	
  }
 

  render(){
	  const { modal } = this.state;
	  const { transaction, contract, admin } = this.props;
	 
	  const modalClass = classNames({
	      'modal-dialog--colored': false,
	      'modal-dialog--header': true,
	   });
	   //<a href={`https://www.nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/${transaction.verified_loan_officer_nmls_id[0]}`} className="link-text" target="_blank">
	   if(transaction && transaction.verified_title_company_name && transaction.verified_title_company_name.length>0 ){
			 return (
			   <Fragment>
				   <span className="offering-status" onClick={this.toggle}>
						 <Tooltip title="Verified Loan Information">
							 <IconButton size={`small`} onClick={this.toggle} style={{ marginTop:"-1px"}} >
								 <ActiveIcon size={16} color={'#780F9E'} />
							 </IconButton>
						 </Tooltip>
						 <br/>
						 <span className="link-text">(Click for Details)</span>
					 </span>		  		
					 <Fragment>
					   <Modal
						 isOpen={modal}
						 toggle={this.toggle}
						 modalClassName={`ltr-support`}
						 className={`modal-dialog--primary ${modalClass}`}
					   >
					   <div className="modal__header">
						   <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
						   <h4 className="text-modal  modal__title"><ActiveIcon size={24} color={'#EDDEF5'} />Verified Title Company</h4>
						 </div>
						 <div className="modal__body">
							 <p style={{fontSize:16}}>Title company and associated info has been verified by recorded ACRIS documents.</p>
							 <hr/>
							
							 {transaction.verified_title_company_name && transaction.verified_title_company_name[0] &&
								   <p style={{fontSize:16}}><strong>Title Company:</strong> {_capitalizeText(transaction.verified_title_company_name[0])}</p>
							 }
							 {transaction.verified_title_company_phone && transaction.verified_title_company_phone[0] &&
									<p style={{fontSize:16}}><strong>Title Company Phone:</strong> {transaction.verified_title_company_phone[0]}</p>
							  }
							{transaction.verified_title_company_email && transaction.verified_title_company_email[0] &&
									<p style={{fontSize:16}}><strong>Title Company Email:</strong> {transaction.verified_title_company_email[0]}</p>
							  }	   
						 </div>
						 
						 <ButtonToolbar className="modal__footer offering-status">
						   {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
						   <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
							   Ok
						   </Button>
						 </ButtonToolbar>
					   </Modal>
				   </Fragment>
			   </Fragment>
			 )
		}else if(admin){
		 return (
			 <Fragment>
		 		<span className="offering-status">
			   	<Tooltip title="Verify Title Information">
				   	<IconButton size={`small`} onClick={(e) => {e.preventDefault();e.stopPropagation();this.openAcris(transaction)}} style={{ marginTop:"-1px"}} >
					   	<VetIcon size={16} color={'#780F9E'} />
				   	</IconButton>
			   	</Tooltip>
		   		</span>		
		 	</Fragment>
	 	)
	 }else{
		 return null;
	 }
 }
	
};


export default TitleVettedStatus;
