import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NewsTimeline from './components/NewsTimeline';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import { connect } from 'react-redux';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const Timeline = (props) => (
  <Container className="news__container">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{'News'}</h3>
        {!isMobile && <h3 className="page-subhead subhead">See the Latest News and Events by Building</h3>}
      </Col>
    </Row>
    <Row>
      <Col md={12} className={'mb-3'}>
       	<TrialAlert />        
      </Col>
    </Row>
    <Row>
      <NewsTimeline {...props}/>
    </Row>
    <Row>
      <Col md={12}>
        <h3 className="page-title"></h3>
        <h3 className="page-subhead subhead"></h3>
      </Col>
    </Row>
  </Container>
);

Timeline.propTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(state => ({
  user: state.user,
}))(withTranslation('common')(Timeline));
