import React from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import MainIcon from 'mdi-react/FileDocumentOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/MagnifyIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const EmailConfirmationCard = ( props ) => {

  return (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className={`email-confirmation short`}>
          <div className="email-confirmation__icon">
            <MainIcon className="email-confirmation__mail" />
          </div>
          <h3 className="email-confirmation__title">{`Nothing Shared`}</h3>
          {props && props.subTab ?
            <p style={{fontSize:16}}>You have not shared this collection yet.</p>
            :
            <p style={{fontSize:16}}>You have not shared anything yet.</p>
          }
          <ButtonToolbar className="email-confirmation__buttons">
            
		     {/*<a href="" target="_blank">
            <Button
		         variant="contained"
		         color="primary"
		         className="buyersList__primary-button"		         
		         startIcon={null}
		     >	About Sharing
		     </Button>	
		     </a>*/}
		     
          </ButtonToolbar>
        </div>
      </CardBody>
    </Card>
  </Col>
  )
};


export default EmailConfirmationCard;
