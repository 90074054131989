import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/InvertColorsIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (floodData, floodzoneColors) => {	
	
	
	
	return [{ value: 50, fill: floodzoneColors[floodData.flood_zone] },
				  { value: 50, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, data, floodData, floodzoneTranslateShort, floodzoneTranslate, floodzoneColors } ) => {
	const floodRiskDesc = floodData.flood_zone == "X" ?  "Minimal Risk Zone"
	:`${floodzoneTranslateShort[floodData.flood_zone]}`;
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10 }}  >
			<div className="card__title">
			  <div className="card__title">
				  <h5 className="bold-text card__title-center">{`Flood Risk`}
					  <ToolTip 
							title={floodzoneTranslate[floodData.flood_zone]} 
						/>	          
				  </h5>
				</div>
			</div>
			<div className="dashboard__health-chart"> 
			  <ResponsiveContainer height={180}>
				<PieChart>
				  <Pie data={data} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	
				</PieChart>
			  </ResponsiveContainer>
			  <div className="dashboard__health-chart-info" style={{marginTop:-15}}>
				<TagOutlineIcon style={{ fill: floodzoneColors[floodData.flood_zone] }} />
				<p className="dashboard__health-chart-number">{floodData.flood_zone == "0.2 PCT ANNUAL CHANCE FLOOD HAZARD" ? "0.2 %" : floodData.flood_zone}</p>
				<p className="dashboard__health-chart-units">{floodRiskDesc}</p>
				 
			  </div>
			  <p className="dashboard__goal">Flood Insurance Rate Map Zone</p>
			</div>
		</CardBody>);
};

const EnergyScoreCard = (props) => {
	const { floodData, col, translateScore, floodzoneTranslateShort, floodzoneTranslate, floodzoneColors } = props;
	const data = calculateBarData(floodData, floodzoneColors);
	
	
	return (
			<Card>
				<CardPercent data={data} floodData={floodData} floodzoneTranslateShort={floodzoneTranslateShort} floodzoneTranslate={floodzoneTranslate}  floodzoneColors={floodzoneColors} />
				
			</Card>
	)
};



export default EnergyScoreCard;
