/* eslint-disable react/no-array-index-key */


import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NewOrderTableProps } from '../../../../shared/prop-types/TablesProps';
import moment from "moment"; 
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import qs from 'querystring';

import SendMessageButton from '../../../../shared/components/buttons/SendMessageButton';
import BuyersList from './Chat';
import Panel from '../../../../shared/components/Panel';

import { connect } from 'react-redux';


import { 
	_capitalizeText,
	_shouldShow,
	_getBuildingPhotos,
	_formatPrice,
	_formatNumber,
	_getBuildingURL,
} from "../../../../shared/helpers/utils";
import{
	_axiosCall,
} from '../../../../shared/helpers/apicalls';

const ssoPrivateKey = '488827f05c3a7aba441f752a4a4f198836266f269936a594'; // Your SSO key

const apiURL = process.env.API_ROOT + '/tribe/';


class QA extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      qaMounted: false,
	      ssoToken: false,
	      tribeGroup: false,
	      tribeUser: false,
	      userData: false,
	      tribeComplete: false,
	      fakeTribe: false,
	      iframeID: 0
	    };
	  }
	  
	componentDidMount() {	
  		//this._mountQA();   		
  		//this._createSSOToken();  
  		//this._getTribeAccessToken();
  		//this._getTribeGroup();
  		this._getTribeGroup();
  	}
  	
  	componentDidUpdate(prevProps, prevState, snapshot) {	
  		//this._mountQA();   		
  		//this._getTribeAccessToken();

  		if(this.state.tribeGroup !== prevState.tribeGroup){
	  		//this._getTribeGroup();
	  		this._createSSOToken();
  		}
  		
/*
  		if(this.props.fakeTribe !== prevProps.fakeTribe){
	  		this._createSSOToken(); 
	  		
  		}
  		
  		if(this.state.tribeUser !== prevState.tribeUser){
	  		
	  		this._addMemberTribeGroup();
  		}
*/
  		
  	}
  	
  	
  	
  	
  	_createSSOToken = () => {
	  const { user } = this.props;	  
	  const { tribeGroup } = this.state;
	  if(!user || !tribeGroup ) return false;	
	  const userData = {
	    email: user.email,
	    id: user.id,
	    name: `${user.firstName} ${user.lastName}`,
	    picture: user.avatar ? user.avatar : `https://buyerslist.s3.amazonaws.com/account.png`,
	    title: user.title ? user.title : ''
	  };	
	  const ssoToken = jwt.sign(userData, ssoPrivateKey, {algorithm: 'HS256'});
	  this.setState( { ssoToken : ssoToken, userData : userData, tribeUser: userData, tribeComplete: true  } );
	  return null;
	  
	  
	  
	  
/*
	  
	  
      const email = user.email; 
      const getData = {
	  	 url: apiURL + `getuser/${email}`,
	  	 method: 'get',  	   
	  }	  
		  
	  _axiosCall(getData)
	    .then(res => {
	      
	      if(res && res.length>0){
		      const tribeUser = res[0];
		      const userData = {
			    email: tribeUser.email,
			    id: tribeUser.id,
			    name: tribeUser.profile.name,
			    picture: tribeUser.profile.picture
			  };		
			  const ssoToken = jwt.sign(userData, ssoPrivateKey, {algorithm: 'HS256'});
		  	  this.setState( { ssoToken : ssoToken, userData : userData, tribeUser: tribeUser, fakeTribe: false } );		      
	      }else{
		       const userData = {
			    email: user.email,
			    id: user.sub,
			    name: user.name,
			    picture: user.avatar ? user.avatar : `https://buyerslist.s3.amazonaws.com/account.png`
			  };	
			  const ssoToken = jwt.sign(userData, ssoPrivateKey, {algorithm: 'HS256'});
			  this.setState( {ssoToken : ssoToken,  fakeTribe: true} )
			  

			  const fakeSSOAdd = {
			  	 url: `https://buyerslist.tribe.so/embed/group/${tribeGroup.slug}?components=input,feed&ssoToken=${ssoToken}`,
			  	 method: 'get',  	   
			  }
			  _axiosCall(fakeSSOAdd)
			  .then(res => {
		      	  console.log(res);
		      	  this._createSSOToken();
	      	  });

	    }
	  })
	  .catch(error => {
	   
	  });
*/

	  
	
	  
	  
	}
	
	_getTribeGroup = () => {
		
/*
      const { buildingRes } = this.props;      
      const { ssoToken, userData } = this.state;
      
      if(!ssoToken) return false;
*/
      
      const slug = this.props.match.params.building_slug;    
      const getData = {
	  	 url: apiURL + `getgroup/${slug}`,
	  	 method: 'get',  	   
	  }	  
		  
	  _axiosCall(getData)
	    .then(res => {
	      if(!res){
		      this._createTribeGroup();
	      }else{
		      this.setState( { tribeGroup : res } )
	      }
	  })
	  .catch(error => {
	   
	  });

	  
	}
	
	_createTribeGroup = () => {
		
	  const { buildingRes } = this.props;
	  
	  const slug = this.props.match.params.building_slug;
	  const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
      const images = _getBuildingPhotos(buildingRes);
	  
    
      const postData = {
	  	 url: apiURL + "creategroup",
	  	 method: 'post',  
	     query: {
			  name: buildingNameOrAdr,
			  slug: slug,
			  picture: images && images[0] ? images[0] : false
	  	  }
	  }
	  
		  
	  _axiosCall(postData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);
	     this.setState( { tribeGroup : res } )
	     
	  })
	  .catch(error => {
	   
	  });

	  
	}
	
	_addMemberTribeGroup = () => {
		
	  const { buildingRes } = this.props;
	  const { userData } = this.state;	  
	  const slug = this.props.match.params.building_slug;
	     
      const postData = {
	  	 url: apiURL + "joingroup/"+slug,
	  	 method: 'post',  
	     query: {
			  user: userData.id
	  	  }
	  }
	  console.log('_addMemberTribeGroup', postData);
		  
	  _axiosCall(postData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);
	     this.setState( { tribeComplete : true } )
	  })
	  .catch(error => {
	   
	  });

	  
	}

	  
	_mountQA = () => {
		const { threadId } = this.props;
		const { ssoToken } = this.state;	
		const tribeQA = {
			"topicId" : threadId,
			"id": "questions-widget",
			"components": ["input"],
			"ssoToken" : ssoToken
		};
		if(window.Tribe && ssoToken && !this.state.qaMounted && document.getElementById("questions-widget")){
			console.log("here");
			window.Tribe('questions', tribeQA);
			this.setState({qaMounted:true});
		}
		  
	}
	render() {	
		
	   const { ssoToken, tribeGroup, tribeUser, tribeComplete, fakeTribe, iframeID } = this.state;	 
	   const { threadId } = this.props;

	   if(!ssoToken || !tribeGroup || !tribeUser || !tribeComplete) return null;
console.log('render building', `https://buyerslist.tribe.so/embed/group/${tribeGroup.slug}?components=header,input,feed&ssoToken=${ssoToken}`);	     
	   return(
		  <Panel
		    xl={12}
		    lg={12}
		    md={12}
		    title={`Questions and Answers`}
		    subhead="To ask a question or post an answer, join this channel."
		    className={'knowledge__container'}
		  >
		      <div id="questions-widget">
		      	<iframe key={iframeID} src={`https://buyerslist.tribe.so/embed/group/${tribeGroup.slug}?components=header,input,feed&ssoToken=${ssoToken}`} 
		      		frameborder="0" 
		      		scrolling="0" 
		      		tabindex="0" 
		      		title="Tribe" 
		      		width="100%" 
		      		id="questions-widget-iframe" 
		      		style={{ minHeight: "2000px", height:"100%", width: "100%"}} />
		      </div>
		  </Panel>
		)
	}
}


export default  connect(state => ({
  user: state.user,
}))(QA);
