import React, { PureComponent, useState, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import TotalFloors from './components/TotalFloors';
import TotalUnits from './components/TotalUnits';
import AveragePPSF from './components/AveragePPSF';
import PriceRange from './components/PriceRange';
import TotalUnsold from './components/TotalUnsold';

import AveragePPSFSold from './components/AveragePPSFSold';
import TotalSqFt from './components/TotalSqFt';
import AvgUnitSqFt from './components/AvgUnitSqFt';
import PriceRangeSold from './components/PriceRangeSold';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import Overview from './overview';
import Resales from './resales';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft:"-8px",
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),      
	},
	
  },
  button:{
	'&.selected' :{
		background:"#D1D6E0"
	}
  }
}));

const Analysis = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'overview');
	
	const [period, setPeriod] = useState("all-time");
	
	const { unitsRes, buildingRes, statsRes, user } = props;  
	const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const summaryTitle = 'Building Analysis - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
	const _toggleTab = (tab) => {
		if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/analysis/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
		  setActiveTab(tab)
		  Publisher.publish(`track.buildingAnalysisView.${tab}`, {building: buildingRes.key});
		}
	  };
	
	const classes = useStyles();
	
	return(
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		{['resale'].indexOf(buildingRes.building_status)>=0 &&   
			<div className="tabs"> 	
			<div className="tabs__wrap mb-2">
				<Nav tabs>
				   
				   <NavItem>
					<NavLink
					  className={classnames({ active: activeTab === 'overview' })}
					  onClick={() => {
						_toggleTab('overview');
					  }}
					>
					  <span className="tab-title">Overview</span>
					</NavLink>
				  </NavItem>
				  <NavItem>
					<NavLink
					  className={classnames({ active: activeTab === 'resales' })}
					  onClick={() => {
						_toggleTab('resales');
					  }}
					>
					  <span className="tab-title">Resale Analysis</span>
					</NavLink>
				  </NavItem>
				 
				</Nav>
			  </div>
			</div>
		}
		{activeTab === 'overview' &&
		  <TabPane tabId="overview">
			  <Overview {...props} activeTab={activeTab} />
		  </TabPane>
		  }
		{activeTab === 'resales' &&
		  <TabPane tabId="resales">
			  <Resales {...props} activeTab={activeTab} />
		  </TabPane>
		  }
		
		
			  
		</Container>	  
		</div>
		
	)
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Analysis));