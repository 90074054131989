import React, { PureComponent, Fragment } from 'react';
import { Progress, Table, Row } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ComposedChart } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import Panel from '../../../../../shared/components/Panel';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';

const apiURL = process.env.API_ROOT + '/query/';


let bedTypes = [];

class SoldTrends extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    themeName: PropTypes.string.isRequired,
  };
 
  
  constructor() {
    super();
    this.state = {
      data: false,
    };
  }
   _setbedrooms = (bedrooms) => {
	 const { buildingRes, statsRes } = this.props;  
	 
	 let maxBeds = 3;
	   if(statsRes && statsRes.has_bed && statsRes.has_bed.unit_mix && statsRes.has_bed.unit_mix.buckets){
		   maxBeds = 0;
		   statsRes.has_bed.unit_mix.buckets.map(item =>{
			   if(item.val > maxBeds){
				   maxBeds = item.val;
			   }
		   })
		   
	   }
	   
	   
	 let stats = {};
	  for(let i = 0; i < bedrooms.length; i++) {
	  	  const bedroom = bedrooms[i];
	  	  
		  if(bedroom.val === 0) {
			  stats['Studio'] = bedroom.count;
			  bedTypes.push('0_Studio');
		  }else if(bedroom.val > 0 && bedroom.val <= maxBeds){
			  stats[bedroom.val+' Bed'] = bedroom.count;
			  bedTypes.push(bedroom.val+' Bed');
		  }
		  
	  }	
	  bedTypes = [...new Set(bedTypes)];  
	  
	  return stats;
	  
  }

  
  componentDidMount() {	
	const { soldStats } = this.props;
	if(soldStats && soldStats.facets && soldStats.facets.building_resale && soldStats.facets.building_resale.trends){
		const { buckets } = soldStats.facets.building_resale.trends;	
		const data = [];
		for(let i = 0; i < buckets.length; i++) {
			const bucket = buckets[i];
			if(bucket.count>0){
				const bedrooms = bucket.by_bedrooms && bucket.by_bedrooms.buckets && bucket.by_bedrooms.buckets[0] ? bucket.by_bedrooms.buckets : [];
				const bedroomStats = this._setbedrooms(bedrooms);
				console.log(bedroomStats);
				let item = {
					year : moment(bucket.val).format('YYYY'),	
					avg_price: bucket.avg_price ? bucket.avg_price : 0,					
				}; 
				
				
				item = Object.assign(item, bedroomStats);
				data.push(item);
			}
		}
		
		this.setState({ data: data });
	}
  }
  
  render() {
      const { t, dir, themeName, lmi } = this.props;    
      const { data } = this.state;
	  if(!data){
		  return null;
	  }
	console.log(bedTypes);
	  return (
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={<Fragment>Resale Volume by Unit Type<ToolTip title={"Number of resales by bedroom type, sponsor sales are excluded in this analysis.  Data is based on ACRIS recorded closings."} /></Fragment>}
		  	subhead={'Nubmer of sales by bedroom type excluding sponsor sales'}
		  >
		    <div dir="ltr">
		      <ResponsiveContainer height={350} className="dashboard__area">
		        <BarChart data={data} margin={{ top: 0, left: -20, bottom: 20, right:20 }}>
			       
		           <XAxis dataKey="year" tickLine={false} reversed={dir === 'rtl'} dx={5} angle={45} tickSize={20} height={60} tickMargin={10} />
			       <YAxis  yAxisId="left" tickLine={false} tickLine={false}  orientation={'left'} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} />
			      {/* <YAxis yAxisId="right" 
			        tickLine={false} 
			        orientation={'right'} 
			       	tickFormatter={value => ['$', new Intl.NumberFormat('en').format(value)].join('')}/> */}
			       <Tooltip/>
			       <Legend />
			       <CartesianGrid vertical={false}/>
			      {/* <Area yAxisId="right" name="Average Price" type="monotone" dataKey="avg_price" fill="#BFC4D1" stroke="#171f26" strokeWidth={2} fillOpacity={0.1} /> */}
			       {bedTypes.sort().map((key, i) => {
				       return <Bar dataKey={key.replace('0_', '')} yAxisId="left" stackId="a" fill={getDefaultChartColors[i]} />
			       })}
			       
			        

			      
			      </BarChart>
		      </ResponsiveContainer>
		    </div>
		  </Panel>
		);
	}
}

export default connect(state => ({
  rtl: state.rtl,
  dir: PropTypes.string.isRequired,
  theme: state.theme,
}))(withTranslation('common')(SoldTrends));
