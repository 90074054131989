import React, { useState, Fragment } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar,
} from 'reactstrap';
import moment from 'moment';
import HeartIcon from 'mdi-react/HeartIcon';
import FormatLineWeightIcon from 'mdi-react/FormatLineWeightIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import { Link } from 'react-router-dom';
import ProfileGallery from './ProfileGallery';
import images from './imgs';
import ProfileTabs from './ProfileTabs';
import ColorSelect from './ColorSelect';
import classNames from 'classnames';
import PercentSold from './PercentSold';
import PercentSqFtSold from './PercentSqFtSold';
import PercentDollarSold from './PercentDollarSold';
import OriginalOwner from './OriginalOwner';
import CorpOwner from './CorpOwner';

import SalesVelocitySmall from './SalesVelocitySmall';
import AborptionRateSmall from './AborptionRateSmall';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import UnitMix from './UnitMix';
import AddToBuyersListButton from '../../../../../shared/components/buttons/AddToBuyersListButton';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';

import { Tooltip } from '@material-ui/core';
import TaxAbatement from '../../../../../shared/components/TaxAbatement';

import BuildingDetailsCard from "./BuildingDetailsCard";


//import SavedCount from '../../../../../shared/components/SavedCount';
import ViewedCount from '../../../../../shared/components/ViewedCount';
import PipelineBuilding from './PipelineBuilding';
import { 
	_capitalizeText,
	_shouldShow,
	_getBuildingPhotos,
	_formatPrice,
	_formatNumber,
	_getBuildingURL,
	_checkBuildingAltAdr,
	_showAltAdrs,
	_getCrossStreet,
	SalesProgress,
	_isCoop
} from "../../../../../shared/helpers/utils";

const ListAmount = ({ lists }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': lists.count <= 5,
    'dashboard__table-orders-amount--low': lists.count <= 3,
  });
  return (
      <div className={amountClass}>
       {lists.map(list => <div />)}
        <span>{lists.length} Members Saved this Building</span>
      </div>
    );
};


const MainInfo = (props) => {
	const { buildingRes, statsRes, share, showStats } = props;
	const cdec_year = buildingRes.first_cdec_date ? parseInt(moment(buildingRes.first_cdec_date).format('YYYY')) : 0;
  
	  let first_close_year = buildingRes.first_closing_date ? parseInt(moment(buildingRes.first_closing_date).format('YYYY')) : 0;
	  
	  if(first_close_year <= 1970){
		  first_close_year = 9999;
	  }
	  
	  const altAdrs = _checkBuildingAltAdr(buildingRes);
	return (
		<Fragment>
		<table className="product-summary__info">
	        <tbody>
				
			  {share && 
					  ((buildingRes.offering_verified_status.toString() === 'active' ||					 
					  (buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY'))) > 1970 ) && buildingRes.building_status!=="resale") &&
				   <tr>
					   <th>{`Sales Progress:`}</th>
					   <td className="pb-2">
						   <SalesProgress label={"Units Sold"} 
							progress={parseFloat(((buildingRes.inventory_total - buildingRes.inventory_unsold) / buildingRes.inventory_total) * 100).toFixed(0)} 
							inventory_unsold={buildingRes.inventory_total - buildingRes.inventory_unsold} inventory_total={buildingRes.inventory_total} maxWidth="80%"
							size={'middle'}  />
					   </td>
					 </tr>   
					
				}
				{buildingRes.building_status !== "resale" &&
					<tr>
					  <th>Sales Started:</th>
					  <td>{parseInt(moment(buildingRes.sales_start_date).format('YYYY')) > 1990 ? 
							  <Fragment>
								  {_capitalizeText(moment(buildingRes.sales_start_date).fromNow())}
								  <span className="small-text"> 
									  {` `}({_capitalizeText(moment(buildingRes.sales_start_date).format('MM/DD/YYYY'))})
								  </span>
							  </Fragment> : `Not Started`}</td>
					</tr>
				  }
				{buildingRes.first_closing_date && buildingRes.building_status !== "resale" &&
				  <tr>
					<th>{`Closings Started:`}</th>
					<td>
						{buildingRes.first_closing_date && parseInt(moment(buildingRes.first_closing_date).format('YYYY')) > 1990  
							? 
							<Fragment>
								{_capitalizeText(moment(buildingRes.first_closing_date).fromNow())} 
								<span className="small-text">
									 {` `}({_capitalizeText(moment(buildingRes.first_closing_date).format('MM/DD/YYYY'))})
								 </span>
							 </Fragment> : `Not Started`
						}
					</td>
				  </tr>  
				  }
	          {buildingRes.year_converted > 1900 && buildingRes.year_converted > buildingRes.year_built &&
		          <tr>
		            <th>{`Year Built:`}</th>
		            <td>
		            	{buildingRes.year_built} <span className="small-text">(Converted: {buildingRes.year_converted})</span>
		            </td>
		          </tr>  
		          }
	           {buildingRes.offering_construction &&
	          <tr>
	            <th>Offering Type:</th>
	            <td>{buildingRes.offering_construction === 'new' ? 'New Construction' : _capitalizeText(buildingRes.offering_construction)} {_capitalizeText(buildingRes.offering_plan_type)}</td>
	          </tr>  
	          }
	           
	          {(buildingRes.marketing_company || buildingRes.sales_office) && buildingRes.building_status !== "resale" &&
	          <tr>
	            <th>Sales Agent:</th>
	            <td>{buildingRes.marketing_company ? buildingRes.marketing_company : buildingRes.sales_office}</td>
	          </tr>  
	          }
	          
	          {buildingRes.developers &&
	          <tr>
	            <th>{buildingRes.developers.toLowerCase().indexOf('llc')>=0 ? <span>Owner Entity</span> : <span>Developer</span>}:</th>
	            <td>{buildingRes.developers}</td>
	          </tr>  
	          }
	          
	          {buildingRes.architect && buildingRes.architect.indexOf("not determined")<0 &&
	          <tr>
	            <th>Architect:</th>
	            <td>{buildingRes.building_status == 'resale' ? _capitalizeText(buildingRes.architect) : buildingRes.architect}
	            	{buildingRes.year_converted && _shouldShow(buildingRes.year_converted) && buildingRes.year_converted > buildingRes.year_built &&
	                	<span className="small-text">
		                	{` `}(Renovation)
		                </span>
	                }
	            </td>
	          </tr>  
	          }
	          {buildingRes.landscape_architect &&
	          <tr>
	            <th>Landscape Design:</th>
	            <td>{buildingRes.landscape_architect}</td>
	          </tr>  
	          }
	          {buildingRes.interior_designers &&
	          <tr>
	            <th>Interior Designer:</th>
	            <td>{buildingRes.interior_designers}</td>
	          </tr>  
	          }
			  
			  {!share &&
			  <Fragment>
	          <tr>
	            <th>Offering Submitted:</th>
	            <td>{parseInt(moment(buildingRes.offering_submitted_date).format('YYYY')) > 1970 ? 
	                <Fragment>
		                {_capitalizeText(moment(buildingRes.offering_submitted_date).fromNow())} 
		                <span className="small-text">
		                	{` `}({_capitalizeText(moment(buildingRes.offering_submitted_date).format('MM/DD/YYYY'))})
		                </span>
	                </Fragment>  :  buildingRes.building_status === "resale" ? 'Submitted' : `Not Submitted`}</td>
	          </tr>
	          <tr>
	            <th>Offering Accepted:</th>
	            <td>{parseInt(moment(buildingRes.offering_accepted_date).format('YYYY')) > 1970 ? 
	                <Fragment>
		                {_capitalizeText(moment(buildingRes.offering_accepted_date).fromNow())} 
		                <span className="small-text">
		                	{` `}({_capitalizeText(moment(buildingRes.offering_accepted_date).format('MM/DD/YYYY'))})
		                </span>
	                </Fragment>  :  buildingRes.building_status === "resale" ? 'Accepted' : `Not Accepted`}</td>
	          </tr>
	          
	          {parseInt(moment(buildingRes.offering_effective_date).format('YYYY')) > 1970 &&
	          <tr>
	            <th>Offering Effective:</th>
	            <td>{parseInt(moment(buildingRes.offering_effective_date).format('YYYY')) > 1970 ? 
	                <Fragment>
		                {_capitalizeText(moment(buildingRes.offering_effective_date).fromNow())} 
		                <span className="small-text">
		                	{` `}({_capitalizeText(moment(buildingRes.offering_effective_date).format('MM/DD/YYYY'))})
		                </span>
	                </Fragment>  : buildingRes.building_status === "resale" ? 'Effective' : `Not Effective`}</td>
	          </tr>
	          }
			  {buildingRes.ownership_type.toLowerCase().indexOf("condo")>=0 &&
	          <tr>
	            <th>Condo Declaration:</th>
	            <td>{cdec_year > 1970 && cdec_year <= first_close_year  ? 
	                 <Fragment>
	                 	{_capitalizeText(moment(buildingRes.first_cdec_date).fromNow())} 
	                 	<span className="small-text">
					 		{` `}({_capitalizeText(moment(buildingRes.first_cdec_date).format('MM/DD/YYYY'))}
					 		
					 		{buildingRes.first_cdec_date !== buildingRes.last_cdec_date && 
						 		<span className="small-text"> - Amended: {moment(buildingRes.last_cdec_date).format('MM/DD/YYYY')}</span>
						 		
					 		}
					 		)
					 		
					 	</span>
					 	
	                </Fragment> : parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970 && parseInt(moment(buildingRes.cdec_date).format('YYYY')) <= first_close_year ? <Fragment>
	                 	{_capitalizeText(moment(buildingRes.cdec_date).fromNow())} 
	                 	<span className="small-text">
					 		{` `}({_capitalizeText(moment(buildingRes.cdec_date).format('MM/DD/YYYY'))})					 		
					 	</span>
					 	
	                </Fragment> : buildingRes.building_status === "resale" ? 'Filed' : `Not Filed`}</td>
	          </tr> 
		  	}
			  </Fragment>
		  	  }
	          {!(parseInt(moment(buildingRes.building_effective_start).format('YYYY')) <= 1970 && buildingRes.first_closing_date && parseInt(moment(buildingRes.first_closing_date).format('YYYY')) > 1970) && buildingRes.building_status!=="resale" &&
	          <tr>
	            <th>Certificate of Occupancy:</th>
	            <td>{parseInt(moment(buildingRes.building_effective_start).format('YYYY')) > 1970 ? 
	                <Fragment>
	                	{_capitalizeText(moment(buildingRes.building_effective_start).fromNow())} 
	                	<span className="small-text">
					 		{` `}({_capitalizeText(moment(buildingRes.building_effective_start).format('MM/DD/YYYY'))})
					 	</span>
	                </Fragment> : _shouldShow(buildingRes.year_converted) ? `--` : `Not Issued`}</td>
	          </tr>
	          }
			  
			  {buildingRes.property_stats && buildingRes.property_stats.facets && buildingRes.property_stats.facets.cc_ppsf && buildingRes.property_stats.facets.cc_ppsf.avg_ppsf > 0.05 &&
				  <tr>
					  <th>Monthly CC PSF:</th>
					  <td>${Math.round((buildingRes.property_stats.facets.cc_ppsf.avg_ppsf*100))/100}</td>
					</tr>
				  
			  }
	          {buildingRes.property_stats && buildingRes.property_stats.facets && buildingRes.property_stats.facets.tax_ppsf && buildingRes.property_stats.facets.tax_ppsf.avg_ppsf > 0.05 &&
					<tr>
						<th>Monthly Tax PSF:</th>
						<td>${Math.round((buildingRes.property_stats.facets.tax_ppsf.avg_ppsf*100))/100}</td>
					  </tr>
					
				}
			  
			
	          
	          {buildingRes.website && buildingRes.website.indexOf('http') >= 0 && !share &&
	          <tr>
	            <th>{`Website:`}</th>
	            <td>
	            	<a href={buildingRes.website} className="product__external-link" target="_blank">See Website <OpenInNewIcon size={14} style={{marginTop:-2}} /></a>
	            </td>
	          </tr>  
	          }
			  {showStats ? 
				 <Fragment>
				<tr>
				  <th>Avg Unit SF:</th>
				  <td>{buildingRes.inventory_stats_listings_average_sqft > 100 ? `${_formatNumber(buildingRes.inventory_stats_listings_average_sqft)} SF` : "--"}</td>
				</tr>
			  <tr>
				  <th>Asking PPSF:</th>
				  <td>{buildingRes.inventory_stats_listings_average_ppsf > 100 ? `$${_formatNumber(buildingRes.inventory_stats_listings_average_ppsf)}` : "--"}</td>
				</tr>
			  <tr>
				<th>Sold PPSF:</th>
				<td>{buildingRes.inventory_stats_transactions_average_ppsf > 100 ? `$${_formatNumber(buildingRes.inventory_stats_transactions_average_ppsf)}` : "--"}</td>
			  </tr>
				</Fragment>:
				null
			 }
			  <TaxAbatement {...props} />
		          </tbody>
		      </table>
		      {buildingRes.building_status == "resale" &&
			      <Fragment>
				      <hr/>
					  <table className="product-summary__info">
				        <tbody>
				          {buildingRes.display_full_street_address && _shouldShow(buildingRes.display_full_street_address) &&
				          <tr>
				            <th>{`Primary Address:`}</th>
				            <td>
				            	{buildingRes.display_full_street_address}
				            </td>
				          </tr>  
				          }
				          {altAdrs && altAdrs.length > 0 &&
				          <tr>
				            <th>{`Alternate Addresses:`}</th>
				            <td>
				            	{Array.isArray(altAdrs)
										? _showAltAdrs(altAdrs, false)
										: altAdrs}
				            </td>
				          </tr>  
				          }
				          {_getCrossStreet(buildingRes) &&
				          <tr>
				            <th>{`Cross Streets:`}</th>
				            <td>
				            	{_getCrossStreet(buildingRes)}
				            </td>
				          </tr>  
				          }
				          {buildingRes.landmark_name && _shouldShow(buildingRes.landmark_name) &&
				          <tr>
				            <th>{`Landmark:`}</th>
				            <td>
				            	{_capitalizeText(buildingRes.landmark_name)}
				            </td>
				          </tr>  
				          }
				          {buildingRes.historic_district_name && _shouldShow(buildingRes.historic_district_name) &&
				          <tr>
				            <th>{`Historic District:`}</th>
				            <td>
				            	{_capitalizeText(buildingRes.historic_district_name)}
				            </td>
				          </tr>  
				          }
				           {buildingRes.building_material && _shouldShow(buildingRes.building_material) &&
				          <tr>
				            <th>{`Building Material:`}</th>
				            <td>
				            	{buildingRes.building_material.map(item => _capitalizeText(item)).join(", ")}
				            </td>
				          </tr>  
				          }
			           </tbody>
				      </table>
				 </Fragment>
			  }
			  
		    </Fragment>

	        
	)
	
}

const ProfileCard = (props) => {
  const [color, setColor] = useState('white');

  const onLike = () => {
    if (color === 'white') {
      setColor('#70bbfd');
    } else {
      setColor('white');
    }
  };
  const { buildingRes, statsRes, share, transactionRes } = props;
  
  let images = _getBuildingPhotos(buildingRes);
  console.log(images);
  if(images && images.length>0){
	  images = images.filter(image => image != "-1").map((image) => { return { src: image } } );
  }
  let buildingResUnits =_shouldShow(buildingRes.inventory_total)
		? buildingRes.inventory_total
		:_shouldShow(buildingRes.total_units_sellable)
		? buildingRes.total_units_sellable
		:_shouldShow(buildingRes.total_units_residential)
		? buildingRes.total_units_residential
		: _shouldShow(buildingRes.residential_units)
		? buildingRes.residential_units
		: _shouldShow(buildingRes.total_units)
		? buildingRes.total_units
		: false;
  
  if(buildingRes.number_of_buildings > 1 && buildingRes.building_status === 'resale' && buildingRes.tax_assessed_residential_units > 1){
	  buildingResUnits = buildingRes.tax_assessed_residential_units;
  }
  const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
  
  const boro = _shouldShow(buildingRes.borough)
	  ? Array.isArray(buildingRes.borough)
		? buildingRes.borough[0].toLowerCase().trim()
		: buildingRes.borough.toLowerCase().trim()
	  : "new york";
	const hood = _shouldShow(buildingRes.neighborhood)
	  ? Array.isArray(buildingRes.neighborhood)
		? buildingRes.neighborhood[0].toLowerCase().trim()
		: buildingRes.neighborhood.toLowerCase().trim()
	  : "nyc";
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="no-border no-padding">
          <div className="product-card">
            <ProfileGallery images={images} />
            

            <div className="product-card__info">
              <h3 className="product-card__title"></h3>
			  <div className="">
				<ViewedCount buildingKey={buildingRes.key} />
				</div>
				
              <Row className="product-card__top">
				{buildingRes.total_floors && _shouldShow(buildingRes.total_floors) && (
					<Col xl={2} lg={3} xs={4} className="mt-2">
						<h3>{buildingRes.total_floors}</h3>
						<span className="product-card__subtext">Floors</span>
					</Col>
				)}
				
				{buildingResUnits && (
					<Col  xl={2} lg={3} xs={4} className="mt-2">
						<h3>{buildingResUnits}</h3>
						<span className="product-card__subtext">Units</span>
					</Col>
				)}

				{buildingRes.year_converted && _shouldShow(buildingRes.year_converted) && buildingRes.year_converted > buildingRes.year_built ? 
					<Col xl={2} lg={3} xs={4} className="mt-2">
						<h3>{buildingRes.year_converted}</h3>
						<span className="product-card__subtext">Year Converted</span>
					</Col>
				: buildingRes.year_built && _shouldShow(buildingRes.year_built) && (
					<Col  xl={2} lg={3} xs={4} className="mt-2">
						<h3>{buildingRes.year_built}</h3>
						<span className="product-card__subtext">Year Built</span>
					</Col>
					: null
				)}
				
				{buildingRes.building_status !== "resale" && statsRes && statsRes.non_zero_price && statsRes.non_zero_price.low_price && _shouldShow(statsRes.non_zero_price.low_price) ?
					<Col xl={6} lg={12} xs={12} className="mt-2">
							<h3>{_formatPrice(statsRes.non_zero_price.low_price).toUpperCase()} - {_formatPrice(statsRes.non_zero_price.high_price).toUpperCase()}</h3>
							<span className="product-card__subtext">{buildingRes.building_status === "resale" ? "Sold" : "Asking"} Price Range</span>
						</Col>
					
				: buildingRes.building_status === "resale" && transactionRes && transactionRes.min_price && _shouldShow(transactionRes.min_price) ?
					<Col xl={6} lg={12} xs={12} className="mt-2">
						<h3>{_formatPrice(transactionRes.min_price).toUpperCase()} - {_formatPrice(transactionRes.max_price).toUpperCase()}</h3>
						<span className="product-card__subtext">Sold Price Range</span>
					</Col>
				:
					<Fragment>
						{buildingRes.inventory_stats_listings_low_price && _shouldShow(buildingRes.inventory_stats_listings_low_price) && (
							<Col xl={6} lg={12} xs={12} className="mt-2">
								<h3>{_formatPrice(buildingRes.inventory_stats_listings_low_price).toUpperCase()} - {_formatPrice(buildingRes.inventory_stats_listings_high_price).toUpperCase()}</h3>
								<span className="product-card__subtext">Price Range</span>
							</Col>
						)}
					</Fragment>
				}
				{/*buildingRes.inventory_stats_listings_average_ppsf && _shouldShow(buildingRes.inventory_stats_listings_average_ppsf) && (
				<Col md={2} xs={3}>
					<h3>${_formatNumber(buildingRes.inventory_stats_listings_average_ppsf)}</h3>
					<span className="product-card__subtext">Price / Sq Ft</span>
				</Col>
				)*/}
			</Row>
              {/*!share && <div className="product-card__rate">
                <SavedCount buildingKey={buildingRes.key} lists={['key', 'key', 'key']} />
				
              </div>*/}
			  
                           
              <hr/>
			  {_isCoop(buildingRes) && (buildingRes.year_built <= 2000 && buildingRes.year_converted <=2000) ?
				<BuildingDetailsCard
				  buildingDetails={buildingRes}
				  hood={hood}
				  boro={boro}
				  {...props}
				/>  
			  :
              	<MainInfo {...props} />
		  	  }
			 
              {false && share && 
              <div className="share-page-buttons">
	              <Tooltip title={`See active listings for ${buildingNameOrAdr}`}>
			        <Button
				         variant="outlined"
				         color="secondary"
				         className="buyersList__secondary-button"		         
				         startIcon={<ListingIcon />}
				         onClick={(e) => {e.stopPropagation();window.open(`https://nyc.marketproof.com${_getBuildingURL(buildingRes, 'building_slugs').replace('buildings', 'building')}?tab=units&sub_tab=active-sales`)}}
				     > Listings
				     </Button>				 
			  	  </Tooltip>
			  	  <Tooltip title={`See stacking plans for ${buildingNameOrAdr} (Subscribers only)`}>
			        <Button
				         variant="outlined"
				         color="secondary"
				         className="buyersList__secondary-button"		         
				         startIcon={<StackingPlanIcon />}
				         onClick={(e) => {e.stopPropagation();window.open(`${_getBuildingURL(buildingRes, 'building_slugs')}/tab/stacking-plans`)}}
				     >	Stacking Plans
				     </Button>				 
			  	  </Tooltip>
			  	  {buildingRes.website && buildingRes.website.indexOf('http') >= 0 &&
			  	  <Tooltip title={`See website for ${buildingNameOrAdr} `}>
			        <Button
				         variant="outlined"
				         color="secondary"
				         className="buyersList__secondary-button"		         
				         startIcon={<WebIcon />}
				         onClick={(e) => {e.stopPropagation();window.open(`${buildingRes.website}`)}}
				     >	Website
				     </Button>				 
			  	  </Tooltip>
			  	  }
              </div> 	
              }  
			 {(buildingRes.offering_construction==='conversion' || share) &&	
				 <Fragment>
				 {!share && <Fragment><br/><hr/><br/></Fragment>}
				 {buildingRes.offering_construction==='conversion' && !share ?	
				  <Row>				  			    
              		    {((buildingRes.offering_verified_status && buildingRes.offering_verified_status.toString() === 'active')  || (  buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970 )) &&         		
	              		<UnitMix {...props} full nosales />
						}
	              </Row>
	              
	              : buildingRes.building_status==="resale" && !share ?
	              <Row>				  			    
	              		<OriginalOwner {...props} col={6} />             		
	              		<CorpOwner {...props} />
	              </Row>
	              
	              : (buildingRes.offering_verified_status.toString() === 'active' ||
	               
	              (buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY'))) > 1970 ) && buildingRes.building_status!=="resale" ? 
		          <Row>	   			  			    
	              		{/*<PercentSold {...props} col={6} />   
	              		<SalesVelocitySmall col={6}  {...props}  period={"1-yr"} />          		
	              		<UnitMix {...props}  col={6} />*/}
	              </Row>
	              :
	              <Row>	   			  			    
	              		{!share && <PercentSold {...props}  col={6} /> }	              		           		
	              </Row>
	              }
	              </Fragment>
              }
            </div>
            {["pipeline", "sponsor"].indexOf(buildingRes.building_status)>=0 && buildingRes.offering_construction!=='conversion'  &&
			  <PipelineBuilding  {...props} />
			}
          </div>
		  
          {buildingRes.offering_construction!=='conversion' && buildingRes.ownership_type.toLowerCase().indexOf("condo")>=0 && !share &&
	          <Fragment>
		          <br/>
		          <hr/> 
		          <br/>
		          <div className="product-card" style={{display:'block'}}>
		             
					  {buildingRes.offering_construction==='conversion'?	
					  <Row>				  			    
		              		<Col md={0} xl={6} lg={6} sm={0} xs={0}/>	
							{((buildingRes.offering_verified_status && buildingRes.offering_verified_status.toString() === 'active')  || (  buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970 )) ?        		
		              		<UnitMix {...props} nosales />
							:
							<Col md={0} xl={6} lg={6} sm={0} xs={0}/>	
							}
		              </Row>
		              
		              : buildingRes.building_status==="resale" && !share ?
		              <Row>				  			    
		              		<OriginalOwner {...props} col={3} />             		
		              		<CorpOwner {...props} />
		              		<UnitMix {...props} nosales />
		              </Row>
		              
		              : (buildingRes.offering_verified_status && buildingRes.offering_verified_status.toString() === 'active' ||
		               
		              (buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY'))) > 1970 ) && buildingRes.building_status!=="resale" ? 
		              <Fragment>
			          <Row>
			          		<PercentSold {...props} /> 
			          		<SalesVelocitySmall {...props} period={"1-yr"} />
			          		<AborptionRateSmall {...props} period={"1-yr"} />
			          		{/*<PercentSqFtSold {...props} /> 	   			  			    
		              		<PercentDollarSold {...props} />*/}             		
		              		<UnitMix {...props} />
		              </Row>
		              </Fragment>
		              :
		              <Row>	   			  			    
		              		{!share && <PercentSold {...props} /> }
		              		           		
		              </Row>
		              }
		              </div>
		       </Fragment>
            }
			
			
			
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileCard;
