import {
  building_tileset,
  pro_tileset
} from "../../../shared/helpers/commonMapFilters";

export default class PitchToggle {
    
    constructor({bearing = -20, pitch = 40, minpitchzoom = null, buildingRes = null}) {
        this._bearing = bearing;
        this._pitch = pitch;
        this._minpitchzoom = minpitchzoom;
        this.buildingRes = buildingRes;
    }

    onAdd(map) {
        this._map = map;
        let _this = this; 

        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Toggle Pitch';
        this._btn.onclick = function() { 
            if (map.getPitch() === 0) {
                let options = {pitch: _this._pitch, bearing: _this._bearing, zoom: 16};
                if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
                    options.zoom = _this._minpitchzoom;
                }
                map.easeTo(options);
                
	            // map.setLayoutProperty(`mp-pipeline-buildings-layer-3d`,  'visibility', 'visible');
	            // map.setLayoutProperty(`mp-sponsor-buildings-layer-3d`,  'visibility', 'visible');
	            // map.setLayoutProperty(`mp-pipeline-buildings-layer-3d-select`,  'visibility', 'visible'); 
	            // map.setLayoutProperty(`mp-sponsor-buildings-layer-3d-select`,  'visibility', 'visible');
	            // map.setLayoutProperty(`mp-pipeline-buildings-layer-3d-hover`,  'visibility', 'visible');
	            // map.setLayoutProperty(`mp-sponsor-buildings-layer-3d-hover`,  'visibility', 'visible');	               
                //map.setLayoutProperty('add-3d-buildings', 'visibility', 'visible');
                pro_tileset.forEach((tileset) => {
                    map.setLayoutProperty(`${tileset.layer_id}-3d`,  'visibility', 'visible');	 
                    map.setLayoutProperty(`${tileset.layer_id}-3d-select`,  'visibility', 'visible');     
                    map.setLayoutProperty(`${tileset.layer_id}-3d-hover`,  'visibility', 'visible');      
                })
                building_tileset.forEach((tileset) => {
                    map.setLayoutProperty(`${tileset.layer_id}-3d`,  'visibility', 'visible');	 
                    map.setLayoutProperty(`${tileset.layer_id}-3d-select`,  'visibility', 'visible');  
                    map.setLayoutProperty(`${tileset.layer_id}-3d-hover`,  'visibility', 'visible');     
                })
                
                _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d';
            } else {
                map.easeTo({pitch: 0, bearing: 0});                
	                
                // map.setLayoutProperty(`mp-pipeline-buildings-layer-3d`,  'visibility', 'none');
                // map.setLayoutProperty(`mp-sponsor-buildings-layer-3d`,  'visibility', 'none');
                // map.setLayoutProperty(`mp-pipeline-buildings-layer-3d-select`,  'visibility', 'none');
                // map.setLayoutProperty(`mp-sponsor-buildings-layer-3d-select`,  'visibility', 'none');
                // map.setLayoutProperty(`mp-pipeline-buildings-layer-3d-hover`,  'visibility', 'none');
                // map.setLayoutProperty(`mp-sponsor-buildings-layer-3d-hover`,  'visibility', 'none');
                //map.setLayoutProperty('add-3d-buildings', 'visibility', 'none');
                pro_tileset.forEach((tileset) => {
                    map.setLayoutProperty(`${tileset.layer_id}-3d`,  'visibility', 'none');	 
                    map.setLayoutProperty(`${tileset.layer_id}-3d-select`,  'visibility', 'none');     
                    map.setLayoutProperty(`${tileset.layer_id}-3d-hover`,  'visibility', 'none');      
                })
                building_tileset.forEach((tileset) => {
                    map.setLayoutProperty(`${tileset.layer_id}-3d`,  'visibility', 'none');	
                    map.setLayoutProperty(`${tileset.layer_id}-3d-select`,  'visibility', 'none');  
                    map.setLayoutProperty(`${tileset.layer_id}-3d-hover`,  'visibility', 'none');   
                })
                _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
            } 
        };
        

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}