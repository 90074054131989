import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';

import{
	_capitalizeText,
} from '../helpers/utils';

class ShareFooter extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.buildingSlug = false;
	this.unit = false;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
 
  render(){
	  const { user, urlState } = this.props;
	  const { collapse } = this.state;
	  return (
	    <div className="footer">
	        <div className="footer__wrapper container">
	          <div className="c_footer-section footer__left">
	            
	            <a className="footer__logo" target="_blank"/>
	          </div>
	          <div className="c_footer-section footer__left">
	            <p><a href="https://marketproof.com/new-development">Prepared by Marketproof New Development</a></p>
	          </div>
	          
			  <div className="break" />
			  <div className="copyright">Marketproof, Inc. &copy; {new Date().getFullYear()}</div>
			</div>
			
	    </div>
	
	  );
	}
};
export default ShareFooter;