import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row,  Card, CardBody } from 'reactstrap';import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setUserProfile } from '../../redux/actions/authActions';

import Exports from './components/Exports';

import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';

import showResults from './Show';

import SnackBar from '../../shared/components/SimpleSnackBar';

import TrialAlert from '../../shared/components/payment/TrialAlert';

import EmptyExportList from './components/EmptyExportList';


import fileDownload from 'js-file-download';


import{
	_capitalizeText,
} from '../../shared/helpers/utils';
import{
	_axiosCall
} from '../../shared/helpers/apicalls';
const oktaToken = process.env.OKTA_TOKEN;

const apiUrl = process.env.API_ROOT;
//const apiUrl = 'http://localhost:8000/buyerslist/';

//const apiUrl = 'https://newsapi.we3.com/buyerslist/'

class Profile extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      snackShow: false,
	      snackMessage: false,
	      snackType: 'success',
	      exports: null,
	      refresh:false
	    };
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);
	    this._getExportDetails = this._getExportDetails.bind(this);
	    this.toggleRefresh = this.toggleRefresh.bind(this);
	    this._getDownload = this._getDownload.bind(this);
	    
	}
	  
    componentDidMount(){
	  const { authState, authService, dispatch, user } = this.props;
	  console.log(authState);
	  this._getExportDetails();
    }
    componentDidUpdate(prevProps, prevState) {
	    
	    const { user } = this.props;
	    
	    if(prevState.refresh !== this.state.refresh){
		     this._getExportDetails();

	    }
	    
	}
   	
	toggleRefresh = () => {
    	this.setState(prevState => ({ refresh: !prevState.refresh }));;
    };
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_getExportDetails = () => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
 
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;

	  let userData = {
			url: apiUrl+'/dataexport/my-exports',
			method: "post",
			query: {
				user_key: user.id
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getExportDetails res back ,", res);
	      
	      if(res && res.length>0){	
		      this.setState( { exports: res } );
		   }else{
			  this.setState( { exports: [] } ); 
		   }
	  })
	  .catch((error) => {
	    console.log("_getExportDetails error ", error);
	  });

	}
	
	_getDownload = (download_id, filename) => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;      
      const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  	  
 	
	  let userData = {
			url: apiUrl+'/dataexport/get-download',
			method: "post",
			query: {
				download_id: download_id
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {      
	      fileDownload(res, filename);    	      
	  })
	  .catch((error) => {
	    console.log("_getExportDetails error ", error);
	  });

	}
	
	render(){
		const { snackShow, snackMessage, snackType, exports } = this.state;
		const { user } = this.props;
		if(exports === null) return null;
		return (
			<Container>
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
			    <Row>
			      <Col md={12}>
			        <h3 className="page-title">{`My Downloads`}</h3>
			        <h3 className="page-subhead subhead">Access Your Data Exports and Owner Lists.</h3>
			      </Col>
			    </Row>
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
				<Fragment>    			    
				    {exports && exports.length > 0 ?
					    <Row>
					      <Exports {...this.props} exports={exports} getDownload={this._getDownload} />			      
					    </Row>
					    :
					    <Row>
					      <EmptyExportList {...this.props}/>			      
					    </Row>
					    
				    }
			    </Fragment>
				    
			    

			</Container>
		);	
	}
  
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(Profile));
