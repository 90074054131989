import React, { PureComponent } from 'react';
import {
  Bar, CartesianGrid, ComposedChart, BarChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList, Cell,
} from 'recharts';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';
import StatIcon from 'mdi-react/FloorPlanIcon';
import ToolTip from '../../../../../shared/components/ToolTip';

import { getDefaultChartColors } from '../../../../../shared/helpers';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	setURLFromState
} from "../../../../../shared/helpers/utils";


const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
     {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#780F9E" />*/}
      <text x={x + width / 2} y={y - radius} fill="#780F9E" textAnchor="middle" dominantBaseline="middle">
        {value === 0 ? 'N/A' : _formatNumber(value)}
      </text>
    </g>
  );
};

const _generateUnitMixData = (statsRes) => {
	 if(!statsRes.unit_mix || !statsRes.unit_mix.buckets){
		  return [];
	  }
	
	let data = [
	  {
	    'name': 'Studio',
	    'value': 0,
	    'color': getDefaultChartColors[0],
	    'search' : 'studio'
	  },
	  {
	    'name': '1 Bed',
	    'value': 0,
	    'color': getDefaultChartColors[1],
	    'search' : '1-bedroom'
	  },
	  {
	    'name': '2 Bed',
	    'value': 0,
	    'color': getDefaultChartColors[2],
	    'search' : '2-bedroom'
	  },
	  {
	    'name': '3 Bed',
	    'value': 0,
	    'color': getDefaultChartColors[3],
	    'search' : '3-bedroom'
	  },
	  {
	    'name': '4+ Bed',
	    'value': 0,
	    'color': getDefaultChartColors[4],
	    'search' : '4-or-more'
	  },
	 
	];
	  
	 statsRes.unit_mix.buckets.sort((a, b) =>  a.val - b.val).map((bucket, i) => {
		 if(bucket.val<0){
			 return false;
		 }
		 if(bucket.val < 4){
			 data[bucket.val].value = bucket.count
		 }
		 
		 
	  });
	  const fourMore = statsRes['4_more_beds'];
	  if(fourMore && fourMore.count > 0){
		 data[4]['value'] = fourMore.count;
	  }
	  return data;
	 	  
  }


class UnitMix extends PureComponent {
  
  toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  handleClick(data, index) {
  	console.log(data, index);
  	const { history } = this.props;
  	
  	const newState = Object.assign({}, this.props); 	
	 if(newState.subFilters && newState.subFilters.offering_bedrooms){
		 	delete( newState.subFilters.offering_bedrooms);
	 }
 	 const baseURL = setURLFromState({}, newState, true);
  	
  	history.push(`/inventory${baseURL}&bedrooms=${data.search}`);
  }

  render() {
    const { facetData } = this.props;
    
    if(!facetData){
	    return null;
    }
    
    const stat = _generateUnitMixData(facetData);
   	console.log(stat);
   	
   


    return ( 		  	
	    <Card>
		    <CardBody className="dashboard__booking-card">
		      <div className="dashboard__booking-total-container">
	          	<h5 className="dashboard__booking-total-description">Unit Mix Overview
	          		<ToolTip 
		        		title={`Based on ${this.props.groupedCount} Marketproof Vetted buildings`} 
		        		body={`Unit mix based on ${_formatNumber(facetData.count)} new development units within ${this.props.groupedCount} Marketproof Vetted buildings `}
		        	 />
	          	</h5>
			  	<StatIcon className="dashboard__trend-icon" />
	          </div>
	          <ResponsiveContainer height={280} className="dashboard__area">
	            <BarChart data={stat} margin={{ top:40, left: 0 }}>
	              <XAxis dataKey="name" tickLine={true} padding={{ left:25, right:25 }} />
	              {/*<YAxis tickLine={false} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} orientation={dir === 'rtl' ? 'right' : 'left'} />
	              <Tooltip content={<TopCensusTooltipContent colorForKey={{ uv: '#555555' }} theme={themeName} />} />*/}
	             {/* <CartesianGrid vertical={false} />*/}
	              <Tooltip formatter={(value, name, props) => ( _formatNumber(value) )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1}}/>

	              <Bar dataKey="value"  name="Count" barSize={50} onClick={this.handleClick.bind(this)} cursor="pointer">
	                {stat.map((item, index) => <Cell fill={item.color} key={index} />)})}
	                <LabelList dataKey="value" content={renderCustomizedLabel} />
				  </Bar>
	            </BarChart>
	          </ResponsiveContainer>
       </CardBody>
	</Card>
    );
  }
}

export default (UnitMix);
