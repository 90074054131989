import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
	width: 300 + theme.spacing(3) * 2,
  },
  margin: {
	height: theme.spacing(3),
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
	<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
	  {children}
	</Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';



const IOSSlider = withStyles({
  root: {
	color: '#3880ff',
	height: 2,
	padding: '15px 0',
  },
  thumb: {
	height: 28,
	width: 28,
	backgroundColor: '#fff',
	boxShadow: iOSBoxShadow,
	marginTop: -14,
	marginLeft: -14,
	'&:focus, &:hover, &$active': {
	  boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
	  // Reset on touch devices, it doesn't add specificity
	  '@media (hover: none)': {
		boxShadow: iOSBoxShadow,
	  },
	},
  },
  active: {},
  valueLabel: {
	left: 'calc(-50% + 12px)',
	top: -22,
	'& *': {
	  background: 'transparent',
	  color: '#000',
	},
  },
  track: {
	height: 2,
  },
  rail: {
	height: 2,
	opacity: 0.5,
	backgroundColor: '#bfbfbf',
  },
  mark: {
	backgroundColor: '#bfbfbf',
	height: 8,
	width: 1,
	marginTop: -3,
  },
  markActive: {
	opacity: 1,
	backgroundColor: 'currentColor',
  },
})(Slider);

const PrettoSlider = withStyles({
  root: {
	color: '#780F9E',
	height: 2,
	width:"300px"
  },
 
})(Slider);

function valueLabelFormat(value) {
  return value;
}
function AirbnbThumbComponent(props) {
  return (
	<span {...props}>
	  <span className="bar" />
	  <span className="bar" />
	  <span className="bar" />
	</span>
  );
}
function valuetext(value) {
  return `${value}°C`;
}
export default function CustomizedSlider(props) {
  const classes = useStyles();

  return (
	<div className={classes.root}>
	 <div className="material-form__label bold-text">{props.label}</div> 
	 <div className="material-form__label">{props.helperText}</div> 
	  <PrettoSlider 
	  valueLabelDisplay="auto" 
	  valueLabelFormat={valueLabelFormat}
	  getAriaValueText={valuetext}
	  name={props.name} 
	  aria-label="discrete-slider-restrict" 
	  defaultValue={props.defaultVal ? props.defaultVal : 50} 
	  min={props.min ? props.min : 0} 
	  max={props.max ? props.max : 100}
	  step={null}
	  marks={props.marks ? props.marks : null}
	  onChangeCommitted={(e, value) => {
		e.preventDefault();
		props.input.onChange(value);
	  }} />
	 
	</div>
  );
}
