import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import Button from '@material-ui/core/Button';
import UncheckedIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import CheckedIcon from 'mdi-react/CheckboxMarkedIcon';

import moment from 'moment';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	_formatPrice,
	buildingStageToolTips,
	_formatNumber,
	_formatDate
} from "../../../shared/helpers/utils";
const useStyles = makeStyles(theme => ({
 
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    fontSize: '12px',
  },
  formControlPop: {
    margin: theme.spacing(1),
    minWidth: 150,
    fontSize: '12px',
    '& .MuiTextField-root':{
	    marginBottom:'10px'
    },
    '& .MuiInputLabel-root':{
	    fontSize:'14px',
	    paddingLeft:'10px'
    },
    '& .MuiInput-input': {
		fontSize:'14px',
		paddingLeft:'10px'
	},
	'& .MuiListItem-gutters':{
		paddingLeft:'10px'
	}
  },
  formLabel: {
	  fontSize: '14px'
  },
  selectLabel :{
	  padding: "3px 25px",
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px',
	   '& .MuiListItem-root' : {
		     paddingTop: '3px',
			 paddingBottom: '3px'
	   }
	 
  },
  listItemText:{
	  fontSize: '14px',
	  '& .MuiListItemText-primary' : {
		  fontSize: '14px'
	  }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: '5px 10px 10px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiMenuProps = {
  PaperProps: {
    style: {
      marginTop: 0 
    },
  },
  InputProps:{
	  style:{
		  fontSize:'12px'
	  }
  }
};

export default function MultiFilter(props) {
  const classes = props.classes ? props.classes : useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, filter, _filters, setSubFilters} = props;
  const [ searchText, setSearchText ] = useState(subFilters[filter.search_key] ? subFilters[filter.search_key] : false);
  
  const [ searchTextFocus, setSearchTextFocus ] = useState(true);
 
  const [ filterValues, setFilterValues ] = useState(subFilters[filter.search_key] ? subFilters[filter.search_key] : []);
  
  
  const [open, setOpen] = React.useState(false);
  
  const handleChange = (selectValue) => {
	  console.log(selectValue)
	  let value = filterValues.slice();
	  
	  if(selectValue == ""){
		  value = [];
		  setFilterValues(value);
		  return
	  }
	  
	  if(value && Array.isArray(value)){
		      const index = value.indexOf(selectValue);
			  if(index>=0){
				  
				 value.splice(index, 1);
			  }else{
				  value = [...value, selectValue];
			  }
			  
			  		 
	  }
	  
	  setFilterValues(value);
	  setOpen(true) 
	  //setSubFilter(event.target.name, value);
  };
  
  const handleReset = event => {
	  console.log("reset")
	  event.stopPropagation();
	  setFilterValues([]);
	  setOpen(true)
  }
  const handleChangeMultiple = ()  => {
	setOpen(false); 
   
    console.log(filter.search_key, filterValues)
    setSubFilter(filter.search_key, filterValues);
  };
  
  const handleFocus = type => {
	  if(type === 'search-text'){
		  setSearchTextFocus(true);
	  }else if(type === 'max-date'){
		  setSearchTextFocus(false);
	  }
  }
 
  
  
  const handleClose = event => {
	
  };

  const handleOpen = () => {
    setOpen(true);
  };
  
  const setTitle = (search_key, search_value) => {
	  let title = null;
	  if(search_value.length>0){
		title = _filters.filter(filter => filter.search_key === search_key)[0].values.filter(value =>  search_value.indexOf(value.filter)>=0).length > 0 
	  		?
	  		_filters.filter(filter => filter.search_key === search_key)[0].values.filter(value => search_value.indexOf(value.filter)>=0)[0].name
	  		:'';
	  }
	  return title ? [title] : [];
	  
  }
      
  return (
	  		
		  		<FormControl className={classes.formControl}>
			        <InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
			        	{filter.default_name && !subFilters[filter.search_key] ? filter.default_name: filter.name}
			        </InputLabel>
			        <Select
			          labelId={`${filter.search_key}-label`}
			          id={`${filter.search_key}`}
			          name={filter.search_key}
			          value={subFilters[filter.search_key] ? setTitle(filter.search_key, subFilters[filter.search_key]) : []}
			          onClose={() => { handleChangeMultiple() } }
			          //onChange={handleChange}
			          open={open}
			          onOpen={handleOpen}
			          MenuProps={{disablePortal: true}}
			          input={<Input id={`${filter.search_key}`} />}
			          renderValue={selected => {
				          console.log(selected)
				          if(subFilters && subFilters[filter.search_key] && subFilters[filter.search_key].length>1){
					        return (
					            <div>			           
					               {selected} <span className="small-text light-text">(+{`${subFilters[filter.search_key].length-1} More`})</span>		
					            </div>
				            )
				          }else{
					          return (
					            <div>			           
					               {selected}			
					            </div>
				            )
				          }
				       }}
			          multiple
			          MenuProps={MenuProps}
			          className={classes.menuItem} 
			        >	
			           	        
			          {filter.values.filter(item => item.search_value != "").map(item => (
			            <MenuItem key={item.filter} value={item.filter} className={classes.menuItem} onClick={() => { handleChange(item.filter) }}>
						  
						  <Checkbox checked={(filterValues && filterValues.indexOf(item.filter)>-1)} 
						  	icon={<UncheckedIcon size={22} />}
							checkedIcon={<CheckedIcon size={22} />}
						  />
						  <ListItemText primary={item.dropdown_name} className={classes.listItemText} />
			            </MenuItem>
			          ))}	
			           <hr style={{marginTop:'0px'}}/>
				         <div style={{padding:'0px 16px 10px',textAlign:'right'}}>	
				         	<Tooltip title={`Reset filter`}>
						        <Button
							         onClick={handleReset}
							         size="small"
							         style={{marginRight:5}}
							     >	Reset
							     </Button>				 
						  	</Tooltip>		         
					         <Tooltip title={`Apply price filter`}>
						        <Button
							         onClick={handleChangeMultiple}
							         variant="outlined"
							         size="small"
							     >	Apply
							     </Button>				 
						  	</Tooltip>
					  	</div>			          
			        </Select>
			    </FormControl>
		    
	    )	
  
}
