import React, { PureComponent } from 'react';
import { Progress, Card, CardBody } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ComposedChart, Text, LabelList } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import Panel from '../../../../../shared/components/Panel';
import StatIcon from 'mdi-react/MapSearchOutlineIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../../shared/helpers';



const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
      <text x={x + width / 2} y={y - radius} fill="#780F9E" textAnchor="middle" dominantBaseline="middle">
        {value === 0 ? 'N/A' : _formatNumber(value)}
      </text>
    </g>
  );
}

const colors = {
	shadow: "#858AA1",
	active: "#4ce1b6",
	contract: "#B070CC",
	sold: "#D6BAE8",
	recorded: "#FF206E",
	resale: "#28C2FF",
	unsold: "#780F9E"
}

const CustomizedAxisTick = ( {x, y, stroke, payload} ) => {
		
   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">{_capitalizeText(payload.value)}</text>
      </g>
    );
}

class NeighborhoodPrice extends PureComponent {
    
  constructor() {
    super();
    
  }
  
  
  _constructNeighborhoodData = (facetData) => {
	  
	const neighborhoods = facetData.non_zero_price.by_neighborhood.buckets; 
	
	const data = [];
	
	neighborhoods.map(hood => {
		const count = hood.count;
		const avg_ppsf = hood.avg_ppsf;
		const avg_price = hood.avg_price;
		const hoodData = {
	 	  	name: hood.val,
	 	  	count,
	 	  	avg_ppsf,
	 	  	avg_price
 	  	}
 	  	
 	  	data.push(hoodData);	
 	  	
 	  	data.sort((a, b) => (a.avg_ppsf > b.avg_ppsf ? -1 : 1));  	
		
	})
	return data; 
    
  }
 

  
  render() {
      const { facetData } = this.props;    
      if(!facetData){
	    return null;
      }
      
      const data = this._constructNeighborhoodData( facetData );

	  return (
	  	<Card>
		    <CardBody className="dashboard__booking-card">
		      <div className="dashboard__booking-total-container">
	          	<h5 className="dashboard__booking-total-description">Neighborhoods With Most Unsold Inventory</h5>
			  	<StatIcon className="dashboard__trend-icon" />
	          </div>
		      <ResponsiveContainer height={400} className="dashboard__area">
		        <BarChart data={data} margin={{ top: 50, left: -20, bottom: 20, right:20 }}>
			       
		           <XAxis dataKey="name" interval={0} tickLine={false} reversed={false} tick={<CustomizedAxisTick/>} height={60} />				
			       <YAxis  yAxisId="left" tickLine={true} orientation={'left'} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} />
			       <Tooltip formatter={(value, name, props) => ( _formatNumber(value) )} labelFormatter={(value) => `${_capitalizeText(value)}`}/>
			       <Legend />
			       <CartesianGrid vertical={false}/>
			       
			       <Bar dataKey={"avg_ppsf"} yAxisId="left" stackId="a" barSize={35} fill={colors['resale']} >
			       	 <LabelList dataKey="avg_ppsf" content={renderCustomizedLabel} />
			       </Bar>
			      </BarChart>
		      </ResponsiveContainer>
			 </CardBody>
		</Card>
		);
	}
}

export default NeighborhoodPrice;
