import React, { useState, Fragment } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import moment from "moment";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _checkBuildingAltAdr,
  _showAltAdrs,
  _getCrossStreet,
  _getOccupancy,
  _getProjectType,
  _formatNum,
  _getServiceLevel,
} from "../../../shared/helpers/utils";
import TaxAbatement from "../../../shared/components/TaxAbatement";
import { Grid } from "@material-ui/core";
import FeatureIcon from "mdi-react/CheckCircleOutlineIcon";
import NoFeatureIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";
import LinkIcon from "mdi-react/InformationOutlineIcon";
import MapIcon from "mdi-react/MapMarkerCircleIcon";
import { Link } from "react-router-dom";

const MainInfoUnits = (props) => {
  const { buildingRes, hood, boro } = props;
  let first_close_year = buildingRes.first_closing_date
    ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
    : 0;

  if (first_close_year <= 1970) {
    first_close_year = 9999;
  }

  if (
    !buildingRes.pipeline_total_units_proposed &&
    buildingRes.pipeline_dwelling_units_proposed
  ) {
    buildingRes.pipeline_total_units_proposed =
      buildingRes.pipeline_dwelling_units_proposed;
  }

  return (
    <Fragment>
      <div className="product-summary__info project-product-summary_info">
        <div>
          {buildingRes && buildingRes.display_full_street_address && (
            <p
              className={`${
                buildingRes && buildingRes.display_full_street_address
                  ? "has-info"
                  : "no-info"
              }`}
            >
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Primary Address:
              <span className="project_details-text_style">
                <Fragment>
                  {_capitalizeText(buildingRes.display_full_street_address)}
                </Fragment>
              </span>
            </p>
          )}

          {buildingRes && buildingRes.name && (
            <p
              className={`${
                buildingRes && buildingRes.name ? "has-info" : "no-info"
              }`}
            >
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Building Name:
              <span className="project_details-text_style">
                <Fragment>{_capitalizeText(buildingRes.name)}</Fragment>
              </span>
            </p>
          )}

          {buildingRes && _getCrossStreet(buildingRes) && (
            <p
              className={`${
                _getCrossStreet(buildingRes) ? "has-info" : "no-info"
              }`}
            >
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Cross Streets:
              <span className="project_details-text_style">
                <Fragment>{_getCrossStreet(buildingRes)}</Fragment>
              </span>
            </p>
          )}

          {buildingRes && buildingRes.zip && (
            <p className={`${buildingRes.zip ? "has-info" : "no-info"}`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Zip Code:
              <span className="project_details-text_style">
                <Fragment>{buildingRes.zip}</Fragment>
              </span>
            </p>
          )}

          {hood && (
            <p className={`${hood ? "has-info" : "no-info"}`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Neighborhood:
              <span className="project_details-text_style">
                <Fragment>
                  {hood === "nyc" ? (
                    "NYC"
                  ) : (
                    <span className="u_link">{_capitalizeText(hood)}</span>
                  )}
                </Fragment>
              </span>
            </p>
          )}
          {boro && (
            <p className={`${boro ? "has-info" : "no-info"}`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Borough:
              <span className="project_details-text_style">
                <Fragment>
                  {boro === "new york" ? (
                    "New York"
                  ) : (
                    <span className="u_link">{_capitalizeText(boro)}</span>
                  )}
                </Fragment>
              </span>
            </p>
          )}

          <TaxAbatement {...props} />
        </div>
      </div>
    </Fragment>
  );
};

const MainInfoArea = (props) => {
  const { buildingRes } = props;
  let bblDisplay = false;

  let buildingOwnershipType =
    buildingRes.ownership_type && _shouldShow(buildingRes.ownership_type)
      ? buildingRes.ownership_type.toString().toLowerCase().trim()
      : false;

  if (buildingRes.block && buildingRes.block > 0 && buildingRes.lot > 0) {
    let borough_code = buildingRes.borough_code;
    let block = buildingRes.block;
    let lot = buildingRes.lot;
    if (buildingOwnershipType && !buildingOwnershipType.includes("condo")) {
      bblDisplay = (
        <Fragment>
          <div>
            {block} | {lot}{" "}
            <Link
              to={{
                pathname: `https://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${borough_code}&block=${block}&lot=${lot}`,
              }}
              target="_blank"
              replace
              className="fa fa-external-link"
            >
              ACRIS <i aria-hidden="true" />
            </Link>
          </div>
        </Fragment>
      );
    } else {
      bblDisplay = (
        <Fragment>
          {block} | {lot}
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <div className="product-summary__info project-product-summary_info">
        <div>
          {bblDisplay && (
            <p
              className={`${bblDisplay ? "has-info d-flex" : "no-info d-flex"}`}
            >
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Block & Lot:
              <span className="project_details-text_style">{bblDisplay}</span>
            </p>
          )}
          <p className={`${buildingOwnershipType ? "has-info" : "no-info"}`}>
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info" />
            Building Type:
            <span className="project_details-text_style">
              {buildingOwnershipType ? (
                <Fragment>{_capitalizeText(buildingOwnershipType)}</Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>

          {buildingRes.year_built && _shouldShow(buildingRes.year_built) ? (
            <p className={`${buildingRes.year_built ? "has-info" : "no-info"}`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Year Built:
              <span className="project_details-text_style">
                {buildingRes.year_built ? buildingRes.year_built : "--"}
              </span>
            </p>
          ) : null}

          {buildingRes &&
            buildingRes.total_floors &&
            _shouldShow(buildingRes.total_floors) && (
              <p
                className={`${
                  buildingRes.total_floors ? "has-info" : "no-info"
                }`}
              >
                <FeatureIcon className="has-info" />
                <NoFeatureIcon className="no-info" />
                Floors:
                <span className="project_details-text_style">
                  {buildingRes.total_floors
                    ? _formatNum(buildingRes.total_floors) + " floors"
                    : "--"}
                </span>
              </p>
            )}

          <p
            className={`${
              buildingRes &&
              buildingRes.residential_units &&
              _shouldShow(buildingRes.residential_units)
                ? "has-info"
                : "no-info"
            }`}
          >
            <FeatureIcon className="has-info" />
            <NoFeatureIcon className="no-info" />
            Residential Units:
            <span className="project_details-text_style">
              {buildingRes &&
              buildingRes.residential_units &&
              _shouldShow(buildingRes.residential_units) ? (
                <Fragment>
                  {_formatNum(buildingRes.residential_units)} units
                </Fragment>
              ) : (
                <span className="no-info">--</span>
              )}
            </span>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

const MainInfoAreaNew = (props) => {
  const { buildingRes } = props;
  let first_close_year = buildingRes.first_closing_date
    ? parseInt(moment(buildingRes.first_closing_date).format("YYYY"))
    : 0;

  if (first_close_year <= 1970) {
    first_close_year = 9999;
  }
  return (
    <Fragment>
      <div className="product-summary__info project-product-summary_info">
        <div>
          {buildingRes.building_access &&
          _shouldShow(buildingRes.building_access) ? (
            <p
              className={`${
                buildingRes.building_access ? "has-info" : "no-info"
              }`}
            >
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Access:
              <span className="project_details-text_style">
                <Fragment>
                  {_capitalizeText(
                    buildingRes.building_access[0].replace(/_/g, " ")
                  )}
                </Fragment>
              </span>
            </p>
          ) : null}

          {buildingRes.building_service_level &&
          _shouldShow(buildingRes.building_service_level) ? (
            <p
              className={`${
                buildingRes.building_service_level ? "has-info" : "no-info"
              }`}
            >
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Service Level:
              <span className="project_details-text_style">
                <Fragment>
                  {_capitalizeText(
                    _getServiceLevel(buildingRes.building_service_level)
                  )}
                </Fragment>
              </span>
            </p>
          ) : null}

          {buildingRes.sq_ft && _shouldShow(buildingRes.sq_ft) ? (
            <p className={`${buildingRes.sq_ft ? "has-info" : "no-info"}`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Floor Area:
              <span className="project_details-text_style">
                <Fragment>
                  {buildingRes.sq_ft
                    ? _formatNum(buildingRes.sq_ft) + " sqft"
                    : "--"}
                </Fragment>
              </span>
            </p>
          ) : null}

          {buildingRes.lot_area ? (
            <p className={`${buildingRes.lot_area ? "has-info" : "no-info"}`}>
              <FeatureIcon className="has-info" />
              <NoFeatureIcon className="no-info" />
              Lot Size:
              <span className="project_details-text_style">
                <Fragment>
                  {buildingRes.lot_area
                    ? _formatNum(buildingRes.lot_area) + " sqft"
                    : "--"}
                </Fragment>
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

const BuildingDetailsCard = (props) => {
  const { buildingRes } = props;

  let images = _getBuildingPhotos(buildingRes);

  if (images && images.length > 0) {
    images = images.map((image) => {
      return { src: image };
    });
  }
  const buildingAltAdr = _checkBuildingAltAdr(buildingRes.address);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="no-border no-padding">
          <div className="profile-card d-block">
            <Grid className="profile-card__info" container>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <MainInfoUnits {...props} />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <MainInfoArea {...props} />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={12}>
                <MainInfoAreaNew {...props} />
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Fragment>
                  <div className="product-summary__info project-product-summary_info">
                    <div>
                      {buildingRes && buildingAltAdr && (
                        <div className="quick-links">
                          <div className="link-section">
                            <p className="title">
                              <MapIcon
                                className={`${
                                  buildingRes && buildingAltAdr
                                    ? "has-info"
                                    : "no-info"
                                }`}
                              />
                              Alternate Addresses:
                            </p>
                            {Array.isArray(buildingAltAdr) ? (
                              <Fragment>
                                {buildingAltAdr.map((addy) => {
                                  return <p className="link address">{addy}</p>;
                                })}
                              </Fragment>
                            ) : typeof buildingAltAdr === "string" ? (
                              <p className="link address">{buildingAltAdr}</p>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              </Grid>
            </Grid>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BuildingDetailsCard;