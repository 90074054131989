import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ListTable from './ListTable';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import UnitsSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';
import UnitStageFilter from '../../../shared/components/unit_filters/UnitStageFilter';
import searchActions from '../../../redux/actions/searchActions';
import Loading from "../../../shared/components/Loading"
import classNames from 'classnames';
import classnames from 'classnames';
import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { isMobile } from 'react-device-detect';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Box, CardActions, CardContent, Tooltip, Typography ,FormGroup, FormControlLabel } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import cardData from "../data.json"
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import QuarterlyIcon from 'mdi-react/CalendarBlankIcon';
import MonthlyIcon from 'mdi-react/CalendarMonthOutlineIcon';
import WeeklyIcon from 'mdi-react/CalendarClockIcon';
import OpenNewIcon from 'mdi-react/OpenInNewIcon';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import ListViewIcon from 'mdi-react/FormatListBulletedIcon';
import GroupViewIcon from 'mdi-react/DomainIcon';
import StatsViewIcon from 'mdi-react/ChartTimelineVariantIcon';

import ClosedDateIcon from 'mdi-react/ClockIcon';
import ReportedDateIcon from 'mdi-react/ClockOutlineIcon';

import DataExport from './DataExport';
import TypeToggle from './TypeToggle';
import DateType from './DateType';
import ActivityToggle from './ActivityToggle';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Panel from '../../../shared/components/Panel';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';
import EnterpriseGate from '../../../shared/components/payment/EnterpriseGate';
import ToolTip from '../../../shared/components/ToolTip';

import ExportModal from './exportreport/ExportModal';


import queryString from "query-string";

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';

import { getDefaultChartColors, getStatusColors } from '../../../shared/helpers';
import { makeStyles } from '@material-ui/core/styles';
import MarketCard from "./MarketCard"
import MarketCardToJson from './MarketCardToJson'; // Adjust the path as necessary

import {
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatCurrency,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	urlSlugBuilding,
	_isNewUser,
	_isEnterpriseUser,
	_objectEqual,
	getCohortQuery,
	_activityFQ,
	_isHouse,
	_formatBigPrice,
	houseBuildingCohortQuery,
	_activityFL
} from "../../../shared/helpers/utils";

import {
	_marketActivityFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../shared/helpers/filters";

import { _hasPermission } from "../../../shared/helpers/access";

let counter = 0;
am4core.useTheme(am4themes_animated);


const PurpleSwitch = withStyles({
	switchBase: {
		color: '#A3ADC2',

		'&$checked': {
			color: '#A3ADC2',
		},
		'&$checked + $track': {
			backgroundColor: '#D1D6E0',
		},
	},
	checked: {},
	track: { backgroundColor: '#D1D6E0' },
})(Switch);


const createStyledCheckbox = (color) => {
	return withStyles({
	  root: {
		color: color,
		'&$checked': {
		  color: color,
		},
	  },
	  checked: {},
	})((props) => <Checkbox color="default" {...props} />);
  };

let statTypes = [
	  { key: "all", title: "Show marketwide (all property types)", name: "Marketwide", checkedColor:"#D5E1A3", activitesQuery:"-event_tags:(commercial-stage_sponsor)", default: true },
	  { key: "newdev", title: "Show new dev", name: "New Development", checkedColor:"#FF206E", activitesQuery:"(event_tags:(event-stage_sponsor) AND -address_2_normalized:\"\")", default: true },
	  { key: "luxury", title: "Show new dev", name: "Luxury ($4M+)", checkedColor:"#7AE7C7", activitesQuery:"(price:[4000000 TO *])", default: true },
	  { key: "resale_condo", title: "Show resale condo", name: "Resale Condo" , checkedColor:"#780F9E", activitesQuery:"(-event_tags:(event-stage_sponsor) AND event_tags:(condo* !condo*p) AND -address_2_normalized:\"\")", default: true },
	  { key: "resale_coop", title: "Show resale co-op", name: "Resale Co-op" ,checkedColor:"#28C2FF", activitesQuery:"(-event_tags:(event-stage_sponsor) AND event_tags:(co?op* coop* condo*p) AND -address_2_normalized:\"\")", default: true},
	  { key: "single_family", title: "Show single family", name: "Single-Family", checkedColor:"#FFD23F",activitesQuery:"(event_tags:(single?family))" },
	  { key: "multi_family", title: "Show multi family", name: "Multi-Family", checkedColor:"#FF691E",activitesQuery:"(event_tags:(multi?family))" },
	  
  ]

const statsMap = {
	  "contracts" : 
		  { facet: "contract_signed",
			stats: [
				{ title : "Contracts Signed", subtitle : "", statKey : "count", formatter:  _formatNumber},
				{ title : "Avg PPSF", subtitle : "", statKey : "ppsf.avg", formatter:  _formatCurrency},
				{ title : "Avg Price", subtitle : "", statKey : "avg_price", formatter:  _formatPrice},
				{ title : "Avg Days on Market", subtitle : "", statKey : "dom.avg", formatter:  _formatNumber}
			],
			eventQuery: `topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -event_tags:*commercial*`
		  },
	  "listings" : 
		  { facet: "new_listings",
			stats: [
				{ title : "New Listings", subtitle : "", statKey : "count", formatter:  _formatNumber},
				{ title : "Avg PPSF", subtitle : "", statKey : "ppsf.avg", formatter:  _formatCurrency},
				{ title : "Avg Price", subtitle : "", statKey : "avg_price", formatter:  _formatPrice},
				{ title : "Dollar Volume", subtitle : "", statKey : "sum_price", formatter:  _formatBigPrice}
			],
			eventQuery: `topic:listings AND event_tags:just-listed AND -event_tags:rent AND -event_tags:*commercial*`
		  },	
	  "closings" : 
		  { facet: "recorded_sold",
			stats: [
				{ title : "Recorded Sales", subtitle : "", statKey : "count", formatter:  _formatNumber},
				{ title : "Avg PPSF", subtitle : "", statKey : "ppsf.avg", formatter:  _formatCurrency},
				{ title : "Avg Price", subtitle : "", statKey : "avg_price", formatter:  _formatPrice},
				{ title : "Dollar Volume", subtitle : "", statKey : "sum_price", formatter:  _formatBigPrice}
			],
			eventQuery: `topic:transactions AND event_tags:recorded-sale AND -event_tags:*commercial* AND  -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale`
		  },
		"price-drops" : 
		  { facet: "price_decrease",
			stats: [
				{ title : "Price Drops", subtitle : "", statKey : "count", formatter:  _formatNumber},
				{ title : "Avg Discount", subtitle : "", statKey : "price_change.avg", formatter:  _formatPrice},
				{ title : "Avg % Discount", subtitle : "", statKey : "price_change.percent", formatter:  (amt) => Math.round(amt * 10000) / 100 + "%"},
				{ title : "Total Discount", subtitle : "", statKey : "price_change.sum", formatter:  _formatPrice}
			],
			eventQuery: `topic:listings AND event_tags:price-decrease AND -event_tags:price-change_off-market AND -event_tags:rent AND -event_tags:*commercial*`
		  },
		 "price-hikes" : 
		   { facet: "price_increase",
			 stats: [
				{ title : "Price Hikes", subtitle : "", statKey : "count", formatter:  _formatNumber},
				{ title : "Avg Increase", subtitle : "", statKey : "price_change.avg", formatter:  _formatPrice},
				{ title : "Avg % Increase", subtitle : "", statKey : "price_change.percent", formatter:  (amt) => Math.round(amt * 10000) / 100 + "%"},
				{ title : "Total Increase", subtitle : "", statKey : "price_change.sum", formatter:  _formatPrice}
			 ],
			 eventQuery: `topic:listings AND event_tags:price-increase AND -event_tags:price-change_off-market AND -event_tags:rent AND -event_tags:*commercial*`
		   }
  }
const useStyles = makeStyles((theme) => ({
	root: {
	  display: 'flex',
	  flexDirection: 'column',
	  marginLeft:"-8px",
	  alignItems: 'left',
	  '& > *': {
		margin: theme.spacing(1),      
	  },
	  
	},
	button:{
	  '&.selected' :{
		  background:"#EDDEF5"
	  }
	}
  }));
const ChartType = ({chartType, setChartType}) => {
	const classes = useStyles();
	return (
		<div style={{}}>
		
			 <ButtonGroup size="small" aria-label="small button group">							        
				 <Tooltip title="Show contract activity" aria-label="Show contract activity">
						<Button onClick={()=> setChartType("contracts")} className={chartType === "contracts" ? classNames(classes.button, "selected") : classNames(classes.button)}>Contracts</Button>
					</Tooltip>
					<Tooltip title="Show new listing activity" aria-label="Show new listing activity">
						<Button onClick={()=> setChartType("listings")} className={chartType === "listings" ? classNames(classes.button, "selected") : classNames(classes.button)}>Listings</Button>
					</Tooltip>
					<Tooltip title="Show closing activity" aria-label="Show closing activity">
							<Button onClick={()=> setChartType("closings")} className={chartType === "closings" ? classNames(classes.button, "selected") : classNames(classes.button)}>Closings</Button>
					   </Tooltip>	
					<Tooltip title="Show price drop activity" aria-label="Show price drop activity">
							<Button onClick={()=> setChartType("price-drops")} className={chartType === "price-drops" ? classNames(classes.button, "selected") : classNames(classes.button)}>Price Drops</Button>
					   </Tooltip> 
					 <Tooltip title="Show price hike activity" aria-label="Show price hike activity">
							<Button onClick={()=> setChartType("price-hikes")} className={chartType === "price-hikes" ? classNames(classes.button, "selected") : classNames(classes.button)}>Price Hikes</Button>
					   </Tooltip>
			 </ButtonGroup>
		 </div>
	)
}

// const DateType = ({gap, setDateType}) => {
// 	const classes = useStyles();
// 	return (
// 		<div style={{}}>
// 		
// 			 <ButtonGroup size="small" aria-label="small button group">							        
// 				 <Tooltip title="Show latest completed week" aria-label="Show latest completed week">
// 				   <Button onClick={()=> setDateType("+7DAY")} className={gap === "+7DAY" ?  classNames(classes.button, "selected") : classNames(classes.button)}>Weekly</Button>
// 			   </Tooltip>
// 			   <Tooltip title="Show latest completed month" aria-label="Show latest completed month">
// 				   <Button onClick={()=> setDateType("+1MONTH")} className={gap === "+1MONTH" ? classNames(classes.button, "selected") : classNames(classes.button)}>Monthly</Button>
// 			   </Tooltip>
// 			   <Tooltip title="Show latest completed quarter" aria-label="Show latest completed quarter">
// 					   <Button onClick={()=> setDateType("+3MONTH")} className={gap === "+3MONTH" ? classNames(classes.button, "selected") : classNames(classes.button)}>Quarterly</Button>
// 			   </Tooltip>	
// 			   <Tooltip title="Show latest completed year" aria-label="Show latest completed year">
// 						  <Button onClick={()=> setDateType("+1YEAR")} className={gap === "+1YEAR" ? classNames(classes.button, "selected") : classNames(classes.button)}>Yearly</Button>
// 				  </Tooltip>  
// 			 </ButtonGroup>
// 		 </div>
// 	)
// }

const buildingUrl = process.env.API_ROOT + '/query/';

const lsPre = "MSP_2_";
const subFilterKey = 'subFilters';
const unitStagesKey = 'unitStages';
const defaultSubFilter = { offering_verified_status: ["not-abandoned"] };
const buildingStagesKey = 'buildingStages';
const defaultStage = ["all"];
const defaultUnitStage = ['all'];
const gap = syncGenericFilter('gap', lsPre, 'gap', "+7DAY");
const chartType = syncGenericFilter('chartType', lsPre, 'chartType', ["contracts", "listings", "closings"]); // Initialize as an array
const defaultGap = gap === '+1MONTH' ? 'month' : 'week';

const isCustom = ["+7DAY", "+1MONTH", "+3MONTH", "+1YEAR"].indexOf(gap) < 0;
 


const isDST = true; //moment().isDST();
// Get the start of the last completed week
let startOfLastWeek = moment().startOf('isoWeek').subtract(1, 'week');

// Get the end of the last completed week
let endOfLastWeek = startOfLastWeek.clone().endOf('week').toISOString();
function getEndOfLastCompletedQuarter() {
	const now = moment();
	const currentMonth = now.month(); // 0-11

	// Determine the start month of the current quarter
	const startMonthOfCurrentQuarter = currentMonth - (currentMonth % 3);

	// Determine the end of the last quarter
	const lastQuarterEnd = moment()
		.month(startMonthOfCurrentQuarter - 1)
		.endOf('month').toISOString();

	return lastQuarterEnd;
}

const endOfLastCompletedQuarter = getEndOfLastCompletedQuarter();
function getDateOneYearAgoFromFirstOfYear() {
	// Get the first day of the current year
	const firstOfYear = moment().startOf('year');
	// Subtract one year from the first day of the current year
	const oneYearAgo = firstOfYear.subtract(1, 'year').toISOString();
	return oneYearAgo;
}
let ondOfLastYear = getDateOneYearAgoFromFirstOfYear();
function getEndOfLastCompletedMonth() {
	const lastCompletedMonthEnd = moment().subtract(1, 'month').endOf('month').toISOString();
	return lastCompletedMonthEnd;
}

const endOfLastCompletedMonth = getEndOfLastCompletedMonth();
let defaultDateStart = '01/07/2019';
let dateStart = defaultDateStart;
let dateEnd = endOfLastWeek;
let customDate = false;
 const calculatePreviousPeriod = (startDate, endDate) => {
	  const endOfDay = endDate ? moment(endDate).endOf('day') : moment().endOf('day');
	  const startOfDay = moment(startDate).startOf('day');
  
	  if (startOfDay.isAfter(endOfDay)) {
		  throw new Error('Start date cannot be after end date.');
	  }
  
	  const duration = endOfDay.diff(startOfDay, 'days') + 1; // Adding 1 to include the end date fully
	  const previousPeriodStartDate = moment(startOfDay).subtract(duration, 'days').toISOString();
	  const previousPeriodEndDate = moment(endOfDay).subtract(duration, 'days').toISOString();
  
	  return {
		  startDate: startOfDay.toISOString(),
		  endDate: endOfDay.toISOString(),
		  previousPeriodStartDate: previousPeriodStartDate,
		  previousPeriodEndDate: previousPeriodEndDate,
		  gap: `+${duration}DAYS`
	  };
  };
  
if(isCustom){
	dateStart = syncGenericFilter('start_date', lsPre, 'dataStart', dateStart);
	dateEnd = syncGenericFilter('end_date', lsPre, 'dataEnd', endOfLastWeek);
	customDate = calculatePreviousPeriod(dateStart, dateEnd)
}
class UnitsSearchTable extends PureComponent {
	defaultStage = _hasPermission(this.props.user, 'sponsor-buildings') ? ['all'] : ['resale'];
	defaultUnitStage = _hasPermission(this.props.user, 'sponsor-buildings') ? ['all'] : ['all'];
	state = {
		order: syncGenericFilter('order', lsPre, 'order', "desc"),
		orderBy: syncGenericFilter('order_by', lsPre, 'orderBy', "count"),
		selected: new Map([]),
		page: 0,
		rowsPerPage: 50,
		fetchInProgress: false,
		buildingsFetchInProgress: false,
		showBottomLoader: false,
		clearInput: false,
		end: 30,
		filterBorough: "all_boroughs",
		searchText: syncURLSearch(this.props, 'searchText'),
		futureInventoryData: false,
		inventoryData: false,
		originalData: false,
		ovelayMinHeight: false,
		reactTableFiltered: [],
		showCounts: { desktop: 3, tablet: 2, mobile: 1 },
		start: 0,
		stats: {},
		subFilters: syncURLFilters(this.props, _marketActivityFilters, subFilterKey, lsPre, defaultSubFilter),
		tableData: false,
		totalUnsoldUnits: false,
		totalCount: 0,
		groupedView: this.props.buildingView ? false : true,
		groupedData: false,
		groupedCount: false,
		buildingKey: false,
		unitListData: false,
		startDate: isCustom ? dateStart : moment(dateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').add(isDST ? -1 : 0, 'hour').toDate().toISOString(),
		endDate: dateEnd, //moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString(),
		gap: gap,
		dataStart: isMobile
			? syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').toDate().toISOString())
			: syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').toDate().toISOString()),
		dataEnd: syncGenericFilter('end_date', lsPre, 'dataEnd', moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()),
		buildingEventCount: 0,
		selectedDate: syncGenericFilter('selected_date', lsPre, 'selectedDate', false),
		eventTypes: syncGenericFilter('event_view_types', lsPre, 'eventTypes', statTypes.filter(s => s.default).map(s => s.key), true),
		soldTimeField: syncGenericFilter('sold_time', lsPre, 'soldTimeField', "time"),
		buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, lsPre, this.defaultStage),
		unitStages: syncStageFilters(this.props, _unitStageFilter, unitStagesKey, lsPre, this.defaultUnitStage),
		viewType: "building",
		buildingData: false,
		chartType: chartType,
		chartView: "property",
	   isCheckedValue:[],
	   cardListViewData:[],
	   customDate: customDate,
	   unitListTableData:[],
	   isUnitListLoaded:false,
	   isExportModalClicked:false,
	   details:'no-details'

	};

	defaultQ = getCohortQuery(this.props.user, true);
	q = '';
	fq = [];
	property_q = '';
	chartLoaded = false;
 checkBoxEvents=[];
	/*
			[		
			"offering_verified_status:(active \"coming soon\" \"offering plan required\" \"condo declaration vetted\")",
			//"offering_verified_status:(active)",
			"deleted:false",
			//"-key:b-4-2315-44th-dr-11101"
		];
	*/

	fl = buildingFL;
	chart;
	_activityFilter = _marketActivityFilters.filter(filter => !filter.filter_type && !filter.activity_hide);
	_propertyFilter = _marketActivityFilters.filter(filter => filter.filter_type && filter.filter_type === 'property' && !filter.activity_hide);
	_buildingFilter = _marketActivityFilters.filter(filter => filter.filter_type && filter.filter_type === 'building' && !filter.activity_hide);
	_unitSubFilter = _marketActivityFilters.filter(filter => !(this.props.buildingView && filter.building_hide) && !filter.activity_hide);
	timeout = null;
	_matchedBuildingIds = [];
	_matchedBuildingSlugs = [];
	_building_q = this.defaultQ;
	formattedData;
	noDataIndicator;
	componentDidMount() {
		//this._getBuildings();
		console.log(this.state)
		this._getEvents(true);


	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
		let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
		let unitStageChange = !_isEqual(prevState.unitStages, this.state.unitStages);

		let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ?
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0
						:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
			this.state.isCheckedValue !==prevState.isCheckedValue ||
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.gap !== prevState.gap ||
			this.state.soldTimeField !== prevState.soldTimeField ||
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged ||
			unitStageChange ||
			buildingStageChange ||
			this.state.chartType !== prevState.chartType ||
			this.state.chartView !== prevState.chartView ||
			!_isEqual(this.state.eventTypes, prevState.eventTypes) ||
			!_isEqual(this.state.chartType, prevState.chartType)
			//this.state.viewType !== prevState.viewType
		) {
			const resetPage = this.state.page === prevState.page;
			console.log("component update", prevState, this.state)
			this.defaultQ = getCohortQuery(this.props.user, true);
			this._getEvents(resetPage);
		} else if (this.state.dataStart !== prevState.dataStart ||
			this.state.dataEnd !== prevState.dataEnd ||
			this.state.selectedDate !== prevState.selectedDate ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order) {
			this._getEvents(false)
		}
		//this is call the unit list api only on report download click
		if(this.state.details !== prevState.details ){
			//this._getUnitList()
		}
		if(!_isEqual(this.state.cardListViewData, prevState.cardListViewData)){
			this._renderStatsHTML().then((html) => {
				this.setState({ renderedHtml: html });
			  });
		}
		console.log("componentDidUpdate")
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';
		const { orderBy: stateOrderBy, order: stateOrder } = this.state;

		if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

		this.setState({ order, orderBy });
	};

	handleSelectAllClick = (event, checked) => {
		if (checked) {
			const { tableData } = this.state;
			const newSelected = new Map();
			tableData.map(n => newSelected.set(n.id, true));
			this.setState({ selected: newSelected });
			return;
		}
		this.setState({ selected: new Map([]) });
	};

	handleClick = (event, id) => {
		event.stopPropagation();
		const { selected } = this.state;
		const newSelected = new Map(selected);
		const value = newSelected.get(id);
		let isActive = true;
		if (value) {
			isActive = false;
		}
		newSelected.set(id, isActive);
		this.setState({ selected: newSelected });
	};

	handleBuildingClick = (event, building) => {
		const buildingURL = _getBuildingURL(building);
		this.props.history.push(buildingURL);
	};

	handleGroupedBuildingClick = (event, building) => {

		event.stopPropagation();
		console.log(building);
		const buildingURL = _getBuildingURL(building, 'building_slugs');
		window.open(buildingURL);
		//this.props.history.push(buildingURL);
	};

	handleChangePage = (event, page) => {
		this.setState({ page: page, start: page * this.state.rowsPerPage });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: parseInt(event.target.value) });
	};

	handleDeleteSelected = () => {
		const { tableData } = this.state;
		let copyData = [...tableData];
		const { selected } = this.state;

		for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
			copyData = copyData.filter(obj => obj.id !== selected[i]);
		}

		this.setState({ tableData: copyData, selected: new Map([]) });
	};

	isSelected = (id) => {
		const { selected } = this.state;
		return !!selected.get(id);
	};

	handleExpandPanel(e, expanded, buildingKey) {
		console.log(e, expanded, buildingKey);
		if (expanded) {
			this.setState({ buildingKey: buildingKey });
		}

	}

	_handleBuildingOpen(building) {
		let baseUrl = window.location.href.split('?')[0];
		window.open(`${baseUrl}?building=${urlSlugBuilding(building)}`);
	}

_setExportModalClicked=(type)=>{
	this.setState({isExportModalClicked:type})
}
	getIntervalName(date) {

		const { gap } = this.state;
		if (gap === '+1MONTH') {
			return moment(date).add(1, 'hour').format('MMM, YYYY');
		}

		return moment(date).add(1, 'hour').format("M/D/YY")


	}

	getIntervalTime(date) {

		const { gap } = this.state;
		if (gap === '+1MONTH') {
			return moment(date, 'MMM, YYYY');
		}

		return moment(date, "M/D/YY");

	}
	calculateDateRange = (facetKey, typeKey) => {
		//its calculating the start Date, end Date
		const { endDate, startDate, gap, chartType, cardListViewData } = this.state;
		const isCustom = ["+7DAY", "+1MONTH", "+3MONTH", "+1YEAR"].indexOf(gap) < 0;
		if (isCustom) {
		  return {
			buildingStart: startDate,
			buildingEnd: endDate,
		  };
		}
		console.log(facetKey, typeKey)
		const value = cardListViewData && cardListViewData[facetKey] && cardListViewData[facetKey].by_time.buckets.slice().reverse().find(item => item[typeKey] && item[typeKey].count && item[typeKey].count>0)
		if (!value) {
		  return {
			buildingStart: endDate.clone().startOf("week").toISOString(),
			buildingEnd: endDate.toISOString,
		  };
		}
		let buildingStart = moment(value.val)
		  .add(defaultGap === "month" ? 0 : 0, "day")
		  .add(isDST ? -1 : 0, "hour")
		  .toDate()
		  .toISOString();
		let buildingEnd = `${moment(buildingStart).subtract(1, 'millisecond').toISOString()}${gap}`;
		return {
		  buildingStart,
		  buildingEnd,
		};
	  };	
	filterQueryFacet(queryFacet, statsMap, chartTypes) {
	  const filteredQueryFacet = {};
	  chartTypes.forEach(chartType => {
		if (statsMap[chartType]) {
		  const facetKey = statsMap[chartType].facet;
		  if (queryFacet[facetKey]) {
			filteredQueryFacet[facetKey] = queryFacet[facetKey];
		  }
		} else {
		  console.warn(`chartType "${chartType}" does not exist in statsMap`);
		}
	  });
	
	  return filteredQueryFacet;
	}

	_getUnitList= async (details)=>{
			//this.setState({isUnitListLoaded:true})
			//this function is calling the group query for unit list
			const {chartType } = this.state;
			
			
			let eventTypeQuery = [];
			
			Object.keys(statsMap).map(key => {
				
				if(chartType.indexOf(key)>=0){
					const event = statsMap[key];
					eventTypeQuery.push({event: key, query:`${event.eventQuery} AND `});
				}
			})

			const statQuery=statTypes.filter((item)=>this.state.eventTypes.includes(item.key))
			const Arr=[];
			eventTypeQuery.forEach((item)=>{
				statQuery.forEach((query)=>{
					console.log(item, query)
					const facetKey = statsMap[item.event].facet;
					const typeKey = query.key;
					const dateRange = this.calculateDateRange(facetKey, typeKey);
					Arr.push(({query:`(${item.query}${query.activitesQuery} AND time:[${dateRange.buildingStart} TO ${dateRange.buildingEnd}])`, name:`${query.key} ${item.event}`}))
				})
			})

				  let groupAxiosData = {
					  url: buildingUrl + "we3-activities-3?cache=3600",
					  method: "post",
					  query: {
						  q: details == "no-details" ? "id:-" : "*:*",
						  fq: this.fq,
						  wt: "json",
						  fl: _activityFL,
						  rows: 0,
						  sort : details == "top-50" ? "price desc" : "time desc",
						  "group": true,
						  "group.limit": 30,
						  "group.query": Arr.map(({query})=>query)
					  }
				  };
	
		  let response = await _axiosCall(groupAxiosData)
		  .then(res => {
			  console.log(res, "queryData")
			  return res
		  }).catch(error=>console.log(error))
	
		  
		  const { from_cache, ...data } = response;
		  console.log(data, "myData")
		  
	
		// Function to map queries to response data
	const mapQueriesToResponse = (queries, responseData) => {
		return queries.map(queryObj => {
		  const matchedResponseKey = Object.keys(responseData).find(
			key => key === queryObj.query
		  );
	  
		  return matchedResponseKey
			? 
			{ name: queryObj.name, data: responseData[matchedResponseKey] }
			: null;
		}).filter(item => item !== null);
	  };
	  
	  // Get the mapped array
	  const result = mapQueriesToResponse(Arr, response);
	 

	const order = [
		'newdev',
		'luxury',
		'resale_condo',
		'resale_coop',
		'single_family',
		'multi_family'
	  ];
	  return result.sort((a, b) => {
		  const nameA = a.name.split(' ')[0].toLowerCase();
		  const nameB = b.name.split(' ')[0].toLowerCase();
		  const indexA = order.indexOf(nameA);
		  const indexB = order.indexOf(nameB);
		  return indexA - indexB;
		});
	// this.setState(prevState => ({
	// 	unitListTableData: result.sort((a, b) => {
	// 		const nameA = a.name.split(' ')[0].toLowerCase();
	// 		const nameB = b.name.split(' ')[0].toLowerCase();
	// 		const indexA = order.indexOf(nameA);
	// 		const indexB = order.indexOf(nameB);
	// 		return indexA - indexB;
	// 	  }),
	// 	  isUnitListLoaded:false
	// }));

	  }
	_getEvents = async (resetPage) => {
		this.q = this.defaultQ;
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, buildingStages, unitStages, isCheckedValue, chartType, customDate } = this.state;
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: resetPage, showBottomLoader: true, groupedData: false, renderedHtml: null }));
		}
		const { searchTags } = this.props;

		let locQuery = _tranformTagsQuery(searchTags);

		let bldSearchCount = searchTags.filter(item => item.type === 'building').length;

		let isBuildingQ = bldSearchCount > 0 && bldSearchCount === searchTags.length;


		if (locQuery) {
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}

		if (!isBuildingQ &&
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._buildingFilter.filter(filter => filter.search_key === key);

					if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
						const qVal = theFilter.length > 0 && theFilter[0].values.length > 0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter) > -1).length > 0
							? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter) > -1).map(value => value.search_value)
							: null;
						if (qVal !== null) {
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;
						}
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += `(${searchKey}:(${qVal}))`;
					} else {

						const qVal = theFilter.length > 0 && theFilter[0].values.length > 0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length > 0
							? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
							: null;
						if (qVal !== null) {
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:${qVal})`;
						}


					}

					const dateArrayFilter = this._buildingFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length > 0)

					if (dateArrayFilter.length > 0) {
						let bldgDateQ = '';
						let dateReplaceKeys = [];

						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];
							if (bldgDateQ) {
								let regex = new RegExp(`{${key}}`, "g");
								bldgDateQ = bldgDateQ.replace(regex, moment(subFilterVal).toISOString())
							} else {
								bldgDateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');

							}

							if (bldgDateQ) {
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									bldgDateQ = bldgDateQ.replace(regex, '*')

								})
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += '(';
								this.q += bldgDateQ;
								this.q += ')';
							}

						})

						console.log("priceQ", bldgDateQ, dateReplaceKeys)
					}

				}
			});
		}

		this.property_q = '';
		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._propertyFilter.filter(filter => filter.search_key === key);
					if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
						const qVal = theFilter.length > 0 && theFilter[0].values.length > 0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter) > -1).length > 0
							? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter) > -1).map(value => value.search_value)
							: null;
						if (qVal !== null) {
							this.property_q += this.property_q.length > 0 ? " AND " : "";
							this.property_q += `(${key}:(${qVal.join(" ")}))`;
						}
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.property_q += this.property_q.length > 0 ? " AND " : "";
						this.property_q += `(${searchKey}:(${qVal}))`;
					} else {
						const qVal = theFilter.length > 0 && theFilter[0].values.length > 0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length > 0
							? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
							: null;

						if (qVal !== null) {
							this.property_q += this.property_q.length > 0 ? " AND " : "";
							this.property_q += `(${key}:${qVal})`;
						}
					}

				}
			});
		}




		
		if (buildingStages && buildingStages.length > 0) {
			if (buildingStages.indexOf("all") < 0) {
				this.q += this.q.length > 0 ? " AND " : "";
				this.q += `(${buildingStages.map(stage => {
					if (stage === "resale") {
						return `(building_status:(resale) OR ${houseBuildingCohortQuery})`;
					} else {
						return `(building_status:("${stage}"))`;
					}
				}).join(" OR ")})`;
			}
		}


		let eventQ = '';

		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._activityFilter.filter(filter => filter.search_key === key);

					if (theFilter && theFilter[0] && theFilter[0].multi_filter) {
						const qVal = theFilter.length > 0 && theFilter[0].values.length > 0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter) > -1).length > 0
							? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter) > -1).map(value => value.search_value)
							: null;
						if (qVal !== null) {
							eventQ += eventQ.length > 0 ? " AND " : "";
							eventQ += `(${key}:(${qVal.join(" ")}))`;
						}
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						eventQ += eventQ.length > 0 ? " AND " : "";
						eventQ += `(${searchKey}:(${qVal}))`;
					} else {
						const qVal = theFilter.length > 0 && theFilter[0].values.length > 0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length > 0
							? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
							: null;
						if (qVal !== null) {
							eventQ += eventQ.length > 0 ? " AND " : "";
							eventQ += `(${key}:${qVal})`;
						}
					}


				}
			});
		}
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._activityFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length > 0)

					if (qArrayFilter.length > 0) {


						const filterKey = qArrayFilter[0].filter_key;
						if (replaceKeys[filterKey]) {
							replaceKeys[filterKey] = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						} else {
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}
						if (priceQ[filterKey]) {
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						} else {

							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');


						}
					}

					const dateArrayFilter = this._activityFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length > 0)

					if (dateArrayFilter.length > 0) {
						let dateQ = '';
						let dateReplaceKeys = [];

						dateArrayFilter.forEach(dateF => {
							dateReplaceKeys = [...dateReplaceKeys, ...dateF.search_key];
							if (dateQ) {
								let regex = new RegExp(`{${key}}`, "g");
								dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
							} else {
								dateQ = dateF.search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');

							}

							if (dateQ) {
								dateReplaceKeys.map(key => {
									let regex = new RegExp(`{${key}}`, "g");
									dateQ = dateQ.replace(regex, '*')

								})
								eventQ += eventQ.length > 0 ? " AND " : "";
								eventQ += '(';
								eventQ += dateQ;
								eventQ += ')';
							}

						})


					}
				}
			});
		}
		if (priceQ && Object.keys(priceQ).length > 0) {

			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._activityFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey)[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if (key.indexOf("min") >= 0) {
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					} else {
						if (key.indexOf("max") >= 0 && qArrayFilter.target && qArrayFilter.target == "fq") {
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						} else {
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}

					}


				})

				if (qArrayFilter.target && qArrayFilter.target == "fq") {
					this.unitFq.push(priceQ[filterKey]);
				} else {
					
					eventQ += eventQ.length > 0 ? " AND " : "";
					eventQ += '(';
					eventQ += priceQ[filterKey];
					eventQ += ')';
				}


			})

		}

		

		setURLFromState(this.props, this.state);

		this.q = this.q.length > 0 ? this.q : "*:*";



		this._building_q = this.q;

		let sort = null;
		if (this.state.orderBy && this.state.orderBy.length > 0) {
			sort = `${this.state.orderBy} ${this.state.order}`;
		}

		this.fq = [..._activityFQ.slice(), ...[`{!join from=alternate_keys fromIndex=we3-buildings to=building}${this.q}`]];

		if(eventQ){
			this.fq.push(eventQ);
		}


		if (this.property_q) {
			this.property_q = `{!join from=key fromIndex=we3-properties to=property}${this.property_q} AND deleted:false`;
			this.fq.push(this.property_q);
		}

		if (unitStages && unitStages.length > 0) {
			if (unitStages.indexOf("all") < 0) {

				if (unitStages.indexOf("resale") >= 0) {
					this.fq.push(`event_tags:("event-stage_${[...["unknown"], ...unitStages].join("\" OR \"event-stage_")}")`);
				} else {
					this.fq.push(`event_tags:("event-stage_${unitStages.join("\" OR \"event-stage_")}")`);
				}

				//fq.push(`{!join from=key fromIndex=we3-properties to=property}deleted:false AND source_organizations:(NYAG CDEC ADEC)`);
			}

		}
		if(isCheckedValue ){
			this.fq.push(isCheckedValue.join(" OR "))
			
		}


		const buildingStart = selectedDate ? selectedDate : dataStart;
		const buildingEnd = selectedDate ? gap === '+1MONTH' ? moment(selectedDate).add(1, 'month').toDate().toISOString() : moment(selectedDate).add(1, 'week').toDate().toISOString() : dataEnd;
//console.log(this.state.viewType,this.state.chartType, this.state.chartView,"eventQ")

		let queryStartDate = startDate;
		
		if(customDate && customDate.previousPeriodStartDate){
			queryStartDate = customDate.previousPeriodStartDate;
		}
		const facetStatQuery = {};
		const selectedStatTypes = statTypes.filter(stat => eventTypes.includes(stat.key));
		selectedStatTypes.forEach(type => {

				facetStatQuery[type.key] = {
					"type" : "query",
					"q" : type.activitesQuery,
					"facet" : {
						"avg_price" : "avg(price)",
						"med_price" : "percentile(price, 50)",
						"sum_price" : "sum(price)",
						"ppsf" : {
							"type" : "query",
							"q" : "sq_ft:[300 TO 20000]",
							"facet" : {
								"avg" : "avg(div(price, sq_ft))",
								"med" : "percentile(div(price, sq_ft), 50)",
							}
						},
						"price_change" : {
							"type" : "query",
							"q" : "price_change_amount:*",
							"facet" : {
								"avg" : "avg(price_change_amount)",
								"percent" : "avg(price_change_percent)",
								"sum" : "sum(price_change_amount)"		
							}
						},
						"dom" : {
							"type" : "query",
							"q" : "days_on_market:[3 TO 720]",
							"facet" : {
								"avg" : "avg(days_on_market)",
								"med" : "percentile(days_on_market, 50)"
							}
						}
					}
				}
			
		})
	
		
		let jsonFacet = {};
		
		Object.keys(statsMap).map(key => {
			
			if(chartType.indexOf(key)>=0){
				const stat = statsMap[key];
				jsonFacet[stat.facet] = {
					"type": "query",
					"q": stat.eventQuery,
					"facet": {
						"by_time": {
							"range": {
								"field": "time",
								"start": queryStartDate,
								"end": endDate,
								"gap": gap,
								"facet": facetStatQuery
							}
						}
					}
				}
			}
		})

		
		//const jsonFacet = this.filterQueryFacet(queryFacet, statsMap, chartType);
		console.log(jsonFacet, statsMap, chartType,"jsonFacet")
		// let facet = {}
		// facet[statsMap[chartType].facet] = queryFacet[statsMap[chartType].facet]
		this.fq = this.fq.filter(fq => fq);
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache_time=1440&always-cache=true",
			method: "post",
			query: {
				q: "*:*",
				fq: this.fq,
				wt: "json",
				rows: 0,
				"json.facet": jsonFacet
			}
		};

		let res = await _axiosCall(axiosData)
			.then(res => {

				return res;

			})
			.catch(error => {
				console.log("error: " + error);
			});

		let cardListViewData= [];

		let chartData = [];
		this._matchedBuildingIds = [];
	
		if (res && res.facets) {
		  let facetData = {};
	
		  if (
			res.facets.by_building &&
			res.facets.by_building.buildings &&
			res.facets.by_building.buildings.buckets
		  ) {
			const formattedBuildings = res.facets.by_building.buildings.buckets.map(
			  (bucket) => {
				this._matchedBuildingIds.push(bucket.val);
				return bucket;
			  }
			);
	
			//this._getBuildings(this._matchedBuildingIds, formattedBuildings);
		 
	
	

			
		}
		console.log(res.facets, "res.facets")
		this.setState(prevState => ({
			fetchInProgress: false,
			buildingsFetchInProgress: false,
			showBottomLoader: false,
			groupedData: [],
			start: resetPage ? 0 : this.state.start,
			page: resetPage ? 0 : this.state.page,
			cardListViewData:res.facets,	
			unitListTableData:[]
		}));
	


		}


	

	};




	_formatStats = (response, redoHiddenFields = false) => {

		if (!response && !redoHiddenFields) {
			return;
		}


	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({ searchText: eValue })

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};

	_setSubFilter = (search_key, search_val) => {

		const subF = Object.assign({}, this.state.subFilters, {
			[search_key]: search_val
		});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});

		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}

	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});

		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setBuildingStage = (stage) => {

		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);
		const indexAll = newStages.indexOf('all');


		if (index >= 0) {

			if (stage === "all") {
				newStages = defaultStage
			} else {
				newStages.splice(index, 1);
			}
		} else {
			if (stage === "all") {
				newStages = ["all"]
			} else {
				if (indexAll >= 0) {
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}

		}

		if (newStages.length === 0) newStages = ['all'];

		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	_setUnitStage = (stage) => {

		let newStages = this.state.unitStages.slice();
		const index = newStages.indexOf(stage);
		const indexAll = newStages.indexOf('all');


		if (index >= 0) {

			if (stage === "all") {
				newStages = defaultUnitStage
			} else {
				newStages.splice(index, 1);
			}
		} else {
			if (stage === "all") {
				newStages = ["all"]
			} else {
				if (indexAll >= 0) {
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}

		}
		if (newStages.length === 0) newStages = defaultUnitStage;
		this.setState({
			unitStages: newStages.filter(s => s !== ""),
			start: 0,
			end: this.state.rowPerPage
		});
		setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
	}
	
	

	_resetSubFilter = (search_key, search_val) => {

		const subF = !this.props.buildingView ? Object.assign({}, defaultSubFilter) : {};
		this.setState({
			buildingStages: !this.props.buildingView ? defaultStage : false,
			unitStages: defaultUnitStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, unitStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}
	_resetActive = () => {

		let isDefault = this.props.searchTags.length === 0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultUnitStage, this.state.unitStages) &&
			_objectEqual(defaultStage, this.state.buildingStages);

		return !isDefault;

	}

	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}

	_toggleView = () => {
		const { startDate } = this.state;

		const isStartDST = moment(dateStart, 'MM/DD/YYYY').isDST();
		if (this.state.gap === '+7DAY') {

			this.setState({
				gap: '+1MONTH', selectedDate: false,
				startDate: moment(dateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf('month').add(0, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString(),
				dataStart: isMobile
					? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf('month').toDate().toISOString()
					: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf('month').toDate().toISOString(),
				dataEnd: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()
			})
			setLocalStorage(lsPre, 'gap', '+1MONTH');
		} else {
			this.setState({
				gap: '+7DAY', selectedDate: false,
				startDate: moment(dateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf('week').add(1, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: endOfLastWeek,
				dataStart: isMobile
					? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf('week').add(1, 'day').toDate().toISOString()
					: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf('week').add(1, 'day').toDate().toISOString(),
				dataEnd: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()
			})
			setLocalStorage(lsPre, 'gap', '+7DAY');

		}

	}
	_setDateType = (type) => {
		const { startDate } = this.state;
	console.log(endOfLastWeek, endOfLastCompletedQuarter, ondOfLastYear);
	
		const isStartDST = moment(dateStart, 'MM/DD/YYYY').isDST();
		if (type === '+7DAY'){
			this.setState({
				customDate : false,
				gap: '+7DAY', selectedDate: false,
				startDate: moment(defaultDateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf('week').add(1, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: endOfLastWeek,
				dataStart: isMobile
					? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf('week').add(1, 'day').toDate().toISOString()
					: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf('week').add(1, 'day').toDate().toISOString(),
				dataEnd: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()
			})
			setLocalStorage(lsPre, 'gap', '+7DAY');
		}else if(type === '+1MONTH') {
	
			this.setState({
				customDate : false,
				gap: '+1MONTH', selectedDate: false,
				startDate: moment(defaultDateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf('month').add(0, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: endOfLastCompletedMonth,
				dataStart: isMobile
					? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf('month').toDate().toISOString()
					: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf('month').toDate().toISOString(),
				dataEnd: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()
			})
			setLocalStorage(lsPre, 'gap', '+1MONTH');
		} else if (type === '+3MONTH') {
			
			this.setState({
				customDate : false,
				gap: '+3MONTH', selectedDate: false,
				startDate: moment(defaultDateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf('month').add(0, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: endOfLastCompletedQuarter,
				dataStart: isMobile
					? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf('month').toDate().toISOString()
					: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf('month').toDate().toISOString(),
				dataEnd: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()
			})
			setLocalStorage(lsPre, 'gap', '+1MONTH');
		} else if (type === '+1YEAR') {
			
			this.setState({
				customDate : false,
				gap: '+1YEAR', selectedDate: false,
				startDate: moment(defaultDateStart, 'MM/DD/YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).startOf('month').add(0, 'day').add(isStartDST !== isDST ? -1 : 0, 'hour').toDate().toISOString(),
				endDate: ondOfLastYear,
				dataStart: isMobile
					? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(3, 'months').startOf('month').toDate().toISOString()
					: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').startOf('month').toDate().toISOString(),
				dataEnd: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString()
			})
			setLocalStorage(lsPre, 'gap', '+1MONTH');
		}
	
	}
	_setCustomDate(customDate){
		this.setState({
			customDate : customDate,
			gap: customDate.gap, 
			selectedDate: false,
			startDate: customDate.startDate,
			endDate: customDate.endDate,
			dataStart: customDate.startDate,
			dataEnd: customDate.endDate,
		})
		setLocalStorage(lsPre, 'gap', customDate.gap);
	}

	_toggleListView = (type) => {
		this.setState({ viewType: type })

	}

	_toggleTime = () => {

		if (this.state.soldTimeField === 'event_time') {
			this.setState({ soldTimeField: 'time', selectedDate: false })
			setLocalStorage(lsPre, 'soldTimeField', 'time');
		} else {
			this.setState({ soldTimeField: 'event_time', selectedDate: false })
			setLocalStorage(lsPre, 'soldTimeField', 'event_time');
		}

	}

	_setChartType = (type) => {
		
		this.setState({ chartType: type });
		setLocalStorage(lsPre, 'chartType', type);
	}
	
	_setDetails = (details) => {
		this.setState({ details: details});
	}
	_setIsCheckedValue=(type)=>{
		this.setState({isCheckedValue: type})
	}
	_toggleChartView = () => {

		const { chartView } = this.state;

		if (chartView == 'event') {
			this.setState({
				chartView: 'property',
				unitStages: ["all"],
				start: 0,
				end: this.state.rowPerPage
			});
		} else {
			this.setState({ chartView: 'event' })
		}

	}
	
	_toggleEventTypes = (item) => {
		const { eventTypes } = this.state;
		// const newArray = [...eventTypes]; // Create a copy of the array to avoid mutating the original array
		// const index = newArray.indexOf(item);
		// console.log(item, "myItem")
		
		// const newArray1 = eventTypes.filter(eventType => item.includes(eventType));

		// console.log(newArray1, "newItem2"); // Debugging log
		// if (index === -1) {
		// 	newArray.push(item); // Item not found, add it
		// } else {
		// 	newArray.splice(index, 1); // Item found, remove it
		// }
		// console.log(newArray,"myItem1" )
		this.setState({ eventTypes: item });
		setLocalStorage(lsPre, 'eventTypes', item);
	
	}
	_renderListViewHTML = (unitListTableData, details) => {
		//here we are creating unit list tables
		const { cardListViewData, gap, chartType, eventTypes, startDate, endDate, groupedData, chartView } = this.state;
		if (!unitListTableData || unitListTableData.length === 0) {
			console.log("here")
		  return "";
		}
		const start = performance.now();
		const selectedStatTypes = statTypes.filter(stat => eventTypes.includes(stat.key));
		const listHTML =  selectedStatTypes.map(statType => {
			const statTypeData = statsMap[statType.key];
			return (
				<>
			  
				 {Object.keys(statsMap).filter(key => chartType.indexOf(key)>=0).map(type => {
					const typeData = statsMap[type];
					if (!cardListViewData || !cardListViewData[typeData.facet]) return null;
					let data = unitListTableData.find(item => item.name == `${statType.key} ${type}`)
					console.log(data)
					return (
						<div className="page-section" style={{padding:'0 20px'}}>
						<h4 style={{
							  margin: '10px 0px 0px 0px',
							  paddingBottom: '0px',
							  fontWeight: 600,
							  fontSize:16,
							  textTransform: 'uppercase'}}>{statType.name} {type}</h4>
						<p style={{
						  margin: '0px 20px 20px 0px',
						  paddingBottom: '0px',
						  fontWeight: 300}}>{details == 'top-50' ? `Top 30 by price are shown` : `Latest 30 are shown`}</p>	
						<ListTable
						  data={data.data}
						  isSelected={this.isSelected}
						  handleBuildingClick={this.handleBuildingClick}
						  handleClick={this.handleClick}
						  handleRequestSort={this.handleRequestSort}
						  handleSelectAllClick={this.handleSelectAllClick}
						  {...this.state}
						  {...this.props}
						  viewType="list"
						  isGrouped
						/>
						</div>
					)
				  })}
				</>
			
			);
		  });
		  
		  const end = performance.now();
		  const duration = end - start;
		  console.log(`_renderListViewHTML took ${duration} milliseconds`);
		  
		  return <div>{listHTML}</div>;
		
	  }
	  
	  
	
	_renderStatsHTML = () => {
	  return new Promise((resolve) => {
		const { cardListViewData, gap, chartType, eventTypes, startDate, endDate, groupedData, chartView } = this.state;
	
		if (!cardListViewData || cardListViewData.length === 0) {
		  resolve(null);
		  return;
		}
		
		const start = performance.now();
		
		// Filter the statTypes based on selected eventTypes
		const selectedStatTypes = statTypes.filter(stat => eventTypes.includes(stat.key));
		
		const generateCardTitle = (params, type) =>  {
		  const { location, period } = params;
		  return `${period} ${type.replace(/-/g, " ")} for ${location}`;
		};
	
		let html = [];
		let index = 0;
	
		const processStatType = () => {
		  if (index >= selectedStatTypes.length) {
			const end = performance.now();
			const duration = end - start;
			console.log(`_renderStatsHTML took ${duration} milliseconds`);
			resolve(html);  // Resolve the promise with the full HTML
			return;
		  }
	
		  const statType = selectedStatTypes[index];
		  const statTypeData = statsMap[statType.key];
	
		  html.push(
			<div key={statType.key} className="property-type-section page-section">
			  <h3 style={{
				margin: '10px 20px 0',
				paddingBottom: '0px',
				fontWeight: 600,
				textTransform: 'uppercase',
				fontSize: 18
			  }}>
				{statType.name}
			  </h3>
			  <div style={{ padding: "5px 20px" }}>
				{Object.keys(statsMap).filter(key => chartType.indexOf(key) >= 0).map(type => {
				  const typeData = statsMap[type];
				  if (!cardListViewData || !cardListViewData[typeData.facet]) return null;
	
				  const reportParams = this._getReportParameters();
				  let snapshotTitle = _capitalizeText(generateCardTitle(reportParams['reportSettings'], type));
	
				  return (
					<div key={type} className="chart-type-section">
					  <div style={{
						display: "flex", flexWrap: "wrap", justifyContent: "space-evenly",
						marginTop: "0rem", marginBottom: "3rem", gap: "1rem"
					  }}>
						{typeData.stats.map((stat, index) => (
						  <MarketCard
							key={`${statType.key}-${type}-${index}`}
							cardListViewData={cardListViewData[typeData.facet].by_time.buckets}
							isWeekly={gap}
							chartType={type}
							title={stat.title}
							subTitle={stat.subtitle}
							statKey={stat.statKey}
							formatter={stat.formatter}
							fetchInProgress={this.state.fetchInProgress}
							type={statType}
							activityType={typeData}
							cardIndex={`${statType.name}-${index}`}
							idx={index}
							fq={this.fq}
							startDate={startDate}
							endDate={endDate}
							groupedData={groupedData}
							snapshotTitle={`${snapshotTitle} - ${statType.name}`}
							chartView={chartView}
						  />
						))}
					  </div>
					</div>
				  );
				})}
			  </div>
			</div>
		  );
	
		  index += 1;
		  requestAnimationFrame(processStatType);  // Schedule the next chunk
		};
	
		requestAnimationFrame(processStatType);  // Start processing
	  });
	};


	
	_getListReportParameters = () => {
		const { searchTags } = this.props;
		const {
			data, order, orderBy, selected, rowsPerPage,checked, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, gap, showBottomLoader, unitStages, viewType, chartType, chartView, cardListViewData, isCheckedValue, startDate, endDate, customDate
		} = this.state;
		
		
		const reportSettings={}
		reportSettings['location'] = searchTags.length>0 ? searchTags.map(s => s.name).join(",") : "NYC";
		reportSettings['period'] = gap == '+7DAY' ? 'Weekly' : gap == '+1MONTH' ? 'Monthly' : gap == '+3MONTH' ? "Quarterly" : gap == '+1YEAR' ? "Yearly"  : `${moment(customDate.startDate).format("ll")} to ${moment(customDate.endDate).format("ll")} - `;
		reportSettings['type'] = chartType
		if (
			subFilters &&
			Object.keys(subFilters).length > 0
		) {
			Object.keys(subFilters).map(key => {
				reportSettings[key] = subFilters[key]
			})
		}
		
		console.log(reportSettings)
	
		const reportData = statTypes.filter(s => eventTypes.indexOf(s.key)>=0).map(type => {
			const key = type.key;
				if(cardListViewData && cardListViewData[statsMap[chartType].facet] && 
				cardListViewData[statsMap[chartType].facet].by_time && 
				cardListViewData[statsMap[chartType].facet].by_time.buckets.length){
					return statsMap[chartType].stats.map(stat => {
							 		return MarketCardToJson({
										 cardListViewData: cardListViewData[statsMap[chartType].facet].by_time.buckets,
										 isWeekly: gap,
										 chartType: chartType,
										 title: `${type.name} - ${stat.title}`,
										 subTitle: stat.subtitle,
										 type: type,
										 statKey: stat.statKey,
										 formatter: stat.formatter,
										 fetchInProgress: fetchInProgress,
										 returnData: true
							 		})
						 		})
								 
								
				}
			
		})

		return {reportSettings, reportData};
	}
	_getReportParameters = () => {
	  const { searchTags } = this.props;
	  const {
		gap, chartType, eventTypes, cardListViewData, customDate, startDate, endDate, subFilters
	  } = this.state;
	
	  // Determine the location based on search tags
	  const location = searchTags.length > 0 ? searchTags.map(s => s.name).join(", ") : "NYC";
	
	  // Determine the period based on the gap or custom date range
	  let period;
	  if (customDate) {
		period = `${moment(customDate.startDate).format("ll")} to ${moment(customDate.endDate).format("ll")}`;
	  } else {
		period = gap === '+7DAY' ? 'Weekly' :
		  gap === '+1MONTH' ? 'Monthly' :
		  gap === '+3MONTH' ? 'Quarterly' :
		  gap === '+1YEAR' ? 'Yearly' : '';
	  }
	
	  // Determine the filter criteria based on subFilters
	  const filters = Object.keys(subFilters).reduce((acc, key) => {
		const filterValue = subFilters[key];
		if (filterValue && filterValue.length > 0) {
		  acc[key] = filterValue;
		}
		return acc;
	  }, {});
	
	  // Generate the report settings
	  const reportSettings = {
		location,
		period,
		types: chartType.join(', '), // Include all selected chart types
		filters
	  };
	
	  // Generate the report data for each selected chart type and property type
	  const reportData = statTypes.flatMap(statType => {
		if (!eventTypes.includes(statType.key)) return [];
	
		return chartType.flatMap(type => {
		  const typeData = statsMap[type];
		  if (!typeData || !cardListViewData || !cardListViewData[typeData.facet]) return [];
	
		  // Map over the stats for each type and return formatted data using MarketCardToJson
		  return typeData.stats.map(stat => {
			
	
			return MarketCardToJson({
			  cardListViewData: cardListViewData[typeData.facet].by_time.buckets,
			  isWeekly: gap,
			  chartType: type,
			  title: `${statType.name} - ${stat.title}`,
			  subTitle: stat.subtitle,
			  statKey: stat.statKey,
			  formatter: stat.formatter,
			  fetchInProgress: this.state.fetchInProgress,
			  type: statType,
			  returnData: true // Ensures data is returned in JSON format
			});
		  });
		});
	  });
	
	  return { reportSettings, reportData };
	}

	
	_generateTitle = (params) =>  {
		const { location, period, types } = params;
		
	
		return `${period} ${types.replace(/-/g, " ")} for ${location}`;
	}
	
	
	render() {
		const {
			data, order, orderBy, selected, rowsPerPage,checked, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, gap, showBottomLoader, unitStages, viewType, chartType, chartView, cardListViewData, isCheckedValue, customDate, renderedHtml
		} = this.state;

		const { searchTags, user, classes } = this.props;
		
		
		if(!_hasPermission(this.props.user, 'sponsor-buildings')){
			statTypes = statTypes.filter(s => s.key !== "newdev")
		}
		const reportParams = this._getReportParameters();
		const title = _capitalizeText(this._generateTitle(reportParams['reportSettings']));
		let html = null;
		if(!fetchInProgress){
			//html = this._renderStatsHTML();
		}
		return (
			<Col md={12} lg={12}>
				{console.log(this.state.startDate, this.state.endDate, "dateconsole")}
				<Card>
					{console.log(this.state.unitListTableData,"groupedData")}
					<CardBody style={{ padding: 0 }} >

						<UnitsSearchAppBar
							handleSearch={this._filterTableByInput}
							numSelected={[...selected].filter(el => el[1]).length}
							handleDeleteSelected={this.handleDeleteSelected}
							onRequestSort={this.handleRequestSort}
							subFilters={subFilters}
							searchText={searchText}
							setSubFilter={this._setSubFilter}
							resetSubFilter={this._resetSubFilter}
							_filters={_hasPermission(user, 'admin-filters') ? this._unitSubFilter : this._unitSubFilter.filter(item => !item.admin_only)}
							placeHolder={_hasPermission(user, 'search-firms') ? false : "Borough, Neighborhood or Building"}
							buildingAttributes={_hasPermission(user, 'search-firms') ? false : []}
							{...this.props}
							groupedView
							buildingStages={this.state.buildingStages}
							setBuildingStage={this._setBuildingStage}
							setSubFilter={this._setSubFilter}
							numToShow={5}
							UnitStageFilter={false}
							setSubFilters={this._setSubFilters}
							resetActive={this._resetActive()}
							showExport={false}
							q={'*:*'}
							fq={[...this.fq, ...["hierarchy:[0 TO 1]"]]}
							fl={"display_full_street_address, building_name, address_2_normalized, markets_major, markets_sub, neighborhood, borough, city, zip, time, event_time, price, event_tags, bedrooms, baths, sq_ft".split(", ")}
							core_name={'we3-activities-3'}
							sort={"time desc"}
							export_name={`${this.state.gap === '+1MONTH' ? "Monthly" : "Weekly"} Market Activity - ${selectedDate ? this.getIntervalName(selectedDate) : this.getIntervalName(dataStart) + ' - ' + this.getIntervalName(dataEnd)}`}
							title={'Export Latest Market Activity (CSV)'}
							description={<Fragment><h4><strong>Export the latest market activity for your own analysis.</strong></h4> <br />   The export will include details about activty type, date, price, bed, bath, size and more.</Fragment>}

						/>
				
						<div className="material-table__header-info" style={{display:"flex", alignItem:"bottom",justifyContent:'space-between'}}>
							<div style={{border:'1px solid #BF96D9',padding:'15px', borderRadius:5,position:'relative'}}>
							<p style={{fontSize:15,fontWeight:500,color:'#780F9E',borderBottom:'1px solid #EDDEF5',marginBottom:10,paddingBottom:5}}>Customize Snapshot</p>
							<div style={{display:"flex",alignItems:'baseline'}}>
								
								 <TypeToggle eventTypes={eventTypes} statTypes={statTypes} user={user} setPropertyTypeFilter={this._toggleEventTypes.bind(this)}/>
								 
								 <ActivityToggle chartType={chartType} setChartType={this._setChartType} />
								<div className="ml-2">
									<DateType gap={gap} customDate={customDate} setDateType={this._setDateType} setCustomDate={this._setCustomDate.bind(this)} calculatePreviousPeriod={calculatePreviousPeriod} />
								</div>
								
							</div>
							</div>
							
							<ExportModal parentFetchInProgress={fetchInProgress} 
							     html={renderedHtml} 
								 reportParams={reportParams} 
								 setIsExportModalClicked={this._setExportModalClicked} 
								 unitListTableData={this.state.unitListTableData} 
								 isUnitListLoaded={this.state.isUnitListLoaded}
								 getUnitList={this._getUnitList.bind(this)}
								 setDetails={this._setDetails.bind(this)}
								 renderListViewHTML={this._renderListViewHTML.bind(this)}
								 renderStatsHTML={this._renderStatsHTML.bind(this)}
								 />
							
							
						</div>
						<hr/>
						
						<div style={{ height: 10, padding: "0 10", margin: 10, clear: "both" }} >
							{(fetchInProgress || !renderedHtml) && <LinearLoading />}
						</div>
						
						{ !(fetchInProgress || !renderedHtml) ?
							<>
							<h3 style={{
							  margin: '10px 20px 30px 20px',
							  paddingBottom: '0px',
							  fontWeight: 600,
							  textTransform: 'uppercase'}}>{title}</h3>
							 
							{renderedHtml}
							</>
							:  null
						}
					
					
					</CardBody>
				</Card>
			
			
			</Col>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
	};
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UnitsSearchTable));