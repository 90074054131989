import React, { PureComponent, Fragment } from 'react';
import {
  Bar, CartesianGrid, BarChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList,
} from 'recharts';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';
import ToolTip from '../../../../../shared/components/ToolTip';

import { getDefaultChartColors } from '../../../../../shared/helpers';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
} from "../../../../../shared/helpers/utils";





class UnitMix extends PureComponent {
	
 data = [];
	
 setData = () => {
	 this.data = [
	  {
	    'name': 'Studio',
	    'value': 0,
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0,
	    'color': getDefaultChartColors[0],
	    'search' : 'studio'
	  },
	  {
	    'name': '1 Bed',
	    'value': 0,
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0,
	    'color': getDefaultChartColors[1],
	    'search' : '1-bedroom'
	  },
	  {
	    'name': '2 Bed',
	    'value': 0,
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0,
	    'color': getDefaultChartColors[2],
	    'search' : '2-bedroom'
	  },
	  {
	    'name': '3 Bed',
	    'value': 0,
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0,
	    'color': getDefaultChartColors[3],
	    'search' : '3-bedroom'
	  },
	  {
	    'name': '4+ Bed',
	    'value': 0,
	    'unsold': 0,
	    'sold' : 0,
	    'contract' : 0,
	    'color': getDefaultChartColors[4],
	    'search' : '4-or-more'
	  },
	 
	];
	 
 }

 renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, name } = props;
  const radius = 10;


  const item = this.data.filter(item => item.name == name)[0];
  const pct_sold = Math.round((item.sold / item.value) * 100);
  return (
    <g>
     {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#780F9E" />*/}
      <text x={x + width / 2} y={y - radius} fill="#5C6178" textAnchor="middle" dominantBaseline="middle">
      	<tspan x={x + width / 2} y={y - radius - 15}  font-size="1em" font-weight="400">{value === 0 ? 'None' : value + ' Units'}</tspan>
        <tspan x={x + width / 2} y={y - radius - 0}  font-size=".85em" fill="#780F9E"> {value > 0 ? '(' + pct_sold + '% Sold)' : '(N/A)'}</tspan>
        
      </text>
    </g>
  );
};

renderCustomizedLabelNoSales = (props) => {
  const { x, y, width, height, value, name } = props;
  const radius = 10;


  const item = this.data.filter(item => item.name == name)[0];
  const pct_sold = Math.round((item.sold / item.value) * 100);
  return (
    <g>
     {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#780F9E" />*/}
      <text x={x + width / 2} y={y - radius} fill="#5C6178" textAnchor="middle" dominantBaseline="middle">
      	<tspan x={x + width / 2} y={y - radius}  font-size="1em" font-weight="400">{value === 0 ? 'None' : value + ' Units'}</tspan>
        
      </text>
    </g>
  );
};

 colors = {
	shadow: "#858AA1",
	active: "#4ce1b6",
	contract: "#FFD23F",
	sold: "#780F9E",
	recorded: "#FF206E",
	resale: "#28C2FF",
	unsold: "#858AA1"
}

  _predictMissingUnitMix = () => {
	  const { statsRes, unitsRes} = this.props;
	  this.setData();
	  
	  const standard = [
		  [0, 400, 600],
		  [601, 725, 900],
		  [900, 1100, 1400],
		  [1401, 1700, 2200],
		  [2201, 2500, 99999],
	  ]
	  
	  if(unitsRes && unitsRes.length > 0){
		  
		  unitsRes.forEach(unit => {
			  
			  if(unit.bedrooms >= 0){
				  
				  if(unit.bedrooms >=0 && unit.bedrooms < 4){
					  this.data[unit.bedrooms].value++;
				  }else{
					  this.data[4].value++;
				  }
				  
			  }else{
				  if(unit.sq_ft > 0){
					  let hasMatch = false;
					  if(statsRes && statsRes.has_bed && statsRes.has_bed.unit_mix && statsRes.has_bed.unit_mix.buckets){
						  statsRes.has_bed.unit_mix.buckets.forEach(bucket => {
							  if(bucket.med_unit_size && bucket.med_unit_size.length > 2){
								  if(unit.sq_ft >= (bucket.med_unit_size[0] * .95) && unit.sq_ft <= (bucket.med_unit_size[2] * 1.05) && !hasMatch){
									  if(bucket.val >=0 && bucket.val < 4){
									  		this.data[bucket.val].value++;
									   }else{
										   this.data[4].value++;
									   }
									   hasMatch = true;
									   
								  }
							  }
						  })
					  }
					  if(!hasMatch){
						  standard.forEach((bucket, beds) => {
							  if(bucket && bucket.length > 2){
								  if(unit.sq_ft >= (bucket[0] * .95) && unit.sq_ft <= (bucket[2] * 1.05) && !hasMatch){
									  if(beds >=0 && beds < 4){
									  		this.data[beds].value++;
									   }else{
										   this.data[4].value++;
									   }
									   hasMatch = true;
									   
								  }
							  }
						  })
					  }
				  }
			  }
			  
		  })
		  
	  }
	  
	  return this.data;
  }
  _generateUnitMixData = (statsRes) => {
	 if(!statsRes.has_bed || !statsRes.has_bed.unit_mix || !statsRes.has_bed.unit_mix.buckets){
		  return [];
	  }
	  
	  
	
	
	this.setData();

	  
	 statsRes.has_bed.unit_mix.buckets.sort((a, b) =>  a.val - b.val).map((bucket, i) => {
		  
		 if(bucket.val >=0 && bucket.val < 4){
			 this.data[bucket.val].value = bucket.count
			 this.data[bucket.val].unsold = bucket.count
			 if(bucket.by_status && bucket.by_status.buckets){
				 bucket.by_status.buckets.map((status, i) => {
/*
					 if(["in contract", "pending"].indexOf(status.val) >=0 ){
						 this.data[bucket.val].contract += status.count;
						 this.data[bucket.val].unsold -= status.count;
					 }
*/
					 
					 if(["sold", "not_yet_recorded", "in contract", "pending", "bulk sold"].indexOf(status.val) >=0 ){
						 this.data[bucket.val].sold += status.count;
						 this.data[bucket.val].unsold -= status.count;
					 }
					 
				 })
			 }
			 
		 }else{
			 this.data[4].value += bucket.count
			 this.data[4].unsold += bucket.count;
			 if(bucket.by_status && bucket.by_status.buckets){
				 bucket.by_status.buckets.map((status, i) => {
/*
					 if(["in contract", "pending"].indexOf(status.val) >=0 ){
						 this.data[4].contract += status.count;
						 this.data[4].unsold -= status.count;
					 }
*/
					 
					 if(["sold", "not_yet_recorded", "in contract", "pending", "bulk sold"].indexOf(status.val) >=0 ){
						 this.data[4].sold += status.count;
						 this.data[4].unsold -= status.count;
					 }
					 
				 })
			 }
		 }
	  });
	  
	  
	  return this.data;
	 	  
  }
  
  toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  handleClick(data, index) {
  	console.log(data, index);
  	const { history, location } = this.props;
  	const base_url = location.pathname.replace('/tab/overview', '');
  	history.push(`${base_url}/tab/units?offering_bedrooms=${data.search}`);
  }
  render() {
    const { statsRes, location, full, nosales, buildingRes } = this.props;
    
    let stat = false;
    let projected = false;
    if(statsRes.has_bed && statsRes.has_bed.count < (buildingRes.inventory_total * .9) ){
	    stat = this._predictMissingUnitMix();
	    projected = true;
    }else{
	    stat = this._generateUnitMixData(statsRes);
    }
      
     
	//if(!stat || stat.length <= 0) return null;

    return (
     <Col md={12} xl={full ? 12 : 6} lg={full ? 12 : 8} sm={12} xs={12}>		  	
	    <Card>
		    <CardBody className="dashboard__health-chart-card" style={{ borderRadius:0, paddingBottom:0, paddingTop:20 }} >
		      <div className="card__title">
	          <h5 className="card__title-center">{nosales ? `Unit Mix` : `% Sold by Unit Type`}{projected && "*"}
	          	<ToolTip 
	        		title={projected ? `* Some are projected by comparing size of like units` : `Based on information in offering plan or condo declaration`} 
	        		body={<span>*If bedroom information is not available for a specific unit, the sq ft of the unit is used to predict the bedrooms based on similar units in the building.</span>}
	        	/>
	          </h5>
	          </div>
			  {statsRes &&
	          <ResponsiveContainer height={160}>
	          	{!nosales ?
	            	<BarChart data={stat} margin={{ top:55, left: 0 }}>
	              	<XAxis dataKey="name" tickLine={false} padding={{ left: 20, right:20 }} />
	              		<Tooltip formatter={(value, name, props) => ( value ? _formatNumber(value) : '0' )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1}}/>
	               	  	<Bar dataKey={"sold"} name="Sold" stackId="a" barSize={40} fill={this.colors['sold']} onClick={this.handleClick.bind(this)} cursor="pointer">					  	
					  	</Bar>
				  	  	
		              	<Bar dataKey="unsold" name="Unsold" fill={this.colors['unsold']} stackId="a"  barSize={40} minPointSize={10}  onClick={this.handleClick.bind(this)} cursor={"pointer"}>
		                	<LabelList dataKey="value" content={this.renderCustomizedLabel} onClick={this.handleClick.bind(this)} />
					  	</Bar>
					  	
			    	</BarChart>
					:
					<BarChart data={stat} margin={{ top:20, left: 0 }}>
				   		<XAxis dataKey="name" tickLine={false} padding={{ left: 20, right:20 }} />
				   		<Tooltip formatter={(value, name, props) => ( value ? _formatNumber(value) : 'None' )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1}}/>
	               	
	
				  	  	<Bar dataKey="value" name="Unix Mix" fill="#780F9E" barSize={40} minPointSize={15}>
		                	<LabelList dataKey="value" content={this.renderCustomizedLabelNoSales} />
					  	</Bar>				
					</BarChart>
					}
	          	</ResponsiveContainer>
		  	}
       </CardBody>
	</Card>
  </Col>

    );
  }
}

export default (UnitMix);
