import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/LightbulbIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
	_formatBigNumber,
	_formatNumber,
	_formatNumberDecimal
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (lastScore) => {	
	
	
	
	return [{ value: 100-lastScore.site_eui_kbtu_ft, fill: getDefaultChartColors[3] },
				  { value: lastScore.site_eui_kbtu_ft, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, data, lastScore } ) => {
	
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10 }}  >
			<div className="card__title">
			  <div className="card__title">
				  <h5 className="bold-text card__title-center">{`Annual Energy Use`}
					  <ToolTip 
							title={`Energy use by this building per year measured by one thousand British Thermal Units (kBtu).`} 
						/>	          
				  </h5>
				</div>
			</div>
			<div className="dashboard__health-chart"> 
			  <ResponsiveContainer height={180}>
				<PieChart>
				  <Pie data={data} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	
				</PieChart>
			  </ResponsiveContainer>
			  <div className="dashboard__health-chart-info" style={{marginTop:-15}}>
				<TagOutlineIcon style={{ fill: getDefaultChartColors[3] }} />
				<p className="dashboard__health-chart-number">{lastScore.site_eui_kbtu_ft ? _formatNumberDecimal(lastScore.site_eui_kbtu_ft, 1) : "--"}</p>
				<p className="dashboard__health-chart-units">kBTU / SF used</p>
				 
			  </div>
			  <p className="dashboard__goal">Latest avail year {lastScore.year_ending} </p>
			</div>
		</CardBody>);
};

const EnergyUseCard = (props) => {
	const { dataRes, col, translateScore } = props;
	const lastScore = dataRes.docs.filter(item => item.site_eui_kbtu_ft).slice(-1).pop();
	
	const data = calculateBarData(lastScore);
	
	
	return (
			<Card>
				<CardPercent data={data} lastScore={lastScore} />
				
			</Card>
	)
};



export default EnergyUseCard;
