import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import BuyersListInput from './components/BuyersListInput';
import BuyersList from './components/BuyersList';
import buyersListActions from '../../redux/actions/buyersListActions';
import buyersListCard from './types';
import { RTLProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import EmptyBuyersList from './components/EmptyBuyersList';
import { _axiosCall } from '../../shared/helpers/apicalls';
import SavedUnits from './components/SavedUnits/index';

import TrialAlert from '../../shared/components/payment/TrialAlert';

import { buildingFL } from "../../shared/helpers/utils";
const apiRoot = process.env.API_ROOT;
const buildingUrl = apiRoot + '/query/';

class MyBuyersList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    buyerslist: PropTypes.arrayOf(buyersListCard).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      incompleteMyBuyersLists: false,
      completedMyBuyersLists: false,
      buildingsFetchInProgress: false,
      buyersList: null,
      buyersListAnalytics: null,
	  activeTab:'buildings'
    };
  }

  componentWillReceiveProps(nextProps) {
	 this._getBuildings(nextProps);

/*
    if(nextProps.savedBuildings){
		
	}else{
		this.setState( { buyersList: [] } );
	}
*/

  }
  
  componentDidMount() {
	this._getBuildings(this.props);
/*
  	if(this.props.dataSet){
			
	}else{
		this.setState( { buyersList: [] } );
	}
*/
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
	  

	if(this.props.dataSet && this.props.savedBuildings && this.state.buyersList && this.props.savedBuildings.filter(item => !item.deleted).length !== this.state.buyersList.length){
		console.log('calling again');
		//	this._getBuildings(this.props);
	}

	  
	 
  }
  
  _setBuyersList = (res) => {
	  
	 let list = res.docs ? res.docs.map(building => {
		 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
	 }) : []
	 
	
	 this.setState({ 
		buyersList: list,
		incompleteMyBuyersLists: list.filter(item => !item.completed),
		completedMyBuyersLists: list.filter(item => item.completed),	
		buildingsFetchInProgress: false	
	});
  }

  
  _getBuildings = async (props) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		const _buildingIDs = props.savedBuildings.filter(item => !item.deleted).map(item => item.key).join(' ');
		
		console.log(_buildingIDs);
		
		if(!props.dataSet || this.state.buildingsFetchInProgress){
			return false;
		}
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var q = "*:*";
		
		var fq = [
			"deleted:false",
			"key:("+_buildingIDs+")"
		];


		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: "inventory_unsold desc",
				fl: buildingFL,
				wt: "json",
				rows: signedIn ? 999 : 6,
				"json.facet": {
					total_unsold: "sum(inventory_unsold)"
				}
			}
		};
		
		this._getBuildingAnalytics(_buildingIDs);

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				this._setBuyersList(res);
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };
  
  _getBuildingAnalytics = async (_buildingIDs) => {
						
		var q = "*:*";
		
		var query = [{
				"$facet": {
					"buildingKeys": [{
							"$unwind": "$savedBuildings"
						},
						{
							"$unwind": "$savedBuildings.key"
						},
						{
							"$match": {
								"$and": [{
										"$or": [{
												"savedBuildings.deleted": {
													"$exists": false
												}
											},
											{
												"savedBuildings.deleted": false
											}
										]
									},
									{
										"savedBuildings.key": {
											"$in": _buildingIDs.split(' ')
										}
									}
								]
							}
						},
						{
							"$group": {
								"_id": {
									"key": "$savedBuildings.key",									
								},
								"total": {
									"$sum": 1
								},
								
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": "$_id.key",
								"total": 1
							}
						},
						{ "$sort" : {
								"savedBuildings.created_timestamp" : -1
							}
						},
					]
				}
			}]


		let axiosData = {
			url: apiRoot+ "/buyerslist/analytics/aggregate",
			method: "post",
			query: query
			
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log(res[0].buildingKeys);
				if(res && res[0] && res[0].buildingKeys){
					this.setState({ buyersListAnalytics : res[0].buildingKeys.slice() });
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };
  
  toggle = (tab) => {
	  const { activeTab } = this.state;
	  if (activeTab !== tab) {
		
		this.setState({
		  activeTab: tab,
		});
	  }
	};

  render() {
    const {
      actions, t, theme, rtl, savedBuildings, dataSet
    } = this.props;

    const { incompleteMyBuyersLists, completedMyBuyersLists, buyersList, buyersListAnalytics, activeTab } = this.state;

	if(buyersList === null){
		return null;
	}

	
	
    return (
	  <div className="dashboard">
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`My Saved`}</h3>
            <h3 className="page-subhead subhead">Manage Your Saved Buildings and Units</h3>
          </Col>
        </Row>
        <Row>
	      <Col md={12} className={'mb-3'}>
	       	<TrialAlert />        
	      </Col>
	    </Row>
		
		<div className="tabs tabs--bordered-bottom">
		  <div className="tabs__wrap">
			<Nav tabs>
			  <NavItem>
				<NavLink
				  className={classnames({ active: activeTab === 'buildings' })}
				  onClick={() => {
					this.toggle('buildings');
				  }}
				>
				  <span className="tab-title">Buildings</span>
				</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
					  className={classnames({ active: activeTab === 'units' })}
					  onClick={() => {
						this.toggle('units');
					  }}
					>
					  <span className="tab-title">Units</span>
					</NavLink>
				  </NavItem>
			    
							   
			</Nav>
			
		  </div>            
		</div>

        <TabContent activeTab={activeTab}>
		{activeTab == "buildings" &&
		  <TabPane tabId="buildings">
			{ (buyersList.length <=0) ?
				<div className="mt-5">
					<EmptyBuyersList  />
		  	  	</div>
				:
            	<BuyersList completed actions={actions} list={buyersList} analytics={buyersListAnalytics} />
			}
          </TabPane>
		}
		{activeTab == "units" &&
		  <TabPane tabId="units">
			<SavedUnits />
		  </TabPane>
		}
		</TabContent>
      </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, 
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [], 
	  		dataSet : state.buyersLists.dataSet };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MyBuyersList));
