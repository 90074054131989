import React from 'react';
import PropTypes from 'prop-types';
import { CardBody, Row, Col } from 'reactstrap';
import { 
	_capitalizeText,
} from "../../../../../shared/helpers/utils";

import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import ArrowEqualIcon from 'mdi-react/MinusCircleOutlineIcon';
import ArrowOffMarket from 'mdi-react/CloseCircleOutlineIcon';
import OnTheMarket from 'mdi-react/MapMarkerCircleIcon';
import ContractIcon from 'mdi-react/PencilCircleOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import OfferingIcon from 'mdi-react/StarCircleOutlineIcon';


import { getDefaultChartColors, getStatusColors } from '../../../../../shared/helpers';




const TimeLineIcon = ({ type }) => {
  let Icon;

  return (
    <div className={`timeline__icon timestamp`}>
      
    </div>
  );
};

TimeLineIcon.propTypes = {
  type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
  type: '',
};

const setIcon = ( item ) => {
	if(item.icon){
		if(item.icon === "up"){
			return <ArrowUpIcon size={40} color="#4ce1b6" />
		}else if(item.icon === "down"){
			return <ArrowDownIcon size={40} color="#FF206E" />
		}else if(item.icon === "listed"){
			return <OnTheMarket size={40} color={getStatusColors['active']} />
		}else if(item.icon === "off"){
			return <ArrowOffMarket size={40} color="#A3ADC2" />
		}else if(item.icon === "contract"){
			return <ContractIcon size={40} color={getStatusColors['contract']} />
		}else if(item.icon === "sold"){
			return <SoldIcon size={40} color={getStatusColors['sold']} />
		}else if(item.icon === "offering"){
			return <OfferingIcon size={40} color="#363347" />
		}
		
	}
	
	return <ArrowEqualIcon size={40} color={`#A3ADC2`} />
}

const TimeLineItem = ({
  type, img, title, date, children, item
}) => (
  <div className="timeline__item">
    {img
      ? <div className="timeline__icon"><img src={img} alt="img" /></div>
      : <TimeLineIcon type={type} />
    }
    <CardBody className="timeline__content"> 
      <Row>
	      <Col xs={2} sm={2} md={2} lg={2} xl={1} className="no-padding" >
	      	{setIcon(item)}
	      </Col>
	      <Col xs={10} sm={10} md={10} lg={10} xl={11}  className="no-padding" style={{paddingLeft:"10px"}}>
	      	  <h4 className="subhead timeline__date">{_capitalizeText(type)} on {date}</h4>
		  	  <h5 className="timeline__title">{title}</h5>
		  	  <div><span className="light-text small-text">{item.body}</span></div>
		  	  {children}
		  </Col>
	  </Row>
    </CardBody>
  </div>
);

TimeLineItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.element.isRequired,
};

TimeLineItem.defaultProps = {
  type: '',
  img: '',
  title: '',
  date: '',
};

export default TimeLineItem;
