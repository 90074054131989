import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';

import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from "moment"; 
import RefreshIcon from 'mdi-react/RefreshIcon';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import qs from 'querystring';
import TrialAlert from '../../shared/components/payment/TrialAlert';

import { 
	_capitalizeText,
	_shouldShow,
	_getBuildingPhotos,
	_formatPrice,
	_formatNumber,
	_getBuildingURL,
} from "../../shared/helpers/utils";
import{
	_axiosCall,
} from '../../shared/helpers/apicalls';

const ssoPrivateKey = '488827f05c3a7aba441f752a4a4f198836266f269936a594'; // Your SSO key

const apiURL = process.env.API_ROOT + '/tribe/';



class KnowledgeCenter extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      ssoToken: false,
	      iframeID: 0
	    };
	  }
	  
	componentDidMount() {	
  		//this._mountKnowledgeCenter();  
  		 		
  		this._createSSOToken();  
  		//this._getTribeAccessToken();
  		//this._getTribeGroup();
  	}
  	
  	componentDidUpdate() {	
  		//this._mountKnowledgeCenter();   		
  		//this._getTribeAccessToken();
  		if(!this.state.ssoToken){
	  		this._createSSOToken(); 
  		}
  		
  	}
  	
  	
  	
  	
  	_createSSOToken = () => {
	  	
	 
	  const { user } = this.props;
	  
	  
	  if(!user) return false;
	  
	  let userData = {
	    email: user.email,
	    id: user.id,
	    name: `${user.firstName} ${user.lastName}` ,
	    picture: user.avatar ? user.avatar : `https://buyerslist.s3.amazonaws.com/account.png`,
	    title: user.title ? user.title : ''
	  };
	  console.log(userData);
	  const ssoToken = jwt.sign(userData, ssoPrivateKey, {algorithm: 'HS256'});
  	  this.setState( { ssoToken : ssoToken } );
	}
	
	
	render() {	
		
	   const { ssoToken, iframeID } = this.state;	 
	   if(!ssoToken) return null;
	   return(
		 <Container className="knowledge__container">
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title page-title-hover" onClick={() => {this.setState( { iframeID : iframeID+1 }) }}>{'Community Center'} <RefreshIcon size={18} color="#363347" /></h3>
		        <h3 className="page-subhead subhead">Get expert advice and insights from real estate professionals.</h3>
		      </Col>
		    </Row>
		   <Row>
		      <Col md={12} className={'mb-3 no-padding'}>
		       	<TrialAlert />        
		      </Col>
		    </Row>
		    <Row>
		      <Col md={12} className={"no-padding"}>
		      
			   		<iframe key={iframeID} src={`https://buyerslist.tribe.so/embed/home?components=menu,input,feed&ssoToken=${ssoToken}`} 
				      		frameborder="0" 
				      		scrolling="0" 
				      		tabindex="0" 
				      		title="Tribe" 
				      		width="100%" 
				      		id="knowledge-center-iframe" 
				      		style={{ minHeight: "6000px", height:"100%", width: "100%"}} />
	
		      </Col>
		    </Row>
		 </Container>
		    
		)
	}
}


export default connect(state => ({
  user: state.user,
}))(KnowledgeCenter);
