import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/HomeMinusIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (buildingRes) => {	
	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalSold, fill: '#780F9E' },
  				{ value: totalAvail, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, buildingRes, data } ) => {
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10, borderRadius:0 }}  >
	    	<div className="card__title">
	          <h5 className="card__title-center">{`% Sold`}</h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={160}>
	            <PieChart>
	              <Pie data={data} dataKey="value" cy={65} innerRadius={60} outerRadius={70} />
	
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="pie-chart-info">
	            <TagOutlineIcon style={{ fill: '#780F9E' }} />
	            <h5 className="profile__chart-number">{parseFloat(buildingRes.inventory_pct_sold).toFixed(0)}%</h5>
	            <div className="product-card__subtext">sold</div>
	             <div className="product-card__subtext">({data[0].value} units)</div>
	          </div>
	        </div>
		</CardBody>);
};

const PercentSold = (props) => {
	const { buildingRes } = props;
	const data = calculateBarData(buildingRes);
	
	
	return (
		  <Col md={12} xl={4} lg={6} sm={12} xs={12}>		  	
		    <Card>
		    {props.location ? 
			    <Link to={`${props.location.pathname.replace('/tab/overview', '')}/tab/units?offering_status=sold`}>
				    <CardPercent data={data} buildingRes={buildingRes} link />
				</Link>
			:
				<CardPercent data={data} buildingRes={buildingRes} />
			}
			</Card>
		  </Col>
    )
};



export default PercentSold;
