// import mixpanel from 'mixpanel-browser';
// mixpanel.init("277c2b24fbe30cd9a754a0074bbf9fa2");

import ReactGA from "react-ga";
ReactGA.initialize(process.env.GA_TRACKING_ID);

export const Analytics = (function() {
	var event_names = {
		buildingTags: "building",
		hoodTags: "neighborhood",
		boroTags: "borough",
		unitTags: "unit",
		personTags: "person",
		companyTags: "company"
	};

	var last_url = null;

	function flattenObject(data) {
		var result = {};
		function recurse(cur, prop) {
			if (Object(cur) !== cur) {
				result[prop] = cur;
			} else if (Array.isArray(cur)) {
				for (var i = 0, l = cur.length; i < l; i++)
					recurse(cur[i], prop + "[" + i + "]");
				if (l == 0) result[prop] = [];
			} else {
				var isEmpty = true;
				for (var p in cur) {
					isEmpty = false;
					recurse(cur[p], prop ? prop + "_" + p : p);
				}
				if (isEmpty && prop) result[prop] = {};
			}
		}
		recurse(data, "");
		return result;
	}

	function doTrackLogin(event, authData) {
		if (
			authData.id !== undefined !== undefined
		) {
			ReactGA.set({ userId: authData.id });
		}
	}

	function doTrackGeneralEvent(event, obj) {
		var data = flattenObject(obj);
		let outsideUrl;
		console.log("doTrackGeneralEvent", event, data);
		//take out outbound link url so not split on .
		if (event.includes("outbound")) {
			outsideUrl = event.slice(event.indexOf("link_out:"), event.length);
			event = event.replace(outsideUrl, "");
		}

		var events = event.split(".");
		if (events[0] == "track") {
			events.shift();
		}

		var event_name = events[0];

		for (var i = 1; i < events.length; i++) {
			var sub_event = events[i];
			var sub_event_name = "event_type";
			if (i > 1) {
				sub_event_name += "_" + i;
			}
			if (event_names[sub_event] !== undefined) {
				data[sub_event_name] = event_names[sub_event];
			} else {
				data[sub_event_name] = sub_event;
			}
		}

		var url = window.location.pathname + window.location.search;
	
		if (url !== last_url) {
/*
			ReactGA.pageview(url);
			last_url = url;
			console.log("GA Track Pageview", url);
*/
		}
	
		if ( 
			!window.location.hostname.includes("localhost") &&
			!window.location.hostname.includes("dev.")
		) {
			console.log("fire events GA");

			ReactGA.event({
				category: event_name,
				action: sub_event ? sub_event : "event without sub event",
				label: obj && obj.label ? obj.label : false
			});

			// mixpanel.track(event_name, data);
		}
		//console.log("mixpanel track", event_name, data);
	}

	return {
		doTrackLogin: doTrackLogin,
		doTrackGeneralEvent: doTrackGeneralEvent
	};
})();