import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LayersOutlineIcon from 'mdi-react/LayersOutlineIcon';
import LayersIcon from 'mdi-react/LayersIcon';
import Button from "@material-ui/core/Button";

import FilterListIcon from 'mdi-react/FilterListIcon';
import ShowIcon from 'mdi-react/EyeIcon';
import HideIcon from 'mdi-react/EyeOffIcon';

import { Tooltip } from '@material-ui/core';




class LayerToggleButton extends React.Component {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
  };
  ref = React.createRef();
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };



  render() {
    const { anchorEl } = this.state;
    const { type, toggleLayer, showLayers, layers, clearAllLayers } = this.props;
    return (
      <div className="layer-toggle" ref={this.ref}>
        <Tooltip title={`Show various layers on the map`}>
          <IconButton
            className=""
            color="inherit"
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            
          >
            {(!showLayers || showLayers.length <= 0) ? <LayersOutlineIcon color="#780F9E" /> : <LayersIcon color="#780F9E" />} <span className="btn-text">Layers</span>
          </IconButton>
      </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
          disableScrollLock={true}
          container={this.ref.current}
          PaperProps={{
            style: {
              left: '50%',
              transform: 'translateX(0%) translateY(50px)',
            }
          }}
        >
        {layers.map(layer => {
          
          if(layer.id){
            return (
              <Tooltip title={`${layer.tooltip ? layer.tooltip : ""}`}>
              <MenuItem onClick={(e)=>{toggleLayer(layer.id)}} className="material-table__layer-item">
                  {showLayers.indexOf(layer.id)>=0 ? 
                      <ShowIcon size={20} color={"#780F9E"} style={{marginRight:5}}/> 
                      :
                      <HideIcon size={20} style={{marginRight:5}}/> 
                  }
                  {layer.name}
              </MenuItem>
              </Tooltip>
            )
          }else{
            return <hr/>
          }
            
        })}
        <hr />
        <div style={{ padding: "0px 16px 10px",marginBottom:-6, textAlign: "right" }}>
          <Tooltip title={`Clear all layers`}>
            <Button
              onClick={() => {
                clearAllLayers();
              }}
              variant="outlined"
              size="small"
            >
              {" "}
              Reset
            </Button>
          </Tooltip>
         
        </div>
        </Menu>
        
      </div>
    );
  }
}

export default LayerToggleButton;
