import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";
import ToolTip from '../../../../../shared/components/ToolTip';

const PriceRange = (props) => {
	
	if(props.buildingRes.building_status === "resale"){
		return (

			  <Col md={12} xl={4} lg={6} xs={12}>
			    <Card>
			    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
			      <CardBody className="dashboard__booking-card card__link">
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            {props.buildingRes.offering_verified_status !== "stalled" && props.statsRes && props.statsRes.non_zero_price && props.statsRes.non_zero_price.low_price ? 
				              <span>{_formatPrice(props.statsRes.non_zero_price.low_price)} - {_formatPrice(props.statsRes.non_zero_price.high_price)}</span> 
				            : props.buildingRes.inventory_stats_listings_low_price && props.buildingRes.inventory_stats_listings_low_price > 0 ? 
				              <span>{_formatPrice(props.buildingRes.inventory_stats_listings_low_price)} - {_formatPrice(props.buildingRes.inventory_stats_listings_high_price)}</span> 
				            : <span>--</span>}
			          </h5>
			        {/*  <TrendingUpIcon className="dashboard__trend-icon" /> */}
			        </div>
			        <h5 className="dashboard__booking-total-description">Price Range  <ToolTip 
					 title={"Sold price range of units in this building."} 
					 
					 /></h5>
			        
			      </CardBody>
			    </Link>
			    </Card>
			  </Col>
	  )
	 }else{
		 return (
			  <Col md={12} xl={4} lg={6} xs={12}>
			    <Card>
			    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
			      <CardBody className="dashboard__booking-card card__link">
			        <div className="dashboard__booking-total-container">
			          <h5 className="dashboard__booking-total-title">
			            {props.buildingRes.offering_verified_status !== "stalled" && props.statsRes && props.statsRes.non_zero_price && props.statsRes.non_zero_price.low_price ? 
				              <span>{_formatPrice(props.statsRes.non_zero_price.low_price)} - {_formatPrice(props.statsRes.non_zero_price.high_price)}</span> 
				            : props.buildingRes.offering_verified_status !== "stalled" && props.buildingRes.inventory_stats_listings_low_price && props.buildingRes.inventory_stats_listings_low_price > 0 ? 
				              <span>{_formatPrice(props.buildingRes.inventory_stats_listings_low_price)} - {_formatPrice(props.buildingRes.inventory_stats_listings_high_price)}</span> 
				            : <span>--</span>}
			          </h5>
			        {/*  <TrendingUpIcon className="dashboard__trend-icon" /> */}
			        </div>
			        <h5 className="dashboard__booking-total-description">Price Range <ToolTip 
					 title={"Asking price range of units in this building."} 
					 
					 /></h5>
			        
			      </CardBody>
			    </Link>
			    </Card>
			  </Col>
	  )
		 
	 }
};

export default PriceRange;
