import React, { PureComponent } from 'react';
import {
  Bar, CartesianGrid, BarChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList,
} from 'recharts';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';

import { getDefaultChartColors } from '../../../../../shared/helpers';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
} from "../../../../../shared/helpers/utils";


const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
     {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#780F9E" />*/}
      <text x={x + width / 2} y={y - radius} fill="#780F9E" textAnchor="middle" dominantBaseline="middle">
        {value === 0 ? 'None' : value}
      </text>
    </g>
  );
};

const _generateUnitMixData = (statsRes) => {
	 if(!statsRes.unit_mix || !statsRes.unit_mix.buckets){
		  return [];
	  }
	
	let data = [
	  {
	    'name': 'Studio',
	    'value': 0,
	    'color': getDefaultChartColors[0],
	    'search' : 'studio'
	  },
	  {
	    'name': '1 Bed',
	    'value': 0,
	    'color': getDefaultChartColors[1],
	    'search' : '1-bedroom'
	  },
	  {
	    'name': '2 Bed',
	    'value': 0,
	    'color': getDefaultChartColors[2],
	    'search' : '2-bedroom'
	  },
	  {
	    'name': '3 Bed',
	    'value': 0,
	    'color': getDefaultChartColors[3],
	    'search' : '3-bedroom'
	  },
	  {
	    'name': '4+ Bed',
	    'value': 0,
	    'color': getDefaultChartColors[4],
	    'search' : '4-or-more'
	  },
	 
	];

	  
	 statsRes.unit_mix.buckets.sort((a, b) =>  a.val - b.val).map((bucket, i) => {
		 if(bucket.val >=0 && bucket.val < 4){
			 data[bucket.val].value = bucket.count
		 }else{
			 data[4].value += bucket.count
		 }
	  });
	  
	  
	  return data;
	 	  
  }


class UnitMix extends PureComponent {
  
  toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  handleClick(data, index) {
  	console.log(data, index);
  	const { history, location } = this.props;
  	const base_url = location.pathname.replace('/tab/overview', '');
  	history.push(`${base_url}/tab/units?offering_bedrooms=${data.search}`);
  }
  render() {
    const { statsRes, location, full } = this.props;
    
    const stat = _generateUnitMixData(statsRes);
   	console.log(stat);


    return (
     <Col md={12} xl={full ? 12 : 8} lg={full ? 12 : 8} sm={12} xs={12}>		  	
	    <Card>
		    <CardBody className="dashboard__health-chart-card" style={{ padding:'10px 20px', borderRadius:0 }} >
		      <div className="card__title">
	          <h5 className="card__title-center">{`Unit Mix`}</h5>
	          </div>
	          <ResponsiveContainer height={160}>
	            <BarChart data={stat} margin={{ top:20, left: 0 }}>
	              <XAxis dataKey="name" tickLine={false} padding={{ left: 20, right:20 }} />
	              <Tooltip formatter={(value, name, props) => ( value ? _formatNumber(value) : 'None' )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1}}/>
	              {location ? 
		              <Bar dataKey="value" name="Unix Mix" fill="#780F9E" barSize={40} minPointSize={15}  onClick={this.handleClick.bind(this)} cursor={"pointer"}>
		                <LabelList dataKey="value" content={renderCustomizedLabel} onClick={this.handleClick.bind(this)} />
					  </Bar>
				  :
				  	  <Bar dataKey="value" name="Unix Mix" fill="#780F9E" barSize={40} minPointSize={15}>
		                <LabelList dataKey="value" content={renderCustomizedLabel} />
					  </Bar>				  
				  }
	            </BarChart>
	          </ResponsiveContainer>
       </CardBody>
	</Card>
  </Col>

    );
  }
}

export default (UnitMix);
