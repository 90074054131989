import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/HomeFloor1Icon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';

const calculateBarData = (statsRes, buildingRes) => {	
	
	let totalOriginal = statsRes.original_owner && statsRes.original_owner.times_exchanged && statsRes.original_owner.times_exchanged.buckets && statsRes.original_owner.times_exchanged.buckets.filter(item => item.val === 1 ).length>0 ? statsRes.original_owner.times_exchanged.buckets.filter(item => item.val === 1 )[0].count : 0;
	totalOriginal += statsRes.original_owner && statsRes.original_owner.times_exchanged  && statsRes.original_owner.times_exchanged.buckets && statsRes.original_owner.times_exchanged.buckets.filter(item => item.val === 0 ).length>0 ? statsRes.original_owner.times_exchanged.buckets.filter(item => item.val === 0 )[0].count : 0
	const totalAvail = 	statsRes.original_owner && statsRes.original_owner.count ? statsRes.original_owner.count - totalOriginal : 0;
	return [{ value: totalOriginal, fill: '#780F9E' },
  				{ value: totalAvail, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, statsRes, data } ) => {
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10, borderRadius:0 }}  >
	    	<div className="card__title">
	          <h5 className="card__title-center">{`% Original Owner`}
			  	<ToolTip title={"Percent of initial buyers who has not resold their unit."} />
			  </h5>
	        </div>
	        <div className="dashboard__health-chart"> 
	          <ResponsiveContainer height={160}>
	            <PieChart>
	              <Pie data={data} dataKey="value" cy={65} innerRadius={60} outerRadius={70} />
	
	            </PieChart>
	          </ResponsiveContainer>
	          <div className="pie-chart-info">
	            <TagOutlineIcon style={{ fill: '#780F9E' }} />
	            <h5 className="profile__chart-number">{data[0].value > 0 ? parseFloat((data[0].value / (data[0].value + (data[1].value ? data[1].value : 0)) * 100) ).toFixed(0) + '%' : '--'}</h5>
	            <div className="product-card__subtext">original</div>
	             <div className="product-card__subtext">({data[0].value > 0 ? data[0].value : '0'} units)</div>
	          </div>
	        </div>
		</CardBody>);
};

const OriginalOwner = (props) => {
	const { buildingRes, statsRes, col } = props;
	const data = calculateBarData(statsRes, buildingRes);
	
	
	return (
		  <Col md={12} xl={col ? col : 3} lg={6} sm={12} xs={12}>		  	
		    <Card>
		    {props.location ? 
			    <Link to={`${props.location.pathname.replace('/tab/overview', '')}/tab/units?owner=original`}>
				    <CardPercent data={data} statsRes={statsRes} buildingRes={buildingRes} link />
				</Link>
			:
				<CardPercent data={data} statsRes={statsRes} buildingRes={buildingRes} />
			}
			</Card>
		  </Col>
    )
};



export default OriginalOwner;
