/* eslint-disable react/no-children-prop */
import React, { useState, Fragment } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar, Nav, NavLink, NavItem, TabContent, TabPane, Container
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import LinkIcon from 'mdi-react/LinkIcon';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import SaveIcon from 'mdi-react/ContentSaveOutlineIcon';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { _hasPermission	} from "../../../shared/helpers/access";
import { nanoid } from 'nanoid'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import RichTextEditor from "../../../shared/components/form/RichTextEditor";

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import GridIcon from 'mdi-react/GridIcon';
import CompIcon from 'mdi-react/BookmarkMultipleOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import FinanceIcon from 'mdi-react/FinanceIcon';
import ChartPieIcon from 'mdi-react/ChartPieIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon';

import{
	_slugifyText,
	_privateKey,
	matTheme,
	_urlSafe
} from '../../../shared/helpers/utils';
import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

import{
	_shareThemes,
} from '../../../shared/helpers/access';


const redux_form_name = 'share_report_form';


const styles = theme => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	//marginLeft:"-8px",
	marginTop:"20px",
	marginBottom:"20px",
	alignItems: 'left',
	'& > *': {
	   
	},
  },
  button:{
	'&.selected' :{
		background:"#780F9E",
		color:"#FFF"
	},
	fontSize:15,
	margin:"0 5px 0 0"
  },
  '.btn-toolbar' : {
	  justifyContent:'center'
  }
});
const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
		className="material-form__field"
		label={label}
		error={touched && error}
		value={input.value}
		children={children}
		select={select}
		multiline={rows}
		rows={rows}
		onFocus={e=>{e.stopPropagation();}}
		onClick={e=>{e.stopPropagation();}}
		onChange={(e) => {
		  e.preventDefault();
		  input.onChange(e.target.value);
		}}
		variant="outlined"
	  />
	</ThemeProvider>
);
const renderLinkField = ({
	  input, label, meta: { asyncValidating, touched, error }, children, select, rows
	}) => {
		console.log(touched, error)
		return (
		<ThemeProvider theme={matTheme}>
		  <TextField
			className="material-form__link"
			label={label}
			error={error!==undefined}
			helperText={error}
			value={_urlSafe(input.value)}
			children={children}
			select={select}
			multiline={rows}
			rows={rows}
			onClick={e=>e.stopPropagation()}
			onChange={(e) => {
			  e.preventDefault();
			  
			  input.onChange(e.target.value);
			  //checkLink(e.target.value)
			  
			}}	
		  />
		</ThemeProvider>
		)
};

const renderCheckbox = ({ input, label }) => {
		return (
		  <FormControlLabel
			  control={
				<Checkbox
				  checked={input.value ? true : false}
				  onChange={input.onChange}
				  onClick={e=>e.stopPropagation()}
				/>
			  }
			  label={label}
			/>
   )
}



const renderRichTextEditor = ({
  input, label, meta: { touched, error }, children, select, rows
}) => {
	
	const editorConfig = {
			readonly: false, // all options from https://xdsoft.net/jodit/doc/
			height:200,
			allowResizeX: false,
			enableDragAndDropFileToEditor: true,
			buttons: [
				'bold',
				'italic',
				'underline',			
				'fontsize',
				'|',
				'link', 'hr', 'align', 
				'|',
				'undo', 'redo'
			],
			buttonsMD: [
				'bold',
				'italic',
				'underline',			
				'fontsize',
				'|',
				'link', 'hr', 'align', 
				'|',
				'undo', 'redo'
			],
			buttonsXS: [
				'bold',
				'italic',
				'underline',			
				'fontsize',
				'|',
				'link', 'hr', 'align', 
				'|',
				'undo', 'redo'
			],
			colorPickerDefaultTab: 'text',
			
	}
	return (
		<RichTextEditor
			  value={input.value}
			  onChange={(content) => {
				input.onChange(content);
			  }}	
			  editorConfig={editorConfig}
		/>
   )
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
	touched: PropTypes.bool,
	error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const apiUrl = process.env.API_ROOT + '/buyerslist/';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const checkLink = (formValues) => {
	
	  if(!formValues.customLink || formValues.customLink.length < 3){
		  return sleep(1).then(() => {
			  throw { customLink: 'Must be 3 or more characters.' }
		  
		  })
	  }
			
	  let dupquery = { "custom_link" : formValues.customLink };
			
	  let apiData = {
			url: apiUrl+'sharepage/query',
			method: "post",
			query: dupquery
			};
	  
	  
	  return _axiosCall(apiData)
		.then(res => {
			if(res.length>0){
				throw { customLink: 'Link has already been used.' }
			}
		   
		})	
	  
 }



const ReportForm = ( props ) => {
	
	const { handleSubmit, reset, t, buildingRes, user, toggle, property, values, listing, saved, handleSave, generateShareBase, generateShareLink, classes, formValues, dispatch, group, isPublic } = props;
	const [copied, setCopied] = useState(false);
	
	const [activeTab, setActiveTab] = useState('overview');	
	
	const isPDF = !(formValues && (!formValues['reportType'] || formValues['reportType'] !== "pdf"));
	
	let bCount, uCount = 0;
	if(group && group.group_data){
		if(group.group_data.units && group.group_data.units.length>0){
			uCount = group.group_data.units.length;
		} 
		if(group.group_data.buildings && group.group_data.buildings.length>0){
			bCount = group.group_data.buildings.length;
		} 
	}
	
	const renderRadioThemes = ({ input, label, disabled }) => {
		return (
		  <RadioGroup aria-label="reportTheme" name="reportTheme" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>
			<FormControlLabel value="marketproof" control={<Radio />} label="Marketproof" />
			{user && user.themes && user.themes.length > 0 &&
				<Fragment>
					{user.themes.map(theme => {
						
						if(_shareThemes[theme]){
							return (
								<FormControlLabel value={theme} control={<Radio />} label={_shareThemes[theme]['display_name']} />
							)
						}
					})}
				</Fragment>
			}
		  </RadioGroup>
	   )
	}
	
	const renderRadioContact = ({ input, label, disabled }) => {
		
		return (
		  <RadioGroup aria-label="contactForm" name="contactForm" value={input.value} onChange={input.onChange} onClick={e=>e.stopPropagation()} row>
				
			<FormControlLabel value="ap" control={<Radio />} label="My Profile Only" />		
			{!isPDF && <FormControlLabel value="contact" control={<Radio />} label="Request Information" />}
			{!isPDF && <FormControlLabel value="tour" control={<Radio />} label="Schedule a Tour" />}
			<FormControlLabel value="" control={<Radio />} label="No Contact Info" />
		  </RadioGroup>
	   )
	}
		
	return(
		<form className="material-form" onSubmit={handleSubmit}>
		<Row>
		{!isPublic && (bCount>50 || uCount>50) &&
			<Col sm={12} md={12} lg={12} xl={12}>
			<div style={{fontSize:16,background:"#F5F7FA",padding:10,marginTop:20,border:'1px solid #780F9E'}}>
				<h4 className={"semi-bold-text mb-2"}>Note</h4>
				A maximum of <span className="semi-bold-text">50 buildings and units</span> will be displayed on a share page.
				<br/>
				This collection exceeds the maximum, consider removing some properties before sharing.
			</div>
			</Col>
		}
		</Row>
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"type"} defaultExpanded>
			<ExpansionPanelSummary
			  expandIcon={<ExpandMoreIcon />}
			  aria-label="Expand"
			  aria-controls=""
			  id={"type"}
			>
			<Container>
			
					<span className="material-form__label bold">1) Select Page Type <span className="small-text">(Required)</span></span>
					<div className="small-text light-text">Each page type will present the properties and information in a different format</div>
				
			</Container>	 
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>	
				<Container>		
					<Col sm={12} md={12} lg={12} xl={12}>				 
					<div className={classes.root}>
						<Card>
		
							  <div className="card__title mb-1">
							  <h5 className="bold-text">{'Page Format'}</h5>
							  <hr/>
							</div>
							 <div className="account" style={{ minHeight:0, height:'auto', background:'transparent' }}>
								<div className="form__form-group account__type">
									<Container>
										
										<Row>
											
											<Col md={3} xl={3} sm={6} className="no-padding">
												<div 
													className={`btn btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] == "grid" ? "selected" : ""}`} 
													onClick={()=> {dispatch(change(redux_form_name, 'reportType', "grid"))}}>
													<GridIcon />
													<div>
														<span className="small-text">Grid View Page</span>
													</div>
												</div>
											</Col>
											<Col md={3} xl={3} sm={6} className="no-padding">
												<div 
													className={`btn btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] == "comp" ? "selected" : ""}`} 
													onClick={()=> {dispatch(change(redux_form_name, 'reportType', "comp"))}}>
													<CompIcon />
													<div>
														<span className="small-text">Comp View Page</span>
													</div>
												</div> 
											</Col>
											{bCount > 0 &&							        	
											<Col md={3} xl={3} sm={6} className="no-padding">
												<div 
													className={`btn btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] == "pdf" ? "selected" : ""}`} 
													onClick={()=> {dispatch(change(redux_form_name, 'reportType', "pdf"))}}>
													<FilePdfBoxIcon />
													<div>
														<span className="small-text">Building Report (PDF Export)</span>
													</div>
												</div> 
											</Col>}
										</Row>
									</Container>
									</div>
							 </div>
					
						</Card>
						{user && user.role && user.role.includes("elliman-user") && 
						<Card>
						
							  <div className="card__title mb-1">
							  <h5 className="bold-text">{'Elliman Custom Reports'} <span className="small-text">(Authorized Users Only)</span></h5>
							  <hr/>
							</div>
							 <div className="account" style={{ minHeight:0, height:'auto', background:'transparent' }}>
								<div className="form__form-group account__type">
									<Container>
										
										<Row>
											
											<Col md={3} xl={3} sm={6} className="no-padding">
												<div 
													className={`btn btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] == "building-comp" ? "selected" : ""}`} 
													onClick={()=> {dispatch(change(redux_form_name, 'reportType', "building-comp"))}}>
													<EnterpriseIcon />
													<div>
													<span className="small-text">Building Sales Presentation</span>
													</div>
												</div>
											</Col>
											<Col md={3} xl={3} sm={6} className="no-padding"> 
												<div
													className={`btn disabled btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] =='floorplan-comp' ? "selected" : ""}`} 
													onClick={()=> {return false;dispatch(change(redux_form_name, 'reportType', "floorplan-comp"))}}>
													<SalesGalleryIcon />
													<div>
														<span className="small-text">Floorplan Comp (Coming soon)</span>
													</div>
												</div>  
											</Col>   
											<Col md={3} xl={3} sm={6} className="no-padding">
												<div 
													className={`btn disabled btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] == "sales-report" ? "selected" : ""}`} 
													onClick={()=> {return false;dispatch(change(redux_form_name, 'reportType', "sales-report"))}}>
													<FinanceIcon />
													<div>
													<span className="small-text">Sales Report  (Coming soon)</span>
													</div>
												</div> 
											</Col>							        	
											
											<Col md={3} xl={3} sm={6} className="no-padding">
												<div 
													className={`btn disabled btn-outline-secondary account__btn-type ${formValues && formValues['reportType'] =='market-report' ? "selected" : ""}`} 
													onClick={()=> {return false;dispatch(change(redux_form_name, 'reportType', "market-report"))}}>
													<ChartPieIcon />
													<div>
													<span className="small-text">Market Report  (Coming soon)</span>
													</div>
												</div>
											</Col>  
										</Row>
									</Container>
									</div>
							 </div>
						
						</Card>
					}
					
						
					</div>					
					</Col>
				</Container>
			</ExpansionPanelDetails>
		</ExpansionPanel>
		
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"title"} >
			<ExpansionPanelSummary
			  expandIcon={<ExpandMoreIcon />}
			  aria-label="Expand"
			  aria-controls=""
			  id={"title"}
			>
			<Container>
			
					<span className="material-form__label bold">2) Add Report Title and Message <span className="small-text">(Optional)</span></span>
					<div className="small-text light-text">Title and message help give the reader context and background about this report</div>
				
			</Container>	 
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>	
				<Container>			 
					<div className={classes.root}>
							<Col sm={12} md={12} lg={12} xl={12}> 
							  <div className="share__link">
									<span className="material-form__label  bold">Headline 1</span>
									 <Field
									  name="reportTitle"
									  component={renderTextField}
									  placeholder=""
									  
									/>
								  </div> 
							</Col>
							<Col sm={12} md={12} lg={12} xl={12}> 
							  <div className="share__link">
									<span className="material-form__label  bold">Heading 2</span>
									 <Field
									  name="reportSubTitle"
									  component={renderTextField}
									  placeholder=""
									  
									/>
								  </div> 
							</Col>   
						   <Col sm={12} md={12} lg={12} xl={12}> 
							  <div className="share__link">
									<span className="material-form__label  bold">Message</span>
									 <Field
									  name="message"
									  component={renderRichTextEditor}
									  placeholder=""
									  multiline
									  rows="2"
									  rowsMax="2"
									/>
								  </div> 
							</Col>
					</div>					
				</Container>
			</ExpansionPanelDetails>
		</ExpansionPanel>
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"contact"}>
			<ExpansionPanelSummary
			  expandIcon={<ExpandMoreIcon />}
			  aria-label="Expand"
			  aria-controls=""
			  id={"contact"}
			>
			<Container>
					<span className="material-form__label bold">3) Choose a Contact Method <span className="small-text">(Optional)</span></span>
					<div className="small-text light-text">Allow people to contact you or remove your contact info</div>
				
			</Container>	 
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>	
				<Container>					 
					<div className={classes.root}>
						<Col sm={12} md={12} lg={12} xl={12}>  
						<Field name="contactForm" component={renderRadioContact} label="Add a Contact Form" value="show" />
						</Col>
					</div>					
				</Container>	 	
			</ExpansionPanelDetails>
		</ExpansionPanel> 
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"contact"}>
			<ExpansionPanelSummary
			  expandIcon={<ExpandMoreIcon />}
			  aria-label="Expand"
			  aria-controls=""
			  id={"contact"}
			>
			<Container>
					<span className="material-form__label bold">4) Select Advanced Settings <span className="small-text">(Optional)</span></span>
					<div className="small-text light-text">Link to active listings and display analytics</div>
				
			</Container>	 
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>	
				<Container>					 
					<div className={classes.root}>
						 
						{!isPDF && <Col sm={12} md={12} lg={12} xl={12}> 
							  <div className="share__link mb-2">            
								<Field name="lk" component={renderCheckbox} label="Include a link to active listings (as available)" value="link" /> 
							  </div>
						  </Col>}
						<Col sm={12} md={12} lg={12} xl={12}> 
								  <div className="share__link mb-2">            
									<Field name="showStats" component={renderCheckbox} label="Include stats about this collection (price, inventory, sales progress, etc)" value="show" /> 
								  </div>
						 </Col>
					</div>					
				</Container>	 	
			</ExpansionPanelDetails>
		</ExpansionPanel> 
		{user && user.themes && user.themes.length > 0 &&
		<ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={"contact"}>
			<ExpansionPanelSummary
			  expandIcon={<ExpandMoreIcon />}
			  aria-label="Expand"
			  aria-controls=""
			  id={"contact"}
			>
			<Container>
					<span className="material-form__label bold">5) Pick a Theme <span className="small-text">(Authorized Users Only)</span></span>
					<div className="small-text light-text">Change branding and colors of your Share Page</div>
				
			</Container>	 
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>	
				<Container>					 
					<div className={classes.root}>
						 
						<Field
						  name="reportTheme"
						  component={renderRadioThemes}
						  placeholder=""
						  
						/>
					</div>					
				</Container>	 	
			</ExpansionPanelDetails>
		</ExpansionPanel> 
		}
		
		{(true || !isPDF) &&	
		        	
			  <div style={{border:"1px solid #D6BAE8", padding:"20px", margin:"50px 0 0", background:"#F5F7FA"}}>
				<span className="material-form__label bold">Share Link</span>
				<div className="custom_share">
					<div className="share_base">{generateShareBase(formValues && formValues['reportType'] ? formValues['reportType'] : false)}</ div>
					<div>
						<Field
						  name="customLink"
						  component={renderLinkField}
						  placeholder=""
						  checkLink={checkLink}
						/>
					</div>
					<div>
						<span className="material-form__label"><a href="javascript:void(0)" onClick={() => handleSave(true)}>Preview <OpenInNewIcon size={12} /></a></span>
					</div>
				</div>
				
			  </div>
		}
		<Row>
		
		<Col sm={12} md={12} lg={12} xl={12}> 
		  <div className="share__link">
		  	<hr style={{borderColor:"#EEE"}} />
			  <p>
			  {false && isPDF ? 
				  <span className="material-form__label">Preview and download your PDF report</span>
			  :
			  <span className="material-form__label">Save and copy the link to share.  Anyone with the link will have access.  You can delete or update the page under <a href="/my-shared" target="_blank">My Shared</a></span>
		  	}
			  .</p>   
			  
		  	<hr/>   
		  </div>
		</Col>
		</Row>
			  <ButtonToolbar className="modal__footer">
				  {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
				<Tooltip title="Cancel">
					<Button
						 onClick={toggle}
						 variant="outlined"
						 color=""
						 className=""		         
						 startIcon={null}
					 >	Cancel
					 </Button>				 
				  </Tooltip>{' '}
				{false && isPDF ? 
					<Tooltip title="Preview and download your PDF report">
						<Button
							 variant="contained"
							 color="primary"
							 className="buyersList__primary-button"		         
							 startIcon={<OpenInNewIcon />}
							 onClick={() => handleSave(true)}
						 >	Preview & Download
						 </Button>	
					  </Tooltip>
					
				:
				<Tooltip title="Save & Copy Share Link to Clipboard">
				<CopyToClipboard text={generateShareLink( props )}
					onCopy={() => handleSave()}>
					<Button
						 variant="contained"
						 color="primary"
						 className="buyersList__primary-button"		         
						 startIcon={<LinkIcon />}
					 >	Save & Copy Link
					 </Button>	
				</CopyToClipboard>			 
				  </Tooltip>
				} 
			 </ButtonToolbar>
		</form>
	)
};

ReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default connect(
  (state, props) => {
	  
  const isPublic = props.group && props.group.group_access && props.group.group_access == "public";
  
  
	  
  let savedValues = {};
  if(props.report){
	 savedValues['reportType'] = props.report && props.report.report_type !== undefined ? props.report.report_type : "grid"; 
	 savedValues['ap'] = props.report.custom_data && props.report.custom_data.ap !== undefined ? props.report.custom_data.ap : true;
	 savedValues['lk'] = props.report.custom_data && props.report.custom_data.lk !== undefined ? props.report.custom_data.lk : true;
	 savedValues['saveme'] = props.report.custom_data && props.report.custom_data.saveme !== undefined ? props.report.custom_data.saveme : true;
	 savedValues['customLink'] = props.report.custom_link ? props.report.custom_link : _slugifyText(nanoid(10));
	 savedValues['message'] = props.report.custom_data && props.report.custom_data.message !== undefined ? props.report.custom_data.message : "";
	 savedValues['reportTitle'] = props.report.custom_data && props.report.custom_data.report_title !== undefined ? props.report.custom_data.report_title : "";
	 savedValues['reportSubTitle'] = props.report.custom_data && props.report.custom_data.report_subtitle !== undefined ? props.report.custom_data.report_subtitle : "";
	 savedValues['reportTheme'] = props.report.custom_data && props.report.custom_data.report_theme !== undefined ? props.report.custom_data.report_theme : "marketproof";
	 savedValues['preparedFor'] = props.report.custom_data && props.report.custom_data.prepared_for !== undefined ? props.report.custom_data.prepared_for : "";
	 savedValues['showStats'] = props.report.custom_data && props.report.custom_data.show_stats !== undefined ? props.report.custom_data.show_stats : false;
	 savedValues['contactForm'] = props.report.custom_data && props.report.custom_data.contact_form !== undefined ? props.report.custom_data.contact_form : props.report.custom_data && props.report.custom_data.ap ? "ap" : "";
  }else{
	 savedValues['reportType'] = "grid";
	 savedValues['ap'] = true;
	 savedValues['lk'] = true;
	 savedValues['saveme'] = true;
	 savedValues['customLink'] = _slugifyText(nanoid(10));
	 savedValues['message'] = isPublic ? props.group.group_description : ""; 
	 savedValues['reportTitle'] = isPublic ? props.group.group_name : "";
	 savedValues['reportSubTitle'] = "";
	 savedValues['reportTheme'] = "marketproof";
	 savedValues['preparedFor'] = "";
	 savedValues['showStats'] = false;
	 savedValues['contactForm'] = "ap";
  }
  const initalValues = Object.assign(savedValues , props.user);
  return {
	initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
	formValues: getFormValues(redux_form_name)(state),
	user: state.user,
	isPublic : isPublic
  }}
)(reduxForm({
  form: redux_form_name, // a unique identifier for this form
  asyncValidate: checkLink,
  asyncChangeFields: ["customLink"]
})((withStyles(styles,  { withTheme: true }))(ReportForm)));
