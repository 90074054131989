import {
  AUTHENTICATE,
  AUTHENTICATE_ERROR_AUTH,
} from '../actions/authActions';

const initialState = null;

/*
{
  username: 'kaelgoodman',
  fullName: 'Kael Goodman',
  avatar: `${process.env.PUBLIC_URL}/img/ksg-photo.jpg`,
  role: 'buyer',
  title:'Real Estate Agent',
  email: 'bobby@buyer.com',
  phone: '917-555-1234',
};
*/

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE:
      localStorage && localStorage.setItem('bl-user', JSON.stringify(action.user));  
      return action.user;
    case AUTHENTICATE_ERROR_AUTH:
      return { error: action.error };
    default:
      return state;
  }
}
