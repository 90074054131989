import React, { PureComponent, useState, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';


import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import LendingHistory from './../Profile/components/LendingHistory';
import OfferingHistory from './../Profile/components/OfferingHistory';
import Permits from "./../Permits";

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft:"-8px",
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),      
	},
	
  },
  button:{
	'&.selected' :{
		background:"#D1D6E0"
	}
  }
}));

const Documents = (props) => {
	const { unitsRes, buildingRes, statsRes, user, offeringPlan } = props; 
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'documents');
	
	const [period, setPeriod] = useState("all-time");
	
	 
	const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const summaryTitle = 'Building Documents - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
	const _toggleTab = (tab) => {
		if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/filings/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
		  setActiveTab(tab)
		  Publisher.publish(`track.buildingDocumentsView.${tab}`, {building: buildingRes.key});
		}
	  };
	
	const classes = useStyles();
	
	return(
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
			<div className="tabs"> 	
			<div className="tabs__wrap mb-2">
				<Nav tabs>
				   <NavItem>
						 <NavLink
						   className={classnames({ active: activeTab === 'documents' })}
						   onClick={() => {
							 _toggleTab('documents');
						   }}
						 >
						   <span className="tab-title">Documents</span>
						 </NavLink>
					 </NavItem>
				   <NavItem>
					<NavLink
					  className={classnames({ active: activeTab === 'financing' })}
					  onClick={() => {
						_toggleTab('financing');
					  }}
					>
					  <span className="tab-title">Financing</span>
					</NavLink>
				  </NavItem>
				  <NavItem>
					   <NavLink
						 className={classnames({ active: activeTab === 'permits' })}
						 onClick={() => {
						   _toggleTab('permits');
						 }}
					   >
						 <span className="tab-title">Permits</span>
					   </NavLink>
				   </NavItem>
				  {offeringPlan && offeringPlan[0] &&
					   <NavItem>
						 <NavLink
						   className={classnames({ active: activeTab === 'offering' })}
						   onClick={() => {
							 _toggleTab('offering');
						   }}
						 >
						   <span className="tab-title">Offering Plan</span>
						 </NavLink>
					   </NavItem>
					 }
				  
				  
			  	
				 
				</Nav>
			  </div>
			</div>

		{activeTab === 'financing' &&
			  <TabPane tabId="financing" className="financing__container">
					  <LendingHistory {...props} />              	           
			  </TabPane>
		  }
		  {activeTab === 'offering' &&
			  <TabPane tabId="offering" className="offering__container">
					  <OfferingHistory {...props} />           	           
			  </TabPane>
		  }
		  {activeTab === 'documents' &&
				<TabPane tabId="offering" className="offering__container">
						<LendingHistory {...props} acris />           	           
				</TabPane>
			}
			{activeTab === 'permits' &&
				<TabPane tabId="permits" className="offering__container">
						<Permits {...props} buildingRes={buildingRes} />           	           
				</TabPane>
			}
			  
		</Container>	  
		</div>
		
	)
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Documents));