import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagIcon from 'mdi-react/TagOutlineIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
} from "../../../../shared/helpers/utils";

const Supply = (props) => {
	  if(!props.facetData) return null;
	  
	  const total_count = props.facetData.total_inventory;
	  const total_active = props.facetData.total_inventory_active;
	  const total_contract = props.facetData.total_inventory_contract;
	  const total_sold = props.facetData.total_inventory_sold + props.facetData.total_inventory_not_yet_recorded;
	  const total_resale = props.facetData.total_inventory_resale;
	  const total_not_yet_recorded = props.facetData.total_inventory_not_yet_recorded
 	
	  const total_shadow = total_count - (total_active + total_contract + total_sold + total_resale);
	  
	  const total_sellout = props.facetData.sellout && props.facetData.sellout.total_sellout > 0 ? props.facetData.sellout.total_sellout : false;
	  
	  const remaining_sellout = ((total_shadow + total_active) / total_count) * total_sellout;
	  return (
		    <Card>
		      <CardBody className="dashboard__booking-card">
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            {total_sellout ? _formatPrice(total_sellout) : '--'}
		          </h5>
		          <TagIcon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">Aggregate Total Sellout</h5>
		        
		      </CardBody>
		    </Card>
	  );
};

export default Supply;
