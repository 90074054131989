import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';

import PriceFilter from './PriceFilter';
import DateFilter from './DateFilter';
import TextFilter from './TextFilter';
import MultiFilter from './MultiFilter';
import AutocompleteFilter from './AutocompleteFilter';

import ChangePlan from './../payment/ChangePlan';
import { subscriptionPlans } from './../payment/plans.js';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	buildingStageToolTips
} from "../../../shared/helpers/utils";
import { 
	_hasPermission
} from "../../../shared/helpers/access";
const useStyles = makeStyles(theme => ({
 
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    fontSize: '12px',
  },
  formControlPop: {
    margin: theme.spacing(1),
    minWidth: 150,
    fontSize: '12px',
    '& .MuiTextField-root':{
	    marginBottom:'10px'
    },
    '& .MuiInputLabel-root':{
	    fontSize:'14px',
	    paddingLeft:'10px'
    },
    '& .MuiInput-input': {
		fontSize:'14px',
		paddingLeft:'10px'
	},
	'& .MuiListItem-gutters':{
		paddingLeft:'10px'
	}
  },
  formLabel: {
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px'
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: '5px 10px 10px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiMenuProps = {
  PaperProps: {
    style: {
      marginTop: 0 
    },
  },
  InputProps:{
	  style:{
		  fontSize:'12px'
	  }
  }
};

export default function UnitsSearchTableFiltersSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, _filters, buildingStages, setBuildingStage, numToShow, advFilterName, UnitStageFilter, selectedFilters, setSubFilters, user, hideBuildingStage } = props;
  const [ modal, setModal ] = useState(false);
  const [ nextPlan, setNextPlan ] = useState(false);
  const [ modalMessage, setModalMessage ] = useState("Access to requires an upgrade to your current subscription");
  
  const handleChange = event => {
	  setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);
  };
  
  const handleChip = chip => {
	  console.log(chip)
	 // setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);

	  if(chip === "resale" && !_hasPermission(user, "resale-buildings")){
		  setNextPlan('advanced');
		  setModalMessage(<span>Access to resale buildings requires an upgrade to your subscription.</span>)
		  setModal(true);
	  }else if(chip === "sponsor" && !_hasPermission(user, "sponsor-buildings")){
			setNextPlan('complete-199');
			setModalMessage(<span>Access to new development buildings and inventory requires an upgrade to your subscription.</span>)
			setModal(true);
	  }else if(chip === "pipeline" && !_hasPermission(user, "pipeline-buildings")){
		  setNextPlan('complete-199');
		  setModalMessage(<span>Access to pipeline buildings and inventory requires an upgrade to your subscription.</span>)
		  setModal(true);
	  }else if(chip === "all" && (!_hasPermission(user, "pipeline-buildings") || !_hasPermission(user, "sponsor-buildings") || !_hasPermission(user, "resale-buildings"))){
			setNextPlan('complete-199');
			setModalMessage(<span>Access to all buildings and inventory requires an upgrade to your subscription.</span>)
			setModal(true);
	  }else{
		  setBuildingStage(chip);
	  }
	  

  };
  
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    console.log(event.target)
/*
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
*/
    //setPersonName(value);
  };
  
  const setTitle = (search_key, search_value) => {
	  
	  return _filters.filter(filter => filter.search_key === search_key)[0].values.filter(value => value.filter === search_value).length > 0 
	  		?
	  		_filters.filter(filter => filter.search_key === search_key)[0].values.filter(value => value.filter === search_value)[0].name
	  		:'';
  }
  const slice = numToShow ? numToShow : 7; //isMobile ? 99 : numToShow ? numToShow : 7;
  
  const hasAdvanced = _filters.length > slice;
  
  let chips = ['sponsor', 'resale', 'pipeline', 'all'];
/*
  if(user && _hasPermission(user, "resale-buildings")){
		chips.push('resale')
  }	
  if(user && _hasPermission(user, "pipeline-buildings")){
		chips.push('pipeline')
  }
  if(chips.length>1){
	  chips.push('all');
  }
*/

  const BuildingStageFilters = () => {
	  
	  const nextPlanDetails = nextPlan ? subscriptionPlans.filter(subPlan => subPlan.id === nextPlan)[0] : false;
	  
	  return (
		  <div className={"building_stage_selector"} style={{display:isMobile ? "block" : "flex",alignItems:"center",padding:"15px 0", margin:"0 10px", borderBottom:!isMobile ? "1px solid #DBE0E8" : 'none'}} >
	    	{!isMobile && <span className="label">Building Stage:</span>}
	    	<div className={classes.chips}>
			     {chips && chips.map(chip => {	
				     const selected = buildingStages && buildingStages.indexOf(chip)>=0;     
				     return selected ? 
				     	<Tooltip title={`${buildingStageToolTips[chip]}`}>	
				     		<Chip
						        size="medium"
						        label={`${_capitalizeText(chip)}`}
						        clickable
						        onClick={()=>handleChip(chip)}
						        value={chip}
						        color='primary'
						        deleteIcon={<DoneIcon />}
						        className={classes.chip}
							/>
					      </Tooltip> :
					      <Tooltip title={`${buildingStageToolTips[chip]}`}>
						      <Chip
							        size="medium"
	// 						        variant='outlined'
							        label={`${_capitalizeText(chip)}`}
							        clickable
							        onClick={()=>handleChip(chip)}
							        value={chip}
							        deleteIcon={<DoneIcon />}
							        className={classes.chip}
						      /> 
						 </Tooltip>
					      
				     
			     })}      
			</div>
			<div style={{marginLeft:20, textAlign: isMobile ? 'right' : 'left'}}>
				<a href="https://blocksandlots.com/new-condo-lifecycle-stages-pipeline-sponsor-sale-resale/" target="_blank" style={{fontSize:"11px",color:"#B070CC"}}>
					 Learn More <HelpCircleOutlineIcon size={12} style={{marginTop:"-3px"}}/> 
	    		</a>
	    	</div> 
	    	
		</div>  
		
	  )
  }
  
  const SubFilters = ( { filters } ) => {
	  return(filters.map((filter, i) => {
		  
		  		if(filter.price_filter){
			  		return (
				  		<PriceFilter filter={filter} {...props} />	
				    )		  		
		  		}else if(filter.date_filter){
			  		return (
				  		<DateFilter filter={filter} {...props} />	
				    )		  		
		  		}else if(filter.text_filter){
			  		return (
				  		<TextFilter filter={filter} {...props} />	
				    )		  		
		  		}else if(filter.multi_filter){
			  		return (
				  		<MultiFilter filter={filter} {...props} />	
				    )		  		
		  		}else if(filter.autocomplete){
					return (
						<AutocompleteFilter filter={filter} {...props} />	
				  	)		  		
				}else{
			  		return (
				      	<FormControl className={classes.formControl}>
					        <InputLabel id={`${filter.search_key}_filter-label`} className={classes.formLabel}>
					        	{filter.default_name && !subFilters[filter.search_key] ? filter.default_name: filter.name}
					        </InputLabel>
					        <Select
					          labelId={`${filter.search_key}-label`}
					          id={`${filter.search_key}`}
					          name={filter.search_key}
					          value={subFilters[filter.search_key] ? setTitle(filter.search_key, subFilters[filter.search_key]) : ''}
					          onChange={handleChange}
					          input={<Input id={`${filter.search_key}`} />}
					          renderValue={selected => (
					            <div>			           
					               {selected}			
					            </div>
					          )}
					          MenuProps={MenuProps}
					          className={classes.menuItem} 
					        >				        
					          {filter.values.map(item => (
					            <MenuItem key={item.filter} value={item.filter} className={classes.menuItem}>
								  {item.dropdown_name}
					            </MenuItem>
					          ))}				          
					        </Select>
					    </FormControl>
					);			  		
		  		}
					    
		  	
		      	
		      
		      }    
		  ));
	  
  }
  
   const selectedText = (filters, countBldgStage) => {
	  
	  
	  let selectedFilters = Object.keys(subFilters).filter(key => filters.filter(filter => filter.search_key === key && !filter.admin_only).length > 0).filter(key => subFilters[key] !=="").length;
	  
	  if(countBldgStage) selectedFilters += buildingStages.filter(item => item !== 'all').length > 0;
	  
	  const text = selectedFilters && selectedFilters>0 ? ` (${selectedFilters})` : '';
	  
	  return text;
  }
  
   return (
	 <Fragment>
	  {UnitStageFilter ?	 
		    <div className={classes.filterContainer}>
		      {isMobile && <div style={{padding:5,fontWeight:500}}>Filter By:</div>}
		      <div>
		      	<SubFilters filters={_filters.slice(0, slice)} />     
			  </div>
			  {isMobile && <div style={{padding:5,fontWeight:500}}><hr/>Unit Stage:</div>}
	           <div className={`unit_toggle_filter`} style={{marginLeft:'8px',marginTop:'8px'}}>	
	             	{UnitStageFilter}
	           </div>
			  {hasAdvanced && !isMobile &&
				  <Alert color="" className="alert--bordered alert--advanced--search" search hideOnStart alertId="advancedMarketActivityFilters" 						minimizeText={advFilterName ? advFilterName + selectedText(_filters.slice(slice), true) : `Advanced Filters${selectedText(_filters.slice(slice), true)}`} 
				  	minimizeHelpText={`Advanced filters to further refine your results.`}
				  	condensedIcon={<AdvancedFilertIcon size={14} />} >
			      <div>

			      	<BuildingStageFilters />
			      
			      	<SubFilters filters={_filters.slice(slice)} />	      
			      </div>
			    </Alert>
		      }
		      <Fragment>
		      	{isMobile && <div style={{padding:5,fontWeight:500}}><hr/>Building Stage:</div>}
			  	{isMobile && <BuildingStageFilters />}
		      </Fragment>
			  
		    </div>
		:
		    <div className={classes.filterContainer}> 
				{!hideBuildingStage &&
				  <Fragment>
			      	{isMobile && <div style={{padding:5,fontWeight:500}}>Building Stage:</div>}
				  	<BuildingStageFilters />
			      </Fragment>
			    }
			    
		      {isMobile && <div style={{padding:5,fontWeight:500}}><hr/>Filter By:</div>}
		      <div style={{marginBottom:10}}>
		      	<SubFilters filters={_filters.slice(0, slice)} />     
			  </div>
			  {hasAdvanced && !isMobile &&
				  <Alert color="" className="alert--bordered alert--advanced--search" search hideOnStart alertId="advancedMarketActivityFilters" minimizeText={advFilterName ? advFilterName + selectedText(_filters.slice(slice)) : `Advanced Filters${selectedText(_filters.slice(slice))}`} 
				  minimizeHelpText={`Advanced filters to further refine your results.`}
				  condensedIcon={<AdvancedFilertIcon size={14} />} >
			      <div>
			      	<SubFilters filters={_filters.slice(slice)} />	      
			      </div>
			    </Alert>
		      }
			  
		    </div>
		 }
		 
		 <ChangePlan modal={modal} setModal={setModal} changePlan={nextPlan} message={modalMessage} />
      </Fragment>
	);
  

  
}
