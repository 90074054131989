import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { _axiosCall } from '../helpers/apicalls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import buyersListActions from '../../redux/actions/buyersListActions';


import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class SavedCount extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      savedCount: null,
      thisBuildingSaved: this.props.savedBuildings && this.props.buildingKey ? this.props.savedBuildings.filter(item => !item.deleted && item.key === this.props.buildingKey).length > 0 : false,
      thisUnitSaved: this.props.savedUnits && this.props.unitKey ? this.props.savedUnits.filter(item => !item.deleted && item.key === this.props.unitKey).length > 0 : false
    };
   
  }
  
  componentDidMount() {	  
	const { buildingKey, unitKey } = this.props;
	//Return null until more users save buildings
	this.setState({savedCount:0})
/*
	if(buildingKey){
		this._getBuildingAnalytics(buildingKey);
	}else{
		this._getUnitAnalytics(unitKey);
	}
*/
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

	 const lastUpdateBuildingSaved = this.props.savedBuildings && this.props.buildingKey ? this.props.savedBuildings.filter(item => !item.deleted && item.key === this.props.buildingKey).length > 0 : false;
	 
	 const lastUpdateUnitSaved = this.props.savedUnits && this.props.unitKey ? this.props.savedUnits.filter(item => !item.deleted && item.key === this.props.unitKey).length > 0 : false;
	 
	 if(lastUpdateBuildingSaved && !this.state.thisBuildingSaved){
		 this.setState( { savedCount : this.state.savedCount + 1,  thisBuildingSaved:lastUpdateBuildingSaved } );
	 }
	 if(!lastUpdateBuildingSaved && this.state.thisBuildingSaved){
		 this.setState( { savedCount : this.state.savedCount - 1,  thisBuildingSaved:lastUpdateBuildingSaved } );
	 }
	  
	 if(lastUpdateUnitSaved && !this.state.thisUnitSaved){
		 this.setState( { savedCount : this.state.savedCount + 1,  thisUnitSaved:lastUpdateUnitSaved } );
	 }
	 if(!lastUpdateUnitSaved && this.state.thisUnitSaved){
		 this.setState( { savedCount : this.state.savedCount - 1,  thisUnitSaved:lastUpdateUnitSaved } );
	 }
	 
	 
	 
  }

  
  _getBuildingAnalytics = async (_buildingIDs) => {
						
		var q = "*:*";
		
		const { buildingKey } = this.props;
		
		var query = [{
				"$facet": {
					"buildingKeys": [{
							"$unwind": "$savedBuildings"
						},
						{
							"$unwind": "$savedBuildings.key"
						},
						{
							"$match": {
								"$and": [{
										"$or": [{
												"savedBuildings.deleted": {
													"$exists": false
												}
											},
											{
												"savedBuildings.deleted": false
											}
										]
									},
									{
										"savedBuildings.key": {
											"$in": _buildingIDs.split(' ')
										}
									}
								]
							}
						},
						{
							"$group": {
								"_id": {
									"key": "$savedBuildings.key"
								},
								"total": {
									"$sum": 1
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": "$_id.key",
								"total": 1
							}
						}
					]
				}
			}]


		let axiosData = {
			url: apiRoot+ "/buyerslist/analytics/aggregate?cache=1440",
			method: "post",
			query: query
			
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log(res[0].buildingKeys);
				if(res && res[0] && res[0].buildingKeys && res[0].buildingKeys.length > 0){
					let savedCount = res[0].buildingKeys.filter(a => a.key === buildingKey)[0].total;
					if(!savedCount) savedCount = 0;
					this.setState( { savedCount : savedCount });
				}else{
					this.setState( { savedCount : 0 });
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };
  
  _getUnitAnalytics = async (_unitIDs) => {
						
		var q = "*:*";
		
		const { unitKey } = this.props;
		
		var query = [{
				"$facet": {
					"unitKeys": [{
							"$unwind": "$savedUnits"
						},
						{
							"$unwind": "$savedUnits.key"
						},
						{
							"$match": {
								"$and": [{
										"$or": [{
												"savedUnits.deleted": {
													"$exists": false
												}
											},
											{
												"savedUnits.deleted": false
											}
										]
									},
									{
										"savedUnits.key": {
											"$in": _unitIDs.split(' ')
										}
									}
								]
							}
						},
						{
							"$group": {
								"_id": {
									"key": "$savedUnits.key"
								},
								"total": {
									"$sum": 1
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": "$_id.key",
								"total": 1
							}
						}
					]
				}
			}]


		let axiosData = {
			url: apiRoot+ "/buyerslist/analytics/aggregate?cache=1440",
			method: "post",
			query: query
			
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log(res[0].unitKeys);
				if(res && res[0] && res[0].unitKeys && res[0].unitKeys.length > 0){
					let savedCount = res[0].unitKeys.filter(a => a.key === unitKey)[0].total;
					if(!savedCount) savedCount = 0;
					this.setState( { savedCount : savedCount } );
				}else{
					this.setState( { savedCount : 0 });
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };

  render(){
	  const { savedCount } = this.state;
	  const { buildingKey, unitKey } = this.props;
	  if( savedCount === null ) return null;
	 
	  if( savedCount <= 0){
		  return null; 
		  
/*
		  (
			<div className="saved-buyer-indicator">
	    
	        	<span className="saved-buyers-small">
	            	-
	            </span> 
	            Be the First to Save This {buildingKey ? 'Building' : 'Unit'}
	        </div>
		
		  );
*/
		  
	  }
	  const lists = Array.apply(null, Array(savedCount)).map(function () {});
	  //console.log(lists);
	  return (
	    <div className="saved-buyer-indicator">
	    
        	<span className="saved-buyers-small">
            	{savedCount}
            </span> 
            User{savedCount > 1 ? 's' : ''} Saved This {buildingKey ? 'Building' : 'Unit'}
        </div>
	  );
	}
};
function mapStateToProps(state) {
	//console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],
	  		dataSet : state.buyersLists.dataSet
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedCount);
