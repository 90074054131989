import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import HelpIcon from 'mdi-react/HelpCircleOutlineIcon';

import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class ToolTip extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
 

  render(){
	  const { modal } = this.state;
	  const { title,  body} = this.props;
	  const modalClass = classNames({
	      'modal-dialog--colored': false,
	      'modal-dialog--header': true,
	   });
		  return (
			<Fragment>
				<span>
		  			<Tooltip title={`${title}`}>
		  				<IconButton size={`small`} onClick={this.toggle}>
		  					<HelpIcon size={14} style={{ marginTop:"-3px", marginLeft:"1px"}} color={'#780F9E'} />
		  				</IconButton>
		  			</Tooltip>
		  		</span>		  		
		  		<Fragment>
			        <Modal
			          isOpen={modal}
			          toggle={this.toggle}
			          modalClassName={`ltr-support`}
					  className={`modal-dialog--primary ${modalClass}`}
			        >
			        <div className="modal__header">
				        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				        <h4 className="text-modal  modal__title"><HelpIcon size={24} color={'#EDDEF5'} />Help</h4>
				      </div>
				      <div className="modal__body">
				      	<p style={{fontSize:16}}>
				      		{body ? body : title}
				      	</p>
				      	      
				      </div>
				      
				      <ButtonToolbar className="modal__footer offering-status">
				        {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
				        <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
				        	Ok
				        </Button>
				      </ButtonToolbar>
			        </Modal>
			    </Fragment>
		    </Fragment>
		  )


	}
};


export default ToolTip;
