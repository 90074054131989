import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Container, Row, Col, Progress, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import MatTableFiltersSelect from './MatTableFiltersSelect';
import MatAppBar from './MatAppBar';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import PreviewIcon from 'mdi-react/EyeOutlineIcon';

import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import OfferingStatus from '../../../shared/components/OfferingStatus';



import Supply from './Supply';
import Active from './Active';
import TotalUnits from './TotalUnits';
import AveragePPSF from './AveragePPSF';
import AveragePrice from './AveragePrice';
import PriceRangePPSF from './PriceRangePPSF';
import PriceRange from './PriceRange';
import TotalUnsold from './TotalUnsold';
import NeighborhoodAnalysis from './NeighborhoodAnalysis';

import CurrentProgress from './CurrentProgress';

import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	SalesProgress
} from "../../../shared/helpers/utils";

import { 
	_buildingAdminFilters	
} from "../../../shared/helpers/filters";

import queryString from "query-string";


let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}
const CurrentStatus = ({ item }) => {
 
  if(['active', 'sold out'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="success">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['coming soon'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="available">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['offering plan required'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="buyerslist">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  return (
    <div>
      <Badge>{_capitalizeText(item.offering_verified_status)}</Badge>
    </div>
  );
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(buildingRes.inventory_pct_unsold === 100 
		&& moment(buildingRes.offering_accepted_date).format('YYYY') <= 2015
		&& moment(buildingRes.sales_start_date).diff(moment(buildingRes.offering_accepted_date), 'days') <= 1){
		return "--";
		
	}
	if(buildingRes.offering_verified_status === 'abandoned'){
		return "--";
	}
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';

const syncURLFilters = (props) => {
	  const { history, location } = props;
	  const subFilters = {};
	  const urlState = { ...queryString.parse(location.search) }
	  
	  Object.keys(urlState).forEach((k) => {
	      if ( // don't save some state values if it meets the conditions below
	        !urlState[k] || // falsy
	        urlState[k] === "" || // string
	        k === "searchText" ||
	        (Array.isArray(urlState[k]) && !urlState[k].length) || // array
	        urlState[k] === subFilters[k] // same as default state, unnecessary
	      ) {
	        delete urlState[k]; // drop query params with new values = falsy
	      }
	  });	  
	  console.log(`?${queryString.stringify(urlState)}`);
	  return urlState;
}
const syncURLSearch = (props, key) => {
	  const { history, location } = props;
	  const urlState = { ...queryString.parse(location.search) }
	  
	  let val = '';
	  Object.keys(urlState).filter(k => key ? key === k : true).forEach((k) => {
	       val = urlState[k];
	  });	  
	  return val;
}

const setURLFromState = (props, state)  => {		
	  const urlState = Object.assign({}, state.subFilters, {searchText : state.searchText} );
	  Object.keys(urlState).forEach((k) => {
	      if ( // don't save some state values if it meets the conditions below
	        !urlState[k] || // falsy
	        urlState[k] === "" || // string
	        (Array.isArray(urlState[k]) && !urlState[k].length)
	      ) {
	        delete urlState[k]; // drop query params with new values = falsy
	      }
	  });		
	  const searchUrl = queryString.stringify(urlState); 	  
	  const newSearch =  searchUrl ? `?${searchUrl}` : '';
	  
	  if(props.location.search !== newSearch){
		 // props.history.push({ search: newSearch });
		 //using window history to avoid component remounting
		 window && window.history.pushState({}, '', newSearch);
	  }	  
}

class MatTable extends PureComponent {
  state = {
    order: 'desc',
    orderBy: 'inventory_unsold',
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	// filterFetchInProgress: false,
	filterStage: "all_projects",
	futureInventoryData: false,
	// headerHeight: 'auto',
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props),
	tableData: false,
	totalUnsoldUnits: false,
	searchText: syncURLSearch(this.props, 'searchText'),
	facetData: null
  };
  
  componentDidMount() {
  	this._getInventoryBuildings();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	    let subFilterChanged =
			_buildingAdminFilters.filter(item => {
				return (
					this.state.subFilters[item.search_key] !==
					prevState.subFilters[item.search_key]
				);
			}).length > 0;
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			subFilterChanged
			) {
			  this._getInventoryBuildings();
		}

	  
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building, 'building_slugs');
	 
	 window.open(`https://marketproof.com${buildingURL.replace('buildings', 'building')}?tab=overview`)
	 //this.props.history.push(buildingURL);
  };
  
  handleBuildingPreviewClick = (event, building) => {
	 event.preventDefault();
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building);
	 
	 window.open(buildingURL);
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  _getInventoryBuildings = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		const { user } = this.props;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var q = "";
		if (this.state.filterBorough !== "all_boroughs") {
			q += `(borough:"${this.state.filterBorough
				.replace("_", " ")
				.toLowerCase()}")`;
		}
		let isActive = false;
		if (this.state.filterStage !== "all_projects") {
			q += q.length > 0 ? " AND " : "";
			if (this.state.filterStage === "completed_only") {
				q += "(building_effective_start:[NOW-5YEARS TO NOW])";
			} else if (this.state.filterStage === "under_construction_only") {
				q +=
					"(sales_start_date:[NOW-5YEARS TO NOW] AND -building_effective_start:[NOW-5YEARS TO NOW])";
			}
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qVal = _buildingAdminFilters.filter(filter => filter.search_key === key)[0].values.filter(value => value.filter === subFilterVal)[0].search_value;
					q += q.length > 0 ? " AND " : "";
					
					if(key === 'offering_verified_status' && ['active', '("offering plan required")'].indexOf(qVal)>=0){
						isActive = true;
					}
					return q += `(${key}:${qVal})`;
				}
			});
		}
		
		setURLFromState(this.props, this.state);
		var fq = [
			"deleted:false",
			"offering_verified_status:* AND -offering_verified_status:\"\" AND -offering_verified_status:\"excluded\"",
			//"offering_accepted_date:[NOW-10YEARS TO NOW]",
			"-offering_construction:(conversion)",
			//"offering_verified_status:(active \"coming soon\" \"offering plan required\")",
			//"ownership_type:*condo*",
			//"total_units_residential:[10 TO *]",
// 			"sales_start_date:[NOW-5YEARS TO NOW]"
		];
		
		
		if(this.state.searchText && this.state.searchText.length > 0){
			const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
			const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
			q += q.length > 0 ? " AND " : "";
			q += `(name:(*${text}* OR *${textCaps}*) OR display_full_street_address:*${text}* OR neighborhood:*${text}*)`;
		}
		q = q.length > 0 ? q : "*:*";
		
		fq.push("offering_accepted_date:[NOW-10YEARS TO NOW] OR offering_verified_status:(active \"coming soon\" \"offering plan required\")");
		
		if(!(user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0)){
			fq.push("total_units:[1 TO *]");
			
		}
		
		
		
		let sort = "total_units desc";
		if(this.state.orderBy && this.state.orderBy.length > 0){
			sort = `${this.state.orderBy} ${this.state.order}`;
		}


		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=15",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: sort,
				fl: buildingFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: 0,
				"json.facet": {
					"total_inventory_unsold": "sum(inventory_unsold)",
					"total_inventory": "sum(inventory_total)",
					"total_inventory_active" : "sum(inventory_active)",		
					"total_inventory_contract" : "sum(inventory_contract)",
					"total_inventory_not_yet_recorded" : "sum(inventory_not_yet_recorded)",	
					"total_inventory_listed" : "sum(inventory_listed)",
					"total_inventory_resale" : "sum(inventory_resale)",
					"total_inventory_shadow" : "sum(inventory_shadow)",	
					"total_inventory_sold" : "sum(inventory_sold)",			
					"by_borough" : {
						"type" : "terms",
						"field" : "borough",
						"limit" : 20,
						"facet" : {
							"total_inventory_unsold": "sum(inventory_unsold)",	
							"total_inventory": "sum(inventory_total)",							
						}
					},
					"by_neighborhood" : {
						"type" : "terms",
						"field" : "neighborhood",
						"limit" : 20,
						"sort" : "total_inventory_unsold desc",
						"facet" : {
							"total_inventory_unsold": "sum(inventory_unsold)",
							"total_inventory": "sum(inventory_total)",
							"total_inventory_active" : "sum(inventory_active)",		
							"total_inventory_contract" : "sum(inventory_contract)",
							"total_inventory_not_yet_recorded" : "sum(inventory_not_yet_recorded)",	
							"total_inventory_listed" : "sum(inventory_listed)",
							"total_inventory_resale" : "sum(inventory_resale)",
							"total_inventory_shadow" : "sum(inventory_shadow)",	
							"total_inventory_sold" : "sum(inventory_sold)",						
						}
					},
					"sellout" : {
						"type": "query",
						"q": "offering_price_current:[1 TO *]",
						"facet": {		
							"average_sellout": "avg(offering_price_current)",
							"total_sellout": "sum(offering_price_current)",
							"max_sellout": "max(offering_price_current)",	
							"min_sellout": "min(offering_price_current)"
						}	
						
					},
					"non_zero_price": {
						"type": "query",
						"q": "inventory_stats_listings_average_ppsf:[1 TO *]",						
						"facet": {		
							"average_ppsf": "avg(inventory_stats_listings_average_ppsf)",
							"max_ppsf": "max(inventory_stats_listings_average_ppsf)",	
							"min_ppsf": "min(inventory_stats_listings_average_ppsf)",	
							"min_listing_low_price" : "min(inventory_stats_listings_low_price)",
							"max_listing_high_price" : "max(inventory_stats_listings_high_price)",			
							"by_borough" : {
								"type" : "terms",
								"field" : "borough",
								"limit" : 20,
								"facet" : {
									"average_ppsf": "avg(inventory_stats_listings_average_ppsf)",
									"max_ppsf": "max(inventory_stats_listings_average_ppsf)",	
									"min_ppsf": "min(inventory_stats_listings_average_ppsf)",								
									}
							},
							
						}
					},
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				
				
				if(res && res.docs){
					this.setState(prevState => ({
						buildingsFetchInProgress: false,
						tableData:  res.docs,
						reactTableFiltered:  res.docs,
						// filterFetchInProgress: false,
						totalCount: res.numFound,
						facetData: res.facets

					}));
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	
	_getEvents = async () => {
		
		var fq = [
			"hierarchy:[0 TO 2]",	
 			"time:[2020-03-16T00:00:01Z TO 2020-03-23T00:00:01Z]"
			
		];
	
	
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				wt: "json",
				rows: 0,
				"json.facet": {
					"new_listings": {
						"type": "query",
						"q": `topic:listings AND event_tags:just-listed AND -event_tags:rent`,
						
					},
					"contract_signed": {
						"type": "query",
						"q": "topic:listings AND event_tags:contract-signed AND -event_tags:rent",
						
					},
					"recorded_sold": {
						"type": "query",
						"q": "topic:transactions AND event_tags:recorded-sale",
					},					
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};

	
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})
		// if(this.state.view === 'grid') {
		//this._filterResponseTableData(eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	_filterResponseTableData = eValue => {
		console.log("_filterResponseTableData eValue", eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			eValue.length > 0
				? this.state.tableData.filter(item => {
						// console.log("filterData item", item)
						const inputVal = eValue.toLowerCase();
						let nameVal, adrVal, hoodVal;
						if (item.name) {
							// console.log('item has name')
							nameVal = item.name.toLowerCase();
						}

						if (
							(item.display_full_street_address &&
								_shouldShow(item.display_full_street_address)) ||
							(item.full_street_address &&
								_shouldShow(item.full_street_address))
						) {
							const field = item.display_full_street_address
								? "display_full_street_address"
								: "full_street_address";
							// console.log('item has adr', field)

							adrVal = item[field].toLowerCase();
						}

						if (item.neighborhood && _shouldShow(item.neighborhood)) {
							const hood =
								Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
									? item.neighborhood[0]
									: item.neighborhood;
							hoodVal = hood.toLowerCase();
						}

						// console.log(((nameVal && nameVal.indexOf(inputVal) >= 0) || (adrVal && adrVal.indexOf(inputVal) >= 0)))
						// searching name and ONE adr field - could update to search both display and full and all alt adrs
						return (
							(nameVal && nameVal.indexOf(inputVal) >= 0) ||
							(adrVal && adrVal.indexOf(inputVal) >= 0) ||
							(hoodVal && hoodVal.indexOf(inputVal) >= 0)
						);
				  })
				: this.state.tableData;

		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
		this.setState(prevState => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
		    //tableData: filteredData,
		    //originalData: response,
		    //filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
		this.setState({
			subFilters: Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			}),
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
     const paginationCount = totalCount;
    if(!tableData){
	    return null;
    }
    const table = reactTableFiltered;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
		  	 <MatAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
            	setSubFilter={this._setSubFilter}
            	_filters={_buildingAdminFilters}           	
             />
            
            <div className="material-table__header-info">
            	<span className="bold-text">{tableCount > 0 ? _formatNumber(tableCount) : '--'}</span>{` `}Buildings
            </div>
            <Container className="dashboard">
            	 <hr/>
			    <Row>
			   	  <Col md={12} xl={12} lg={12} xs={12}>
			   	  	<CurrentProgress  {...this.state} />
			   	  </Col>
			   </Row>
			   <Row >
			   	 <Col md={12} xl={3} lg={6} xs={6}>
			   	  	<Supply {...this.state} />
			   	  </Col> 
			   	  <Col md={12} xl={3} lg={6} xs={6}>
				 	<PriceRange  {...this.state} />	
			     </Col> 	 
			   	 <Col md={12} xl={3} lg={6} xs={6}>
			   	 	<AveragePPSF {...this.state} />
			     </Col>
			     <Col md={12} xl={3} lg={6} xs={6}>
			   	 	<PriceRangePPSF  {...this.state} />	
			   	 </Col>
			     		                
			   </Row>
			   <Row>
			   	  <Col md={12} xl={12} lg={12} xs={12}>
			   	  	<NeighborhoodAnalysis  {...this.state} />
			   	  </Col>
			   </Row>
			</Container>
            
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user
}))(withRouter(MatTable));