import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row,  Card, CardBody,  Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setUserMyGroups } from '../../redux/actions/authActions';
import classnames from 'classnames';

import List from './components/List';

import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';

import showResults from './Show';

import SnackBar from '../../shared/components/SimpleSnackBar';

import TrialAlert from '../../shared/components/payment/TrialAlert';

import EmptyList from './components/EmptyList';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';


import fileDownload from 'js-file-download';
import { _hasPermission	} from "../../shared/helpers/access";
import CommunityGroups from './components/CommunityGroups';


import{
	_capitalizeText,
	_objectEqual
} from '../../shared/helpers/utils';
import{
	_axiosCall
} from '../../shared/helpers/apicalls';
const oktaToken = process.env.OKTA_TOKEN;

const apiUrl = process.env.API_ROOT;
//const apiUrl = 'http://localhost:8000/buyerslist/';

//const apiUrl = 'https://newsapi.we3.com/buyerslist/'
const defaultSubFilter = {};
class MyGroups extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      snackShow: false,
	      snackMessage: false,
	      snackType: 'success',
	      groups: null,
	      refresh:false,
		  order:  "desc",
		  orderBy: "created_timestamp",
		  page: 0,
		  rowsPerPage: 10,
		  numFound: 0,
		  searchText : '',
		  subFilters : defaultSubFilter,
		  activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'my-collections'
	    };
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);
	    this._getGroups = this._getGroups.bind(this);
	    this.toggleRefresh = this.toggleRefresh.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSearch = this.handleSearch.bind(this);	
		this._setSubFilter = this._setSubFilter.bind(this);	
		this._resetSubFilter = this._resetSubFilter.bind(this);	     
		this._setSubFilters = this._setSubFilters.bind(this);
	    
	}
	  
    componentDidMount(){
	  const { authState, authService, dispatch, user } = this.props;
	  console.log(authState);
	  this._getGroups();
    }
    componentDidUpdate(prevProps, prevState) {
	    
	    const { user } = this.props;
	    let subFilterChanged = false
	    if(subFilterChanged ||
			prevState.refresh !== this.state.refresh ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.searchText !== prevState.searchText
		){
		     this._getGroups();

	    }
	    
	}
   	
	toggleRefresh = () => {
    	this.setState(prevState => ({ refresh: !prevState.refresh, groups: null }));;
    };
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_getGroups = () => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
	  const { orderBy, order, rowsPerPage, page, searchText, subFilters } = this.state;
 
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  let query = {
			user_key: user.id,
			deleted : { "$exists" : false },
			source: { "$ne" : "PIPELINE-APP"},
		}
	  if(searchText){
		query['$or'] = [ 
			{"group_name" : { "$regex" : searchText, "$options" : "i"}}
		];
	}
	
	
	
	  let sort = {};
	  sort[orderBy] = order == "desc" ? -1 : 1;
	  let userData = {
			url: apiUrl+'/buyerslist/groups/query',
			method: "post",
			query: {
				query : query,
				sort : sort,
				limit : rowsPerPage,
				skip : (page) * rowsPerPage
				
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getGroups res back ,", res);
	      
	      if(res && res.numFound>0){	
		      this.setState( { numFound: res.numFound, groups: res.docs } );
		   }else{
			  this.setState( { groups: [] } ); 
		   }
	  })
	  .catch((error) => {
	    console.log("_getGroups error ", error);
	  });

	}
	
	
	handleRequestSort = (event, orderBy) => {
		let order = 'desc';
		const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
		if (stateOrderBy === orderBy && stateOrder === 'desc') { order = 'asc'; }
	
		this.setState({ order, orderBy, page : 0 });
	};
	handleChangePage = (event, page) => {
		this.setState({ page: page, start: page * this.state.rowsPerPage });
	  };
	
  	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  	};
	handleSearch = (searchText) => {
		this.setState({ searchText : searchText});
	}
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
	}
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
	
	}
	
	_resetActive = () => {
		
		let isDefault = _objectEqual(defaultSubFilter, this.state.subFilters);
		return !isDefault;
		
	}
	
	setTab = (tab) => {
		   const { activeTab } = this.state;
		   this.setState({activeTab: tab})
		   
		   const newURL = `/collections/${tab}`
		   window.history.pushState(null, null, newURL);
	};
	   
	render(){
		const { snackShow, snackMessage, snackType, groups, subFilters, searchText, activeTab } = this.state;
		const { user } = this.props;
		if(groups === null) return null;
		return (
			<Container >
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
			    <Row>
			      <Col md={12}>
			        <h3 className="page-title">{`Collections`}</h3>
			        <h3 className="page-subhead subhead">Manage Your Sharable Groups.</h3>
			      </Col>
			    </Row>
				
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
				{_hasPermission(user, 'collections') ?
				<Fragment>  
					{_hasPermission(user, 'community-collections') &&  			    
					<div className="tabs tabs--bordered-bottom mb-3">
					  <div className="tabs__wrap">
						<Nav tabs>
						  <NavItem>
							<NavLink
							  className={classnames({ active: activeTab === 'my-collections' })}
							  onClick={() => {
								this.setTab('my-collections');
							  }}
							>
							  <span className="tab-title">My Collections</span>
							</NavLink>
						  </NavItem>
					  
						  <NavItem>
							  <NavLink
								className={classnames({ active: activeTab === 'shared-collections' })}
								onClick={() => {
								  this.setTab('shared-collections');
								}}
							  >
								<span className="tab-title">Shared Collections</span>
							  </NavLink>
						  </NavItem>
							
						</Nav>
					  </div>
					</div>
					}
					<TabContent activeTab={activeTab}>
					 	{activeTab === 'my-collections'  && 
					   	<TabPane tabId="my-collections">
									{groups.length<=0 && !searchText && Object.keys(subFilters).length<=0 ?
										<Fragment>
										{/*
										<Row>
											<CommunityGroups toggleRefresh={this.toggleRefresh} setTab={this.setTab.bind(this)} />
										</Row>
										*/}
										<Row>
						  					<EmptyList {...this.props} toggleRefresh={this.toggleRefresh}/>			      
										</Row>
										</Fragment>
										:
										<Fragment>
										{/*
										<Row>
										<CommunityGroups toggleRefresh={this.toggleRefresh} setTab={this.setTab.bind(this)} />
										</Row>
										*/}
										
					    				<Row>
						  				
					      				<List 
						  	  				{...this.props} 
							  				{...this.state}
							  				toggleRefresh={this.toggleRefresh} 
							  				handleRequestSort={this.handleRequestSort} 
							  				handleChangePage={this.handleChangePage}  
							  				handleChangeRowsPerPage={this.handleChangeRowsPerPage}
							  				handleSearch={this.handleSearch}
							  				setSubFilter={this._setSubFilter}
							  				setSubFilters={this._setSubFilters}
							  				resetSubFilter={this._resetSubFilter}
							  				filters={[]}
							  				resetActive={this._resetActive()}
							  				/>			      
					    				</Row>
						   			    </Fragment>
				    				}
							</TabPane>
						}
						{activeTab === 'shared-collections'  && 
						   <TabPane tabId="shared-collections">
							   <Row>
								<CommunityGroups toggleRefresh={this.toggleRefresh} setTab={this.setTab.bind(this)} full />
							   </Row>
							</TabPane>
						}
					</TabContent>
			    </Fragment>
				:
				 <Row>
						 <EnterpriseGate 
							 copy={<div><span>Access to Collections requires an upgrade to your subscription.</span> 
								 <p><a href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo" style={{color:'#780F9E',fontSize:16}} target="_blank">
									 Learn about Collections <OpenInNewIcon size={16} style={{marginTop:-2}} /></a></p></div>}
							 upgrade={true}
							 user={user}
							 img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-collections-mockup.png`}
							 nextPlan={"complete-199"} />
				 </Row>
				 }   
			    

			</Container>
		);	
	}
  
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(MyGroups));
