import React, { Fragment } from 'react';

import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect, withRouter } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';

import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";

import MatTable from './components/MatTable';
import MatAppBar from '../../shared/components/building_filters/UnitsSearchAppBar';



import{
	_capitalizeText,
} from '../../shared/helpers/utils';

const Buildings = ( {user, match } ) => {
	  const type = match && match.params.type ? match.params.type : 'residential';

	  return(
		  <Container>
			    <Fragment>
				    
				      <MatTable />
			    </Fragment>
			    
		  </Container>
	  )

};


export default connect(state => ({
  user: state.user,
}))(withOktaAuth(withRouter(Buildings)));

