import React, { useState, Fragment } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Badge, Row, Collapse
} from 'reactstrap';
import moment from 'moment';
import HeartIcon from 'mdi-react/HeartIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import { Link } from 'react-router-dom';
import ProfileGallery from './ProfileGallery';
import ProfileTabs from './ProfileTabs';
import ColorSelect from './ColorSelect';
import classNames from 'classnames';
import AddToBuyersListButton from '../../../../../shared/components/buttons/AddToBuyersListButton';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import SavedCount from '../../../../../shared/components/SavedCount';

import EmailIcon from 'mdi-react/EmailIcon';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';

import TrendDownIcon from 'mdi-react/TrendingDownIcon';
import TrendUpIcon from 'mdi-react/TrendingUpIcon';

import EnterpriseGate from './../../../../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "./../../../../../shared/helpers/access";
import ShareContractAgents from './../../../../../shared/components/ShareContractAgents'
import TaxAbatement from '../../../../../shared/components/TaxAbatement';
import BuildingDetailsCard from "./BuildingDetailsCard";
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_formatBath,
	_getBuildingURL,
	_nameToUrl,
	_formatUnitNumber,
	_formatOwnerName,
	_isResiUnit,
	_isCoop,
	_getBuildingPhotos,
	_shouldShow
} from '../../../../../shared/helpers/utils';

const ListAmount = ({ lists }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': lists.count <= 5,
    'dashboard__table-orders-amount--low': lists.count <= 3,
  });
  return (
      <div className={amountClass}>
       {lists.map(list => <div />)}
        <span>{lists.length} Members Saved this Unit</span>
      </div>
    );
};


const PriceDetails = ({property}) => {
	return (
		<Fragment>
		  	{property.offering_price > 0 && 
              <tr>
                <th>Offering Price:</th>
                <td>${_formatNumber(property.offering_price)} 
                	{property.sq_ft > 0 && <span className="small-text">
                		{` `}(${_formatNumber(property.offering_price / property.sq_ft)} PPSF)
                	</span>}
                </td>
              </tr>
            }
			{property.last_listed_sale_date_all > 0 && 
			  <tr>
				<th>Last Listed Date:</th>
				<td>
					{moment.unix(property.last_listed_sale_date_all).format('MM/DD/YYYY')}
					<span className="small-text">
					{' '}({moment.unix(property.last_listed_sale_date_all).fromNow()})
					</span>
				</td>
			  </tr>
			}
            {property.last_listed_sale_price_all > 0 && 
              <tr>
                <th>Last Listed Price:</th>
                <td>${_formatNumber(property.last_listed_sale_price_all)} 
					
                	
                </td>
              </tr>
            }
			{property.last_listed_sale_price_all > 0 && property.sq_ft > 0 && 
			  <tr>
				<th>Last Listed PPSF:</th>
				<td>${_formatNumber(property.last_listed_sale_price_all / property.sq_ft)} 
					{' '}
					<span className="small-text"> / Sq Ft</span>
				</td>
			  </tr>
			}
			{property.last_sold_date > 0 && 
			  <tr>
				<th>Last Sold Date:</th>
				<td>{moment.unix(property.last_sold_date).format('MM/DD/YYYY')}
					 {` `}
					<span className="small-text">
					({moment.unix(property.last_sold_date).fromNow()})
					</span>
				</td>
			  </tr>
			}
            {property.last_sold_price > 0 && 
              <tr>
                <th>Last Sold Price:</th>
                <td>${_formatNumber(property.last_sold_price)} 
                	
                </td>
              </tr>
            }
            {property.last_sold_price > 0 && property.sq_ft > 0 && 
			  <tr>
				<th>Last Sold PPSF:</th>
				<td>${_formatNumber(property.last_sold_price / property.sq_ft)}
					{' '}
					<span className="small-text"> / Sq Ft</span>
				</td>
			  </tr>
			}
            
            {property.alternate_unitcodes && property.alternate_unitcodes.length > 1 &&
	            
	            <tr>
                <th>Unit Also Known As:</th>
                <td>
                	{property.alternate_unitcodes ? property.alternate_unitcodes.filter(u => u !== property.address_2_normalized).map(u => 
				       <Badge style={{marginRight:5}} color="shadow">{_formatUnitNumber(u)}</Badge>) : '--'
				    }
                </td>
              </tr>
             }
            

            
            
        
		  </Fragment>
	)
		
}


const PriceData = (props) => {
	const { latestUnit, unitHistory, property, listing, history, activeResale, transactions } = props;
	
	const firstUnit = unitHistory && unitHistory[0] ? unitHistory[0] : latestUnit;
	
	const lastTransaction = transactions && transactions[0] && transactions[0].price > 0 ? transactions[0] : false;
	
	if(listing && !listing.deleted && (listing.sale_status=="active" || listing.sale_status=="in contract" || listing.sale_status=="pending")){
		return (<Fragment>
				  <div className="small-text">Listed for {_capitalizeText(listing.listing_type)}
				  {listing.active_date_start && listing.active_date_start !== 0 &&              
				  <span className="small-text"> - {_capitalizeText(moment(listing.active_date_start).fromNow())} ({moment(listing.active_date_start).format('MM/DD/YYYY')})</span>}
				  </div>
				  <h1 className="product-card__price">${listing.price >0 ? _formatNumber(listing.price) : '--'}</h1>
			</Fragment>)
	}
	
	
	if(activeResale && property.current_status==='active'){
		
		const discount = property && property.last_sold_price && property.last_sold_price > 1000 ? ((listing.price - property.last_sold_price)/property.last_sold_price * 100).toFixed(2) : 0;
		return <Fragment>
	          	  <div className="small-text">Listed Price {property.last_sold_price && property.last_sold_price > 1000 && ' vs. Last Sold Price'}{property.last_sold_date ? ` (Sold ${_capitalizeText(moment.unix(property.last_sold_date).fromNow())})` : ""}
					{property.last_listed_sale_date_all && property.last_listed_sale_date_all !== 0 &&              
						<span className="small-text"> - {_capitalizeText(moment.unix(property.last_listed_sale_date_all).fromNow())} ({moment.unix(property.last_listed_sale_date_all).format('MM/DD/YYYY')})</span>
					  }
					</div>
	              <h1 className="product-card__price">${_formatNumber(listing.price)} 
				  	
	              	{property && property.last_sold_price && property.last_sold_price > 1000 &&	              
			              <span className="product-card__old-price">${_formatNumber(property.last_sold_price)}</span>
			        }
			        {(discount < 0 || discount > 0) &&
				        <div className="small-text">
				        	{discount < 0  ? 
						  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#F53246'} /> 
						  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
						  		: null
						  	}
					  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
					  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
					  	</div>
				  	}
	              </h1>
	           </Fragment>
		
		
	}
	
	switch(property && property.current_status){
		case "active" :
			if(property.last_listed_sale_price_all > 0){
				const discount = property && property.offering_price ? (((listing ? listing.price : property.last_listed_sale_price_all) - property && property.offering_price)/property && property.offering_price * 100).toFixed(2) : 0;
				return <Fragment>
			          	  <div className="small-text">Listed {property.last_listed_sale_date_all && property.last_listed_sale_date_all !== 0 &&              
								<span className="small-text"> - {_capitalizeText(moment.unix(property.last_listed_sale_date_all).fromNow())} ({moment.unix(property.last_listed_sale_date_all).format('MM/DD/YYYY')})</span>
							  }</div>
			              <h1 className="product-card__price">${listing ? _formatNumber(listing.price) : _formatNumber(property.last_listed_sale_price_all)} 
			              	{property && property.offering_price &&	 property.offering_price > 1000 &&             
					              <span className="product-card__old-price">${_formatNumber(property.offering_price)}</span>
					        }
					        {(discount < 0 || discount > 0) &&
						        <div className="small-text">
						        	{discount < 0  ? 
								  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#F53246'} /> 
								  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
								  		: null
								  	}
							  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
							  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
							  	</div>
						  	}
			              </h1>
			           </Fragment>
			}else{
				const discount = property && property.offering_price ? (((listing ? listing.price : property.last_listed_sale_price_all) - property && property.offering_price)/property && property.offering_price * 100).toFixed(2) : 0;
				return <Fragment>
					  <div className="small-text">Latest Offering Price {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price && 'vs. Initial Offing Price'}</div>
		              <h1 className="product-card__price">{latestUnit && latestUnit.price ? `$${_formatNumber(latestUnit.price)}` : '--'} 
		              {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price &&	              
			              <span className="product-card__old-price">${_formatNumber(firstUnit.price)}</span>
			          }
			          {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  }
			          </h1>
			        </Fragment>	
			}
			break;
		case "in contract" :
		case "pending" :
			if(property.last_contract_price > 0){
				
				return <Fragment>
					  <div className="small-text">Latest Price {unitHistory && unitHistory.length > 1 && unitHistory[0].price !== latestUnit.price && 'vs. Initial Offing Price'}</div>
		              <h1 className="product-card__price">${property.last_contract_price ? _formatNumber(property.last_contract_price) : '--'} 
		              {unitHistory && unitHistory.length > 1 && unitHistory[0].price !== latestUnit.price &&	              
			              <span className="product-card__old-price">${_formatNumber(unitHistory[0].price)}</span>
			          }</h1>
			        </Fragment>	
				
			}else if(property.last_listed_sale_price_all > 0){
				const discount = property && property.offering_price ? (((listing ? listing.price : property.last_listed_sale_price_all) - property && property.offering_price)/property && property.offering_price * 100).toFixed(2) : 0;
				return <Fragment>
		          	  <div className="small-text">Last Listed Price</div>
		              <h1 className="product-card__price">${_formatNumber(property.last_listed_sale_price_all)} 
		              	
				        {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  	}
		              </h1>
					</Fragment>	
				
			}else{
				return <Fragment>
					  <div className="small-text">Latest Offering Price {unitHistory && unitHistory.length > 1 && unitHistory[0].price !== latestUnit.price && 'vs. Initial Offing Price'}</div>
		              <h1 className="product-card__price">${latestUnit ? _formatNumber(latestUnit.price) : '--'} 
		              {unitHistory && unitHistory.length > 1 && unitHistory[0].price !== latestUnit.price &&	              
			              <span className="product-card__old-price">${_formatNumber(unitHistory[0].price)}</span>
			          }</h1>
			        </Fragment>	
			}
			
			break;
		case "sold" :
		case "resale" : 
		case "not_yet_recorded" :
		case "closed" :
		
			if(lastTransaction.price > 0 && lastTransaction.listed_price > 100){
				let discount = lastTransaction && lastTransaction.discount > -100 && lastTransaction.discount < 100 ? lastTransaction.discount : 0;
				return <Fragment>
		          	  <div className="small-text">Sold Price {lastTransaction.listed_price > 100 && ' vs. Listed Price'}</div>
		              <h1 className="product-card__price">${_formatNumber(lastTransaction.price)} 
		              	{lastTransaction.listed_price > 100 &&	              
				              <span className="product-card__old-price">${_formatNumber(lastTransaction.listed_price )} </span>
				        }
				        {lastTransaction.parcels_count == 1 && lastTransaction.parcels_property_types.indexOf('sr')>=0 && lastTransaction.parcels_property_types.indexOf('ps')>=0 ?
                          		<div className="light-text xsmall-text mb-2" style={{marginTop:"-5px"}}>(Includes Parking & Storage)</div>
                          	: lastTransaction.parcels_count == 1 && lastTransaction.parcels_property_types.indexOf('sr')>=0 ?
                          		<div className="light-text xsmall-text mb-2" style={{marginTop:"-5px"}}>(Includes Storage)</div>
                          	: lastTransaction.parcels_count == 1 &&lastTransaction.parcels_property_types.indexOf('ps')>=0 ?
                          		<div className="light-text xsmall-text mb-2" style={{marginTop:"-5px"}}>(Includes Parking)</div>
                          	: null
                         }
				      
				        {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  	}
					  	
		              </h1>
	
					</Fragment>	
				
			}else if(property.last_listed_sale_price_all > 0 && property.last_listed_sale_price_all > 100 && property.last_sold_date > property.last_listed_sale_date_all && (property.last_listed_sale_date_all === 0 || (property.last_sold_date - property.last_listed_sale_date_all)/86400 < 1095)){
				let discount = (property.last_sold_price>0 && property.last_listed_sale_price_all>0) ? (((property.last_sold_price - property.last_listed_sale_price_all) / property.last_listed_sale_price_all) * 100).toFixed(2) : 0;
				return <Fragment>
		          	  <div className="small-text">Sold Price {property.last_listed_sale_price_all > 100 && <span>{property.last_listed_sale_key_all ? 'vs. Listed Price' : ' vs. Offering Price'}</span>}</div>
		              <h1 className="product-card__price">${_formatNumber(property.last_sold_price)} 
		              	{lastTransaction.listed_price > 100 &&	              
				              <span className="product-card__old-price">${_formatNumber(property.last_listed_sale_price_all )} </span>
				        }
				       				      
				        {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  	}
					  	
		              </h1>
	
					</Fragment>	
				
			}else if(property.last_sold_price > 0){
				let discount = firstUnit && firstUnit.price ? ((property.last_sold_price - firstUnit.price)/firstUnit.price * 100).toFixed(2) : 0;
				
				return <Fragment>
		          	  <div className="small-text">Sold 
							{property.last_sold_date && property.last_sold_date !== 0 &&              
							  <span className="small-text"> - {_capitalizeText(moment.unix(property.last_sold_date).fromNow())} ({moment.unix(property.last_sold_date).format('MM/DD/YYYY')})</span>
							}
						</div>
		              <h1 className="product-card__price">${_formatNumber(property.last_sold_price)} 
		              {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price &&	              
							<span className="product-card__old-price">${_formatNumber(firstUnit.price)}</span>
						}
				        
				      
				        {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  	}
		              </h1>
	
					</Fragment>	
			}else if(latestUnit && latestUnit.price > 0){
				const discount = firstUnit && firstUnit.price && latestUnit && latestUnit.price ? ((latestUnit.price - firstUnit.price)/firstUnit.price * 100).toFixed(2) : 0;				
				return <Fragment>
					  <div className="small-text">Latest Offering Price {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price && 'vs. Initial Offing Price'}</div>
		              <h1 className="product-card__price">{latestUnit && latestUnit.price ? `$${_formatNumber(latestUnit.price)}` : '--'} 
		              {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price &&	              
			              <span className="product-card__old-price">${_formatNumber(firstUnit.price)}</span>
			          }
			          {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  }
			          </h1>
			        </Fragment>	
			}else{
				if(property.last_listed_sale_price_all > 0){
			       return <Fragment>
					  <div className="small-text">Last Listed Price</div>
		              <h1 className="product-card__price">${property.last_listed_sale_price_all >0 ? _formatNumber(property.last_listed_sale_price_all) : '--'}
					  	{property.last_listed_sale_date_all && property.last_listed_sale_date_all !== 0 &&              
							<span className="small-text"> - {_capitalizeText(moment.unix(property.last_listed_sale_date_all).fromNow())} ({moment.unix(property.last_listed_sale_date_all).format('MM/DD/YYYY')})</span>
						  }
					  </h1>
		              
			        </Fragment>	
			    }else{
				    return null;
			    }
			}
			
			break;
		default : 
			if(property.last_listed_sale_price_all > 0){
		       return <Fragment>
				  <div className="small-text">Last Listed Price</div>
	              <h1 className="product-card__price">${property.last_listed_sale_price_all >0 ? _formatNumber(property.last_listed_sale_price_all) : '--'}</h1>
	              
		        </Fragment>	
		    }else if(latestUnit) {
				
				const discount = firstUnit && firstUnit.price && latestUnit && latestUnit.price ? ((latestUnit.price - firstUnit.price)/firstUnit.price * 100).toFixed(2) : 0;
				return <Fragment>
					  <div className="small-text">Latest Offering Price {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price && 'vs. Initial Offing Price'}</div>
		              <h1 className="product-card__price">{latestUnit && latestUnit.price ? `$${_formatNumber(latestUnit.price)}` : '--'} 
		              {firstUnit && firstUnit.price > 0 && firstUnit.price !== latestUnit.price &&	              
			              <span className="product-card__old-price">${_formatNumber(firstUnit.price)}</span>
			          }
			          {(discount < 0 || discount > 0) &&
					        <div className="small-text">
					        	{discount < 0  ? 
							  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#F53246'} /> 
							  		: discount > 0 ? <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
							  		: null
							  	}
						  		<span className="semi-bold-text">{discount > 0 ? '+'+discount : discount}%</span>
						  		<span className="small-text">{discount > 0 ? " Price Increase" : " Discount"}</span>
						  	</div>
					  }
			          </h1>
			        </Fragment>		
		    }else{
				if(listing && !listing.deleted && listing.sale_status=="active"){
					return (<Fragment>
					  		<div className="small-text">Listed for {_capitalizeText(listing.listing_type)}</div>
					  		<h1 className="product-card__price">${listing.price >0 ? _formatNumber(listing.price) : '--'}</h1>
						</Fragment>)
				}else{
					return null;
				}
			    
		    }
		       
		        
			break;
	}
	
	
}

const ProfileCard = (props) => {
  const [color, setColor] = useState('white');

  const onLike = () => {
    if (color === 'white') {
      setColor('#70bbfd');
    } else {
      setColor('white');
    }
  };
  
  const { latestUnit, unitHistory, property, listing, buildingRes, share, activeResale, urlState, transactions, user, isPDF} = props;
  
  let listingContacts = false;
  //Account for yearly taxes in the listings
  if(listing && listing.monthly_taxes > 0 && (listing.monthly_taxes/listing.price)>.01){
	  listing.monthly_taxes = Math.round(listing.monthly_taxes/12)
  }
   		 
   if(listing && listing.contacts_json){
			try{
				const agents = JSON.parse(listing.contacts_json);					
				listingContacts = agents.filter(agent => agent.email && agent.email.indexOf('@') >=0).map(agent=>{
					return{
						"Email" : agent.email,
						"Name" : agent.name
					}
				})					
			}catch(e){
									
			}
	}
	
	const lastTransaction = transactions && transactions.length > 0 ? transactions[0] : false;
	if(!property) return null;
	const boro = _shouldShow(buildingRes.borough)
	  ? Array.isArray(buildingRes.borough)
		? buildingRes.borough[0].toLowerCase().trim()
		: buildingRes.borough.toLowerCase().trim()
	  : "new york";
	const hood = _shouldShow(buildingRes.neighborhood)
	  ? Array.isArray(buildingRes.neighborhood)
		? buildingRes.neighborhood[0].toLowerCase().trim()
		: buildingRes.neighborhood.toLowerCase().trim()
	  : "nyc";
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="no-border no-padding">
          <div className="product-card">
            <ProfileGallery property={property} listing={listing} share={share} buildingRes={buildingRes}/>
            
            <div className="product-card__info"> 
            {(property && property.offering_verified_status && property.offering_verified_status === 'active' && property.baths <= 0 && property.offering_baths > 0) ?             
              <h3 className="product-card__title">
               <React.Fragment>
	              {property.bedrooms >= 0 &&
		            <React.Fragment>
	              		{parseInt(property.offering_bedrooms) === 0 ? 'Studio' : property.offering_bedrooms} 
				  		<span className="sub-text">{property.offering_bedrooms === 0 ? '' : property.offering_bedrooms === 1 ? 'Bed' : 'Beds'}</span>
				  		<span className="small-divider">|</span>
	              	</React.Fragment>
	              }
	              {property.offering_baths && property.offering_baths >0 &&
		            <React.Fragment>
	              		{property.offering_baths}
				  		<span className="sub-text">{property.offering_baths === 1 ? 'Bath' : 'Baths'}</span>
				  	</React.Fragment>
		          }
		          {property.offering_half_baths && property.offering_half_baths >0 &&
		            <React.Fragment>
		            	<span className="small-divider">|</span>
	              		{property.offering_half_baths}
				  		<span className="sub-text">{property.offering_half_baths === 1 ? 'Half Bath' : 'Half Baths'}</span>
				  	</React.Fragment>
		          }
	             {property.offering_sq_ft && property.offering_sq_ft > 0 &&
		              	<React.Fragment>
			              	<span className="small-divider">|</span>
			              	{property.offering_sq_ft > 0 && _formatNumber(property.offering_sq_ft)}
			              	<span className="sub-text">Sq Ft</span>
					  	</React.Fragment>
		          }
	              {property.offering_exterior_sq_ft && property.offering_exterior_sq_ft > 0 ?
		              	<React.Fragment>
		              		<span className="small-divider">|</span>
					  		{_formatNumber(property.offering_exterior_sq_ft)}
					  		<span className="sub-text">Exterior Sq Ft</span>
		              	</React.Fragment>
		              	:
	              	property.exterior_sq_ft && property.exterior_sq_ft > 0 ?
	              	<React.Fragment>
	              		<span className="small-divider">|</span>
				  		{_formatNumber(property.exterior_sq_ft)}
				  		<span className="sub-text">Exterior Sq Ft</span>
	              	</React.Fragment>
	              	: null
		          }
	          </React.Fragment>
              </h3>
              : property.baths > 0 ?
              <h3 className="product-card__title">
                {property.bedrooms >=0 &&
	               <Fragment>
	              	{property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
	              	<span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? 'Bed' : 'Beds'}</span>
	              	<span className="small-divider">|</span>
				  	</Fragment>
				}
              	{property.baths}
              	<span className="sub-text">{property.baths === 1 ? 'Bath' : 'Baths'}</span>
              	 {property.baths % 1 == 0 && property.half_baths && property.half_baths >0 &&
		            <React.Fragment>
		            	<span className="small-divider">|</span>
	              		{property.half_baths}
				  		<span className="sub-text">{property.half_baths === 1 ? 'Half Bath' : 'Half Baths'}</span>
				  	</React.Fragment>
		          }
              	{property.sq_ft && property.sq_ft > 0 &&
	              	<React.Fragment>
	              	<span className="small-divider">|</span>
	              	{_formatNumber(property.sq_ft)}
	              	<span className="sub-text">Sq Ft</span>
	              	</React.Fragment>
	            }
              	{property.exterior_sq_ft && property.exterior_sq_ft > 0 ?
	              	<React.Fragment>
	              		<span className="small-divider">|</span>
				  		{_formatNumber(property.exterior_sq_ft)}
				  		<span className="sub-text">Exterior Sq Ft</span>
	              	</React.Fragment>
	              	:
	              	property.exterior_sq_ft && property.exterior_sq_ft > 0 ?
	              	<React.Fragment>
	              		<span className="small-divider">|</span>
				  		{_formatNumber(property.exterior_sq_ft)}
				  		<span className="sub-text">Exterior Sq Ft</span>
	              	</React.Fragment>
	              	: null
	            }
              </h3>
              : property.sq_ft > 0 ?
              <h3 className="product-card__title">
               	{property.sq_ft && property.sq_ft > 0 &&
	              	<React.Fragment>
	              	{_formatNumber(property.sq_ft)}
	              	<span className="sub-text">Sq Ft</span>
	              	</React.Fragment>
	            }
              	{property.offering_exterior_sq_ft && property.offering_exterior_sq_ft > 0 ?
	              	<React.Fragment>
	              		<span className="small-divider">|</span>
				  		{_formatNumber(property.offering_exterior_sq_ft)}
				  		<span className="sub-text">Exterior Sq Ft</span>
	              	</React.Fragment>
	              	: 
	              	property.exterior_sq_ft && property.exterior_sq_ft > 0 ?
	              	<React.Fragment>
	              		<span className="small-divider">|</span>
				  		{_formatNumber(property.exterior_sq_ft)}
				  		<span className="sub-text">Exterior Sq Ft</span>
	              	</React.Fragment>
	              	: null
	            }
              </h3>
              :
              null

              
             }
              {!share && <div className="product-card__rate">
               	<SavedCount unitKey={property.key} lists={['key', 'key', 'key']} />
              </div>}
              
              <PriceData {...props} />
			  {listing && listing.sale_status === 'active' && !listing.deleted &&  
				<Fragment> 
				<hr/>         
				<div className="contact_container">
				
					<div className="contact__left">	
						<div className="contact_header">Listing Courtesy Of</div> 
										
						{listingContacts &&
							<h5>
							{listingContacts.map((agent, i) =>			          		
									<span className="agent_name">{agent.Name}{i < listingContacts.length-1 && ", "}</span>	          	
							)}	
							</h5>			          	
						}
						<h6 className="light-text">{listing.broker_display_name && _capitalizeText(listing.broker_display_name)}</h6>
						<div className="contact_header mt-2">{listing.buyer_agent_commission > 0 ? `Co-Broke Commssion: ${listing.buyer_agent_commission}%` : ''}</div>	
						{/*<p className="light-text">Contact {user.firstName} for more information or to schedule a tour.</p>*/}
					</div>
					<div className="contact__right">
					{!isPDF && (!share || (urlState && (urlState.lk === "true" || urlState.lk === true))) &&
						<div className="">
						
							<Tooltip title={`See listing for more information`}>
								 <a className="" href={`https://nyc.marketproof.com${_getBuildingURL(property, 'building').replace('buildings/', 'building/')}/${_nameToUrl(property.address_2_normalized)}?tab=listing`} target="_blank">
								  <Button								         
									   variant="contained"
									   color="primary"
									   className="buyersList__primary-button"		         
									   startIcon={<OpenInNewIcon />}
								   >	See Listing
								   </Button>	
							  </a>			 
							</Tooltip>					  	
					  </div>
					  }					  		
					  
					</div>
				</div>
				</Fragment>
				
				} 
			  <hr/>
			  <BuildingDetailsCard
				buildingDetails={buildingRes}
				hood={hood}
				boro={boro}
				{...props}
			  /> 
			 
           
              <ShareContractAgents {...props} />
			  <hr/>
			 
			  {!share ?
				  <Fragment>
				  <table className="product-summary__info">
			            <tbody>
			            
			               <tr>
			                <th>Current Owner:</th>
			                <td>
			                
			                {!_hasPermission(user, "ownership") ?
	
								<EnterpriseGate 
									overrideText={<span>Upgrade to see owner info</span>}
									upgrade={true}
									user={user}
									nextPlan={"complete"}
									hideIcon
								/>			
							
								
			                : 
			                
			                
			                	<Fragment>
			                	
			                	{lastTransaction && lastTransaction.owner ? 
				                	<Fragment>{Array.isArray(lastTransaction.owner) ? lastTransaction.owner.map(owner => _capitalizeText(owner)).join(" | ") : _capitalizeText(lastTransaction.owner.split(/,(?=\S)/).map(name => name.split(/,\s/).reverse().join(" ")).join(' | '))}</Fragment> 
				                	: property.owner && property.owner !== 'unavailableowner' ? <Fragment>{_capitalizeText(property.owner.replace(/,(?=\S)/, ' '))}</Fragment> 
				                	: <span>{property.property_status === 'sponsor' ? 'Sponsor' : 'Unavailable'}</span>			                
				                }{' '}<span className="small-text"><a href="javascript:void(0)" className="link-text" onClick={() => props.setTab('financing')}>(See ownership history)</a></span>
				                </Fragment>
				            }
							</td>
			              </tr>
			              
			              {!listing && !latestUnit && <PriceDetails  {...props} />}
			              {property.times_exchanged > 0 &&
			              <tr>
			                <th>Orginal Owner:</th>
			                <td>{property.times_exchanged > 1 || (property.times_exchanged && property.times_exchanged > 1) ? 
				                	<Fragment>No <span className="small-text">(Unit was sold or transfered by original owner)</span></Fragment> 
				                	: <Fragment>Yes <span className="small-text">(Original owner has not sold)</span></Fragment> 
				                }
							</td>
			              </tr>
			              }
			              <tr>
			                <th>Listed for Rent:</th>
			                <td>{property.last_listed_rent_price_all > 1 ? 'Yes' : <Fragment>No <span className="small-text"></span></Fragment>} {` `}
			                {property.last_listed_rent_date_all > 1 &&
								<span className="small-text">
		                		({moment.unix(property.last_listed_rent_date_all).fromNow()} for ${_formatNumber(property.last_listed_rent_price_all)})
								</span>
							}
	
							</td>
			              </tr>
			              {false && property.block > 1 &&
				              <tr>
				                <th>Block & Lot:</th>
				                <td>
									{property.block} | {property.lot} 
									{' '}<span className="small-text"><a className="link-text" href={`https://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${property.borough_code}&block=${property.block}&lot=${property.lot}`} target="_blank">(ACRIS <OpenInNewIcon size={11} style={{marginTop:-2}} />)</a></span>
								</td>
				              </tr>
				              
			              }
			              
						  
					      
			            </tbody>
			          </table>
		          
		          <hr/>
		          </Fragment>
		       : !share && property.description && property.description.length>0 ?
		        <Fragment>
				  <table className="product-summary__info">
			            <tbody>
			            <tr>
			            	<td>
			            		{property.description[0]}
			            	</td>
			            </tr>
			            </tbody>
			          </table>
			    </Fragment>
			    :null		       
		       }
			  {listing && listing.monthly_fees && listing.listing_type === "sale" ?
				  <Fragment>
		        
			         {_isCoop(buildingRes) ?
				      <Fragment>
				       <table className="product-summary__info">
			            <tbody>
			              <tr>
			                <th>Est Maintenance:</th>
			                <td>{listing.monthly_fees > 0 ? '$'+_formatNumber(listing.monthly_fees) + '/ mo' : '--'} </td>
			              </tr>
			              {latestUnit && latestUnit.residential_shares &&
			              <tr>
			                <th>Number of Shares:</th>
			                <td>{parseFloat(latestUnit.residential_shares)}</td>
			              </tr>
			              }
			              <PriceDetails {...props} />
			            </tbody>
			          </table>  
			          <hr/>
			          </Fragment>
				         
		             : listing.listing_type === "sale" ?
		             	<Fragment>
			               <table className="product-summary__info">
				            <tbody>
				             
				              <tr>
				                <th>Est Taxes:</th>
				                <td>{listing.monthly_taxes > 0 ? '$'+_formatNumber(listing.monthly_taxes) + '/ mo' : latestUnit && latestUnit.monthly_taxes > 0 ? '$'+_formatNumber(latestUnit.monthly_taxes) : ''}
									{' '}<span className="small-text"><a className="link-text" href={`javascript:void(0)`} onClick={() => props.setTab('taxes')}>(See Assessment)</a></span>
								</td>
				              </tr>
				              <tr>
				                <th>Carrying Cost:</th>
				                <td>{listing.monthly_fees > 0 && listing.monthly_taxes > 0 ? '$'+_formatNumber(listing.monthly_fees + listing.monthly_taxes) + '/ mo' : latestUnit && latestUnit.monthly_carrying_charges > 0 ? '$'+_formatNumber(latestUnit.monthly_carrying_charges) :'--'}</td>
				              </tr>
				              {latestUnit && latestUnit.residential_shares &&
				              <tr>
				                <th>Common Interest:</th>
				                <td>{parseFloat(latestUnit.residential_shares).toFixed(2) }%</td>
				              </tr>
				              }
				              <PriceDetails {...props} />
				            </tbody>
				          </table>
				           {listing.sale_status === 'active' && <div style={{color:"#BFC4D1"}} className="small-text light-text mt-3">Listing last updated {moment.unix(listing.modified_date).fromNow()}.</div>}

				          <hr/>
				          </Fragment>
			          :
			           null
			          }
			         
	              
				 </Fragment>
			                
	          
	          : latestUnit ?
		        <Fragment>
		        
		         {_isCoop(buildingRes) ?
			       <table className="product-summary__info">
		            <tbody>
		              <tr>
		                <th>Est Maintenance:</th>
		                <td>${_formatNumber(latestUnit.monthly_fees)} / mo</td>
		              </tr>
		              {props.latestUnit.residential_shares &&
		              <tr>
		                <th>Number of Shares:</th>
		                <td>{parseFloat(latestUnit.residential_shares)}</td>
		              </tr>
		              }
		              <PriceDetails  {...props} />
		            </tbody>
		          </table>  
			         
	             : <table className="product-summary__info">
		            <tbody>
		              <tr>
		                <th>Common Charge:</th>
		                <td>${_formatNumber(latestUnit.monthly_fees)} / mo</td>
		              </tr>
		              <tr>
		                <th>Est Taxes:</th>
		                <td>${_formatNumber(latestUnit.monthly_taxes)} / mo
							
							{' '}<span className="small-text"><a className="link-text" href={`javascript:void(0)`} onClick={() => props.setTab('taxes')}>(See Assessment)</a></span>
						</td>
		              </tr>
		              <tr>
		                <th>Carrying Cost:</th>
		                <td>${latestUnit.monthly_carrying_charges ? _formatNumber(latestUnit.monthly_carrying_charges) : _formatNumber(latestUnit.monthly_fees + latestUnit.monthly_taxes)} / mo</td>
		              </tr>
		              {props.latestUnit.residential_shares &&
		              <tr>
		                <th>Common Interest:</th>
		                <td>{parseFloat(latestUnit.residential_shares).toFixed(2) }%</td>
		              </tr>
		              }
					  <PriceDetails  {...props} />
				      
		            </tbody>
		          </table>
		          }
	              <hr/>
	           
	               <div  style={{color:"#BFC4D1"}} className="small-text light-text">
	                Information based on the Schedule A from {latestUnit.document_number === 0 ? 'Initial Offering plan' : `Amendment ${latestUnit.document_number} of the offering plan`} document.
	                <br/>  Offering Plan ID: {latestUnit.file_number.toUpperCase()}.
	              </div>
	               <hr/>
              </Fragment>
              :
              <table className="product-summary__info">
			  	<tbody>
					 {share && <PriceDetails  {...props} />}
					 {!_isCoop(buildingRes) && <TaxAbatement {...props} min setTab={props.toggle} />}
				</tbody>
			  </table>
              }
             
               
              
              
             
             
             {/* <ProfileTabs /> */}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileCard;
