import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import BuyersLists from '../BuyersLists/index';
import BuyerLeads from './components/BuyerLeads/index';
import Overview from './components/Summary/index';

class Dashboard extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log(this.props);
	  console.log(process.env.NODE_ENV);
	}
	
	render(){
		
		const { user } = this.props;
	    if(!user) return null;
		
		switch(user.role){
			case 'seller':
				return <BuyerLeads />
								
				break;	
			case 'media':
				return <Redirect
			            to={{
			              pathname: "/news"
			            }}
			          />
			
				break;
			case 'enterprise':
				return <Redirect
			            to={{
			              pathname: "/my-buildings"
			            }}
			          />
			
				break;			
			default:
				return <Overview {...this.props} />
		}
				
	}
	
}

export default connect(state => ({
  user: state.user,
}))(Dashboard);
