import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import HomeVariantOutlineIcon from 'mdi-react/HomeVariantOutlineIcon';
import ToolTip from '../../../../../shared/components/ToolTip';

const TotalBookings = (props) => (
	  <Col md={12} xl={3} lg={6} xs={6}>
	    <Card>
	    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
	      <CardBody className="dashboard__booking-card card__link">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            {props.buildingRes.inventory_total}
	          </h5>
	          <HomeVariantOutlineIcon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Total Units
			<ToolTip 
			title={"Total sellable units in the building excluding resident manager unit."} 
			
			/>
			</h5>
	        
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
);

export default TotalBookings;
