import React, { PureComponent, useState } from 'react';

import { Modal, ButtonToolbar } from 'reactstrap';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ThemeProps, RTLProps, UserProps } from '../../prop-types/ReducerProps';
import moment from 'moment';
import Alert from '../Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/CalendarClockIcon';
import Button from '@material-ui/core/Button';
import ActiveIcon from 'mdi-react/CheckDecagramIcon';
import classNames from 'classnames';

import { bindActionCreators } from 'redux';
import {isMobile} from 'react-device-detect';
import buyersListActions from '../../../redux/actions/buyersListActions';
import TrialSubscribe from './TrialSubscribe';

import { 
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage
} from "../../../shared/helpers/utils";


class TrialAlert extends PureComponent {
	
  
  static propTypes = {
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
    children: PropTypes.element.isRequired,
    location: PropTypes.shape({}).isRequired,
    user: UserProps.isRequired,
  };
  
  constructor(props) {
    super(props)
    this.state = {
	    subscribe:false,
	    modal:false
    } 
    this.setSubscribe = this.setSubscribe.bind(this);
    this.toggle = this.toggle.bind(this);
   }
   
   componentDidMount() {	  
	 const seen = getLocalStorage('trial_', 'trialLimit');
	 
	 if(localStorage && !seen){
		 this.setState({ modal : true});
		 setLocalStorage('trial_', 'trialLimit', true);
	 }
	
   }
   
   setSubscribe(sub){
	   this.setState( {subscribe:sub} );
   }
      
   toggle(){
	   const { modal } = this.state;
		if(modal){
			this.setState({ modal : false})
		}else{
			this.setState({ modal : true})
		}
   }
  

  render() {
    const {
      theme, children, rtl, location, user, viewedBuildings
    } = this.props;
    const modalClass = classNames({
	      'modal-dialog--colored': false,
	      'modal-dialog--header': true,
	   });
    const { subscribe, modal } = this.state;
    const viewedCount = viewedBuildings && Array.isArray(viewedBuildings) ? viewedBuildings.length : 0;
    const maxViews = process.env.MAX_TRIAL_BUILDING_VIEWS;
    const remainingViews = maxViews - viewedCount;   
    
    if(user && user.subscriptionStatus && user.subscriptionStatus === 'trialing' ){
	    return (
	      <Alert noClose color="success" className="alert--bordered" alertId="trialAccount" icon iconItem={<AlertIcon />} minimizeText={`${moment.unix(parseInt(user.trialEnd)).fromNow(1)} left on your trial`}>
	      {isMobile ? 
		      <p>
	          	<span className="bold-text">{`${moment.unix(parseInt(user.trialEnd)).isBefore(moment()) ? 'Your free trial is expiring soon.' : moment.unix(parseInt(user.trialEnd)).fromNow(1) + ' left on your free trial.'} `} </span> 
	          	<div><strong>{remainingViews > 0 ? remainingViews : 0}</strong> of <strong>{maxViews}</strong> building views remaining. {remainingViews <=0 ? <a href="javascript:void(0)" onClick={() => this.setSubscribe(true)}><br/>Activate subscription now &raquo;</a> : <a href="javascript:void(0)" onClick={() => this.toggle()}><br/>Learn More &raquo;</a>}</div>
	          </p> 
		      
		     :
	          <p>
	          	<span className="bold-text">{`${moment.unix(parseInt(user.trialEnd)).isBefore(moment()) ? 'Your free trial is expiring soon.' : 'You have ' + moment.unix(parseInt(user.trialEnd)).fromNow(1) + ' left on your free trial.'}`} </span> 
	          	<div><strong>{remainingViews > 0 ? remainingViews : 0}</strong> of <strong>{maxViews}</strong> building views remaining. {remainingViews <=0 ? <a href="javascript:void(0)" onClick={() => this.setSubscribe(true)}>Activate subscription for full access &raquo;</a> : <a href="javascript:void(0)" onClick={() => this.toggle()}>Learn More &raquo;</a> }</div>
	          </p>  
	       }
	          <TrialSubscribe subscribe={subscribe} setSubscribe={this.setSubscribe} /> 
	          <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`ltr-support`}
				  className={`modal-dialog--primary ${modalClass}`}
		        >
		        <div className="modal__header">
			        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
			        <h4 className="text-modal  modal__title"><ActiveIcon size={24} color={'#EDDEF5'} /> Thanks for Subscribing</h4>
			      </div>
			      <div className="modal__body">
			      	<p style={{fontSize:16}}>{`During your free trial you get unlimited access to the site's analytics and you get 5 building views.`}</p>	      	      
			      </div>
			      
			      <ButtonToolbar className="modal__footer offering-status">
			        {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
			        <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
			        	Ok
			        </Button>
			      </ButtonToolbar>
		        </Modal>     
	      </Alert>
	    );
    }else{
	    return null
    }
  }
}


function mapStateToProps(state) {
	//console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings) ? state.buyersLists.viewedBuildings.slice() : [],
	  		dataSet : state.buyersLists.dataSet
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialAlert);