import React, { useEffect, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

const A4_HEIGHT_PX = 1200; // Height of an A4 page in pixels (approximately 297mm at 96 DPI)
const HEADER_HEIGHT_PX = 130; // Adjust according to your header height (50px + 20px margin-bottom)
const FOOTER_HEIGHT_PX = 60; // Adjust according to your footer height (70px + 20px margin-top)

const ExportView = forwardRef(({ content, headerContent, headerHTML, footerHTML, preview }, ref) => {
  useEffect(() => {
    const iframe = ref.current;
    const iframeDoc = iframe.contentDocument;

    const adjustIframeHeight = () => {
      if (iframeDoc.body.scrollHeight) {
        iframe.style.height = `${iframeDoc.body.scrollHeight}px`;
      }
    };
    const insertPageBreaks = () => {
      const contents = Array.from(iframeDoc.querySelectorAll('.page-section'));
      console.log(contents, "Detected page sections"); // Debugging line
      let accumulatedHeight = 0;
      let currentPageDiv = createNewPage();

      contents.forEach((content, index) => {
        const contentHeight = content.offsetHeight;
        accumulatedHeight += contentHeight;

		if (accumulatedHeight > (A4_HEIGHT_PX - HEADER_HEIGHT_PX - FOOTER_HEIGHT_PX) && index > 0) {
		  // Start a new page
		  accumulatedHeight = contentHeight; // Reset for the next page

          // Create and insert a new page div
          currentPageDiv = createNewPage();
        }

        currentPageDiv.appendChild(content);
      });
    };

    const createNewPage = () => {
      const pageDiv = document.createElement('div');
      pageDiv.classList.add('export-page');

      const headerDiv = document.createElement('div');
      headerDiv.innerHTML = ReactDOMServer.renderToString(headerHTML);
      headerDiv.classList.add('export-page-header');
      pageDiv.appendChild(headerDiv);

      const footerDiv = document.createElement('div');
      footerDiv.innerHTML = ReactDOMServer.renderToString(footerHTML);
      footerDiv.classList.add('export-page-footer');
      pageDiv.appendChild(footerDiv);

      iframeDoc.body.appendChild(pageDiv);
      return pageDiv;
    };

    const renderContent = () => {
      iframeDoc.open();
      iframeDoc.write(`
        <html>
          <head>
            ${headerContent}
            <style>
              body { margin: 0; overflow: hidden; } /* Remove scrollbars from the body */
              #root { overflow: hidden; } /* Ensure root div does not overflow */
              .page-break {
                page-break-before: always;
              }
              .export-page {
                break-after: page;
                min-height: ${A4_HEIGHT_PX}px;
                border-bottom: 20px solid #f5f7fa;
                position: relative;
                padding-bottom: ${FOOTER_HEIGHT_PX}px; /* Adjust padding to include footer */
              }
              .export-page-header {
                height: ${HEADER_HEIGHT_PX}px; /* Adjust height as necessary */
                margin-bottom: 20px; /* Space after header */
              }
              .export-page-footer {
                height: ${FOOTER_HEIGHT_PX}px; /* Adjust height as necessary */
                margin-top: 20px; /* Space before footer */
                position: absolute;
                bottom: 0;
                width: 100%;
                padding:10px 25px;
              }
              @media print {
                .export-page {
                  border-bottom: none;
                }
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);
      iframeDoc.close();

      // Render the React component inside the iframe
      ReactDOM.render(content, iframeDoc.getElementById('root'), () => {
        // Adjust height after rendering
        insertPageBreaks();
        adjustIframeHeight();
      });
    };

    renderContent();

    // Adjust height whenever the window is resized
    window.addEventListener('resize', adjustIframeHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustIframeHeight);
    };
  }, [content, headerHTML, footerHTML]);

  return <iframe ref={ref} scrolling="no" style={{ width: '100%', border: 'none', overflow: 'hidden', background:'#FFF', minHeight: `${A4_HEIGHT_PX}px` }} />;
});

const areEqual = (prevProps, nextProps) => {
  return prevProps.preview === nextProps.preview;
};

export default React.memo(ExportView, areEqual);
