import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from "react-router-dom";
import { Tooltip } from '@material-ui/core';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import BuyersListInput from './components/BuyersListInput';
import BuyersList from './components/BuyersList';
import buyersListActions from '../../../../../../redux/actions/buyersListActions';
import buyersListCard from './types';
import { RTLProps, ThemeProps } from '../../../../../../shared/prop-types/ReducerProps';
import EmptyBuyersList from '../../../../../BuyersLists/components/EmptyBuyersList';

import Panel from '../../../../../../shared/components/Panel';

import { _axiosCall } from '../../../../../../shared/helpers/apicalls';

import {
	buildingFL
} from "../../../../../../shared/helpers/utils";
const buildingUrl = process.env.API_ROOT + '/query/';


class MyBuyersList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    buyerslist: PropTypes.arrayOf(buyersListCard).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      incompleteMyBuyersLists: false,
      completedMyBuyersLists: false,
      buildingsFetchInProgress: false,
      buildingIds: [], //props.buyersLists.map(item => item.key),
      buyersList: null,
      totalCount: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this._getBuildings(nextProps);
  }
  
  componentDidMount() {
  	this._getBuildings(this.props);
  }
  
  componentDidUpdate(){
    
  }
  
  _setBuyersList = (res) => {	  
	     const totalCount = this.props.savedBuildings.filter(item => !item.deleted).length;
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),	
			totalCount: totalCount	
		});
  }
  
  _getBuildings = async (props) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		const _buildingIDs = props.savedBuildings.filter(item => !item.deleted).map(item => item.key).join(' ');
		
		if(!props.dataSet || this.state.buildingsFetchInProgress){
			return false;
		}
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var q = "*:*";
		
		var fq = [
			"deleted:false",
			"key:(" + _buildingIDs + ")",
// 			"key:(b-3-180-front-st-11201 b-3-105-york-st-11201)",
			//"pictures:*"
		];


		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: "year_built desc, sales_start_date desc",
				fl: buildingFL,
				wt: "json",
				rows: 3
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				this._setBuyersList(res);
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };

  render() {
    const {
      actions, t, theme, rtl,
    } = this.props;

    const { incompleteMyBuyersLists, completedMyBuyersLists, buyersList, totalCount } = this.state;
    
    
	if(buyersList === null){
		
		 return (
		 <Panel lg={7} title={'My Buildings'} subhead={`Building Added to My Dashboard`}>		 
		 </Panel>
		 );
	}
		
	if(buyersList.length <= 0){
		
		 return (
		 <Panel lg={7} title={'My Buildings'} subhead={`Building Added to My Dashboard`}>		 
		 	<EmptyBuyersList small={true} />
		 </Panel>
		 );
	}

	if(!this.state.buyersList){
		return null; {/*<EmptyBuyersList />;*/}
	}
    return (
       <Panel lg={7} 
	  	title={`My Buildings (${totalCount})`}
	  	subhead={`Building Added to My Dashboard`}
	  	label={null}	  	
	  	>
            <BuyersList completed actions={actions} list={buyersList} />
            <div className="unit__load text-right semi-bold-text">			   		
			    <Tooltip title="See All My Buildings">
			        <Link to="/my-buildings">See All &raquo;</Link>			 
			  	</Tooltip>				
		    </div>
          
      </Panel>    );
  }
}

function mapStateToProps(state) {
	console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl,
	  	savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  	dataSet : state.buyersLists.dataSet  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MyBuyersList));
