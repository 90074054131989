import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";
import QuickStartGuide from '../../shared/components/QuickStartGuide';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user } = this.props;
	    if(!user) return null;

		return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Inventory</h3>
		        {!isMobile && <h3 className="page-subhead subhead">Search for Units by Location, Size, Price and More.</h3> }
		      </Col>
		    </Row>
		     {_hasPermission(user, 'inventory-search') ?
			   <Fragment>
			     <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			   {/* <Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
				          <p>
				          	<span className="bold-text">{`About The Data`}</span> 
				            <div>{`The information provided herein is provisional and subject to change as more buildings and units are vetted.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row> */}
			   <QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_0a0acc9fbb"} type={"Inventory"} />
			    <Row>
			      <UnitsSearchTable />
			    </Row>
		    </Fragment>
		    :
			     <Row>
				    <EnterpriseGate />
				 </Row>
			}
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



