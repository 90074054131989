import React, { Fragment } from "react";
import {
  Card, CardBody, Container, Col, Row, ButtonToolbar,
} from 'reactstrap';

import FeatureIcon from 'mdi-react/CheckCircleOutlineIcon';
import EnterpriseGate from './../../../../../shared/components/payment/EnterpriseGate';


import {
	_capitalizeText,
	_chunkArray,
	_getFeaturesArr,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from './../../../../../shared/helpers/utils.js';

import { amenitiesList } from "./../../../../../shared/helpers/bp-utils.js";
import { userEditFieldsObj } from "./../../../../../shared/helpers/userEditFields.js";
import { _hasPermission	} from "./../../../../../shared/helpers/access";

export default function BuildingManagement (props) {
	const { isHouse, buildingRes, hpdRes, user } = props;
	const rows = hpdRes;
	if(!hpdRes || hpdRes.length <= 0) return null;
	if(!_hasPermission(user, "building-management")){
		return null;
		
		/*(
			<Row>
				<EnterpriseGate 
					copy={<span>Upgrade to see building management info.</span>}
					upgrade={true}
					user={user}
					nextPlan={"complete"}
					hideIcon
				/>			
			</Row>
		)*/
		
	}
				
	
	const mgmAgent = hpdRes.filter(item => item.type ==="agent").length > 0 ? hpdRes.filter(item => item.type ==="agent")[0] : false;
	const corpOwner = hpdRes.filter(item => item.type ==="corporateowner").length > 0 ? hpdRes.filter(item => item.type ==="corporateowner")[0] : false;
	const indvOwner = hpdRes.filter(item => item.type ==="individualowner").length > 0 ? hpdRes.filter(item => item.type ==="individualowner")[0] : false;
	const siteMgr = hpdRes.filter(item => item.type ==="sitemanager").length > 0 ? hpdRes.filter(item => item.type ==="sitemanager")[0] : false;
	const headOfficer = hpdRes.filter(item => item.type ==="headofficer").length > 0 ? hpdRes.filter(item => item.type ==="headofficer")[0] : false;
	const officiers = hpdRes.filter(item => item.type ==="officer").length > 0 ? hpdRes.filter(item => item.type ==="officer") : false;
	
	if(!mgmAgent && !corpOwner) return null;
	const showAddress = (item) => { 
		return (item.business_full_street_address && item.business_zip) ? 
			<div className="small-text light-text" style={{color:'#AAA'}}>
				{_capitalizeText((item.business_full_street_address + (item.business_address_2 ? ', #' + item.business_address_2 : '') + ', ' + item.business_city + ', ' + (item.businesss_state != item.business_city ? item.businesss_state : '')  + ' ' + item.business_zip).toLowerCase())}			
			</div> 
			: null 
	}
	return(
		<Fragment>
		<Row style={{width:"100%"}}>
	      <Col md={12}>
	        <h3 className="section-title section-title--not-last mt-0">Building Management</h3>
	        <h3 className="section-title section-title--not-last subhead"> </h3>
	      </Col>
	    </Row>
		<Card>
		<CardBody className="" style={{background:"#F5F7FA"}}>
		<div className="product-card">
		 
		<Row style={{width:"100%"}}>				
			<Col xl={6} md={6} >		
				<table className="product-summary__info">
		            <tbody>
		            {corpOwner.corporation_name &&
		              <tr>
		                <th>Corporate Owner</th>
		                <td>{_capitalizeText(corpOwner.corporation_name)} </td>
		              </tr>
		            }
		            {mgmAgent.corporation_name &&
		              <tr>
		                <th>{mgmAgent.full_name == mgmAgent.corporation_name ? 'Managing Agent' : 'Management Company'}</th>
		                <td>{_capitalizeText(mgmAgent.corporation_name)} {showAddress(mgmAgent)}</td>
		              </tr> 
		            }
		            {mgmAgent.full_name && mgmAgent.full_name != mgmAgent.corporation_name && 
		              <tr>
		                <th>Managing Agent</th>
		                <td>{_capitalizeText(mgmAgent.full_name)} 
		                  <div className="small-text" style={{color:'#AAA'}}>
				             {_capitalizeText(mgmAgent.corporation_name)}
				          </div>
		                </td>
		              </tr> 
		            } 
		           
		             </tbody>
				</table>
				 	 
			</Col>
			<Col xl={6} md={6}>
				<table className="product-summary__info">
		            <tbody>
		             {siteMgr.full_name && 
		              <tr>
		                <th>Onsite Manager</th>
		                <td>{_capitalizeText(siteMgr.full_name)} 
		                </td>
		              </tr> 
		            }  
		            {headOfficer.full_name && headOfficer.full_name != "first last" &&
		              <tr>
		                <th>Head Officer</th>
		                <td>{_capitalizeText(headOfficer.full_name)}
		                	{headOfficer.title && 
								<div className="small-text" style={{color:'#AAA'}}>
				                	{_capitalizeText(headOfficer.title)}
				                </div>
			                }
		                </td>
		              </tr>
		            }
		            {officiers && officiers.map(officier => (
			            <tr>
			                <th> Officer</th>
			                <td>{_capitalizeText(officier.full_name)}
			                	{officier.title && 
									<div className="small-text" style={{color:'#AAA'}}>
					                	{_capitalizeText(officier.title.replace(".", ". "))}
					                </div>
				                }
			                </td>
			              </tr>
			            
		            ))}
		             
		            
		             </tbody>
				</table>		 
			</Col>
		</Row>	
		<div  style={{color:"#BFC4D1", textAlign:"right", width:"100%"}} className="small-text light-text mt-3">
        	Information based on <a href="https://www1.nyc.gov/site/hpd/services-and-information/register-your-property.page" className="link-text" target="_blank">Housing Preservation & Development (HPD) property registrations</a>.
		</div>
		</div>         
        </CardBody>
      </Card>
      </Fragment>	
	);
	
}
