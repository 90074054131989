/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import Button from '@material-ui/core/Button';
import ExportIcon from 'mdi-react/CloudDownloadIcon';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import{
	matTheme,
} from '../../helpers/utils';

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    multiline={rows}
	    rows={rows}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    variant="outlined"

	  />
	</ThemeProvider>
);

const renderCheckbox = ({ input, label, disabled }) => {
	
	return (
		  <FormControlLabel
		      control={
		        <Checkbox
		          checked={input.value ? true : false}
		          onChange={input.onChange}
		        />
		      }
		      label={label}
		      disabled={disabled}
		    />
   )
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const ExportForm = ({ handleSubmit, reset, t, user, toggle, export_name, btnText }) => (
	
      <form className="material-form" onSubmit={handleSubmit}>
         <Row>
          <Col sm={12} md={12} lg={12} xl={12}>         	
	          <div>
	            <span className="material-form__label">Export Name (For reference later)</span>
	            <Field
	              name="exportName"
	              component={renderTextField}
	              placeholder="Export Name"
	            />
	            
	          </div>
          </Col>          
         </Row>
         <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
		  <p style={{fontSize:15}}>You will receive an email when your export is ready for download.</p>
         <ButtonToolbar className="modal__footer" style={{padding:0}}>
            <Tooltip title="Cancel">
		        <Button
			         onClick={toggle}
			         variant="outlined"
			         color=""
			         className=""		         
			         startIcon={null}
			         style={{marginRight:10}}
			     >	Cancel
			     </Button>				 
		  	</Tooltip>{' '}
            <Tooltip title="Confirm Data Export Request">
		        <Button
		        	 onClick={handleSubmit}
			         variant="contained"
			         color="primary"
			         className="buyersList__primary-button"		         
			         startIcon={<ExportIcon />}
			     >	{btnText ? btnText : "Confirm Export" }
			     </Button>				 
		  	</Tooltip>
         </ButtonToolbar>
         </Col>          
         </Row> 
    </form>
);

ExportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default connect(
  (state, props) => ({
    initialValues: Object.assign({}, { exportName: props.export_name ? props.export_name : 'My Export'}), // pull initial values from account reducer
    formValues: getFormValues("export_form")(state)
  })
)(reduxForm({
  form: 'export_form', // a unique identifier for this form
})(ExportForm));
