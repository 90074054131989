import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setUserProfile } from '../../../redux/actions/authActions';
import { Publisher } from "./../../../shared/pubsub/publisher.js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import { _hasPermission	} from "./../../../shared/helpers/access";

import{
	_doSSOLogout,
	_setSSOToken,
	_getSSOToken
} from './../../../shared/components/auth/sso/ssoutils';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
    this.logout = this.logout.bind(this);
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };
  
  async logout() {
	const { authState, authService, dispatch, user, dataSet } = this.props;
	const ssoToken = _getSSOToken();

	if(!authState.isPending && authState.isAuthenticated){
		authService.logout('/');
		dispatch(setUserProfile(null));
		
		Publisher.publish("logout", {});
	}else if(user.authType === "sso"){
		_doSSOLogout(this.props, setUserProfile);
		
	}else if(ssoToken){
		_doSSOLogout(this.props, setUserProfile);
	}
   
  }
  
  sideRoleContent = (user) => {
	  if(!user) return null;
	  if(!user.accountType) user.accountType='buyer';
	  
	  		if(!_hasPermission(user, 'sponsor-buildings')){
				  return  (
				  <Fragment>
					  <ul className="sidebar__block sidebar__bold">
						<SidebarCategory title="My Dashboard" icon="heart" collapse>
							 <SidebarLink title="Overview" route="/dashboard" onClick={this.hideSidebar} /> 
							 <SidebarLink title="Market Snapshots" route="/analysis/market-snapshots" isNew onClick={this.hideSidebar} />
							 <SidebarLink title="News" route="/news" onClick={this.hideSidebar} />
						  <SidebarLink title="Saved" route="/my-buildings" onClick={this.hideSidebar} />
						  
						  <SidebarLink title="Shared" route="/my-shared" onClick={this.hideSidebar} />
						  <SidebarLink title="Collections"  route="/collections" onClick={this.hideSidebar} />
						  <SidebarLink title="Downloads" route="/my-exports" onClick={this.hideSidebar} />
				  
						  
						</SidebarCategory>              
					  </ul>  
					
					  
					  {/*<ul className="sidebar__block">
						  <SidebarLink title="Transaction Center" icon="cart" route="/transaction-center" onClick={this.hideSidebar} />
						  <SidebarLink title="Community Center" icon="bubble" route="/community-center" onClick={this.hideSidebar} />
					  </ul>*/}
					  {isMobile ?	
					  <Fragment>					
						  <ul className="sidebar__block sidebar__bold">
							  <SidebarLink title="Search Inventory" icon="apartment"   route="/inventory" onClick={this.hideSidebar} />
							  <SidebarLink title="Search Buildings" icon="apartment"  route="/building-database/sale" onClick={this.hideSidebar} />
							  <SidebarLink title="Search Houses" icon="home"  route="/building-database/house" onClick={this.hideSidebar} />						        
						  </ul>					    
					  </Fragment>
					  :
						  <ul className="sidebar__block sidebar__bold">
							   <SidebarCategory title="Condos & Co-Ops" newLink icon="apartment" collapse>
								  <SidebarLink title="New Development" upgrade={!_hasPermission(user, 'sponsor-buildings')} route="/building-database/sale/sponsor?building_stage=sponsor" onClick={this.hideSidebar} />	   
								  <SidebarLink title="Resale Buildings" upgrade={!_hasPermission(user, 'resale-buildings')} route="/building-database/sale/resale?building_stage=resale" onClick={this.hideSidebar} />
								  <SidebarLink title="Resale Inventory" upgrade={!_hasPermission(user, 'resale-buildings')}   route="/inventory/resale?building_stage=resale" onClick={this.hideSidebar} />	
								  
								  {/*<SidebarLink title="All" route="/building-database/sale?building_stage=all" onClick={this.hideSidebar} />					        		
								  <SidebarLink title="Rentals" newLink route="/building-database/rental" onClick={this.hideSidebar} />*/}								
							  </SidebarCategory>
							  <SidebarCategory title="Houses" icon="home" collapse>
								  <SidebarLink title="Townhouse"  route="/building-database/house/?building_class=townhouse" onClick={this.hideSidebar} />	 
							  
								  <SidebarLink title="Single-Family"  route="/building-database/house/?ownership_type=single-family&building_class=" onClick={this.hideSidebar} />
							  
								  <SidebarLink title="Multi-Family" route="/building-database/house/?ownership_type=multi-family&building_class=" onClick={this.hideSidebar} />
								  
								  
								  {/*<SidebarLink title="All" route="/building-database/sale?building_stage=all" onClick={this.hideSidebar} />					        		
								  <SidebarLink title="Rentals" newLink route="/building-database/rental" onClick={this.hideSidebar} />*/}								
							  </SidebarCategory>
							
							 
							  <SidebarCategory title="Research" newLink icon="chart-bars" collapse>
							  	  <SidebarLink title="Map Search"  route="/map" onClick={this.hideSidebar} />
								  <SidebarLink title="Market Activity" route="/analysis/market-pulse" onClick={this.hideSidebar} />
								  <SidebarLink title="Price Trends" upgrade={!_hasPermission(user, 'price-trends')} route="/analysis/pricing" onClick={this.hideSidebar} />
								  <SidebarLink title="Discount Analysis" upgrade={!_hasPermission(user, 'discount-analysis')} route="/analysis/discount" onClick={this.hideSidebar} />
									
									<SidebarLink title="Past Sales" upgrade={!_hasPermission(user, 'past-sales')} route="/past-sales" onClick={this.hideSidebar} />
							  </SidebarCategory>
							 
							  {_hasPermission(user, 'admin-tools') &&
								  <SidebarCategory title="Admin Tools" icon="lock" collapse>						        
									  <SidebarLink title="Coming Soon" route="/coming-soon" onClick={this.hideSidebar} />																    </SidebarCategory>
							   }
						  </ul>
					  
					  
					  }
						  
				  </Fragment>	        
				  )
			}else{

	    		return  (
					<Fragment>
						<ul className="sidebar__block sidebar__bold">
			          	<SidebarCategory title="My Dashboard" icon="heart" collapse>
			  		 		<SidebarLink title="Overview" route="/dashboard" onClick={this.hideSidebar} /> 
						{!isMobile ?	<SidebarLink title="Market Snapshots" route="/analysis/market-snapshots" isNew onClick={this.hideSidebar} /> : null}
			  		 		<SidebarLink title="News" route="/news" onClick={this.hideSidebar} />
			            	<SidebarLink title="Saved" route="/my-buildings" onClick={this.hideSidebar} />
							
							<SidebarLink title="Shared" route="/my-shared" onClick={this.hideSidebar} />
							<SidebarLink title="Collections" route="/collections" onClick={this.hideSidebar} />
				        	<SidebarLink title="Downloads" route="/my-exports" onClick={this.hideSidebar} />
	
				        	
			          	</SidebarCategory>              
			        	</ul>  
			      	
						
						{/*<ul className="sidebar__block">
							<SidebarLink title="Transaction Center" icon="cart" route="/transaction-center" onClick={this.hideSidebar} />
							<SidebarLink title="Community Center" icon="bubble" route="/community-center" onClick={this.hideSidebar} />
						</ul>*/}
						{isMobile ?	
						<Fragment>					
							<ul className="sidebar__block sidebar__bold">
								<SidebarLink title="Search Inventory" icon="apartment"   route="/inventory" onClick={this.hideSidebar} />
								  <SidebarLink title="Search Buildings" icon="apartment"  route="/building-database/sale" onClick={this.hideSidebar} />
								  <SidebarLink title="Search Houses" icon="home"  route="/building-database/house" onClick={this.hideSidebar} />						        
					    	</ul>					    
						</Fragment>
						:
							<ul className="sidebar__block sidebar__bold">
							 	<SidebarCategory title="Condos & Co-Ops" newLink icon="apartment" collapse>
							 			
					        		{_hasPermission(user, 'sponsor-buildings') && <SidebarLink title="New Development" upgrade={!_hasPermission(user, 'sponsor-buildings')} route="/building-database/sale/sponsor" onClick={this.hideSidebar} />}
									<SidebarLink title="Resale" upgrade={!_hasPermission(user, 'resale-buildings')} route="/building-database/sale/resale" onClick={this.hideSidebar} />
									<SidebarLink title="Pipeline" upgrade={!_hasPermission(user, 'pipeline-buildings')} route="/building-database/sale/pipeline" onClick={this.hideSidebar} />
									
									<SidebarLink title="Inventory" route="/inventory/all?building_stage=all&current_status=available&group_view=list&property_type=residential&unit_stage=all" onClick={this.hideSidebar} /> 
									
					        		{/*<SidebarLink title="All" route="/building-database/sale?building_stage=all" onClick={this.hideSidebar} />					        		
									<SidebarLink title="Rentals" newLink route="/building-database/rental" onClick={this.hideSidebar} />*/}								
					        	</SidebarCategory>
								<SidebarCategory title="Houses" icon="home" collapse>
									<SidebarLink title="Townhouse"  route="/building-database/house/?building_class=townhouse" onClick={this.hideSidebar} />	 
								
									<SidebarLink title="Single-Family"  route="/building-database/house/?ownership_type=single-family&building_class=" onClick={this.hideSidebar} />
								
									<SidebarLink title="Multi-Family" route="/building-database/house/?ownership_type=multi-family&building_class=" onClick={this.hideSidebar} />
									
									
									{/*<SidebarLink title="All" route="/building-database/sale?building_stage=all" onClick={this.hideSidebar} />					        		
									<SidebarLink title="Rentals" newLink route="/building-database/rental" onClick={this.hideSidebar} />*/}								
								</SidebarCategory>
								{/* <SidebarCategory title="Marketing"  icon="chart-bars" isNew collapse>
									<SidebarLink title="Shareables" route="/marketing/shareables" new onClick={this.hideSidebar} />
								</SidebarCategory> */}
					       	
								<SidebarCategory title="Research"  icon="chart-bars" collapse>
									<SidebarLink title="Map Search" route="/map" new onClick={this.hideSidebar} />
									<SidebarLink title="Market Activity" route="/analysis/market-pulse" onClick={this.hideSidebar} />

									
									<SidebarLink title="Price Trends" upgrade={!_hasPermission(user, 'price-trends')} route="/analysis/pricing" onClick={this.hideSidebar} />
									<SidebarLink title="Discount Analysis" upgrade={!_hasPermission(user, 'discount-analysis')} route="/analysis/discount" onClick={this.hideSidebar} />
									
									<SidebarLink title="Past Sales" upgrade={!_hasPermission(user, 'past-sales')} route="/past-sales" onClick={this.hideSidebar} />
								</SidebarCategory>
								<SidebarCategory title="New Dev Tools" newLink icon="pie-chart" collapse>
									<SidebarLink title="Sales Velocity" upgrade={!_hasPermission(user, 'sales-velocity')}  route="/analysis/sales-velocity" onClick={this.hideSidebar} />	
									<SidebarLink title="Inventory Analysis" route="/inventory-analysis" onClick={this.hideSidebar} />	

									{/*<SidebarLink title="Condos & Co-ops" route="/building-database/sale" onClick={this.hideSidebar} />*/}		
									<SidebarLink title="Permits & Filings" upgrade={!_hasPermission(user, 'pipeline')} route="/filings" onClick={this.hideSidebar} />
									<SidebarLink title="Sales Agents" upgrade={!_hasPermission(user, 'sales-agents')}  route="/sales-agents" onClick={this.hideSidebar} />	
									<SidebarLink title="Developers" upgrade={!_hasPermission(user, 'developers')} route="/developers" onClick={this.hideSidebar} />
									<SidebarLink title="Architects" upgrade={!_hasPermission(user, 'architects')} route="/architects" onClick={this.hideSidebar} />
									<SidebarLink title="Lenders" upgrade={!_hasPermission(user, 'lenders')} route="/lenders" onClick={this.hideSidebar} />	
									
												        								            		
					        	</SidebarCategory>
					        	{/*_hasPermission(user, 'admin-tools') &&
						        	<SidebarCategory title="Admin Tools" icon="lock" collapse>						        
							        	<SidebarLink title="Coming Soon" route="/coming-soon" onClick={this.hideSidebar} />																    </SidebarCategory>
						     	*/}
					    	</ul>
						
						
						}
							
					</Fragment>	        
		        	)
				}

  }

  render() {
    const { changeToDark, changeToLight, user } = this.props;
    return (
      <div className="sidebar__content"> 
        {this.sideRoleContent(user)}
        
          
          
        <ul className="sidebar__block">          
          {/*<SidebarCategory title="Settings" icon="cog">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Mode</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Mode</p>
            </button>
          </SidebarCategory>*/}
          {/*<SidebarLink title="Log Out" icon="exit" onClick={this.logout} />
          <SidebarLink title="My Account" icon="user" route="/my-account"  onClick={this.hideSidebar} />*/}
          <SidebarCategory title="My Account" icon="user">
        	<SidebarLink title="Profile & Password" route="/my-account" onClick={this.hideSidebar} />
            <SidebarLink title="Subscription & Billing" route="/my-subscription" onClick={this.hideSidebar} />
          </SidebarCategory> 
		 	          
          <li className="sidebar__link" onClick={this.logout}>
		      <span className={`sidebar__link-icon lnr lnr-exit`} />
		      <p className="sidebar__link-title">
		        Log Out		       
		      </p>
		  </li>
		  
		  {isMobile &&
			  <div style={{margin:10,padding:'10px 30px',background:'#fafbfe',fontSize:'11px'}}>
	        	For access to all tools,<br/> use the desktop version.
	          </div>
	       }
        </ul>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(SidebarContent));
