/* eslint-disable react/no-children-prop */
import React, { Fragment, useState } from 'react';
import {
  Card, CardBody, Row, Col, ButtonToolbar, Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Tooltip } from '@material-ui/core';

import { Field, reduxForm, getFormValues, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import InfoIcon from 'mdi-react/InformationOutlineIcon';

import Modal from '../../../shared/components/Modal';

import TrialSubscribe from './../../../shared/components/payment/TrialSubscribe';
import ChangePlan from '../../../shared/components/payment/ChangePlan';
import CouponForm from '../../../shared/components/payment/stripe/CouponForm';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import{
	_capitalizeText,
	_formatNumber
} from '../../../shared/helpers/utils';

import { subscriptionPlans, appFeatures, DataCreditUsageProgress, _isPipelineSubscriber } from '../../../shared/components/payment/plans.js';
import { _canSelfServe } from '../../../shared/helpers/access.js'; 


const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);


const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined

const renderTextField = ({
  input, label, meta: { touched, error, warning }, children, select,
}) => (
  <Fragment>
	  <TextField
	    className="material-form__field"
	    label={label}
	    error={touched && error}
	    value={input.value}
	    children={children}
	    select={select}
	    onChange={(e) => {
	      e.preventDefault();
	      input.onChange(e.target.value);
	    }}
	    type="password"
	  />
	  {touched && ((error && <div className="field-error">{error}</div>) || (warning && <div className="field-error warn">{warning}</div>))}
  </Fragment>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};

const SubscriptionForm = ( props ) => {
	
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ subscribe, setSubscribe ] = useState(false); 
	const [ planOpen, setPlanOpen ] = useState(false);
	
	const { cancelSubscription, reset, user, subscription, reactivateSubscription, userCreditUsed, setCoupon, updateSubscriptionDetails, subscriptionSchedule, releaseSubscriptionSchedule} = props;
	const { subscriptionPlan } = user;
	const currentPlan = subscriptionPlans.filter(plan => plan.stripeProductId === subscriptionPlan)[0];
	if(!subscriptionPlans || !currentPlan || !subscription.start_date || _isPipelineSubscriber(user) || !_canSelfServe(user)){
		return (
		<Col md={12} lg={8}>
		    <Card>
		      <CardBody>
		        <div className="card__title">
		          <h5 className="bold-text">{'My Subscription'}</h5>
		          <div className="page-subhead subhead"></div>
		          <hr/>
		        </div>
		        {/*<div>
		        	Subscription ID: <strong className="plan-bold">{user.subscriptionId}</strong>
		        </div>*/}
		        <div>
		        	Current Plan: <strong className="plan-bold">Custom Plan</strong>
		        </div>
		        <div>
		        	Price: <strong className="plan-bold">--</strong>
		        </div>
		        <div>
		        	Billing: <strong className="plan-bold">--</strong>
		        </div>
		        <div>
		        	Subscription Started: <strong className="plan-bold">--</strong>
		        </div>
		        <div>
		        	Next Invoice Date: <strong className="plan-bold">--</strong>
		        </div>
		        <hr/>
		        {/* <div className="text-right cancel-subscription">	        	
		        	<Modal
			            color="danger"
			            title="Cancel Subscription"
			            header
			            btn="Cancel Subscription"
			            message=" "
			            onSubmit={() => cancelSubscription(cancelSubscription)}
			            submitBtnText={'Cancel Subscription'}
			        >
			        	<p>{`We're sorry to see you go.`}</p>
			        	<p>{`Please note that once you cancel, you will lose all access to Marketproof New Development as well as any saved information.`}</p>
			        	<p> Are you sure you want to continue?</p>
			        </Modal>
		        </div> */}
		        
		      </CardBody>
		    </Card>
		  </Col>
		);
		
	}
	let totalAtCheckout = subscription.plan && subscription.plan.amount ? (subscription.plan.amount/100) : false;
	let coupon = subscription.discount && subscription.discount.coupon && subscription.discount.coupon;
	if(coupon){
		if(coupon.percent_off){
			totalAtCheckout = Math.round(totalAtCheckout * ((100-coupon.percent_off)/100));
		}else if(coupon.amount_off){
			totalAtCheckout = Math.round(totalAtCheckout - (coupon.amount_off/100));
		}
	}
	
	let hasSubscriptionSchedule = subscriptionSchedule && subscriptionSchedule.phases 
	return(
		
	  <Col md={12} lg={8}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <h5 className="bold-text">{'My Subscription'}</h5>
	          <div className="page-subhead subhead"></div>
	          <hr/>
	        </div>
			
	        {/*<div>
	        	Subscription ID: <strong className="plan-bold">{user.subscriptionId}</strong>
	        </div>*/}
	        <div>
	        	Current Plan: <strong className="plan-bold">{currentPlan.name}</strong>
	        </div>
	        <div>
		        Status: <strong className="plan-bold">{subscription.status === 'trialing' ? `Free Trial (Expires: ${moment(subscription.trial_end*1000).format('M/D/YYYY')})` : subscription.cancel_at_period_end ? <span className="canceled">{`Canceled (Subscription Ends On: ${moment.unix(subscription.current_period_end).format('L')})`}</span> : _capitalizeText(subscription.status)}</strong>
		    </div>
	        <div>
	        	Plan Price: <strong className="plan-bold">${subscription.plan ? totalAtCheckout : currentPlan.pricePerMonth} / {_capitalizeText(currentPlan.billingFrequency)}</strong>
				
	        </div>
	        
	       
	        {subscription.discount && subscription.discount.coupon && subscription.discount.coupon.name &&
		        <div>
		        	Discount: <strong className="plan-bold">{_capitalizeText(subscription.discount.coupon.name)}</strong>
					
		        </div>	        
	        }
	        <div>
	        	Billing: <strong className="plan-bold">Once per {_capitalizeText(currentPlan.billingFrequency)}</strong>
	        </div>
	        <div>
	        	Subscription Started: <strong className="plan-bold">{moment.unix(subscription.start_date).format('L')}</strong>
	        </div>
	        {!subscription.cancel_at_period_end &&
	        <div>
	        	Next Invoice Date: <strong className="plan-bold">{moment.unix(subscription.current_period_end).format('L')}</strong>
	        </div>
	        }
			
			{hasSubscriptionSchedule && 
				<div style={{background:"#F5F7FA",padding:10,border:"1px solid #780F9E",marginTop:20}}>
					<div className="card__title mb-0"><h5 className="bold-text"><InfoIcon size={20} color="#780F9E" style={{marginTop:-3}}/>  {'Upcoming Plan Change'}</h5></div>
					<hr/>
					{subscriptionSchedule.phases.map((phase, i) => {
						if(phase.plans && phase.plans[0] && phase.plans[0].plan){
							const plan = subscriptionPlans.filter(plan => plan.stripeProductId === phase.plans[0].plan)[0];
							if(subscription.plan.id == phase.plans[0].plan){
								return <div>Current Plan: <strong className="plan-bold">{plan ? plan.name : ""} (${plan.pricePerMonth}/mo + Tax) ends on {moment.unix(phase.end_date).format('L')}</strong> </div>
							}else{
								return <div>Future Plan: <strong className="plan-bold">{plan ? plan.name : ""} (${plan.pricePerMonth}/mo + Tax) starts on {moment.unix(phase.start_date).format('L')}</strong> </div>
							}
							
						}else{
							return null;
						}
					
					})}
					
				</div>	
			}
			
	        <hr/>
	        <div className="text-right cancel-subscription">
	         <ButtonToolbar>
		     {/*currentPlan.id === 'advanced' ?
			     <Fragment>
			     	
			     	<Button
				         variant="contained"
				         color="outline-primary"
				         className="buyersList__primary-button"	
				         style={{width:200}}	   
				          onClick={() => {setPlanOpen(true)}}      
				     >	
				     	Downgrade Plan
				     </Button>
				     <ChangePlan modal={planOpen} setModal={setPlanOpen} changePlan={'basic'} downgrade />
			     </Fragment>
			     :
			     <Fragment>
			     	
			     	<Button
				         variant="contained"
				         color="primary"
				         className="buyersList__primary-button"	
				         style={{width:200}}	   
				          onClick={() => {setPlanOpen(true)}}      
				     >	
				     	Upgrade Plan
				     </Button>
				     <ChangePlan modal={planOpen} setModal={setPlanOpen} changePlan={'advanced'} />
			     </Fragment>
		    */ }
		    {!hasSubscriptionSchedule && !subscription.cancel_at_period_end && <Link to={`/pricing`}><Button
		         variant="contained"
		         color="primary"
		         className="buyersList__primary-button"	
		         style={{marginBottom:10}}	   
		             
		     >	
		     	Change Plan
		     </Button></Link>}
			 {!hasSubscriptionSchedule &&
			 <div style={{display:'inline-block'}}>
				 <Elements stripe={stripePromise}>
				  <CouponForm user={user} setCoupon={setCoupon} currentCoupon={coupon} currentPlan={currentPlan} trial={subscription.status === 'trialing'} selectedPlan={currentPlan} updateSubscriptionDetails={updateSubscriptionDetails} showButton />
				</Elements>
			 </div>
		 	 }
	         {subscription.status === 'trialing' && <Button color={'success'} style={{marginBottom:10}}	  onClick={() => {setSubscribe(true)}}>Activate Subscription</Button>}
			 {hasSubscriptionSchedule && <Button
				  variant="contained"
				  color="primary"
				  className="buyersList__primary-button"	
				  style={{marginBottom:10}}	   
				  onClick={()=>releaseSubscriptionSchedule()}
					  
			  >	
				  Cancel Plan Change
			  </Button>}
	         {!subscription.cancel_at_period_end ?	        	
	        	<Modal
		            color="danger"
		            buttonClass={"btn-outline-danger"}
		            title={subscription.status === 'trialing' ? 'Cancel Trial' : 'Cancel Subscription'}
		            header
		            btn={subscription.status === 'trialing' ? 'Cancel Trial' : 'Cancel Subscription'}
		            message=" "
		            onSubmit={() => cancelSubscription(subscription)}
		            submitBtnText={subscription.status === 'trialing' ? 'Cancel Trial' : 'Cancel Subscription'}
		        >
		        	<p>{`We're sorry to see you go.`}</p>
		        	{subscription.status === 'trialing' ?
		        		<p>{`Please note that once you cancel, you will lose all access to Marketproof New Development as well as any saved information.`}</p>
		        		:
						<p>{`After`} <strong>{moment.unix(subscription.current_period_end).format('L')}</strong>{`, you will no long have access to Marketproof New Development or any saved information.`}</p>
		        	}
		        	<p> Are you sure you want to continue?</p>
		        </Modal>
		        :
		        <Modal
		            color="success"
		            title={'Reactivate Subscription'}
		            header
		            btn={'Reactivate Subscription'}
		            message=" "
		            onSubmit={() => reactivateSubscription(subscription)}
		            submitBtnText={'Reactivate Subscription'}
		        >
		        	
					<p>{`After`} <strong>{moment.unix(subscription.current_period_end).format('L')}</strong>{`, you will no long have access to Marketproof New Development or any saved information.`}</p>
					<p>{`Reactivate your subscription at anytime until then for continued access.`}</p>
		        	
		        </Modal>
		        }
		        
		        </ButtonToolbar>
	        </div>
	        <TrialSubscribe subscribe={subscribe} setSubscribe={setSubscribe} />
	      </CardBody>
	    </Card>
	  </Col>
	)
};

const form = 'password_form';

export default connect(
  (state, props) => {
  const initalValues = Object.assign({}, props.user);
  console.log(initalValues);
  return {
    initialValues: Object.assign({}, initalValues), // pull initial values from account reducer
    formValues: getFormValues(form)(state),
    user: state.user,
  }}
)(reduxForm({
  form: form, // a unique identifier for this form
})(SubscriptionForm));
