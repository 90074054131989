import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import axios from "axios";
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { getFormValues, change } from 'redux-form';

import AISummaryForm from './AISummaryForm';
import SnackBar from '../../../shared/components/SimpleSnackBar';

import { Publisher } from './../../pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_slugifyText,
	setLocalStorage,
	_formatCurrency,
	_getFeaturesArr,
	_formatNumberDecimal
} from '../../../shared/helpers/utils';
import { amenitiesList } from "../../../shared/helpers/bp-utils.js";
import { userEditFieldsObj } from "../../../shared/helpers/userEditFields.js";

import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/'; //'http://localhost:8000/buyerslist/';
const form = 'ai_summary_form';
class AISummaryButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: 'Contact',
	    modal: false,
	    saved: false,
	    link_id: this.props.linkID ? this.props.linkID : false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
		deleteModal: false,
		fetchInProgress:false
	    
	};
	this.toggle = this.toggle.bind(this);
	this.handleGenerate = this.handleGenerate.bind(this);
    
  }
  
   async handleGenerate(preview) {
   // event.preventDefault();
     const { authState, authService, dispatch, user, formValues, buildingRes, property, listing, actions, transactionRes, statsRes, activityStatsRes } = this.props;
     const { link_id } = this.state;
	 this.setState({fetchInProgress:true});
	 const buildingFieldMap = {
		 "name" : { name: "Building Name", formatter : false } ,
		 "display_full_street_address" :  { name: "Address", formatter : false } ,
		 "neighborhood" :  { name: "Neighborhood", formatter : false } ,
		 "architect" :  { name: "Architect", formatter : false } ,
		 "total_floors" :  { name: "Total Floors", formatter : false } ,
		 "total_units_residential" :  { name: "Total Units", formatter : false } ,
		 "inventory_stats_listings_average_ppsf" :  { name: "PPSF", formatter : _formatCurrency } ,
		 "year_built" :  { name: "Year Built", formatter : false } ,
		 "building_status" :  { name: "Sales Stage", formatter : ((val) => val + " Stage") },
		 "developers" :  { name: "Developer", formatter : false } ,
		 "interior_designers" :  { name: "Interior Designer", formatter : false } ,
		 "manager" :  { name: "Property Mananger", formatter : false } ,
		 
	 }
	 const propertyFieldMap = {
		  "address_2_normalized" : { name: "Unit Number", formatter : _formatUnitNumber } ,
		  "floor" : { name: "Floor", formatter : false } ,
		  "bedrooms" : { name: "Bedrooms", formatter : false } ,
		  "baths" :  { name: "Bathrooms", formatter : false } ,
		  "sq_ft" :  { name: "Square Feet", Feet : _formatNumber } ,
		  "exterior_sq_ft" :  { name: "Exterior Squre Feet", formatter : _formatNumber },
		  "monthly_fees" :  { name: "Unit Common Charges", formatter : _formatCurrency },
		  "monthly_taxes" :  { name: "Unit Taxes", formatter : _formatCurrency }
	  }
	 if(buildingRes["building_status"] == "sponsor"){
		 buildingFieldMap["inventory_stats_listings_low_price"] = { name: "Low Unit Price", formatter : _formatPrice };
		 buildingFieldMap["inventory_stats_listings_high_price"] = { name: "High Unit Price", formatter : _formatPrice };
		 buildingFieldMap["inventory_pct_unsold"] = { name: "% Units Sold", formatter : ((val) => Math.round((100 - val))+"%") };		
		 buildingFieldMap["marketing_company"] = { name: "Sales & Marketing", formatter : false };	
	 }
	 
	
	
	 
	 let promptData = [];
	 
	 if(property){
		  Object.keys(property).map(key => {
			   if(propertyFieldMap[key]){
				   
				   const val = Array.isArray(property[key]) && property[key][0] ? property[key][0] : property[key];			 
				   if(val){
					   promptData.push(`${propertyFieldMap[key].name}=${propertyFieldMap[key].formatter ? propertyFieldMap[key].formatter(val) : val}`);
				   }
			   }
		   })
	  }
	 
	 Object.keys(buildingRes).map(key => {
		 if(buildingFieldMap[key]){
			 
			 const val = Array.isArray(buildingRes[key]) && buildingRes[key][0] ? buildingRes[key][0] : buildingRes[key];			 
			 if(val){
				 promptData.push(`${buildingFieldMap[key].name}=${buildingFieldMap[key].formatter ? buildingFieldMap[key].formatter(val) : val}`);
			 }
		 }
	 })
	 
	const _floodzoneTranslateShort = {
		 "VE" : "Extremely High Risk - Wave Hazard",
		 "AE" : "High Risk",
		 "A" : "High Risk",
		 "AO" : "High Risk",
		 "X" : "Minimal Risk",
		 "0.2 PCT ANNUAL CHANCE FLOOD HAZARD" : "High Risk"
	 }
	 
	 
	 
	 if(transactionRes ){
		 if(transactionRes['count_no_finance'] && transactionRes['count_no_finance']['no_finance']){
			 promptData.push(`Percent Cash Buyers=${_formatNumber(transactionRes['count_no_finance']['no_finance'])}%`);
		 }
		 
		 if(buildingRes["building_status"] == "resale"){
			 if(transactionRes['dom']['count']){
				  promptData.push(`Average Days on Market=${_formatNumber(transactionRes['dom']['count'])} Days`);
			 }
			 if(transactionRes['avg_price']){
				   promptData.push(`Average Unit Price=$${_formatNumber(transactionRes['avg_price'])}`);
			 }
		 }
		 
		  
	 }
	 
	 if(statsRes){
		 if(buildingRes["building_status"] == "sponsor" ){
		
			if(statsRes['non_zero_price'] && statsRes['non_zero_price']['average_price']>0) promptData.push(`Average Unit Price=${_formatNumber(statsRes['non_zero_price']['average_price'])}`);
		 }
		  
	  }
	  if(activityStatsRes){
		  if(buildingRes["building_status"] == "sponsor" ){
			  if(activityStatsRes['sold_3_mo'] && activityStatsRes['sold_3_mo'].count) promptData.push(`3 Months Sales Velocity=${_formatNumberDecimal((activityStatsRes['sold_3_mo'].count / 3), 1)} Units per Month`);
		   }
	  }
	  

	 let prompt = "Write a building summary in a factual tone using the following data points.";
	 
	 if(formValues.userPrompt && formValues.userPrompt != ""){
		 prompt = formValues.userPrompt;
	 }
	 
	 if(buildingRes["flood_zone_1"][0] && _floodzoneTranslateShort[buildingRes["flood_zone_1"][0].toUpperCase()] && prompt.toLowerCase().indexOf("listing") <0 ){
		  promptData.push(`Building Flood Risk=${_floodzoneTranslateShort[buildingRes["flood_zone_1"][0].toUpperCase()]}`);
	  }
	 
	 prompt += " Don’t justify your answers. Don’t give information not mentioned in the Marketproof Data.";
	 
	 // if(formValues.summaryType == "listing"){
		//  prompt = "Act as a real estate agent write a descriptive listing summary based on the following.";
		//  const compiledFeatureList = _getFeaturesArr('building', false, buildingRes);
		//  const highlightedAmenities = amenitiesList.filter(item => compiledFeatureList.some(el => item.values.includes(el))).map(el => el.name);
		//  
		//  promptData.push(`Building Amenities=${highlightedAmenities.join(", ")}`);
	 // }else if(formValues.summaryType == "social"){
		//  prompt = "Write a social media post for a building with following information:";
	 // }
	 // 
	 // if(formValues.includeNeighborhoodInfo){
		//  prompt += "In a separate paragraph include neighborhood lifestyle, restaurant, shopping, entertainment and transportation info in at least 300 words:";
	 // }
	 // prompt += "\n\n";
	 // prompt += promptData.join("\n");
     
	 const queryData = {
		 "messages" : [
			 {role : "system", content : `You are a real estate expert, use the following Marketproof Data about a property.\n\n${promptData.join("\n")}`},
		 	 {role : "user", content : prompt},
		 ],
		 //"max_tokens" : 999,
		 //"model" : formValues.aiModel ? formValues.aiModel : "gpt-3.5-turbo"
	 }
   
	 let apiData = {
			url: apiUrl+'ai/stream',
			method: "post",
			query: queryData
	  	  };
			
		const res = await fetch(apiUrl+'ai/stream', {
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(queryData)
		})
		// Create a reader for the response body
		const reader = res.body.getReader();
		// Create a decoder for UTF-8 encoded text
		const decoder = new TextDecoder("utf-8");
		let result = "";
		// Function to read chunks of the response body
		const readChunk = async () => {
			return reader.read().then(({ value, done }) => {
				if (!done) {
					let dataString = decoder.decode(value);
					//console.log(dataString);
					if(dataString){
						
						dataString = "["+dataString.replace(/}\{/g, "},{") + "]";
						try{
							const list = JSON.parse(dataString.toString().trim());
							
							list.forEach(data => {
								if (data.error) {
									console.error("Error while generating content: " + data.message);
								} else {
									result = data.streamHead ? data.text : result + data.text;
									
									actions.change(form, 'summary', result.replace(/^\n\n/, '').replace(/(?:\n\n|\r|\n)/g, '<br/>'));
									return readChunk();
								}
							})
							
						}catch(e){
							console.log(e);
							this.setState({fetchInProgress:false});
						}
					}
					
				} else {
					console.log("done");
					this.setState({fetchInProgress:false});
				}
			});
		};
		
		await readChunk();
	  
	 // let res = await _axiosCall(apiData);
	 
	 // if(typeof(EventSource) !== "undefined") {
	 //   var source = new EventSource(apiUrl+'ai/stream');
	 //   source.onmessage = function(event) {
		// console.log(event)
	 //   };
	 // } else {
	 //   console.log("Sorry, your browser does not support server-sent events...");
	 // }
	  
	 //  const response = await axios({
		//   method: 'get',
		//   url: apiUrl+'ai/stream',
		//   data: queryData,
		//   responseType: 'stream'
		// })
	 // const stream = response;
	 // 
	 // stream.on('data', data => {
		//  console.log(data);
	 // });
	 // 
	 // stream.on('end', () => {
		//  console.log("stream done");
	 // });
	  
	  // _axiosCall(apiData)
	  //   .then(res => {
		//     console.log(res)
		// 	if(res && res.choices && res.choices[0] && res.choices[0].message){
		// 		actions.change(form, 'summary', res.choices[0].message.content.replace(/^\n\n/, '').replace(/(?:\n\n|\r|\n)/g, '<br/>'));
		// 	}
		//     this.setState({fetchInProgress:false});
		// })
   // addMyBuyersList(buildingKey, priority);
   // this.setState({ buttonText: 'Building Saved'})
    
  }
  
 
  snackOpen = (message, type) => {
		const { snackOpen } = this.props;
		
		if(snackOpen){
			snackOpen(message, type)
		}else{
			this.setState( { snackShow: true, snackMessage: message, snackType : type} )
		}
	};
	
	snackClose = (event, reason) => {
	    const { snackClose } = this.props;
		event && event.stopPropagation();
		if (reason === 'clickaway') {
		  return;
		}	
		
		if(snackClose){
			snackClose(event, reason);
		}else{
			this.setState( { snackShow: false, snackMessage: '' } )
		}
	};
	


  toggle() {
	const { buildingRes, property, toggleRefresh, report } = this.props;
	
	const { modal } = this.state;
	
	if(modal && report){
		toggleRefresh();
	}
    this.setState(prevState => ({ modal: !prevState.modal }));
    
  }
  
 
  
  generateShareBase = ( ) => {		 
	 
	 //let shareBaseURL = `https://newdev.marketproof.com/share/`	 
	 let shareBaseURL = window.location.protocol + "//" + window.location.host + "/share/"
	 
	 return shareBaseURL;
  }
  generateShareLink = ( ) => {		 
	
	 const { buildingRes, property, formValues, initialValues, generateShareBase } = this.props;
	 
	 
	 let shareBaseURL = this.generateShareBase();
	 
	 if(formValues && formValues.customLink){
		 return shareBaseURL + formValues.customLink; 
	 }else if(initialValues && initialValues.customLink){
		 return shareBaseURL + initialValues.customLink; 
	 }
	 
	 return shareBaseURL; // + formValues.customLink;
}
  
  

  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, listing, report, shareLink, small, transactionRes, statsRes
    } = this.props;
	
	const disabled = !property && (!buildingRes || !transactionRes || !statsRes);
	//if(disabled) return null;
    
    const { modal, snackShow, snackMessage, saved, snackType, deleteModal, fetchInProgress } = this.state;
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;

	let salesOffice = false;
	
	try{
		salesOffice = JSON.parse(buildingRes.sales_office_details);
	}catch(e){
		
	}

    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				{report ? 
					<Fragment>
						<Tooltip title="Edit This Shared Page">	            		
					  	<IconButton	
						  	className="material-table__toolbar-button"
						  	onClick={(e) => {e.stopPropagation();this.toggle()}}						  
					  	>
					  	<EditIcon /> 
					  	</IconButton>
						</Tooltip>
						<Tooltip title="Delete This Shared Page">	            		
					  	<IconButton	
						  	className="material-table__toolbar-button"
						  	onClick={(e) => {e.preventDefault();e.stopPropagation();this.toggleDelete()}}						  
					  	>
					  	<DeleteIcon /> 
					  	</IconButton>
						</Tooltip>
						<Modal
						  isOpen={deleteModal}
						  toggle={(e) => {e.stopPropagation();this.toggleDelete()}}
						  modalClassName={`${rtl.direction}-support`}
						  className={`modal-dialog contact__modal share__modal`}
						>
						<div className="modal__header">
							<button className="lnr lnr-cross modal__close-btn" type="button" onClick={(e) => {e.stopPropagation();this.toggleDelete()}} />
						  </div>
						  <Row>
												  
							<Col xl={12} lg={12} md={12}>
								<div className="modal__body background_white">
									<h4 className="contact__title">Delete Your Share Page for {buildingNameOrAdr}
										{property && <span> - {_formatUnitNumber(property.address_2_normalized)}</span>}
									</h4>
									
									{property && 
										<h5 className="contact__sub-title">
											{property.offering_price && property.offering_price > 0 ? <Fragment>${_formatNumber(property.offering_price)}<span className="small-divider">|</span></Fragment> : null} 
											
											{property.bedrooms >=0 && property.baths>0 &&
												<Fragment>
													  {property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
													  <span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? ' Bed' : ' Beds'}</span>
													  <span className="small-divider">|</span>
													  {property.baths}
													  <span className="sub-text">{property.baths === 1 ? ' Bath' : ' Baths'}</span>
													  <span className="small-divider">|</span>
												</Fragment>
											}
											  {_formatNumber(property.sq_ft)}
											  <span className="sub-text"> Sq Ft</span>				              	
						
									   </h5>
										
									}
									<hr/> 
									<div style={{fontSize:16}} className="mb-3">
										<p>Once deleted, this report will no longer be accssible to anyone with the shared link.</p>
										<p>View: {shareLink && report.custom_link && <span><a href={shareLink} className="link-text" target="_blank">{shareLink}</a></span>} </p>
										<p>Are you sure you want to delete this report?</p>
									</div>
									<hr/>      
									{/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}				             
									 <ButtonToolbar className="modal__footer">
										   {/* <Field name="saveme" component={renderCheckbox} label={`Save this ${property ? 'Unit' : 'Building'}`} value="saveme" />*/}
										 <Tooltip title="Cancel">
											 <Button
												  onClick={this.toggleDelete}
												  variant="outlined"
												  color=""
												  className=""		         
												  startIcon={null}
											  >	Cancel
											  </Button>				 
										   </Tooltip>{' '}
										   
										   <Tooltip title="Confirm Delete of Report">
												<Button
													onClick={this.handleDelete}
													variant="contained"
													  color="secondary"
													  className="buyersList__primary-button"	         
													 startIcon={<DeleteIcon />}
												 >	Delete
												 </Button>				 
											  </Tooltip>{' '}
									  </ButtonToolbar>
								</div>	
												
							</Col>
								  
						  </Row>
						</Modal>
					</Fragment>
					:
					<Tooltip title={`Use AI to generate a summary of this ${property ? 'Unit' : 'Building'}`}>
						<Button
							 onClick={(e) => {e.stopPropagation();this.toggle()}}
							 variant="contained"
							 color="secondary"
							 size={small ? "small" : ""}
							 className="buyersList__secondary-button"		         
							 startIcon={!isMobile && <CogOutlineIcon />}
							 style={{minWidth:160}}
							 
						 >	AI Summary
						 </Button>				 
					</Tooltip>
				}
				
		    
		  	
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal share__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
				  <Row>
				  			        	
					<Col xl={12} lg={12} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">AI Generated Summary for {buildingNameOrAdr} 
								{property && <span> - {_formatUnitNumber(property.address_2_normalized)}</span>}
								<Badge className="sidebar__link-badge"><span>Beta</span></Badge>
							</h4>
							
							{property && 
								<h5 className="contact__sub-title">
									{property.offering_price && property.offering_price > 0 ? <Fragment>${_formatNumber(property.offering_price)}<span className="small-divider">|</span></Fragment> : null} 
									
									{property.bedrooms >=0 && property.baths>0 &&
										<Fragment>
							              	{property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
							              	<span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? ' Bed' : ' Beds'}</span>
							              	<span className="small-divider">|</span>
							              	{property.baths}
							              	<span className="sub-text">{property.baths === 1 ? ' Bath' : ' Baths'}</span>
							              	<span className="small-divider">|</span>
							            </Fragment>
							        }
					              	{_formatNumber(property.sq_ft)}
					              	<span className="sub-text"> Sq Ft</span>				              	

				               </h5>
					            
				            }

				            <hr/>      
				            {/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}				             
				             <AISummaryForm 
				             	buildingRes={buildingRes} 
				             	user={user} 
				             	property={property} 
				             	toggle={this.toggle} 
				             	listing={listing} 
				             	saved={saved} 
				             	handleGenerate={this.handleGenerate} 
				             	generateShareBase={this.generateShareBase} 
				             	generateShareLink={this.generateShareLink}
								report={this.props.report}
								fetchInProgress={fetchInProgress}
								disabled={disabled}
				             	/>
				        </div>	
				        	        	
					</Col>
					  	
				  </Row>
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, formValues: getFormValues(form)(state), rtl: state.rtl, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({change}, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AISummaryButton));
