/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import FullscreenIcon from 'mdi-react/FullscreenIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';



const CustomHeader = ({ innerProps, currentView, modalProps, isModal }) => {
	    console.log(innerProps, currentView);	
	    const download = () => {
		    var element = document.createElement("a");
		    var file = new Blob(
		      [
		        currentView.url
		      ],
		      { type: "image/*" }
		    );
		    element.href = URL.createObjectURL(file);
		    element.download = "floorplan.jpg";
		    element.click();
		};
	    if(!isModal){
	    	return null;
	    }else{  
		    return(
				<div className="react-images__custom-header">
					<Tooltip title={`Close`}>
						<IconButton onClick={modalProps.onClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={`Expand Fullscreen`}>
						<IconButton onClick={modalProps.toggleFullscreen}>
							<FullscreenIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={`Open In New Tab`}>
						<IconButton onClick={() => window.open(`${currentView.url}`)}>
							<OpenInNewIcon size={22} />
						</IconButton>
					</Tooltip>
				</div>			    		
		    );
	    }
	    
}



export default class Gallery extends PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
      alt: PropTypes.string,
    })).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      tag: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      images: props.images.filter( img => { return (props.match.params.subsubtab && props.match.params.subsubtab !== 'all') ? img.tag == props.match.params.subsubtab : true }),
      currentTag: props.match && props.match.params.subsubtab ? props.match.params.subsubtab : 'all',
      tags: props.tags,
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  onFilter = (tag, e) => {
    const { images, buildingRes } = this.props;
    e.preventDefault();
    if (tag === 'all') {
      const imgs = images;
      this.setState({
        images: imgs,
        currentTag: 'all',
      });
    } else {
      const imgs = images.filter(t => t.tag === tag);
     
		 
	  
      this.setState({
        images: imgs,
        currentTag: tag,
      });
    }
    
     const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tag ? `/tab/layout/diagrams/${tag}` : '');
	 window.history && window.history.pushState({}, null, buildingURL);
  };

  openLightbox = (index, event) => {
	  
	console.log(index, event);
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  gotoPrevious = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImage - 1 }));
  };

  gotoNext = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImage + 1 }));
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  handleClickImage = () => {
    const { currentImage } = this.state;
    const { images } = this.props;
    if (currentImage === images.length - 1) return;
    this.gotoNext();
  };

  render() {
    const {
      currentImage, lightboxIsOpen, tags, images, currentTag,
    } = this.state;

    return (
      <div className="gallery">
        <div className="gallery__btns">
          <button
            type="button"
            className={`gallery__btn${currentTag === 'all' ? ' gallery__btn--active' : ''}`}
            onClick={e => this.onFilter('all', e)}
          >all
          </button>
          {tags.map((btn, i) => (
            <button
              type="button"
              key={i}
              className={`gallery__btn${btn.tag === currentTag ? ' gallery__btn--active' : ''}`}
              onClick={e => this.onFilter(btn.tag, e)}
            >{btn.title}
            </button>
          ))}
        </div>
        {images.map((img, i) => (
          <div className="gallery__img-wrap" key={i} onClick={e => this.openLightbox(i, e)} style={{ backgroundImage : `url("${img.src}")` }}>
            {/*<img src={img.src} alt={img.alt} />*/}
            <div className="gallery_img-info">
            	<h5>{img.type === "floor" ? 	            	
	            	 	<Fragment>Floor{img.sub_tags && img.sub_tags.length > 1 ? 's ' : ' '}
	            	 	 <span className="light-text">{img.sub_tags && img.sub_tags.length > 1 ? img.sub_tags[0] + ' - ' + img.sub_tags[img.sub_tags.length-1] : _urlToName(img.type_id)}</span></Fragment>
	            	  : 
	            	    <Fragment>{img.type.replace(/-/g, ' ')} <span className="light-text">{_urlToName(img.type_id)}</span></Fragment>
	            	}
	             </h5>
            	
            </div>
          </div>
        ))}
        
        <ModalGateway>
	        {lightboxIsOpen ? (
	          <Modal onClose={this.closeLightbox}>
	            <Carousel 
	            	currentIndex={currentImage}
					views={images} 
					components={{ Header: CustomHeader }} 
	            />
	          </Modal>
	        ) : null}
	    </ModalGateway>
      {/*  <Lightbox
          currentImage={currentImage}
          images={images}
          isOpen={lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        />*/}
      </div>
    );
  }
}
