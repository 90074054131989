/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import EditIcon from 'mdi-react/PencilIcon';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	BuildingCurrentStatus,
	BuildingPhoto,
	SalesProgress
} from "../../../../../shared/helpers/utils";


const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

class RelatedBuildingItems extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      description: PropTypes.string,
      colors: PropTypes.arrayOf(PropTypes.string),
      new: PropTypes.bool,
    })),
  };

  static defaultProps = {
    items: [],
  };
  
  getBuildingLink = (item) => {
	 return _getBuildingURL(item);
  };

  render() {
    const { items, user } = this.props;

    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {items.map((item, i) => (
            <CardBody className="catalog-item" key={i}>
              <Link className="catalog-item__link" to={`${this.getBuildingLink(item)}`}>
                {/*item.new ? <img className="catalog-item__label" src={New} alt="new" /> : ''}
                {item.sale ? <img className="catalog-item__label" src={Sale} alt="sale" /> : ''*/}
               
                <div className="catalog-item__img-wrap">
                  {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
				  	<BuildingPhoto item={item} />
				  	
                </div> 
                <BuildingCurrentStatus item={item} />               
                <div className="catalog-item__info">
                   <h4 className="catalog-item__price">
                   	{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address))}
				   	{item.building_name && <div>{_capitalizeText(_replaceAbbreviations(item.display_full_street_address))}</div>}
				   	<div className="light-text small-text" style={{color:"#858AA1"}}>
	                  	{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
	                </div>	                  
                   </h4>
                   <h5 className="product-card__title">
		              	<div className="">{item.inventory_stats_listings_low_price > 100 ? `${_formatPrice(item.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(item.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</div>
		              	
		              	
		              	<span className="light-text small-text">{_formatNumber(item.total_floors)} Floors</span>
		              	<span className="small-divider">|</span>
		              	
		              	<span className="light-text small-text">{_formatNumber(item.inventory_total)} Units</span>
		            </h5>
		            <SalesProgress progress={parseFloat(item.inventory_pct_unsold).toFixed(0)} inventory_unsold={item.inventory_unsold} inventory_total={item.inventory_total} maxWidth={'90%'} label={'Unsold Units'} />

                  
                 
                 </div>
              </Link>
            </CardBody>
          ))}
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  user: state.user,
}))(withRouter(RelatedBuildingItems));
