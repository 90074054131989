import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import moment from 'moment';


import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import Profile from './../../../../SingleBuilding/components/Profile';

import AddToBuyersListButton from '../../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../../shared/components/buttons/ShareButton';

import ProductCard from './../../../../SingleBuilding/components/Profile/components/ProfileCard';
import ShowMap from './../../../../../shared/components/map';

import BuildingProductCard from './../../../../SingleBuilding/components/Profile/components/ProfileCard';
import BuildingAmenities from './../../../../SingleBuilding/components/Profile/components/BuildingAmenities';

import ShareTopBar from './../../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../../shared/components/ShareContact'
import ShareFooter from './../../../../../shared/components/ShareFooter'

import Button from '@material-ui/core/Button';
import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';
import UnitMatrix from './analysis/UnitMatrix';
import UnitList from './analysis/UnitList';
import BlendedMatrix from './analysis/BlendedMatrix';
import { withOktaAuth } from '@okta/okta-react';

import { Publisher } from './../../../../../shared/pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	_privateKey,
	_validUnitSources,
	propertyExportFL
} from '../../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../../shared/components/auth/sso/ssoutils';
const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;

const _ellimanUnitStatus = (unit) =>  {
	let eStatus = "Unclassified";
	let unitClone = Object.assign({}, unit)
	const status = unitClone.current_status;
	const statusMap = {
		"sold" : "Closed",
		"resale" : "Closed",
		"not_yet_recorded" : "Signed",
		"in contract" : "Signed",
		"active" : "On Market",
		"bulk sold" : "Bulk Sales"
	}
	
	
	if(statusMap[status]) eStatus = statusMap[status];
	
	if(eStatus == "Unclassified" && unit.times_sold >= 1){
		eStatus = "Closed";
	}
	return eStatus;
	
}

const _ellimanSortOrder = (eStatus) => {
	let ord = 5;
	const sortMap ={
		"Closed" : 1,
		"Bulk Sales" : 2,
		"Signed" : 3, 
		"On Market" : 4, 
		"Unclassified" : 6
	}
	if(sortMap[eStatus]) ord = sortMap[eStatus];
	return ord;
}
class BuildingMatrix extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
  };

  constructor(props) {
	super(props);
	this.state = {
	  activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
	  statsRes: null,
	  fetchInProgress: false,
	  buildingRes: this.props.sharePageData.building,
	  userRes:undefined
	};
	this.buildingSlug = false;
	this.urlState = {};
  }
  
  componentDidMount() {
	  
	const { sharePageData } = this.props;
	const { buildingRes } = this.state;
	if(sharePageData){
		this.buildingSlug = sharePageData.building_key;
		
		this.urlState = sharePageData.custom_data ? sharePageData.custom_data : {};
		//this._getBuildingOPData(buildingRes)
		this.setState({statsRes: buildingRes.property_stats.facets, unitsRes: buildingRes.property_stats.docs.slice()});
		
	}else{
		
		const urlLink = this.props.match.params && this.props.match.params.encrypted_key ? CryptoJS.AES.decrypt(atob(this.props.match.params.encrypted_key), _privateKey).toString(CryptoJS.enc.Utf8) : false;
		
		const queryParts = urlLink.split('?');
		const pathParts = queryParts[0].split('/');
		
		this.buildingSlug = pathParts[0] ? pathParts[0] : false;
		this.urlState = queryParts[1] ? queryString.parse(queryParts[1]) : false;
		
	   console.log(this.buildingSlug, this.urlState);
	   
		
	}
	
	//this._getBuildingData();
	//if(this.urlState.ap){
	//this._getUserData(this.urlState.ap);
	//}
	//Publisher.publish(`track.shareView.building`, {building: this.buildingSlug, label: this.buildingSlug});
	
	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {	
	 const { sharePageData } = this.props;
	 if(!prevState.buildingRes && this.state.buildingRes){
		Publisher.publish(`track-mp.shareView.building`, {building: this.state.buildingRes, user: sharePageData && sharePageData.user ? sharePageData.user : false, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true }); 
	 }	
	 
  }

  toggle = (tab) => {
	const { activeTab } = this.state;
	if (activeTab !== tab) {
	  this.setState({
		activeTab: tab,
	  });
	}
  };
  
  _getUserData = (key) => {
	 // console.log('_getBuildingData called', this.state)
	  const { sharePageData, user } = this.props;
	  if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
	  }
	  
	  if(!(sharePageData && sharePageData.user_key) && user){
		  this.setState({
				userRes: user,
				fetchInProgress: false
		  })
		  
		  return false;
	  }
			
	  let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${sharePageData && sharePageData.user_key ? sharePageData.user_key : key}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  if(res && res.errorCode && res.errorCode == "E0000007"){
				_getUser({id:sharePageData && sharePageData.user_key ? sharePageData.user_key : key}).then(ssoUser  => {
					console.log(ssoUser)
					if(ssoUser){
						this.setState({
							userRes:ssoUser,
							fetchInProgress: false
						})	
					}
					
				})
		 }else{
				  this.setState({
					  userRes:res.profile,
					  fetchInProgress: false
				  })
			}

	  })
	  .catch(error => {
		console.log("_getUserData error ", error);
		this.setState({
		  fetchInProgress: false
		})
	  });
	}

  
  _getBuildingData = () => {
	 // console.log('_getBuildingData called', this.state)
	  const { sharePageData } = this.props;
	  if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
	  }
			
	
/*
	  let buildingData = {
			url: apiURL + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: 'key:*-'+this.buildingSlug,
				fq: 'deleted:false',
				fl: buildingFL,
				wt: "json",
				rows: 1
			}
	  };
*/
	  
	  let buildingData = {
			url: process.env.API_ROOT + `/buildings/${this.buildingSlug}?type=minimum`,
			method: "get"
	  };
	  
	  _axiosCall(buildingData)
		.then(res => {
		 // console.log("_getBuildingData building res back ,", res);

		if(res && res && res.building && res.building[0]) {
		  this.setState({
			  buildingRes: res.building[0],
			  fetchInProgress: false
		  });
		  
		  
		  
		  this._getBuildingOPData(res.building[0]);

		}
	  })
	  .catch(error => {
		console.log("BlBuildingPage error ", error);
		this.setState({
		  fetchInProgress: false
		})
	  });
	}

	
  render() {
	const { rtl, sharePageData, isModal, pageNum} = this.props;
	const { buildingRes, statsRes, unitsRes, urlState, userRes } = this.state;
	
	const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
	}	
	
	const user = Object.assign({}, defaultUser, userRes);
	
	 if( !statsRes ){
		return <LinearLoading />;
	}
	
	const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const pages = [];
	const firstPage = 23;
	const subPage = 23;
	
	unitsRes.map(unit => {
		unit['elliman_status'] = _ellimanUnitStatus(unit);
	})
	
	const _sortBy = (a, b) => {
		if(_ellimanSortOrder(a.elliman_status) == _ellimanSortOrder(b.elliman_status) ){
			return a.bedrooms > b.bedrooms ? 1 : -1
		}else{
			return _ellimanSortOrder(a.elliman_status) > _ellimanSortOrder(b.elliman_status) ? 1 : -1;
		}
	}
	unitsRes.sort(_sortBy);
	
	let sponsorUnits = unitsRes.filter(unit => unit['times_sold'] <=1 && !(unit['times_sold']==1 && ['active', 'in contract', 'pending'].indexOf(unit['current_status'])>=0));
	let resaleUnits = unitsRes.filter(unit => unit['times_sold'] > 1 || (unit['times_sold']==1 && ['active', 'in contract', 'pending'].indexOf(unit['current_status'])>=0));
	
	console.log(unitsRes, resaleUnits)
	pages.push(<UnitList title={"Sponsor Unit List (Sorted by Status, Bedroom, and Price)"} unitData={sponsorUnits.slice(0, firstPage)} />);
	if(sponsorUnits.length > firstPage){
		for (let i = firstPage; i < sponsorUnits.length; i+=subPage) {	
			pages.push(<UnitList title={"Sponsor Unit List Continued (Sorted by Status, Bedroom, and Price)"} unitData={sponsorUnits.slice(i, i+subPage)} />);		
		}
	}
	if(resaleUnits.length>0){
		pages.push(<UnitList title={"Resale Unit List (Sorted by Status, Bedroom, and Price)"} unitData={resaleUnits.slice(0, firstPage)} />);
		if(resaleUnits.length > firstPage){
			for (let i = firstPage; i < resaleUnits.length; i+=subPage) {	
				pages.push(<UnitList title={"Resale Unit List Continued (Sorted by Status, Bedroom, and Price)"} unitData={resaleUnits.slice(i, i+subPage)} />);		
			}
		}
	}



	
	return (
		<Fragment>
		<div className={`pdf-page ${sharePageData && sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}`}>
		<ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />
		<div className={`share__wrapper ${isModal ? "is-modal" : ""}`}>
			
			<Container className="share dashboard">
							
				<Row>
					<Col md={12} lg={12} xl={12}>
						<Card style={{padding:0}}>
						  <CardBody className="no-border no-padding mg-btm-0"> 
							{!isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								<div className="top__toolbar" style={{display:"flex"}}>
									 <ContactDeveloperButton  buildingRes={buildingRes} share  />
							   </div>  
							}      	
							<div className="card__title" key={buildingRes.id}>							  
							   <h3 className="page-title">{buildingNameOrAdr}</h3>
							   {isMobile ?
								   <Fragment>
								  <h3 className="page-subhead subhead">			        	
										{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
										{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
								  </h3> 
								  
								  </Fragment>
							   :
								  <h3 className="page-subhead subhead">			        	
											{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>}{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
								  </h3>
							   }
							   </div>
							  
						  </CardBody>
						</Card>
					  </Col>
					 
				</Row>
				
				
				<Row>
				  <Col md={12}>
					  <UnitMatrix mixData={statsRes.sponsor_matrix} title="Sponsor Unit Matrix" stage="sponsor" overview/>			
				  </Col>
					 
				</Row>
				<div className="mt-5 mb-4"><hr/></div>
				<Row>
				  <Col md={12}>
					  <UnitMatrix mixData={statsRes.resale_matrix} title="Resale Unit Matrix" stage="resale" overview/>			
				  </Col>
					 
				</Row>  
				<div style={{marginBottom:30}}/>		
			   
				  
				
			<span className="page-num">Page {pageNum}</span>
			</Container>
			<ShareFooter sharePageData={sharePageData} />

		</div>
		</div>
		{pages.length>0 &&
			<Fragment>
			{pages.map((page, i) => {
				return <div className={`pdf-page ${sharePageData && sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}`}>
				<ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />
					<div className={`share__wrapper ${isModal ? "is-modal" : ""}`}>
					
					<Container className="share dashboard">
					<Row>
						<Col md={12} lg={12} xl={12}>
							<Card style={{padding:0}}>
							  <CardBody className="no-border no-padding mg-btm-0"> 
								{!isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
									<div className="top__toolbar" style={{display:"flex"}}>
										 <ContactDeveloperButton  buildingRes={buildingRes} share  />
								   </div>  
								}      	
								<div className="card__title" key={buildingRes.id}>							  
								   <h3 className="page-title">{buildingNameOrAdr}</h3>
								   {isMobile ?
									   <Fragment>
									  <h3 className="page-subhead subhead">			        	
											{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
											{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
									  </h3> 
									  
									  </Fragment>
								   :
									  <h3 className="page-subhead subhead">			        	
												{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>}{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
									  </h3>
								   }
								   </div>
								  
							  </CardBody>
							</Card>
						  </Col>
						 <span className="page-num">Page {pageNum+i+1}</span>
					</Row>
					
						{page}
						<span className="page-num">Page {pageNum+i+1}</span>
					</Container>
					<ShareFooter sharePageData={sharePageData} />
					</div>
				</div>
			})}
			
			</Fragment>
		}
		</Fragment>
	);
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(BuildingMatrix)));
