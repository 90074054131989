import React, { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import FeatureIcon from "mdi-react/CheckCircleOutlineIcon";
import NoFeatureIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";
import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _getCrossStreet,
  _isResidential,
  _isHouse,
  _formatNum,
  _getServiceLevel,
  _validateLink,
  _showFeatures,
  _ordinalSuffix,
  _titleCase,
  _formatDate,
  _formatOwnerName,
} from "../../../shared/helpers/utils";

const BuildingProfile = ({ buildingDetails, hood, boro }) => {
  const isResidential = _isResidential(buildingDetails);
  const isHouse = _isHouse(buildingDetails);

  let buildingOwnershipType =
    buildingDetails.ownership_type &&
    _shouldShow(buildingDetails.ownership_type)
      ? buildingDetails.ownership_type.toString().toLowerCase().trim()
      : false;

  const landUse = {
    1: "One & Two Family Buildings",
    2: "Multi-Family Walk-Up Buildings",
    3: "Multi-Family Elevator Buildings",
    4: "Mixed Residential & Commercial Buildings",
    5: "Commercial & Office Buildings",
    6: "Industrial & Manufacturing Buildings",
    7: "Transportation & Utility",
    8: "Public Facilities & Institutions",
    9: "Open Space & Outdoor Recreation",
    10: "Parking Facilities",
    11: "Vacant Land",
  };

  const lotType = {
    0: "Mixed or Unknown",
    1: "Block Assemblage - encompasses an entire block",
    2: "Waterfront -  bordering on a body of water",
    3: "Corner - bordering on two intersecting streets",
    4: "Through - connects two streets and fronts on both streets",
    5: "Inside Lot",
    6: "Interior - has no street frontage",
    7: "Island - entirely surrounded by water",
    8: "Alley -  too narrow to accommodate a building, usually 12 ft or less",
    9: "Submerged Land -  totally or almost completely submerged",
  };

  let bblDisplay = false;
  if (
    buildingDetails.block &&
    buildingDetails.block > 0 &&
    buildingDetails.lot > 0
  ) {
    let borough_code = buildingDetails.borough_code;
    let block = buildingDetails.block;
    let lot = buildingDetails.lot;
    if (buildingOwnershipType && !buildingOwnershipType.includes("condo")) {
      bblDisplay = (
        <span>
          <div>
            {block} | {lot}{" "}
            <Link
              to={{
                pathname: `https://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${borough_code}&block=${block}&lot=${lot}`,
              }}
              target="_blank"
              replace
              className="fa fa-external-link"
            >
              ACRIS <i aria-hidden="true" />
            </Link>
          </div>
        </span>
      );
    } else {
      bblDisplay = (
        <span>
          {block} | {lot}
        </span>
      );
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Col xl={12} lg={12} className="mb-0">
        <h3 className="section-title section-title--not-last project_page-heading">Overview</h3>
        <p className="popup_sub_heading">
          Current information about{" "}
          {_capitalizeText(buildingDetails.display_full_street_address)}
        </p>
        <Card>
          <CardBody className="no-border no-padding">
            <Grid className="profile-card__info" container>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingDetails &&
                      buildingDetails.display_full_street_address && (
                        <p
                          className={`${
                            buildingDetails.display_full_street_address
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Address:
                          <span className="project_details-text_style">
                            <Fragment>
                              {_capitalizeText(
                                buildingDetails.display_full_street_address
                              )}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails && buildingDetails.zip && (
                      <p
                        className={`${
                          buildingDetails.zip ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Zip Code:
                        <span className="project_details-text_style">
                          <Fragment>{buildingDetails.zip}</Fragment>
                        </span>
                      </p>
                    )}

                    {hood && (
                      <p className={`${hood ? "has-info" : "no-info"}`}>
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Neighborhood:
                        <span className="project_details-text_style">
                          <Fragment>
                            {hood === "nyc" ? (
                              "NYC"
                            ) : (
                              <span className="u_link">
                                {_capitalizeText(hood)}
                              </span>
                            )}
                          </Fragment>
                        </span>
                      </p>
                    )}

                    {boro && (
                      <p className={`${boro ? "has-info" : "no-info"}`}>
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Borough:
                        <span className="project_details-text_style">
                          <Fragment>
                            {boro === "new york" ? (
                              "New York"
                            ) : (
                              <span className="u_link">
                                {_capitalizeText(boro)}
                              </span>
                            )}
                          </Fragment>
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingOwnershipType && (
                      <p
                        className={`${
                          buildingOwnershipType ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Building Type:
                        <span className="project_details-text_style">
                          <Fragment>
                            {_capitalizeText(buildingOwnershipType)}
                          </Fragment>
                        </span>
                      </p>
                    )}

                    {buildingDetails.sq_ft &&
                      _shouldShow(buildingDetails.sq_ft) ? (
                        <p
                          className={`${
                            buildingDetails.sq_ft ? "has-info" : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Size:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.sq_ft
                                ? _formatNum(buildingDetails.sq_ft) + " sqft"
                                : "--"}
                            </Fragment>
                          </span>
                        </p>
                      ): null}

                    {buildingDetails && buildingDetails.lot_area && (
                      <p
                        className={`${
                          buildingDetails.lot_area ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Lot Size:
                        <span className="project_details-text_style">
                          <Fragment>
                            {buildingDetails.lot_area
                              ? _formatNum(buildingDetails.lot_area) + " sqft"
                              : "--"}
                          </Fragment>
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Col>

      <Col xl={12} lg={12} className="mb-0">
        <h3 className="section-title section-title--not-last project_page-heading">Size & Use</h3>
        <p className="popup_sub_heading">
          Building sizes, how the building is used, and the ratio between built
          floor area and total allowed floor area (FAR).
        </p>

        <Card>
          <CardBody className="no-border no-padding">
            <Grid className="profile-card__info" container>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    <p
                      className={`${
                        buildingDetails.building_frontage &&
                        buildingDetails.building_frontage > 1
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Building Dimensions:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.building_frontage &&
                          buildingDetails.building_frontage > 1
                            ? buildingDetails.building_frontage
                            : "--"}{" "}
                          ft x{" "}
                          {buildingDetails.building_depth &&
                          buildingDetails.building_depth > 1
                            ? buildingDetails.building_depth
                            : "--"}{" "}
                          ft
                        </Fragment>
                      </span>
                    </p>

                    {buildingDetails.total_building_floor_area &&
                      _shouldShow(
                        buildingDetails.total_building_floor_area
                      ) && (
                        <p
                          className={`${
                            buildingDetails.total_building_floor_area
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Building Sqft:
                          <span className="project_details-text_style">
                            <Fragment>
                              {_formatNum(
                                buildingDetails.total_building_floor_area
                              )}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails &&
                      buildingDetails.lot_frontage &&
                      buildingDetails.lot_frontage > 1 && (
                        <p
                          className={`${
                            buildingDetails.lot_frontage &&
                            buildingDetails.lot_frontage > 1
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Lot Dimensions:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.lot_frontage &&
                              buildingDetails.lot_frontage > 1
                                ? buildingDetails.lot_frontage
                                : "--"}{" "}
                              ft x{" "}
                              {buildingDetails.lot_depth &&
                              buildingDetails.lot_depth > 1
                                ? buildingDetails.lot_depth
                                : "--"}{" "}
                              ft
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails &&
                      buildingDetails.lot_area &&
                      _shouldShow(buildingDetails.lot_area) && (
                        <p
                          className={`${
                            buildingDetails.lot_area ? "has-info" : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Lot Sqft:
                          <span className="project_details-text_style">
                            <Fragment>{buildingDetails.lot_area}</Fragment>
                          </span>
                        </p>
                      )}
                    {buildingDetails && buildingDetails.built_far && (
                      <p
                        className={`${
                          buildingDetails.built_far ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Built FAR:
                        <span className="project_details-text_style">
                          <Fragment>
                            {buildingDetails.built_far > 0
                              ? buildingDetails.built_far
                              : "--"}
                          </Fragment>
                        </span>
                      </p>
                    )}

                    <p
                      className={`${
                        buildingDetails && buildingDetails.max_residential_far
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Max Residential FAR:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.max_residential_far > 0
                            ? buildingDetails.max_residential_far
                            : "--"}
                        </Fragment>
                      </span>
                    </p>

                    <p
                      className={`${
                        buildingDetails && buildingDetails.max_commercial_far
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Max Commercial FAR:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.max_commercial_far > 0
                            ? buildingDetails.max_commercial_far
                            : "--"}
                        </Fragment>
                      </span>
                    </p>

                    {buildingDetails && buildingDetails.max_facility_far && (
                      <p
                        className={`${
                          buildingDetails.max_facility_far
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Max Facility FAR:
                        <span className="project_details-text_style">
                          <Fragment>
                            {buildingDetails.max_facility_far > 0
                              ? buildingDetails.max_facility_far
                              : "--"}
                          </Fragment>
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingDetails &&
                      buildingDetails.total_building_floor_area && (
                        <p
                          className={`${
                            buildingDetails.total_building_floor_area
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Total Floor Area:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.total_building_floor_area
                                ? _formatNum(
                                    buildingDetails.total_building_floor_area
                                  ) + " sqft"
                                : "--"}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails &&
                      buildingDetails.residential_floor_area && (
                        <p
                          className={`${
                            buildingDetails.residential_floor_area
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Residential Floor Area:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.residential_floor_area
                                ? _formatNum(
                                    buildingDetails.residential_floor_area
                                  ) + " sqft"
                                : "--"}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    <p
                      className={`${
                        buildingDetails && buildingDetails.garage_floor_area
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Garage Floor Area:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.garage_floor_area
                            ? _formatNum(buildingDetails.garage_floor_area) +
                              " sqft"
                            : "--"}
                        </Fragment>
                      </span>
                    </p>

                    <p
                      className={`${
                        buildingDetails && buildingDetails.commercial_floor_area
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Commercial Floor Area:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.commercial_floor_area
                            ? _formatNum(
                                buildingDetails.commercial_floor_area
                              ) + " sqft"
                            : "--"}
                        </Fragment>
                      </span>
                    </p>

                    <p
                      className={`${
                        buildingDetails && buildingDetails.retail_floor_area
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Retail Floor Area:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.retail_floor_area
                            ? _formatNum(buildingDetails.retail_floor_area) +
                              " sqft"
                            : "--"}
                        </Fragment>
                      </span>
                    </p>

                    <p
                      className={`${
                        buildingDetails && buildingDetails.factory_floor_area
                          ? "has-info"
                          : "no-info"
                      }`}
                    >
                      <FeatureIcon className="has-info" />
                      <NoFeatureIcon className="no-info" />
                      Factory Floor Area:
                      <span className="project_details-text_style">
                        <Fragment>
                          {buildingDetails.factory_floor_area
                            ? _formatNum(buildingDetails.factory_floor_area) +
                              " sqft"
                            : "--"}
                        </Fragment>
                      </span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Col>

      <Col xl={12} lg={12} className="mb-0">
        <h3 className="section-title section-title--not-last project_page-heading">
          Classification
        </h3>
        <p className="popup_sub_heading">
          How this building is identified and classified by the NYC Dept of
          Buildings and Dept of Finance.
        </p>
        <Card>
          <CardBody className="no-border no-padding">
            <Grid className="profile-card__info" container>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingDetails &&
                      buildingDetails.bin &&
                      _shouldShow(
                        buildingDetails.bin &&
                          _shouldShow(buildingDetails.bin[0])
                      ) && (
                        <p
                          className={`${
                            buildingDetails.bin && buildingDetails.bin[0]
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Building ID (BIN):
                          <span className="project_details-text_style">
                            <Fragment>
                              {!buildingDetails.bin[0].includes("000000") ? (
                                <span>
                                  {buildingDetails.bin[0]}{" "}
                                  <Link
                                    to={{
                                      pathname: `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=${buildingDetails.bin[0]}`,
                                    }}
                                    target="_blank"
                                    className="fa fa-external-link"
                                  >
                                    DOB <i aria-hidden="true" />
                                  </Link>
                                </span>
                              ) : (
                                <span>Unassigned</span>
                              )}{" "}
                            </Fragment>
                          </span>
                        </p>
                      )}
                    {bblDisplay && _shouldShow(bblDisplay) && (
                      <p
                        className={`${
                          bblDisplay ? "has-info d-flex" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Block & Lot:
                        <span className="project_details-text_style">
                          <Fragment>{bblDisplay}</Fragment>
                        </span>
                      </p>
                    )}

                    {buildingDetails.bin &&
                      _shouldShow(buildingDetails.bin[0]) &&
                      buildingDetails.number_of_buildings && (
                        <p
                          className={`${
                            buildingDetails &&
                            buildingDetails.number_of_buildings
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Buildings on Lot:
                          <span className="project_details-text_style">
                            <Fragment>
                              {_formatNum(buildingDetails.number_of_buildings)}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {!isHouse &&
                      buildingDetails.bin_dwelling_units &&
                      buildingDetails.bin_dwelling_units > 0 &&
                      _shouldShow(buildingDetails.total_units) && (
                        <p
                          className={`${
                            buildingDetails.total_units ? "has-info" : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Total Units on Lot:
                          <span className="project_details-text_style">
                            <Fragment>
                              {_formatNum(buildingDetails.total_units) +
                                " (" +
                                _formatNum(
                                  buildingDetails.number_of_buildings
                                ) +
                                " Bldgs)"}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.lot_type_code &&
                    _shouldShow(buildingDetails.lot_type_code) ? (
                      <p
                        className={`${
                          buildingDetails.lot_type_code ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Lot Type:
                        <span className="project_details-text_style">
                          <Fragment>
                            {lotType[buildingDetails.lot_type_code]
                              ? lotType[buildingDetails.lot_type_code]
                              : buildingDetails.lot_type_code}
                          </Fragment>
                        </span>
                      </p>
                    ) : (
                      <p
                        className={`${
                          buildingDetails.lot_type_code ? "has-info" : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        <span className="project_details-text_style">
                          <>Corner Lot, </>
                          <>No</>
                        </span>
                      </p>
                    )}

                    {buildingDetails.irregular_lot_code &&
                      _shouldShow(buildingDetails.irregular_lot_code) && (
                        <p
                          className={`${
                            buildingDetails &&
                            buildingDetails.irregular_lot_code
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Irregularly Shaped Lot:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.irregular_lot_code}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.building_construction_style &&
                      _shouldShow(
                        buildingDetails.building_construction_style
                      ) &&
                      buildingDetails.building_era &&
                      parseInt(buildingDetails.building_era.slice(-4)) >=
                        buildingDetails.year_built && (
                        <p
                          className={`${
                            buildingDetails &&
                            buildingDetails.building_construction_style
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Architectural Style:
                          <span className="project_details-text_style">
                            <Fragment>
                              {_showFeatures(
                                buildingDetails.building_construction_style
                              )}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.building_material &&
                      _shouldShow(buildingDetails.building_material) &&
                      buildingDetails.building_era &&
                      parseInt(buildingDetails.building_era.slice(-4)) >=
                        buildingDetails.year_built && (
                        <p
                          className={`${
                            buildingDetails.building_material
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Building Material:
                          <span className="project_details-text_style">
                            <Fragment>
                              {_showFeatures(buildingDetails.building_material)}
                            </Fragment>
                          </span>
                        </p>
                      )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingDetails.building_class &&
                      _shouldShow(buildingDetails.building_class) && (
                        <p
                          className={`${
                            buildingDetails.building_class
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Building Class:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                to={{
                                  pathname:
                                    "https://www1.nyc.gov/assets/finance/jump/hlpbldgcode.html",
                                }}
                                target="_blank"
                                className="fa fa-external-link"
                              >
                                {buildingDetails.building_class}
                                <i
                                  aria-hidden="true"
                                  className="fa fa-question-circle-o u_pd-2px u_font-sm"
                                />
                              </Link>
                              {buildingDetails.building_class_description
                                ? " - " +
                                  _titleCase(
                                    buildingDetails.building_class_description
                                  )
                                : ""}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.tax_class &&
                      _shouldShow(buildingDetails.tax_class) && (
                        <p
                          className={`${
                            buildingDetails.tax_class ? "has-info" : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Tax Class:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                to={{
                                  pathname:
                                    "https://www1.nyc.gov/site/finance/taxes/property-tax-rates.page",
                                }}
                                target="_blank"
                                className="fa fa-external-link"
                              >
                                Class {buildingDetails.tax_class}
                                <i
                                  aria-hidden="true"
                                  className="fa fa-question-circle-o u_pd-2px u_font-sm"
                                />{" "}
                              </Link>
                              {buildingDetails.tax_class_description
                                ? " - " +
                                  _titleCase(
                                    buildingDetails.tax_class_description
                                  )
                                : ""}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingOwnershipType &&
                      _shouldShow(
                        buildingOwnershipType.includes("condo") &&
                          buildingDetails.condo_num
                      ) && (
                        <p
                          className={`${
                            buildingDetails && buildingDetails.garage_floor_area
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Condo Number:
                          <span className="project_details-text_style">
                            <Fragment>{buildingDetails.condo_num}</Fragment>
                          </span>
                        </p>
                      )}
                    {buildingOwnershipType &&
                      _shouldShow(
                        buildingOwnershipType.includes("coop") &&
                          buildingDetails.coop_num
                      ) && (
                        <p
                          className={`${
                            buildingDetails && buildingDetails.coop_num
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Coop Number:
                          <span className="project_details-text_style">
                            <Fragment>{buildingDetails.coop_num}</Fragment>
                          </span>
                        </p>
                      )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Col>

      <Col xl={12} lg={12} className="mb-0">
        <h3 className="section-title section-title--not-last project_page-heading">
          Zoning & Community
        </h3>
        <p className="popup_sub_heading">
          Zoning details, maps and community info.
        </p>
        <Card>
          <CardBody className="no-border no-padding">
            <Grid className="profile-card__info" container>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingDetails.block &&
                      _shouldShow(buildingDetails.block) && (
                        <p
                          className={`${
                            buildingDetails.block ? "has-info" : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Zoning & Land Use:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                to={{
                                  pathname: `https://zola.planning.nyc.gov/lot/${buildingDetails.borough_code}/${buildingDetails.block}/${buildingDetails.lot}`,
                                }}
                                target="_blank"
                                className="fa fa-external-link"
                              >
                                Land Use Map
                                <i
                                  aria-hidden="true"
                                  className="fa fa-external-link u_pd-5px u_font-sm"
                                />
                              </Link>
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {_shouldShow(buildingDetails.zoning_district_1) && (
                      <p
                        className={`${
                          buildingDetails.zoning_district_1
                            ? "has-info d-flex"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Zoning Districts:
                        <span className="project_details-text_style">
                          <Fragment>
                            <Link
                              to={{
                                pathname:
                                  "https://www1.nyc.gov/site/planning/zoning/districts-tools/" +
                                  buildingDetails.zoning_district_1
                                    .slice(0, 2)
                                    .toLowerCase() +
                                  ".page",
                              }}
                              target="_blank"
                              className="fa fa-external-link"
                            >
                              {buildingDetails.zoning_district_1}
                              <i
                                aria-hidden="true"
                                className="fa fa-question-circle-o u_pd-5px u_font-sm"
                              />
                            </Link>
                          </Fragment>
                        </span>
                      </p>
                    )}

                    {buildingDetails.commercial_overlay_1 &&
                      _shouldShow(buildingDetails.commercial_overlay_1) && (
                        <p
                          className={`${
                            buildingDetails &&
                            buildingDetails.commercial_overlay_1
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Commercial Overlay:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                to={{
                                  pathname: `https://www1.nyc.gov/site/planning/zoning/districts-tools/commercial-districts-c1-c8.page`,
                                }}
                                target="_blank"
                                className="fa fa-external-link"
                              >
                                {buildingDetails.commercial_overlay_1}
                                {buildingDetails.commercial_overlay_2 &&
                                _shouldShow(
                                  buildingDetails.commercial_overlay_2
                                )
                                  ? ", " + buildingDetails.commercial_overlay_2
                                  : ""}
                                <i
                                  aria-hidden="true"
                                  className="fa fa-question-circle-o u_pd-5px u_font-sm"
                                />
                              </Link>
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.zoning_map_num &&
                      _shouldShow(buildingDetails.zoning_map_num) && (
                        <p
                          className={`${
                            buildingDetails.zoning_map_num
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Historic Zoning Map:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                to={{
                                  pathname: `https://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/historical-zoning-maps/maps${
                                    buildingDetails.zoning_map_num.length ===
                                      2 &&
                                    buildingDetails.zoning_map_num[0] < 10
                                      ? "0" +
                                        buildingDetails.zoning_map_num.toLowerCase()
                                      : buildingDetails.zoning_map_num.toLowerCase()
                                  }`,
                                }}
                                target="_blank"
                                className="fa fa-external-link"
                              >
                                Historic Zoning Map
                                <i
                                  aria-hidden="true"
                                  className="fa fa-external-link u_pd-5px u_font-sm"
                                />
                              </Link>
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {_shouldShow(buildingDetails.land_use_category) && (
                      <p
                        className={`${
                          buildingDetails.land_use_category
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Land Use Category:
                        <span className="project_details-text_style">
                          <Fragment>
                            {landUse[
                              parseInt(buildingDetails.land_use_category)
                            ]
                              ? landUse[
                                  parseInt(buildingDetails.land_use_category)
                                ]
                              : buildingDetails.land_use_category}
                          </Fragment>
                        </span>
                      </p>
                    )}

                    {buildingDetails.school_district &&
                      _shouldShow(buildingDetails.school_district) && (
                        <p
                          className={`${
                            buildingDetails && buildingDetails.school_district
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          School District:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.school_district}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.split_zoning_boundary_indicator &&
                      _shouldShow(
                        buildingDetails.split_zoning_boundary_indicator
                      ) && (
                        <p
                          className={`${
                            buildingDetails &&
                            buildingDetails.split_zoning_boundary_indicator
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Split Zoning:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.split_zoning_boundary_indicator}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.zoning_map_code &&
                      _shouldShow(buildingDetails.zoning_map_code) && (
                        <p
                          className={`${
                            buildingDetails.building_material
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Zoning Map Code:
                          <span className="project_details-text_style">
                            <Fragment>
                              {buildingDetails.zoning_map_code}
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {false && buildingDetails.sanborn_map_num && (
                      <p
                        className={`${
                          buildingDetails.sanborn_map_num
                            ? "has-info"
                            : "no-info"
                        }`}
                      >
                        <FeatureIcon className="has-info" />
                        <NoFeatureIcon className="no-info" />
                        Sanborn Map Num:
                        <span className="project_details-text_style">
                          <Fragment>{buildingDetails.sanborn_map_num}</Fragment>
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={12}>
                <div className="product-summary__info project-product-summary_info">
                  <div>
                    {buildingDetails.community_district &&
                      _shouldShow(buildingDetails.community_district) && (
                        <p
                          className={`${
                            buildingDetails.community_district
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Community District:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                className="fa fa-external-link"
                                to={{
                                  pathname:
                                    "https://communityprofiles.planning.nyc.gov/" +
                                    buildingDetails.borough.toLowerCase() +
                                    "/" +
                                    parseInt(
                                      buildingDetails.community_district
                                        .toString()
                                        .slice(1, 3)
                                    ),
                                }}
                                target="_blank"
                              >
                                {_titleCase(buildingDetails.borough)}{" "}
                                {parseInt(
                                  buildingDetails.community_district
                                    .toString()
                                    .slice(1, 3)
                                )}
                                <i
                                  aria-hidden="true"
                                  className="fa fa-question-circle-o u_pd-5px u_font-sm"
                                />
                              </Link>
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.council &&
                      _shouldShow(buildingDetails.council) && (
                        <p
                          className={`${
                            buildingDetails.council ? "has-info" : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          City Council:
                          <span className="project_details-text_style">
                            <Fragment>
                              <Link
                                to={{
                                  pathname: `https://council.nyc.gov/district-${buildingDetails.council}`,
                                }}
                                target="_blank"
                                className="fa fa-external-link"
                              >
                                District {buildingDetails.council}
                                <i
                                  aria-hidden="true"
                                  className="fa fa-question-circle-o u_pd-5px u_font-sm"
                                />
                              </Link>
                            </Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.census_tract &&
                      _shouldShow(buildingDetails.census_tract) && (
                        <p
                          className={`${
                            buildingDetails && buildingDetails.census_tract
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Census Block:
                          <span className="project_details-text_style">
                            <Fragment>{buildingDetails.census_tract}</Fragment>
                          </span>
                        </p>
                      )}

                    {buildingDetails.census_block &&
                      _shouldShow(buildingDetails.census_block) && (
                        <p
                          className={`${
                            buildingDetails && buildingDetails.census_block
                              ? "has-info"
                              : "no-info"
                          }`}
                        >
                          <FeatureIcon className="has-info" />
                          <NoFeatureIcon className="no-info" />
                          Census Block:
                          <span className="project_details-text_style">
                            <Fragment>{buildingDetails.census_block}</Fragment>
                          </span>
                        </p>
                      )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default BuildingProfile;
