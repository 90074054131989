import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Modal, ButtonToolbar, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import axios from "axios";
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../../redux/actions/buyersListActions';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LocationIcon from 'mdi-react/MapMarkerIcon';
import InfoIcon from 'mdi-react/NotebookOutlineIcon';
import ContactIcon from 'mdi-react/BookmarkPlusOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import RobotIcon from 'mdi-react/RobotIcon';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { getFormValues, change } from 'redux-form';

import AIAssistantForm from './AIAssistantForm';
import SnackBar from '../../../../shared/components/SimpleSnackBar';

import { Publisher } from './../../../pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_slugifyText,
	setLocalStorage,
	_formatCurrency,
	_getFeaturesArr,
	_formatNumberDecimal
} from '../../../../shared/helpers/utils';
import { amenitiesList } from "../../../../shared/helpers/bp-utils.js";
import { userEditFieldsObj } from "../../../../shared/helpers/userEditFields.js";

import{
	_axiosCall
} from '../../../../shared/helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/'; //'http://localhost:8000/buyerslist/';
const form = 'ai_summary_form';
class AIAssistantButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { 
	    buttonText: 'Contact',
	    modal: false,
	    saved: false,
	    link_id: this.props.linkID ? this.props.linkID : false,
	    snackShow: false,
	    snackMessage: false,
	    snackType: 'success',
		deleteModal: false,
		fetchInProgress:false,
		aiExtractionProgress:false,
		streamvalue : "",
		hasGen: false
	    
	};
	this.toggle = this.toggle.bind(this);
	this.handleGenerate = this.handleGenerate.bind(this);
	this.extractFeatures = this.extractFeatures.bind(this);
    
  }
  
  async extractFeatures() {
	 // event.preventDefault();
	   const { authState, authService, dispatch, user, formValues, buildingRes, property, listing, actions, transactionRes, statsRes, activityStatsRes, buildingListing } = this.props;
	   const { link_id } = this.state;
	   this.setState({aiExtractionProgress:true});
	 
	   let prompt = `The following is a real estate listing description from a random unit in a building.  Extract only the in-unit appliances and finishes that are common to all units in this building, ignore any that indicates renovation.  Extract only the top 10 as a distinct list of property feature keywords in csv format. Do not include anything else.`;
	   if(buildingRes.building_status != "sponsor"){
		   prompt = `The following is a real estate listing description from a random unit in a building.  Extract only amenities and finishes that are common to all units in this building, ignore any in-unit features.  Extract only the top 10 as a distinct list of property feature keywords in csv format. Do not include anything else.`;
	   }
	   
	   prompt += " Don’t justify your answers. Use facts.";
	
	   
	   let systemContent = `You are a real estate agent.`;
	   
	   if(listing && listing.description && listing.description.length > 50){
		   prompt += "\n\n";
		   prompt += `"${listing.description}"`;
	   }else if(buildingListing && buildingListing.description && buildingListing.description.length > 50){
		   prompt += "Ignore outdoor space.  Ignore number of bedrooms.  Ignore number of baths.  Ignore size of the unit.\n\n";
		   prompt += `"${buildingListing.description}"`;
	   }else{
		   this.setState({aiExtractionProgress:false});
		   return false;
	   }
	   
	   const queryData = {
		   "messages" : [
			   {role : "system", content : systemContent},
				{role : "user", content : prompt},
		   ],
		   "temperature" : .5
		   //"max_tokens" : 999,
		   //"model" : formValues.aiModel ? formValues.aiModel : "gpt-3.5-turbo"
	   }
	 
	   let apiData = {
			  url: apiUrl+'ai/stream',
			  method: "post",
			  query: queryData
			  };
			  
		  const res = await fetch(apiUrl+'ai/stream', {
			  method: 'POST',
			  headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			  },
			  body: JSON.stringify(queryData)
		  })
		  // Create a reader for the response body
		  const reader = res.body.getReader();
		  // Create a decoder for UTF-8 encoded text
		  const decoder = new TextDecoder("utf-8");
		  let result = "";
		  // Function to read chunks of the response body
		  const readChunk = async () => {
			  return reader.read().then(({ value, done }) => {
				  if (!done) {
					  let dataString = decoder.decode(value);
					  //console.log(dataString);
					  if(dataString){
						  
						  dataString = "["+dataString.replace(/}\{/g, "},{") + "]";
						  try{
							  const list = JSON.parse(dataString.toString().trim());
							  let commaCount = 0;
							 
							  list.forEach(data => {
								  if (data.error) {
									  console.error("Error while generating content: " + data.message);
								  } else {
									  result = data.streamHead ? data.text : result + data.text;
									  
									  if((result.match(/,/g)|| []).length > commaCount){
										  commaCount = (result.match(/,/g)|| []).length;
										  const highlights = result.split(",").map(item => {
											  
											  let str = item.trim();
											  let i = str.indexOf(":");
											  if (i !== -1) {
												str = str.substr(i + 1).trim();
											  }
											  str = str.replace(/undefined/g , "");
											  str = str.replace(/"/g , "");
											  return _capitalizeText(str.trim())
										  
										  }).filter(item => item !== "");
										  console.log(highlights);
										  actions.change(form, 'highlights', highlights);
									  }
									  
									  return readChunk();
								  }
							  })
							  
						  }catch(e){
							  console.log(e);
							  this.setState({aiExtractionProgress:false});
						  }
					  }
					  
				  } else {
					  console.log("done");
					  this.setState({aiExtractionProgress:false});
				  }
			  });
		  };
		  
		  await readChunk();

	  
	}
  
   async handleGenerate(preview) {
   // event.preventDefault();
     const { authState, authService, dispatch, user, formValues, buildingRes, property, listing, actions, transactionRes, statsRes, activityStatsRes, buildingListing, taxAbatement } = this.props;
     const { link_id } = this.state;
	 this.setState({fetchInProgress:true, hasGen:false});
	 this.startGen = true;
	 const buildingFieldMap = {
		 "name" : { name: "Building Name", formatter : false } ,
		 "display_full_street_address" :  { name: "Address", formatter : false } ,
		 "neighborhood" :  { name: "Neighborhood", formatter : false } ,
		 "architect" :  { name: "Architect", formatter : false } ,
		 "total_floors" :  { name: "Total Floors", formatter : false } ,
		 "year_built" :  { name: "Year Built", formatter : false } ,		 
		 "developers" :  { name: "Developer", formatter : false } ,
		 "interior_designers" :  { name: "Interior Designer", formatter : false } ,
		// "manager" :  { name: "Property Mananger", formatter : false } ,
		 
	 }
	 const propertyFieldMap = {
		  "address_2_normalized" : { name: "Unit Number", formatter : _formatUnitNumber } ,
		  "floor" : { name: "Floor", formatter : false } ,
		  "bedrooms" : { name: "Bedrooms", formatter : (val) => val == 0 ? "Studio" : val } ,
		  "baths" :  { name: "Bathrooms", formatter : false } ,
		  "sq_ft" :  { name: "Interior Square Feet", Feet : _formatNumber } ,
		  "exterior_sq_ft" :  { name: "Exterior Squre Feet", formatter : _formatNumber },
		//  "monthly_fees" :  { name: "Unit Common Charges", formatter : _formatCurrency },
		//  "monthly_taxes" :  { name: "Unit Taxes", formatter : _formatCurrency }
	  }
	 // if(buildingRes["building_status"] == "sponsor"){		 
		//  buildingFieldMap["building_status"] = { name: "Building Sales Stage", formatter : (val => val + " Stage") };
	 // }
	 if(buildingRes["year_converted"] > buildingRes["year_built"]){		 
		  buildingFieldMap["year_converted"] = { name: "Year Converted", formatter : false };
	  }
	  if(buildingRes['inventory_total'] && buildingRes['inventory_total'] > 1){
		  buildingFieldMap["inventory_total"] = { name: "Total Units", formatter : false };
	  }else if(buildingRes['total_units_residential'] && buildingRes['total_units_residential'] > 1){
		  buildingFieldMap["total_units_residential"] = { name: "Total Units", formatter : false };
	  }else if(buildingRes['total_units'] && buildingRes['total_units'] > 1){
			buildingFieldMap["total_units"] = { name: "Total Units", formatter : false };
	  }
	
	
	 
	 let promptData = [];
	 
	 if(property){
		  Object.keys(property).map(key => {
			   if(propertyFieldMap[key]){
				   
				   const val = Array.isArray(property[key]) && property[key][0] ? property[key][0] : property[key];			 
				   if(val && _shouldShow(val)){
					   promptData.push(`${propertyFieldMap[key].name}=${propertyFieldMap[key].formatter ? propertyFieldMap[key].formatter(val) : val}`);
				   }
			   }
		   })
	  }
	 
	 Object.keys(buildingRes).map(key => {
		 if(buildingFieldMap[key]){
			 
			 const val = Array.isArray(buildingRes[key]) && buildingRes[key][0] ? buildingRes[key][0] : buildingRes[key];			 
			 if(val && _shouldShow(val)){
				 promptData.push(`${buildingFieldMap[key].name}=${buildingFieldMap[key].formatter ? buildingFieldMap[key].formatter(val) : val}`);
			 }
		 }
	 })
	 const compiledFeatureList = _getFeaturesArr('building', false, buildingRes);
	  const highlightedAmenities = amenitiesList.filter(item => compiledFeatureList.some(el => item.values.includes(el))).map(el => el.name);
	  
	  promptData.push(`Building Amenities=${highlightedAmenities.join(", ")}`);
	  
	  if(formValues && formValues['highlights'] && formValues['highlights'].length>0){
		  promptData.push(`Key Unit Features=${formValues['highlights'].join(", ")}`);
	  }
	  
	  if(taxAbatement && taxAbatement[0]){
		  const curYear = new Date().getFullYear();
		  if(taxAbatement[0]['program_421a']){
			  const expYear = parseInt(moment(taxAbatement[0]['program_421a_end_date']).format('YYYY'));
			  if(expYear > curYear){
				  promptData.push(`Tax Abatement=421a Until ${expYear}`);
			  }
		  }else if(taxAbatement[0]['program_j51']){
				const expYear = parseInt(moment(taxAbatement[0]['program_j51_end_date']).format('YYYY'));
				if(expYear > curYear){
					promptData.push(`Tax Abatement=J51 Until ${expYear}`);
				}
			}
	  }
	  

	

	 let prompt = `Write a real estate listing description in a ${formValues && formValues['tone'] ? formValues['tone'] : "neutral"} tone in ${formValues && formValues['length'] ? formValues['length'] : "400"} words.`;
	 
	 if(formValues.userPrompt && formValues.userPrompt != ""){
		 prompt = formValues.userPrompt;
	 }

	 //prompt += " Don’t justify your answers. Don’t give information not mentioned in the Marketproof Data.";
	 prompt += " Don’t justify your answers.  Don't summarize. Include the address. Use facts.  Prioritize and use all the information provided in the Marketproof Data, especially Key Unit Features.";
	 
	 if(buildingRes["building_status"] == "sponsor"){
		if (moment(buildingRes["building_effective_start"]).year() > 1970 || moment(buildingRes["first_closing_date"]).year() > 1970) {
			 prompt += ` Add to the response: "Immediate closings."`;
					 
		 }
		 if(buildingRes["inventory_pct_unsold"]){
			 if(buildingRes["inventory_pct_unsold"] < 50 && buildingRes["inventory_pct_unsold"] > 15){
				 prompt += ` Add to the response: "Over 50% sold."`;
			 }else if(buildingRes["inventory_pct_unsold"] <= 15){
				 prompt += ` Add to the response: "One of the last remaining units."`;
			 }
		 }
		 if(buildingRes['offering_file_number'] && buildingRes['offering_file_number'] != ""){
			 prompt += ` Append the following to the end: "The complete Offering Terms are in an Offering Plan available from Sponsor. File No. ${buildingRes['offering_file_number'].substring(0,2).toUpperCase()} ${buildingRes['offering_file_number'].substring(2,4)}-${buildingRes['offering_file_number'].substring(4)}."`;
		 }
		
		
		
			  
	  }
	
	 
	 let systemContent = `You are a real estate expert, use the following Marketproof Data about a property.\n\n${promptData.join("\n")}`;
	 
	 if(!formValues['highlights'] || formValues['highlights'].length < 3){
		 if(listing && listing.description){
			  systemContent += "\n\nExtract only unit appliances, finishes and building amenities from the following for text but always prioritize Marketproof Data when available.  Don’t justify your answers.";
			  systemContent += "\n\n";
			  systemContent += `"${listing.description}"`;
		  }else if(buildingListing && buildingListing.description){
			  systemContent += "\n\nExtract only unit appliances (ignore any that indicates renovation), finishes and building amenities from the following for text but always prioritize Marketproof Data when available.  Don’t justify your answers.";
			   systemContent += "\n\n";
			   systemContent += `"${buildingListing.description}"`;
		  }
	 }
	 
     
	 const queryData = {
		 "messages" : [
			 {role : "system", content : systemContent},
		 	 {role : "user", content : prompt},
		 ],
		 "temperature" : .5
		 //"max_tokens" : 999,
		 //"model" : formValues.aiModel ? formValues.aiModel : "gpt-3.5-turbo"
	 }
   
	 let apiData = {
			url: apiUrl+'ai/stream',
			method: "post",
			query: queryData
	  	  };
			
		const res = await fetch(apiUrl+'ai/stream', {
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(queryData)
		})
		// Create a reader for the response body
		const reader = res.body.getReader();
		// Create a decoder for UTF-8 encoded text
		const decoder = new TextDecoder("utf-8");
		let result = "";
		// Function to read chunks of the response body
		const readChunk = async () => {
			return reader.read().then(({ value, done }) => {
				if (!done) {
					let dataString = decoder.decode(value);
					//console.log(dataString);
					if(dataString){
						
						dataString = "["+dataString.replace(/}\{/g, "},{") + "]";
						try{
							const list = JSON.parse(dataString.toString().trim());
							
							list.forEach(data => {
								if (data.error) {
									console.error("Error while generating content: " + data.message);
								} else {
									result = data.streamHead ? data.text : result + data.text;
									let summary = result.replace(/undefined/g, "").replace(/^\n\n/, '').replace(/(?:\n\n|\r|\n)/g, '<br/>');
									this.setState({streamvalue: summary})
									//actions.change(form, 'summary', summary);
									return readChunk();
								}
							})
							
						}catch(e){
							//console.log(e);
							this.setState({fetchInProgress:false});
						}
					}
					
				} else {
					
					
					
					if(this.startGen){
						
						actions.change(form, 'summary', this.state.streamvalue);
						let customData = Object.assign({}, this.props.formValues);
						const fieldsToTrack = ['summary', 'taskType', 'length', 'tone', 'highlights'];
						Object.keys(customData).forEach(key => {
							if(fieldsToTrack.indexOf(key) < 0){
								delete customData[key];
							}
						})
						console.log("done", customData);
						Publisher.publish(`track-mp.aiUnitTask`, {user : user, building: buildingRes, property: property, customData: customData});
					}
					this.startGen = false;
				
					
					
					this.setState({fetchInProgress:false, hasGen:true});
				}
			});
		};
		
		await readChunk();

    
  }
  
 
  snackOpen = (message, type) => {
		const { snackOpen } = this.props;
		
		if(snackOpen){
			snackOpen(message, type)
		}else{
			this.setState( { snackShow: true, snackMessage: message, snackType : type} )
		}
	};
	
	snackClose = (event, reason) => {
	    const { snackClose } = this.props;
		event && event.stopPropagation();
		if (reason === 'clickaway') {
		  return;
		}	
		
		if(snackClose){
			snackClose(event, reason);
		}else{
			this.setState( { snackShow: false, snackMessage: '' } )
		}
	};
	


  toggle() {
	const { buildingRes, property, toggleRefresh, report, user } = this.props;
	
	const { modal } = this.state;
	
	if(!modal){
		this.extractFeatures();
		let customData = Object.assign({}, this.props.formValues);
		const fieldsToTrack = ['summary', 'taskType', 'length', 'tone', 'highlights'];
		Object.keys(customData).forEach(key => {
			if(fieldsToTrack.indexOf(key) < 0){
				delete customData[key];
			}
		})
		//console.log("done", customData);
		Publisher.publish(`track-mp.aiUnitTaskStart`, {user : user, building: buildingRes, property: property, customData: customData});
	}
    this.setState(prevState => ({ modal: !prevState.modal, hasGen:false }));
    
    
  }
  
 
  
  generateShareBase = ( ) => {		 
	 
	 //let shareBaseURL = `https://newdev.marketproof.com/share/`	 
	 let shareBaseURL = window.location.protocol + "//" + window.location.host + "/share/"
	 
	 return shareBaseURL;
  }
  generateShareLink = ( ) => {		 
	
	 const { buildingRes, property, formValues, initialValues, generateShareBase } = this.props;
	 
	 
	 let shareBaseURL = this.generateShareBase();
	 
	 if(formValues && formValues.customLink){
		 return shareBaseURL + formValues.customLink; 
	 }else if(initialValues && initialValues.customLink){
		 return shareBaseURL + initialValues.customLink; 
	 }
	 
	 return shareBaseURL; // + formValues.customLink;
}
  
  

  render() {
    const {
      actions, t, theme, rtl, buildingRes, user, property, listing, report, shareLink, small, transactionRes, statsRes
    } = this.props;
	
	const disabled = !property && (!buildingRes || !transactionRes || !statsRes);
	//if(disabled) return null;
    
    const { modal, snackShow, snackMessage, saved, snackType, deleteModal, fetchInProgress, aiExtractionProgress, streamvalue, hasGen} = this.state;
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;

	let salesOffice = false;
	
	try{
		salesOffice = JSON.parse(buildingRes.sales_office_details);
	}catch(e){
		
	}

    return (
	    <Fragment>
	    	<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
				
				<Tooltip title={`Use the Marketproof AI Assistant to write a listing description for this ${property ? 'unit' : 'building'}`}>
					<Button
						 onClick={(e) => {e.stopPropagation();this.toggle()}}
						 variant="contained"
						 color="secondary"
						 size={small ? "small" : ""}
						 className="buyersList__secondary-button"		         
						 startIcon={!isMobile && <RobotIcon />}
						 style={{minWidth:isMobile ? 130 : 160}}
						 
					 >	AI Assistant
					 </Button>				 
				</Tooltip>
				
				
		    
		  	
		  	<Fragment>
		        <Modal
		          isOpen={modal}
		          toggle={this.toggle}
		          modalClassName={`${rtl.direction}-support`}
		          className={`modal-dialog contact__modal share__modal`}
		        >
		        <div className="modal__header">
		            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
		          </div>
				  <Row>
				  			        	
					<Col xl={12} lg={12} md={12}>
						<div className="modal__body background_white">
							<h4 className="contact__title">Marketproof AI Assistant 
								<Badge className="sidebar__link-badge" style={{marginTop:-3}}><span>Beta</span></Badge>
							</h4>
							
							<h5>
							 {buildingNameOrAdr} 
							{property && <span> - {_formatUnitNumber(property.address_2_normalized)}</span>}
							</h5>
							{property && 
								<h5 className="contact__sub-title">
									{property.offering_price && property.offering_price > 0 ? <Fragment>${_formatNumber(property.offering_price)}<span className="small-divider">|</span></Fragment> : null} 
									
									{property.bedrooms >=0 && property.baths>0 &&
										<Fragment>
							              	{property.bedrooms === 0 ? 'Studio' : property.bedrooms} 
							              	<span className="sub-text">{property.bedrooms === 0 ? '' : property.bedrooms === 1 ? ' Bed' : ' Beds'}</span>
							              	<span className="small-divider">|</span>
							              	{property.baths}
							              	<span className="sub-text">{property.baths === 1 ? ' Bath' : ' Baths'}</span>
							              	<span className="small-divider">|</span>
							            </Fragment>
							        }
					              	{_formatNumber(property.sq_ft)}
					              	<span className="sub-text"> Sq Ft</span>				              	

				               </h5>
					            
				            }

				            <hr/>      
				            {/*<ContactForm buildingRes={buildingRes} user={user} property={property} toggle={this.toggle} />*/}				             
				             <AIAssistantForm 
				             	buildingRes={buildingRes} 
				             	user={user} 
				             	property={property} 
				             	toggle={this.toggle} 
				             	listing={listing} 
				             	saved={saved} 
				             	handleGenerate={this.handleGenerate} 
				             	generateShareBase={this.generateShareBase} 
				             	generateShareLink={this.generateShareLink}
								report={this.props.report}
								fetchInProgress={fetchInProgress}
								aiExtractionProgress={aiExtractionProgress}
								disabled={disabled}
								streamvalue={streamvalue}
								hasGen={hasGen}
								{...this.props}
				             	/>
				        </div>	
				        	        	
					</Col>
					  	
				  </Row>
		        </Modal>
		    </Fragment>

		</Fragment>
    );
  }
}

function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, formValues: getFormValues(form)(state), rtl: state.rtl, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({change}, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AIAssistantButton));
