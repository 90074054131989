import React, { useState, useRef, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import { Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToolbar } from 'reactstrap';
import ExportView from './ExportView';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import FileExportIcon from 'mdi-react/FileImportIcon';
import ExportSettingsForm from './ExportSettingsForm';
import { Publisher } from '../../../../shared/pubsub/publisher.js';
import LinearLoading from "../../../../shared/components/LinearLoading";
import FileDocumentEditOutlineIcon from 'mdi-react/FileDocumentEditOutlineIcon';

import { _axiosCall } from '../../../../shared/helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/';
const exportAPIURL = process.env.MP_EXPORT_SERVER;

function formatDate() {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

const dateFormatted = formatDate();
const sanitizeResults = (result, paragraphs) => {
  let sanitized = result.replace(/undefined/g, "");

  sanitized = sanitized.replace(/^\n+/, '');
  sanitized = sanitized.replace(/^### (.*$)/gim, '<h3>$1</h3>');
  sanitized = sanitized.replace(/^## (.*$)/gim, '<h2>$1</h2>');
  sanitized = sanitized.replace(/^# (.*$)/gim, '<h1>$1</h1>');
  sanitized = sanitized.replace(/\*\*(.*)\*\*/gim, '<b>$1</b>');
  sanitized = sanitized.replace(/\*(.*)\*/gim, '<i>$1</i>');
  sanitized = sanitized.replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>');

  if (paragraphs) {
  sanitized = sanitized.replace(/(?:\n|\r|\n\n)+/g, '</p><p>');
  sanitized = `<p>${sanitized}</p>`;
  } else {
  sanitized = sanitized.replace(/(?:\n|\r|\n\n)+/g, '');
  }

  return sanitized;
};
const ExportModal = (props) => {
  const { html, user, parentFetchInProgress, getUnitList, setDetails, reportParams, renderListViewHTML, renderStatsHTML} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState("");
  const [orientation, setOrientation] = useState("portrait");
  const [preview, setPreview] = useState(false);
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  
  const [aiRun, setAiRun] = useState(false); // State to track if handleAI has been run
  const [aiInProgress, setAIInProgress] = useState(false);
  
  const [unitListProgress, setUnitListProgress] = useState(false);
  const previewContainerRef = useRef(null);

  
  const defaultValues = {
    reportTitle: "",
    summary: "",
    details: 'no-details',
    branding: user && user.logo ? "mylogo" : "marketproof",
    contact: "hide"
  };
  
  
  const [formValues, setFormValues] = useState(defaultValues);
  const progressRef = useRef("");
  const iframeRef = useRef(null);
  
  const setIframeContent = (height) => {
    const iframe = iframeRef.current;
    if(iframe){
      const iframeDoc = iframe.contentDocument;
      iframe.style.height = `${height}px`
    }
    
  };
  
  const styles = Array.from(
    document.querySelectorAll('style, link[rel="stylesheet"]')
  )
    .map((style) => {
      let clonedStyle = style.cloneNode(true);
      if (clonedStyle.tagName.toLowerCase() === "link") {
        const linkElement = clonedStyle;
        const href = linkElement.getAttribute("href");
        const absoluteUrl = new URL(href, document.baseURI).href;
        linkElement.setAttribute("href", absoluteUrl);
      }
      return clonedStyle.outerHTML;
    })
    .join("\n");

  const ReportHeader = () => {
    return (
      <div className="header page-section">
        <div className="export-header">
          <div>
            {formValues &&
              formValues.branding &&
              formValues.branding === "mylogo" &&
              user &&
              user.logo ? (
              <img src={user.logo} style={{ maxHeight: 70 }} />
            ) : formValues &&
              formValues.branding &&
              formValues.branding === "marketproof" ? (
              <img
                src="https://marketproof-hosting.s3.amazonaws.com/logos/marketproof-logo-horizontal-transparent-background.png"
                style={{ maxHeight: 70 }}
              />
            ) : null}
          </div>
          <div>{ReportContact()}</div>
        </div>
      </div>
    );
  };

  const ReportTitle = () => {
    if (!formValues || !formValues.reportTitle) return null;
    return (
      <div className="page-section">
        <div className="report-title">
          <h2>{formValues.reportTitle}</h2>
        </div>
      </div>
    );
  };

  const ReportSummary = () => {
    if (!formValues || !formValues.summary) return null;
    return (
      <div className="page-section">
        <div className="report-summary">
          <div
            className="report-content"
            dangerouslySetInnerHTML={{ __html: formValues.summary }}
          />
        </div>
      </div>
    );
  };

  const ReportFooter = () => {
    return (
      <div className="page-section">
        <div className="report-footer">
          Data by{" "}
          <a
            className="footer__logo"
            href={"https://marketproof.com/"}
            target="_blank"
          >
            <img
              src="https://marketproof-hosting.s3.amazonaws.com/logos/marketproof-logo-horizontal-transparent-background.png"
              style={{ width: 100 }}
            />
          </a>
          {" | "}
          {dateFormatted}
        </div>
      </div>
    );
  };
  
  const ListHTML = () => {
    if(!formValues.listHTML) return <></>;
    if(formValues.details && formValues.details == 'no-details') return <></>;
    return (
      <>{formValues.listHTML}</>
    );
  };

  const ReportContact = () => {
    if (formValues && formValues.contact && formValues.contact === "show") {
      return (
        <div className="page-section">
          <div className="report-contact">
            <div
              className="avatar-img"
              style={{ backgroundImage: `url(${user.avatar})` }}
              alt="avatar"
            />

            <div>
              <p className="contact-name">
                {user.firstName} {user.lastName}
                <br />
              </p>
              <div className="contact-title">
                {user.title && <span>{user.title}</span>}
                {user.company && (
                  <span>
                    {" | "}
                    {user.company}
                  </span>
                )}

                {user.agentLicenseNumber && (
                  <span>
                    {" "}
                    {" | "}License #: {user.agentLicenseNumber}
                  </span>
                )}
              </div>
              <div className="contact-info">
                {user.email && <span>{user.email}</span>}
                {user.primaryPhone && (
                  <span>
                    {" | "}
                    {user.primaryPhone}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  let htmlContent = (
    <Fragment>
      {ReportTitle()}
      {ReportSummary()}

      {html}
      {ListHTML()}
    </Fragment>
  );
  const headerContent = `
    <style>
    body {
      font-family: Arial, sans-serif;
    }
    .key-stat{
      font-size:30px !important;
    }
    .page-section{
      width:100%;
    }
    .export-header{
      padding: 20px 20px;
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      border-bottom: 1px solid #333;
      justify-content:space-between;
    }
    .report-contact{
      display:flex;
    }
    .report-contact .avatar-img {
      margin-right:10px;
    }
    .report-contact .contact-name{
      font-size:22px;
      font-weight:700;
      line-height:27px;
    }
    .report-contact .contact-title, .report-contact .contact-info{
      font-size:18px;
      font-weight:300;
      line-height:21px;
    }
    .report-contact .avatar-img{
      width:70px;
      height:70px;
      background-size:cover;
      border-radius:50%;
    }
    .report-title{
      margin:20px;
    }
    .report-summary{
      margin:20px;
      padding:20px;
      background:#F5F7FA;
      margin-bottom:30px;
    }
    .report-summary .report-content{
      font-size:20px;
      column-count:2;
    }
    .report-title h2{
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 15px;
      border-bottom: 1px solid #AAA;
      margin-bottom: 30px;
    }
    @media print {
      body {
        transform: scale(1); /* Zoom to 80% */
      }
    }
    </style>
    ${styles}
  `;

  const scripts = `
    <script src="https://cdnjs.cloudflare.com/ajax/libs/react/17.0.2/umd/react.production.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/react-dom/17.0.2/umd/react-dom.production.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/recharts/2.0.10/Recharts.min.js"></script>
  `;

  useEffect(() => {
    const eventSource = new EventSource(`${exportAPIURL}/events`);

    eventSource.onmessage = function (event) {
      const progress = JSON.parse(event.data);
      if (progressRef.current !== progress.status) {
        progressRef.current = progress.status;
        setProgress(`Status: ${progress.status}`);
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    //props.setIsExportModalClicked(!isModalOpen);
    if (isModalOpen) {
      setFormValues(defaultValues);
      setAiRun(false);
    }
  };
  const scrollToTop = () => {
    if (previewContainerRef.current) {
      previewContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  useEffect(() => {
      const fetchData = async () => {
        scrollToTop();
        if (formValues.details !== 'no-details') {
          setUnitListProgress(true);
          setIframeContent(1200);
          try {
            const unitListTableData = await getUnitList(formValues.details);
            
           
            const listHTML = renderListViewHTML(unitListTableData, formValues.details);
            setFormValues(prevState => ({
               ...prevState,
               listHTML: listHTML
             }));
            
            setUnitListProgress(false);
          
          } catch (error) {
            console.error("Error fetching unit list:", error);
          }
        }else{
          setIframeContent(0);
          setUnitListProgress(true);
          
          setFormValues(prevState => ({
             ...prevState,
             listHTML: null
           }));
           setUnitListProgress(false);
        }
      };
      
      fetchData();
      
   }, [formValues.details]);
   
   useEffect(() => {
     if (!aiRun && isModalOpen) {
       handleAI();
     }
   }, [isModalOpen, aiRun]); 
   
   const handleAI = async (e) => {
   if (e) {
     e.preventDefault();
     e.stopPropagation();
   }
   
   setAIInProgress(true);
   scrollToTop();
   setFormValues(prevState => ({
     ...prevState,
     reportTitle: 'Writing title...',
     summary: 'Writing summary...',
     aiRefresh: false
   }));
   
   try {
     // Execute both API calls simultaneously
     const [title, summary] = await Promise.all([handleAIGenerateTitle(), handleAIGenerate()]);
     
     // Update state with the results only when both are resolved
     setFormValues(prevState => ({
       ...prevState,
       reportTitle: title,
       summary: summary,
       aiRefresh: true
     }));
     
     setAiRun(true); // Mark as run
     
   } catch (error) {
     console.error("Error generating title and summary:", error);
   } finally {
     setAIInProgress(false);
   }
   };
   
   const handleAIGenerateTitle = async () => {
   
   const prompt = "Write a title for a real estate Market Snapshot report for NYC, just a few words. No quotes. No formatting, just text.";
   const queryData = {
     "messages": [
     { role: "system", content: `You are a real estate expert, use the following information.\n\n  The report is filtered by the following parameters: ${JSON.stringify(reportParams["reportSettings"])}` },
     { role: "user", content: prompt },
     ],
     model: "gpt-4o"
   };
   
   const axiosData = {
     url: apiUrl + 'ai/chat',
     method: "post",
     query: queryData
   };
   
   try {
     const res = await _axiosCall(axiosData);
     if (res && res.choices && res.choices[0] && res.choices[0].message && res.choices[0].message.content) {
     const result = res.choices[0].message.content;
     return sanitizeResults(result);
     }
   } catch (error) {
     console.log("Error generating title:", error);
   }
   return "";
   };
   
   const handleAIGenerate = async () => {
   
   const prompt = "Write a brief real estate market analysis based on data given, no longer than 240 words. The context is property sales or listings. Your job is to provide readers w/ high-level context about market direction. the analysis should be concise and brief, use the stats provided, e.g. increased XX%. Segment the analysis by market type, e.g. New Development, Luxury, Resale Condo, etc. Speak as the author of the report, do not refer to data or anything technical. Keep it high level. Use only very basic markdown such as bold text formatting. Separate as paragraphs, do not include main heading but each market type should have a subheading using bold text (not heading tags). If there are multiple market types, summarize the whole as well as provide a brief intro. DO NOT EXCEED 250 words.";
   
   const queryData = {
     "messages": [
     { role: "system", content: `You are a real estate expert, use the following information.\n\n  The report is filtered by the following parameters: ${JSON.stringify(reportParams["reportSettings"])}
       The report contains the following key stats:${JSON.stringify(reportParams["reportData"])}` },
     { role: "user", content: prompt },
     ],
     model: "gpt-4o"
   };
   
   const axiosData = {
     url: apiUrl + 'ai/chat',
     method: "post",
     query: queryData
   };
   
   try {
     const res = await _axiosCall(axiosData);
     if (res && res.choices && res.choices[0] && res.choices[0].message && res.choices[0].message.content) {
     const result = res.choices[0].message.content;
     return sanitizeResults(result, true);
     }
   } catch (error) {
     console.log("Error generating summary:", error);
   }
   return "";
   };

  const handleExport = async (e) => {
    e.preventDefault();
    setProgress('Submitting...');
    const iframeDoc = iframeRef.current.contentDocument;
    const htmlContent = iframeDoc.documentElement.outerHTML;
    const uniqueId = uuidv4();
    const fileName = `${user.id}/Market-Snapshot-${dateFormatted}-${uniqueId}.pdf`;
    const exportName = `Market-Snapshot-${dateFormatted}-${uniqueId}.pdf`;
    setExportInProgress(true);

    try {
      const response = await fetch(`${exportAPIURL}/generate-pdf-save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ html: htmlContent, orientation: 'portrait', fileName }),
      });

      if (response.ok) {
        const { url } = await response.json();

        const fileResponse = await fetch(url);
        const blob = await fileResponse.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = exportName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);

        setProgress('Download complete');
        setExportInProgress(false);
        const customData = {
          file: url
        }
        Publisher.publish(`track-mp.aiSnapshotExport`, { user: user, customData: customData });
      } else {
        console.error('Failed to generate PDF');
        setProgress('Error generating PDF');
        setExportInProgress(false);
      }
    } catch (error) {
      console.error('Error exporting PDF:', error);
      setProgress('Error generating PDF');
      setExportInProgress(false);
    }
  };

  return (
    <div>
      <Tooltip title="Use AI to write a summary and download this report">
        <Button
          variant="contained"
          color="primary"
          className="buyersList__primary-button"
          startIcon={<FileDocumentEditOutlineIcon />}
          onClick={toggleModal}
          disabled={parentFetchInProgress}
        >
          {" "}
          Write Report
        </Button>
      </Tooltip>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        size="lg"
        className={`modal-dialog pdf_export_modal`}
      >
        <ModalHeader toggle={toggleModal}>
          <h4 className="contact__title">Personalize and Export</h4>
        </ModalHeader>
        <div className="pdf_modal_container">
          <div className="pdf_preview">
            <div
              className="modal__body"
              style={{
                height: "100%",
                minHeight: "calc(90vh - 300px)",
                padding: 15,
              }}
            >
              <h5 className="title mb-2">Preview</h5>
              <div
                style={{
                  border: "1px solid rgba(0,0,0,.12)",
                  boxShadow: "2px 2px 6px rgba(0,0,0,.12)",
                  paddingTop: 10,
                  paddingLeft: 10,
                  background: "#F5F7FA",
                  minHeight: "calc(90vh - 490px)",
                }}
              >
                <div
                  id={"previewContainer"}
                  ref={previewContainerRef}
                  style={{
                    width: "165%",
                    maxHeight: "calc(100vh + 25px)",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    transform: "scale(0.6)",
                    transformOrigin: "0 0",
                    marginBottom: "-40vh",
                  }}
                >
                  {formValues ? (
                    <>
                      {!unitListProgress && aiRun ? (
                        <ExportView
                          ref={iframeRef}
                          content={htmlContent}
                          headerContent={headerContent}
                          scripts={scripts}
                          headerHTML={<ReportHeader />}
                          footerHTML={<ReportFooter />}
                          preview={preview}
                        />
                      ) : (
                        <div
                          style={{
                            minHeight: "1200px",
                            borderBottom: "20px solid #f5f7fa",
                            position: "relative",
                            paddingBottom: "60px",
                            background: "#fff",
                          }}
                        >
                          <div
                            style={{
                              padding: "20px 20px",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "50px",
                              borderBottom: "1px solid #333",
                              justifyContent: "space-between",
                              height: "130px",
                            }}
                          >
                            <ReportHeader />
                          </div>
                          <div>
                            <LinearLoading />
                            <h2 style={{padding:50,fontSize:38,fontWeight:600,textTransform:'uppercase'}}><FileDocumentEditOutlineIcon size={50} color={'#780F9E'} style={{marginTop:-5}}/> Writing Report...</h2>
                          </div>
                            
                          
                         
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="pdf_settings">
            <div
              className="modal__body background_gray"
              style={{ padding: 15 }}
            >
              <h5 className="title">Personalize</h5>

              <ExportSettingsForm
                user={user}
                setPreview={() => setPreview(!preview)}
                setParentFetchInProgress={setFetchInProgress}
                formValues={formValues}
                setFormValues={setFormValues}
                {...props}
                defaultValues={defaultValues}
                handleAI={handleAI}
                aiInProgress={aiInProgress}
              />
            </div>
          </div>
        </div>
        <ModalFooter>
          <ButtonToolbar className="modal__footer mb-3">
            <div id="progress" style={{ color: "#780F9E", fontWeight: 600 }}>
              {progress}
            </div>
            <Tooltip title="Cancel">
              <Button
                onClick={toggleModal}
                variant="outlined"
                color=""
                className="mr-2"
                startIcon={null}
              >
                {" "}
                Cancel
              </Button>
            </Tooltip>{" "}
            <Tooltip title="Download your PDF report">
              <Button
                variant="contained"
                color="primary"
                className="buyersList__primary-button"
                startIcon={<FileExportIcon />}
                onClick={handleExport}
                disabled={fetchInProgress || aiInProgress}
                width={200}
              >
                {" "}
                {exportInProgress ? "Exporting... " : "Download Report"}
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
   user: state.user
  };
};

export default connect(mapStateToProps)(ExportModal);
