import React, { useState, Fragment }  from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import ContactIcon from 'mdi-react/ForumOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/LockOpenIcon';
import UpgradeIcon from 'mdi-react/StarCircleOutlineIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';



export default function CustomSubscription(props) { 
	const { img, copy, upgrade, user, nextPlan } = props;
	const [ modal, setModal ] = useState(false); 
	
	return (
		  <Col md={12}>
		    <Card>
		      <CardBody style={{padding:"30px 0 0 0"}}>
		        <div className="email-confirmation" style={{minHeight:0}}>
		          <div className="email-confirmation__icon" style={{paddingTop:0}}>
		             <ContactIcon className="email-confirmation__mail" />
		            <CheckboxMarkedCircleIcon className="email-confirmation__check" style={{top:0}} />
		          </div>
		          <h3 className="email-confirmation__title">You Have a Custom Subscription</h3>
		          <h4>Your account is a part of a custom subscription plan.  To make changes, please contact us.</h4>
		          <div className="email-confirmation__buttons"  style={{marginTop:30}}>
			          <ButtonToolbar>
			          	<div>
				          	<Fragment>
					          	<Button
							         variant="contained"
							         color="primary"
							         className="buyersList__primary-button intercom_launch"	
							         style={{width:200}}	         
							     >	Contact Us
							     </Button>
						     </Fragment>		
			          	</div>
			          				   		 
			          </ButtonToolbar>
					  
					 
		           </div>
		        </div>
		        {img &&
		        	<div><img src={img} style={{width:"100%"}}/></div>
		        }
		      </CardBody>
		    </Card>
		  </Col>
    )
};


