import React, { PureComponent, Fragment } from 'react';
import { Progress, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../shared/components/Panel';
import { getDefaultChartColors } from '../../../../../shared/helpers';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from '../../../../../shared/helpers/utils';


	
class UnitBreakdown extends PureComponent {
  static propTypes = {
   	t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }
  _generateUnitMixBreakdown = (statsRes) => {
	 if(!statsRes.unit_mix || !statsRes.unit_mix.buckets){
		  return [];
	  }
	 const total = statsRes.unit_mix.buckets.map(bucket => bucket.count).reduce((accumulator, current) => accumulator + current );
	 let other = 0;
	 let data = statsRes.unit_mix.buckets.sort((a, b) =>  a.val - b.val).map((bucket, i) => {
		 
		 return {name: bucket.val > 0 ? `${bucket.val} Bed` : `Studio`,
			 	 count:  bucket.count,
			 	 size: bucket.avg_unit_size,
			 	 price: bucket.avg_ppsf,
			 	 carrying_cost: bucket.avg_monthly_carrying_charges,
			 	 avg_fees: bucket.avg_fees,
			 	 min_price: bucket.min_price,
			 	 max_price: bucket.max_price,
			 	 color: getDefaultChartColors[i]
	}
	 });
	 	 
	 return data;
	  
  }

  
  render() {
	const { t, statsRes } = this.props;
    if(!statsRes){
	    return null;
    }
    const data = this._generateUnitMixBreakdown(statsRes);

	return (
		  <Panel lg={12} xl={7} md={12} title={`Unit Breakdown`}>
		    <Table responsive striped className="table--bordered dashboard__audience-table">
		      <thead>
		        <tr>
		          <th>Unit Type</th>
		          <th>Count</th>
		          <th>Avg Size</th>
		          <th>Avg PPSF</th>
		          <th>Avg CC</th>
		          <th>Range</th>
		        </tr>
		      </thead>
		      <tbody>
		        {data.map(unit => 
		        <tr>
		          <td><span style={{ backgroundColor: unit.color, width:"10px", height:"10px", display:"inline-block" }} /> {unit.name}</td>
		          <td>{unit.count}</td>
		          <td>{_formatNumber(unit.size)} sq ft</td>
		          <td>${_formatNumber(unit.price)}</td>
		          <td>${_formatNumber(unit.avg_fees)}<span className="small-text">/mo</span></td>
		          <td>
		            {_formatPrice(unit.min_price)} - {_formatPrice(unit.max_price)}
		          </td>
		        </tr>
		        )}
		      </tbody>
		    </Table>
		  </Panel>
	  )
	  
  }
}

export default withTranslation('common')(UnitBreakdown);
