import React from 'react';
import { Col, Container, Row, Modal, ButtonToolbar } from 'reactstrap';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    "& .MuiStepIcon-active": { color: "#780F9E" },
    "& .MuiStepIcon-completed": { color: "#780F9E" }
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(add, type) {
	return ['Select Report Type', 'Personalize Report', 'Save & Share'];
	
	
  
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Search company';
    case 1:
      return 'Update company details';
    case 2:
      return 'Associate to project';
    default:
      return 'Unknown step';
  }
}

export default function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const { activeStep, setActiveStep, onSubmit, add, type, saveInProgress, handleSubmit } = props;
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return false; //return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((activeStep+1));
    setSkipped(newSkipped);
  };

   const handleBack = () => {
    setActiveStep((activeStep-1));
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps} className={classes.active}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div style={{padding:isMobile ? "0 10px" : "0 30px"}}>
      	{props.children}
      </div>
      <div>
	      <ButtonToolbar className="modal__footer" style={{paddingRight:30}}>
	        {activeStep === steps.length ? (
	          <div>
	            <Typography className={classes.instructions}>
	              All steps completed - you&apos;re finished
	            </Typography>
	            <Button onClick={handleReset} className={classes.button}>
	              Reset
	            </Button>
	          </div>
	        ) : (
	          <div>
	            {/*<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>*/}
	            <div>
	              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
	                Back
	              </Button>
	              {false && isStepOptional(activeStep) && (
	                <Button
	                  variant="contained"
	                  color="primary"
	                  onClick={handleSkip}
	                  className={classes.button}
	                  disabled={saveInProgress}
	                >
	                  Skip
	                </Button>
	              )}
	
	              <Button
	                variant="contained"
	                color="primary"
	                onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
	                className={classes.button}
	                disabled={saveInProgress}
	              >
	                {activeStep === 0 ? 'Next' : activeStep === 1 ? 'Next' : 'Save & Copy Link'}
	              </Button>
	            </div>
	          </div>
	        )}
	         </ButtonToolbar>
	      </div>
    </div>
  );
}
