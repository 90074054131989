import React from 'react';
import { Col, Row } from 'reactstrap';
import { isMobile } from "react-device-detect";


export default function QuickStartGuide({link, type}) {

  return (
    <Row>
      <Col md={12} className={'text-right'}>
        
        <span style={{fontSize:'12px',marginBottom:'15px',color:'#858AA1'}}>
          {!isMobile && <span>Have a question about {type}? {` `}</span>}
           <a href={link} className="link-text" target="_blank">
             Quick Start Guide
          </a>
        </span>
      </Col>
    </Row>
  );
}