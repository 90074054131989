import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/BorderNoneIcon';
import ToolTip from '../../../../../shared/components/ToolTip';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	setURLFromState
} from "../../../../../shared/helpers/utils";

const AveragePPSFListed = (props) => {

  if(!props.facetData) return null;

  const ppsf = props.facetData.listed_non_zero_price && props.facetData.listed_non_zero_price.avg_ppsf ? (props.facetData.listed_non_zero_price.avg_ppsf).toFixed(0) : false;
  console.log(props.facetData.listed_non_zero_price)
  const newState = Object.assign({}, props); 	
	 if(newState.subFilters && newState.subFilters.current_status){
		 	delete( newState.subFilters.current_status);
	 }
 	 const baseURL = setURLFromState({}, newState, true);
  return (
	  	<Card>
	  	<Link to={`/inventory${baseURL}&current_status=available`}>
	      <CardBody className="dashboard__booking-card card__link">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            ${_formatNumber(ppsf)}
	          </h5>
	          <TagOutlineIcon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Avg Listed PPSF
	        	<ToolTip 
	        		title={`Based on available listing price over the past 24 months`} 
	        		body={`Average listed price per square foot is based only on listed units within the ${props.groupedCount} Marketproof Vetted buildings.`}
	        	/>
	        </h5>
	      </CardBody>
	    </Link>
	    </Card>
	 )
};

export default AveragePPSFListed;
