export const SET_PLAN = 'SET_PLAN';
export const CLEAR_PLAN = 'CLEAR_PLAN';

export function setPlan(plan) {
  return {
    type: SET_PLAN,
    plan: plan
  };
}

export function clearPlan() {
  return {
    type: CLEAR_PLAN
  };
}
