import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/PhoneClassicIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { _axiosCall } from '../helpers/apicalls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import buyersListActions from '../../redux/actions/buyersListActions';


import{
	_capitalizeText,
} from '../helpers/utils';

const apiURL = process.env.API_ROOT;


const ShowView = ({ rank, period }) => {
	return (
		<div className="saved-buyer-indicator">
		
			<span className="saved-buyers-small">
				 <span style={{fontWeight:200}}>#</span> {rank}  <span style={{fontWeight:200}}></span>
			</span> Most Viewed Building on Marketproof Over the Last {_capitalizeText(period.replace("-", " "))}
			
		</div>
	)
}

class ViewedCount extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      viewedData: null
    };
   
  }
  
  componentDidMount() {	  
	const { buildingKey, unitKey } = this.props;
	//Return null until more users save buildings


	if(buildingKey){
		this._getBuildingAnalytics(buildingKey);
	}else{
		//this._getUnitAnalytics(unitKey);
	}

	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

	 const lastUpdateBuildingSaved = this.props.savedBuildings && this.props.buildingKey ? this.props.savedBuildings.filter(item => !item.deleted && item.key === this.props.buildingKey).length > 0 : false;
	 
	 const lastUpdateUnitSaved = this.props.savedUnits && this.props.unitKey ? this.props.savedUnits.filter(item => !item.deleted && item.key === this.props.unitKey).length > 0 : false;
	 
	 if(lastUpdateBuildingSaved && !this.state.thisBuildingSaved){
		 this.setState( { ViewedCount : this.state.ViewedCount + 1,  thisBuildingSaved:lastUpdateBuildingSaved } );
	 }
	 if(!lastUpdateBuildingSaved && this.state.thisBuildingSaved){
		 this.setState( { ViewedCount : this.state.ViewedCount - 1,  thisBuildingSaved:lastUpdateBuildingSaved } );
	 }
	  
	 if(lastUpdateUnitSaved && !this.state.thisUnitSaved){
		 this.setState( { ViewedCount : this.state.ViewedCount + 1,  thisUnitSaved:lastUpdateUnitSaved } );
	 }
	 if(!lastUpdateUnitSaved && this.state.thisUnitSaved){
		 this.setState( { ViewedCount : this.state.ViewedCount - 1,  thisUnitSaved:lastUpdateUnitSaved } );
	 }
	 
	 
	 
  }
  
  _getBuildingAnalytics = async () => {
			  
		  const { type, buildingKey } = this.props;
		  
		  if (!this.state.fetchInProgress) {
			  this.setState(prevState => ({ fetchInProgress: true, }));
		  }
		  
		  
		  
		  const queryData = [{
				  "$addFields": {
					  "formattedDate": {
						  "$dateToString": {
							  "format": "%Y-%m-%d",
							  "date": "$created_timestamp"
						  }
					  }
				  }
			  },
			  {
				  "$unwind": {
					  "path": "$building"
				  }
			  },
			  {
				  "$facet": {
					  "one-day": [{
							  "$match": {
								 "formattedDate": {
									   "$gte": moment().add(-24, 'hour').format('YYYY-MM-DD')
								   },
								   "event_type": "buildingView"
							  }
						  },
						  {
							  "$group": {
								  "_id": {
									  "building": "$building.key"
								  },
								  "count": {
									  "$sum": 1
								  }
							  }
		  
						  },
						  {
							  "$sort": {
								  "count": -1
							  }
						  },
						  {
							  "$limit": 20
						  }
					  ],
					  "seven-day": [{
								"$match": {
								   "formattedDate": {
										 "$gte": moment().add(-7, 'days').format('YYYY-MM-DD')
									 },
									 "event_type": "buildingView"
								}
							},
							{
								"$group": {
									"_id": {
										"building": "$building.key"
									},
									"count": {
										"$sum": 1
									}
								}
					  
							},
							{
								"$sort": {
									"count": -1
								}
							},
							{
								"$limit": 20
							}
						],
					   "thirty-day": [{
								"$match": {
								   "formattedDate": {
										 "$gte": moment().add(-30, 'days').format('YYYY-MM-DD')
									 },
									 "event_type": "buildingView"
								}
							},
							{
								"$group": {
									"_id": {
										"building": "$building.key"
									},
									"count": {
										"$sum": 1
									}
								}
					  
							},
							{
								"$sort": {
									"count": -1
								}
							},
							{
								"$limit": 20
							}
						],
					 
				  }
			  }
		  
		  
		  ]
				   
		  let axiosData = {
			  url: apiURL + "/buyerslist/event/aggregate",
			  method: "post",
			  query: queryData
		  };
  
		  let response = await _axiosCall(axiosData)
		  .then(res => {
			  
			  const buildingIDs = [];
			  if(res && res[0]){
				 this.setState({viewedData: res[0]})
			  }else{
				 this.setState({viewedData: false})
			  }
			  
			  
		  })
		  .catch(error => {
			  console.log("error: " + error);
		  });
		  
		  
	};


  
  _getUnitAnalytics = async (_unitIDs) => {
						
		var q = "*:*";
		
		const { unitKey } = this.props;
		
		var query = [{
				"$facet": {
					"unitKeys": [{
							"$unwind": "$savedUnits"
						},
						{
							"$unwind": "$savedUnits.key"
						},
						{
							"$match": {
								"$and": [{
										"$or": [{
												"savedUnits.deleted": {
													"$exists": false
												}
											},
											{
												"savedUnits.deleted": false
											}
										]
									},
									{
										"savedUnits.key": {
											"$in": _unitIDs.split(' ')
										}
									}
								]
							}
						},
						{
							"$group": {
								"_id": {
									"key": "$savedUnits.key"
								},
								"total": {
									"$sum": 1
								}
							}
						},
						{
							"$project": {
								"_id": 0,
								"key": "$_id.key",
								"total": 1
							}
						}
					]
				}
			}]


		let axiosData = {
			url: apiURL+ "/buyerslist/analytics/aggregate?cache=1440",
			method: "post",
			query: query
			
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };

  render(){
	  const { viewedData } = this.state;
	  const { buildingKey, unitKey } = this.props;
	  if( viewedData === null ) return null;
	  
	  let rank = 999;
	  let period = false;
	  
	  if(viewedData['thirty-day'] && Array.isArray(viewedData['thirty-day'])){		  
		  let index = viewedData['thirty-day'].findIndex(item => item._id.building == buildingKey);
		  if(index > -1 && index < rank){
			 rank = index;
			 period = "30 Days";
		  }
	  }
	  
	  if(viewedData['seven-day'] && Array.isArray(viewedData['seven-day'])){		  
			let index = viewedData['seven-day'].findIndex(item => item._id.building == buildingKey);
			if(index > -1 && index < rank){
				 rank = index;
				 period = "7 Days";
			}
	  }
	  
	  if(viewedData['one-day'] && Array.isArray(viewedData['one-day'])){		  
			  let index = viewedData['one-day'].findIndex(item => item._id.building == buildingKey);
			  if(index > -1 && index < rank){
				   rank = index;
				   period = "1 Day";
			  }
	  }
	  
	  if(rank > -1 && rank < 999){
		  return <ShowView rank={rank+1} period={period} />
	  }
	  
	 
	 return null;
	}
};


export default (ViewedCount);
