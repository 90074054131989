import React, { PureComponent, useState, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';


import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';


import Energy from './energy';
import Flood from './flood';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft:"-8px",
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),      
	},
	
  },
  button:{
	'&.selected' :{
		background:"#D1D6E0"
	}
  }
}));

const Environment = (props) => {
	const { unitsRes, buildingRes, statsRes, user } = props;  
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : buildingRes.building_status == "resale" ? 'energy' : "flood");
	
	const [period, setPeriod] = useState("all-time");
	
	
	const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const summaryTitle = 'Building Environment - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
	const _toggleTab = (tab) => {
		if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/environment/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
		  setActiveTab(tab)
		  Publisher.publish(`track.buildingEnvironmentView.${tab}`, {building: buildingRes.key});
		}
	  };
	
	const classes = useStyles();
	
	return(
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
			<div className="tabs"> 	
			<div className="tabs__wrap mb-2">
				<Nav tabs>
				   {buildingRes.building_status == "resale" &&
				   <NavItem>
					<NavLink
					  className={classnames({ active: activeTab === 'energy' })}
					  onClick={() => {
						_toggleTab('energy');
					  }}
					>
					  <span className="tab-title">Energy</span>
					</NavLink>
				  </NavItem>
			  	  }
		
				  <NavItem>
					<NavLink
					  className={classnames({ active: activeTab === 'flood' })}
					  onClick={() => {
						_toggleTab('flood');
					  }}
					>
					  <span className="tab-title">Flood</span>
					</NavLink>
				  </NavItem>
			  	  {buildingRes.building_status != "resale" &&
					   <NavItem>
						<NavLink
						  className={classnames({ active: activeTab === 'energy' })}
						  onClick={() => {
							_toggleTab('energy');
						  }}
						>
						  <span className="tab-title">Energy</span>
						</NavLink>
					  </NavItem>
						}
				 
				</Nav>
			  </div>
			</div>

		
		
		
			  
		</Container>
		{activeTab === 'energy' &&
		  <TabPane tabId="energy">
			 <Energy {...props} />
		  </TabPane>
		  }
		{activeTab === 'flood' &&
		  <TabPane tabId="flood">
			 <Flood {...props} />
		  </TabPane>
		  }	  
		</div>
		
	)
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Environment));