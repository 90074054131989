import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import AlertIcon from 'mdi-react/InformationOutlineIcon';
import UnitIcons from '../../../shared/components/UnitIcons';
import TrendDownIcon from 'mdi-react/TrendingDownIcon';
import TrendUpIcon from 'mdi-react/TrendingUpIcon';

import EnterpriseGate from '../../../shared/components/payment/EnterpriseGate';
import ACRISVettedStatus from '../../../shared/components/ACRISVettedStatus';
import LoanVettedStatus from '../../../shared/components/LoanVettedStatus';
import AttorneyVettedStatus from '../../../shared/components/AttorneyVettedStatus';
import TitleVettedStatus from '../../../shared/components/TitleVettedStatus';
import ListingAgentInfo from '../../../shared/components/ListingAgentInfo';

import { _hasPermission	} from "../../../shared/helpers/access";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_formatOwnerName,
	BuildingStageBadge,
	_isCoop
} from "../../../shared/helpers/utils";

import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';

class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 20,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
	};

	componentDidMount() {
		
		if(this.props.buildingKey){
			this.queryUnits();
		}
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(this.props.buildingKey){
		  if(!_objectEqual(this.props.q, prevProps.q) ||
		  (this.props.buildingKey !== prevProps.buildingKey ||
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start)){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 this.queryUnits(reset);
		  }		 
		}
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};
	

	
	queryUnits = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const {buildingKey, q, fq, fl } = this.props;	
		const { orderBy, order } = this.state;
		
		const isContract = this.props.subFilters.offering_status && this.props.subFilters.offering_status === 'in-contract';
		
		let sort = "floor asc, address_2_normalized asc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			if(orderBy === 'address_2_normalized'){
				sort = `floor ${order}, ${orderBy} ${order}`;
			}else if(orderBy === 'offering_price'){
				sort = `last_listed_sale_price_all ${order}, ${orderBy} ${order}`;
			}else if(orderBy === 'last_sold_date'){
				if(isContract){
					sort = `last_contract_date ${order}`;
				}else{
					sort = `${orderBy} ${order}, last_contract_date ${order}`;
				}
			}else{
				sort = `${orderBy} ${order}`;
			}
			
		}
		
		const unitsFq = [...fq, `building:${buildingKey}`];			
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=60",
			method: "post",
			query: {
				q: q,
				fq: unitsFq,
				sort: sort,
				fl,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	render(){
		const { reactTableFiltered, handleBuildingClick, isSelected, handleClick, order, orderBy, selected, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, building, showUnitStageFilter} = this.props;
		if(!reactTableFiltered && (this.state.start===0 && (this.state.unitListData.length===0 || this.props.fetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const data = reactTableFiltered || this.state.unitListData;
		let isAdmin = user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0;
		
		if( data.length <= 0 ){
			return (
			  <Col md={12}>
			    <Card>
			      <CardBody className={`small`}>
			        <div className={`email-confirmation`} >
			          <div className="email-confirmation__icon">
			            <AlertIcon size={50} color={`#D6BAE8`} />			            
			          </div>
			          <h3 className="email-confirmation__title">No Sales Found</h3>
			          <p className="email-confirmation__sub"></p>
			          
			        </div>
			      </CardBody>
			    </Card>
			  </Col>
			  )
		}
		
		
		return (
		<Fragment>
			<div>
			<Table className="material-table">
		        <UnitsSearchTableHead
		          numSelected={[...selected].filter(el => el[1]).length}
		          order={order}
		          orderBy={orderBy}
		          onSelectAllClick={handleSelectAllClick}
		          onRequestSort={isGrouped ? this._handleRequestUnitsSort : handleRequestSort}
		          rowCount={reactTableFiltered.length}
		        />
		        <TableBody>
		          {data
		            .map((d) => {
		              const itemSelected = isSelected(d.id);
		              const floorplan = false; //_getUnitFloorPlan(d);
					  const buildingData = d;
					  //If building is sold out override stats
					  if(building && building.offering_verified_status === 'sold out'){						  
						  if(["active", "not_yet_recorded", "sold", "in contract", "resale"].indexOf(d.offering_status) < 0){
							  d.offering_status = "sold";
						  }						  
					  }
					  
					  d.contacts = d.agents ? d.agents.map((a,i) => {
						  return {
							  "name" : _capitalizeText(a),
							  "company" : d.broker,
							  "email" : d.agent_emails[i]
						  }
					  }) : [];
		              return (
		                <TableRow
		                  className="material-table__row"
		                  role="checkbox"
		                  onClick={event => handleBuildingClick(event, d)}
		                  aria-checked={itemSelected}
		                  tabIndex={-1}
		                  key={d.id}
		                  selected={itemSelected}
		                >
		                 
		                   <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding=""
                          >
                           <span className="light-text small-text">
                           		<span className="status-small sold" /> 
								{d.address_2_normalized && d.address_2_normalized != "" && d.ownership_type.indexOf("family")<0 && d.ownership_type.indexOf("rental")<0 ? 
								<span>
                           		{d.price === 0 ? 'Transfer' : (d.parcels_condocoop_count > 1 || (d.parcels_condocoop_count ==0 && d.parcels_count > 1)) ? 'Bulk Sale' : (d.sponsored_sale && showUnitStageFilter) ? 'Sponsor Sale' : !showUnitStageFilter ? 'Resale' : 'Resale'}
								</span>
								:
								<span>Sold</span>
								}
								
                           	</span>

                          </TableCell>  
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{moment(d.close_date_formatted).format('M/D/YY')}</span>
                          </TableCell>   
						   <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{d.recorded_datetime ? moment(d.recorded_datetime).format('M/D/YY') : "--"}</span>
                          </TableCell>   
		                
		                  <TableCell
		                    className="material-table__cell material-table__cell-right nowrap"
		                    component="td"
		                    scope="row"
		                    padding=""
		                  >
		                  	 {isGrouped || this.props.buildingView ?
		                      <div className="icon__floorplan"><span >{d.address_2_normalized.toUpperCase().replace("APT", "Apt").replace("#", "Apt")}</span>		                   
			                    
			                    {/*<UnitIcons building={building} unit={d} />*/}
			                   	<Fragment>
						  			<OpenInNewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`${_getBuildingURL(buildingData, 'building')}/${_nameToUrl(d.address_2_normalized)}`); }} />
						  		</Fragment>
				                   	
				                 
		                      </div>
			                 :
			                  <div className="icon__floorplan">
			                  	<span>{d.name ? _capitalizeText(d.name) : _capitalizeText(_replaceAbbreviations((d.display_full_street_address ? d.display_full_street_address : d.full_street_address)))}, {d.address_2_normalized.toUpperCase().replace("APT", "Apt")} <BuildingStageBadge item={d} small/></span>
			                  	
			                    {/*<UnitIcons building={building} unit={d} />*/}
			                    {!isMobile && <Fragment>
						  			<OpenInNewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`${(building ? _getBuildingURL(building) : _getBuildingURL(d, 'building'))}/${_nameToUrl(d.address_2_normalized)}`); }} />
									  
						  		</Fragment>}
			                    <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>							
			                   
		                   	  </div>
		                   	  
		                   	}
		                   	
		                   	
		                   	
		                   	
		                  </TableCell>
		                  
		                  
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="small-text">
							 
							 
							{d.price > 1000 ? '$' +_formatNumber(d.price) : d.price == -1 ? 'Not Available' : '$0 (Non-Market)'}   <ACRISVettedStatus transaction={d}  admin={isAdmin} /></span>
                          	{d.parcels_condocoop_count > 1 && <span className="light-text xsmall-text"><br/>(Bulk {d.price > 0 ? 'Sale' : 'Transfer'} of {d.parcels_count} Units)</span>}
                          	{d.parcels_condocoop_count == 0 && d.parcels_count>=2 && <span className="light-text xsmall-text"><br/>(Bulk {d.price > 0 ? 'Sale' : 'Transfer'} of {d.parcels_count} Units)</span>}
                          	{d.parcels_condocoop_count == 1 && d.parcels_property_types.indexOf('sr')>=0 && d.parcels_property_types.indexOf('ps')>=0 ?
	                          		<span className="light-text xsmall-text"><br/>(Includes Parking & Storage)</span>
	                          	: d.parcels_condocoop_count == 1 && d.parcels_property_types.indexOf('sr')>=0 ?
	                          		<span className="light-text xsmall-text"><br/>(Includes Storage)</span>
	                          	: d.parcels_condocoop_count == 1 &&d.parcels_property_types.indexOf('ps')>=0 ?
	                          		<span className="light-text xsmall-text"><br/>(Includes Parking)</span>
	                          	: null
	                         }
                          </TableCell> 
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >   {d.discount > -100 && d.discount < 100 && d.discount !== 0 && d.parcels_condocoop_count <= 1 ?
	                          <Fragment>                      
                          		{d.discount < 0 ? 
								  		<TrendDownIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#FF206E'} /> 
								  		: <TrendUpIcon  size={18} style={{ marginTop:"-3px", marginRight:"5px"}} color={'#4ce1b6'}/>
								  	} 			                          
								<span className="small-text">{(d.discount).toFixed(2)} %</span>
							  </Fragment> 
							  :
							  <span className="light-text small-text">--</span>
							  }
							</TableCell>
							<TableCell
								className="material-table__cell material-table__cell-right"
								size="small"                          
							  >
								  <span className="light-text small-text">{d.contract_date_formatted && parseInt(moment(d.contract_date_formatted).format('YYYY')) > 1971 ? moment(d.contract_date_formatted).format('M/D/YY') : "--"}   <ACRISVettedStatus transaction={d} contract admin={isAdmin} /></span>
							  </TableCell>
		                   <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	
		                  	<span className="light-text small-text">{d.days_to_contract >  0 && d.days_to_contract <= 1460 ? _formatNumber(d.days_to_contract) + ' Days': '--'}</span>
		                  	
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	
		                  	<span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
		                  	
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	
		                  	<span className="light-text small-text">{d.baths > -1 ? d.baths+' Bath' : '--'}</span>
		                  	
		                  </TableCell>
						  
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                    <span className="light-text small-text">{d.price_per_sqft > 0 && d.parcels_condocoop_count <= 1 ? '$'+_formatNumber(d.price_per_sqft) : '--'}</span>
		                  </TableCell>
		                  
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                    
		                    <div className="progress-wrap">
				              <span className="small-text">{d.percent_financed > 0 && d.percent_financed <= 100 ? _formatNumber(d.percent_financed) + '%' : (d.percent_financed == -1 && d.financed_amt>100) ? '$'+_formatNumber(d.financed_amt) : '--'}</span>
				              {(d.percent_financed > 0 && d.percent_financed <= 100)  && <Progress value={d.percent_financed} />}
				            </div>
		                  </TableCell>
						  <TableCell
							  className="material-table__cell material-table__cell-right"
							  size="small"                          
							>		            
							  {!_hasPermission(user, "originator") ?
						  
								  <span className="light-text small-text">
									  <EnterpriseGate 
									  overrideText={<span>Upgrade</span>}
									  upgrade={true}
									  user={user}
									  nextPlan={"complete"}
									  hideIcon
								  /></span>			
							  
								  
							  :       	
								<div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>
								{_hasPermission(user, "originator") && d.verified_loan_officer_first_name &&
								  <div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>{_capitalizeText(d.verified_loan_officer_first_name)} {d.verified_loan_officer_middle_name && _capitalizeText(d.verified_loan_officer_middle_name)} {_capitalizeText(d.verified_loan_officer_last_name)}</div>
								} 
								
								{d.verified_lender_name ? "("+_capitalizeText(d.verified_lender_name)+")" : d.lender ? _formatOwnerName(d.lender, true) : '--'}
							   {_hasPermission(user, "originator") &&
								<LoanVettedStatus transaction={d} admin={isAdmin} />
								
								  }
								  
								</div>	
								
									
								}     
								            	
							</TableCell>
							<TableCell
							  className="material-table__cell material-table__cell-right"
							  size="small"                          
							>		            
							  {!_hasPermission(user, "originator") ?
							
								  <span className="light-text small-text">
									  <EnterpriseGate 
									  overrideText={<span>Upgrade</span>}
									  upgrade={true}
									  user={user}
									  nextPlan={"complete"}
									  hideIcon
								  /></span>			
							  
								  
							  :       	
								<div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>
								{_hasPermission(user, "originator") && d.verified_title_company_name && d.verified_title_company_name[0] ?
								  <Fragment>{_capitalizeText(d.verified_title_company_name[0])}</Fragment>
								  :
								  <Fragment>--</Fragment>
								} 
								
								
							   {_hasPermission(user, "originator") &&
								<TitleVettedStatus transaction={d} admin={isAdmin} />
								
								  }
								  
								</div>	
								
									
								}     
												
							</TableCell>
							<TableCell
							  className="material-table__cell material-table__cell-right"
							  size="small"                          
							>		            
							  {!_hasPermission(user, "buyer-attorney") ?
							
								  <span className="light-text small-text">
									  <EnterpriseGate 
									  overrideText={<span>Upgrade</span>}
									  upgrade={true}
									  user={user}
									  nextPlan={"complete"}
									  hideIcon
								  /></span>			
							  
								  
							  :       	
								<div className="light-text small-text" style={{width:220,whiteSpace:"normal"}}>
								{_hasPermission(user, "buyer-attorney") && d.verified_buyers_attorney_first_name &&
								  <div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>{_capitalizeText(d.verified_buyers_attorney_first_name)} {d.verified_buyers_attorney_middle_name && _capitalizeText(d.verified_buyers_attorney_middle_name)} {_capitalizeText(d.verified_buyers_attorney_last_name)}</div>
								} 
								
								
								<span>{d.verified_buyers_attorney_firm_name ? "("+_capitalizeText(d.verified_buyers_attorney_firm_name)+")" : d.verified_buyers_attorney_unavailable === true ? "Not Available in ACRIS" : '--'}</span>
							   {_hasPermission(user, "buyer-attorney") &&
								<AttorneyVettedStatus transaction={d} admin={isAdmin} />
								
								  }
								  
								</div>	
								
									
								}     
												
							</TableCell>
							  <TableCell
								  className="material-table__cell material-table__cell-right"
								  size="small"                          
								>
									
									
									<div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>{d.contacts && d.contacts.map((c, i) => {
										
										return (<Fragment><ListingAgentInfo agent={c} />{i<(d.contacts.length-1) && ', '}</Fragment>);
									  
									  })}</div>
									<div className="light-text small-text mt-1" style={{width:200,whiteSpace:"normal"}}>{d.broker ? "("+_formatOwnerName(d.broker.split(/,(?=\S)/)) + ")" : ''}</div>
								  
									
								</TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	{!_hasPermission(user, "ownership") ?

								<span className="light-text small-text">
									<EnterpriseGate 
									overrideText={<span>Upgrade</span>}
									upgrade={true}
									user={user}
									nextPlan={"complete"}
									hideIcon
								/></span>			
							
								
			                : 
		                  	
		                  	<div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>{d.owner ? _formatOwnerName(d.owner.split(/,(?=\S)/)) : '--'}</div>
		                  	}
		                  	
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                    {!_hasPermission(user, "ownership") ?

								<span className="light-text small-text">
									<EnterpriseGate 
									overrideText={<span>Upgrade</span>}
									upgrade={true}
									user={user}
									nextPlan={"complete"}
									hideIcon
								/></span>			
							
								
			                :                  	
		                  	<div className="light-text small-text" style={{width:200,whiteSpace:"normal"}}>{d.seller ? _formatOwnerName(d.seller.split(/,(?=\S)/)) : '--'}</div>
		                  }		                  	
		                  </TableCell>
		               
                          						  			                
		                </TableRow>
		              );
		            })}
		          {emptyRows > 0 && (
		            <TableRow style={{ height: 49 * emptyRows }}>
		              <TableCell colSpan={6} />
		            </TableRow>
		          )}
		        </TableBody> 
		        
              
		      </Table>
		      </div>
		     
			   {!reactTableFiltered && 
				<div className="unit__load">			   		
				    <Tooltip title="Load more units">
				        <Button
					        onClick={(e) => {this._loadMore(e)}}
					         variant="contained"
					         color="news"
					         className={`timeline__load-more`}		         
					         startIcon={<LoadMoreIcon />}
					         disabled={this.state.fetchInProgress || this.state.noMoreResults}
					     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "No More Units" : "Load More"}`}
					     </Button>				 
				  	</Tooltip>				
			    </div>
			    }
			</Fragment>
		);
	}
}

export default connect(state => ({
  user: state.user
}))(UnitsListTable);