import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Button, Tooltip } from '@material-ui/core';
import FinanceIcon from 'mdi-react/ChartAreasplineIcon';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
    fontSize: '14px',
  },
  menuItem: {
    fontSize: '15px',
    '&.Mui-selected': {
      backgroundColor: 'transparent !important', // Prevent background change on selection
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent !important', // Prevent background change on focus
    },
  },
  listItemText: {
    fontSize: '15px',
    '& .MuiListItemText-primary': {
      fontSize: '15px',
    },
  },
  select: {
    maxWidth: '200px',
    fontSize: '15px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  inputLabel: {
    color:'#780F9E',
    '&.Mui-focused': {
      // Styles for the active state
     color:'#9600BF'
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      marginTop: 8,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const chartOptions = [
  { key: 'contracts', name: 'Contracts' },
  { key: 'listings', name: 'Listings' },
  { key: 'closings', name: 'Closings' },
  { key: 'price-drops', name: 'Price Drops' },
  { key: 'price-hikes', name: 'Price Hikes' },
];

const ChartTypeDropdown = ({ chartType, setChartType }) => {
  const classes = useStyles();
  const [tempChartType, setTempChartType] = useState(chartType);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setTempChartType(event.target.value);
  };

  const handleCancel = (event) => {
    event.stopPropagation(); // Prevent triggering select item logic
    setTempChartType(chartType);
    setOpen(false); // Close dropdown
  };

  const handleApply = (event) => {
    event.stopPropagation(); // Prevent triggering select item logic
    setChartType(tempChartType);
    setOpen(false); // Close dropdown
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderSelectedValue = (selected) => {
    if (selected.length === 0) return '';

    const firstSelected = chartOptions.find(option => option.key === selected[0]);
    if (!firstSelected) return '';

    if (selected.length > 1) {
      return <span><FinanceIcon size={16} color="#BF96D9" style={{marginTop:-2}} /> {firstSelected.name} <span className="small-text">(+{selected.length - 1} More)</span></span>;
    }

    return <span><FinanceIcon size={16} color="#BF96D9" style={{marginTop:-2}} /> {firstSelected.name}</span>;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.inputLabel}>Select Metrics</InputLabel>
      <Select
        multiple
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={tempChartType}
        onChange={handleChange}
        renderValue={renderSelectedValue}
        MenuProps={MenuProps}
        className={classes.select}
      >
        {chartOptions.map((option) => (
          <MenuItem key={option.key} value={option.key} className={classes.menuItem}>
            <Checkbox
              checked={tempChartType.indexOf(option.key) > -1}
            />
            <ListItemText primary={option.name} className={classes.listItemText} />
          </MenuItem>
        ))}
        <div className={classes.actionButtons}>
          <Tooltip title="Cancel selection">
            <Button onClick={handleCancel} size="small" style={{ marginRight: 5 }}>
              Cancel
            </Button>
          </Tooltip>
          <Tooltip title="Apply filter">
            <Button onClick={handleApply} variant="outlined" size="small">
              Apply
            </Button>
          </Tooltip>
        </div>
      </Select>
    </FormControl>
  );
};

export default ChartTypeDropdown;
