import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Col, Progress, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import MatTableFiltersSelect from './MatTableFiltersSelect';
import MatAppBar from './MatAppBar';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import CondoDecIcon from 'mdi-react/AlphaCCircleOutlineIcon';

import LinearLoading from '../../../shared/components/LinearLoading';

import ActiveIcon from 'mdi-react/CheckDecagramIcon';

import CheckedIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';

import OfferingStatus from '../../../shared/components/OfferingStatus';
import BuildingIcons from '../../../shared/components/BuildingIcons';


import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage
} from "../../../shared/helpers/utils";

import { 
	_buildingFilters	
} from "../../../shared/helpers/filters";

import queryString from "query-string";


let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      if(a['inventory_unsold'] < b['inventory_unsold']){
	      return 1;
      }
      if(a['inventory_unsold'] > b['inventory_unsold']){
	      return -1;
      }

      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    if(a['inventory_unsold'] < b['inventory_unsold']){
	      return 1;
	}
	if(a['inventory_unsold'] > b['inventory_unsold']){
	      return -1;
	}
    return 0;
  };
}
const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
	 <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';

const lsPre = "B_";
const subFilterKey = 'subFilters';

class MatTable extends PureComponent {
  state = {
    order: 'desc',
    orderBy: 'inventory_pct_unsold',
    selected: new Map([]),
	page: 0,
    rowsPerPage: 50,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	// filterFetchInProgress: false,
	filterStage: "all_projects",
	futureInventoryData: false,
	// headerHeight: 'auto',
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	// signedIn: this.props.reduxState && this.props.reduxState.user.authenticated,
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _buildingFilters, subFilterKey, lsPre),
	tableData: false,
	totalUnsoldUnits: false,
  };
  
  componentDidMount() {
  	this._getInventoryStats();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	
	    let subFilterChanged =
			_buildingFilters.filter(item => {
				return (
					this.state.subFilters[item.search_key] !==
					prevState.subFilters[item.search_key]
				);
			}).length > 0;
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.filterStage !== prevState.filterStage ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			searchTagsChange ||
			subFilterChanged
			) {
				
			  const resetPage = this.state.page === prevState.page;	
			  this._getInventoryBuildings(this.props, resetPage, {
				callback: function(response) {
					let effective_year = moment().year() - 5;
					response.docs = response.docs.map((doc, i) => {
						doc["is_completed"] =
							moment(doc["building_effective_start"]).year() >=
							effective_year;
						doc["has_sales_started"] =
							moment(doc["sales_start_date"]).year() > 1969;
						return doc;
					});
		
					this._formatStats(response, resetPage);
				}.bind(this),
				url: buildingUrl
			});
		}

	  
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page:0, start:0 });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  _getInventoryBuildings = async (query, resetPage, obj) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		
		var q = "";

		if(locQuery){
			q += locQuery;
		}
		if (this.state.filterStage !== "all_projects") {
			q += q.length > 0 ? " AND " : "";
			if (this.state.filterStage === "completed_only") {
				q += "(building_effective_start:[NOW-5YEARS TO NOW])";
			} else if (this.state.filterStage === "under_construction_only") {
				q +=
					"(sales_start_date:[NOW-5YEARS TO NOW] AND -building_effective_start:[NOW-5YEARS TO NOW])";
			}
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qVal = _buildingFilters.filter(filter => filter.search_key === key)[0].values.filter(value => value.filter === subFilterVal)[0].search_value;
					q += q.length > 0 ? " AND " : "";
					return q += `(${key}:${qVal})`;
				}
			});
		}
		
		setURLFromState(this.props, this.state.subFilters);
		
		q = q.length > 0 ? q : "*:*";
		
		let sort = "inventory_pct_unsold desc";
		if(this.state.orderBy && this.state.orderBy.length > 0){
			sort = `${this.state.orderBy} ${this.state.order}`;
		}
		
		var fq = [
			"deleted:false",
			//"key:("+_buildingIDs+")",
			//"source_organizations:NYAG",
			//"offering_verified_status:active",
			"offering_verified_status:(active \"coming soon\" \"offering plan required\" \"condo declaration vetted\")"
		];

		if (query.fq && query.fq.length > 0) {
			fq = fq.concat(query.fq);
		}

		let axiosData = {
			url: obj.url + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: sort + ', id asc',
				fl: buildingFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"json.facet": {
					total_unsold: "sum(inventory_unsold)"
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if (typeof obj.callback === "function") {
					let formattedRes = obj.callback(res);
					return formattedRes;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};

	_getInventoryStats = (newStart = false) => {
		console.log("_getInventoryStats this.props ", this.props);
		console.log("_getInventoryStats called Inventory state", this.state);

		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({
				buildingsFetchInProgress: true
			}));
		}

		this._getInventoryBuildings(this.props, true, {
			callback: function(response) {
				let effective_year = moment().year() - 5;
				response.docs = response.docs.map((doc, i) => {
					doc["is_completed"] =
						moment(doc["building_effective_start"]).year() >= effective_year;
					doc["has_sales_started"] =
						moment(doc["sales_start_date"]).year() > 1969;
					return doc;
				});
				this._formatStats(response, false);
			}.bind(this),
			url: buildingUrl
		});
	};

	_formatStats = (response, resetPage, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		// number to gate info at
		let showUnsigned = this.props.isDesktop ? this.state.showCounts.desktop : this.props.isTablet ? this.state.showCounts.tablet : this.state.showCounts.mobile;
		console.log('showUnsigned', showUnsigned)
		let formattedData = response
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
		let effective_year = moment().year() - 5;
		let hide_fields = [
			"name",
			"full_street_address",
			"display_full_street_address"
		];

		let docs = formattedData.map((doc, i) => {
			let item = Object.assign({}, doc);
			
			// adds "Sign up ..." as field value for the fields in hide_fields
			if (!signedIn && i >= showUnsigned) {
				Object.keys(doc).map(function(key, index) {
					if (hide_fields.indexOf(key) >= 0) {
						return item[key] = "Sign up to see";
					} else {
						return item[key] = doc[key];
					}
				});
			
			// replace hidden fields with originalData fields bc shouldn't be hidden 
			} else if(!signedIn && i < showUnsigned && item[hide_fields[0]].includes('Sign up to see')) {
				const originalData = this.state.originalData.docs
				Object.keys(doc).map(function(key, index) {
					if (hide_fields.indexOf(key) >= 0) {
						return item[key] = originalData[i][key];
					} else {
						return item[key] = doc[key];
					}
				});
			}
			
			// no need to do again for hidden field rerenders
			if(response) {
				item["sold"] = item["inventory_sold"];
				item["sold"] += item["inventory_not_yet_recorded"];
				item["Sold"] += item["inventory_sold"];
				item["Sold"] += item["inventory_resale"];
				item["pct_sold"] =
					100 -
					Math.round((item.inventory_unsold / item.inventory_total) * 100);
			}

			return item;
		});

		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			tableData:  docs,
			reactTableFiltered:  docs,
			originalData: redoHiddenFields ? prevState.originalData : response,
			// filterFetchInProgress: false,
			totalCount: response.numFound,
			start: resetPage ? 0 : this.state.start,
			page: resetPage ? 0 : this.state.page,
			stats: redoHiddenFields 
				? prevState.stats 
				: Object.assign(prevState.stats, {
					total_buildings: response.facets.count,
					total_units: response.facets.total_unsold,
					total_inventory: response.facets.total_inventory
			})
		}));
	};
	
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		// if(this.state.view === 'grid') {
		this._filterResponseTableData(name, eValue);
		// } else {
		// 	this._filterColumn(name, eValue);
		// }
	};

	_filterResponseTableData = (name, eValue) => {
		console.log("_filterResponseTableData eValue", name, eValue);
		if (
			!this.state.tableData ||
			(this.state.tableData && this.state.tableData.length === 0)
		) {
			return;
		}

		const filteredData =
			(name && eValue)
				? this.state.tableData.filter(item => {
						// console.log("filterData item", item)
						const inputVal = eValue;
						let nameVal, adrVal, hoodVal, boroVal;
						if (item.name) {
							// console.log('item has name')
							nameVal = item.name.toLowerCase();
						}

						if (
							(item.display_full_street_address &&
								_shouldShow(item.display_full_street_address)) ||
							(item.full_street_address &&
								_shouldShow(item.full_street_address))
						) {
							const field = item.display_full_street_address
								? "display_full_street_address"
								: "full_street_address";
							// console.log('item has adr', field)

							adrVal = item[field].toLowerCase();
						}

						if (name === 'neighborhood' && item.neighborhood && _shouldShow(item.neighborhood)) {
							const hood =
								Array.isArray(item.neighborhood) &&
								_shouldShow(item.neighborhood[0])
									? item.neighborhood[0]
									: item.neighborhood;
							hoodVal = hood.toLowerCase();
							
							return (hoodVal && hoodVal.indexOf(inputVal.hood.toLowerCase()) >= 0)
						}
						
						if (name === 'borough' && item.neighborhood && _shouldShow(item.neighborhood)) {
							const boro = item.borough ? item.borough : false;
							boroVal = boro.toLowerCase();
							return (boroVal && boroVal.indexOf(inputVal) >= 0)
						}
						
						return false;

				  })
				: this.state.tableData;

/*
		console.log(
			"filteredData",
			filteredData,
			"this.state.tableData",
			this.state.tableData
		);
*/
		this.setState(prevState => ({
			// buildingsFetchInProgress: false,
			reactTableFiltered: filteredData,
		    //tableData: filteredData,
		    //originalData: response,
		    //filterFetchInProgress:false
		}));
	};

	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, {});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}	
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, buildingsFetchInProgress
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const tableCount = totalCount;
    const paginationCount = totalCount;
    
	const table = reactTableFiltered;
	
	
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
           {/*<div className="card__title">
              <h5 className="bold-text">Select and Analyze</h5>
            </div>*/}
            <MatAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				resetSubFilter={this._resetSubFilter}
            	setSubFilter={this._setSubFilter}
            	_filters={_buildingFilters}           	
             />
           	
            
            <div className="material-table__header-info"><span className="bold-text">{tableCount > 0 ? tableCount : '--'}</span>{` `}Buildings</div>
            
             <div style={{padding:"0 10px"}}>
            	{buildingsFetchInProgress ? <LinearLoading /> : <div style={{height:20}} />}
			</div>
			{table && 
            <div className="material-table__wrap">
             
              <Table className="material-table">
                <MatTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={tableData.length}
                />
                
                
                <TableBody>
                 
                  {table
                    .map((d) => {
                      const isSelected = this.isSelected(d.id);
                      let hasContact = false;
                      
                      if(d.sales_office_details && d.sales_office_details.length > 2){
	                      try{
		                      const contactData = JSON.parse(d.sales_office_details);
		                      if(contactData && contactData.email && contactData.email.indexOf('@')>=0){
			                      hasContact = true;
		                      }
		                      
	                      }catch(e){
		                      
	                      }
                      }
                      
                      
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleBuildingClick(event, d)}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={isSelected}
                        >
                          <TableCell className="material-table__cell" padding="checkbox">
                            {/*<Tooltip title="Add to Cohort">
                            	<Checkbox checked={isSelected} className="material-table__checkbox" />
                            </Tooltip>*/}
                            {user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
	                            <Fragment>
	                            {d.offering_file_number && d.offering_file_number &&
							  		<Fragment>
							  			<IconButton onClick={(e) => { e.preventDefault();e.stopPropagation(); window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`)}} >
							  				<OpenIcon color={'#BFC4D1'} />
							  			</IconButton>
							  		</Fragment>
						  		}
						  		</Fragment>
					  		}
                          </TableCell>
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <span>{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
                            {d.offering_construction==='conversion' &&
				                    <span className="unit-type_badge">Conv</span>
			                }
							<OfferingStatus building={d} />
						  	<BuildingIcons building={d} />
						  	
					  		
                            
                            </span>
                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
                            
                            

                          </TableCell>
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	 {d.offering_construction==='conversion' 
	                          	 ?
	                          	 <SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min color={"grey"} resale={d.building_status === 'resale'} />
	                          	 :
	                          	 d.building_status === 'resale' ?
	                          	 <SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min color={"grey"} resale={d.building_status === 'resale'} />
	                          	 :
				                 <SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min resale={d.building_status === 'resale'}/>
			                  }
                          	
                          </TableCell> 
                          <TableCell
                            className="material-table__cell light-text small-text"
                            size="small" 
                            align="center"
                          >
                          	{d.building_status === 'resale' ?
	                          	<span className="light-text small-text">--</span>
	                        :  
				            	<span className="light-text small-text">{d.inventory_unsold > 0 ? _formatNumber(d.inventory_unsold) : 0}</span>
			                 } 
                          
                          
                          </TableCell>                        
                          <TableCell
                            className="material-table__cell light-text small-text"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{_formatNumber(d.inventory_total)}</span>
                          </TableCell>
                           <TableCell
                            className="material-table__cell light-text small-text"
                            size="small" 
                            align=""
                          ><span className="light-text small-text">{_capitalizeText(calcuateSalesStart(d, 'fromNow'))}</span>
                          </TableCell>                       
                          <TableCell
                            className="material-table__cell"
                            align=""
                          ><span className="light-text small-text">{d.inventory_stats_listings_average_ppsf > 100 ? `$${_formatNumber(d.inventory_stats_listings_average_ppsf)}` : '--'}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="right"
                          ><span className="light-text small-text">{d.inventory_stats_listings_low_price > 100 ? `${_formatPrice(d.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(d.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</span>
                          </TableCell>
                          {/* <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          ><span className="light-text small-text"><CurrentStatus item={d} /></span>
                          </TableCell>*/}
                          
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                
              </Table>
              
            </div>
            }
            {paginationCount ? <TablePagination
	              component="div"
	              className="material-table__pagination"
	              count={paginationCount}
	              rowsPerPage={rowsPerPage}
	              page={page}
	              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
	              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
	              onChangePage={this.handleChangePage}
	              onChangeRowsPerPage={this.handleChangeRowsPerPage}
	              rowsPerPageOptions={[25, 50, 100]}
	              dir="ltr"
	              SelectProps={{
	                inputProps: { 'aria-label': 'rows per page' },
	                native: true,
	              }}
	            /> : null}

          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(MatTable));