import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import SpeedometerIcon from 'mdi-react/SpeedometerIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import CryptoJS from 'crypto-js';
import OpenIcon from 'mdi-react/OpenInNewIcon';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	urlSlugBuilding
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';


  
const calculateVelocity = (buildingRes, statsRes, period) => {	
	
	if(!buildingRes.sales_start_date) return '--';
	
	let totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	let months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);	
	
	if(period && period === "1-yr"){
		if(statsRes && statsRes.sold_1_yr && statsRes.sold_1_yr.count >= 0){
			totalSold = statsRes.sold_1_yr.count;
			if(months > 12){
				months = 12;
			}
		}
	}else if(period && period === "3-mo"){
		if(statsRes && statsRes.sold_3_mo && statsRes.sold_3_mo.count >= 0){
			totalSold = statsRes.sold_3_mo.count;
			if(months > 3){
				months = 3;
			}
		}
	}else if(period && period === "6-mo"){
		if(statsRes && statsRes.sold_6_mo && statsRes.sold_6_mo.count >= 0){
			totalSold = statsRes.sold_6_mo.count;
			if(months > 6){
				months = 6;
			}
		}
	}
	
	
	const velocity = (totalSold / months).toFixed(1);
	
	
	const soldPct = totalSold / buildingRes.inventory_total;
	if(soldPct < .01){
		return '--';
	}
	
	return velocity;
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes, statsRes, period) => {	
	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalSold, fill: getDefaultChartColors[0] },
  				{ value: totalAvail, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const SalesVelocity = (props, { t }) => {
	const { buildingRes, location, statsRes, period, activityStatsRes } = props;	
	const base_url = location.pathname.replace('analysis', 'units');
	
	return (
		  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
		    <Card>
		      <CardBody className="dashboard__health-chart-card">
		        <div className="card__title">
		          <h5 className="bold-text card__title-center">{`Sales Velocity (${period === '3-mo' ? '3 Mo Avg' : period === '6-mo' ? '6 Mo Avg' : period === '1-yr' ? '12 Mo Avg' : 'All Time'})`}
		          		<ToolTip 
			        		title={`Average monthly velocity based on the  ${period === '3-mo' ? 'last 3 months of sales' : period === '6-mo' ? 'last 6 months of sales' : period === '1-yr' ? 'last 1 year of sales' : ' sales since launch'} .`} 
			        	/>	          
		          </h5>
		        </div>
		        <div className="dashboard__health-chart"> 
		          <ResponsiveContainer height={180}>
		            <PieChart>
		              <Pie data={calculateBarData(buildingRes, activityStatsRes, period)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
		            </PieChart>
		          </ResponsiveContainer>
		          <div className="dashboard__health-chart-info">
		            <SpeedometerIcon style={{ fill: getDefaultChartColors[0] }} />
		            <p className="dashboard__health-chart-number">{calculateVelocity(buildingRes, activityStatsRes, period)}</p>
		            <p className="dashboard__health-chart-units">sales/mo</p>
		          </div>
		        </div>
		        
		        <p className="dashboard__goal"><a className="link-text" href={`/analysis/market-pulse?building=${urlSlugBuilding(buildingRes)}&building_stage=all&event_types=contracts-reported-signed&gap=_plus_1MONTH&group_view=building&order=desc&order_by=count&sold_time=event_time&start_date=${moment(buildingRes.sales_start_date).format("YYYY-MM-DD")}T04%3A00%3A00.000Z&unit_stage=sponsor`} target="_blank">See Monthly Sales Trend <OpenIcon size={12} style={{marginTop:-1}}/></a></p>
		      </CardBody>
		    </Card>
		  </Col>
	)
};

export default SalesVelocity;
