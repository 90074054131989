import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 

import{
	_capitalizeText,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

  
const calculateAbsorption = (buildingRes) => {		
	if(!buildingRes.sales_start_date) return '--';	
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + buildingRes.inventory_contract;
	const months = moment().diff(moment(buildingRes.sales_start_date), 'months', true);	
	const velocity = totalSold / months;
	
	if(velocity <=0){
		return '--';
	}
	
	const monthsLeft = (buildingRes.inventory_unsold / velocity).toFixed(1);	
	return monthsLeft;
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calculateBarData = (buildingRes) => {		
	const totalSold = buildingRes.inventory_sold + buildingRes.inventory_resale + buildingRes.inventory_not_yet_recorded + + buildingRes.inventory_contract;
	const totalAvail = 	buildingRes.inventory_total - totalSold;
	return [{ value: totalAvail, fill: getDefaultChartColors[1] },
  				{ value: totalSold, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}

const calcuateSellout = (buildingRes, type) => {		
	const monthsLeft = calculateAbsorption(buildingRes);
	if( monthsLeft === '--' ){
		if(type === 'fromNow'){
			return 'Not Enough Info';
		}else{
			return null;
		}
		
	}
	
	
	if(type === 'fromNow'){
		return moment().add(monthsLeft, 'M').fromNow();
	}	
	return `(${moment().add(monthsLeft, 'M').format("M/D/YYYY")})`;
}

const AbsorptionRate = (props, { t }) => (
  <Col md={12} xl={6} lg={6} sm={12} xs={12}>
    <Card>
      <CardBody className="dashboard__health-chart-card">
        <div className="card__title">
          <h5 className="bold-text card__title-center">{`Absorption Rate`}</h5>
        </div>
        <div className="dashboard__health-chart"> 
          <ResponsiveContainer height={180}>
            <PieChart>
              <Pie data={calculateBarData(props.buildingRes)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
            </PieChart>
          </ResponsiveContainer>
          <div className="dashboard__health-chart-info">
            <ClockOutlineIcon style={{ fill: getDefaultChartColors[1] }} />
            <p className="dashboard__health-chart-number">{calculateAbsorption(props.buildingRes)}</p>
            <p className="dashboard__health-chart-units">months remaining</p>
          </div>
        </div>
        <p className="dashboard__goal">Est Sellout: {_capitalizeText(calcuateSellout(props.buildingRes, 'fromNow'))} {calcuateSellout(props.buildingRes)}</p>
      </CardBody>
    </Card>
  </Col>
);

AbsorptionRate.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(AbsorptionRate);
