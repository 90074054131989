import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';


import{
	_axiosCall
} from '../../../shared/helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/buyerslist/imageupload';


class DropZoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    ]).isRequired,
  };

  static defaultProps = {
    customHeight: false,
  };

  constructor() {
    super();
    this.state = {};
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
	  
    const { onChange } = this.props;
    
    var formData = new FormData();
	
	files.map(file => {
		formData.append('file', file);
	});
	
	  let photoData = {
			url: apiUrl,
			method: "post",
			query: formData
	  };
	  
	  
	  _axiosCall(photoData)
	    .then(res => {
	      console.log("onDrop res back ,", res);
	       onChange(res.map(fl => 
	       {
		      return { preview: fl };
		   }
		  ));
	
	  })
	  .catch(error => {
	    console.log("onDrop error ", error);
	  });
   
  }

  removeFile(index, e) {
    const { onChange, value } = this.props;
	const files = value && !Array.isArray(value) ? [{preview: value}] : value;    
	e.preventDefault();
    onChange(files.filter((val, i) => i !== index));
  }

  render() {
    const {
      value, customHeight, name
    } = this.props;

    const files = value && !Array.isArray(value) ? [{preview: value}] : value;
console.log(files);
    return (
      <div className={`dropzone dropzone--single ${name == "logo" ? "dropzone--logo" : "dropzone--avatar"}`}>
        <Dropzone
          accept="image/jpeg, image/png"
          name={name}
          multiple={false}
          onDrop={(fileToUpload) => {
            this.onDrop(fileToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
	       <Tooltip title="For best results, use a 400 X 400 pixel photo">
            <div {...getRootProps()} className="dropzone__input">
               {(!files || files.length === 0)
              && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" /> Upload
              </div>
              )}
              <input {...getInputProps()} />
            </div>
           </Tooltip>
          )}
        </Dropzone>
         {files && Array.isArray(files) && files.length > 0
        && (
	    
        <aside className="dropzone__img">
          <img src={files[0].preview} alt="drop-img" />
          {/*<p className="dropzone__img-name">{files[0].name}</p>*/}
          <button className="dropzone__img-delete" type="button" onClick={e => this.removeFile(0, e)}>
            Change
          </button>
        </aside>
        
        )}
      </div>
    );
  }
}

const renderDropZoneField = (props) => {
  const { input, customHeight } = props;
  return (
    <DropZoneField
      {...input}
      customHeight={customHeight}
    />
  );
};

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  customHeight: PropTypes.bool,
};

renderDropZoneField.defaultProps = {
  customHeight: false,
};

export default renderDropZoneField;
