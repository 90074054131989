import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { getDefaultChartColors } from '../../../shared/helpers';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
} from '../../../shared/helpers/utils';


const CurrentProgress = (props) => {
	if(!props.facetData) return null;
	
	const total_count = props.facetData.total_inventory;
 	const total_active = props.facetData.total_inventory_active;
 	const total_contract = props.facetData.total_inventory_contract;
 	const total_sold = props.facetData.total_inventory_sold + props.facetData.total_inventory_not_yet_recorded;
 	const total_resale = props.facetData.total_inventory_resale;
 	const total_not_yet_recorded = props.facetData.total_inventory_not_yet_recorded
 	
 	const total_shadow = total_count - (total_active + total_contract + total_sold + total_resale);

	
	return (
		    <Card>
		      <CardBody className="dashboard__booking-card">
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		            {_formatNumber(total_count)} 
		          </h5>
		          <TrendingUpIcon className="dashboard__trend-icon" />
		        </div>
		        <h5 className="dashboard__booking-total-description">
		        	 Total New Development Units
		        </h5>	 
		        <div className="progress-wrap progress-wrap--big progress-long">
		        	<p className="dashboard__booking-card-progress-label">
		        		 ({(((total_shadow + total_active) / total_count) * 100).toFixed(0)}%) Unsold
		        	</p>
				    <Progress multi>
				    {total_shadow>0 &&
				        <Progress bar color="shadow" value={total_shadow} max={total_count}>
				        	{/*((total_shadow / total_count) * 100).toFixed(0)}% */}
							<Link to={`/inventory?offering_status=shadow`}>	{_formatNumber(total_shadow)} Shadow</Link>	
						</Progress>
				    }
				    {total_active>0 &&
				        <Progress bar color="active" value={total_active} max={total_count}>
				        	{/*((total_active / total_count) * 100).toFixed(0)}% */}
				        	<Link to={`/inventory?offering_status=active`}>{_formatNumber(total_active)} Active</Link>			
				        </Progress>
				    }				    
				    {total_contract>0 &&
				        <Progress bar color="contract" value={total_contract} max={total_count}>
				        	{/*((total_contract / total_count) * 100).toFixed(0)}%*/ }
				        	<Link to={`/inventory?offering_status=in-contract`}>{_formatNumber(total_contract)} Contract</Link>				
				        </Progress>
				    }
				    {total_sold>0 &&
				        <Progress bar color="sold" value={total_sold} max={total_count}>
				        	{/*((total_sold / total_count) * 100).toFixed(0)}% */}
				        	<Link to={`/inventory?offering_status=closed`}>{_formatNumber(total_sold)} Sold	</Link>			
				        </Progress>
				    }
				    {total_resale>0 &&
				        <Progress bar color="resale" value={total_resale} max={total_count}>
				        	{/*((total_resale / total_count) * 100).toFixed(0)}% */}
				        	<Link to={`/inventory?offering_status=resale`}>{_formatNumber(total_resale)} Resale	</Link>			
				        </Progress>
				    }
				    
				    </Progress> 				    
				</div>     
		      </CardBody>
		    </Card>
	)
};

export default CurrentProgress;
