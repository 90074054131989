import React, { PureComponent } from 'react';
import { Alert, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import ComingIcon from 'mdi-react/ProgressClockIcon';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';


export default class AlertComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    icon: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    color: '',
    icon: false,
    className: '',
    alertId:''
  };

  constructor(props) {
    super(props);
    
    const { alertId, minimizeText, hideOnStart, noClose } = this.props;

    this.state = {
      visible: hideOnStart && !localStorage.getItem(alertId) ? false : localStorage.getItem(alertId) === 'hide' ? false : noClose ? true : false,
    };
  }

  onShow = () => {
	const { alertId } = this.props;
    this.setState({ visible: true });
    localStorage.setItem(alertId, 'show');
  };

  onDismiss = () => {
	const { alertId } = this.props;
    this.setState({ visible: false });
    localStorage.setItem(alertId, 'hide');
    
  };

  render() {
    const { visible } = this.state;

    const {
      color, className, icon, children, iconItem, minimizeText, noClose, search, condensedIcon, minimizeHelpText
    } = this.props;
    let Icon;
    
    if(iconItem){
	    Icon = iconItem;
    }else{
	    switch (color) {
	      case 'info':
	        Icon = <InformationOutlineIcon />;
	        break;
	      case 'success':
	        Icon = <ComingIcon />;
	        break;
	      case 'warning':
	        Icon = <CommentAlertOutlineIcon />;
	        break;
	      case 'danger':
	        Icon = <CloseCircleOutlineIcon />;
	        break;
	      default:
	        break;
	    }
	    
    }

    
    
    

    if (visible) {
      return (
        <Alert color={color} className={className} isOpen={visible}>
          {icon && <div className="alert__icon">{Icon}</div>}
          {!noClose && 
	          <Tooltip title={'Close'} className={"close"}>
	          	<IconButton
	              aria-label="Close"
				  onClick={this.onDismiss}
	              color="inherit"	              	              
	            >
	          			<CloseCircleOutlineIcon style={{color:'#858AA1'}}/>
			  		</IconButton>
			  </Tooltip>
	       }
          <div className={`alert__content ${!icon ? "no_icon" : ""}`}>
            {children}
          </div>
        </Alert>
      );
    }

    return <a className={`alert_show ${search ? " search_filter" : ""}`} onClick={this.onShow}>{condensedIcon ? condensedIcon : <InformationOutlineIcon size={14} />} {minimizeText ? minimizeText : 'Show Notice'} 
    	{minimizeHelpText && <Tooltip title={minimizeHelpText}><IconButton size={`small`} style={{background:"transparent"}}>
    		<HelpCircleOutlineIcon size={13} color={'#780F9E'}/></IconButton></Tooltip>}
    </a>;
  }
}
