import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, Collapse
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import DownIcon from 'mdi-react/PhoneClassicIcon';
import EmailIcon from 'mdi-react/EmailIcon';

import Button from '@material-ui/core/Button';


import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../../shared/helpers/apicalls';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

import Overview from './../Overview';

import Profile from './../Profile';

import AddToBuyersListButton from '../../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../../shared/components/buttons/ShareButton';

import ProductCard from './../Profile/components/ProfileCard';
import Timeline from './../../components/Timeline';
import ShowMap from './../../../../shared/components/map';

import BuildingProductCard from './../../../SingleBuilding/components/Profile/components/ProfileCard';
import queryString from "query-string"

import ShareTopBar from './../../../../shared/components/ShareTopBar'
import ShareContact from './../../../../shared/components/ShareContact'
import ShareFooter from './../../../../shared/components/ShareFooter'

import ListingIcon from 'mdi-react/TagIcon';
import StackingPlanIcon from 'mdi-react/FormatLineStyleIcon';
import WebIcon from 'mdi-react/WebIcon';

import BuildingCard from './../../../SingleBuilding/components/Profile/components/ProfileCard';
import BuildingAmenities from './../../../SingleBuilding/components/Profile/components/BuildingAmenities';
import TaxAbatement from '../../../../shared/components/TaxAbatement';
import { Publisher } from './../../../../shared/pubsub/publisher.js';
import ExportButton from './../../../SharePage/ExportButton';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	_privateKey,
	_scrollID,
	UnitCurrentStatus,
	_slugifyText
} from '../../../../shared/helpers/utils';
import{
	_getUser
} from '../../../../shared/components/auth/sso/ssoutils';
const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;


class SingleUnitShare extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
      latestUnit: false,
      unitHistory: false,
      property: false,
      listing: null,
      fetchInProgress: false,
      buildingRes: false,
      collapse: false,
      statsRes: false,
      userRes:false,
	  transactions:null
    };
    this.buildingSlug = false;
	this.unit = false;
	this.urlState = {};
  }
  
  
  
  componentDidMount() {
	const { history, location, sharePageData } = this.props;

console.log("componentDidMount - Unit Share", this.props, window.last_tracked_mp_url)
	
	
	if(sharePageData){
		this.buildingSlug = sharePageData.building_key;
		this.unit = sharePageData.property.address_2_normalized;
		this.urlState =  sharePageData.custom_data ? sharePageData.custom_data : {};
		
		
	}else{

		const urlLink = CryptoJS.AES.decrypt(atob(this.props.match.params.encrypted_key), _privateKey).toString(CryptoJS.enc.Utf8); 
		
		const queryParts = urlLink.split('?');
		const pathParts = queryParts[0].split('/');
		
		this.buildingSlug = pathParts[0] ? pathParts[0] : false;
		this.unit = pathParts[1] ? pathParts[1].replace("apt-", "apt\\ ").replace(/-/g, "*") : false;
		this.urlState = queryParts[1] ? queryString.parse(queryParts[1]) : false;
	}
	
   console.log(this.buildingSlug, this.unit, this.urlState);
   this._getBuildingData();
   //if(this.urlState.ap){
	this._getUserData(this.urlState.ap);
   //}
   
   Publisher.publish(`track.shareView.unit`, {building: this.buildingSlug, unit: this.unit, label: this.buildingSlug + " - " + this.unit});

	
/*
    this.buildingSlug = CryptoJS.AES.decrypt(atob(this.props.match.params.building_slug), _privateKey).toString(CryptoJS.enc.Utf8); 
    const unitSlug = CryptoJS.AES.decrypt(atob(this.props.match.params.unit), _privateKey).toString(CryptoJS.enc.Utf8); 
	this.unit = unitSlug.replace("apt-", "apt\\ ").replace(/-/g, "*");	  
	
*/
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {		
	console.log(this.props.match.params.unit);
	const { sharePageData } = this.props;
	const { buildingRes } = this.state;
	if(prevProps.match.params.unit !== this.props.match.params.unit){
		this._getBuildingData();
	}		
	
	if(!prevState.property && buildingRes && this.state.property){
		Publisher.publish(`track-mp.shareView.unit`, {building: buildingRes, user: sharePageData && sharePageData.user ? sharePageData.user : false, property : this.state.property, report : sharePageData && sharePageData._id ? {_id : sharePageData._id} : false, checkDupURL:true  }); 
	}
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  
  _getUserData = (key) => {
     // console.log('_getBuildingData called', this.state)
      const { sharePageData, user } = this.props;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  
	  if(!(sharePageData && sharePageData.user_key) && user){
			this.setState({
				  userRes: user,
				  fetchInProgress: false
			})
			
			return false;
	  }
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${sharePageData && sharePageData.user_key ? sharePageData.user_key : key}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res && res.errorCode && res.errorCode == "E0000007"){
			  _getUser({id:sharePageData && sharePageData.user_key ? sharePageData.user_key : key}).then(ssoUser  => {
				  console.log(ssoUser)
				  if(ssoUser){
					  this.setState({
						  userRes:ssoUser,
						  fetchInProgress: false
					  })	
				  }
				  
			  })
	   	   }else{
				this.setState({
					userRes:res.profile,
					fetchInProgress: false
				})
		  }

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

  
  _getBuildingData = () => {
	  const { sharePageData } = this.props;
     // console.log('_getBuildingData called', this.state)
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	      
	  let buildingData = {
			url: apiURL + "we3-buildings?cache=1440",
			method: "post",
			query: {
				q: sharePageData ? 'key:'+this.buildingSlug : 'key:*-'+this.buildingSlug,
				fq: 'deleted:false',
				fl: buildingFL,
				wt: "json",
				rows: 1
			}
	  };
	  
	  _axiosCall(buildingData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    if(res && res.docs && res.docs[0]) {
	      this.setState({
	      	buildingRes: res.docs[0],
	      	fetchInProgress: false
	      });
	      this._getUnitData(res.docs[0]);
		  this._getUnitOPData(res.docs[0]);
		  this._getBuildingOPData(res.docs[0]);
		  this._getACRISData(res.docs[0]);
	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

  
  _getUnitOPData = async (buildingRes) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip}) AND address_2_normalized:(${this.unit})`;

		let axiosData = {
			url: apiURL + "mp-offering-plans-schedulea",
			method: "post",
			query: {
				q: q,
				fq: ["-deleted:true"],
				sort: "address_2_normalized asc",
				wt: "json",
				rows: 999,
				"json.facet": {
					avg_price: "avg(price)",
					avg_ppsf: "avg(div(price, sq_ft))",
					med_price: "percentile(price,50)",
					med_ppsf: "percentile(div(price, sq_ft),25,50,75)",
					min_price: "min(price)",
					max_price: "max(price)",
					unit_mix : {
						type : "terms",
						field: "bedrooms",
						limit: 10,	
						facet: {
							avg_unit_size : "avg(sq_ft)",
							med_unit_size: "percentile(sq_ft,25,50,75)",
							avg_fees : "avg(monthly_fees)",
							med_fees: "percentile(monthly_fees,25,50,75)",
							avg_taxes : "avg(monthly_taxes)",
							med_taxes: "percentile(monthly_taxes,25,50,75)",
							avg_ppsf: "avg(div(price, sq_ft))",
							min_price: "min(price)",
							max_price: "max(price)",
							avg_monthly_carrying_charges: "avg(monthly_carrying_charges)",
						}					
					}
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){
					const latestUnit = res.docs.filter(item => item.document_version==='latest')[0];
					const unitHistory = res.docs.filter(item => item.id.indexOf('latest')<0);
					this.setState({latestUnit: latestUnit, unitHistory: unitHistory, fetchInProgress: false});
				}else{
					this.setState({fetchInProgress: false});
				}
				
				
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };
  
  _getUnitData = async (buildingRes) => {
	    const { sharePageData } = this.props;
	    
	     if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
     	}

		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		const q = `(address_2_normalized:(${sharePageData ? '"' + this.unit + '"' : this.unit}) OR address_2:("${this.unit.toString().replace("apt ", "")}")) AND (alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip})`;

		let axiosData = {
			url: apiURL + `we3-properties?cache=15&cache_key=${_slugifyText(this.unit)}`,
			method: "post",
			query: {
				q: q,
				fq: ["deleted:false"],
				sort: "address_2_normalized asc",
				wt: "json",
				rows: 10
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){						
					const property = res.docs[0];				
					this.setState({property: property});
					
										
					if(property.offering_status === 'active' || property.last_listed_sale_key_rebny || property.last_listed_sale_key_all){						
						
						if(property.last_listed_sale_key_all){
							this._getListingData(property.last_listed_sale_key_all);
						}else{
							this.setState({listing:false, fetchInProgress: false});
						}
						
					}else{
						this.setState({listing:false, fetchInProgress: false})
					}

				}				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
  };
  _getBuildingOPData = async (buildingRes) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const q = `building:${buildingRes.key} AND document_version:latest AND baths:[1 TO *]`;

		let axiosData = {
			url: apiURL + "mp-offering-plans-schedulea",
			method: "post",
			query: {
				q: q,
				fq: ["-deleted:true"],
				sort: "address_2_normalized asc",
				wt: "json",
				rows: 999,
				"json.facet": {
					avg_price: "avg(price)",
					avg_ppsf: "avg(div(price, sq_ft))",
					med_price: "percentile(price,50)",
					med_ppsf: "percentile(div(price, sq_ft),25,50,75)",
					min_price: "min(price)",
					max_price: "max(price)",
					unit_mix : {
						type : "terms",
						field: "bedrooms",
						limit: 10,	
						facet: {
							avg_unit_size : "avg(sq_ft)",
							med_unit_size: "percentile(sq_ft,25,50,75)",
							avg_fees : "avg(monthly_fees)",
							med_fees: "percentile(monthly_fees,25,50,75)",
							avg_taxes : "avg(monthly_taxes)",
							med_taxes: "percentile(monthly_taxes,25,50,75)",
							avg_ppsf: "avg(div(price, sq_ft))",
							min_price: "min(price)",
							max_price: "max(price)",
							avg_monthly_carrying_charges: "avg(monthly_carrying_charges)",
						}					
					}
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				this.setState({statsRes: res.facets, unitsRes: res.docs});
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
  
  _getListingData = async (listingKey) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
			
		const q = `key:"${listingKey}"`;

		let axiosData = {
			url: apiURL + "search-listings",
			method: "post",
			query: {
				q: q,
				fq: ["deleted:false"],
				sort: "address_2_normalized asc",
				wt: "json",
				rows: 10
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){					
					this.setState({listing: res.docs[0]});
				}else{
					this.setState({listing: false});
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
  };
  
  _getACRISData = async (buildingRes) => {
	  		const { sharePageData } = this.props;
		   if (!this.state.fetchInProgress) {
			  this.setState(prevState => ({ fetchInProgress: true, }));
		   }
  
		  const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		  		
		 const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip}) AND address_2_normalized:(${sharePageData ? '"' + this.unit + '"' : this.unit})`;
  
		  let axiosData = {
			  url: apiURL + "we3-transactions?cache=900",
			  method: "post",
			  query: {
				  q: q,
				  fq: ["source_providers:*ACRIS*"],
				  sort: "close_date_formatted desc",
				  wt: "json",
				  rows: 10
			  }
		  };
  
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  if(res.docs && res.docs.length>0){						
					  this.setState({transactions:res.docs, fetchInProgress: false});
				  }else{
					  this.setState({transactions:false, fetchInProgress: false});
				  }				
			  })
			  .catch(error => {
				  console.log("error: " + error);
				  this.setState({transactions:false, fetchInProgress: false});
			  });
		  return formattedData;
	};
  toggle = (tab) => {
	  const { buildingRes  } = this.props;
	  const { activeTab } = this.state;
	  this.setState({
		activeTab: tab,
	  });
	};
	
  render() {
    const { rtl, sharePageData, isModal, isPDF } = this.props;
    
     
    const { activeTab, latestUnit, unitHistory, property, listing, fetchInProgress, buildingRes, collapse, userRes } = this.state;
    if(!property || listing===null || fetchInProgress || !userRes) {
	    return <LinearLoading />;
    }
    
    const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood && buildingRes.neighborhood[0] ? buildingRes.neighborhood[0] : "";
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	
	const unitNumber = _formatUnitNumber(property.address_2_normalized);
	
	const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
    }	
    
    const user = Object.assign({}, defaultUser, userRes);
    const activeResale = listing && listing.sale_status === 'active' && ['not_yet_recorded','sold','resale'].indexOf(property.offering_status) >= 0;

    return (
		<Fragment>
		<div className={`${sharePageData && sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}${isPDF ? " share-pdf-report" : ""}`}>
	    <div className={`share__wrapper ${isModal ? "is-modal" : ""}`}>
	    	<ShareTopBar user={user} urlState={this.urlState} sharePageData={sharePageData} />
	    	<Container className="dashboard share">
	    		{!isModal && <ShareContact  user={user} urlState={this.urlState} sharePageData={sharePageData}  isModal={isModal}  print={isPDF}  />}
		    	<Row>
			    	<Col md={12} lg={12} xl={12}>
				        <Card>
				          <CardBody className="no-border no-padding">   
							{!isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								<div className="top__toolbar" style={{display:"flex"}}>
									 <ContactDeveloperButton listing={listing} buildingRes={buildingRes} share  />
							   </div>  
							}        	
				            <div className="card__title" key={property.id}>
							   <div className="unit__badge">
							   
							   		{property.offering_status === 'active' ? 
								   		<a href={`https://marketproof.com${_getBuildingURL(property, 'building').replace('buildings/', 'building/')}/${_nameToUrl(property.address_2_normalized)}?tab=listing`} target="_blank">
								   			<UnitCurrentStatus status={property.current_status} unit={property} expanded />
								   		</a>
								   		:
								   		<UnitCurrentStatus status={property.current_status} unit={property} expanded />
							   		}
							   		{activeResale &&
								   		<a href={`https://marketproof.com${_getBuildingURL(property, 'building').replace('buildings/', 'building/')}/${_nameToUrl(property.address_2_normalized)}?tab=listing`} target="_blank">
								   			<Badge color="available">Active Resale</Badge>
								   		</a>
							   		}

							    </div>
				               <h3 className="page-title">
				               	<span className="header-building__link" onClick={() => {_scrollID("building", -100)}}>	
				               		{buildingNameOrAdr} 
				               	</span>	
				               		{` `}/ {unitNumber}	
				               	</h3>
						       
						       {isMobile ?
					               <Fragment>
					              <h3 className="page-subhead subhead">			        	
										{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
										{_hood ? _capitalizeText(_hood) + ", " : ""} {_capitalizeText(_boro)}
								  </h3> 
								  
								  </Fragment>
					           :
							      <h3 className="page-subhead subhead">			        	
											{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>}{_hood ? _capitalizeText(_hood) + ", " : ""}  {_capitalizeText(_boro)}
							      </h3>
						       }	
				             
				            </div>
							{isMobile && ['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 && sharePageData && sharePageData.custom_data && sharePageData.custom_data.contact_broker && 
								   <div className="top__toolbar" style={{textAlign:"center"}}>
										<ContactDeveloperButton  listing={listing}  buildingRes={buildingRes} share  />
								  </div>  
							   } 
				          </CardBody>
				        </Card>
				      </Col>
				</Row>
				{!isPDF && 
				<div className="tabs tabs--bordered-bottom">
				  <div className="tabs__wrap">
					<Nav tabs>
					  <NavItem>
						<NavLink
						  className={classnames({ active: activeTab === 'overview' })}
						  onClick={() => {
							this.toggle('overview');
						  }}
						>
						  <span className="tab-title">Overview</span>
						</NavLink>
					  </NavItem>
					 
					  {!(buildingRes.ownership_type && ['condop', 'coop'].indexOf(buildingRes.ownership_type.toLowerCase())>=0) &&
					  <NavItem>
						<NavLink
							className={classnames({ active: activeTab === 'taxes' })}
							onClick={() => {
							  this.toggle('taxes');
							}}
						  >
							<span className="tab-title">Taxes</span>
						  </NavLink>
						</NavItem>}
					
									   
					</Nav>
					
				  </div>            
				</div>
				}
				
				
				<TabContent activeTab={activeTab}>
				  <TabPane tabId="overview">
					   <Row>
				  		<ProductCard {...this.state} urlState={this.urlState} setTab={this.toggle.bind(this)} share isPDF={isPDF}  />
						</Row>	  
						<Row>
						  <Col md={12} >
							<h3 className="section-title section-title--not-last">Location</h3>
							<h3 className="section-title section-title--not-last subhead"> </h3>
						  </Col>
						</Row>
						<Row className="mb-2">
							<Col md={12}>
								<Card>
								 <CardBody>
								<ShowMap item={this.state.buildingRes} print={isPDF} />
								 </CardBody>
								</Card>
							</Col>
						</Row>
						
						<Row>
							<Timeline {...this.state} />
						</Row>
				  </TabPane>
					
					{!(buildingRes.ownership_type && ['condop', 'coop'].indexOf(buildingRes.ownership_type.toLowerCase())>=0) && activeTab === 'taxes' &&
						  <TabPane tabId="taxes" className="" style={{minHeight:"50vh"}}>
							  <TaxAbatement latestUnit={latestUnit} unitHistory={unitHistory} property={property} listing={listing} {...this.state} {...this.props} activeResale={activeResale} />              	           
						  </TabPane>
					  }
				  
				  </TabContent>  
				
			
			    
			   

			</Container>
			
			<Container id="building" className="dashboard share mt-5 pt-5">
			    <Row>
			      <Col md={12} lg={12} xl={12}>
			        <h3 className="page-title">About {buildingNameOrAdr}</h3>
			        <h3 className="section-title section-title--not-last subhead mb-3"> </h3>
			      </Col>
			      
			    </Row>

			    <Row>
			       
			      <BuildingCard {...this.state} property={false} share/>
			    </Row>
			 
			    <Row>
			    	<Col md={12}>
			    	 <h3 className="section-title section-title--not-last">Amenities & Features</h3>
					 <h3 className="section-title section-title--not-last subhead"> </h3>
					 <hr/>
			    	 <BuildingAmenities {...this.state} />
					</Col>
			    </Row>
			    <Row>
				  <Col md={12}>
						<p className={"small-text light-text mt-4 mb-2"} style={{color:'#858AA1'}}>All information is from sources deemed reliable but is subject to errors and omissions. No representation is made as to the accuracy of any information. All information is subject to change. All rights to content, photographs, and graphics are reserved for the content source.</p>
					</Col>
				 </Row>
			    {/*<Row>
			     	<Col md={2} />
			    	<Col md={8}>
						<div className="mp-signup">
					 		<span>
					 		<h4>Not a Marketproof Subscriber?</h4>
					 		<p>Access all NYC new development and condo inventory, past sales for 250K condo units and pipeline of future projects.</p>
					 		</span>
					 		<Button
						         variant=""
						         color="primary"
						         className="buyersList__secondary-button"
						         onClick={(e) => {e.stopPropagation();window.open(`https://marketproof.com/new-development`)}}		         
						         
						     >	Sign Up Here
						     </Button>	
						 </div>
				
      
				    </Col>
				    <Col md={2} />
			    </Row>*/}

			    {/*buildingRes.description && buildingRes.description[0] &&
				<Fragment>
				    <Row>
				      <Col md={12}>
				        <h3 className="section-title section-title--not-last">Description</h3>
				        <h3 className="section-title section-title--not-last subhead"> </h3>
				       
				       
				    	<hr/>
				    	</Col>
					</Row>
				    <Row>
				    	 <Col xl={12} lg={12} className="mb-2">
				    	 
				               <p className="typography-message">
				               {buildingRes.description[0].replace(/Building\n/g, '').replace(/Residences\n/g, '\n').replace(/Amenities\n/g, '\n').replace(/\n\n/g, '\n').replace(/^\n/, '').split('\n').map((item, key) => {
									return <React.Fragment>{item}<br/></React.Fragment>
								})}
				               </p>
				           
				    	
				        </Col>
					</Row>
				</Fragment>
				*/}  
				
			</Container>
			<ShareFooter sharePageData={sharePageData} />
			
		</div>
		</div>
		{isPDF && <ExportButton sharePageData={sharePageData} title={`${buildingNameOrAdr} - ${unitNumber} Property Report`} />}
		</Fragment>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(SingleUnitShare)));
