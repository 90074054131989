import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Collapse, Modal, ButtonToolbar } from 'reactstrap';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import ActiveIcon from 'mdi-react/CheckDecagramIcon';
import ComingIcon from 'mdi-react/AlertDecagramOutlineIcon';
import VettedIcon from 'mdi-react/AlertDecagramOutlineIcon';


import{
	_capitalizeText,
} from '../helpers/utils';

const apiRoot = process.env.API_ROOT;


class OfferingStatus extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
      modal:false
    };
   
  }
  
  componentDidMount() {	  
	
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){

		 
	 
  }

  toggle = (e) => {
	  
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({modal:!this.state.modal});
  }
 

  render(){
	  const { modal } = this.state;
	  const { building } = this.props;
	  const modalClass = classNames({
	      'modal-dialog--colored': false,
	      'modal-dialog--header': true,
	   });
	  if(building.offering_verified_status === 'active' ){
		  return (
			<Fragment>
				<span className="offering-status">
		  			<Tooltip title="Vetted by Marketproof">
		  				<IconButton size={`small`} onClick={this.toggle} style={{ marginTop:"-3px", marginLeft:"5px"}} >
		  					<ActiveIcon size={16} color={'#780F9E'} />
		  				</IconButton>
		  			</Tooltip>
		  		</span>		  		
		  		<Fragment>
			        <Modal
			          isOpen={modal}
			          toggle={this.toggle}
			          modalClassName={`ltr-support`}
					  className={`modal-dialog--primary ${modalClass}`}
			        >
			        <div className="modal__header">
				        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				        <h4 className="text-modal  modal__title"><ActiveIcon size={24} color={'#EDDEF5'} /> Vetted by Marketproof</h4>
				      </div>
				      <div className="modal__body">
				      	<p style={{fontSize:16}}>This building has undergone a series verification steps by a Marketproof New Development analyst to help ensure information accuracy.</p>
				      	{/*<p>Including:</p>
				      	<ul>
				      		<li>Offering plan data extraction.</li>
				      		<li>Offering plan amendment data extraction.</li>
				      		<li>Dept. of Buildings cross reference.</li>
				      		<li>Dept. of Finance cross reference.</li>
				      		<li>Market data verification.</li>
				      		<li>News data verification.</li>
				      	</ul>*/}
				      	      
				      </div>
				      
				      <ButtonToolbar className="modal__footer offering-status">
				        {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
				        <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
				        	Ok
				        </Button>
				      </ButtonToolbar>
			        </Modal>
			    </Fragment>
		    </Fragment>
		  )
	  }
	  
	  if(['coming soon' , 'offering plan required', 'condo declaration vetted'].indexOf(building.offering_verified_status) >= 0 ){
		  return (
			<Fragment>
				<span className="offering-status">
		  			<Tooltip title="Vetting in Progress">
		  				<IconButton size={`small`} onClick={this.toggle} style={{ marginTop:"-3px", marginLeft:"5px"}}>
		  					<ComingIcon size={16}  color={'#780F9E'} />
		  				</IconButton>
		  			</Tooltip>
		  		</span>		  		
		  		<Fragment>
			        <Modal
			          isOpen={modal}
			          toggle={this.toggle}
			          modalClassName={`ltr-support`}
					  className={`modal-dialog--primary ${modalClass}`}
			        >
			        <div className="modal__header">
				        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
				        <h4 className="text-modal  modal__title"><ComingIcon size={24} color={'#EDDEF5'} /> Vetting in Progress</h4>
				      </div>
				      <div className="modal__body">
				      	<p style={{fontSize:16}}>Marketproof New Development is currently processing this building and verifying information.  More details will be added to this building shortly.</p>				    
				      </div>
				      
				      <ButtonToolbar className="modal__footer offering-status">
				        {/*<Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}*/}
				        <Button className="modal_ok btn btn-primary" onClick={this.toggle} color={`primary`}>
				        	Ok
				        </Button>
				      </ButtonToolbar>
			        </Modal>
			    </Fragment>
		    </Fragment>
		  )
	  }
	  
	  return null;

	}
};


export default OfferingStatus;
