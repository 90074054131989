import React, { Fragment } from "react";
import ConciergeIcon from 'mdi-react/BellRingOutlineIcon';
import DoormanIcon from 'mdi-react/AccountKeyOutlineIcon';
import ElevatorIcon from 'mdi-react/ElevatorIcon';
import GardenIcon from 'mdi-react/FlowerTulipOutlineIcon';
import RoofDeckIcon from 'mdi-react/WeatherSunnyIcon';
import PoolIcon from 'mdi-react/PoolIcon';
import FitnessIcon from 'mdi-react/DumbbellIcon';
import ParkingIcon from 'mdi-react/CarBrakeParkingIcon';
import PetIcon from 'mdi-react/DogServiceIcon';
import { 
  _capitalizeText, 
  _getBuildingAge,
  _getFeaturesArr, 
  _isCommerical,
  _isHouse,
  _scrollID,
  _shouldShow 
} from './utils'

export const amenitiesList = [
  {
    name: "concierge",
    icon: <ConciergeIcon />,
    group: "building_service_level",
    values: [
      "concierge",
      // "staffandsecurity-*concierge-*",
      // "staffandsecurity-concierge*-full-time"
    ],
  },
  {
    name: "doorman",
    icon: <DoormanIcon />,
    values: [
      "doorman-full-time",
      "doorman_full_time",
      "doorman",
      "fulltime-doorman",
      "full-time-doorman",
      "staffandsecurity-*doorman-*",
      "staffandsecurity-doorman*-full-time",
      "doorman-part-time",
      "doorman_part_time",
      "staffandsecurity-doorman*-part-time",
    ],
    group: "building_service_level",
  },
  {
    name: "elevator",
    icon: <ElevatorIcon />,
    group: "building_access",
    values: [
      "elevator",
      "features-elevators-2",
      "features-elevators-1",
      "features-elevators-3",
      "features-elevators-4",
      "features-elevators-6",
      "features-elevators-5",
      "features-elevators-8",
      "features-elevators-7"
    ],
  },
  {
    name: "garden",
    icon: <GardenIcon />,
    group: "amenities_outdoor_space",
    values: [
      "garden",
      "facilities-outdoorspace-garden"
    ],
  },
  {
    name: "roof deck",
    icon: <RoofDeckIcon />,
    group: "amenities_outdoor_space",
    values: [
      "roof-deck",
      "facilities-outdoorspace-roof-deck",
      "deck",
      "features-customfeatures-roof-deck"
    ],
  },
  {
    name: "swimming pool",
    icon: <PoolIcon />,
    values: [
      // "swimming_pool",
      "pool",
      "swimming",
      "swimming-pool",
      "facilities-fitness-pool"
    ],
    group: "amenities_health",
  },
  {
    name: "fitness center",
    icon: <FitnessIcon />,
    group: "amenities_health",
    values: [
      "fitness-room",
      "fitness-center",
      "gym",
      "gymnasium",
      "health-club",
      "fitness-facility",
      "fitness-room",
      "facilities-fitness-fitness-center",
      "facilities-fitness-health-club",
      "features-customfeatures-fitness-center",
      "features-customfeatures-gym"
    ],
  },
  {
    name: "parking",
    icon: <ParkingIcon />,
    group: "amenities_auto",
    values: [
      "parking",
      "parking-available",
      "outdoor-parking",
      "garage",
      "features-customfeatures-garage",
      // "parking_garage",
      "parking-garage",
      "facilities-parking-parking-garage"
    ],
  },
  {
    name: "pets allowed",
    icon: <PetIcon />,
    group: "building_policies",
    values: [
      "pets-allowed",
      "pets-allowed-case-by-case",
      "cats-allowed"        
    ],
  },
];

export const _isRunningOnServerSide = () => {
    return typeof(window) === "undefined";
};

export const _isRunningOnClientSide = () => {
    return typeof(window) !== "undefined";
};


export const _getArrLen = (arr, len) => {
  if(!arr || arr.length === 0) {return;}
  let slicedArr = [...arr];
  
  if(arr > len) {
    slicedArr = slicedArr.slice(0, len);
  } 
  return slicedArr;
}