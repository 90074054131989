import React, { PureComponent, Fragment } from 'react';
import { Col, Container, Row,  Card, CardBody } from 'reactstrap';import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setUserProfile } from '../../redux/actions/authActions';

import SubscriptionForm from './components/SubscriptionForm';
import BillingForm from './components/BillingForm';
import DataUsage from './components/DataUsage';
import Invoices from './components/Invoices';
import CustomSubscription from './components/CustomSubscription';

import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';

import showResults from './Show';

import SnackBar from '../../shared/components/SimpleSnackBar';

import TrialAlert from '../../shared/components/payment/TrialAlert';

import QuickStartGuide from '../../shared/components/QuickStartGuide';

import{
	_capitalizeText,
} from '../../shared/helpers/utils';
import{
	_axiosCall
} from '../../shared/helpers/apicalls';
const oktaToken = process.env.OKTA_TOKEN;

const apiUrl = process.env.API_ROOT + '/buyerslist/';
//const apiUrl = 'http://localhost:8000/buyerslist/';

//const apiUrl = 'https://newsapi.we3.com/buyerslist/'

class BillingProfile extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      snackShow: false,
	      snackMessage: false,
	      snackType: 'success',
	      customer: false,
	      subscription: false,
	      paymentMethod: false,
	      invoices:false,
	      refresh:false,
	      userCreditUsed: false,
	      customSubscription: false,
		  coupon: false,
		  subscriptionSchedule:false
	    };
	    this.updateUser = this.updateUser.bind(this);
	    this._cancelSubscription = this._cancelSubscription.bind(this);
	    	
	    this.snackOpen = this.snackOpen.bind(this);
	    this.snackClose = this.snackClose.bind(this);
	    this._getSubscriptionDetails = this._getSubscriptionDetails.bind(this);
	    this._getInvoices = this._getInvoices.bind(this);
	    this.toggleRefresh = this.toggleRefresh.bind(this);
	    this._getUserCredits = this._getUserCredits.bind(this);
		this.setCoupon = this.setCoupon.bind(this);
		this._updateSubscriptionDetails = this._updateSubscriptionDetails.bind(this);
		this._releaseSubscriptionSchedule = this._releaseSubscriptionSchedule.bind(this);
	    
	}
	  
    componentDidMount(){
	  const { authState, authService, dispatch, user } = this.props;
	  console.log(authState);
	  this._getSubscriptionDetails();
    }
    componentDidUpdate(prevProps, prevState) {
	    
	    const { user } = this.props;
	    
	    if(prevState.refresh !== this.state.refresh ||
	   		 (prevProps.user.subscriptionStatus !== user.subscriptionStatus) ||
	   		 (prevProps.user.subscriptionPlan !== user.subscriptionPlan)){
		     this._getSubscriptionDetails();

	    }
	    
	}
    updateUser(values) {
	    console.log(values);
	    this._updateUserProfile(values);
	  
	}
	
	toggleRefresh = () => {
    	this.setState(prevState => ({ refresh: !prevState.refresh }));;
    };
	
	snackOpen = (message, type) => {
    	this.setState( { snackShow: true, snackMessage: message, snackType : type} )
    };

    snackClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }	
	    this.setState( { snackShow: false, snackMessage: ''} )
	};
	
	_getSubscriptionDetails = () => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
 
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  /**User without a subscripton - prepaid or custom plan**/
	  if(user.downloadAccess === 'manual'){
		  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
		  var firstDay = Math.floor(new Date(y, m, 1).getTime() / 1000);
		  var lastDay = Math.floor(new Date(y, m + 1, 0).getTime() / 1000);
		  const subscription = {
			  current_period_start:firstDay,
			  current_period_end:lastDay
		  }
		  this._getUserCredits(subscription);
		  this.setState({
			  customer: this.props.user,
		      subscription: {},
		      paymentMethod: {},
		      customSubscription: true
	      });
	      
	      return false;
	  }
	   /**User without a subscripton - prepaid or custom plan**/

	  let userData = {
			url: apiUrl+'stripe/get-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.customer){	
		      this._getUserCredits(res.subscription);
		      this.setState(
			      {
				      customer: res.customer,
				      subscription: res.subscription,
				      paymentMethod: res.paymentMethod,
					  subscriptionSchedule: res.subscriptionSchedule ? res.subscriptionSchedule : false
			      }
		      )
		      if(res.customer && res.customer.id){
			      this._getInvoices(res.customer.id);
		      }
		      
		  }else{
			  this.setState({
				  customer: user,
			      subscription: {},
			      paymentMethod: {},
				  subscriptionSchedule: false
		      });		  
						  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });

	}
	_updateSubscriptionDetails = (couponResult) => {
	  
	  const { changePlan, user } = this.props;
	  const { subscriptionId } = user;
	  const { subscription, coupon } = this.state;
	  
	  
	  if(!subscriptionId) return false;
	  
	  
	  if(this.state.fetchInProgress) return false;
	  
	  this.setState({fetchInProgress : true});
	
	  let userData = {
			url: apiUrl+'stripe/update-subscription',
			method: "post",
			query: {
				subscription_id: subscriptionId,
				subscriptionData: {
				  coupon : couponResult && couponResult.id ? coupon.id : ''
				}
			}
	  };
	 
		
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  
		  if(res && res.subscription && res.subscription.id){	
			 
			 this.setState(
				   {
					   
					   subscription: res.subscription,
				   }
			   )
			  
		  }else{
			 if(res && res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
			 }		  
		  }
	  })
	  .catch((error) => {
		console.log("_getUserData error ", error);
	  });
	
	
	}
	_releaseSubscriptionSchedule = () => {
	  
	  const { changePlan, user } = this.props;
	  const { subscriptionId } = user;
	  const { subscriptionSchedule, coupon } = this.state;
	  
	  
	  if(!subscriptionSchedule) return false;
	  
	  
	  if(this.state.fetchInProgress) return false;
	  
	  this.setState({fetchInProgress : true});
	
	  let userData = {
			url: apiUrl+'stripe/release-subscription-schedule',
			method: "post",
			query: {
				subscription_schedule_id: subscriptionSchedule.id,
				
			}
	  };
	 
		
	  _axiosCall(userData)
		.then(res => {
		  console.log("_getUserData res back ,", res);
		  
		  if(res && res.id){	
			 
			 this.setState(
				   {
					   
					   subscriptionSchedule: false
				   }
			   )
			  
		  }else{
			 if(res && res.message){
				this.snackOpen(<Fragment><span>Oops, your subscription was not updated. <p className="small-text light-text" style={{ marginLeft:'30px', color:'#FFF' }}>{_capitalizeText(res.message)}</p></span></Fragment>, 'error');
			 }else{
				this.snackOpen('Oops, something went wrong, your subscription was not activated.', 'error');
			 }		  
		  }
	  })
	  .catch((error) => {
		console.log("_getUserData error ", error);
	  });
	
	
	}
	setCoupon(coupon) {
	   this.setState( {coupon: coupon} )
	}
	
	_getUserCredits = (subscription) => {
		
	  const { id } = this.props.user;
	  let userData = {
			url: process.env.API_ROOT+'/dataexport/analytics/aggregate',
			method: "post",
			query: [
				    {
				        "$match": {
				            "$and" :[
				                { "user_key" : id },
				                { "created_timestamp_unix" : {
				                    "$gte" : subscription.current_period_start,
				                    "$lt" : subscription.current_period_end
				                }}
				            ]           
				        }
				    },
				    {
				        "$group" : {
				            "_id" : null,
				            "total" : {
				                "$sum" : "$credits"
				            }
				        }
				    }
				]
			
	  };
	  	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res && res.length>0 && res[0].total){	
	        this.setState({userCreditUsed:res[0].total})	      
		  }else{
			this.setState({userCreditUsed:0})		  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	  });
	
	
  }
	
	_updateUserProfile= (values) => {
     // console.log('_getBuildingData called', this.state)           
      const { authState, authService, dispatch, user } = this.props;	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  
	  let profile = Object.assign({}, user, values);
	  const user_id = profile.id;
	  if(profile.id) delete profile.id
	  if(profile.login) delete profile.login
	  if(profile.role) delete profile.role
	 	  
	  let userData = {
			url: apiUrl+'user/update',
			method: "post",
			query: {
				id: user_id,
				profile: profile
			}
	  };  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.profile){
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
		      this.snackOpen('Your Subscription Has Been Cancelled.', 'success');
		  }else{
			  if(res.message){
				this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error'); 
			 }else{
				this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error'); 
			 }
			  
		  }
	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	   this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error'); 
	  });
	}
	
	
	_cancelSubscription = (subscription) => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
 
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  
	  
	  let cancelEndPoint = subscription.status === 'trialing' || !subscription.current_period_end ? 'stripe/cancel-subscription' : 'stripe/cancel-subscription-period-end';

	  let userData = {
			url: apiUrl+cancelEndPoint,
			method: "post",
			query: {
				subscription_id: subscriptionId,
				user: user,
				current_period_end: subscription && subscription.current_period_end ? subscription.current_period_end : false
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.status && res.status === 'canceled'){	
		      
		      
		       this.snackOpen('Your subscription has been cancelled.');

		       setTimeout(function(){  
			       let oktaUser = Object.assign({}, res.profile);
				   oktaUser['id']= res.id;
				   dispatch(setUserProfile(oktaUser));			      
			  }.bind(this), 3000);

		            
		      
		  }else if(res && res.status && res.status === 'active' && res.cancel_at_period_end){
			  this.snackOpen('Your subscription has been cancelled.');	
			  this.toggleRefresh();	  			  
		  }else{			  
			 if(res.message){
				this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error'); 
			 }else{
				this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error'); 
			 }
			  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error');
	  });

	}
	
	_reactivateSubscription = (subscription) => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
 
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;
	  
	  
	  let cancelEndPoint = 'stripe/reactivate-subscription';

	  let userData = {
			url: apiUrl+cancelEndPoint,
			method: "post",
			query: {
				subscription_id: subscriptionId,
				user: user,
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.status && res.status === 'active'){			      
		      
		       this.snackOpen('Your subscription has been reactivated!');
		       this.toggleRefresh();	  			  
     		      
		  }else{			  
			 if(res.message){
				this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Reactivated.', 'error'); 
			 }else{
				this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Reactivated.', 'error'); 
			 }
			  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	    this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Reactivated.', 'error');
	  });

	}
	
	_getInvoices = (customer) => {
     // console.log('_getBuildingData called', this.state)
           
      const { authState, authService, dispatch, user } = this.props;	  	  
 
	  const { subscriptionId } = user;
	  
	  if(!subscriptionId) return false;

	  let userData = {
			url: apiUrl+'stripe/get-invoices',
			method: "post",
			query: {
				customer: customer
			}
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      
	      if(res && res.data){	
	           this.setState(
			      {
				      invoices: res.data
			      }
		      ) 
		      
		  }else{			  
			 
			  
		  }
	  })
	  .catch((error) => {
	    console.log("_getUserData error ", error);
	    
	  });

	}
	
	 setAccountType = (type) => {   

		 const { authState, authService, dispatch, user } = this.props;	  	  
	      let headers = {
			  "Authorization" : `SSWS ${oktaToken}`,
		  }
		  
		  let profile = Object.assign({}, user, {accountType:type});
		  const user_id = profile.id;
		  if(profile.id) delete profile.id
		  if(profile.login) delete profile.login
		  if(profile.role) delete profile.role
		 	  
		  let userData = {
				url: apiUrl+'user/update',
				method: "post",
				query: {
					id: user_id,
					profile: profile
				}
		  };  
		  _axiosCall(userData)
		    .then(res => {
		      console.log("_getUserData res back ,", res);
		      
		      if(res && res.profile){
				  let oktaUser = Object.assign({}, res.profile);
				  oktaUser['id']= res.id;
			      dispatch(setUserProfile(oktaUser));
			      this.snackOpen('Your Account Type Has Been Changed.', 'success');
			  }else{
				  if(res.message){
					this.snackOpen('Oops, Something Went Wrong, Your Account Type Could Not Be Changed.', 'error'); 
				 }else{
					this.snackOpen('Oops, Something Went Wrong, Your Account Type Could Not Be Changed.', 'error'); 
				 }
				  
			  }
		  })
		  .catch(error => {
		    console.log("_getUserData error ", error);
		   this.snackOpen('Oops, Something Went Wrong, Your Subscription Was Not Cancelled.', 'error'); 
		  });
	  };

	
	
	render(){
		const { snackShow, snackMessage, snackType, subscription, customer, paymentMethod, invoices, userCreditUsed, coupon, subscriptionSchedule } = this.state;
		const { user } = this.props;
		if(!paymentMethod) return false;
		return (
			<Container>
				<SnackBar 
					snackShow={snackShow} 
					snackMessage={snackMessage} 
					snackClose={this.snackClose} 
					snackDuration={5000} 
					snackType={snackType} 
				/>
			    <Row>
			      <Col md={12}>
			        <h3 className="page-title">{`Subscription & Billing`}</h3>
			        <h3 className="page-subhead subhead">Update your subscription information</h3>
			      </Col>
			    </Row>
				<QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_cb3ddc849c"} type={"Subscription & Billing"} />

			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    {user.role && Array.isArray(user.role) && user.role.indexOf('admin')>=0 &&
				<Row>
				  <Col md={12} lg={12}>
				    <Card>
				      <CardBody>
				      	<div className="card__title">
				          <h5 className="bold-text">{'Change Account Type'}</h5>
				          <div className="page-subhead subhead"></div>
				          <hr/>
				        </div>
					     <div className="account" style={{ minHeight:0, height:'auto', background:'transparent' }}>
						    <div className="form__form-group account__type">
						        <Container>
						        	
							        <Row>
							        	
							        	<Col md={3} xl={3} sm={6} className="no-padding">
							        		<div 
							        			className={`btn btn-outline-secondary account__btn-type ${user.accountType==='buyer' || !user.accountType ? "selected" : ""}`} 
							        			onClick={() => this.setAccountType('buyer')}>
							        			<BuyerAgentIcon />
												<span className="small-text">Buyer</span>
											</div>
							        	</Col>
							        	<Col md={3} xl={3} sm={6} className="no-padding">
							        		<div 
							        			className={`btn btn-outline-secondary account__btn-type ${user.accountType==='seller' ? "selected" : ""}`} 
							        			onClick={() => this.setAccountType('seller')}>
							        			<SalesGalleryIcon />
							        			<span className="small-text">Seller</span>
							        		</div> 
							        	</Col>							        	
							        	<Col md={3} xl={3} sm={6} className="no-padding"> 
							        		<div
							        			className={`btn btn-outline-secondary account__btn-type ${user.accountType==='enterprise' ? "selected" : ""}`} 
							        			onClick={() => this.setAccountType('enterprise')}>
							        			<EnterpriseIcon />
							        			<span className="small-text">Enterprise</span>
							        		</div>  
							        	</Col>   
							        	<Col md={3} xl={3} sm={6} className="no-padding">
							        		<div 
							        			className={`btn btn-outline-secondary account__btn-type ${user.accountType==='media' ? "selected" : ""}`} 
												onClick={() => this.setAccountType('media')}>
							        			<MediaIcon />
							        			<span className="small-text">Media</span>
							        		</div>
							        	</Col>  
							        </Row>
						        </Container>
						        </div>
						 </div>
						 </CardBody>
					</Card>
					</Col>
					</Row>
			       }
			    {user && user.subscriptionId &&
					<Row>				      
				      {(!user.subscriptionHierarchy || user.subscriptionHierarchy != 'child') &&
					       <BillingForm {...this.props} customer={customer} subscription={subscription} paymentMethod={paymentMethod} snackOpen={this.snackOpen} toggleRefresh={this.toggleRefresh} />
				      }
				      {(!user.subscriptionHierarchy || user.subscriptionHierarchy != 'child') ?
					      <SubscriptionForm {...this.props} 
					      	customer={customer} 
					      	subscription={subscription} 
							subscriptionSchedule={subscriptionSchedule}
					      	cancelSubscription={this._cancelSubscription} 
					      	reactivateSubscription={this._reactivateSubscription} 
					      	userCreditUsed={userCreditUsed}
							setCoupon={this.setCoupon}
							coupon={coupon}
							updateSubscriptionDetails={this._updateSubscriptionDetails}
							releaseSubscriptionSchedule={this._releaseSubscriptionSchedule}
					      	/>
					      	:
					    	<CustomSubscription />
				      	}
				      	{(!user.downloadAccess || user.downloadAccess != "disabled") && 
					      	<DataUsage {...this.props} userCreditUsed={userCreditUsed} />					      	
				      	}
				      
				    </Row>
			    }
			    {invoices && 
				    <Row>
				    {(!user.subscriptionHierarchy || user.subscriptionHierarchy != 'child') &&
				      <Invoices {...this.props} customer={customer} subscription={subscription} cancelSubscription={this._cancelSubscription} invoices={invoices} />
				    }				      
				    </Row>
				    
			    }
			    

			</Container>
		);	
	}
  
};

export default connect(state => ({
  user: state.user,
}))(withOktaAuth(BillingProfile));
