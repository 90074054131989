import React, { PureComponent, useState, Fragment, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { GoogleMap, withGoogleMap, withScriptjs, Polygon, Marker } from "react-google-maps";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import Panel from '../../../../shared/components/Panel';


import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import AlertIcon from 'mdi-react/InformationOutlineIcon';

import FloodRiskCard from './components/FloodRiskCard';
import SpecialHazardCard from './components/SpecialHazardCard';

import LinearLoading from '../../../../shared/components/LinearLoading';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL,
	_formatLatLong,
	_formatCoords
} from '../../../../shared/helpers/utils';

import{
	_axiosCall,
} from '../../../../shared/helpers/apicalls';
const apiURL = process.env.API_ROOT + '/query/';
const styles = {
	default: [
	  { stylers: [
			{ hue: "#009ECB" },
			{ saturation: -60 }
		  ]
	  },
	  {
		  //points of interest
		featureType: 'poi.business',
		stylers: [{visibility: 'off'}]
	  },
	  {
		featureType: 'transit',
		elementType: 'labels.icon',
		stylers: [{visibility: 'off'}]
	  }
	]
};

const map_marker = {
  path:
	  "m1152,640q0,-106 -75,-181t-181,-75t-181,75t-75,181t75,181t181,75t181,-75t75,-181zm256,0q0,109 -33,179l-364,774q-16,33 -47.5,52t-67.5,19t-67.5,-19t-46.5,-52l-365,-774q-33,-70 -33,-179q0,-212 150,-362t362,-150t362,150t150,362z",
	fillColor: "#780F9E",
	fillOpacity: .9,
	// strokeWeight: 1,
	scale: 0.025,
};

const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft:"-8px",
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),      
	},
	
  },
  button:{
	'&.selected' :{
		background:"#D1D6E0"
	}
  }
}));
const _floodzoneTranslate = {
	"VE" : "This building is near or in a zone that's in the most hazardous of flood zones and below base flood elevation.  Flood insurance may be mandatory.",
	"AE" : "This building is near or in a zone that's subject to rising waters. Flood insurance may be mandatory.",
	"A" : "This building is near or in a zone that's subject to rising waters. Flood insurance may be mandatory.",
	"AO" : "This building is near or in a zone that's subject to rising waters. Flood insurance may be mandatory.",
	"X" : "This building is not in or near a risky flood zone. Flood insurance is not mandatory.",
	"0.2 PCT ANNUAL CHANCE FLOOD HAZARD" : "This building is at least partially in a zone that's subject to rising waters. Flood insurance may be mandatory."
}

const _floodzoneTranslateShort = {
	"VE" : "Extremely High Risk - Wave Hazard",
	"AE" : "High Risk",
	"A" : "High Risk",
	"AO" : "High Risk",
	"X" : "Minimal Risk",
	"0.2 PCT ANNUAL CHANCE FLOOD HAZARD" : "High Risk"
}

const _floodzoneColors = {
	"VE" : "#F53246",
	"AE" : "#FF691E",
	"A" : "#FF691E",
	"AO" : "#FF691E",
	"X" : "#2abb9b",
	"0.2 PCT ANNUAL CHANCE FLOOD HAZARD" : "#FF691E"
}
const FloodGMap = withScriptjs(withGoogleMap((props) =>
	<GoogleMap
	  defaultZoom={props.zoom ? props.zoom : 18}
	  defaultCenter={{ lat: props.lat, lng: props.long }}
	  defaultOptions={{ styles: styles.default}}
	 
	>
	{props.polyCoords &&
	  <Polygon
		path={props.polyCoords}
		options={{
		  fillColor: "#f7b665",
		  fillOpacity: 0.5,
		  strokeColor: "f7b665",
		  strokeOpacity: 1,
		  strokeWeight: 1
		}}
	  />

	 }
	 
	{props.lat && props.long && 
		<Marker 
			position={{ lat: props.lat, lng: props.long }}
			icon={map_marker} />
	}
   

	</GoogleMap>
))

const FloodZone = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'summary');
	
	const [period, setPeriod] = useState("all-time");
	
	const [listingStats, setListingStats] = useState(null);
	const [floodRes, setFloodRes] = useState(null);
	const [statsRes, setStatsRes] = useState(null);
	
	const { unitsRes, buildingRes, user } = props;  
	const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
	  ? _capitalizeText(buildingRes.name)
	  : buildingRes.display_full_street_address
		? _capitalizeText(buildingRes.display_full_street_address)
		: _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const summaryTitle = 'Building Analysis - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
	const _toggleTab = (tab) => {
		if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/analysis/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
		  setActiveTab(tab)
		  Publisher.publish(`track.buildingAnalysisView.${tab}`, {building: buildingRes.key});
		}
	  };


	const getFloodZones = async () => {
	  var geocode = buildingRes.geocode;
	  var lat_lng = geocode.split(", ");
	  var query = 'polygon:"Intersects(' + lat_lng[1] + " " + lat_lng[0] + ')"';
	  var floodZoneCallData = {
		url: apiURL + "fema-flood-zones",
		method: "post",
		query: {
		  q: query,
		  fl: "id,flood_zone,source_providers,geojson,special_flood_hazard_area",
		  sort: "source_cit asc",
		  fq: "-flood_zone:X",
		  wt: "json",
		  rows: 40
		}
	  };
	  let formattedData = await _axiosCall(floodZoneCallData)
		.then(res => {
		  var floodzones = {};
		  if (res && res.docs && res.docs.length > 0) {
			for (var i = 0; i < res.docs.length; i++) {
			  var element = res.docs[i];
			  var aKey = element.source_providers[0];
	
			  if (!Array.isArray(floodzones[aKey])) {
				floodzones[aKey] = [];
				floodzones[aKey].push(element);
			  } else {
				floodzones[aKey].push(element);
			  }
			}
		  } else {
			floodzones = {
			  "FEMA-FLOOD-ZONES-2007": [
				{
				  id: "1",
				  flood_zone: "X",
				  special_flood_hazard_area: false,
				  source_providers: ["FEMA-FLOOD-ZONES-2007"]
				}
			  ],
			  "FEMA-FLOOD-ZONES-2015": [
				{
				  id: "2",
				  flood_zone: "X",
				  special_flood_hazard_area: false,
				  source_providers: ["FEMA-FLOOD-ZONES-2015"]
				}
			  ]
			};
		  }
	
		  setFloodRes(floodzones);
		})
		.catch(error => {
		  console.log("error: " + error);
		});

	};
	useEffect(() => {
	  getFloodZones();

	}, []);
	const classes = useStyles();
	var latLong = _formatLatLong(buildingRes.geocode);
	if(floodRes === null){
		return  (
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		<div>
		<LinearLoading />
		</div>
		</Container>	  
		</div>	
		)
	}
	if(false){
		return (
		  <Col md={12}>
			<Card>
			  <CardBody className={`small`}>
				<div className={`email-confirmation`} >
				  <div className="email-confirmation__icon">
					<AlertIcon size={50} color={`#D6BAE8`} />			            
				  </div>
				  <h3 className="email-confirmation__title">No FloodZone Info Found</h3>
				  <p className="email-confirmation__sub">Buildings under 25,000 sq ft are not required to report FloodZone benchmarks.</p>
				  
				</div>
			  </CardBody>
			</Card>
		  </Col>
		  )
	}
	return (

		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		 {floodRes &&
			<Row>
				{Object.keys(floodRes).map(key => {
					
					const isSpecial = floodRes[key].filter(zone => zone.special_flood_hazard_area).length > 0;
					const zone = floodRes[key].filter(zone => zone.flood_zone != "OPEN WATER")[0];
					return(
					<Panel 
						  md={12}
						  lg={12}
						  xl={12} 
						  title={key == "FEMA-FLOOD-ZONES-2007" ?
							  <span>Effective FEMA Flood Zones (Before Sandy)</span>
						  :
							  <span>Proposed FEMA Flood Zones (After Sandy)</span>
						  }
						  subhead={key == "FEMA-FLOOD-ZONES-2007" ?
							  <span>How current flood zones may affect this property - {_floodzoneTranslate[zone.flood_zone]}</span>
						  :
							  <span>How the 2015 proposed flood zone (under review) may affect this building - {_floodzoneTranslate[zone.flood_zone]}</span>
						  } 
					  >
						<div dir="ltr">
						<Row className="mb-3">
							<Col md={12} xl={12} lg={12} sm={12} xs={12}>	
								<h6 className="">{_floodzoneTranslate[zone.flood_zone]}</h6>
							</Col>
						</Row>
						<Row>
						<Col md={12} xl={3} lg={6} sm={12} xs={12}>	
							<FloodRiskCard 	floodData={zone} floodzoneTranslateShort={_floodzoneTranslateShort} floodzoneTranslate={_floodzoneTranslate} floodzoneColors={_floodzoneColors}/>	
						</Col>
						<Col md={12} xl={3} lg={6} sm={12} xs={12}>	
							<SpecialHazardCard isSpecial={isSpecial} floodData={zone} floodzoneTranslateShort={_floodzoneTranslateShort} floodzoneTranslate={_floodzoneTranslate} floodzoneColors={_floodzoneColors}/>	
						</Col>
						<Col md={12} xl={6} lg={6} sm={12} xs={12}>	
							<Card>
							<CardBody className={`dashboard__health-chart-card`} style={{ padding:10 }} >				
							<div id={`${key}-map`} className="footprint-map">
							{floodRes[key][0].geojson ?
								
							  	<FloodGMap isMarkerShown={false}
										googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEYB3e7_LMx9R5aEx3y8w99Cv2s3XJy_o&libraries=geometry,drawing,places"
										loadingElement={<div style={{ height: `100%` }} />}
										containerElement={<div style={{ height: `260px` }} />}
										mapElement={<div style={{ height: `260px` }} />}
										lat={latLong.lat}
										long={latLong.long}
										polyCoords={_formatCoords(floodRes[key][0].geojson)}
										zoom={key == "FEMA-FLOOD-ZONES-2007" ? 16 : 19}
							  	/>
							  	
							:
							
								<FloodGMap isMarkerShown={false}
										googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEYB3e7_LMx9R5aEx3y8w99Cv2s3XJy_o&libraries=geometry,drawing,places"
										loadingElement={<div style={{ height: `100%` }} />}
										containerElement={<div style={{ height: `260px` }} />}
										mapElement={<div style={{ height: `260px` }} />}
										lat={latLong.lat}
										long={latLong.long}
							  	/>
							
							
							}
							</div>	
						
							
						
							</CardBody>
							</Card>
							
							</Col>
						</Row>
						
					</div>
				 </Panel>
			 	)
				})}	
			</Row>
		}
			<Row>
		
			
			</Row>
		</Container>	  	
		   
		</div>
			
	)
	
	
};

export default connect(state => ({
  user: state.user,
}))(withRouter(FloodZone));