import React, { PureComponent } from 'react';
import { Progress, Table, Row } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Line, ComposedChart } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import Panel from '../../../../../shared/components/Panel';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const apiURL = process.env.API_ROOT + '/query/';


let bedTypes = [];

class ListingTrendsPrice extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    themeName: PropTypes.string.isRequired,
  };
 
  
  constructor() {
    super();
    this.state = {
      data: false,
    };
  }
   _setbedrooms = (bedrooms, lastItem) => {
	  const { buildingRes, statsRes } = this.props;  
	  
	  let maxBeds = 3;
	  if(statsRes && statsRes.has_bed && statsRes.has_bed.unit_mix && statsRes.has_bed.unit_mix.buckets){
		  maxBeds = 0;
		  statsRes.has_bed.unit_mix.buckets.map(item =>{
			  if(item.val > maxBeds){
				  maxBeds = item.val;
			  }
		  })
		  
	  }
	  let stats = Object.assign({}, lastItem);
	  for(let i = 0; i < bedrooms.length; i++) {
	  	  const bedroom = bedrooms[i];
	  	  
		  if(bedroom.val === 0) {
			  stats['Studio'] = bedroom.avg_price ? bedroom.avg_price : stats['Studio'];
			  bedTypes.push('0_Studio');
		  }else if(bedroom.val > 0 && bedroom.val <= maxBeds){
			  stats[bedroom.val+' Bed'] = bedroom.avg_price ? bedroom.avg_price : stats[bedroom.val+' Bed'];
			   bedTypes.push(bedroom.val+' Bed');
		  }

	  }	
	  bedTypes = [...new Set(bedTypes)];  
	  return stats;
	  
  }

  

  
  componentDidMount() {	
	const { listingStats } = this.props;
	if(listingStats){
		const { buckets } = listingStats.facets.listing_price;	
		const data = [];
		for(let i = 0; i < buckets.length; i++) {
			const bucket = buckets[i];
			if(bucket.count>0){
				const bedrooms = bucket.by_bedrooms && bucket.by_bedrooms.buckets && bucket.by_bedrooms.buckets[0] ? bucket.by_bedrooms.buckets : [];
				const bedroomStats = this._setbedrooms(bedrooms, data[i-1]);
				let item =  Object.assign({}, bedroomStats);
				item['year'] = moment(bucket.val).format('YYYY');
				data.push(item);
			}
		}
		this.setState({ data: data });
	}
  }
  
  render() {
      const { t, dir, themeName, lmi } = this.props;    
      const { data } = this.state;
	  if(!data){
		  return null;
	  }
	  return (
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={'Average Listing Price'}
		  	subhead={'Monthly average price by bedroom since sales started'}
		  >
		    <div dir="ltr">
		      <ResponsiveContainer height={350} className="dashboard__area">
		        <ComposedChart data={data} margin={{ top: 0, left: -10, bottom: 20, right:20 }}>
			       
		           <XAxis dataKey="year" tickLine={false} reversed={dir === 'rtl'} dx={5} angle={45} tickSize={20} height={60} tickMargin={10} />
			       <YAxis  yAxisId="left" tickLine={false} tickLine={false}  orientation={'left'} tickFormatter={value =>  _formatPrice(value)} />
			      {/* <YAxis yAxisId="right" 
			        tickLine={false} 
			        orientation={'right'} 
			       	tickFormatter={value => ['$', new Intl.NumberFormat('en').format(value)].join('')}/> */}
			       <Tooltip formatter={value => '$'+_formatNumber(value)} />
			       <Legend />
			       <CartesianGrid vertical={false}/>
			      {/*  */}
			       {bedTypes.sort().map((key, i) => {
				       
				       return <Line yAxisId="left" dot={{ strokeWidth:2 }} type="monotone" dataKey={key.replace('0_', '')} fill="#BFC4D1" stroke={getDefaultChartColors[i]} strokeWidth={1} fillOpacity={0} />
			       })}
			       
			        

			      
			      </ComposedChart>
		      </ResponsiveContainer>
		    </div>
		  </Panel>
		);
	}
}

export default connect(state => ({
  rtl: state.rtl,
  dir: PropTypes.string.isRequired,
  theme: state.theme,
}))(withTranslation('common')(ListingTrendsPrice));
