import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import FinanceIcon from 'mdi-react/FinanceIcon';
import ChartLineVariantIcon from 'mdi-react/ChartLineVariantIcon';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../../shared/helpers/utils";

const AveragePPSF = (props) => {
		if(props.unitStats){
			return (
				 <Col md={12} xl={3} lg={6} xs={12}>
				    <Card>
				      <CardBody className="dashboard__booking-card card__link">
				        <div className="dashboard__booking-total-container">
						{props.sold ?
						  <h5 className="dashboard__booking-total-title">
							  ${props.unitStats.sold_ppsf && props.unitStats.sold_ppsf.average > 0 ? _formatNumber(props.unitStats.sold_ppsf.average)
								  : '--'}
						  </h5>
						  :
				          <h5 className="dashboard__booking-total-title">
				            ${props.unitStats.asking_ppsf && props.unitStats.asking_ppsf.average > 0 ? _formatNumber(props.unitStats.asking_ppsf.average)
					            : '--'}
				          </h5>
					    }
						{props.sold ? 
				          <FinanceIcon className="dashboard__trend-icon" />
						  :
						  <ChartLineVariantIcon className="dashboard__trend-icon" />
						}
				        </div>
				        <h5 className="dashboard__booking-total-description">Avg {props.sold ? "Sold" : "Asking"} PPSF</h5>
				      </CardBody>
				    </Card>
				  </Col>
			)
		}

		return null;
		
	
	 	
		
	
};

export default AveragePPSF;
