import React, { Fragment } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import axios from "axios";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from 'mdi-react/MenuIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import BlankCircle from 'mdi-react/PlusCircleOutlineIcon';
import SelectedCircle from 'mdi-react/MinusCircleOutlineIcon';

import ResetIcon from 'mdi-react/AutorenewIcon';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import MoreIcon from 'mdi-react/MoreVertIcon';
import ChartIcon from 'mdi-react/ChartPieIcon';

import UnitsSearchTableSortButton from './UnitsSearchTableSortButton';
import UnitsSearchTableFiltersSelect from './UnitsSearchTableFiltersSelect';

import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

import Search from '../../../../shared/components/algolia/Search';

import ExportButton from '../../../../shared/components/data_export/ExportButton';
import Chip from '@material-ui/core/Chip';
import DownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import UpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';



import { 
	_capitalizeText,
	searchBarStyles
} from "../../../../shared/helpers/utils";

const apiUrl = process.env.API_ROOT;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop:'20px',
    marginBottom: '25px',
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
  chip:{
    backgroundColor:'#F5F7FA',
    '&:focus':{
      backgroundColor:'#F5F7FA',
    },
    marginBottom:5
  },
  outline:{
    backgroundColor:'#FFF',
    '&:focus':{
      backgroundColor:'#FFF',
    },
    marginBottom:5,
    borderColor:'#D6BAE8',
    color:'#780F9E'
  },
  selectedChip:{
    backgroundColor:'#B070CC',
    color:"#FFF",
    '&:focus':{
      backgroundColor:'#B070CC',
    },
    '&:hover':{
      backgroundColor:'#BF96D9',
    },
    marginBottom:5
  }
}));

export default function UnitsSearchAppBar(props) {
  const classes = searchBarStyles();
  
  const tagClasses = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
  const [popularTags, setPopularTags] = React.useState([]);
  const [seeAllTags, setSeeAllTags] = React.useState(false);
  const { handleSearch, numSelected, handleDeleteSelected, onRequestSort, subFilters, setSubFilter, resetSubFilter, _filters, searchText, building, buildingView, setSubFilters, tags, setTags, resetTags} = props;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  
  React.useEffect(() => {
    let active = true;
    
    const query = [{
      "$match" : {
        "$and" : [
          {"deleted" : {"$exists" : false}},
          {"group_access" : "public"}
        ]
      }
    },
    {
      "$project": {
        "_id": 0,
        "group_tags": 1
      }
    }, {
      "$unwind": "$group_tags"
    }, {
      "$group": {
        "_id": "$group_tags",
        "count": {
          "$sum": 1
        }
      }
    }, {
      "$project": {
        "_id": 0,
        "group_tags": "$_id",
        "count": 1
      }
    }, {
      "$sort": {
        "count": -1
      }
    },{
      "$limit" : 50
    }];
  
    axios.post(`${apiUrl}/buyerslist/groups/aggregate`, query).then((results) => {
  
  console.log(results)
      if (results && results.data && Array.isArray(results.data)) {
         setPopularTags(results.data)
      }
    
    });
  
    return () => {
    active = false;
    };
  }, [searchText]);

  const isSelected = (tag) => {return tags.indexOf(tag) >= 0};
  
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <div style={{textAlign:'right'}}>
          {tags.length> 0 ?
             <IconButton
                    aria-label="Reset Filters"
                    onClick={resetTags}
                    color="inherit"
                  >
                      <ResetIcon style={{color:'#780F9E'}}/>
              </IconButton>
             :
             <IconButton
                   aria-label="Reset Filters"
                   onClick={null}
                   color="inherit"
                 >
                     <ResetIcon style={{color:'#BFC4D1'}}/>
             </IconButton>
           }
          	  <IconButton
              aria-label="Close Filters"
              onClick={handleMobileMenuClose}
              color="inherit"
            >
          			<CloseCircleOutlineIcon style={{color:'#BFC4D1'}}/>
		  	</IconButton>		  	
	   </div>
      {popularTags && popularTags.length>0 &&
        <div style={{paddingLeft:20}} >
          <div>Popular Tags:</div>
         <div className={`group-tags ${tagClasses.root}`}>
            {popularTags.map(tag => { 
            if(isSelected(tag.group_tags)){
              return <Chip icon={<SelectedCircle size={20} color="#FFF" />} className={tagClasses.selectedChip} label={`${tag.group_tags} (${tag.count})`} clickable onClick={()=>{setTags(tag.group_tags)}} />
            }else{
              return <Chip icon={<BlankCircle size={20} color="#780F9E" />} className={tagClasses.chip} label={`${tag.group_tags} (${tag.count})`} clickable onClick={()=>{setTags(tag.group_tags)}} />
            }
           })}
           
          </div> 
         </div>
         
       }  
    </Menu>
  );
  let timeout = null;
  let showTags = 6;
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
       {/*   <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
           <InputBase
              placeholder={`Collection Name`}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyUp={e =>{
	              clearTimeout(timeout);
	              const searchText = e.target.value;
	              console.log('key up', e.target.value);
	              timeout = setTimeout(function (searchText) {
		              	 console.log('handle search', searchText);
				        handleSearch(
							    searchText
						    )
				  }.bind(this, searchText), 800);
              }}
			 
            />
          </div>
          
          <div className={classes.grow} />
          
        
          <div className={classes.sectionDesktop}>	 
          	
	          {/*<UnitsSearchTableSortButton onRequestSort={onRequestSort} {...props} />*/}
	      </div>
 {/*         <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div> */}
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.sectionDesktop}>
	      {popularTags && popularTags.length>0 &&
           <div className={`group-tags ${tagClasses.root}`}>
             <span>Popular Tags:</span> 
             {seeAllTags ?
              <Fragment>
                {popularTags.map(tag => { 
                  if(isSelected(tag.group_tags)){
                    return <Chip icon={<SelectedCircle size={20} color="#FFF" />} className={tagClasses.selectedChip} label={`${tag.group_tags} (${tag.count})`} clickable onClick={()=>{setTags(tag.group_tags)}} />
                  }else{
                    return <Chip icon={<BlankCircle size={20} color="#780F9E" />} className={tagClasses.chip} label={`${tag.group_tags} (${tag.count})`}  clickable onClick={()=>{setTags(tag.group_tags)}} />
                  }
                 })}
                 
                 <Chip icon={<UpIcon size={20} color="#780F9E" />} variant="outlined" className={tagClasses.outline} label={`Minimize Tags`} clickable onClick={()=>{setSeeAllTags(false)}} />
             </Fragment>
             :
             <Fragment>
               {popularTags.slice(0,showTags).map(tag => { 
                if(isSelected(tag.group_tags)){
                  return <Chip icon={<SelectedCircle size={20} color="#FFF" />} className={tagClasses.selectedChip} label={`${tag.group_tags} (${tag.count})`}  clickable onClick={()=>{setTags(tag.group_tags)}} />
                }else{
                  return <Chip icon={<BlankCircle size={20} color="#780F9E" />} className={tagClasses.chip} label={`${tag.group_tags} (${tag.count})`}  clickable onClick={()=>{setTags(tag.group_tags)}} />
                }
               })}
               <Chip icon={<DownIcon size={20} color="#780F9E" />} variant="outlined" className={tagClasses.outline} label={`+${popularTags.length - showTags} More`} clickable onClick={()=>{setSeeAllTags(true)}} />
             </Fragment>
           }
             
             
             {tags.length> 0 ?
               <IconButton
                      aria-label="Reset Filters"
                      onClick={resetTags}
                      color="inherit"
                    >
                        <ResetIcon style={{color:'#780F9E'}}/>
                </IconButton>
               :
               <IconButton
                     aria-label="Reset Filters"
                     onClick={null}
                     color="inherit"
                   >
                       <ResetIcon style={{color:'#BFC4D1'}}/>
               </IconButton>
             }
           </div>
           
         }  
	       
	     </div>
      {renderMobileMenu}
      {/*renderMenu*/}
    </div>
  );
}
