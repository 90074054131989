import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuIcon from 'mdi-react/MenuIcon';
import Alert from '../../../shared/components/Alert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import AdvancedFilertIcon from 'mdi-react/FilterVariantIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';

import UncheckedIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import CheckedIcon from 'mdi-react/CheckboxMarkedIcon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import DoneIcon from 'mdi-react/DoneIcon';
import { 
	_capitalizeText,
	unitStageToolTips
} from "../../../shared/helpers/utils";
import { _hasPermission	} from "../../../shared/helpers/access";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    fontSize: '12px',
  },
  formLabel: {
	  fontSize: '14px'
  },
  menuItem:{
	   fontSize: '14px'
  },
  listItemText:{
	  fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginLeft: 5,
  },
  chip: {
    margin: 5,
    marginLeft: 5,
    width:120,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterContainer:{
	  padding: 0
  },
  label: {
	  marginBottom:0
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function mapStateToProps(state) {
  return {  user: state.user};
}




export default connect(mapStateToProps)(function UnitStageFilter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { subFilters, setSubFilter, _filters, unitStages, setUnitStage, label, customToolTip, user } = props;

  
  const handleChange = event => {
	  setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);
  };
  
  const handleChip = chip => {
	  console.log(chip)
	 // setSubFilter(event.target.name, event.target.value);
//     setPersonName(event.target.value);
	  setUnitStage(chip);

  };
  
  const setTitle = (search_key, search_value) => {
	  
	  return _filters.filter(filter => filter.search_key === search_key)[0].values.filter(value => value.filter === search_value)[0].name;
  }
  const slice = isMobile ? 99 : 7;
  
  const chips = ['all', 'sponsor', 'resale'];
  //console.log(user, _hasPermission(user, 'sponsor-buildings'), _hasPermission(user, 'resale-buildings'));
  if(!(_hasPermission(user, 'sponsor-buildings') && _hasPermission(user, 'resale-buildings'))){
    return null;
  }

  return (
    <div className={classes.filterContainer}> 
    	<div style={{display:isMobile ? "block" : "flex",alignItems:"center"}} >
    		{!isMobile &&<span className="label" style={{marginTop:-7,marginRight:5}}>{label ? label : 'Include:'}</span>}
	    	<div className={classes.chips+' unit-filter'}>
			     {chips && chips.map(chip => {	
				     const selected = unitStages && Array.isArray(unitStages) && unitStages.indexOf(chip)>=0;     
				     return <Tooltip title={`${customToolTip ? customToolTip[chip] : unitStageToolTips[chip]}`}>	
						     	<FormControlLabel
							 		control={
							     		<Checkbox
									        checked={selected}
									        onChange={()=>handleChip(chip)}
									        inputProps={{ 'aria-label': 'primary checkbox' }}
									        icon={<UncheckedIcon size={22} />}
									        checkedIcon={<CheckedIcon size={22} />}
									      />
								      }
							        label={`${_capitalizeText(chip)} Units`}
							      />
					      </Tooltip>
					      				     
			     })}      
			</div>
			<div style={{marginLeft:20}}>
				{/*<a href="https://blocksandlots.com/new-condo-lifecycle-stages-pipeline-sponsor-sale-resale/" target="_blank" style={{fontSize:"11px",color:"#B070CC"}}>
					 Learn More <HelpCircleOutlineIcon size={12} style={{marginTop:"-3px"}}/> 
	    		</a>*/}
			</div>	
	    </div> 	  
    </div>
  );
})
