import React, { PureComponent, Fragment } from 'react';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge
} from 'reactstrap';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	propertyFL,
	_privateKey,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations,
	SalesProgress,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_isHouse
} from '../../../../../shared/helpers/utils';

export const BuildingGridView = ({buildingRes, toggleModal}) => {
	
	return (
	  <div className="catalog-items__wrap">
		<div className="catalog-items">
		  {buildingRes.map((item, i) => (
			<CardBody className="catalog-item" key={i} onClick={()=>toggleModal("building", item)}>
			
			   
				<div className="catalog-item__img-wrap">
				  {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
					  <BuildingPhoto item={item} />
					  
				</div> 
				<BuildingCurrentStatus item={item} />               
				<div className="catalog-item__info">
				   <h4 className="catalog-item__price">
					   {item.name ? _capitalizeText(item.name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address))}
					   {item.building_name && <div>{_capitalizeText(_replaceAbbreviations(item.display_full_street_address))}</div>}
					   <div className="light-text small-text" style={{color:"#858AA1"}}>
						  {item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
					</div>	                  
				   </h4>
				   <h5 className="product-card__title">
						  <div className="">{item.inventory_stats_listings_low_price > 100 ? `${_formatPrice(item.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(item.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</div>
						  
						  
						  <span className="light-text small-text">{_formatNumber(item.total_floors)} Floors</span>
						  <span className="small-divider">|</span>
						  
						  <span className="light-text small-text">{_formatNumber(item.inventory_total)} Units</span>
					</h5>
					{['sponsor', 'pipeline'].indexOf(item.building_status) >= 0 &&
					<div className="mt-2">
						{item.offering_construction==='conversion' 
					   	?
					   	<SalesProgress label={"Units Sold"} 
						 	progress={parseFloat(((item.inventory_total - item.inventory_unsold) / item.inventory_total) * 100).toFixed(0)} 
						 	inventory_unsold={item.inventory_total - item.inventory_unsold} inventory_total={item.inventory_total}  color={"grey"} maxWidth="85%" size={'middle'} />
					   	
					   	: item.building_status === 'resale' ?
					   	<SalesProgress label={"Units Sold"}  progress={parseFloat(item.inventory_pct_unsold).toFixed(0)} inventory_unsold={item.inventory_unsold} inventory_total={item.inventory_total} min color={"grey"} resale={item.building_status === 'resale'} maxWidth="85%" size={'middle'} />
					   	:
					   	<SalesProgress label={"Units Sold"} 
						 	progress={parseFloat(((item.inventory_total - item.inventory_unsold) / item.inventory_total) * 100).toFixed(0)} 
						 	inventory_unsold={item.inventory_total - item.inventory_unsold} inventory_total={item.inventory_total} maxWidth="85%" size={'middle'} />
						} 
					</div>
					}
				  
				 
				 </div>
			</CardBody>
		  ))}
		</div>
	  </div>
	);
}

export const UnitsGridView = ({unitRes, toggleModal}) => {
	
	
	return (
	  <div className="catalog-items__wrap">
		<div className="catalog-items">
		  {unitRes.map((item, i) => {
			const img = item.listing && item.listing.main_photo ? item.listing.main_photo : _getUnitFloorPlan(item) ? _getUnitFloorPlan(item)[0].src : false;
			  
			return (
			<CardBody className="catalog-item" key={i} onClick={()=>toggleModal("unit", item)}>
				<div className="catalog-item__img-wrap">
				<div className="catalog-item__img-wrap">
				  {img ?
					<img src={img} / >
					:
					  <FloorPlanIcon size={200} color="#CCC" />
				  }
					  
				</div>
				
				{!_isHouse(item) && <UnitCurrentStatus unit={item} />}
					  
				</div>
				<div className="catalog-item__info">
				   <h4 className="catalog-item__price">{item.last_listed_sale_price_all > 0 ? '$'+_formatNumber(item.last_listed_sale_price_all) : '--'}
					{item.last_listed_sale_price_all > 0 && item.sq_ft > 0 &&
						  <span className="small-text ml-2">
							 (${_formatNumber(item.last_listed_sale_price_all/item.sq_ft)}
							  <span className="sub-text"> PPSF</span>)
						  </span>
					  }   
				   
				   </h4>
					
				   <h5 className="product-card__title">
						  {item.bedrooms === 0 ? 'Studio' : item.bedrooms} 
						  <span className="sub-text">{item.bedrooms === 0 ? '' : item.bedrooms === 1 ? 'Bed' : 'Beds'}</span>
						  <span className="small-divider">|</span>
						  {item.baths}
						  <span className="sub-text">{item.baths === 1 ? 'Bath' : 'Baths'}</span>
						  <span className="small-divider">|</span>
						  {_formatNumber(item.sq_ft)}
						  <span className="sub-text">Sq Ft</span>
						  
						  {/*item.exterior_sq_ft ?
							  <React.Fragment>
								  <span className="small-divider">|</span>
								  {_formatNumber(item.exterior_sq_ft)}
								  <span className="sub-text">Exterior Sq Ft</span>
							  </React.Fragment>
							  : null
						*/}
					  </h5>
				  <h5 className="catalog-item__title">
				 <span>{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address ? item.display_full_street_address : item.full_street_address))}, {item.address_2_normalized.toUpperCase().replace("APT", "Apt")}</span>
				  <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}</span>
				  </h5>
				  <p className="catalog-item__description"></p>
				 
				  
				  {/*item.colors.map((c, index) => (
					<span
					  key={index}
					  className="catalog-item__color"
					  style={{ background: c }}
					/>
				  ))*/}
				</div>
			</CardBody>
		  )})}
		</div>
	  </div>
	);
}