/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import FullscreenIcon from 'mdi-react/FullscreenIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';



const CustomHeader = ({ innerProps, currentView, modalProps, isModal }) => {
	    console.log(innerProps, currentView);	
	    const download = () => {
		    var element = document.createElement("a");
		    var file = new Blob(
		      [
		        currentView.url
		      ],
		      { type: "image/*" }
		    );
		    element.href = URL.createObjectURL(file);
		    element.download = "floorplan.jpg";
		    element.click();
		};
	    if(!isModal){
	    	return null;
	    }else{  
		    return(
				<div className="react-images__custom-header">
					<Tooltip title={`Close`}>
						<IconButton onClick={modalProps.onClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={`Expand Fullscreen`}>
						<IconButton onClick={modalProps.toggleFullscreen}>
							<FullscreenIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={`Open In New Tab`}>
						<IconButton onClick={() => window.open(`${currentView.url}`)}>
							<OpenInNewIcon size={22} />
						</IconButton>
					</Tooltip>
				</div>			    		
		    );
	    }
	    
}



export default class Gallery extends PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
      alt: PropTypes.string,
    })).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      tag: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      images: props.images.slice(),
      currentTag: props.match && props.match.params.subtab ? props.match.params.subtab : 'all',
      tags: props.tags,
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  onFilter = (tag, e) => {
    const { images, buildingRes } = this.props;
    e.preventDefault();
    if (tag === 'all') {
      const imgs = images;
      this.setState({
        images: imgs,
        currentTag: 'all',
      });
    } else {
      const imgs = images.filter(t => t.tag === tag);
     
		 
	  
      this.setState({
        images: imgs,
        currentTag: tag,
      });
    }
    
     const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tag ? `/tab/plans/${tag}` : '');
	 window.history && window.history.pushState({}, null, buildingURL);
  };

  openLightbox = (index, event) => {
	  
	console.log(index, event);
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  gotoPrevious = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImage - 1 }));
  };

  gotoNext = () => {
    this.setState(prevState => ({ currentImage: prevState.currentImage + 1 }));
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  handleClickImage = () => {
    const { currentImage, images } = this.state;
    if (currentImage === images.length - 1) return;
    this.gotoNext();
  };

  render() {
    const {
      currentImage, lightboxIsOpen, tags, currentTag, images
    } = this.state;
    //const { images } = this.props;
    return (
      <div className="gallery">

        {images.map((img, i) => (
          <div className="gallery__img-wrap" key={i} onClick={e => this.openLightbox(i, e)} style={{ backgroundImage : `url("${img.src}")` }}>
            {/*<img src={img.src} alt={img.alt} />*/}
            <div className="gallery_img-info">
            	<h5>
	            	    <Fragment>{img.address_2_normalized} <span className="light-text"></span></Fragment>
	            	
	             </h5>
            	
            </div>
          </div>
        ))}
        
        <ModalGateway>
	        {lightboxIsOpen ? (
	          <Modal onClose={this.closeLightbox}>
	            <Carousel 
	            	currentIndex={currentImage}
					views={images} 
					components={{ Header: CustomHeader }} 
	            />
	          </Modal>
	        ) : null}
	    </ModalGateway>
      
      </div>
    );
  }
}
