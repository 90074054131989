import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import UnitsSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';
import UnitStageFilter from '../../../shared/components/unit_filters/UnitStageFilter';
import searchActions from '../../../redux/actions/searchActions';




import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {isMobile} from 'react-device-detect';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import BuildingIcon from 'mdi-react/HomeCityOutlineIcon';
import ListIcon from 'mdi-react/HomeVariantOutlineIcon';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import QuarterlyIcon from 'mdi-react/CalendarBlankIcon';
import MonthlyIcon from 'mdi-react/CalendarMonthOutlineIcon';
import WeeklyIcon from 'mdi-react/CalendarClockIcon';
import OpenNewIcon from 'mdi-react/OpenInNewIcon';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import ListViewIcon from 'mdi-react/FormatListBulletedIcon';
import GroupViewIcon from 'mdi-react/DomainIcon';
import StatsViewIcon from 'mdi-react/ChartTimelineVariantIcon';

import ClosedDateIcon from 'mdi-react/ClockIcon';
import ReportedDateIcon from 'mdi-react/ClockOutlineIcon';

import DataExport from './DataExport';

import ToolTip from '../../../shared/components/ToolTip';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Panel from '../../../shared/components/Panel';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';
import EnterpriseGate from '../../../shared/components/payment/EnterpriseGate';

import SalseVelocityRankChart from './SalseVelocityRankChart';

import classNames from 'classnames';

import queryString from "query-string";

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';

import { getDefaultChartColors, getStatusColors } from '../../../shared/helpers';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	urlSlugBuilding,
	_isNewUser,
	_isEnterpriseUser,
	_objectEqual,
	getCohortQuery,
	_activityFQ
} from "../../../shared/helpers/utils";

import { 
	_buildingAdminFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../shared/helpers/filters";

import { _hasPermission	} from "../../../shared/helpers/access";

let counter = 0;
am4core.useTheme(am4themes_animated);
function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const styles = theme => ({
	  root: {
	    display: 'flex',
	    flexDirection: 'column',
	    //marginLeft:"-8px",
	    marginBottom:"15px",
	    alignItems: 'left',
	    '& > *': {
		   
	    },
	  },
	  button:{
		'&.selected' :{
		    background:"#D1D6E0"
	    },
	  }
	});
	
const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';

const lsPre = "SV_2_";
const subFilterKey = 'subFilters';
const unitStagesKey = 'unitStages';
const defaultSubFilter = {offering_verified_status:["not-abandoned"],offering_construction:["exclude-conversion"]};
const buildingStagesKey = 'buildingStages';
const defaultStage = ["sponsor"];
const defaultUnitStage = ['sponsor'];
const gap = syncGenericFilter('gap', lsPre, 'gap', "+7DAY");
const defaultGap = gap === '+1MONTH' ? 'month' : 'week';

const dateStart = '01/05/2015';
const isDST = moment().isDST();


const VelocityChart = (props, { t }) => (

    <div className="dashboard__health-chart"> 
    
      <ResponsiveContainer height={70} width={70}>
        <PieChart>
          <Pie isAnimationActive={false} data={[{ value: 2, fill: '#9600BF' },{ value: 5, fill: '#DBE0E8' }]} startAngle={0} dataKey="value" cx={30}  cy={30} innerRadius={20} outerRadius={25} />
        </PieChart>
       
      </ResponsiveContainer>
	     <div className="dashboard__buyers-list-count-info" style={{position:"absolute", top:29, width:70}}>
	        <p className="dashboard__buyers-list-number">
	        	{props.velocity}
	        	
	        </p>
	         <p className="dashboard__buyers-list-text small-text light-text" style={{fontSize:10, marginTop:15, color:"#858AA1"}}>Sales / Mo</p>
	      </div>  
    </div>
    
        
);

class UnitsSearchTable extends PureComponent {
  
  state = {
    order: syncGenericFilter('order', lsPre, 'order', "desc"),
    orderBy:  syncGenericFilter('order_by', lsPre, 'orderBy', "count"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    showBottomLoader: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _buildingAdminFilters.filter(f => !f.hide_velocity), subFilterKey, lsPre, defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	//startDate:moment(dateStart, 'MM/DD/YYYY').set({hour:0,minute:0,second:0,millisecond:0}).startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').add(isDST ? -1 : 0, 'hour').toDate().toISOString(),
	//endDate: moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
	//gap:  gap,
	chartData:[],
/*
	dataStart:isMobile 
				? syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(3, 'months').startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1 , 'day').toDate().toISOString())
				: syncGenericFilter('start_date', lsPre, 'dataStart', moment().set({hour:0,minute:0,second:0,millisecond:0}).subtract(1, 'years').startOf(defaultGap).add(defaultGap == 'month' ? 0 : 1, 'day').toDate().toISOString()),
	dataEnd: syncGenericFilter('end_date', lsPre, 'dataEnd', moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString()),
*/
	buildingEventCount:0,
	//selectedDate:syncGenericFilter('selected_date', lsPre, 'selectedDate', false),
	eventTypes:[ "Contracts Reported Signed" ], //syncGenericFilter('event_types', lsPre, 'eventTypes', [ "Contracts Reported Signed" ], true),
	soldTimeField: "event_time", //syncGenericFilter('sold_time', lsPre, 'soldTimeField', "event_time"),
	buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, lsPre, defaultStage),
	unitStages:  defaultUnitStage, //syncStageFilters(this.props, _unitStageFilter, unitStagesKey, lsPre, defaultUnitStage),
	viewType : "building",
	buildingData: false,
	period : syncGenericFilter('period', lsPre, 'period', "1-yr")
  };
  
  defaultQ = getCohortQuery(this.props.user);
  q='';
  fq = [];
  property_q = '';
  chartLoaded = false;
  
/*
  	[		
		"offering_verified_status:(active \"coming soon\" \"offering plan required\" \"condo declaration vetted\")",
		//"offering_verified_status:(active)",
		"deleted:false",
		//"-key:b-4-2315-44th-dr-11101"
	];
*/
	
  fl = buildingFL;
  chart;
  _activityFilter = []; //_buildingAdminFilters.filter(filter => !filter.filter_type);
  _propertyFilter = []; //_buildingAdminFilters.filter(filter => filter.filter_type && filter.filter_type === 'property');
  _buildingFilter = _buildingAdminFilters.filter(f => !f.hide_velocity);
  _unitSubFilter = []; //_buildingAdminFilters.filter(filter => !(this.props.buildingView && filter.building_hide));
  timeout = null;
   _matchedBuildingIds = [];
   _matchedBuildingSlugs = [];
   _building_q = this.defaultQ;
   formattedData;
   tempEventTypes = this.state.eventTypes.slice(0);
   noDataIndicator;
  componentDidMount() {
  	 //this._getBuildings();
  console.log(this.state)	 
  	 this._getEvents(true);
  	 
  	
  }
 
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
	  	let unitStageChange = !_isEqual(prevState.unitStages, this.state.unitStages);
	  	
	    let subFilterChanged =
			this._buildingFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||			
			this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.period !== prevState.period ||
			this.state.soldTimeField !== prevState.soldTimeField || 
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged ||
			unitStageChange ||
			buildingStageChange
			//this.state.viewType !== prevState.viewType
			) {
			  const resetPage = this.state.page === prevState.page;	
			  console.log("component update", prevState, this.state)
			  this.defaultQ = getCohortQuery(this.props.user);		
			  this._getEvents(resetPage);
		}else if(this.state.dataStart !== prevState.dataStart ||
			this.state.dataEnd !== prevState.dataEnd ||
			this.state.selectedDate !== prevState.selectedDate ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			!_isEqual(this.state.eventTypes, prevState.eventTypes)){
				this._getEvents(false)
		}
		
		

	  
  }

  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'building_slugs');
	 window.open(buildingURL);
	 //this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _handleBuildingOpen(building) {
	 let baseUrl = window.location.href.split('?')[0];
	window.open(`${baseUrl}?building=${urlSlugBuilding(building)}`);
  }
  
  _getDetailQuery = () => {
	  	const { dataStart, dataEnd, selectedDate, eventTypes, gap, soldTimeField, unitStages} = this.state;
    
    			
		
		let stageQuery = '';
		if(unitStages && unitStages.length>0){
			if(unitStages.indexOf("all")<0){
				if(unitStages.indexOf("resale")>=0){
					stageQuery = ` AND event_tags:("event-stage_${[...["unknown"], ...unitStages].join("\" OR \"event-stage_")}")`;
				}else{
					stageQuery = ` AND event_tags:("event-stage_${unitStages.join("\" OR \"event-stage_")}")`;
				}
				
			}
			
		}
		
		let eventQ = '';
		

				
		const detailQuery = eventTypes.filter(type => type !== "").map(type => {
			switch (type) {
				case "Listings" : 
					return `(topic:listings AND event_tags:just-listed AND -event_tags:rent AND  -address_2_normalized:"" AND -event_tags:*commercial*${stageQuery}${eventQ})`;
					break;
				case "Contracts Reported Signed" : 
					return `(topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -address_2_normalized:"" AND -event_tags:*commercial*${stageQuery}${eventQ})`;
					break;
				case "Sales" : 
					return `(topic:transactions AND event_tags:recorded-sale AND -address_2_normalized:"" AND -event_tags:*commercial* AND -event_tags:parcels-condocoop_0 AND -event_tags:property-type_parking-space AND -event_tags:property-type_storage-room AND -event_tags:parcels_bulk-sale${stageQuery}${eventQ})`
					break;
			}
				
		}).join(" OR ");
		
		return detailQuery ? detailQuery : 'id:none';
	  
  }
  
  getIntervalName(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date).add(1, 'hour').format('MMM, YYYY');
	  }
	  
	  return moment(date).add(1, 'hour').format("M/D/YY")
	  
	  
  }
  
  getIntervalTime(date){
	  
	  const { gap } = this.state;	  
	  if(gap === '+1MONTH'){
		  return moment(date, 'MMM, YYYY');
	  }
	 
	  return moment(date, "M/D/YY");  
	  
  }
 
	
	_getEvents = async (resetPage) => {
		this.q = this.defaultQ;
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, buildingStages, unitStages, period } = this.state;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: resetPage, showBottomLoader:true, groupedData: false }));
		}
		const { searchTags } = this.props;
		
		let locQuery = _tranformTagsQuery(searchTags);
		
		let bldSearchCount = searchTags.filter(item => item.type === 'building').length;
		
		let isBuildingQ = bldSearchCount > 0 && bldSearchCount === searchTags.length;
		

		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}
		
		this.q += this.q.length > 0 ? " AND building_status:sponsor" : "building_status:sponsor";

		if (!isBuildingQ &&
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._buildingFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else{
							
						const qVal = this._buildingFilter.filter(filter => filter.search_key === key).length>0 
									? this._buildingFilter.filter(filter => filter.search_key === key)[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						if(qVal!==null){
							this.q += this.q.length > 0 ? " AND " : "";
							return this.q += `(${key}:${qVal})`;
						}
					}
					
				}
			});
		}
		
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._buildingFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this._buildingFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						dateReplaceKeys = [...dateReplaceKeys, ...dateArrayFilter[0].search_key];					
						if(dateQ){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._buildingFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		if(dateQ){
			dateReplaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				dateQ = dateQ.replace(regex, '*')
			
			})
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += '(';
			this.q += dateQ;
			this.q += ')';			
		}
		/****Price& Date query***/
		
		
		
		
		
		
		setURLFromState(this.props, this.state);
		
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		
		
		this._building_q = this.q;
		
		let sort = null;
		if(this.state.orderBy && this.state.orderBy.length > 0){			
				sort = `${this.state.orderBy} ${this.state.order}`;			
		}
		
		let time = false;
		let saleStart = false;
		
		this.q += this.q.length > 0 ? " AND " : "";
		 
		if(period == "3-mo"){
			time = "time:[NOW-3MONTHS TO NOW]";
			this.q += "sales_start_date:[NOW-15YEARS TO NOW-3MONTHS]";
		}else if(period == "6-mo"){
			time = "time:[NOW-6MONTHS TO NOW]";
			this.q += "sales_start_date:[NOW-15YEARS TO NOW-6MONTHS]";
		}else if(period == "1-yr"){
			time = "time:[NOW-12MONTHS TO NOW]";
			this.q += "sales_start_date:[NOW-15YEARS TO NOW-12MONTHS]";
		}
		
		
			
		
		this.fq = [];
		this.fq = [..._activityFQ.slice(), ...[`{!join from=alternate_keys fromIndex=we3-buildings to=building}${this.q}`]];
		
		if(time){
			this.fq.push(time);
		}
		
						
		const buildingQuery = this._getDetailQuery();

		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: this.fq,
				wt: "json",
				rows: 0,
				"json.facet": {
/*
					"contract_signed": {
						"type": "query",
						"q": `topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -address_2_normalized:"" AND -event_tags:*commercial*`,
						"facet": {
							"by_time": {
								"range": {
									"field": "time",
									"start": startDate,
									"end": endDate,
									"gap": gap,
									"facet": {
										"total_price": "sum(price)",
										"buildings" : "unique(building)"
									}
								}
							}
						}
					},
*/
					"by_building": {
						"type" : "query",
						"q" : `${buildingQuery}`,
						"facet" : {
							"unique_buildings" : "unique(building)",
							"buildings" : {
								"type": "terms",
								"field": "building",
								"mincount": 1,
								"sort" : sort,
								"offset": resetPage ? 0 : this.state.start,
								"limit": this.state.rowsPerPage
/*
								"facet": {
									"total_inventory_total": "sum(inventory_total)",
									"total_inventory_unsold": "sum(inventory_unsold)"
								}
*/
							}
							
						}
						
					}
				}
			}
		};

		let res = await _axiosCall(axiosData)
			.then(res => {
				
				return res;

			})
			.catch(error => {
				console.log("error: " + error);
			});
			
			let chartData = [];
			this._matchedBuildingIds = [];		
	
			if(res && res.facets){
				let facetData = {};
				
				
				if(res.facets.by_building && res.facets.by_building.buildings && res.facets.by_building.buildings.buckets){
					const formattedBuildings = res.facets.by_building.buildings.buckets.map(bucket => {
						this._matchedBuildingIds.push(bucket.val);
						return bucket;
					})
							
					this._getBuildings(this._matchedBuildingIds, formattedBuildings);
				
					
					if(res.facets.contract_signed && res.facets.contract_signed.by_time){
						
						res.facets.contract_signed.by_time.buckets.forEach(bucket => {
							const key = this.getIntervalName(bucket.val);								
							let existingItem = chartData.filter(item => item.time === key);
							if(existingItem && existingItem[0]){
								existingItem[0].contract_signed = bucket.count
							}
						})
						
					}
					
					this.setState(prevState => ({
						fetchInProgress: false,
						//buildingsFetchInProgress: false,
						//groupedData:  formattedDataBuilding,
						groupedCount: res.facets.by_building && res.facets.by_building.unique_buildings ? res.facets.by_building.unique_buildings : 0, //response.building.ngroups,	
						totalCount: res.facets.new_listings && res.facets.new_listings.count ? res.facets.new_listings.count : 0,
						buildingEventCount: res.facets.by_building && res.facets.by_building.count ? res.facets.by_building.count : 0,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page,
						chartData: chartData,
					}));
					
				}else{
					this.setState(prevState => ({
						fetchInProgress: false,
						buildingsFetchInProgress: false,
						showBottomLoader:false,
						groupedData:  [],
						groupedCount: res.facets.by_building && res.facets.by_building.unique_buildings ? res.facets.by_building.unique_buildings : 0, //response.building.ngroups,	
						totalCount: res.facets.new_listings && res.facets.new_listings.count ? res.facets.new_listings.count : 0,
						buildingEventCount: res.facets.by_building && res.facets.by_building.count ? res.facets.by_building.count : 0,
						start: resetPage ? 0 : this.state.start,
						page: resetPage ? 0 : this.state.page,
						chartData: chartData,
					}));
					
				}
				
				
			}

		// console.log(formattedData)
		//return formattedData;
	};
	
	 
  
  _getBuildings = async (_matchedBuildingIds, formattedBuildings) => {
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"alternate_keys:("+_matchedBuildingIds.join(" ")+")",
			"deleted:false"			
		];

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: buildingFL,
				wt: "json",
				rows: _matchedBuildingIds.length
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
			
		
		const groupedData = formattedBuildings.map(bucket => {
			bucket['building'] = formattedData.filter(building => building.key == bucket.val)[0];
			return bucket;
		})	
		
		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			groupedData:  groupedData,
			buildingData: formattedData,
			showBottomLoader: false
		}));
		
	
	};
	
	_formatStats = (response, redoHiddenFields = false) => {

		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		
		if(newStages.length === 0 ) newStages = ['all'];
		
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	_setUnitStage = (stage) => {
	
		let newStages = this.state.unitStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultUnitStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = defaultUnitStage;
		this.setState({
			unitStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = !this.props.buildingView ? Object.assign({}, defaultSubFilter) : {};
		this.setState({
			buildingStages : !this.props.buildingView ? defaultStage : false,
			unitStages : defaultUnitStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, unitStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}
	_resetActive = () => {

		let	isDefault = this.props.searchTags.length===0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			//_objectEqual(defaultUnitStage, this.state.unitStages) &&
			_objectEqual(defaultStage, this.state.buildingStages);

	    return !isDefault;
		
	}	
		
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}

	
	
	_toggleListView = (type) => {
		this.setState({viewType:type})
		
	}
	
	 setPeriod = (period) => {
	  this.setState({period:period})
     }
	
	

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, soldTimeField, gap, showBottomLoader, unitStages, viewType, period
    } = this.state;
    
    const { searchTags, user, classes } = this.props;
    
    let companyShow = { name:"Sales Agents", type:"marketing_company"};
    
    const attributeTag = searchTags.filter(item => ['building', 'neighborhood', 'borough'].indexOf(item.type) < 0);
    
    if(attributeTag && attributeTag.length>0){
	    if(attributeTag[0].type === 'developers'){
		    companyShow = { name:"Developer", type:"developers"};
	    }else if(attributeTag[0].type === 'architect'){
		    companyShow = { name:"Architect", type:"architect"};
	    }
    }
    
    
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;
    let months = 1;
	if(period == "3-mo"){
		months = 3;
	}else if(period == "6-mo"){
		months = 6;
	}else if(period == "1-yr"){
		months = 12;
	}
   	
	const buildingQuery = this._getDetailQuery();

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >

            <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={_hasPermission(user, 'admin-filters') ?  this._buildingFilter : this._buildingFilter.filter(item => !item.admin_only)}   
            	placeHolder={_hasPermission(user, 'search-firms') ? false : "Borough, Neighborhood or Building"}
            	buildingAttributes={_hasPermission(user, 'search-firms') ? false : []}   
            	{...this.props}  
            	groupedView   
            	buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	setSubFilter={this._setSubFilter}	
            	numToShow={5}
				
				setSubFilters={this._setSubFilters}
				resetActive={this._resetActive()}
				q={'*:*'}
		  		fq={[...this.fq, ...[`${buildingQuery}`], ...["hierarchy:[0 TO 1]"]]}
		  		fl={"display_full_street_address, building_name, address_2_normalized, markets_major, markets_sub, neighborhood, borough, city, zip, time, event_time, price, event_tags, bedrooms, baths, sq_ft".split(", ")}	     	
          		core_name={'we3-activities-3'}
          		
          		sort={"time desc"}
				hideBuildingStage
             />
             
          
            <div className="material-table__header-info">
            	<Row>
			   		<Col md={12} className={"mt-2"}>
			   			
			   			<div className={classes.root}>
			   			  	
					      <ButtonGroup size="small" aria-label="small button group">							        
					      	<Tooltip title="Sales velocity based on the last 3 months of contracts reported. Buildings that did not report a contract or started sales less than 3 months ago are excluded." aria-label="See sales velocity and absorption rate based on the last 3 months of sales.">
					        	<Button onClick={()=> this.setPeriod("3-mo")} className={period === "3-mo" && classNames(classes.button, "selected")}>3 Months</Button>
					        </Tooltip>
					        <Tooltip title="Sales velocity based on the last 6 months of contracts reported. Buildings that did not report a contract or started sales less than 6 months ago are excluded." aria-label="See sales velocity and absorption rate based on the last 6 months of sales.">
					        	<Button onClick={()=> this.setPeriod("6-mo")} className={period === "6-mo" && classNames(classes.button, "selected")}>6 Months</Button>
					        </Tooltip>
					        <Tooltip title="Sales velocity based on the last 12 months of contracts reported. Buildings that did not report a contract or started sales less than 12 months ago are excluded." aria-label="See sales velocity and absorption rate based on the last 1 year of sales.">
					       	 	<Button onClick={()=> this.setPeriod("1-yr")} className={period === "1-yr" && classNames(classes.button, "selected")}>12 Months</Button>
					       	</Tooltip>	
					      
					      </ButtonGroup>
					  </div>
			   		</Col>
			   </Row>
            </div>


			
			
			{!_hasPermission(user, 'market-activity-detail') ? 
				<div style={{marginTop:50}}>
					<EnterpriseGate 
						copy={<span>Access to Market Activity Details requires an upgrade to your subscription.</span>}
						img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-market-activity-detail.png`}
						upgrade={true}
						user={user}
						nextPlan={"advanced"}
					/>
					
				</div>
				
			:
            <Fragment>
		            {(showBottomLoader || buildingsFetchInProgress) && <LinearLoading />}
		            
		            

		            
		            <SalseVelocityRankChart facetData={groupedData} months={months}/>
		            
		            
		            <div className="material-table__wrap">
		              
		              
		              {!buildingsFetchInProgress && !fetchInProgress && this.state.groupedView && groupedData && 
			              
			         
			          
			           <div className="material-table expansion__table">
			           		 <hr/>
			           		 <Col md={12} lg={12} xl={12}>
			           		<Row>
			           			<Col md={12} lg={8} xl={8}>
					           		<div className="material-table__header-info pb-3 desktop__show">
					           			<span className="bold-text">{months}-Months Sales Velocity</span>  Based on {' '}
										<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
										{eventTypes.length===1 ? eventTypes[0] : 'Events'} 
										
										{groupedCount ? <Fragment> Across <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings </Fragment> : ''}
											
									</div>
									<div className="material-table__header-info pb-3 mobile__show">
										<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
										{eventTypes.length===1 ? eventTypes[0] : 'Events'}  
										
										{groupedCount ? <Fragment> Across <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings </Fragment> : ''}
										<br/> Date Range: { selectedDate ? 
											 <Fragment> {this.state.gap === '+1MONTH' ? "Month" : "Week"} of{` `} 
											 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
											 </Fragment>
											:
											 <Fragment> 
											 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
											 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
											</Fragment>
										}	
									</div>
								</Col>
								<Col md={12} lg={4} xl={4} className={"text-right"}>
					           		{/* <div className={`material-table__header-info  pb-3 unit_building_toggle`} style={{marginTop:-10}}>								      
								       <ButtonGroup size="small" aria-label="small outlined button group">
									        	<Tooltip title={`See events grouped by buildings`}>
									        		<Button disabled={viewType=="building"} onClick={() => {this._toggleListView("building")}} startIcon={<GroupViewIcon size={14} color={viewType!="building" ? "#780F9E" : "#A3ADC2"} />}>Group</Button>
									        	</Tooltip>
									        	<Tooltip title={`See event details by units`}>
									        		<Button disabled={viewType=="list"} onClick={() => {this._toggleListView("list")}} startIcon={<ListViewIcon size={14} color={viewType!="list" ? "#780F9E" : "#A3ADC2"} />}>List</Button>
									        	</Tooltip>
									        	<Tooltip title={`See analytics about the events`}>
									        		<Button disabled={viewType=="map"} onClick={() => {this._toggleView("map")}} startIcon={<StatsViewIcon size={14} color={viewType!="map" ? "#780F9E" : "#A3ADC2"} />}>Stats</Button>
									        	</Tooltip>	  
										</ButtonGroup>			      	
								    </div>*/}

						             
					            </Col>
					          </Row>	
							 </Col>
							 
							 
			           	{viewType == "building" ?
				          <Fragment>	
			           	   <Container>
						        <Row className="expansion__summary__header">
									<Col lg={1} xs={12}>	                          
									  	<TableSortLabel
							                active={orderBy === "count"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "count")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Velocity
							              </TableSortLabel>
									</Col>
									 <Col lg={4} className="">	 
									 	Building
			                        	{/*<TableSortLabel
							                active={orderBy === "index"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "index")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Building
							              </TableSortLabel>  */}                       
									 	
									</Col>	
									<Col lg={2} xs={12} className="no-padding">	
										Sales Start                       
									  	{/*<TableSortLabel
							                active={orderBy === companyShow.type}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, companyShow.type)}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                {companyShow.name}
							              </TableSortLabel>*/}
									</Col>
									<Col lg={2} xs={12} className="">	
										 Sales Progress                          
									  	{/*<TableSortLabel
							                active={orderBy === "inventory_total"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "inventory_total")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Total Units
							              </TableSortLabel>*/}
									</Col>
									<Col lg={3} xs={12} className="no-padding">
										Absorption	                          
									  	{/*<TableSortLabel
							                active={orderBy === "inventory_unsold"}
							                direction={order}
							                onClick={event => this.handleRequestSort(event, "inventory_unsold")}
							                className="material-table__sort-label"
							                dir="ltr"
							              >
							                Unsold Units
							              </TableSortLabel>*/}
									</Col>	
									
									                     
			                       
									
			                    </Row>
			                </Container>
			              {groupedData.length ===0 &&		              
				            <Container>
						        <Row className="no__data">
									<Col lg={12} xs={12} className="no__data-text">			                          
									  	No data found for your search.
									</Col>	
								</Row>
							</Container>
			              }  	           
			              {groupedData.filter(item => item.building).map((d) => {
				                const item = d
				                const numFound = item.count;
				                const isSelected = this.isSelected(item.val);
				                const building = item.building;
				                const salesVelocity = Math.round((numFound / months) * 10 ) / 10;
				                const monthsLeft = Math.round((building.inventory_unsold / salesVelocity) * 10 ) / 10;
					            return(  			            
					              <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
							        <ExpansionPanelSummary
							          expandIcon={<ExpandMoreIcon />}
							          aria-label="Expand"
							          aria-controls=""
							          id={d.groupValue}
							        >
							        <Container>
								        <Row className="expansion__summary" style={{alignItems:"center"}}>
											<Col lg={1} xs={12} >			                          
											  	<Tooltip title={`See Market Activity - ${building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}`}>	   
													<IconButton	size={`small`} style={{background:"transparent"}} onClick={(e) => {e.stopPropagation();e.preventDefault(); this._handleBuildingOpen(building);}}> 			                          
											  			{/*<Badge color="matching" style={{ width:50 }}><span className="bold-text">{Math.round((numFound / months) * 10 ) / 10}</span> 
											  			
											  				<OpenNewIcon size={12} color={'#FFF'} style={{marginLeft:3,marginTop:-2}} />
											  			</Badge>*/}
											  			<VelocityChart velocity={salesVelocity} />
											  			
											  		</IconButton>
												</Tooltip>
											</Col>	
											<Col lg={4} xs={12} >			                          
											  <div className="expansion__header">									  									  	
											  		<span className="building_name"  onClick={event => this.handleGroupedBuildingClick(event, building)}>{building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}
								                	 <BuildingIcons building={building} />
											  		 
											  		</span>
						                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{building.neighborhood && building.neighborhood[0] ? `${_capitalizeText(building.neighborhood[0])}, ` : ''} {_capitalizeText(building.borough)}</span>
						                            <br/>
						                            
						                            
						                            <div className={"mt-1"}>
						                            <span className="light-text small-text">
						                             PPSF:  {building.inventory_stats_listings_average_ppsf > 100 ? `$${_formatNumber(building.inventory_stats_listings_average_ppsf).toUpperCase()}` : '--'}
						                            
						                            {' | '}
						                            Range: {building.inventory_stats_listings_low_price > 100 ? `${_formatPrice(building.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(building.inventory_stats_listings_high_price).toUpperCase()}` : '--'}
						                            </span>
						                            </div>
											  
											  </div>				          									  
											</Col>
											<Col lg={2} xs={12}  className="no-padding">			                          
											  		{/*<span className="light-text small-text">{building[companyShow.type] ? _capitalizeText(building[companyShow.type]) : 'Not Available'}</span>*/}
											  		<div>{_capitalizeText(moment(building.sales_start_date).fromNow())}</div>
											  		<span className="light-text small-text" style={{color:"#858AA1"}}>({moment(building.sales_start_date).format("M/D/YYYY")})</span>
											</Col>
											<Col lg={2} xs={12} className="">			                          
											  	
											
											  	{building.offering_construction==='conversion' 
					                          	 ?
					                          	 <SalesProgress label={"Units Sold"} 
								                 	progress={parseFloat(((building.inventory_total - building.inventory_unsold) / building.inventory_total) * 100).toFixed(0)} 
								                 	inventory_unsold={building.inventory_total - building.inventory_unsold} inventory_total={building.inventory_total}  color={"grey"} />
					                          	 :
								                 <SalesProgress label={"Units Sold"} 
								                 	progress={parseFloat(((building.inventory_total - building.inventory_unsold) / building.inventory_total) * 100).toFixed(0)} 
								                 	inventory_unsold={building.inventory_total - building.inventory_unsold} inventory_total={building.inventory_total}  />
							                    } 

											</Col>	
											<Col lg={3} xs={12}  className="no-padding">	
												{building.offering_construction==='conversion' ?
													<Fragment>		                          
											  			
												  		<span className="light-text small-text" style={{color:"#858AA1"}}>
												  			Not Applicable
												  		</span>
												  		<ToolTip 
											        		title={`Absoprtion is not calculated for rental conversion buildings as not all units are eligible for sale.`} 
											        	/>
											  		</Fragment>
											  		:
											  		<Fragment>
											  			{monthsLeft} <span className="light-text small-text">Months</span>
												  		<br/>
												  		<span className="light-text small-text" style={{color:"#858AA1"}}>
												  			Est Sellout: {_capitalizeText(moment().add(monthsLeft, 'M').fromNow())} 
												  			{' '}({moment().add(monthsLeft, 'M').format("M/D/YYYY")})
												  			
												  		</span>
											  		</Fragment>
											  	}	
											</Col>	
											
										             
					                       		                        			                        	
				                        </Row>
				                    </Container>
							        </ExpansionPanelSummary>
							        <ExpansionPanelDetails>				        
							        	<UnitsListTable 
						              		isSelected={this.isSelected}
						              		handleBuildingClick={this.handleBuildingClick}
						              		handleClick={this.handleClick}
						              		handleRequestSort={this.handleRequestSort}
						              		handleSelectAllClick={this.handleSelectAllClick}
						              		{...this.state} 
						              		{...this.props} 
						              		reactTableFiltered={false}	
						              		isGrouped
						              		q={'*:*'}
						              		fq={this.fq ? [...this.fq, `${buildingQuery}`] : [`${buildingQuery}`]}
						              		fl={this.fl}	
						              		building={building}
						              		handleGroupedBuildingClick={this.handleGroupedBuildingClick}	
						              		buildingKey={building.key}              		
						              />
							        </ExpansionPanelDetails>
							     </ExpansionPanel>
							    )
							 })
			             }  
			              </Fragment>
		            
			              :	              
				              <Fragment>
				              <UnitsListTable 
				              		isSelected={this.isSelected}
				              		handleBuildingClick={this.handleBuildingClick}
				              		handleClick={this.handleClick}
				              		handleRequestSort={this.handleRequestSort}
				              		handleSelectAllClick={this.handleSelectAllClick}
				              		{...this.state} 
				              		{...this.props} 
				              		reactTableFiltered={false}	
				              		isGrouped
				              		q={'*:*'}
				              		fq={this.fq ?  [...this.fq, `${buildingQuery}`] : [`${buildingQuery}`]}
				              		fl={this.fl}	
				              />
				              <hr/>
				              </Fragment>     
			              }
			            </div>
			          }
		             
		            </div>
		            {viewType=="building" && paginationCount && groupedData ? <TablePagination
		              component="div"
		              className="material-table__pagination"
		              count={paginationCount}
		              rowsPerPage={rowsPerPage}
		              page={page}
		              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
		              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
		              onChangePage={this.handleChangePage}
		              onChangeRowsPerPage={this.handleChangeRowsPerPage}
		              rowsPerPageOptions={[25, 50, 100]}
		              dir="ltr"
		              SelectProps={{
		                inputProps: { 'aria-label': 'rows per page' },
		                native: true,
		              }}
		            /> : null}
			</Fragment>
			}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
	  user: state.user, 
	  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles,  { withTheme: true })(UnitsSearchTable)));