import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { sidebarReducer, themeReducer, rtlReducer, buyersListReducer, authReducer, searchReducer, planReducer } from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  buyersLists: buyersListReducer,
  user: authReducer,
  search: searchReducer,
  plan: planReducer
});

const store = createStore(reducer);

export default store;
