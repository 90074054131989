import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FilterGallery from './components/FilterGallery';

const Gallery = ( props ) => (
  <Container>
    <Row>
      <FilterGallery {...props} />
    </Row>
  </Container>
);

export default Gallery;
