import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';
import UnitIcons from '../../../shared/components/UnitIcons';

import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import ArrowEqualIcon from 'mdi-react/MinusCircleOutlineIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	SalesProgress,
	propertyFL,
	_activityFQ
} from "../../../shared/helpers/utils";

import { _hasPermission	} from "../../../shared/helpers/access";
import { getStatusColors } from '../../../shared/helpers';


import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';
const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
	 <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};
const UnitStatus = ({ event, type }) => {
 	return null;
    return (event.event_tags.indexOf("event-stage_sponsor")>=0 ?
		<Tooltip title={`This unit was owned by the Sponsor at the time of the event.`}>
			<IconButton size={`small`} style={{background:"transparent"}}>
				<span className="unit-type_badge" style={{borderColor:'#BFC4D1',background:'#FFF',color:'#BFC4D1'}}>Sponsor Sale</span>
			</IconButton>
		</Tooltip>
	: event.event_tags.indexOf("event-stage_resale")>=0 ?
	    <Tooltip title={`This unit was already sold by the Sponsor at the time of the event.`}>
			<IconButton size={`small`} style={{background:"transparent"}}>
				<span className="unit-type_badge" style={{borderColor:'#BFC4D1',background:'#FFF',color:'#BFC4D1'}}>Resale</span>
			</IconButton>
		</Tooltip>
	: null
	)
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}

const ShowStatus = ({d}) => {
	let status = "off market";
	if(d && d.property_data && d.property_data.current_status){
		
		if(["pending", "in contract", "contract"].indexOf(d.property_data.current_status.toLowerCase())>=0){
			status = "contract"
		}else if(["active"].indexOf(d.property_data.current_status.toLowerCase())>=0){
			status = "active"
		}
	}
	
	return (
	<Tooltip title={`Current status of this unit`}>
		 <IconButton size={`small`} style={{background:'transparent'}}>
			 <span className="unit-type_badge" style={{background:getStatusColors[status] }}>{_capitalizeText(status)} <OpenInNewIcon size={11} /></span>
		 </IconButton>
	 </Tooltip>
 	)
}
class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 20,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
		propertiesFetchInProgress: false,
	};
	
	isAdmin = false;

	componentDidMount() {
		const { user } = this.props;
		if(this.props.building || this.props.building === "" || this.props.viewType == 'list'){
			this.queryUnits();
		}
		
		this.isAdmin = _hasPermission(user, "market-activity-error-checking");
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(this.props.building || this.props.building === "" || this.props.viewType == 'list'){
		  if(!_objectEqual(this.props.q, prevProps.q) ||
		  (this.props.buildingKey !== prevProps.buildingKey ||
		   this.props.viewType !== prevProps.viewType ||
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start)){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 this.queryUnits(reset);
		  }		 
		}
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};
	
	handleUnitClick = (item) => {
	 const buildingURL = _getBuildingURL(item, 'building_slugs') + '/' + _nameToUrl(item.address_2_normalized.toLowerCase().replace('unit', 'apt'));
	 //this.props.history.push(buildingURL);
	 window.open(buildingURL);
    };

	
	queryUnits = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const { building, buildingKey, q, fq, fl, viewType } = this.props;	
		
		let sort = "time desc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			sort = `${this.state.orderBy} ${this.state.order}`;
			
			
		}
			
		
		const unitsFq = _activityFQ.slice();			
		if(viewType=="building"){
			unitsFq.push(`building:(${building.alternate_keys.join(' ')})`);
		}
		console.log(fq, unitsFq, "buildingfq")
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=15",
			method: "post",
			query: {
				q: q,
				fq: [...fq, ...unitsFq],
				sort: sort,
				wt: "json",
				rows: 50,
				start: this.state.start,
						
			}
		};
		
		

		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					console.log(res.docs, "resp1")
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					const propKeys = [];
					res.docs.map(item => {
						// if(item.address_2_normalized==""){
						// 	propKeys.push(item.building.replace("b-", "p-"));
						// }
						propKeys.push(item.property);
					})
					this._getProperties(propKeys, res.docs.map(doc => doc.building), newUnitListData);
										
					
					
					
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	_getListings = async (_matchedListingIds, _matchedBuildingIds, newUnitListData) => {
		const { viewType } = this.props;	
		
		if (!this.state.propertiesFetchInProgress) {
			this.setState(prevState => ({ propertiesFetchInProgress: true, }));
		}
		var fq = [
			"key:("+_matchedListingIds.join(" ")+")",
			"deleted:false"			
		];
	
		let axiosData = {
			url: buildingUrl + "search-listings?cache=15",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: ["key", "sale_status"],
				wt: "json",
				rows: _matchedListingIds.length
			}
		};
	
		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
		
		if(formattedData && formattedData.length > 0){
			newUnitListData =  newUnitListData.map(item => {
				console.log(item.foreign_key)
				if(!item['listing_data']){
					item['listing_data'] = formattedData.filter(listing => item.foreign_id == listing.key)[0];
				}
				
				return item;
			});
			
		}
		
		if(viewType === "list"){
			this._getBuildings(_matchedBuildingIds, newUnitListData);
		}else{
			this.setState( { unitListData: newUnitListData, propertiesFetchInProgress: false, fetchInProgress: false, buildingKey:false } )
		}
		
		
	
	};
	
	_getProperties = async (_matchedPropertyIds, _matchedBuildingIds, newUnitListData) => {
		const { viewType } = this.props;	
		
		if (!this.state.propertiesFetchInProgress) {
			this.setState(prevState => ({ propertiesFetchInProgress: true, }));
		}
		var fq = [
			"alternate_keys:("+_matchedPropertyIds.join(" ")+") OR key:("+_matchedPropertyIds.join(" ")+")",
			"deleted:false"			
		];

		let axiosData = {
			url: buildingUrl + "we3-properties?cache=15",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: ["key", "current_status"],
				wt: "json",
				rows: _matchedPropertyIds.length
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
		
		if(formattedData && formattedData.length > 0){
			newUnitListData =  newUnitListData.map(item => {
				if(!item['property_data']){
					if(item.address_2_normalized==""){
						
					}
					item['property_data'] = formattedData.filter(property => item.property == property.key)[0];
				}
				
				return item;
			});
			
		}
		
		if(viewType === "list"){
			this._getBuildings(_matchedBuildingIds, newUnitListData);
		}else{
			this.setState( { unitListData: newUnitListData, propertiesFetchInProgress: false, fetchInProgress: false, buildingKey:false } )
		}
		
		
	
	};
	
	_getBuildings = async (_matchedBuildingIds, newUnitListData) => {
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"alternate_keys:("+_matchedBuildingIds.join(" ")+")",
			"deleted:false"			
		];
console.log(buildingFL,fq, "formatted1")
		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: buildingFL,
				wt: "json",
				rows: _matchedBuildingIds.length
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
			console.log(formattedData, "formatteddata")
		if(formattedData && formattedData.length > 0){
			 newUnitListData = newUnitListData.map(item => {
				item['building_data'] = formattedData.filter(building => item.building.indexOf(building.key)>=0)[0];
				return item;
			})	
		}
		
		console.log(newUnitListData, "newUnitListData")
		this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false });
		
		
	
	};
	
	render(){
		const { reactTableFiltered, handleBuildingClick, isSelected, handleClick, order, orderBy, selected, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, building, soldTimeField, buildingKey, viewType, handleGroupedBuildingClick, chartType, chartView } = this.props;
		if(!reactTableFiltered && (this.state.start===0 && (this.state.unitListData.length===0 || this.state.fetchInProgress || this.props.propertiesFetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const data = reactTableFiltered || this.state.unitListData;
console.log(data, "data")
		return (
		<Fragment>
			<div>
			<Table className="material-table">
		        <UnitsSearchTableHead
		          numSelected={[...selected].filter(el => el[1]).length}
		          order={order}
		          orderBy={orderBy}
		          onSelectAllClick={handleSelectAllClick}
		          onRequestSort={isGrouped ? this._handleRequestUnitsSort : handleRequestSort}
		          rowCount={reactTableFiltered.length}
		        />
		        <TableBody>
		          {data
		            .map((d) => {
		              const itemSelected = isSelected(d.id);
		              const floorplan = false; //_getUnitFloorPlan(d);
					  const buildingData = d;
					  let salesOffice = null;
					  if(d.sales_office_details){
					   		 try{
						   		 salesOffice = JSON.parse(d.sales_office_details);						   		
					   		 }catch(e){
						   		 //cannot parse JSON
					   		 }	   		 
				   	  }
					  let showStatus, eventType;
						if(d.topic[0] === "listings" && d.event_tags.indexOf("just-listed")>=0){
							  showStatus = <Fragment><span className="status-small listed"/>Listed <UnitStatus event={d} /></Fragment>
							  eventType = "Listed"
						  }else if(d.topic[0] === "listings" && d.event_tags.indexOf("contract-signed")>=0){
							  showStatus = <Fragment><span className="status-small contract"/> Contract <UnitStatus event={d} /></Fragment>
							  eventType = "Contract Signed"
						  }else if(d.topic[0] === "transactions"){
							  showStatus = <Fragment><span className="status-small sold" /> Closed<UnitStatus event={d} /></Fragment>
							  eventType = "Closed"
						  }else if(d.topic[0] === "listings" && d.event_tags.indexOf("price-decrease")>=0){
							  showStatus = <Fragment><span className="status-small price-drop"/> Price Drop <UnitStatus event={d} /></Fragment>
							  eventType = "Price Drop"
						  }else if(d.topic[0] === "listings" && d.event_tags.indexOf("price-increase")>=0){
							  showStatus = <Fragment><span className="status-small price-hike"/> Price Hike <UnitStatus event={d} /></Fragment>
							  eventType = "Price Hike"
						  }
						if(chartView == "property"){
						  
							if(d.event_tags.indexOf("event-stage_sponsor")>=0){
							  console.log(eventType, "eventType")
								  showStatus = <Fragment><span className="status-small newdev"/>New Dev ({eventType}) <UnitStatus event={d} /></Fragment>
							  }else if(d.event_tags.indexOf("condo")>=0){
								  showStatus = <Fragment><span className="status-small condo"/> Resale Condo ({eventType}) <UnitStatus event={d} /></Fragment>
							  }else if(d.event_tags.indexOf("single-family")>=0){
								  showStatus = <Fragment><span className="status-small single"/> Single-Family ({eventType}) <UnitStatus event={d} /></Fragment>
							  }else if(d.event_tags.indexOf("multi-family")>=0){
								  showStatus = <Fragment><span className="status-small multi"/> Multi-Family ({eventType}) <UnitStatus event={d} /></Fragment>
							  }else{
								  showStatus = <Fragment><span className="status-small coop" /> Resale Co-op ({eventType})<UnitStatus event={d} /></Fragment>
							  }
							
						}
					  

		              return (
		                <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleUnitClick( d)}
                          aria-checked={itemSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={itemSelected}
                        >
                           <TableCell className="material-table__cell" padding="checkbox" onClick={event => handleClick(event, d.id)}>
		                  	{/*<Tooltip title="Add to List">
		                    	<Checkbox checked={itemSelected} className="material-table__checkbox" />
		                    </Tooltip>   */}                       
		                  </TableCell>
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{d.topic[0] === "transactions" ? <span>{moment(d[soldTimeField]).format('M/D/YY')} {soldTimeField === "time" ? "(Recorded Date)" : ""}</span> : moment(d.time).format('M/D/YY')}</span>
                          </TableCell>   
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                           <span className="light-text small-text">
                           
                           
                           		{showStatus}
                           	</span>

                          </TableCell>    
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                           {viewType == "list" && 
	                           <Fragment>
	                           {d && d.building_data ?  
		                           
		                           <span className="building_name">{d.building_data.name ? _capitalizeText(d.building_data.name) : _capitalizeText(d.building_data.display_full_street_address)}{d.address_2_normalized ? ", " + d.address_2_normalized.toUpperCase().replace("APT", "Apt") : ""}</span>
		                           :
		                           <span>{d.building_name ? _capitalizeText(d.building_name) : _capitalizeText(d.display_full_street_address)}{d.address_2_normalized ? ", " + d.address_2_normalized.toUpperCase().replace("APT", "Apt") : ""}</span>
		                        }
								<ShowStatus d={d} />
	                            </Fragment>
                           }
                           <span>
						   {/* {d.address_2_normalized.toUpperCase().replace("APT", "Apt")}  <UnitIcons building={building} unit={d} eventView /> */}
                            {viewType == "list" ?
	                           <div className="light-text small-text">{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</div>:
	                           <>	{d.address_2_normalized.toUpperCase().replace("APT", "Apt")} <ShowStatus d={d} /></>
	                        }                     		
                           </span>
						  

                          </TableCell>
                            <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	<span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                    <span className="light-text small-text">{d.baths > -1 ? d.baths+' Bath' : '--'}</span>
		                  </TableCell>
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	{["Price Drop", "Price Hike"].indexOf(eventType)>=0 && d.price_change_percent ? 
									<span className="small-text">
									{eventType == "Price Drop" ? <ArrowDownIcon size={16} color="#FF206E" style={{marginTop:-2}} /> : <ArrowUpIcon size={16}  color="#4ce1b6" style={{marginTop:-2}} />} {d.price_change_percent ? `${Math.round(d.price_change_percent * 10000)/100}%` : "--"}<span className="small-text light-text"><br/>(
									{d.price_change_amount ? '$'+_formatNumber(Math.abs(d.price_change_amount)) : '--'})</span>
									
									</span>
								  :
									<span className="small-text">{parseInt(d.price.toString().replace(/\D/g,'')) > 0 ? '$'+_formatNumber(d.price.toString().replace(/\D/g,'')) : '--'}
										{d.ppsf ? 
											<span className="light-text small-text"><br/>({d.ppsf})</span>
											: null
										}
									</span>
								}
                          </TableCell>                         
                          
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          >
	                          	<span className="light-text small-text">
	                          		<a href={`javascript:void(0)`} className="alert_show">                          		
	                          			See Unit <OpenInNewIcon size={14} style={{marginTop:"-3px"}} />
	                          		</a><br/>
	                          	</span>                        
                          </TableCell> 
                          
                        </TableRow>
		              );
		            })}
		          {emptyRows > 0 && (
		            <TableRow style={{ height: 49 * emptyRows }}>
		              <TableCell colSpan={6} />
		            </TableRow>
		          )}
		        </TableBody> 
		        
              
		      </Table>
		      </div>
		     
			   {!reactTableFiltered && 
				<div className="unit__load">			   		
				    <Tooltip title="Load more units">
				        <Button
					        onClick={(e) => {this._loadMore(e)}}
					         variant="contained"
					         color="news"
					         className={`timeline__load-more`}		         
					         startIcon={<LoadMoreIcon />}
					         disabled={this.state.fetchInProgress || this.state.noMoreResults}
					     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "No More Activities" : "Load More"}`}
					     </Button>				 
				  	</Tooltip>				
			    </div>
			    }
			</Fragment>
		);
	}
}

export default connect(state => ({
  user: state.user
}))(UnitsListTable);