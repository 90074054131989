import React, { useRef, useState, useEffect } from 'react';
import html2canvas from 'html2canvas';

const MergeImages = ({ image1Src, image2Src }) => {
  const canvasRef = useRef(null);
  const image1Ref = useRef(new Image());
  const image2Ref = useRef(new Image());
  const [image1Loaded, setImage1Loaded] = useState(false);
  const [image2Loaded, setImage2Loaded] = useState(false);
  const [dropPosition, setDropPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
	const image1 = image1Ref.current;
	const image2 = image2Ref.current;

	image1.crossOrigin = "anonymous";
	image2.crossOrigin = "anonymous";

	image1.src = image1Src;
	image2.src = image2Src;

	image1.onload = () => {
	  setImage1Loaded(true);
	  drawImages();
	};

	image2.onload = () => {
	  setImage2Loaded(true);
	  drawImages();
	};
  }, [image1Src, image2Src]);

  useEffect(() => {
	if (image1Loaded && image2Loaded) {
	  drawImages();
	}
  }, [image1Loaded, image2Loaded, dropPosition]);

  const drawImages = () => {
	const canvas = canvasRef.current;
	const ctx = canvas.getContext('2d');
	ctx.clearRect(0, 0, canvas.width, canvas.height);

	const image1 = image1Ref.current;
	const image2 = image2Ref.current;

	if (image1Loaded) {
	  ctx.drawImage(image1, dropPosition.x, dropPosition.y, image1.width, image1.height);
	}

	if (image2Loaded) {
	  ctx.globalAlpha = 0.5;
	  ctx.drawImage(image2, 0, 0, canvas.width, canvas.height);
	  ctx.globalAlpha = 1.0;
	}
  };

  const handleDragStart = (event) => {
	event.dataTransfer.setData('text/plain', null);
  };

  const handleDragOver = (event) => {
	event.preventDefault();
  };

  const handleDrop = (event) => {
	event.preventDefault();
	const rect = canvasRef.current.getBoundingClientRect();
	const x = event.clientX - rect.left - (image1Ref.current.width / 2);
	const y = event.clientY - rect.top - (image1Ref.current.height / 2);
	setDropPosition({ x, y });
  };

  const handleDownload = () => {
	html2canvas(canvasRef.current).then(canvas => {
	  const link = document.createElement('a');
	  link.href = canvas.toDataURL('image/png');
	  link.download = 'merged-image.png';
	  link.click();
	});
  };

  return (
	<div>
	  <canvas
		ref={canvasRef}
		width={500}
		height={500}
		onDragOver={handleDragOver}
		onDrop={handleDrop}
		style={{ border: '1px solid black' }}
	  ></canvas>
	  <img
		src={image1Src}
		alt="Image 1"
		draggable="true"
		onDragStart={handleDragStart}
		style={{ width: '100px', cursor: 'grab' }}
	  />
	  <button onClick={handleDownload}>Download Merged Image</button>
	</div>
  );
};

export default MergeImages;
