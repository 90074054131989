import React, { useState, Fragment } from 'react';
import {
  Button,
  ButtonGroup,
  Tooltip,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import CloseIcon from 'mdi-react/CloseIcon';
import CalendarRangeIcon from 'mdi-react/CalendarRangeIcon';

const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '-8px',
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),
	},
  },
  button: {
	'&.selected': {
	  background: '#EDDEF5',
	},
  },
  dialogTitle: {
	fontSize: '1rem',
	fontWeight: 'bold'
  },
  dialogContent: {
	fontSize: '1rem',
	color: theme.palette.text.primary,
  },
  dialogActions: {
	// justifyContent: 'space-between',
	paddingBottom:20
  },
  dialogButton: {
	color: '#780F9E',
	borderColor: '#780F9E',
	'&:hover': {
	  backgroundColor: '#EDDEF5',
	},
  },
  errorText: {
	color: theme.palette.error.main,
	marginBottom: theme.spacing(2),
	fontSize: 13,
  },
  dateContainer: {
	display: 'flex',
	alignItems: 'center',
	marginBottom: theme.spacing(3),
	marginTop: theme.spacing(1),
  },
  closeButton: {
	padding: theme.spacing(1),
  },
  datePicker: {
	flex: 1,
  },
  defaultOptions: {
	marginTop: 0,
  },
  listItem: {
	borderTop: `1px solid ${theme.palette.divider}`,
	paddingLeft:0,
	paddingRight:0
  },
  listItemText: {
	'&> span': {
	  fontSize: '0.875rem', // Smaller font size
	},
  },
  presetTitle: {
	fontWeight: 'bold',
	marginBottom: theme.spacing(0),
	fontSize: '.9rem'
  },
}));

const DateType = ({ gap, setDateType, setCustomDate, customDate, calculatePreviousPeriod }) => {
  const classes = useStyles();
  const [customDateModal, setCustomDateModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState('');

  const predefinedGaps = ['+7DAY', '+1MONTH', '+3MONTH', '+1YEAR'];

  const handleCustomDateClick = () => {
	setCustomDateModal(true);
  };

  const handleDateTypeChange = (type) => {
	setDateType(type);
	setCustomDateModal(false); // Reset custom date selection
  };

  const handleStartDateChange = (date) => {
	setStartDate(date);
	setError(''); // Clear error on date change
  };

  const handleEndDateChange = (date) => {
	setEndDate(date);
	setError(''); // Clear error on date change
  };

  const removeStartDate = () => {
	setStartDate(null);
	setError('');
  };

  const removeEndDate = () => {
	setEndDate(null);
	setError('');
  };

  const applyCustomDate = () => {
	if (!startDate) {
	  setError('Start date is required.');
	  return;
	}

	try {
	  const { startDate: newStartDate, endDate: newEndDate, previousPeriodStartDate, previousPeriodEndDate, gap } =
		calculatePreviousPeriod(startDate, endDate);

	  setCustomDate({
		startDate: newStartDate,
		endDate: newEndDate,
		gap: gap,
		previousPeriodStartDate: previousPeriodStartDate,
		previousPeriodEndDate: previousPeriodEndDate,
	  });

	  setDateType(gap);
	  setCustomDateModal(false);
	} catch (error) {
	  setError(error.message);
	}
  };

  const handleClose = () => {
	setCustomDateModal(false);
	setError(''); // Clear error on close
  };

  const isCustomSelected = !predefinedGaps.includes(gap);

  const handlePresetClick = (startDate, endDate) => {
	setStartDate(startDate);
	setEndDate(endDate);
  };

 const presetOptions = [
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`Week to Date (${moment().startOf('isoWeek').format('M/D/YYYY')} - ${moment().format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().startOf('week'), moment()],
	 },
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`Month to Date (${moment().startOf('month').format('M/D/YYYY')} - ${moment().format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().startOf('month'), moment()],
	 },
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`Quarter to Date (${moment().startOf('quarter').format('M/D/YYYY')} - ${moment().format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().startOf('quarter'), moment()],
	 },
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`Year to Date (${moment().startOf('year').format('M/D/YYYY')} - ${moment().format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().startOf('year'), moment()],
	 },
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`Week of ${moment().subtract(2, 'weeks').startOf('isoWeek').format('MMM Do')} (${moment().subtract(2, 'weeks').startOf('isoWeek').format('M/D/YYYY')} - ${moment().subtract(2, 'weeks').endOf('isoWeek').format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().subtract(2, 'weeks').startOf('isoWeek'), moment().subtract(2, 'weeks').endOf('isoWeek')],
	 },
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`${moment().subtract(2, 'months').format('MMMM YYYY')} (${moment().subtract(2, 'months').startOf('month').format('M/D/YYYY')} - ${moment().subtract(2, 'months').endOf('month').format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().subtract(2, 'months').startOf('month'), moment().subtract(2, 'months').endOf('month')],
	 },
	 {
		 label: <Fragment><CalendarRangeIcon size="18" style={{marginTop:-2}} color="#BF96D9" /> {`Q${moment().subtract(2, 'quarters').quarter()} ${moment().subtract(2, 'quarters').year()} (${moment().subtract(2, 'quarters').startOf('quarter').format('M/D/YYYY')} - ${moment().subtract(2, 'quarters').endOf('quarter').format('M/D/YYYY')})`}</Fragment>,
		 value: [moment().subtract(2, 'quarters').startOf('quarter'), moment().subtract(2, 'quarters').endOf('quarter')],
	 }
 ];


  return (
	<div>
	  <ButtonGroup size="small" aria-label="small button group">
		<Tooltip title="Show latest completed week" aria-label="Show latest completed week">
		  <Button
			onClick={() => handleDateTypeChange('+7DAY')}
			className={gap === '+7DAY' ? classNames(classes.button, 'selected') : classNames(classes.button)}
		  >
			Weekly
		  </Button>
		</Tooltip>
		<Tooltip title="Show latest completed month" aria-label="Show latest completed month">
		  <Button
			onClick={() => handleDateTypeChange('+1MONTH')}
			className={gap === '+1MONTH' ? classNames(classes.button, 'selected') : classNames(classes.button)}
		  >
			Monthly
		  </Button>
		</Tooltip>
		<Tooltip title="Show latest completed quarter" aria-label="Show latest completed quarter">
		  <Button
			onClick={() => handleDateTypeChange('+3MONTH')}
			className={gap === '+3MONTH' ? classNames(classes.button, 'selected') : classNames(classes.button)}
		  >
			Quarterly
		  </Button>
		</Tooltip>
		<Tooltip title="Show latest completed year" aria-label="Show latest completed year">
		  <Button
			onClick={() => handleDateTypeChange('+1YEAR')}
			className={gap === '+1YEAR' ? classNames(classes.button, 'selected') : classNames(classes.button)}
		  >
			Yearly
		  </Button>
		</Tooltip>
		<Tooltip title="Select custom date range" aria-label="Select custom date range">
		  <Button
			onClick={handleCustomDateClick}
			className={isCustomSelected ? classNames(classes.button, 'selected') : classNames(classes.button)}
		  >
			Custom
		  </Button>
		</Tooltip>
	  </ButtonGroup>
	  <Dialog open={customDateModal} onClose={handleClose}>
		<DialogTitle className={classes.dialogTitle}>Select Date Range</DialogTitle>
		
		
		<DialogContent className={classes.dialogContent}>
		  <Typography className={classes.presetTitle}>Select Start and End Date</Typography>
		  {error && <Typography className={classes.errorText}>{error}</Typography>}
		  <MuiPickersUtilsProvider utils={MomentUtils}>
			<div className={classes.dateContainer}>
			  <hr/>
			  <div>
				<DatePicker
				  autoOk
				  clearable
				  className={classes.datePicker}
				  label="Start Date"
				  value={startDate}
				  onChange={handleStartDateChange}
				  disableFuture
				  format="MM/DD/YYYY"
				/>
				<div style={{width:30,display:'inline-flex'}}>
				{startDate && (
				  <IconButton className={classes.closeButton} onClick={removeStartDate}>
					<CloseIcon />
				  </IconButton>
				)}
				</div>
			  </div>
			  <div>
				<DatePicker
				  autoOk
				  clearable
				  className={classes.datePicker}
				  label="End Date"
				  value={endDate}
				  onChange={handleEndDateChange}
				  disableFuture
				  format="MM/DD/YYYY"
				  style={{ marginLeft: 10 }}
				/>
				<div style={{width:30,display:'inline-flex'}}>
				{endDate && (
				  <IconButton className={classes.closeButton} onClick={removeEndDate}>
					<CloseIcon />
				  </IconButton>
				)}
				</div>
			  </div>
			</div>
			<Typography className={classes.presetTitle}>Or Choose From Below</Typography>
			<List className={classes.defaultOptions}>
			  {presetOptions.map((option) => (
				<ListItem
				  button
				  key={option.label}
				  onClick={() => handlePresetClick(option.value[0], option.value[1])}
				  className={classes.listItem}
				>
				  <ListItemText primary={option.label} className={classes.listItemText} />
				</ListItem>
			  ))}
			</List>
		  </MuiPickersUtilsProvider>
		  <hr/>
		</DialogContent>
		
		<DialogActions className={classes.dialogActions}>
		  <Button onClick={handleClose} variant="outlined"
		   color=""
		   className=""	
		   >
			Cancel
		  </Button>
		  <Button onClick={applyCustomDate} 
		  variant="contained"
		   color="primary"
		   className="buyersList__primary-button"	
		   startIcon={<CalendarRangeIcon />}	 
		   >
			Apply
		  </Button>
		</DialogActions>
	  </Dialog>
	</div>
  );
};

export default DateType;
