import React, { Fragment, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import {Card, CardBody, Row, Col, Modal, ButtonToolbar } from 'reactstrap';
import AddListIcon from 'mdi-react/ViewGridPlusIcon';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddCollectionButton from '../../../../shared/components/collections/AddCollectionButton';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import Chip from '@material-ui/core/Chip';

import CompReportShare from './../../../SharePage/reports/comp/index';
import ReportButton from './../../../SharePage/ReportButton';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import moment from 'moment';
import { 
	_capitalizeText,
	_getBuildingURL,
	_truncateWords,
  _getDefaultBuildingPhoto,
  _getBuildingPhotos,
  _getUnitFloorPlan
} from "../../../../shared/helpers/utils";

import Collapse from '../../../../shared/components/Collapse';

const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	justifyContent: 'left',
	flexWrap: 'wrap',
	marginTop:'10px',
	'& > *': {
	  marginRight: theme.spacing(0.5),
	},
  },
  small: {
	  display: 'flex',
	  justifyContent: 'left',
	  flexWrap: 'wrap',
	  marginTop:'5px',
	  
	  '& > *': {
		marginRight: theme.spacing(0.5),
		fontSize: '11px',
		height:'24px',
		marginBottom:'5px',
	  },
	},
}));

const TimeLineIcon = ({ type, date }) => {
  let Icon;

  switch (type) {
	case 'new-york-yimby':
	  Icon = <span className="">NYY</span>;
	  break;
	case 'the-new-york-post':
	  Icon = <div className="timeline__icon"><img src={`${process.env.PUBLIC_URL}/img/publisher/the-new-york-post.png`} alt="img" /></div>;
	  break;
	case 'file':
	  Icon = <span className="lnr lnr-file-add" />;
	  break;
	default:
	  Icon = <span className=""></span>
	  break;
  }

  return (
	<div className={`timeline__icon ${type}`}>
	  {Icon}
	</div>
  );
};

const _clickNews = (e, item, history) => {
	 e.stopPropagation();
	 window.open(item.external_url);
/*
	 const buildingURL = _getBuildingURL(item, 'building') + "/tab/news";
	 history.push(buildingURL);
*/
	
	
}

const GetGroupImage = ( {group,  unitRes, buildingRes} ) => {
  let bldg = false;
  let unit = false;
  if(buildingRes && group.group_data && group.group_data.buildings && group.group_data.buildings[0]){
	bldg = buildingRes.filter(b => group.group_data.buildings.indexOf(b.key)>=0).slice(0, 4);		  
	
  }else if(unitRes && group.group_data && group.group_data.units && group.group_data.units[0]){
	unit = unitRes.filter(u => group.group_data.units.indexOf(u.key)>=0 && (u.offering_floorplans || u.floorplans)).slice(0, 4)
  }
  //console.log(bldg, unit)
  if(bldg && bldg[0] && _getBuildingPhotos(bldg[0])){
	return <div className={`timeline__image collection_image imgs-${bldg.length}`} style={{ backgroundImage: `url(${_getBuildingPhotos(bldg[0])[0]})` }}>
				{bldg.map(b => {
					return <div className="sub__image" style={{ backgroundImage: `url(${_getBuildingPhotos(b)[0]})` }} />
				})}
				
		   </div>
  }else if(unit && unit[0] && _getUnitFloorPlan(unit[0])){
	return <div className={`timeline__image collection_image imgs-${unit.length}`} style={{backgroundImage: `url(${_getUnitFloorPlan(unit[0])[0].src})`}}>
				{unit.map(b => {
					return <div className="sub__image" style={{ backgroundImage: `url(${_getUnitFloorPlan(b)[0].src})` }} />
				})}
		</div>
  }else{
	return <AddListIcon />
  }
  
}

const _handleBuildingClick = (e, item, history) => {
	 e.stopPropagation();
	 const buildingURL = _getBuildingURL(item, 'building');
	 //history.push(buildingURL);
	  window.open(buildingURL);
};

const _showExcerpt = (item, words) => {
	const content = item.group_description.replace(/(<([^>]+)>)/ig," ").replace(/[^\w\s\,\'\’\-\.]/gi, " ");
	
	return (
		  <p>      
			  { _truncateWords(content, words ? words : 15).trim()}  
		  </p>
	)
}

TimeLineIcon.propTypes = {
  type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
  type: '',
};

const GroupItem = ({
  type, img, title, date, children, item, history, unitRes, buildingRes, toggleRefresh, snackOpen, snackClose, full
}) => {
	const [modal, toggleModal] = React.useState(false);
	const classes = useStyles();
	const toggle = () => {
		toggleModal(!modal);
	}
	
	const sharePageData = {
		"group_id": item.group_id,
		"building_key": false,
		"building": false,
		"property": false,
		"report_type": "grid",
		"custom_data": {
			"group": item
		}
	}
	
	return (
		<Fragment>
			<Modal
			  isOpen={modal}
			  toggle={toggle}
			  modalClassName={`ltr-support`}
			  className={`modal-dialog contact__modal community_collection`}
			>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
			  </div>
			  <Row>
									  
				<Col xl={12} lg={12} md={12}>
					<div className="modal__body background_white">
						<h4 className="contact__title mb-2">Community Collection: {item.group_name}</h4>
						<h4 className="subhead timeline__date mb-2">By: Marketproof {' | '} {_capitalizeText(moment(item.modified_timestamp).fromNow())}</h4>
						<div className="">
							<div>
								<span className="small-text light-text">									  
									  Buildings: {item.group_data && item.group_data.buildings && item.group_data.buildings.length} {' '}
								  </span>				          
								<span className="small-text light-text">{' | '}</span>
								<span className="small-text light-text">									  
									  Units: {item.group_data && item.group_data.units && item.group_data.units.length} {' '}
								</span>							  	
								  
							  </div>	  
							  
						  </div>
						 
							 {item.group_tags && item.group_tags.length>0 &&
								 <div className={`group-tags ${classes.root}`}>
									{item.group_tags.map(tag => <Chip label={tag} variant="outlined" />)}	
						 
								 </div>
								 
							 }
						<hr/>
						{item.group_description && item.group_description!== "" && 
						<div>
						<h4 className="bold-text mb-3">About This Collection</h4>
						<div className="desc">
							<span className="bold-text mb-2"> </span>
							
							<div
							   dangerouslySetInnerHTML={{
								 __html: item.group_description
							   }}>
							 </div>
							
						</div>
						</div>
						}
						<h4 className="bold-text mb-0">In This Collection</h4>
						<div className="preview_container">
					    	<CompReportShare sharePageData={sharePageData} preview  />
						</div>
						<br/><br/>
						<div style={{paddingBottom:10,marginBottom:20, textAlign: 'right', borderBottom:"1px solid #DBE0E8"}}>
							What can I do with collections?{' '}
							<a href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo" target="_blank" className="link-text">
								 Learn More <HelpCircleOutlineIcon size={12} style={{marginTop:"-3px"}}/> 
							</a>
						</div> 
						
						  <div className={`contact__form ${modal ? 'is-contact-open' : ''}`}>
							  <div className="headline">
								  <h3>Share This Collection</h3>
								  <h4>{item.group_name}</h4>
							  </div>
							  <div>
									<ButtonToolbar className="modal__footer dashboard">
										  
										   {/*<AddCollectionButton  group={item} toggleRefresh={toggleRefresh} snackOpen={snackOpen} snackClose={snackClose} copy />*/}
										   <ReportButton group={item} snackOpen={snackOpen} snackClose={snackClose} />
									  </ButtonToolbar>   
							</div>
							</div>
					</div>	
									
				</Col>
					  
			  </Row>
			</Modal>
  			<Card>
				
				<CardBody className="collection__item card__link" onClick={toggle}>
	 			<Row>
		  			<Col xs={12} sm={12} md={full ? 3 : 5} lg={full ? 3 : 55}>
			  			<GetGroupImage group={item} unitRes={unitRes} buildingRes={buildingRes} />
		  			</Col>
		 			
		  			<Col xs={ 12 } sm={ 12 } md={full ? 9 : 7 } lg={full ? 9 : 7 } className={isMobile ? "mt-2" : "pl-0"}>
							<h4 className="subhead timeline__date">By: Marketproof {' | '} {_capitalizeText(moment(item.modified_timestamp).fromNow())}</h4>
			  			<h4 className="title">{_truncateWords(item.group_name, 7)}</h4>
			  			<div className="">
							<div>
								<span className="small-text light-text">									  
						  			Buildings: {item.group_data && item.group_data.buildings && item.group_data.buildings.length} {' '}
					  			</span>				          
								<span className="small-text light-text">{' | '}</span>
								<span className="small-text light-text">									  
						  			Units: {item.group_data && item.group_data.units && item.group_data.units.length} {' '}
								</span>							  	
					  			
				  			</div>	
							{item.group_tags && item.group_tags.length>0 &&
								 <div className={`group-tags ${classes.small}`}>
								    {item.group_tags.slice(0,2).map(tag => <Chip label={tag} variant="outlined" />)}
									{item.group_tags.length>2 && <Chip label={`+${item.group_tags.length-2} More`} variant="outlined" /> }								 
								 </div>
								 
							 }  
				  			
			  			</div>
			  			<div className="timeline__location">
				  			<span ></span>
				 			
			  			</div>
			  			{(!(item.group_tags && item.group_tags.length>0) || full) && _showExcerpt(item, item.group_tags && item.group_tags.length>0 ? full ? 50 : 10 : full ? 50 : 15)}
			  			<div className="copy-collection">
							<ReportButton group={item} snackOpen={snackOpen} snackClose={snackClose} />
				  			<AddCollectionButton  group={item} toggleRefresh={toggleRefresh} snackOpen={snackOpen} snackClose={snackClose} copy />
				 			
			  			</div>
		  			</Col>
	  			</Row>
				</CardBody>
  			</Card>
		</Fragment>
	)
};

GroupItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.element.isRequired,
};

GroupItem.defaultProps = {
  type: '',
  img: '',
  title: '',
  date: '',
};

export default withRouter(GroupItem);
