import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FilterGallery from './components/FilterGallery';

const FloorPlans = ( props ) => (
  <Container>
    <Row>
      <FilterGallery {...props} />
    </Row>
  </Container>
);

export default FloorPlans;
