/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { CardBody, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';

import moment from 'moment';
import Slider from 'react-slick';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	StatusBadge,
	_getUnitFloorPlan
} from "../../../../../../shared/helpers/utils";


const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

class RelatedUnitItems extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      description: PropTypes.string,
      colors: PropTypes.arrayOf(PropTypes.string),
      new: PropTypes.bool,
    })),
  };

  static defaultProps = {
    items: [],
  };
  
  getUnitLink = (item) => {
	 return _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
  };

  render() {
    const { items, user } = this.props;
    const settings = {
		  dots: false,
		  infinite: false,
		  speed: 500,
		  autoplay: false,
		  swipeToSlide: true,
		  prevArrow: <ArrowLeftIcon size={30} fill="#000" />,
		  nextArrow: <ArrowRightIcon  size={30} fill="#000" />,
		  responsive: [
			{ breakpoint: 768, settings: { slidesToShow: 1 } },
		    { breakpoint: 992, settings: { slidesToShow: 2 } },
		    { breakpoint: 1200, settings: { slidesToShow: 3 } },
		    { breakpoint: 1536, settings: { slidesToShow: 4 } },
		    { breakpoint: 100000, settings: { slidesToShow: 4 } },
		  ],
	  };


    return (
      <div className="catalog-items_small catalog-items__wrap">
        <div className="catalog-items">
          <Slider {...settings} className="">
          {items.map((item, i) => (
            <CardBody className="catalog-item" key={i}>
              <Link className="catalog-item__link" to={`${this.getUnitLink(item)}`}>
                {/*item.new ? <img className="catalog-item__label" src={New} alt="new" /> : ''}
                {item.sale ? <img className="catalog-item__label" src={Sale} alt="sale" /> : ''*/}
               
                <div className="catalog-item__img-wrap">
                  {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
				  	{/*_getUnitFloorPlan(item) ?
	                	<img src={_getUnitFloorPlan(item)[0].src} / >
	                :
				  		<FloorPlanIcon size={200} color="#CCC" />
				    */}
				  	
                </div>
               {/*<Badge color="available">{moment(item.last_listed_sale_date_all*1000).fromNow()}</Badge>*/}
               <StatusBadge status={item.offering_status} />
                <div className="catalog-item__info">
                   <h4 className="catalog-item__price">
                   		${item.last_listed_sale_price_all ? _formatNumber(item.offering_price) : _formatNumber(item.offering_price)}
                   		
                   </h4>
                   
                   <h5 className="product-card__title">
		              	{item.offering_bedrooms > -1 ? item.offering_bedrooms === 0 ? 'Studio' : item.offering_bedrooms : '--'} 
		              	<span className="sub-text">{item.offering_bedrooms === 0 ? '' : item.offering_bedrooms === 1 ? 'Bed' : 'Beds'}</span>
		              	<span className="small-divider">|</span>
		              	{item.offering_baths}
		              	<span className="sub-text">{item.offering_baths === 1 ? 'Bath' : 'Baths'}</span>
		              	<span className="small-divider">|</span>
		              	{_formatNumber(item.offering_sq_ft)}
		              	<span className="sub-text">Sq Ft</span>
		          </h5>
                  <h5 className="catalog-item__title">
                  <span>
                  		{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address))}
                  </span>
                  <br/>
                  <span>
                  	{item.address_2_normalized.toUpperCase().replace("APT", "Apt")}
                  </span>
                  <br/>
                  <span className="light-text small-text">
                  	{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
                  </span>
                  </h5>
                  <p className="catalog-item__description"></p>
                 
                  
                  {/*item.colors.map((c, index) => (
                    <span
                      key={index}
                      className="catalog-item__color"
                      style={{ background: c }}
                    />
                  ))*/}
                 
                </div>
              </Link>
            </CardBody>
          ))}
           </Slider>
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  user: state.user,
}))(withRouter(RelatedUnitItems));
