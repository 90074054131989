import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import { Tooltip } from '@material-ui/core';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import IconButton from '@material-ui/core/IconButton';
import { _hasPermission	} from "../../shared/helpers/access";
import QuickStartGuide from '../../shared/components/QuickStartGuide';


class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user } = this.props;
	    if(!user) return null;

		return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Sales Velocity</h3>
		        <h3 className="page-subhead subhead">Analyze Pace of Sales in New Development Buildings.</h3>
		      </Col>
		    </Row>
		     {_hasPermission(user, 'sales-velocity') && user.subscriptionStatus !== 'trialing' ?
			 <Fragment>
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			   {/*<Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="salesVelocity" icon iconItem={<AlertIcon />} minimizeText={`About Sales Velocity`}>
				          <p>
				          	<span className="bold-text">{`About Sales Velocity`}</span> 
				            <div>{`Sales velocity is a measure of how quickly units are selling in a building, calculated using the number of contracts reported over a certain number of months.`}
				            	<br/>
				            	<a href="https://blocksandlots.com/new-sales-velocity-tool-shows-which-developments-are-on-top/" target="_blank">Learn more about the details and the applications of sales velocity &raquo;</a>
				            </div>
				          </p>
				        </Alert>
					</Col>
			    </Row>*/}
			   <QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_fd634bd292"} type={"Sales Velocity"} />

			    <Row>
			      <UnitsSearchTable />
			    </Row>
			 </Fragment>
			:
	    	  <Row>
			    <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-sales-velocity.png`}
			    	copy={(_hasPermission(user, 'sales-velocity') && user.subscriptionStatus ==='trialing') ? <span>Access to Sales Velocity Analysis requires you to activiate your trial subscription.</span> :  <span>Access to Sales Velocity Analysis requires an upgrade to your subscription.</span>}
		    		upgrade={!_hasPermission(user, 'sales-velocity')}
					user={user}
					nextPlan={"complete-199"}
					trialSubscribe={user.subscriptionStatus ==='trialing'}
			    />

			 </Row>

		    }
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



