import React, { Fragment, useState } from 'react';
import {
  Card, CardBody, Col, Row, Button,
  
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

import CheckIcon from 'mdi-react/CheckCircleOutlineIcon';
import CircleIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon';
import ChangePlan from '../../ChangePlan';
import OpenNew from 'mdi-react/OpenInNewIcon';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';


import{
	_capitalizeText,
	_formatNumber
} from '../../../../helpers/utils';

import { appFeatures, dataFeatures, costPerCredit } from './../../plans.js';

const FreeAccount = ({ plan, user, currentPlan }) => {
	const [ planOpen, setPlanOpen ] = useState(false);
	
	return null;
	return (
	 <div className={"container"}>
		<Row className={"mb-5 mt-3"}>
	  	  <Col md={2}/>
	      <Col md={8}>
	        <div className="free-account">
	        	<p>Only interested in browsing <strong>properties on the market</strong>? <br/> You can use our <strong>free listing website</strong> to find sales and rentals.</p>
	        	<a href="https://marketproof.com/listings" target="_blank" className="button hero-button">Listing Website <OpenNew size={14} style={{marginTop:-3}} /></a>
	        </div>			        
	        
	      </Col>
	      <Col md={2}/>
	    </Row>
	    <hr/>
    </div>  )
};

export default FreeAccount;
