import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Progress, Card, CardBody, Row, Col } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ComposedChart, Text, LabelList } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../shared/helpers';
import Panel from '../../../shared/components/Panel';
import StatIcon from 'mdi-react/MapSearchOutlineIcon';
import ToolTip from '../../../shared/components/ToolTip';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	setURLFromState
} from '../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../shared/helpers';



const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
      <text x={x + width / 2} y={y - radius} fill="#780F9E" textAnchor="middle" dominantBaseline="middle">
        {value === 0 ? 'N/A' : _formatNumber(value)}
      </text>
    </g>
  );
}

const colors = {
	shadow: "#858AA1",
	active: "#4ce1b6",
	contract: "#D6BAE8",
	sold: "#B070CC",
	recorded: "#FF206E",
	resale: "#28C2FF",
	unsold: "#780F9E"
}

const CustomizedAxisTick = ( {x, y, stroke, payload} ) => {
		
   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-65)">{_capitalizeText(payload.value)}</text>
      </g>
    );
}

const svgToPng = (svg, width, height) => {
    return new Promise((resolve, reject) => {

      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');

      // Set background to white
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, width, height);

      let xml = new XMLSerializer().serializeToString(svg);
      let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
      let img = new Image(width, height);

      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        let imageData = canvas.toDataURL('image/png', 1.0);

        resolve(imageData)
      }

      img.onerror = () => reject();

      img.src = dataUrl;
    });
};

class NeighborhoodAnalysis extends PureComponent {
    
  constructor() {
    super();
    
  }
  currentChart;
  
  _constructVelocityData = (facetData, months) => {
		
	const data = [];
	
	facetData.filter(item => item.building).slice(0, 20).map(item => {
		const building = item.building;
		const salesVelocity = Math.round((item.count / months) * 10 ) / 10;
 	  	const veloData = {
	 	  	name: building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address),
	 	  	boro: _capitalizeText(building.borough),
	 	  	count: item.count,
	 	  	Velocity : salesVelocity,
	 	  	building: building
	 	}
 	  	
 	  	data.push(veloData);	
 	  	
 	  	data.sort((a, b) => (a.count > b.count ? -1 : 1));  	
 	  	//console.log(JSON.stringify(data))
		
	})
	return data; 
    
  }
  
  handleClick(data, index) {
	 const buildingURL = _getBuildingURL(data.building, 'building_slugs');
	 window.open(buildingURL);

  }
  
  handleDownload = async () => {
	  
	return false;
    const chart = this.currentChart

    let chartSVG = ReactDOM.findDOMNode(chart).children[0]

    const pngData = await svgToPng(chartSVG, 1200, 1600)
    document.write('<img src="'+pngData+'"/>') // <------
  }
 

  
  render() {
      const { facetData, months } = this.props;    
      if(!facetData || !months){
	    return null;
      }
      
      const data = this._constructVelocityData( facetData, months );

	  return (
		  <Fragment>
		  	  <div className="material-table__header-info">
            	<Row>		            		
            		<Col md={12} lg={12}>
		            	<h5 className="bold-text mt-3 text-center">
		            		 Top {facetData.filter(item => item.building).slice(0, 20).length} Buildings Ranked by  {months}-Months Sales Velocity
		            	</h5>
		            	
					</Col>
            	</Row>
            </div>
		      <ResponsiveContainer  height={400} className="dashboard__area">
		        <BarChart ref={(chart) => this.currentChart = chart} data={data} margin={{ top: 20, left: 0, bottom: 20, right:40 }}>
			       
		           <XAxis dataKey="name" interval={0} tickLine={false} reversed={false} tick={<CustomizedAxisTick/>} height={150} />				
			       <YAxis  yAxisId="left" tickLine={true} orientation={'left'} tickFormatter={value => [new Intl.NumberFormat('en').format(value)].join('')} />
			       <Tooltip formatter={(value, name, props) => ( value + " Sales / Mo" )} labelFormatter={(value) => `${_capitalizeText(value)}`} cursor={{ stroke: '#780F9E', strokeWidth: 1 }}/>
=			       <CartesianGrid vertical={false}/>
			       <Bar dataKey={"Velocity"} yAxisId="left" stackId="a" barSize={35} fill={colors['unsold']} onClick={this.handleClick.bind(this)} cursor="pointer">
			       </Bar>
			    
			       
			       {/*<Bar dataKey={"Sold"} yAxisId="left" stackId="a" barSize={35} fill={colors['sold']} onClick={this.handleClick.bind(this)} cursor="pointer" >
			       	 
			       </Bar>*/}
			      </BarChart>
		      </ResponsiveContainer>
		 </Fragment>
			 
		);
	}
}

export default NeighborhoodAnalysis;
