import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import ProductCard from './components/ProfileCard';
import RelatedUnits from './components/RelatedUnits';
import UnitHistory from './components/UnitHistory';

import PercentFinanced from './components/PercentFinanced';
import TimesSold from './components/TimesSold';
import AverageDOM from './components/AverageDOM';

import Timeline from './../../components/Timeline';
import ShowMap from './../../../../shared/components/map';



const Profile = (props) => (
  <div>
    <Row>
      <ProductCard {...props} />
    </Row>
    
{/*
    <Row>
      <Col md={12}>
        <h3 className="section-title section-title--not-last">Offering History</h3>
        <h3 className="section-title section-title--not-last subhead"> </h3>
      </Col>
    </Row>
    <Row>
       <Col md={12}>
	   	<UnitHistory unitHistory={props.unitHistory} />
	   </Col>
    </Row>
*/}
	 {(props.transactions && props.transactions.filter(item => item.price > 0).length > 0) &&
	   <Row className="mt-3">
	   	  <TimesSold {...props} />
	   	  <PercentFinanced {...props} />
	   	  <AverageDOM {...props} />
	   	 {/* <AverageDiscount {...props} />
	   	  <AverageDOM {...props} />*/}
	   </Row>
	   }

	<Row>
      <Col md={12} >
        <h3 className="section-title section-title--not-last">Location</h3>
        <h3 className="section-title section-title--not-last subhead"> </h3>
      </Col>
    </Row>
    <Row className="mb-2">
    	<Col md={12}>
    		<Card>
    		 <CardBody>
    		<ShowMap item={props.buildingRes} />
    		 </CardBody>
    		</Card>
		</Col>
    </Row>
    
    <Row>
		<Timeline {...props} />
    </Row>
    <Row className="mt-3">
      <Col md={12} style={{ padding:"30px auto" }}>

        <h3 className="section-title section-title--not-last timeline__header-center">Similar Units</h3>
        <h3 className="section-title section-title--not-last subhead"> </h3>
        <hr/>
      </Col>
    </Row>
    <Row>
      <RelatedUnits {...props} />
    </Row>
  </div>
);

export default Profile;
