import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import MatTable from './components/MatTable';
import Alert from '../../shared/components/Alert';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import AlertIcon from 'mdi-react/InformationOutlineIcon';

const Buildings = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Research Buildings</h3>
        <h3 className="page-subhead subhead">Active New Development Projects</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} className={'mb-3'}>
       	<TrialAlert />        
      </Col>
    </Row>

    <Row>
    	<Col md={12} className={'mb-3'}>
			<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
	          <p>
	          	<span className="bold-text">{`About The Data`}</span> 
	            <div>{`The information provided herein is provisional and subject to change as 200+ more buildings are vetted and added.`}</div>
	          </p>
	        </Alert>
		</Col>
    </Row>
    <Row>
      <MatTable />
    </Row>
  </Container>
);

export default Buildings;

