import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import UnitTabContainer from './components/UnitTabContainer';
import { RTLProps } from '../../shared/prop-types/ReducerProps';

import TrialAlert from '../../shared/components/payment/TrialAlert';
import AlertIcon from 'mdi-react/AlertDecagramOutlineIcon';
import Alert from '../../shared/components/Alert';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_generateRandomAPI,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	buildingFL
} from '../../shared/helpers/utils';

import{
	_axiosCall,
	_makeBuildingAxiosData,
} from '../../shared/helpers/apicalls';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';

import { _hasPermission	} from "../../shared/helpers/access";
import LinearLoading from '../../shared/components/LinearLoading';


const apiURL = process.env.API_ROOT + '/query/';

const buildingUrl = process.env.API_ROOT + '/query/';
	
class SingleBuilding extends PureComponent {
	
	
	_boro = _urlToName(this.props.match.params.borough);
    _hood = _urlToName(this.props.match.params.neighborhood);

	state = {
      buildingRes: false,
      fetchInProgress: false,
	}
	
	componentDidMount() {
		this._isMounted = true; 
		if(!this.state.buildingRes) {
			this._getBuildingData();
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {		
		if(prevProps.match.params.building_slug !== this.props.match.params.building_slug ||
		   prevProps.match.params.unit !== this.props.match.params.unit){
			this._getBuildingData();
		}
		 
    }
	
	_getBuildingData = () => {
     // console.log('_getBuildingData called', this.state)
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  const buildingSlug = this.props.match.params.building_slug === '280-cadman-plz-w-11201' ? '1-clinton-st-11201' : this.props.match.params.building_slug;
	  
    
/*
	  let buildingData = {
			url: buildingUrl + "we3-buildings?cache=1440",
			method: "post",
			query: {
				q: 'key:*-'+buildingSlug,
				fq: 'deleted:false',
				fl: buildingFL,
				wt: "json",
				rows: 1
			}
	  };
*/
	  
	  let buildingData = {
			url: process.env.API_ROOT + `/buildings/${buildingSlug}?type=minimum`,
			method: "get"
	  };

	  
	  _axiosCall(buildingData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    //if(this._isMounted && res && res.docs && res.docs[0]) {
		if(this._isMounted && res && res.building && res.building[0]) {
	      this.setState({
	      	buildingRes: res.building[0],
	      	fetchInProgress: false
	      })
	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

	render(){
		
		
		const { buildingRes, fetchInProgress } = this.state;
		const { user } = this.props;
		if(!buildingRes || fetchInProgress){
			 return <LinearLoading />;
		}
		if(!_hasPermission(user, 'sponsor-buildings') && buildingRes.building_status == "sponsor"){
			return(
				<Row>
				  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-macbook-sponsor-buildings.png`}
					  copy={<span>Access to new development units requires an update to your subscription.</span>}
				  />
				</Row>
			)
		} 
		if(!_hasPermission(user, 'pipeline-buildings') && buildingRes.building_status == "pipeline"){
			return(
				<Row>
				  <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-macbook-pipeline-buildings.png`}
					  copy={<span>Access to pipeline units requires an update to your subscription.</span>}
				  />
				</Row>
			)
		} 
		return (
			 <Container className="dashboard">
			    <Row>
			      <Col md={12} className={'mb-3'}>
			       	<TrialAlert />        
			      </Col>
			    </Row>
			    {/*(buildingRes.offering_verified_status !== 'active' ) &&
			    <Row>
			    	<Col md={12} className={'mb-3'}>
						<Alert color="success" className="alert--bordered"  alertId="vettingProgress" icon iconItem={<AlertIcon />} minimizeText={`Vetting in Progress`}>
				          <p>
				          	<span className="bold-text">{`Vetting in Progress`}</span> 
				            <div>{`Marketproof New Development is currently processing this building and verifying information.  More details will be added to this building shortly.`}</div>
				          </p>
				        </Alert>
					</Col>
			    </Row>
			    */}
			    <Row>
			      <UnitTabContainer buildingRes={buildingRes} />
			    </Row>
			</Container>
		)
		
	}
  };

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(SingleBuilding)));
