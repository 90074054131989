import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Badge, Progress } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../shared/components/Panel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import { Tooltip } from '@material-ui/core';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import moment from "moment";

import UnitListTableHead from './UnitListTableHead';
import ListingAgentInfo from '../../../../../shared/components/ListingAgentInfo';

import { getDefaultChartColors } from '../../../../../shared/helpers';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL
} from "../../../../../shared/helpers/utils";

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}


	
class UnitList extends PureComponent {
  static propTypes = {
   	t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
	this.state = {
		    order: 'asc',
		    orderBy: 'inventory_unsold',
		    selected: new Map([]),
		    page: 0,
		    rowsPerPage: 999,
			clearInput: false,
			end: 999,
			start: 0,
			stats: {},
			subFilters: {},
		};

  }
  
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };
  
  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  
  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
   handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building, 'building') + '/' + _nameToUrl(building.address_2_normalized);
	 this.props.history.push(buildingURL);
  };



  
  
  render() {
	const { t, unitsRes, title } = this.props;
	const {
     	order, orderBy, selected, rowsPerPage, page, 
    } = this.state;
    if(!unitsRes){
	    return null;
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, unitsRes.length - (page * rowsPerPage));
    var today = moment().startOf('day');

	return (
		  <Panel lg={12} xl={12} md={12} title={title ? title : `Unit List`}>
		  	<div className="material-table__wrap">
              <Table className="material-table">
              	<UnitListTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={unitsRes.length}
                />
                {!unitsRes || unitsRes.length<=0 ?
                
                    
                   <TableBody>
                           <tr>
                             <td colSpan={10} className="text-center">
                              
                               <div className="bold-text mt-3">No Listings {title}</div>
                             </td>
                           </tr>
                   </TableBody>
                 :
                <TableBody>
                  {unitsRes
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map((d) => {
                      const isSelected = this.isSelected(d.id);
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleBuildingClick(event, d)}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={isSelected}
                        >
                         <TableCell
                           className="material-table__cell material-table__cell-right"
                           component="th"
                           scope="row"
                           padding=""
                         >
                           {d.sale_status == "active" ? 
                            <Badge color="available" style={{width:100}}>
                              <span className="bold-text">On the Market</span>
                            </Badge>
                            : d.sale_status == "coming soon" ? 
                            <Badge color="sold-transfer" style={{width:100}}>
                              <span className="bold-text">Coming Soon</span>
                            </Badge>
                            :
                            <Badge color="contract" style={{width:100}}>
                              <span className="bold-text">In Contract</span>
                            </Badge>
                           }
                         </TableCell>
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding=""
                          >
                           	<span>{d.address_2_normalized.toUpperCase().replace("APT", "Apt") || "--"}</span>
                          </TableCell>
                         <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding=""
                          >
                            {d.price ? `$${_formatNumber(d.price)}` : "--"}
                            {d.listing_type=="sale" ? 
                            <span className="light-text small-text"><br/>{d.sq_ft > 1 ? `$${_formatNumber(d.price/d.sq_ft)} psf` : '--'}</span>
                            :
                            <span className="light-text small-text"><br/>{d.sq_ft > 1 ? `$${_formatNumber((d.price/d.sq_ft) * 12)} psf/yr` : '--'}</span>
                            }
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                            <span className="light-text small-text">{d.baths > 0 ? d.baths+' Bath' : '--'}</span>
                          </TableCell>
                          
						  <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{d.sq_ft > 0 ? <React.Fragment>{_formatNumber(d.sq_ft)} sf</React.Fragment>  : `--`}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{d.active_date_start && Math.ceil(moment.duration(today - moment(d.active_date_start)).asDays()) > 0 ? <React.Fragment>{_formatNumber(Math.ceil(moment.duration(today - moment(d.active_date_start)).asDays()))} days</React.Fragment>  : `--`}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small" 
                            align="center"
                          >
                          <span  className="small-text">{d.contacts && d.contacts.map((c, i) => {
                            
                            return (<Fragment><ListingAgentInfo agent={c} />{i<(d.contacts.length-1) && ', '}</Fragment>);
                          
                          })}<br/></span>
                          <span className="light-text small-text">{d.broker_display_name ? <React.Fragment>{_capitalizeText(d.broker_display_name)}</React.Fragment>  : `--`}</span>
                          <span className="light-text small-text"><br/>{d.buyer_agent_commission > 0 ? `Co-Broke Commssion: ${d.buyer_agent_commission}%` : ''}</span>
                          </TableCell>
                         
                         <TableCell
                           className="material-table__cell material-table__cell-right"
                           align="center"
                         >
                         
                          <span className="light-text small-text">
                             <a href={`javascript:void(0)`} className="alert_show"
                             onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`https://nyc.marketproof.com${_getBuildingURL(d, 'building').replace('buildings/', 'building/')}/${_nameToUrl(d.address_2_normalized)}?tab=listing`);}}>
                               See Listing <OpenInNewIcon size={12} />
                             </a><br/>
                           </span>
                                            
                         </TableCell>
                          
                        </TableRow>
                      );
                    })}
                 
                </TableBody>
              }
              </Table>
            </div>
            

		   
		  </Panel>
	  )
	  
  }
}

export default withTranslation('common')(withRouter(UnitList));
