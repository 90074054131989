import React, { PureComponent, useState, Fragment, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import TotalFloors from './components/TotalFloors';
import TotalUnits from './components/TotalUnits';
import AveragePPSF from './components/AveragePPSF';
import PriceRange from './components/PriceRange';
import TotalUnsold from './components/TotalUnsold';

import AveragePPSFSold from './components/AveragePPSFSold';
import TotalSqFt from './components/TotalSqFt';
import AvgUnitSqFt from './components/AvgUnitSqFt';
import PriceRangeSold from './components/PriceRangeSold';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import CurrentProgress from './components/CurrentProgress';

import SalesVelocity from './components/SalesVelocity';
import AbsorptionRate from './components/AbsorptionRate';
import PercentShadow from './components/PercentShadow';

import PercentFinanced from './components/PercentFinanced';
import AverageDiscount from './components/AverageDiscount';
import AverageDOM from './components/AverageDOM';

import PercentSold from './components/PercentSold';
import PercentSqFtSold from './components/PercentSqFtSold';
import PercentDollarSold from './components/PercentDollarSold';

import UnitMix from './components/UnitMix';
import UnitBreakdown from './components/UnitBreakdown';

import ListingTrends from './components/ListingTrends';
import ListingTrendsPrice from './components/ListingTrendsPrice';

import StackedUnits from './../../../Reports/StackedUnits/components/StackedUnits';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';
import LinearLoading from '../../../../shared/components/LinearLoading';
import SoldTrendsPPSFAllBeds from './components/SoldTrendsPPSFAllBeds';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL,
	_isCoop
} from '../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../shared/helpers/apicalls';
const apiURL = process.env.API_ROOT + '/query/';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft:"-8px",
    alignItems: 'left',
    '& > *': {
      margin: theme.spacing(1),      
    },
    
  },
  button:{
	'&.selected' :{
	    background:"#D1D6E0"
    }
  }
}));

const Overview = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'summary');
	
	const [period, setPeriod] = useState("all-time");
	const [soldStats, setSoldStats] = useState(null);
    const { unitsRes, buildingRes, statsRes, user, setResalePeriod, resaleTimePeriod, transFetchInProgress, propFetchInProgress } = props;  
    const _boro = buildingRes.borough;
    const _hood = buildingRes.neighborhood[0];
    const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
    const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
    const summaryTitle = 'Building Analysis - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
    const _toggleTab = (tab) => {
	    if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/analysis/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
	      setActiveTab(tab)
	      Publisher.publish(`track.buildingAnalysisView.${tab}`, {building: buildingRes.key});
	    }
	  };
	const salesURL = _getBuildingURL(buildingRes, 'building_slugs') + `/tab/sales`;
	const classes = useStyles();
	let isCoop = _isCoop(buildingRes);
	const getSalesTrend = async () => { 
			const addressQ = buildingRes.alternate_addresses.join('" OR "');					
			const q = `alternate_addresses:("${addressQ}") AND -address_2_normalized:"" (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}")`;
			const fq = [
				"status:sold",
				"transaction_source:ACRIS*",
				"price:[50000 TO *]",
				"parcels_condocoop_count:1"
			]
			let axiosData = {
				url: apiURL + "we3-transactions?cache=3600",
				method: "post",
				query: {
					q: q,
					sort: "close_date_formatted desc",
					wt: "json",
					fq: fq,
					rows: 0,
					"json.facet": {
					
						building_ppsf: {
					  		type : "query",
					  		q : `alternate_addresses:("${addressQ}") AND -address_2_normalized:"" (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}") AND price_per_sqft:[300 TO *]`,
					  		facet : {
						  		trends : {
							  		range: {
								  		field: "close_date_formatted",
								  		start: buildingRes.sales_start_date,
								  		end: "NOW",
								  		gap: "+1YEAR",
								  		facet: {
											by_stage: {
									 		type: "terms",
									 		field: "sponsored_sale",
									  		limit: 5,
									  		facet: {
												avg_ppsf: "avg(price_per_sqft)"
									  		}
											},
											avg_ppsf: "avg(price_per_sqft)"
								  		}
									}  
								}
					  		}
						},
				  }
					
				}
			};
			let formattedData = await _axiosCall(axiosData)
			.then(res => {
				setSoldStats(res);
			});
	}
	useEffect(() => {
	  getSalesTrend();
	  //getListingTrend();
	}, []);
    const SummarySponsor = () => {
	    return (
		    <div>
			   <Row>
			    <TotalUnits {...props} />
			    <TotalFloors {...props} /> 
			    <AvgUnitSqFt {...props} />   
			    <TotalSqFt {...props} /> 				                
			   </Row>
			   <Row>
			   	<AveragePPSF {...props} />
			   	<AveragePPSFSold {...props} />
			    <PriceRange {...props} />	
			   </Row>
			    {props.buildingRes && props.buildingRes.building_status === 'sponsor' &&
			   <Row>
			   	  <CurrentProgress {...props} />
			   </Row>
			   }
			   {!(props.buildingRes && props.buildingRes.offering_construction === 'conversion') &&  (props.buildingRes && props.buildingRes.building_status === 'sponsor') &&
			    <Card>
				   <CardBody className="percent-sold">
					   	   <Row>
					   		<Col md={12} className={"mb-3"}>
					   			<h5 className="dashboard__booking-total-description">
						            Analyze Percent Sold
						        </h5>
							</Col>
						   </Row>
						   <Row>	 				   	  
						   	  <PercentSold {...props} col={4} />
						   	  <PercentSqFtSold {...props}  col={4}/> 	   			  			    
						   	  <PercentDollarSold {...props}  col={4}/>
						   </Row>
					</CardBody>
				</Card>
			   }
			
			  
			   
			   {!(props.buildingRes && props.buildingRes.offering_construction === 'conversion') &&  (props.buildingRes && props.buildingRes.building_status === 'sponsor') &&
				   <Card>
				   <CardBody>
					   <Row>
					   		<Col md={12} className={"mb-2"}>
					   			<h5 className="dashboard__booking-total-description">
						            Analyze Sales By Time Period
						        </h5>
					   			<div className={classes.root}>
					   			  	
							      <ButtonGroup size="small" aria-label="small button group">							        
							      	<Tooltip title="Sales velocity, absorption rate and units listed based on the last 3 months of sales." aria-label="See sales velocity and absorption rate based on the last 3 months of sales.">
							        	<Button onClick={()=> setPeriod("3-mo")} className={period === "3-mo" && classNames(classes.button, "selected")}>3 Months</Button>
							        </Tooltip>
							        <Tooltip title="Sales velocity, absorption rate and units listed based on the last 6 months of sales." aria-label="See sales velocity and absorption rate based on the last 6 months of sales.">
							        	<Button onClick={()=> setPeriod("6-mo")} className={period === "6-mo" && classNames(classes.button, "selected")}>6 Months</Button>
							        </Tooltip>
							        <Tooltip title="Sales velocity, absorption rate and units listed based on the last 1 year of sales." aria-label="See sales velocity and absorption rate based on the last 1 year of sales.">
							       	 	<Button onClick={()=> setPeriod("1-yr")} className={period === "1-yr" && classNames(classes.button, "selected")}>12 Months</Button>
							       	</Tooltip>	
							       	<Tooltip title="Sales velocity, absorption rate and units listed since sales launched." aria-label="See sales velocity and absorption rate based on sales since launch.">
							        	<Button onClick={()=> setPeriod("all-time")} className={period === "all-time" && classNames(classes.button, "selected")}>All Time</Button>
							        </Tooltip>
							      </ButtonGroup>
							  </div>
					   		</Col>
					   </Row>
					   <Row>					   	  
					   	  <SalesVelocity {...props} period={period} />
					   	  <AbsorptionRate {...props} period={period} />
					   	  <PercentShadow {...props} period={period} />
					   </Row>
					  
					 </CardBody>
					</Card>
			   }
			   {(props.buildingRes && props.buildingRes.building_status === 'resale') &&
			   <Row>
			   	  <PercentFinanced {...props} />
			   	  <AverageDiscount {...props} />
			   	  <AverageDOM {...props} />
			   </Row>
			   }
			  
			   {props.statsRes.count>0 &&
			   <Row>
			   		<UnitMix {...props} />
			   		<UnitBreakdown {...props} />
			   </Row>
			   }
			</div>		    
	    )
    } 
	
	const SummaryResale = () => {
		return (
			<div>
			   <Row>
				<TotalUnits {...props} />
				<TotalFloors {...props} /> 
				<AvgUnitSqFt {...props} />   
				<TotalSqFt {...props} /> 				                
			   </Row>
			   <Card>
				  <CardBody style={{minHeight:360}}>
					  <Row>
							  <Col md={12} className={"mb-2"}>
								  <h5 className="dashboard__booking-total-description">
								   Analyze By Time Period
							   </h5>
								  <div className={classes.root}>
										
								 <ButtonGroup size="small" aria-label="small button group">							        
									 <Tooltip title="Sales velocity, absorption rate and units listed based on the last 3 months of sales." aria-label="See sales velocity and absorption rate based on the last 3 months of sales.">
									   <Button onClick={()=> setResalePeriod(1)} className={resaleTimePeriod === 1 && classNames(classes.button, "selected")}>1 Year</Button>
								   </Tooltip>
								   <Tooltip title="Sales velocity, absorption rate and units listed based on the last 6 months of sales." aria-label="See sales velocity and absorption rate based on the last 6 months of sales.">
									   <Button onClick={()=> setResalePeriod(2)} className={resaleTimePeriod === 2 && classNames(classes.button, "selected")}>2 Years</Button>
								   </Tooltip>
								   <Tooltip title="Sales velocity, absorption rate and units listed based on the last 1 year of sales." aria-label="See sales velocity and absorption rate based on the last 1 year of sales.">
										   <Button onClick={()=> setResalePeriod(5)} className={resaleTimePeriod === 5 && classNames(classes.button, "selected")}>5 Years</Button>
									  </Tooltip>	
									  <Tooltip title="Sales velocity, absorption rate and units listed since sales launched." aria-label="See sales velocity and absorption rate based on sales since launch.">
									   <Button onClick={()=> setResalePeriod(999)} className={resaleTimePeriod === 999 && classNames(classes.button, "selected")}>All Time</Button>
								   </Tooltip>
								 </ButtonGroup>
							 </div>
							  </Col>
					  </Row>
					  {(transFetchInProgress || propFetchInProgress) ?
						  <LinearLoading />
						:
						<Fragment>
			   			<Row>
				   			<AveragePPSF {...props} />
				   			<AveragePPSFSold {...props} />
							<PriceRangeSold {...props} />	
			   			</Row>
			   			<Row>
				 			<PercentFinanced {...props} />
				 			<AverageDiscount {...props} />
				 			<AverageDOM {...props} />
			   			</Row>
						<Row>
							  <UnitMix {...props} statsRes={props.transactionRes} isSales />
							  <UnitBreakdown {...props} statsRes={props.transactionRes} isSales />
						</Row>
						</Fragment>
					  }
			   		</CardBody>
				</Card>
			   {soldStats &&
					 <Row>
						 <SoldTrendsPPSFAllBeds buildingRes={props.buildingRes} statsRes={props.statsRes} soldStats={soldStats} />
					 </Row>
				  }
			   {props.statsRes.count>0 && !isCoop &&
			   <Row>
					   <UnitMix {...props} />
					   <UnitBreakdown {...props} />
			   </Row>
			   }
			</div>		    
		)
	} 
	
	return(
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
	
			<div>
				<ExportComponentButton {...props} title={summaryTitle} component={(props.buildingRes && props.buildingRes.building_status === 'resale') ? <SummaryResale /> : <SummarySponsor {...props} activeTab={activeTab} />} />
				{(props.buildingRes && props.buildingRes.building_status === 'resale') ?
					<SummaryResale />
				:
					<SummarySponsor />
				}
			</div>
			
			  
		</Container>	  
	    </div>
	    
	)
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Overview));