import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import ShowMap from "../../shared/components/map";
import MapModal from "../../shared/components/map/MapModal";
import { _axiosCall } from "../../shared/helpers/apicalls";
import BuildingContainer from "./components/BuildingContainer";

import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _getBuildingURLBase,
} from "../../shared/helpers/utils";

const buildingUrl = process.env.API_ROOT + "/query/";

class BuildingDetail extends PureComponent {
  state = {
    buildingRes: null,
    fetchInProgress: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this._getBuildingData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  _getBuildingData = () => {
    const { trxnRes, isShare } = this.props;
    console.log("_getBuildingData called", this.state);
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({ fetchInProgress: true }));
    }

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }
    var fq;
    if (trxnRes && trxnRes.key && !trxnRes.alternate_addresses) {
      fq = ["key:(" + trxnRes.key + ")", "deleted:false"];
    } else if (
      trxnRes &&
      trxnRes.alternate_addresses &&
      Array.isArray(trxnRes.alternate_addresses)
    ) {
      if (trxnRes.zip) {
        fq = [
          `alternate_addresses:("${trxnRes.alternate_addresses.join(
            '" OR "'
          )}") AND zip:${trxnRes.zip}`,
          "deleted:false",
        ];
      } else if (trxnRes.city && trxnRes.building && trxnRes.building.length) {
        fq = [
          `(alternate_addresses:("${trxnRes.alternate_addresses.join(
            '" OR "'
          )}") AND city:${trxnRes.city}) OR key:${trxnRes.building[0]}`,
          "deleted:false",
        ];
      } else if (trxnRes.borough) {
        fq = [
          `alternate_addresses:("${trxnRes.alternate_addresses.join(
            '" OR "'
          )}") AND borough:${trxnRes.borough}`,
          "deleted:false",
        ];
      }
    } else if (trxnRes && trxnRes.building_key && trxnRes.key) {
      fq = [
        "key:(" + trxnRes.building_key + ")",
        "-key:" + trxnRes.key,
        "deleted:false",
      ];
    } else if (trxnRes && trxnRes.display_full_street_address) {
      if (trxnRes.zip) {
        fq = [
          `display_full_street_address:("${trxnRes.display_full_street_address}") AND zip:${trxnRes.zip}`,
          "deleted:false",
        ];
      } else if (trxnRes.city) {
        fq = [
          `display_full_street_address:("${trxnRes.display_full_street_address}") AND city:${trxnRes.city}`,
          "deleted:false",
        ];
      } else if (trxnRes.borough) {
        fq = [
          `display_full_street_address:("${trxnRes.display_full_street_address}") AND borough:${trxnRes.borough}`,
          "deleted:false",
        ];
      }
    }

    let axiosData = {
      url: buildingUrl + "we3-buildings?cache=15",
      method: "post",
      query: {
        q: "*:*",
        fq: fq,
        sort: "inventory_unsold desc",
        wt: "json",
        rows: 1,
      },
    };

    _axiosCall(axiosData)
      .then((res) => {
        console.log("Chart Data---------", this.props);
        if (res && res.docs && res.docs[0]) {
          this.setState({ buildingRes: res.docs[0], fetchInProgress: false });
          if (!isShare) {
            let newUrl = _getBuildingURLBase(res.docs[0], "building_slugs");
            if (
              this.props &&
              this.props.mainTab &&
              this.props.mainTab.key === "construction"
            ) {
              window.history.pushState("", "", [
                `/building/${newUrl}?construction=${trxnRes.id}`,
              ]);
            } else if (
              this.props &&
              this.props.mainTab &&
              this.props.mainTab.key === "transfer"
            ) {
              window.history.pushState("", "", [
                `/building/${newUrl}?transfer=${trxnRes.id}`,
              ]);
            } else {
              window.history.pushState("", "", [`/building/${newUrl}`]);
            }
          }
        } else {
          this.setState({ buildingRes: trxnRes, fetchInProgress: false });
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        this.setState({ buildingRes: trxnRes, fetchInProgress: false });
      });
  };

  render() {
    const { buildingRes, fetchInProgress } = this.state;

    if (buildingRes === null) return null;

    return (
      <Container className="dashboard">
        <Row>
          <BuildingContainer {...this.props} buildingRes={buildingRes} />
        </Row>
      </Container>
    );
  }
}

export default connect((state) => ({
  user: state.user,
}))(BuildingDetail);
