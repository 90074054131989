/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {  Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NewOrderTableProps } from '../../../../../shared/prop-types/TablesProps';
import moment from "moment"; 
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import ArrowEqualIcon from 'mdi-react/MinusCircleOutlineIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import SupplyIcon from 'mdi-react/CurrencyUsdIcon';

import TablePagination from '@material-ui/core/TablePagination';

import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AlertIcon from 'mdi-react/InformationOutlineIcon';

import LinearLoading from '../../../../../shared/components/LinearLoading';

import EnterpriseGate from './../../../../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "./../../../../../shared/helpers/access";
import UnitsSearchAppBar from './documents/UnitsSearchAppBar';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_formatBath,
	_formatOwnerName,
	_getBuildingURL,
	_nameToUrl,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	_objectEqual
} from '../../../../../shared/helpers/utils';
import { 
	_salesFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../../../shared/helpers/filters";
import { _axiosCall } from '../../../../../shared/helpers/apicalls';

import Panel from '../../../../../shared/components/Panel';

const apiURL = process.env.API_ROOT + '/query/';

let lsPre = "FD1_";
const subFilterKey = 'subFilters';
const defaultSubFilter = { };
const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}><DropdownItem>Messsage</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);


DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const Trend = ({ last, current }) => {
	
	if(last.total_price > current.total_price){		
		return (
			<ArrowDownIcon size={18} color="#f03434" />
		);		
	}else if(last.total_price < current.total_price){
		return (
			<ArrowUpIcon size={18} color="#4ce1b6" />
		);
	}
	
	return (
		<ArrowEqualIcon size={18} color="#999" />
	);
    
};


const processHistory = (offeringPlan) => {
	
	let lastPrice = offeringPlan.statCard_ppOnAccep ? parseFloat(offeringPlan.statCard_ppOnAccep.replace(/,/g,'').replace('$', '')).toFixed(0) : offeringPlan.price_current;	
	
	const newTotalPriceRegEx = /new\s+total\s+offering\s+price\s+\$[0-9\,]+/;
	const newTotalPriceReplaceRegEx = /new\s+total\s+offering\s+price\s+\$/;
	
	let amendments = [
		{
			number: 0,
			total_price : lastPrice,
			submitted_date : moment(offeringPlan.submitted_date).format('MM/DD/YYYY'),
			contents : offeringPlan.file_number.toUpperCase()
		}
	];
	
	offeringPlan.amendments.map(item => {
		const newPriceMatch = item.contents.match(newTotalPriceRegEx);
		let newPrice = lastPrice;
		if(newPriceMatch && newPriceMatch[0]){
			newPrice = parseInt(newPriceMatch[0].replace(newTotalPriceReplaceRegEx, '').replace(/\,/g, ''));
			lastPrice = newPrice;
		}
		let amendment = {
			number: item.amendmentnum,
			total_price : newPrice,
			submitted_date : item.submitteddate,
			contents : item.contents
		}
		amendments.push(amendment);	
	});
	
	return amendments;
}

class LendingHistory extends PureComponent {
	
	constructor(props) {
	    super(props);
	    this.state = {	     
	      lendingData: null,
	      order:  "desc",
		  orderBy: "recorded_datetime",
		  totalCount : 0,
		  page: 0,
		  rowsPerPage: 50,
		  start: 0,
		  bblQuery: null,
		  fetchInProgress:false,
		  subFilters: syncURLFilters(this.props, this._documentFilters, false, false, false),
		  searchText: syncURLSearch(this.props, 'searchText'),
		  filterReady:false
	    };
	  }
	  q = '';
	  _documentFilters = [	
	  	{
		  	name: "Document Date",
		  	show_name: true,
		  	search_key: ["min_date", "max_date"],
		  	filter_key : "document_date",
		  	date_filter: true,
		  	search_value: ['document_date:[{min_date} TO {max_date}]']		
	  	},
	  	{
		  	name: "Recorded Date",
		  	show_name: true,
		  	search_key: ["min_recorded_date", "max_recorded_date"],
		  	filter_key : "recorded_date",
		  	date_filter: true,
		  	search_value: ['recorded_datetime:[{min_recorded_date} TO {max_recorded_date}]']		
	  	},
	  	{
		  	name: "Amount",
		  	show_name: true,
		  	search_key: ["min_amt", "max_amt"],
		  	price_filter: true,
		  	search_value: ['document_amt:[{min_amt} TO {max_amt}]']		
	  	},	
	  ]	  
	  componentDidMount() {
		    this._getFinanceData();
			
		  	//this._getACRISData();
	  }
	  componentDidUpdate(prevProps, prevState, snapshot) {
		const { bblQuery } = this.state;
		let subFilterChanged =
		this._documentFilters.filter(item => {
			return (
				Array.isArray(item.search_key) ? 
					item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
				:
					this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
			);
		}).length > 0;
		if(this.state.page !== prevState.page ||
			this.state.rowsPerPage !== prevState.rowsPerPage ||
			this.state.searchText !== prevState.searchText ||
			subFilterChanged){
				this._getACRISData(bblQuery);		
			}  
	  }
	  handleRequestSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy });
	  };
	  handleChangePage = (event, page) => {
		  this.setState({ page: page, start: page * this.state.rowsPerPage });
		};
	  
		handleChangeRowsPerPage = (event) => {
		  this.setState({ rowsPerPage: parseInt(event.target.value) });
		};
	   _getFinanceData = async () => {
		    const { buildingRes } = this.props;
		    const addressQ = buildingRes.alternate_addresses.join('" OR "');
		    const q = `(alternate_addresses:("${addressQ}") AND (zip:${this.props.buildingRes.zip} OR borough:"${this.props.buildingRes.borough}")) AND address_2:""`;	
		    let axiosData = {
				url: apiURL + "dob-assessments",
				method: "post",
				query: {
					q: q,
					fq: [`assessment_year:[${buildingRes.year_converted > buildingRes.year_built ? (buildingRes.year_converted - 2) : (buildingRes.year_built - 2)} TO *]`],
					fl: "borough_code,block,lot",
					sort: "assessment_year desc",
					wt: "json",
					rows: 99,
										
				}
			};
	
			let formattedData = await _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					let bblQuery = `(block:${buildingRes.block} AND lot:${buildingRes.lot} AND borough_code:${buildingRes.borough_code})`;
					if(res.docs && res.docs.length>0){
						
						const lots = res.docs.map(item => item.lot).filter((value, index, self) => self.indexOf(value) === index);
						
						bblQuery = `(block:${buildingRes.block} AND lot:(${lots.join(" OR ")}) AND borough_code:${buildingRes.borough_code})`;						
					}
					
					this._getDocumentTypes(bblQuery);
					this._getACRISData(bblQuery);				
				})
				.catch(error => {
					console.log("error: " + error);
				});	
			// console.log(formattedData)
			return formattedData;
		    
		    
	  }
	  
	  _getDocumentTypes = async (bblQuery) => {
		  const { buildingRes, acris } = this.props;
		  const addressQ = buildingRes.alternate_addresses.join('" OR "');	
		  const q = `((alternate_addresses:("${addressQ}") AND (zip:${this.props.buildingRes.zip} OR borough:"${this.props.buildingRes.borough.toLowerCase()}")) OR ${bblQuery})`;	
		  const fq = [			 
			  `recorded_datetime:[${buildingRes.year_converted > buildingRes.year_built ? (buildingRes.year_converted - 15) : (buildingRes.year_built - 15)}-01-01T00:00:00Z TO *]`,			
		  ]
		  let axiosData = {
			  url: apiURL + "we3-acris-real-property",
			  method: "post",
			  query: {
				  q: q,
				  fq: fq,
				  wt: "json",
				  rows: 0,
				  "json.facet": {
					  "doc_types": {
						  "type" : "terms",
						  "field" : "doc_type_desc",
						  "mincount" : 1,
						  "limit" : 999
					  }		
				  }
		  
				  
			  }
		  };
		  
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  const values = [];
				  if(res && res.facets && res.facets.doc_types){
					  res.facets.doc_types.buckets.map(type => {
						  const display = _capitalizeText(type.val).replace(/Ucc/g, "UCC").replace("Rptt", "RPTT").replace("Rett", "RETT");
						  values.push({
								  name: display,
								  filter: _nameToUrl(type.val),
								  dropdown_name: `${display} (${_formatNumber(type.count)})`,
								  search_value: `("${type.val}")`,
							  })
					  })
					  this._documentFilters.unshift({
							  name: "Type",
							  show_name: true,
							  search_key: "doc_type_desc",
							  multi_filter:true,
							  values: values
						  })
					  
				  }
				  this.setState({filterReady:true})
			})
	  }
	  
	  _getACRISData = async (bblQuery) => {
		    const { buildingRes, acris } = this.props;
			const { start, rowsPerPage} = this.state;
			this.setState({fetchInProgress:true});
		    
		    const fl = [
			    "recorded_datetime", "document_amt", "document_id", "party*", "document_date", "doc_type*", "id", "source_providers", "address_2_normalized", "property_type"
		    ]
		  
		    const fq = [
			    //"doc_type:(mtge agmt asst sat cdec adec al&r deed)",
			    //"document_amt:[1 TO *]",
			    //`class_code_description:"mortgages & instruments"`,
			    `recorded_datetime:[${buildingRes.year_converted > buildingRes.year_built ? (buildingRes.year_converted - 15) : (buildingRes.year_built - 15)}-01-01T00:00:00Z TO *]`,
			    //"-property_type:(rg rv rp pa)",
				//"address_2_normalized:\"\""
		    ]
			
			if(!acris){
				fq.push("doc_type:(mtge agmt asst sat cdec adec al&r deed)");
			}
			
			
			
			const addressQ = buildingRes.alternate_addresses.join('" OR "');						
			this.q = `((alternate_addresses:("${addressQ}") AND (zip:${this.props.buildingRes.zip} OR borough:"${this.props.buildingRes.borough.toLowerCase()}")) OR ${bblQuery})`;	
			
			if(this.state.searchText && this.state.searchText.length > 0){
				const text = this.state.searchText.replace(/ /g, '*').toLowerCase();
				const textCaps = _capitalizeText(this.state.searchText.toLowerCase(), false).replace(/ /g, '*');
				this.q += this.q.length > 0 ? " AND " : "";
				this.q += `(address_2_normalized:apt*${text}* OR (-address_2:apt* AND address_2:*${text}*) OR party_1:(*${text}*) OR party_2:(*${text}*))`;
			}
			
			if (
				this.state.subFilters &&
				Object.keys(this.state.subFilters).length > 0
			) {
				Object.keys(this.state.subFilters).map(key => {
					let subFilterVal = this.state.subFilters[key];
					if (subFilterVal && subFilterVal != "") {
						const theFilter = this._documentFilters.filter(filter => filter.search_key === key);
						let qVal = null;
						
						if(theFilter && theFilter[0] && theFilter[0].text_filter){
							
							if(theFilter[0].search_fields){
								qVal = subFilterVal.split(" ").map(term => {
										return "(" + theFilter[0].search_fields.map(field => {
											return `${field}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*)`
										}).join(" OR ") + ")"
									}).join(" AND ");
									
								
								
							}else{
								qVal = subFilterVal.split(" ").map(term => {
									return `(${key}:(*${term.toLowerCase()}* *${term.toUpperCase()}* *${_capitalizeText(term)}* *${term}*))`
								}).join(" AND ");
							}
							
							
							if(qVal!==null){						
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += `(${qVal})`;
								
							}
							
						}else if(theFilter && theFilter[0] && theFilter[0].multi_filter){
							const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
										? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
										: null;
							if(qVal!==null){					
								this.q += this.q.length > 0 ? " AND " : "";
								if(theFilter[0].no_key){
									this.q += `(${qVal.join(" OR ")})`;
								}else{
									this.q += `(${key}:(${qVal.join(" ")}))`;
								}
									
							}		
						}else{
							qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
							if(qVal!==null){
							
								if(theFilter[0].target && theFilter[0].target === 'fq'){
									this.unitFq.push(qVal);
								}else{							
									this.q += this.q.length > 0 ? " AND " : "";
									this.q += `(${key}:${qVal})`;
									
								}
								
							}
						}
					}
				});		
			}
			/****Price & Date query***/
			let priceQ = {};
			let dateQ = {};
			
			let replaceKeys = {};
			let dateReplaceKeys = {};
			if (
				this.state.subFilters &&
				Object.keys(this.state.subFilters).length > 0
			) {
				Object.keys(this.state.subFilters).map(key => {
					let subFilterVal = this.state.subFilters[key];
					if (subFilterVal && subFilterVal != "") {
						const qArrayFilter = this._documentFilters.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
						
						if(qArrayFilter.length>0){	
							
							
							const filterKey = qArrayFilter[0].filter_key;
							console.log("qArrayFilter", qArrayFilter);
							if(replaceKeys[filterKey]){
								replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
							}else{
								replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
							}				
							if(priceQ[filterKey]){
								let regex = new RegExp(`{${key}}`, "g");
								priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
							}else{
								priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
								
								
							}											
						}	
						
						const dateArrayFilter = this._documentFilters.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
						if(dateArrayFilter.length>0){	
							const filterKey = dateArrayFilter[0].filter_key;
							
							if(dateReplaceKeys[filterKey]){
								dateReplaceKeys[filterKey]  = [...dateReplaceKeys[filterKey], ...dateArrayFilter[0].search_key];
							}else{
								dateReplaceKeys[filterKey] = [...[], ...dateArrayFilter[0].search_key]
							}	
							
												
							if(dateQ[filterKey]){
								let regex = new RegExp(`{${key}}`, "g");
								dateQ[filterKey] = dateQ[filterKey].replace(regex, moment(subFilterVal).toISOString())
							}else{		
								dateQ[filterKey] = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
								
							}											
						}	
					}
				});		
			}	
			console.log("priceQ", priceQ, replaceKeys)
			if(priceQ && Object.keys(priceQ).length>0){
				
				Object.keys(priceQ).map(filterKey => {
					const qArrayFilter = this._documentFilters.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
					replaceKeys[filterKey].map(key => {
						let regex = new RegExp(`{${key}}`, "g");
						if(key.indexOf("min")>=0){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
						}else{
							if(key.indexOf("max")>=0 && qArrayFilter && qArrayFilter.target && qArrayFilter.target=="fq"){
								priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
							}else{
								priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
							}
							
						}
						
					
					})
					
					if(qArrayFilter && qArrayFilter.target && qArrayFilter.target =="fq"){
						this.unitFq.push(priceQ[filterKey]);
					}else{
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += '(';
						this.q += priceQ[filterKey];
						this.q += ')';	
					}
					
					
				})
						
			}
			if(dateQ && Object.keys(dateQ).length>0){
				Object.keys(dateQ).map(filterKey => {
					const dateArrayFilter = this._documentFilters.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
					dateReplaceKeys[filterKey].map(key => {
						let regex = new RegExp(`{${key}}`, "g");
						dateQ[filterKey] = dateQ[filterKey].replace(regex, '*')
						
					
					})
					
					if(dateArrayFilter && dateArrayFilter.target && dateArrayFilter.target =="fq"){
						this.unitFq.push(dateQ[filterKey]);
					}else{
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += '(';
						this.q += dateQ[filterKey];
						this.q += ')';	
					}
				})
				
						
			}
			/****Price& Date query***/
			let axiosData = {
				url: apiURL + "we3-acris-real-property",
				method: "post",
				query: {
					q: this.q,
					fq: fq,
					fl: fl.join(', '),
					sort: "document_date desc, recorded_datetime asc, doc_type asc",
					wt: "json",
					rows: acris ? rowsPerPage : 9999,
					start: start,
					"json.facet": {
						"by_document": {
							"type" : "terms",
							"field" : "doc_type_desc",
							"mincount" : 1,
							"limit" : 999
						}		
					}

					
				}
			};
	
			let formattedData = await _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					if(res.docs && res.docs.length>0){
						
						let documents = {};
						
						const lendingData = [];
						let lenders = [];
						
						res.docs.map(item => {
							
							if(!documents[item.document_id]){								
								documents[item.document_id] = {
									'address2_list' : item.address_2_normalized !== "" ? [item.address_2_normalized] : [],
									'property_type_list' : item.property_type ? [item.property_type] : [],
									'type' : item.doc_type,
									'doc' : item,
									'amount' : item.document_amt ? item.document_amt : 0,
									'date' : item.document_date ? item.document_date : null						
								}								
							}else{
								if(item.address_2_normalized !== "" 
									&& documents[item.document_id].address2_list.indexOf(item.address_2_normalized)<0){
									documents[item.document_id].address2_list.push(item.address_2_normalized);
									if(item.property_type){
										documents[item.document_id].property_type_list.push(item.property_type);
									}
									
									
								}								
								
								
							}
							
														
							return item;														
						});
						for(let document_id in documents){
							let doc = documents[document_id];
							let count_sc = doc.property_type_list.filter(item => item === 'sc').length;
							if(acris ||  (doc.address2_list.length === 0 && doc.doc.property_type !== "sc") || (count_sc > (buildingRes.inventory_total * .25))){
								
								try{
									doc.doc.party_json = JSON.parse(doc.doc.party_json);
								}catch(e){
									doc.doc.party_json = false;
								}
								doc.doc.address2_list = doc.address2_list;
								lendingData.push(doc.doc);
							}
							
						}
						lendingData.sort((a, b) => {							
							if(a.recorded_datetime == b.recorded_datetime){
								if(b.doc_type == 'deed' && a.doc_type != 'deed'){
									 return -1;		
								}else{
									return 1;
								}					
							}else{
								return a.recorded_datetime > b.recorded_datetime ? -1 : 1;
							}
						})
						this.setState({lendingData: lendingData, totalCount: res.numFound, fetchInProgress:false});
					}else{
						this.setState({lendingData: [], totalCount: 0, fetchInProgress:false});
					}					
				})
				.catch(error => {
					console.log("error: " + error);
				});	
			// console.log(formattedData)
			return formattedData;
	  };
	  
	  _filterTableByInput = (name, eValue) => {
		  console.log("_filterTableByInput name", name, "eValue", eValue);
		  this.setState({searchText: eValue})
	  
	  };
	  _setSubFilter = (search_key, search_val) => {
	  
		  console.log(search_key, search_val);
		  const subF = Object.assign({}, this.state.subFilters, {
				  [search_key]: search_val
			  });
		  this.setState({
			  subFilters: subF,
			  start: 0,
			  end: this.state.rowPerPage
		  });
		  
		  setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	  }
	  
	  _setSubFilters = (subFilters) => {
		  const subF = Object.assign({}, this.state.subFilters, subFilters);
		  this.setState({
			  subFilters: subF,
			  start: 0,
			  end: this.state.rowPerPage
		  });
		  
		  setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	  }
	  
	  _resetSubFilter = (search_key, search_val) => {
	  
		  const subF =Object.assign({}, defaultSubFilter);
		  this.setState({
			
			  subFilters: subF,
			  searchText: "",
			  start: 0,
			  end: this.state.rowPerPage
		  });

		  deleteLocalStorage(lsPre, subFilterKey);
	  }	
	  _resetActive = () => {
		  
		  
		  
		  let isDefault = true;
	
		  isDefault = _objectEqual(defaultSubFilter, this.state.subFilters) &&
		  this.state.searchText == ""
		  
		  
			
		  return !isDefault;
		  
	  }

	  render() {
		  const { offeringPlan, buildingRes, user, acris } = this.props;
		  const { lendingData, order, orderBy, totalCount, page, rowsPerPage, fetchInProgress, subFilters, searchText, filterReady} = this.state;
		  
		   if(!acris && !_hasPermission(user, "finance-tab")){
				return (
					<EnterpriseGate 
						copy={<span>Access to building financing history requires an Enterprise subscription.</span>}
						upgrade={false}
						user={user}
						nextPlan={"enterprise"}
					/>	
				)   
			   
		  }
		 
		  if(!filterReady) return <LinearLoading />;
		 
		  
		  if(lendingData && lendingData.length <= 0 ){
			return (
			  <Col md={12}>
			    <Card>
			      <CardBody className={`small`}>
			        <div className={`email-confirmation`} >
			          <div className="email-confirmation__icon">
			            <AlertIcon size={50} color={`#D6BAE8`} />			            
			          </div>
			          <h3 className="email-confirmation__title">No Information Found</h3>
			          <p className="email-confirmation__sub"></p>
			          
			        </div>
			      </CardBody>
			    </Card>
			  </Col>
			  )
		  }
		  
		  const data = lendingData && lendingData.filter((thing, index, self) =>
			  index === self.findIndex((t) => (
			    t.document_id === thing.document_id
			  ))
			)
			
		  const loans = data && data.filter(item => ['agmt', 'mtge'].indexOf(item.doc_type)>=0 && item.document_amt > 0);
		  const lender = data && data.filter(item => ['asst', 'agmt', 'mtge'].indexOf(item.doc_type)>=0);
		  
		  
		  //const amendments = processHistory(offeringPlan[0]);
		  
		 
		  return (
		    <React.Fragment>
				{/*<Row className="mt-3">
			    	<Col md={12}>
			        	<h3 className="section-title section-title--not-last">Financing History</h3>
						<h3 className="section-title section-title--not-last subhead"> </h3>
					</Col>
			    </Row>*/}
				{!acris ?
					<Fragment>
					{loans &&
						<Row>
				   	 		<Col md={12} xl={3} lg={6} xs={12}>	
						 		<Card style={{height:'auto', paddingBottom:5}}>
					        		<CardBody className="dashboard__card-widget">
						            		<div className="card__title">
						              		<h5 className="bold-text">{`Latest Financing`}</h5>
						            		</div>
						        		
						      		<div className="mobile-app-widget">
						          		<div className="mobile-app-widget__top-line">
						            		<p className="mobile-app-widget__total-stat" style={{fontSize:20}}>
						            			{loans[0] && loans[0].document_amt ? `$${_formatNumber(loans[0].document_amt)}` : '--'}
							        		</p>
						            		<SupplyIcon className="dashboard__trend-icon" />
						          		</div>
						          		<div className="mobile-app-widget__title">
						            		<h5>Loan Amount</h5>
						          		</div>
						         		
					          		</div>
									</CardBody>
					    		</Card>
					 		</Col>
					 		<Col md={12} xl={6} lg={6} xs={12}>	
						 		<Card style={{height:'auto', paddingBottom:5}}>
					        		<CardBody className="dashboard__card-widget">
						            		<div className="card__title">
						              		<h5 className="bold-text">{`Lender`}</h5>
						            		</div>
						        		
						      		<div className="mobile-app-widget">
						          		<div className="mobile-app-widget__top-line">
						            		<p className="mobile-app-widget__total-stat" style={{fontSize:20}}>
						            			{lender[0] && lender[0].party_2 ? _capitalizeText(lender[0].party_2[0]) : '--'}
							        		</p>
						            		<SupplyIcon className="dashboard__trend-icon" />
						          		</div>
						          		<div className="mobile-app-widget__title">
						            		<h5>{'Entity Name'}</h5>
						          		</div>
						         		
					          		</div>
									</CardBody>
					    		</Card>
					 		</Col>
					 		<Col md={12} xl={3} lg={6} xs={12}>	
						 		<Card style={{height:'auto', paddingBottom:5}}>
					        		<CardBody className="dashboard__card-widget">
						            		<div className="card__title">
						              		<h5 className="bold-text">{`Date`}</h5>
						            		</div>
						        		
						      		<div className="mobile-app-widget">
						      			{loans[0] && loans[0].document_date ?
							      			<Fragment>
								          		<div className="mobile-app-widget__top-line">
								           		
								            		<p className="mobile-app-widget__total-stat" style={{fontSize:20}}>
								            			{moment(loans[0].document_date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY')}
									        		</p>
								            		<SupplyIcon className="dashboard__trend-icon" />
								          		</div>
								          		<div className="mobile-app-widget__title">
								            		<h5>{moment(loans[0].document_date.split('T')[0], 'YYYY-MM-DD').fromNow()}</h5>
								          		</div>
								    		</Fragment>
						        		:
						        			<Fragment>
								          		<div className="mobile-app-widget__top-line">
								           		
								            		<p className="mobile-app-widget__total-stat" style={{fontSize:20}}>
								            			--
									        		</p>
								            		<SupplyIcon className="dashboard__trend-icon" />
								          		</div>
								          		<div className="mobile-app-widget__title">
								            		<h5>--</h5>
								          		</div>
								    		</Fragment>
						        		
						        		}
						         		
					          		</div>
									</CardBody>
					    		</Card>
					 		</Col>
						</Row>
					}
				</Fragment>
				:
				<UnitsSearchAppBar 
					handleSearch={this._filterTableByInput}
					handleDeleteSelected={this.handleDeleteSelected}
					onRequestSort={this.handleRequestSort} 
					subFilters={subFilters}
					searchText={searchText}
					setSubFilter={this._setSubFilter}
					resetSubFilter={this._resetSubFilter}
					_filters={this._documentFilters}      
					{...this.props}  
					groupedView   
					building={buildingRes}
					setSubFilters={this._setSubFilters}	
					resetActive={this._resetActive()}
					totalCount={totalCount}
					
				 />
				}
				<hr/>
				{(lendingData===null || fetchInProgress) ? 
					<LinearLoading />
				:
			    <Row>
			    	<Col md={12}>
			    	 <Card>
					 <CardBody >
			    	<div className="material-table__wrap">
			    	 <div className="material-table expansion__table">	
			    	 <Container>
					 <Row className="expansion__summary__header">
					        <Col lg={2} className="no-padding">
	                      
					                Type

	                        </Col> 
						{/*	<Col lg={2} xs={12} >	                          

					                Recorded Date
	
							</Col>	*/}
							<Col lg={2} xs={12} >	                          
							  	    Date
					           
							</Col>		                        
	                       
							<Col lg={2} className="no-padding">
	
					                Amount
	
	                        </Col>
	                                               
	                        
	                       
	                        <Col lg={4}>
	                      		 Entity
	                        </Col>
	                       
	                    </Row>
	                    </Container>

			    	 {data.map((item, index) => (
						 <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
					        <ExpansionPanelSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-label="Expand"
					          aria-controls=""
					          id={item.id}
					        >
					        <Container>
						        <Row className="expansion__summary">
						        	<Col lg={2} xs={12} className="no-padding">	
										<span className={"mobile__show light-text small-text"}>Type:</span>
										<span style={{ width:12, height:12, display:"inline-block", background:item.doc_type === 'deed' ? "#F53246" :  item.doc_type.indexOf('sat')>=0 ? "#4ce1b6" : "#9600BF", borderRadius:3, marginRight:5}} /> 		                          
									  	{item.doc_type_desc === 'deed' ? "Ownership Transfer (Deed)" : _capitalizeText(item.doc_type_desc)}
									</Col>
									{/*<Col lg={2} xs={12} >
			                            <span className={"mobile__show light-text small-text"}>Recorded Date:</span>
									  	{moment(item.recorded_datetime.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY')}
									  	<br/>
									  	<span className="light-text small-text">
							         		<a className="primary" 
							         			href="javascript:void(0);"
							         			onClick={(e) => {
								         			e.stopPropagation(); 
								         			window.open(`https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${item.document_id}`);}} 
								         		>
							         		 See Document <OpenInNewIcon size={12} />
							         		</a>
							         	</span>
									</Col>*/}
									<Col lg={2} xs={12} >
			                            <span className={"mobile__show light-text small-text"}>Document Date:</span>
									  	{moment(item.document_date.split('T')[0], 'YYYY-MM-DD').year() > 1970 ? moment(item.document_date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY') : moment(item.recorded_datetime.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY')}
									  	<br/>
									  	<span className="light-text small-text">
							         		<a className="primary" 
							         			href="javascript:void(0);"
							         			onClick={(e) => {
								         			e.stopPropagation(); 
								         			window.open(`https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id=${item.document_id}`);}} 
								         		>
							         		 See Document <OpenInNewIcon size={12} />
							         		</a>
							         	</span>
									</Col>		                        
			                        <Col lg={2} xs={12} className="no-padding">	
			                        	<span className={"mobile__show light-text small-text"}>Amount:</span>		                          
									  	{item.document_amt > 0 ? `$${_formatNumber(item.document_amt)}` : `--`}
									  	<br/>
									  	<span className="light-text small-text">
									  		{item.address2_list.length == 1 ? <a className="primary" 
											   href="javascript:void(0);"
											   onClick={(e) => {
												e.stopPropagation(); 
												window.open(`${(buildingRes ? _getBuildingURL(buildingRes) : _getBuildingURL(item, 'building'))}/${_nameToUrl(item.address2_list[0])}`);}} 
												>{item.address2_list[0].toUpperCase().replace("APT", "Apt")} <OpenInNewIcon size={12} /></a> : item.address2_list.length > 1 ? (item.address2_list.length + ' Units') : 'Building / Lot' } 
									  	</span>
									</Col>
								                       
			                        <Col lg={6} xs={7}>
			                        	<span className={"mobile__show light-text small-text"}>Description:</span>
			                        	
			                        	{(item.doc_type === 'deed' || item.doc_type === 'rptt&ret') && item.document_amt > 0 ?
											<Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}transfered ownership to{' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											{' '}through a sale of the unit.
											</Fragment>
										 : (item.doc_type === 'deed' || item.doc_type === 'rptt&ret') && item.document_amt == 0 ?
											<Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}transfered ownership to{' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>
										 : item.doc_type === 'mtge' ?
											 <Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}took out or modified a mortgage with {' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>
										 : item.doc_type === 'agmt' ?
											 <Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}consolidated or transfered a mortgage to {' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>
										 : item.doc_type === 'asst' ?
											 <Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}assigned a mortgage to {' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>			                        				                         
										 : item.doc_type === 'sat' ?
											 <Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}paid off a mortagage with {' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>
										 : item.doc_type === 'sat' ?
											 <Fragment>
											<span className={''}>{_formatOwnerName(item.party_1)}</span>
											{' '}partially paid off a mortagage with {' '}
											<span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>	
										 : item.doc_type === 'ucc1' ?
										  <Fragment>
										 <span className={''}>{_formatOwnerName(item.party_1)}</span>
										 {' '}took out a loan from {' '}
										 <span className={''}>{_formatOwnerName(item.party_2)}</span>
										 </Fragment>				                         
										 :
											 <Fragment>
											 <span className={''}>{_formatOwnerName(item.party_1)}</span>
											 {' - '}
											 <span className={''}>{_formatOwnerName(item.party_2)}</span>
											</Fragment>	
										}
			                        	
			                        	
			                        	
		                            </Col> 
		                                                      
		                        </Row>
		                    </Container>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>	
					        	 <Container>
					        	 	<Table responsive striped className="dashboard__table-orders table-finance">
					        	 	 <thead>
								        <tr>
								          <th>Property Type</th>
								          <th>{item.doc_type === 'mtge' ? "Borrower" : "Party 1"}</th>
								          <th>{item.doc_type === 'mtge' ? "Lender" : "Party 2"}</th>								         
								        </tr>
								      </thead>
					        	 	 <tbody>
					        	 	
								          <tr key={index}>
								          	<td>
								          		<p>
								          		
								          		{_capitalizeText(item.property_type_desc)}</p>
								          		<br/>
								          		<span className="light-text small-text">
								          		Ref: 
								          		<a className="primary" 
								         			href="javascript:void(0);"
								         			onClick={(e) => {
									         			e.stopPropagation(); 
									         			window.open(`https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id=${item.document_id}`);}} 
									         		>
								         		   {item.document_id} <OpenInNewIcon size={12} />
								         		</a></span>
								         		
								         		

								          	</td>	
								          	<td>
								          	 {item.party_json  && item.party_json.party_1 && item.party_json.party_1.parties  && item.party_json.party_1.parties.map((item, index) => (
									          <div style={{padding:5}}>
								            	{_capitalizeText(item.name)}<br/>
								            	{item.address_1 
										              ? _capitalizeText(item.address_1)
										              : item.addr1 ? _capitalizeText(item.addr1) 
										              : ''									              
										         }
										         { item.addr2 ? <Fragment><br/>{_capitalizeText(item.addr2)}</Fragment> : ''}	
										         { item.city ? <Fragment><br/>{_capitalizeText(item.city)}</Fragment> : ''}
										         { item.state ? <Fragment>{', '}{_capitalizeText(item.state)}</Fragment> : ''}
										         { item.zip ? <Fragment>{' '}{_capitalizeText(item.zip)}</Fragment> : ''}		
										       </div>
										      ))}				            	
								            </td>	
								            <td className="" style={{whiteSpace:'nowrap'}}>
								            	 {item.party_json && item.party_json.party_2  && item.party_json.party_2.parties && item.party_json.party_2.parties.map((item, index) => (
										          <div style={{padding:5}}>
									            	{_capitalizeText(item.name)}<br/>
									            	{item.address_1 
											              ? _capitalizeText(item.address_1)
											              : item.addr1 ? _capitalizeText(item.addr1) 
											              : ''									              
											         }
											         { item.addr2 ? <Fragment><br/>{_capitalizeText(item.addr2)}</Fragment> : ''}	
											         { item.city ? <Fragment><br/>{_capitalizeText(item.city)}</Fragment> : ''}
											         { item.state ? <Fragment>{', '}{_capitalizeText(item.state)}</Fragment> : ''}
											         { item.zip ? <Fragment>{' '}{_capitalizeText(item.zip)}</Fragment> : ''}		
											       </div>
										      ))}									              
								            </td>						            
								            								            
								          </tr>
								        
					        	 	 </tbody>
								 	 </Table>
					        	 </Container>		        
					        </ExpansionPanelDetails>
					     </ExpansionPanel>
					    )) }
					    </div>
					    </div>
					    </CardBody>
					    </Card>
					</Col>
			    </Row>
				}
				{acris && totalCount ?
			   		<TablePagination
				 		component="div"
				 		className="material-table__pagination"
				 		count={totalCount}
				 		rowsPerPage={rowsPerPage}
				 		page={page}
				 		backIconButtonProps={{ 'aria-label': 'Previous Page' }}
				 		nextIconButtonProps={{ 'aria-label': 'Next Page' }}
				 		onChangePage={this.handleChangePage}
				 		onChangeRowsPerPage={this.handleChangeRowsPerPage}
				 		rowsPerPageOptions={[25, 50, 100]}
				 		dir="ltr"
				 		SelectProps={{
				   		inputProps: { 'aria-label': 'rows per page' },
				   		native: true,
				 		}}
			   		/> :
					   null
		   		}
		    </React.Fragment>
		  );
	}
}

export default (LendingHistory);
