/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import {
  BarChart, Bar, Cell, ResponsiveContainer, LabelList, Tooltip
} from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import Icon from 'mdi-react/ChartBarIcon';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment";

import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	pipelineCohortQuery,
	mpndCohortQuery,
	resaleCohortQuery
} from "../../../../../shared/helpers/utils";


const buildingUrl = process.env.API_ROOT + '/query/';

const isDST = moment().isDST();
const defaultQ = `((${pipelineCohortQuery}) OR (${mpndCohortQuery}) OR (${resaleCohortQuery})) AND deleted:false`;

const startDate = moment().set({hour:0,minute:0,second:0,millisecond:0}).add(-1, 'year').startOf('month').toDate().toISOString();

const endDate = moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString();

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="dashboard__total-tooltip" style={{background:"#171F26",padding:"5px 10px",borderRadius:3}}>
        <p className="label small-text" style={{color:"#FFF",fontSize:"11px"}}>{`${_capitalizeText(payload[0].payload.time)}:`}
        <span style={{fontWeight:500}}> {`${_formatNumber(payload[0].value)} Events`}</span></p>
      </div>
    );
  }

  return null;
};

const CustomLabelList = (props) => {
  console.log('CustomLabelList : ', props); // It is Top Result. not have x, y
  const {x, y, stroke, value} = props;

  return (
    <text x={x} dx={5} y={65} fill={stroke} fontSize={10} textAnchor="middle">{value[0].toUpperCase()}</text>
  )
};


class MarketActivity extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      data : false
    };
  }
   componentDidMount() {
  	 //this._getBuildings();
  	 
  	 this._getEvents(true);
  	 
  	
  }


  handleClick = (item) => {
	const { data } = this.state;
    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };
  
  _getEvents = async (resetPage) => {
		

		var fq = [
			'(topic:listings AND event_tags:just-listed AND -event_tags:rent AND -address_2_normalized:"" AND -event_tags:*commercial*) OR (topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -address_2_normalized:"" AND -event_tags:*commercial*) OR (topic:transactions AND event_tags:recorded-sale AND -address_2_normalized:"" AND -event_tags:*commercial* AND -event_tags:*building* AND -event_tags:parcels-condocoop_0 AND -event_tags:parcels_bulk-sale)',
			"hierarchy:[0 TO 2]",	
 			"-time:[2020-03-18T20:42:19Z TO 2020-03-19T00:47:46Z]",
 			`{!join from=alternate_keys fromIndex=we3-buildings to=building}${defaultQ}`
		
			
		];
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				wt: "json",
				rows: 0,
				"json.facet": {
					"by_time": {
						"range": {
							"field": "time",
							"start": startDate,
							"end": endDate,
							"gap": "+1MONTH"
							
						}
					}
				}
			}
		};
		
		let chartData = [];

		let res = await _axiosCall(axiosData)
			.then(res => {
				
				return res;

			})
			.catch(error => {
				console.log("error: " + error);
			});
			
		if(res && res.facets){
			if(res.facets.by_time){
							
				chartData = res.facets.by_time.buckets.map(bucket => {
					const key = moment(bucket.val).add(1, 'hour').format('MMM, YYYY');
					return { time: key, count: bucket.count}
				})
					
			}
			
			this.setState({
				data: chartData,
				activeIndex: chartData.length-1
			})
		}
			
		// console.log(formattedData)
		//return formattedData;
	};

  render() {
    const { activeIndex, data } = this.state;

	console.log("market activity", activeIndex)
        
    return (
      <Col md={12} xl={4} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget card__link">
          <Link to={`/analysis/market-pulse`}>
            <div className="card__title">
              <h5 className="bold-text">{'Condo Market Activity'}</h5>
            </div>
            {data &&
	        <Fragment>
	            <div className="dashboard__total">
	              
	              {/*<div className="dashboard__total-stat">
	              	<span style={{ color:"#9600BF", fontWeight:500 }}>{_formatNumber(data[activeIndex].count)}</span>
	              	<div className="small-text light-text">(Events - MTD)</div>
	              </div>*/}
	              <div className="dashboard__chart-container" style={{maxWidth:"none"}}>
	                <ResponsiveContainer height={70}>
	                  <BarChart data={data} margin={{right:20}}>
	                  	<Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}}/>
	                    <Bar dataKey="count" onClick={this.handleClick}>
	                      {data.map((entry, index) => (
	                          <Cell
	                            cursor="pointer"
	                            fill={index === activeIndex ? '#9600BF' : '#BF96D9'}
	                            key={`cell-${index}`}
	                          />
	                        ))
	                      }
	                    </Bar>
	                    
	                  </BarChart>
	                </ResponsiveContainer>
	              </div>  
	              <Icon className="dashboard__trend-icon" />        
	            </div>
	            <p className="progress__label"><span style={{ color:"#9600BF", fontWeight:500 }}>{_formatNumber(data[activeIndex].count)}</span> <span className="small-text light-text"> Listings, Contracts and Sales This Month</span></p> 
            </Fragment>
            }
           </Link>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default MarketActivity;
