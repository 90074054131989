import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const rows = [
  {
    id: '', disablePadding: true, noSort: true, label: '',
  },
 {
    id: 'close_date_formatted', disablePadding: false, label: 'Sold Date',
  },
  {
    id: 'recorded_datetime', disablePadding: false, label: 'Recorded Date',
  },
  {
    id: 'address_2_normalized', disablePadding: false, label: 'Unit #',
  },
  {
    id: 'price', disablePadding: false, label: 'Sold Price',
  },
  {
    id: 'discount', disablePadding: false, label: 'Discount',
  },
  {
    id: 'contract_date_formatted', disablePadding: false, label: 'Contract Date',
  },
  {
    id: 'days_to_contract', disablePadding: false, label: 'Days on Market',
  },
  {
    id: 'bedrooms', disablePadding: false, label: 'Beds',
  },
  {
    id: 'baths', disablePadding: false, label: 'Baths',
  },
  
   {
    id: 'price_per_sqft', disablePadding: false, label: 'PPSF',
  },
  {
    id: 'percent_financed', disablePadding: false, label: 'Financed',
  },
  {
    id: 'lender', disablePadding: false, label: 'Lender',
  },
  {
    id: 'verified_title_company_name', disablePadding: false, noSort: true, label: 'Title Company',
  },
  {
    id: 'verified_buyers_attorney_firm_name', disablePadding: false, label: 'Buyer\'s Attorney',
  },
  {
    id: 'agents', disablePadding: false, noSort: true, label: 'Listing Broker',
  },
 
  {
    id: 'owner', disablePadding: false, label: 'Buyer',
  },
  {
    id: 'seller', disablePadding: false, label: 'Seller',
  }
];

//if(isMobile) rows.splice(0,1);

class UnitsSearchTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          
          {/*<TableCell padding="checkbox">
          </TableCell>*/}
          {rows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.noSort ? 
	              <span>{row.label}</span>
	            :
	              <TableSortLabel
	                active={orderBy === row.id}
	                direction={order}
	                onClick={this.createSortHandler(row.id)}
	                className="material-table__sort-label"
	                dir="ltr"
	              >
	                {row.label}
	              </TableSortLabel>
	              
              }
              
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(UnitsSearchTableHead);
