import React from 'react';
import { Badge, Progress } from 'reactstrap';
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText
} from '../../helpers/utils';

export const subscriptionPlans = [
	/*{
		id: 'basic',
		name: 'Basic',
		pricePerMonth:14.99,
		pricePerYear:240,
		billingFrequency:'month',
		billingFrequencyMonths:12,
		description:'Cancel Anytime',
		totalAtCheckout:14.99,
		freeTrial : true,
		contactUs : false,
		stripeProductId: process.env.NODE_ENV === "development" ? 'price_1HZi3xG44QC64PsROMcvDFxz' : 'price_1HZi0JG44QC64PsRACC9qKor',
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Individuals'],
		checkTitle: '7-Day Free Trial',
		dataCredits : 0
	},
	{
		id: 'advanced',
		name: 'Advanced',
		pricePerMonth:49,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Cancel Anytime',
		totalAtCheckout:49,
		freeTrial : true,
		contactUs : false,
		stripeProductId: process.env.NODE_ENV === "development" ? 'price_1I4X7xG44QC64PsRJfBpWfQi' : 'price_1I5u44G44QC64PsRZq0J3cjy',
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Individuals'],
		checkTitle: '7-Day Free Trial',
		dataCredits : 10
	},
	{
		id: 'complete',
		name: 'Complete',
		pricePerMonth:249,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Cancel Anytime',
		totalAtCheckout:249,
		freeTrial : true,
		contactUs : false,
		stripeProductId: process.env.NODE_ENV === "development" ? 'price_1I75VmG44QC64PsRiKWWdjQJ' : 'price_1I75XFG44QC64PsRtPbcXYpV',
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Real Estate Brokers', 'CRE Brokers', 'Mortgage & Banking', 'Private Equity', 'Title', 'Legal', 'Developers'],
		checkTitle: '7-Day Free Trial',
		dataCredits : 50
	},
*/
	{
		id: 'resale',
		name: 'Resale',
		pricePerMonth:29,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Cancel Anytime',
		totalAtCheckout:29,
		freeTrial : true,
		contactUs : false,
		canUpgrade : true,
		stripeProductId: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local" ? 'price_1Mvk6QG44QC64PsRU6DzQem4' : 'price_1Mvk1AG44QC64PsRKq9AYtFQ',
		taxCode: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Resale Condos & Co-ops', 'Search Past Sales', 'Market & Price Analysis', 'Monitor Discounts Trends'],
		checkTitle: '7-Day Free Trial',
		dataCredits : 0
	},
	{
		id: 'complete',
		name: 'Resale & New Dev',
		pricePerMonth:229,
		pricePerYear:2748,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Cancel Anytime',
		totalAtCheckout:229,
		freeTrial : true,
		contactUs : true,
		canUpgrade : true,
		stripeProductId: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local"  ? 'price_1PsAgJG44QC64PsRwqhK6srS' : 'price_1Ps3qBG44QC64PsRUEJOY67y',
		taxCode: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['New Dev Buildings & Shadow Inventory', 'New Development Tools', 'New Dev Developers, Architects and Agents', "Resale Condos & Co-ops"],
		checkTitle: '7-Day Free Trial',
		dataCredits : 25,
		startAt: true
	},
	{
		id: 'enterprise',
		name: 'Enterprise',
		pricePerMonth:9500,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Contact Us',
		totalAtCheckout:9500,
		freeTrial : false,
		contactUs : true,
		stripeProductId: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local"  ? 'price_1PsCOoG44QC64PsRSV4ABnck' : 'price_1PsCPXG44QC64PsRgeGXJK6s',
		taxCode: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba',
		users:['Real Estate Brokers', 'CRE Brokers', 'Mortgage & Banking', 'Private Equity', 'Title', 'Legal', 'Developers'],
		hidePrice:false,
		checkTitle: "Tailored To Your Needs",
		dataCredits : 100,
		startAt: true,
		hideChangePlan: true

	},
	
];


export const appFeatures = [
	{
		name: 'Daily Newsletter (Email)',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Weekly Market Update (Email)',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Resale Condos & Co-ops',
		plans: ['advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	
	{
		name: 'Resale Inventory Search',
		plans: ['advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Townhouses, Single & Multi-Families',
		plans: ['advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	// {
	// 	name: 'AI Assistant',
	// 	plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
	// 	description: ''
	// },	
	{
		name: 'Market Activity',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Sharing',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},	
	{
		name: 'Draw on Map Search',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},	
/*
	{
		name: 'Report Your Buy/Sell Side Contracts',
		plans: ['basic', 'advanced', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},

	{
		name: 'Market Activity (Unit Details)',
		plans: ['advanced',  'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},	
	*/
	{
		name: 'Energy Use & Environmental Risk',
		plans: ['advanced', 'resale', 'complete', 'complete-199','enterprise'],
		description: ''
	},
	{
		name: 'Consumer Lender & Loan Officer Details',
		plans: ['advanced', 'resale', 'complete', 'complete-199','enterprise'],
		description: ''
	},
	{
		name: 'Buyer\'s Attorney and Law Firm Details',
		plans: ['advanced', 'resale', 'complete', 'complete-199','enterprise'],
		description: ''
	},
	{
		name: 'Stacking Plans',
		plans: ['advanced',  'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Create & Share Collections',
		plans: ['advanced', 'resale','complete', 'complete-199','enterprise'],
		description: ''
	},	
	{
		name: 'Verified Contract Date and Sold Price',
		plans: ['advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Building Management & Contacts',
		plans: ['advanced', 'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Price Trends',
		plans: ['advanced', 'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Past Sales Search',
		plans: ['advanced', 'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Financial Document Search',
		plans: ['advanced', 'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Permit Search',
		plans: ['advanced', 'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Bulk Sales Search',
		plans: ['advanced', 'resale','complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Buyer & Seller Name Search',
		plans: ['advanced', 'resale', 'complete', 'complete-199','enterprise'],
		description: ''
	},
	{
		name: 'Project Financing & Offering Info',
		plans: ['advanced', 'resale', 'complete', 'complete-199','enterprise'], 
		description: ''
	},
	{
		name: 'New Dev Buildings',
		plans: ['complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Sponsor Inventory Search',
		plans: [ 'complete', 'complete-199', 'enterprise'],
		description: ''
	},
	
	{
		name: 'Sales Velocity Analysis',
		plans: ['complete', 'complete-199', 'enterprise'],
		description: ''
	},
	
	{
		name: 'Pipeline Buildings & Analysis',
		plans: ['complete', 'complete-199', 'enterprise'],
		description: ''
	},
	
	
	{
		name: 'Discount Analysis',
		plans: ['complete', 'complete-199', 'enterprise'],
		description: ''
	},
	
	{
		name: 'Sales Agents - Analysis',
		plans: ['complete', 'complete-199','enterprise'],
		description: ''
	},
	{
		name: 'Architects - Analysis',
		plans: ['complete', 'complete-199', 'enterprise'],
		description: ''
	},
	{
		name: 'Developers - Analysis',
		plans: ['complete', 'complete-199','enterprise'],
		description: ''
	},
/*
	{
		name: 'Rental Buildings',
		plans: ['complete', 'complete-199', 'enterprise'],
		description: ''
	},
*/
	{
		name: 'Permits & Filings',
		plans: ['complete', 'complete-199','enterprise'],
		description: ''
	},	
	
/*
	{
		name: 'Report Firm\'s Buy/Sell Side Contracts',
		plans: ['enterprise'],
		description: ''
	},
*/
	{
		name: 'Lenders - Analysis',
		plans: ['enterprise'],
		description: ''
	},
	
	{
		name: 'Research & Advisory Services',
		plans: ['enterprise'],
		description: ''
	},
	{
		name: 'Access to Marketproof team',
		plans: ['enterprise'],
		description: ''
	},
	{
		name: 'Custom Reports',
		plans: ['enterprise'],
		description: ''
	},
	// {
	// 	name: 'Priority Support',
	// 	plans: ['enterprise'],
	// 	description: ''
	// },
	
	

]

export const dataFeatures = [
	{
		name: 'Data Export (Self-serve)',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: 'Export building, unit and market activity data for your own use.',
		learn_more : "https://help.marketproof.com/en/articles/4857038-guide-to-exporting-data-on-marketproof-new-development"
	},
	{
		name: 'Pay As You Go Data Credits',
		plans: ['basic', 'advanced', 'resale', 'complete', 'complete-199', 'enterprise'],
		description: 'Buy more data credits at anytime if you run out.',
		learn_more : "https://help.marketproof.com/en/articles/4857038-guide-to-exporting-data-on-marketproof-new-development"
	},
	{
		name: 'No Data Credits Inlcuded',
		plans: ['advanced', 'complete', 'complete-199', 'enterprise'],
		description: 'Data credits allow you to export a varity of data sets for your use.',
		learn_more : "https://help.marketproof.com/en/articles/4857038-guide-to-exporting-data-on-marketproof-new-development"
	},
	
	{
		name: 'API Access',
		plans: ['enterprise'],
		description: ''
	}

]

export const coreTranslate = {
	"we3-properties" : "unit",
	"we3-buildings" : "building",
	"we3-activities-3" : "activity",
	"we3-transactions" : "sale"
}

export const dataExportCreditMap = {
	"building" : 4,
	"unit" : 15,
	"activity" : 50,
	"sale" : 15,
}

export const maxDownload = (core_name, user) => {
	let dataExportCreditMap = {
		"building" : 100,
		"unit" : 1000,
		"activity" : 2000,
		"sale" : 2000
	}
	if(user && user.role.indexOf('admin')>=0){
		dataExportCreditMap = {
			"building" : 9999999,
			"unit" : 9999999,
			"activity" : 9999999,
			"sale" : 9999999
		}
	}
	return dataExportCreditMap[coreTranslate[core_name]];
}


export const calculateCredits = (core_name, rows, facets) => {
	
	let total_records = 0;
	if(facets){
		if(coreTranslate[core_name] == "building"){			
			if(facets.by_units && facets.by_units.buckets){
				facets.by_units.buckets.forEach(bucket => {					
					if(bucket.count){
						const multiplier = 1; //(bucket.val + 50) / 50;
						total_records += bucket.count * multiplier;
					}	
				})
				
			}
			
		}else if(coreTranslate[core_name] == "unit"){
			if(facets.by_status && facets.by_status.buckets){
				facets.by_status.buckets.forEach(bucket => {					
					if(bucket.count){
						const multiplier = bucket.val == "shadow" ? 5 : 1;
						total_records += bucket.count * multiplier;
					}	
				})
				
			}			
		}
	}else{
		total_records = rows;
	}
	
	return Math.ceil(total_records / dataExportCreditMap[coreTranslate[core_name]]);	
}

export const costPerCredit = 5; 

export const translateCreditToDollars = (credits) => {
	return credits * costPerCredit;
}

export const DataCreditUsageProgress = ({ credit_used, credit_total, maxWidth, label, color, usage } ) => { 
		
		let creditAvail = credit_total - credit_used;
		if(creditAvail < 0) creditAvail = 0;
		
		let progress = parseFloat(((creditAvail) / credit_total) * 100);		
		let credit_limit_used = credit_used > credit_total ? credit_total : credit_used;		
		let credit_over_limit_used = credit_used > credit_total ? credit_used - credit_total : 0;
		
		if(usage){
			return (
		      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :'100%' }} >
		        <div className="dashboard__stat">
		          <div className={`progress-wrap progress-wrap--big`}>	          
		            <Progress multi>
		            	<Progress bar value={credit_limit_used} max={credit_total+credit_over_limit_used}>
		            		<span>{credit_limit_used ? _formatNumber(credit_limit_used) : '0'} of {credit_total > 0 ? _formatNumber(credit_total) : '0'} <span className="small-text light-text" style={{color:'#FFF'}}>Included</span></span>
		            	</Progress>
		            	{credit_over_limit_used > 0 && 
			             <Progress bar color="active" value={credit_over_limit_used} max={credit_total+credit_over_limit_used}>
			            	 <span>{_formatNumber(credit_over_limit_used)} <span className="small-text light-text" style={{color:'#FFF'}}> Pay As You Go</span></span>
							</Progress>
		            	}
		            </Progress>
		          </div>
		        </div>
		      </div>
		    ); 
		}
		return (
	      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :'100%' }} >
	        <div className="dashboard__stat">
	          <div className={`progress-wrap reverse progress-wrap--middle progress-wrap--${color ? color : 'purple'}-gradient progress-wrap--rounded`}>	          
	            <p className="progress__label" style={{fontSize:13}}><span className="semi-bold-text">{creditAvail > 0 ? _formatNumber(creditAvail) : '0'} of {credit_total > 0 ? _formatNumber(credit_total) : '0'}</span> included credits available</p>	           
	            <Progress value={progress} />
	          </div>
	        </div>
	      </div>
	    );  

}

export const _isPipelineSubscriber = (user) => {
   return user && user.role && user.role.filter(r => ['pipeline-complete', 'pipeline-basic', 'pipeline-enterprise'].indexOf(r)>-1).length>0
}

export const _isNewDevSubscriber = (user) => {
   return user && user.subscriptionPlan
}