/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/TagIcon';
import Panel from './../../../../../shared/components/Panel';
import Button from '@material-ui/core/Button';
import { Badge } from 'reactstrap';

import ArrowIcon from 'mdi-react/ChevronRightIcon';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_formatPrice,
	_formatNumber
} from "../../../../../shared/helpers/utils";

import {
  Card, CardBody, Col, Progress, ButtonToolbar
} from 'reactstrap';

const TotalInventory = ( { data, type } ) => {

  if(type == "condo"){
		return (	
			  <Col md={12} xl={3} lg={3} xs={12}>
				<Card>
					<CardBody className="dashboard__card-widget card__link">
					   {!isMobile ?
						<Link to={`/inventory/resale?building_stage=all&current_status=active&group_view=building&order=desc&order_by=count&property_type=residential&unit_stage=resale`}>
							<div className="card__title">
							  <h5 className="bold-text">{`Condo & Co-op`} 
							  
							  </h5>
							</div>
						
					  <div className="mobile-app-widget">
						  <div className="mobile-app-widget__top-line">
							<p className="mobile-app-widget__total-stat">{data && data.active_condo ? _formatNumber((data.active_condo.count + data.active_coop.count)) : "--"}</p>
							<Icon className="dashboard__trend-icon" />
						  </div>
						  <div className="mobile-app-widget__title">
							<h5>{'Listed Resales'}</h5>
						  </div>
						  <p className="progress__label">
							  <span className="small-text light-text">Condo: </span> <span style={{ color:"#9600BF" }}>{data && data.active_condo ? _formatNumber(data.active_condo.count) : "--"}</span>  <span className="small-text light-text"> | Co-op: </span><span style={{ color:"#9600BF" }}>{data && data.active_coop ? _formatNumber(data.active_coop.count) : "--"}</span>
						 
						  </p> 
						 
					   </div>
					  </Link>
					  :
					  
						<div>
							<div className="panel__title">
							  <h5 className="bold-text">{`Condo & Co-op`}</h5>
							</div>
							<Card style={{padding:'0px 20px'}}>
							<CardBody>
							<div className="mobile-app-widget" style={{textAlign:'center'}}>
							  <div className="mobile-app-widget__top-line" style={{display:'block'}}>
								<p className="mobile-app-widget__total-stat">{data && data.active_condo ? _formatNumber((data.active_condo.count + data.active_coop.count)) : "--"}</p>
							  </div>
							  <div className="mobile-app-widget__title" style={{textAlign:'center'}}>
								<h5>{'Listed Resales'}</h5>
							  </div>
							  <p className="progress__label">
									<span className="small-text light-text">Condo: </span> <span style={{ color:"#9600BF" }}>{data && data.active_condo ? _formatNumber(data.active_condo.count) : "--"}</span>  <span className="small-text light-text"> | Co-op: </span><span style={{ color:"#9600BF" }}>{data && data.active_coop ? _formatNumber(data.active_coop.count) : "--"}</span>
							   
								</p> 
							  <Link to={`/inventory/resale?building_stage=all&current_status=active&group_view=building&order=desc&order_by=count&property_type=residential&unit_stage=resale`}>
								<Button
									 variant="contained"
									 color="primary"
									 className="buyersList__primary-button"		         
									 startIcon={null}
								 >	Search Condos & Co-ops
								 </Button>	
							 </Link>
							</div>
							</CardBody>
							</Card>
						</div>
					  }
					</CardBody>
				</Card>
			  </Col>
		  )
	}else if(type=="house"){
		 return (	
			   <Col md={12} xl={3} lg={3} xs={12}>
				 <Card>
					 <CardBody className="dashboard__card-widget card__link">
						{!isMobile ?
						 <Link to={`/building-database/house/?building_stage=all&current_status=active&order=desc&order_by=last_listed_sale_date_all`}>
							 <div className="card__title">
							   <h5 className="bold-text">{`Single & Multi-Fam`} 
							   {/*<Badge className="dashboard__link-badge"><span>New</span></Badge>*/}
							   
							   </h5>
							 </div>
						 
					   <div className="mobile-app-widget">
						   <div className="mobile-app-widget__top-line">
							 <p className="mobile-app-widget__total-stat">{data && data.active_single_family ? _formatNumber((data.active_single_family.count + data.active_multi_family.count)) : "--"}</p>
							 <Icon className="dashboard__trend-icon" />
						   </div>
						   <div className="mobile-app-widget__title">
							 <h5>{'Listed Properties'}</h5>
						   </div>
						   <p className="progress__label">
							  <span className="small-text light-text">Single-Fam: </span> <span style={{ color:"#9600BF" }}>{data && data.active_single_family ? _formatNumber(data.active_single_family.count) : "--"}</span>  <span className="small-text light-text"> | Multi-Fam: </span><span style={{ color:"#9600BF" }}>{data && data.active_multi_family ? _formatNumber(data.active_multi_family.count) : "--"}</span>
						  
						   </p> 
						  
						</div>
					   </Link>
					   :
					   
						 <div>
							 <div className="panel__title">
							   <h5 className="bold-text">{`Single & Multi-Family`}</h5>
							 </div>
							 <Card style={{padding:'0px 20px'}}>
							 <CardBody>
							 <div className="mobile-app-widget" style={{textAlign:'center'}}>
							   <div className="mobile-app-widget__top-line" style={{display:'block'}}>
								 <p className="mobile-app-widget__total-stat">{data && data.active_single_family ? _formatNumber((data.active_single_family.count + data.active_multi_family.count)) : "--"}</p>
							   </div>
							   <div className="mobile-app-widget__title" style={{textAlign:'center'}}>
								 <h5>{'Active Resales'}</h5>
							   </div>
							   <p className="progress__label">
									<span className="small-text light-text">Single-Fam: </span> <span style={{ color:"#9600BF" }}>{data && data.active_single_family ? _formatNumber(data.active_single_family.count) : "--"}</span>  <span className="small-text light-text"> | Multi-Fam: </span><span style={{ color:"#9600BF" }}>{data && data.active_multi_family ? _formatNumber(data.active_multi_family.count) : "--"}</span>
								
								 </p> 
							   <Link to={`/building-database/house/?building_stage=all&current_status=active&order=desc&order_by=last_listed_sale_date_all`}>
								 <Button
									  variant="contained"
									  color="primary"
									  className="buyersList__primary-button"		         
									  startIcon={null}
								  >	Search Houses
								  </Button>	
							  </Link>
							 </div>
							 </CardBody>
							 </Card>
						 </div>
					   }
					 </CardBody>
				 </Card>
			   </Col>
		   )
	}
	  
	
  return (	
	  <Col md={12} xl={4} lg={4} xs={12}>
	    <Card>
	        <CardBody className="dashboard__card-widget card__link">
	           {!isMobile ?
		        <Link to={`/inventory/resale?unit_stage=resale&current_status=active&property_type=residential`}>
		            <div className="card__title">
		              <h5 className="bold-text">{`Resale Inventory`} 
		              
		              </h5>
		            </div>
		        
		      <div className="mobile-app-widget">
		          <div className="mobile-app-widget__top-line">
		            <p className="mobile-app-widget__total-stat">{data && data.active_condo && data.active_coop ? _formatNumber(data.active_condo.count + data.active_coop.count) : "--"}</p>
		            <Icon className="dashboard__trend-icon" />
		          </div>
		          <div className="mobile-app-widget__title">
		            <h5>{'Active Resale Units'}</h5>
		          </div>
		          <p className="progress__label">
		          	<span className="small-text light-text">Across</span> <span style={{ color:"#9600BF" }}>{data && data.active_condo ? _formatNumber(data.active_condo.buildings) : "--"}</span>  <span className="small-text light-text"> Condo</span><span className="small-text light-text"> and </span><span style={{ color:"#9600BF" }}>{data && data.active_coop ? _formatNumber(data.active_coop.buildings) : "--"}</span> <span className="small-text light-text">Co-op Buildings</span>
		          {/*	<a href="javascript:void(0)" 
		              	onClick={(e) => {e.stopPropagation();e.preventDefault();window.Intercom('startTour', 180617);}} 
		              	style={{fontSize:'11px',color:'#9600BF',textTransform:'uppercase',fontWeight:'400',float:'right',marginTop:'3px'}}>
		              	Take a Tour<ArrowIcon size={12} />
		            </a>	*/}
		          </p> 
		          {/*<div className="progress-wrap progress-wrap--small
		          progress-wrap--turquoise-gradient progress-wrap--label-top"
		          >
		            <Progress value={62}>
		            	<div style={{ position:"absolute", right:0, fontSize:"10px", bottom:"10px", color:"#4ce1b6" }}>62%</div>
		            		<p className="progress__label">8,850 <span className="small-text light-text">Unsold</span></p> 
		            </Progress>
		          </div>*/}
		       </div>
	          </Link>
			  :
			  
		        <div>
		        	<div className="panel__title">
		              <h5 className="bold-text">{`Resale Condo Inventory`} </h5>
		              <h5 className="subhead">{`Resale Condo Inventory`} </h5>
		            </div>
		            <Card style={{padding:'0px 20px'}}>
					<CardBody>
		        	<div className="mobile-app-widget" style={{textAlign:'center'}}>
			          <div className="mobile-app-widget__top-line" style={{display:'block'}}>
			            <p className="mobile-app-widget__total-stat">{data && data.active ? _formatNumber(data.active.count) : "--"}</p>
			          </div>
			          <div className="mobile-app-widget__title" style={{textAlign:'center'}}>
			            <h5>{'Available Units'}</h5>
			          </div>
			          <p className="progress__label">
			          	<span className="small-text light-text">Across</span> <span style={{ color:"#9600BF" }}>{data && data.active ? _formatNumber(data.active.buildings) : "--"}</span>  <span className="small-text light-text"> Buildings</span>
			          </p>
			          <Link to="/inventory/resale">
			            <Button
					         variant="contained"
					         color="primary"
					         className="buyersList__primary-button"		         
					         startIcon={null}
					     >	Search Resales
					     </Button>	
					 </Link>
			        </div>
		            </CardBody>
					</Card>
		        </div>
	          }
			</CardBody>
	    </Card>
	  </Col>
  )
};


export default withTranslation('common')(TotalInventory);
