import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ShareableContent from './components/ShareableContent';
import TrialAlert from '../../../shared/components/payment/TrialAlert';

const professionals = {
	'legal' : [
		{ 'name' : 'Pierre Debbas', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/debbas.png', 
		  'focus' : "(212) 888-3100", 
		  'description' : 'Finance, condo development and conversion, commercial leasing, banking/private banking and more...',
		  'link' : 'https://www.romerdebbas.com/'},
		{ 'name' : 'Andrew Grabiner', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/gglgny.svg', 
		  'focus' : "(212) 344 0200", 
		  'description' : 'Focusing on both residential real estate transactions and lender-based mortgage finance transactions.',
		  'link' : 'https://www.gglgny.com/'},
		{ 'name' : 'Zerline Goodman', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/zerline-goodman.png', 
		  'focus' : "(718) 488-7764", 
		  'description' : 'Successfully guiding New Yorkers through the Real Estate Process.',
		  'link' : 'http://zlglawyer.com/'},
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''}
	],
	'mortgage' : [		
		{ 'name' : 'Gary Farro', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/first-republic-bank-logo%402x.88ef21ce.png', 
		  'focus' : "(888) 408-0288", 
		  'description' : 'Find cost-effective financing options for your property purchase or construction costs.',
		  'link' : 'https://www.firstrepublic.com'},
		{ 'name' : 'Brian Scott Cohen', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/wells.png', 
		  'focus' : "(718) 780-9132", 
		  'description' : 'I\'ll help you understand your options so you can make informed decisions.',
		  'link' : 'https://www.wfhm.com/loans/brian-cohen/about.page'},
		{ 'name' : 'Stephen Rybak', 'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/guardhill.png', 'focus' : "(917) 847-7531", 'description' : 'A mortgage lender providing solutions for all borrowers.', 'link':'https://guardhill.com/'},
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
	],
	'title' : [
		{ 'name' : 'Brian Tormey', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/titlevest.png', 
		  'focus' : "(212) 757-5800", 
		  'description' : 'Awarded Best Title Agency by the New York Law Journal Reader Rankings Survey Every Year Since 2013.',
		  'link' : 'https://www.titlevest.com/'},
		{ 'name' : 'Lorraine Lincoln', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/freedomabstract.png', 
		  'focus' : "(631) 414-5900", 
		  'description' : 'Full service agency providing title and settlement services nationally.',
		  'link' : 'https://www.freedomabstract.com'},		
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
	],
	'insurance' : [
		{ 'name' : 'Adam Herfield', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/firldsgroupins.jpg', 
		  'focus' : "(646) 979-9010", 
		  'description' : 'At Fields Group, we pledge to make the process of buying insurance as simple and painless as possible.',
		  'link' : 'https://www.fieldsgroupins.com/'},	
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
	],
	'analysis' : [
		{ 'name' : 'Kael Goodman', 
			'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/marketproof-logo-horizonal-black.png', 
			'focus' : "(917) 740-3781", 
			'description' : 'Founder & CEO of Marketproof, NYC’s most complete and accurate real estate market intelligence platform.',
			'link' : 'https://marketproof.com/about'},		
		{ 'name' : 'Donna Olshan', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/olshan-logo.jpg', 
		  'focus' : "(212) 751-3300", 
		  'description' : "President of Olshan Realty, a leading boutique residential brokerage, and author of the Olshan Luxury Report.",
		  'link' : 'https://olshan.com/'},	
		{ 'name' : 'Donna Olshan', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/olshan-logo.jpg', 
		  'focus' : "(212) 751-3300", 
		  'description' : "President of Olshan Realty, a leading boutique residential brokerage, and author of the Olshan Luxury Report.",
		  'link' : 'https://olshan.com/'},	
		{ 'name' : 'Donna Olshan', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/olshan-logo.jpg', 
		  'focus' : "(212) 751-3300", 
		  'description' : "President of Olshan Realty, a leading boutique residential brokerage, and author of the Olshan Luxury Report.",
		  'link' : 'https://olshan.com/'},	
		  
		{ 'name' : 'Donna Olshan', 
		  'img' : 'https://marketproof-hosting.s3.amazonaws.com/logos/olshan-logo.jpg', 
		  'focus' : "(212) 751-3300", 
		  'description' : "President of Olshan Realty, a leading boutique residential brokerage, and author of the Olshan Luxury Report.",
		  'link' : 'https://olshan.com/'},	
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},
		{ 'name' : '', 'img' : false, 'focus' : "", 'description' : ''},

		
	]
	
}

const Shareables = ({ t }) => (
  <Container className="professional__container">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{'Shareables'}</h3>
        <h3 className="page-subhead subhead">Ready-made content for social, newsletters and more</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} className={'mb-3'}>
       	<TrialAlert />        
      </Col>
    </Row>
     <Row>
      <ShareableContent title={'Instagram'} subhead={'Connect with expert analysts and firms'}  label={'2 New'}  professionals={professionals.analysis} />
    </Row>
     <Row>
      <ShareableContent title={'Newsletter'} subhead={'Connect with legal professionls and firms'}  label={'3 New'}  professionals={professionals.legal}/>
    </Row>
    <Row>
      <ShareableContent title={'LinkedIn'} subhead={'Connect with mortgage professionals and firms'} label={'2 New'} professionals={professionals.mortgage} />
    </Row>   
    <Row>
      <ShareableContent title={'Reports'} subhead={'Connect with title professionls and firms'}  label={'2 New'}  professionals={professionals.title}/>
    </Row>
   
    <Row>
      <Col md={12}>
        <h3 className="page-title"></h3>
        <h3 className="page-subhead subhead"></h3>
      </Col>
    </Row>
  </Container>
);

export default Shareables;
