import React, { Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {Card, CardBody, Row, Col } from 'reactstrap';
import moment from 'moment';
import { 
	_capitalizeText,
	_getBuildingURL,
	_truncateWords,
  _getDefaultBuildingPhoto
} from "../../../../../../shared/helpers/utils";

import Collapse from '../../../../../../shared/components/Collapse';


const TimeLineIcon = ({ type, date }) => {
  let Icon;

  switch (type) {
    case 'new-york-yimby':
      Icon = <span className="">NYY</span>;
      break;
    case 'the-new-york-post':
      Icon = <div className="timeline__icon"><img src={`${process.env.PUBLIC_URL}/img/publisher/the-new-york-post.png`} alt="img" /></div>;
      break;
    case 'file':
      Icon = <span className="lnr lnr-file-add" />;
      break;
    default:
      Icon = <span className=""></span>
      break;
  }

  return (
    <div className={`timeline__icon ${type}`}>
      {Icon}
    </div>
  );
};

const _clickNews = (e, item, history) => {
	 e.stopPropagation();
	 window.open(item.external_url);
/*
	 const buildingURL = _getBuildingURL(item, 'building') + "/tab/news";
	 history.push(buildingURL);
*/
	
	
}

const _handleBuildingClick = (e, item, history) => {
	 e.stopPropagation();
	 const buildingURL = _getBuildingURL(item, 'building');
	 //history.push(buildingURL);
	  window.open(buildingURL);
};

const _showExcerpt = (item) => {
	const content = item.content.replace(/(<([^>]+)>)/ig," ").replace(/[^\w\s\,\'\’\-\.]/gi, " ");
	
	return (
	      <p>      
	      	{item.content && _truncateWords(content, 25).trim()} <a className="timeline__link" onClick={(e) => _clickNews(e, item)}>Read More</a>   
	      </p>
    )
}

TimeLineIcon.propTypes = {
  type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
  type: '',
};

const NewsItem = ({
  type, img, title, date, children, item, history
}) => (
  <Card>
    
    <CardBody className="news__item" onClick={(e) => _handleBuildingClick(e, item, history)} >
     <Row>
     	 {item.main_photo && item.event_tags.indexOf('recorded-sale')<0 ?     	 	     
	      <Col xs={12} sm={12} md={5} lg={5}>
	      	<div className="timeline__image" style={{ backgroundImage: `url(${item.main_photo}), url(${_getDefaultBuildingPhoto(item)})` }} />
	      </Col>
	     :
         <Col xs={12} sm={12} md={5} lg={5}>
           <div className="timeline__image timeline__clickable" style={{ backgroundImage: `url(${_getDefaultBuildingPhoto(item)})` }} onClick={(e) => _clickNews(e, item)} />
         </Col>
        }
	      <Col xs={ 12 } sm={ 12 } md={ 7 } lg={ 7 }>
	      	  <h4 className="subhead timeline__date">{moment(item.time).fromNow()}</h4>
		      <h4 className="timeline__title" onClick={(e) => _clickNews(e, item, history)}>{_truncateWords(item.title, 7)}</h4>
		      <div className="timeline__publisher">{_truncateWords(_capitalizeText(item.company_slugs[0].replace(/-/g, ' ')),6)}</div>
		      <div className="timeline__location" onClick={(e) => _handleBuildingClick(e, item, history)}>
			      <span >{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(item.display_full_street_address)}</span>
			     
		      </div>
		      {_showExcerpt(item)}
		  </Col>
	  </Row>
    </CardBody>
  </Card>
);

NewsItem.propTypes = {
  type: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.element.isRequired,
};

NewsItem.defaultProps = {
  type: '',
  img: '',
  title: '',
  date: '',
};

export default withRouter(NewsItem);
