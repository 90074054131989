/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/AlarmPlusIcon';
import Panel from './../../../../../shared/components/Panel';
import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
} from "../../../../../shared/helpers/utils";

const buildingUrl = process.env.API_ROOT + '/query/';
const getRecentlyAdded = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
	
		var q = "offering_verified_status:(\"active\")";
		
		var fq = [
			"deleted:false",
			"offering_verified_active_date:[NOW-6MONTHS TO NOW]",

		];

	

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=30",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: null,
				wt: "json",
				rows: 0,
				"json.facet": {
					total_units: "sum(inventory_total)",
					total_unsold: "sum(inventory_unsold)"
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
};

class RecentlyAdded extends PureComponent {
	
  constructor(props) {
    super(props);
    this.state = {
       data : false
    };
  }
  componentDidMount() {
  	getRecentlyAdded().then(res => { 
		  this.setState( { data : res });
	});
  }
  
   render(){
	   const { data } = this.state;
	   if(!data) return null;
  
		return (
			   <Col md={12} xl={4} lg={4} xs={12}>
			    <Card>
			        <CardBody className="dashboard__card-widget card__link">
			        <Link to={`/buildings?offering_verified_active_date=recently-added`}>
				      <div className="card__title">
				          <h5 className="bold-text">{`Recently Added`}</h5>
				      </div>
				      <div className="mobile-app-widget">
				          <div className="mobile-app-widget__top-line">
				            <p className="mobile-app-widget__total-stat">{_formatNumber(data.facets.total_units)}</p>
				            <Icon className="dashboard__trend-icon" />
				          </div>
				          <div className="mobile-app-widget__title">
				            <h5>{'Units'}</h5>
				          </div>
				          <p className="progress__label"><span style={{ color:"#9600BF" }}>{data.numFound}</span> <span className="small-text light-text">Buildings</span></p> 
				          {/*<div className="progress-wrap progress-wrap--small
				          progress-wrap--turquoise-gradient progress-wrap--label-top"
				          >
				            <Progress value={62}>
				            	<div style={{ position:"absolute", right:0, fontSize:"10px", bottom:"10px", color:"#4ce1b6" }}>62%</div>
				            		<p className="progress__label">8,850 <span className="small-text light-text">Unsold</span></p> 
				            </Progress>
				          </div>*/}
			          </div>
			          </Link>
					</CardBody>
			    </Card>
			  </Col>


		  )
	   
   }
  
};

export default RecentlyAdded;
