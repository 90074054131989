import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, ButtonToolbar
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import BuildingIcons from '../../../../../shared/components/BuildingIcons';
import Slider from 'react-slick';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleIcon';
import MainIcon from 'mdi-react/HomeCityOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/MagnifyIcon';
import moment from "moment";
import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import {
	buildingFL,
	propertyFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl,
	BuildingPhoto,
	BuildingStageBadge,
	BuildingCurrentStatus,
	_replaceAbbreviations
} from "../../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const apiURL = process.env.API_ROOT;
const styles = theme => ({
	  root: {
	    display: 'flex',
	    flexDirection: 'column',
	    //marginLeft:"-8px",
	    marginBottom:"15px",
	    alignItems: 'left',
	    '& > *': {
		   
	    },
	  },
	  button:{
		'&.selected' :{
		    background:"#D1D6E0"
	    },
	    fontSize:11,
	    width:90
	  }
	});
const EmptyList = ({title, type}) => {
	return (
		<Card>
		<CardBody style={{padding:15}}>
		<Table responsive className="table-striped trend-buildings">
		  <tbody>
		  <tr>
			  <th colSpan={2}><h5 className="bold-text">{title}</h5></th>
			  
		  </tr>
		  {type === "properties" ?
		  <tr>
			  <td colSpan={2}>
			  <div className="light-text">No unit viewed yet</div>	
			  <br/>			  
				<Link to="/inventory">
								 
					 <div>
						 <span className="link-text">Search Units &raquo;</span>
					 </div>
				 </Link>
			  </td>
			</tr>
			:
			<tr>
			  <td colSpan={2}>
			  <div className="light-text">No building viewed yet</div>	
			  <br/>			  
				<Link to="/building-database/sale/sponsor">
								 
					 <div>
						 <span className="link-text">Search Buildings &raquo;</span>
					 </div>
				 </Link>
			  </td>
			</tr>
		}
		</tbody>
		</Table>
		
		</CardBody>
		</Card>	 
	
	)
}	
const ShowBuildings = ({data, title, color, period}) => {
	
	if(data === false){
		return (
			 <EmptyList title={title} type={"buildings"}/>
		 );
	}
	
	
	return (
		<Card>
		<CardBody style={{padding:15}}>
		 
	    <Table responsive className="table-striped trend-buildings">
	      <tbody>
	      <tr>
	      	<th colSpan={2}><h5 className="bold-text">{title}</h5></th>
	      	{!isMobile &&   <th className="views"><h5 className="bold-text"></h5></th>}
	      </tr>
	      {data && Array.isArray(data) && data.map((bldg, i) => {
		      
		        const item = bldg.building ? bldg.building : false;
		        
		        if(!item) return null;
		      	const buildingNameOrAdr = item.name 
			      ? _capitalizeText(item.name)
			      : item.display_full_street_address
			        ? _capitalizeText(item.display_full_street_address)
			        : _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building_slugs');
		      return (
			 
				      <tr key={i} style={{border:0,cursor:"pointer"}}>
				      	<td className="photo">
				  			<Link to={unitURL}>
				  			<BuildingPhoto item={item} />
				  			</Link>	
						</td>      				       	
						<td>
							<Link to={unitURL}>
								<div>{buildingNameOrAdr} <BuildingStageBadge item={item} small /></div>
								<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}
								 {/*_capitalizeText(item.borough)*/}
								 </span>
								<br/> 	
								<span className="light-text small-text" style={{color:"#858AA1"}}>{item.building_status == 'resale' && item.number_of_buildings > 1 && item.tax_assessed_total_units > 1 ?  _formatNumber(item.tax_assessed_total_units) :  _formatNumber(item.total_units)} Total Units</span>
										
							</Link>	
							
						</td>  
						{!isMobile && 
						<td className="views">						
				  				<span className="light-text small-text"  style={{color:"#858AA1"}}>  {_capitalizeText(moment(bldg.created_timestamp).fromNow())}</span>
						</td>
						}  
			          </tr>
			      
			    )
		      
	      })}
	        
	       
	      </tbody>
	    </Table>
	    
		</CardBody>
		</Card>

    )
}

const ShowUnits = ({data, title, color}) => {
	
	if(data === false){
		return (
		 	<EmptyList title={title} type={"properties"}/>
	 	);
	}
	return (
		<Card>
		<CardBody style={{padding:15}}>
		
		<Table responsive className="table-striped">
		  <tbody>
			  <tr>
				<th colSpan={2}><h5 className="bold-text">{title}</h5></th>
				{!isMobile &&   <th className="views"><h5 className="bold-text"></h5></th> }
			</tr>
			
		  {data && Array.isArray(data) && data.filter(item => item.property).length>0 && data.map((property, i) => {
			  
			  const item = property.property ? property.property : false;
			  if(!item) return null;
				  const buildingNameOrAdr = item.building_name 
				  ? _capitalizeText(item.building_name)
				  : item.display_full_street_address
					? _capitalizeText(item.display_full_street_address)
					: _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
			  return (
			 
					  <tr key={i} style={{border:0,cursor:"pointer"}}>
						<td className="photo">
							   <Link to={unitURL}>
							   <UnitCurrentStatus unit={item} small />
							   </Link>	
						 </td>     				       	
						<td>
							<Link to={unitURL}>
								<div>{buildingNameOrAdr}, {item.address_2_normalized ? item.address_2_normalized.toUpperCase().replace("APT", "Apt") : ""}</div>
								<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}</span>
								<br/>
								{item.baths>0 ?
								 <Fragment>
									<span className="light-text small-text">{item.bedrooms > -1 ? item.bedrooms === 0 ? 'Studio' : item.bedrooms+' Bed' : '--'}</span>
									{` | `}<span className="light-text small-text">{item.baths > -1 ? item.baths+' Bath' : '--'}</span>
									
								</Fragment>
								 
								:
								 item.sq_ft>0 ? 
									<span className="light-text small-text">
										{_formatNumber(item.sq_ft)} sq ft
									</span>
								:
									<span className="light-text small-text">--</span>
								}
							</Link>	
							
						</td> 
						{!isMobile &&   
						<td className="views">
						
								<span className="light-text small-text"  style={{color:"#858AA1"}}>  {_capitalizeText(moment(property.created_timestamp).fromNow())}</span>
			
							  
						</td>
						}	
					  </tr>
				  
				)
			  
		  })}
			
		   
		  </tbody>
		</Table>
		
		</CardBody>
		</Card>

	)
}

class RecentHistory extends PureComponent {
	constructor(props) {
	    super(props);
	    this.state = {
	      fetchInProgress: false,
	      buildings: null,
		  properties: null,
	      period: "today",
		  limit : isMobile ? 3 : 5
	    };
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.period !== prevState.period){
			this._getActivity();
		}
		
	}

	componentDidMount() {
		this._getActivity();
		
	  	
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
    }
	_getActivity = async () => {
			
		const { limit } = this.state;
		const { type, user } = this.props;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		
		
		
		
		const queryData = [
			{
				"$match": {
					"user_key": { "$in" : [user.id, user.id.toString().toLowerCase()] }
				}
			},
		
			{
				"$facet": {
					"buildings": [{
							"$match": {
								"event_type": "buildingView"								
							}
						},
						
						{
							"$group": {
								"_id": {
									"building": "$building.key"
								},
								"created_timestamp" : { "$max": "$created_timestamp" },
								"count": {
									"$sum": 1
								}
							}
						
						},
						{
							"$sort": {
								"created_timestamp": -1
							}
						},
						{
							"$limit": 10
						}
					],
					"properties": [{
							"$match": {
								"event_type": "unitView"								
							}
						},
						
						{
							"$group": {
								"_id": {
									"property": "$property.key"
								},
								"created_timestamp" : { "$max": "$created_timestamp" },
								"count": {
									"$sum": 1
								}
							}
						
						},
						{
							"$sort": {
								"created_timestamp": -1
							}
						},
						{
							"$limit": 10
						}
					]
				}
			}
		
		
		]
				 
		let axiosData = {
			url: apiURL + "/buyerslist/event/aggregate?cache=refresh",
			method: "post",
			query: queryData
		};

		let response = await _axiosCall(axiosData)
		.then(res => {
			
			const buildingIDs = [];
			const propertyIDs = [];
			if(res && res[0]){
				
				if(res[0]["buildings"] && res[0]["buildings"].length>0){
					res[0]["buildings"].map(building => {
						 buildingIDs.push(building._id.building);
					})
					this._getBuildings(buildingIDs, res[0]);
				}else{
					this.setState({ buildings : false, fetchInProgress : false, buildingsFetchInProgress: false });
				}
				if(res[0]["properties"] && res[0]["properties"].length>0){
					res[0]["properties"].map(property => {
					 	propertyIDs.push(property._id.property);
					})
					this._getProperties(propertyIDs, res[0]);
				}else{
					this.setState({ properties : false, fetchInProgress : false, buildingsFetchInProgress: false });
				}
				
				
				
			}
		})
		.catch(error => {
			console.log("error: " + error);
		});
		
		
  };
  
  _getBuildings = async (_buildingIDs, resData) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const { limit } = this.state;
			
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var q = "key:("+_buildingIDs.join(" ")+")";
		
		var fq = [
			"deleted:false"			
		];


		let axiosData = {
			url: apiURL + "/query/we3-buildings?cache=60",
			method: "post",
			query: {
				q: q,
				fq: fq,
				fl: buildingFL,
				wt: "json",
				rows: 50
			}
		};
		

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				//this._setBuyersList(res);
				if(res && res.docs){
					res.docs.map(bldg => {
					
						
						if(resData['buildings'].filter(b => b._id.building == bldg.key).length>0){
							resData['buildings'].filter(b => b._id.building == bldg.key)[0]['building'] = bldg;
						}
						
					});
					this.setState({ buildings : resData['buildings'].filter(b => b.building).slice(0, limit), fetchInProgress : false, buildingsFetchInProgress: false });
				}else{
					this.setState({ buildings : resData['buildings'].slice(0, limit), fetchInProgress : false, buildingsFetchInProgress: false });
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
			

		// console.log(formattedData)
		return formattedData;
  };
  
  _getProperties = async (_buildingIDs, resData) => {
		  const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		  const { limit } = this.state;
			  
		  if (!this.state.buildingsFetchInProgress) {
			  this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		  }
		  var q = "key:("+_buildingIDs.join(" ")+")";
		  
		  var fq = [
			  "deleted:false"			
		  ];
  
  
		  let axiosData = {
			  url: apiURL + "/query/we3-properties?cache=60",
			  method: "post",
			  query: {
				  q: q,
				  fq: fq,
				  fl: propertyFL,
				  wt: "json",
				  rows: 50
			  }
		  };
		  
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  //this._setBuyersList(res);
				  if(res && res.docs){
					  res.docs.map(property => {
					  
						  
						  if(resData['properties'].filter(b => b._id.property == property.key).length>0){
							  resData['properties'].filter(b => b._id.property == property.key)[0]['property'] = property;
						  }
						  
					  });
					  
					  this.setState({ properties : resData['properties'].filter(p => p.property).slice(0, limit), fetchInProgress : false, buildingsFetchInProgress: false });
				  }else{
					  this.setState({ properties : resData['properties'].slice(0, limit), fetchInProgress : false, buildingsFetchInProgress: false });
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
			  
  
		  // console.log(formattedData)
		  return formattedData;
	};
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  setPeriod = (period) => {
	  this.setState({period:period})
  }
  
  render(){
	const { savedUnits, totalCount, period, buildings, properties, fetchInProgress, buildingsFetchInProgress } = this.state;
	const { classes, type } = this.props;
	//if(!data) return null;
	
	if(buildings === false && properties === false) return null;
	return (
		<Card>
		   <CardBody>
			   <Row>
			   		<Col md={12} className={"mb-2"}>
			   			<div className="card__title">
			              <h5 className="bold-text">{'My History (Recently Viewed)'}</h5>
			              
			            </div>
			   			{/*<div className={classes.root}>
			   			  	
					      <ButtonGroup size="small" aria-label="small button group" >							        
					      	<Tooltip title="Most viewed buildings today" aria-label="Most viewed buildings today">
					        	<Button onClick={()=> this.setPeriod("today")} className={period === "today" ? classNames(classes.button, "selected") : classNames(classes.button)}>Today</Button>
					        </Tooltip>
					        <Tooltip title="Most viewed buildings this week" aria-label="Most viewed buildings this week">
					       	 	<Button onClick={()=> this.setPeriod("this-week")} className={period === "this-week" ? classNames(classes.button, "selected") : classNames(classes.button)}>This Week</Button>
					       	</Tooltip>	
					         <Tooltip title="Most viewed buildings this month" aria-label="Most viewed buildings this month">
					       	 	<Button onClick={()=> this.setPeriod("this-month")} className={period === "this-month" ? classNames(classes.button, "selected") : classNames(classes.button)}>This Month</Button>
					       	</Tooltip>
					      </ButtonGroup>
					  </div>*/}
			   		</Col>
			   </Row>
			   
			   {(fetchInProgress || buildingsFetchInProgress) ?
				   <div>
				   <LinearLoading />
				   <div style={{height:357}}>
				   	
				   </div>
				   </div>
				   
				   :
				   <Row>
					  <Col md={6} className={"mb-2"}>
						  <ShowBuildings data={buildings} title={"Buildings"} color={"#4ce1b6"} period={period} />
					  </Col>
					  <Col md={6} className={"mb-2"}>
						  <ShowUnits data={properties} title={"Units"} color={"#FFD23F"} period={period} />
					  </Col>
					 					   	  
					   
				  </Row>
				   
				
			   }
			   
			      
			   
			    {/*<div className="unit__load text-right semi-bold-text">			   		
				    <Tooltip title="See More">
				        <Link to={`/building-database/sale/sponsor?building_stage=sponsor,resale`}>See All History &raquo;</Link>			 
				  	</Tooltip>				
			    </div>*/}
			 </CardBody>
		</Card>
	
	)
	
   }
};

RecentHistory.propTypes = {
  t: PropTypes.func.isRequired,
};


export default withStyles(styles,  { withTheme: true })(RecentHistory);
