// import React from "react";
// import moment from "moment";
import axios from 'axios';
import{
	_axiosCall
} from '../../../helpers/apicalls';
import{
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage
} from '../../../helpers/utils';

import buyersListActions from '../../../../redux/actions/buyersListActions';
import { Publisher } from "./../../../pubsub/publisher.js";


export const  _getUserSSO = async (ssoToken, props, setUserProfile) => {

  const { authState, authService, dispatch } = props;
  
  const ssoOrg = _getSSOOrg();
  console.log(ssoOrg);
  if(ssoOrg == "MPSSO"){
	  return _getMPUserSSO(ssoToken, props, setUserProfile);
  }else if(ssoOrg == "REALPLUS"){
	  return _getRealPlusUserSSO(ssoToken, props, setUserProfile);
  }
	  
  let headers = {
	"Authorization" : `Bearer ${ssoToken.access_token}`,
  }
  let userData = {
	  url: `${process.env.REALOGY_BASE_URL}userinfo`,
	  method: "get",
	  headers:headers
  };
  const allowedEmails = ["previewrlgy@gmail.com", "bonita.lau@cbhome.com"];
  
  return _axiosCall(userData)
	.then(res => {
	console.log("_getUserData res back ,", res);
	if(res){
	  let oktaUser = Object.assign({}, res);
	  oktaUser['authType'] = 'sso';
	  oktaUser['role'] = ['complete-199'];
	  oktaUser['subscriptionPlan'] = 'custom-sso';
	  oktaUser['id']= res.sub;
	  oktaUser['firstName'] = res.given_name;
	  oktaUser['lastName'] = res.family_name;
	  oktaUser['title'] = "Real Estate Professional";
	  oktaUser['company'] = "Coldwell Banker Warburg";
	  oktaUser['subscriptionId'] = 'custom-sso';
	  oktaUser['ssoOrganiztion'] = 'cbwarburg';
	//console.log(oktaUser) 
	  //if((oktaUser.email && oktaUser.email.indexOf("cbwarburg.com")>=0)){
	  		_getUser(oktaUser).then(user => {
		  		if(user){
			  		
			  		const combinedUser = Object.assign({}, oktaUser, user);
			  		dispatch(setUserProfile(combinedUser));
			  		dispatch(buyersListActions.setUserData(combinedUser));
			  		Publisher.publish("login", combinedUser);
		  		}else{
					_checkWarburgAuthorization(oktaUser).then(result => {
						
						if(result && result.authentication === true || (oktaUser.email && (oktaUser.email.toLowerCase().indexOf("cbrealty.com")>=0) || allowedEmails.indexOf(oktaUser.email.toLowerCase())>=0)){
							_createUser(oktaUser).then(user => {
								  const combinedUser = Object.assign({}, oktaUser, user);
								  dispatch(setUserProfile(combinedUser));
								  dispatch(buyersListActions.setUserData(combinedUser));
								  Publisher.publish("login", combinedUser);
							})
						}else{
							_deleteSSOToken();
							window.location.href = "/cbwarburg?error=unauthorized";
						}
						
						
					}).catch(error =>{
						console.log(error);
						dispatch(setUserProfile(null));
						_deleteSSOToken();
						window.location.href = "/cbwarburg?error=unauthorized";
					});
			  		
		  		}
	  		}).catch(error =>{
				  console.log(error);
				 dispatch(setUserProfile(null));
				 _deleteSSOToken();
				  window.location.href = "/cbwarburg?error=unauthorized";
			});
  	//   }else{
		// 	window.location.href = "/login";
	  // }
	}else{
			dispatch(setUserProfile(null));
			_deleteSSOToken();
			window.location.href = "/cbwarburg?error=unauthorized";
	}
	

  })
  .catch(error => {
	
	if(error && error.status && error.status == 401){
		console.log("error 401")
		dispatch(setUserProfile(null));
		_deleteSSOToken();
	}
	console.log(error)
	
  });
}

export const  _getMPUserSSO = async (ssoToken, props, setUserProfile) => {

  const { authState, authService, dispatch } = props;
	  
  let headers = {
	"Authorization" : `Bearer ${ssoToken.access_token}`,
  }
  let userData = {
	  url: `${process.env.MP_BASE_URL}userinfo`,
	  method: "get",
	  headers:headers
  };
  
  
  return _axiosCall(userData)
	.then(res => {
	console.log("_getUserData res back ,", res);
	if(res){
	
	  let oktaUser = Object.assign({}, res);
	  		oktaUser['id']= res.sub;
			oktaUser['authType'] = 'sso';
			oktaUser['ssoType'] = 'MPSSO';
			oktaUser['avatar'] = oktaUser['avatar'] ? oktaUser['avatar'] : "https://buyerslist.s3.amazonaws.com/account.png";
			if(res.AllProfile){
				oktaUser = Object.assign({}, oktaUser, res.AllProfile);
			}
	  		_getUser(oktaUser).then(user => {
				  console.log(user)
				  if(user){
					  
					  const combinedUser = Object.assign({}, user, oktaUser);
					  dispatch(setUserProfile(combinedUser));
					  dispatch(buyersListActions.setUserData(combinedUser));
					  Publisher.publish("login", combinedUser);
				  }else{
					dispatch(setUserProfile(oktaUser));
					Publisher.publish("login", (oktaUser));
					//_deleteSSOToken();
					  
				  }
			  }).catch(error =>{
				  console.log(error);
				  dispatch(setUserProfile(null));
				  _deleteSSOToken();
			});
	}else{
			dispatch(setUserProfile(null));
			_deleteSSOToken();
	}
	

  })
  .catch(error => {
	
	if(error && error.status && error.status == 401){
		console.log("error 401")
		dispatch(setUserProfile(null));
		_deleteSSOToken();
	}
	console.log(error)
	
  });
}

export const  _getRealPlusUserSSO = async (ssoToken, props, setUserProfile) => {

  const { authState, authService, dispatch } = props;
	  
  let headers = {
	"Authorization" : `Bearer ${ssoToken.access_token}`,
  }
  let userData = {
	  url: `${process.env.REALPLUS_BASE_URL}userinfo`,
	  method: "get",
	  headers:headers
  };
  
  
  return _axiosCall(userData)
	  .then(res => {
	  console.log("_getUserData res back ,", res);
	  if(res){
		let oktaUser = Object.assign({}, res);
		let nameParts = res.name.split(' ');
		let first_name = nameParts[0]
		let last_name = nameParts[nameParts.length-1]
		oktaUser['authType'] = 'sso';
		oktaUser['role'] = ['complete-199'];
		oktaUser['subscriptionPlan'] = 'custom-sso';
		oktaUser['id']= res.sub;
		oktaUser['firstName'] = first_name;
		oktaUser['lastName'] = last_name;
		oktaUser['title'] = "Real Estate Professional";
		oktaUser['subscriptionId'] = 'custom-sso';
		oktaUser['ssoOrganiztion'] = 'realplus';
	  //console.log(oktaUser) 
		//if((oktaUser.email && oktaUser.email.indexOf("cbwarburg.com")>=0)){
				_getUser(oktaUser).then(user => {
					if(user){
						
						const combinedUser = Object.assign({}, oktaUser, user);
						dispatch(setUserProfile(combinedUser));
						dispatch(buyersListActions.setUserData(combinedUser));
						Publisher.publish("login", combinedUser);
					}else{
					 
					  _createUser(oktaUser).then(user => {
							const combinedUser = Object.assign({}, oktaUser, user);
							dispatch(setUserProfile(combinedUser));
							dispatch(buyersListActions.setUserData(combinedUser));
							Publisher.publish("login", combinedUser);
					  })
						 	
					}
				}).catch(error =>{
					console.log(error);
				   dispatch(setUserProfile(null));
				   _deleteSSOToken();
					window.location.href = "/realplus?error=unauthorized";
			  });
		//   }else{
		  // 	window.location.href = "/login";
		// }
	  }else{
			  dispatch(setUserProfile(null));
			  _deleteSSOToken();
			  window.location.href = "/realplus?error=unauthorized";
	  }
	  
  
	})
	.catch(error => {
	  
	  if(error && error.status && error.status == 401){
		  console.log("error 401")
		  dispatch(setUserProfile(null));
		  _deleteSSOToken();
	  }
	  console.log(error)
	  
	});
}

export const _getUser = async (user) => {
		
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/userdata/${user.id}`,
			method: "get"
		};
		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.length>0){
					return res[0];
				}else{
					return false;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
};

export const _createUser = async (user) => {
		
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/createssouser/${user.id}`,
			method: "post",
			query: user
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				console.log('Data', res);
				if(res && res.length>0){
					return res[0];
				}else{
					return false;
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
};


export const  _doUserSSOLogout = async (props, setUserProfile) => {
  const { authState, authService, dispatch } = props;
  const ssoOrg = _getSSOOrg();
 
  if(ssoOrg == "MPSSO"){
	return _doUserMPSSOLogout(props, setUserProfile);
  }else if(ssoOrg == "REALPLUS"){
	return _doUserRealPlusSSOLogout(props, setUserProfile);
  }
	
  const ssoToken = _getSSOToken();
  if(ssoToken && ssoToken.id_token){
	  const logURL = `${process.env.REALOGY_BASE_URL}logout?client_id=${process.env.REALOGY_CLIENT_ID}&id_token_hint=${ssoToken.id_token}&post_logout_redirect_uri=${process.env.REALOGY_LOGOUT_REDIRECT_URI}`;
	  _deleteSSOToken();
	  
	  Publisher.publish("logout", {});
	  window.location.href = logURL;
	  //dispatch(setUserProfile(null));
	  return false;
  }
}

export const  _doUserRealPlusSSOLogout = async (props, setUserProfile) => {
  const { authState, authService, dispatch } = props;
  const ssoToken = _getSSOToken();
  if(ssoToken && ssoToken.id_token){
	  const logURL = `${process.env.REALPLUS_BASE_URL}logout?client_id=${process.env.REALPLUS_CLIENT_ID}&id_token_hint=${ssoToken.id_token}&post_logout_redirect_uri=${process.env.REALPLUS_LOGOUT_REDIRECT_URI}`;
	  _deleteSSOToken();
	  
	  Publisher.publish("logout", {});
	  window.location.href = logURL;
	  //dispatch(setUserProfile(null));
	  return false;
  }
}

export const  _doUserMPSSOLogout = async (props, setUserProfile) => {
  const { authState, authService, dispatch } = props;
  const ssoToken = _getSSOToken();
  if(ssoToken && ssoToken.id_token){
	  const logURL = `${process.env.MP_BASE_URL}logout?client_id=${process.env.MP_CLIENT_ID}&id_token_hint=${ssoToken.id_token}&post_logout_redirect_uri=${process.env.MP_LOGOUT_REDIRECT_URI}`;
	  _deleteSSOToken();
	  
	  Publisher.publish("logout", {});
	  window.location.href = logURL;
	  //dispatch(setUserProfile(null));
	  return false;
  }
}

export const _checkWarburgAuthorization = (user) => {
	return new Promise((resolve, reject) => {
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/realogy/cbwarburg`,
			method: "get"
		};
		
		let formattedData = _axiosCall(axiosData)
			.then(res => {
				//console.log(user, res)
				if(user && user.id && res && res.agents && res.agents.filter(agent => agent && agent.companyOfficeAssociation && agent.companyOfficeAssociation.agentOktaIds && agent.companyOfficeAssociation.agentOktaIds.indexOf(user.id)>=0).length>0){
					resolve({authentication:true, agent: user})
					
				}
				resolve({authentication:false, agent: user});
				
			})
			.catch(error => {
				reject(Error(error));
			});
		
		// console.log(formattedData)
		return formattedData;
	  });

};

export const _setSSOToken = (ssoToken, ssoOrg) => {
	ssoOrg = ssoOrg ? ssoOrg : 'CBWarburg';
	deleteLocalStorage("marketproof", "_ssoTokenType");
	setLocalStorage("marketproof", "_ssoTokenType", ssoOrg);
	return setLocalStorage(ssoOrg, '_SSOToken', ssoToken);
}

export const _deleteSSOToken = () => {
	const ssoOrg = getLocalStorage("marketproof", "_ssoTokenType");
	deleteLocalStorage(ssoOrg, '_SSOToken');
	return deleteLocalStorage("marketproof", "_ssoTokenType");
}
export const _getSSOToken = () => {

	const ssoOrg = getLocalStorage("marketproof", "_ssoTokenType");
	return getLocalStorage(ssoOrg, '_SSOToken')
}

export const _getSSOOrg = () => {
	return getLocalStorage("marketproof", "_ssoTokenType");
}

export const _doSSOLogout = (props, setUserProfile) => {
	const ssoOrg = getLocalStorage("marketproof", "_ssoTokenType");
	return _doUserSSOLogout(props, setUserProfile, ssoOrg)
}

