import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BuyerLeadList from './components/BuyerLeadList';

const BuyerLeads = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{`Marketproof New Development Leads`}</h3>
        <h3 className="page-subhead subhead">Direct web leads from your building on Marketproof New Development </h3>
      </Col>
    </Row>
	<Row>
      <BuyerLeadList />
    </Row>
  </Container>
);

export default BuyerLeads;