import React, { PureComponent } from 'react';
import { connect } from 'react-redux';


import { Col, Container, Row } from 'reactstrap';
import ProductCard from './components/ProfileCard';
import RelatedBuildings from './components/RelatedBuildings';
import ProfileTabs from './components/ProfileTabs';
import BuildingAmenities from './components/BuildingAmenities';
import OfferingHistory from './components/OfferingHistory';

import ShowMap from './../../../../shared/components/map';


import{
	_axiosCall,
} from './../../../../shared/helpers/apicalls';

const apiUrl = process.env.API_ROOT + '/event/';

class Project extends PureComponent {
	
	state = {
      eventData: false,
      fetchInProgress: false,
	}
	
	componentDidMount() {
		this._isMounted = true;
		this._getEventData();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {		
				 
    }
    
    _getEventData = () => {
     // console.log('_getBuildingData called', this.state)
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  
    
	  let eventData = {
			url: apiUrl + this.props.buildingRes.slugs[0],
			method: "get"
	  };
	  
	  _axiosCall(eventData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    if(this._isMounted && res) {
	      this.setState({
	      	eventData: res,
	      	fetchInProgress: false
	      })
	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}
    
    render(){
		
		const { eventData, fetchInProgress } = this.state;
		
		if(!eventData) return null;
		
		return (
		  <Container>
		    <Row>
		      <ProductCard {...this.props} eventData={eventData} />
		    </Row>
		         <Row>
		      <Col md={12}>
		        <h3 className="section-title section-title--not-last"></h3>
		        <h3 className="section-title section-title--not-last subhead"> </h3>
		       
		       
		    	<hr/>
		    	<Row>
		         <Col xl={12} lg={12} >
		           <ShowMap item={this.props.buildingRes} height={`360px`} />
		         </Col>
				 </Row>
			  </Col>
			</Row>
		   </Container>
		)
	}
};

export default connect(state => ({
  user: state.user,
}))(Project);
