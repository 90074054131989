import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import SpeedometerIcon from 'mdi-react/ContrastCircleIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_formatNumber
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';




const calculateBarData = (transaction) => {	
	
	const totalSold = transaction.percent_financed ? transaction.percent_financed : 0;
	const totalAvail = 	100 - totalSold;
	return [{ value: totalSold, fill: getDefaultChartColors[0] },
  				{ value: totalAvail, fill: '#DBE0E8' }];
	//Sales Velocity Sales Started: {moment(props.buildingRes.sales_start_date).format("MM/DD/YYYY")}
}


const PercentFinanced = (props, { t }) => {
	const { buildingRes, location, transactions } = props;	
	const base_url = location.pathname.replace('/tab/overview', '') + '/tab/financing';
	
	const transaction = transactions.filter(item => item.price > 0)[0];
	
	return (
		  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
		    <Card>
		    <Link to={`${base_url}`}>
		      <CardBody className="dashboard__health-chart-card card__link">
		        <div className="card__title">
		          <h5 className="bold-text card__title-center">{`Percent Financed`}</h5>
		        </div>
		        <div className="dashboard__health-chart"> 
		          <ResponsiveContainer height={180}>
		            <PieChart>
		              <Pie data={calculateBarData(transaction)} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
		            </PieChart>
		          </ResponsiveContainer>
		          <div className="dashboard__health-chart-info">
		            <SpeedometerIcon style={{ fill: getDefaultChartColors[0] }} />
		            <p className="dashboard__health-chart-number">{transaction.percent_financed ? transaction.percent_financed.toFixed(0) + '%' : '--'}</p>
		            <p className="dashboard__health-chart-units">{transaction.financed_amt ? '$' + _formatNumber(transaction.financed_amt) : null}</p>
		          </div>
		        </div>
		        <p className="dashboard__goal">{transaction.percent_financed > 0 ? 
			        <Fragment>Based On: {transaction.sponsored_sale? 'Sponsor sale' : 'Last resale'} {moment(transaction.close_date_formatted).fromNow()}</Fragment>
			    	:
			    	<Fragment>No financing information available for {transaction.sponsored_sale? 'sponsor sale' : 'last resale'}</Fragment>   
		    	} 
		    	</p>
		      </CardBody>
		      </Link>
		    </Card>
		  </Col>
	)
};

export default PercentFinanced;
