import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { connect } from 'react-redux';

import {
  Row, Col, Container,
} from 'reactstrap';
import BuyerAgentIcon from 'mdi-react/AccountOutlineIcon';
import SalesGalleryIcon from 'mdi-react/FloorPlanIcon';
import MediaIcon from 'mdi-react/BullhornOutlineIcon';
import EnterpriseIcon from 'mdi-react/DomainIcon';

 

import { withRouter } from "react-router-dom"
import { setUserProfile } from '../../../redux/actions/authActions';


class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      role: this.props.user && this.props.user.role ? this.props.user.role : 'agent'
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };
  
  setRole = (role) => {   
	  
	this.setState({role: role});
	const { dispatch } = this.props;
	dispatch(setUserProfile(role))		
  };
  
  

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/">Forgot a password?</a>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
            />
          </div>
        </div>
        
        <div className="form__form-group account__type">
        <hr/>
        <Container>
        	
	        <Row>
	        	<Col xl={12} className="no-padding bold-text">
	        		Select Account Type
	        	</Col>
	        	<Col md={3} xl={3} sm={6} className="no-padding">
	        		<div 
	        			className={`btn btn-outline-secondary account__btn-type ${this.state.role==='buyer' ? "selected" : ""}`} 
	        			onClick={() => this.setRole('buyer')}>
	        			<BuyerAgentIcon />
						<span className="small-text">Buyer</span>
					</div>
	        	</Col>
	        	<Col md={3} xl={3} sm={6} className="no-padding">
	        		<div 
	        			className={`btn btn-outline-secondary account__btn-type ${this.state.role==='seller' ? "selected" : ""}`} 
	        			onClick={() => this.setRole('seller')}>
	        			<SalesGalleryIcon />
	        			<span className="small-text">Seller</span>
	        		</div> 
	        	</Col>
	        	<Col md={3} xl={3} sm={6} className="no-padding">
	        		<div 
	        			className={`btn btn-outline-secondary account__btn-type ${this.state.role==='media' ? "selected" : ""}`} 
						onClick={() => this.setRole('media')}>
	        			<MediaIcon />
	        			<span className="small-text">Media</span>
	        		</div>
	        	</Col>
	        	<Col md={3} xl={3} sm={6} className="no-padding"> 
	        		<div
	        			className={`btn btn-outline-secondary account__btn-type ${this.state.role==='enterprise' ? "selected" : ""}`} 
	        			onClick={() => this.setRole('enterprise')}>
	        			<EnterpriseIcon />
	        			<span className="small-text">Enterprise</span>
	        		</div>  
	        	</Col>     
	        </Row>
        </Container>
        </div>
        
        <div className="form__form-group">
		    <Link className="account__btn btn btn-primary account__btn btn-lg" to="/dashboard">
		      Sign In
		    </Link>
		    <Link className="btn btn-outline-primary account__btn btn-lg" to="/log_in">Create Account</Link>
        </div>
        
        
        
      </form>
    );
  }
}
export default reduxForm({
  form: 'log_in_form',
})(connect(state => ({
  user: state.user,
}))(withRouter(LogInForm)));
