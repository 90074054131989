
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

export default class OktaSignInWidget extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
/*
    const oktaWidgetConfig = {
      baseUrl: `https://${process.env.OKTA_DOMAIN}`,
      authParams: {
        pkce: false
      },
      features: {
        rememberMe: false,
        selfServiceUnlock: false,
        registration: true,
        router: true
      },
      helpLinks: {
        help: "#"
      },
      logo: `${process.env.PUBLIC_URL}/img/marketproof-newdev-logo.png`,
      colors: {
		  brand: '#780F9E'
	   },
	   i18n: {
  // Overriding English properties
		  'en': {
		    'primaryauth.title': 'Sign IN',
		    'primaryauth.username.placeholder': 'Email'
		  }
	   }
    };
    if (
      this.props.resetToken !== undefined &&
      this.props.resetToken !== null &&
      this.props.resetToken.length > 0
    ) {
      oktaWidgetConfig["recoveryToken"] = this.props.resetToken;
    }
*/
    

    //this.props.oktaWidget = new OktaSignIn(oktaWidgetConfig);
    if(this.props.oktaWidget && typeof this.props.oktaWidget.remove === 'function'){
	    this.props.oktaWidget.remove();
    }
    this.props.oktaWidget.renderEl({el}, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    if(this.props.oktaWidget && typeof this.props.oktaWidget.remove === 'function'){
	    this.props.oktaWidget.remove();
    }

  }

  render() {
    return <div />;
  }
};