import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setUserProfile } from '../../../redux/actions/authActions';
import { withOktaAuth } from '@okta/okta-react';

import buyersListActions from '../../../redux/actions/buyersListActions';
import { Publisher } from "./../../../shared/pubsub/publisher.js";
import { _isPipelineSubscriber, _isNewDevSubscriber } from './../../../shared/components/payment/plans';

import{
	_capitalizeText,
	getLocalStorage
} from '../../../shared/helpers/utils';
	
import{
	_axiosCall
} from '../../../shared/helpers/apicalls';
import{
	_getUserSSO,
	_setSSOToken,
	_getSSOToken,
	_doSSOLogout
} from '../../../shared/components/auth/sso/ssoutils';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const oktaToken = process.env.OKTA_TOKEN;


class TopbarProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      fetchInProgress: false
    };
    this.logout = this.logout.bind(this);

  }
  
  componentDidMount(){
	  const { authState, authService, dispatch, dataSet, user } = this.props;
/*
	  try{
		  console.log(authState);

		  if (!authState.isPending && !authState.isAuthenticated) {
		      // When user isn't authenticated, forget any user info
		      //setUserInfo(null);
		      dispatch(setUserProfile(null));
		   } else {
			   
			  //if(!user){
				  authService.getUser().then((user) => {
		        
			        this._getUserOktaData(user);
			        if(!dataSet){
				        this._getUserMongoData(user); 
			        }
			         
			     });
				  
			  //}
		      
	
		  }
	  }catch(e){
		  console.log("Oauth Error");
	  }
*/

  }
  
  _getUserOktaData = (user) => {
     // console.log('_getBuildingData called', this.state)
     if(!user) return false;
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
      
      const { authState, authService, dispatch } = this.props;
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://${process.env.OKTA_DOMAIN}/api/v1/users/${user.sub}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      if(res){
		      this.setState({ fetchInProgress : false });
			  let oktaUser = Object.assign({}, res.profile);
			  oktaUser['id']= res.id;
		      dispatch(setUserProfile(oktaUser));
	      }
		  

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({ fetchInProgress : false });
	    dispatch(setUserProfile(user));
	  });
  }
  
    _getUserMongoData = (user) => {
  		if(!user) return false;
        if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
         }
      
        const { authState, authService, dispatch } = this.props;
      
		let axiosData = {
			url: `${process.env.API_ROOT}/buyerslist/userdata/${user.sub}`,
			method: "get"
		};

		 _axiosCall(axiosData)
			.then(res => {
				console.log('Data', res);
				if(res && res[0]){
					this.setState({ fetchInProgress : false });
					let mongoUser = Object.assign({}, res[0]);
					dispatch(buyersListActions.setUserData(mongoUser))
				}else{
					dispatch(buyersListActions.setUserData({}))
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});

   }
  
   
  componentDidUpdate(prevProps, prevState, snapshot){
	  const { authState, authService, dispatch, dataSet, user } = this.props;

	  try{
		  console.log(authState);
		  const ssoToken = _getSSOToken();
		  if (!authState.isPending && !authState.isAuthenticated && !ssoToken) {
		      // When user isn't authenticated, forget any user info
		      //setUserInfo(null);
		      dispatch(setUserProfile(null));
		   } else {
			   
			  if(!user && !ssoToken){
				  authService.getUser().then((user) => {
		        
			        this._getUserOktaData(user);
			        if(!dataSet){
				        this._getUserMongoData(user); 
			        }
			         
			     });
				  
			  }else if(ssoToken && ssoToken.access_token && !user){
					 console.log(ssoToken)
					 _getUserSSO(ssoToken, this.props, setUserProfile)
					  
				}
		      
	
		  }
	  }catch(e){
		  console.log("Oauth Error");
	  }

  }


  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
  
  toggleUser = (role) => {   
	const { dispatch } = this.props;
	dispatch(setUserProfile(role));
	this.toggle();
  };
  
  async logout() {
	const { authState, authService, dispatch, user, dataSet } = this.props;
	const ssoToken = _getSSOToken();

	if(!authState.isPending && authState.isAuthenticated){
		authService.logout('/');
		dispatch(setUserProfile(null));
		
		Publisher.publish("logout", {});
	}else if(user.authType === "sso"){
		_doSSOLogout(this.props, setUserProfile);
	}else if(ssoToken){
		_doSSOLogout(this.props, setUserProfile);
	}
  }
  
    
  render() {
    const { collapse } = this.state;
    
    const { user } = this.props;
    
    console.log(user);
    
    if (!user) return null;
    
    

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <div className="topbar__avatar-img-container">
          <div className="topbar__avatar-img" style={{backgroundImage : `url(${user.avatar}), url(https://buyerslist.s3.amazonaws.com/account.png)`}} alt="avatar" />
          </div>
          <p className="topbar__avatar-name">
          	{user.firstName} {user.lastName}
		  	<br/><span className="small-text light-text">{user.title ? _capitalizeText(user.title.replace(/-/g, ' ')) : ""}</span>
          </p>          
          <DownIcon className="topbar__icon" />
        </button>
        
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            
            <TopbarMenuLink
              title="My Account"
              icon="user"
              path="/my-account"
              onClick={this.toggle}
              />
			 
			   <div className="topbar__menu-divider" />
				<h6 className="topbar__menu-header">My Subscriptions</h6>
				{_isNewDevSubscriber(user) &&
					<TopbarMenuLink
					  title="Marketproof Pro"
					  icon="arrow-right"
					  path="/"
					  onClick={this.toggle}
					  
					  />
					
				  }
				{_isPipelineSubscriber(user) &&
				  <TopbarMenuLink
					title="Marketproof Pipeline"
					icon="arrow-right"
					path="https://pipeline.marketproof.com"
					onClick={this.toggle}
					external
					/>
				  
				}
				<div className="topbar__menu-divider" />
				<TopbarMenuLink
				  title="Homepage"
				  icon="arrow-right"
				  path="https://marketproof.com/mplogin"
				  onClick={this.toggle}
				  external
				  />
            <div className="topbar__menu-divider" />
              
              <span className="topbar__link"  onClick={this.logout}>
		        <span className={`topbar__link-icon lnr lnr-exit`} />
		        <p className="topbar__link-title">Log Out</p>
		      </span>
          </div>
        </Collapse>
      </div>
    );
  }
}


export default connect(state => ({
  user: state.user,
  dataSet : state.buyersLists.dataSet
}))(withOktaAuth(TopbarProfile))