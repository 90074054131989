import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";

import { Progress } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../shared/components/Panel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import { Tooltip } from '@material-ui/core';

import UnitListTableHead from './UnitListTableHead';

import { getDefaultChartColors } from '../../../../../shared/helpers';

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL
} from "../../../../../shared/helpers/utils";

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}


	
class UnitList extends PureComponent {
  static propTypes = {
   	t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
	this.state = {
		    order: 'asc',
		    orderBy: 'inventory_unsold',
		    selected: new Map([]),
		    page: 0,
		    rowsPerPage: 25,
			clearInput: false,
			end: 30,
			start: 0,
			stats: {},
			subFilters: {},
		};

  }
  
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };
  
  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  
  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
   handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building, 'building') + '/' + _nameToUrl(building.address_2_normalized);
	 this.props.history.push(buildingURL);
  };



  
  
  render() {
	const { t, unitsRes } = this.props;
	const {
     	order, orderBy, selected, rowsPerPage, page, 
    } = this.state;
    if(!unitsRes){
	    return null;
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, unitsRes.length - (page * rowsPerPage));

	return (
		  <Panel lg={12} xl={12} md={12} title={`Unit List`}>
		  	<div className="material-table__wrap">
              <Table className="material-table">
              	<UnitListTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={unitsRes.length}
                />
                <TableBody>
                  {unitsRes
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map((d) => {
                      const isSelected = this.isSelected(d.id);
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleBuildingClick(event, d)}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={isSelected}
                        >
                          <TableCell className="material-table__cell" padding="checkbox" onClick={event => this.handleClick(event, d.id)}>
                            <Tooltip title="Add to Cohort">
                            	<Checkbox checked={isSelected} className="material-table__checkbox" />
                            </Tooltip>
                          </TableCell>
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                           	<span>{d.address_2_normalized.toUpperCase().replace("APT", "Apt")}</span>
                          </TableCell>
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{d.bedrooms}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                            <span className="light-text small-text">{d.baths}</span>
                          </TableCell>
						  <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{_formatNumber(d.sq_ft)}  sf</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{d.exterior_sq_ft ? <React.Fragment>{_formatNumber(d.exterior_sq_ft)} sf</React.Fragment>  : `--`}</span>
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small" 
                            align="center"
                          ><span className="light-text small-text">{d.price ? `$${_formatNumber(d.price)}` : "--"}</span>
                          </TableCell>
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          ><span className="light-text small-text">${_formatNumber(d.monthly_fees)}/mo</span>
                          </TableCell>
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          ><span className="light-text small-text">${_formatNumber(d.monthly_taxes)}/mo</span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={unitsRes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />

		    {/*<Table responsive striped className="table--bordered dashboard__audience-table">
		      <thead>
		        <tr>
		          <th>Unit Number</th>
		          <th>Bed / Bath</th>
		          <th>Size</th>
		          <th>Offering Price</th>
		          <th>Common Charge</th>
		          <th>Estimate Taxes</th>
		        </tr>
		      </thead>
		      <tbody>
		        {data.map(unit => 
		        <tr>
		          <td>{d.address_2_normalized.toUpperCase().replace("APT", "Apt")}</td>
		          <td>{d.bedrooms} / {d.baths}</td>
		          <td>{_formatNumber(d.sq_ft)} sq ft</td>
		          <td>${_formatNumber(d.price)}</td>
		          <td>${_formatNumber(d.monthly_fees)}<span className="small-text">/mo</span></td>
		          <td>
		            ${_formatNumber(d.monthly_taxes)}<span className="small-text">/mo</span>
		          </td>
		        </tr>
		        )}
		      </tbody>
		    </Table>*/}
		  </Panel>
	  )
	  
  }
}

export default withTranslation('common')(withRouter(UnitList));
