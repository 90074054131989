import React, { PureComponent, Fragment } from 'react';
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import buyersListActions from '../../../../../redux/actions/buyersListActions';
import { Badge, Table } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import EmptyBuyersList from '../../../../BuyersLists/components/EmptyBuyersList';
import LinearLoading from '../../../../../shared/components/LinearLoading';

import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import {
	propertyExportFL,
	_capitalizeText,
	_getBuildingURL,
	_shouldShow,
	_formatNumber,
	_formatPrice,
	UnitCurrentStatus,
	_nameToUrl
} from "../../../../../shared/helpers/utils";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const buildingUrl = process.env.API_ROOT + '/query/';
const styles = theme => ({
	  root: {
		display: 'flex',
		flexDirection: 'column',
		//marginLeft:"-8px",
		marginBottom:"15px",
		alignItems: 'left',
		'& > *': {
		   
		},
	  },
	  button:{
		'&.selected' :{
			background:"#D1D6E0"
		},
		fontSize:11,
		width:90
	  }
	});
	
const ShowUnits = ({data, title, color, priceField, status, dateField}) => {
	let units_url = window.location.pathname.replace('/tab/overview', '') + '/tab/units';
	units_url +=`?current_status=${status}&property_type=residential`;
	if(status == 'active' || status=='in-contract'){
		units_url = window.location.pathname.replace('/tab/overview', '/tab/listings')
	}else{
		units_url = window.location.pathname.replace('/tab/overview', '/tab/sales')
	}
	return (
		<Card>
		<CardBody style={{padding:15}}>
		 <div className="card__title flex">
		  <h5 className="bold-text">{title} ({data && data.doclist && data.doclist.numFound > 0 ? data.doclist.numFound : "0"}) </h5>
		  {data && data.doclist && data.doclist.numFound > 0 &&
		  <div className="unit__load text-right">			   		
			   <Tooltip title="See More">
				   <Link to={units_url} className="link-text">See All &raquo;</Link>			 
				 </Tooltip>				
		   </div>
	   		}
		 </div>
		 
		<Table responsive className="table-striped">
		  <tbody>
		  {data && data.doclist && Array.isArray(data.doclist.docs) && data.doclist.docs.length>0 ? 
			<Fragment>
		    	{data && data.doclist && data.doclist.docs.slice(0, 3).map((item, i) => {
				  const buildingNameOrAdr = item.building_name 
				  ? _capitalizeText(item.building_name)
				  : item.display_full_street_address
					? _capitalizeText(item.display_full_street_address)
					: _capitalizeText(item.full_street_address);
				const adr = item.display_full_street_address ? item.display_full_street_address : item.full_street_address;
				const unitURL = _getBuildingURL(item, 'building') + '/' + _nameToUrl(item.address_2_normalized);
			  return (
			 
					  <tr key={i} style={{border:0,cursor:"pointer"}}>
						  <td>
							  <Badge style={{ width:70,background:color }}>
								  <span className="bold-text">{_formatPrice(item[priceField])}</span> 
							  </Badge>
						</td>      				       	
						<td>
							<Link to={unitURL}>
								<div>{buildingNameOrAdr}, {item.address_2_normalized.toUpperCase().replace("APT", "Apt")}</div>
								<span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}</span>
								<br/>
								{item.baths>0 ?
								 <Fragment>
									<span className="light-text small-text">{item.bedrooms > -1 ? item.bedrooms === 0 ? 'Studio' : item.bedrooms+' Bed' : '--'}</span>
									{` | `}<span className="light-text small-text">{item.baths > -1 ? item.baths+' Bath' : '--'}</span>
									
								</Fragment>
								 
								:
								 item.sq_ft>0 ? 
									<span className="light-text small-text">
										{_formatNumber(item.sq_ft)} sq ft
									</span>
								:
									<span className="light-text small-text">--</span>
								}
								{/*<br/>
								<span className="light-text small-text">{moment.unix(item[dateField]).fromNow()}</span>*/}
							</Link>	
							
						</td>   
						
							
					  </tr>
				  
				)
			  
		  })}
		  </Fragment>
		  :
		  <tr>
			  <td colSpan={2} className="text-center">
				  <div className="bold-text">None Found</div>
			  </td>
		  </tr>
		  }
			
		   
		  </tbody>
		</Table>
		
		</CardBody>
		</Card>

	)
}
class LatestStatus extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		  fetchInProgress: false,
		  data: null,
		  period: "7-day"
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.period !== prevState.period){
			this._getUnits();
		}
		
	}

	componentDidMount() {
		this._getUnits();
		
		  
	}
	_setBuyersList = (res) => {	  
		 const list = res.docs ? res.docs.map(building => {
			 return Object.assign({}, building, this.props.savedBuildings.filter(item => item.key === building.key)[0]);
		 }) : []
		 this.setState({ 
			buyersList: list,
			incompleteMyBuyersLists: list.filter(item => !item.completed),
			completedMyBuyersLists: list.filter(item => item.completed),		
		});
	}
	_getUnits = async () => {
			
		const { period } = this.state;
		const { unitQuery } = this.props;
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}

		
		const defaultFq = ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""', `-offering_verified_status:stalled`, "property_type:(\"\" *condo* \"resident manager unit\" *coop* *affordable*)"];
				 
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=3600",
			method: "post",
			query: {
				q: unitQuery,
				fq: defaultFq,
				fl: [...propertyExportFL, ...["floor", "building"]],
				wt: "json",
				rows: 0,
				sort : "last_sold_date desc, last_contract_date desc, last_listed_sale_date_all desc",
				"group": true,
				"group.limit": 20,
				// "group.sort" : [
				// 	"last_listed_sale_date_all desc",
				// 	"last_contract_date desc",
				// 	"last_sold_date desc"
				// ],
				"group.query": [
					`current_status:active`, 
					`current_status:"in contract"`,
					`current_status:(*sold* transfer)`]		
			}
		};

		let response = await _axiosCall(axiosData)
		.then(res => {
			return res;
		})
		.catch(error => {
			console.log("error: " + error);
		});
		this.setState({ data : response, fetchInProgress : false });
	
  };
  
  handleBuildingClick = (event, building, tab) => {
	 event.stopPropagation();
	 const buildingURL = _getBuildingURL(building, 'building_slugs') + (tab ? `/${tab}` : '');
	 this.props.history.push(buildingURL);
  };
  
  setPeriod = (period) => {
	  this.setState({period:period})
  }
  
  render(){
	const { savedUnits, totalCount, period, data, fetchInProgress } = this.state;
	const { classes, type } = this.props;
	//if(!data) return null;
	return (
		<div>
			  
			   
			   {(!data || fetchInProgress) ?
				   <div>
				   <LinearLoading />
				   <div style={{height:300}}>
					   
				   </div>
				   </div>
				   
				   :
				 
				   <Row>
					   <Col md={4} className={"mb-2"}>
						   <ShowUnits data={data ? data[Object.keys(data)[0]] : false} title={"On The Market"} color={"#4ce1b6"} priceField={"last_listed_sale_price_all"} status={"active"} dateField={"last_listed_sale_date_all"}  />
					   </Col>
					   <Col md={4} className={"mb-2"}>
						   <ShowUnits data={data ? data[Object.keys(data)[1]] : false} title={"In Contract"} color={"#FFD23F"} priceField={"last_listed_sale_price_all"} status={"in-contract"} dateField={"last_contract_date"}  />
					   </Col>
					   <Col md={4} className={"mb-2"}>
						   <ShowUnits data={data ? data[Object.keys(data)[2]] : false} title={"Sold"}  color={"#28C2FF"} priceField={"last_sold_price"} status={"closed"} dateField={"last_sold_date"} />
					   </Col>					   	  
						
				   </Row>
			   }
		</div>
	
	)
	
   }
};

LatestStatus.propTypes = {
  t: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const buyersLists = state.buyersLists.priorityFilter
	? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
	: state.buyersLists.buyersLists;
  return { buyersLists, theme: state.theme, rtl: state.rtl, 
			  savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : [],  
			  dataSet : state.buyersLists.dataSet };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,  { withTheme: true })(LatestStatus));
