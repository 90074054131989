import React, { PureComponent } from 'react';
import { withRouter, Link } from "react-router-dom";

import { Col, Card, CardBody } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import LinearLoading from '../../../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';
import ShareableItems from './ShareableItems';
import Panel from '../../../../../shared/components/Panel';
import MergeImages from './MergeImages';


import { _buildingIDs, _axiosCall } from '../../../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	_replaceAbbreviations,
	_formatBed,
	_calPercentage,
	_checkFieldForValue,
	_isCCC,
	propertyFL,
} from "../../../../../shared/helpers/utils";

const apiURL = process.env.API_ROOT + '/query/';



class Professionals extends PureComponent {

  state = {
		rowsPerPage: 35,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		noMoreResults:false,
		Professionals: []
  }


  componentDidMount() {	  
	  const { buildingRes, property } = this.props;	  
	  //this._getProfessionals(buildingRes, property);
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	  if(prevProps.match.params.building_slug !== this.props.match.params.building_slug ||
		   prevProps.match.params.unit !== this.props.match.params.unit){
		  this.setState( { start:0, Professionals: []})
	  }else if(this.state.start !== prevState.start){
		 const { buildingRes, property } = this.props;
		// this._getProfessionals(buildingRes, property);
	  }		 
	
  }


	
  render(){
	  const { Professionals } = this.state;
	  const {  title, subhead, label,  professionals } = this.props;
	  if(!Professionals){
		  return null;
	  }
	 	  return(
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={title}
		  	subhead={subhead}
		  	label={label}
		  >
		  <MergeImages 
			  image1Src={"https://marketproof-hosting.s3.amazonaws.com/logos/marketproof-logo-horizonal-black.png"} 
			  image2Src={"https://mcusercontent.com/b884b837c32ec731c7d2fab47/images/8bca9481-78a9-23df-e91a-6ed773863685.png"}
			/>
		  
		    <ShareableItems items={professionals} />
		 
		  </Panel>
	  )
	  
  }
  
}

export default withRouter(Professionals);
