import React from 'react';
import { Badge, Progress } from 'reactstrap';
import { fade, makeStyles } from '@material-ui/core/styles';
import queryString from "query-string";
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme } from '@material-ui/core/styles';
import OpenInNewIcon from "mdi-react/OpenInNewIcon";

import moment from 'moment';

import { _hasPermission } from "./access";
const gApiKey = "AIzaSyCA4m2MxRRUtpJ7fPNiLw0i2RzgQ_Qk0YE"; //"AIzaSyBPfa0KmEt3mpJLZLI8JXTXS78G7NFLGoA";

//IS DATA EQUAL
export const _isEqual = (a, b) => {
  // Check if both items are strictly equal
  if (a === b) return true;

  // If they are both Date objects, compare their values
  if (a instanceof Date && b instanceof Date) 
    return a.getTime() === b.getTime();

  // If one of them is null or not an object, they are not equal
  if (!(a instanceof Object) || !(b instanceof Object)) 
    return false;

  // If they are not the same type, they are not equal
  if (a.constructor !== b.constructor) 
    return false;

  // Compare if both are arrays or objects
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!_isEqual(a[i], b[i])) return false;
    }
    return true;
  } else {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    // If number of properties is different, objects are not equivalent
    if (keysA.length !== keysB.length) 
      return false;

    // Check if keys and values are identical
    for (const key of keysA) {
      if (!keysB.includes(key)) return false;
      if (!_isEqual(a[key], b[key])) return false;
    }
    return true;
  }
};

export const _objectEqual = (o1, o2) => {
  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  for (let p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  return true;
};

export const _shouldShow = (data, only) => {
  var should_show = false;

  if (typeof data === "string") data = data.trim();

  if (
    data !== undefined &&
    data !== null &&
    data != "" &&
    data !== -1 &&
    data !== 0 &&
    data != "null"
  ) {
    should_show = true;
  }
  if (only !== undefined && Array.isArray(only) && !only.indexOf(data) >= 0) {
    should_show = false;
  }

  return should_show;
};

export const _capitalizeText = (str, doReplace=true) => {
  // console.log('_capitalizeText', str)
  if (!str) return "";

  var text = str.toString().replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }).replace(/-\w/, function(txt){return txt.toUpperCase()});
  if(doReplace) text = text
    .replace("&Amp;", " & ")
    .replace("Cbs", "CBS")
    .replace("Co Op", "Co-op")
    .replace("Co-Op", "Co-op")
    .replace("Co-op-", "Co-op ")
    .replace(/Coop\b/, "Co-op ")
    .replace("Public-Facilities", "Public Facilities")
    .replace("Cps", "CPS")
    .replace("crg", "CRG")
    .replace("Crg", "CRG")
    .replace("Dsa", "DSA")
    .replace(/^Elliman/, "Douglas Elliman Real Estate")
    .replace("Evgb", "EVGB")
    .replace("Gpb", "GPB")
    .replace("Gph", "GPH")
    .replace("Gp", "GP")
    .replace("Gst", "GST")
    .replace("Hfz", "HFZ")
    .replace("Hsbc", "HSBC")
    .replace("Iii", "III")
    .replace(/Ii\b/, "II")
    .replace("Jfk", "JFK")
    .replace("Jpmorgan", "JPMorgan")
    .replace("Jp ", "JP ")
    .replace("Kwnyc", "KWNYC")
    // .replace("Lic", "LIC")
    .replace(/ lic(?![A-Za-z])/gi, " LIC")
    // .replace("Llc", "LLC")
    .replace(/ llc(?![A-Za-z])/gi, " LLC")
    .replace("Llp", "LLP")
    .replace("Lllp", "LLLP")
    .replace("Lml", "LML")
    .replace("Lp", "LP")
    .replace("Ltf", "LTF")
    .replace("Ltd", "LTD")
    .replace("ltd", " LTD")
    .replace("Multifamily", "Multi-Family")
    .replace("Mns", "MNS")
    .replace("Mtl", "MTL")
    .replace(/\b,\s?N.?a.?,?\b/, "")
    .replace("Nbo4", "NBO4")
    .replace("Nyc", "NYC")
    .replace("NYCha", "NYCHA")
    .replace("Nys", "NYS")
    .replace("Ny", "NY")
    .replace("-Op", "-op")
    .replace("Pc", "PC")
    .replace("P.c", "P.C")
    .replace("Pllc", "PLLC")
    .replace("Pnc", "PNC")
    .replace("P.s", "P.S")
    .replace(" Pss ", " PSS ")
    .replace(/, Ra\b/, ", RA")
    .replace("Rca", "RCA")
    .replace("Rktb", "RKTB")
    .replace("Rkt&b", "RKT&B")
    .replace("Singlefamily", "Single Family")
    .replace("Stv", "STV")
    .replace("Tf", "TF")
    .replace("The Xi", "The XI")
    .replace("Usa", "USA")
    .replace("U.s.a", "U.S.A")
    .replace("Ymca", "YMCA")
    .replace(/ Na$/, ", N.A.")
    .replace(" N.a.", " N.A.")
    .replace("Td ", "TD ")
    .replace("Cmtg ", "CMTG ")
    .replace(" Of ", " of ")
    .replace(" And ", " and ")
    .replace("Mers", "MERS")
    .replace(" Ag ", " AG ")
    .replace(" Ppsf", " PPSF")
    .replace("Cpw", "CPW")
    .replace("L.l.c", "LLC")
    .replace("L.l.p", "LLP")
    .replace("Single Residential Condo Unit", "Condo Unit");
    
    if(text.indexOf('Breds ')>=0) text = "Blackstone Real Estate Debt Strategies";
    

  return text;
};

export const _nameToUrl = (name, type) => {
  if(!name) {return "";}
  name = name.toLowerCase().trim();
  if (name.includes("co-op") && (!type || type !== "company")) {
    name = name.replace(/-/g, "");
  }

  //turns ' into %27 for urls
  name = name.replace(/\'/g, '%27')
  // console.log('name after %27 replace', name)
  
  name = name.replace("unit", 'apt');
  
  // replaces all non-word chars - need to keep % for ' uri encoding
  return name.replace(/[^a-zA-Z0-9%_]+/g, "-");
  // return name.replace(/\W+/g, "-");
};

//TO DO ONCE WE KNOW WHAT WE WANT DONE WITH CO-OP, COOP, AND OTHER EDGE CASES
export const _urlToName = url => {
  const namesWithPunctuation = {
    "astoria-ditmars": "astoria-ditmars",
    "co-op-city": "co-op city",
    "douglaston-little-neck": "douglaston-little neck",
    "holliswood-terrace-heights": "holliswood-terrace heights",
    "travis-chelsea": "travis-chelsea",
    "st-george": "st. george",
  };

  if (!url) return "";
  url = url.toLowerCase().trim();

  if(
      (url.includes("coop") && !url.includes("cooper")) || url.includes("co-op-")
    ) {
    return (url = url
      .replace(/-/g, " ")
      .replace(/(coop)/g, "co-op")
      .replace(/(co\sop)/g, "co-op"));
  } else if (url.includes("co-op")) {
    return url;
  } else if(namesWithPunctuation[url]) {
    return namesWithPunctuation[url];
  } else {
    url = url.replace(/%27/g, "'");
    return url.replace(/-/g, " ");
  }

  // return url.replace(/-/g, ' ');
};

export const _chunkArray = (arr, size) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    throw new TypeError("_chunkArray Input should be Array longer than 0");
  }

  if (typeof size !== "number") {
    throw new TypeError("_chunkArray Size should be a Number");
  }

  // if(arr.length === 0) {return arr;}

  let tempArray = [];

  for (let i = 0, j = arr.length; i < j; i += size) {
    let myChunk = arr.slice(i, i + size);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const _scrollTo = (top, left) => {
  // console.log("_scrollTo ", top, left);
  try {
    window.scroll({
      top: top ? top : 0,
      left: left ? left : 0,
      behavior: "smooth"
    });
  } catch (e) {
    window.scroll(top ? top : 0, left ? left : 0);
  }
};

// export const _scrollRight = (right) => {
//   console.log('_scrollRight ', right)
//   window.scroll({
//     top: top ? top : 0,
//     left: 0,
//     behavior: 'smooth'
//   });
// }

export const _scrollID = (id, offset) => {
  var el = null;
  el = document.getElementById(id);
  if (offset === undefined) {
    offset = 0;
  }

  if (el === undefined || !el || el === null) {
    el = document.getElementsByClassName(id);
    if (el.length <= 0) {
      return false; // window.scrollTo(0, 0);
    } else {
      el = el[0];
    }
  }

  function findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
  }
  var scrollTo = function(to, duration) {
    var element = document.scrollingElement || document.documentElement,
      start = element.scrollTop,
      change = to - start,
      startDate = +new Date(),
      easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      },
      animateScroll = function() {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = to;
        }
      };
    animateScroll();
  };

  var yScroll = findPos(el);
  yScroll = parseInt(yScroll) + parseInt(offset);
  //console.log(yScroll);
  scrollTo(yScroll, 200);
  //window.scrollTo(0, yScroll - 70);
};

//random google map api key
export const _generateRandomAPI = arr => {
	if(!arr) {return}
  let apiIndex = Math.floor(Math.random() * arr.length);
  return apiIndex;
};

export const _makeBuildingPhotosArr = (building) => {
  let photoArr = building && building.pictures && building.pictures.length > 0
    ? building.image &&
      building.image != "" &&
      building.image.indexOf("olr") < 0
      ? [building.image, ...building.pictures]
      : building.pictures
    : building.image &&
      building.image != "" &&
      building.image.indexOf("olr") < 0
      ? [building.image]
      : _getBuildingPhotos(building);
  return photoArr
}

export const _hasMPImgUpload = (picsArr) => {
  // checking for all pass versions of image uploads - MARKETPROOF, WE3-BUILDINGS/BUILDING, drive.google, usp=sharing, we3-news
  let hasMPImg = picsArr.filter(image => (image.toLowerCase().indexOf('marketrpoof') > -1 || image.indexOf('WE3-BUILDINGS/BUILDING') > -1 || image.indexOf("usp=sharing") < -1 || image.indexOf("we3-news") > -1) && image.toLowerCase().indexOf('!')<0) && 
    picsArr.filter(image => (image.toLowerCase().indexOf('marketrpoof') > -1 || image.indexOf('WE3-BUILDINGS/BUILDING') > -1 || image.indexOf("usp=sharing") < -1 || image.indexOf("we3-news") > -1) && image.toLowerCase().indexOf('!')<0).length > 0;

  // console.log('hasMPImg', hasMPImg)
  return hasMPImg;
}

export const _getMarketProofImg = (picsArr) => {
  // console.log("_getMarketProofImg picsArr", picsArr)
  if(!picsArr || (picsArr && picsArr.length === 0)) return [];
  
  if(!_hasMPImgUpload(picsArr)) { 
    // console.log('no mp img returning picsArr[0]', picsArr[0])
    return [picsArr[0]];
  } else {
    // console.log("has mpImage")
    if(picsArr.slice().join('').toLowerCase().indexOf('marketrpoof')) {
      // console.log("MARKETPROOF", [picsArr.filter(image => image.indexOf('MARKETPROOF') > -1 && image.toLowerCase().indexOf('!')<0)[0]])
      return [picsArr.filter(image => image.toLowerCase().indexOf('marketrpoof') > -1 && image.toLowerCase().indexOf('!')<0 && image.indexOf('drive.google')<0).slice(-1).pop()];
    } else if(picsArr.slice().join('').includes("we3-news")) {
      // console.log("we3-news/building.image", [picsArr.filter(image => image.indexOf('we3-news') > -1 && image.toLowerCase().indexOf('!')<0)[0]])
      return [picsArr.filter(image => image.indexOf('we3-news') > -1 && image.toLowerCase().indexOf('!')<0)[0]];
    } else {
      // console.log("drive or WE3_BUILDINGS or usp", [picsArr.filter(image => (image.indexOf("drive.google") > -1 || image.indexOf('WE3-BUILDINGS/BUILDING') > -1 || image.indexOf("usp=sharing") < -1) && image.toLowerCase().indexOf('!')<0)[0]])
      return [picsArr.filter(image => (image.indexOf('WE3-BUILDINGS/BUILDING') > -1 || image.indexOf("usp=sharing") < 0) && image.toLowerCase().indexOf('!')<0)[0]];  
    }
  }
}

export const _getBuildingPhotos = function(building, url = false) {
  // console.log('_getBuildingPhotos ', building)
  var buildingImages = [];
  var address = building.display_full_street_address
    ? building.display_full_street_address
    : building.full_street_address;

  const hood = building.city
    ? building.city
    : building.neighborhood
    ? Array.isArray(building.neighborhood) && building.neighborhood.length > 0
      ? building.neighborhood[0]
      : building.neighborhood
    : false;

  var defaultImage =
    "/maps/api/streetview?key=" + gApiKey +
    "&size=700x700&location=" +
    address +
    ", " +
    building.city +
    ", " +
    building.zip +
    "&fov=90&pitch=10";

  //Shortcut the image processing and use backend cache.
   defaultImage = `${process.env.API_ROOT}/query/gsign?url=${defaultImage}`;
  
  if(building.pictures && Array.isArray(building.pictures) && building.pictures.filter(img => img != "" && img != "-1" && img.indexOf('drive.google')<0 && img.indexOf("maps/api/streetview") < 0).length>0){
	  var found = false;
    
	  const imgArr = _makeBuildingPhotosArr(building, gApiKey);

//console.log(imgArr)
      buildingImages = imgArr.filter(url => url && url != "" && url != "-1" && url.indexOf('drive.google')<0 && url.indexOf("usp=sharing") < 0 && url.indexOf("bellmarc") < 0);
//	  console.log(buildingImages)
	 if(buildingImages && buildingImages.length > 0) return buildingImages.reverse().map(url => url.replace("http://", "https://"));
  }
  if (process.env.API_ROOT) {
    defaultImage = process.env.API_ROOT + "/buildings/pictures/" + building.key;
    if(building.key && building.key[0]=="p"){
	    defaultImage = process.env.API_ROOT + "/buildings/pictures/" + building.building;
		}
	    buildingImages.push(defaultImage);
	    return buildingImages;
	  }

  if (
    building.image &&
    building.image != "" &&
    building.img != "-1" &&
    building.image.indexOf("olr") < 0
  ) {
    buildingImages.push(building.image);
  }

  if (
    building.pictures &&
    building.pictures.length > 0 &&
    buildingImages.length == 0
  ) {
    for (var i = 0; i < building.pictures.length; i++) {
      //check for list of concatenated images - account for error in manual override
      var img = building.pictures[i].split("\n")[0];

      if (img.indexOf("we3-news") >= 0) {
        buildingImages.push(img);
        break;
      }
    }
    if (buildingImages.length == 0) {
      
    }

    /*Remove images we don't have license to
      if (buildingImages.length == 0) {
        for (var i = 0; i < building.pictures.length; i++) {
          //check for list of concatenated images - account for error in manual override
          var img = building.pictures[i].split('\n')[0];
  
          if (img.indexOf('bellmark') < 0 && img.indexOf('bellmarc') < 0 && img.indexOf('usp=sharing') < 0 && img !='') {
            //buildingImages.push(img);
            break;
          }
        }
        }
*/
  }

  if (buildingImages.length == 0) {
    buildingImages.push(defaultImage);
  }
  
  return buildingImages.map(url => url.replace("http://", "https://"));
};

export const _getDefaultBuildingPhoto = function(item, key) {
	let defaultImage = null;
	if (process.env.API_ROOT) {
		if(item.key && item.key.indexOf('b-')>=0){
			defaultImage = process.env.API_ROOT + "/buildings/pictures/" + item.key;
		}else if(item.building){
			defaultImage = process.env.API_ROOT + "/buildings/pictures/" + item.building;
		}
	}
    return defaultImage;	

}
// #STRING MANIPULATIONS
export const _slugifyText = (text) => {
  // console.log('_slugifyText ', text)

  if(!text) return '';
  const sluggedText = text
    .toString()
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/_/g, "-") 
    .replace(/\\/g, "-")

  // console.log('_slugifyText ', sluggedText)
  return sluggedText;
}

export const _makeFileName = (text) => {
  // console.log('_slugifyText ', text)

  if(!text) return '';
  let fileName = _slugifyText(text).replace(/[^\w\s-]/gi, '').replace(/-{2,}/g,"-");

  // console.log('_slugifyText ', sluggedText)
  return fileName;
}

export const _makeSlugifiedArr = (featureSet) => {
  if(!featureSet) return [];
  const featureArr = Array.isArray(featureSet)
        ? featureSet.filter((el, i, arr) => el && arr.indexOf(el) === i).map(el => _slugifyText(el))
        : [_slugifyText(featureSet)]

  return featureArr;
}

export const _formatNumber = number => {
  if (!number) return;

  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const _formatNumberDecimal = (number, dec) => {
  if (!number) return;
  if(dec === undefined) dec = 2;
  const options = { 
	  minimumFractionDigits: dec,
	  maximumFractionDigits: dec 
	};
  return Number(number).toLocaleString('en', options);
};

export const _formatCurrency = number => {
  if (!number) return "--";

  return (
    "$" +
    Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

export const _formatPrice = price => {
 // price = ~~price;
  price = Math.round(Math.abs(price));
  if (price <= 0) {
    return "--";
  }
  
  if (price.toString().length >= 10) {
    return "$" + parseFloat((price / 1000000000).toFixed(price % 1000000000 != 0 ? 2 : 0)).toString() + "B";
  }else if (price.toString().length >= 7) {
    return "$" + parseFloat((price / 1000000).toFixed(price % 1000000 != 0 ? 2 : 0)).toString() + "M";
  }else if (price.toString().length >= 4 && price.toString().length <7 ) {
    return "$" + (price / 1000).toFixed() + "K";
  }
  return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const _formatBigPrice = price => {
 // price = ~~price;
  price = Math.round(Math.abs(price));
  if (price <= 0) {
    return "--";
  }
  
  if (price.toString().length >= 10) {
    return "$" + parseFloat((price / 1000000000).toFixed(price % 1000000000 != 0 ? 1 : 0)).toString() + "B";
  }else if (price.toString().length >= 7) {
    return "$" + parseFloat((price / 1000000).toFixed(price % 1000000 != 0 ? 0 : 0)).toString() + "M";
  }else if (price.toString().length >= 4 && price.toString().length <7 ) {
    return "$" + (price / 1000).toFixed() + "K";
  }
  return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const _formatBigNumber = (number, dec) => {
 // price = ~~price;
  number = Math.round(Math.abs(number));
  if (number <= 0) {
    return "--";
  }
  if(dec === undefined) dec = 2;
  
  if (number.toString().length >= 10) {
    return parseFloat((number / 1000000000).toFixed(number % 1000000000 != 0 ? dec : 0)).toString() + "B";
  }else if (number.toString().length >= 7) {
    return parseFloat((number / 1000000).toFixed(number % 1000000 != 0 ? dec : 0)).toString() + "M";
  }else if (number.toString().length >= 4 && number.toString().length <7 ) {
    return (number / 1000).toFixed() + "K";
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const _formatDate = date => {
	if (date.match(/^\d{2}$/) !== null) {
	    date = date + '/';
	} else if (date.match(/^\d{2}\/\d{2}$/) !== null) {
	    date = date + '/';
	}
	return date
};

export const _formatQueensAddress = (display_address, alternate_addresses) => {
  var streetNumberDisplay = display_address.split(" ")[0];
  var queensStreetNumber = false;

  for (var i = 0; i < alternate_addresses.length; i++) {
    var altStreetDisplay = alternate_addresses[i];
    var altStreetNumber = altStreetDisplay.split(" ")[0];
    if (
      altStreetNumber.includes("-") &&
      streetNumberDisplay == altStreetNumber.replace("-", "")
    ) {
      queensStreetNumber = altStreetNumber;
      break;
    }
  }

  if (queensStreetNumber !== false) {
    display_address = display_address.replace(
      streetNumberDisplay,
      queensStreetNumber
    );
  }

  return display_address;
};
export const _replaceAbbreviations = text => {
  // console.log("_replaceAbbreviations", text);
  var abbreviations = {
    n: "north",
    s: "south",
    e: "east",
    w: "west",
    ne: "northeast",
    nw: "northwest",
    se: "southeast",
    sw: "southwest",
    aly: "alley",
    anx: "annex",
    arc: "arcade",
    ave: "avenue",
    yu: "bayou",
    bch: "beach",
    bnd: "bend",
    blf: "bluff",
    btm: "bottom",
    blvd: "boulevard",
    br: "branch",
    brg: "bridge",
    brk: "brook",
    bg: "burg",
    byp: "bypass",
    cp: "camp",
    cyn: "canyon",
    cpe: "cape",
    cswy: "causeway",
    ctr: "center",
    cir: "circle",
    clfs: "cliffs",
    clb: "club",
    cor: "corner",
    cors: "corners",
    crse: "course",
    ct: "court",
    cts: "courts",
    cv: "cove",
    crk: "creek",
    cres: "crescent",
    xing: "crossing",
    dl: "dale",
    dm: "dam",
    dv: "divide",
    dr: "drive",
    est: "estates",
    expy: "expressway",
    ext: "extension",
    fall: "fall",
    fls: "falls",
    fry: "ferry",
    fld: "field",
    flds: "fields",
    flt: "flats",
    for: "ford",
    frst: "forest",
    fgr: "forge",
    fork: "fork",
    frks: "forks",
    ft: "fort",
    fwy: "freeway",
    gdns: "gardens",
    gtwy: "gateway",
    gln: "glen",
    gn: "green",
    grv: "grove",
    hbr: "harbor",
    hvn: "haven",
    hts: "heights",
    hwy: "highway",
    hl: "hill",
    hls: "hills",
    holw: "hollow",
    inlt: "inlet",
    is: "island",
    iss: "islands",
    isle: "isle",
    jct: "junction",
    cy: "key",
    knls: "knolls",
    lk: "lake",
    lks: "lakes",
    lndg: "landing",
    ln: "lane",
    lgt: "light",
    lf: "loaf",
    lcks: "locks",
    ldg: "lodge",
    loop: "loop",
    mall: "mall",
    mnr: "manor",
    mdws: "meadows",
    ml: "mill",
    mls: "mills",
    msn: "mission",
    mt: "mount",
    mtn: "mountain",
    nck: "neck",
    orch: "orchard",
    oval: "oval",
    park: "park",
    pky: "parkway",
    pass: "pass",
    path: "path",
    pike: "pike",
    pnes: "pines",
    pl: "place",
    pln: "plain",
    plns: "plains",
    plz: "plaza",
    pt: "point",
    prt: "port",
    pr: "prairie",
    radl: "radial",
    rnch: "ranch",
    rpds: "rapids",
    rst: "rest",
    rdg: "ridge",
    riv: "river",
    rd: "road",
    row: "row",
    run: "run",
    shl: "shoal",
    shls: "shoals",
    shr: "shore",
    shrs: "shores",
    spg: "spring",
    spgs: "springs",
    spur: "spur",
    sq: "square",
    sta: "station",
    stra: "stravenues",
    strm: "stream",
    st: "street",
    smt: "summit",
    ter: "terrace",
    trce: "trace",
    trak: "track",
    trl: "trail",
    trlr: "trailer",
    tunl: "tunnel",
    tpke: "turnpike",
    un: "union",
    vly: "valley",
    via: "viaduct",
    vw: "view",
    vlg: "village",
    vl: "ville",
    vis: "vista",
    walk: "walk",
    way: "way",
    wls: "wells",
    bldg: "building",
    dept: "department",
    fl: "floor",
    rm: "room",
    ste: "suite"
  };

  var new_string = "";
  var words = text && text.length>0 ? text.toLowerCase().split(" ") : [];
  
  var new_string = "";
  for(var i in words){
	let word = words[i];
	let skip = false;
	if(word == "st" && i<(words.length-1)){
		skip = true;
	}
	
	if(abbreviations[word] && !skip){
		new_string+= abbreviations[word];
	}else{
		new_string+= word;
	}
	new_string+= " ";
  }
  return new_string.trim();
};
export const _isResidential = (building, checkVal) => {
  // console.log('_isResidential building', building, 'checkVal', checkVal)
  var resiTypes = [
    "condo",
    "coop",
    "co op",
    "co-op",
    "condop",
    "rental",
    "rentals",
    "residential-rental",
    "apartment",
    "single family",
    "single-family",
    "multi family",
    "multi-family",
    "five to six family",
    "five-to-six-family",
    "four family",
    "four-family",
    "three family",
    "three-family",
    "two-family",
    "two family"
  ];
  // console.log('_isResidential ', building.ownership_type && _matchArray(resiTypes, building.ownership_type))
  if(building && building.ownership_type) {
    return (
      building.ownership_type && _matchArray(resiTypes, building.ownership_type)
    );
  } else {
    return _matchArray(resiTypes, checkVal);
  }
};
export const _matchArray = (arrayList, value) => {
   // console.log('_matchArray arrayList', arrayList, 'value', value, )
  if(!value || !_shouldShow(arrayList)) {return;}
  var isFound = false;
  // var resiTypes = ['condo', 'coop', 'condop', 'multi-family', 'single-family', 'co op', 'co-op'];
  for (var i in arrayList) {
    var type = arrayList[i];
    // if(value === 'condo') {
    //   console.log('_matchArray type', type, 'value', value, ((value && value.toLowerCase().indexOf(type) >= 0) || 
    //    (type && type.includes(value.toLowerCase())))
    //   )
    // }
    
    if ((value && value.toLowerCase().indexOf(type) >= 0) || 
       (type && type.toString().includes(value.toLowerCase()))
    ) {
      isFound = true;
    }
  }

  return isFound;
};
export const _getBuildingURLBase = (building, key) => {
  //if(!building.id) return "";
  const hood = _shouldShow(building.neighborhood)
	? Array.isArray(building.neighborhood)
		? building.neighborhood[0]
		: building.neighborhood
	: "nyc";
  key = !key ? 'building_slugs' : key;
  const type = "building";
  const buildingTagInfo = {
		building_slug: key ? Array.isArray(building[key]) ? key === 'building_slugs' ? building[key][0] : building[key][0].substr(4) :  building[key] ? building[key].substr(4) : building.id.substr(4) : building.id.substr(4),
		building_display:
			type === "building"
				? undefined
				: building.display_full_street_address
				? building.display_full_street_address
				: building.full_street_address,
		display_adr:
			building.building_slugs && building.building_slugs[0]
				? building.building_slugs[0]
				: key ? Array.isArray(building[key]) ? key === 'building_slugs' ? building[key][0] : building[key][0].substr(4) :  building[key] ? building[key].substr(4) : building.id.substr(4) : building.id.substr(4)
	};
	
	const buildingURL =
		_nameToUrl(building.borough) +
		"/" +
		_nameToUrl(hood) +
		"/" +
		buildingTagInfo.building_slug;
	return buildingURL;
};


export const _getBuildingURL = (building, key) => {
  
	const buildingURL =
		"/buildings/" + _getBuildingURLBase(building, key);
	return buildingURL;
};

export const buildingFL = [
			"building_effective_start",
			"inventory_active",
			"inventory_contract",
			"inventory_sold",
			"inventory_unsold",
			"inventory_not_yet_recorded",
			"inventory_resale",
			"inventory_other",
			"inventory_listed",
			"inventory_total",
            "inventory_residential_units:div(residential_units, number_of_buildings)",
			"inventory_shadow",
			"inventory_pct_listed",
			"inventory_pct_shadow",
			"inventory_pct_unsold",
			"inventory_pct_sold",
			"inventory_last_updated",
			"inventory_stats_listings_average_ppsf",
      "inventory_stats_transactions_average_ppsf",
			"inventory_stats_listings_low_price",
			"inventory_stats_listings_high_price",
			"inventory_bulk_unsold",
			"inventory_pct_bulk_unsold",
			"inventory_pct_bulk_sold",
      "inventory_stats_listings_average_sqft",
			"sales_start_date",
			"active_listings_rent",
			"active_listings_sale",
			"address_count",
			"address_deliverable",
			"block",
			"lot",
			"borough",
			"borough_code",
			"building",
			"building_category",
			"building_name",
			"building_complex",
			"city",
			"county",
			"created_date",
			"display_full_street_address",
			"alternate_addresses",
			"full_street_address",
			"geocode",
			"id",
			"image",
			"key",
      "alternate_keys",
			"master_key",
			"name",
			"neighborhood",
			"ownership_type",
			"ownership_occupancy_sub_types",
			"photo_count",
			"pictures",
			"pluto_address_found",
			"primary_full_street_address",
			"property_category",
			"solr_updated",
			"solr_updated_date",
			"sq_ft",
			"state",
			"tom",
			"total_floors",
			"total_units*",
			"number_of_buildings",
			"building_era",
			"building_construction_style",
			"landmark_name",
			"historic_district",
			"historic_district_name",
			"residential_units",
			"thumbnail_large",
			"thumbnail_small",
			"type",
			"type_list",
			"website",
			"year_built",
			"zip",
			"sales_office",
			"marketing_company",
			"amenity_operator",
			"interior_designers",
			"architect",
			"owner",
			"developers",
			"description",
			"building_service_level",
			"building_access",
			"amenities*",
			"building_social_info",
			"manager",
			"notes",
			"sales_office_details",
			"offering_floorplans",
			"deleted",		
			"year_converted",
			"landscape_architect",
			"offering*",
			"total_loan_amount",
			"lenders",
			"loan_amounts",	
			"loan_dates",
			"lease_start_date",
			"*cdec*",
			"building_status",
			"first_closing_date",
			"min_offering_sold_pct",
			"last_listed_sale_date_formatted",
			"last_contract_date_formatted",
			"last_sold_date_formatted",
			"tax_assessed_residential_units",
			"tax_assessed_total_units",
			"condo_num",
			"master_key",
			"custom_fields_json",
      "user_description",
      "lot*",
      'building_frontage',
      'year_altered_1',
      'year_altered_2'
		];
export const propertyFL = ["full_street_address", 
			"display_full_street_address", 
			"alternate_addresses",
			"alternate_unitcodes",
			"zip",
			"address_2", 
			"address_2_normalized", 
			"bedrooms", 
			"baths",
			"half_baths",
			"sq_ft",
			"exterior_sq_ft",
			"offering_price",
			"building",
			"building_name",
			"neighborhood",
			"borough",
			"monthly_fees",
			"monthly_taxes",
			"key",
			"total_units",
			"residential_units",
			"last_sold_price",
			"last_sold_date",
			"price",
			"last_listed_sale_price_all",
			"last_listed_sale_date_all",
            "last_listed_sale_modified_date_all",
            "last_listed_sale_ppsf_all",
  			"last_listed_rent_price_all",
			"last_listed_rent_date_all",
			"last_contract_date",
			"last_contract_price",
			"last_contract_date_formatted",
			"last_listed_sale_key_all",
			"last_listed_rent_key_all",
            "last_listed_sale_status_all",
            "last_listed_rent_status_all",
			"id",
			"offering*",
			"property_type",
			"cdec_date",
			"deleted",
			"floorplans",
			"offering_floorplans",
			"source_organizations",
			"property_status",
			"current_status",
			"first_sold_date",
			"first_sold_price",
			"first_sold_date_formatted",
			"first_listed_date",
			"first_listed_date_formatted",
			"first_listed_price",
			"times_sold",
			"div(last_sold_price, sq_ft)",
			"div(last_listed_sale_price_all, sq_ft)",
			"div(last_contract_price, sq_ft)",
            "div(sub(offering_price, last_listed_sale_price_all), offering_price)",
            "div(sub(offering_price, last_sold_price), offering_price)",
			"owner",
            "ownership_type",
			"verification_source",
			"borough_code",
			"block",
			"lot",
			"times_exchanged",
			"last_exchanged_date",
			"master_key",
			"custom_fields_json",
      "has_floorplans_resized",
      "year*",
      "building_class",
      "property_features",
      "building_features"];	
			
export const mailingListFL = [
			"building_name",
			"display_full_street_address",
			"address_2_normalized", 
			"neighborhood",
			"borough",
			"city",
			"state",
			"zip",
			"owner",
			"bedrooms", 
			"baths",
			"sq_ft",
			"last_sold_price",
			"last_sold_date",
			"year_built",
			"year_converted",
			"borough",
			"neighborhood",
			"building"						
			];				
export const propertyExportFLRestricted = [
			"building_name",
			"display_full_street_address",
			"address_2_normalized", 
			"neighborhood",
			"borough",
			"zip",
			"bedrooms", 
			"baths",
			"half_baths",
			"sq_ft",
			"exterior_sq_ft",	
			"current_status"			
			];		
export const propertyExportFL = [
			"building_name",
			"display_full_street_address",
			"address_2_normalized", 
			"neighborhood",
			"borough",
			"city",
			"state",
			"zip",
			"bedrooms", 
			"baths",
			"half_baths",
			"sq_ft",
			"exterior_sq_ft",	
			"current_status",		
			"monthly_fees",
			"monthly_taxes",
			"offering_price",
			"last_sold_price",
			"last_sold_date",
      "div(last_sold_price, sq_ft)",
			"last_listed_sale_price_all",
			"last_listed_sale_date_all",
      "div(last_listed_sale_price_all, sq_ft)",
			"last_contract_date",
			"last_contract_price",
			"last_contract_date_formatted",
      "div(last_contract_price, sq_ft)",
			"property_type",
			"property_status",
			"offering_status",			
			"first_sold_price",
			"first_sold_date_formatted",
			"first_listed_date_formatted",
			"first_listed_price",
			"times_sold"
      
      
      /*
,
			"predicted_bedrooms",
			"predicted_baths"
*/
						
			];	
export const salesExportFL = [
			"building_name",
			"display_full_street_address",
			"address_2_normalized", 
			"neighborhood",
			"borough",
			"city",
			"state",
			"zip",
			"bedrooms", 
			"baths",
			"sq_ft",
			"price",
      "price_per_sqft",
      "sponsored_sale",
      "discount",
      "listed_date_formatted",
      "contract_date_formatted",
			"close_date_formatted",
      "days_to_contract",
      "percent_financed",
      "financed_amt",
      "parcels_condocoop_count",
      "parcels_count",
      
/*
			"owner",
			"seller",
*/
			];	
export const buildingExportFL = ["name",
				"display_full_street_address",
				"neighborhood",
				"markets_major",
				"markets_sub",
				"borough",
				"city",
				"county",
				"state",
				"zip",
				"ownership_type",
				"year_built",
				"year_converted",
				"year_sales_start",
				"sales_start_date",
				"building_effective_start",
				"building_status",
				"first_closing_date",					
				"total_floors",
				"total_units",
				"residential_units",
				"inventory_last_updated",
				"inventory_total",
				"inventory_unsold",
                "inventory_contract",
				"inventory_sold",
                "inventory_stats_listings_average_ppsf",
                "inventory_stats_transactions_average_ppsf",
				"sales_office",
        "marketing_company",
				"notes",
    //    "sales_office_details",

/*
				"first_cdec_date",
				"last_cdec_date",
				"inventory_active",
				"inventory_contract",
				"inventory_not_yet_recorded",
				"inventory_shadow",
				"inventory_pct_sold",
				"inventory_pct_unsold",
				"inventory_stats_listings_high_price",
				"inventory_stats_listings_low_price",
*/
				"offering_price_initial",
				"offering_price_current",
				
/*
				"offering_submitted_date",
				"offering_accepted_date",
				"offering_effective_date",
				"offering_plan_type",
				"offering_construction",
				"inventory_active",
				"inventory_contract",
				"inventory_not_yet_recorded",
				"inventory_resale",
				"inventory_other",
				"inventory_listed",
				"inventory_shadow",
				"inventory_bulk_sold",
				"inventory_transfer",
				"inventory_stats_listings_average_ppsf",
				"inventory_stats_listings_low_price",
				"inventory_stats_listings_high_price",
				"marketing_company",
				"amenity_operator",
				"interior_designers",
				"architect",
				"owner",
				"developers",
				"key"
*/
				];
 				
export const _formatUnitNumber = (address_2_normalized, building) => {
	let formatted = address_2_normalized ? address_2_normalized.toUpperCase().replace('APT', 'Unit') : '';

	if(building && building.key == 'b-3-98-front-st-11201'){
		formatted = formatted.replace('GARAGE', 'GRG').replace('GARDEN', 'GRD');
	}
	
	if(formatted === "") formatted = "Unit";
	return formatted;
	
}

export const _formatBed = (bedrooms) => {

	if(bedrooms === 0){
		return 'Studio';
	}else if(bedrooms === 1){
		return '1 Bed';
	}else if(bedrooms > 1) {
		return bedrooms + ' Beds'
	}
	
	return '--';
}
export const _formatBath = (baths) => {

	if(baths === 1){
		return '1 Bath';
	}else{
		return baths + ' Baths'
	}
	
	return '--';
}

export const _getFeaturesArr = (type, isHouse, data) => {
  console.log('_getFeaturesArr type', type, 'isHouse', isHouse,  'data', data)
  let compiledFeatureList = [];
  let featuresData = [];
  let amenitiesData = [];
  
  if(isHouse || type === 'unit') {
    const _appliances = data && _shouldShow(data.property_features_appliances)
      ? _makeSlugifiedArr(data.property_features_appliances)
      : [];

    const _finishes = data && _shouldShow(data.property_features_finishes)
      ? _makeSlugifiedArr(data.property_features_finishes)
      : [];

    const _layout = data && _shouldShow(data.property_features_layout)
      ? _makeSlugifiedArr(data.property_features_layout)
      : [];

    const _exposure = data && _shouldShow(data.property_features_exposure)
      ? _makeSlugifiedArr(data.property_features_exposure)
      : [];

    const _views = data && _shouldShow(data.property_features_views)
      ? _makeSlugifiedArr(data.property_features_views)
      : [];

    const _outdoorSpace = data && _shouldShow(data.property_features_private_outdoor_space)
      ? _makeSlugifiedArr(data.property_features_private_outdoor_space)
      : [];

    const _hvac = data && _shouldShow(data.property_features_hvac)
      ? _makeSlugifiedArr(data.property_features_hvac)
      : []; 

    featuresData = [..._appliances, ..._finishes, ..._layout, ..._exposure, ..._views, ..._outdoorSpace, ..._hvac].filter((item, i, arr) => arr.indexOf(item) === i);
    // console.log("featuresData", featuresData)
  }
  
  if(isHouse || type === 'building') {
    const _autoAmenities = data && _shouldShow(data.amenities_auto)
      ? _makeSlugifiedArr(data.amenities_auto)
      : [];

    const _storageAmenities = data && _shouldShow(data.amenities_building_storage)
      ? _makeSlugifiedArr(data.amenities_building_storage)
      : [];

    const _entertainmentAmenities = data && _shouldShow(data.amenities_entertainment)
      ? _makeSlugifiedArr(data.amenities_entertainment)
      : [];

    const _healthAmenities = data && _shouldShow(data.amenities_health)
      ? _makeSlugifiedArr(data.amenities_health)
      : [];

    const _outdoorSpaceAmenities = data && _shouldShow(data.amenities_outdoor_space)
      ? _makeSlugifiedArr(data.amenities_outdoor_space)
      : [];

    const _buildingAccess = data && _shouldShow(data.building_access)
      ? _makeSlugifiedArr(data.building_access)
      : [];

    const _buildingServiceLevel = data && _shouldShow(data.building_service_level)
      ? _makeSlugifiedArr(data.building_service_level)
      : [];

    const _buildingPolicies = data && _shouldShow(data.building_policies)
      ? _makeSlugifiedArr(data.building_policies)
      : [];

    const _buildingEco = data && _shouldShow(data.amenities_eco)
          ? _makeSlugifiedArr(data.amenities_eco)
          : [];

    const _laundry = data && _shouldShow(data.amenities_laundry)
          ? _makeSlugifiedArr(data.amenities_laundry)
          : [];

    const _commonSpace = data && _shouldShow(data.amenities_common_space)
          ? _makeSlugifiedArr(data.amenities_common_space)
          : [];

    const _buildingTechnology = data && _shouldShow(data.amenities_technology)
          ? _makeSlugifiedArr(data.amenities_technology)
          : [];
    
    amenitiesData = [..._autoAmenities, ..._storageAmenities, ..._entertainmentAmenities, ..._healthAmenities, ..._outdoorSpaceAmenities, ..._buildingAccess, ..._buildingServiceLevel, ..._buildingPolicies, ..._buildingEco, ..._laundry, ..._commonSpace, ..._buildingTechnology].filter((item, i, arr) => arr.indexOf(item) === i);
  }

  if(type == "unit") {
    compiledFeatureList = featuresData;
  } else if(isHouse) {
    compiledFeatureList = [...featuresData, ...amenitiesData];
  } else {
    compiledFeatureList = amenitiesData;
  }

// console.log('featuresData returned ', featuresData)
// console.log('amenitiesData returned ', amenitiesData)
// console.log('compiledFeatureList returned ', compiledFeatureList)
  return compiledFeatureList;
}
export const  _formatPropertyType = (property_type) => { 
 	let propertyTypeTranslate = {
			"single residential condo unit" : "condo unit",
			"single residential coop unit" : "coop unit"
			
		}
	return property_type ? propertyTypeTranslate[property_type] ? _capitalizeText(propertyTypeTranslate[property_type]) : _capitalizeText(property_type) : ''
}

export const _truncateWords = (str, no_words) => {
	if(str.split(" ").length>no_words){
		return str.split(" ").splice(0,no_words).join(" ")+'...';
	}
    return str;
}
export const _calPercentage = (num, perc) => {
  const percentOfNum = parseInt(num) * perc / 100;
  return percentOfNum;
}
export const _checkFieldForValue = (data, field, value) => {
  if(!data[field] || !value || !_shouldShow(data[field])) return;

  console.log("_checkFieldForValue called field", field, "data[field]", data[field], "value",value);

  let found = false;

  if(Array.isArray(data[field])) {
    found = !Array.isArray(value)
      ? data[field].filter(el => el.includes(value)).length > 0
      : data[field].reduce((acc, curr) => { 
        console.log("acc", acc, "curr", curr, 'value.indexOf(curr)', value.indexOf(curr))
        if(value.indexOf(curr) > -1) {
          acc.push(curr)
        } 
        return acc;
      }, []).length > 0
      //   el => value.filter(item => item.includes(el))).length > 0
      // }
      // : data[field].map(el => value.filter(item => item.includes(el))).length > 0
        // : false;

    console.log("field and value arr", data[field].reduce((acc, curr) => { if(value.indexOf(curr) > -1 && acc.length === 0) {
          acc.push(curr)
        } 
        return acc;}, []))
  
  } else {
    found = !Array.isArray(value)
      ? data[field].includes(value)
      : value.filter(el => el.indexOf(data[field]) > -1).length > 0;
    console.log("field no arr and value arr", data[field].includes(value), value.filter(el => el.indexOf(data[field]) > -1))
  }  

  console.log("found", found)
  return found;
}
export const _isCCC = building => {
  var is_ccc = false;
  var cccTypes = ["condo", "coop", "condop", "co op", "co-op"];
 
  if (
    building &&
    building.ownership_type &&
    _matchArray(cccTypes, building.ownership_type)
  ) {
    is_ccc = true;
  }
  return is_ccc;
};

export const HouseCurrentStatus = (props) => {
  const { unit, expanded } = props;
  const width=110;
  switch(unit.current_status){
    case "active" :
    return <Badge color="available" style={{width:expanded ? "auto" : width}}>Listed</Badge>
      break;
    case "in contract" :
    case "pending" :
              case "active under contract":
      return <Badge color="contract" style={{width:expanded ? "auto" : width}}>In Contract</Badge>
      break;
    case "coming soon" :
      return <Badge color="sold-transfer" style={{width:expanded ? "auto" : width}}>Coming Soon</Badge>
      break;
    default : 			
      return <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Off Market</Badge>
      break;
  }

}
export const UnitCurrentStatus = (props) => {
	const { unit, expanded } = props;
	const width=110;
	let type = "Other";
	if(!_isResiUnit(unit)){
		
		const typeMap = {
			"Amenity" : ["gym", "common", "lobby", "pool", "entertainment"],
			"Parking" : ["parking", "garage"],
			"Storage" : ["storage"],
			"Commercial" : ["commercial", "office", "retail"]
		}
		
		Object.keys(typeMap).forEach(key => {
			typeMap[key].forEach(match => {
				if(unit.property_type.indexOf(match)>-1){
					 type = key
				}
			})
		})
		return <Badge color="not-applicable" style={{width:expanded ? "auto" : width}}>{type}</Badge>
	}
	
	if(unit.offering_submitted_date){
		
		let offering_submitted_year = parseInt(moment(unit.offering_submitted_date).format('YYYY'));
		if(offering_submitted_year > 1970 && offering_submitted_year <= 2010 && unit.current_status === "shadow"){
			return <Badge color="sold" style={{width:expanded ? "auto" : width}}>Sold</Badge>
		}
		
	}
	
	
	if(unit.source_organizations.filter(source => ['NYAG', 'CDEC', 'ADEC'].indexOf(source) > -1).length > 0){
			if(unit.property_status === "sponsor"){
				switch(unit.current_status){
					case "active" :
					return <Badge color="available" style={{width:expanded ? "auto" : width}}>Sponsor Listed</Badge>
						break;
					case "in contract" :
					case "pending" :
                    case "active under contract":
						return <Badge color="contract" style={{width:expanded ? "auto" : width}}>Sponsor Contract</Badge>
						break;
					case "sold" :
					
					case "not_yet_recorded" :
						return <Badge color="sold" style={{width:expanded ? "auto" : width}}>{expanded ? "Sponsor Sold - Not Yet Recorded" : "Sponsor Sold"}</Badge>
						break;
					case "closed" :
					case "resale" :
						return <Badge color="sold" style={{width:expanded ? "auto" : width}}>Sponsor Sold</Badge>
						break;
					case "bulk sold" :
						return <Badge color="sold" style={{width:expanded ? "auto" : width}}>Bulk Sold</Badge>
						break;
					case "transfer" :
						return <Badge color="sold-transfer" style={{width:expanded ? "auto" : width}}>{expanded ? "Non-Market Transferred" : "Transferred"}</Badge>
						break;
					default : 			
						return unit && unit.offering_construction === 'conversion' ?  <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Conversion</Badge> : <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Shadow</Badge>
						break;
				}
				
			}else{
				switch(unit.current_status){
					case "active" :
					return unit.times_sold>=1 ? <Badge color="available" style={{width:expanded ? "auto" : width}}>Resale</Badge> : <Badge color="available" style={{width:expanded ? "auto" : width}}>Listed</Badge>
						break;
					case "in contract" :
					case "pending" :
                    case "active under contract":
						return <Badge color="contract" style={{width:expanded ? "auto" : width}}>In Contract</Badge>
						break;
					case "shadow" :
						return <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Shadow</Badge>
						break;
					case "sold" :
					case "not_yet_recorded" :
						return  <Badge color="sold" style={{width:expanded ? "auto" : width}}>{unit.times_sold > 1 ? 'Sold' : 'Sponsor Sold'}</Badge>
						break;
					case "bulk sold" :
						return  <Badge color="sold" style={{width:expanded ? "auto" : width}}>{unit.times_sold >= 1 ? 'Sold' : 'Bulk Sold'}</Badge>
						break;
					case "transfer" :
						return <Badge color={unit.times_sold <=0 ? 'sold-transfer' : 'sold'} style={{width:expanded ? "auto" : width}}>{unit.times_sold >= 1 ? 'Sold' : expanded ? "Non-Market Transferred" : "Transferred"}</Badge>	
					
		/*
					case "not_yet_recorded" :
						return <Badge color="sold">{expanded ? "Sold - Not Yet Recorded" : "Sold"}</Badge>
						break;
		*/
		
					default : 			
						return  <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Shadow</Badge>
						break;
				}
			}
		
	}else if(unit.ownership_type && unit.ownership_type == "coop") {
       switch(unit.current_status){
         case "active" :
         return <Badge color="available" style={{width:expanded ? "auto" : width}}>Listed</Badge>
           break;
         case "in contract" :
         case "pending" :
                   case "active under contract":
           return <Badge color="contract" style={{width:expanded ? "auto" : width}}>In Contract</Badge>
           break;
         case "shadow" :
           return <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Not Active</Badge>
           break;
         case "sold" :
         case "not_yet_recorded" :
           return  <Badge color="sold" style={{width:expanded ? "auto" : width}}>{'Recently Sold'}</Badge>	
         case "bulk sold" :
           return  <Badge color="sold" style={{width:expanded ? "auto" : width}}>{unit.times_sold >= 1 ? 'Recently Sold' : 'Bulk Sold'}</Badge>
           break;
         case "transfer" :
           return <Badge color={unit.times_sold <=0 ? 'sold-transfer' : 'sold'} style={{width:expanded ? "auto" : width}}>{unit.times_sold >= 1 ? 'Sold' : expanded ? "Non-Market Transferred" : "Transferred"}</Badge>	
         default : 			
           return  <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Shadow</Badge>
           break;
       }
    
    } else{
		switch(unit.current_status){
					case "active" :
					return <Badge color="available" style={{width:expanded ? "auto" : width}}>Listed</Badge>
						break;
					case "in contract" :
					case "pending" :
                    case "active under contract":
						return <Badge color="contract" style={{width:expanded ? "auto" : width}}>In Contract</Badge>
						break;
					case "shadow" :
						return <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Shadow</Badge>
						break;
					case "sold" :
					case "not_yet_recorded" :
						return  <Badge color="sold" style={{width:expanded ? "auto" : width}}>{unit.times_sold > 1 ? 'Sold' : 'Sponsor Sold'}</Badge>	
					case "bulk sold" :
						return  <Badge color="sold" style={{width:expanded ? "auto" : width}}>{unit.times_sold >= 1 ? 'Sold' : 'Bulk Sold'}</Badge>
						break;
					case "transfer" :
						return <Badge color={unit.times_sold <=0 ? 'sold-transfer' : 'sold'} style={{width:expanded ? "auto" : width}}>{unit.times_sold >= 1 ? 'Sold' : expanded ? "Non-Market Transferred" : "Transferred"}</Badge>	
					default : 			
						return  <Badge color="shadow" style={{width:expanded ? "auto" : width}}>Shadow</Badge>
						break;
				}
		
	}
	
	
	
	
}

export const StatusBadge = (item) => {
	
	switch(item.status){
		case "active" :
			return <Badge color="available">{item.expanded ? "Sponsor Sale " : "Active"}</Badge>
			break;
		case "in contract" :
		case "pending" :
        case "active under contract":
			return <Badge color="contract">Contract</Badge>
			break;
		case "sold" :
		
		case "not_yet_recorded" :
		case "closed" :
		case "resale" :
			return <Badge color="sold">{item.expanded ? "Sponsor " : ""}Sold</Badge>
			break;
		default : 			
			return item.unit && item.unit.offering_construction === 'conversion' ?  <Badge color="shadow">Conversion</Badge> : <Badge color="shadow">Shadow</Badge>
			break;
	}
	
	
}
export const BuildingCurrentStatus = ({ item }) => {
  
  if(item['building_status'] == "resale"){
    if(item['active_listings_sale'] > 0){
      return(
        <div className="todo__badge-container">
          <Badge color="available">{item['active_listings_sale']} Active Listings</Badge>
        </div>
      )
    }else{
      return(<div>
        <Badge>No Units Listed</Badge>
      </div>)
    }
  }

  if (moment(item["building_effective_start"]).year() > 1970 || item.building_status == "resale" || moment(item["first_closing_date"]).year() > 1970) {
    return (
	 <div className="todo__badge-container">
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};

export const buildingStageToolTips = {
	  'sponsor' : 'Building has an accepted offering plan, is currently selling, and is controlled by the Sponsor.',
	  'resale' : 'Building is controlled by the owners and less than 10 years old.',
	  'pipeline' : 'Building is planned or built and has submitted an offering plan to state regulators.',
	  'all' : 'All new development condos & co-ops with offering accepted in last 10 years.',
	  
}

export const unitStageToolTips = {
	  'sponsor' : 'Units owned by the Sponsor.',
	  'resale' : ' Units that have been sold by the Sponsor.',
	  'unverified' : 'Units that have not been verified.',
	  'all' : 'All sponsor, resale and unverified units.',
	  
}

export const BuildingStageBadge = ({ item, small }) => {
  if (item["building_status"]) {
	if(small){
		return (
		    <Tooltip title={`${buildingStageToolTips[item["building_status"]]}`}>
	  			<IconButton size={`small`} style={{background:"transparent"}}>
	  				<span className="unit-type_badge">{_capitalizeText(item.building_status)} {item.ownership_type && _capitalizeText(item.ownership_type)}</span>
	  			</IconButton>
	  		</Tooltip>
	    );
	}else{
		return (
		    <Tooltip title={`${buildingStageToolTips[item["building_status"]]}`}>	
				<IconButton size={`small`}>
					<Badge color="stage">{_capitalizeText(item["building_status"])} Stage {item.ownership_type && _capitalizeText(item.ownership_type)}</Badge>
				</IconButton>
			</Tooltip>
	    );
	}
    
  }else{
    if(small){
      return (
          <Tooltip title={`${buildingStageToolTips[item["building_status"]]}`}>
            <IconButton size={`small`} style={{background:"transparent"}}>
              <span className="unit-type_badge">{item.ownership_type && _capitalizeText(item.ownership_type)}</span>
            </IconButton>
          </Tooltip>
        );
    }else{
      return (
          <Tooltip title={`${buildingStageToolTips[item["building_status"]]}`}>	
          <IconButton size={`small`}>
            <Badge color="stage">{item.ownership_type && _capitalizeText(item.ownership_type)}</Badge>
          </IconButton>
        </Tooltip>
        );
    }
  } 
  return null;
};

export const BuildingPhoto = ({ item, bustCache }) => {
	const buildingImages = _getBuildingPhotos(item, '');
  let curtime = Date.now();
	return (
		buildingImages[0].indexOf('googleapis')>=0 ? 
		   <div className="todo__lead-image">
		   		<img className="lead-image" src={`${buildingImages[0]}${bustCache ? "?cachebust="+curtime : ""}`} />
		   </div>
	      :
          <div className="todo__lead-image" style={{ backgroundImage: "url('" + buildingImages[0] + (bustCache ? "?cachebust="+curtime : "") + "')" }} />
        
		
	)
	
}

export const SalesProgress = ({ progress, inventory_unsold, inventory_total, maxWidth, label, min, color, resale, size } ) => { 
	
	if(resale){
		return (
	      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :120 }} >
	        <div className="dashboard__stat">
	          <div className={`progress-wrap progress-wrap--${size ? size : 'small'} progress-wrap--grey-gradient progress-wrap--rounded`}>
	          	<p className="progress__label">Resale Stage</p>
	          	<Progress value={0} />
	          </div>
	        </div>
	      </div>
	    );
	}else if(parseInt(inventory_total) > 0){
		return (
	      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :120 }} >
	        <div className="dashboard__stat">
	          <div className={`progress-wrap progress-wrap--${size ? size : 'small'} progress-wrap--${color ? color : 'purple'}-gradient progress-wrap--rounded`}>
	          {min ? 
	            <p className="progress__label">{progress > 0 ? progress : 0}% Unsold</p>
	           :
	            <p className="progress__label">{inventory_unsold} of {inventory_total} {label ? label : 'Units'} ({progress}%)</p>
	           }
	            <Progress value={progress} />
	          </div>
	        </div>
	      </div>
	    );
		
	}else{
		return (
	      <div className={`dashboard__stat-item`} style={{ maxWidth: maxWidth ? maxWidth :120 }} >
	        <div className="dashboard__stat">
	          <div className={`progress-wrap progress-wrap--${size ? size : 'small'} progress-wrap--grey-gradient progress-wrap--rounded`}>
	          	<p className="progress__label">N/A</p>
	          	<Progress value={0} />
	          </div>
	        </div>
	      </div>
	    );
	}
    

}

export const _formatLatLong = data => {
  if (!data) {return;}
  let mapPoints = {};
  if (typeof data === 'string') {
    mapPoints.lat = parseFloat(data.substring(0, data.indexOf(",")));
    mapPoints.long = parseFloat(
      data.substring(data.indexOf(",") + 1, data.length)
    );
  } else {
    mapPoints.lat = data[0]
    mapPoints.long = data[1]
  }
  return mapPoints;
};

export const _formatCoords = polyCoords => {
  if (polyCoords !== undefined && polyCoords != "") {
    let coords = JSON.parse(polyCoords).coordinates;
    //console.log(coords);
    let formattedCoords = [];
    coords.forEach(function(coord) {
      coord.forEach(function(item) {
        formattedCoords.push({ lat: item[1], lng: item[0] });
      });
    });

    //console.log(formattedCoords);
    return formattedCoords;
  }

  return false;
};

export const _formatCoordsMulti = polyCoords => {
  if (polyCoords !== undefined && polyCoords != "") {
    let poly = JSON.parse(polyCoords);
    let formattedMultiCoords = [];
    let multiCoords = poly.coordinates;
    if (poly.type.toLowerCase() == "multipolygon") {
      multiCoords.forEach(function(coords) {
        let formattedCoords = [];
        coords.forEach(function(coord) {
          coord.forEach(function(item) {
            formattedCoords.push({ lat: item[1], lng: item[0] });
          });
        });
        formattedMultiCoords.push(formattedCoords);
      });
    } else {
      let formattedCoords = [];
      multiCoords.forEach(function(coord) {
        coord.forEach(function(item) {
          formattedCoords.push({ lat: item[1], lng: item[0] });
        });
      });
      formattedMultiCoords.push(formattedCoords);
    }

    // console.log(formattedMultiCoords);
    return formattedMultiCoords;
  }

  return false;
};

export const _getUnitFloorPlan = (property) => {
	
	let images = [];
    if(property && property.offering_floorplans){
	    try{
			const plans = JSON.parse(property.offering_floorplans);
			
			images = plans.filter(plan => (plan.url.indexOf("cloudfront") >=0 || plan.url.indexOf("we3") >=0 || plan.url.indexOf("marketproof") >=0 || plan.url.indexOf("blankslate") >=0)).map(plan => {
			  plan['src'] = plan['url'].replace("cdn-images.we3.com", "dc2nzxe4zztjf.cloudfront.net").replace("cdn.images.we3.com", "dmaaf11jcu066.cloudfront.net");
			  plan['caption'] = `Floor Plan - ${_formatUnitNumber(property.address_2_normalized)}`;
			  return plan;
			})
		}catch(e){
			
		}
    }
    if(images.length <= 0){
	    if(property && property.floorplans && property.floorplans.length>0 && property.floorplans.filter(plan => plan.indexOf('we3')>=0 || plan.indexOf('marketproof')>=0).length>0){
		    
			images = property.floorplans.filter(plan => plan.indexOf('we3')>=0 || plan.indexOf('marketproof')>=0).slice(0,1).map(item => {
			  let plan = {};
			  plan['src'] = item.replace("cdn-images.we3.com", "dc2nzxe4zztjf.cloudfront.net").replace("cdn.images.we3.com", "dmaaf11jcu066.cloudfront.net");
			  plan['caption'] = `Floor Plan - ${_formatUnitNumber(property.address_2_normalized)}`;
			  return plan;
			})
		

    	}
    }
    
    if(images.length <= 0){
	    if(property && property.floorplans && property.floorplans.length>0){
		    
			images = property.floorplans.filter(plan => (plan.indexOf("cloudfront") >=0 || plan.indexOf("we3") >=0 || plan.indexOf("marketproof") >=0 || plan.indexOf("blankslate") >=0)).slice(0,1).map(item => {
			  let plan = {};
			  plan['src'] = item.replace("cdn-images.we3.com", "dc2nzxe4zztjf.cloudfront.net").replace("cdn.images.we3.com", "dmaaf11jcu066.cloudfront.net");
			  plan['caption'] = `Floor Plan - ${_formatUnitNumber(property.address_2_normalized)}`;
			  return plan;
			})
		

    	}
    }
    
    return images.length > 0 ? images : false;
}

export const _qaThreadMap = {
	'b-1-240-south-street-10002' : '5e667767e2fd073fc0fd4b22',
	'b-4-2315-44th-dr-11101' : '5e66a81b1e706c0f42536414'
}

export const _privateKey = 'mpbuyerslist';

export const subscriptionPlans = [
	/*{
		id: 'buyerslist_agent_subscription_yearly',
		name: 'Annual',
		pricePerMonth:20,
		pricePerYear:240,
		billingFrequency:'year',
		billingFrequencyMonths:12,
		description:'Save $120 / Year',
		totalAtCheckout:240,
		taxCode:'txr_1HCVNJG44QC64PsRdrtshCba'
	},
	{
		id: 'buyerslist_agent_subscription_monthly',
		name: 'Monthly',
		pricePerMonth:30,
		pricePerYear:360,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'$360 / Year',
		totalAtCheckout:30,
		taxCode:'txr_1HCVNJG44QC64PsRdrtshCba'
	}*/
	{
		id: process.env.NODE_ENV === "development" ? 'price_1HZi3xG44QC64PsROMcvDFxz' : 'price_1HZi0JG44QC64PsRACC9qKor',
		name: 'Basic',
		checkTitle: '7-Day Free Trial',
		pricePerMonth:14.99,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Free for 7 days, cancel anytime.',
		totalAtCheckout:14.99,
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba'
	},
	{
		id: process.env.NODE_ENV === "development" ? 'price_1I4X7xG44QC64PsRJfBpWfQi' : 'price_1I5u44G44QC64PsRZq0J3cjy',
		name: 'Advanced',
		checkTitle: '7-Day Free Trial',
		pricePerMonth:49,
		billingFrequency:'month',
		billingFrequencyMonths:1,
		description:'Free for 7 days, cancel anytime.',
		totalAtCheckout:49,
		taxCode: process.env.NODE_ENV === "development" ? 'txr_1HPuUwG44QC64PsRi91xssbx' : 'txr_1HCVNJG44QC64PsRdrtshCba'
	}
]

export const BuildingStatus = ({ item }) => {
 
  if(['active'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="success">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['sold out'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="complete">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['coming soon'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="available">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  if(['offering plan required'].indexOf(item.offering_verified_status) >= 0){
	  return (
	    <div>
	      <Badge color="buyerslist">{_capitalizeText(item.offering_verified_status)}</Badge>
	    </div>
	  );
  }
  return (
    <div>
      <Badge>{_capitalizeText(item.offering_verified_status)}</Badge>
    </div>
  );
};


export const _checkBuildingNameVsAdr = hit => {
  if (!hit.name) {
    return false;
  }

  let fullName = hit.name
    ? _replaceAbbreviations(hit.name)
        .toLowerCase()
        .trim()
    : "";

  if (fullName.endsWith(", the")) {
    fullName = "the " + fullName.replace(", the", "");
  }

  const fullAdr = hit.display_full_street_address
    ? _replaceAbbreviations(hit.display_full_street_address)
        .toLowerCase()
        .trim()
    : "";
  const displayAdr = hit.building_display
    ? _replaceAbbreviations(hit.building_display)
        .toLowerCase()
        .trim()
    : "";

  let checkedName =
    fullAdr.indexOf(fullName) >= 0 ||
    displayAdr.indexOf(fullName) >= 0 ||
    displayAdr.replace("-", "").indexOf(fullName) >= 0
      ? false
      : fullName;

  return checkedName;
};

export const _selectBestSlug = (building) => {
  if (building.display_full_street_address) {
	  var display_address = building.display_full_street_address
	  var building_slugs = building.building_slugs && Array.isArray(building.building_slugs) ? building.building_slugs : [];
	  var streetNumberDisplay = display_address.split(" ")[0];
	  var streetName = display_address.split(" ")[1].toLowerCase();
	  var building_slug = building_slugs[0];

	  var matchFound = false;
	  for (var i = 0; i < building_slugs.length; i++) {
	    var buildingSlug = building_slugs[i];
	    var buildingSlugNumber = buildingSlug.split("-")[0];
	    var buildingSlugStreet = buildingSlug.split("-")[1].toLowerCase();


	    if(!matchFound && streetNumberDisplay == buildingSlugNumber){
		    building_slug = building_slugs[i];
		    matchFound = true;
		}
		if(matchFound && streetNumberDisplay === buildingSlugNumber && streetName === buildingSlugStreet){
			building_slug = building_slugs[i];
		}

	  }

	  building.building_slugs.unshift(building_slug);
	  building.building_slugs = [...new Set(building.building_slugs)];
  }

  return building;
};

//CHANGES DISPLAY ADR IN ALGOLIA TO WHAT USER IS QUERYING BY
export const _formatAddressAutoComplete = (hit, query) => {
  if (query !== undefined && query.length < 3) {
    return hit;
  }

  if (
    hit.alternate_addresses === undefined ||
    hit.alternate_addresses.length <= 0 ||
    !_shouldShow(hit.alternate_addresses)
  ) {
    return hit;
  }

  String.prototype.capitalize = function() {
    return this.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const altAdrsUnAbbr = Array.isArray(hit.alternate_addresses) 
    ? hit.alternate_addresses.map(adr => {
      return _formatOrdinalsUnAbbrAdr(adr);
      // return _replaceAbbreviations(adr)
    }) 
    : _formatOrdinalsUnAbbrAdr(hit.alternate_addresses)
    // : _replaceAbbreviations(hit.alternate_addresses);
   

    // if(query === 'one manhattan west') {console.log("altAdrsUnAbbr", altAdrsUnAbbr, altAdrsUnAbbr.includes('one manhattan west')) }

  // IF THE DISPLAY_ADR ISN'T IN THE ALT ADR IT IS MOST LIKELY WRONG SO REASSIGN IT TO THE ALT ADR[0]
  if (!altAdrsUnAbbr.includes(_formatOrdinalsUnAbbrAdr(hit.display_full_street_address))) {
    // if(query === 'one manhattan west') {console.log("changing display", hit.display_full_street_address, _formatOrdinalsUnAbbrAdr(hit.display_full_street_address)) }
    
    hit.display_full_street_address = _formatOrdinalsUnAbbrAdr(hit.alternate_addresses[0]);
    // if(query === 'one manhattan west') {console.log("after", hit.display_full_street_address) }
  }

  let fullDisplayAdr = 
  // _capitalizeText(
    _formatOrdinalsUnAbbrAdr(hit.display_full_street_address);
    // _replaceAbbreviations(hit.display_full_street_address)
  // );

  // if(query === 'one manhattan west')console.log('fullDisplayAdr', fullDisplayAdr, 'hit.display_full_street_address', hit.display_full_street_address, 'altAdrsUnAbbr', altAdrsUnAbbr)
  let nameDisplay = hit.name ? hit.name.toLowerCase() : "";
  // if(query === 'one manhattan west')console.log('nameDisplay', nameDisplay)
  if (
    query &&
    fullDisplayAdr &&
    (fullDisplayAdr.indexOf(_formatOrdinalsUnAbbrAdr(query)) >= 0 ||
      nameDisplay.indexOf(_formatOrdinalsUnAbbrAdr(query)) >= 0)
  ) {
    if (hit.borough == "queens" && hit.display_full_street_address) {
      hit.display_full_street_address = _formatQueensAddress(
        hit.display_full_street_address,
        hit.alternate_addresses
      );
    }
    // if(query === 'one manhattan west')console.log('returning hit')
    return hit;
  }

  let name_best_match_words = false;
  
  // name match
  if(hit._highlightResult && hit._highlightResult.name && hit._highlightResult.name.matchedWords &&
        Array.isArray(hit._highlightResult.name.matchedWords) &&
        hit._highlightResult.name.matchedWords.length > 0)
  {
    name_best_match_words = hit._highlightResult.name.matchedWords.length;
    // if(query === 'one manhattan west') {console.log("name_best_match_words", name_best_match_words) }

  } 
  
  // alt adr match
  if (
    hit._highlightResult &&
    Array.isArray(hit._highlightResult.alternate_addresses)
  ) {
    let alt_adr_best_match_index = -1;
    let alt_adr_best_match_words = -1;
    for (var i = 0; i < hit._highlightResult.alternate_addresses.length; i++) {
      var alt_addy = hit._highlightResult.alternate_addresses[i];
      // if(query === 'one manhattan west') {console.log("alt_addy", alt_addy, "alt_addy.matchedWords", alt_addy.matchedWords) }
      
      if (
        alt_addy.matchedWords &&
        Array.isArray(alt_addy.matchedWords) &&
        alt_addy.matchedWords.length > 0 &&
        alt_addy.matchedWords.length > alt_adr_best_match_words
      ) {
        alt_adr_best_match_index = i;
        alt_adr_best_match_words = alt_addy.matchedWords.length;
        // if(query === 'one manhattan west') {console.log("alt_adr_best_match_index", alt_adr_best_match_index, "alt_adr_best_match_words", alt_adr_best_match_words) }
      }
    }
    
    // if(query === 'one manhattan west') {console.log("name_best_match_words exists herre", name_best_match_words, alt_adr_best_match_words) }
    
    if(alt_adr_best_match_index >= 0) {
      if(name_best_match_words && name_best_match_words >= alt_adr_best_match_words) {
        // if(query === 'one manhattan west') {console.log("name >= alt_adr_best_match_words", name, alt_adr_best_match_words, name >= alt_adr_best_match_words, ) }
        
        // if(fullDisplayAdr == _formatOrdinalsUnAbbrAdr(hit.display_full_street_address)) {
          return hit;
        // } else {

        // }
        
      } else if ((!name_best_match_words || name_best_match_words && name_best_match_words < alt_adr_best_match_words)) {
        var address_unabbrev = _formatOrdinalsUnAbbrAdr(
          hit.alternate_addresses[alt_adr_best_match_index]
        );

        hit.original_display_text = _capitalizeText(fullDisplayAdr);
        hit.building_display = _capitalizeText(address_unabbrev);
        // if(query === 'one manhattan west') {console.log("building_display", hit.building_display) }

        if (
          fullDisplayAdr != address_unabbrev &&
          // fullDisplayAdr.toLowerCase() != address_unabbrev.toLowerCase() &&
          fullDisplayAdr != ""
        ) {
          hit.primary_adr = false;
        }
      }
    }
    // if (best_match_index >= 0) {
    //   var address_unabbrev = _formatOrdinalsUnAbbrAdr(
    //     hit.alternate_addresses[alt_adr_best_match_index]
    //   );

    //   hit.original_display_text = _capitalizeText(fullDisplayAdr);
    //   hit.building_display = _capitalizeText(address_unabbrev);
    //   if(query === 'one manhattan west') {console.log("building_display", hit.building_display) }

    //   if (
    //     fullDisplayAdr != address_unabbrev &&
    //     // fullDisplayAdr.toLowerCase() != address_unabbrev.toLowerCase() &&
    //     fullDisplayAdr != ""
    //   ) {
    //     hit.primary_adr = false;
    //   }
    // }
  }

  if (!hit.building_display) {
    hit.building_display = _capitalizeText(fullDisplayAdr);
  }

  if (hit.borough == "queens" && hit.building_display) {
    hit.building_display = _formatQueensAddress(
      hit.building_display,
      hit.alternate_addresses
    );
  }

  return hit;
};
//return empty string for array data values if undefined or '' is present - ONLY TAKING FIRST VALUE
export const _validArrTags = arr => {
  let validVal =
    arr !== undefined ? (arr.length > 0 ? (arr[0] ? arr[0] : "") : "") : "";
  return validVal;
};

//return empty string for string data values if undefined or '' is present
export const _validStrTags = str => {
  let validStr = str !== undefined ? (str !== "" ? str : "") : "";
  return validStr;
};


export const _ordinalSuffix = i => {
  // console.log('i', i, typeof i, i.toString())
  const numI = parseInt(i) && 
               (i.input ? !i.input.includes('th') : !i.toString().includes('th')) &&
               (i.input ? !i.input.includes('st') : !i.toString().includes('st')) &&
               (i.input ? !i.input.includes('nd') : !i.toString().includes('nd')) &&
               (i.input ? !i.input.includes('rd') : !i.toString().includes('rd')) 
    ? parseInt(i) 
    : false;
  // if(i == 1)console.log('_ordinalSuffix called', i, 'numI', numI)

  if(!numI) return;
  
  var j = numI % 10,
    k = numI % 100;
  if (j == 1 && k != 11) {
    return numI + "st";
  }
  if (j == 2 && k != 12) {
    return numI + "nd";
  }
  if (j == 3 && k != 13) {
    return numI + "rd";
  }
  return numI + "th";
};

export const _formatOrdinalsUnAbbrAdr = (adr) => {
  if(!adr || !_shouldShow(adr)) {return};
  // console.log('_formatOrdinalsUnAbbrAdr called', adr);
  let formattedAdr = adr.replace("-", "").replace(/\s\s+/g, " ").toLowerCase().trim();
  const splitStr = formattedAdr.split(" ");
  // console.log('splitStr', splitStr)
  
  const nameStart = parseInt(splitStr[1]) && Number(splitStr[1]) >= 1
    ? 1
    : splitStr[2] && parseInt(splitStr[2]) && Number(splitStr[2]) >= 1 
      ? 2
      : false;
  const strName = nameStart
    ? splitStr[nameStart]
    : false;

  // parseInt(splitStr[1]) && Number(splitStr[1]) >= 1
  //   ? splitStr[1]
  //   : splitStr[2] && parseInt(splitStr[2]) && Number(splitStr[2]) >= 1 
  //     ? splitStr[2]
  //     : false;
  // console.log('strName', strName)

  const endOfName = strName && nameStart
    ? splitStr.slice(nameStart + 1).join(" ")
    : false;
  // const strName = formattedAdr.substr((formattedAdr.indexOf(' ') + 1), formattedAdr.length);
  
  const strNameNum = strName ? _ordinalSuffix(strName.match(/\d+/)) : false;
  formattedAdr = strNameNum 
          ? splitStr[0] + " " + (nameStart && nameStart > 1 ? splitStr[1] + ' ' : '') +  strName.replace(/\d+/, strNameNum) + " " + (endOfName ? endOfName : '')
          : formattedAdr;
  
  // console.log('adr', adr, 'strName', strName, 'strNameNum', strNameNum, 'formattedAdr', formattedAdr, )
  // console.log('replacedAbr formatted', _replaceAbbreviations(formattedAdr.replace(/\s\s+/g, " ")).trim())
  return _replaceAbbreviations(formattedAdr.replace(/\s\s+/g, " ")).trim()

}
export const _parseToPolygonQuery = polygon => {
  const result = "geocode:\"Intersects(POLYGON((";
  const polygonString = polygon.map(coord => coord.join(" ")).join(", ");
  
  return `${result}${polygonString})))\"`;
};

export const _tranformTagsQuery = (searchTags, buildingKey) => {
	let q = '';
	let query = [];
	let attrQuery = [];
	
	buildingKey = buildingKey ? buildingKey : 'alternate_keys';
	searchTags && searchTags.map(tag => {
		if(tag.type==='borough'){
			query.push(`borough:"${tag.data}"`);
		}else if(tag.type==='neighborhood'){
			query.push(`((neighborhood:"${tag.data.hood}" OR markets_major:"${tag.data.hood}") AND borough:"${tag.data.boro}")`);
		}else if(tag.type==='building'){
			query.push(`(${buildingKey}:"${tag.data.key}")`);
		}else if(tag.type==='area'){
          console.log(tag.data)
          if(tag.data && tag.data.coordinates && tag.data.coordinates[0]){
            query.push(_parseToPolygonQuery(tag.data.coordinates[0]))
          }else if(tag.data && Array.isArray(tag.data) && tag.data.length>0){
            const polySearches = tag.data.map(poly => _parseToPolygonQuery(poly.coordinates[0]));
            query.push(`(${polySearches.join(" OR ")})`);
          }
            
        }else{
			attrQuery.push(`${tag.type}:*${tag.data.replace(/ /g, "\\ ")}*`);
		}	
		
	});
	
	if(query.length>0){
		q = '(' + query.join(' OR ') + ')';
	}
	
	if(attrQuery.length>0){
		if(q != ''){
			q = q + ' AND (' + attrQuery.join(' OR ') + ')';
		}else{
			q = '(' + attrQuery.join(' OR ') + ')';
		}
		
	}
	return q;		
}


export const searchBarStyles = makeStyles(theme => ({
  appBar: {
	backgroundColor:'#A3ADC2 !important',
	transform:'none !important',
	color:'#FFF !important'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0, //theme.spacing(3),
      width: '90%',
      maxWidth:800
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7) + ' !important',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    color:'#FFF !important'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
export const searchBarStylesPurple = makeStyles(theme => ({
  appBar: {
  backgroundColor:'#780F9E !important',
  transform:'none !important',
  color:'#FFF !important'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0, //theme.spacing(3),
      width: '90%',
      maxWidth:800
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7) + ' !important',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    color:'#FFF !important'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export const setLocalStorage = (lsPre, key, value)  => {
	if(value === undefined || value === null) return false;	
	let store = value;
	if(typeof value === 'object'){
		store = JSON.stringify(value);
	}
	if(localStorage) localStorage.setItem("mpnd_"+lsPre+key, store);	
	
}

export const getLocalStorage = (lsPre, key)  => {	
	if(localStorage){
    let sKey = "mpnd_"+lsPre+key;
		let value = localStorage.getItem(sKey);
    
		try{
			value = JSON.parse(value);
		}catch(e){
			
		}
		return value;

	} 	
	return null;	
}

export const deleteLocalStorage = (lsPre, key)  => {	
	if(localStorage){
		localStorage.removeItem("mpnd_"+lsPre+key);
	} 	
	return null;	
}

export const syncURLFilters = (props, filters, storageKey, lsPre, defaultFilters) => {
	  const { history, location } = props;
	  const subFilters = {};
	  let urlState = { ...queryString.parse(location.search) }
	  
	  if(Object.keys(urlState).length === 0){
		  if(storageKey){
			   const storage = getLocalStorage(lsPre, storageKey);			  
			   if(storage && typeof storage === 'object' && Object.keys(storage).length>0){
				   urlState = storage;
			   }else if(defaultFilters){
				   urlState = defaultFilters;	
			   }		  				
		  }else if(defaultFilters){			  
				urlState = defaultFilters;			  
		  }		  
	  }	  
	  
	  Object.keys(urlState).forEach((k) => {
		  let filterItem = filters.filter(filter => filter.search_key === k 
		  	|| (Array.isArray(filter.search_key) && filter.search_key.filter(sk => sk === k).length > 0));
	      if ( // don't save some state values if it meets the conditions below
	        !urlState[k] || // falsy
	        urlState[k] === "" || // string
	        (Array.isArray(urlState[k]) && !urlState[k].length) || // array
	        urlState[k] === subFilters[k] ||
	        filterItem.length<1 
	      ) {
	        delete urlState[k]; // drop query params with new values = falsy
	      }
	      
	      if(filterItem.length>0){
		      console.log("filterItem", filterItem); 
		      if(filterItem[0] && filterItem[0].type && filterItem[0].type === 'array'){
			      urlState[k] = [urlState[k]];
		      }
		      if(filterItem[0] && filterItem[0].multi_filter && filterItem[0].multi_filter && urlState[k]){
			  	  urlState[k] = urlState[k].toString().split(",");
			  }
              if(filterItem[0] && filterItem[0].autocomplete && filterItem[0].autocomplete && urlState[k]){
                  urlState[k] = urlState[k].toString().split(",");
              }
	      }
	      
	      
	  });	 
	  
	  console.log("syncURLFilters", urlState); 
	  return urlState;
}

export const syncStageFilters = (props, filters, storageKey, lsPre, defaultFilters) => {
	  const { history, location } = props;
	  const subFilters = {};
	  let urlState = { ...queryString.parse(location.search) }
	  
	  let stageValue = defaultFilters;
	  
	  if(Object.keys(urlState).length === 0){
		  if(storageKey){
			   const storage = getLocalStorage(lsPre, storageKey);			  
			   if(storage && typeof storage === 'object'){
				   stageValue = storage;
			   }else if(defaultFilters){
				   stageValue = defaultFilters;	
			   }		  				
		  }else if(defaultFilters){			  
				stageValue = defaultFilters;			  
		  }		  
	  }	  
	  
	  Object.keys(urlState).forEach((k) => {
		  let filterItem = filters.filter(filter => filter.search_key === k);
	      
	      if(filterItem.length>0){
		      if(filterItem[0] && filterItem[0].type && filterItem[0].type === 'array'){
			      stageValue = urlState[k].split(",");
		      }
	      }   
	  });	 
	  
	  return stageValue;
}
export const syncURLSearch = (props, key) => {
	  const { history, location } = props;
	  const urlState = { ...queryString.parse(location.search) }
	  
	  let val = '';
	  Object.keys(urlState).filter(k => key ? key === k : true).forEach((k) => {
	       val = urlState[k];
	  });	  
	  return val;
}

export const urlSlugBuilding = (building) => {
	return btoa(`${_slugifyText(building.key)}_${building.name ? _slugifyText(building.name) : _slugifyText(building.display_full_street_address)}`);
}

export const tranformSearchTags = (searchTags) => {
	let searchTagObj = {};
	const translateTag = (tag) => {
		if(tag.type === 'neighborhood'){
			return `${_slugifyText(tag.data.hood)}_${_slugifyText(tag.data.boro)}`;
		}else if(tag.type === 'borough'){
			return `${_slugifyText(tag.data)}`;
		}else if(tag.type === 'building'){
			return urlSlugBuilding(tag.data);
		}else if(tag.type === 'area'){
          return btoa(JSON.stringify(tag.data));
    }else if(['marketing_company', 'developers', 'architects', 'lenders'].indexOf(tag.type) >=0){
			return btoa(`${_slugifyText(tag.data)}_${tag.data.replace(/\s/, '-')}`);
		}
	}
	
	searchTags.map(tag => {
		if(searchTagObj[tag.type]){
			searchTagObj[tag.type].push(translateTag(tag))
		}else{
			searchTagObj[tag.type] = [translateTag(tag)]
		}
	})	
	return searchTagObj;
	
}
export const setURLFromState = (props, state, valOnly)  => {	
	console.log(state.groupedView);	
	  const urlState = Object.assign({}, state.subFilters, 
	  			state.buildingStages ? {'building_stage' : state.buildingStages} : {}, 
	  			state.unitStages ? {'unit_stage' : state.unitStages } : {}, 
	  			props.searchTags ? tranformSearchTags(props.searchTags) : {},  
	  			{searchText : state.searchText},
	  			state.eventTypes ? {'event_types' : state.eventTypes.map(item => _slugifyText(item)) } : {},
	  			state.dataStart ? {'start_date' : state.dataStart } : {} ,
	  			state.dataEnd ? {'end_date' : state.dataEnd } : {},
	  			state.soldTimeField ? {'sold_time' : state.soldTimeField } : {},
	  			state.gap ? {'gap' : state.gap.replace('+', '_plus_') } : {},
	  			state.interval ? {'interval' : state.interval } : {},
	  			state.selectedDate ? {'selected_date' : state.selectedDate } : {},
	  			state.period ? {'period' : state.period } : {},
                state.chartType ? {'chartType' : state.chartType } : {},
	  			state.order ? {'order' : state.order } : {},
	  			state.orderBy ? {'order_by' : state.orderBy } : {},
	  			state.groupedView !== undefined ? state.groupedView === true ? {'group_view' :  "building" } : {'group_view' :  "list" } : {},
	  	);
	  
	  	  
	  Object.keys(urlState).forEach((k) => {
	      if ( // don't save some state values if it meets the conditions below
	        !urlState[k] || // falsy
	        urlState[k] === "" || // string
	        k === "searchText" ||
	        (Array.isArray(urlState[k]) && !urlState[k].length)
	      ) {
	        delete urlState[k]; // drop query params with new values = falsy
	      }
	  });		
	  const searchUrl = queryString.stringify(urlState, {arrayFormat: 'comma'}); 	
	  const newSearch =  searchUrl ? `?${searchUrl}` : props.location.pathname;
	  
	  if(valOnly) return newSearch;
	  if(window.location.search !== newSearch){
		 // props.history.push({ search: newSearch });
		 //using window history to avoid component remounting
		 console.log(newSearch, valOnly, window.location.search);
		 window && window.history.pushState({}, '', newSearch);
	  }	  
}

export const syncGenericFilter = (filter_name, storageKey, lsPre, defaultValue, doCaps) => {
	  const subFilters = {};
	  let urlState = { ...queryString.parse(window.location.search) }
	  
	  let filterValue = defaultValue;
	  if(Object.keys(urlState).length === 0){
		  if(storageKey){
			   const storage = getLocalStorage(lsPre, storageKey);			  
			   if(storage && typeof storage === 'object'){
				   filterValue = storage;
			   }else if(defaultValue){
				   filterValue = defaultValue;	
			   }		  				
		  }else if(defaultValue){			  
				filterValue = defaultValue;			  
		  }		  
	  }	  
	  if(urlState[filter_name]){		  
		  let filterItem = urlState[filter_name];	      
	      if(filterItem){
		      if(Array.isArray(defaultValue)){
			      filterValue = filterItem.split(",").map(item => doCaps ? _capitalizeText(item.replace(/-/g, ' ')) : item);
		      }else if(filter_name === 'group_view'){
			      filterValue = {'group_view' : filterItem};
			      
			  }else{
			      filterValue = doCaps ? _capitalizeText(filterItem.replace(/-/g, ' ')) : filterItem.replace('_plus_', '+');
		      }
	      }   
	  };	 
	  
	  return filterValue;
}

export const mpndCohortQuery = `(offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_verified_status:(active "coming soon" "offering plan required" "offering plan acquired" "condo declaration vetted")) AND offering_verified_status:("" "monitoring" "reviewed" "offering plan required" "offering plan acquired" "condo declaration vetted" "vetting required" "vetted" "coming soon" "active" "sold out") AND deleted:false AND building_status:(sponsor)`;


export const comingSoonCohortQuery = `(offering_accepted_date:[2000-01-01T00:00:00Z TO 2010-11-01T00:00:00Z] OR (cdec_date:[2000-01-01T00:00:00Z TO NOW] AND offering_accepted_date:[* TO 1970-01-01T05:00:00Z])) AND deleted:false`;


export const resaleCohortQuery = `(ownership_type:(*condo*) AND -ownership_type:condop AND property_category:(resi* mixed*use) AND -full_street_address:"" AND -full_street_address:/.* garage .*/ AND number_of_buildings:[1 TO *] AND (condo_num:['1' TO *] OR (offering_verified_status:active AND building_status:resale)) AND ((tax_assessed_residential_units:[1 TO *] OR building_class:R3 OR (offering_verified_status:active AND building_status:resale)) AND -building_class:R9) AND -offering_verified_status:\"excluded\" AND deleted:false) AND building_status:resale`;

export const coopCohortQuery = 'ownership_type:(*co?op* *coop* condop*) AND number_of_buildings:[1 TO *] AND -ownership_occupancy_type:rental';

export const houseCohortQuery = '(ownership_type:(single?family multi?family) OR (ownership_type:rental AND total_units:[* TO 3])) AND total_units:[1 TO 6] AND address_2_normalized:"" AND -display_full_street_address:(*garage* *rear*)';

export const houseBuildingCohortQuery = '(ownership_type:(single?family multi?family) AND total_units:[1 TO 6]) OR (ownership_type:rental AND total_units:[* TO 3])';
/*Tax Assessment Fix*/
//export const resaleCohortQuery = `ownership_type:(*condo*) AND -ownership_type:condop AND property_category:(resi* mixed*use) AND -full_street_address:"" AND -full_street_address:/.* garage .*/ AND number_of_buildings:[1 TO *] AND (condo_num:['1' TO *]) AND -offering_verified_status:\"excluded\"`;

//`ownership_type:(*condo* *coop*) AND property_category:(residential mixed*use) AND -full_street_address:"" AND -full_street_address:/.* garage .*/ AND number_of_buildings:[1 TO *] AND (condo_num:['1' TO *] OR coop_num:['1' TO *])`;

//`ownership_type:(*condo*) AND property_category:(residential mixed*use) AND -full_street_address:"" AND -full_street_address:/.* garage .*/ AND number_of_buildings:[1 TO *] AND (condo_num:['1' TO *])`;

//`ownership_type:(*condo*) AND property_category:(residential mixed*use) AND -full_street_address:"" AND -full_street_address:/.* garage .*/ AND number_of_buildings:[1 TO *] AND (condo_num:['1' TO *] OR coop_num:['1' TO *])`;

//`ownership_type:(*condo* *co?op* *coop*) AND property_category:(residential mixed*use) AND -full_street_address:"" AND -full_street_address:/.* garage .*/ AND number_of_buildings:[1 TO *] AND (condo_num:['1' TO *] OR coop_num:['1' TO *])`;

//`((offering_accepted_date:[2000-01-01T00:00:00Z TO NOW] AND offering_plan_type:condominium) OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW]) AND deleted:false AND (inventory_pct_sold:[50 TO *] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW]) AND cdec_date:[2000-01-01T00:00:00Z TO NOW] AND building_status:(resale)`;



export const pipelineCohortQuery = `(offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_verified_status:(active "coming soon" "offering plan required" "offering plan acquired" "condo declaration vetted")) AND offering_verified_status:("" "monitoring" "reviewed" "offering plan required" "offering plan acquired" "condo declaration vetted" "vetting required" "vetted" "coming soon" "active" "sold out") AND deleted:false AND building_status:(pipeline)`;

export const recentCohortQuery = `(offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])`;

export const getCohortQuery = (user, all=false) => {
	let query = mpndCohortQuery;
	
  if(user && _hasPermission(user, "resale-buildings") && _hasPermission(user, "sponsor-buildings") && _hasPermission(user, "pipeline-buildings")){
		
    query = `(${pipelineCohortQuery}) OR (${mpndCohortQuery}) OR (${resaleCohortQuery}) OR (${coopCohortQuery})`;
  }else if(user && _hasPermission(user, "resale-buildings") && _hasPermission(user, "sponsor-buildings")){
    query = `(${mpndCohortQuery}) OR (${resaleCohortQuery}) OR (${coopCohortQuery})`;
  }else if(user && _hasPermission(user, "resale-buildings")){
		query = `(${resaleCohortQuery}) OR (${coopCohortQuery})`;
	}else if(user && _hasPermission(user, "sponsor-buildings")){
    query = `(${mpndCohortQuery})`;
  }
  
  if(all){
    query += ` OR (${houseBuildingCohortQuery})`
  }
	
	return `(${query})`;
	
}
export const getHouseCohortQuery = (user) => {
  let query = houseCohortQuery;
 
  
  return `(${query})`;
  
}
export const getCohortQueryAll = () => {
  let query = `(${pipelineCohortQuery}) OR (${mpndCohortQuery}) OR (${resaleCohortQuery}) OR (${coopCohortQuery}) OR (${houseBuildingCohortQuery})`;
  return `(${query})`;
}

export const matTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#B070CC',
      main: '#9600BF',
      dark: '#780F9E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
})

export const _relatedHoods ={
	"astoria_queens" : [
		{"type":"neighborhood","data":{"hood":"astoria","boro":"queens"},"id":"astoria_queens","name":"astoria"},
		{"type":"neighborhood","data":{"hood":"astoria-ditmars","boro":"queens"},"id":"astoria-ditmars_queens","name":"astoria-ditmars"},
		
	],
	"long-island-city_queens" : [
		{"type":"neighborhood","data":{"hood":"long island city","boro":"queens"},"id":"long-island-city_queens","name":"long island city"},
		{"type":"neighborhood","data":{"hood":"hunter's point","boro":"queens"},"id":"hunter%27s-point_queens","name":"hunter's point"}
	],
	"dumbo_brooklyn" : [ 
		{"type":"neighborhood","data":{"hood":"dumbo","boro":"brooklyn"},"id":"dumbo_brooklyn","name":"dumbo"},
		{"type":"neighborhood","data":{"hood":"vinegar hill","boro":"brooklyn"},"id":"vinegar-hill_brooklyn","name":"vinegar hill"}
	],
	"midtown_manhattan" : [
	  // 	{"type":"neighborhood","data":{"hood":"midtown","boro":"manhattan"},"id":"midtown_manhattan","name":"midtown"},
		// {"type":"neighborhood","data":{"hood":"midtown east","boro":"manhattan"},"id":"midtown-east_manhattan","name":"midtown east"},
		// {"type":"neighborhood","data":{"hood":"midtown west","boro":"manhattan"},"id":"midtown-west_manhattan","name":"midtown west"},
		{"type":"neighborhood","data":{"hood":"midtown south","boro":"manhattan"},"id":"midtown-south_manhattan","name":"midtown south"},
		{"type":"neighborhood","data":{"hood":"turtle bay","boro":"manhattan"},"id":"turtle-bay_manhattan","name":"turtle bay"},
		{"type":"neighborhood","data":{"hood":"hudson yards","boro":"manhattan"},"id":"hudson-yards_manhattan","name":"hudson yards"},
		{"type":"neighborhood","data":{"hood":"central park south","boro":"manhattan"},"id":"central-park-south_manhattan","name":"central park south"},
		{"type":"neighborhood","data":{"hood":"clinton","boro":"manhattan"},"id":"clinton_manhattan","name":"clinton"},
		{"type":"neighborhood","data":{"hood":"beekman","boro":"manhattan"},"id":"beekman_manhattan","name":"beekman"},	
		{"type":"neighborhood","data":{"hood":"murray hill","boro":"manhattan"},"id":"murray-hill_manhattan","name":"murray hill"},
		{"type":"neighborhood","data":{"hood":"sutton place","boro":"manhattan"},"id":"sutton-place_manhattan","name":"sutton place"},
		{"type":"neighborhood","data":{"hood":"kips bay","boro":"manhattan"},"id":"kips-bay_manhattan","name":"kips bay"}	
	],
	"midtown-east_manhattan" : [
		//{"type":"neighborhood","data":{"hood":"midtown east","boro":"manhattan"},"id":"midtown-east_manhattan","name":"midtown east"},
		{"type":"neighborhood","data":{"hood":"turtle bay","boro":"manhattan"},"id":"turtle-bay_manhattan","name":"turtle bay"},
		{"type":"neighborhood","data":{"hood":"beekman","boro":"manhattan"},"id":"beekman_manhattan","name":"beekman"},	
		{"type":"neighborhood","data":{"hood":"murray hill","boro":"manhattan"},"id":"murray-hill_manhattan","name":"murray hill"},
		{"type":"neighborhood","data":{"hood":"sutton place","boro":"manhattan"},"id":"sutton-place_manhattan","name":"sutton place"},
		{"type":"neighborhood","data":{"hood":"kips bay","boro":"manhattan"},"id":"kips-bay_manhattan","name":"kips bay"},			
	],
	"midtown-west_manhattan" : [
		{"type":"neighborhood","data":{"hood":"hudson yards","boro":"manhattan"},"id":"hudson-yards_manhattan","name":"hudson yards"},
		{"type":"neighborhood","data":{"hood":"midtown west","boro":"manhattan"},"id":"midtown-west_manhattan","name":"midtown west"},
	//	{"type":"neighborhood","data":{"hood":"clinton","boro":"manhattan"},"id":"clinton_manhattan","name":"clinton"}
	],
	"financial-district_manhattan" : [
		{"type":"neighborhood","data":{"hood":"financial district","boro":"manhattan"},"id":"financial-district_manhattan","name":"financial district"},
		{"type":"neighborhood","data":{"hood":"fulton seaport","boro":"manhattan"},"id":"fulton-seaport_manhattan","name":"fulton seaport"}
	],
	"upper-east-side_manhattan" : [
		{"type":"neighborhood","data":{"hood":"upper east side","boro":"manhattan"},"id":"upper-east-side_manhattan","name":"upper east side"},
		{"type":"neighborhood","data":{"hood":"lenox hill","boro":"manhattan"},"id":"lenox-hill_manhattan","name":"lenox hill"},
		{"type":"neighborhood","data":{"hood":"yorkville","boro":"manhattan"},"id":"yorkville_manhattan","name":"yorkville"},
		{"type":"neighborhood","data":{"hood":"carnegie hill","boro":"manhattan"},"id":"carnegie-hill_manhattan","name":"carnegie hill"}
	],
	"upper-west-side_manhattan" : [
		{"type":"neighborhood","data":{"hood":"upper west side","boro":"manhattan"},"id":"upper-west-side_manhattan","name":"upper west side"},
		{"type":"neighborhood","data":{"hood":"lincoln square","boro":"manhattan"},"id":"lincoln-square_manhattan","name":"lincoln square"},
		{"type":"neighborhood","data":{"hood":"manhattan valley","boro":"manhattan"},"id":"manhattan-valley_manhattan","name":"manhattan valley"}
	],
	"lower-east-side_manhattan" : [
		{"type":"neighborhood","data":{"hood":"lower east side","boro":"manhattan"},"id":"lower-east-side_manhattan","name":"lower east side"},
		{"type":"neighborhood","data":{"hood":"two bridges","boro":"manhattan"},"id":"two-bridges_manhattan","name":"two bridges"}
	],
	"flatiron_manhattan" : [ 
		{"type":"neighborhood","data":{"hood":"flatiron","boro":"manhattan"},"id":"flatiron_manhattan","name":"flatiron"},
		{"type":"neighborhood","data":{"hood":"nomad","boro":"manhattan"},"id":"nomad_manhattan","name":"nomad"}
	],
	"williamsburg_brooklyn" : [
		{"type":"neighborhood","data":{"hood":"williamsburg","boro":"brooklyn"},"id":"williamsburg_brooklyn","name":"williamsburg"},
		{"type":"neighborhood","data":{"hood":"east williamsburg","boro":"brooklyn"},"id":"east-williamsburg_brooklyn","name":"east williamsburg"}
	]
}

export const _isNewUser = (user) => {
		
	if(user && user.trialStart && user.trialStart > 1607558400){
		return true;
	}
	return false;	
}

export const _isEnterpriseUser = (user) => {	
	return (user.role && Array.isArray(user.role) && user.role.filter(role => ['admin', 'enterprise'].indexOf(role) >=0).length > 0);	
}

export const _isMERS = (name) => {
	
	return (name && (name.indexOf('mers')>=0 || (name.indexOf('electronic')>=0)))
}

export const _reverseName = (name, simple) => {
			
		if(_isMERS(name)){
			
			return simple ? 'Mortgage Elecontric Registration System (MERS)' : ' a lender that registered the loan with Mortgage Elecontric Registration System (MERS)';
		}
		
		if(name.endsWith('llc') || name.endsWith('inc') || name.endsWith('corp') || name.endsWith('n.a.')){
			return _capitalizeText(name);
		}

		return  _capitalizeText(name.split(/,\s/).reverse().join(" "));
}	

export const _formatOwnerName = (owner, simple) => {
	if(!owner) return '--';

	return Array.isArray(owner) ? owner.map(name => _reverseName(name, simple)).join(", ") : _reverseName(owner, simple)
}

export const _isResiUnit = ( unit ) => {
	let isResi = false;
	if(unit.property_type.indexOf('commercial') < 0 && (unit.property_type.indexOf('condo') > -1 || unit.property_type.indexOf('coop') > -1  || unit.property_type.indexOf('co-op') > -1 || unit.property_type == "" || unit.property_type.indexOf("affordable") > -1 || unit.property_type.indexOf("resi") > -1 || unit.property_type.indexOf("apartment") > -1)){
		isResi = true;
	}
	
	return isResi;
}

export const _validUnitSources = "user-verified-valid CDEC ADEC NYCDOF NYAG dof-acris*"; //"dof-acris-cdec dof-acris-adec"
export const _formatAltAdrGoat = data => {
  // console.log("_formatAltAdrGoat", data);
  if(!data) {return false;}
  const altAdrs = [];
  let altPairLen = 0;

  for(let key in data) {
    // console.log('key', key);
    // console.log('key includes giHighHouseNumber', key.includes('giHighHouseNumber'));
    if(key.includes('giHighHouseNumber')) {
      // console.log('adding to lealtPairLenn')
      altPairLen++;
    }
  }
  // console.log('altPairLen', altPairLen)

  for(let i = 1; i <= altPairLen; i ++) {
    const streetNameNum = data['giStreetName' + i] && data['giStreetName' + i].match(/\d+/)
      ?  _ordinalSuffix(data['giStreetName' + i].match(/\d+/))
      : false
    // console.log('streetNameNum', streetNameNum)
    const formattedStreet = streetNameNum
      ? data['giStreetName' + i].toLowerCase().trim().replace(/\d+/, streetNameNum).replace(/\s+/g, " ")
      : data['giStreetName' + i] ? data['giStreetName' + i].toLowerCase().trim().replace(/\s+/g, " ") : "";

    // console.log('formattedStreet', formattedStreet)
    const adr = data['giHighHouseNumber' + i] && data['giLowHouseNumber' + i] &&  data['giLowHouseNumber' + i] === data['giHighHouseNumber' + i]
      ? `${data['giHighHouseNumber' + i].replace("-", "")} ${formattedStreet}`
      : data['giLowHouseNumber' + i] && data['giHighHouseNumber' + i]
        ? `${data['giLowHouseNumber' + i].replace("-", "")} - ${data['giHighHouseNumber' + i].replace("-", "")} ${formattedStreet}`
        : false
    if(adr) {altAdrs.push(adr)}
  }
  // console.log('goat altAdrs arr', altAdrs)
  return altAdrs;
}

export const _sortAddresses = (arr) => {
  if(!_shouldShow(arr)){return;}

  const sortedArr = arr
  .sort((a, b) => 
        {
          const streetNameA = a.includes(' - ') ? a.split(" ").slice(3).toString().replace(/,/g, " ").trim() : a.substr((a.indexOf(' ') + 1), a.length);
          const streetNameB = b.includes(' - ') ? b.split(" ").slice(3).toString().replace(/,/g, " ").trim() : b.substr((b.indexOf(' ') + 1), b.length);
          
          if(streetNameA === streetNameB ) {
            // console.log('streeName the same sort by num', a, b, a.substr(0, a.indexOf(' ')) - b.substr(0, b.indexOf(' ')))
            return a.substr(0, a.indexOf(' ')) - b.substr(0, b.indexOf(' '));
          } else { 
            // console.log('diff streetName sort by name', streetNameA, streetNameB)
            if(streetNameA > streetNameB) {
              // console.log('rtn 1')
              return 1;
            } else if(streetNameA < streetNameB) {
              // console.log('rtn -1')
              
              return -1;
            } else {
              // console.log('rtn 0')
              return 0;
            }
          }
        }
      )
  // console.log('sortedArr', sortedArr)
  return sortedArr;
}


export const _checkBuildingAltAdr = building => {
  // console.log('_checkBuildingAltAdr', building);
  const buildingAdr = building.display_full_street_address 
    ? building.display_full_street_address 
    : building.full_street_address
      ? building.full_street_address
      : false;
  
  if (!buildingAdr ||
      (!building.alternate_addresses && !building.goatData) ||
      (Array.isArray(building.alternate_addresses) && building.alternate_addresses.length === 0) ||
      building.alternate_addresses === " " ||
      !_shouldShow(building.alternate_addresses)
  ) {
    // console.log('_checkBuildingAltAdr exit out buildingAdr', buildingAdr, 'building.alternate_addresses', building.alternate_addresses);
    return false;
  }

  const reStrLetter = /\d+[a-zA-Z]/g;
  // console.log('_checkBuildingAltAdr altAdrs', building.alternate_addresses)
  let primaryAdr = _replaceAbbreviations(buildingAdr.replace(/\s\s+/g, " ")).trim();
  const primaryStrArr = primaryAdr.split(" ");
  if(primaryStrArr[0].match(reStrLetter)) {
    primaryAdr = primaryStrArr[0].toUpperCase() + " " + primaryStrArr.slice(1).join(" ")
  }

  const altAdrs = building.goatData && _sortAddresses(_formatAltAdrGoat(building.goatData))
    ? _sortAddresses(_formatAltAdrGoat(building.goatData))
    : building.alternate_addresses
      ? Array.isArray(building.alternate_addresses) 
        ? _sortAddresses(building.alternate_addresses
          .map(adr => {
            // let formattedAdr = adr.replace("-", "").replace(/\s\s+/g, " ");
            // const strName = formattedAdr.substr((formattedAdr.indexOf(' ') + 1), formattedAdr.length);
            // const strNum = _ordinalSuffix(strName.match(/\d+/));
            // // console.log('getting strNum', formattedAdr.substr((formattedAdr.indexOf(' ') + 1), formattedAdr.length).match(/\d+/))
            // formattedAdr = strNum 
            //   ? formattedAdr.substr(0, (formattedAdr.indexOf(' ') + 1)) + " " + strName.replace(/\d+/, strNum)
            //   : formattedAdr;

            // console.log('strNum adr', adr, 'formattedAdr', formattedAdr, 'strNum', strNum)
            return _formatOrdinalsUnAbbrAdr(adr)
            // return _replaceAbbreviations(formattedAdr.replace(/\s\s+/g, " ")).trim()
          })
          .filter((adr, i, arr) => {
            if(adr) {
              // take out two numbers with no text after - leaves 305 5th st but kills 305 5 st
              return !adr.match(/(\d+\s\d+\W)|(\d+\s\w+\s\d+\W)/g) && 
              // !adr.match(/\d+\s\d+\W/g) && (\d+\s\d+\W)|(\d+\s\w+\s\d+\W)
              // get rid of dups
              arr.indexOf(adr) === i 
            } else {
              return false;
            }
          })
        )
        : building.alternate_addresses
      : false;

  // console.log('_checkBuildingAltAdr primaryAdr', primaryAdr, 'altAdrs', altAdrs);

  let name;

  // exit out of range formatting if in queens
  if (
    building.borough &&
    building.borough.toLowerCase().trim() == "queens" &&
    (building.alternate_addresses || altAdrs)
  ) {
    // console.log('queens no formatting called', altAdrs)
    if (Array.isArray(altAdrs)) {
      name = altAdrs.filter((adr, i, arr) => {
      // console.log('name filtering queens adr', adr, 'i',i);
      // const primaryAdrCheck = building.goatData ? (adr !== primaryAdr) : (adr !== primaryAdr.replace("-", ""));
      return (
        adr &&
        adr !== primaryAdr.replace("-", "") &&
        // primaryAdrCheck &&
        arr.indexOf(adr) === i);
      });

    //string alt adr
    } else {
  // TODO CHECK SINGLE ALT ADR
      name =
        altAdrs === primaryAdr
          ? false
          : altAdrs;
    }

    // console.log('queens altAdrs ', name && name.length > 0 
      // ? Array.isArray(name) 
      //   ? name.map(adr => _capitalizeText(adr)) 
      //   : _capitalizeText(name) 
      // : false)

    return name && name.length > 0 
      ? Array.isArray(name) 
        ? name.map(adr => _capitalizeText(adr)) 
        : _capitalizeText(name) 
      : false;
  }
  
  // if(building.goatData) {
  //   console.log('goatData', _shouldShow(altAdrs), altAdrs)
  //   if(_shouldShow(altAdrs)){
  //     if(altAdrs.length > 1) {
  //       name = altAdrs.filter(adr => (adr && adr !== primaryAdr.replace("-", "")));
  //     } else {
  //       name = altAdrs[0] === primaryAdr
  //         ? false
  //         : altAdrs;
  //     }
  //   } else {
  //     name = false;
  //   }
  
  // // no goat data use our alt adr
  // } else 
  if (Array.isArray(altAdrs)) {
    const filteredAlts = altAdrs.map(
      (adr, i) => {
        const strNum = adr.includes(' - ')
          ? {start:adr.split(" ")[0], end: adr.split(" ")[2]}
          : adr.substr(0, adr.indexOf(' '));
        const nextNum = i !== altAdrs.length - 1 
          ? altAdrs[i + 1].includes(' - ')
            ? {start: altAdrs[i + 1].split(" ")[0], end: altAdrs[i + 1].split(" ")[2]} 
            : altAdrs[i + 1].substr(0, altAdrs[i + 1].indexOf(' '))
          : false;
        
        const prevNum = i !== 0 
          ? altAdrs[i - 1].includes(' - ')
            ? {start: altAdrs[i - 1].split(" ")[0], end: altAdrs[i - 1].split(" ")[2]} 
            : altAdrs[i - 1].substr(0, altAdrs[i - 1].indexOf(' '))
          : false;

        const strName = adr.includes(' - ')
          ? adr.split(" ").slice(3).toString().replace(/,/g, " ").trim()
          : adr.substr((adr.indexOf(' ') + 1), adr.length);
        const nextName = i !== altAdrs.length - 1 
          ? altAdrs[i + 1].includes(' - ')
            ? altAdrs[i + 1].split(" ").slice(3).toString().replace(/,/g, " ").trim()
            : (altAdrs[i + 1].substr((altAdrs[i + 1].indexOf(' ') + 1), altAdrs[i + 1].length))
          : false;
        
        const prevName = i !== 0 
          ? altAdrs[i - 1].includes(' - ')
            ? altAdrs[i - 1].split(" ").slice(3).toString().replace(/,/g, " ").trim()
            : (altAdrs[i - 1].substr((altAdrs[i - 1].indexOf(' ') + 1), altAdrs[i - 1].length))
          : false;
        // console.log('i', i, 'adr', adr, 'nextAdr', altAdrs[i + 1], 'prevAdr', altAdrs[i - 1])
        // console.log('strName', strName, 'nextName', nextName, 'prevName', prevName)
        // console.log('strNum', strNum, 'nextNum', nextNum, 'prevNum', prevNum)
        // console.log('reStrLetter match', strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter))
        // if(nextNum) console.log('nexNum has letter', 
        //         (nextNum.end ? nextNum.end.match(reStrLetter) || nextNum.start.match(reStrLetter) : nextNum.match(reStrLetter))
        //   )
        //  if(prevNum) console.log('prevNum has letter', 
        //         (prevNum.end ? prevNum.end.match(reStrLetter) || prevNum.start.match(reStrLetter) : prevNum.match(reStrLetter))
        //   )

        const newAdr = (
          (i > 0 && i !== altAdrs.length - 1
            
            // check for diff st name - doesn't match prevName
            ? strName !== prevName
              ? strName !== nextName   
                ? adr
                
                // nextName same as str - check for num diff
                : (strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter))
                  ? adr
                  : parseInt(nextNum.start ? nextNum.start : nextNum) - parseInt(strNum.end ? strNum.end : strNum) > 2
                    ? adr
                    : parseInt(nextNum.start ? nextNum.start : nextNum) === parseInt(strNum.end ? strNum.end : strNum)
                      ? false
                      // ? 'stuff'
                      // TODO - missing last adr in range - check two ahead for 3 part range - 234 e 11th manhattan
                      // : iPlus2Num && parseInt(iPlus2Num.start ? iPlus2Num.start : iPlus2Num) - parseInt(nextNum.end ? nextNum.end : nextNum) <= 2
                        // ? `${strNum.start ? strNum.start : strNum} - ${iPlus2Num.end ? iPlus2Num.end : iPlus2Num} ${strName}here`
                        : strNum.start && nextNum.end
                          ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                          : strNum.start || nextNum.end
                            ? strNum.start && !nextNum.end
                              ? `${strNum.start} - ${nextNum} ${strName}`
                              : strNum.start
                                ? adr 
                                : nextNum.end  
                                  ? {adr: adr, start: true, end: false, stuff1: 'stuff1'}
                                  : false
                                
                                // : 'other stuff'
                            : i + 1 === altAdrs.length - 1
                              ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                              : {adr: adr, start: true, end: false, stuff: 'stuff'}

              
            // same strName as prevName, check nextName THEN no more than 2 diff in st num
              : strName !== nextName
                // diff nextName
                ? parseInt(strNum.start ? strNum.start : strNum) - parseInt(prevNum.end ? prevNum.end : prevNum) > 2 ||
                  (strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter)) ||
                  (prevNum.end ? prevNum.end.match(reStrLetter) || prevNum.start.match(reStrLetter) : prevNum.match(reStrLetter))
                  ? adr
                  : parseInt(strNum.start ? strNum.start : strNum) === parseInt(prevNum.end ? prevNum.end : prevNum)
                    ? false
                    // ? 'here'
                    : strNum.end && prevNum.start
                      ? {adr: adr, start: false, end: true, endNum: strNum.end ? strNum.end : strNum, strName: strName, here: 'here' }
                      // ? `${prevNum.start} - ${strNum.end} ${strName}`
                      : !strNum.end && prevNum.start
                        ? false
                        // ? 'here1'
                        : {adr: adr, start: false, end: true, endNum: strNum.end ? strNum.end : strNum, strName: strName, here1: 'here1' }

                // strName matchs prev and next
                : parseInt(nextNum.start ? nextNum.start : nextNum) - parseInt(strNum.end ? strNum.end : strNum) > 2 || 
                  (strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter)) ||
                  (nextNum.end ? nextNum.end.match(reStrLetter) || nextNum.start.match(reStrLetter) : nextNum.match(reStrLetter)) 
                  ? parseInt(strNum.start ? strNum.start : strNum) - parseInt(prevNum.end ? prevNum.end : prevNum) > 2 ||
                    (strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter)) ||
                    (prevNum.end ? prevNum.end.match(reStrLetter) || prevNum.start.match(reStrLetter) : prevNum.match(reStrLetter)) 
                    ? adr
                    : parseInt(strNum.start ? strNum.start : strNum) === parseInt(prevNum.end ? prevNum.end : prevNum)
                      ? false
                      // ? 'there'
                      : {adr: adr, start: false, end: true, endNum: strNum.end ? strNum.end : strNum, strName: strName, there: 'there'}
                  
                  : parseInt(nextNum.start ? nextNum.start : nextNum) === parseInt(strNum.end ? strNum.end : strNum)
                    ? parseInt(strNum.start ? strNum.start : strNum) - parseInt(prevNum.end ? prevNum.end : prevNum) > 2
                      ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                      : parseInt(strNum.start ? strNum.start : strNum) === parseInt(prevNum.end ? prevNum.end : prevNum)
                        ? `${prevNum.start ? prevNum.start : prevNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                        // : 'other'
                        : false
                    
                // nextNum.end and strNum.start !== || > 2
                    : parseInt(strNum.start ? strNum.start : strNum) - parseInt(prevNum.end ? prevNum.end : prevNum) > 2
                      ? {adr: adr, start: true, end: false, other: 'other'}
                      // ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                      : parseInt(strNum.start ? strNum.start : strNum) === parseInt(prevNum.end ? prevNum.end : prevNum)
                        ? `${prevNum.start ? prevNum.start : prevNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                        : false

                  // : parseInt(strName) - parseInt(prevName) > 2
                  //   ? {adr: adr, start: true, end: false, more: 'more'}
                  //   : false
            
            // first index
            : i !== altAdrs.length - 1
              ? strName !== nextName || 
                (strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter)) ||
                (nextNum.end ? nextNum.end.match(reStrLetter) || nextNum.start.match(reStrLetter) : nextNum.match(reStrLetter))
                ? adr 
                : adr.includes(' - ')
                  ? parseInt(nextNum.start ? nextNum.start : nextNum) - parseInt(strNum.end ? strNum.end : strNum) > 2
                    ? adr 
                    : parseInt(nextNum.start ? nextNum.start : nextNum) === parseInt(strNum.end ? strNum.end : strNum)
                      ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}` 
                      : {adr: adr, start: true, end: false, first: 'first'}
                        // ? {adr: adr, start: true, end: false, first: 'first'}
                        // : 'first false'
                  
                  // : nextNum.end  
                  //   ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                  //   : {adr: adr, start: true, end: false, first1: 'first1'}

                  : parseInt(nextNum.start ? nextNum.start : nextNum) - parseInt(strNum.end ? strNum.end : strNum) > 2
                    ? adr 
                    : parseInt(nextNum.start ? nextNum.start : nextNum) === parseInt(strNum.end ? strNum.end : strNum)
                      ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}` 
                      : nextNum.end  
                        ? `${strNum.start ? strNum.start : strNum} - ${nextNum.end ? nextNum.end : nextNum} ${strName}`
                        : {adr: adr, start: true, end: false, first1: 'first1'}
              
              // last index
              : strName === prevName
                ? !adr.includes(' - ')
                  ? parseInt(strNum.start ? strNum.start : strNum) - parseInt(prevNum.end ? prevNum.end : prevNum) > 2 ||
                    strNum.match(reStrLetter)
                    ? adr 
                    // not greater than 2, should already be in prev, always false?
                    // : parseInt(strNum.start ? strNum.start : strNum) === parseInt(prevNum.end ? prevNum.end : prevNum)
                    //   ? 'last'
                    //   : prevNum.start 
                    //     ?  
                    //     : 
                      
                      : !strNum.end 
                        // all single nums in a range
                        ? !prevNum.end 
                          ? {adr: adr, start: false, end: true, endNum: strNum.end ? strNum.end : strNum, strName: strName, last1: 'last1'}
                          : false
                          // : 'last false'
                        // : 
                          // ? 'last other false'
                        : {adr: adr, start: false, end: true, endNum: strNum.end ? strNum.end : strNum, strName: strName, last: 'last'}
                  : parseInt(strNum.start ? strNum.start : strNum) - parseInt(prevNum.end ? prevNum.end : prevNum) > 2 || 
                    (strNum.end ? strNum.end.match(reStrLetter) || strNum.start.match(reStrLetter) : strNum.match(reStrLetter))
                    ? adr
                    // : false
                    
                    : parseInt(strNum.start ? strNum.start : strNum) === parseInt(prevNum.end ? prevNum.end : prevNum)
                      ? false
                      : {adr: adr, start: false, end: true, endNum: strNum.end ? strNum.end : strNum, strName: strName, last2: 'last2'}
                : adr
            )
        );
        
        // console.log('newAdr', newAdr, adr)
        return newAdr;
      }     
    ).filter(adr => adr);

    // console.log('filteredAlts', filteredAlts);

    name = filteredAlts.map((adr, i) => {
     // console.log('name adr map', adr,i, 'next in arr', filteredAlts[i + 1])
     // console.log('next in Arr with -', (!filteredAlts[i + 1] || (filteredAlts[i + 1] && !filteredAlts[i + 1].end))) 
     // 'checking for next', (!filteredAlts[i + 1] || (filteredAlts[i + 1] && !filteredAlts[i + 1].end)), 
     // 'full', (adr.adr.includes(' - ') && (!filteredAlts[i + 1] || (filteredAlts[i + 1] && !filteredAlts[i + 1].end))) || (!filteredAlts[i + 1] || (filteredAlts[i + 1] && !filteredAlts[i + 1].end) ))
      // create ranges
      return typeof adr === 'string'
        ? adr 
        : adr.start 
          // ? (adr.adr.includes(' - ') && !filteredAlts[i + 1].end) || !filteredAlts[i + 1].end
          // ? (adr.adr.includes(' - ') && 
          //     (!filteredAlts[i + 1] || 
          //       (filteredAlts[i + 1] && (!filteredAlts[i + 1].end || !filteredAlts[i + 1].start)) 
          //     || (!filteredAlts[i + 1] || (filteredAlts[i + 1] && !filteredAlts[i + 1].end))
          //     )
          //   )
          ? (adr.adr.includes(' - ') && 
              (!filteredAlts[i + 1] || 
                (filteredAlts[i + 1] && !filteredAlts[i + 1].end)
              )
            ) 
            || (!filteredAlts[i + 1] || (filteredAlts[i + 1] && !filteredAlts[i + 1].end))
            
            ? adr.adr
            : adr.adr.substr(0, adr.adr.indexOf(' ')) + ' - ' + filteredAlts[i + 1].endNum + ' ' + filteredAlts[i + 1].strName
          : adr.end && 
            (filteredAlts[i - 1] && 
              !filteredAlts[i - 1].start && 
              (filteredAlts[i - 1].adr 
                ? !filteredAlts[i - 1].adr.includes(adr.endNum + ' ' + adr.strName) 
                : !filteredAlts[i - 1].includes(adr.endNum + ' ' + adr.strName)
              )
            )
            ? adr.adr
            : false
          // : 'here false'

    }).filter((adr, i, arr) => {
     // console.log('name filtering adr', adr, 'i',i);
      // const primaryAdrCheck = building.goatData ? (adr !== primaryAdr) : (adr !== primaryAdr.replace("-", ""));
      return (
        adr &&
        adr !== primaryAdr.replace("-", "") &&
        // primaryAdrCheck &&
        arr.indexOf(adr) === i);
    });

  //string alt adr
  } else {
// TODO CHECK SINGLE ALT ADR
    name =
      altAdrs === primaryAdr
        ? false
        : altAdrs;
  }

  if (
    building.borough &&
    building.borough.toLowerCase().trim() == "queens" &&
    name &&
    (building.alternate_addresses || altAdrs)
  ) {
    name = Array.isArray(name) 
      ? name.map(adr => _formatQueensAddress(adr, altAdrs ? altAdrs : building.alternate_addresses))
      : _formatQueensAddress(name, altAdrs ? altAdrs : building.alternate_addresses);
  }

  // console.log('name check', name)
  //returns either array or string of alt adrs, OR false for no alt adrs
  return name && name.length > 0 
    ? Array.isArray(name) 
      ? name.map(adr => _capitalizeText(adr)) 
      : _capitalizeText(name) 
    : false;
};
export const _showAltAdrs = (adrArr, mobile) => {
  if(!adrArr) return;
  return adrArr.map((adr, i) => {
    if (i === adrArr.length - 1 && adrArr.length > 1) {
      return (
        <span key={adr} className="">
          {adr}
        </span>
      );
    } else if (i === 0) {
      if (adrArr.length > 1) {
        return (
          <span key={adr} className="">
            {adr} 
            <br />
          </span>
        );
      } else {
        return (
          <span key={adr} className="">
            {adr}
          </span>
        );
      }
    } else {
      return (
        <span key={adr + i} className="">
          {adr} 
          <br />
        </span>
      );
    }
  });
};

export const _getCrossStreet = building => {
  var cross = "";
  if (
    building.goatData &&
    building.goatData.lowCrossStreetName1 &&
    building.goatData.lowCrossStreetName1.toLowerCase() != "bend"
  ) {
    cross = _capitalizeText(building.goatData.lowCrossStreetName1);
  }

  if (
    building.goatData &&
    building.goatData.highCrossStreetName1 &&
    building.goatData.highCrossStreetName1.toLowerCase() != "bend"
  ) {
    if (cross !== "") cross += " and ";
    cross += _capitalizeText(building.goatData.highCrossStreetName1);
  }
  return cross;
};

export const _activityFQ = [
	"hierarchy:[0 TO 2]",	
	// "-(time:[2020-03-18T20:42:19Z TO 2020-03-19T00:47:46Z] AND building:b-4-2315-44th-dr-11101 AND foreign_id:*RPLU*)",
	// "-(time:[2021-09-09T13:00:00Z TO 2021-09-10T20:00:00Z] AND (foreign_id:REBNY-LISTING-RPLU* OR foreign_id:REBNY-LISTING-PDES*) AND slugs:*elliman*)",
	// "-(time:[2021-09-30T13:00:00Z TO 2021-10-01T01:00:00Z] AND (foreign_id:REBNY-LISTING-RPLU*) AND slugs:*elliman* AND building:b-1-132-madison-ave-10016)",
	// "-foreign_id:(REBNY-LISTING-RPLU-3346205670 REBNY-LISTING-RPLU-3346199729 REBNY-LISTING-RPLU-5121552553 REBNY-LISTING-RPLU-5121552555 REBNY-LISTING-RPLU-33421317535 REBNY-LISTING-PRCH-3694032 REBNY-LISTING-PRCH-3694023 REBNY-LISTING-RPLU-3346392995 REBNY-LISTING-RPLU-3346364853 REBNY-LISTING-RPLU-5121413140 REBNY-LISTING-RPLU-5121606768 REBNY-LISTING-RPLU-5121631607 REBNY-LISTING-RPLU-5121655847 REBNY-LISTING-RPLU-33421524836 REBNY-LISTING-PRCH-3743809 REBNY-LISTING-RPLU-5121655844 REBNY-LISTING-RPLU-5121577847 REBNY-LISTING-RPLU-5121708547 REBNY-LISTING-RPLU-5121577893 REBNY-LISTING-RPLU-5121810630 REBNY-LISTING-RPLU-33421776941 REBNY-LISTING-RPLU-33421824022)",
	// "-(time:[2022-01-18T20:42:19Z TO 2022-01-23T00:47:46Z] AND slugs:*corcoran* AND foreign_id:*RPLU* AND event_tags:just-listed)",
    "price:[100000 TO 300000000]"
]

export const _activityFL = [
  "full_street_address",
  "display_full_street_address",
  "street_number",
  "street",
  "address_2",
  "address_2_normalized",
  "alternate_addresses",
  "neighborhood",
  "city",
  "state",
  "borough",
  "borough_code",
  "zip",
  "geocode",
  "markets_major",
  "markets_sub",
  "building",
  "building_name",
  "property",
  "block",
  "lot",
  "borough_tags",
  "address_normalized",
  "search_string",
  "id",
  "object",
  "foreign_id",
  "actor",
  "verb",
  "title",
  "topic",
  "url",
  "content",
  "sq_ft",
  "baths",
  "price",
  "event_tags",
  "bedrooms",
  "building_slugs",
  "time",
  "slugs",
  "created_date",
  "hierarchy",
  "main_photo"]

export const _urlSafe = (string) => {
	if(!string) return "";
	return string.toString().replace(/[^a-zA-Z0-9-_]/g, '')
}

export const calcuateSalesStart = (buildingRes, type) => {		
  
  if(buildingRes.offering_verified_status === 'abandoned'){
    return "--";
  }
  const sales_year = parseInt(moment(buildingRes.sales_start_date).format('YYYY'));
  if(buildingRes.building_status === 'resale'){
    
    if(!buildingRes.sales_start_date || sales_year <= 1970){
      return "--";
    }
    
    const offering_effective_year = buildingRes.offering_effective_date ?  parseInt(moment(buildingRes.offering_effective_date).format('YYYY')) : 0;
    const first_closing_year = buildingRes.first_closing_date ?  parseInt(moment(buildingRes.first_closing_date).format('YYYY')) : 0;
    
    if(sales_year > offering_effective_year){
      return "--";
    }
    if(sales_year > first_closing_year){
      return "--";
    }
  }
  if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
    if(type === 'fromNow'){
      return 'Not Yet Started';
    }else{
      return null;
    }
  }
  
  if(type === 'fromNow'){
    return moment(buildingRes.sales_start_date).fromNow();
  }	
  return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}
export const calcuateTCODate = (buildingRes, type) => {		
  
  if(buildingRes.offering_verified_status === 'abandoned'){
    return "--";
  }
  const tco_year = parseInt(moment(buildingRes.building_effective_start).format('YYYY'));
  if(buildingRes.building_status === 'resale'){
    
    if(!buildingRes.sales_start_date || tco_year <= 1970){
      return "--";
    }
    
    const offering_effective_year = buildingRes.offering_effective_date ?  parseInt(moment(buildingRes.offering_effective_date).format('YYYY')) : 0;
    const first_closing_year = buildingRes.first_closing_date ?  parseInt(moment(buildingRes.first_closing_date).format('YYYY')) : 0;
    
    if(tco_year > offering_effective_year){
      return "--";
    }
    if(tco_year > first_closing_year){
      return "--";
    }
  }
  if(!buildingRes.building_effective_start || parseInt(moment(buildingRes.building_effective_start).format('YYYY')) <= 1970){
    if(type === 'fromNow'){
      return '--';
    }else{
      return null;
    }
  }
  
  if(type === 'fromNow'){
    return moment(buildingRes.building_effective_start).fromNow();
  }	
  return `(${moment(buildingRes.building_effective_start).format("M/D/YYYY")})`;
}

export const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
   <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};

export const _zeroPad = function(number, length) {
  if (number) {
    var amt = length - number.toString().length;

    return "0".repeat(amt) + number.toString();
  }

  return "";
};

export const _getPipelineBuildingURL = (building, key) => {
  
  const buildingURL = "https://pipeline.marketproof.com/project/" + building["_id"]
  return buildingURL;
};

export const ProfileAvatar = ({ contact, large, companyCard }) => {
  const profileColors = ["#9decd6", "#9eb3c1", "#f9b2b2", "#66abf5"];

  const abbrev = companyCard
    ? contact.display_name && contact.display_name[0]
    : contact.first_name
    ? contact.first_name[0]
    : " ";
  const color = profileColors[abbrev.charCodeAt(0) % profileColors.length];
  return (
    <span>
      {contact.logo && contact.logo.length>5 ? (
        <div
          className={`contact_avatar ${large ? "large avatar_margin" : ""}`}
          style={{ backgroundImage: `url(${contact.logo})` }}
        />
      ) : (
        <div
          className={`contact_avatar ${large ? "large avatar_margin" : ""}`}
          style={{
            background: color,
          }}
        >
          {companyCard
            ? contact.display_name && contact.display_name[0]
            : contact.first_name
            ? contact.first_name[0]
            : "-"}
          {!companyCard ? contact.last_name && contact.last_name[0] : null}
        </div>
      )}
    </span>
  );
};

export const deleteLocalStorageWithPrefix = (prefix) => {
  
    if(!localStorage) return null;
    // Iterate over all keys in local storage
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        // Check if the key starts with the specified prefix
        if (key.startsWith("mpnd_"+prefix)) {
            // Delete the item from local storage
            localStorage.removeItem(key);
        }
    }
}

export const _isCoop = (bldg) => {
  return ['coop', 'co-op', 'residential-co-op', 'co op'].indexOf(bldg.ownership_type.toLowerCase())>=0 || bldg.ownership_occupancy_type == "coop"
}
export const _isHouse = (bldg) => {
  return ['single-family', 'multi-family', 'single family', 'multi family'].indexOf(bldg.ownership_type.toLowerCase())>=0 || (['rental'].indexOf(bldg.ownership_type.toLowerCase())>=0 && bldg.total_units <= 3)
}
export const jobTypes = {
  a1: "DOB BIS - A1 (Major Alteration)",
  a2: "DOB BIS - A2 (Multiple Work Types)",
  a3: "DOB BIS - A3 (Minor Alteration)",
  nb: "DOB BIS - NB (New Building)",
  dm: "DOB BIS - DM (Demolition)",
  si: "DOB BIS - SI (ubdivison: Improvement)",
  sc: "DOB BIS - SC (Subdivison: Condo)",
  pa: "DOB BIS - PA (Place of Assembly)",
  sg: "DOB BIS - SG (Sign)",
  1: "DOB Now - Alteration",
  alt: "DOB Now - Alteration",
  2: "DOB Now - No Work",
  4: "DOB Now - Alteration CO Required",
  5: "DOB Now - New Building",
  6: "DOB Now - New Building w/ Existing Elements to Remain",
  7: "DOB Now - Demolition",
};

export const workTypes = {
  ch: "Chute",
  fn: "Fence",
  sh: "Sidewalk shed",
  sf: "Scaffold",
  bl: "Boiler",
  fa: "Fire alarm",
  fb: "Fuel burning",
  fp: "Fuel Supression",
  fd: "Full Demoliion",
  mh: "Mechnical/HVAC",
  ot: "General construction",
  sd: "Standpipe",
  sp: "Sprinkler",
  eq: "Construction Equipment",
  al: "Alteration",
  dm: "Demolition and removal",
  ew: "Equipment work",
  fo: "Foundation/Earthwork",
  "fo/ea": "Earthwork",
  nb: "New building",
  pl: "Plumbing",
  sg: "Sign",
  s: "Sign",
  gc: "General construction",
  an: "Antenna",
  be: "Boiler equipment",
  cc: "Curb cut",
  ea: "Earthwork",
  el: "Electrical",
  fn: "Boiler equipment",
  la: "Limited alteration",
  ms: "Mechanical systems",
  pa: "Place of assembly",
  pm: "Protection and mechanical methods",
  pmm: "Protection and mechanical methods",
  se: "Support of excavation",
  st: "Structural",
  ta: "Temporary place of assembly",
  vt: "Elevator",
  fs: "Fuel Storage",
  sc : "Suspended Scaffold"
};

export const jobTypeList = [
  { key: "default", display: "Any Job Type" },
  { key: "nb", display: "DOB BIS - NB (New Building)" },
  { key: "a1", display: "DOB BIS - A1 (Major Alteration)" },
  { key: "a2", display: "DOB BIS - A2 (Multiple Work Types)" },
  { key: "a3", display: "DOB BIS - A3 (Minor Alteration)" },
  { key: "dm", display: "DOB BIS - DM (Demolition)" },
  { key: "si", display: "DOB BIS - SI (Subdivison: Improvement)" },
  { key: "sc", display: "DOB BIS - SC (Subdivison: Condo)" },
  { key: "pa", display: "DOB BIS - PA (Place of Assembly)" },
  { key: "1", display: "DOB Now - Alteration" },
  { key: "alt", display: "DOB Now - Alteration" },
  { key: "2", display: "DOB Now - No Work" },
  { key: "4", display: "DOB Now - Alteration CO Required" },
  { key: "5", display: "DOB Now - New Building" },
  {
    key: "6",
    display: "DOB Now - New Building w/ Existing Elements to Remain",
  },
];

export const permitteeTypes ={
  'gc' : "General Contractor",
  'mp' : "Master Plumber",
  'p' : "Plumber",
  'fs' : "Fire Supression",
  'f' : "Fire Protection",
  'sp' : "Sprinkler",
  'sg' : "Sign Hanger"
}

export const applicantTypes ={
  'ra' : "Registered Architect",
  'pe' : "Professional Engineer",
  'p' : "Plumber",
  'r' : "Special Rigger",
  'f' : "Fire Protection",
  'sp' : "Sprinkler",
  'sg' : "Sign Hanger"
}
export const _makePermitsUrl = (permit) => {
  var date = permit.pre_filing_date;

  return (
    <div>
      <div className={"light-text small-text"}>
        {permit.source_providers.indexOf("DOB-NOW-PERMITS") < 0 ? (
          <span className="no-wrap">
            <a
              className="link-text"
              href={`http://a810-bisweb.nyc.gov/bisweb/JobsQueryByNumberServlet?passjobnumber=${permit.job_number ? permit.job_number : permit.key}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {permit.job_number ? permit.job_number : permit.key}{permit.job_doc_number ? "-"+permit.job_doc_number : " "}
              <OpenInNewIcon size={11} style={{ marginTop: -2 }} />
            </a>
          </span>
        ) : (
          <span className="no-wrap">
            <a
              className="link-text"
              href={`https://a810-dobnow.nyc.gov/publish/Index.html#!/?passjobnumber=${permit.key}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
             {permit.key}{" "}
              <OpenInNewIcon size={11} style={{ marginTop: -2 }} />
            </a>
          </span>
        )}
        {/*<a href={`http://a810-bisweb.nyc.gov/bisweb/JobsQueryByNumberServlet?passjobnumber=${permit.job_number}`} target="_blank">Job #:{permit.job_number} <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
      </div>
    </div>
  );
}

export const _formatNum = (num) => {
  if (num === undefined || num === null || num < 0) {
    return "--";
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const _getServiceLevel = (building_service_level) => {
  // console.log('_getServiceLevel', building_service_level)
  var svcLevelOrder = [
    "full-service",
    "doorman_full_time",
    "doorman-full-time",
    "lobby-attendant",
    "doorman",
    "concierge",
    "live-in-super",
    "elevator-man",
    "virtual-doorman",
    // "virtual-attendant",
    // "voice-intercom",
    "intercom",
  ];
  for (var i in svcLevelOrder) {
    var svc = svcLevelOrder[i];
    if (building_service_level.indexOf(svc) >= 0) {
      return svc.replace(/_/g, " ").replace(/-/g, " ");
    }
  }

  return building_service_level[0];
};

export const _showFeatures = (features) => {
  if (!features) return;
  features = features.filter((feature) => feature != "" && feature).slice(0);
  return features.sort().map((feature, i) => {
    feature = feature ? _titleCase(feature.replace(/-/g, " ")) : "";

    if (i === features.length - 1 && features.length > 1) {
      return (
        <span key={feature} className="u_pd-rt-5">
          {" "}
          {feature}
        </span>
      );
    } else if (i === 0) {
      if (features.length > 1) {
        return (
          <span key={feature} className="u_pd-rt-5">
            {feature} <span className="u_pd-left-5">&#8226;</span>{" "}
          </span>
        );
      } else {
        return (
          <span key={feature} className="u_pd-rt-5">
            {" "}
            {feature}
          </span>
        );
      }
    } else {
      return (
        <span key={feature + i} className="u_pd-rt-5">
          {" "}
          {feature} <span className="u_pd-left-5">&#8226;</span>{" "}
        </span>
      );
    }
  });
};
export const _titleCase = (string) => {
  if (!string || typeof string !== "string") return;
  return string
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/-([a-z]?)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
};

export const ShowBadge = ({ text, small }) => {
  if (small) {
    return (
      <Tooltip title={text}>
        <IconButton size={`small`} style={{ background: "transparent" }}>
          <span className="unit-type_badge">{text}</span>
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={text}>
        <IconButton size={`small`}>
          <Badge color="stage">{text} </Badge>
        </IconButton>
      </Tooltip>
    );
  }

  return null;
};
export const _getStreetPhoto = function (building, url = false) {
  // console.log('_getBuildingPhotos ', building)

  var buildingImages = [];
  if (!building) return [];
  var address = building.display_full_street_address
    ? building.display_full_street_address
    : building.address
    ? building.address.full_street_address
    : "";

  const hood =
    building.address && building.address.city
      ? building.address.city
      : building.address && building.address.neighborhood
      ? Array.isArray(building.address.neighborhood) &&
        building.address.neighborhood.length > 0
        ? building.address.neighborhood[0]
        : building.address.neighborhood
      : false;

  var defaultImage =
    "/maps/api/streetview?key=" +
    gApiKey +
    "&size=260x260&location=" +
    address +
    ", " +
    building.city +
    ", " +
    building.zip +
    "&fov=90&pitch=10";
    
    return `${process.env.API_ROOT}/query/gsign?url=${defaultImage}`;

  //return defaultImage;
};

export const _formatFilingType = (type, tags) => {
  switch (type) {
    case "offeringplans":
      if (tags && tags.indexOf("submitted") >= 0) return "Offering Submitted";
      else if (tags && tags.indexOf("accepted") >= 0)
        return "Offering Accepted";
      else if (tags && tags.indexOf("effective") >= 0)
        return "Offering Effective";
      else return "--";
    case "permits":
      if (tags && tags.indexOf("nb") >= 0) return "New Building Filing";
      else if (tags && tags.indexOf("a1") >= 0)
        return "Major Alteration Filing";
      else if (tags && tags.indexOf("dm") >= 0) return "Demolition Filing";
      else return "--";
    case "coo":
      if (tags && tags.indexOf("coo-temporary") < 0)
        return "Final Certificate of Occupancy";
      else if (tags && tags.indexOf("coo-temporary") >= 0)
        return "Temporary Certificate of Occupancy";
    default:
      return "--";
  }
};
export const _getListingDisplayRules = (listing, authData) => {
  // console.log('_getListingDisplayRules called', listing, 'listingData', listingData,);

  const listingData = listing.key
    ? Object.assign({}, listing)
    : listing.data
    ? Object.assign({}, listing.data)
    : false;
  if (
    !listingData ||
    (listingData && !_shouldShow(listingData.source_namespace))
  ) {
    return;
  }
  listingData.source_namespace = Array.isArray(listingData.source_namespace)
    ? listingData.source_namespace
    : [listingData.source_namespace];

  // console.log('_getListingDisplayRules past !listingData', listing, 'listingData', listingData,);

  let listingObj = {
    // blur: false,
    // show: true,
  };

  if (listingData.source_namespace.includes("MARKETPROOF-ONEKEY")) {
    // console.log('MLSLI')
    /* RULES:
      - only disclaimer for IDX
      - not doing VOW for now - only 3 are VOW only
      - can't show images on old/expired/non-active listings
    */

    listingObj = {
      name: "ONEKEY MLS",
      disclaimer: true,
      isIDX: listingData.is_idx,
      isVOW: false,
      // isVOW: listingData.is_vow,
      showListing: true,
      showUnit: true,
    };
  } else if (listingData.source_namespace.includes("BKMLS")) {
    // console.log('not from REBNY, MLSLI')
    listingObj = {
      name: "BKMLS",
      disclaimer: true,
      isIDX: true,
      isVOW: false,
      showListing: true,
      showUnit: true,
    };

    // REBNY only
  } else if (!listingData.source_namespace.includes("REBNY")) {
    // console.log('not from REBNY, MLSLI')
    listingObj = {
      name: listingData.source_namespace[0],
      disclaimer: false,
      isIDX: listingData.is_idx,
      isVOW: listingData.is_vow,
      showListing: true,
      showUnit: true,
    };

    // REBNY only
  } else {
    // console.log('REBNY listing')
    if (!authData) {
      return;
    }
    // if vow needs sign in to show - NOT true if vow AND idx or just idx
    const authNeeded =
      ((authData &&
        authData.authenticated &&
        authData.userData &&
        !authData.userData.vow_verified) ||
        (authData && !authData.authenticated)) &&
      ((listingData.is_vow && !listingData.is_idx) ||
        (!listingData.is_vow && !listingData.is_idx));
    const signedIn =
      authData &&
      authData.authenticated &&
      authData.userData &&
      authData.userData.vow_verified;

    /* RULES: 
       - only disclaimer for IDX
      - only show 1 image (no carousel) if VOW and not verified and signed in 
      - can't show images on old/expired/non-active listings
      - can't show unit OR listing if not verified and signed in 
    */

    listingObj = {
      name: "REBNY",
      disclaimer: listingData.is_idx,
      isIDX: listingData.is_idx,
      isVOW:
        (!listingData.is_idx && !listingData.is_vow) || listingData.is_vow
          ? true
          : false,
      numOfImgsToShow: authNeeded ? 1 : false,
      showListing: authNeeded ? (signedIn ? true : false) : true,
      showUnit: authNeeded ? (signedIn ? true : false) : true,
      signInRequired: authNeeded,
      signedInForVOW: signedIn,
    };
  }

  // console.log('_getListingDisplayRules listingObj.name', listingObj.name, listingObj);
  return listingObj;
};

const occupancyMap = {
  ZoningOccupancyClassification: {
    1: "Assembly",
    2: "Assembly",
    3: "Assembly",
    4: "Assembly",
    5: "Assembly",
    6: "Assembly",
    7: "Office",
    8: "Educational Institution",
    9: "Factory or Industrial",
    10: "Factory or Industrial",
    11: "Factory or Industrial",
    12: "Factory or Industrial",
    13: "Factory or Industrial",
    14: "Factory or Industrial",
    15: "Factory or Industrial",
    16: "Assisted Living",
    17: "Assisted Living",
    18: "Assisted Living",
    19: "Assisted Living",
    20: "Retail",
    21: "Residential (Hotels or Dormitories)",
    22: "Residential (Apartments)",
    23: "Residential (1 and 2 Family Houses)",
    24: "Storage",
    25: "Storage",
    26: "Utility",
    27: "Factory or Industrial",
    28: "Storage",
    29: "Storage",
    30: "Retail",
    31: "Factory or Industrial",
    32: "Factory or Industrial",
    33: "Business or Office",
    34: "Assembly (Theaters)",
    35: "Assembly (Churches, Concert Halls)",
    36: "Assembly (Outdoors)",
    37: "Assembly (Museums)",
    38: "Assembly",
    39: "Assembly",
    40: "Educational Institution",
    41: "Assisted Living",
    42: "Assisted Living",
    43: "Residential (Hotels)",
    44: "Residential (Apartments)",
    45: "Three Family Dwelling",
    46: "Residential (1 & 2 Family House)",
    47: "Miscellaneous",
    48: "Public Building",
    49: "Residence Building",
    50: "Commercial Building",
  },
  ZoningOccupancyClassificationShort: {
    1: "Assembly",
    2: "Assembly",
    3: "Assembly",
    4: "Assembly",
    5: "Assembly",
    6: "Assembly",
    7: "Office",
    8: "Educational Institution",
    9: "Factory or Industrial",
    10: "Factory or Industrial",
    11: "Factory or Industrial",
    12: "Factory or Industrial",
    13: "Factory or Industrial",
    14: "Factory or Industrial",
    15: "Factory or Industrial",
    16: "Assisted Living",
    17: "Assisted Living",
    18: "Assisted Living",
    19: "Assisted Living",
    20: "Retail",
    21: "Hotel or Dormitory",
    22: "Apartments",
    23: "1 or 2 Family House",
    24: "Storage",
    25: "Storage",
    26: "Utility",
    27: "Factory or Industrial",
    28: "Storage",
    29: "Storage",
    30: "Retail",
    31: "Factory or Industrial",
    32: "Factory or Industrial",
    33: "Business or Office",
    34: "Theater",
    35: "Churches or Concert Hall",
    36: "Outdoor",
    37: "Museum",
    38: "Assembly",
    39: "Assembly",
    40: "Educational Institution",
    41: "Assisted Living",
    42: "Assisted Living",
    43: "Hotels",
    44: "Apartments",
    45: "Three Family Dwelling",
    46: "1 or 2 Family House",
    47: "Miscellaneous",
    48: "Public Building",
    49: "Residence",
    50: "Commercial",
  },
  ZoningOccupancyClassificationCode: {
    1: "a1",
    2: "a2",
    3: "a2",
    4: "a3",
    5: "a4",
    6: "a5",
    7: "b",
    8: "e",
    9: "f1",
    10: "f2",
    11: "h1",
    12: "h2",
    13: "h3",
    14: "h4",
    15: "h5",
    16: "i1",
    17: "i2",
    18: "i3",
    19: "i4",
    20: "m",
    21: "r1",
    22: "r2",
    23: "r3",
    24: "s1",
    25: "s2",
    26: "u",
    27: "a",
    28: "b1",
    29: "b2",
    30: "c",
    31: "d1",
    32: "d2",
    33: "e",
    34: "f1a",
    35: "f1b",
    36: "f2",
    37: "f3",
    38: "f4",
    39: "f4",
    40: "g",
    41: "h1",
    42: "h2",
    43: "j1",
    44: "j2",
    45: "j2",
    46: "j3",
    47: "k",
    48: "pub",
    49: "res",
    50: "com",
  },
};
export const _getOccupancy = (code, sm) => {
  var key = Object.keys(occupancyMap.ZoningOccupancyClassificationCode).find(
    (key) => occupancyMap.ZoningOccupancyClassificationCode[key] == code
  );

  if (sm) {
    return occupancyMap.ZoningOccupancyClassificationShort[key];
  } else {
    return occupancyMap.ZoningOccupancyClassification[key];
  }
};

export const _getOccupancyCode = (key, sm) => {
  if (sm) {
    return occupancyMap.ZoningOccupancyClassificationShort[key];
  } else {
    return occupancyMap.ZoningOccupancyClassification[key];
  }
};
export const _listingPrice = (price) => {
  if (!price) return;
  if (parseInt(price) <= 0) {
    return "--";
  }
  return (
    "$" +
    Math.round(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};
export const _fixGoogleImage = (res) => {
  res &&
    res.docs.forEach((doc) => {
      let lat, long, street;
      if (
        doc.address &&
        doc.address.geocode &&
        doc.address.geocode.type &&
        doc.address.geocode.coordinates
      ) {
        lat = Number(doc.address.geocode.coordinates[1]);
        long = Number(doc.address.geocode.coordinates[0]);
      } else if (
        doc.address &&
        doc.address.goat_geocode &&
        doc.address.goat_geocode.lat
      ) {
        lat = Number(doc.address.goat_geocode.lat);
        long = Number(doc.address.goat_geocode.lng);
      } else if (
        doc.address &&
        (doc.address.full_street_address || doc.address.street)
      ) {
        street = doc.address.full_street_address || doc.address.street;
      }
      if (lat && long) {
        doc.images &&
          doc.images.forEach((EachImage) => {
            EachImage.image.map((image) => {
              if (image.preview.includes("maps.googleapis.com")) {
                if (!image.preview.includes(`location=${lat},${long}`)) {
                  image.preview = image.preview.replace(
                    "location=",
                    `location=${lat},${long}`
                  );
                }
              } else if (typeof image.preview === "number") {
                let preview =
                  `https://maps.googleapis.com/maps/api/streetview?key=${gApiKey}&size=700x700&location=, new york city, &fov=90&pitch=10&source=outdoor`;
                image.preview = preview.replace(
                  "location=",
                  `location=${lat},${long}`
                );
              }
              return image;
            });
          });
      } else if (street) {
        doc.images &&
          doc.images.forEach((EachImage) => {
            EachImage.image.map((image) => {
              if (image.preview.includes("maps.googleapis.com")) {
                if (!image.preview.includes(`location=${street}`)) {
                  image.preview = image.preview.replace(
                    "location=",
                    `location=${street}`
                  );
                }
              } else if (typeof image.preview === "number") {
                let preview =
                  `https://maps.googleapis.com/maps/api/streetview?key=${gApiKey}&size=700x700&location=, new york city, &fov=90&pitch=10&source=outdoor`;
                image.preview = preview.replace(
                  "location=",
                  `location=${street}`
                );
              }
              return image;
            });
          });
      }
    });
  return res;
};
export const _getMPNDBuildingPopupURL = (building, key) => {
  const buildingURL = "/building/" + _getBuildingURLBase(building, key);
  return buildingURL;
};
export const _mapGOATData = (building, goatData) => {
  building["borough_code"] = goatData["bblBoroughCode"]
    ? parseInt(goatData["bblBoroughCode"])
    : building["borough_code"];
  building["block"] = goatData["bblTaxBlock"]
    ? parseInt(goatData["bblTaxBlock"])
    : building["block"];
  building["lot"] = goatData["bblTaxLot"]
    ? parseInt(goatData["bblTaxLot"])
    : building["lot"];
  building["bbl"] = goatData["bbl"] ? goatData["bbl"] : building["bbl"];

  if (!building["geocode"] || building["geocode"].length <= 5) {
    building["geocode"] =
      goatData["latitude"] && goatData["longitude"]
        ? goatData["latitude"] + ", " + goatData["longitude"]
        : building["geocode"];
    // console.log(building['geocode']);
  }

  return building;
};

export const _mapPLUTOData = (building, plutoDataMain) => {
  building["polygon_json"] = JSON.stringify(plutoDataMain["geometry"]);

  let plutoData = plutoDataMain["properties"];

  building["block"] = plutoData["Block"]
    ? plutoData["Block"]
    : building["block"];
  building["lot"] = plutoData["Lot"] ? plutoData["Lot"] : building["lot"];
  building["community_district"] = plutoData["CD"]
    ? plutoData["CD"]
    : building["community_district"];
  building["census_tract"] = plutoData["CT2010"]
    ? plutoData["CT2010"]
    : building["census_tract"];
  building["census_block"] = plutoData["CB2010"]
    ? plutoData["CB2010"]
    : building["census_block"];
  building["school_district"] = plutoData["SchoolDist"]
    ? plutoData["SchoolDist"]
    : building["school_district"];
  building["council"] = plutoData["Council"]
    ? plutoData["Council"]
    : building["council"];

  building["fire_company"] = plutoData["FireComp"]
    ? plutoData["FireComp"]
    : building["fire_company"];
  building["police_precinct"] = plutoData["PolicePrct"]
    ? plutoData["PolicePrct"]
    : building["police_precinct"];
  building["health_area"] = plutoData["HealthArea"]
    ? plutoData["HealthArea"]
    : building["health_area"];
  building["sanitation_borough"] = plutoData["SanitBoro"]
    ? plutoData["SanitBoro"]
    : building["sanitation_borough"];
  building["sanitation_district"] = plutoData["SanitDistrict"]
    ? plutoData["SanitDistrict"]
    : building["sanitation_district"];
  building["sanitation_subsection"] = plutoData["SanitSub"]
    ? plutoData["SanitSub"]
    : building["sanitation_subsection"];

  building["zoning_district_1"] = plutoData["ZoneDist1"]
    ? plutoData["ZoneDist1"]
    : building["zoning_district_1"];
  building["zoning_district_2"] = plutoData["ZoneDist2"]
    ? plutoData["ZoneDist2"]
    : building["zoning_district_2"];
  building["zoning_district_3"] = plutoData["ZoneDist3"]
    ? plutoData["ZoneDist3"]
    : building["zoning_district_3"];
  building["zoning_district_4"] = plutoData["ZoneDist4"]
    ? plutoData["ZoneDist4"]
    : building["zoning_district_4"];

  building["commercial_overlay_1"] = plutoData["Overlay1"]
    ? plutoData["Overlay1"]
    : building["commercial_overlay_1"];
  building["commercial_overlay_2"] = plutoData["Overlay2"]
    ? plutoData["Overlay2"]
    : building["commercial_overlay_2"];

  building["special_purpose_district_1"] = plutoData["SPDist1"]
    ? plutoData["SPDist1"]
    : building["special_purpose_district_1"];
  building["special_purpose_district_2"] = plutoData["SPDist2"]
    ? plutoData["SPDist2"]
    : building["special_purpose_district_2"];
  building["special_purpose_district_3"] = plutoData["SPDist3"]
    ? plutoData["SPDist3"]
    : building["special_purpose_district_3"];

  building["limited_height"] = plutoData["LtdHeight"]
    ? plutoData["LtdHeight"]
    : building["limited_height"];

  building["split_zoning_boundary_indicator"] = plutoData["SplitZone"]
    ? plutoData["SplitZone"]
    : building["split_zoning_boundary_indicator"];
  building["building_class"] = plutoData["BldgClass"]
    ? plutoData["BldgClass"]
    : building["building_class"];
  building["land_use_category"] = plutoData["LandUse"]
    ? plutoData["LandUse"]
    : building["land_use_category"];
  building["number_of_easements"] = plutoData["Easements"]
    ? plutoData["Easements"]
    : building["number_of_easements"];
  building["type_of_ownership_code"] = plutoData["OwnerType"]
    ? plutoData["OwnerType"]
    : building["type_of_ownership_code"];
  building["owner_name"] = plutoData["OwnerName"]
    ? plutoData["OwnerName"]
    : building["owner_name"];
  building["lot_area"] = plutoData["LotArea"]
    ? plutoData["LotArea"]
    : building["lot_area"];

  building["total_building_floor_area"] = plutoData["BldgArea"]
    ? plutoData["BldgArea"]
    : building["total_building_floor_area"];
  building["commercial_floor_area"] = plutoData["ComArea"]
    ? plutoData["ComArea"]
    : building["commercial_floor_area"];
  building["residential_floor_area"] = plutoData["ResArea"]
    ? plutoData["ResArea"]
    : building["residential_floor_area"];
  building["retail_floor_area"] = plutoData["RetailArea"]
    ? plutoData["RetailArea"]
    : building["retail_floor_area"];
  building["garage_floor_area"] = plutoData["GarageArea"]
    ? plutoData["GarageArea"]
    : building["garage_floor_area"];
  building["storage_floor_area"] = plutoData["StrgeArea"]
    ? plutoData["StrgeArea"]
    : building["storage_floor_area"];
  building["factory_floor_area"] = plutoData["FactryArea"]
    ? plutoData["FactryArea"]
    : building["factory_floor_area"];
  building["other_floor_area"] = plutoData["OtherArea"]
    ? plutoData["OtherArea"]
    : building["other_floor_area"];
  building["floor_area_source_code"] = plutoData["AreaSource"]
    ? plutoData["AreaSource"]
    : building["floor_area_source_code"];
  building["number_of_buildings"] = plutoData["NumBldgs"]
    ? plutoData["NumBldgs"]
    : building["number_of_buildings"];
  building["number_of_floors"] =
    plutoData["NumFloors"] && !_shouldShow(building["number_of_floors"])
      ? plutoData["NumFloors"]
      : building["number_of_floors"];
  building["residential_units"] =
    plutoData["UnitsRes"] && !_shouldShow(building["residential_units"])
      ? plutoData["UnitsRes"]
      : building["residential_units"];
  building["total_units"] =
    plutoData["UnitsTotal"] && !_shouldShow(building["total_units"])
      ? plutoData["UnitsTotal"]
      : building["total_units"];
  building["lot_frontage"] = plutoData["LotFront"]
    ? plutoData["LotFront"]
    : building["lot_frontage"];
  building["lot_depth"] = plutoData["LotDepth"]
    ? plutoData["LotDepth"]
    : building["lot_depth"];
  building["building_frontage"] = plutoData["BldgFront"]
    ? plutoData["BldgFront"]
    : building["building_frontage"];
  building["building_depth"] = plutoData["BldgDepth"]
    ? plutoData["BldgDepth"]
    : building["building_depth"];
  building["extension_code"] = plutoData["Ext"]
    ? plutoData["Ext"]
    : building["extension_code"];
  building["proximity_code"] = plutoData["ProxCode"]
    ? plutoData["ProxCode"]
    : building["proximity_code"];
  building["irregular_lot_code"] = plutoData["IrrLotCode"]
    ? plutoData["IrrLotCode"]
    : building["irregular_lot_code"];
  building["lot_type_code"] = plutoData["LotType"]
    ? plutoData["LotType"]
    : building["lot_type_code"];
  building["basement_code"] = plutoData["BsmtCode"]
    ? plutoData["BsmtCode"]
    : building["basement_code"];
  building["assessed_land_value"] = plutoData["AssessLand"]
    ? plutoData["AssessLand"]
    : building["assessed_land_value"];
  building["assessed_total_value"] = plutoData["AssessTot"]
    ? plutoData["AssessTot"]
    : building["assessed_total_value"];
  building["exempt_land_value"] = plutoData["ExemptLand"]
    ? plutoData["ExemptLand"]
    : building["exempt_land_value"];
  building["exempt_total_value"] = plutoData["ExemptTot"]
    ? plutoData["ExemptTot"]
    : building["exempt_total_value"];
  building["year_built"] =
    plutoData["YearBuilt"] && !_shouldShow(building["year_built"])
      ? plutoData["YearBuilt"]
      : building["year_built"];
  building["year_altered_1"] = plutoData["YearAlter1"]
    ? plutoData["YearAlter1"]
    : building["year_altered_1"];
  building["year_altered_2"] = plutoData["YearAlter2"]
    ? plutoData["YearAlter2"]
    : building["year_altered_2"];
  building["historic_district"] = plutoData["HistDist"]
    ? plutoData["HistDist"]
    : building["historic_district"];
  building["landmark_name"] = plutoData["Landmark"]
    ? plutoData["Landmark"]
    : building["landmark_name"];
  building["built_far"] = plutoData["BuiltFAR"]
    ? plutoData["BuiltFAR"]
    : building["built_far"];
  building["max_residential_far"] = plutoData["ResidFAR"]
    ? plutoData["ResidFAR"]
    : building["max_residential_far"];
  building["max_commercial_far"] = plutoData["CommFAR"]
    ? plutoData["CommFAR"]
    : building["max_commercial_far"];
  building["max_facility_far"] = plutoData["FacilFAR"]
    ? plutoData["FacilFAR"]
    : building["max_facility_far"];
  building["borough_code"] = plutoData["BoroCode"]
    ? plutoData["BoroCode"]
    : building["borough_code"];
  building["bbl"] = plutoData["BBL"] ? plutoData["BBL"] : building["bbl"];
  building["condo_num"] = plutoData["CondoNo"]
    ? plutoData["CondoNo"]
    : building["condo_num"];
  building["census_tract_2"] = plutoData["Tract2010"]
    ? plutoData["Tract2010"]
    : building["census_tract_2"];
  building["x_coordinate"] = plutoData["XCoord"]
    ? plutoData["XCoord"]
    : building["x_coordinate"];
  building["y_coordinate"] = plutoData["YCoord"]
    ? plutoData["YCoord"]
    : building["y_coordinate"];
  building["zoning_map_num"] = plutoData["ZoneMap"]
    ? plutoData["ZoneMap"]
    : building["zoning_map_num"];
  building["zoning_map_code"] = plutoData["ZMCode"]
    ? plutoData["ZMCode"]
    : building["zoning_map_code"];
  building["sanborn_map_num"] = plutoData["Sanborn"]
    ? plutoData["Sanborn"]
    : building["sanborn_map_num"];
  building["tax_map_num"] = plutoData["TaxMap"]
    ? plutoData["TaxMap"]
    : building["tax_map_num"];
  building["e_designation_num"] = plutoData["EDesigNum"]
    ? plutoData["EDesigNum"]
    : building["e_designation_num"];
  building["apportionment_bbl"] = plutoData["APPBBL"]
    ? plutoData["APPBBL"]
    : building["apportionment_bbl"];
  building["apportionment_date"] = plutoData["APPDate"]
    ? plutoData["APPDate"]
    : building["apportionment_date"];
  building["pluto_dtm_indicator"] = plutoData["PLUTOMapID"]
    ? plutoData["PLUTOMapID"]
    : building["pluto_dtm_indicator"];
  building["pluto_version_number"] = plutoData["Version"]
    ? plutoData["Version"]
    : building["pluto_version_number"];

  return building;
};
export const _formatListingHeadline = (listingHeadline) => {
  return listingHeadline
    .replace(/apt|\#/i, "Unit")
    .replace(/, building/i, "")
    .trim();
};
export const _formatUnitDisplay = (unit) => {
  return (
    "Unit " +
    unit
      .toLowerCase()
      .replace("_", "/")
      .slice(unit.indexOf("-") + 1, unit.length)
      .replace(/-/g, " ")
      .replace("unit", "")
      .replace("apt", "")
      .toUpperCase()
      .trim()
  );
};

export const _isEmpty = (value) => {
  // Check for null or undefined
  if (value == null) {
    return true;
  }

  // Check for empty string
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  // Check for empty array
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  // Check for empty object
  if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
    return true;
  }

  // For anything else, return false (including non-empty strings, arrays, objects, etc.)
  return false;
}