/* eslint-disable no-use-before-define */
import axios from "axios";

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';

const useStyles = makeStyles((theme) => ({
  root: {
	width: '100%',
	'& > * + *': {
	  marginTop: theme.spacing(3),
	},
	
  },
  '.MuiIconButton-root' : {
	  padding:'12px'
  }
}));
const apiUrl = process.env.API_ROOT;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}
const filterOptions = (options, { inputValue }) => { return options }

export default function CollectionTags({ tags, handleTagChange }) {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  React.useEffect(() => {
	  let active = true;
	  
	  const query = [{
		"$match" : {
			"$and" : [
		  	{"deleted" : {"$exists" : false}},
		  	{"group_access" : "public"}
			]
	  	}
		},
		{
		  "$project": {
			  "_id": 0,
			  "group_tags": 1
		  }
	  }, {
		  "$unwind": "$group_tags"
	  }, {
		  "$group": {
			  "_id": "$group_tags",
			  "count": {
				  "$sum": 1
			  }
		  }
	  }, {
		  "$project": {
			  "_id": 0,
			  "group_tags": "$_id",
			  "count": 1
		  }
	  }, {
		  "$sort": {
			  "count": -1
		  }
	  }];

	  axios.post(`${apiUrl}/buyerslist/groups/aggregate`, query).then((results) => {
		if (active) {
		  let newOptions = [];
  
  console.log(results)
		  if (results && results.data && Array.isArray(results.data)) {
			setOptions(results.data);
			//setOptions(results.data.map(tag => tag.group_tags));
		  }
		
		  
		}
	  });
  
	  return () => {
		active = false;
	  };
  }, [value, inputValue]);
  return (
	<div className={classes.root}>
	  <Autocomplete
		multiple
		limitTags={2}
		id="multiple-limit-tags"
		options={options}
		getOptionSelected={(option, value) => (value.group_tags ? value.group_tags : value) === (option.group_tags ? option.group_tags : option)}
		freeSolo
		onChange={(event, newValue) => {
		  console.log("onChange",newValue)
		  handleTagChange(newValue.map(option => option.group_tags ? option.group_tags : option ).filter(onlyUnique));
		}}
		onInputChange={(event, newInputValue) => {
		  console.log("onInputChange",newInputValue)
		}}
		
		getOptionLabel={(option) => option.group_tags ? option.group_tags : option}
		defaultValue={tags}
		renderInput={(params) => (
		  <TextField {...params} 
		  	onClick={(event) => {
			  event.stopPropagation();
		  	}} 
		  	variant="outlined" 
			label="Collection Tags" 
			placeholder="Add a Tag" />
		)}
		renderOption={(option) => {
			if(option.type=="add"){
			   return (
				 <div className="autocomplete-search"><AddIcon size={20} style={{ marginRight:"3px", marginTop:"-2px"}} color={'#780F9E'}  />{inputValue ? <span>Add <strong>{inputValue}</strong> As New Company</span> : <span>Add New Company</span>}</div>
			   );
			}else{
			   return (
				  <div className="autocomplete-search">{option.group_tags} <span className="small-text light-text pl-2">({option.count} Collection{option.count > 1 && 's'})</span></div>
				); 
			}
			  
			
		  }}
	  />
	</div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const standardTags = ["Manhattan", "Brooklyn", "Queens", "Bronx", "Staten Island"];
