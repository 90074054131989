import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import searchActions from "../../../redux/actions/searchActions";
import {
  Hits,
  Index,
  Highlight,
  Configure,
  connectSearchBox,
} from "react-instantsearch-dom";
import { _capitalizeText } from "../../../shared/helpers/utils";
import InputBase from "@material-ui/core/InputBase";
import SearchMapPolygon from "../../../shared/components/map/SearchMapPolygon";

import SearchTags from "./SearchTags";

//currentRefinement = user query, refine is algolia defined function
class MySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapModal: false,
      clearMap: false,
    };

    this._clearTagAndQuery = this._clearTagAndQuery.bind(this);
    this._handleMapAreaTag = this._handleMapAreaTag.bind(this);
    this._toggleMapModal = this._toggleMapModal.bind(this);
    this._clearMap = this._clearMap.bind(this);
    this._resetClearMap = this._resetClearMap.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('ASearch didUpdate ', prevProps, this.props, prevState, this.state);
  }

  _toggleMapModal() {
    const { mapModal } = this.state;
    this.setState({ mapModal: !mapModal });
  }
  _clearMap() {
    this.setState({ clearMap: true });
  }
  _resetClearMap() {
    this.setState({ clearMap: false });
  }

  _inputFocus(e) {
    const input = document.getElementById("tag-input");
    input.focus();
  }

  _clearTagAndQuery(tag) {
    this.props.refine("");
    return this.props.onIcon(tag);
  }

  _handleMapAreaTag(val) {
    const { addTag, removeTag, clearTags } = this.props.actions;
    console.log(val, "obj");
    //is an array of all the polygon values
    const polygonValue = val
      .filter((item) => Boolean(item.geometry))
      .map((item) => ({...item.geometry, polygon_id: item.id}));
    const clickedLayer = val.filter((item) => Boolean(item.clicked));

    if (clickedLayer.length) {
      clickedLayer.forEach((obj) => {
        //remove clicked key from clicked layers before sending it to query
        if (obj.hasOwnProperty("clicked")) {
          delete obj.clicked;
          addTag(obj);
        }
      });
    }
    if (polygonValue.length) {
      //this is an array of polygons
      addTag({
        type: "area",
        data: polygonValue,
        id: "custom-area",
        name: "Map Area",
      });
    }
  }

  _openResults = () => {
    //return this.props.onFocus();
  };

  // _handleKeyPress(e) {
  //   if(e.key === 'Enter'){
  //     console.log('enter press here! ')
  //   } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
  //   	console.log('arrow keys')
  //   }
  // }

  render() {
    const { mapModal, clearMap } = this.state;
    const { hideMapDraw, hasAreaSearch } = this.props;
    const searchTags = this.props.searchTags;
    const { clearTags } = this.props.actions;
    const tagLen = searchTags ? searchTags.length : 0;
    const displayTags = !this.props.isMobile
      ? searchTags && tagLen > 2
        ? searchTags.slice(0, 2)
        : false
      : searchTags && tagLen > 1
      ? searchTags.slice(0, 1)
      : false;
    const polyArea =
      searchTags.filter((tag) => tag.type == "area").length > 0
        ? searchTags.filter((tag) => tag.type == "area")[0]
        : false;
    const hideDraw = hideMapDraw;
    const disableDraw =
      searchTags.filter((tag) => tag.type != "area").length > 0;
    // console.log(
    // 	"ASearch searchTags ", searchTags, 'displayTags', displayTags, 'tagLen', tagLen)
    // 	window.location.pathname.includes("building")
    // );
    return (
      <Fragment>
        <div
          className="MuiInputBase-root navbar-center c_search-nav"
          onClick={(e) => {
            return null;
            this.props.openAConClick.bind(this);
          }}
        >
          <SearchTags
            searchBar
            clearMap={this._clearMap}
            toggleMapModal={this._toggleMapModal}
          />
          <InputBase
            autoComplete="off"
            style={{
              paddingLeft: this.props.width,
              height: this.props.height,
              paddingTop: this.props.pTop,
            }}
            onKeyPress={this._handleKeyPress}
            type="text"
            //new changes
            
            // value={polyArea ? "" : this.props.value}
            value={this.props.value}
            onChange={(e) => {
              // if (hasAreaSearch) {
              //   return null;
              // } else {
              //   this.props.refine(e.target.value);
              // }
              this.props.refine(e.target.value);
              console.log( this.props.refine(e.target.value), "hello123")
            }}
            className="c_nav-input MuiInputBase-input"
            placeholder={polyArea ? "" : this.props.placeholder}
            onFocus={this._openResults}
            id="tag-input"
            // disabled={polyArea}
          />
          {console.log(this.props.searchTags, "seraching")}
          {tagLen > 0 && (
            <span
              style={{ marginRight: !hideDraw ? 115 : 20 }}
              className={`c_clear-search`}
              onClick={() => {
                clearTags();
                this._clearMap();
              }}
            >
              Clear
            </span>
          )}

          {!hideDraw && (
            <SearchMapPolygon
              standardView
              handleMapAreaTag={this._handleMapAreaTag}
              polyArea={polyArea}
              mapModal={mapModal}
              toggleMapModal={this._toggleMapModal}
              clearMap={clearMap}
              disableDraw={disableDraw}
              resetClearMap={this._resetClearMap}
              //commented this..as in Map polygon, already using redux state
              // searchTags={this.props.searchTags}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

const ASearch = connectSearchBox(MySearch);

function mapStateToProps(state) {
  console.log(state);
  return {
    searchTags: Array.isArray(state.search.searchTags)
      ? state.search.searchTags.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ASearch);
