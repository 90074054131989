import React from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import MainIcon from 'mdi-react/DomainIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/MagnifyIcon';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const EmailConfirmationCard = ( props ) => {

  return (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className={`email-confirmation`}>
          <div className="email-confirmation__icon">
            <MainIcon className="email-confirmation__mail" />
            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
          </div>
          <h3 className="email-confirmation__title">No Building Saved</h3>
          {!isMobile ? 
	         <ButtonToolbar className="email-confirmation__buttons">
	            <Link to="/building-database/sale">
	            <Button
			         variant="contained"
			         color="primary"
			         className="buyersList__primary-button"		         
			         startIcon={null}
			     >	Search Buildings
			     </Button>	
			     
			     </Link>
	          </ButtonToolbar>
          :
		     <Link to="/building-database/sale">
		     	
		     	<div style={{fontSize:'14px',margin:20,color:'#9600BF'}}>
		     		Start by researching buildings &raquo;
		     	</div>
		     </Link>
		  }
        </div>
      </CardBody>
    </Card>
  </Col>
  )
};


export default EmailConfirmationCard;
