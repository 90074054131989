import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Progress, Badge
} from 'reactstrap';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import UnitsSearchTableHead from './UnitsSearchTableHead';
import MatTableToolbar from './MatTableToolbar';
//import UnitsSearchAppBar from './UnitsSearchAppBar';
import UnitsSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';
import UnitStageFilter from '../../../shared/components/unit_filters/UnitStageFilter';

import UnitsListTable from './UnitsListTable';
import LinearLoading from '../../../shared/components/LinearLoading';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {isMobile} from 'react-device-detect';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';

import TrendDownIcon from 'mdi-react/TrendingDownIcon';
import TrendUpIcon from 'mdi-react/TrendingUpIcon';



import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import QuarterlyIcon from 'mdi-react/CalendarBlankIcon';
import MonthlyIcon from 'mdi-react/CalendarMonthOutlineIcon';


import GroupedUnitsSearchTableHead from './GroupedUnitsSearchTableHead';
import ExpandMoreIcon from  'mdi-react/ExpandMoreIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import BoroughAnalysis from './components/BoroughAnalysis';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Panel from '../../../shared/components/Panel';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';

import queryString from "query-string";

import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	_scrollID,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	syncGenericFilter,
	_objectEqual,
	getCohortQuery,
	_isHouse
} from "../../../shared/helpers/utils";

import { 
	_marketActivityFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../shared/helpers/filters";
import { _hasPermission	} from "../../../shared/helpers/access";

let counter = 0;
am4core.useTheme(am4themes_animated);
function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';
const lsPre = "PTV2_";
const subFilterKey = 'subFilters';
const unitStagesKey = 'unitStages';
const buildingStagesKey = 'buildingStages';
//const defaultStage = ["all"];
const defaultUnitStage = ['all'];
const defaultGap = getLocalStorage(lsPre, 'gap') && getLocalStorage(lsPre, 'gap') === '+1MONTH' ? 'month' : 'week';
const dateStart = '01/01/2005';
const dateStartContract = '01/01/2015';
const isDST = moment().isDST();
const defaultSubFilter = {};

class UnitsSearchTable extends PureComponent {
  defaultStage = _hasPermission(this.props.user, 'sponsor-buildings') && _hasPermission(this.props.user, 'resale-buildings') ? ['all'] : _hasPermission(this.props.user, 'sponsor-buildings') ? ["sponsor"] : ['resale'];
  defaultUnitStage = _hasPermission(this.props.user, 'sponsor-buildings') ? ['all'] : ['all'];
  state = {
	order: syncGenericFilter('order', lsPre, 'order', "desc"),
    orderBy:  syncGenericFilter('order_by', lsPre, 'orderBy', "avg_ppsf"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 100,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    showBottomLoader: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: syncURLSearch(this.props, 'searchText'),
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: null,
	subFilters: syncURLFilters(this.props, _marketActivityFilters.filter(filter => !filter.transaction_hide), subFilterKey, lsPre),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	groupedData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	startDate:moment(dateStart, "MM/DD/YYYY").toDate().toISOString(),
	endDate: moment().add(-15, 'day').endOf('month').set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString(),
	gap:"+7DAY",
	chartData:[],
	dataStart:isMobile 
				? syncGenericFilter('start_date', lsPre, 'dataStart', moment(dateStart, "DD/MM/YYYY").toDate().toISOString())
				: syncGenericFilter('start_date', lsPre, 'dataStart', moment(dateStart, "DD/MM/YYYY").toDate().toISOString()),
	dataEnd:syncGenericFilter('end_date', lsPre, 'dataEnd', moment().add(-15, 'day').endOf('month').set({hour:0,minute:0,second:0,millisecond:0}).toDate().toISOString()),
	buildingEventCount:0,
	selectedDate:syncGenericFilter('selected_date', lsPre, 'selectedDate', false),
	eventTypes:syncGenericFilter('event_types', lsPre, 'eventTypes', [ "Condo", "Co-op", "New Dev", "Condo PSF", "Co-op PSF", "New Dev PSF", "Single-Fam", "Multi-Fam", "Single-Fam PSF", "Multi-Fam PSF"], true),
	interval: syncGenericFilter('interval', lsPre, 'interval', "quarterly"),
	activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
	buildingStages: syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, lsPre, this.defaultStage),
	unitStages:  syncStageFilters(this.props, _unitStageFilter, unitStagesKey, lsPre, this.defaultUnitStage),
	soldTimeField: "close_date_formatted",
	ppsfType: "average"
  };

  defaultQ = "offering_verified_status:* AND -offering_verified_status:\“\” AND -offering_verified_status:\“excluded\” AND -offering_verified_status:\“abandoned\” AND deleted:false AND deleted:false";
  q='offering_verified_status:* AND -offering_verified_status:\“\” AND -offering_verified_status:\“excluded\” AND -offering_verified_status:\“abandoned\” AND deleted:false';
  defaultFQ = [
	`close_date_formatted:[${this.state.startDate} TO ${this.state.endDate}]`, 
  	"status:sold", 
  	"transaction_source:(*ACRIS* *acris*)", 
  	"price:[300000 TO 250000000]", 
	"parcels_count:[* TO 2]",
 
  ];
  
  fq = false;
  tq = "*:*"
  property_q = '';
  building_q = '';
  chartLoaded = false;

  
  activeBullets = false;
	
	
  fl = buildingFL;
  chart;
  _unitSubFilter = _marketActivityFilters.filter(filter => !filter.transaction_hide);
  _transactionFilter = _marketActivityFilters.filter(filter => !filter.filter_type && !filter.transaction_hide);
  _propertyFilter = _marketActivityFilters.filter(filter => filter.filter_type && filter.filter_type === 'property' && !filter.transaction_hide);
  _buildingFilter = _marketActivityFilters.filter(filter => filter.filter_type && filter.filter_type === 'building' && !filter.transaction_hide);
  timeout = null;
   _matchedBuildingIds = [];
   _matchedBuildingSlugs = [];
   formattedData;
   tempEventTypes = this.state.eventTypes.slice(0);
   noDataIndicator;
  componentDidMount() {
  	 this._getStats(true);
  	 
  	
  }
 
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
	  	let unitStageChange = !_isEqual(prevState.unitStages, this.state.unitStages);
	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
		if (
								
			this.state.interval !== prevState.interval ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged ||
			buildingStageChange ||
			unitStageChange ||
			this.state.soldTimeField !== prevState.soldTimeField ||
			this.state.ppsfType !== prevState.ppsfType
			) {
			  console.log("component update", prevState, this.state)		
			  this._getStats(true);
		}else if(this.state.dataStart !== prevState.dataStart ||
			this.state.dataEnd !== prevState.dataEnd ||
			this.state.selectedDate !== prevState.selectedDate ||			
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.activeTab !== prevState.activeTab ){
			
			this._getStats(false);

		}
		
		if(prevProps.match.params.tab !== this.props.match.params.tab){
			this.setState({activeTab:this.props.match.params.tab })			
		}
		setURLFromState(this.props, this.state);
		

	  
  }
  
  buildChart = (data) => {
	    let chart = am4core.create("chartdiv", am4charts.XYChart);
	    const { user } = this.props;
  	 	const { selectedDate, eventTypes, startDate,  dataStart, endDate, dataEnd, interval, ppsfType} = this.state;
  	    chart.scrollbarX = new am4core.Scrollbar();
  	 // Add data
		
		chart.data = data;

		function showIndicator(noDataIndicator) {
		  if (noDataIndicator) {
		    noDataIndicator.show();
		  }
		  else {
		    noDataIndicator = chart.tooltipContainer.createChild(am4core.Container);
		    noDataIndicator.background.fill = am4core.color("#fff");
		    noDataIndicator.background.fillOpacity = 0.8;
		    noDataIndicator.width = am4core.percent(100);
		    noDataIndicator.height = am4core.percent(100);
		
		    var indicatorLabel = noDataIndicator.createChild(am4core.Label);
		    indicatorLabel.text = "No data found for your search.";
		    indicatorLabel.align = "center";
		    indicatorLabel.valign = "middle";
		    indicatorLabel.fontSize = 14;
		  }
		}
		
		function hideIndicator(noDataIndicator) {
		   noDataIndicator.hide();
		}
		
		function highLightColumn(categoryX){
			chart.series.each(function(series) {				    
			     series.columns && series.columns.values.forEach(c => {
				    if (c.dataItem.categoryX == categoryX) {
				          c.isActive = true;
				    }
				    
				 });
				 
				 series.bullets && series.bullets.each(bullet => {
					 bullet.clones.each(c => {
						 if (c.dataItem.categoryX == categoryX) {
						    c.isActive = true;
						 }
					 })
				
				  });
			}.bind(this));
		}
		
		function unHighLightAll(categoryX){
			chart.series.each(function(series) {				    
			     series.columns && series.columns.values.forEach(c => {
				    if (c.dataItem.categoryX == categoryX) {
				          c.isActive = !c.isActive;
				    }else{
						  c.isActive = false;
					}
				    
				 });
				 
				 series.bullets && series.bullets.each(bullet => {
					 bullet.clones.each(c => {
						 if (c.dataItem.categoryX == categoryX) {
						    c.isActive = !c.isActive;
						 }else{
							c.isActive = false;
						 }
					 })
				
				  });
			}.bind(this));
		}
		
		chart.events.on("beforedatavalidated", function(ev) {
		  // check if there's data
		  if (ev.target.data.length == 0) {
		    showIndicator(this.noDataIndicator);
		  }
		  else if (this.noDataIndicator) {
		    hideIndicator(this.noDataIndicator);
		  }
		}.bind(this));
		
		chart.events.on("ready", function(ev) {
			    console.log("here")
			    if(selectedDate){
					const columnX  = this.getIntervalName(selectedDate);
					highLightColumn(columnX);
				}
			  
		}.bind(this));
		
		// Create axes
		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "time";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = "right";
		categoryAxis.renderer.labels.template.verticalCenter = "middle";
		categoryAxis.renderer.labels.template.rotation = 290;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.minHeight = 110;
	
		
		
		const slideChange = (ev) => {
			if(this.timeout) clearTimeout(this.timeout);
			
			const { dataStart, dataEnd } = this.state;
			let axis = ev.target;
			let start = axis.getPositionLabel(axis.start);
			let end = axis.getPositionLabel(axis.end);
			
			const formattedStart = this.getIntervalTime(start);
			const formattedEnd = this.getIntervalTime(end);
			
			if(start && end && (dataStart !== start || dataEnd !== end)){
				this.timeout = setTimeout(function (start, end, series) {
				//  
				   if(this.activeBullets) this.activeBullets.isActive = false;
				   if(this.chartLoaded){
					   chart.series.each(function(series) {
					      series.columns && series.columns.values.forEach(c => c.isActive = false);
					      series.bullets && series.bullets.each(bullet => {
							 bullet.clones.each(c => {				
									c.isActive = false;
								 
							 })					
						  });
					    });
				       this.setState({
					       dataStart: formattedStart.toDate().toISOString(), 
					       dataEnd: formattedEnd.toDate().toISOString(),
					       selectedDate: false,
					       eventTypes: this.tempEventTypes.slice(0)
					   });
					}

				}.bind(this, start, end, chart), 100)				
			}			
		}
		
		categoryAxis.events.on("endchanged", slideChange);
		categoryAxis.events.on("startchanged", slideChange);
		
		
		const totalSpan = moment(endDate).diff(moment(startDate));
		const dateDiffStart = moment(dataStart).diff(moment(startDate));
		const dateDiffEnd = moment(dataEnd).diff(moment(startDate));


		categoryAxis.start = dateDiffStart / totalSpan;//isMobile ? 0.875 : 0.8;
		categoryAxis.end = dateDiffEnd / totalSpan;

		categoryAxis.keepSelection = true;
		
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.minWidth = 50;
		
		
		// Create series
		const createSeriesLine = (field, name, type) => {
  
		   // Second value axis
		 		  // Set up series
		  var series = chart.series.push(new am4charts.LineSeries());
		  series.name = `${name}`;
		  series.dataFields.valueY = field;
		  series.dataFields.categoryX = "time";
		  series.sequencedInterpolation = true;
		  series.connect = false;
		  
/*
		  series.defaultState.transitionDuration = 300;
		  series.hiddenState.transitionDuration = 300;
*/
		  series.showOnInit = false;
		  valueAxis.renderer.opposite = true;	
		  valueAxis.title.text = "";
		   
		  series.strokeWidth = 2;
		  series.tensionX = 0.77;
		  //valueAxis.min = 900;
		  valueAxis.extraMax = .014;

		 
		  
		  series.bullets.template.width = am4core.percent(80);
			  
		  
		  // Make it stacked
		 // series.stacked = true;
		  let color = "#780F9E";
		  if(field === "coop") color = "#28C2FF";
		  if(field === "newdev") color = "#FF206E";
		  if(field === "single_family") color = "#FFD23F";
		  if(field === "multi_family") color = "#FF691E";
		  series.stroke = am4core.color(color);
		  
		  var bullet = series.bullets.push(new am4charts.Bullet());
			bullet.fill = am4core.color(color); // tooltips grab fill from parent by default
			bullet.tooltipText = "[#fff font-size: 14px]{categoryX} - [/][#fff font-size: 14px] {name}: [#fff bold]${valueY}[/] [#fff]{additional}[/]";
		
			bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
			bullet.setStateOnChildren = true;
			
			var circle = bullet.createChild(am4core.Circle);
			circle.radius = 4;
			circle.fill = am4core.color("#FFF");
			circle.strokeWidth = 2;
			
			
			var hoverState = bullet.states.create("hover");
			hoverState.properties.scale = 1.5;
			
			series.bullets.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
			
			var activeState = bullet.states.create("active");
			activeState.fill = am4core.color('#5C1F7A');
			activeState.properties.fillOpacity = 1;
			activeState.properties.strokeOpacity = 1;
			
			var circleActiveState = circle.states.create("active");
			circleActiveState.properties.radius = 6;
			circleActiveState.properties.fill = am4core.color(color);
			
			
			bullet.events.on("hit", function(ev){
			 const { dataStart, dataEnd, interval } = this.state;
			 if(this.activeBullets && ev.target !== this.activeBullets) this.activeBullets.isActive = false;
			  
			  unHighLightAll(ev.target.dataItem.categories.categoryX);
/*
			  chart.series.each(function(series) {				  
				    series.bullets && series.bullets.each(function(c){
					    if(c !== ev.target){
						    c.isActive = false;
					    }else{
						    c.isActive = !c.isActive;
					    }
				    }.bind(this))
			  }.bind(this));
*/
			  
			//  ev.target.isActive = !ev.target.isActive;
			  
			  if(ev.target.isActive){
				  this.activeBullets = ev.target;

				  let start = ev.target.dataItem.categories.categoryX;
				  highLightColumn(start);
				  let formattedEnd = interval === 'quarterly' ? this.getIntervalTime(start).add(3, 'month') : this.getIntervalTime(start).add(1, 'month');
				  
				  const formattedStart = this.getIntervalTime(start);
				  let type = ev.target.dataItem.component.dataFields.valueY;

				  
				  if(dataStart !== formattedStart || dataEnd !== formattedEnd){
					 setTimeout(function (formattedStart, formattedEnd, series) {
					       this.setState({
						       selectedDate: formattedStart.add((isDST ? 0 : 1), 'hour').toDate().toISOString(), 
						   });
		
					}.bind(this, formattedStart, formattedEnd, series), 100)
					   
				  }			  
			  }else{	
				  this.activeBullets = ev.target;		  
				  this.setState({
					  selectedDate:false,
				  })			  
			  }
			}.bind(this));
			
			if(eventTypes.indexOf(name) < 0){
			  series.hidden = true;
			  series.hide();
		    }
					  
		  return series;
		};
		
		// Create series
		// Create series
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		  valueAxis.title.text = "";
		  valueAxis.renderer.opposite = false;	
		  valueAxis.min = 0;
		const createSeriesColumn = (field, name) => {
			
			
		  // Second value axis
		  
  
		  // Set up series
		  var series = chart.series.push(new am4charts.ColumnSeries());
		  series.name = name;
		  series.dataFields.valueY = field;
		  series.dataFields.categoryX = "time";
		  series.sequencedInterpolation = true;
		  series.yAxis = valueAxis;
/*
		  series.defaultState.transitionDuration = 300;
		  series.hiddenState.transitionDuration = 300;
*/
		  series.showOnInit = false;
		  
		  // Make it stacked
		  series.stacked = true;
		  series.clustered = true;
		  
		  // Configure columns
		  series.columns.template.width = am4core.percent(80);
		  series.columns.template.tooltipText = "[#fff font-size: 14px]{categoryX} - {name}: [#fff bold]{valueY}[/][/]";  
		  
		  
		
		  series.columns.template.column.cornerRadiusTopLeft = 0;
		  series.columns.template.column.cornerRadiusTopRight = 0;
		  series.columns.template.column.fillOpacity = 0.8;
		  series.columns.template.strokeWidth = 0;
		
		  series.columns.template.column.cursorOverStyle = am4core.MouseCursorStyle.pointer;
		  
		  var hoverState = series.columns.template.column.states.create("hover");
			hoverState.properties.cornerRadiusTopLeft = 0;
			hoverState.properties.cornerRadiusTopRight = 0;
			hoverState.properties.fillOpacity = 1;
			
			//series.columns.template.column.togglable=true;
			
			//series.columns.template.column.togglable=true;
			var activeState = series.columns.template.states.create("active");
			activeState.properties.fill = am4core.color('#290059');
			activeState.dx = 4;
			activeState.dy = 4;
			activeState.opacity = 0.3;
			


			series.columns.template.events.on("hit", function(ev) {
			  const { dataStart, dataEnd, interval } = this.state;
			  console.log(ev)
			  
			  unHighLightAll(ev.target.dataItem.categories.categoryX);
/*
			  chart.series.each(function(series) {
				    series.columns && series.columns.values
					  .filter(c => c !== ev.target)
					  .forEach(c => c.isActive = false);
			  });
			  
			  ev.target.isActive = !ev.target.isActive;
*/
			  
			  if(ev.target.isActive){
				 

				  let start = ev.target.dataItem.categories.categoryX;
				  highLightColumn(start);
				  let formattedEnd = interval === 'quarterly' ? this.getIntervalTime(start).add(3, 'month') : this.getIntervalTime(start).add(1, 'month');
				  
				  const formattedStart = this.getIntervalTime(start);
				  let type = ev.target.dataItem.component.dataFields.valueY;

				  
				  if(dataStart !== formattedStart || dataEnd !== formattedEnd){
					 setTimeout(function (formattedStart, formattedEnd, series) {
					       this.setState({
						       selectedDate: formattedStart.add((isDST ? 0 : 1), 'hour').toDate().toISOString(), 
						   });
		
					}.bind(this, formattedStart, formattedEnd, series), 100)
					   
				  }			  
			  }else{			  
				  this.setState({
					  selectedDate:false,
				  })			  
			  }
			  
			}.bind(this));


			let color = "#A3ADC2";
			if(field === "condo_count") color = "#5C6178";
			if(field === "coop_count") color = "#A3ADC2";
	
			// let color = "#780F9E";
		  	// if(field === "coop_count") color = "#28C2FF";
		  	// if(field === "condo_count") color = "#780F9E";
			if(field === "newdev_count") color = "#363347";
			if(field === "single_family_count") color = "#BFC4D1";
			if(field === "multi_family_count") color = "#DBE0E8";
			
			series.columns.template.adapter.add("fill", function(fill, target) {
			  //return chart.colors.getIndex(target.dataItem.index);
			  
			  return am4core.color(color);
			}.bind(this));
			
			
			if(eventTypes.indexOf(name) < 0){
			  series.hidden = true;
			  series.hide();
		    }
			
 		  return series;
		};
		
		
		
		//createSeriesColumn("count", "Number of Sales");
		if(_hasPermission(this.props.user, 'sponsor-buildings')){
			createSeriesColumn("newdev_count", "New Dev");
		}
		createSeriesColumn("condo_count", "Condo");
		createSeriesColumn("coop_count", "Co-op");
		createSeriesColumn("single_family_count", "Single-Fam");
		createSeriesColumn("multi_family_count", "Multi-Fam");
		if(_hasPermission(this.props.user, 'sponsor-buildings')){
			createSeriesLine("newdev", "New Dev PSF", ppsfType == "median" ? "Med" : "Avg");
		}
		createSeriesLine("condo", "Condo PSF", ppsfType == "median" ? "Med" : "Avg");
		createSeriesLine("coop", "Co-op PSF", ppsfType == "median" ? "Med" : "Avg");
		createSeriesLine("single_family", "Single-Fam PSF", ppsfType == "median" ? "Med" : "Avg");
		createSeriesLine("multi_family", "Multi-Fam PSF", ppsfType == "median" ? "Med" : "Avg");
		
		
		//createSeriesLine("avg_discount_without_tax", "Avg Listing Price Discount");
		//createSeriesLine("avg_ppsf", "Avg Offering Price Discount");
		
		
		
		
		// Cursor
		chart.cursor = new am4charts.XYCursor();
		chart.cursor.lineX.stroke = am4core.color("#000");	
		chart.cursor.lineX.strokeWidth = 2;
		chart.cursor.lineX.strokeOpacity = 1;
		chart.cursor.lineX.strokeDasharray = "6,4";
		
		chart.cursor.lineY.stroke = am4core.color("#000");
		chart.cursor.lineY.strokeWidth = 2;
		chart.cursor.lineY.strokeOpacity = 1;
		chart.cursor.lineY.strokeDasharray = "6,4";
				
		chart.exporting.menu = new am4core.ExportMenu();		
		chart.zoomOutButton.background.fill = am4core.color("#780F9E");
		chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#9600BF");
		
		
		// Legend
		chart.legend = new am4charts.Legend();
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = 15;
		markerTemplate.height = 15;
		chart.legend.labels.template.width = 450;
		chart.legend.labels.template.truncate = false;

		chart.legend.itemContainers.template.events.on("hit", function(ev) {
			console.log(ev);
		  
		  const { eventTypes } = this.state;
		  const type = ev.target.dataItem.name;
		   chart.series.each(function(series) {
		      //series.columns && series.columns.values.forEach(c => c.isActive = false);
		      
		      //series.bullets && series.bullets.each(c => c.isActive = false);
		    });
			
			let itemContainer = ev.target;
			let dataItem = itemContainer.dataItem;
			let series = dataItem.dataContext;
			
			// Check for the specific series by name
			if (series.name === "Multi-Family") {
				// Here, we manipulate the itemContainer to achieve the break effect
				// For instance, setting a large marginBottom creates a break
				itemContainer.marginBottom = 20;
			
				// Optionally, you could also change the appearance to indicate a new section
				// e.g., itemContainer.background.fill = am4core.color("#f0f0f0");
			}
		    
		   setTimeout(function (eventTypes, type) {
			 console.log(eventTypes, type)
			  if(this.tempEventTypes.indexOf(type) >= 0){
				  this.tempEventTypes = this.tempEventTypes.filter(item => item !== type).slice(0);
				  this.setState( { eventTypes: this.tempEventTypes.slice(0) })
				  setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }else{
				 this.tempEventTypes = [...this.tempEventTypes, ...[type]];
				 this.setState( { eventTypes: this.tempEventTypes.slice(0) } )
				 setLocalStorage(lsPre, 'eventTypes', this.tempEventTypes.slice(0));
			  }
		    }.bind(this, eventTypes, type), 100);
		  //let seriesColumn = ev.target.dataItem.dataContext.columns.template;
		  
		}.bind(this));

	

		chart.exporting.menu.items = [{
		  "label": "Export",
		  "menu": [
		    { "type": "png", "label": "Image (PNG)" },
		    { "type": "pdf", "label": "PDF" },
// 		    { "type": "csv", "label": "Data (CSV)" },
		  ]
		}];
		if(_hasPermission(user, 'data-export')){
			chart.exporting.menu.items[0].menu.push({ "type": "csv", "label": "Data (CSV)" });
			
		}
		chart.exporting.filePrefix="Marketproof New Development - Price Trend";
		this.chart = chart;
  }
  
  
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'building_slugs');
	 
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  getIntervalName(date){
	  
	  const { interval } = this.state;	  
	  if(interval === 'monthly'){
		  return moment(date).format('MMM, YYYY');
	  }
	  
	  
	  const m = moment(date).format('M');
	  const y = moment(date).format('YYYY');
	  const tMap={
		  1 : 'Q1',
		  2 : 'Q1',
		  3 : 'Q1',
		  4 : 'Q2',
		  5 : 'Q2',
		  6 : 'Q2',
		  7 : 'Q3',
		  8 : 'Q3',
		  9 : 'Q3',
		  10 : 'Q4',
		  11 : 'Q4',
		  12 : 'Q4'
	  }
	  return tMap[m] + ', ' + y;
  }
  
  getIntervalTime(date){
	  if(!date){
		  return false;
	  }
	  const { interval } = this.state;	  
	  if(interval === 'monthly'){
		  return moment(date, 'MMM, YYYY');
	  }
	 
	  const parts = date.split(',');
	  const tMap={
		  1 : 1,
		  2 : 4,
		  3 : 7,
		  4 : 10
	  }	
	  if(parts[0].indexOf('Q')>=0){
		  const a = parts[0][1];
		  const m = tMap[a];
		  const y = parts[1].trim();
		  
		  return moment(`${m}/${y}`, 'M/YYYY');
	  }
	  
	  
	  
  }
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _getStats = async (resetPage) => {
		this.q =  '';
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: resetPage, showBottomLoader:true, groupedData:false, tableData:false, reactTableFiltered:false }));
		}
		
		const { searchTags } = this.props;
		
		const { buildingStages, unitStages, soldTimeField, ppsfType } = this.state;
		
		let locQuery = _tranformTagsQuery(searchTags, 'building');
		

		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}

		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._transactionFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.q += this.q.length > 0 ? " AND " : "";
						this.q += `(${searchKey}:(${qVal}))`;
					}else{
						
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						
						if(qVal!==null){
							this.q += this.q.length > 0 ? " AND " : "";
						    this.q += `(${key}:${qVal})`;
						}
					}
				}
			});
		}
		
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._transactionFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							
							priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							
							
						}											
					}	
					
					const dateArrayFilter = this._transactionFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						dateReplaceKeys = [...dateReplaceKeys, ...dateArrayFilter[0].search_key];					
						if(dateQ){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._transactionFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					
					this.unitFq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		if(dateQ){
			dateReplaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				dateQ = dateQ.replace(regex, '*')
			
			})
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += '(';
			this.q += dateQ;
			this.q += ')';			
		}
		/****Price& Date query***/
		
		if(unitStages && unitStages.length>0){
			if(unitStages.indexOf("all")<0 && !(unitStages.indexOf("sponsor")>=0 && unitStages.indexOf("resale")>=0)){
				this.q += this.q.length > 0 ? " AND " : "";
				this.q += `${unitStages.indexOf("sponsor")>=0 ? "(sponsored_sale:true AND ownership_type:condo)" : "-(sponsored_sale:true AND ownership_type:condo)"}`;
			}
			
		}
		
		setURLFromState(this.props, this.state);
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		let sort = null;
		if(this.state.orderBy && this.state.orderBy.length > 0){			
				sort = `${this.state.orderBy} ${this.state.order}`;			
		}
		
		const gap = this.state.interval==='quarterly' ? "+3MONTHS" : "+1MONTHS";
		
		//this.fq[0] = `close_date_formatted:[${this.state.dataStart} TO ${this.state.dataEnd}]`;
		this.defaultFQ[0] = `${soldTimeField}:[${this.state.startDate} TO ${this.state.endDate}]`;
		this.fq = this.defaultFQ.slice();		
		//this.fq.push(`{!join from=key fromIndex=we3-properties to=property}${this.q}`);
		let buildingQ = getCohortQuery(this.props.user, true);
		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._buildingFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							buildingQ += buildingQ.length > 0 ? " AND " : "";
							buildingQ += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						buildingQ += buildingQ.length > 0 ? " AND " : "";
						buildingQ += `(${searchKey}:(${qVal}))`;
					}else{
						
						
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						if(qVal!==null){
							buildingQ += buildingQ.length > 0 ? " AND " : "";
						    buildingQ += `(${key}:${qVal})`;
						}
					}
					
				}
			});
		}
		
		if(buildingStages && buildingStages.length>0){
			if(buildingStages.indexOf("all")<0){
				buildingQ += buildingQ.length > 0 ? " AND " : "";
				buildingQ += `building_status:("${buildingStages.join("\" OR \"")}")`;

			}
		}
		
		if(buildingQ.length > 0){
			this.building_q = buildingQ;
			this.fq.push(`{!join from=alternate_keys fromIndex=we3-buildings to=building}${buildingQ}`);
		}
		
		if(soldTimeField == "contract_date_formatted"){
			this.fq.push("{!frange l=1 u=1}if(gt(close_date_formatted, contract_date_formatted),1,0)");
		}
		
		
		let buildingDates = `${soldTimeField}:[${this.state.dataStart} TO ${this.state.dataEnd}]`;
		let rankDates = buildingDates; //`close_date_formatted:[${moment(this.state.dataEnd).add(this.state.interval === 'monthly' ? -12 : -12, 'months').toDate().toISOString()} TO ${this.state.dataEnd}]`
		
		if(this.state.selectedDate){
			
			let endDate = moment(this.state.selectedDate).add(3, 'months').toDate().toISOString();
			if(this.state.interval === 'monthly'){
				endDate = moment(this.state.selectedDate).add(1, 'months').toDate().toISOString();
				
			}			
			buildingDates = `${soldTimeField}:[${this.state.selectedDate} TO ${endDate}]`;			
			rankDates = `${soldTimeField}:[${this.state.selectedDate} TO ${endDate}]`
			
		}
		
		this.property_q = '';
		if (this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._propertyFilter.filter(filter => filter.search_key === key);
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.property_q += this.property_q.length > 0 ? " AND " : "";
							this.property_q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else if(theFilter && theFilter[0] && theFilter[0].autocomplete){
						const searchKey = theFilter[0] && theFilter[0].search_key;
						let operator = "AND";
						let searchValue =  Array.isArray(subFilterVal) ? subFilterVal : [subFilterVal];
						searchValue = searchValue.map(val => {
							if(val.indexOf("~")>=0){
								const parts = val.split("~");
								operator = parts[parts.length-1];
								return parts[0];
							}
						})
						
						const qVal = searchValue.map(val => `*${val.replace(/[^a-zA-Z0-9]/g, '*')}*`).join(` ${operator} `);
						this.property_q += this.property_q.length > 0 ? " AND " : "";
						this.property_q += `(${searchKey}:(${qVal}))`;
					}else{
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;

						if(qVal!==null){
							this.property_q += this.property_q.length > 0 ? " AND " : "";
						    this.property_q += `(${key}:${qVal})`;
						}
					}
					
				}
			});
		}
		
		if(this.property_q){
			this.property_q = `{!join from=key fromIndex=we3-properties to=property}${this.property_q} AND deleted:false`;
			this.fq.push(this.property_q);
		}


		let axiosData = {
			url: buildingUrl + "we3-transactions?cache=60",
			method: "post",
			query: {
				q: this.q,
				fq: this.fq,
				fl: null,
				rows:0,
				start: 0,
				wt: "json",	
				"json.facet" : {
						"by_building": {
							"type" : "query",
							"q" : buildingDates,
							"facet":{
								"num_buildings" : "unique(building)",
								"by_buildings": {
									"type": "terms",
									"field": "building",
									"mincount": 1,
									"sort" : sort,
									"offset": resetPage ? 0 : this.state.start,
									"limit": this.state.rowsPerPage,								
									"facet": {
										"avg_ppsf": "avg(price_per_sqft)",
										"med_ppsf": "percentile(price_per_sqft, 50)",
										"avg_price" : "avg(price)",
										"ppsf_stats" : {
											"type" : "query",
											"q" : "price_per_sqft:[300 TO 20000]",
											"facet" : {
												"avg_ppsf": "avg(price_per_sqft)",
												"med_ppsf": "percentile(price_per_sqft, 50)",
												"avg_price" : "avg(price)"
											}
										}
									}
								}
							}				
						},						
						"rank_analysis": {
							"type" : "query",
							"q" : rankDates + ' AND price_per_sqft:[300 TO 20000]',
							"facet":{
								"by_boroughs": {
									"type": "terms",
									"field": "borough",
									"mincount": 0,
									"limit" : 5,
									"sort" : "avg_ppsf desc",
									"facet": {
										"avg_ppsf": "avg(price_per_sqft)",
										"med_ppsf": "percentile(price_per_sqft, 50)",
										"avg_price" : "avg(price)"
									}
								},
								"by_neighborhoods": {
									"type": "terms",
									"field": "neighborhood",
									"mincount": 1,
									"limit" : 999,
									"sort" : "avg_ppsf desc",
									"facet": {
										"avg_ppsf": "avg(price_per_sqft)",
										"med_ppsf": "percentile(price_per_sqft, 50)",
										"avg_price" : "avg(price)",
										"by_borough" : {
											"type": "terms",
											"field": "borough"
										}
									}
								},
								"by_bedrooms": {
									"type" : "query",
									"q" : "bedrooms:[0 TO 5]",
									"facet":{
										"bedrooms" : {
											"type": "terms",
											"field": "bedrooms",
											"mincount": 1,
											"limit" : 5,
											"sort" : "avg_ppsf desc",
											"facet": {
												"avg_ppsf": "avg(price_per_sqft)",
												"med_ppsf": "percentile(price_per_sqft, 50)",
												"avg_price" : "avg(price)"
											}
										}
										
									}
								}
							}							
						},
						"by_count" : {
							"type" : "query",
							"q" : "*:*",
							"facet":{
								"by_time": {
									"range": {
										"field": `${soldTimeField}`,
										"start": this.state.startDate,
										"end": this.state.endDate,
										"gap": gap,
										"facet": {
											"condo": {
												"type" : "query",
												"q" : "ownership_type:(*condo* !condo*p) AND -(sponsored_sale:true AND ownership_type:condo) AND property_type:(sc mc sp mp) AND parcels_condocoop_count:1",
											},
											"coop": {
												"type" : "query",
												"q" : "ownership_type:(*co?op* *coop* condo*p) AND -(sponsored_sale:true AND ownership_type:condo) AND property_type:(sc mc sp mp) AND parcels_condocoop_count:1",
											},
											"newdev": {
												"type" : "query",
												"q" : " (sponsored_sale:true AND ownership_type:condo) AND property_type:(sc mc sp mp) AND parcels_condocoop_count:1",
											},
											"single_family": {
												"type" : "query",
												"q" : "ownership_type:(single?family)",
											},
											"multi_family": {
												"type" : "query",
												"q" : "ownership_type:(multi?family)",
											}						
										}
									}
								}
							}
							
						},
						"condo" : {
							"type" : "query",
							"q" : "price_per_sqft:[300 TO 20000] AND ownership_type:(*condo* !condo*p) AND -(sponsored_sale:true) AND property_type:(sc mc sp mp) AND parcels_condocoop_count:1",
							"facet":{
								"by_time": {
									"range": {
										"field": `${soldTimeField}`,
										"start": this.state.startDate,
										"end": this.state.endDate,
										"gap": gap,
										"facet": {
											"avg_ppsf": "avg(price_per_sqft)",
											"med_ppsf": "percentile(price_per_sqft, 50)",
											"avg_price" : "avg(price)"
											
										}
									}
								}
							}
							
						},
						"coop" : {
							"type" : "query",
							"q" : "price_per_sqft:[300 TO 20000] AND ownership_type:(*co?op* *coop* condo*p) AND -(sponsored_sale:true AND ownership_type:*condo*) AND property_type:(sc mc sp mp) AND parcels_condocoop_count:1",
							"facet":{
								"by_time": {
									"range": {
										"field": `${soldTimeField}`,
										"start": this.state.startDate,
										"end": this.state.endDate,
										"gap": gap,
										"facet": {
											"avg_ppsf": "avg(price_per_sqft)",
											"med_ppsf": "percentile(price_per_sqft, 50)",
											"avg_price" : "avg(price)"
											
										}
									}
								}
							}
							
						},
						"newdev" : {
							"type" : "query",
							"q" : "price_per_sqft:[300 TO 20000] AND (sponsored_sale:true AND ownership_type:*condo*) AND property_type:(sc mc sp mp) AND parcels_condocoop_count:1",
							"facet":{
								"by_time": {
									"range": {
										"field": `${soldTimeField}`,
										"start": this.state.startDate,
										"end": this.state.endDate,
										"gap": gap,
										"facet": {
											"avg_ppsf": "avg(price_per_sqft)",
											"med_ppsf": "percentile(price_per_sqft, 50)",
											"avg_price" : "avg(price)"
											
										}
									}
								}
							}
							
						},
						"single_family" : {
							"type" : "query",
							"q" : "price_per_sqft:[300 TO 20000] AND ownership_type:(single?family)",
							"facet":{
								"by_time": {
									"range": {
										"field": `${soldTimeField}`,
										"start": this.state.startDate,
										"end": this.state.endDate,
										"gap": gap,
										"facet": {
											"avg_ppsf": "avg(price_per_sqft)",
											"med_ppsf": "percentile(price_per_sqft, 50)",
											"avg_price" : "avg(price)"
											
										}
									}
								}
							}
							
						},
						"multi_family" : {
							"type" : "query",
							"q" : "price_per_sqft:[300 TO 20000] AND ownership_type:(multi?family)",
							"facet":{
								"by_time": {
									"range": {
										"field": `${soldTimeField}`,
										"start": this.state.startDate,
										"end": this.state.endDate,
										"gap": gap,
										"facet": {
											"avg_ppsf": "avg(price_per_sqft)",
											"med_ppsf": "percentile(price_per_sqft, 50)",
											"avg_price" : "avg(price)"
											
										}
									}
								}
							}
							
						}
						
					}								
			}
		};
		
		let chartData = [];

	
		let res = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});
			
			
			this._matchedBuildingIds = [];		
	
			if(res && res.facets){
				let facetData = {};
				
				
				if(res.facets.by_building && res.facets.by_building.by_buildings && res.facets.by_building.by_buildings.buckets){
					const formattedBuildings = res.facets.by_building.by_buildings.buckets.map(bucket => {
						this._matchedBuildingIds.push(bucket.val);
						return bucket;
					})
					
					if(this.state.activeTab === 'buildings'){						
						this._getBuildings(this._matchedBuildingIds, formattedBuildings);
					}
				}
				
				if(res.facets.by_count && res.facets.by_count.by_time){
					
					chartData = res.facets.by_count.by_time.buckets.map(bucket => {
						const key = this.getIntervalName(bucket.val);// moment(bucket.val).add(1, 'month').format("MMM - YYYY");
						return { time: key, 
								 count: bucket.count,
								 condo_count: bucket.condo && bucket.condo.count ? bucket.condo.count : 0,
								 coop_count: bucket.coop && bucket.coop.count ? bucket.coop.count : 0,
								 newdev_count: bucket.newdev && bucket.newdev.count ? bucket.newdev.count : 0,
								 single_family_count: bucket.single_family && bucket.single_family.count ? bucket.single_family.count : 0,
								 multi_family_count: bucket.multi_family && bucket.multi_family.count ? bucket.multi_family.count : 0,
						}
					})
					
				}	
				
				
				
				if(res.facets.condo && res.facets.condo.by_time){
							
					res.facets.condo.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);//moment(bucket.val).add(1, 'month').format("MMM - YYYY");					
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0] && bucket.count > 0){
							existingItem[0].condo = ppsfType == "median" ? Math.round(bucket.med_ppsf) : Math.round(bucket.avg_ppsf);
						}
					})
					
				}
				
				if(res.facets.coop && res.facets.coop.by_time){
							
					res.facets.coop.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);//moment(bucket.val).add(1, 'month').format("MMM - YYYY");					
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0] && bucket.count > 0){
							existingItem[0].coop = ppsfType == "median" ? Math.round(bucket.med_ppsf) : Math.round(bucket.avg_ppsf);
						}
					})
					
				}
				if(res.facets.newdev && res.facets.newdev.by_time){
							
					res.facets.newdev.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);//moment(bucket.val).add(1, 'month').format("MMM - YYYY");					
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0] && bucket.count > 0){
							existingItem[0].newdev = ppsfType == "median" ? Math.round(bucket.med_ppsf) : Math.round(bucket.avg_ppsf);
						}
					})
					
				}
				if(res.facets.single_family && res.facets.single_family.by_time){
							
					res.facets.single_family.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);//moment(bucket.val).add(1, 'month').format("MMM - YYYY");					
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0] && bucket.count > 0){
							existingItem[0].single_family = ppsfType == "median" ? Math.round(bucket.med_ppsf) : Math.round(bucket.avg_ppsf);
						}
					})
					
				}
				if(res.facets.multi_family && res.facets.multi_family.by_time){
							
					res.facets.multi_family.by_time.buckets.forEach(bucket => {
						const key = this.getIntervalName(bucket.val);//moment(bucket.val).add(1, 'month').format("MMM - YYYY");					
						let existingItem = chartData.filter(item => item.time === key);
						if(existingItem && existingItem[0] && bucket.count > 0){
							existingItem[0].multi_family = ppsfType == "median" ? Math.round(bucket.med_ppsf) : Math.round(bucket.avg_ppsf);
						}
					})
					
				}
				
/*
				if(chartData[chartData.length-1].count <=0){
					chartData.pop();
				}
*/
			
			}
			
			if(resetPage){
				this.buildChart(chartData);
			}
			
			
			
			this.setState(prevState => ({
				fetchInProgress: false,
				totalCount: res.facets && res.facets.by_building && res.facets.by_building.count ? res.facets.by_building.count : 0,
				groupedCount: res.facets && res.facets.by_building && res.facets.by_building.num_buildings ? res.facets.by_building.num_buildings : 0, //response.building.ngroups,	
				buildingEventCount:  res.facets && res.facets.by_building && res.facets.by_building.count ? res.facets.by_building.count : 0,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page,
				chartData: chartData,
				showBottomLoader: false,
				stats : res.facets && res.facets
			}));			
	
	};
	
	_getBuildings = async (_matchedBuildingIds, formattedBuildings) => {
		
		const { startDate, endDate, gap, dataStart, dataEnd, selectedDate, eventTypes } = this.state;
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			"key:("+_matchedBuildingIds.join(" ")+")",
			"deleted:false"			
		];

		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				fl: buildingFL,
				wt: "json",
				rows: _matchedBuildingIds.length
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				return res.docs;
			})
			.catch(error => {
				console.log("error: " + error);
			});
			
		
		const groupedData = formattedBuildings.map(bucket => {
			bucket['building'] = formattedData.filter(building => building.key == bucket.val)[0];
			return bucket;
		})	
		
		this.setState(prevState => ({
			buildingsFetchInProgress: false,
			groupedData:  groupedData,
			showBottomLoader: false
		}));
		

		// console.log(formattedData)
		return formattedData;
	};
	
	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	_setSubFilter = (search_key, search_val) => {
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setSubFilters = (subFilters) => {
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = this.defaultStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		
		if(newStages.length === 0 ) newStages = ['all'];
		
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	
	_setUnitStage = (stage) => {
	
		let newStages = this.state.unitStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultUnitStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = defaultUnitStage;
		this.setState({
			unitStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages : this.defaultStage,
			unitStages : defaultUnitStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, unitStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}
	_resetActive = () => {

		let	isDefault = this.props.searchTags.length===0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultUnitStage, this.state.unitStages) &&
			_objectEqual(this.defaultStage, this.state.buildingStages);

	    return !isDefault;
		
	}	
	
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		
		if(this.state.interval === 'quarterly'){
			this.setState( { interval : 'monthly', selectedDate: false } )
			setLocalStorage(lsPre, 'interval', 'monthly');
		}else{
			this.setState( { interval : 'quarterly', selectedDate: false } )
			setLocalStorage(lsPre, 'interval', 'quarterly');

		}
		
	}
	
	 toggle = (tab) => {
	    const { activeTab } = this.state;
	    if (activeTab !== tab) {
		  let pageURL = "/analysis/discount" + (tab ? `/tab/${tab}` : '');
		  
		  if(window.location.search){
			  pageURL += window.location.search;
		  }
		  //this.props.history.push(pageURL);		
	      this.setState({
	        activeTab: tab,
	      });
	      _scrollID('tabs', -100);
	    }
	 };
	 
	 setFilter = (key, value) => {
		 const subFilters = {}
		 subFilters[key] = value;

		 
		 this.setState({ subFilters : subFilters });
	 }
	 
	 _toggleTime = () => {
		
		if(this.state.soldTimeField === 'close_date_formatted'){
			this.setState( { soldTimeField : 'contract_date_formatted', selectedDate: false, startDate: moment(dateStartContract, "MM/DD/YYYY").toDate().toISOString(), dataStart: moment(dateStartContract, "DD/MM/YYYY").toDate().toISOString() } )
			setLocalStorage(lsPre, 'soldTimeField', 'contract_date_formatted');
		}else{
			this.setState( { soldTimeField : 'close_date_formatted', selectedDate: false, startDate: moment(dateStart, "MM/DD/YYYY").toDate().toISOString(), dataStart: moment(dateStart, "DD/MM/YYYY").toDate().toISOString() } )
			setLocalStorage(lsPre, 'soldTimeField', 'close_date_formatted');
		}
		
	}
	_togglePPSF = () => {
		
		if(this.state.ppsfType === 'median'){
			this.setState( { ppsfType : 'average' } )
			setLocalStorage(lsPre, 'ppsfType', 'average');
		}else{
			this.setState( { ppsfType : 'median' } )
			setLocalStorage(lsPre, 'ppsfType', 'median');
		}
		
	}
	


  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, groupedData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, searchText, buildingEventCount, dataStart, dataEnd, selectedDate, eventTypes, showBottomLoader, stats, activeTab, unitStages, soldTimeField
    } = this.state;
    
    const { searchTags, user } = this.props;
    
    let companyShow = { name:"Sales Agents", type:"marketing_company"};
    
    const attributeTag = searchTags.filter(item => ['building', 'neighborhood', 'borough'].indexOf(item.type) < 0);
    
    if(attributeTag && attributeTag.length>0){
	    if(attributeTag[0].type === 'developers'){
		    companyShow = { name:"Developer", type:"developers"};
	    }else if(attributeTag[0].type === 'architect'){
		    companyShow = { name:"Architect", type:"architect"};
	    }
    }
    
    
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = false; //this.state.groupedView ? groupedCount : totalCount;
    
    const buildingStart = selectedDate ? selectedDate : dataStart;
	const buildingEnd = selectedDate ? moment(selectedDate).add(7, 'days').toDate().toISOString() : dataEnd;
	
	let buildingDates = `close_date_formatted:[${this.state.dataStart} TO ${this.state.dataEnd}]`;
		
	if(this.state.selectedDate){
		
		let endDate = moment(this.state.selectedDate).add(3, 'months').toDate().toISOString();
		if(this.state.interval === 'monthly'){
			endDate = moment(this.state.selectedDate).add(1, 'months').toDate().toISOString();
			
		}			
		buildingDates = `${soldTimeField}:[${this.state.selectedDate} TO ${endDate}]`;
		
	}

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >

            <UnitsSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
				searchText={searchText}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={(user && user.role.indexOf('admin')>=0) ?  this._unitSubFilter : this._unitSubFilter.filter(item => !item.admin_only)} 
            	placeHolder={(user && (user.role.indexOf('admin')>=0 ||  user.role.indexOf('enterprise')>=0)) ? false : "Borough, Neighborhood or Building"}
            	buildingAttributes={(user && (user.role.indexOf('admin')>=0 ||  user.role.indexOf('enterprise')>=0)) ? false : []}      
            	{...this.props}  
				autocompleteFQ={this.building_q}
            	groupedView   
            	buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	setSubFilter={this._setSubFilter}	
            	numToShow={5}
				UnitStageFilter={<UnitStageFilter
							unitStages={unitStages}
							setUnitStage={this._setUnitStage}
							customToolTip={{
								  'sponsor' : 'Units owned by the Sponsor at the time of the sale.',
								  'resale' : ' Units that have been sold by the Sponsor at the time of the sale.',
								  'unverified' : 'Units that have not been verified at the time of the sale.',
								  'all' : 'All sponsor, resale and unverified units.',	  
							}}
						/> }
				setSubFilters={this._setSubFilters}
				resetActive={this._resetActive()}
             />
             
             {/*<div className="material-table__header-info">
            	<Row>
            		
            		<Col md={12} lg={12}>
             
		             <div className={`unit_toggle_filter`}>
						<UnitStageFilter
							unitStages={unitStages}
							setUnitStage={this._setUnitStage}
							customToolTip={{
								  'sponsor' : 'Units owned by the Sponsor at the time of the sale.',
								  'resale' : ' Units that have been sold by the Sponsor at the time of the sale.',
								  'unverified' : 'Units that have not been verified at the time of the sale.',
								  'all' : 'All sponsor, resale and unverified units.',	  
							}}
						/>  
					  </div>
					</Col>
				</Row>
			</div>	*/}
             <div style={{height:10,padding:"0 10",margin:10}} >
              {fetchInProgress && this.state.groupedView && <LinearLoading /> }
             </div>
            
            <div className="material-table__header-info">
            	<Row>
            		<Col md={12} lg={12}>
		            	<h5 className="bold-text mb-3">
		            	<div className={"mobile__show"}>
		            		
		            		{this.state.interval === 'quarterly' ? "Quarterly" : "Monthly"} Price Trends - {` `}
							{ selectedDate ? 
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							 {` `}							 
		            	</div>
		            	<span className={"desktop__show"}>
		            		{this.state.interval === 'quarterly' ? "Quarterly" : "Monthly"} Price Trends - {` `}
		            		{ selectedDate ? 
							 <Fragment>
							 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
							 </Fragment>
							:
							 <Fragment> 
							 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
							 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
							</Fragment>
							}
							 {` `}
						</span>	 
		            	</h5>
		            	
					</Col>
					
					
            	</Row>
            </div>
            
            <div className="material-table__header-info">
            	<Row>	
					<Col md={12} lg={12} style={{ textAlign:"left" }}>
		            	<span>						  
						  {/*<QuarterlyIcon color="#DBE0E8" />*/}
						  <span>Quarterly</span>
				          <Tooltip title={`${this.state.interval === 'quarterly' ? "Change Chart to Monthly" : "Change Chart to Quarterly"}`}>
					        <PurpleSwitch 
					            checked={this.state.interval !== 'quarterly'}
					        	onChange={this._toggleView}
					        />
					      </Tooltip>
					      <span>Monthly</span>					      	
					      {/*<MonthlyIcon color="#DBE0E8" />*/}			       
					    </span>
					    <span style={{margin:"0 10px", color:"#D1D6E0", textAlign: "center"}}> | </span>
					    <span>						  
						 <span>Closed Date</span>
				          <Tooltip title={`${this.state.soldTimeField === 'event_time' ? "Change Sold Activity to Recorded Date" : "Change Sold Activity to Closed Date"}`}>
					        <PurpleSwitch 
					            checked={this.state.soldTimeField !== 'close_date_formatted'}
					        	onChange={this._toggleTime}
					        />
					      </Tooltip>
					      <span>Contract Date</span>					      	
					    </span>
						<span style={{margin:"0 10px", color:"#D1D6E0", textAlign: "center"}}> | </span>
						<span>						  
						 <span>Med PPSF</span>
						  <Tooltip title={`${this.state.ppsfType === 'median' ? "Change to Average PPSF" : "Change to Median PPSF"}`}>
							<PurpleSwitch 
								checked={this.state.ppsfType !== 'median'}
								onChange={this._togglePPSF}
							/>
						  </Tooltip>
						  <span>Avg PPSF</span>					      	
						</span>
			    	</Col>
            	</Row>
            </div>
           
          
			<div id="chartdiv" style={{ width: "100%", height: "600px", marginTop:"0px", opacity: fetchInProgress ? .5 : 1 }}></div>
			<div style={{height:20}} />
			
			<div  id="tabs" className="tabs tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'overview' })}
                      onClick={() => {
                        this.toggle('overview');
                      }}
                    >
                      <span className="tab-title">Overview</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'buildings' })}
                      onClick={() => {
                        this.toggle('buildings');
                      }}
                    >
                      <span className="tab-title">Buildings</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            
             <TabContent activeTab={activeTab}>
             {activeTab === 'overview' &&
              <TabPane tabId="overview">
					<div className="material-table__header-info">
		            	<Row>
		            		<Col md={12} lg={12}>
								<h5 className="bold-text mb-3">Price Analysis - {` `}
								{ selectedDate ? 
									 <Fragment>  
									 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
									 </Fragment>
									:
									 <Fragment> 
									 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
									 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
									</Fragment>
								}
								
								</h5>
							</Col>
						</Row>
					</div>
					{(fetchInProgress || buildingsFetchInProgress || showBottomLoader) && <LinearLoading />}
					 <Container className="dashboard" style={{opacity: fetchInProgress || buildingsFetchInProgress || showBottomLoader ? .3 : 1}}>
						{stats &&
							<Row>
								<BoroughAnalysis stats={stats} itemKey={'by_boroughs'} col={6} name={'Borough'} {...this.props}  toggle={this.toggle} setFilter={this.setFilter} />
								<BoroughAnalysis stats={stats} itemKey={'by_bedrooms'} col={6}  name={'Bedroom'} {...this.props} toggle={this.toggle} setFilter={this.setFilter} />
				   				
				   				<BoroughAnalysis stats={stats} itemKey={'by_neighborhoods'} col={12}  name={'Neighborhood'} {...this.props}  toggle={this.toggle} setFilter={this.setFilter} />				   				
				   			</Row>
				   		}
				    </Container>
				</TabPane>
             } 
             
             {activeTab === 'buildings' &&
				 <TabPane tabId="buildings">
				            <div className="material-table__wrap">
				              {(fetchInProgress || buildingsFetchInProgress || showBottomLoader) && <LinearLoading />}
				              
				              {groupedData &&       
					           <div className="material-table expansion__table">	
					           		<div className="material-table__header-info pb-3 desktop__show">
										<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
										{eventTypes.length===1 && eventTypes[0]} Sales 
										
										{groupedCount ? <Fragment> Across <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings </Fragment> : ''}
										| Date Range: { selectedDate ? 
											 <Fragment> 
											 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
											 </Fragment>
											:
											 <Fragment> 
											 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
											 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
											</Fragment>
										}	
									</div>
									<div className="material-table__header-info pb-3 mobile__show">
										<span className="bold-text">{buildingEventCount > 0 ? _formatNumber(buildingEventCount) : '--'}</span>{` `} 
										{eventTypes.length===1 && eventTypes[0]} Events 
										
										{groupedCount ? <Fragment> Across <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings </Fragment> : ''}
										<br/> Date Range: { selectedDate ? 
											 <Fragment>  
											 	<span className="bold-text">{this.getIntervalName(selectedDate)}</span>
											 </Fragment>
											:
											 <Fragment> 
											 	<span className="bold-text">{this.getIntervalName(dataStart)}</span> to{` `}  
											 	<span className="bold-text">{this.getIntervalName(dataEnd)}</span>
											</Fragment>
										}	
									</div>
									
										           		
					           	   <Container>
								        <Row className="expansion__summary__header">
											<Col lg={1} xs={12}>	                          
											  	<TableSortLabel
									                active={orderBy === "count"}
									                direction={order}
									                onClick={event => this.handleRequestSort(event, "count")}
									                className="material-table__sort-label"
									                dir="ltr"
									              >
									                Sales
									              </TableSortLabel>
											</Col>
											 <Col lg={5} className="">	 
					                        	<TableSortLabel
									                active={orderBy === "index"}
									                direction={order}
									                onClick={event => this.handleRequestSort(event, "index")}
									                className="material-table__sort-label"
									                dir="ltr"
									              >
									                Building
									              </TableSortLabel>                         
											 	
											</Col>	
										{/*	<Col lg={1} xs={12} className="no-padding">	                          
											  	<TableSortLabel
									                active={orderBy === companyShow.type}
									                direction={order}
									                onClick={event => this.handleRequestSort(event, 'avg_ppsf')}
									                className="material-table__sort-label"
									                dir="ltr"
									              >
									                Avg PPSF
									              </TableSortLabel>
											</Col> */}
											<Col lg={2} xs={12} className="">	                          
											  	<TableSortLabel
									                active={orderBy === companyShow.type}
									                direction={order}
									                onClick={event => this.handleRequestSort(event, 'avg_ppsf')}
									                className="material-table__sort-label"
									                dir="ltr"
									              >
									                Avg PPSF
									              </TableSortLabel>
											</Col>
											<Col lg={2} xs={12} className="">	                          
											  	<TableSortLabel
									                active={orderBy === "avg_price"}
									                direction={order}
									                onClick={event => this.handleRequestSort(event, "avg_price")}
									                className="material-table__sort-label"
									                dir="ltr"
									              >
									                Avg Unit Price
									              </TableSortLabel>
											</Col>
											<Col lg={2} xs={12} className="no-padding">	                          											  	
									                Unsold Units			
											</Col>	
											
											                     
					                       
											
					                    </Row>
					                </Container>
					              {groupedData.length ===0 &&		              
						            <Container>
								        <Row className="no__data">
											<Col lg={12} xs={12} className="no__data-text">			                          
											  	No data found for your search.
											</Col>	
										</Row>
									</Container>
					              }  	           
					              {groupedData.filter(item => item.building).map((d) => {
						                const item = d
						                const numFound = item.count;
						                const isSelected = this.isSelected(item.val);
						                const building = item.building;
							            return(  			            
							              <ExpansionPanel TransitionProps={{ unmountOnExit: true }} id={item.val}>
									        <ExpansionPanelSummary
									          expandIcon={<ExpandMoreIcon />}
									          aria-label="Expand"
									          aria-controls=""
									          id={d.groupValue}
									        >
									        <Container>
										        <Row className="expansion__summary">
													<Col lg={1} xs={12} >			                          
													  	<Badge color="matching" style={{ width:50 }}>
													  		<span className="bold-text">{item.count}</span>
													  	</Badge>
													</Col>	
													<Col lg={5} xs={12} >			                          
													  <div className="expansion__header">									  									  	
													  		<span className="building_name" onClick={event => this.handleGroupedBuildingClick(event, building)}>{building && building.name ? _capitalizeText(building.name) : _capitalizeText(building.display_full_street_address)}
													  		
													  		 <BuildingIcons building={building} />
													  		
													  		</span>
								                            <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{building.neighborhood && building.neighborhood[0] ? `${_capitalizeText(building.neighborhood[0])}, ` : ''} {_capitalizeText(building.borough)}
								                            
								                            
								                            </span>
													  
													  </div>				          									  
													</Col>
													{/*<Col lg={1} xs={12}  className="no-padding">			                          
													  <div className="expansion__header">									  									  	
													  	<span className="small-text">
													  		
													  		${Math.round(item.avg_ppsf)}</span>
													  </div>								  			          									  
													</Col>*/}
													<Col lg={2} xs={12}  className="">			                          
													  <div className="expansion__header">									  									  	
													  	
													  		<span className="small-text bold-text">{item.ppsf_stats.avg_ppsf > 0 ? '$' + _formatNumber(item.ppsf_stats.avg_ppsf) : '--'}</span>
													  </div>								  			          									  
													</Col>
													<Col lg={2} xs={12} >
																                          
													  	<span className="small-text bold-text">{item.ppsf_stats.avg_price > 0 ? '$' + _formatNumber(item.ppsf_stats.avg_price) : '--'}</span>
													</Col>	
													<Col lg={2} xs={12}  className="no-padding">			                          
													  	{building.offering_construction==='conversion' 
							                          	 ?
							                          	 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total}  color={"grey"} />
														 : _isHouse(building) ? <span className="light-text small-text">Not Applicable</span>
							                          	 :
										                 <SalesProgress progress={parseFloat(building.inventory_pct_unsold).toFixed(0)} inventory_unsold={building.inventory_unsold} inventory_total={building.inventory_total}  />
									                    } 
													</Col>	
													
												             
							                       		                        			                        	
						                        </Row>
						                    </Container>
									        </ExpansionPanelSummary>
									        <ExpansionPanelDetails>				        
									        	<UnitsListTable 
								              		isSelected={this.isSelected}
								              		handleBuildingClick={this.handleBuildingClick}
								              		handleClick={this.handleClick}
								              		handleRequestSort={this.handleRequestSort}
								              		handleSelectAllClick={this.handleSelectAllClick}
								              		{...this.state} 
								              		{...this.props} 
								              		reactTableFiltered={false}	
								              		isGrouped
								              		q={this.q}
								              		fq={this.property_q ? [...[this.property_q], ...this.fq, ...[buildingDates]] : [...this.fq, ...[buildingDates]]}
								              		fl={this.fl}	
								              		building={building}		              		
								              />
									        </ExpansionPanelDetails>
									     </ExpansionPanel>
									    )
									 })
					             }  
					            </div>
					          }
				            
				            
				              {!this.state.groupedView && 	              
					              <UnitsListTable 
					              		isSelected={this.isSelected}
					              		handleBuildingClick={this.handleBuildingClick}
					              		handleClick={this.handleClick}
					              		handleRequestSort={this.handleRequestSort}
					              		handleSelectAllClick={this.handleSelectAllClick}
					              		{...this.state} 
					              		{...this.props} 
					              		
					              />      
				              }
				            </div>
				   </TabPane>
             }
             
             </TabContent> 
         
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(UnitsSearchTable));