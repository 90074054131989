import React, { PureComponent } from 'react';
import { Progress, Table, Row } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Line, ComposedChart } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import Panel from '../../../../../shared/components/Panel';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_formatNumberDecimal
} from '../../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const apiURL = process.env.API_ROOT + '/query/';


let bedTypes = [];

class EnergyUseTrends extends PureComponent {
  static propTypes = {
	t: PropTypes.func.isRequired,
	themeName: PropTypes.string.isRequired,
  };
 
  
  constructor() {
	super();
	this.state = {
	  data: false,
	};
  }
   

  
  componentDidMount() {	
	const { dataRes, statData, buildingRes, buildingNameOrAdr } = this.props;
	
	if(dataRes){
		const { docs } = dataRes;		
		const data = [];
		for(let i = 0; i < docs.length; i++) {
			const doc = Object.assign({}, docs[i]);
			
			doc['year'] = doc.year_ending;
			doc['Energy Used'] = doc.site_eui_kbtu_ft ? doc.site_eui_kbtu_ft : null;
			doc[buildingNameOrAdr] = doc.site_eui_kbtu_ft ? doc.site_eui_kbtu_ft : null;
			doc['Citywide Median'] = statData.nyc[doc.year_ending] && statData.nyc[doc.year_ending].med_site_eui_kbtu_ft ? statData.nyc[doc.year_ending].med_site_eui_kbtu_ft : null;
			doc[buildingRes.borough + ' Median'] = statData.borough[doc.year_ending] && statData.borough[doc.year_ending].med_site_eui_kbtu_ft ? statData.borough[doc.year_ending].med_site_eui_kbtu_ft : null;
			data.push(doc);
		}
		
		this.setState({ data: data });
	}
  }
  
  render() {
	  const { t, dir, themeName, lmi, translateScore, buildingNameOrAdr, buildingRes} = this.props;    
	  const { data } = this.state;
	  if(!data){
		  return null;
	  }
	  return (
		  <Panel 
			  md={12}
			  lg={12}
			  xl={12} 
			  title={'Energy Use'}
			  subhead={'Energy use in kBTU per square foot compared to city and borough wide multi-family buildings'}
		  >
			<div dir="ltr">
			  <ResponsiveContainer height={350} className="dashboard__area">
				<ComposedChart data={data} margin={{ top: 0, left: -10, bottom: 20, right:20 }}>
				   
				   <XAxis dataKey="year" tickLine={false} reversed={dir === 'rtl'} dx={5} angle={45} tickSize={20} height={60} tickMargin={10} />
				   <YAxis  yAxisId="left" tickLine={false} tickLine={false}  orientation={'left'} tickFormatter={value =>  value} />
		
				   <Tooltip formatter={value => _formatNumberDecimal(value, 1) + ` kBTU / SF`} />
				   <Legend />
				   <CartesianGrid vertical={false}/>
				
					   
				    <Line yAxisId="left" dot={{ strokeWidth:2 }} type="monotone" dataKey={buildingNameOrAdr} fill="#BFC4D1" stroke={getDefaultChartColors[0]} strokeWidth={2} fillOpacity={0} />
				    <Line yAxisId="left" dot={{ strokeWidth:0 }} type="monotone" dataKey={"Citywide Median"} fill="#FF691E" stroke={"#28C2FF"} strokeWidth={1} fillOpacity={0} />
					<Line yAxisId="left" dot={{ strokeWidth:0 }} type="monotone" dataKey={buildingRes.borough + ' Median'} fill="#FF206E" stroke={"#FF691E"} strokeWidth={1} fillOpacity={0} />

				  
				  </ComposedChart>
			  </ResponsiveContainer>
			</div>
		  </Panel>
		);
	}
}

export default connect(state => ({
  rtl: state.rtl,
  dir: PropTypes.string.isRequired,
  theme: state.theme,
}))(withTranslation('common')(EnergyUseTrends));
