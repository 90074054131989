/* eslint-disable no-use-before-define */
import axios from "axios";

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddIcon from 'mdi-react/PlusCircleOutlineIcon';

const useStyles = makeStyles((theme) => ({
  root: {
	width: '100%',
	'& > * + *': {
	  marginTop: theme.spacing(1),
	},
  },
  fieldset : {
	'& .Mui-focused .MuiOutlinedInput-notchedOutline' : {
		borderColor:"#780F9E"
	},
	'& legend':{
		fontSize:16
	}
	
  },
  '.MuiIconButton-root' : {
	  padding:'12px'
  },
  
}));
const apiUrl = process.env.API_ROOT;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}
const filterOptions = (options, { inputValue }) => { return options }

const defaultOptions = []

export default function FormAutocompleteSelect({ tags, input, placeholder, label, helperText }) {
  const classes = useStyles();
  const [options, setOptions] = React.useState( defaultOptions);
  const [value, setValue] = React.useState(input.value ? input.value : []);
  const [inputValue, setInputValue] = React.useState('');
  React.useEffect(() => {
	
	  return () => {
		
	  };
  }, [value, inputValue, options]);
  return (
	<div className={classes.root}>
	
	  <Autocomplete
		multiple
		limitTags={999}
		id="multiple-limit-tags"
		options={options}
		getOptionSelected={(option, value) => (value.group_tags ? value.group_tags : value) === (option.group_tags ? option.group_tags : option)}
		freeSolo
		onChange={(event, newValue) => {
		  console.log("onChange",newValue)
		  input.onChange(newValue);
		 // handleTagChange(newValue.map(option => option.group_tags ? option.group_tags : option ).filter(onlyUnique));
		}}
		
		onInputChange={(event, newInputValue) => {
		  console.log("onInputChange",newInputValue)
		  input.onChange(newInputValue);
		}}
		
		getOptionLabel={(option) => option.group_tags ? option.group_tags : option}
		defaultValue={value}
		renderInput={(params) => (
		  <TextField {...params} 
		  	className={classes.fieldset}
			  onClick={(event) => {
			  event.stopPropagation();
			  }} 
			  variant="outlined" 
			label="" 
			placeholder={placeholder} />
		)}
		renderOption={(option) => {

			   return (
				  <div className="autocomplete-search">{option}</div>
				); 
	
			  
			
		  }}
	  />
	</div>
  );
}
