/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NewOrderTableProps } from '../../../../../shared/prop-types/TablesProps';
import moment from "moment"; 
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import ArrowEqualIcon from 'mdi-react/MinusCircleOutlineIcon';

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_formatBed,
	_formatBath,
} from '../../../../../shared/helpers/utils';

import Panel from '../../../../../shared/components/Panel';

const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/dashboard_e_commerce/edit/${index}`}><DropdownItem>Messsage</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Contact</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);


DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const Trend = ({ last, current }) => {
	
	if(last.price > current.price){		
		return (
			<ArrowDownIcon size={18} color="#f03434" />
		);		
	}else if(last.price < current.price){
		return (
			<ArrowUpIcon size={18} color="#4ce1b6" />
		);
	}
	
	return (
		<ArrowEqualIcon size={18} color="#999" />
	);
    
};


const UnitHistory = ({ t, onDeleteRow, unitHistory }) => (

<div>
 
    <Table responsive striped className="dashboard__table-orders">
      <thead>
        <tr>
          <th />
          <th>Offering Plan Document</th>
          <th>Bed</th>
          <th>Bath</th>
          <th>Size</th>
          <th>Exterior</th>         
          <th>Price</th>
          <th>Common Charges</th>
          <th>Est Taxes</th>
          <th>Common Interest</th>

        </tr>
      </thead>
      <tbody>
        {unitHistory.map((event, index) => (
          <tr key={index}>
            <td style={{paddingRight:0}}>
            	<Trend last={unitHistory[index > 0 ? (index-1) : 0]} current={event} /> 
            </td>
            <td className="">
              {event.document_number === 0 ? 'Initial Offering Plan' : `Amendment ${event.document_number}`}
            </td>
            <td className="dashboard__table-orders-total" dir="ltr">{_formatBed(event.bedrooms)}</td>
            <td className="dashboard__table-orders-total" dir="ltr">{_formatBed(event.baths)}</td>
            <td className="dashboard__table-orders-total" dir="ltr">{_formatNumber(event.sq_ft)} sq ft</td>
            <td className="dashboard__table-orders-total" dir="ltr">{event.exterior_sq_ft ? `${_formatNumber(event.exterior_sq_ft)} sq ft` : '--'}</td>            
            <td>
             ${_formatNumber(event.price)} 
            </td>
            <td> ${_formatNumber(event.monthly_fees)}</td>
            <td> ${_formatNumber(event.monthly_taxes)}</td>
            <td className="dashboard__table-orders-total" dir="ltr">{parseFloat(event.residential_shares).toFixed(2) }%</td>
            
          </tr>
        ))}
      </tbody>
    </Table>
{/*  <BuyersList /> */}
  </div>
);

UnitHistory.propTypes = {
  newOrder: NewOrderTableProps.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(UnitHistory);
