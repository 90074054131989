import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardBody,
  Col,
  Progress,
  Badge,
  Row,
  Container,
  Modal,
  ModalFooter,
  ButtonToolbar,
  ModalHeader,
} from "reactstrap";
import TablePagination from "@material-ui/core/TablePagination";

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import searchActions from "../../../redux/actions/searchActions";
import UnitsListTable from "./UnitsListTable";
import LinearLoading from "../../../shared/components/LinearLoading";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { isMobile } from "react-device-detect";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import OpenNewIcon from "mdi-react/OpenInNewIcon";
import Button from "@material-ui/core/Button";
import BuildingIcons from "../../../shared/components/BuildingIcons";
import ArrowUpIcon from 'mdi-react/ChevronUpCircleOutlineIcon';
import ArrowDownIcon from 'mdi-react/ChevronDownCircleOutlineIcon';
import { _buildingIDs, _axiosCall } from "../../../shared/helpers/apicalls";
import {
  _capitalizeText,
  _formatNumber,
  _formatPrice,
  _formatCurrency,
  _nameToUrl,
  _shouldShow,
  _getBuildingURL,
  buildingFL,
  _replaceAbbreviations,
  _formatBed,
  propertyFL,
  SalesProgress,
  _isEqual,
  _tranformTagsQuery,
  syncURLFilters,
  syncURLSearch,
  setURLFromState,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  syncStageFilters,
  mpndCohortQuery,
  syncGenericFilter,
  urlSlugBuilding,
  _isNewUser,
  _isEnterpriseUser,
  _objectEqual,
  getCohortQuery,
  _activityFQ,
  _isHouse,
  _formatBigPrice,
  houseBuildingCohortQuery,
} from "../../../shared/helpers/utils";

import {
  _marketActivityFilters,
  _buildingStageFilter,
  _unitStageFilter,
} from "../../../shared/helpers/filters";

import { _hasPermission } from "../../../shared/helpers/access";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#A3ADC2",

    "&$checked": {
      color: "#A3ADC2",
    },
    "&$checked + $track": {
      backgroundColor: "#D1D6E0",
    },
  },
  checked: {},
  track: { backgroundColor: "#D1D6E0" },
})(Switch);

let counter = 0;

const buildingUrl = process.env.API_ROOT + "/query/";

const lsPre = "MAV3_2_";
const subFilterKey = "subFilters";
const unitStagesKey = "unitStages";
const defaultSubFilter = { offering_verified_status: ["not-abandoned"] };
const buildingStagesKey = "buildingStages";
const defaultStage = ["all"];
const defaultUnitStage = ["all"];
const gap = syncGenericFilter("gap", lsPre, "gap", "+7DAY");
const chartType = syncGenericFilter(
  "chartType",
  lsPre,
  "chartType",
  "contracts"
);
const defaultGap = gap === "+1MONTH" ? "month" : "week";

const dateStart = "01/07/2019";
const isDST = true; //moment().isDST();
// Get the start of the last completed week
let startOfLastWeek = moment().startOf("isoWeek").subtract(1, "week");

// Get the end of the last completed week
let endOfLastWeek = startOfLastWeek.clone().endOf("week");
function getEndOfLastCompletedQuarter() {
  const now = moment();
  const currentMonth = now.month(); // 0-11

  // Determine the start month of the current quarter
  const startMonthOfCurrentQuarter = currentMonth - (currentMonth % 3);

  // Determine the end of the last quarter
  const lastQuarterEnd = moment()
    .month(startMonthOfCurrentQuarter - 1)
    .endOf("month");

  return lastQuarterEnd;
}

const endOfLastCompletedQuarter = getEndOfLastCompletedQuarter();
function getDateOneYearAgoFromFirstOfYear() {
  // Get the first day of the current year
  const firstOfYear = moment().startOf("year");
  // Subtract one year from the first day of the current year
  const oneYearAgo = firstOfYear.subtract(1, "year");
  return oneYearAgo;
}
let ondOfLastYear = getDateOneYearAgoFromFirstOfYear();
function getEndOfLastCompletedMonth() {
  const lastCompletedMonthEnd = moment().subtract(1, "month").endOf("month");
  return lastCompletedMonthEnd;
}

const endOfLastCompletedMonth = getEndOfLastCompletedMonth();
class CardModal extends PureComponent {
  defaultStage = _hasPermission(this.props.user, "sponsor-buildings")
    ? ["all"]
    : ["resale"];
  defaultUnitStage = _hasPermission(this.props.user, "sponsor-buildings")
    ? ["all"]
    : ["all"];
  state = {
    order: syncGenericFilter("order", lsPre, "order", "desc"),
    orderBy: syncGenericFilter("order_by", lsPre, "orderBy", "count"),
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
    showBottomLoader: false,
    clearInput: false,
    end: 30,
    filterBorough: "all_boroughs",
    searchText: syncURLSearch(this.props, "searchText"),
    futureInventoryData: false,
    inventoryData: false,
    originalData: false,
    ovelayMinHeight: false,
    reactTableFiltered: [],
    showCounts: { desktop: 3, tablet: 2, mobile: 1 },
    start: 0,
    stats: {},
    subFilters: syncURLFilters(
      this.props,
      _marketActivityFilters,
      subFilterKey,
      lsPre,
      defaultSubFilter
    ),
    tableData: false,
    totalUnsoldUnits: false,
    totalCount: 0,
    groupedView: this.props.buildingView ? false : true,
    groupedData: false,
    groupedCount: false,
    buildingKey: false,
    unitListData: false,
    noDataShow: false,
    startDate: moment(dateStart, "MM/DD/YYYY")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .startOf(defaultGap)
      .add(defaultGap == "month" ? 0 : 1, "day")
      .add(isDST ? -1 : 0, "hour")
      .toDate()
      .toISOString(),
    endDate: endOfLastWeek, //moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString(),
    gap: gap,
    dataStart: isMobile
      ? syncGenericFilter(
          "start_date",
          lsPre,
          "dataStart",
          moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .subtract(3, "months")
            .startOf(defaultGap)
            .add(defaultGap == "month" ? 0 : 1, "day")
            .toDate()
            .toISOString()
        )
      : syncGenericFilter(
          "start_date",
          lsPre,
          "dataStart",
          moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .subtract(1, "years")
            .startOf(defaultGap)
            .add(defaultGap == "month" ? 0 : 1, "day")
            .toDate()
            .toISOString()
        ),
    dataEnd: syncGenericFilter(
      "end_date",
      lsPre,
      "dataEnd",
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate()
        .toISOString()
    ),
    buildingEventCount: 0,
    selectedDate: syncGenericFilter(
      "selected_date",
      lsPre,
      "selectedDate",
      false
    ),
    soldTimeField: syncGenericFilter(
      "sold_time",
      lsPre,
      "soldTimeField",
      "time"
    ),
    buildingStages: syncStageFilters(
      this.props,
      _buildingStageFilter,
      buildingStagesKey,
      lsPre,
      this.defaultStage
    ),
    unitStages: syncStageFilters(
      this.props,
      _unitStageFilter,
      unitStagesKey,
      lsPre,
      this.defaultUnitStage
    ),
    viewType: "building",
    buildingData: false,
    chartType: this.props.chartType,
    chartView: this.props.chartView,
    isCheckedValue: [],
    cardListViewData: [],
    clickedEvent: "",
  };

  defaultQ = getCohortQuery(this.props.user, true);
  q = "";
  fq = [];
  property_q = "";
  chartLoaded = false;
  fl = buildingFL;
  chart;
  _activityFilter = _marketActivityFilters.filter(
    (filter) => !filter.filter_type && !filter.activity_hide
  );
  _propertyFilter = _marketActivityFilters.filter(
    (filter) =>
      filter.filter_type &&
      filter.filter_type === "property" &&
      !filter.activity_hide
  );
  _buildingFilter = _marketActivityFilters.filter(
    (filter) =>
      filter.filter_type &&
      filter.filter_type === "building" &&
      !filter.activity_hide
  );
  _unitSubFilter = _marketActivityFilters.filter(
    (filter) =>
      !(this.props.buildingView && filter.building_hide) &&
      !filter.activity_hide
  );
  timeout = null;
  _matchedBuildingIds = [];
  _matchedBuildingSlugs = [];
  _building_q = this.defaultQ;
  formattedData;
  noDataIndicator;

  componentDidMount() {
    this._getEvents(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let searchTagsChange = !_isEqual(
      prevProps.searchTags,
      this.props.searchTags
    );
    let buildingStageChange = !_isEqual(
      prevState.buildingStages,
      this.state.buildingStages
    );
    let unitStageChange = !_isEqual(
      prevState.unitStages,
      this.state.unitStages
    );

    let subFilterChanged =
      this._unitSubFilter.filter((item) => {
        return Array.isArray(item.search_key)
          ? item.search_key.filter(
              (key) => this.state.subFilters[key] !== prevState.subFilters[key]
            ).length > 0
          : this.state.subFilters[item.search_key] !==
              prevState.subFilters[item.search_key];
      }).length > 0;
    if (
      this.state.isCheckedValue !== prevState.isCheckedValue ||
      this.state.filterBorough !== prevState.filterBorough ||
      this.state.searchText !== prevState.searchText ||
      this.state.page !== prevState.page ||
      this.state.rowsPerPage !== prevState.rowsPerPage ||
      this.state.gap !== prevState.gap ||
      this.state.soldTimeField !== prevState.soldTimeField ||
      this.state.groupedView !== prevState.groupedView ||
      (this.state.buildingKey !== prevState.buildingKey &&
        this.state.buildingKey !== false) ||
      searchTagsChange ||
      subFilterChanged ||
      unitStageChange ||
      buildingStageChange ||
      this.state.chartType !== prevState.chartType ||
      this.state.chartView !== prevState.chartView
      //this.state.viewType !== prevState.viewType
    ) {
      const resetPage = this.state.page === prevState.page;
      console.log("component update", prevState, this.state);
      this.defaultQ = getCohortQuery(this.props.user, true);
      this._getEvents(resetPage);
    } else if (
      this.state.dataStart !== prevState.dataStart ||
      this.state.dataEnd !== prevState.dataEnd ||
      this.state.selectedDate !== prevState.selectedDate ||
      this.state.orderBy !== prevState.orderBy ||
      this.state.order !== prevState.order ||
      !_isEqual(this.state.eventTypes, prevState.eventTypes)
    ) {
      this._getEvents(false);
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleGroupedBuildingClick = (event, building) => {
    event.stopPropagation();
    console.log(building);
    const buildingURL = _getBuildingURL(building, "building_slugs");
    window.open(buildingURL);
    //this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  _handleBuildingOpen(building) {
    let baseUrl = window.location.href.split("?")[0];
    window.open(`${baseUrl}?building=${urlSlugBuilding(building)}`);
  }

  calculateDateRange = (monthlyData, defaultGap, isDST, isWeekly) => {
    const { endDate, startDate } = this.props;
    // Extract the value from monthlyData
    const value = monthlyData[1].val;
    
    const isCustom = ["+7DAY", "+1MONTH", "+3MONTH", "+1YEAR"].indexOf(isWeekly) < 0;
    if(isCustom){
      return {
        buildingStart: startDate,
        buildingEnd: endDate,
      };
    }
    if (!value) {
      return {
        buildingStart: endDate.clone().startOf("week").toISOString(),
        buildingEnd: endDate.toISOString,
      };
    }
    
    

    let buildingStart = moment(value)
      .add(defaultGap === "month" ? 0 : 0, "day")
      .add(isDST ? -1 : 0, "hour")
      .toDate()
      .toISOString();

    let buildingEnd;

    // if (isWeekly === "+1MONTH") {
    //   buildingEnd = moment(value)
    //     .add(1, "month")
    //     .add(isDST ? -2 : -1, "hour")
    //     .toDate()
    //     .toISOString();
    // } else if (isWeekly === "+3MONTH") {
    //   buildingEnd = moment(value)
    //     .add(3, "months")
    //     .add(isDST ? -2 : -1, "hour")
    //     .toDate()
    //     .toISOString();
    // } else if (isWeekly === "+1YEAR") {
    //   buildingEnd = moment(value)
    //     .add(1, "year")
    //     .add(isDST ? -2 : -1, "hour")
    //     .toDate()
    //     .toISOString();
    // } else {
    //   buildingEnd = moment(value)
    //     .add(1, "week")
    //     .add(0, "day")
    //     .add(isDST ? -2 : -1, "hour")
    //     .toDate()
    //     .toISOString();
    // }
    
    buildingEnd = `${moment(buildingStart).subtract(1, 'millisecond').toISOString()}${isWeekly}`;

    return {
      buildingStart,
      buildingEnd,
    };
  };
  _getDetailQuery = () => {
    const {
      dataStart,
      dataEnd,
      selectedDate,
      eventTypes,
      gap,
      soldTimeField,
      unitStages,
      chartType,
      chartView,
      startDate,
      endDate
    } = this.state;
    const { monthlyData, isWeekly } = this.props;
    const isDST = false;

    const dateRange = this.calculateDateRange.bind(this)(
      monthlyData,
      defaultGap,
      isDST,
      isWeekly
    );

    const buildingStart = dateRange.buildingStart;
    const buildingEnd = dateRange.buildingEnd;


    const detailQuery = `(${this.props.activityType.eventQuery} AND ${this.props.type.activitesQuery} AND time:[${buildingStart} TO ${buildingEnd}])`;

    return detailQuery ? detailQuery : "id:none";
  };
  handleBuildingClick = (event, building) => {
    const buildingURL = _getBuildingURL(building);
    this.props.history.push(buildingURL);
  };
  getIntervalName(theDate) {
    const { isWeekly } = this.props;
    
    if(theDate.indexOf("+")>=0){
      let date = theDate.split("+")[0]
      
      if (isWeekly === "+1MONTH") {
        return moment(date).add(1, "M").add(1, "hour").format("M/D/YY");
      }else if(isWeekly === "+3MONTH"){
        return moment(date).add(3, "M").add(1, "hour").format("M/D/YY");
      }else if(isWeekly == '+1YEAR'){
        return moment(date).add(1, "Y").add(1, "hour").format("M/D/YY");
      }
    }

    return moment(theDate).add(1, "hour").format("M/D/YY");
  }

  _getEvents = async (resetPage) => {
    //this.q = this.defaultQ;
    const {
      startDate,
      endDate,
      gap,
      dataStart,
      dataEnd,
      selectedDate,
      eventTypes,
      soldTimeField,
      buildingStages,
      unitStages,
      isCheckedValue,
    } = this.state;
    if (!this.state.fetchInProgress) {
      this.setState((prevState) => ({
        fetchInProgress: resetPage,
        showBottomLoader: true,
        groupedData: false,
      }));
    }
    const { searchTags } = this.props;


    this.q = this.q.length > 0 ? this.q : "*:*";



    let sort = null;
    if (this.state.orderBy && this.state.orderBy.length > 0) {
      sort = `${this.state.orderBy} ${this.state.order}`;
    }

    this.fq = this.props.fq;

    const buildingQuery = this._getDetailQuery();
    const facetStatQuery = {};

    let axiosData = {
      url: buildingUrl + "we3-activities-3?cache_time=1440&always-cache=true",
      method: "post",
      query: {
        q: "*:*",
        fq: this.fq,
        wt: "json",
        rows: 0,
        "json.facet": {
          by_building: {
            type: "query",
            q: `${buildingQuery}`,
            facet: {
              unique_buildings: "unique(building)",
              buildings: {
                type: "terms",
                field: "building",
                mincount: 1,
                sort: sort,
                offset: resetPage ? 0 : this.state.start,
                limit: this.state.rowsPerPage,
                facet: {
                  "sum_price" : "sum(price)",
                  "avg_price" : "avg(price)",
                  "avg_chg_amt" : "avg(price_change_amount)",
                  "avg_chg_pct" : "avg(price_change_percent)",
                  "ppsf" : {
                      "type" : "query",
                      "q" : "sq_ft:[100 TO 30000]",
                      "facet" : {
                        "avg_ppsf" : "avg(div(price, sq_ft))"
                      }
                  }
                }
              },
            },
          },
        },
      },
    };

    let res = await _axiosCall(axiosData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    let cardListViewData = [];

    let chartData = [];
    this._matchedBuildingIds = [];

    if (res && res.facets) {
      let facetData = {};

      if (
        res.facets.by_building &&
        res.facets.by_building.buildings &&
        res.facets.by_building.buildings.buckets
      ) {
        const formattedBuildings = res.facets.by_building.buildings.buckets.map(
          (bucket) => {
            this._matchedBuildingIds.push(bucket.val);
            return bucket;
          }
        );

        this._getBuildings(this._matchedBuildingIds, formattedBuildings);
      }
      this.setState((prevState) => ({
        fetchInProgress: false,
        buildingsFetchInProgress: false,
        showBottomLoader: false,
        groupedData: [],
        start: resetPage ? 0 : this.state.start,
        page: resetPage ? 0 : this.state.page,
        cardListViewData: res.facets,
        buildingEventCount:
          res.facets.by_building && res.facets.by_building.count
            ? res.facets.by_building.count
            : 0,
        groupedCount:
          res.facets.by_building && res.facets.by_building.unique_buildings
            ? res.facets.by_building.unique_buildings
            : 0,
      }));
    }
  };

  _getBuildings = async (_matchedBuildingIds, formattedBuildings) => {
    const {
      startDate,
      endDate,
      gap,
      dataStart,
      dataEnd,
      selectedDate,
      eventTypes,
    } = this.state;

    if (!this.state.buildingsFetchInProgress) {
      this.setState((prevState) => ({ buildingsFetchInProgress: true }));
    }
    var fq = [
      "alternate_keys:(" + _matchedBuildingIds.join(" ") + ")",
      "deleted:false",
    ];

    let axiosData = {
      url: buildingUrl + "we3-buildings?cache=60",
      method: "post",
      query: {
        q: "*:*",
        fq: fq,
        fl: buildingFL,
        wt: "json",
        rows: _matchedBuildingIds.length,
      },
    };

    let formattedData = await _axiosCall(axiosData)
      .then((res) => {
        return res.docs;
      })
      .catch((error) => {
        console.log("error: " + error);
      });

    const groupedData = formattedBuildings.map((bucket) => {
      bucket["building"] = formattedData.filter(
        (building) =>
          building.key == bucket.val ||
          (building.alternate_keys &&
            building.alternate_keys.indexOf(bucket.val) >= 0)
      )[0];
      return bucket;
    });
    console.log(groupedData, "groupedData");
    this.setState((prevState) => ({
      buildingsFetchInProgress: false,
      groupedData: groupedData,
      buildingData: formattedData,
      showBottomLoader: false,
      noDataShow: true,
    }));
  };

  _toggleView = (e) => {
    this.setState({ viewType: e.target.checked ? "list" : "building" });
  };
  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      checked,
      page,
      tableData,
      reactTableFiltered,
      subFilters,
      totalCount,
      groupedData,
      groupedCount,
      unitListData,
      fetchInProgress,
      buildingsFetchInProgress,
      searchText,
      buildingEventCount,
      dataStart,
      dataEnd,
      selectedDate,
      eventTypes,
      soldTimeField,
      gap,
      showBottomLoader,
      unitStages,
      viewType,
      chartType,
      chartView,
      cardListViewData,
      isCheckedValue,
      noDataShow,
    } = this.state;

    const {
      searchTags,
      user,
      classes,
      isOpen,
      toggleModal,
      isWeekly,
      monthlyData,
      snapshotTitle,
    } = this.props;

    let companyShow = { name: "Sales Agents", type: "marketing_company" };
    const buildingQuery = this._getDetailQuery.bind(this)();
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;
    const isDST = false;
    const dateRange = this.calculateDateRange.bind(this)(
      monthlyData,
      defaultGap,
      isDST,
      isWeekly
    );
    const eventType =  _capitalizeText(
       chartType.replace(/-/g, " ")
     );
    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          size="lg"
          className={`full_width_modal`}
        >
          <ModalHeader toggle={toggleModal} style={{ border: "none" }}>
            <div>
              <h4 className="contact__title">{snapshotTitle}</h4>
              
            </div>
          </ModalHeader>
          <div className="">
            <div
              className="modal__body"
              style={{
                height: "100%",
                minHeight: "calc(90vh - 300px)",
                padding: "10px 15px 15px 15px",
                width: "100%",
                textAlign: "left",
              }}
            >
              {(showBottomLoader || buildingsFetchInProgress) && (
                <LinearLoading />
              )}

              <div className="material-table__header-info pb-3 pl-0 desktop__show">
                {groupedData ? (
                      <div className="count_toggle">
                        <div className="material-table__header-info pb-0">
                          <span className="bold-text">
                            {buildingEventCount > 0
                              ? `${_formatNumber(
                                  buildingEventCount
                                )} ${eventType}`
                              : "--"}
                          </span>
                          {` `}
                          {groupedCount ? (
                            <Fragment>
                              {" "}
                              Across{" "}
                              <span className="bold-text">
                                {_formatNumber(groupedCount)}
                              </span>{" "}
                              Buildings{" "}
                            </Fragment>
                          ) : (
                            ""
                          )}

                          {monthlyData[1].val ? (
                            <Fragment>
                              | Date Range:{" "}
                              {isWeekly === "+7DAY" ? (
                                <>
                                  Week of
                                  {` `}
                                  <span className="bold-text">
                                    {this.getIntervalName(monthlyData[1].val)}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="bold-text">
                                    {this.getIntervalName(
                                      dateRange.buildingStart
                                    )}
                                  </span>{" "}
                                  to{` `}
                                  <span className="bold-text">
                                    {this.getIntervalName(
                                      dateRange.buildingEnd
                                    )}
                                  </span>
                                </>
                              )}
                            </Fragment>
                          ) : null}
                        </div>
                        
                        <div>
                          <span style={{ color: "#5C6178" }}>
                            {!isMobile ? "Building View" : "Bldgs"}
                          </span>
                          <Tooltip
                            title={`${
                              this.state.viewType == "building"
                                ? "Expand to See Unit List"
                                : "Condense to Group by Buildings"
                            }`}
                          >
                            <PurpleSwitch onChange={this._toggleView} checked={this.state.viewType == "list"} />
                          </Tooltip>
                          <span style={{ color: "#5C6178" }}>
                            {!isMobile ? "List View" : "Units"}
                          </span>
                        </div>
                      </div>
                ) : null}
              </div>
              <div className="material-table__wrap">
                {!buildingsFetchInProgress &&
                  !fetchInProgress &&
                  this.state.groupedView &&
                  groupedData && (
                    <div className="material-table expansion__table">
                      {viewType == "building" ? (
                        <Fragment>
                          <Container>
                            <Row className="expansion__summary__header">
                              <Col lg={1} xs={12}>
                                <TableSortLabel
                                  active={orderBy === "count"}
                                  direction={order}
                                  onClick={(event) =>
                                    this.handleRequestSort(event, "count")
                                  }
                                  className="material-table__sort-label"
                                  dir="ltr"
                                >
                                  {eventType}
                                </TableSortLabel>
                              </Col>
                              <Col lg={4} className="">
                                Building
                              </Col>
                              <Col lg={1} xs={12} className="no-padding">
                                Year Built
                              </Col>
                              <Col lg={2} xs={12} className="no-padding">
                                Total Units
                              </Col>
                              {["price-drops", "price-hikes"].indexOf(chartType)>-1 ?
                              <>
                                <Col lg={2} xs={12} className="no-padding">
                                   Avg Change %
                                </Col>
                                <Col lg={2} xs={12} className="no-padding">
                                   Avg Change Amt
                                </Col>
                              </>                          
                              :
                              <>
                              <Col lg={2} xs={12} className="no-padding">
                                 Avg Unit Price
                              </Col>
                              <Col lg={2} xs={12} className="no-padding">
                                 Avg PPSF
                              </Col>
                              </>
                              }
                              
                            </Row>
                          </Container>
                          {!groupedData.length && noDataShow && (
                            <Container>
                              <Row className="no__data">
                                <Col lg={12} xs={12} className="no__data-text">
                                  No data found for your search.
                                </Col>
                              </Row>
                            </Container>
                          )}
                          {groupedData
                            .filter((item) => item.building)
                            .map((d) => {
                              const item = d;
                              const numFound = item.count;
                              const isSelected = this.isSelected(item.val);
                              const building = item.building;
                              let current_year = new Date().getFullYear();
                              return (
                                <ExpansionPanel
                                  TransitionProps={{ unmountOnExit: true }}
                                  id={item.val}
                                >
                                  <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls=""
                                    id={d.groupValue}
                                  >
                                    <Container>
                                      <Row className="expansion__summary">
                                        <Col lg={1} xs={12}>
                                          <Tooltip
                                            title={`See Market Activity - ${
                                              building && building.name
                                                ? _capitalizeText(building.name)
                                                : _capitalizeText(
                                                    building.display_full_street_address
                                                  )
                                            }`}
                                          >
                                            <IconButton
                                              size={`small`}
                                              style={{
                                                background: "transparent",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                this._handleBuildingOpen(
                                                  building
                                                );
                                              }}
                                            >
                                              <Badge
                                                color="matching"
                                                style={{ width: 50 }}
                                              >
                                                <span className="bold-text">
                                                  {_formatNumber(numFound)}
                                                </span>
                                                <OpenNewIcon
                                                  size={12}
                                                  color={"#FFF"}
                                                  style={{
                                                    marginLeft: 3,
                                                    marginTop: -2,
                                                  }}
                                                />
                                              </Badge>
                                            </IconButton>
                                          </Tooltip>
                                        </Col>
                                        <Col lg={4} xs={12}>
                                          <div className="expansion__header">
                                            <span
                                              className="building_name"
                                              onClick={(event) =>
                                                this.handleGroupedBuildingClick(
                                                  event,
                                                  building
                                                )
                                              }
                                            >
                                              {building && building.name
                                                ? _capitalizeText(building.name)
                                                : _capitalizeText(
                                                    building.display_full_street_address
                                                  )}
                                              <BuildingIcons
                                                building={building}
                                              />
                                            </span>
                                            <br />
                                            <span
                                              className="light-text small-text"
                                              style={{ color: "#858AA1" }}
                                            >
                                              {building.neighborhood &&
                                              building.neighborhood[0]
                                                ? `${_capitalizeText(
                                                    building.neighborhood[0]
                                                  )}, `
                                                : ""}{" "}
                                              {_capitalizeText(
                                                building.borough
                                              )}
                                            </span>
                                          </div>
                                        </Col>
                                        <Col
                                          lg={1}
                                          xs={12}
                                          className="no-padding"
                                        >
                                          <div className="expansion__header">
                                            <span className="light-text small-text">{building.year_built > 1700 ? building.year_built : '--'} {building.year_built > current_year && '(Proj)'}</span>
                                              
                                              {building.year_converted > 1700 && building.year_converted > building.year_built ? 
                                                <Fragment>
                                                  <br/>
                                                  <span className="light-text small-text" style={{color:"#858AA1"}}>{building.year_converted} {building.year_converted > current_year ? '(Proj Conv)' : '(Conv)'}</span>
                                                </Fragment>
                                             
                                             :
                                                 null
                                            }
                                          </div>
                                        </Col>
                                        <Col lg={2} xs={12}>
                                          <span className="light-text small-text">
                                            {_formatNumber(
                                              building.inventory_total
                                            )}{" "}
                                            Units
                                          </span>
                                        </Col>
                                          
                                          {["price-drops", "price-hikes"].indexOf(chartType)>-1 ?
                                          <>
                                            <Col
                                              lg={2}
                                              xs={12}
                                              className="no-padding"
                                            >
                                            
                                              {item.avg_chg_pct ? 
                                                <span className="small-text">{chartType == "price-drops" ? <ArrowDownIcon size={16} color="#FF206E" style={{marginTop:-2}} /> : <ArrowUpIcon size={16}  color="#4ce1b6" style={{marginTop:-2}} />} {Math.round(item.avg_chg_pct * 10000)/100}%</span>
                                                : null
                                              }
                                              
                                            </Col>
                                            <Col
                                              lg={2}
                                              xs={12}
                                              className="no-padding"
                                            >
                                              
                                              {item.avg_chg_amt ? 
                                                <span className="small-text">{chartType == "price-drops" ? <ArrowDownIcon size={16} color="#FF206E" style={{marginTop:-2}} /> : <ArrowUpIcon size={16}  color="#4ce1b6" style={{marginTop:-2}} />} ${_formatNumber(Math.abs(item.avg_chg_amt))}</span>
                                                : null
                                              }
                                            </Col>
                                          </>
                                          :
                                          <>
                                          <Col
                                            lg={2}
                                            xs={12}
                                            className="no-padding"
                                          >
                                          
                                            {item.avg_price ? 
                                              <span className="small-text">{_formatPrice(item.avg_price)}</span>
                                              : null
                                            }
                                            {item.sum_price ? 
                                              <span className="small-text light-text"><br/>(Total Volume: {_formatPrice(item.sum_price)} <span className="small-text light-text"></span>)</span>
                                              : null
                                            }
                                            
                                          </Col>
                                          <Col
                                            lg={2}
                                            xs={12}
                                            className="no-padding"
                                          >
                                            {item.ppsf && item.ppsf.avg_ppsf ? 
                                              <span className="small-text">${_formatNumber(item.ppsf.avg_ppsf)} Avg PPSF</span>
                                              : null
                                            }
                                           
                                            
                                          </Col>
                                          </>}
                                      </Row>
                                    </Container>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>
                                    <UnitsListTable
                                      isSelected={this.isSelected}
                                      handleBuildingClick={
                                        this.handleBuildingClick
                                      }
                                      handleClick={this.handleClick}
                                      handleRequestSort={this.handleRequestSort}
                                      handleSelectAllClick={
                                        this.handleSelectAllClick
                                      }
                                      {...this.state}
                                      {...this.props}
                                      reactTableFiltered={false}
                                      isGrouped
                                      q={"*:*"}
                                      fq={
                                        this.fq
                                          ? [...this.fq, ...[`${buildingQuery}`]]
                                          : [`${buildingQuery}`]
                                      }
                                      fl={this.fl}
                                      building={building}
                                      handleGroupedBuildingClick={
                                        this.handleGroupedBuildingClick
                                      }
                                      buildingKey={building.key}
                                    />
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              );
                            })}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <UnitsListTable
                            isSelected={this.isSelected}
                            handleBuildingClick={this.handleBuildingClick}
                            handleClick={this.handleClick}
                            handleRequestSort={this.handleRequestSort}
                            handleSelectAllClick={this.handleSelectAllClick}
                            {...this.state}
                            {...this.props}
                            reactTableFiltered={false}
                            isGrouped
                            q={this.q}
                            fq={
                              this.fq
                                ? [...this.fq, ...[`${buildingQuery}`]]
                                : [`${buildingQuery}`]
                            }
                            fl={this.fl}
                          />
                          <hr />
                        </Fragment>
                      )}
                    </div>
                  )}
              </div>
              {viewType == "building" &&
              paginationCount &&
              groupedData &&
              noDataShow ? (
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={paginationCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[25, 50, 100]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                />
              ) : null}
            </div>
          </div>
          <ModalFooter>
            <ButtonToolbar className="modal__footer mb-3">
              <Tooltip title="Cancel">
                <Button
                  onClick={toggleModal}
                  variant="outlined"
                  color=""
                  className="mr-2"
                  startIcon={null}
                >
                  {" "}
                  Cancel
                </Button>
              </Tooltip>{" "}
            </ButtonToolbar>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    searchTags: Array.isArray(state.search.searchTags)
      ? state.search.searchTags.slice()
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(searchActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardModal));
