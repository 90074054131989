import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import Supply from './components/Supply';
import Active from './components/Active';
import TotalUnits from './components/TotalUnits';
import AveragePPSF from './components/AveragePPSF';
import AveragePrice from './components/AveragePrice';
import AveragePPSFListed from './components/AveragePPSFListed';
import AveragePPSFSold from './components/AveragePPSFSold';

import AverageDiscountAll from './components/AverageDiscountAll';

import AverageDiscount from './components/AverageDiscount';

import AveragePriceListed from './components/AveragePriceListed';
import AveragePriceSold from './components/AveragePriceSold';

import PriceRange from './components/PriceRange';
import TotalUnsold from './components/TotalUnsold';

import CurrentProgress from './components/CurrentProgress';

import SalesVelocity from './components/SalesVelocity';
import AbsorptionRate from './components/AbsorptionRate';
import PercentShadow from './components/PercentShadow';

import UnitMix from './components/UnitMix';
import UnitBreakdown from './components/UnitBreakdown';

import NeighborhoodAnalysis from './components/NeighborhoodAnalysis';
import NeighborhoodPrice from './components/NeighborhoodPrice';
import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';


const Analysis = (props) => (
	<Container className="dashboard no-padding">
		   {/* <Row>
		   	  <Col md={12} xl={12} lg={12} xs={12}>
		   	  	<CurrentProgress  {...props} />
		   	  </Col>
		   </Row> */}
		   <Row >
		   	 <Col md={12} xl={3} lg={6} xs={12}>
		   	  	<Supply {...props} />
		   	  </Col>  	 
		   	 <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AveragePPSF {...props} />
		     </Col>
		      <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AveragePPSFListed {...props} />
		     </Col>
		     <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AveragePPSFSold {...props} />
		     </Col>
		   </Row>
		   <Row>
		   	  <Col md={12} xl={4} lg={4} sm={12} xs={12}>
		   	  	<UnitMix {...props} />
		   	  </Col>
		   	  <Col md={12} xl={8} lg={8} sm={12} xs={12}>
		   	  	<UnitBreakdown {...props} />
		   	  </Col>
		   </Row>
		
		{/*   <Row>
		     <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AverageDiscount {...props} />
		   	 </Col>
		     <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AveragePrice {...props} />
		   	 </Col>
		   	 <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AveragePriceListed {...props} />
		     </Col>
		     <Col md={12} xl={3} lg={6} xs={12}>
		   	 	<AveragePriceSold {...props} />
		     </Col>		              
		   </Row> */}
		   {/*<Row>
		   	 <SalesVelocity {...props} />
		   	 <AbsorptionRate {...props} />
		   	 <PercentShadow {...props} />
		   </Row>*/}
		   {!(props.unitStages && props.unitStages.length == 1 && props.unitStages.indexOf("resale")>=0) &&
		   <Row>
		   	  <Col md={12} xl={12} lg={12} xs={12}>
		   	  	<NeighborhoodAnalysis  {...props} />
		   	  </Col>
		   </Row> 
		   }
		      
		   {props.user && ['seller', 'enterprise'].indexOf(props.user.role) > -1 &&
		   <Row>
		   	 {/* <ListingTrends buildingRes={props.buildingRes} statsRes={props.statsRes} />
		   	  <ListingTrendsPrice buildingRes={props.buildingRes} statsRes={props.statsRes} /> */}
		   </Row>
		   }
		   <Row>
		   	 {/* <SalesVelocity buildingRes={props.buildingRes} statsRes={props.statsRes} />
		   	  <AbsorptionRate buildingRes={props.buildingRes} statsRes={props.statsRes} />
		   	  <PercentShadow buildingRes={props.buildingRes} statsRes={props.statsRes} />*/}
		   </Row>
		   <Row>
		    {/*	<UnitMix statsRes={props.statsRes} />
		   	<UnitBreakdown statsRes={props.statsRes} />*/}
		   </Row>
	</Container>
)


const NewDevelopment = (props) => {
	
	
	return (
		<div>
			<ExportComponentButton {...props} title={"Inventory Analysis"} component={<Analysis {...props} />} />
			<Analysis {...props} />	
		</div>  	
	)
};

export default connect(state => ({
  user: state.user,
}))(withRouter(NewDevelopment));