import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";

import { Card, CardBody, Col, Progress, Badge, Row, Container, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import MatTableHead from './MatTableHead';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import AlertIcon from 'mdi-react/InformationOutlineIcon';
import UnitIcons from '../../../shared/components/UnitIcons';
import BuildingIcons from '../../../shared/components/BuildingIcons';
import IconButton from '@material-ui/core/IconButton';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import AddCollectionButton from '../../../shared/components/collections/AddCollectionButton';

import MyReports from '../../MyReports';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	UnitCurrentStatus,
	_formatUnitNumber,
	propertyExportFL,
	propertyFL,
	SalesProgress,
	calcuateTCODate,
	calcuateSalesStart,
	BuildingStatus,
	CurrentStatus,
	_isHouse,
	HouseCurrentStatus
} from "../../../shared/helpers/utils";

import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';

class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 20,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
		selected: new Map([]),
		activeTab: 'buildings',
		selectedUnits: {}
	};

	componentDidMount() {
		const { group } = this.props;
		
		
		if(group && group.group_data && group.group_data['buildings'] && group.group_data['buildings'].length>0){
			this.queryBuildings();
		}else if(group && group.group_data && group.group_data['units'] && group.group_data['units'].length>0){
			this.queryUnits();
			this.setState({activeTab:'units'})
		}
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { activeTab } = this.state;
		const { group } = this.props;
		
		  if( 
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 if(activeTab == 'buildings'){
				this.queryBuildings(reset); 
			 }else if(activeTab == 'units'){
				this.queryUnits(reset); 
			 }
			 
		  }		
		  
		  
		
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    _handleBuildingClick = (event, building) => {
		 const buildingURL = _getBuildingURL(building, 'building_slugs') + '/' + _nameToUrl(building.address_2_normalized ? building.address_2_normalized : "");
		 window.open(buildingURL)
		 //this.props.history.push(buildingURL);
	};
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};
	

	
	queryUnits = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const { group } = this.props;
		
		const { orderBy, order } = this.state;
		
		
		let sort = "floor asc, address_2_normalized asc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			if(orderBy === 'address_2_normalized'){
				sort = `floor ${order}, ${orderBy} ${order}`;
			}else if(orderBy === 'offering_price'){
				sort = `last_listed_sale_price_all ${order}, ${orderBy} ${order}`;
			}else if(orderBy === 'last_sold_date'){
				sort = `${orderBy} ${order}, last_contract_date ${order}`;
			}else{
				sort = `${orderBy} ${order}`;
			}
			
		}
		const q = `key:(${group.group_data['units'].join(" ")})`;
		
		const unitsFq = ['deleted:false'];			
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=0",
			method: "post",
			query: {
				q: q,
				fq: unitsFq,
				sort: sort,
				fl: propertyFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false, noMoreResults: (res.docs.length == res.numFound) })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	queryBuildings = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const { group } = this.props;
		
		const { orderBy, order } = this.state;
		
		
		let sort = "total_units desc";
		if(orderBy && orderBy.length > 0){
			sort = `${orderBy} ${order}`;
			
		}
		const q = `key:(${group.group_data['buildings'].join(" ")})`;
		
		const buildingsFq = ['deleted:false'];			
		let axiosData = {
			url: buildingUrl + "we3-buildings?cache=0",
			method: "post",
			query: {
				q: q,
				fq: buildingsFq,
				sort: sort,
				fl: buildingFL,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};
	
		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false, noMoreResults: (res.docs.length == res.numFound)   })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	toggle = (tab) => {
		const { activeTab } = this.state;
		if (activeTab !== tab) {
		  if(tab == 'units'){
			  this.queryUnits(true);
		  }else if(tab == 'buildings'){
			  this.queryBuildings(true);
		  }
		  this.setState({
			activeTab: tab,
			unitListData: [],
			start: 0,
			page: 0,
			noMoreResults: false,
			selectedUnits: {}
		  });
		}
	};
	
	_selectUnit(property){
		const { selectedUnits } = this.state;
		const newSelected = Object.assign({}, selectedUnits);
		
		if(newSelected[property.key]){
			delete newSelected[property.key];			
		}else{
			newSelected[property.key] = property;
		}
		
		this.setState( { selectedUnits: Object.assign({}, newSelected) } );
	}
	
	_handleSelectAllClick(){
		const { selectedUnits, unitListData } = this.state;
		let newSelected = {}
		
		if(Object.keys(selectedUnits).length < unitListData.length){
			
		
			unitListData.forEach(item => {
				newSelected[item.key] = item;
			})
		}
		
		this.setState( { selectedUnits: newSelected } );
		
	}
	render(){
		const { reactTableFiltered,  isSelected, handleClick, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, building, group} = this.props;
		const { selected, unitListData, fetchInProgress, activeTab, selectedUnits, order, orderBy } = this.state;
		if((this.state.start===0 && (unitListData===0 || this.props.fetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const data = unitListData;
		let isHouse = false;
		
		data && data.map(d => {
			if(_isHouse(d)){
				isHouse = true;
			}	
		})
		
		
		if(!fetchInProgress && data.length <= 0 && ["buildings", "units"].indexOf(activeTab)>=0){
			return (
			  <Col md={12}>
			    <Card>
			      <CardBody className={`small`}>
			        <div className={`email-confirmation`} >
			          <div className="email-confirmation__icon">
			            <AlertIcon size={50} color={`#D6BAE8`} />			            
			          </div>
			          <h3 className="email-confirmation__title">No Buildings or Units Added to This Collection</h3>
	
			          <p className="email-confirmation__sub"><a href="https://help.marketproof.com/en/articles/6150058-guide-to-collections-sharing-on-marketproof-new-dev-condo" className="link-text" target="_blank">Learn About Collections <OpenInNewIcon size={16} style={{marginTop:-3}}/></a></p>
			          
			        </div>
			      </CardBody>
			    </Card>
			  </Col>
			  )
		}
		
		
		return (
		<Fragment>
			<div className="tabs tabs--bordered-bottom mb-0">
			  <div className="tabs__wrap">
				<Nav tabs>
					{group && group.group_data && group.group_data['buildings'] && group.group_data['buildings'].length>0 &&
				  		<NavItem>
							<NavLink
					  		className={classnames({ active: activeTab === 'buildings' })}
					  		onClick={() => {
								this.toggle('buildings');
					  		}}
							>
					  		<span className="tab-title">Buildings</span>
							</NavLink>
				  		</NavItem>
			  		}
					{group && group.group_data && group.group_data['units'] && group.group_data['units'].length>0 &&
				  		<NavItem>
					  		<NavLink
								className={classnames({ active: activeTab === 'units' })}
								onClick={() => {
						  		this.toggle('units');
								}}
					  		>
								<span className="tab-title">{group.group_data['units'].filter(d => d.indexOf("apt")>=0).length <= 0 ? "Houses" : "Units"}</span>
					  		</NavLink>
							</NavItem>
					}
				  	<NavItem>
					  	<NavLink
							className={classnames({ active: activeTab === 'shared' })}
							onClick={() => {
						  	this.toggle('shared');
							}}
					  	>
							<span className="tab-title">Shared</span>
					  	</NavLink>
					</NavItem>
					
				</Nav>
			  </div>
			</div>
			
			<TabContent activeTab={activeTab}>
				
			{activeTab === 'shared' &&
			    <TabPane tabId="shared">
				  <div className="mt-5">
				    <MyReports group={group}  subTab /> 
				  </div>
				</TabPane>
			}   
			   
			 {activeTab === 'buildings' &&
			   <TabPane tabId="buildings">
				   		
						<div className="material-table__wrap">
						  <AddCollectionButton selectedUnits={selectedUnits} groupType={"buildings"}  {...this.props} removeSelectFromGroup />
						  <Table className="material-table">
							<MatTableHead
							  numSelected={Object.keys(selectedUnits).length}
							  order={order}
							  orderBy={orderBy}
							  onSelectAllClick={this._handleSelectAllClick.bind(this)}
							  onRequestSort={this._handleRequestUnitsSort.bind(this)}
							  rowCount={data.length}
							/>
							
							<TableBody>
							  {data
								  .map((d) => {
								  const isSelected = false; //this.isSelected(d.id);
								  //If building is sold out override stats
		/*
								  if(d.offering_verified_status === 'sold out'){
									  d.inventory_unsold = 0;
									  d.inventory_pct_unsold = 0;
								  }
								  
		*/
								  let inventory_pct_unsold = d.inventory_pct_bulk_unsold ? d.inventory_pct_bulk_unsold : d.inventory_pct_unsold;
								  let inventory_unsold = d.inventory_bulk_unsold ? d.inventory_bulk_unsold : d.inventory_unsold;
								  let offering_effective_year = d.offering_effective_date ?  parseInt(moment(d.offering_effective_date).format('YYYY')) : 0;
								  if(offering_effective_year <= 1970){
									  offering_effective_year = 0;
								  }
								  let current_year = new Date().getFullYear();
								  return (
									<TableRow
									  className="material-table__row"
									  role="checkbox"
									  onClick={event => this._handleBuildingClick(event, d)}
									  aria-checked={isSelected}
									  tabIndex={-1}
									  key={d.id}
									  selected={isSelected}
									>
									  <TableCell className="material-table__cell" padding="checkbox">
										   <Checkbox checked={selectedUnits[d.key]===undefined ? false : true} onClick={(e)=>{e.preventDefault();e.stopPropagation(); this._selectUnit(d);}} className={`material-table__checkbox ${selectedUnits[d.key]!==undefined && 'material-table__checkbox--checked'}`} />							
									  
										{user.role && Array.isArray(user.role) 
											&& user.role.indexOf('admin')>=0 
											&& d.offering_file_number && d.offering_file_number &&
											  <Fragment>
												  <IconButton onClick={(e) => { e.preventDefault();e.stopPropagation(); window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${d.offering_file_number}`)}} >
													  <OpenIcon color={'#BFC4D1'} />
												  </IconButton>
											  </Fragment>
										  }
										
									  </TableCell>
									   
									  <TableCell
										className="material-table__cell material-table__cell-right"
										component="th"
										scope="row"
										padding="none"
									  >
										<span className={"building_name"}>{d.name ? _capitalizeText(d.name) : _capitalizeText(d.display_full_street_address)}
																												  
											  <BuildingIcons building={d} />
											  
		
										</span>
										<br/><span className="light-text small-text">{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>
									  </TableCell>
									 
									  <TableCell
										className="material-table__cell material-table__cell-right"
										size="small"                          
									  >
									  
									  {d.offering_construction==='conversion' 
										   ?
										   <SalesProgress progress={parseFloat(inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min color={"grey"} resale={d.building_status === 'resale'}/>
										   : d.building_status === 'resale' ?
										   <SalesProgress progress={parseFloat(d.inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min color={"grey"} resale={d.building_status === 'resale'} />
										   :
										 <SalesProgress progress={parseFloat(inventory_pct_unsold).toFixed(0)} inventory_unsold={d.inventory_unsold} inventory_total={d.inventory_total} min resale={d.building_status === 'resale'} />
									  }
									  </TableCell>
									  <TableCell
										className="material-table__cell light-text small-text"
										size="small" 
										align="center"
									  >
											{d.inventory_total > 0 && d.building_status !== 'resale' ?		 
																			 
											<span className="light-text small-text">{inventory_unsold > 0 ? _formatNumber(inventory_unsold) : 0}</span>
											:
											<span className="light-text small-text">--</span>
										  }
										  
									  </TableCell>                         
									  <TableCell
										className="material-table__cell light-text small-text"
										size="small" 
										align=""
									  >
									   <span className="light-text small-text" >
									  {d.inventory_total > 0 ? `${d.ownership_type === 'coop' ? _formatNumber(d.inventory_total) + ' Co-op' : _formatNumber(d.inventory_total) + ' Condo'}` : '' }
									  </span>
									  <br/>
									  <span className="light-text small-text" style={{color:"#858AA1"}}>{d.building_status == 'resale' && d.number_of_buildings > 1 && d.tax_assessed_total_units > 1 ?  _formatNumber(d.tax_assessed_total_units) :  _formatNumber(d.total_units)} Total</span>
									  
									 
									  </TableCell>
									   <TableCell
										className="material-table__cell"
										align=""
									  ><span className="light-text small-text">{d.year_built > 1700 ? d.year_built : '--'} {d.year_built > current_year && '(Proj)'}</span>
									  
									  {d.year_converted > 1700 && d.year_converted > d.year_built ? 
										  <Fragment>
											<br/>
											  <span className="light-text small-text" style={{color:"#858AA1"}}>{d.year_converted} {d.year_converted > current_year ? '(Proj Conv)' : '(Conv)'}</span>
										  </Fragment>
									   : (offering_effective_year - d.year_built) > 10 ?
											 <Fragment>
											<br/>
											  <span className="light-text small-text" style={{color:"#858AA1"}}>{offering_effective_year} (Conv)</span>
										  </Fragment>
									   :
											 null
									  }
									  </TableCell> 
									  <TableCell
										className="material-table__cell"
										align=""
									  ><span className="light-text small-text">{moment(d.offering_submitted_date).year() > 1970 ? _capitalizeText(moment(d.offering_submitted_date).fromNow()) : '--'}</span>
									  </TableCell> 
									  <TableCell
										className="material-table__cell"
										align=""
									  ><span className="light-text small-text">{moment(d.offering_accepted_date).year() > 1970 ? _capitalizeText(moment(d.offering_accepted_date).fromNow()) : '--'}</span>
									  </TableCell>
									   <TableCell
										className="material-table__cell light-text small-text"
										size="small" 
										align=""
									  ><span className="light-text small-text">{_capitalizeText(calcuateSalesStart(d, 'fromNow'))}</span>
									  </TableCell>                       
									  <TableCell
										className="material-table__cell"
										align=""
									  ><span className="light-text small-text">{_capitalizeText(calcuateTCODate(d, 'fromNow'))}</span>
									  </TableCell>
									  <TableCell
										className="material-table__cell material-table__cell-right"
										align="right"
									  ><span className="light-text small-text">{d.inventory_stats_listings_low_price > 100 ? `${_formatPrice(d.inventory_stats_listings_low_price).toUpperCase()} - ${_formatPrice(d.inventory_stats_listings_high_price).toUpperCase()}` : '--'}</span>
									  </TableCell>
									 {(user && user.role.indexOf('admin')>=0) &&  <TableCell
										className="material-table__cell material-table__cell-right"
										align="center"
									  ><span className="light-text small-text">{user.role.indexOf('admin')>=0 ? <BuildingStatus item={d} /> : <CurrentStatus item={d} /> }</span>
									  </TableCell> }
									  
									</TableRow>
								  );
								})}
							  {emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
								  <TableCell colSpan={6} />
								</TableRow>
							  )}
							</TableBody>
						  </Table>
						</div> 						 
				</TabPane>
			 }
			 {activeTab === 'units' &&
			  <TabPane tabId="units">
					<div>
					<AddCollectionButton selectedUnits={selectedUnits} groupType={"units"}  {...this.props} removeSelectFromGroup />
					<Table className="material-table">
		        		<UnitsSearchTableHead
		          			numSelected={Object.keys(selectedUnits).length}
		          			order={order}
		          			orderBy={orderBy}
		          			onSelectAllClick={this._handleSelectAllClick.bind(this)}
		          			onRequestSort={this._handleRequestUnitsSort.bind(this)}
		          			rowCount={data.length}
		        		/>
		        		<TableBody>
		          		{data
		            		.map((d) => {
		              		const itemSelected = false; //isSelected(d.id);
		              		const floorplan = false; //_getUnitFloorPlan(d);
					  		const buildingData = d;
					  		//If building is sold out override stats
					  		if(building && building.offering_verified_status === 'sold out'){						  
						  		if(["active", "not_yet_recorded", "sold", "in contract", "resale"].indexOf(d.offering_status) < 0){
							  		d.offering_status = "sold";
						  		}						  
					  		}
					  		
					  		if(d.baths % 1 == 0 && d.half_baths>0){
						  		d.baths = d.baths + .5;
						  					  		
					  		}
		              		return (
		                		<TableRow
		                  		className="material-table__row"
		                  		role="checkbox"
		                  		onClick={event => this._handleBuildingClick(event, d)}
		                  		aria-checked={itemSelected}
		                  		tabIndex={-1}
		                  		key={d.id}
		                  		selected={itemSelected}
		                		>
		                 		
		                  		<TableCell className="material-table__cell" padding="checkbox">
									   <Checkbox checked={selectedUnits[d.key]===undefined ? false : true} onClick={(e)=>{e.preventDefault();e.stopPropagation(); this._selectUnit(d);}} className={`material-table__checkbox ${selectedUnits[d.key]!==undefined && 'material-table__checkbox--checked'}`} />							
								</TableCell>
		                 		{!isMobile && <TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small"                          
		                  		>
		                  	 		{isHouse ? <HouseCurrentStatus status={d.current_status} unit={d} /> : <UnitCurrentStatus status={d.current_status} unit={d} />}
		                  	 		{/*d.current_status === 'not_yet_recorded' && 
			                  	 		<div className="light-text xsmall-text no-wrap text-center" style={{marginTop:3}}>(Not Yet Recorded)
			                  	 		</div>*/}
		                  		</TableCell> }
		                		
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right nowrap"
		                    		component="td"
		                    		scope="row"
		                    		padding=""
		                  		>
		                  	 		{isGrouped || this.props.buildingView ?
		                      		<div className="icon__floorplan"><span>{_formatUnitNumber(d.address_2_normalized, building).replace("#", "Apt")}</span>		                   
			                    		
			                    		<UnitIcons building={building} unit={d} />
			                   			<Fragment>
						  					<OpenInNewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`${_getBuildingURL(buildingData, 'building_slugs')}/${_nameToUrl(d.address_2_normalized)}`); }} />
						  				</Fragment>
				                   			
				                 		
		                      		</div>
			                 		:
			                  		<div className="icon__floorplan">
			                  			<span>{d.name ? _capitalizeText(d.name) : _capitalizeText(_replaceAbbreviations(d.display_full_street_address))}, {d.address_2_normalized.toUpperCase().replace("APT", "Apt")}</span>
			                  			
			                    		{!isHouse && <UnitIcons building={building} unit={d} />}
			                    		{!isMobile && <Fragment>
						  					<OpenInNewIcon size={14} style={{ marginTop:"-3px", marginLeft:"5px"}} color={'#BFC4D1'} onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`${_getBuildingURL(buildingData, 'building_slugs')}/${_nameToUrl(d.address_2_normalized)}`); }} />
						  				</Fragment>}
			                    		<br/><span className="light-text small-text" style={{color:"#858AA1"}}>{d.neighborhood && d.neighborhood[0] ? `${_capitalizeText(d.neighborhood[0])}, ` : ''} {_capitalizeText(d.borough)}</span>							
			                   		
		                   	  		</div>
		                   	  		
		                   			}
		                   			
		                   			
		                   			
		                   			
		                  		</TableCell>
		                  		
		                 		
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small"                          
		                  		>
		                  			
		                  			<span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
		                  			
		                  		</TableCell>
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small"                          
		                  		>
		                  			
		                  			<span className="light-text small-text">{d.baths > -1 ? d.baths+' Bath' : '--'}</span>
		                  			
		                  		</TableCell>
						  		
		                   		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small" 
		                    		align="center"
		                  		>
		                  			{ (d.offering_status === 'in contract') && _shouldShow(d.last_contract_price) && d.last_contract_price > 100  ? 
		                  				<Fragment>
		                  					<span className="small-text">
		                  					
		                  						{_shouldShow(d.last_contract_price) ? `$${_formatNumber(d.last_contract_price)}` : "--"}
		                  					</span>
		                  					
		                  				</Fragment>
		                  			: _shouldShow(d.last_listed_sale_price_all) && d.last_listed_sale_price_all > 100 ? 
		                  				<Fragment>
		                  					<span className="small-text">
		                  						{_shouldShow(d.last_listed_sale_price_all) ? `$${_formatNumber(d.last_listed_sale_price_all)}` : "--"}
		                  					</span>
		                  					
		                  				</Fragment>
		                  				
		                  			: 
		                  				<Fragment>
		                  					<span className="small-text">{_shouldShow(d.offering_price) ? `$${_formatNumber(d.offering_price)}` : "--"}</span>
						  					{_shouldShow(d.offering_price) && <span className="light-text xsmall-text no-wrap"></span>}
		                  				</Fragment>
		                  			}
		                  		</TableCell>
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small" 
		                    		align="center"
		                  		>
		                  		
		                    		{typeof d["div(last_listed_sale_price_all, sq_ft)"] === 'number' && d["div(last_listed_sale_price_all, sq_ft)"] > 100 ? 
			                    		<span className="small-text">
			                  						${_formatNumber(d["div(last_listed_sale_price_all, sq_ft)"])}
			                  			</span>
			                  			:
			                  			<span className="small-text">
			                  				--
			                  			</span>
			                  			
		                  			}
		                  		</TableCell>
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small" 
		                    		align="center"
		                  		>
		                  			{  (d.last_sold_price && d.last_sold_price > 100) ?
			                  			<Fragment>
		                  					<span className="small-text">{_shouldShow(d.last_sold_price) ? `$${_formatNumber(d.last_sold_price)}` : "--"}</span>
		                  				</Fragment>
		                  			:
		                  				<Fragment>
		                  					<span className="light-text small-text">--</span>
		                  				</Fragment>
		                  			}
		                  		</TableCell>
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small" 
		                    		align="center"
		                  		>
		                  		
		                    		{typeof d["div(last_sold_price, sq_ft)"] === 'number' && d["div(last_sold_price, sq_ft)"] > 100 ? 
			                    		<span className="small-text">
			                  						${_formatNumber(d["div(last_sold_price, sq_ft)"])}
			                  			</span>
			                  			:
			                  			<span className="small-text">
			                  				--
			                  			</span>
		                  			}
		                  		</TableCell>
		                   		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small"                          
		                  		>
		                  			{	d.current_status === 'in contract' ? 
			                  			<Fragment>
			                  			{parseInt(moment.unix(d.last_contract_date).format('YYYY')) > 1970 ? 
				                  			<Fragment>
					                		<span className="light-text small-text">{moment.unix(d.last_contract_date).format('MM/DD/YYYY')}</span>
				                  			<span className="light-text xsmall-text no-wrap"><br/>(Contract Date)</span>
				                  			</Fragment>	
				                		:
				                			<span className="light-text xsmall-text no-wrap">--</span>
				                		}	
				                		</Fragment> 
				                		: d.current_status === 'not_yet_recorded' ? 	   
				                			<span className="light-text small-text no-wrap">Not Yet Recorded</span>               		
			                  			: d.last_sold_date && d.last_sold_date !== 0 ?
			                  			<Fragment> 
		                    			<span className="light-text small-text">{moment.unix(d.last_sold_date).format('MM/DD/YYYY')}</span>
		                    			{d.current_status === 'not_yet_recorded' && <span className="light-text xsmall-text no-wrap"><br/>(Not Yet Recorded)</span>}
		                    			</Fragment>	
		                    			:
		                  				<span className="light-text small-text">--</span>
		                  			}
		                  		</TableCell>  
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small" 
		                    		align="center"
		                  		>
		                  			{d.offering_verified_status === "active"  || d.offering_sq_ft > -1 ? 
		                  				<span className="light-text small-text">{_shouldShow(d.offering_sq_ft) ? _formatNumber(d.offering_sq_ft)+' sf' : '--'}</span>
		                  			:
		                  				<span className="light-text small-text">{_shouldShow(d.sq_ft) ? _formatNumber(d.sq_ft)+' sf' : '--'}</span>
		                  			}
		                  		</TableCell>    
		                  		<TableCell
		                    		className="material-table__cell material-table__cell-right"
		                    		size="small" 
		                    		align="center"
		                  		><span className="light-text small-text">{_shouldShow(d.offering_exterior_sq_ft) ? <React.Fragment>{_formatNumber(d.offering_exterior_sq_ft)} sf</React.Fragment>  : `--`}</span>
		                		</TableCell>
		                		
		                		</TableRow>
		              		);
		            		})}
		          		{emptyRows > 0 && (
		            		<TableRow style={{ height: 49 * emptyRows }}>
		              		<TableCell colSpan={6} />
		            		</TableRow>
		          		)}
		        		</TableBody> 
		        		
              		
		      		</Table>
		      		</div>
				</TabPane>
				}
		       </TabContent>
			   {!reactTableFiltered && ["buildings", "units"].indexOf(activeTab)>=0 && 
				<div className="unit__load">			   		
				    <Tooltip title="Load more units">
				        <Button
					        onClick={(e) => {this._loadMore(e)}}
					         variant="contained"
					         color="news"
					         className={`timeline__load-more`}		         
					         startIcon={<LoadMoreIcon />}
					         disabled={this.state.fetchInProgress || this.state.noMoreResults}
					     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? `No More ${_capitalizeText(activeTab)}` : "Load More"}`}
					     </Button>				 
				  	</Tooltip>				
			    </div>
			    }
			</Fragment>
		);
	}
}

export default connect(state => ({
  user: state.user
}))(withRouter(UnitsListTable));