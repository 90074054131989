import React, { PureComponent } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';

export default class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { activeTab } = this.state;
	const { buildingRes } = this.props;
    return (
      <div className="tabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Description
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                this.toggle('2');
              }}
            >
              Neighborhood
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                this.toggle('3');
              }}
            >
              Schools
            </NavLink>
          </NavItem>
           <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                this.toggle('4');
              }}
            >
              Transportation
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="typography-message">
          <TabPane tabId="1">
            {buildingRes.description && 
	               <p className="typography-message">
	               {buildingRes.description[0].replace(/Building\n\n/g, '').replace(/Residences\n\n/g, '\n').replace(/Amenities\n\n/g, '\n').replace(/\n\n/g, '\n').split('\n').map((item, key) => {
						return <React.Fragment>{item}<br/></React.Fragment>
					})}
	               </p>
               }
          </TabPane>
          <TabPane tabId="2">
            <p>Area info.
            </p>
          </TabPane>
          <TabPane tabId="3">
            <p>School info.
            </p>
          </TabPane>
          <TabPane tabId="4">
            <p>Transportation info.
            </p>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
