import React, { PureComponent, useState, Fragment, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import TotalFloors from './components/TotalFloors';
import TotalUnits from './components/TotalUnits';
import AveragePPSF from './components/AveragePPSF';
import PriceRange from './components/PriceRange';
import TotalUnsold from './components/TotalUnsold';

import AveragePPSFSold from './components/AveragePPSFSold';
import TotalSqFt from './components/TotalSqFt';
import AvgUnitSqFt from './components/AvgUnitSqFt';
import PriceRangeSold from './components/PriceRangeSold';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';


import ListingTrends from './components/ListingTrends';
import ListingTrendsPrice from './components/ListingTrendsPrice';

import SoldTrends from './components/SoldTrends';
import SoldTrendsPrice from './components/SoldTrendsPrice';
import SoldTrendsPPSF from './components/SoldTrendsPPSF';

import LinearLoading from '../../../../shared/components/LinearLoading';

import ExportComponentButton from '../../../../shared/components/buttons/ExportComponentButton';
import { Publisher } from '../../../../shared/pubsub/publisher.js';


import classnames from 'classnames';
import { _hasPermission	} from "../../../../shared/helpers/access";
import{
	_capitalizeText,
	_formatNumber,
	_slugifyText,
	_getBuildingURL
} from '../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../shared/helpers/apicalls';
const apiURL = process.env.API_ROOT + '/query/';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft:"-8px",
    alignItems: 'left',
    '& > *': {
      margin: theme.spacing(1),      
    },
    
  },
  button:{
	'&.selected' :{
	    background:"#D1D6E0"
    }
  }
}));

const Sales = (props) => {
	const [activeTab, setActiveTab] = useState(props.match && props.match.params.subtab ? props.match.params.subtab : 'summary');
	
	const [period, setPeriod] = useState("all-time");
	
	const [listingStats, setListingStats] = useState(null);
	const [soldStats, setSoldStats] = useState(null);
	
    const { unitsRes, buildingRes, statsRes, user } = props;  
    const _boro = buildingRes.borough;
    const _hood = buildingRes.neighborhood[0];
    const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
    const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
    const summaryTitle = 'Building Analysis - ' + buildingNameOrAdr + ', ' + _hood + ', ' + _boro;
    const _toggleTab = (tab) => {
	    if (activeTab !== tab) {
		  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/analysis/${tab}` : '');
		  //props.history.replace(buildingURL);
		  window.history && window.history.pushState({}, null, buildingURL);
	      setActiveTab(tab)
	      Publisher.publish(`track.buildingAnalysisView.${tab}`, {building: buildingRes.key});
	    }
	  };
	const getListingTrend = async () => { 
			const addressQ = buildingRes.alternate_addresses.join('" OR "');					
			const q = `alternate_addresses:("${addressQ}") AND -address_2_normalized:""  AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}")`;
			const fq = [
				"deleted:false",
				"listing_type:sale",
				"price:[50000 TO *]",
				`source_namespace:(${process.env.LISTING_NAMESPACES})`
			]
			let axiosData = {
				url: apiURL + "search-listings",
				method: "post",
				query: {
					q: q,
					sort: "solr_updated_date desc",
					wt: "json",
					fq: fq,
					rows: 1,
					"json.facet": {
					listing_price: {
			  		range: {
						field: "active_date_start",
						start: buildingRes.sales_start_date,
						end: "NOW",
						gap: "+1YEAR",
						facet: {
				  		by_bedrooms: {
							type: "terms",
							field: "bedrooms",
							limit: 5,
							facet: {
					  		avg_price: "avg(price)"
							}
				  		},
				  		avg_price: "avg(price)",
						}
			  		}   
					},
		  		}
					
				}
			};
			let formattedData = await _axiosCall(axiosData)
			.then(res => {
				setListingStats(res);
			});
	}
	const getSalesTrend = async () => { 
			const addressQ = buildingRes.alternate_addresses.join('" OR "');					
			const q = `alternate_addresses:("${addressQ}") AND -address_2_normalized:"" (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}")`;
			const fq = [
				"status:sold",
				"transaction_source:ACRIS*",
				"price:[50000 TO *]",
				"parcels_condocoop_count:1"
			]
			let axiosData = {
				url: apiURL + "we3-transactions?cache=3600",
				method: "post",
				query: {
					q: q,
					sort: "close_date_formatted desc",
					wt: "json",
					fq: fq,
					rows: 0,
					"json.facet": {
					building_resale: {
					  type : "query",
					  q : `alternate_addresses:("${addressQ}") AND -address_2_normalized:"" (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}") AND sponsored_sale:false`,
					  facet : {
						  trends : {
							  range: {
								  field: "close_date_formatted",
								  start: buildingRes.sales_start_date,
								  end: "NOW",
								  gap: "+1YEAR",
								  facet: {
									by_bedrooms: {
									  type: "terms",
									  field: "bedrooms",
									  limit: 5,
									  facet: {
										avg_price: "avg(price)"
									  }
									},
									avg_price: "avg(price)",
								  }
								}  
						  }
					  }
					},
					building_resale_ppsf: {
					  type : "query",
					  q : `alternate_addresses:("${addressQ}") AND -address_2_normalized:"" (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}") AND price_per_sqft:[300 TO *]`,
					  facet : {
						  trends : {
							  range: {
								  field: "close_date_formatted",
								  start: buildingRes.sales_start_date,
								  end: "NOW",
								  gap: "+1YEAR",
								  facet: {
									by_bedrooms: {
									  type: "terms",
									  field: "bedrooms",
									  limit: 5,
									  facet: {
										avg_ppsf: "avg(price_per_sqft)"
									  }
									},
									avg_ppsf: "avg(price_per_sqft)"
								  }
							}  
						}
					  }
					},
				  }
					
				}
			};
			let formattedData = await _axiosCall(axiosData)
			.then(res => {
				setSoldStats(res);
			});
	}
	useEffect(() => {
	  getSalesTrend();
	  //getListingTrend();
	}, []);
	const classes = useStyles();
	
	if(!soldStats){
		return  (
		<div>
		<Container className="dashboard" style={{ "border" : "0px solid #DBE0E8" }}>
		<div>
		<LinearLoading />
		</div>
		</Container>	  
		</div>	
		)
	}
	

    return (

	    <div>
		{soldStats &&
		   <Row>
		   		<SoldTrends buildingRes={props.buildingRes} statsRes={props.statsRes} soldStats={soldStats} />
				<SoldTrendsPPSF buildingRes={props.buildingRes} statsRes={props.statsRes} soldStats={soldStats} />
				<SoldTrendsPrice buildingRes={props.buildingRes} statsRes={props.statsRes} soldStats={soldStats} />
		   </Row>
		  }
		  {listingStats &&
		   <Row>
		   	  <ListingTrends buildingRes={props.buildingRes} statsRes={props.statsRes} listingStats={listingStats} />
		   	  <ListingTrendsPrice buildingRes={props.buildingRes} statsRes={props.statsRes} listingStats={listingStats} />
		   </Row>
		  }
		   
		</div>
	    
    )
    
	
};

export default connect(state => ({
  user: state.user,
}))(withRouter(Sales));