import React, { PureComponent } from 'react';
import {
  Card, CardBody, Row, Col, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CacheOffIcon from 'mdi-react/LayersOffOutlineIcon';
import CacheOnIcon from 'mdi-react/LayersOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

export default class CacheComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    icon: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    color: '',
    icon: false,
    className: '',
    alertId:''
  };

  constructor(props) {
    super(props);
    
    const { cacheId, minimizeText } = this.props;

    this.state = {
     	cacheOff: localStorage.getItem(cacheId) && localStorage.getItem(cacheId) ===  'true' ? true : false,
    };
  }

  
  _toggleView = () => {
		const { cacheOff } = this.state;
		const { cacheId } = this.props;
	
		this.setState( { cacheOff : !cacheOff } )		
		localStorage.setItem(cacheId, !cacheOff);

		
  }

  render() {
 
    
    const { cacheOff } = this.state;

    
    
   
	return (
		 <Row>
		  <Col md={12} lg={12}>
		    <Card>
		      <CardBody>
		        <div className="card__title">
		          <h5 className="bold-text">{'Application Settings (Admin Only)'}</h5>
		          <div className="page-subhead subhead"></div>
		          <hr/>
		        </div>
		        <Row>
		        	
		        	<Col md={12} lg={12}>		
		        		<h6 className="bold-text">{`${cacheOff ? "Cache Disabled" : "Cache Enabled"}`}</h6>
		        		        		 
						<CacheOffIcon color="#DBE0E8" />
				        <Tooltip title={`${cacheOff ? "Enable Cache" : "Disable Cache"}`}>

					    <PurpleSwitch 
					        checked={!cacheOff}
					    	onChange={this._toggleView}
					    />
					    </Tooltip>					      	
					    <CacheOnIcon color="#DBE0E8" />	
					</Col>
				</Row>
				</CardBody>
		    </Card>
		  </Col>
		</Row>
	);
    

   
  }
}
