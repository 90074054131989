import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

const rows = [
 {
    id: 'status', disablePadding: false, label: 'Status',
  },
  {
    id: 'address_2_normalized', disablePadding: false, label: 'Unit #',
  },
 
  {
    id: 'bedrooms', disablePadding: false, label: 'Beds',
  },
  {
    id: 'baths', disablePadding: false, label: 'Baths',
  },
  
  {
    id: 'last_listed_sale_price_all', disablePadding: false, label: 'Last Ask Price',
  },
  // {
  //   id: 'div(sub(offering_price_initial, last_listed_sale_price_all), offering_price_initial)', disablePadding: false, label: 'Ask vs. OP',
  // },
  {
    id: 'div(last_listed_sale_price_all,sq_ft)', disablePadding: false, label: 'Last Ask PPSF',
  },
  {
    id: 'last_sold_price', disablePadding: false, label: 'Sold Price',
  },
  {
    id: 'div(last_sold_price,sq_ft)', disablePadding: false, label: 'Sold PPSF',
  },
   {
    id: 'last_sold_date', disablePadding: false, label: 'Sold Date',
  },
  {
    id: 'offering_sq_ft', disablePadding: false, label: 'Size',
  },
  {
    id: 'offering_exterior_sq_ft', disablePadding: false, label: 'Exterior',
  },
/*
  
  {
    id: 'offering_monthly_carrying_charges', disablePadding: false, label: 'Carrying Cost',
  }
 
*/
   
];

if(isMobile) rows.splice(0,1);

class UnitsSearchTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          
          <TableCell padding="checkbox">
            <Checkbox
              className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
                className="material-table__sort-label"
                dir="ltr"
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(UnitsSearchTableHead);
