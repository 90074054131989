import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';


import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import AddToBuyersListButton from '../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../shared/components/buttons/ShareButton';

import ProductCard from './../../SingleBuilding/components/Profile/components/ProfileCard';
import ShowMap from './../../../shared/components/map';


import InventoryStack from './components/InventoryStack';

import BuildingProductCard from './../../SingleBuilding/components/Profile/components/ProfileCard';
import BuildingAmenities from './../../SingleBuilding/components/Profile/components/BuildingAmenities';

import ShareTopBar from './../../../shared/components/ShareTopBar'
import ShareContact from './../../../shared/components/ShareContact'
import ShareFooter from './../../../shared/components/ShareFooter'

import BrandedTopBar from './../../../shared/components/BrandedTopBar'
import BrandedFooter from './../../../shared/components/BrandedFooter'

import { withOktaAuth } from '@okta/okta-react';

import { Publisher } from './../../../shared/pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	_privateKey,
	propertyExportFL
} from '../../../shared/helpers/utils';

const apiURL = process.env.API_ROOT + '/query/';
const oktaToken = process.env.OKTA_TOKEN;


class StackedUnits extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'status',
      statsRes: null,
      fetchInProgress: false,
      buildingRes: false,
      userRes:false
    };
    this.buildingSlug = false;
	this.urlState = {};
  }
  
  componentDidMount() {
	  
	const urlLink = CryptoJS.AES.decrypt(atob(this.props.match.params.encrypted_key), _privateKey).toString(CryptoJS.enc.Utf8); 
	
	const queryParts = urlLink.split('?');
	const pathParts = queryParts[0].split('/');
	
	this.buildingSlug = pathParts[0] ? pathParts[0] : false;
	this.urlState = queryParts[1] ? queryString.parse(queryParts[1]) : false;
	
   this._getBuildingData();
   if(this.urlState.ap){
	   this._getUserData(this.urlState.ap);
   }
   Publisher.publish(`track.shareView.unit`, {building: this.buildingSlug});

	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {		
	 
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  
  _getUserData = (key) => {
     // console.log('_getBuildingData called', this.state)
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  	  
      let headers = {
		  "Authorization" : `SSWS ${oktaToken}`,
	  }
	  let userData = {
			url: `https://dev-397854.okta.com/api/v1/users/${key}`,
			method: "get",
			headers:headers
	  };
	  
	  
	  _axiosCall(userData)
	    .then(res => {
	      console.log("_getUserData res back ,", res);
	      this.setState({
		      userRes:res.profile,
		      fetchInProgress: false
	      })

	  })
	  .catch(error => {
	    console.log("_getUserData error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

  
  _getBuildingData = () => {
     // console.log('_getBuildingData called', this.state)
      if (!this.state.fetchInProgress) {
		  this.setState(prevState => ({ fetchInProgress: true, }));
      }
	  
	  let buildingData = {
			url: process.env.API_ROOT + `/buildings/${this.buildingSlug}?type=minimum`,
			method: "get"
	  };
	  
	  _axiosCall(buildingData)
	    .then(res => {
	     // console.log("_getBuildingData building res back ,", res);

	    if(res && res && res.building && res.building[0]) {
	      this.setState({
	      	buildingRes: res.building[0],
	      	fetchInProgress: false
	      });
	      this._getBuildingOPData(res.building[0]);

	    }
	  })
	  .catch(error => {
	    console.log("BlBuildingPage error ", error);
	    this.setState({
	      fetchInProgress: false
	    })
	  });
	}

   _getBuildingOPData = async (buildingRes) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const addressQ = buildingRes.alternate_addresses.join('" OR "');
		
		const q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}")) AND ((((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\") OR (-offering_verified_status:active AND source_organizations:(CDEC ADEC) AND property_type:(*condo* \"resident manager unit\" *coop* *affordable*)))  AND -address_2_normalized:"") OR current_status:active)`;

		let axiosData = {
			url: apiURL + "we3-properties?cache=60",
			method: "post",
			query: {
				q: q,
				fq: ["-deleted:true", "-address_2_normalized:\"\""],
				sort: "floor asc, address_2_normalized asc",
				fl : [...propertyExportFL, ...["floor", "line", "offering_submitted_date", "div(last_sold_price, sq_ft)", "div(last_listed_sale_price_all, sq_ft)", "div(last_contract_price, sq_ft)"]],
				wt: "json",
				rows: 999,
				"json.facet": {
					"avg_price": "avg(offering_price)",
					"avg_sq_ft": "avg(offering_sq_ft)",
					"low_bed": "min(offering_bedrooms)",
					"unit_mix" : {
						"type" : "terms",
						"field" : "offering_bedrooms",
						"facet": {
							"avg_unit_size" : "avg(offering_sq_ft)",
							"med_unit_size": "percentile(offering_sq_ft,25,50,75)",
							"non_zero_price": {
								"type": "query",
								"q": "last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *]",
								"facet": {
									"avg_unit_size" : "avg(last_listed_sale_price_all)",
									"med_unit_size": "percentile(last_listed_sale_price_all,25,50,75)",
									"avg_fees" : "avg(offering_monthly_fees)",
									"med_fees": "percentile(offering_monthly_fees,25,50,75)",
									"avg_taxes" : "avg(offering_monthly_taxes)",
									"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
									"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
									"min_price": "min(last_listed_sale_price_all)",
									"max_price": "max(last_listed_sale_price_all)",
									"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
								},
							},
							"by_status" : {
								"type" : "terms",
								"field" : "offering_status"
							},
							
						}
					},
					"non_zero_price": {
						"type": "query",
						"q": "last_listed_sale_price_all:[1 TO *] AND sq_ft:[100 TO *]",
						"facet": {
							"low_price": "min(last_listed_sale_price_all)",
							"high_price": "max(last_listed_sale_price_all)",
							"average_price": "avg(last_listed_sale_price_all)",
							"average_sq_ft": "avg(offering_sq_ft)",
							"sum_price": "sum(last_listed_sale_price_all)",
							"low_sq_ft": "min(div(last_listed_sale_price_all, sq_ft))",
							"high_sq_ft": "max(div(last_listed_sale_price_all, sq_ft))",
							"low_bed": "min(offering_bedrooms)",
							"high_bed": "max(offering_bedrooms)",
							"by_neighborhood" : {
								"type" : "terms",
								"field" : "neighborhood",
								"sort" : "avg_ppsf desc",
								"limit" : 20,
								"facet" : {
									"avg_price": "avg(offering_price)",
									"avg_ppsf": "avg(div(offering_price, sq_ft))",
								}
							},
							
						}
					},
					"total_sold": {
						"type": "query",
						"q": "offering_price:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)",
						"facet": {
							"low_price": "min(offering_price)",
							"high_price": "max(offering_price)",
							"average_price": "avg(offering_price)",
							"average_sq_ft": "avg(offering_sq_ft)",
							"sum_price": "sum(offering_price)",
							"low_sq_ft": "min(offering_sq_ft)",
							"high_sq_ft": "max(offering_sq_ft)",
							"low_bed": "min(offering_bedrooms)",
							"high_bed": "max(offering_bedrooms)",
							"by_neighborhood" : {
								"type" : "terms",
								"field" : "neighborhood",
								"sort" : "avg_ppsf desc",
								"limit" : 20,
								"facet" : {
									"avg_price": "avg(offering_price)",
									"avg_ppsf": "avg(div(offering_price, sq_ft))",
								}
							},
							
						}
					},
					"total_sellout": {
						"type": "query",
						"q": "offering_price:[1 TO *]",
						"facet": {
							"low_price": "min(offering_price)",
							"high_price": "max(offering_price)",
							"average_price": "avg(offering_price)",
							"average_sq_ft": "avg(offering_sq_ft)",
							"sum_price": "sum(offering_price)",
							"low_sq_ft": "min(offering_sq_ft)",
							"high_sq_ft": "max(offering_sq_ft)",
							"low_bed": "min(offering_bedrooms)",
							"high_bed": "max(offering_bedrooms)",
							"by_neighborhood" : {
								"type" : "terms",
								"field" : "neighborhood",
								"sort" : "avg_ppsf desc",
								"limit" : 20,
								"facet" : {
									"avg_price": "avg(offering_price)",
									"avg_ppsf": "avg(div(offering_price, sq_ft))",
								}
							},
							
						}
					},
					"first_sold": {
						"type": "query",
						"q": "last_sold_date:[1000 TO *]",
						"facet": {
							"first_sold_date" : "min(last_sold_date)"
						},
					},
					"by_status" : {
						"type": "query",
						"q": "property_status:sponsor",
						"facet": {
							"sponsor": {
								"type" : "terms",
								"field" : "current_status"
							},													
						}	
						
					},
					"resale":{
						"type": "query",
						"q": "property_status:resale",
					},
					"by_floor" : {
						"type" : "terms",
						"field" : "floor",
						"limit": 999,
						"facet": {
							"non_zero_price": {
								"type": "query",
								"q": "last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *]",
								"facet": {
									"avg_unit_size" : "avg(last_listed_sale_price_all)",
									"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
									"min_price": "min(last_listed_sale_price_all)",
									"max_price": "max(last_listed_sale_price_all)",								},
							},
						}
					},
					"discount" : {
						"type": "query",
						"q": "last_listed_sale_price_all:[100 TO *] AND last_sold_price:[100 TO *]",
						"facet": {
							"min" : "min(div(sub(last_sold_price, last_listed_sale_price_all), last_listed_sale_price_all))",
							"max" : "max(div(sub(last_sold_price, last_listed_sale_price_all), last_listed_sale_price_all))"
						}
					}
					
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				this.setState({statsRes: res.facets, unitsRes: res.docs});
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
  
	
  render() {
    const { rtl } = this.props;
    const { buildingRes, statsRes, unitsRes, urlState, userRes } = this.state;
    
    const defaultUser = {
	  title: 'Real Estate Agent',
	  avatar: `${process.env.PUBLIC_URL}/img/account.png`,
	  role: 'buyer',
    }	
    
    const user = Object.assign({}, defaultUser, userRes);
    
     if(!statsRes || !buildingRes){
	    return <LinearLoading />;
    }
    
    const _boro = buildingRes.borough;
	const _hood = buildingRes.neighborhood[0];
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	

	
    return (
	    <div className={`share__wrapper ${this.urlState.theme}`}>
	    	{this.urlState.theme ?
		    	<BrandedTopBar theme={this.urlState.theme} user={user} urlState={this.urlState} />
		    	:
				<ShareTopBar user={user} urlState={this.urlState} />
			}
		    <Container className="share dashboard">
			    <Row>
				    <Col md={12} lg={12} xl={12}>
				        <Card>
				          <CardBody className="no-border no-padding mg-btm-0">          	
				            <div className="card__title" key={buildingRes.id}>							  
				               <h3 className="page-title">{buildingNameOrAdr}</h3>
				               {isMobile ?
					               <Fragment>
					              <h3 className="page-subhead subhead">			        	
										{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
										{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
								  </h3> 
								  
								  </Fragment>
					           :
							      <h3 className="page-subhead subhead">			        	
											{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>}{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
							      </h3>
						       }
						       </div>
				          </CardBody>
				        </Card>
				      </Col>
				</Row>
				 <ShareContact  user={user} urlState={this.urlState} />
				<Row>
			      <InventoryStack {...this.state} />
			    </Row>
			    
			</Container>    
			<Container id="building" className="dashboard share mt-5 pt-5">
				<Row>
			      <Col md={12} >
			        <h3 className="page-title">About {buildingNameOrAdr}</h3>
			        <h3 className="section-title section-title--not-last subhead mb-3"> </h3>
			      </Col>
			    </Row>
				<Row>
			      <ProductCard {...this.state} share />
			    </Row>
			   
			   
			    
			    <Row>
			      <Col md={12}>
			        <h3 className="section-title section-title--not-last">Amenities</h3>
			        <h3 className="section-title section-title--not-last subhead"> </h3>
			        <hr/>
			      </Col>
			    </Row>
			    <Row>
			    	<Col md={12}>
			    	 <BuildingAmenities {...this.state} />
			    	 </Col>
			    </Row>
			    <Row>
			      <Col md={12}>
			        <h3 className="section-title section-title--not-last">Location</h3>
			        <h3 className="section-title section-title--not-last subhead"> </h3>
			       
			       
			    	<hr/>
			    	<Row>
			    	 {/*<Col xl={6} lg={12} className="mb-2">
			    	 {buildingRes.description && 
			               <p className="typography-message">
			               {buildingRes.description[0].replace(/Building\n/g, '').replace(/Residences\n/g, '\n').replace(/Amenities\n/g, '\n').replace(/\n\n/g, '\n').replace(/^\n/, '').split('\n').map((item, key) => {
								return <React.Fragment>{item}<br/></React.Fragment>
							})}
			               </p>
			           }
			    	
			        </Col>*/}
			         <Col xl={12} lg={12} >
			           <ShowMap item={buildingRes} height={`360px`} />
			         </Col>
					 </Row>
				  </Col>
				</Row>
			    

			</Container>
			
			{this.urlState.theme ?
		    	<BrandedFooter theme={this.urlState.theme} user={user} urlState={this.urlState} />
		    	:
				<ShareFooter />
			}
			

		</div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(withOktaAuth(StackedUnits))));
