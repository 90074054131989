import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import buyersListActions from '../../../redux/actions/buyersListActions';

import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon';
import HeartIcon from 'mdi-react/HeartIcon';
import TrashCanIcon from 'mdi-react/TrashCanOutlineIcon';

import { Publisher } from './../../pubsub/publisher.js';



class AddToBuyersListButton extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editMyBuyersList: PropTypes.func,
      completeMyBuyersList: PropTypes.func,
      deleteMyBuyersList: PropTypes.func,
      addMyBuyersList: PropTypes.func,
      togglePriorityFilter: PropTypes.func,
    }).isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    buildingKey: PropTypes.string,
    units: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = { buttonText: 'Save', saved:false };
  }
  
  componentDidMount() {
	  	
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
	  
	 // console.log(this.props)
  }
    
  handleSubmit(event, buildingKey, unitKey, units) {
	event.stopPropagation();
    event.preventDefault();
    
    const { user } = this.props;
    const { addMyBuyersList, saveBuilding, saveUnit } = this.props.actions;
    
    if(units){
	    //addMyBuyersList(buildingKey, units, user);
	    saveUnit(unitKey, buildingKey, user);
	    Publisher.publish('track.save.unit', {building: buildingKey, unit:unitKey});
    }else{
	    saveBuilding(buildingKey, user);
	    Publisher.publish('track.save.building', {building: buildingKey});
    }
  		
    
    //this.setState({ buttonText: 'Saved', saved:true })
    
  }
 
 


  render() {
    const {
      actions, t, theme, rtl, buildingKey, units, buyersLists, savedBuildings, savedUnits, unitKey, unitSave, removeOnly
    } = this.props;
    
    const saved = unitSave ? 
    			savedUnits.findIndex(item => (item.key === unitKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 
    			: 
    			savedBuildings.findIndex(item => (item.key === buildingKey && (typeof item.deleted === 'undefined' || item.deleted === false))) > -1 ;
    return (
		    <Tooltip title={saved ? `Removed from My ${units ? 'Units' : 'Buildings'} ` : `Save to My ${units ? 'Units' : 'Buildings'}`}>
		        <IconButton
			        onClick={(e) => {this.handleSubmit(e, buildingKey, unitKey, units)}}
			         variant=""
			         color=""
			         className={`material-table__toolbar-button save-buyerslist`}		         
			     >	{saved ? removeOnly ? <TrashCanIcon /> : <HeartIcon /> : <HeartOutlineIcon />}
			     </IconButton>				 
		  	</Tooltip>
    );
  }
}

function mapStateToProps(state) {
	//console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		savedBuildings: Array.isArray(state.buyersLists.savedBuildings) ? state.buyersLists.savedBuildings.slice() : [],
	  		savedUnits: Array.isArray(state.buyersLists.savedUnits) ? state.buyersLists.savedUnits.slice() : []  
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddToBuyersListButton));
