import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import { _hasPermission	} from "../../../shared/helpers/access";

const PipelineCheckbox = withStyles({
  root: {
    color: "#56E39F",
    '&$checked': {
      color: "#56E39F",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NewDevCheckbox = withStyles({
  root: {
    color: "#FF206E",
    '&$checked': {
      color: "#FF206E",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ResaleCondoCheckbox = withStyles({
  root: {
    color: "#780F9E",
    '&$checked': {
      color: "#780F9E",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ResaleCoopCheckbox = withStyles({
  root: {
    color: "#28C2FF",
    '&$checked': {
      color: "#28C2FF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SingleFamCheckbox = withStyles({
  root: {
    color: "#FFD23F",
    '&$checked': {
      color: "#FFD23F",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const MultiFamCheckbox = withStyles({
  root: {
    color: "#FF691E",
    '&$checked': {
      color: "#FF691E",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function LegendToggle(props) {
	
  const { propertyTypes, setPropertyTypeFilter, zoom, user } = props;
  const [state, setState] = React.useState({
    residential: true,
    commercial: true,
    mixed: true
  });

  const handleChange = (event) => {
    setPropertyTypeFilter(event.target.name);
  };
  
  const disabled = zoom < 18;

  return (
	<div className="legend-toggle">
	    <FormGroup row>
        {_hasPermission(user, 'sponsor-buildings') ?
        <>
          <Tooltip title={"Click to toggle pipeline buildings"}>	
	      <FormControlLabel
	        control={<PipelineCheckbox checked={propertyTypes.indexOf("pipeline")>-1} onChange={handleChange} name="pipeline" />}
	        label="Pipeline"
	      />
          </Tooltip>
          <Tooltip title={"Click to toggle new development buildings"}>	
	      <FormControlLabel
	        control={<NewDevCheckbox checked={propertyTypes.indexOf("new-dev")>-1} onChange={handleChange} name="new-dev" />}
	        label="New Dev"
	      />
          </Tooltip>
      </>:null}
          <Tooltip title={"Click to toggle resale condo buildings"}>	
	      <FormControlLabel
	        control={<ResaleCondoCheckbox checked={propertyTypes.indexOf("resale-condo")>-1} onChange={handleChange} name="resale-condo" />}
	        label="Resale Condo"
	      />
          </Tooltip>
          <Tooltip title={"Click to toggle resale co-op buildings"}>	
          <FormControlLabel
            control={<ResaleCoopCheckbox checked={propertyTypes.indexOf("resale-coop")>-1} onChange={handleChange} name="resale-coop" />}
            label="Resale Co-Op"
          />
          </Tooltip>
          <Tooltip title={disabled ? "Zoom in to see single-family homes" : "Click to toggle single-family"}>	
          <FormControlLabel
            control={<SingleFamCheckbox checked={!disabled && propertyTypes.indexOf("single-family")>-1} onChange={handleChange} name="single-family" />}
            label="Single-Family"
            disabled={disabled}
          />
          </Tooltip>
          <Tooltip title={disabled ? "Zoom in to see multi-family homes" : "Click to toggle multi-family"}>	
          <FormControlLabel
            control={<MultiFamCheckbox checked={!disabled && propertyTypes.indexOf("multi-family")>-1} onChange={handleChange} name="multi-family" />}
            label="Multi-Family"
            disabled={disabled}
          />
          </Tooltip>
	    </FormGroup>
	</div>
  );
}
