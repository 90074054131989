import React, { PureComponent, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container } from 'reactstrap';
import NewsItem from './NewsItem';
import { _axiosCall, _buildingIDs } from '../../../shared/helpers/apicalls';
import moment from 'moment';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import LinearLoading from '../../../shared/components/LinearLoading';
import classnames from 'classnames';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';


import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import { Publisher } from '../../../shared/pubsub/publisher.js';


import AlertIcon from 'mdi-react/InformationOutlineIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	mpndCohortQuery,
	pipelineCohortQuery,
	resaleCohortQuery,
	getCohortQuery
} from "../../../shared/helpers/utils";
import { _hasPermission	} from "../../../shared/helpers/access";
const apiURL = process.env.API_ROOT + '/query/';
const useStyles = makeStyles((theme) => ({
  root: {
	display: 'flex',
	flexDirection: 'column',
	marginLeft:"-8px",
	alignItems: 'left',
	'& > *': {
	  margin: theme.spacing(1),      
	},
	
  },
  button:{
	'&.selected' :{
		background:"#EDDEF5"
	},
	'width': 110
  }
}));

const SelectEventTypes = ({activeTab, eventType, setEventType, changeType, setChangeType}) => {
	  const classes = useStyles();
	  
	  if(["listings", "contracts", "closings", "price-changes"].indexOf(activeTab) < 0) return null;
	  return (
		  <div>
		  
		  <div className={"mt-3"}>
		  
			   <ButtonGroup size="small" aria-label="small button group">							        
				   <Tooltip title="Show all activity" aria-label="Show all activity">
					 <Button onClick={()=> setEventType("all")} className={eventType === "all" ? classNames(classes.button, "selected") : classNames(classes.button)}>All</Button>
				 </Tooltip>
				 <Tooltip title="Show sales activity" aria-label="Show sales activity">
					 <Button onClick={()=> setEventType("sale")} className={eventType === "sale" ? classNames(classes.button, "selected") : classNames(classes.button)}>For Sale</Button>
				 </Tooltip>
				 <Tooltip title="Show rental activity" aria-label="Show rental activity">
					 <Button onClick={()=>  setEventType("rent")} className={eventType === "rent" ? classNames(classes.button, "selected") : classNames(classes.button)}>For Rent</Button>
					</Tooltip>				
			   </ButtonGroup>
			   {activeTab == "price-changes" ? 
			   <ButtonGroup size="small" aria-label="small button group" className={"ml-5"}>							        
					  <Tooltip title="Show all activity" aria-label="Show all activity">
						<Button onClick={()=> setChangeType("all")} className={changeType === "all" ? classNames(classes.button, "selected") : classNames(classes.button)}>All</Button>
					</Tooltip>
					<Tooltip title="Show price drops" aria-label="Show price drops">
						<Button onClick={()=> setChangeType("decrease")} className={changeType === "decrease" ? classNames(classes.button, "selected") : classNames(classes.button)}>Price Drops</Button>
					</Tooltip>
					<Tooltip title="Show price hikes" aria-label="Show rental hikes">
						<Button onClick={()=>  setChangeType("increase")} className={changeType === "increase" ? classNames(classes.button, "selected") : classNames(classes.button)}>Price Hikes</Button>
					   </Tooltip>				
				  </ButtonGroup>
				: null 
			}
		   </div>
		   <hr/>
		  </div>
	  )
  }


class TimelineHistory extends PureComponent {  
  state = {
		rowsPerPage: 25,
		fetchInProgress: false,
		clearInput: false,
		start: 0,
		data: [],
		noMoreResults:false,
		activeTab : this.props.match && this.props.match.params.subtab ? this.props.match.params.subtab : 'summary',
		eventType : "all",
		changeType : "all"
  }
  
  fqMap = {
	"summary" : !this.props.buildingRes ? '(topic:(stories OR offeringplans OR coo)) OR (topic:permits AND event_tags:nb)' : '(topic:(stories OR offeringplans OR coo OR listings OR transactions)) OR (topic:permits AND event_tags:nb)',
	'articles' : 'topic:stories',
	'construction' : 'topic:(offeringplans OR coo) OR (topic:permits AND event_tags:nb)',
	'listings' : !this.props.buildingRes ?  'topic:listings AND event_tags:just-listed AND -event_tags:rent' : 'topic:listings AND event_tags:just-listed',
	'contracts' : 'topic:listings AND event_tags:contract-signed AND -event_tags:rent',
	'sales' : 'topic:transactions',
	'price-changes' : 'event_tags:(price-decrease price-increase)'
  }
	
  componentDidMount() {
	  
  	  this._getNews();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  if(this.state.start !== prevState.start ){
		  this._getNews();
	  }
	  if(this.state.activeTab !== prevState.activeTab ||
		 this.state.eventType !== prevState.eventType ||
		 this.state.changeType !== prevState.changeType){
		 this._getNews(true);		
	  }
	   
  }
  
  _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
  }
  
  _setActiveTab(tab){
	  const { buildingRes } = this.props;
	  const newURL = buildingRes ? _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/news/${tab}` : '') : `/news/${tab}`;
	  		  //props.history.replace(buildingURL);
	  window.history && window.history.pushState({}, null, newURL);
      Publisher.publish(`track.NewsView.${tab}`, {});
	  this.setState({activeTab:tab, data:[]})
  }
  
  
  
  _getNews = async (reset) => {
	  	if(this.state.fetchInProgress === true){
		  	return false;
	  	}
	  	this.setState({ fetchInProgress: true });
		if(reset){
			this.setState({data:[], start: 0})
		}
	  	const { buildingRes, user } = this.props;
	  	const { activeTab, eventType, changeType } = this.state;
	   
	    const fq = [		    
		    "hierarchy:[0 TO 2]",		    
		    `time:[* TO NOW]`,
		     "-(time:[2021-09-09T13:00:00Z TO 2021-10-09T20:00:00Z] AND ((foreign_id:REBNY-LISTING-RPLU* OR foreign_id:REBNY-LISTING-PDES*) AND slugs:*elliman*))",
			 this.fqMap[activeTab]
	    ]	  
	    	     
	    let q = '*:*';
	    if(buildingRes){
		     const addressQ = buildingRes.alternate_addresses.join('" OR "');
		     q = `(full_street_address:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}"))`;
		     if(buildingRes.building_slugs && buildingRes.building_slugs.length>0){
			     q += ` OR building_slugs:(${buildingRes.building_slugs.join(' ')})`;
			     
		     }
		     //fq[0] = '(topic:(stories OR offeringplans OR coo OR listings OR transactions)) OR (topic:permits AND event_tags:nb)';
		     
		}else{
		
			
			fq.push(`{!join from=key fromIndex=we3-buildings to=building}(${getCohortQuery(this.props.user, true)})`);
			
		}
		if(eventType == "sale"){
			fq.push(`-event_tags:rent`)
		}else if(eventType == "rent"){
			fq.push(`event_tags:rent`)
		}
		if(changeType == "decrease" && activeTab == "price-changes"){
			fq.push(`event_tags:price-decrease`)
		}else if(changeType == "increase"){
			fq.push(`event_tags:price-increase`)
		}
	    	    
		const axiosData = {
			url: apiURL + "we3-activities-3?cache=60",
			method: "post",
			query: {
				q,
				fq,
				sort: activeTab === "articles" ? "time desc" : "time desc",
				wt: "json",
				rows: this.state.rowsPerPage,
				start: reset ? 0 : this.state.start
			}
		};

		const formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					
					this.setState( { data: [...this.state.data, ...res.docs], fetchInProgress: false })

				
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false })
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
	};
	
	_setEventType = (type) => {
		this.setState({eventType:type})
	}
	_setChangeType = (type) => {
		this.setState({changeType:type})
	}

  render() {
	  const { user } = this.props;
	  const { data, fetchInProgress, activeTab, eventType, changeType } = this.state;	  
	  if(!data ){
			return null;
	  }
	  
	  
 
	  return (
			  <Col md={12}>
			
			  <div className={`tabs ${!this.props.buildingRes ? "tabs--bordered-bottom" : ""}`}>
		            <div className="tabs__wrap">
		                <Nav tabs>
		                   <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'summary' })}
		                      onClick={() => {
		                        this._setActiveTab('summary');
		                      }}
		                    >
		                      <span className="tab-title">Summary</span>
		                    </NavLink>
		                  </NavItem>
		
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'articles' })}
		                      onClick={() => {
		                        this._setActiveTab('articles');
		                      }}
		                    >
		                      <span className="tab-title">Articles</span>
		                    </NavLink>
		                  </NavItem>
		                  {_hasPermission(user, 'sponsor-buildings') &&
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'construction' })}
		                      onClick={() => {
		                        this._setActiveTab('construction');
		                      }}
		                    >
		                      <span className="tab-title">Construction</span>
		                    </NavLink>
		                  </NavItem>
					  	  }
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'listings' })}
		                      onClick={() => {
		                        this._setActiveTab('listings');
		                      }}
		                    >
		                      <span className="tab-title">Listings</span>
		                    </NavLink>
		                  </NavItem>
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'contracts' })}
		                      onClick={() => {
		                        this._setActiveTab('contracts');
		                      }}
		                    >
		                      <span className="tab-title">Contracts</span>
		                    </NavLink>
		                  </NavItem>
		                  
		                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'sales' })}
		                      onClick={() => {
		                        this._setActiveTab('sales');
		                      }}
		                    >
		                      <span className="tab-title">Sales</span>
		                    </NavLink>
		                  </NavItem>
		                  <NavItem>
							  <NavLink
								className={classnames({ active: activeTab === 'price-changes' })}
								onClick={() => {
								  this._setActiveTab('price-changes');
								}}
							  >
								<span className="tab-title">Price Changes</span>
							  </NavLink>
							</NavItem>
						 
		                </Nav>
		              </div>
					  <div>
					  <SelectEventTypes activeTab={activeTab} eventType={eventType} setEventType={this._setEventType.bind(this)} changeType={changeType} setChangeType={this._setChangeType.bind(this)} />
						 
					  </div>
		        </div>
				
		        {fetchInProgress && data.length <=0 ? 
			        <Row>
						<LinearLoading />
					</Row>
			
				: (!fetchInProgress && data.length <= 0 ) ?
		

		        <div className={`email-confirmation`} >
		          <div className="email-confirmation__icon">
		            <AlertIcon size={50} color={`#D6BAE8`} />			            
		          </div>
		          <h3 className="email-confirmation__title">No News Yet</h3>
		          <p className="email-confirmation__sub">Marketproof data is updated in real time.  Check back soon.</p>
		          
		        </div>
		      
		        :
		        
		        <Fragment>
					
				    <div className="timeline">
				    {/*<span className="timeline__start"/>*/}
				    {data.map(item => {
					    try{			
							const data = JSON.parse(item.data);
							item.data = data;	
						}catch(e){
							//ToDo
						}
					    
					    return <NewsItem item={item} />
						       				    
				    })}
				    </div>
				    <div className="timeline_load">
					    <Tooltip title="Load more news">
					        <Button
						        onClick={(e) => {this._loadMore(e)}}
						         variant="contained"
						         color="news"
						         className={`timeline__load-more`}		         
						         startIcon={<LoadMoreIcon />}
						         disabled={this.state.fetchInProgress || this.state.noMoreResults}
						     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "That's All The News" : "Load More"}`}
						     </Button>				 
					  	</Tooltip>
				    </div>
			    </Fragment>
			    }
			   
			  </Col>
	    )
   };
}

export default withRouter(TimelineHistory);
