import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TagOutlineIcon from 'mdi-react/BorderNoneIcon';
import ToolTip from '../../../../../shared/components/ToolTip';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	setURLFromState
} from "../../../../../shared/helpers/utils";

const AveragePPSF = (props) => {

  if(!props.facetData) return null;
  
  const newState = Object.assign({}, props); 	
	 if(newState.subFilters && newState.subFilters.current_status){
		 	delete( newState.subFilters.current_status);
	 }
 	 const baseURL = setURLFromState({}, newState, true);


  const ppsf = props.facetData.non_zero_price && props.facetData.non_zero_price.avg_ppsf ? (props.facetData.non_zero_price.avg_ppsf).toFixed(0) : false;
  return (
	  	<Card>
	  	<Link to={`/inventory${baseURL}&current_status=available`}>
	      <CardBody className="dashboard__booking-card card__link">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            ${_formatNumber(ppsf)}
	          </h5>
	          <TagOutlineIcon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Avg Offering PPSF
	        	<ToolTip 
	        		title={`Based on prices from offering plan schedule A`} 
	        		body={<span>Average offering price per square foot is based the latest available offering plan schedule A from {props.groupedCount} Marketproof Vetted buildings.<br/><br/>This metric tracks all units in the offering plan regardless of whether the unit was listed or sold.</span>}
	        	/>
	        </h5>
	      </CardBody>
	    </Link>
	    </Card>
	 )
};

export default AveragePPSF;
