const searchActions = {
  addTag(tag) {
    return {
      type: 'ADD_TAG',
      tag
    };
  },
  removeTag(tag) {
    return {
      type: 'REMOVE_TAG',
      tag
    };
  },
  removeMapTag(tag){
    return {
      type:'REMOVE_MAP_TAG',
      tag
    }
  },
  clearTags(){
	return {
      type: 'CLEAR_TAGS',
    };
  },
  clearAttributes(){
	return {
      type: 'CLEAR_ATTRIBUTES',
    };
  }
 
};
export default searchActions;
