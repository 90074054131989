import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import CardSection from './CardSection';
import '../Payment.scss'

const apiUrl = process.env.API_ROOT + '/buyerslist/';
//const apiUrl = 'http://localhost:8000/buyerslist/';


export default function UpdateCCForm(props) {
  const stripe = useStripe();
  const elements = useElements(); 
  const { user, selectedPlan, updateUserProfile, customer, snackOpen, toggleRefresh } = props;
  const [ fetchInProgress, setFetchInProgress ] = useState(false);
  const [ subscription, setSubscription ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ modal, setModal ] = useState(false);
  
  
   const stripePaymentMethodHandler = async (result) => {
	   
	  console.log(result);
	   if(result && result.paymentMethod 
	  	&& result.paymentMethod.card 
	  	&& result.paymentMethod.card.funding 
	  	&& result.paymentMethod.card.funding === "prepaid"){
		  	
		  	
		  	console.log("here");
		  	setError("Prepaid cards are not accepted, please try again.");
	  
	  }else if (result.error) {
	    // Show error in payment form
	    
	    if(result.error.message){
		    setError(result.error.message);
	    }
	    
	  } else {
		setFetchInProgress(true);
		console.log(result);
	    // Otherwise send paymentMethod.id to your server
	    fetch(`${apiUrl}stripe/update-billing`, {
	      method: 'post',
	      headers: {'Content-Type': 'application/json'},
	      body: JSON.stringify({
	        customer: customer.id,
	        payment_method: result.paymentMethod.id
	      }),
	    }).then(function(billing) {
		  console.log(billing);
		  
		  billing.json().then(billingResult => {
			  
			  console.log(billingResult);
			  if(billingResult.defaultPayment && billingResult.defaultPayment.id){
				  setFetchInProgress(false);
				  setError(false);
				  snackOpen('Your Credit Card Has Been Updated.', 'success');
				  setModal(false);
				  toggleRefresh();

			  }else{
				  setFetchInProgress(false);
				  if(billingResult.raw && billingResult.raw.message){
					  setError(billingResult.raw.message + ' Please try again');
				  }else{
					  setError('Credit card update failed, pease contact us for assistance');
				  }
				  
				  
			  }
			  
			 
		  })	      
	      
	    }).then(function(customer) {
	      // The customer has been created
	    });
	  }
   }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    event.stopPropagation();
    if(fetchInProgress) return null;
    
    

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email,
      },
    });

    stripePaymentMethodHandler(result);
    
  };
  
  const toggle = () => {
	  setModal(!modal);
  }
  
  const modalClass = classNames({
      'modal-dialog--colored': false,
      'modal-dialog--header': true,
   });
 
  return (
	<div>
	    <Button color={'primary'} onClick={toggle}>Update Credit Card</Button>
	    <Modal
	      isOpen={modal}
	      toggle={toggle}
	      modalClassName={`ltr-support`}
	      className={`modal-dialog--primary ${modalClass}`}
	    >
	      
		      <div className="modal__header">
		        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
		        <h4 className="text-modal  modal__title">Update Credit Card</h4>
		      </div>
		      <form onSubmit={handleSubmit}>
		      <div className="modal__body">
		        <p>Future payments will be automatically charged to your new credit card.</p>
		      	<div className="cc-title">Enter New Credit Card Information</div>
		      	<div className="card-input">		        
			      <CardSection />
			    </div>
			      {error && <div className="payment-error">{error}</div>}			      
		      </div>
		      
		      <ButtonToolbar className="modal__footer">
		        <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
		        <Button className="modal_ok" type="submit" color={'primary'} >
		        	{fetchInProgress ? <span>Processing...	</span> : <span>Update Credit Card</span>}
		        </Button>
		      </ButtonToolbar>
	      </form>
	    </Modal>
	</div>
    
  );
}