import React, { Fragment } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import TagOutlineIcon from 'mdi-react/StarIcon';
import PropTypes from 'prop-types';
import moment from "moment"; 
import ToolTip from '../../../../../shared/components/ToolTip';

import {
  Link
} from "react-router-dom";
import{
	_capitalizeText,
	_getBuildingURL,
} from '../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../shared/helpers';

const calculateBarData = (lastScore) => {	
	
	
	
	return [{ value: lastScore.energy_star_score, fill: getDefaultChartColors[0] },
				  { value: 100-lastScore.energy_star_score, fill: '#DBE0E8' }];
}

const CardPercent = ( { link, data, lastScore } ) => {
	
	
	return (<CardBody className={`${link ? 'chart__link' : ''} dashboard__health-chart-card`} style={{ padding:10 }}  >
			<div className="card__title">
			  <div className="card__title">
				  <h5 className="bold-text card__title-center">{`Energy Star Score`}
					  <ToolTip 
							title={`An energy efficiency score is the Energy Star Rating that a building earns using the United States Environmental Protection Agency online benchmarking tool, Energy Star Portfolio Manager, to compare building energy performance to similar buildings in similar climates.`} 
						/>	          
				  </h5>
				</div>
			</div>
			<div className="dashboard__health-chart"> 
			  <ResponsiveContainer height={180}>
				<PieChart>
				  <Pie data={data} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
	
				</PieChart>
			  </ResponsiveContainer>
			  <div className="dashboard__health-chart-info" style={{marginTop:-15}}>
				<TagOutlineIcon style={{ fill: getDefaultChartColors[0] }} />
				<p className="dashboard__health-chart-number">{lastScore.energy_star_score}</p>
				<p className="dashboard__health-chart-units">energy score</p>
				 
			  </div>
			  <p className="dashboard__goal">Latest avail year {lastScore.year_ending} </p>
			</div>
		</CardBody>);
};

const EnergyScoreCard = (props) => {
	const { dataRes, col, translateScore } = props;
	const lastScore = dataRes.docs.filter(item => item.energy_star_score).slice(-1).pop();
	
	const data = calculateBarData(lastScore);
	
	
	return (
			<Card>
				<CardPercent data={data} lastScore={lastScore} />
				
			</Card>
	)
};



export default EnergyScoreCard;
