import React, { PureComponent, Fragment } from 'react';
import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge, ButtonToolbar
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../shared/components/LinearLoading';
import CryptoJS from 'crypto-js';
import MainIcon from 'mdi-react/FileDocumentOutlineIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/MagnifyIcon';
import Button from '@material-ui/core/Button';
import SingleUnitShare from '../SingleUnit/components/Share/index';
import SingleBuildingShare from '../SingleBuilding/components/Share/index';

import CompReportShare from './reports/comp/index';
import ShareContactButton from './components/ShareContactButton';
import CustomReportShare from './reports/custom/comp-pdf';
import PDFReport from './reports/pdf';

import ExportButton from './ExportButton';

import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../shared/helpers/apicalls';
import { RTLProps } from '../../shared/prop-types/ReducerProps';

import queryString from "query-string"

import { withOktaAuth } from '@okta/okta-react';

import { Publisher } from './../../shared/pubsub/publisher.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	buildingFL,
	_privateKey,
	getLocalStorage
} from '../../shared/helpers/utils';


const apiURL = process.env.API_ROOT + '/buyerslist';
const oktaToken = process.env.OKTA_TOKEN;


class SharePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
		sharePageData:false,
		badlink: false   
    };
    this.buildingSlug = false;
	this.urlState = {};
  }
  
  componentDidMount() {
	  
	const customLink = this.props.match && this.props.match.params && this.props.match.params.custom_link ? this.props.match.params.custom_link : false;
	
	const isPreview = this.props.location && this.props.location.search && this.props.location.search == "?preview=true";
	console.log(isPreview)
	if(isPreview){
		
		let previewData = getLocalStorage("share_", customLink);
		console.log(previewData)
		if(previewData && previewData.custom_link){
			this.setState({sharePageData : previewData});
		}else{
			this.setState({badlink:true, sharePageData:true})
		}
			
			
	}else{
		this._getSharePage(customLink)
	}
	window.Intercom && window.Intercom('update', {
	  "hide_default_launcher": true
	});
//console.log("componentDidMount - _getSharePage")	
	
  }
  
  _getSharePage = async (customLink) => {
	
		let axiosData = {
			url: apiURL + "/sharepage/query",
			method: "post",
			query: {
				query: {
					custom_link: customLink,
					deleted : { "$exists" : false }
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.numFound > 0){
					this.setState({sharePageData : res.docs[0]});
				}else{
					this.setState({badlink:true, sharePageData:true})
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
				this.setState({badlink:true})
		});
			
				
	
  };
  
  _updateShareData = (sharePageData) => {
	  
	  this.setState({sharePageData: sharePageData})
  }

	
  render() {
    const { sharePageData, badlink } = this.state;
    const { user } = this.props;
    if(!sharePageData) return null;
	
	let isPDF = sharePageData.custom_data && sharePageData.custom_data.share_type == "pdf";
	if(badlink){
		return (
		  <Col md={12}>
			<Card>
			  <CardBody style={{border:0}}>
				<div className={`not-found`}>
				  <div>
					
					<a className="mp-not-found-logo" href="https://marketproof.com/new-development" target="_blank"/>
				  </div>
				  <h3 className="email-confirmation__title">{`Oops, Nothing Here.`}</h3>
				  <p style={{fontSize:16}}>You have landed on Marketproof New Dev & Condo, click the button to learn more.</p>
					
					 <a href="https://marketproof.com/new-development" target="_blank">
					<Button
						 variant="contained"
						 color="primary"
						 className="buyersList__primary-button"		         
						 startIcon={null}
					 >	Learn More
					 </Button>	
					 </a>
					 
				</div>
			  </CardBody>
			</Card>
		  </Col>
		  )
	}
	
	
	if(sharePageData.report_type && sharePageData.report_type != "single"){
		if(["comp", "grid"].indexOf(sharePageData.report_type)>=0){
			return (
				<div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
					<CompReportShare sharePageData={sharePageData} updateShareData={this._updateShareData.bind(this)} />
					<ShareContactButton sharePageData={sharePageData} />
				</div>
			)
		}else if(["pdf"].indexOf(sharePageData.report_type)>=0){
			return (
				<div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
					<PDFReport sharePageData={sharePageData} updateShareData={this._updateShareData.bind(this)} />
				</div>
			)
		}else if(["building-comp"].indexOf(sharePageData.report_type)>=0){
			if(false && !user){
				return (
				  <Col md={12}>
					<Card>
					  <CardBody style={{border:0}}>
						<div className={`not-found`}>
						  <div>
							
							<a className="mp-not-found-logo" href="https://marketproof.com/pro" target="_blank"/>
						  </div>
						  <h3 className="email-confirmation__title">{`You are not authorized to view this report`}</h3>
						  <p style={{fontSize:16}}>Access to this report requires you to be logged in user.</p>
							
							 <a href="https://marketproof.com/pro" target="_blank">
							<Button
								 variant="contained"
								 color="primary"
								 className="buyersList__primary-button"		         
								 startIcon={null}
							 >	Learn More
							 </Button>	
							 </a>
							 
						</div>
					  </CardBody>
					</Card>
				  </Col>
				  )
			}
			
			return (
				<div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
					<CustomReportShare sharePageData={sharePageData} updateShareData={this._updateShareData.bind(this)} />
					<ShareContactButton sharePageData={sharePageData} />
				</div>
			)
		}
	}
	
	if(sharePageData.property){
		return (
			<div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
				<SingleUnitShare sharePageData={sharePageData} updateShareData={this._updateShareData.bind(this)} isPDF={isPDF} />
				<ShareContactButton sharePageData={sharePageData} updateShareData={this._updateShareData.bind(this)} isPDF={isPDF} />
			</div>
		)
	}else if(sharePageData.building){
		return (
			<div className={sharePageData.custom_data && sharePageData.custom_data.report_theme ? sharePageData.custom_data.report_theme : "marketproof"}>
		  		<SingleBuildingShare sharePageData={sharePageData} isPDF={isPDF} />
				<ShareContactButton sharePageData={sharePageData} isPDF={isPDF} />
				
			</div>
		)
	}
	
	
    
  }
}

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(withTranslation('common') (withRouter(withOktaAuth(SharePage))));