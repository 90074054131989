import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';



import { setUserProfile } from '../../../redux/actions/authActions';


export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

export function hookAuth(WrappedComponent) {
  return function HockComponent(props) {
    const auth = useAuth();
    return <WrappedComponent {...props} auth={auth} />;
  };
}

const AuthProvider = ({
  children,
  onRedirectCallback,  
  setUserProfile,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [authUser, setAuthUser] = useState();
  const [authClient, setAuth] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
	 // setAuthUser(user);
      setLoading(false);
      setIsAuthenticated(true);
    };
    initAuth();
    // eslint-disable-next-line
  }, []);
  
  const updateUser = async (user) => {
	  setUserProfile(user);
  }


  return (	
	    <AuthContext.Provider
	      value={{
	        isAuthenticated,
	        authUser,
	        loading,
	        updateUser,
	      }}
	    >
	      {children}
	    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onRedirectCallback: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

AuthProvider.defaultProps = {
  onRedirectCallback: () => window.history.replaceState({}, document.title, window.location.pathname),
};

function mapStateToProps(state) {
    return { user: state.user, theme: state.theme, rtl: state.rtl };
}

function mapDispatchToProps(dispatch) {
  return { setUserProfile: bindActionCreators(setUserProfile, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
