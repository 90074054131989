import React, { useState, useEffect } from 'react';
import { browserName } from 'react-device-detect';
import {useStripe, useElements, CardElement, PaymentRequestButtonElement} from '@stripe/react-stripe-js';
import LinearLoading from '../../LinearLoading';
import CardSection from './CardSection';
import 'whatwg-fetch';
const apiUrl = process.env.API_ROOT + '/buyerslist/';
//const apiUrl = 'http://localhost:8000/buyerslist/';

const loader = <svg width="80" height="10" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
			    <circle cx="15" cy="15" r="15">
			        <animate attributeName="r" from="15" to="15"
			                 begin="0s" dur="0.8s"
			                 values="15;9;15" calcMode="linear"
			                 repeatCount="indefinite" />
			        <animate attributeName="fill-opacity" from="1" to="1"
			                 begin="0s" dur="0.8s"
			                 values="1;.5;1" calcMode="linear"
			                 repeatCount="indefinite" />
			    </circle>
			    <circle cx="60" cy="15" r="9" fill-opacity="0.3">
			        <animate attributeName="r" from="9" to="9"
			                 begin="0s" dur="0.8s"
			                 values="9;15;9" calcMode="linear"
			                 repeatCount="indefinite" />
			        <animate attributeName="fill-opacity" from="0.5" to="0.5"
			                 begin="0s" dur="0.8s"
			                 values=".5;1;.5" calcMode="linear"
			                 repeatCount="indefinite" />
			    </circle>
			    <circle cx="105" cy="15" r="15">
			        <animate attributeName="r" from="15" to="15"
			                 begin="0s" dur="0.8s"
			                 values="15;9;15" calcMode="linear"
			                 repeatCount="indefinite" />
			        <animate attributeName="fill-opacity" from="1" to="1"
			                 begin="0s" dur="0.8s"
			                 values="1;.5;1" calcMode="linear"
			                 repeatCount="indefinite" />
			    </circle>
			</svg>

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements(); 
  const { user, selectedPlan, updateUserProfile, coupon, currentPlan, totalAtCheckout, trial } = props;
  const [ fetchInProgress, setFetchInProgress ] = useState(false);
  const [ subscription, setSubscription ] = useState(false);
  const [ error, setError ] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestResult, setPaymentRequestResult] = useState(null);
//   const [clientSecret, setClientSecret] = useState(null)
  
  useEffect(() => {
    if (stripe) {
	    console.log(currentPlan);
	  setPaymentRequest(null);
	  let label = currentPlan.name + ' Subscription';
	  if(coupon && coupon.name){
		  label += ' (' + coupon.percent_off ?  coupon.percent_off + '% Off' :  '$' + Math.round(coupon.amount_off/100) + ' Off' + ')';
	  }
	  let paymentRequestData = {
        country: 'US',
        currency: 'usd',
        total: {
          label: label,
          amount: trial ? 0 : Math.round(parseFloat(totalAtCheckout)*100),
        },
        requestPayerName: false,
        requestPayerEmail: false,
      };
	  console.log(paymentRequestData);
      const pr = stripe.paymentRequest(paymentRequestData);

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
	     console.log(result);
        if (result) {
	      console.log('makePayment', result);	       
          setPaymentRequest(pr); 
          setPaymentRequestResult(result);
			
        }else{
	      setPaymentRequest(false); 
        }
      });
    }
  }, [stripe, currentPlan, coupon, trial]);
  
  
   const stripePaymentMethodHandler = async (result, email, selectedPlan, user) => {
	   
	  console.log(result);
	  
	  if(result && result.paymentMethod 
	  	&& result.paymentMethod.card 
	  	&& result.paymentMethod.card.funding 
	  	&& result.paymentMethod.card.funding === "prepaid"){
		  	
		  	
		  	console.log("here");
		  	setError("Prepaid cards are not accepted, please try again.");
	  
	  }else if (result.error) {
	    // Show error in payment form
	    if(result.error.message){
		    setError(result.error.message);
	    }
	  } else {
		  
		console.log(result);
		setFetchInProgress(true);
	    // Otherwise send paymentMethod.id to your server
	    window.fetch(`${apiUrl}stripe/create-customer`, {
	      method: 'post',
	      headers: {'Content-Type': 'application/json'},
	      body: JSON.stringify({
	        email: email,
	        payment_method: result.paymentMethod.id,
	        plan_id: selectedPlan,
	        coupon: coupon,
	        trial: trial,
	        tax_code: currentPlan.taxCode,
	        user: user ? user : {}
	      }),
	    }).then(function(subscribe) {
		  console.log(subscribe);
		  
		  subscribe.json().then(payment => {
			  
			  console.log(payment);
			  if(payment && payment.status && payment.status === 'active'){
				  updateUserProfile(payment, (updatedUser)=>{
					   //console.log(updatedUser);
					   setFetchInProgress(false);
					   setError(false);
					   setSubscription(payment);
				  });
			  }else if(payment && payment.status && payment.status === 'trialing'){
				  
				  updateUserProfile(payment, (updatedUser)=>{
					   //console.log(updatedUser);
					   setFetchInProgress(false);
					   setError(false);
					   setSubscription(payment);
				  });
			  }else{
				  if(payment.raw && payment.raw.message){
					  setError(payment.raw.message + '  Please try again.');
				  }else{
					  setError('An error occured while processing your credit card, please contact us for assistance.');
				  }
				  
				  setFetchInProgress(false);
			  }
			  
			 
		  })	      
	      
	    }).then(function(customer) {
	      // The customer has been created
	    });
	  }
   }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    event.stopPropagation();
    if(fetchInProgress) return null;
   

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email,
      },
    });

    stripePaymentMethodHandler(result, user.email, selectedPlan, user);
    
  };
  
  const handlePaymentRequest = async (clientSecret, ev) => {
	  
	  const {error: confirmError} = await stripe.confirmCardPayment(
	    clientSecret,
	    {payment_method: ev.paymentMethod.id, setup_future_usage: 'off_session'},
	    {handleActions: false},
	    
	  )
	
	  if (confirmError) {
	    // Report to the browser that the payment failed, prompting it to
	    // re-show the payment interface, or show an error message and close
	    // the payment interface.
	    ev.complete('fail');		    
	    console.log("Confirm Failed", confirmError);
	    if(confirmError.message){				  
			  setError(confirmError.message);
		}else{
			  setError('An error occured while processing your credit card, please contact us for assistance.');
		}
	    
	  } else {
	    // Report to the browser that the confirmation was successful, prompting
	    // it to close the browser payment method collection interface.
	    ev.complete('success');
	    // Let Stripe.js handle the rest of the payment flow.
	    console.log("Confirm Success")
	    const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret);
	    if (error) {
	      // The payment failed -- ask your customer for a new payment method.
	      console.log("Confirm Success - Payment Failed", error);
	      if(error.message){
			  setError(error.message);
		  }else{				  
			  setError('An error occured while processing your credit card, please contact us for assistance.');
		  }
	      
	    } else {
	      // The payment has succeeded.
	       console.log("Payment Success", paymentIntent);
	       stripePaymentMethodHandler(ev, user.email, selectedPlan, user);
	    }
	  }
  }
  
  if(true || paymentRequest === false){
	  	return (
		    <form onSubmit={handleSubmit}>
		      <div className="cc-title">Enter Your Credit Card Information</div>
		      <div className="card-input">
		      	<CardSection />
		      </div>
		      {error && <div className="payment-error">{error}</div>}
		      <button type="submit" className={`payment-btn-primary ${!stripe || fetchInProgress ? 'disabled' : ''}`} disabled={!stripe || fetchInProgress}>
		        {fetchInProgress ? <span>{loader}</span> : <span>{trial ? 'Start Trial' : 'Subscribe'}</span>}
		      </button>
		    </form>
		  );
	  
  }
  
  if (false && paymentRequest && paymentRequestResult) {
	  console.log('render PaymentRequestButtonElement')
	  paymentRequest.on('paymentmethod', async (ev) => {
				
		 window.fetch(`${apiUrl}stripe/payment-intent`, {
		      method: 'post',
		      headers: {'Content-Type': 'application/json'},
		      body: JSON.stringify({
		        coupon: coupon,
		        currentPlan: currentPlan,
		        totalAtCheckout : totalAtCheckout*100,
		        trial: trial
		      }),
		    }).then(function(pi) {
			    console.log(pi)
			    pi.json().then(piResult =>{
				    console.log(piResult) 
				    const clientSecret = piResult.clientSecret;				   
					handlePaymentRequest(clientSecret, ev).catch(e => { console.log(e)}) 	    
			    })			    
			})
		  // Confirm the PaymentIntent without handling potential next actions (yet).
		  
	});
    return ( 
    	<div>
    	{paymentRequestResult.applePay === false && browserName === "Chrome" ? 
	    		<button className="google-pay-button" onClick={() => { paymentRequest.show() }}></button>
	    		:			
				<PaymentRequestButtonElement options={
			    	{paymentRequest, style: {
					    paymentRequestButton: {
					      type: 'default',
					      // One of 'default', 'book', 'buy', or 'donate'
					      // Defaults to 'default'
					
					      theme: 'dark',
					      // One of 'dark', 'light', or 'light-outline'
					      // Defaults to 'dark'
					
					      height: '48px',
					      // Defaults to '40px'. The width is always '100%'.
					    },
					  }}} />			
		}
	    	<div className="payment-options text-center" style={{paddingTop:'20px'}}>
	    		<span style={{background:'#FFF',position:'relative',zIndex:9,display:'inline-block',padding:'0 10px'}}>or</span>
				<hr style={{marginTop:'-10px'}} />
	    	</div>
	    	
	    	<form onSubmit={handleSubmit}>
		      <div className="cc-title">Enter Your Credit Card Information</div>
		      <div className="card-input">
		      	<CardSection />
		      </div>
		      {error && <div className="payment-error">{error}</div>}
		      <button type="submit" className={`payment-btn-primary ${!stripe || fetchInProgress ? 'disabled' : ''}`} disabled={!stripe || fetchInProgress}>
		        {fetchInProgress ? <span>{loader}</span> : <span>{trial ? 'Start Trial' : 'Subscribe'}</span>}
		      </button>
		    </form>
		</div>
	)
  }
  
  
  
  return <div style={{height:100,paddingTop:50,marginLeft:-10,marginRight:-10}}><LinearLoading /></div>;
		

  
}