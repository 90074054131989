import React, { PureComponent, Fragment } from 'react';
import { Progress, Table, Row } from 'reactstrap';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Line, ComposedChart } from 'recharts';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import getTooltipStyles from '../../../../../shared/helpers';
import Panel from '../../../../../shared/components/Panel';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_isCoop
} from '../../../../../shared/helpers/utils';
import{
	_axiosCall,
} from '../../../../../shared/helpers/apicalls';
import { getDefaultChartColors } from '../../../../../shared/helpers';
import ToolTip from '../../../../../shared/components/ToolTip';

const apiURL = process.env.API_ROOT + '/query/';


let bedTypes = [];

class SoldTrendsPrice extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    themeName: PropTypes.string.isRequired,
  };
 
  
  constructor() {
    super();
    this.state = {
      data: false,
    };
  }

  

  
  componentDidMount() {	
	const { soldStats, statsRes, buildingRes } = this.props;
	
	if(soldStats && soldStats.facets && soldStats.facets.building_ppsf){
		const { buckets } = soldStats.facets.building_ppsf.trends;		
		const data = [];
		let bedroomStats = {};
		for(let i = 0; i < buckets.length; i++) {
			const bucket = buckets[i];
			if(bucket.count>0){
			
				let item =  {}
				let byStage = bucket.by_stage && bucket.by_stage.buckets ? bucket.by_stage.buckets : [];
				item['Year'] = moment(bucket.val).format('YYYY');
				item['Price Per Square Feet'] = bucket.avg_ppsf ? bucket.avg_ppsf : buckets[i-1].avg_ppsf;
				
				item['Number of Sales'] = bucket.count ? bucket.count : 0;
				item['Number of Sponsor Sales'] = byStage.filter(s => s.val === true)[0] ? byStage.filter(s => s.val === true)[0].count : 0;
				item['Number of Resales'] = byStage.filter(s => s.val === false)[0] ? byStage.filter(s => s.val === false)[0].count : 0;
				data.push(item);
			}
		}
		this.setState({ data: data });
	}
  }
  
  render() {
      const { t, dir, themeName, lmi, buildingRes } = this.props;    
      const { data } = this.state;
	  if(!data){
		  return null;
	  }
	  let isCoop = _isCoop(buildingRes);
	  return (
		  <Panel 
		  	md={12}
		  	lg={12}
		  	xl={12} 
		  	title={<Fragment>Sales & Price Trend<ToolTip title={"Average price per square feet, number of sponsor and resale closings by year.  Data is based on ACRIS recorded closings.  Note that sold prices were not available in ACRIS before the year 2000."} /></Fragment>}
		  	subhead={'Yearly average price / sq ft with number of sales.'}
		  >
		    <div dir="ltr">
		      <ResponsiveContainer height={350} className="dashboard__area">
		        <ComposedChart data={data} margin={{ top: 0, left: -10, bottom: 20, right:20 }}>
			       
		           <XAxis dataKey="Year" tickLine={false} reversed={dir === 'rtl'} dx={5} angle={45} tickSize={20} height={60} tickMargin={10} />
			       <YAxis  yAxisId="left" tickLine={true} orientation={'left'} tickFormatter={value =>  value > 0 ? '$' + _formatNumber(value) : ""} />
				   <YAxis  yAxisId="right" tickLine={true}  orientation={'right'} tickFormatter={value =>  value} />
			      {/* <YAxis yAxisId="right" 
			        tickLine={false} 
			        orientation={'right'} 
			       	tickFormatter={value => ['$', new Intl.NumberFormat('en').format(value)].join('')}/> */}
			       <Tooltip formatter={(value, name, props) => name == "Price Per Square Feet" ? '$'+ (value > 0 ? _formatNumber(value) : 0) : (value > 0 ? _formatNumber(value) : 0)} />
			       <Legend />
			       <CartesianGrid vertical={false}/>
			 	  	{isCoop ?
						<Bar dataKey={"Number of Sales"} yAxisId="right" stackId="a" fill={"#5C6178"} fillOpacity={1}/> 	
					:
					<Fragment>
				     <Bar dataKey={"Number of Sponsor Sales"} yAxisId="right" stackId="a" fill={"#5C6178"} fillOpacity={1}/>  
					 <Bar dataKey={"Number of Resales"} yAxisId="right" stackId="a" fill={"#A3ADC2"} fillOpacity={1}/> 
					</Fragment>
					}
				     <Line yAxisId="left" dot={{ stroke: getDefaultChartColors[0], strokeWidth: 5 }} type="monotone" dataKey={"Price Per Square Feet"} fill="#BFC4D1" stroke={getDefaultChartColors[0]} strokeWidth={2} fillOpacity={0} />
			         
			       
			        

			      
			      </ComposedChart>
				 
					
					
	
		      </ResponsiveContainer>
		    </div>
		  </Panel>
		);
	}
}

export default connect(state => ({
  rtl: state.rtl,
  dir: PropTypes.string.isRequired,
  theme: state.theme,
}))(withTranslation('common')(SoldTrendsPrice));
