import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import AnalysisSearchContainer from './components/AnalysisSearchContainer';
import TrialAlert from '../../shared/components/payment/TrialAlert';

import Alert from '../../shared/components/Alert';

import AlertIcon from 'mdi-react/InformationOutlineIcon';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";
import QuickStartGuide from '../../shared/components/QuickStartGuide';

class Analytics extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	render(){
		const { user } = this.props;
	    if(!user) return null;
	    return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Inventory Analysis</h3>
		        <h3 className="page-subhead subhead">Analyze Residential Units in New Developments & Condos.</h3>
		      </Col>
		    </Row>
		    {_hasPermission(user, 'inventory-analysis') ?
				<Fragment>
				    <Row>
				      <Col md={12} className={'mb-3'}>
				       	<TrialAlert />        
				      </Col>
				    </Row>
				    {/* <Row>
				    	<Col md={12} className={'mb-3'}>
							<Alert color="success" className="alert--bordered"  alertId="aboutData" icon iconItem={<AlertIcon />} minimizeText={`About The Data`}>
					          <p>
					          	<span className="bold-text">{`About The Data`}</span> 
					            <div>{`The information provided herein is provisional and subject to change as more buildings and units are vetted.`}</div>
					          </p>
					        </Alert>
						</Col>
				    </Row> */}
					<QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_e97ecee402"} type={"Inventory Analysis"} />

				    <Row> 
				     
				      <AnalysisSearchContainer />
				    </Row>
				</Fragment>
				:
				     <Row>
					    <EnterpriseGate />
					 </Row>
			}
		  </Container>
		);
	}
};


export default connect(state => ({
  user: state.user,
}))(Analytics);
