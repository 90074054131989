/* eslint-disable react/no-children-prop */
import React, { Component, Fragment } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import ClearIcon from "mdi-react/CloseCircleOutlineIcon";
import AlertIcon from 'mdi-react/AlertCircleOutlineIcon';
import Button from '@material-ui/core/Button';
import ReportIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import moment from 'moment';
import ShareContractAgents from '../../../shared/components/ShareContractAgents'
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import ReportStepper from './ReportStepper';
import { nanoid } from 'nanoid'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import TuneVerticalIcon from 'mdi-react/TuneVerticalIcon';

import {
  DatePicker,
  KeyboardDatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import{
	matTheme,
	
} from '../../../shared/helpers/utils';
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	_formatUnitNumber,
	_nameToUrl,
	_urlSafe,
	_slugifyText
} from '../../../shared/helpers/utils';
import { _hasPermission	} from "../../../shared/helpers/access";
const redux_form_name = "report_stepper_form";

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, rows, disabled
}) => (
	<ThemeProvider theme={matTheme}>
	  <TextField
		className="material-form__field"
		label={label}
		error={touched && error}
		value={input.value}
		children={children}
		select={select}
		multiline={rows}
		rows={rows}
		onChange={(e) => {
		  e.preventDefault();
		  input.onChange(e.target.value);
		}}
		variant="outlined"
		disabled={disabled}
		
	  />
	  {touched && ((error && <span>{error}</span>))}
	</ThemeProvider>
);
renderTextField.defaultProps = {
  label: '',
  meta: null,
  select: false,
  children: [],
};
renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
	touched: PropTypes.bool,
	error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

const required = value => value ? undefined : 'Required'
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
const renderDateField = ({
  input, label, meta: { touched, error }, children, select, rows
}) => (
	<div>
	<MuiPickersUtilsProvider utils={MomentUtils}>
	  <DatePicker 
		autoOk
		clearable
		placeholder="M/D/YYYY"
		   value={input.value ? input.value : null} 
		   onChange={date => input.onChange(moment(date).format('M/D/YYYY'))} 
		   format="M/D/YYYY"
			variant="inline"
		inputVariant="outlined"
	   />
	</MuiPickersUtilsProvider>
	</div>
);

const contractFormatter = (number) => {
  if (!number) return;

	return '$' + number
		.replace(/,/g , '')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const contractParser = (number) => {
  return number.replace(/[^0-9\.]+/g , '');
};

const renderCheckbox = ({ input, label, disabled }) => {
	return (
		  <FormControlLabel
			  control={
				<Checkbox
				  checked={input.value ? true : false}
				  onChange={input.onChange}
				  onClick={e => e.stopPropagation()}
				/>
			  }
			  label={label}
			  disabled={disabled}
			/>
   )
}
const renderRadio = ({ input, label, disabled }) => {
	console.log(input.value);
	return (
	  <RadioGroup aria-label="agentType" name="agentType" value={input.value} onChange={input.onChange} row>
		<FormControlLabel value="buyer" control={<Radio />} label="Buyer" />
		<FormControlLabel value="seller" control={<Radio />} label="Seller" />
	  </RadioGroup>
   )
}

const renderLinkField = ({
	  input, label, meta: { asyncValidating, touched, error }, children, select, rows
	}) => {
		console.log(touched, error)
		return (
		<ThemeProvider theme={matTheme}>
		  <TextField
			className="material-form__link"
			label={label}
			error={error!==undefined}
			helperText={error}
			value={_urlSafe(input.value)}
			children={children}
			select={select}
			multiline={rows}
			rows={rows}
			onChange={(e) => {
			  e.preventDefault();
			  
			  input.onChange(e.target.value);
			  //checkLink(e.target.value)
			  
			}}	
		  />
		</ThemeProvider>
		)
};
const mockProperty = {
			"custom_fields_json": "{\"mpnd_user_contract\":\"{\\\"meta\\\":{\\\"userEmail\\\":\\\"ning@marketproof.com\\\",\\\"userKey\\\":\\\"00u57xs7bfNsi46jh4x6\\\",\\\"submitTime\\\":1642629306},\\\"contract_buyers_agent\\\":{\\\"buyerAgentFullName\\\":\\\"Linda Crawford\\\",\\\"buyerAgentFirmName\\\":\\\"Best in Town Brokerage \\\",\\\"buyerAgentEmail\\\":\\\"linda@bestintown.com\\\",\\\"buyerAgentPhone\\\":\\\"212-555-1234\\\"},\\\"contract_sellers_agent\\\":{\\\"sellerAgentFullName\\\":\\\"Jake Elsdon\\\",\\\"sellerAgentFirmName\\\":\\\"Acme Brokers\\\",\\\"sellerAgentEmail\\\":\\\"jake@acmebrokers.com\\\",\\\"sellerAgentPhone\\\":\\\"917-555-2345\\\"},\\\"contract_expected_close_date\\\":1644469200}\"}",
			"last_contract_date" : moment().unix()
		}

const styles = theme => ({
		  root: {
			display: 'flex',
			flexDirection: 'column',
			//marginLeft:"-8px",
			marginTop:"50px",
			marginBottom:"50px",
			alignItems: 'left',
			'& > *': {
			   
			},
		  },
		  button:{
			'&.selected' :{
				background:"#D1D6E0"
			},
			fontSize:16
		  },
		  '.btn-toolbar' : {
			  justifyContent:'center'
		  }
		});
class ReportStepperForm  extends Component {
	
	constructor(props) {
		super(props);
		this.state = { 
		   activeStep : 0,
		   errors: false
		};
		
		
		this.setActiveStep = this.setActiveStep.bind(this);
				
	}
	disabledFields = [];
	
	componentDidMount() {
	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		const { formValues, dispatch, user } = this.props;
		
		
		
		
	}
	
	setActiveStep(step){
	  const { formValues, user} = this.props;
	  
	  let errors = [];
	  if(step > 1){

		  {/* if(!formValues.isBuyer && !formValues.isSeller){
			  if(_hasPermission(user, "firm-contracts")){
				  errors.push("You or your firm must have represented the seller to submit a contract.")
			  }else{
				  errors.push("You must be the seller's agent to submit a contract.")
			  }
			  
		  }
		  if(formValues.isBuyer){			  
			  if((!formValues.buyerAgentFullName || !formValues.buyerAgentEmail)){
				  errors.push("Please provide buyer's agent name and email.")
			  }			  
		  }
		  if(formValues.isSeller){			  
			  if((!formValues.sellerAgentFullName || !formValues.sellerAgentEmail)){
				  errors.push("Please provide seller's agent name and email.")
			  }			  
		  }
		  
		  if(formValues.contractDate && formValues.contractDate && moment(formValues.contractDate, "M/D/YYYY").unix() > moment(formValues.closeDate, "M/D/YYYY").unix()){			  		errors.push("Contract date cannot be after the close date.")  
							
		  } */}
	  }
	  
	  if(errors.length<=0){
		  this.setState({ activeStep : step, errors : false });
	  }else{
		  this.setState({ errors : errors });
	  }
	  
	  
  }
  
	render(){
		const { handleSubmit, reset, t, buildingRes, user, toggle, property, saved, buildingNameOrAdr, setFormValues, formValues, generateShareBase, handleSave, checkLink, classes} = this.props;
		const { activeStep, errors } = this.state;
		
		let realProperty = false;
		if(activeStep>=1){
			let updatedState = setFormValues();
			console.log(updatedState)
			if(updatedState){
				
				realProperty = {
					 "custom_fields_json" : JSON.stringify(updatedState['custom_fields']),
					 "last_contract_date" : updatedState["last_contract_date"]
				}
			}
		}

	console.log(realProperty);
		return(
		
		 <ReportStepper activeStep={activeStep} setActiveStep={this.setActiveStep} cancel={toggle} handleSubmit={handleSubmit} >
		  {activeStep == 0 ?
		 
			 <div>
				<Fragment>
					 
					  <h4 className="bold-text">Select Report Type</h4>
					  <p className="email-confirmation__sub mb-3">Your collection can be presented a different formats, select the one you'd like to share. </p>
					  
					    <div className={classes.root}>
								   
							<ButtonToolbar size="" aria-label=" button group" >							        
								<Tooltip title="Most viewed buildings today" aria-label="Most viewed buildings today">
								  <Button variant="outlined" onClick={()=> {}} className={formValues === "1-day" ? classNames(classes.button, "selected") : classNames(classes.button)}>Comp Report</Button>
							  </Tooltip>
							  {/*<Tooltip title="Most viewed buildings yesterday" aria-label="Most viewed buildings yesterday">
								  <Button onClick={()=> this.setPeriod("yesterday")} className={period === "yesterday" ? classNames(classes.button, "selected") : classNames(classes.button)}>Yesterday</Button>
							  </Tooltip>*/}
							  <Tooltip title="Most viewed buildings this week" aria-label="Most viewed buildings this week">
									  <Button variant="outlined" onClick={()=> {}} className={formValues  === "7-days" ? classNames(classes.button, "selected") : classNames(classes.button)}>Property List</Button>
								 </Tooltip>	
							   <Tooltip title="Most viewed buildings this month" aria-label="Most viewed buildings this month">
									  <Button variant="outlined" onClick={()=> {}} className={formValues  === "30-days" ? classNames(classes.button, "selected") : classNames(classes.button)}>Data Export</Button>
								 </Tooltip>
							</ButtonToolbar>
						</div>
						
					  <hr/>
				</Fragment>
			</div>
			
			: activeStep == 1 ?
			<form className="material-form" onSubmit={handleSubmit}>
				<div>
					<Fragment>
						  <h4 className="bold-text">Customize Your Report</h4>
						  <p className="email-confirmation__sub mb-3">Personalize your report for your audience. </p>
						  
					
					</Fragment>
					 <Row>
					   <Col sm={12} md={12} lg={12} xl={12}> 
						   <div className="checkbox__items mb-2">            
							 <Field name="ap" component={renderCheckbox} label={<span>Include My Name and Contact (Update in <Link to={`/my-account`} className="link-text">My Account</Link>)</span>} value="info" /> 
						   </div>
						   
					   </Col>
					   
					   <Col sm={12} md={12} lg={12} xl={12}> 
							   <div className="checkbox__items mb-2">            
								 <Field name="units" component={renderCheckbox} label="Include Units" value="link" /> 
							   </div>
							   <div className="checkbox__items mb-2">            
								   <Field name="buildings" component={renderCheckbox} label="Include Buildings" value="link" /> 
								 </div>
						</Col>
						
						<Col sm={12} md={12} lg={12} xl={12}> 
						   <div className="share__link">
								 <span className="material-form__label">Add a Message</span>
								  <Field
								   name="message"
								   component={renderTextField}
								   placeholder=""
								   multiline
								   rows="2"
								   rowsMax="2"
								 />
							   </div> 
						 </Col>
					 </Row>
					 <hr/>
				</div>
			</form>
			:
		 
			<div style={{textAlign:"left",  margin:"20px auto"}}>
				<Fragment>
					  <h4 className="bold-text">Customize Share Link</h4>
					  <p className="email-confirmation__sub mb-3">Preview your report and set your share URL. </p>
					  
				
				</Fragment>
				 <Row>
					<Col sm={12} md={12} lg={12} xl={12} className={"mt-2 mb-4"}>         	
					  	<div>
							
							<div className="custom_share">
								<div className="share_base">{generateShareBase()}</ div>
								<div>
									<Field
								  	name="customLink"
								  	component={renderLinkField}
								  	placeholder=""
								  	checkLink={checkLink}
									/>
								</div>
								<div>
									<span className="material-form__label"><a href="javascript:void(0)" onClick={() => handleSave(true)}>Save & Preview <OpenInNewIcon size={12} /></a></span>
								</div>
							</div>
							
					  	</div>
				  	</Col>
					</Row>
				 <hr/>
			</div>
		}
		</ReportStepper>
		)
	}
};

ReportStepperForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default connect(
  (state, props) => ({
	initialValues: Object.assign({ccme : true, saveme : !props.saved, fullName: props.user.firstName + ' ' + props.user.lastName,  customLink: _slugifyText(nanoid(10))}, props.user), 
		
		formValues: getFormValues(redux_form_name)(state)
  })
)(reduxForm({
  form: 'report_stepper_form', // a unique identifier for this form
})((withStyles(styles,  { withTheme: true }))(ReportStepperForm)));
