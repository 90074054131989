import React, { useState, Fragment, useRef } from 'react';
import {
  Card, CardBody, Col, Row, ButtonToolbar, Badge,  Nav, NavLink, NavItem, TabContent, TabPane, Container, Modal, 
} from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import HeartIcon from 'mdi-react/HeartIcon';
import ImageIcon from 'mdi-react/DownloadIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import classnames from 'classnames';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import ExportIcon from 'mdi-react/FilePdfBoxIcon';

import moment from 'moment';

import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { _hasPermission	} from "../../shared/helpers/access";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_slugifyText,
	_makeFileName
} from '../../shared/helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
	'& > *': {
	  margin: theme.spacing(1),
	  width: '100%',
	},
  },
  formControl:{
	  width: '100%',
	  marginBottom:'20px'
  },
  button:{
	  color: '#780F9E',
	  marginBottom:'10px'
  },
  radio:{
	  focused: {
		 color:'#780F9E' 
	},
	root:{
		'& > *': {
			fontSize:'12px',
			flexDirection: 'initial'
		}
	}  
  }
}));




const ExportButton = (props) => {
	
  const { title, buttonStyle, user, sharePageData } = props;
  const [modal, setModal] = useState(false); 
  const [branding, setBranding] = useState('marketproof');
  const [buttonText, setButtonText] = useState('Download PNG'); 
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [exportTitle, setExportTitle] = useState(title); 
  const classes = useStyles();

  const componentRef = useRef();
  const printDocument = () => {
	//this.setState({exportProgress:true});
	const input = document.getElementsByClassName('share__wrapper')[0];
	const pages = document.querySelectorAll('.pdf-page').length;
	
	const reportName = title ? `${title}.pdf` : sharePageData.custom_data && sharePageData.custom_data.report_title ? `${sharePageData.custom_data.report_title}.pdf` : "Marketproof-Export.pdf";
	
	
	html2canvas(input, {
		//allowTaint: true,
		//useCORS: true,
		logging:true,
		proxy: process.env.API_ROOT+"/proxy"
	}).then((canvas) => {
		
		  const imgData = canvas.toDataURL('image/png');
		  
		  const imgWidth = canvas.width;
		  const pageHeight = canvas.height/pages;
		  const imgHeight = canvas.height; //((canvas.height * imgWidth) / canvas.width);
		  
		 // console.log(canvas.height, canvas.width, imgHeight);
		  let heightLeft = imgHeight;
		  const doc = new jsPDF('p', 'px', [canvas.width, imgHeight]);
		  let position = 0;
		  doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
		 
		  doc.save(reportName);
		  //this.setState({exportProgress:false});
		// const imgData = canvas.toDataURL('image/png');
		// const pdf = new jsPDF('l', 'mm', [297, 210], true);
		// pdf.addImage(imgData, 'PNG', 0, 0);
		// // pdf.output('dataurlnewwindow');
		// pdf.save("download.pdf");
	  })
	;
  }
  return (   
	  <Fragment>
		   <Tooltip title={`Export Page as PDF`}>
			   <Fab
				 variant="extended"
				 size="medium"
				 color="secondary"
				 aria-label="add"
				 style={{fontWeight:400,background:'#780F9E', position:'fixed',bottom:"20px",right:"20px",zIndex:999}}
				 onClick={()=>{printDocument()}}
				 className={"print-button"}
			   >
				 <ExportIcon style={{marginRight:5}} /> {` `} Export PDF
			   </Fab>
		   </Tooltip>
		</Fragment>
  );
};

export default ExportButton;
