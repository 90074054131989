import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import moment from 'moment';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';

import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

import Analysis from './Analysis';
import Units from './Units';
import Sales from './Sales';
import Listings from './Listings';
import BuyersList from './BuyersList';
import Profile from './Profile';
import QA from './QA';

import StackingPlans from './StackingPlans';

import AddToBuyersListButton from '../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../shared/components/buttons/ContactDeveloperButton';
import ShareButton from '../../../shared/components/buttons/ShareButton';
import ReportButton from '../../../shared/components/buttons/ReportButton';
import OwnerListButton from '../../../shared/components/data_export/OwnerListButton';
import AISummaryButton from '../../../shared/components/ai/AISummaryButton';


import { bindActionCreators } from 'redux';
import buyersListActions from '../../../redux/actions/buyersListActions';


import NewsTimeline from '../../News/components/NewsTimeline';
import LinearLoading from '../../../shared/components/LinearLoading';

import Plans from './Plans';
import Environment from './Environment';
import Documents from './Documents';
import SavedCount from '../../../shared/components/SavedCount';
import { Publisher } from './../../../shared/pubsub/publisher.js';

import TrialLimit from '../../../shared/components/payment/TrialLimit';

import LendingHistory from './Profile/components/LendingHistory';
import OfferingHistory from './Profile/components/OfferingHistory';

import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';
import AddCollectionButton from '../../../shared/components/collections/AddCollectionButton';

import BuildingProfile from './Profile/components/BuildingProfile';


import { _hasPermission	} from "../../../shared/helpers/access";
import TaxAbatement from '../../../shared/components/TaxAbatement';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";

import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_shouldShow,
	_urlToName,
	_getBuildingURL,
	_qaThreadMap,
	StatusBadge,
	BuildingCurrentStatus,
	BuildingStageBadge,
	propertyExportFL,
	_validUnitSources,
	propertyExportFLRestricted,
	mailingListFL,
	_activityFQ,
	_isCoop,
	_isHouse,
	propertyFL,
	HouseCurrentStatus
} from '../../../shared/helpers/utils';

const apiURL = process.env.API_ROOT + '/query/';

class BuildingTabContainer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
      unitsRes: false,
      statsRes: false,
      qaMounted: false,
      gatedView: false,
      transactionRes: false,
      transactionDocs: false,
      hpdRes:null,
      activityStatsRes:null,
	  taxAbatement:null,
	  resaleTimePeriod: 2,
	  transFetchInProgress:false,
	  propFetchInProgress:false
    };
  }
  
  q = '';
  
  componentDidMount() {
	const { buildingRes, user, viewedBuildings  } = this.props;
	const { viewBuilding } = this.props.actions;
	
	const viewedCount = viewedBuildings && Array.isArray(viewedBuildings) ? viewedBuildings.length : 0;
    const maxViews = process.env.MAX_TRIAL_BUILDING_VIEWS;
	const remainingViews = maxViews - viewedCount;
	if(user.subscriptionStatus && user.subscriptionStatus === 'trialing' && remainingViews <= 0 && viewedBuildings.filter(item => item.key === buildingRes.key).length <= 0){
		this.setState( { gatedView :true });
	}else if(user.subscriptionStatus && user.subscriptionStatus === 'trialing' && remainingViews < 0){
		this.setState( { gatedView :true });
	}else{
		this._getBuildingOPData(); 	 	
		this._getBuildingTransactionData();
		this._getBuildingHPDData();
		this._getActivityData();
		viewBuilding(buildingRes.key, user);
		Publisher.publish(`track.buildingView.${this.state.activeTab}`, {building: buildingRes.key});
		Publisher.publish(`track-mp.buildingView.${this.state.activeTab}`, {user : user, building: buildingRes});
		
	}
		
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	const { user, viewedBuildings, buildingRes } = this.props;
	const { gatedView } = this.state;
	const { viewBuilding } = this.props.actions;
	if(prevProps.match.params.tab !== this.props.match.params.tab){
		this.setState({activeTab:this.props.match.params.tab })
		
	}
	
	if(user.subscriptionStatus && user.subscriptionStatus === 'active' && gatedView === true){
		this.setState( { gatedView : false });
		this._getBuildingOPData(); 	 
		this._getBuildingTransactionData();	
		this._getBuildingHPDData();
		this._getActivityData();
		viewBuilding(buildingRes.key, user);
		Publisher.publish(`track.buildingView.${this.state.activeTab}`, {building: buildingRes.key});		
		Publisher.publish(`track-mp.buildingView.${this.state.activeTab}`, {user : user, building: buildingRes});
	}
	if(this.state.resaleTimePeriod !== prevState.resaleTimePeriod){
		this._getBuildingTransactionData();	
		this._getBuildingOPData();
	}

  }
  
 
  toggle = (tab) => {
	const { buildingRes  } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
	  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + (tab ? `/tab/${tab}` : '');
	  this.props.history.push(buildingURL);
      this.setState({
        activeTab: tab,
      });
      Publisher.publish(`track.buildingView.${tab}`, {building: buildingRes.key});
    }
  };
  
  setResalePeriod = (period) => {
	  this.setState({resaleTimePeriod : period});
  }
  
  
  
  
  _getBuildingOPData = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const { buildingRes } = this.props;
		const { resaleTimePeriod } = this.state;
		
		
		const addressQ = buildingRes.alternate_addresses.join('" OR "');
		this.setState({propFetchInProgress:true})
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough.toLowerCase()}"))`
		
		let statsQ = '';
		
		let isCoop = _isCoop(buildingRes);
		let isHouse = _isHouse(buildingRes);
		
		if(!isHouse){
			if(['active'].indexOf(buildingRes.offering_verified_status)>=0){
				statsQ = `((offering_verified_status:active AND source_organizations:(CDEC NYAG ADEC) AND baths:[1 TO *]) OR verification_source:(user-verified-valid CDEC ADEC))`
			 	q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}))`;		
			}else if(buildingRes.cdec_date && parseInt(moment(buildingRes.cdec_date).format('YYYY')) > 1970 && !isCoop){
				let unitSource = "source_organizations:(CDEC ADEC)";
			  	if(buildingRes.cdec_units_properties < (buildingRes.inventory_total * .8)){
				 	unitSource = `(source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}))`
			  	}
			 	statsQ = `(${unitSource} AND property_type:("" *condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial* AND -property_type:*multi*)`;
			 	q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;
			}else if(['coming soon'].indexOf(buildingRes.offering_verified_status)>=0){
				statsQ = `(source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:"")`;
			 	q += ` AND (${statsQ} OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
			}else if(!isCoop){
				q += ` AND (source_organizations:(CDEC ADEC) OR verification_source:(${_validUnitSources}) OR (current_status:(active \"in contract\")))`;		
			}
		}
		const isResale = ['resale'].indexOf(buildingRes.building_status)>=0;
		
		if(statsQ.length>0){
			statsQ = 'AND ' + statsQ;
		}
			
		
		this.q = q;
		const priceField = isResale ? "last_sold_price" : "last_listed_sale_price_all";
		let fq = ["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""', `-offering_verified_status:stalled`];
		let fl = [...propertyExportFL, ...["floor", "line", "offering_submitted_date", "div(last_sold_price, sq_ft)", "div(last_listed_sale_price_all, sq_ft)", "div(last_contract_price, sq_ft)", "offering_exterior_sq_ft", "times_exchanged", "last_exchanged_date", "*floorplan*"]];
		if(isHouse){
			fq = ["-deleted:true", "-offering_unit_abandoned:true", "address_2_normalized:\"\""];
			fl = [...propertyFL, ...["description"]];
		}
		
		let axiosData = {
			url: apiURL + "we3-properties?cache=15",
			method: "post",
			query: {
				q: q,
				fq: fq,
				fl: fl,
				sort: 'floor asc, address_2_normalized asc',
				wt: "json",
				rows: 999,
				"json.facet": {
					"avg_price": `avg(${priceField})`,
					"avg_sq_ft": "avg(sq_ft)",
					"low_bed": "min(bedrooms)",
					"has_bed" : {
						"type": "query",
						"q": `bedrooms:[0 TO *] AND -property_type:"resident manager unit"`,
						"facet": {
							"unit_mix": {
								"type" : "terms",
								"field" : "bedrooms",
								"facet": {
									"avg_unit_size" : "avg(sq_ft)",
									"med_unit_size": "percentile(sq_ft,0,50,100)",
									
									"non_zero_price": {
										"type": "query",
										"q": `${priceField}:[100 TO *] AND sq_ft:[100 TO *] ${statsQ}`,
										"facet": {
											"avg_unit_size" : "avg(sq_ft)",
											"med_unit_size": "percentile(sq_ft,25,50,75)",
											"avg_fees" : "avg(offering_monthly_fees)",
											"med_fees": "percentile(offering_monthly_fees,25,50,75)",
											"avg_taxes" : "avg(offering_monthly_taxes)",
											"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
											"avg_ppsf": `avg(div(${priceField}, sq_ft))`,
											"min_price": `min(${priceField})`,
											"max_price": `max(${priceField})`,
											"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
										},
									},
									"by_status" : {
										"type" : "terms",
										"field" : "offering_status"
									},
									
								}
							}
						}
					},
					"non_zero_price": {
						"type": "query",
						"q": `${priceField}:[100000 TO *] AND sq_ft:[100 TO *] ${statsQ} AND property_type:("" *condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial* AND -property_type:*multi*`,
						"facet": {
							"low_price": `min(${priceField})`,
							"high_price": `max(${priceField})`,
							"average_price": `avg(${priceField})`,
							"average_sq_ft": `avg(${priceField})`,
							"sum_price": `sum(${priceField})`,
							"low_sq_ft": `min(sq_ft)`,
							"high_sq_ft": `max(sq_ft)`,
							"low_bed": `min(bedrooms)`,
							"high_bed": `max(bedrooms)`,
							
						}
					},
					"total_sold": {
						"type": "query",
						"q": `last_listed_sale_price_all:[1 TO *] AND offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*) ${statsQ}`,
						"facet": {
							"low_price": "min(last_listed_sale_price_all)",
							"high_price": "max(last_listed_sale_price_all)",
							"average_price": "avg(last_listed_sale_price_all)",
							"average_sq_ft": "avg(last_listed_sale_price_all)",
							"sum_price": "sum(last_listed_sale_price_all)",							
							"low_sq_ft": "min(sq_ft)",
							"high_sq_ft": "max(sq_ft)",
							"low_bed": "min(bedrooms)",
							"high_bed": "max(bedrooms)",
							"by_neighborhood" : {
								"type" : "terms",
								"field" : "neighborhood",
								"sort" : "avg_ppsf desc",
								"limit" : 20,
								"facet" : {
									"avg_price": "avg(offering_price)",
									"avg_ppsf": "avg(div(offering_price, sq_ft))",
								}
							},
							
						}
					},
					"total_sellout": {
						"type": "query",
						"q": `last_listed_sale_price_all:[1 TO *]  ${statsQ} AND -property_type:"resident manager unit"`,
						"facet": {
							"low_price": "min(last_listed_sale_price_all)",
							"high_price": "max(last_listed_sale_price_all)",
							"average_price": "avg(last_listed_sale_price_all)",
							"average_sq_ft": "avg(last_listed_sale_price_all)",
							"sum_price": "sum(last_listed_sale_price_all)",
							"sum_sq_ft": "sum(sq_ft)",
							"low_sq_ft": "min(last_listed_sale_price_all)",
							"high_sq_ft": "max(sq_ft)",
							"low_bed": "min(offering_bedrooms)",
							"high_bed": "max(offering_bedrooms)",
							"sold_volume" : {
								"type" : "query",
								"q" : "offering_status:(not_yet_recorded OR sold OR closed OR resale OR *contract* OR *pending*)",
								"facet" : {
									"sum_price": "sum(last_listed_sale_price_all)",
									"sum_sq_ft": "sum(sq_ft)"
								}
							}														
						}
					},
					"total_sq_ft": {
						"type": "query",
						"q": `sq_ft:[1 TO *]  ${statsQ} AND property_type:("" *condo* "resident manager unit" *coop* *affordable*)`,
						"facet" : {
									"avg_sq_ft": "avg(sq_ft)",
									"sum_sq_ft": "sum(sq_ft)"
								}
						
					},
					"first_sold": {
						"type": "query",
						"q": `last_sold_date:[1000 TO *] ${statsQ}`,
						"facet": {
							"first_sold_date" : "min(last_sold_date)"
						},
					},
					"sold_1_yr": {
						"type": "query",
						"q": `last_contract_date_formatted:[NOW-1YEAR TO NOW]`
						
					},
					"sold_3_mo": {
						"type": "query",
						"q": `last_contract_date_formatted:[NOW-3MONTH TO NOW]`
						
					},
					"sold_6_mo": {
						"type": "query",
						"q": `last_contract_date_formatted:[NOW-6MONTH TO NOW]`
						
					},
					"listed_1_yr": {
						"type": "query",
						"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-1, "year").unix()} TO ${moment().endOf('day').unix()}]`
						
					},
					"listed_3_mo": {
						"type": "query",
						"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-3, "month").unix()} TO ${moment().endOf('day').unix()}]`
						
					},
					"listed_6_mo": {
						"type": "query",
						"q": `last_listed_sale_listed_date_all:[${moment().endOf('day').add(-6, "month").unix()} TO ${moment().endOf('day').unix()}]`
						
					},

					"by_status" : {
						"type": "query",
						"q": `property_status:sponsor ${statsQ} AND -property_type:"resident manager unit" AND property_type:(*residential*condo* condo "condo unit" *coop* *affordable* "" *dwelling* *timeshare* *residential* *apartment*)`,
						"facet": {
							"sponsor": {
								"type" : "terms",
								"field" : "current_status"
							},													
						}	
						
					},
					"resale":{
						"type": "query",
						"q": `-property_status:sponsor ${statsQ} AND -property_type:"resident manager unit" AND property_type:(*residential*condo* condo "condo unit" *coop* *affordable* "" *dwelling* *timeshare* *residential* *apartment*)`,
					},
					"ppsf":{
						"type": "query",
						"q": `last_listed_sale_price_all:[500000 TO 300000000] AND sq_ft:[100 TO *]  ${statsQ} ${isResale ? " AND last_listed_sale_date_all:[" + (Math.round(moment().endOf('day').add((-1 * resaleTimePeriod), "year").valueOf() / 1000)) + " TO *]" : ""}`,
						"facet": {
							"sum_listed_price": "sum(last_listed_sale_price_all)",
							"sum_sq_ft": "sum(sq_ft)",
							"avg_ppsf" : "avg(div(last_listed_sale_price_all, sq_ft))"
						}
					},
					"original_owner" : {
						"type": "query",
						"q": `times_exchanged:[0 TO *] AND owner:* ${statsQ}`,
						"facet" : {
							"times_sold" : {
								"type" : "terms",
								"field" : "times_sold"
							},
							"times_exchanged" : {
								"type" : "terms",
								"field" : "times_exchanged"
							}
							
						}
												
					},
					"corp_owner" : {
						"type": "query",
						"q": `(owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) OR owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)) ${statsQ}`,																	
					},
					"has_rented" : {
						"type": "query",
						"q": `last_listed_rent_date:[1000 TO *] ${statsQ}`,																
					},
/*
					"person_owner" : {
						"type": "query",
						"q": `(-owner:(*LLC *INC *CORP *TRUST *L.P. *AUTHORITY *ASSOCIATES *COMPANY *L.L.C) AND -owner:(*llc *inc *corp *trust *l.p. *authority *associates *company *l.l.c)) ${statsQ}`,																	
					}
*/

				}
			}
		};
		if(isHouse){
			delete axiosData["query"]["json.facet"];
		}
		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				//console.log(res.docs);
				if(isHouse && res.docs[0]){
					this._getListingData(res.docs[0])
				}
				this.setState({statsRes: res.facets, unitsRes: res.docs.slice(), propFetchInProgress:false});
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	_getBuildingTransactionData = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const { buildingRes } = this.props;
		const { resaleTimePeriod } = this.state;
		const addressQ = buildingRes.alternate_addresses.join('" OR "');
		this.setState({transFetchInProgress:true})
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}"))`
		let fq = ["price:[100000 TO 250000000] AND -property_type:(pa OR sr OR ps)", '-address_2_normalized:""', 'status:sold AND source_namespace:WE3 AND transaction_source:(ACRIS-DOC OR ACRIS)', 'parcels_condocoop_count:[1 TO 2]'];
		
		if(_isHouse(buildingRes)){
			fq = ["price:[100000 TO 250000000] AND -property_type:(pa OR sr OR ps)", 'status:sold AND source_namespace:WE3 AND transaction_source:(ACRIS-DOC OR ACRIS)']
		}
		let axiosData = {
			url: apiURL + "we3-transactions?cache=15",
			method: "post",
			query: {
				q: q,
				fq: fq,
				sort: null,
				wt: "json",
				fl: ['address_2_normalized', 'discount*', 'price', 'close_date*', 'verified_*', 'lender'],
				rows: 999,
				"json.facet": {					
					"sold_prices": {
						"range": {
							"field": "close_date_formatted",
							"start": buildingRes.first_closing_date,
							"end": "NOW+31DAY+1MONTH",
							"gap": "+1YEAR",
							"facet": {
								"by_bedrooms": {
									"type": "terms",
									"field": "bedrooms",
									"facet": {
										"avg_price": "avg(price)",
										"median_price": "percentile(price,50)"
									}
								},
								"ppsf": {
									"type": "query",
									"q": "price_per_sqft:[500 TO 10000]",
									"facet": {
										"avg_ppsf": "avg(price_per_sqft)"
									}
								}
							}
						}
					},
					"min_price" : "min(price)",
					"max_price" : "max(price)",
					"avg_price": "avg(price)",
					"median_price": "percentile(price,50)",
					"sold_range" : {
						"type": "query",
						"q": `price:[1 TO *] AND close_date_formatted:[NOW-${resaleTimePeriod}YEAR TO NOW]`,
						"facet": {
							"min_price" : "min(price)",
							"max_price" : "max(price)",
							"avg_price": "avg(price)",
							"median_price": "percentile(price,50)",
						}
					},
					"dom": {
						"type": "query",
						"q": `days_to_contract:[1 TO 9999] AND close_date_formatted:[NOW-${resaleTimePeriod}YEAR TO NOW]`,
						"facet": {
							"min_dom": "min(days_to_contract)",
							"avg_dom": "avg(days_to_contract)",
							"median_dom": "percentile(days_to_contract,50)",
							"max_dom": "max(days_to_contract)"
						}
					},
					"discount": {
						"type": "query",
						"q": `discount:[-30 TO 30] AND close_date_formatted:[NOW-${resaleTimePeriod}YEAR TO NOW]`,
						"facet": {
							"avg_discount": "avg(discount)"
						}
					},
					"ppsf": {
						"type": "query",
						"q": `price_per_sqft:[200 TO 25000] AND ${buildingRes.building_status === "resale" ? "close_date_formatted:[NOW-" + resaleTimePeriod + "YEAR TO NOW]" : "sponsored_sale:true"} AND parcels_condocoop_count:1 AND property_type:(sc sp mc mp ck)`,
						"facet": {
							"min_ppsf": "min(price_per_sqft)",
							"avg_ppsf": "avg(price_per_sqft)",
							"max_ppsf": "max(price_per_sqft)",
							"sum_sold_price": "sum(price)",
							"sum_sq_ft": "sum(sq_ft)",
						}
					},
					"pct_financed": {
						"type": "query",
						"q": `percent_financed:[1 TO 100] AND close_date_formatted:[NOW-${resaleTimePeriod}YEAR TO NOW]`,
						"facet": {
							"avg_pct": "avg(percent_financed)"
						}
					},
					"count_no_finance": {
						"type": "query",
						"q": "*:*",
						"facet": {
							"no_finance": "avg(percent_financed)"
						}
					},
					"price_changes": {
						"type": "query",
						"q": "*:*",
						"facet": {
							"avg_price_changes": "avg(num_price_changes)"
						}
					},
					"has_bed" : {
						"type": "query",
						"q": `bedrooms:[0 TO *] AND close_date_formatted:[NOW-${resaleTimePeriod}YEAR TO NOW]`,
						"facet": {
							"unit_mix": {
								"type" : "terms",
								"field" : "bedrooms",
								"facet": {
									"avg_unit_size" : "avg(sq_ft)",
									"med_unit_size": "percentile(sq_ft,0,50,100)",
									"non_zero_price": {
										"type": "query",
										"q": `price:[100 TO *] AND sq_ft:[100 TO *]`,
										"facet": {
											"avg_unit_size" : "avg(sq_ft)",
											"med_unit_size": "percentile(sq_ft,25,50,75)",
											
											"avg_ppsf": `avg(div(price, sq_ft))`,
											"min_price": `min(price)`,
											"max_price": `max(price)`
										},
									}
									
								}
							}
						}
					},
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				this.setState({transactionRes: res.facets, transactionDocs: res.docs, transFetchInProgress:false});
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	
	_getBuildingHPDData = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const { buildingRes } = this.props;
		const addressQ = buildingRes.alternate_addresses.join('" OR "');
		
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}"))`
		
		let axiosData = {
			url: apiURL + "we3-hpd-multi-dwelling-registrations?cache=60",
			method: "post",
			query: {
				q: q,
				fq: [],
				sort: "last_registration_date desc",
				wt: "json",
				rows: 10,
			}
		}
		
		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res && res.docs){
					this.setState({hpdRes: res.docs});
				}else{
					this.setState({hpdRes: false});
				}
					
			})
			.catch(error => {
				console.log("error: " + error);
			});

	}

	
	_getActivityData = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		const { buildingRes } = this.props;
		const addressQ = buildingRes.alternate_addresses.join('" OR "');
		
		let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}"))`
		
		let axiosData = {
			url: apiURL + "we3-activities-3?cache=3600",
			method: "post",
			query: {
				q: q,
				fq: [..._activityFQ.slice(), "(topic:listings AND event_tags:contract-signed AND -event_tags:rent AND -address_2_normalized:\"\" AND -event_tags:*commercial* AND event_tags:(\"event-stage_sponsor\"))"],
				wt: "json",
				rows: 0,
				"json.facet": {
					"sold_1_yr": {
						"type": "query",
						"q": `time:[NOW-1YEAR TO NOW]`
						
					},
					"sold_3_mo": {
						"type": "query",
						"q": `time:[NOW-3MONTH TO NOW]`
						
					},
					"sold_6_mo": {
						"type": "query",
						"q": `time:[NOW-6MONTH TO NOW]`
						
					},
					
				}
			}
		}
		
		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res && res.facets){
					this.setState({activityStatsRes: res.facets});
				}else{
					this.setState({activityStatsRes: false});
				}
					
			})
			.catch(error => {
				console.log("error: " + error);
			});

	}
	
	_getListingData = async (unit) => {
			const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
			const { buildingRes } = this.props;		
			const q = `key:${unit.last_listed_sale_key_all.replace("REBNY-LISTING-", "*")}`;
	
			let axiosData = {
				url: apiURL + "search-listings?cache=15",
				method: "post",
				query: {
					q: q,
					//fq: ["deleted:false"],
					sort: "solr_updated_date desc",
					wt: "json",
					rows: 10
				}
			};
	
			let formattedData = await _axiosCall(axiosData)
				.then(res => {
					// console.log('Chart Data', res);
					if(res.docs && res.docs.length>0){					
						this.setState({listing: res.docs[0]});
					}else{
						this.setState({listing: false});
					}
					
				})
				.catch(error => {
					console.log("error: " + error);
				});
			return formattedData;
	  };

  render() {
    const { buildingRes, rtl, user, viewedBuildings, offeringPlan } = this.props;
    const { activeTab, statsRes, unitsRes, gatedView, transactionRes, hpdRes, transactionDocs, activityStatsRes, resaleTimePeriod } = this.state;
    
    if(gatedView){
	     return <TrialLimit />;
	}
       
    {/* if(!statsRes){
	    return <LinearLoading />;
    } */}
    
    const _boro = _urlToName(this.props.match.params.borough);
	const _hood = _urlToName(this.props.match.params.neighborhood);
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	const floorPlans = unitsRes ? unitsRes.filter(unit => unit.has_floorplans_resized == true || unit.offering_floorplans_count > 0) : null;
	let isCoop = _isCoop(buildingRes);
	let isHouse = _isHouse(buildingRes);
	
	if(isHouse && !unitsRes) return null;
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>          	
            <div className="card__title">
            	<Row>	        
	            	<Tooltip title="Back to Buildings">	            		
		              	<IconButton	className="material-table__toolbar-button" onClick={() => this.props.history.go(-1) }>
						  <ArrowBackIcon />
					  	</IconButton>
				  	</Tooltip>				
					<Tooltip title="See building profile on Marketproof">	            		
		              	<IconButton	
		              		className="material-table__toolbar-button"
		              		onClick={(e) => {e.stopPropagation();window.open(`https://marketproof.com/${_getBuildingURL(buildingRes, 'building_slugs').replace('buildings', 'building')}`)}}
		              	>
						  <OpenInNewIcon /> 
					  	</IconButton>
				  	</Tooltip>
				  	<AddToBuyersListButton buildingKey={buildingRes.key} />
				  	{_hasPermission(user, "admin-tools") &&  
					  	<Fragment>
					  		{buildingRes.offering_plan_documents && buildingRes.offering_plan_documents[0] &&
							  	<Tooltip title="Download Offering Plan (Admin Only)">
			                      	<IconButton
							            onClick={(e) => {e.stopPropagation();window.location.href=buildingRes.offering_plan_documents[0];}}
							            className="material-table__toolbar-button"
							          >
			
									  <DocIcon />
								  	</IconButton>
							  	</Tooltip>	
						  	}
						  	
					  	</Fragment>					  	
				  	}
				  	
				  	{_hasPermission(user, "ag-link") && buildingRes.offering_file_number && buildingRes.offering_file_number !== '' &&
					  	<Tooltip title="See Offering Plan on AG Website">
	                      	<IconButton
					            onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`https://offeringplandatasearch.ag.ny.gov/REF/planFormServlet?planId=${buildingRes.offering_file_number}`); }}
					            className="material-table__toolbar-button"
					          >
	
							  <OpenIcon />
						  	</IconButton>
					  	</Tooltip>
					}
				  	
				  	<div className="top__toolbar" style={{display:"flex"}}>
				  		{/*<AddToBuyersListButton buildingKey={buildingRes.key} other={`test`} />
				  		<AISummaryButton  buildingRes={buildingRes} transactionRes={transactionRes} statsRes={statsRes} activityStatsRes={activityStatsRes} />*/}
				  		{!isMobile && <OwnerListButton 
				  			q={this.q}
							fq={["-deleted:true", "-offering_unit_abandoned:true", '-address_2_normalized:""', 'property_type:(*condo* \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* other *apartment*) AND -property_type:(*commercial*)', 'owner:* AND -owner:""', '-property_status:(sponsor)']}
							fl={mailingListFL}
							sort={"floor asc, address_2_normalized asc"}
							core_name={'we3-properties'}
							export_name={`Owner List - ${buildingNameOrAdr}`} 
							title={'Export Owner List'}  
			          		description={<Fragment><h4><strong>Export owner list for this building.</strong></h4> <br/>   The export will include mailing address and owner name of resale units.</Fragment>}
				  		
				  		/>}
				  		{['pipeline', 'sponsor'].indexOf(buildingRes.building_status) >=0 &&
				  			<ContactDeveloperButton  buildingRes={buildingRes}  />
				  		}
				  		<ShareButton  buildingRes={buildingRes} other={`test`} />
				  		{false && ['sponsor'].indexOf(buildingRes.building_status) >=0 && (_hasPermission(user, 'stacked-report')) && 
					  		 <ReportButton  buildingRes={buildingRes} />
					  	}
						<AddCollectionButton buildingRes={buildingRes} />
				    </div>
				</Row>
				
				 <div className="unit__badge">
			   
			   		{buildingRes.offering_construction === 'conversion' && 
				   		<StatusBadge status={buildingRes.offering_construction} unit={buildingRes}/>	
			   		}
					   {isHouse && unitsRes && unitsRes[0] &&
						  <HouseCurrentStatus status={unitsRes[0].current_status} unit={unitsRes[0]} expanded /> 
							
					   }
			    </div>
               <h3 className="page-title">{buildingNameOrAdr} <BuildingIcons building={buildingRes} /></h3>
		       {isMobile ?
	               <Fragment>
	              <h3 className="page-subhead subhead">			        	
						{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
						{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
				  </h3> 
				  
				  </Fragment>
	           :
			      <h3 className="page-subhead subhead">			        	
							{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>} {_capitalizeText(_hood)}, {_capitalizeText(_boro)}
			      </h3>
		       }	
            </div>
            <div className="tabs tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'overview' })}
                      onClick={() => {
                        this.toggle('overview');
                      }}
                    >
                      <span className="tab-title">Overview</span>
                    </NavLink>
                  </NavItem>
                  {!isHouse && <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'analysis' })}
                      onClick={() => {
                        this.toggle('analysis');
                      }}
                    >
                      <span className="tab-title">Analysis</span>
                    </NavLink>
                  </NavItem>}
				  {((!isHouse && !isCoop) || buildingRes.offering_verified_status == "active") &&
                   <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'stacking-plans' })}
                      onClick={() => {
                        this.toggle('stacking-plans');
                      }}
                    >
                      <span className="tab-title">Stacking Plans</span>
                    </NavLink>
                  </NavItem> 
			  	}
				  
	              <Fragment>	              	
	                 {!isHouse && <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'units' })}
	                      onClick={() => {
	                        this.toggle('units');
	                      }}
	                    >
	                      <span className="tab-title">Units</span>
	                    </NavLink>
	                  </NavItem>
				 	 }
					 
					 <NavItem>
						  <NavLink
							className={classnames({ active: activeTab === 'listings' })}
							onClick={() => {
							  this.toggle('listings');
							}}
						  >
							<span className="tab-title">Listings</span>
						  </NavLink>
					 </NavItem>          
	                  <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'sales' })}
	                      onClick={() => {
	                        this.toggle('sales');
	                      }}
	                    >
	                      <span className="tab-title">Sales</span>
	                    </NavLink>
	                  </NavItem>
					  {isHouse && <NavItem>
						<NavLink
						  className={classnames({ active: activeTab === 'taxes' })}
						  onClick={() => {
							this.toggle('taxes');
						  }}
						>
						  <span className="tab-title">Taxes</span>
						</NavLink>
					  </NavItem>
					  }
					  <NavItem>
						  <NavLink
							className={classnames({ active: activeTab === 'filings' })}
							onClick={() => {
							  this.toggle('filings');
							}}
						  >
							<span className="tab-title">Filings</span>
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({ active: activeTab === 'environment' })}
							onClick={() => {
							  this.toggle('environment');
							}}
						  >
							<span className="tab-title">Environment</span>
						  </NavLink>
						</NavItem> 
					 <NavItem>
						<NavLink
						  className={classnames({ active: activeTab === 'layout' })}
						  onClick={() => {
							this.toggle('layout');
						  }}
						>
						  <span className="tab-title">Layout</span>
						</NavLink>
					  </NavItem> 
					 
	                  <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'news' })}
	                      onClick={() => {
	                        this.toggle('news');
	                      }}
	                    >
	                      <span className="tab-title">News</span>
	                    </NavLink>
	                  </NavItem>
	                
	                 
	   
	                  {/*  <NavItem>	                  
	                  <NavLink
	                      className={classnames({ active: activeTab === 'qa' })}
	                      onClick={() => {
	                        this.toggle('qa');
	                      }}
	                    >
	                      <span className="tab-title">Q & A</span>
	                    </NavLink> 
	                  </NavItem>*/}
	               {/*}   
	                  <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'buyerslist' })}
	                      onClick={() => {
	                        this.toggle('buyerslist');
	                      }}
	                    >
	                      <span className="tab-title">BuyersList</span>
	                    </NavLink>
	                  </NavItem>
	               */}
                  </Fragment> 
                                
                </Nav>                
              </div>            
            </div>
            <TabContent activeTab={activeTab}>
             {activeTab === 'overview' &&
              <TabPane tabId="overview">
               	<Profile statsRes={statsRes} transactionDocs={transactionDocs} transactionRes={transactionRes} unitsRes={unitsRes} {...this.props} toggle={this.toggle} hpdRes={hpdRes} activityStatsRes={activityStatsRes} unitQuery={this.q} listing={this.state.listing}/>
              </TabPane>
              }
              {activeTab === 'analysis' &&
              <TabPane tabId="analysis">
              	<Analysis statsRes={statsRes} unitsRes={unitsRes} {...this.props} {...this.state} transactionRes={transactionRes} transactionDocs={transactionDocs} activityStatsRes={activityStatsRes}  setResalePeriod={this.setResalePeriod}/>               
              </TabPane>
              }
              {activeTab === 'stacking-plans' && (!isCoop || buildingRes.offering_verified_status == "active") &&
              <TabPane tabId="stacking-plans">
              	<StackingPlans statsRes={statsRes} unitsRes={unitsRes} {...this.props} transactionRes={transactionRes} transactionDocs={transactionDocs}/>              
              </TabPane>
              }
			  {activeTab === 'environment' &&
				<TabPane tabId="environment">
					<Environment statsRes={statsRes} unitsRes={unitsRes} {...this.props} floorPlans={floorPlans}/>              
				</TabPane>
				}
   	          <Fragment>	          
	          	{activeTab === 'units' && 
	              <TabPane tabId="units">
	                <Units statsRes={statsRes} unitsRes={unitsRes} {...this.props} transactionRes={transactionRes} transactionDocs={transactionDocs}/>
	              </TabPane>
	            }
				{activeTab === 'layout' &&
				  <TabPane tabId="layout">
					  <Plans statsRes={statsRes} unitsRes={unitsRes} {...this.props} floorPlans={floorPlans}/>              
				  </TabPane>
				  }
	            {activeTab === 'sales' && 
	              <TabPane tabId="sales">
	                <Sales statsRes={statsRes} unitsRes={unitsRes} {...this.props} />
	              </TabPane>
	            }
				{activeTab === 'listings' && 
				  <TabPane tabId="listings">
					<Listings statsRes={statsRes} unitsRes={unitsRes} {...this.props} />
				  </TabPane>
				}
	            {/*activeTab === 'buyerslist' && 
	              <TabPane tabId="buyerslist">
	                <BuyersList />
	              </TabPane>
	            */}
              </Fragment> 
               
              {activeTab === 'news' &&
              <TabPane tabId="news">              	
	             <Fragment>	             	
				    <Row className="news__container">
				      <NewsTimeline {...this.props} />
				    </Row>
				 </Fragment>		              	              	               	
              </TabPane>
              }
             
			  {activeTab === 'qa' &&
              <TabPane tabId="qa" className="qa__container">
              		<QA {...this.props} />		              	           
              </TabPane>
              }
			  {activeTab === 'filings' &&
					<TabPane tabId="filings" className="financing__container">
							<Documents {...this.props} />              	           
					</TabPane>
				}
            
			{activeTab === 'details' &&
				<TabPane tabId="details" className="financing__container">
					<BuildingProfile
			  			buildingDetails={buildingRes}
			  			{...this.props}
				/>
				</TabPane>
			}
			{(isHouse && activeTab === 'taxes' && unitsRes && unitsRes[0]) &&
				<TabPane tabId="taxes" className="" style={{minHeight:"50vh"}}>
					<TaxAbatement property={unitsRes[0]}  {...this.props}  />              	           
				</TabPane>
			}
              
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
	console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings) ? state.buyersLists.viewedBuildings.slice() : [],
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(BuildingTabContainer)));
