import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';

import BuildingSearchAppBar from '../../../shared/components/building_filters/UnitsSearchAppBar';

import UnitStageFilter from '../../../shared/components/unit_filters/UnitStageFilter';

import LinearLoading from '../../../shared/components/LinearLoading';

import NewDevelopment from './NewDevelopment';

import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import { Tooltip } from '@material-ui/core';
import moment from "moment";

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


import ChartIcon from 'mdi-react/ChartAreasplineIcon';
import ListIcon from 'mdi-react/FormatListBulletedIcon';


import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_formatBed,
	propertyFL,
	SalesProgress,
	_isEqual,
	_tranformTagsQuery,
	syncURLFilters,
	syncURLSearch,
	setURLFromState,
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
	syncStageFilters,
	mpndCohortQuery,
	_objectEqual,
	getCohortQuery,
	recentCohortQuery,
	_validUnitSources
} from "../../../shared/helpers/utils";

import { 
	_unitFilters,
	_buildingStageFilter,
	_unitStageFilter
} from "../../../shared/helpers/filters";
import { _hasPermission	} from "../../../shared/helpers/access";

let counter = 0;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#A3ADC2',
   
    '&$checked': {
      color: '#A3ADC2',
    },
    '&$checked + $track': {
      backgroundColor: '#D1D6E0',
    },
  },
  checked: {},
  track: { backgroundColor: '#D1D6E0' },
})(Switch);

const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) < 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}


const buildingUrl = process.env.API_ROOT + '/query/';
const lsPre = "IA5_";
const subFilterKey = 'subFilters';
const defaultStage = ["sponsor"];
const defaultUnitStage = ['sponsor'];
const buildingStagesKey = 'buildingStages';
const unitStagesKey = 'unitStages';
const defaultSubFilter = {current_status : ["available"], property_type : ["residential"]};
class AnalysisSearchContainer extends PureComponent {
  defaultStage = _hasPermission(this.props.user, 'sponsor-buildings') && _hasPermission(this.props.user, 'resale-buildings') ? ['all'] : _hasPermission(this.props.user, 'sponsor-buildings') ? ["sponsor"] : ['resale'];
  defaultUnitStage = _hasPermission(this.props.user, 'sponsor-buildings') ? ['sponsor'] : ['all'];
  state = {
    order: !this.props.buildingView ? "desc" : "",
    orderBy: !this.props.buildingView ? "count" : "",
    selected: new Map([]),
    page: 0,
    rowsPerPage: 50,
    fetchInProgress: false,
    buildingsFetchInProgress: false,
	clearInput: false,
	end: 30,
	filterBorough: "all_boroughs",
	searchText: "",
	futureInventoryData: false,
	inventoryData: false,
	originalData: false,
	ovelayMinHeight: false,
	reactTableFiltered: [],
	showCounts: {desktop: 3, tablet: 2, mobile: 1},
	start: 0,
	stats: {},
	subFilters: syncURLFilters(this.props, _unitFilters, subFilterKey, lsPre, defaultSubFilter),
	tableData: false,
	totalUnsoldUnits: false,
	totalCount: 0,
	groupedView: this.props.buildingView ? false : true,
	facetData:false,
	groupedCount:false,
	buildingKey:false,
	unitListData:false,
	soldData:false,
	unitStages: this.defaultUnitStage, // syncStageFilters(this.props, _unitStageFilter, unitStagesKey, lsPre, defaultUnitStage),
	buildingStages: this.defaultStage //syncStageFilters(this.props, _buildingStageFilter, buildingStagesKey, lsPre, defaultStage)
  };
  
  defaultQ = `((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND -address_2_normalized:\"\") OR (current_status:(active \"in contract\")) OR ((verification_source:(${_validUnitSources}) OR ownership_type:co*cop) AND -address_2_normalized:\"\") OR building_class:R3)`; //"offering_verified_status:active AND source_organizations:NYAG AND baths:[1 TO *] AND offering_price:* AND -offering_file_number:\"\" AND offering_file_number:* AND -offering_construction:(conversion)";
  
 defaultBuidingQ = getCohortQuery(this.props.user);
 
  _propertyFilter = _unitFilters.filter(filter => !filter.filter_type);
  _buildingFilter = _unitFilters.filter(filter => filter.filter_type && filter.filter_type === 'building');
   
  q='';
  fq = [		
		`-offering_verified_status:stalled`,
		"deleted:false",
		`-offering_unit_abandoned:true`,
		`building_class:R3 OR address_2_normalized:?*`
	];
	
  fl = propertyFL;
  _unitSubFilter = _unitFilters.filter(filter => !(this.props.buildingView && filter.building_hide) && !filter.hide_analysis);
  
  componentDidMount() {
  	this._getInventoryUnits();
  	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
	  	let searchTagsChange = !_isEqual(prevProps.searchTags, this.props.searchTags);
	  	let unitStageChange = !_isEqual(prevState.unitStages, this.state.unitStages);
	  	let buildingStageChange = !_isEqual(prevState.buildingStages, this.state.buildingStages);
	console.log(this.state.subFilters, prevState.subFilters)
	    let subFilterChanged =
			this._unitSubFilter.filter(item => {
				return (
					Array.isArray(item.search_key) ? 
						item.search_key.filter(key => this.state.subFilters[key] !== prevState.subFilters[key]).length > 0						
					:
						this.state.subFilters[item.search_key] !== prevState.subFilters[item.search_key]
				);
			}).length > 0;
			
		if (
			this.state.filterBorough !== prevState.filterBorough ||
			this.state.searchText !== prevState.searchText ||
			this.state.orderBy !== prevState.orderBy ||
			this.state.order !== prevState.order ||
			this.state.page !== prevState.page ||
			this.state.groupedView !== prevState.groupedView ||
			(this.state.buildingKey !== prevState.buildingKey && this.state.buildingKey !== false) ||
			searchTagsChange ||
			subFilterChanged ||
			unitStageChange ||
			buildingStageChange
			) {
			  const resetPage = this.state.page === prevState.page;
			  this.defaultBuidingQ = getCohortQuery(this.props.user);
			  this._getInventoryUnits(resetPage);
		}

	  
  }
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { tableData } = this.state;
      const newSelected = new Map();
      tableData.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
	event.stopPropagation();
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };
  
  handleBuildingClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building, 'building') + '/' + _nameToUrl(building.address_2_normalized);
	 this.props.history.push(buildingURL);
  };
  
  handleGroupedBuildingClick = (event, building) => {
	  
	 event.stopPropagation();
	 console.log(building);
	 const buildingURL = _getBuildingURL(building, 'key');
	 
	 this.props.history.push(buildingURL);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, start: page * this.state.rowsPerPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { tableData } = this.state;
    let copyData = [...tableData];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ tableData: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };
  
  handleExpandPanel(e, expanded, buildingKey){
	  console.log(e, expanded, buildingKey);	
	  if(expanded){
		  this.setState({buildingKey: buildingKey});
	  }  
	  
  }
  
  _getInventoryUnits = async (resetPage) => {
		this.q = this.defaultQ;
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, facetData:false, tableData:false, reactTableFiltered:false }));
		}
		
		const { searchTags } = this.props;
		const { unitStages, buildingStages } = this.state;
		let locQuery = _tranformTagsQuery(searchTags, 'building');


		if(locQuery){
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += `${locQuery}`;
		}


		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const theFilter = this._propertyFilter.filter(filter => filter.search_key === key);
					
					if(theFilter && theFilter[0] && theFilter[0].multi_filter){
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
									? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
									: null;
						if(qVal!==null){					
							this.q += this.q.length > 0 ? " AND " : "";
							this.q += `(${key}:(${qVal.join(" ")}))`;	
						}		
					}else{
					
						const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
									: null;
						const additionalFQ = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => value.filter === subFilterVal).length>0 
									&& theFilter[0].values.filter(value => value.filter === subFilterVal)[0].additional_fq
									? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].additional_fq
									: false;
						
						if(qVal!==null){
							
							if(theFilter[0].target && theFilter[0].target === 'fq'){
								this.unitFq.push(qVal);
							}else{							
								this.q += this.q.length > 0 ? " AND " : "";
								this.q += `(${key}:${qVal})`;
								
							}
							
						}
						
						if(additionalFQ){
							this.unitFq = this.unitFq.concat(additionalFQ);
						}
					}
					
				}
			});		
		}
		let fq = this.fq.slice();
		// const isShadow = this.state.subFilters.current_status && ['available', 'shadow'].indexOf(this.state.subFilters.current_status)>=0;
		// const isContract = this.state.subFilters.current_status && this.state.subFilters.current_status === 'in-contract';		
		// const isAvailable = this.state.subFilters.current_status && ['available', 'active', 'shadow'].indexOf(this.state.subFilters.current_status)>=0;
		/****Price & Date query***/
		let priceQ = {};
		let dateQ = '';

		let replaceKeys = {};
		let dateReplaceKeys = [];
		if (
			this.state.subFilters &&
			Object.keys(this.state.subFilters).length > 0
		) {
			Object.keys(this.state.subFilters).map(key => {
				let subFilterVal = this.state.subFilters[key];
				if (subFilterVal && subFilterVal != "") {
					const qArrayFilter = this._propertyFilter.filter(filter => filter.price_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(qArrayFilter.length>0){	
						
						
						const filterKey = qArrayFilter[0].filter_key;
						console.log("qArrayFilter", qArrayFilter);
						if(replaceKeys[filterKey]){
							replaceKeys[filterKey]  = [...replaceKeys[filterKey], ...qArrayFilter[0].search_key];
						}else{
							replaceKeys[filterKey] = [...[], ...qArrayFilter[0].search_key]
						}				
						if(priceQ[filterKey]){
							let regex = new RegExp(`{${key}}`, "g");
							priceQ[filterKey] = priceQ[filterKey].replace(regex, subFilterVal)
						}else{
							if(filterKey == "asking_price"){
								priceQ[filterKey] = 'last_listed_sale_price_all:[{min_price} TO {max_price}]'.replace(`{${key}}`, subFilterVal);
							}else{
								priceQ[filterKey] = qArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, subFilterVal)).join(' OR ');
							}
							
						}											
					}	
					
					const dateArrayFilter = this._propertyFilter.filter(filter => filter.date_filter && Array.isArray(filter.search_key) && filter.search_key.filter(skey => skey === key).length>0)
					
					if(dateArrayFilter.length>0){	
						dateReplaceKeys = [...dateReplaceKeys, ...dateArrayFilter[0].search_key];					
						if(dateQ){
							let regex = new RegExp(`{${key}}`, "g");
							dateQ = dateQ.replace(regex, moment(subFilterVal).toISOString())
						}else{		
							dateQ = dateArrayFilter[0].search_value.map(search => search.replace(`{${key}}`, moment(subFilterVal).toISOString())).join(' OR ');							
							
						}											
					}										
				}
			});		
		}	
		console.log("priceQ", priceQ, replaceKeys)
		if(priceQ && Object.keys(priceQ).length>0){
			
			Object.keys(priceQ).map(filterKey => {
				const qArrayFilter = this._propertyFilter.filter(filter => filter.filter_key && filter.filter_key == filterKey )[0];
				replaceKeys[filterKey].map(key => {
					let regex = new RegExp(`{${key}}`, "g");
					if(key.indexOf("min")>=0){
						priceQ[filterKey] = priceQ[filterKey].replace(regex, '0')
					}else{
						if(key.indexOf("max")>=0 && qArrayFilter.target && qArrayFilter.target=="fq"){
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '99999999')
						}else{
							priceQ[filterKey] = priceQ[filterKey].replace(regex, '*')
						}
						
					}
					
				
				})
				
				if(qArrayFilter.target && qArrayFilter.target =="fq"){
					fq.push(priceQ[filterKey]);
				}else{
					this.q += this.q.length > 0 ? " AND " : "";
					this.q += '(';
					this.q += priceQ[filterKey];
					this.q += ')';	
				}
				
				
			})
					
		}
		if(dateQ){
			dateReplaceKeys.map(key => {
				let regex = new RegExp(`{${key}}`, "g");
				dateQ = dateQ.replace(regex, '*')
			
			})
			this.q += this.q.length > 0 ? " AND " : "";
			this.q += '(';
			this.q += dateQ;
			this.q += ')';			
		}
		/****Price& Date query***/
				
		
		// if(unitStages && unitStages.length>0){
		// 	if(unitStages.indexOf("all")<0){
		// 		this.q += this.q.length > 0 ? " AND " : "";
		// 		this.q += `property_status:("${unitStages.join("\" OR \"")}")`;
		// 		this.q += ` AND (source_organizations:(NYAG CDEC ADEC) OR verification_source:(${_validUnitSources}))`;
		// 	}
		// 	
		// }
		
		
		
		let defaultBuidingQ = this.defaultBuidingQ;
		
		if(buildingStages && buildingStages.length>0){
			if(buildingStages.indexOf("all")<0){
				defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
				defaultBuidingQ += `building_status:("${buildingStages.join("\" OR \"")}")`;

			}
						
		}
		if (this.state.subFilters &&
				Object.keys(this.state.subFilters).length > 0
			) {
				Object.keys(this.state.subFilters).map(key => {
					let subFilterVal = this.state.subFilters[key];
					if (subFilterVal && subFilterVal != "") {
						const theFilter = this._buildingFilter.filter(filter => filter.search_key === key);
					
						if(theFilter && theFilter[0] && theFilter[0].multi_filter){
							const qVal = theFilter.length>0 && theFilter[0].values.length>0 && theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).length>0
										? theFilter[0].values.filter(value => subFilterVal.indexOf(value.filter)>-1).map(value => value.search_value)
										: null;
							if(qVal!==null){					
								defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
								defaultBuidingQ += `(${key}:(${qVal.join(" ")}))`;	
							}		
						}else{
							const qVal = theFilter && theFilter.length>0 
										? theFilter[0].values.filter(value => value.filter === subFilterVal)[0].search_value
										: null;
							if(qVal!==null){
								defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
								defaultBuidingQ += `(${key}:${qVal})`;
							}
						}
						
					}
				});
		}
/*
		if(isShadow){
			defaultBuidingQ += defaultBuidingQ.length > 0 ? " AND " : "";
			defaultBuidingQ += `${recentCohortQuery}`;
		}
*/
		let bldgQ = `{!join from=key fromIndex=we3-buildings to=building}${defaultBuidingQ}`;
		fq.push(bldgQ);
		
		setURLFromState(this.props, this.state);
		
		this.q = this.q.length > 0 ? this.q : "*:*";
		
		let sort = this.state.groupedView ? "count desc" : "full_street_address asc, address_2_normalized asc";
		if(this.state.orderBy && this.state.orderBy.length > 0){
			sort = `${this.state.orderBy} ${this.state.order}`;
		}
		const priceStartDate = (buildingStages.indexOf("resale") > -1 || buildingStages.indexOf("all") > -1 ) ? moment().subtract(2, 'years').unix() : 0;
		
		let axiosData = {
			url: buildingUrl + "we3-properties?cache=60",
			method: "post",
			query: {
				q: this.q,
				fq: fq,
				sort: !this.state.groupedView ? sort : null,
				fl: this.fl,
				rows:this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				wt: "json",
				
/*
				rows: this.state.rowsPerPage,
				start: resetPage ? 0 : this.state.start,
				"group.field":"building",
				"group.sort":"offering_price asc",
				"group.ngroups": true,
				"group":this.state.groupedView,	
*/			
				
			}
		};
		
		if(this.state.groupedView){
			axiosData.query.rows = 0;
			axiosData.query.start = 0;
			axiosData.query["json.facet"] = {
					"building_count" : "unique(building)",
					"non_zero_price": {
						"type": "query",
						"q": "offering_price:[300000 TO *] AND sq_ft:[100 TO *]",
						"facet": {
							"low_price": "min(offering_price)",
							"high_price": "max(offering_price)",
							"average_price": "avg(offering_price)",
							"average_sq_ft": "avg(offering_sq_ft)",
							"avg_ppsf": "avg(div(offering_price, sq_ft))",
							"sum_price": "sum(offering_price)",
							"sum_sq_ft": "sum(offering_sq_ft)",
							"low_sq_ft": "min(offering_sq_ft)",
							"high_sq_ft": "max(offering_sq_ft)",
							"low_bed": "min(offering_bedrooms)",
							"high_bed": "max(offering_bedrooms)",
							"by_neighborhood" : {
								"type" : "terms",
								"field" : "neighborhood",
								"sort" : "avg_ppsf desc",
								"limit" : 20,
								"facet" : {
									"avg_price": "avg(offering_price)",
									"avg_ppsf": "avg(div(offering_price, sq_ft))",
								}
							},
							
						}
					},
					"by_status" : {
						"type" : "terms",
						"field" : "current_status",
						"facet": {
							"non_zero_price": {
								"type": "query",
								"q": "last_listed_sale_price_all:[100 TO *] AND sq_ft:[100 TO *]",
								"facet": {
									"sum_price" :  "sum(last_listed_sale_price_all)",
								},
							},
							
							
							"by_status" : {
								"type" : "terms",
								"field" : "current_status"
							},
							
						}
					},
					"unsold": {
						"type": "query",
						"q": '(current_status:((((current_status:shadow AND -ownership_type:(*co?op* *coop* !condo*p)) AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW])) OR (current_status:transfer AND -offering_construction:conversion AND property_status:sponsor AND (offering_submitted_date:[2010-11-01T00:00:00Z TO NOW] OR offering_accepted_date:[2010-11-01T00:00:00Z TO NOW]))) (active))) AND (property_type:((*residential*condo* condo *co-op* \"condo unit\" \"resident manager unit\" *coop* *affordable* \"\" *dwelling* *timeshare* *residential* *apartment*))) AND property_status:(\"sponsor\") AND (source_organizations:(NYAG CDEC ADEC) OR (verification_source:(user-verified-valid CDEC ADEC NYCDOF NYAG dof-acris*) OR ownership_type:co*cop) OR current_status:active)',
						
					},
					"sponsor_status": {
						"type": "query",
						"q": "property_status:sponsor",
						"facet" : {
							"current_status": {
								"type" : "terms",
								"field" : "current_status",
							}
						}
					},
					"resale_status": {
						"type": "query",
						"q": "property_status:resale",
						"facet" : {
							"current_status": {
								"type" : "terms",
								"field" : "current_status",
							},
						}
						
					},
					"by_neighborhood" : {
						"type" : "terms",
						"field" : "neighborhood",
						"limit" : 20,
						"facet" : {
							"by_status" : {
								"type" : "terms",
								"field" : "offering_status"
							},
							"borough" :{
								"type" : "terms",
								"field" : "borough"
							}
						}
					},
					"listed_non_zero_price": {
								"type": "query",
								"q": `(last_listed_sale_price_all:[300000 TO 200000000] AND last_listed_sale_date:[${priceStartDate} TO *]) AND sq_ft:[100 TO 10000]`,
								"facet": {
									"avg_unit_size" : "avg(sq_ft)",
									"med_unit_size": "percentile(sq_ft,25,50,75)",
									"avg_fees" : "avg(monthly_fees)",
									"med_fees": "percentile(monthly_fees,25,50,75)",
									"avg_taxes" : "avg(monthly_taxes)",
									"med_taxes": "percentile(monthly_taxes,25,50,75)",
									"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
									"min_price": "min(last_listed_sale_price_all)",
									"max_price": "max(last_listed_sale_price_all)",
									"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
								},
					},
					"unit_mix" : {
						"type" : "terms",
						"field" : "bedrooms",
						"facet": {
							"non_zero_price": {
								"type": "query",
								"q": `(last_listed_sale_price_all:[300000 TO 200000000] AND last_listed_sale_date:[${priceStartDate} TO *]) AND sq_ft:[100 TO 20000] AND monthly_fees:[100 TO *]`,
								"facet": {
									"avg_unit_size" : "avg(sq_ft)",
									"med_unit_size": "percentile(sq_ft,25,50,75)",
									"avg_fees" : "avg(monthly_fees)",
									"med_fees": "percentile(monthly_fees,25,50,75)",
									"avg_taxes" : "avg(monthly_taxes)",
									"med_taxes": "percentile(monthly_taxes,25,50,75)",
									"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
									"min_price": "min(last_listed_sale_price_all)",
									"max_price": "max(last_listed_sale_price_all)",
									"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
								},
							},
							"by_status" : {
								"type" : "terms",
								"field" : "current_status"
							},
							"unsold": {
								"type": "query",
								"q": '((((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND last_listed_sale_price_all:* AND -offering_file_number:"") OR (-offering_verified_status:active AND source_organizations:(CDEC ADEC) AND property_type:(*condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial*))  AND -address_2_normalized:"") OR current_status:active) AND (current_status:(active transfer) OR (current_status:shadow AND -offering_construction:conversion)) AND property_status:sponsor'
							}
							
						}
					},
					"4_more_beds": {
						"type": "query",
						"q": "bedrooms:[4 TO *]",
						"facet": {
							"non_zero_price": {
								"type": "query",
								"q": `(last_listed_sale_price_all:[300000 TO 200000000] AND last_listed_sale_date:[${priceStartDate} TO *]) AND sq_ft:[100 TO *] AND monthly_fees:[100 TO *]`,
								"facet": {
									"avg_unit_size" : "avg(sq_ft)",
									"med_unit_size": "percentile(sq_ft,25,50,75)",
									"avg_fees" : "avg(monthly_fees)",
									"med_fees": "percentile(monthly_fees,25,50,75)",
									"avg_taxes" : "avg(monthly_taxes)",
									"med_taxes": "percentile(monthly_taxes,25,50,75)",
									"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
									"min_price": "min(last_listed_sale_price_all)",
									"max_price": "max(last_listed_sale_price_all)",
									"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
								},
							},
							"avg_unit_size" : "avg(offering_sq_ft)",
							"med_unit_size": "percentile(offering_sq_ft,25,50,75)",
							"avg_fees" : "avg(offering_monthly_fees)",
							"med_fees": "percentile(offering_monthly_fees,25,50,75)",
							"avg_taxes" : "avg(offering_monthly_taxes)",
							"med_taxes": "percentile(offering_monthly_taxes,25,50,75)",
							"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
							"min_price": "min(last_listed_sale_price_all)",
							"max_price": "max(last_listed_sale_price_all)",
							"avg_monthly_carrying_charges": "avg(offering_monthly_carrying_charges)",
							"unsold": {
								"type": "query",
								"q": '((((offering_verified_status:active AND source_organizations:NYAG AND offering_baths:[1 TO *] AND last_listed_sale_price_all:* AND -offering_file_number:"") OR (-offering_verified_status:active AND source_organizations:(CDEC ADEC) AND property_type:(*condo* "resident manager unit" *coop* *affordable*) AND -property_type:*commercial*))  AND -address_2_normalized:"") OR current_status:active) AND (current_status:(active) OR (current_status:shadow AND -offering_construction:conversion)) AND property_status:sponsor'
							}
							
						},
						
					},
					"4_more_beds_status": {
						"type": "query",
						"q": "bedrooms:[4 TO *]",
						"facet": {
							"by_status" : {
								"type" : "terms",
								"field" : "offering_status"
							}
						},
					},
					"avg_price": "avg(last_listed_sale_price_all)",
					"avg_sq_ft": "avg(offering_sq_ft)",
					"low_bed": "min(offering_bedrooms)",
					"sold_units": {
						"type": "query",
						"q": "offering_status:(sold \"in contract\" not_yet_recorded hold resale) AND last_listed_sale_price_all:[100000 TO 200000000]",
						"facet": {
							"avg_price" : "avg(last_listed_sale_price_all)",
							"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",

						},
					},
					"unsold_units": {
						"type": "query",
						"q": `-offering_status:("sold", "not_yet_recorded", "closed", "resale", "bulk sold") AND property_status:sponsor AND -offering_construction:conversion`,
						"facet": {
							"avg_price" : "avg(last_listed_sale_price_all)",
							"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",
							"by_neighborhood" : {
								"type" : "terms",
								"field" : "neighborhood",
								"limit" : 20,
								"facet" : {
									"by_status" : {
										"type" : "terms",
										"field" : "offering_status"
									},
									"borough" :{
										"type" : "terms",
										"field" : "borough"
									}
								}
							},

						},
					},
					"listed_units": {
						"type": "query",
						"q": "-offering_status:(\"\")",
						"facet": {
							"avg_price" : "avg(last_listed_sale_price_all)",
							"avg_ppsf": "avg(div(last_listed_sale_price_all, sq_ft))",

						},
					},
				}
					
				
			
		}

	
		let response = await _axiosCall(axiosData)
			.then(res => {
				return res;
			})
			.catch(error => {
				console.log("error: " + error);
			});
			
		this._getSoldData(this.q, bldgQ);
		if(this.state.groupedView){
			
			let facetData = response && response.facets ? response.facets : {};
			facetData.numFound = response.numFound;
			
			this.setState(prevState => ({
				fetchInProgress: false,
				facetData:  facetData,
				totalCount: response.numFound,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page,
				groupedCount: facetData && facetData.building_count
			}));
			

		}else{
			let formattedData = response && response.docs
			? response && response.docs && response.docs.slice(0)
			: this.state.reactTableFiltered;
			
			let docs = formattedData && formattedData.map((doc, i) => {
				let item = Object.assign({}, doc);			
				return item;
			});
	
			this.setState(prevState => ({
				fetchInProgress: false,
				tableData:  docs,
				reactTableFiltered:  docs,	
				totalCount: response.numFound,
				groupedCount: false,
				start: resetPage ? 0 : this.state.start,
				page: resetPage ? 0 : this.state.page
			}));
		}
			
	};
	
	_getSoldData = async (query, bldgQ) => {
		
		if (!this.state.buildingsFetchInProgress) {
			this.setState(prevState => ({ buildingsFetchInProgress: true, }));
		}
		var fq = [
			`-address_2_normalized:""`,
			`status:sold`,
			`price:[100000 TO *]`,
			`transaction_source:ACRIS*`,
			`parcels_condocoop_count:1`,
			`property_type:(sc sp)`,
			`sponsored_sale:true`,
			`close_date_formatted:[NOW-2YEARS TO NOW]`,
			`{!join from=alternate_keys fromIndex=we3-properties to=property}${query}`	
		];
		
		if(bldgQ){
			fq.push(bldgQ);
		}

		let axiosData = {
			url: buildingUrl + "we3-transactions?cache=60",
			method: "post",
			query: {
				q: "*:*",
				fq: fq,
				sort: null,
				wt: "json",
				rows: 1,
				"json.facet" : {
					"non_zero_price": {
						"type": "query",
						"q": "price:[100000 TO 200000000]",
						"facet": {
							"low_price": "min(price)",
							"high_price": "max(price)",
							"average_price": "avg(price)",
							"average_sq_ft": "avg(sq_ft)",
							"average_ppsf": "avg(price_per_sqft)",
							"sum_price": "sum(price)",
							
						}
					},
					"non_zero_listed_price": {
						"type": "query",
						"q": "listed_price:[100000 TO 200000000] AND price:[100000 TO 200000000] AND discount:[-30 TO 30] AND sq_ft:[100 TO *]",
						"facet": {
							"low_price": "min(listed_price)",
							"high_price": "max(listed_price)",
							"average_price": "avg(listed_price)",
							"average_ppsf": "avg(div(listed_price, sq_ft))",
							"average_ppsf_closed": "avg(price_per_sqft)",
							"average_price_closed": "avg(price)",
							"average_discount": "avg(discount)",							
						}
					},
					"discount_price":{
						"type": "query",
						"q": "offering_discount_without_tax:[-30 TO 30] AND -offering_discount:[-1000 TO -100] AND close_date_formatted:[NOW-3MONTHS TO NOW]",
						"facet": {
							"low_price": "min(offering_discount_without_tax)",
							"high_price": "max(offering_discount_without_tax)",
							"average_discount": "avg(offering_discount_without_tax)",
							"mode" : {
								"type": "terms",
								"field": "offering_discount_without_tax",
							}
						}
						
					},
					"sales_velocity":{
						"type": "query",
						"q": "close_date_formatted:[NOW-13MONTHS TO NOW-1MONTH]",
						
					},
					"by_time": {
						"range": {
							"field": "close_date_formatted",
							"start": "NOW-13MONTHS",
							"end": "NOW-1MONTH",
							"gap": "+1MONTH",
							"facet": {
								"total_price": "sum(price)",
							}
						}
					}
											
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(response => {
				// console.log('Chart Data', res);
				let soldData = response && response.facets && response.facets;
				this.setState(prevState => ({
				buildingsFetchInProgress: false,
				soldData: soldData
			}));
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
	};
	
	_formatStats = (response, redoHiddenFields = false) => {
		console.log("_formatStats response", response, 'redoHiddenFields', redoHiddenFields);
		console.log("_formatStats state", this.state);
		if (!response && !redoHiddenFields) {
			return;
		}
		
		
	};

	_filterTableByInput = (name, eValue) => {
		console.log("_filterTableByInput name", name, "eValue", eValue);
		this.setState({searchText: eValue})

	};


	_filterColumn = (columnId, value, display) => {
		const newfilter = {
			display: display,
			id: columnId,
			value
		};

		const filtersWhithoutNew = this.state.reactTableFiltered.filter(
			item => item.id !== columnId
		);
		this.setState({
			reactTableFiltered: [...filtersWhithoutNew, newfilter]
		});
	};
	
	_setSubFilter = (search_key, search_val) => {
	
		console.log(search_key, search_val);
		const subF = Object.assign({}, this.state.subFilters, {
				[search_key]: search_val
			});
		
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	_setSubFilters = (subFilters) => {
		console.log(subFilters)
		const subF = Object.assign({}, this.state.subFilters, subFilters);
		this.setState({
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		
		setLocalStorage(lsPre, subFilterKey, JSON.stringify(subF));
	}
	
	_setBuildingStage = (stage) => {
	
		let newStages = this.state.buildingStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		
		if(newStages.length === 0 ) newStages = ['all'];
		
		this.setState({
			buildingStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, buildingStagesKey, JSON.stringify(newStages));
	}
	
	_setUnitStage = (stage) => {
	
		let newStages = this.state.unitStages.slice();
		const index = newStages.indexOf(stage);		
		const indexAll = newStages.indexOf('all');
		
		
		if(index>=0){
			
			if(stage === "all"){
				newStages = defaultUnitStage
			}else{
				newStages.splice(index, 1);
			}
		}else{
			if(stage === "all"){
				newStages = ["all"]
			}else{
				if(indexAll>=0){
					newStages.splice(indexAll, 1);
				}
				newStages.push(stage)
			}
			
		}
		if(newStages.length === 0 ) newStages = ['all'];
		this.setState({
			unitStages: newStages,
			start: 0,
			end: this.state.rowPerPage
		});		
		setLocalStorage(lsPre, unitStagesKey, JSON.stringify(newStages));
	}
	
	_resetSubFilter = (search_key, search_val) => {
	
		const subF = Object.assign({}, defaultSubFilter);
		this.setState({
			buildingStages : defaultStage,
			unitStages : defaultUnitStage,
			subFilters: subF,
			start: 0,
			end: this.state.rowPerPage
		});
		deleteLocalStorage(lsPre, buildingStagesKey);
		deleteLocalStorage(lsPre, unitStagesKey);
		deleteLocalStorage(lsPre, subFilterKey);
	}	
	
	_resetActive = () => {
		
		let isDefault = this.props.searchTags.length===0 &&
			_objectEqual(defaultSubFilter, this.state.subFilters) &&
			_objectEqual(defaultUnitStage, this.state.unitStages) &&
			_objectEqual(defaultStage, this.state.buildingStages);
	  	
	    return !isDefault;
		
	}
		
	_setBorough = (search_val) => {
		this.setState({
			filterBorough: search_val,
			start: 0,
			end: this.state.rowPerPage
		});
	}
	
	_toggleView = () => {
		
		if(this.state.groupedView){
			this.setState( { groupedView : false, orderBy : "", order : "" } )
		}else{
			this.setState( { groupedView : true, orderBy : "count", order : "desc" } )
		}
		
	}

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, tableData, reactTableFiltered, subFilters, totalCount, facetData, groupedCount, unitListData, fetchInProgress, buildingsFetchInProgress, unitStages
    } = this.state;
    const { user } = this.props;
    const emptyRows = rowsPerPage - reactTableFiltered.length;
    const paginationCount = this.state.groupedView ? groupedCount : totalCount;
    

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding:0 }} >
           
             <BuildingSearchAppBar 
            	handleSearch={this._filterTableByInput}
            	numSelected={[...selected].filter(el => el[1]).length}
				handleDeleteSelected={this.handleDeleteSelected}
				onRequestSort={this.handleRequestSort} 
				subFilters={subFilters}
            	setSubFilter={this._setSubFilter}
            	resetSubFilter={this._resetSubFilter}
            	_filters={(user && user.role.indexOf('admin')>=0) ?  this._unitSubFilter : this._unitSubFilter.filter(item => !item.admin_only)}      
            	{...this.props}  
            	groupedView   
            	building={this.props.building}	
            	placeHolder={'Building, Neighborhood or Borough'}
            	unitStages={unitStages}
            	setUnitStage={this._setUnitStage}
            	buildingStages={this.state.buildingStages}
				setBuildingStage={this._setBuildingStage}
            	buildingAttributes={[]}
            	goToBuilding
            	numToShow={7}
            	UnitStageFilter={false}
				setSubFilters={this._setSubFilters}
				buildingTab={'analysis'}
				resetActive={this._resetActive()}

             />
             
             
            
            <div className="material-table__header-info unit_search">
            	{/*<Row>
            		
            		<Col md={12} lg={12}>
            			<div className={`unit_toggle_filter`}>
            				<UnitStageFilter
								unitStages={unitStages}
								setUnitStage={this._setUnitStage}
								label={'Include:'}
							/>
	            		    
						    
						</div>
						<div>
						Analysis based on <span className="bold-text">{totalCount > 0 ? _formatNumber(totalCount) : '--'}</span>{` `} units {_formatNumber(groupedCount) ? <Fragment> in <span className="bold-text">{_formatNumber(groupedCount)}</span> Buildings</Fragment> : ''}
						</div>
					</Col>
					
				</Row>*/}
            	<hr/>
            	{(fetchInProgress || buildingsFetchInProgress) ? 
					<LinearLoading />
				:
					<Row className="mt-1">
	            		<Col md={12} lg={12}>	            		
	            		<NewDevelopment {...this.props} {...this.state} />
	            		</Col>
	            	</Row>
	            }
            </div>
            
           
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect(state => ({
  user: state.user,
  searchTags: Array.isArray(state.search.searchTags) ? state.search.searchTags.slice() : []
}))(withRouter(AnalysisSearchContainer));