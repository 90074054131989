import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from 'mdi-react/MenuIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import AccountCircle from 'mdi-react/AccountCircleIcon';
import ResetIcon from 'mdi-react/AutorenewIcon';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import MoreIcon from 'mdi-react/MoreVertIcon';
import ChartIcon from 'mdi-react/ChartPieIcon';

import MatTableFilterButton from './MatTableFilterButton';
import MatTableFiltersSelect from './MatTableFiltersSelect';

import Search from '../../../shared/components/algolia/Search';

import { _capitalizeText, searchBarStyles } from '../../../shared/helpers/utils';





function PrimarySearchAppBar(props) {
  const classes = searchBarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
  const { handleSearch, numSelected, handleDeleteSelected, onRequestSort, subFilters, setSubFilter, resetSubFilter, _filters, searchTags } = props;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MatTableFiltersSelect
	      	subFilters={subFilters}
	        setSubFilter={setSubFilter}
	        _filters={_filters}
	       />
    </Menu>
  );

  return (
	
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
         {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>*/}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            {/*<InputBase
              placeholder="Address, Name or Neighborhood…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyUp={e =>
					handleSearch(
						"name",
						e.target.value
					)
			  }
            />*/}
            <Search             
            	handleSearch={handleSearch}
            	classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}/>
          </div>
          <div className={classes.grow} />
        
          <div className={classes.sectionDesktop}>	 
	          <MatTableFilterButton onRequestSort={onRequestSort} />
	      </div>
 {/*         <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div> */}
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      
      <div className={classes.sectionDesktop}>
	      <MatTableFiltersSelect
	      	subFilters={subFilters}
	        setSubFilter={setSubFilter}
	        _filters={_filters}
	       />
	       <div style={{marginTop:20}}>
		       <Tooltip title="Reset Filters">	 
	          	  <IconButton
	              aria-label="Reset Filters"
	              onClick={resetSubFilter}
	              color="inherit"
	            >
	          			<ResetIcon style={{color:'#BFC4D1'}}/>
			  		</IconButton>
			  	</Tooltip>
		   </div>
	  </div>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}




export default PrimarySearchAppBar;
