import React from 'react';
import { Container, Row } from 'reactstrap';
import EmptyList from './components/EmptyList';

const EmailConfirmation = ( props ) => (
  <Container>
    <Row className={`${props.small ? 'small' : ''}`}>
   
	    <EmptyList />
      
    </Row>
  </Container>
);

export default EmailConfirmation;
