import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../../../shared/helpers/utils";
import Icon from 'mdi-react/FlipToBackIcon';
import ToolTip from '../../../../../shared/components/ToolTip';

const AvgUnitSqFt = (props) => (
	  <Col md={12} xl={3} lg={6} xs={6}>
	    <Card>
	    <Link to={`${props.location.pathname.replace('analysis', 'units')}`}>
	      <CardBody className="dashboard__booking-card card__link">
	        <div className="dashboard__booking-total-container">
	          <h5 className="dashboard__booking-total-title">
	            {props && props.statsRes && props.statsRes.total_sq_ft && props.statsRes.total_sq_ft.avg_sq_ft ? _formatNumber(props.statsRes.total_sq_ft.avg_sq_ft) : '--'}
	          </h5>
	          <Icon className="dashboard__trend-icon" />
	        </div>
	        <h5 className="dashboard__booking-total-description">Avg Unit Size (Sq Ft)
			<ToolTip 
			title={"Average interior square feet of all sellable units in this building excluding resident manager unit."} 
			
			/>
			</h5>
	        
	      </CardBody>
	    </Link>
	    </Card>
	  </Col>
);

export default AvgUnitSqFt;
