import React, { PureComponent, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  ButtonToolbar,
  Progress,
  Container,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Badge
} from "reactstrap";
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MainIcon from "mdi-react/CheckDecagramIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import moment from 'moment';
import StarIcon from 'mdi-react/StarCircleIcon';

import {
  _capitalizeText,
  _shouldShow,
  _getBuildingPhotos,
  _formatPrice,
  _formatNumber,
  _getBuildingURL,
  _getCrossStreet,
  _isResidential,
  _isHouse,
  _formatNum,
  _getServiceLevel,
  _validateLink,
  _showFeatures,
  _ordinalSuffix,
  _titleCase,
  _formatDate,
  _formatOwnerName,
  _getPipelineBuildingURL,
  SalesProgress
} from "../../../../../shared/helpers/utils";
import { _axiosCall } from '../../../../../shared/helpers/apicalls';
import { _isPipelineSubscriber, _isNewDevSubscriber } from '../../../../..//shared/components/payment/plans';
const buildingUrl = 'https://newsapi.marketproof.com/pipeline/';
//process.env.API_ROOT + 
export const _formatPhoneWithHide = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
	return "(" + match[1] + ") " + match[2] + "-XXXX";
  }
  return null;
};

export const _formatEmailWithHide = (email) => {
  if (email && typeof email === "string") {
	let emailArr = email.split("@");
	let hideEmail = "****" + "@" + emailArr[1];
	return hideEmail;
  }
  return "";
};
export const StatusBadge = ({building}) => {

	let expanded = false;
	var width = 100;
  
   
	let stage =  building.project_stage && building.project_stage.pipeline_project_stage
		? building.project_stage.pipeline_project_stage
		: building.pipeline_project_stage;
	if (stage == "completion") {
	  return (
		<Fragment>
		  <Badge color="available" style={{ width: expanded ? "auto" : width }}>
			Operation
		  </Badge>
		  { building.pipeline_project_abandoned === true ?
			  <Badge color="error" style={{marginLeft:10, width: expanded ? "auto" : width }}>
				Abandoned
			  </Badge>
			  : building.pipeline_project_stalled === true ?
			  <Badge color="error" style={{marginLeft:10, width: expanded ? "auto" : width }}>
				Stalled
			  </Badge>
			  : null
			  
			}
		  </Fragment>
	  );
	} else if (stage == "construction") {
	  return (
		<Fragment>
		<Badge color="contract" style={{ width: expanded ? "auto" : width }}>
		  Construction
		</Badge>
		{ building.pipeline_project_abandoned === true ?
			<Badge color="error" style={{marginLeft:10, width: expanded ? "auto" : width }}>
			  Abandoned
			</Badge>
			: building.pipeline_project_stalled === true ?
			<Badge color="error" style={{marginLeft:10, width: expanded ? "auto" : width }}>
			  Stalled
			</Badge>
			: null
			
		  }
		</Fragment>
	  );
	} else {
	  return (
		<Fragment>
		  <Badge color="sold">Pre-development</Badge>
		  { building.pipeline_project_abandoned === true ?
			<Badge color="error" style={{marginLeft:10, width: expanded ? "auto" : width }}>
			  Abandoned
			</Badge>
			: building.pipeline_project_stalled === true ?
			<Badge color="error" style={{marginLeft:10, width: expanded ? "auto" : width }}>
			  Stalled
			</Badge>
			: null
			
		  }
		</Fragment>
	  )
	}
};

const PipelineBuilding = ( props ) => {
  
  const { buildingRes, user } = props;
  const [pipelineBuildingRes, setPipelineBuildingRes] = React.useState(null);
  
  React.useEffect(() => {
   	
   	_getMongoBuildingData(buildingRes)
	
  	
   },[]);
	
	
  const _getMongoBuildingData = (buildingRes) => {
   // console.log('_getBuildingData called', this.state)
  
	if(!buildingRes["master_key"]) return false;
	let axiosData = {
		  url: buildingUrl + "query",
		  method: "post",
		  query: { query : { "$and" : [ {"key_list" : `${buildingRes["master_key"]}`}, { "meta.deleted" : {"$exists" : false}}] }}  
	}
	
	_axiosCall(axiosData)
	  .then(res => {
	   // console.log("_getBuildingData building res back ,", res);
  
	  //if(this._isMounted && res && res.docs && res.docs[0]) {
	  if(res && res.docs && res.docs[0]) {
			setPipelineBuildingRes(res.docs[0])
	  }
	})
	.catch(error => {
	  console.log("BlBuildingPage error ", error);
	 
	});
  }
  const _seeProject = (e, pipelineBuildingRes) => {
	e.preventDefault();
	e.stopPropagation();
	 
	const buildingURL = _getPipelineBuildingURL(pipelineBuildingRes);
	window.open(buildingURL);
	return false;
	 
  }
  if(!pipelineBuildingRes) return null;
  
  if(!user || !user.role || !Array.isArray(user.role) || !_isPipelineSubscriber(user)) return null;
  return (
	  
	
	<div className="pipeline-callout">
	<p>Additional information from <a href="https://pipeline.marketproof.com" className="link-text" target="_blank">Marketproof Pipeline</a></p>
	<div className="info-section">
	<div className="stats">
		<Fragment>
		<div className="stat mt-2">
			<h3><StatusBadge building={pipelineBuildingRes} /></h3>
			<span className="product-card__subtext">Construction Stage</span>
		</div>
		{pipelineBuildingRes.pipeline_filing_date && parseInt(moment(pipelineBuildingRes.pipeline_filing_date).format('YYYY')) > 1990 ?
			<div className="stat mt-2">
				<h3><Fragment>
						  {_capitalizeText(moment(pipelineBuildingRes.pipeline_filing_date).fromNow())}
						  <span className="small-text"> 
							  {` `}({_capitalizeText(moment(pipelineBuildingRes.pipeline_filing_date).format('MM/DD/YYYY'))})
						  </span>
					  </Fragment></h3>
				<span className="product-card__subtext">Permits Filed</span>
			</div>
		: pipelineBuildingRes.pipeline_pre_filing_date && parseInt(moment(pipelineBuildingRes.pipeline_pre_filing_date).format('YYYY')) > 1990 ?
			<div className="stat mt-2">
				<h3><Fragment>
					  	{_capitalizeText(moment(pipelineBuildingRes.pipeline_pre_filing_date).fromNow())}
					  	<span className="small-text"> 
						  	{` `}({_capitalizeText(moment(pipelineBuildingRes.pipeline_pre_filing_date).format('MM/DD/YYYY'))})
					  	</span>
				  	</Fragment></h3>
				<span className="product-card__subtext">Permits Filed</span>
			</div>
		: null
		}
		<div className="stat mt-2">
			<h3>{pipelineBuildingRes.pipeline_height_proposed && parseInt(pipelineBuildingRes.pipeline_height_proposed) > 1 ? 
				  <Fragment>
					  {_formatNumber(pipelineBuildingRes.pipeline_height_proposed)}
					  <span className="small-text"> 
						  {` Feet`}
					  </span>
				  </Fragment> : `--`}</h3>
			<span className="product-card__subtext">Building Height</span>
		</div>
		<div className="stat mt-2">
			<h3>{pipelineBuildingRes.organizations && pipelineBuildingRes.contacts ? pipelineBuildingRes.organizations.length + pipelineBuildingRes.contacts.length : '--'} <span className="small-text">Contacts</span></h3>
			<span className="product-card__subtext">Companies & People</span>
		</div>
		{pipelineBuildingRes.organizations && pipelineBuildingRes.organizations.filter(c => c && c.project_roles && c.phone && c.project_roles.indexOf('developer')>=0 && c.phone &&  c.phone.length>0).length>0 ? 
			<div className="stat mt-2">
				<h3>
				  {_formatPhoneWithHide(pipelineBuildingRes.organizations.filter(c => c && c.project_roles && c.project_roles.indexOf('developer')>=0 && c.phone && c.phone.length>0)[0]['phone'][0])}
				</h3>
				<span className="product-card__subtext">Developer Phone</span>
			</div>
			: pipelineBuildingRes.contacts && pipelineBuildingRes.contacts.filter(c => c && c.project_roles && c.phone && c.project_roles.indexOf('developer')>=0 && c.phone &&  c.phone.length>0).length>0 ? 
		<div className="stat mt-2">
			<h3>
			  {_formatPhoneWithHide(pipelineBuildingRes.contacts.filter(c => c && c.project_roles && c.project_roles.indexOf('developer')>=0 && c.phone && c.phone.length>0)[0]['phone'][0])}
			</h3>
			<span className="product-card__subtext">Developer Phone</span>
		</div>
		: pipelineBuildingRes.contacts && pipelineBuildingRes.contacts.filter(c => c && c.project_roles && c.phone && c.project_roles.indexOf('owner')>=0 && c.phone && c.phone.length>0).length>0 ? 
		<div className="stat mt-2">
			<h3>
			  {_formatPhoneWithHide(pipelineBuildingRes.contacts.filter(c => c && c.project_roles && c.project_roles.indexOf('owner')>=0 && c.phone && c.phone.length>0)[0]['phone'][0])}
			</h3>
			<span className="product-card__subtext">Owner Phone</span>
		</div>
		: null
		}
		</Fragment>
		
		
	</div>
	<Tooltip title="See Project"> 
		<Button
		   onClick={(e) => _seeProject(e, pipelineBuildingRes)}
		   variant="contained"
		   color="primary"
		   className="buyersList__primary-button"		   
		   endIcon={<OpenInNewIcon size={18} />}      
	   >	More on Pipeline
	   </Button>
			   
	</Tooltip>
	</div>
	</div>
  )
}


export default PipelineBuilding