import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Gallery from './Gallery';
import Items from './imgs';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import LinearLoading from '../../../../../shared/components/LinearLoading';

import { 
	_capitalizeText,
	_getUnitFloorPlan
} from "../../../../../shared/helpers/utils";

const tags = [
  { tag: 'floor', title: 'Floor Plates', show: false },
  { tag: 'elevation-map', title: 'Elevation Maps', show: false }, 
  { tag: 'site-plan', title: 'Site Plans', show: false },
  { tag: 'zoning', title: 'Zoning', show: false },
  { tag: 'storage', title: 'Storage', show: false },
  { tag: 'maisonette', title: 'Maisonette', show: false },
  { tag: 'bulkhead', title: 'Bulkhead', show: false },
  { tag: 'mechanical', title: 'Mechanical', show: false },
  { tag: 'elevator', title: 'Elevator', show: false },
  { tag: 'roof', title: 'Roof', show: false },
  { tag: 'lobby', title: 'Lobby', show: false },
  { tag: 'other', title: 'Other', show: false },
];

const FilterGallery = ( props ) => {
	const { floorPlans } = props;

	const sortTypes = ['elevation-map', 'site-plan', 'zoning', 'floor','storage'];
	const allPlans = floorPlans ? floorPlans.map(unit => {
		unit = Object.assign({}, unit, _getUnitFloorPlan(unit)[0]);
		
		 return unit;
	}) : []
	
	
	
	let images = {};
	allPlans.filter(unit => unit.src && typeof unit.src === 'string').map(image => {	
		if(!images[image.address_2_normalized]){
			images[image.address_2_normalized] = image;
			images[image.address_2_normalized]['sub_tags'] = [];
		}		
	})
	if(floorPlans === null){
		return <LinearLoading />;
	}
	if(!floorPlans || floorPlans.length<=0 ){
		return (
		  <Col md={12}>
			<Card>
			  <CardBody className={`small`}>
				<div className={`email-confirmation`} >
				  <div className="email-confirmation__icon">
					<AlertIcon size={50} color={`#D6BAE8`} />			            
				  </div>
				  <h3 className="email-confirmation__title">No Floor Plans Found</h3>
				  <p className="email-confirmation__sub"></p>
				  
				</div>
			  </CardBody>
			</Card>
		  </Col>
		  )
	}
	
	return (
		  <Col md={12} lg={12}>
		    <Card>
		      <CardBody>
		        <Gallery images={Object.values(images)} tags={tags.filter(tag => tag.show)} {...props} />
		      </CardBody>
		    </Card>
		  </Col>
    )
};

export default FilterGallery;
