
export const Intercom = (function () {
	const intercom_app_id = 'dxotx31t';
	var event_names = {
		'buildingTags' : 'building',
		'hoodTags' : 'neighborhood',
		'boroTags' : 'borough',
		'unitTags' : 'unit',
	}
	
	function flattenObject(data) {
	    var result = {};
	    function recurse (cur, prop) {
	        if (Object(cur) !== cur) {
	            result[prop] = cur;
	        } else if (Array.isArray(cur)) {
	             for(var i=0, l=cur.length; i<l; i++)
	                 recurse(cur[i], prop + "[" + i + "]");
	            if (l == 0)
	                result[prop] = [];
	        } else {
	            var isEmpty = true;
	            for (var p in cur) {
	                isEmpty = false;
	                recurse(cur[p], prop ? prop+"_"+p : p);
	            }
	            if (isEmpty && prop)
	                result[prop] = {};
	        }
	    }
	    recurse(data, "");
	    return result;
	}
	
	function buildCustomAttributes(obj){
		
		var attributes = {};
		for (var key in obj) {
		  	var field = obj[key];
		  	
		  	if(Array.isArray(field)){
			  	field = field.join(',');
		  	}
		  	if(typeof field == 'string'){
			  	attributes[key] = field.substring(0,255);
		  	}else if(typeof field == 'number' || typeof field == 'boolean'){
			  	attributes[key] = field;
		  	}
		  	
		}
		
		return attributes;
		
	}
	
	
	function doLogin(event, authData){

	    if(authData.id !== undefined){		      
		      if(authData !== undefined && authData.email !== undefined){	
			      var intercomProfile = {
					   app_id: intercom_app_id,
					   email: authData.email,
					   user_id: authData.id,
					   name: authData.firstName + ' ' + authData.lastName,
					   mp_site: 'BuyersList',
				  };				  
				  var customAttributes = buildCustomAttributes(authData);
				  intercomProfile = Object.assign(intercomProfile, customAttributes);	  				  		      
			      window.Intercom('boot', intercomProfile);
			      //console.log("intercom boot user", intercomProfile);
		      }
	    }
	}
	
	function doUpdate(event, authData){

	    if(authData.id !== undefined){		      
		      if(authData !== undefined && authData.email !== undefined){	
			      			  
				   var intercomProfile = {
					   app_id: intercom_app_id,
					   email: authData.email,
					   user_id: authData.id,
					   name: authData.firstName + ' ' + authData.lastName,
					   mp_site: 'BuyersList',
				  };				  
				  var customAttributes = buildCustomAttributes(authData);
				  intercomProfile = Object.assign(intercomProfile, customAttributes);	  				  		      
			      window.Intercom('update', intercomProfile);
			      //console.log("intercom boot user", intercomProfile);
		      }
	    }
	}
	
	function doLogout(event, authData){
		//console.log("intercom shutdown user", authData);
	    window.Intercom('shutdown');
	}
	
	function doTrackEvent(event, obj){	   
	    var data = flattenObject(obj);
	    var events = event.split(".");
	    if(events[0] == 'track'){
		    events.shift();
	    }
	    
	    var event_name = events[0];
	    
	    for(var i=1;i<events.length;i++){
		    var sub_event = events[i];
		    var sub_event_name = 'event_type';
		    if(i>1){
			    sub_event_name += '_'+i;
		    }
		    if(event_names[sub_event] !== undefined){
			    data[sub_event_name] = event_names[sub_event];
		    }else{
			    data[sub_event_name] = sub_event;
		    }
		    
	    }
	    var customAttributes = buildCustomAttributes(data);
			if(!window.location.hostname.includes('localhost') && !window.location.hostname.includes('dev.')) {
	    	window.Intercom('trackEvent', event_name, customAttributes);
	    }
		//console.log("intercom track", event_name, customAttributes);
	}

    
    return {
	    doLogin : doLogin,
	    doLogout: doLogout,
	    doTrackEvent: doTrackEvent,
	    doUpdate: doUpdate
    }
})();