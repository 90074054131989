import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import SpeedometerIcon from 'mdi-react/SpeedometerIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment"; 
import { Tooltip } from '@material-ui/core';


import{
	_capitalizeText,
} from '../../../../../../../shared/helpers/utils';
import { getDefaultChartColors } from '../../../../../../../shared/helpers';

const data = [{ value: 3, fill: '#9600BF' },{ value: 5, fill: '#DBE0E8' }]
const BuyersListCount = (props, { t }) => (

    <div className="dashboard__health-chart"> 
    
      <ResponsiveContainer height={80} width={80}>
        <PieChart>
          <Pie data={[{ value: props.listCount, fill: '#9600BF' },{ value: 5, fill: '#DBE0E8' }]} startAngle={0} dataKey="value" cx={35}  cy={35} innerRadius={25} outerRadius={30} />
        </PieChart>
      </ResponsiveContainer>
      <Tooltip title="See Others on This BuyersList">
	      <div className="dashboard__buyers-list-count-info">
	        <p className="dashboard__buyers-list-number">{props.listCount}</p>
	        <p className="dashboard__buyers-list-text">{`See BuyersList`}</p>
	      </div>
    </Tooltip> 
    </div>
        
);

BuyersListCount.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BuyersListCount);
