import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {
  Link
} from "react-router-dom";
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
} from "../../../shared/helpers/utils";

const PriceRangePPSF = (props) => {
	  if(!props.facetData) return null;
	  
	  return (
		    <Card>
		    <Link to={`/inventory?offering_status=unsold`}>
		      <CardBody className="dashboard__booking-card card__link">
		        <div className="dashboard__booking-total-container">
		          <h5 className="dashboard__booking-total-title">
		          {props.facetData.non_zero_price && props.facetData.non_zero_price.min_ppsf > 0 ? 
		            <span>{_formatPrice(props.facetData.non_zero_price.min_ppsf)} - {_formatPrice(props.facetData.non_zero_price.max_ppsf)}</span>
		            :
		            <span>--</span>
		           }
		          </h5>
		        {/*  <TrendingUpIcon className="dashboard__trend-icon" /> */}
		        </div>
		        <h5 className="dashboard__booking-total-description">Listed Price / Sq Ft Range</h5>
		        
		      </CardBody>
		    </Link>
		    </Card>
	  )
};

export default PriceRangePPSF;
