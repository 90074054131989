/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';
import EditIcon from 'mdi-react/PencilIcon';
import AddToBuyersListButton from '../../../../../shared/components/buttons/AddToBuyersListButton';
import BuyersListCount from './../../BuyersListCount';
import ShareButton from '../../../../../shared/components/buttons/ShareButton';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	UnitCurrentStatus,
	_getUnitFloorPlan
} from "../../../../../shared/helpers/utils";


const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

class SavedUnitItems extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      description: PropTypes.string,
      colors: PropTypes.arrayOf(PropTypes.string),
      new: PropTypes.bool,
    })),
  };

  static defaultProps = {
    items: [],
  };
  
  
  
  handleUnitClick = (event, item) => {
	   event.stopPropagation();
	   const buildingURL = _getBuildingURL(item, 'building_slugs') + '/' + _nameToUrl(item.address_2_normalized);
	   window.open(buildingURL);
	};

  render() {
    const { items, user, analytics} = this.props;

    return (
      <div className="catalog-items__wrap">
        <div className="catalog-items">
          {items.map((item, i) => {
	          
	        const savedCount = analytics && analytics.length>0 && analytics.filter(a => a.key === item.key)[0] ? analytics.filter(a => a.key === item.key)[0].total : '--';
	        return (
            <CardBody className="catalog-item card__link" key={i} onClick={event => this.handleUnitClick(event, item)}>
                {/*item.new ? <img className="catalog-item__label" src={New} alt="new" /> : ''}
                {item.sale ? <img className="catalog-item__label" src={Sale} alt="sale" /> : ''*/}
               
                <div className="catalog-item__img-wrap">
                  {/*<img className="catalog-item__img" src={item.src} alt="catalog-item-img" />*/}
				  	{_getUnitFloorPlan(item) ?
	                <img src={_getUnitFloorPlan(item)[0].src} / >
	                :
				  	<FloorPlanIcon size={200} color="#CCC" />
				  }
				  	
                </div>
                
                <UnitCurrentStatus unit={item} />
                <div className="saved-buyer-indicator">
                	{/*<span className="saved-buyers-small">
	                	{savedCount}</span> 
	                Member{savedCount > 1 ? 's' : ''} Saved This Unit*/}
					<ShareButton  buildingRes={Object.assign({}, item, {name:item.building_name, key:item.building})} property={item} small />
	            </div>
                <div className="catalog-item__info">
                
                   {(item.offering_verified_status === 'sold' || item.offering_verified_status === 'resale') && item.last_sold_price > 0 ?
	                 	<h4 className="catalog-item__price">${_formatNumber(item.last_sold_price)}</h4>  
	                  : item.last_listed_sale_price_all > 0 ?
	                  	<h4 className="catalog-item__price">${_formatNumber(item.last_listed_sale_price_all)}</h4> 
	                  : (item.price > 0 || item.offering_price >0 ) ?
					 	<h4 className="catalog-item__price">${item.price ? _formatNumber(item.price) : _formatNumber(item.offering_price)}</h4>
					  : null
				   }
				   
                   <AddToBuyersListButton unitKey={item.key} buildingKey={item.building} units={[item.address_2_normalized]} unitSave removeOnly />
                    {user && ['seller', 'enterprise'].indexOf(user.accountType) > -1 &&
	                    <div style={{ position:'absolute', right:45, top:-40 }}>
		              	  <Tooltip title="Update Unit Details">	            		
			              	<IconButton	
			              		className="material-table__toolbar-button"
			              		onClick={(e) => {e.stopPropagation()}}
			              	>
							  <EditIcon color="#ddd" /> 
						  	</IconButton>
						  </Tooltip>
						  </div>
	              	  
              	   }
              	   {(item.offering_verified_status === 'active' || item.offering_baths > 0 ) ? 
                   <h5 className="product-card__title">
		              	{item.offering_bedrooms > -1 ? item.offering_bedrooms === 0 ? 'Studio' : item.offering_bedrooms : '--'} 
		              	<span className="sub-text">{item.offering_bedrooms === 0 ? '' : item.offering_bedrooms === 1 ? 'Bed' : 'Beds'}</span>
		              	<span className="small-divider">|</span>
		              	{item.offering_baths}
		              	<span className="sub-text">{item.offering_baths === 1 ? 'Bath' : 'Baths'}</span>
		              	<span className="small-divider">|</span>
		              	{_formatNumber(item.offering_sq_ft)}
		              	<span className="sub-text">Sq Ft</span>
		              	{/*item.exterior_sq_ft ?
			              	<React.Fragment>
			              		<span className="small-divider">|</span>
						  		{_formatNumber(item.exterior_sq_ft)}
						  		<span className="sub-text">Exterior Sq Ft</span>
			              	</React.Fragment>
			              	: null
			            */}
		          </h5>
		          : item.baths > 0 ?
		          <h5 className="product-card__title">
		              	{item.bedrooms > -1 ? item.bedrooms === 0 ? 'Studio' : item.bedrooms : '--'} 
		              	<span className="sub-text">{item.bedrooms === 0 ? '' : item.bedrooms === 1 ? 'Bed' : 'Beds'}</span>
		              	<span className="small-divider">|</span>
		              	{item.baths}
		              	<span className="sub-text">{item.baths === 1 ? 'Bath' : 'Baths'}</span>
		              	{item.sq_ft && item.sq_ft > 0 &&
			              	<Fragment>
			              	<span className="small-divider">|</span>
			              	{_formatNumber(item.sq_ft)}
			              	<span className="sub-text">Sq Ft</span>
			              	</Fragment>
		              	}
		          </h5>
		          : null
		          }
                  <h5 className="catalog-item__title">
                 <span>{item.building_name ? _capitalizeText(item.building_name) : _capitalizeText(_replaceAbbreviations(item.display_full_street_address))}, {item.address_2_normalized.toUpperCase().replace("APT", "Apt")}</span>
                  <br/><span className="light-text small-text" style={{color:"#858AA1"}}>{item.neighborhood && item.neighborhood[0] ? `${_capitalizeText(item.neighborhood[0])}, ` : ''} {_capitalizeText(item.borough)}</span>
                  </h5>
                  <p className="catalog-item__description"></p>
                  {/*item.colors.map((c, index) => (
                    <span
                      key={index}
                      className="catalog-item__color"
                      style={{ background: c }}
                    />
                  ))*/}
                  
                </div>
                
            </CardBody>
          )})}
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  user: state.user,
}))(SavedUnitItems);
