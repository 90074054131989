import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody, Col, Progress, Badge, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTableHead from './UnitsSearchTableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import FloorPlanIcon from 'mdi-react/FloorPlanIcon';

import LinearLoading from '../../../shared/components/LinearLoading';
import Button from '@material-ui/core/Button';
import LoadMoreIcon from 'mdi-react/RefreshIcon';
import LoadingIcon from 'mdi-react/DotsHorizontalIcon';

import CharityIcon from 'mdi-react/CharityIcon';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import IconButton from '@material-ui/core/IconButton';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import OpenIcon from 'mdi-react/FileDocumentOutlineIcon';
import CertificateOutlineIcon from 'mdi-react/CertificateIcon';
import ContactIcon from 'mdi-react/EmailOutlineIcon';


import { 
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_nameToUrl,
	_shouldShow,
	_getBuildingURL,
	buildingFL,
	_replaceAbbreviations,
	_objectEqual,
	StatusBadge,
	_getUnitFloorPlan,
	SalesProgress
} from "../../../shared/helpers/utils";

import { _axiosCall } from '../../../shared/helpers/apicalls';
const buildingUrl = process.env.API_ROOT + '/query/';
const CurrentStatus = ({ item }) => {
 
  if (moment(item["building_effective_start"]).year() > 1969) {
    return (
	 <div>
      <Badge color="available">Move-in Ready</Badge>
     </div>
    );
  } 
  return (
    <div>
      <Badge>Under Construction</Badge>
    </div>
  );
};


const calcuateSalesStart = (buildingRes, type) => {		
	
	if(!buildingRes.sales_start_date || parseInt(moment(buildingRes.sales_start_date).format('YYYY')) <= 1970){
		if(type === 'fromNow'){
			return 'Not Yet Started';
		}else{
			return null;
		}
	}
	
	if(type === 'fromNow'){
		return moment(buildingRes.sales_start_date).fromNow();
	}	
	return `(${moment(buildingRes.sales_start_date).format("M/D/YYYY")})`;
}
class UnitsListTable extends PureComponent{
	state = {
	    order: "",
	    orderBy: "",
	    selected: new Map([]),
	    page: 0,
	    rowsPerPage: 20,
	    fetchInProgress: false,
		buildingKey:false,
		unitListData:[],
		start:0,
		noMoreResults:false,
	};

	componentDidMount() {
		
		if(this.props.building || this.props.building === ""){
			this.queryUnits();
		}
	  	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(this.props.building || this.props.building === ""){
		  if(!_objectEqual(this.props.q, prevProps.q) ||
		  (this.props.buildingKey !== prevProps.buildingKey ||
		   this.state.orderBy !== prevState.orderBy ||
		   this.state.order !== prevState.order ||
		   this.state.start !== prevState.start)){
			   
			 const reset = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
			 this.queryUnits(reset);
		  }		 
		}
		 
    }
    
    _loadMore = () => {
	  if(this.state.fetchInProgress === true){
		  	return false;
	  }
	  this.setState({ start: (this.state.start+this.state.rowsPerPage) });    
	  
    }
    
    _handleRequestUnitsSort = (event, property) => {
	    const orderBy = property;
	    let order = 'desc';
	    
	console.log(event, property);    
	    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
	
	    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }
	
	    this.setState({ order, orderBy, start: 0 });
	};
	
	handleUnitClick = (event, building) => {
	 const buildingURL = _getBuildingURL(building, 'building') + '/' + _nameToUrl(building.address_2_normalized);
	 //this.props.history.push(buildingURL);
	 window.open(buildingURL);
    };

	
	queryUnits = async(reset) => {
		
		if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
		}
		
		const {building, q, fq, fl } = this.props;	
		
		let sort = "time desc"
		if(this.state.orderBy && this.state.orderBy.length > 0){
			
			sort = `${this.state.orderBy} ${this.state.order}`;
			
			
		}
			
		
		const unitsFq = [`building:"${building}"`, "hierarchy:[0 TO 1]"];			
		let axiosData = {
			url: buildingUrl + "we3-activities-3?cache=15",
			method: "post",
			query: {
				q: q,
				fq: [...fq, ...unitsFq],
				sort: sort,
				wt: "json",
				rows: this.state.rowsPerPage,
				start: this.state.start,
						
			}
		};

		let response = await _axiosCall(axiosData)
			.then(res => {
				if(res && res.docs && res.docs.length>0){
					const newUnitListData = reset ? res.docs : [...this.state.unitListData, ...res.docs];
					this.setState( { unitListData: newUnitListData, fetchInProgress: false, buildingKey:false })
				}else if(res.docs.length === 0 ){
					this.setState( { noMoreResults: true, fetchInProgress: false, buildingKey:false })
				}
			})
			.catch(error => {
				console.log("error: " + error);
			});
	}
	
	render(){
		const { reactTableFiltered, handleBuildingClick, isSelected, handleClick, order, orderBy, selected, emptyRows, handleSelectAllClick, handleRequestSort, isGrouped, user, building, soldTimeField} = this.props;
		if(!reactTableFiltered && (this.state.start===0 && (this.state.unitListData.length===0 || this.props.fetchInProgress))){
			return ( 			
				<LinearLoading />
			)
		}
		
		const data = reactTableFiltered || this.state.unitListData;
		
		
		
		return (
		<Fragment>
			<div>
			<Table className="material-table">
		        <UnitsSearchTableHead
		          numSelected={[...selected].filter(el => el[1]).length}
		          order={order}
		          orderBy={orderBy}
		          onSelectAllClick={handleSelectAllClick}
		          onRequestSort={isGrouped ? this._handleRequestUnitsSort : handleRequestSort}
		          rowCount={reactTableFiltered.length}
		        />
		        <TableBody>
		          {data
		            .map((d) => {
		              const itemSelected = isSelected(d.id);
		              const floorplan = false; //_getUnitFloorPlan(d);
					  const buildingData = d;
					  let salesOffice = null;
					  if(d.sales_office_details){
					   		 try{
						   		 salesOffice = JSON.parse(d.sales_office_details);						   		
					   		 }catch(e){
						   		 //cannot parse JSON
					   		 }	   		 
				   	  }

		              return (
		                <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={event => this.handleUnitClick(event, d)}
                          aria-checked={itemSelected}
                          tabIndex={-1}
                          key={d.id}
                          selected={itemSelected}
                        >
                           <TableCell className="material-table__cell" padding="checkbox" onClick={event => handleClick(event, d.id)}>
		                  	{/*<Tooltip title="Add to List">
		                    	<Checkbox checked={itemSelected} className="material-table__checkbox" />
		                    </Tooltip>   */}                       
		                  </TableCell>
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">{d.topic[0] === "transactions" ? <span>{moment(d[soldTimeField]).format('M/D/YY')} {soldTimeField === "time" ? "(Recorded Date)" : ""}</span> : moment(d.time).format('M/D/YY')}</span>
                          </TableCell>   
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                           <span className="light-text small-text">
                           		{d.topic[0] === "listings" && d.event_tags.indexOf("just-listed")>=0 && 
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#4ce1b6", borderRadius:3, marginRight:3}} /> Listed
	                           		</Fragment>
	                           	}
	                           	{d.topic[0] === "listings" && d.event_tags.indexOf("contract-signed")>=0 && 
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#FFD23F", borderRadius:3, marginRight:3}} /> Contract Signed
	                           		</Fragment>
	                           	}
	                           	{d.topic[0] === "transactions" && 
	                           		<Fragment>
	                           			<span style={{ width:12, height:12, display:"inline-block", background:"#F53246", borderRadius:3, marginRight:3}} /> Sold
	                           		</Fragment>
	                           	}
                           	</span>

                          </TableCell>    
                           
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                           <span>{d.address_2_normalized.toUpperCase().replace("APT", "Apt")}</span>

                          </TableCell>
                            <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                  	<span className="light-text small-text">{d.bedrooms > -1 ? d.bedrooms === 0 ? 'Studio' : d.bedrooms+' Bed' : '--'}</span>
		                  </TableCell>
		                  <TableCell
		                    className="material-table__cell material-table__cell-right"
		                    size="small"                          
		                  >
		                    <span className="light-text small-text">{d.baths > -1 ? d.baths+' Bath' : '--'}</span>
		                  </TableCell>
                         
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            size="small"                          
                          >
                          	<span className="light-text small-text">${_formatNumber(d.price)}</span>
                          </TableCell>                         
                          
                           <TableCell
                            className="material-table__cell material-table__cell-right"
                            align="center"
                          >
                          {!(d.topic[0] === "listings" && d.event_tags.indexOf("contract-signed")>=0) ?
	                          	<span className="light-text small-text">
	                          		<a href={`javascript:void(0)`} className="alert_show"
	                          		onClick={(e) => {e.preventDefault();e.stopPropagation();window.open(`https://marketproof.com/article/${d.slugs[0]}`);}}>
	                          			See Event
	                          		</a><br/>
	                          	</span>
	                          :
	                          <span className="light-text small-text" style={{color:"#D1D6E0"}}>
	                          		--
	                          </span>
	                          
	                      }
	                                           
                          </TableCell>
                          
                        </TableRow>
		              );
		            })}
		          {emptyRows > 0 && (
		            <TableRow style={{ height: 49 * emptyRows }}>
		              <TableCell colSpan={6} />
		            </TableRow>
		          )}
		        </TableBody> 
		        
              
		      </Table>
		      </div>
		     
			   {!reactTableFiltered && 
				<div className="unit__load">			   		
				    <Tooltip title="Load more units">
				        <Button
					        onClick={(e) => {this._loadMore(e)}}
					         variant="contained"
					         color="news"
					         className={`timeline__load-more`}		         
					         startIcon={<LoadMoreIcon />}
					         disabled={this.state.fetchInProgress || this.state.noMoreResults}
					     >	{`${this.state.fetchInProgress ? "Loading..." : this.state.noMoreResults ? "No More Activities" : "Load More"}`}
					     </Button>				 
				  	</Tooltip>				
			    </div>
			    }
			</Fragment>
		);
	}
}

export default connect(state => ({
  user: state.user
}))(UnitsListTable);