import React from 'react';
import { Link } from "react-router-dom";

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './../../../../../shared/components/Panel';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
const SalesReport = ({ t }) => (
  <Col md={12} xl={6} lg={6} xs={12}>
    <Card>
        <CardBody className="dashboard__card-widget">
	        <Link to={`/inventory?offering_status=sold`}>
	            <div className="card__title">
	              <h5 className="bold-text">{`Coming Soon`} <span className="small-text light-text">(Recently Added)</span></h5>
	            </div>
	        </Link>
    <div className="dashboard__sales-report">
      {/*<div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">Available Now</p>
        <p className="dashboard__sales-report-now">14,392 <span className="small-text light-text">Units</span></p>
        <p className="dashboard__sales-report-plan">8,850 Unsold</p>
        <p className="dashboard__sales-report-value progress__label">27%</p>
        <Progress className="bg-active" value={27} />
      </div>*/}
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">This Month</p>
        <p className="dashboard__sales-report-now">22 <span className="small-text light-text">Units Added</span></p>
        <p className="dashboard__sales-report-plan">80 Expected</p>
        <p className="dashboard__sales-report-value progress__label">27%</p>
        <Progress className="bg-active" value={27} />
      </div>
      {/*<div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">This Year</p>
        <p className="dashboard__sales-report-now">2,300 <span className="small-text light-text">Units Added</span></p>
        <p className="dashboard__sales-report-plan">7,000 Expected</p>
        <p className="dashboard__sales-report-value progress__label">32%</p>
        <Progress className="bg-active" value={32.5} />
      </div>*/}
    {/*  <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">This Yeear</p>
        <p className="dashboard__sales-report-now">650</p>
        <p className="dashboard__sales-report-plan">2500 planned</p>
        <p className="dashboard__sales-report-value progress__label">18%</p>
        <Progress value={26} />
      </div>*/}
    </div>
 </CardBody>
    </Card>
  </Col>
);

SalesReport.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(SalesReport);