import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import UnitsSearchTable from './components/UnitsSearchTable';
import Alert from '../../shared/components/Alert';
import { Link } from 'react-router-dom';
import AlertIcon from 'mdi-react/InformationOutlineIcon';
import TrialAlert from '../../shared/components/payment/TrialAlert';
import EnterpriseGate from '../../shared/components/payment/EnterpriseGate';
import { _hasPermission	} from "../../shared/helpers/access";
import QuickStartGuide from '../../shared/components/QuickStartGuide';

class Units extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	     
	    };
	}
	componentDidMount() {
	  console.log('componentDidMount Inventory');
	}
	
	render(){
		const { user } = this.props;
	    if(!user) return null;

		return(
		  <Container>
		    <Row>
		      <Col md={12}>
		        <h3 className="page-title">Lenders</h3>
		        <h3 className="page-subhead subhead">Analyze Organizations Who Provided Financing to New Developments & Condos. </h3>
		      </Col>
		    </Row>
		    {_hasPermission(user, 'lenders') ?
				 <Fragment>
			    	<Row>
				      <Col md={12} className={'mb-3'}>
				       	<TrialAlert />        
				      </Col>
				    </Row>
				     <QuickStartGuide link={"https://help.marketproof.com/en/articles/9039686-marketproof-pro-quick-start-guide#h_2d8991f280"} type={"Lenders"} />

				    <Row>
				      <UnitsSearchTable />
				    </Row>
			    </Fragment>			    
			    :
			    <Row>
			      <EnterpriseGate img={`https://marketproof-new-development.s3.amazonaws.com/mpnd-lenders-mockup-blur.jpg`}
			      	copy={<span>Access to Lenders requires an Enterprise subscription.</span>}
					 user={user}
			      />
			    </Row>
			}
		    
		  </Container>
		);
	}
};

export default connect(state => ({
  user: state.user,
}))(Units);



