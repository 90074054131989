import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from 'mdi-react/MenuDownIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const options = ['fit', 'uniform', 'size'];

const viewTranslate ={
	'fit' : "View: Best Fit",
	'uniform' : "View: Uniform",
	'size' : "View: True to Size"
}

const viewTranslateTooltip ={
	'fit' : "Fits as many units as possible with variance for sq ft. Best for getting an overview.",
	'uniform' : "All units shown with same width.  Best for visualizing lines within the building.",
	'size' : "Unit width based on sq ft.  Best for visualizing unit size in relation to each other."
}

export default function ViewTypeButton(props) {
	
  const { viewType, setViewType } = props;
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (option, index) => {
    setViewType(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="left" style={{width:'auto',marginBottom:'20px'}}>
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" style={{boxShadow:'none'}}>
           <Tooltip title={viewTranslateTooltip[viewType]}>
		   		<Button onClick={handleClick} style={{margin:0,borderColor:'#FFF'}}>{viewTranslate[viewType]}</Button>
           </Tooltip>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            style={{minWidth:0, margin:0}}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:99}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
	                  <Tooltip title={viewTranslateTooltip[option]}>	
	                      <MenuItem
	                        key={option}
	                       
	                        selected={option === viewType}
	                        onClick={(event) => handleMenuItemClick(option, index)}
	                      >
	                        {viewTranslate[option]}
	                      </MenuItem>
	                   </Tooltip>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
