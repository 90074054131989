import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import LinearLoading from "../../../shared/components/LinearLoading";
import { _capitalizeText, _titleCase, _zeroPad } from "../../../shared/helpers/utils";
import {
  _getDOBComplaints,
  _getDOBViolations,
  _getECBViolations,
  _getHPDViolations,
} from "../../../shared/helpers/apicalls";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableHead, TableSortLabel } from "@material-ui/core";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";

const url = process.env.API_ROOT + "/query/";
const columns = [
  {
    id: 1,
    label: "Date",
    diableSort:false
  },
  {
    id: 2,
    label: "Type",
    diableSort:true
  },
  {
    id: 3,
    label: "Status",
    diableSort:true
  },
  {
    id: 4,
    label: "Description",
    diableSort:true
  },
  
];

const _complaintCodes = [
  { code: 1, description: "Accident - Construction/plumbing", priority: "A" },
  {
    code: 3,
    description: "Adjacent Buildings - Not Protected",
    priority: "A",
  },
  { code: 4, description: "After Hours Work - Illegal", priority: "B" },
  {
    code: 5,
    description: "Permit - None (Building/ Pa/ Demo Etc.)",
    priority: "B",
  },
  {
    code: 6,
    description: "Construction - Change Grade / Change Watercourse",
    priority: "B",
  },
  { code: 9, description: "Debris - Excessive", priority: "B" },
  {
    code: 10,
    description: "Debris/building -falling Or In Danger Of Falling",
    priority: "A",
  },
  {
    code: 12,
    description: "Demolition-unsafe/illegal/mechanical Demo",
    priority: "A",
  },
  {
    code: 13,
    description: "Elevator In (Fdny) Readiness-none",
    priority: "A",
  },
  {
    code: 14,
    description: "Excavation - Undermining Adjacent Building",
    priority: "A",
  },
  { code: 15, description: "Fence - None/inadequate/illegal", priority: "B" },
  { code: 16, description: "Inadequate Support/shoring", priority: "A" },
  { code: 18, description: "Material Storage - Unsafe", priority: "A" },
  {
    code: 20,
    description: "Landmark Building - Illegal Work",
    priority: "A",
  },
  {
    code: 21,
    description:
      "Safety Net/guard Rail-damaged/inadequate/none(Over 6 Sty/75 Ft)",
    priority: "B",
  },
  {
    code: 23,
    description:
      "Sidewalkshed/supported Scaffold/ Inadequate/ Defective/ None/no",
    priority: "B",
  },
  { code: "", description: "Pmt/no Cert", priority: "" },
  {
    code: 29,
    description: "Building - Vacant, Open And Unguarded",
    priority: "C",
  },
  {
    code: 30,
    description: "Building Shaking/vibrating/struct Stability Affected",
    priority: "A",
  },
  {
    code: 31,
    description: "Certificate Of Occupancy - None/illegal/contrary To Co",
    priority: "C",
  },
  {
    code: 35,
    description: "Curb Cut/driveway/carport - Illegal",
    priority: "D",
  },
  {
    code: 37,
    description: "Egress - Locked/blocked/improper/no Secondary Means",
    priority: "A",
  },
  { code: 45, description: "Illegal Conversion", priority: "B" },
  {
    code: 49,
    description:
      "Storefront Or Business Sign/awning/marquee/canopy %u2013 Illegal",
    priority: "C",
  },
  {
    code: 50,
    description:
      "Sign Falling - Danger/sign Erection Or Display In Progress %u2013 Illegal",
    priority: "A",
  },
  { code: 52, description: "Sprinkler System - Inadequate", priority: "B" },
  { code: 53, description: "Vent/exhaust - Illegal/improper", priority: "D" },
  {
    code: 54,
    description: "Wall/retaining Wall - Bulging/cracked",
    priority: "B",
  },
  { code: 55, description: "Zoning - Non-conforming", priority: "D" },
  {
    code: 56,
    description: "Boiler - Fumes/smoke/carbon Monoxide",
    priority: "A",
  },
  {
    code: 58,
    description: "Boiler - Defective / Inoperative / No Permit",
    priority: "B",
  },
  {
    code: 59,
    description: "Electrical Wiring %u2013 Defective / Exposed, In Progress",
    priority: "B",
  },
  {
    code: 62,
    description: "Elevator-danger Condition/shaft Open/unguarded",
    priority: "A",
  },
  {
    code: 63,
    description: "Elevator - Defective / Inoperative",
    priority: "B",
  },
  {
    code: 65,
    description: "Gas Hook-up/piping - Illegal Or Defective",
    priority: "A",
  },
  {
    code: 66,
    description: "Plumbing Work - Illegal/no Permit(Also Sprinkler/standpipe)",
    priority: "B",
  },
  {
    code: 67,
    description: "Crane - No Permit/license/cert/unsafe/illegal",
    priority: "A",
  },
  {
    code: 71,
    description: "Sro - Illegal Work/no Permit/change In Occup-use",
    priority: "B",
  },
  { code: 73, description: "Failure To Maintain", priority: "C" },
  {
    code: 74,
    description: "Illegal Commercial/manufacturing Use In Residential Zone",
    priority: "C",
  },
  { code: 75, description: "Adult Establishment", priority: "B" },
  {
    code: 76,
    description: "Unlicensed/illegal/improper Plumbing Work In Progress",
    priority: "A",
  },
  {
    code: 77,
    description: "Contrary To Ll58/87 (Handicap Access)",
    priority: "C",
  },
  {
    code: 78,
    description: "Privately Owned Public Space/non-compliance",
    priority: "B",
  },
  {
    code: 79,
    description: "Lights From Parking Lot Shining On Building",
    priority: "C",
  },
  {
    code: 80,
    description: "Elevator Not Inspected/illegal/no Permit",
    priority: "D",
  },
  { code: 81, description: "Elevator - Accident", priority: "A" },
  { code: 82, description: "Boiler - Accident/explosion", priority: "A" },
  {
    code: 83,
    description: "Construction -contrary/beyond Approved Plans/permits",
    priority: "B",
  },
  {
    code: 85,
    description: "Failure To Retain Water / Improper Drainage (Ll103/89)",
    priority: "C",
  },
  {
    code: 86,
    description: "Work Contrary To Stop Work Order",
    priority: "A",
  },
  {
    code: 88,
    description: "Safety Net/guard Rail-dam/inadeq/none(6fl.75 Ft Or Less)",
    priority: "B",
  },
  {
    code: 89,
    description: "Accident-cranes/derricks/suspension",
    priority: "A",
  },
  { code: 90, description: "Unlicensed/illegal Activity", priority: "C" },
  {
    code: 91,
    description: "Site Conditions Endangering Workers",
    priority: "A",
  },
  {
    code: 92,
    description: "Illegal Conversion Of Manufacturing/industrial Space",
    priority: "B",
  },
  {
    code: 93,
    description: "Request For Retaining Wall Safety Inspection",
    priority: "B",
  },
  {
    code: 94,
    description: "Plumbing-defective/leaking/not Maintained",
    priority: "C",
  },
  {
    code: "1a",
    description: "Illegal Conversion Commercial Bldg/space To Dwelling Units",
    priority: "B",
  },
  {
    code: "1b",
    description: "Illegal Tree Removal/topo. Change In Snad",
    priority: "B",
  },
  { code: "1d", description: "Con Edison Referral", priority: "B" },
  {
    code: "1e",
    description: "Suspended (Hanging) Scaffolds- No Pmt/lic/dangerous/accident",
    priority: "A",
  },
  { code: "1g", description: "Stalled Construction Site", priority: "B" },
  {
    code: "1k",
    description: "Bowstring Truss Tracking Complaint",
    priority: "D",
  },
  { code: "1z", description: "Enforcement Work Order (Dob)", priority: "D" },
  {
    code: "2a",
    description: "Posted Notice Or Order Removed/tampered With",
    priority: "B",
  },
  {
    code: "2b",
    description: "Failure To Comply With Vacate Order",
    priority: "A",
  },
  {
    code: "2c",
    description: "Smoking Ban %u2013 Smoking On Construction Site",
    priority: "B",
  },
  {
    code: "2d",
    description:
      "Smoking Signs %u2013 %u201cno Smoking Signs%u201d Not Observed On Construction Site",
    priority: "B",
  },
  {
    code: "2e",
    description: "Demolition Notification Received",
    priority: "A",
  },
  {
    code: "2f",
    description: "Building Under Structural Monitoring",
    priority: "D",
  },
  {
    code: "2g",
    description:
      "Advertising Sign/billboard/posters/flexible Fabric %u2013 Illegal",
    priority: "C",
  },
  {
    code: "2h",
    description: "Second Avenue Subway Construction",
    priority: "D",
  },
  { code: "2j", description: "Sandy: Building Destroyed", priority: "D" },
  {
    code: "2k",
    description: "Structurally Compromised Building (Ll33/08)",
    priority: "D",
  },
  {
    code: "2l",
    description: "Façade (Ll11/98)- Unsafe Notification",
    priority: "D",
  },
  { code: "2m", description: "Monopole Tracking Complaint", priority: "D" },
  {
    code: "3a",
    description: "Unlicensed/illegal/improper Electrical Work In Progress",
    priority: "B",
  },
  {
    code: "4a",
    description: "Illegal Hotel Rooms In Residential Buildings",
    priority: "B",
  },
  {
    code: "4b",
    description: "Sep - Professional Certification Compliance Audit",
    priority: "B",
  },
  { code: "4c", description: "Excavation Tracking Complaint", priority: "D" },
  {
    code: "4d",
    description: "Interior Demo Tracking Complaint",
    priority: "D",
  },
  { code: "4f", description: "Sst Tracking Complaint", priority: "D" },
  {
    code: "4g",
    description: "Illegal Conversion No Access Follow-up",
    priority: "B",
  },
  {
    code: "4j",
    description: "M.a.r.c.h. Program (Interagency)",
    priority: "D",
  },
  { code: "4k", description: "Best - Dm Tracking Complaint", priority: "D" },
  {
    code: "4l",
    description: "Best - High-rise Tracking Complaint",
    priority: "D",
  },
  {
    code: "4m",
    description: "Best - Low-rise Tracking Complaint",
    priority: "D",
  },
  {
    code: "4n",
    description: "Retaining Wall Tracking Complaint",
    priority: "D",
  },
  {
    code: "4p",
    description: "Legal/padlock Tracking Complaint",
    priority: "D",
  },
  { code: "4w", description: "Woodside Settlement Project", priority: "C" },
  {
    code: "5a",
    description: "Request For Joint Fdny/dob Inspection",
    priority: "B",
  },
  {
    code: "5b",
    description: "Non-compliance W/lightweight Materials",
    priority: "A",
  },
  {
    code: "5c",
    description:
      "Structural Stability Impacted - New Building Under Construction",
    priority: "A",
  },
  {
    code: "5e",
    description: "Amusement Ride Accident/incident",
    priority: "A",
  },
  { code: "5f", description: "Compliance Inspection", priority: "B" },
  {
    code: "5g",
    description: "Unlicensed/illegal/improper Work In Progress",
    priority: "B",
  },
  { code: "6a", description: "Vesting Inspection", priority: "C" },
];

const _dispositionCodes = [
  { code: "A1", description: "Buildings Violations(S) Served" },
  { code: "A2", description: "Criminal Court Summons Served" },
  { code: "A3", description: "Full Stop Work Order Served" },
  {
    code: "A4",
    description: "Buildings Violations(S) And Criminal Court Summons Served",
  },
  {
    code: "A6",
    description: "Vacant/open/unguarded Structure - Violation(S) Issued",
  },
  { code: "A7", description: "Complaint Accepted By Padlock Unit" },
  { code: "A8", description: "ECB Violation Served" },
  { code: "A9", description: "ECB & Dob Violations Served" },
  {
    code: "B1",
    description:
      "Buildings Violation(S) Prepared And Attempt To Serve Will Be Made",
  },
  {
    code: "B2",
    description: "ECB Violations(S) Prepared And Attempt To Serve Will Be Made",
  },
  {
    code: "C1",
    description: "Inspector Unable To Gain Access - Attempt Made",
  },
  {
    code: "C2",
    description: "Inspector Unable To Gain Access - Final Attempt",
  },
  { code: "C3", description: "Access Denied - Attempt Made" },
  { code: "C4", description: "Access Denied - Final Attempt" },
  { code: "C5", description: "Aw: No Access - 1st Attempt" },
  { code: "C6", description: "Aw: Access Denied - 1st Attempt" },
  { code: "C7", description: "Aw: No Access - 2nd Attempt" },
  { code: "C8", description: "Aw: Access Denied - 2nd Attempt" },
  { code: "D1", description: "Complaint Reassigned To Construction Unit" },
  { code: "D2", description: "Complaint Reassigned To Plumbing Unit" },
  { code: "D3", description: "Complaint Reassigned To Elevator Unit" },
  { code: "D4", description: "Complaint Reassigned To Best Squad" },
  {
    code: "D5",
    description: "Complaint Reassigned To Emergency Response Team",
  },
  { code: "D6", description: "Complaint Reassigned To Boiler Unit" },
  {
    code: "D7",
    description: "Complaint Reassigned To Cranes And Derricks Unit",
  },
  { code: "D9", description: "Complaint Reassigned To Electrical Unit" },
  {
    code: "Ea",
    description: "Complaint Assigned To Interior Demolition Unit",
  },
  {
    code: "Eb",
    description: "Complaint Assigned To Facade Insepction Safety Program",
  },
  {
    code: "Ec",
    description: "Complaint Assigned To Struct Compromised Buildings Unit",
  },
  { code: "Ed", description: "Complaint Assigned To Retaining Walls Unit" },
  { code: "Ee", description: "Complaint Reassigned For Review" },
  {
    code: "E1",
    description: "Complaint Assigned To Building Marshal's Office",
  },
  { code: "E2", description: "Complaint Reassigned To Padlock Unit" },
  {
    code: "E3",
    description: "Complaint Reassigned To Borough Office For Final Inspection",
  },
  {
    code: "E6",
    description: "Complaint Reassigned To Special Operations Unit",
  },
  { code: "E7", description: "Complaint Reassigned To Scaffold Safety Team" },
  {
    code: "E8",
    description: "Complaint Assigned To Excavation Inspection & Audits Team",
  },
  { code: "E9", description: "Complaint Assigned To Stalled Sites Unit" },
  {
    code: "F1",
    description:
      "Complaint Referred To Department Of Environmental Protection(Dep)",
  },
  {
    code: "F2",
    description:
      "Complaint Referred To Nys Division Of Housing And Community Renewal",
  },
  {
    code: "F3",
    description:
      "Complaint Referred To Department Of Health And Mental Hygiene",
  },
  {
    code: "F5",
    description: "Complaint Referred To Department Of Sanitation (Dos)",
  },
  {
    code: "F6",
    description: "Complaint Referred To Department Of Transportation",
  },
  {
    code: "F7",
    description: "Complaint Referred To Nys Office Of Real Properties Services",
  },
  {
    code: "F8",
    description:
      "Complaint Referred To Housing Preservation And Development (Hpd)",
  },
  {
    code: "F9",
    description:
      "Complaint Referred To Housing And Urban Development - Federal",
  },
  {
    code: "G2",
    description: "Complaint Referred To Department Of Parks And Recreation",
  },
  {
    code: "G3",
    description: "Complaint Referred To Taxi And Limousine Commission",
  },
  {
    code: "G4",
    description: "Complaint Referred To Department Of Consumer Affairs",
  },
  {
    code: "G5",
    description: "Complaint Referred To New York Police Department",
  },
  {
    code: "G6",
    description: "Complaint Referred To Fire Department Of New York",
  },
  {
    code: "G7",
    description: "Complaint Referred To Mayor's Office Of Special Enforcement",
  },
  { code: "G8", description: "Complaint Referred To Nyc Housing Authority" },
  {
    code: "G9",
    description:
      "Complaint Referred To Department Of Citywide Administrative Services",
  },
  { code: "H1", description: "Please See Complaint Number" },
  {
    code: "H3",
    description:
      "Building Violation Issued For Failure To Obey Stop Work Order",
  },
  {
    code: "H4",
    description: "Criminal Court Summons Served Due To Failure To Obey Swo",
  },
  {
    code: "H5",
    description:
      "Stop All Work / No Tcao (Temporary Certificate Of Approval And Operation)",
  },
  {
    code: "I1",
    description: "Complaint Unsubstantiated Based On Department Records",
  },
  {
    code: "I2",
    description: "No Violation Warranted For Complaint At Time Of Inspection",
  },
  { code: "I3", description: "Compliance Inspection Performed" },
  {
    code: "J1",
    description: "Follow-up Inspection To Be Scheduled Upon Further Research",
  },
  {
    code: "K1",
    description: "Insufficient Information/unable To Locate Address",
  },
  {
    code: "K2",
    description: "Address Invalid - Pending Buildings Verification",
  },
  {
    code: "K3",
    description: "Cranes And Derricks - No Address - See Comments",
  },
  {
    code: "K4",
    description:
      "Cranes And Derricks - Stop Work Order - No Associated Address",
  },
  { code: "K5", description: "Letter Of Deficiency Issued" },
  { code: "K6", description: "Letter Of Deficiency Issued With Partial Swo" },
  { code: "K7", description: "Notification Of Correction Received" },
  { code: "K8", description: "Correction Verified By Dob" },
  { code: "L1", description: "Partial Stop Work Order" },
  { code: "L2", description: "Stop Work Order Fully Rescinded" },
  { code: "L3", description: "Stop Work Order Partially Rescinded" },
  { code: "Ma", description: "March: No Enforcement Action Taken" },
  {
    code: "Mb",
    description: "March: Failure To Maintain Bldg/ecb Nov Issued",
  },
  {
    code: "Mc",
    description: "March: Contrary To Approved Plans/ecb Nov Issued",
  },
  {
    code: "Md",
    description: "March: Exit Passage Obstructed/ecb Nov Issued",
  },
  {
    code: "Me",
    description: "March: Exit Passage Obstructed/ecb Nov & Full Vacate Issued",
  },
  {
    code: "Mf",
    description:
      "March: Exit Passage Obstructed/ecb Nov & Partial Vacate Issued",
  },
  {
    code: "Mg",
    description: "March: Occupancy Contrary To C Of O/none/ecb Nov Issued",
  },
  { code: "Mh", description: "March: No Pa Permit/ecb" },
  {
    code: "Mi",
    description: "March: No Pa Permit/ecb Nov & Partial Vacate Issued",
  },
  { code: "Mj", description: "March: Work Without Permit/ecb Nov Issued" },
  { code: "Mk", description: "March: No Pa Permit/ecb Nov Issued" },
  { code: "M1", description: "Bicycle Access Plan: Elevator Use Acceptable" },
  {
    code: "M2",
    description: "Bicycle Access Plan: Elevator Use Not Acceptable",
  },
  {
    code: "M3",
    description: "Bicycle Access Plan: Alternate Use Parking Requirement Met",
  },
  {
    code: "M4",
    description:
      "Bicycle Access Plan: Alternate Use Parking Requirement Not Met",
  },
  { code: "Nd", description: "Notice Of Deficiency Issued" },
  { code: "P1", description: "Job Vested" },
  {
    code: "P2",
    description: "Follow Up Inspection Required Pending Adoption",
  },
  { code: "P3", description: "Closure / Padlock Order Issued" },
  { code: "P4", description: "Closure / Padlock Order Issued Rescinded" },
  { code: "P5", description: "Potential Plumbing Work" },
  { code: "P6", description: "Initial Notification Accepted" },
  {
    code: "Q1",
    description: "Compromised Structure: Owner Prof Report Required",
  },
  {
    code: "Q2",
    description: "Compromised Structure: Owner Prof Report Received",
  },
  {
    code: "Q3",
    description:
      "Compromised Structure: Report Deadline Passed - Penalties Apply",
  },
  { code: "Q4", description: "Compromised Structure: Condition Remedied" },
  {
    code: "Q5",
    description:
      "Compromised Structure: Report Deadline Passed, Civil Penalties Due",
  },
  { code: "Ra", description: "Commissioner's Order - Issued To Owner" },
  {
    code: "Rb",
    description: "Commissioner's Order - Owner Remediation Plans Accepted",
  },
  {
    code: "Rc",
    description: "Commissioner's Order - Owner Remediation In Progress",
  },
  { code: "Rd", description: "Commissioner's Order - Daily Monitoring" },
  { code: "Re", description: "Commissioner's Order - Weekly Monitoring" },
  { code: "Rf", description: "Commissioner's Order - Monthly Monitoring" },
  {
    code: "Rg",
    description: "Commissioner's Order - Owner Remediation Completed",
  },
  { code: "Rh", description: "Emergency Declaration - Issued" },
  { code: "Ri", description: "Immediate Emergency Declaration - Issued" },
  {
    code: "Rj",
    description: "Immediate/emergency Declaration - Action Completed",
  },
  { code: "Rk", description: "Unsafe Building - Violation Issued" },
  { code: "Rl", description: "Unsafe Building - Action Completed" },
  {
    code: "Rm",
    description: "Structural Monitoring: Owner Professional Report Required",
  },
  {
    code: "Rn",
    description:
      "Structural Monitoring: Owner Professional Report Deadline Passed",
  },
  { code: "Ru", description: "Ll11 Unsafe - Initiated" },
  { code: "Rv", description: "Ll11 Unsafe - Action Completed" },
  {
    code: "Rw",
    description: "Immediate/emergency Dec - Previously Issued/underway",
  },
  {
    code: "Rx",
    description: "Unsafe Building - Precept Previously Issued/underway",
  },
  { code: "Ry", description: "Facade Report" },
  { code: "Rz", description: "Vacate - Previously Issued/underway" },
  {
    code: "R1",
    description: "Inspection - No Immediate Action/no Follow-up Required",
  },
  {
    code: "R2",
    description: "Inspection - No Immediate Action/weekly Inspection",
  },
  {
    code: "R3",
    description: "Inspection - No Immediate Action/monthly Inspection",
  },
  { code: "R4", description: "Inspection - Engineering Assessment Required" },
  {
    code: "R5",
    description: "Inspection - Class 1 ECB(S) Written/order To Correct",
  },
  {
    code: "R6",
    description: "Engineering - No Immediate Action/no Follow-up Required",
  },
  {
    code: "R7",
    description: "Engineering - No Immediate Action/weekly Assessment",
  },
  {
    code: "R8",
    description: "Engineering - No Immediate Action/monthly Assessment",
  },
  {
    code: "R9",
    description: "Building At Risk Program - No Immediate Danger",
  },
  { code: "S0", description: "All Work Completed" },
  { code: "S1", description: "Stalled: Excavation ‚äì No Immediate Threat" },
  {
    code: "S2",
    description: "Stalled: Excavation Deteriorating - No Immediate Threat",
  },
  { code: "S3", description: "Stalled: Excavation - Unsafe" },
  {
    code: "S4",
    description: "Stalled: Superstructure ‚äì No Immediate Threat",
  },
  {
    code: "S5",
    description:
      "Stalled: Superstructure Deteriorating ‚äì No Immediate Threat",
  },
  { code: "S6", description: "Stalled: Superstructure - Unsafe" },
  { code: "S7", description: "Stalled: No Construction Begun / Vacant Site" },
  { code: "S8", description: "Stalled: Construction In Progress" },
  { code: "S9", description: "Stalled: Emergency Declaration Filed" },
  {
    code: "V3",
    description:
      "Stop Work Order Violation Served (Non-compliant After-hours Work)",
  },
  { code: "W1", description: "Violation Served For Disobeying Vacate Order" },
  { code: "Wa", description: "Weather Related: No Action Necessary" },
  { code: "Wb", description: "Weather Related: No Access" },
  {
    code: "Wc",
    description:
      "Weather Rlt: Nod Issued/unsafe/red Tag Remains/eng.evaluation Required",
  },
  {
    code: "Wd",
    description:
      "Weather Rlt: Nod Issued/restricted/ylw Tag Remains/eng.evaluation Reqd",
  },
  {
    code: "We",
    description:
      "Weather Rlt: Nod Issued/dwngrd To Ylw/restricted/eng.evaluation Required",
  },
  {
    code: "Wf",
    description:
      "Weather Related: No Further Action Necessary/downgraded To Green",
  },
  {
    code: "Wg",
    description:
      "Weather Related: Green Tag Rescinded/no Occupancy Restrictions",
  },
  {
    code: "Wh",
    description:
      "Weather Rlt: Green Tag Rescinded/structurally Sound/utilities Unresolved",
  },
  {
    code: "Wi",
    description:
      "Weather Rlt: No Action Warranted By Dob/refer To Other Agency/call 311",
  },
  {
    code: "Wj",
    description:
      "Weather Related: Enforcement Order To Be Issued/see Referenced Complaint",
  },
  { code: "Xx", description: "Administrative Closure" },
  { code: "Y1", description: "Full Vacate Order Served" },
  { code: "Y2", description: "Vacate Order Fully Rescinded" },
  { code: "Y3", description: "Partial Vacate Order Served" },
  { code: "Y4", description: "Vacate Order Partially Rescinded" },
];

const _getBins = (buildingRes) => {
  let _Bins = [];
  if (
    buildingRes.altAddressData &&
    buildingRes.altAddressData.docs.length > 0
  ) {
    for (let i = 0; i < buildingRes.altAddressData.docs.length; i++) {
      _Bins = _Bins.concat(buildingRes.altAddressData.docs[i].bin);
    }
  }

  return _Bins;
};

const _filterHPDViolations = (res, buildingRes, type) => {
  let filteredViolations = {
    hpd: []
  };

  let _Bins = _getBins(buildingRes);
  if (res.docs && res.docs.length > 0) {
    for (var i = 0; i < res.docs.length; i++) {
      res.docs[i].Doctype = type;
      var violation = res.docs[i];
      if (
        !_Bins ||
        !violation.bin ||
        _Bins.length <= 0 ||
        _Bins.indexOf(violation.bin) >= 0
      ) {
        violation['id'] = violation['id']; 
        violation['number'] = violation['violation_id'];
        violation['type'] = type;
        violation['date'] = violation['nov_issued_date'] ? violation['nov_issued_date'] : violation['inspection_date'];
        violation['status'] = violation['current_status'];
        violation['description'] = violation['nov_description'];
        violation['url'] = _makeHPDViolationURL(violation);
        filteredViolations.hpd.push(violation);
      
      }
    }
  }
  return filteredViolations;
};

const _filterDOB311 = (violations, buildingRes, type) => {
  var filteredViolations = [];
  let complaints = {};
  let _Bins = _getBins(buildingRes);

  if (violations.docs && violations.docs.length > 0) {
    for (var i = 0; i < violations.docs.length; i++) {
      violations.docs[i].Doctype = type;
      var violation = violations.docs[i];

      // violation.violation_type = type;
      if (
        !_Bins ||
        !violation.bin_number ||
        _Bins.length <= 0 ||
        _Bins.indexOf(violation.bin_number) >= 0
      ) {
        
        if (complaints[violation.complaint_number]) {
          if (violation.status == "closed") {
            complaints[violation.complaint_number] = Object.assign(
              {},
              complaints[violation.complaint_number],
              violation
            );
          }
        } else {
          complaints[violation.complaint_number] = violation;
        
        }
  
         
        
      
      }
    }
    for (var key in complaints) {
      let violation = complaints[key];
       violation['id'] = violation['id']; 
       violation['number'] = violation['complaint_number']; 
       violation['type'] = type;
       violation['date'] = violation['entered_date'];
       violation['status'] = violation['status'];
       violation['description'] = _findComplaintCode(violation['complaint_category'])['description'] != "--" ? 
          _findComplaintCode(violation['complaint_category'])['description']
        : _findComplaintCode(violation['disposition_code'], 'disposition')['description'];
       violation['url'] = _makeComplaintsUrl(violation); 
        filteredViolations.push(violation);
    }
    
  }

  return filteredViolations;
};
const _filterDOBViolations = (violations, buildingRes) => {
  var filteredViolations = {
    dob: [],
    ecb: [],
  };

  let _Bins = _getBins(buildingRes);

  if (violations.docs && violations.docs.length > 0) {
    for (var i = 0; i < violations.docs.length; i++) {
      var violation = violations.docs[i];
      if (!_Bins || 
        !violation.bin_number ||
        _Bins.length <= 0 ||
        _Bins.indexOf(violation.bin_number) >= 0
      ) {
        if (violation.is_ecb_doc) {
          violation['id'] = violation['id']; 
          violation['number'] = violation['ecb_number']; 
          violation['type'] = "ECB Violation";
           violation['date'] = violation['ecb_issue_date'];
           violation['status'] = violation['ecb_violation_status'];
           violation['description'] = violation['violation_description_ecb'];
           violation['url'] = _makeViolationsUrl(violation); 
          filteredViolations.ecb.push(violation);
         
        } else {
          violation['id'] = violation['id']; 
          violation['number'] = violation['number']; 
          violation['type'] = "DOB Violation";
           violation['date'] = violation['issue_date'];
           violation['status'] = violation['violation_category'];
           violation['description'] = violation['violation_type'];
           violation['url'] = _makeViolationsUrl(violation); 
          filteredViolations.dob.push(violation);
          
        }
      }
    }
  }

  return filteredViolations;
};
const _filterComplaints = (res, type) => {
  var complaints = {};
  var filteredComplaints = {
    dobComplaints: [],
  };
  if (res.docs && res.docs.length > 0) {
    for (var i = 0; i < res.docs.length; i++) {
      res.docs[i].Doctype = type;
      var complaint = res.docs[i];
      // complaint.violation_type = type;
      if (complaints[complaint.complaint_number]) {
        if (complaint.status == "closed") {
          complaints[complaint.complaint_number] = Object.assign(
            {},
            complaints[complaint.complaint_number],
            complaint
          );
        }
      } else {
        complaints[complaint.complaint_number] = complaint;
        complaints[complaint.complaint_number].complaint_desc =
          _findComplaintCode(complaint.complaint_category);
      }
    }
  }

  var complaintsArray = [];
  for (var key in complaints) {
    complaints[key]['id'] = complaints[key]['id']; 
    complaints[key]['number'] = complaints[key]['complaint_number']; 
    complaints[key]['type'] = type;
    complaints[key]['date'] = complaints[key]['date_entered'];
    complaints[key]['status'] = complaints[key]['status'];
    complaints[key]['description'] = complaints[key]['complaint_category_description'];
    complaints[key]['url'] = _makeComplaintsUrl(complaints[key]);
    filteredComplaints.dobComplaints.push(Object.assign({}, complaints[key]));
    
  }

  return filteredComplaints;
};

const _findComplaintCode = (code, type) => {
  if(!code)  return { code: "--", description: "--", priority: "--" };
  if (type == "disposition") {
    code = code.toString().toUpperCase();
    for (var i = 0; i < _dispositionCodes.length; i++) {
      var disposition = _dispositionCodes[i];
      if (disposition.code.toString() == code.toString()) {
        return disposition;
      }
    }
  } else {
    code = code.replace(/^0+/, "");
    for (var i = 0; i < _complaintCodes.length; i++) {
      var complaint = _complaintCodes[i];
      if (complaint.code.toString() == code.toString()) {
        return complaint;
      }
    }
  }

  return { code: "--", description: "--", priority: "--" };
};

const _makeViolationsUrl = (row) => {
  var violation = row;
  var date = violation.is_ecb_doc ? violation.ecb_issue_date : violation.issue_date;
  var isn = _zeroPad(violation.isn_dob_bis_viol, 10);
  //console.log(isn);
  return violation.is_ecb_doc ? `http://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?requestid=2&ecbin=${violation.ecb_number}`
  :`http://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=2&allbin=${violation.bin_number}&allinquirytype=BXS3OCV4&allisn=${isn}&ppremise60=${violation.number}`;
 }
  
const _makeComplaintsUrl = (row) => {
  var complaint = row;
    
  var date = complaint.entered_date;


  var key = _zeroPad(complaint.complaint_number, 10);
  return `http://a810-bisweb.nyc.gov/bisweb/OverviewForComplaintServlet?complaintno=${complaint.complaint_number}`;
}
  
const _makeHPDViolationURL = (row) => {
  var violation = row;
  var date = violation.nov_issued_date;
  return `https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?subject=&env_report=REMOTE_HOST%2CHTTP_ADDR%2CHTTP_USER_AGENT&bgcolor=%23FFFFFF&required=p2&p1=${violation.borough_code}&p2=${violation.street_number}&p3=${violation.street}`;
}

const Violations = ({ buildingRes, user }) => {
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(40);
  const [data, setData] = useState(null);
  const [sort, setSort] = useState('desc');

  const sortArray = (property) => {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          /* next line works with strings and numbers, 
           * and you may want to customize it to your needs
           */
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }
  useEffect(() => {
    let combinedData = [];
    Promise.all([
      _getDOBComplaints(buildingRes, { url: url }),
      _getECBViolations(buildingRes, { url: url }),
      _getHPDViolations(buildingRes, { url: url }),
      _getDOBViolations(buildingRes, { url: url }),
    ]).then((res) => {
      if (res[0]) {
        const filteredComplaints = _filterComplaints(res[0], "DOB Complaint");
        combinedData = [...combinedData, ...filteredComplaints.dobComplaints];
      }
      if (res[1]) {
        const filteredComplaints = _filterDOB311(
          res[1],
          buildingRes,
          "DOB 311 Complaint"
        );
        combinedData = [...combinedData, ...filteredComplaints];
      }
      if (res[2]) {
        const filteredViolations = _filterHPDViolations(
          res[2],
          buildingRes,
          "HPD Violation"
        );
        combinedData = [...combinedData, ...filteredViolations.hpd];
      }
      if (res[3]) {
        const filteredComplaints = _filterDOBViolations(
          res[3],
          buildingRes,
          "DOB Violations"
        );
        combinedData = [
          ...combinedData,
          ...filteredComplaints.dob,
          ...filteredComplaints.ecb
        ];
      }
      combinedData.sort(sortArray("-date"))
      setData(combinedData);
    });
  }, []);


  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleSort = () => {
    if(sort === "desc"){
      
      setSort('asc')
      data.sort(sortArray("date"))
    }else{
      setSort('desc')
      data.sort(sortArray("-date"))
    }
    
    setData(data);
    setPage(0);
  };

  return (
    <Fragment>
   {data === null ? 
     <LinearLoading/>
     :
    <Fragment>
      <div className="material-table__wrap">
        <Table className="material-table">
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
              className={`material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
              </TableCell> */}
              {columns.map((row) => (
                <TableCell
                  className="material-table__cell material-table__cell--sort material-table__cell-right"
                  key={row.id}
                  // align={rtl.direction === "rtl" ? "right" : "left"}
                  padding={row.disablePadding ? "none" : "default"}
                  sortDirection={"desc"}
                >
                  {row.diableSort ? (
                    <span>{row.label}</span>
                  ) : (
                    <TableSortLabel
                      // active={orderBy === row.id}
                      direction={sort}
                      onClick={handleSort}
                      className="material-table__sort-label"
                      dir="ltr"
                    >
                      {row.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { data.length == 0 ?
              <TableRow className="material-table__row" >
                <TableCell colSpan={columns.length+1} className="material-table__cell" align="">
                    No Violations or Complaints Found
                </TableCell>
              </TableRow>
              : data.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
              .map((d) => {
                return (
                  <TableRow
                    className="material-table__row"
                    role="checkbox"
                    // onClick={(event) => handleBuildingClick(event, d)}
                    // aria-checked={thisSelected}
                    tabIndex={-1}
                    key={d.key}
                    // selected={thisSelected}
                  >
                    <TableCell className="material-table__cell" align="">
                      {d.date ? (
                        <div>
                          <div className="mb-1">{_capitalizeText(moment(d.date).fromNow())}</div>
                          <div className="light-text small-text">
                            ({moment(d.date).format("MM/DD/YYYY")})
                          </div>
                        </div>
                      ) : (
                        <span className="light-text small-text">--</span>
                      )}
                     
                    </TableCell>
                    <TableCell
                      className="material-table__cell light-text small-text"
                      size="small"
                      align=""
                    >
                      <div>
                        <div  className="mb-1">{d.type ? d.type : "--"}</div>
                        <div className="light-text small-text">
                        (#: {d.number})
                      </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="material-table__cell light-text small-text"
                      size="small"
                      align=""
                    >
                      {d.status ? (
                        <div>
                        <div className="light-text small-text mb-2">
                          {_capitalizeText(d.status)}
                        </div>
                        {(d.status.indexOf("active") > -1 || d.status.indexOf("open") > -1) &&
                        <div className="light-text small-text">
                          <a href={d.url} className="link-text" target="_blank">Confirm Status <OpenInNewIcon size={12} style={{marginTop:-2}} /></a>
                        </div>
                        }
                        </div>
                        
                      ) : (
                        <span className="light-text small-text">--</span>
                      )}
                      
                    </TableCell>
                    <TableCell
                      className=" light-text small-text"
                      size="small"
                      align=""
                    >
                      {d.description ? (
                        <div>
                        <div className="light-text small-text mb-2">
                          {_capitalizeText(d.description)}
                        </div>
                        <div className="light-text small-text">
                          <a href={d.url} className="link-text" target="_blank">More Info <OpenInNewIcon size={12} style={{marginTop:-2}} /></a>
                        </div>
                        </div>
                      ) : (
                        <span className="light-text small-text">--</span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>

      <TablePagination
        component="div"
        className="material-table__pagination"
        count={data && data.length}
        rowsPerPage={rowPerPage}
        page={page}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[8, 20, 40, 80, 120]}
        dir="ltr"
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
      />
    </Fragment>
    
  }
  </Fragment>
  );
};

export default Violations;
