import React, { PureComponent, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Container, Badge,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import DocIcon from 'mdi-react/FileDownloadOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';
import ContractIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import SoldIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import LinearLoading from '../../../shared/components/LinearLoading';
import LendingHistory from './Profile/components/LendingHistory';


import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { _buildingIDs, _axiosCall } from '../../../shared/helpers/apicalls';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

import { bindActionCreators } from 'redux';
import buyersListActions from '../../../redux/actions/buyersListActions';

import Overview from './Overview';

import BuyersList from './BuyersList';
import Profile from './Profile';
import Sales from './Sales';

import AddToBuyersListButton from '../../../shared/components/buttons/AddToBuyersListButton';
import ContactDeveloperButton from '../../../shared/components/buttons/ContactDeveloperButton';
import ReportUnitStatusButton from '../../../shared/components/buttons/ReportUnitStatusButton';

import ShareButton from '../../../shared/components/buttons/ShareButton';
import AddCollectionButton from '../../../shared/components/collections/AddCollectionButton';
import AIAssistantButton from '../../../shared/components/ai/assistant/AIAssistantButton';

import TrialLimit from '../../../shared/components/payment/TrialLimit';

import UnitIcons from '../../../shared/components/UnitIcons';

import { Publisher } from './../../../shared/pubsub/publisher.js';

import BuildingIcons from '../../../shared/components/BuildingIcons';
import OfferingStatus from '../../../shared/components/OfferingStatus';

import TaxAbatement from '../../../shared/components/TaxAbatement';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect";
import{
	_capitalizeText,
	_formatNumber,
	_formatPrice,
	_getBuildingPhotos,
	_makeSlugifiedArr,
	_formatUnitNumber,
	_urlToName,
	_getBuildingURL,
	_nameToUrl,
	StatusBadge,
	UnitCurrentStatus,
	propertyFL,
	_isResiUnit,
	_slugifyText,
	_isCoop
} from '../../../shared/helpers/utils';

const apiURL = process.env.API_ROOT + '/query/';


class UnitTabContainer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.match && this.props.match.params.tab ? this.props.match.params.tab : 'overview',
      latestUnit: false,
      unitHistory: false,
      property: false,
      listing: null,
      fetchInProgress: false,
      gatedView: false,
      transactions:null,
	  buildingListing: null,
	  taxAbatement: null
    };
    this.toggle = this.toggle.bind(this);
  }
  
  componentDidMount() {
	const { buildingRes, user, viewedBuildings  } = this.props;
	const unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : '""';		
	
	const { viewBuilding } = this.props.actions;
	
	const viewedCount = viewedBuildings && Array.isArray(viewedBuildings) ? viewedBuildings.length : 0;
    const maxViews = process.env.MAX_TRIAL_BUILDING_VIEWS;
	const remainingViews = maxViews - viewedCount;
	if(user.subscriptionStatus && user.subscriptionStatus === 'trialing' && remainingViews <= 0 && viewedBuildings.filter(item => item.key === buildingRes.key).length <= 0){
		this.setState( { gatedView :true });
	}else if(user.subscriptionStatus && user.subscriptionStatus === 'trialing' && remainingViews < 0){
		this.setState( { gatedView :true });
	}else{
		this._getUnitData();
	  	this._getUnitOPData();
	  	this._getACRISData();	  	
		this._getBuildingTaxAbatementData();
		const { viewBuilding } = this.props.actions;  	
	  	viewBuilding(buildingRes.key, user);
	  	
	  	Publisher.publish(`track.unitView.${this.state.activeTab}`, {building: buildingRes.key, unit: unit});
	  	
		
	}
	
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {		
	const { buildingRes, user, viewedBuildings  } = this.props;
	const unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : '""';
	const { viewBuilding } = this.props.actions;
	const { gatedView } = this.state;
	if(prevProps.match.params.unit !== this.props.match.params.unit){
		this._getUnitData();
		this._getUnitOPData();
		this._getACRISData();
		this._getBuildingTaxAbatementData();
	}
	
	if(user.subscriptionStatus && user.subscriptionStatus === 'active' && gatedView === true){
		this.setState( { gatedView : false });
		this._getUnitData();
	  	this._getUnitOPData();	 
	  	this._getACRISData(); 
		this._getBuildingTaxAbatementData();	
		const { viewBuilding } = this.props.actions;  	
	  	viewBuilding(buildingRes.key, user);	  	
	  	Publisher.publish(`track.unitView.${this.state.activeTab}`, {building: buildingRes.key, unit: unit});
	  	
	}
	
			 
  }

  toggle = (tab) => {
    const { buildingRes  } = this.props;
    const { activeTab } = this.state;
    const unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : '""';
    if (activeTab !== tab) {
	  const buildingURL = _getBuildingURL(buildingRes, 'building_slugs') + '/' + _nameToUrl(unit) + (tab ? `/tab/${tab}` : '');
	  this.props.history.push(buildingURL);
      this.setState({
        activeTab: tab,
      });
      Publisher.publish(`track.buildingView.${tab}`, {unit: unit});
    }
  };
  
  _getUnitOPData = async () => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		const { buildingRes } = this.props;		
		const unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : '""';			
		const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip}) AND address_2_normalized:(${unit})`;

		let axiosData = {
			url: apiURL + "mp-offering-plans-schedulea",
			method: "post",
			query: {
				q: q,
				fq: ["-deleted:true"],
				sort: "address_2_normalized asc",
				wt: "json",
				rows: 999,
				"json.facet": {
					avg_price: "avg(price)",
					avg_ppsf: "avg(div(price, sq_ft))",
					med_price: "percentile(price,50)",
					med_ppsf: "percentile(div(price, sq_ft),25,50,75)",
					min_price: "min(price)",
					max_price: "max(price)",
					unit_mix : {
						type : "terms",
						field: "bedrooms",
						limit: 10,	
						facet: {
							avg_unit_size : "avg(sq_ft)",
							med_unit_size: "percentile(sq_ft,25,50,75)",
							avg_fees : "avg(monthly_fees)",
							med_fees: "percentile(monthly_fees,25,50,75)",
							avg_taxes : "avg(monthly_taxes)",
							med_taxes: "percentile(monthly_taxes,25,50,75)",
							avg_ppsf: "avg(div(price, sq_ft))",
							min_price: "min(price)",
							max_price: "max(price)",
							avg_monthly_carrying_charges: "avg(monthly_carrying_charges)",
						}					
					}
				}
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){
					const latestUnit = res.docs.filter(item => item.document_version==='latest')[0];
					const unitHistory = res.docs.filter(item => item.id.indexOf('latest')<0);
					this.setState({latestUnit: latestUnit, unitHistory: unitHistory, fetchInProgress: false});
				}else{
					this.setState({latestUnit: null, unitHistory: null, fetchInProgress: false});
				}
				
				
				
			})
			.catch(error => {
				console.log("error: " + error);
			});

		// console.log(formattedData)
		return formattedData;
  };
  
  _getUnitData = async () => {
	     if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
     	}

		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		const { buildingRes, user } = this.props;		
		let unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : '""';		
		unit = unit === 'property' ? '""' : unit;
		const q = `(address_2_normalized:(${unit}) OR address_2_normalized:(${this.props.match.params.unit})) AND (alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip})`;

		let axiosData = {
			url: apiURL + `we3-properties?cache=15&cache_key=${_slugifyText(unit)}`,
			method: "post",
			query: {
				q: q,
				fl: [...propertyFL, ...["description"]],
				fq: ["deleted:false"],
				sort: "address_2_normalized asc",
				wt: "json",
				rows: 10
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){						
					let property = res.docs[0];	
					//If building is sold out override stats
					if(buildingRes && buildingRes.offering_verified_status === 'sold out'){
						if(["active", "not_yet_recorded", "sold", "in contract", "resale"].indexOf(property.offering_status) < 0){
							  property.offering_status = "sold";
						}							
					}
								
					this.setState({property: property});	
					
					Publisher.publish(`track-mp.unitView.${this.state.activeTab}`, {user : user, building: buildingRes, property: property});
									
					if(property.offering_status === 'active' || property.last_listed_rent_key_all || property.last_listed_sale_key_all){
						
						if(property.last_listed_sale_key_all && !(property.last_listed_rent_status_all == 'active' && property.last_listed_sale_status_all !='active')){
							this._getListingData(property.last_listed_sale_key_all);
						}else if(property.last_listed_rent_key_all){
							this._getListingData(property.last_listed_rent_key_all);
						}else{
							this._getBuildingListingData();
							
						}						
					}else{
						this._getBuildingListingData();
					}
				}else{
					this.setState({listing:false, fetchInProgress: false});
				}				
			})
			.catch(error => {
				console.log("error: " + error);
				this.setState({listing:false, fetchInProgress: false});
			});
		return formattedData;
  };
  
  _getACRISData = async () => {
	     if (!this.state.fetchInProgress) {
			this.setState(prevState => ({ fetchInProgress: true, }));
     	}

		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		const { buildingRes } = this.props;		
		const unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : '""';	
		const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip}) AND address_2_normalized:(${unit})`;

		let axiosData = {
			url: apiURL + "we3-transactions?cache=15",
			method: "post",
			query: {
				q: q,
				fq: ["source_providers:*ACRIS*"],
				sort: "close_date_formatted desc",
				wt: "json",
				rows: 10
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){						
					this.setState({transactions:res.docs, fetchInProgress: false});
				}else{
					this.setState({transactions:false, fetchInProgress: false});
				}				
			})
			.catch(error => {
				console.log("error: " + error);
				this.setState({transactions:false, fetchInProgress: false});
			});
		return formattedData;
  };

  
  _getListingData = async (listingKey) => {
		const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		const { buildingRes } = this.props;		
		const unit = this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*");		
		const q = `key:${listingKey.replace("REBNY-LISTING-", "*").replace("REBNY-VOW-", "REBNY-IDX-")}`;

		let axiosData = {
			url: apiURL + "search-listings?cache=15",
			method: "post",
			query: {
				q: q,
				//fq: ["deleted:false"],
				sort: "solr_updated_date desc",
				wt: "json",
				rows: 10
			}
		};

		let formattedData = await _axiosCall(axiosData)
			.then(res => {
				// console.log('Chart Data', res);
				if(res.docs && res.docs.length>0){					
					this.setState({listing: res.docs[0]});
				}else{
					this.setState({listing: false});
				}
				
			})
			.catch(error => {
				console.log("error: " + error);
			});
		return formattedData;
  };
  
  _getBuildingListingData = async () => {
		  const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;		
		  const { buildingRes } = this.props;		
		  const q = `(alternate_addresses:("${buildingRes.alternate_addresses.join('" OR "')}") AND zip:${buildingRes.zip})`;
  
		  let axiosData = {
			  url: apiURL + "search-listings?cache=15",
			  method: "post",
			  query: {
				  q: q,
				  fl : ["description"],
				  fq: ["deleted:false", "listing_type:sale"],
				  sort: "sale_status asc, listing_date desc",
				  wt: "json",
				  rows: 5
			  }
		  };
  
		  let formattedData = await _axiosCall(axiosData)
			  .then(res => {
				  // console.log('Chart Data', res);
				  if(res.docs && res.docs.length>0){	
					  let blgListing = res.docs[0];
					  res.docs.forEach(l => {
						  if(l.description && l.description.length > blgListing.description.length){
							  blgListing = l;
						  }
					  })
					  				
					  this.setState({listing: false, buildingListing: blgListing, fetchInProgress: false});
				  }else{
					  this.setState({listing: false, fetchInProgress: false});
				  }
				  
			  })
			  .catch(error => {
				  console.log("error: " + error);
			  });
		  return formattedData;
	};
	_getBuildingTaxAbatementData = async () => {
		 const signedIn = true; //this.props.reduxState && this.props.reduxState.user.authenticated;
		 const { buildingRes, property } = this.props;
		 const addressQ = buildingRes.alternate_addresses.join('" OR "');
		 
		 let q = `(alternate_addresses:("${addressQ}") AND (zip:${buildingRes.zip} OR borough:"${buildingRes.borough}"))`
		 
		 let axiosData = {
			 url: apiURL + "we3-tax-abatements-subsidies?cache=120",
			 method: "post",
			 query: {
				 q: q,
				 fq: [],
				 sort: "report_date desc",
				 wt: "json",
				 rows: 10,
			 }
		 }
		 
		 let formattedData = await _axiosCall(axiosData)
			 .then(res => {
				 // console.log('Chart Data', res);
				 if(res && res.docs){
					 this.setState({taxAbatement: res.docs});
				 }else{
					 this.setState({taxAbatement: false});
				 }
				 
				
					 
			 })
			 .catch(error => {
				 console.log("error: " + error);
			 });
	 
	 }
  render() {
    const { buildingRes, rtl, user, transactionRes, statsRes  } = this.props;
    const { activeTab, latestUnit, unitHistory, property, listing, fetchInProgress, gatedView, transactions, buildingListing, taxAbatement} = this.state;
    const unit = this.props.match.params.unit ? this.props.match.params.unit.replace("apt-", "apt\\ ").replace(/-/g, "*") : "";
     if(gatedView){
	     return <TrialLimit />;
	}
	
	
    if(!property || listing===null || fetchInProgress) {
	    return <LinearLoading />;
    }
    
    const _boro = _urlToName(this.props.match.params.borough);
	const _hood = _urlToName(this.props.match.params.neighborhood);
	const buildingNameOrAdr = buildingRes.name 
      ? _capitalizeText(buildingRes.name)
      : buildingRes.display_full_street_address
        ? _capitalizeText(buildingRes.display_full_street_address)
        : _capitalizeText(buildingRes.full_street_address);
	const adr = buildingRes.display_full_street_address ? buildingRes.display_full_street_address : buildingRes.full_street_address;
	
	const unitNumber = _formatUnitNumber(property.address_2_normalized, buildingRes);
	
	const activeResale = listing && listing.sale_status === 'active' && ['not_yet_recorded','sold','resale'].indexOf(property.offering_status) >= 0;
	
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>          	
            <div className="card__title" key={property.id}>
            	<Row>
	        
	            	<Tooltip title="Back to Buildings">	            		
		              	<IconButton	className="material-table__toolbar-button" onClick={() => this.props.history.go(-1) }>
						  <ArrowBackIcon />
					  	</IconButton>
				  	</Tooltip>
				
					<Tooltip title="See Building Profile">	            		
		              	<IconButton	
		              		className="material-table__toolbar-button"
		              		onClick={(e) => {e.stopPropagation();window.open(`https://marketproof.com${_getBuildingURL(buildingRes, 'building_slugs').replace('buildings', 'building')}/${property.address_2_normalized.replace("apt ", "unit-").replace(' ', '-')}`)}}
		              	>
						  <OpenInNewIcon /> 
					  	</IconButton>
				  	</Tooltip>
				  	<AddToBuyersListButton unitKey={property.key} buildingKey={buildingRes.key} units={[property.address_2_normalized]} unitSave />
				  	{/*buildingRes.offering_plan_documents && buildingRes.offering_plan_documents[0] && 
					  	<Tooltip title="Download Offering Plan">
	                      	<IconButton
					            onClick={(e) => {e.stopPropagation();window.location.href=buildingRes.offering_plan_documents[0];}}
					            className="material-table__toolbar-button"
					          >
	
							  <DocIcon className="material-table__checkbox" />
						  	</IconButton>
					  	</Tooltip>
				  	*/}
				  	{user && ['seller', 'enterprise'].indexOf(user.role) > -1 &&
					  	<Fragment>
			              	<Tooltip title="Update Unit Details">	            		
				              	<IconButton	
				              		className="material-table__toolbar-button"
				              		onClick={(e) => {e.stopPropagation()}}
				              	>
								  <EditIcon /> 
							  	</IconButton>
							</Tooltip>
							<Tooltip title="Mark In Contract">	            		
				              	<IconButton	
				              		className="material-table__toolbar-button"
				              		onClick={(e) => {e.stopPropagation()}}
				              	>
								  <ContractIcon /> 
							  	</IconButton>
							</Tooltip>	
	
							<Tooltip title="Mark as Sold">	            		
				              	<IconButton	
				              		className="material-table__toolbar-button"
				              		onClick={(e) => {e.stopPropagation()}}
				              	>
								  <SoldIcon /> 
							  	</IconButton>
							</Tooltip>	
						</Fragment>              	  
              	   }
				  	<div className="top__toolbar" style={{display:"flex"}}>
				  		<AIAssistantButton property={property} buildingRes={buildingRes} transactionRes={transactionRes} statsRes={statsRes} listing={listing} buildingListing={buildingListing} taxAbatement={taxAbatement}/>
				  		{(["active"].indexOf(property.current_status)>=0 || (buildingRes.building_status === 'sponsor' && ["shadow", ""].indexOf(property.current_status)>=0)) &&
				  			<ContactDeveloperButton  buildingRes={buildingRes} property={property} other={`test`} listing={listing} />
				  		}
						
				  		<ShareButton  buildingRes={buildingRes} property={property} other={`test`}  listing={listing} />
						<AddCollectionButton buildingRes={buildingRes} property={property} listing={listing} />
				  		
				    </div>
				</Row>
			   <div className="unit__badge">
			   
			   		{/*property.source_organizations.filter(source => ['NYAG', 'CDEC', 'ADEC'].indexOf(source) > -1).length > 0 &&
				   		property.property_status === 'resale' && 				   		
				   		<StatusBadge status={'sold'} unit={property} expanded />				   		
			   		*/}
			   		<UnitCurrentStatus status={property.current_status} unit={property} expanded /> 
						   
					{/*<ReportUnitStatusButton  buildingRes={buildingRes} property={property} other={`test`} listing={listing} textButton />*/}
			    </div>
               <h3 className="page-title">
               	<Link className="header-building__link" to={`${_getBuildingURL(buildingRes, 'key')}`}>	
               		{buildingNameOrAdr} 
               	</Link>	
               		{` `}/ {unitNumber ? unitNumber : 'Unit'}	
               		
                    <UnitIcons building={buildingRes} unit={property} detail />
               	</h3>
                {isMobile ?
	               <Fragment>
	              <h3 className="page-subhead subhead">			        	
						{buildingRes.name ? <span>{_capitalizeText(adr)}<br/></span> : ''}
						{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
				  </h3> 
				  
				  </Fragment>
	           :
			      <h3 className="page-subhead subhead">			        	
							{buildingRes.name ? _capitalizeText(adr) + ', ': ''}{buildingRes.name && isMobile && <br/>}{_capitalizeText(_hood)}, {_capitalizeText(_boro)}
			      </h3>
		       }	
		       
		      
                        
             
            </div>
            {_isResiUnit(property) &&
	           <div className="tabs tabs--bordered-bottom">
	              <div className="tabs__wrap">
	                <Nav tabs>
	                  <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'overview' })}
	                      onClick={() => {
	                        this.toggle('overview');
	                      }}
	                    >
	                      <span className="tab-title">Overview</span>
	                    </NavLink>
	                  </NavItem>
	                  <NavItem>
		                    <NavLink
		                      className={classnames({ active: activeTab === 'sales' })}
		                      onClick={() => {
		                        this.toggle('sales');
		                      }}
		                    >
		                      <span className="tab-title">Past Sales</span>
		                    </NavLink>
		                  </NavItem>
	                  {(!_isCoop(buildingRes)) &&
						  <NavItem>
							<NavLink
								className={classnames({ active: activeTab === 'taxes' })}
								onClick={() => {
								  this.toggle('taxes');
								}}
							  >
								<span className="tab-title">Taxes</span>
							  </NavLink>
							</NavItem>}
					    <NavItem>
	                    <NavLink
	                      className={classnames({ active: activeTab === 'financing' })}
	                      onClick={() => {
	                        this.toggle('financing');
	                      }}
	                    >
	                      <span className="tab-title">Financing</span>
	                    </NavLink>
						</NavItem>   
						<NavItem>
						<NavLink
						  className={classnames({ active: activeTab === 'documents' })}
						  onClick={() => {
							this.toggle('documents');
						  }}
						>
						  <span className="tab-title">Documents</span>
						</NavLink>
	                  </NavItem>    
	                                   
	                </Nav>
	                
	              </div>            
	            </div>
	        }
            <TabContent activeTab={activeTab}>
              <TabPane tabId="overview">
              	<Profile latestUnit={latestUnit} unitHistory={unitHistory} property={property} listing={listing} {...this.props} activeResale={activeResale} transactions={transactions} setTab={this.toggle} />
              </TabPane>
              {activeTab === 'sales' &&
	              <TabPane tabId="sales" className="">
	              		<Sales unit={unit} latestUnit={latestUnit} unitHistory={unitHistory} property={property} listing={listing} {...this.props} activeResale={activeResale} transactions={transactions} />              	           
	              </TabPane>
		      }
			  {(!_isCoop(buildingRes)) && activeTab === 'taxes' &&
					<TabPane tabId="taxes" className="" style={{minHeight:"50vh"}}>
						<TaxAbatement unit={unit} latestUnit={latestUnit} unitHistory={unitHistory} property={property} listing={listing} {...this.props} activeResale={activeResale} transactions={transactions} />              	           
					</TabPane>
				}

              {activeTab === 'financing' &&
	              <TabPane tabId="financing" className="financing__container">
	              		<LendingHistory latestUnit={latestUnit} unitHistory={unitHistory} property={property} listing={listing} {...this.props} activeResale={activeResale} transactions={transactions} />              	           
	              </TabPane>
		      }
			  {activeTab === 'documents' &&
					<TabPane tabId="documents" className="financing__container">
							<LendingHistory latestUnit={latestUnit} unitHistory={unitHistory} property={property} listing={listing} {...this.props} activeResale={activeResale} transactions={transactions} acris />              	           
					</TabPane>
				}
            </TabContent>
            
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
	//console.log(state);
  const buyersLists = state.buyersLists.priorityFilter
    ? state.buyersLists.buyersLists.filter(item => item.priority === state.buyersLists.priorityFilter)
    : state.buyersLists.buyersLists;
  return { buyersLists, 
	  		theme: state.theme, 
	  		rtl: state.rtl, 
	  		user: state.user, 
	  		viewedBuildings: Array.isArray(state.buyersLists.viewedBuildings) ? state.buyersLists.viewedBuildings.slice() : [],
	  	};
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(buyersListActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(UnitTabContainer)));
